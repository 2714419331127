<template>
  <div
    ref="modal"
    class="modal fade"
    :class="{ show: active, 'd-block': active }"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title badge bg-primary">Rute Tol</h5>

          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            v-on:click="$emit('closeModal', null)"
            aria-label="btn-close"
          ></button>
        </div>

        <div class="modal-body">
          <div class="row mb-3">
            <div class="col-lg-4">
              <label
                for="working_day"
                class="col-form-label"
                style="padding-left: 25px"
                >Type Truck</label
              >
            </div>
            <div class="col-lg-6">
              <label>: {{ data?.ratio?.truck_category }} - {{ getGolongan }}</label>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-4">
              <label
                for="defaultconfig"
                class="col-form-label"
                style="padding-left: 25px"
                >Route</label
              >
            </div>
            <div class="col-lg-6">
              
              <multiselect
                @search-change="asyncRoute"
                v-model="route"
                :options="fetchRoute"
                label="name"
                track-by="name"
                placeholder="Please Select Route"
                @input="loadRoute"
              >
                <span slot="noResult">-</span>
              </multiselect>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-4">
              <div>
                <label
                  for="defaultconfig"
                  class="col-form-label"
                  style="padding-left: 25px"
                  >Pool - Load</label
                >
              </div>
              <div class="row">
                <div class="col-md-12 table-responsive">
                  <table class="table table-bordered mb-3">
                    <thead>
                      <tr>
                        <td>Nama Tol</td>
                        <td>Harga</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in tolDeparture" :key="index">
                        <td>{{ item.toll_gate_name }}</td>
                        <td>Rp. {{ getTolPrice(item) | format_number }}</td>
                      </tr>
                      <tr v-if="tolDeparture.length <= 0">
                        <td colspan="2">Empty Row</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div>
                <label
                  for="defaultconfig"
                  class="col-form-label"
                  style="padding-left: 25px"
                  >Loading - Unloading</label
                >
              </div>
              <div class="row">
                <div class="col-md-12 table-responsive">
                  <table class="table table-bordered mb-3">
                    <thead>
                      <tr>
                        <td>Nama Tol</td>
                        <td>Harga</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in tolBack" :key="index">
                        <td>{{ item.toll_gate_name }}</td>
                        <td>Rp.{{ getTolPrice(item) | format_number }}</td>
                      </tr>
                      <tr v-if="tolBack.length <= 0">
                        <td colspan="2">Empty Row</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>


            <div class="col-md-4">
              <div>
                <label
                  for="defaultconfig"
                  class="col-form-label"
                  style="padding-left: 25px"
                  >Unloading - Pool</label
                >
              </div>
              <div class="row">
                <div class="col-md-12 table-responsive">
                  <table class="table table-bordered mb-3">
                    <thead>
                      <tr>
                        <td>Nama Tol</td>
                        <td>Harga</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in tolReturn" :key="index">
                        <td>{{ item.toll_gate_name }}</td>
                        <td>Rp.{{ getTolPrice(item) | format_number }}</td>
                      </tr>
                      <tr v-if="tolBack.length <= 0">
                        <td colspan="2">Empty Row</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="row ">
                <div class="col-md-8 col-sm-10">Total  Pool - Load</div>
                <div class="col-md-4 col-sm-2">
                  : Rp.{{ getDepaturePrice | format_number }}
                </div>
              </div>
              
            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-8 col-sm-10">Total loading - Unloading</div>
                <div class="col-md-4 col-sm-2">
                  : Rp.{{ getBackPrice | format_number }}
                </div>
              </div>
            </div>


            <div class="col-md-4">
              <div class="row">
                <div class="col-md-8 col-sm-10">Total Unloading - Pool</div>
                <div class="col-md-4 col-sm-2">
                  : Rp.{{ getReturnPrice | format_number }}
                </div>
              </div>
            </div>

          </div>

       

        </div>

        <div class="modal-footer">
  <div class="row w-100">
    <!-- Grand Total di sebelah kiri -->
    <div class="col-md-6 d-flex align-items-center">
      <b>Grand Total: Rp.{{ (getDepaturePrice + getBackPrice + getReturnPrice) | format_number }}</b>
    </div>
    <!-- Tombol di sebelah kanan dengan jarak -->
    <div class="col-md-6 d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-secondary me-3"
        v-on:click="$emit('closeModal', null)"
      >
        {{ $t("close_txt") }}
      </button>
      <button
        type="button"
        class="btn btn-success"
        v-on:click="submitData"
      >
        Next
      </button>
    </div>
  </div>
</div>



      </div>
    </div>
  </div>
</template>

<script>
import multiselect from "vue-multiselect";

export default {
  components: {
    multiselect:multiselect
  },
  name: "ModalRuteTolUjo",
  emits: ["closeModal"],
  props: {
    showModal: Boolean,
    data: {},
  },
  computed: {
    getGolongan() {
      let text = "Undefined";
      if (this.data?.ratio) {
        if (this.data.ratio.class == "1") {
          text = "Golongan I";
        } else if (this.data.ratio.class == "2") {
          text = "Golongan II";
        } else if (this.data.ratio.class == "3") {
          text = "Golongan III";
        } else if (this.data.ratio.class == "4") {
          text = "Golongan IV";
        } else if (this.data.ratio.class == "5") {
          text = "Golongan V";
        }
      }
      return text;
    },
    getDepaturePrice() {
      let text = 0;
      let data = this.data?.ratio;
      if (this.tolDeparture.length > 0) {
        this.tolDeparture.forEach(function (datas) {
          text += datas.gate_master["class_" + data.class];
        });
      }
      return text;
    },
    getBackPrice() {
      let text = 0;
      let data = this.data?.ratio;
      if (this.tolBack.length > 0) {
        this.tolBack.forEach(function (datas) {
          text += datas.gate_master["class_" + data.class];
        });
      }
      return text;
    },


    getReturnPrice() {
      let text = 0;
      let data = this.data?.ratio;
      if (this.tolReturn.length > 0) {
        this.tolReturn.forEach(function (datas) {
          text += datas.gate_master["class_" + data.class];
        });
      }
      return text;
    },

  },
  watch: {
    showModal: {
      handler(newVal) {
        this.active = newVal;
        this.loadItems();
        if(this.active){
          if(this.data.toll_route.length == 0 && this.data.RouteList.length !== 0 ){
            this.getSuggestedRoute();
          }
          console.log(typeof this.data.toll_route);
          console.log(this.data.toll_route);
          if(this.route == null && typeof this.data.toll_route !== "undefined"){
            this.loadRoute(this.data.toll_route);
            this.route = this.data.toll_route;
          }
        }
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      active: this.showModal,
      route: null,
      fetchRoute: [],
      tolBack: [],
      tolDeparture: [],
      tolReturn: [],
    };
  },
  methods: {
    getSuggestedRoute(){
      let route = this.data.RouteList[0];
      console.log(route);
      const baseURI = this.$settings.endPoint + "rm-toll-route?route_name="+route.route_name;
      return this.$http.get(baseURI).then((response) => {
        let data = response.data.datas.data;
        if(data.length > 0){
          this.route =data[0];
          this.loadRoute(data[0])
        }
        console.log(data);
      });
    },
    submitParent(index, row) {
      this.$emit("closeModal", row);
    },
    asyncRoute(ev) {
      const baseURI = this.$settings.endPoint + "rm-toll-route?name=" + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchRoute = response.data.datas.data;
      });
    },
    submitData(){
      let data ={
        'route':this.route,
        'price':this.getDepaturePrice + this.getBackPrice
      }
     this.$emit("closeModal", data);

    },
    loadRoute(ev) {
      this.tolDeparture = [];
      this.tolBack = [];
      this.tolReturn = [];
      if (ev !== null) {
        const baseURI =
          this.$settings.endPoint +
          "rm-toll-route/detail/" +
          ev.rm_toll_route_id;
        return this.$http.get(baseURI).then((response) => {
          this.tolDeparture = response.data.datas.departure;
          this.tolBack = response.data.datas.back;
          this.tolReturn = response.data.datas.return;
        });
      }
      console.log(ev);
    },
    getTolPrice(ev) {
      return ev.gate_master["class_" + this.data.ratio.class];
    },
    loadItems() {
      const baseURI = this.$settings.endPoint + "rm-toll-route";
      return this.$http.get(baseURI).then((response) => {
        this.fetchRoute = response.data.datas.data;
      });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },
  },
  mounted() {
    //this.loadItems();
    // this.rows = this.props.data;
  },
};
</script>

<style scoped>
.blur-content {
  filter: blur(5px);
  pointer-events: none;
}

.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 120%;
  height: 100%;
  margin-left: -10%;
}
</style>
