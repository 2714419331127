<template>
  <div
    ref="modal"
    class="modal fade "
    :class="{ show: active, 'd-block': active }"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title badge bg-primary">
            Verifikasi by {{ filterBy }}
          </h5>

          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            v-on:click="$emit('closeModalVerifikasi', null)"
            aria-label="btn-close"
          ></button>
        </div>

        <div class="modal-body">
          <form class="forms-sample" @submit.prevent="submitData" method="POST">
                    <div class="d-flex justify-content-between align-items-baseline mb-2"></div>
                    <div class="row mb-3">
                      <div class="col-sm-4">
                        <label for="customer_name " class="col-form-label">Customer</label>
                        <input type="text" class="form-control" id="customer_name" placeholder="Customer Code"
                          :value="headers.customer_name" disabled />
                        <div v-if="errors.customer_name">
                          <div v-for="error in errors.customer_name" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-sm-4">
                        <div class="mb-3">
                          <label for="period_name" class="col-form-label">Carier</label>
                          <input type="text" class="form-control" disabled
                            :value="forms.carrier_no + '-' + forms.carrier_name">
                          <!-- <v-select
                            :options="fetchCarier"
                            v-model="forms.carier"
                            return-object
                          ></v-select> -->
                          <div v-if="errors.period_name">
                            <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="mb-3">
                          <label for="period_name" class="col-form-label">Divison</label>
                          <input type="text" class="form-control" disabled :value="forms.division">

                          <div v-if="errors.period_name">
                            <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="mb-3">
                          <label for="period_name" class="col-form-label">Load Group</label>
                          <input type="text" class="form-control" disabled :value="forms.load_group">

                          <div v-if="errors.period_name">
                            <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-4">
                            <div class="mb-1">
                              <label for="period_name" class="col-form-label">Tier</label>
                              <input type="text" class="form-control" disabled :value="forms.tier">
                              <div v-if="errors.period_name">
                                <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="mb-1">
                              <label for="period_name" class="col-form-label">All Inclusive</label>
                              <input type="text" class="form-control" disabled :value="forms.all_inclusive">

                              <div v-if="errors.all_inclusive">
                                <div v-for="error in errors.all_inclusive" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="mb-1">
                              <label for="period_name" class="col-form-label">Round Trip</label>
                              <input type="text" class="form-control" disabled :value="forms.round_trip">

                              <div v-if="errors.round_trip">
                                <div v-for="error in errors.round_trip" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="mb-2">
                          <label for="period_name" class="col-form-label">Origin Location</label>
                          <input type="text" class="form-control" disabled :value="forms.origin_location">

                          <!-- <multiselect
                            @search-change="asyncLocation"
                            v-model="originLocation"
                            :options="fetchLocation"
                            label="name"
                            track-by="name"
                            placeholder="Please Select"
                          >
                            <span slot="noResult">-</span>
                          </multiselect> -->

                          <div v-if="errors.period_name">
                            <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="mb-2">
                          <label for="period_name" class="col-form-label">Origin Region</label>
                          <input type="text" class="form-control" disabled :value="forms.origin_region">

                          <!-- <multiselect
                            @search-change="asyncSearchRegion"
                            v-model="originRegion"
                            :options="fetchRegion"
                            label="region_code"
                            track-by="region_code"
                            placeholder="Please Select"
                          >
                            <span slot="noResult">-</span>
                          </multiselect> -->

                          <div v-if="errors.period_name">
                            <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="mb-2">
                          <label for="period_name" class="col-form-label">Destination Location</label>
                          <!-- <multiselect
                            @search-change="asyncLocation"
                            v-model="destLocation"
                            :options="fetchLocation"
                            label="name"
                            track-by="name"
                            placeholder="Please Select"
                          >
                            <span slot="noResult">-</span>
                          </multiselect> -->
                          <input type="text" class="form-control" disabled :value="forms.destination_location">

                          <div v-if="errors.period_name">
                            <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="mb-2">
                          <label for="period_name" class="col-form-label">Destination Region</label>
                          <input type="text" class="form-control" disabled :value="forms.destination_region">


                          <div v-if="errors.period_name">
                            <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="mb-3">
                          <label for="period_name" class="col-form-label">Equipment</label>
                          <!-- <v-select
                            :options="fetchCarier"
                            v-model="carier"
                            return-object
                          ></v-select> -->
                          <input type="text" class="form-control" disabled :value="forms.equipment">

                          <!-- <multiselect
                              @search-change="asyncSearchSKU"
                              v-model="equipment"
                              :options="fetchSku"
                              placeholder="Please Select"
                              track-by="type_truck"
                              label="type_truck"
                            >
                              <span slot="noResult">-</span>
                            </multiselect> -->
                          <div v-if="errors.period_name">
                            <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="mb-2">
                          <label for="period_name" class="col-form-label">Effective Date</label>
                          <!-- <date-range-picker
                            style="width: 100%"
                            :locale-data="locale"
                            v-model="effectiveDate"
                            :opens="opens"
                            :singleDatePicker="true"
                            :timePicker24Hour="true"
                            :timePicker="true"
                          >
                          </date-range-picker> -->
                          <input type="text" class="form-control" disabled :value="forms.effective_date">

                          <div v-if="errors.period_name">
                            <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="mb-2">
                          <label for="period_name" class="col-form-label">Expired Date</label>
                          <!-- <date-range-picker
                            style="width: 100%"
                            :locale-data="locale"
                            v-model="expiredDate"
                            :opens="opens"
                            :singleDatePicker="true"
                            :timePicker24Hour="true"
                            :timePicker="true"
                          >
                          </date-range-picker> -->
                          <input type="text" class="form-control" disabled :value="forms.expiration_date">

                          <div v-if="errors.period_name">
                            <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="mb-3">
                          <div class="row">
                            <div class="col-sm-6">
                              <label for="period_name" class="col-form-label">Currency</label>
                              <input type="text" class="form-control" disabled :value="forms.currency">
                              <div v-if="errors.period_name">
                                <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <label for="period_name" class="col-form-label">Cost Basis / Rate Basis</label>
                              <input type="text" class="form-control" disabled :value="forms.cost_basis">


                              <div v-if="errors.period_name">
                                <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-lg-6">
                        <label for="period_code" class="col-form-label">{{
                          $t("periodCodeTxt")
                        }}</label>
                        <input
                          type="text"
                          class="form-control"
                          id="period_code"
                          :placeholder="$t('periodCodeTxt')"
                          v-model="forms.period_code"
                          readonly
                        />
                        <div v-if="errors.period_code">
                          <div
                            v-for="error in errors.period_code"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <label for="price" class="col-form-label">{{
                          $t("priceTxt")
                        }}</label>
                        <input
                          type="number"
                          class="form-control"
                          id="price"
                          v-model="forms.price"
                          :placeholder="$t('priceTxt')"
                          required
                        />
                        <div v-if="errors.price">
                          <div
                            v-for="error in errors.price"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div> -->
                    </div>
                    <div class="row mb-3">
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                          <a class="nav-link active" id="truckload-tab" data-bs-toggle="tab" href="#truckload" role="tab"
                            aria-controls="truckload" aria-selected="true">Truck Load</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" id="accessorial-tab" data-bs-toggle="tab" href="#accessorial" role="tab"
                            aria-controls="accessorial" aria-selected="true">Accessorial</a>
                        </li>
                      </ul>
                      <div class="tab-content border border-top-0 p-3" id="myTabContent">
                        <div class="tab-pane fade show active" id="truckload" role="tabpanel"
                          aria-labelledby="truckload-tab">
                          <!-- Truck Load Tabs -->
                          <div class="row">
                            <div class="col-sm-4" v-if="false">
                              <div class="row mb-3">
                                <label class="col-sm-3 col-form-label">Pool</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control" placeholder="Pool" v-model="truckLoad.pool"
                                    disabled />
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-3 col-form-label">Loading</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control" placeholder="Loading"
                                    v-model="truckLoad.loading" disabled />
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-3 col-form-label">Unloading</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control" placeholder="Unloading"
                                    v-model="truckLoad.unloading" disabled />
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-3 col-form-label">Back to Pool</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control" placeholder="Back to Pool"
                                    v-model="truckLoad.back_to_pool" disabled />
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-4" v-if="false">
                              <div class="row mb-3">
                                <label class="col-sm-3 col-form-label">KM (Basic)</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control" placeholder="KM (Basic)"
                                    v-model="forms.km_basis" />
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-3 col-form-label">KM (Margin)</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control" placeholder="KM (Margin)"
                                    v-model="forms.km_margin" />
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-3 col-form-label">Rasio</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control" placeholder="Rasio" v-model="forms.rasio" />
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-3 col-form-label">Solar (Liter)</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control" placeholder="Solar (Liter)"
                                    v-model="forms.solar_liter" />
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <div class="row mb-3">
                                <label class="col-sm-4 col-form-label">Rate</label>
                                <div class="col-sm-8">
                                  <input type="text" class="form-control" placeholder="Solar (Rupiah)"
                                    v-model="forms.solar_rupiah" disabled />
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-4 col-form-label">Lead Time</label>
                                <div class="col-sm-8">

                                  <input type="text" class="form-control" placeholder="Lead Time"
                                    v-model="forms.lead_time" disabled />
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-4 col-form-label">Service Level</label>

                                <div class="col-sm-8">
                                  <input type="text" class="form-control" disabled :value="forms.service_level">

                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-4 col-form-label">No Intermediate Stop</label>
                                <div class="col-sm-8">
                                  <input type="text" class="form-control" disabled :value="forms.no_intermediate_stop">

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="accessorial" role="tabpanel" aria-labelledby="accessorial-tab">
                          <div class="row table-responsive">
                            <table class="table">
                              <thead>
                                <th>No</th>
                                <th>Enabled</th>
                                <th>Rate Lane Type</th>
                                <th>Rate Mode</th>
                                <th>Charge Code</th>
                                <th>Quantity</th>
                                <th>Rate</th>
                                <th>Min Rate</th>
                                <th>Max Rate</th>
                                <th>Weight Rating</th>
                                <th>Auto Apply</th>
                              </thead>
                              <tr v-for="(data, index) in accessorial" :key="data.charge_code">
                                <td>{{ index + 1 }}</td>
                                <td>{{ data.enabled }}</td>
                                <td>{{ data.rate_line_type }}</td>
                                <td>{{ data.mode }}</td>
                                <!-- <td>{{ data.charge_code }}</td> -->
                                <td>
                                  {{ data.charge_code }}
                                </td>
                                <td>{{ data.cost_qty }}</td>
                                <!-- <td>{{ data.rate }}</td> -->
                                <td>
                                  {{ data.rate }}
                                </td>
                                <td>{{ data.min }}</td>
                                <td>{{ data.max }}</td>
                                <!-- <td>{{ data.weight_rating }}</td> -->
                                <td>{{ data.weight_rating == 0 ? "No" : "Yes" }}</td>
                                <td>{{ data.auto_apply == 0 ? "No" : "Yes" }}</td>
                              </tr>
                              <tr v-if="accessorial.length == 0">
                                <td colspan="11" style="text-align: center">
                                  Empty Data
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                   
                  </form>
        </div>
        <div class="modal-footer">
         

                      <button class="btn btn-warning text-black fw-bolder btn-icon-text" v-on:click="$emit('closeModalVerifikasi', null)" type="button">
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("close_txt") }}
                      </button>

          

                      <button v-if="!isLoading" class="btn btn-primary float-end btn-sm" @click="submitData">
                        <i class="link-icon" data-feather="save"></i>
                          Verifikasi
                      </button>

                      <div v-if="isLoading" class="d-flex align-items-center">
                        <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                        <strong>{{ $t("loadingTxt") }}...</strong>
                      </div>

        </div>

        

      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  components: {},
  name: "ModalVerifikasi",
  emits: ["closeModalVerifikasi"],
  props: {
    showModalVerifikasi: Boolean,
    rm_payable_rate_detail_id: null,
    rm_payable_rate_detail_id_copy:null,
    filterBy:String,
  },
  watch: {
    showModalVerifikasi: {
      handler(newVal) {

        this.active = newVal;
       
        if (this.active) {
          this.loadItems(this.rm_payable_rate_detail_id_copy);
        }

        

        const body = document.querySelector("body");
        this.showModalVerifikasi
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      headers:[],
      forms:[],
      accessorial:[],
      isLoading: false,
      active: this.showModalVerifikasi,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 1000,
      },
      columns: [],
      errors: [],
     
     
      rows: [],
      rowsSelection: [],
      locale: [],
      loadId: "",
      orderStatus: "",
      rules: {
        select: [(v) => !!v || "Item is required"],
      },
    };
  },
  methods: {
    submitParent(index, row) {
      this.$emit("closeModalVerifikasi", row);
    },

    removeOrderHeaderIds() {
      this.transport_order_header_ids = [];
      this.$refs["list-transport-orders"].unselectAllInternal();
      // console.log(this.$refs['list-transport-orders'].selectedRows)
    },

    selectionChanged(ev) {
      this.rowsSelection = [];
      this.transport_order_header_ids = [];
      this.rowsSelection = ev.selectedRows;

      this.rowsSelection.forEach((item) => {
        this.transport_order_header_ids.push(item.transport_order_header_id);
      });
    },


    getAllFormated(name_field, value) {
     
     if (value != null) {
       
       let formatted = parseFloat(value).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

       if (formatted.indexOf(',') === -1) {
         formatted += ',00';
       } else {
         let decimalPart = formatted.split(',')[1];
         if (decimalPart.length === 1) {
           formatted += '0';
         }
       }


       this.forms[name_field] = formatted;

     } else {
       this.forms[name_field] = 0
     }


     return this.forms[name_field]

   },



   submitData() {
      this.isLoading=true;
      // this.forms.company_id = this.company.company_id;
  
      const baseURI = this.$settings.endPoint + "rm-payable-rate-header/verify-recomendation/"+this.rm_payable_rate_detail_id;
      this.$http
        .post(baseURI,{
          rm_payable_rate_detail_id_copy:this.rm_payable_rate_detail_id_copy
        })
        .then((response) => {
          this.isLoading=false;
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            //console.log(response.data.datas.user_id);
            var params = this.$onRandom(this.rm_payable_rate_detail_id);
            if(this.filterBy=='rute'){
              
              window.location.href = "/payable-rate/rute/edit/" + params;
            }else{
            
              window.location.href = "/payable-rate/customer/detail/"+this.$route.params.id+"/edit/" + params;
            }
           
         
            // this.success('Berhasil');
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.isLoading=false;
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              if (this.errors.message) {
                this.error(this.errors.message);
              } else {
                this.resultError(error.response.data.errors);
              }
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },



    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },


    getAllFormatedDetail2(index, name_field, value) {

      let isNegative = value < 0;
      let absoluteValue = Math.abs(value);

      //console.log(this.accessorial[index][name_field]);

      let formatted = parseFloat(absoluteValue).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      if (formatted.indexOf(',') === -1) {
        formatted += ',00';
      } else {
        let decimalPart = formatted.split(',')[1];
        if (decimalPart.length === 1) {
          formatted += '0';
        }
      }

      formatted = isNegative ? '-' + formatted : formatted;

      return formatted
      //this.accessorial[index].rate=formatted;

      // return  this.accessorial[index];

    },


    loadItems(id) {
      this.rows = [];
    
      const baseURI =
        this.$settings.endPoint + "rm-payable-rate-header/detail-detail/" + id;
      return this.$http
        .get(
          baseURI
        )
        .then((response) => {
         
          this.headers = response.data.datas.header;
          this.forms = response.data.datas;
          // this.forms.solar_liter=this.forms.solar_liter.toFixed(2);

          // console.log(response.data.datas.accessorial);
          this.accessorial = response.data.datas.accessorial;
          console.log(this.accessorial);
          this.getAllFormated('solar_rupiah', this.forms.solar_rupiah)
          this.getAllFormated('km_basis', this.forms.km_basis)
          this.getAllFormated('km_margin', this.forms.km_margin)
          this.getAllFormated('solar_liter', this.forms.solar_liter)
         

         
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                // this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                // this.$router.push('/page-not-found');
              }
            }
          }
        });
    },

   

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },
    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  mounted() {
    //this.loadItems();
    //   this.loadItems();
    // this.rows = this.props.data;
  },
};
</script>
  
  <style scoped>
.blur-content {
  filter: blur(5px);
  pointer-events: none;
}

.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 120%;
  height: 100%;
  margin-left: -10%;
}
</style>