<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TemplateOrder"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="col-lg-12 col-xl-12 stretch-card">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-lg-6">
                    <h4 style="margin-right: 5px" class="float-start">
                      Payable Tariff
                    </h4>
                  </div>

                  <div class="col-lg-6">
                    <button
                      v-if="permision_role.method_read"
                      class="btn btn-success float-end btn-xs"
                      style="margin-right: 5px"
                      @click.prevent="createData()"
                    >
                      <i class="link-icon" data-feather="plus"></i>
                      {{ $t("createNew") }}
                    </button>
                    <!-- <button
                      class="btn btn-twitter float-end btn-xs"
                      style="margin-right: 5px"
                      @click.prevent="payableTariffUpload()"
                    >
                      <i class="link-icon" data-feather="upload"></i>
                      Payable Tariff Upload
                    </button> -->
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row my-3">
                  <div class="col-lg-4">
                    <div class="mb-3">
                      <label class="form-label">Date Range</label>
                      <date-range-picker
                        style="width: 100%"
                        control-container-class="form-control"
                        :locale-data="locale"
                        v-model="dateRange"
                        @update="loadItems"
                        :opens="opens"
                        :showDropdowns="true"
                        :ranges="customRange"
                        :singleDatePicker="false"
                        :timePicker24Hour="false"
                        :timePicker="false"
                        :showWeekNumbers="false"
                      >
                      </date-range-picker>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="mb-3">
                      <label class="form-label">Status</label>
                      <div class="mb-4">
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="active"
                            value="active"
                            name="active"
                            v-model="status"
                          />
                          <label class="form-check-label" for="active">
                            Active
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="complete"
                            value="complete"
                            name="complete"
                            v-model="status"
                          />
                          <label class="form-check-label" for="complete">
                            Complete
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="canceled"
                            value="canceled"
                            name="canceled"
                            v-model="status"
                          />
                          <label class="form-check-label" for="canceled">
                            Canceled
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="mb-5">
                      <button
                        class="btn btn-primary"
                        type="button"
                        @click="loadItems"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                <vue-good-table
                  title="list-user"
                  mode="remote"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  @on-selected-rows-change="selectionChanged"
                  :totalRows="totalRecords"
                  :line-numbers="true"
                  :isLoading.sync="isLoading"
                  :pagination-options="{
                    enabled: true,
                  }"
                  :select-options="{
                    enabled: true,
                    selectOnCheckboxOnly: true,
                    selectionText: $t('data_selected'),
                    clearSelectionText: $t('clear'),
                  }"
                  :rows="rows"
                  :columns="columns"
                >
                  <div slot="table-actions">
                    {{ $t("no_select_data") }}
                  </div>
                  <div slot="selected-row-actions">
                    <button
                      v-if="btnUploadFile"
                      type="button"
                      class="btn btn-success btn-xs me-2"
                      style="margin-right: 20px"
                      @click="uploadFile"
                    >
                      Upload File
                    </button>
                    <button
                      type="button"
                      class="btn btn-twitter btn-xs me-2"
                      style="margin-right: 20px"
                    >
                      Request Rate File
                    </button>
                    <button
                      type="button"
                      class="btn btn-warning btn-xs me-2"
                      style="margin-right: 20px"
                    >
                      Change Status
                    </button>

                    <button
                      type="button"
                      class="btn btn-success btn-xs me-2"
                      style="margin-right: 20px"
                      @click="sendBlujayAll"
                    >
                    <i class="mdi mdi-send" style="font-size: 16px"></i> Send to Blujay
                    </button>

                  </div>
                  <template slot="table-row" slot-scope="props">
                    <span
                      v-if="props.column.field == 'effective_expiration_date'"
                    >
                      {{ props.row.effective_date }} -{{
                        props.row.expiration_date
                      }}
                    </span>
                    <span v-if="props.column.field == 'actions'">
                      <a
                        href="#"
                        title="Edit"
                        @click.prevent="editData(props.index, props.row)"
                      >
                        <i
                          class="mdi mdi-square-edit-outline"
                          style="font-size: 16px"
                        ></i>
                      </a>

                      <a
                        v-if="props.row.status !== 'ENABLED'"
                        href="#"
                        title="Status"
                        @click.prevent="
                          confirmStatus(props.index, props.row, 'ENABLED')
                        "
                      >
                        <i
                          class="mdi mdi-checkbox-marked"
                          style="font-size: 16px"
                        ></i>
                      </a>

                      <a
                        v-if="props.row.status == 'ENABLED'"
                        href="#"
                        title="Status"
                        @click.prevent="
                          confirmStatus(props.index, props.row, 'DISABLED')
                        "
                      >
                        <i
                          class="mdi mdi-close-box-outline"
                          style="font-size: 16px"
                        ></i>
                      </a>
                      <a
                        href="#"
                        title="Download Rate"
                        @click.prevent="downloadRate(props.index, props.row)"
                      >
                        <i class="mdi mdi-download" style="font-size: 16px"></i>
                      </a>
                      <a
                        href="#"
                        title="Send to Blujay"
                        @click.prevent="sendBlujay(props.index, props.row)"
                      >
                        <i class="mdi mdi-send" style="font-size: 16px"></i>
                      </a>
                      <a
                        href="#"
                        title="Detail"
                        @click.prevent="DetailData(props.index, props.row)"
                      >
                        <i class="mdi mdi-eye" style="font-size: 16px"></i>
                      </a>
                    </span>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>
                </vue-good-table>
              </div>
            </div>
            <modal-add
              :showModal="showModalAdd"
              :data="passData"
              @closeModal="closeAddModal"
            ></modal-add>
            <modal-tariff-file-upload
              :showModal="showModalTariffUpload"
              :data="payableTariffId"
              @closeModal="closeMyModal"
            ></modal-tariff-file-upload>

            <modal-tariff-upload-detail
              :showModal="showModalTariffUploadDetail"
              :data="payableTariffId"
              @closeModal="closeMyModal"
            ></modal-tariff-upload-detail>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import modalAdd from "@/components/modal/modalPayableTariffAdd.vue";
import modalTariffFileUpload from "@/components/modal/modalTariffFileUploadPayable.vue";
import modalTariffUploadDetail from "@/components/modal/modalTariffUploadDetailPayable.vue";

import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "PayableTarif",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "modal-add": modalAdd,
    "modal-tariff-file-upload": modalTariffFileUpload,
    "modal-tariff-upload-detail": modalTariffUploadDetail,

    DateRangePicker,
  },
  data() {
    const startDate = moment().subtract(7, "d").format("YYYY-MM-DD hh:mm:ss");
    //const startDate = new Date();
    const endDate = moment(Date.now() + 7 * 24 * 3600 * 1000).format(
      "YYYY-MM-DD"
    );

    let today = new Date();
    today.setHours(0, 0, 0, 0);

    let yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);

    const current = new Date();
    let firstDay = current.getDate() - current.getDay();
    return {
      customRange: {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        "This Week": [
          new Date(current.setDate(firstDay)),
          new Date(current.setDate(current.getDate() + 6)),
        ],
        "Last  Week": [
          new Date(current.setDate(firstDay - 7)),
          new Date(current.setDate(firstDay - 1)),
        ],
        "This Month": [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth() + 1, 0),
        ],
        "Last month": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "This year": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
      },
      dateRange: {
        startDate,
        endDate,
      },
      opens: "center",
      status: [],
      locale: {
        format: "yyyy-mm-dd",
      },

      passData: [],
      showModalAdd: false,
      showModalTariffUpload: false,
      showModalTariffUploadDetail: false,
      payableTariffIds: [],
      payableTariffId: "",
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      company_code: "",
      errors: [],
      permision_role: [],
      langs: ["id", "en"],
      rowsSelection: "",
      detailUser: [],
      detailDivisi: [],
      totalRecords: 0,
      btnUploadFile: true,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [],
      // rows: [],
      rows: [
        // {
        //   company_code:"ADS",
        //   company_name:"Andalan Dunia Semesta",
        //   sap_code:"3000014429",
        //   enteries:100,
        //   last_update:"20/03/2023"
        // }
      ],
    };
  },
  watch: {},
  methods: {
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RmPayableTariffController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },
    DetailData(index, row) {
      this.payableTariffId = row.rm_payable_tariff_id;
      this.showModalTariffUploadDetail = true;
    },
    payableTariffUpload() {
      var uri = "/payable-tariff/upload";
      if (this.company_code && this.company_code !== undefined) {
        uri = uri + "?company_code=" + this.company_code;
      }
      window.location.href = uri;
    },
    uploadFile() {
      this.showModalTariffUpload = true;
    },
    createData() {
      this.passData = [];
      this.passData.type = "create";
      this.showModalAdd = true;
    },

    sendBlujayAll() {
      var baseURI =
        this.$settings.endPoint + "rm-payable-tariff/send-to-blujay-nintex";
      let data = {
        rm_payable_tariff_id: this.payableTariffIds,
      };
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
          this.$http
            .post(baseURI, data, { timeout: 30000 })
            .then((response) => {
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
                this.isloading = false;
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {
                if (error.response) {
                  // console.log(error.response.data.errors.message);
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.errors = error.response.data.errors;
                    this.error(error.response.data.errors.message);
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push("/page-not-found");
                  }
                }
              }
            });
        }
      });
      // console.log(index, ev);
    },
    
    downloadRate(index,row){
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      const baseURI =
        this.$settings.endPoint +
        "rm-payable-tariff/download-file/" +
        row.rm_payable_tariff_id;
      return this.$http
        .get(baseURI, {
          responseType: "blob",
        })
        .then((response) => {
          this.loading();
          var file_name = "download_" + CurrentDate + ".xlsx";
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        })
        .catch((error) => {
          this.loading();
          // console.log(error.response);
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              if (error.response.data.errors.length >= 1) {
                this.showModalTransportError = true;
              } else {
                this.resultError(error.response.data.errors);
              }
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
        });
    },  
    uploadData() {
      window.location.href = "/order/payable-method/upload";
    },

    editData(index, row) {
      this.passData = [];
      this.passData = row;
      this.passData.type = "edit";
      this.showModalAdd = true;
    },

    loadColoum() {
      if (
        this.company_code == null ||
        this.company_code == "" ||
        this.company_code == undefined
      ) {
        this.columns = [
          {
            label: "Action",
            field: "actions",
            width: "100px",
          },
          {
            label: "SoB",
            field: "sob",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By SoB", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "Model",
            field: "mode",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Model", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: 'Carrier',
            field: "carrier_name",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By ", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "Tariff Name",
            field: "tariff_name",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Tariff Name", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
         
          {
            label: "Description",
            field: "description",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Description", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "Effective - Expiration",
            field: "effective_expiration_date",
            width: "180px",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Effective - Expiration", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "Status",
            field: "status",
            filterOptions: {
              enabled: false, // enable filter for this column
              placeholder: "Filter By Status", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          
       
         
        ];
      } else {
        this.columns = [
          {
            label: "Action",
            field: "actions",
            width: "100px",
          },
          {
            label: "Payable Tariff Name",
            field: "tariff_name",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Payable Tariff Name", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "Sub Tariff Name",
            field: "subtariff_name",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Sub Tariff Name", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "Description",
            field: "description",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Description", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "Effective - Expiration",
            field: "effective_expiration_date",
            width: "180px",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Effective - Expiration", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "Status",
            field: "status",
            filterOptions: {
              enabled: false, // enable filter for this column
              placeholder: "Filter By Status", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "SoB",
            field: "sob",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By SoB", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "Current Phase",
            field: "current_phase",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Payable Tariff Name", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "Current Status",
            field: "current_status",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Sub Tariff Name", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          // {
          //   label: "Payable Tariff Name",
          //   field: "tariff_name",
          //   filterOptions: {
          //     enabled: true, // enable filter for this column
          //     placeholder: "Filter By Payable Tariff Name", // placeholder for filter input
          //     filterValue: "", // initial populated value for this filter
          //     filterDropdownItems: [], // dropdown (with selected values) instead of text input
          //     trigger: "enter", //only trigger on enter not on keyup
          //   },
          // },
        ];
      }
    },

    // load items is what brings back the rows from server
    loadItems() {
      this.fade(true);
      const baseURI = this.$settings.endPoint + "rm-payable-tariff";
      //var columnFilters = this.serverParams.columnFilters;
      var effective_expiration_date = "";
      if (this.serverParams.columnFilters.effective_expiration_date != "") {
        effective_expiration_date = this.$moment(
          this.serverParams.columnFilters.effective_expiration_date
        ).format("YYYY-MM-DD");
      }

      // var status_=[];
     
      // if(this.status.length!=0){
      //   for (let index = 0; index < this.status.length; index++) {
        
      //     status_.push(this.status[index]);
      //   }

      // }

      // var id='';

      //localhost:8081/rm-payable-method/?download=&description&file_reference&rating_method&sob&enabled&company_name=mapan
      return this.$http
        .get(
          baseURI +
            `?limit=${this.serverParams.per_page}&page=${
              this.serverParams.page
            }&sort_field=${this.serverParams.sort.field}&sort_type=${
              this.serverParams.sort.type
            
            }&status_complete=${this.status}&tariff_name=${this.serverParams.columnFilters.tariff_name}&description=${this.serverParams.columnFilters.description}&sob=${this.serverParams.columnFilters.sob}&effective_expiration_date=${effective_expiration_date}`
        )
        .then((response) => {
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
          this.loading();
        });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },
    confirmStatus(index, row, status) {
      var baseURI =
        this.$settings.endPoint +
        "rm-payable-tariff/change-enabled/" +
        row.rm_payable_tariff_id;
      var data = {
        enabled: status,
      };
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
          this.$http
            .post(baseURI, data, { timeout: 30000 })
            .then((response) => {
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
                this.isloading = false;
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {
                if (error.response) {
                  // console.log(error.response.data.errors.message);
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.errors = error.response.data.errors;
                    this.error(error.response.data.errors.message);
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push("/page-not-found");
                  }
                }
              }
            });
        }
      });
      // console.log(index, row, status);
    },
    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },
    selectionChanged(ev) {
      this.rowsSelection = [];
      this.payableTariffIds = [];
      this.rowsSelection = ev.selectedRows;
      this.btnUploadFile = true;
      this.payableTariffId = "";
      if (this.rowsSelection.length == 1) {
        this.payableTariffId = this.rowsSelection[0].rm_payable_tariff_id;
      } else {
        this.btnUploadFile = false;
      }
      // console.log(this.payableTariffId);
      this.rowsSelection.forEach((item) => {
        this.payableTariffIds.push(item.rm_payable_tariff_id);
      });
      // console.log(this.payableTariffIds);
    },
    sendBlujay(index, ev) {
      var baseURI =
        this.$settings.endPoint + "rm-payable-tariff/send-to-blujay-nintex";
      let data = {
        rm_payable_tariff_id: [ev.rm_payable_tariff_id],
      };
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
          this.$http
            .post(baseURI, data, { timeout: 30000 })
            .then((response) => {
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
                this.isloading = false;
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {
                if (error.response) {
                  // console.log(error.response.data.errors.message);
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.errors = error.response.data.errors;
                    this.error(error.response.data.errors.message);
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push("/page-not-found");
                  }
                }
              }
            });
        }
      });
      // console.log(index, ev);
    },
    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },
    closeAddModal(ev,id){
      // console.log(ev,id);
      this.showModalAdd=false;
      if(ev == 'open'){
        this.payableTariffId = id;
        this.showModalTariffUpload = true;
      }
      this.loadItems();

    },
    closeMyModal() {
      this.showModalAdd = false;
      this.showModalTariffUpload = false;
      this.showModalTariffUploadDetail = false;
      this.loadItems();
    },
    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },

    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },
  },
  events: {},
  created: function () {},
  mounted() {
    this.company_code =
      this.$route.query == undefined ? "" : this.$route.query.company_code;
    // console.log(this.company_code);
    this.loadColoum();
    this.fetchIt();
    this.loading();
  },
};
</script>
<style scoped></style>
