<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>   
    <div class="loadertext" v-if="this.isLoading"> {{$t('loadingTxt')}}...</div>   
    <div class="main-wrapper" :class="{'blur-content': this.isLoading}">
      <!--begin::sidebar-->
      <sidebar-component classMenu="BranchEdit"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            "
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 25px"
                  >
                  {{ $t("trafficViolation") }} Edit
                  </h6>
                  <button
                    class="btn btn-default btn-sm float-end"
                    @click="resetForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "
                    ></div>
                    <div class="row">
                      <div class="col-sm-6">
                                            <div class="row mb-3">
                      <div class="col-lg-3">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Status</label
                        >
                      </div>
                      <div class="col-lg-8">
                      <v-select
                          :options="fetchStatus"
                          v-model="status"
                          label="status"
                          return-object
                         
                        ></v-select>
                        <div v-if="errors.status">
                          <div
                            v-for="error in errors.status"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-3">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Date</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <Datepicker v-model="forms.tv_date" :bootstrap-styling=true format="dd-MM-yyyy"
                        placeholder="Please Select Date..."></Datepicker>
                        <div v-if="errors.tv_date">
                          <div
                            v-for="error in errors.tv_date"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    
                   
                        <div class="row mb-3">
                          <div class="col-lg-3">
                            
                            <label for="defaultconfig" style="padding-left: 25px" 
                          class="col-form-label">Load ID</label>
                          </div>
                          <div class="col-lg-5">
                            <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                            <input readonly type="text" id="load_number" name="load_number" v-model="forms.load_number"
                              class="form-control" placeholder="Load ID" read-only />


                            <div v-if="errors.load_number">
                              <div v-for="error in errors.load_number" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <button class="btn btn-success" @click.prevent="openModal()" style="margin-right:10px">Browse</button>
                            <button class="btn btn-danger" @click.prevent="clearLoad()">No Load</button>
                          </div>

                         

                        </div>
                        

                        <div class="row mb-3">
                          <div class="col-lg-3">
                            
                          <label for="defaultconfig" style="padding-left: 25px" 
                          class="col-form-label">Vehicle No<span class="wajib">*</span></label>
                          </div>
                          <div class="col-lg-8">
                            <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                            <!-- <input readonly type="text" id="vehicle_no" name="vehicle_no" v-model="forms.vehicle_no"
                              class="form-control" read-only /> -->
                              <v-select :options="fetchVehicleNo" v-model="vehicle_no" :disabled="disabledBeforeLoad" label="vehicle_no" return-object
                               @search="asyncSearchVehicleNo"></v-select>

                            <div v-if="errors.load_number">
                              <div v-for="error in errors.load_number" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-3">
                            
                          <label for="defaultconfig" style="padding-left: 25px" 
                          class="col-form-label">Driver NMK<span class="wajib">*</span></label>
                          </div>
                          <div class="col-lg-8">
                            <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                            <!-- <input  type="text" id="driver_nmk" name="driver_nmk" v-model="forms.driver_nmk"
                              class="form-control" :disabled="disabledBeforeLoad" /> -->
                              <v-select :options="fetchDriverNmk" v-model="driver_nmk" @input="changeDriverNmk(driver)" :disabled="disabledBeforeLoad" label="driver_id" return-object
                               @search="asyncSearchDriverNmk"></v-select>

                            <div v-if="errors.load_number">
                              <div v-for="error in errors.load_number" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-3">
                            
                          <label for="defaultconfig" style="padding-left: 25px" 
                          class="col-form-label">Driver Name<span class="wajib">*</span></label>
                          </div>
                          <div class="col-lg-8">
                            <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                            <input type="text" id="driver_name" name="driver_name" v-model="forms.driver_name"
                              class="form-control" :disabled="disabledBeforeLoad" />


                            <div v-if="errors.load_number">
                              <div v-for="error in errors.load_number" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-3">
                            
                          <label for="defaultconfig" style="padding-left: 25px" 
                          class="col-form-label">Driver Hp</label>
                          </div>
                          <div class="col-lg-8">
                            <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                            <input type="text" id="driver_hp" name="driver_hp" v-model="forms.driver_hp"
                              class="form-control" :disabled="disabledBeforeLoad" />


                            <div v-if="errors.driver_hp">
                              <div v-for="error in errors.load_number" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        

                        <div class="row mb-3">
                          <div class="col-lg-3">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px" 
                              >Category <span class="wajib">*</span></label
                            >
                          </div>
                          <div class="col-lg-8">
                            <v-select
                              :options="fetchCategory"
                              v-model="category"
                              label="name"
                              return-object
                              required
                            ></v-select>

                            <div v-if="errors.category">
                              <div
                                v-for="error in errors.category"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-3">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Reason <span class="wajib">*</span></label
                            >
                          </div>
                          <div class="col-lg-8">
                            <v-select
                              :options="fetchReason"
                              v-model="reason"
                              label="name"
                              return-object
                              required
                            ></v-select>

                            <div v-if="errors.reason">
                              <div
                                v-for="error in errors.category"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                           <div class="row mb-3">
                          <div class="col-lg-3">
                            
                          <label for="defaultconfig" style="padding-left: 25px" 
                          class="col-form-label">Location</label>
                          </div>
                          <div class="col-lg-8">
                            <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                            <input type="text" id="location" name="location" v-model="forms.location"
                              class="form-control" />


                            <div v-if="errors.location">
                              <div v-for="error in errors.location" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-3">
                            
                          <label for="defaultconfig" style="padding-left: 25px" 
                          class="col-form-label">Detail</label>
                          </div>
                          <div class="col-lg-8">
                            <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                            <!-- <input type="text" id="location" name="location" v-model="forms.location"
                              class="form-control" /> -->
                              <textarea class="form-control" v-model="forms.tv_detail" cols="10"></textarea>


                            <div v-if="errors.tv_detail">
                              <div v-for="error in errors.tv_detail" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        
                        <div class="row mb-3">
                          <div class="col-lg-3">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px" 
                              >Result <span class="wajib">*</span></label
                            >
                          </div>
                          <div class="col-lg-8">
                            <v-select
                              :options="fetchResult"
                              v-model="result"
                              label="name"
                              return-object
                              required
                            ></v-select>

                            <div v-if="errors.result">
                              <div
                                v-for="error in errors.result"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-3">
                            
                          <label for="defaultconfig" style="padding-left: 25px" 
                          class="col-form-label">Investigation Result</label>
                          </div>
                          <div class="col-lg-8">
                            <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                            <!-- <input type="text" id="location" name="location" v-model="forms.location"
                              class="form-control" /> -->
                              <textarea class="form-control" v-model="forms.tv_investigation_result" cols="10"></textarea>


                            <div v-if="errors.tv_investigation_result">
                              <div v-for="error in errors.tv_investigation_result" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-3">
                            
                          <label for="defaultconfig" style="padding-left: 25px" 
                          class="col-form-label">Action Plan</label>
                          </div>
                          <div class="col-lg-8">
                            <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                            <!-- <input type="text" id="location" name="location" v-model="forms.location"
                              class="form-control" /> -->
                              <textarea class="form-control" v-model="forms.tv_action_plan" cols="10"></textarea>


                            <div v-if="errors.tv_action_plan">
                              <div v-for="error in errors.tv_action_plan" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-3">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Date Finish</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <Datepicker v-model="forms.tv_date_finish" :bootstrap-styling=true format="dd-MM-yyyy"
                            placeholder="Please Select Date..."></Datepicker>
                            <div v-if="errors.tv_date_finish">
                              <div
                                v-for="error in errors.tv_date_finish"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                      </div>
                        <div class="row mb-3">
                          <div class="col-lg-3">
                            
                          <label for="defaultconfig" style="padding-left: 25px" 
                          class="col-form-label">Cost</label>
                          </div>
                          <div class="col-lg-8">
                            <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                            <input type="number" id="tv_cost" name="tv_cost" v-model="forms.tv_cost"
                              class="form-control"  />


                            <div v-if="errors.tv_cost">
                              <div v-for="error in errors.tv_cost" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card-footer">
                      <button
                        class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        "
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button
                        class="btn btn-primary float-end btn-sm"
                        type="submit"
                      >
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>

                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
          <modal-unloading :showModal="showModalUnloading" :data="this.dataModal" @closeModal="closeMyModal">
        </modal-unloading>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
  
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import myMixinAddress from '@/middleware/mixinAddress.js';
import modalUnloading from "@/components/modal/modalUnloading.vue";


import Datepicker from "vuejs-datepicker";

export default {
  name: "BranchEdit",
  props: {},
  mixins: [myMixinAddress],
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    Datepicker,
    "modal-unloading": modalUnloading,
  },
  data() {
    return {
      disabledBeforeLoad:true,
      dataModal: {},
      category:"",
      reason:"",
      status:"",
      showModalUnloading:false,
      fetchStatus:[],
      vehicle_no:"",
      fetchVehicleNo:[],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      poolAll:[],
      permision_role: [],
      userData: "",
      companyCode: "",
      fetchCategory:[],
      fetchReason:[],
      result:"",
      fetchResult:[],
      driver_nmk:"",
      fetchDriverNmk:[],
      forms: {  

          master_driver_id:"",
          blujay_order_id:"",
          load_number:"",
          order_detail_id:"",
          order_number:"",
          shipment_date:"",
          customer_id:"",
          customer_name:"",
          master_truck_id:"",
          vehicle_no:"",
          driver_nmk:"",
          driver_name:"",
          driver_id:"",
          driver_hp:"",
          route:"",
          category:"",
          reason:"",
          location:"",
          tv_date:"",
          tv_result:"",
          tv_status:"",


      },
    };
  },
  watch: {},
  methods: {
    isNumberLatLong(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 45) {
      evt.preventDefault();
      } else {
      return true;
      }
    },
    addField() {
      //fieldType.push({ value: "" });
      this.poolAll.push({
        pool_name: "",
        pool_province: "",
        pool_address: "",
        pool_city: "",
        pool_postalcode: "",
        method_upload: "",
        pool_latitude: "",
        pool_longitude: "",
      });
    },

    clearLoad(){
      this.forms.load_number="";
      this.disabledBeforeLoad=false;
    },


    loadResult() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=tvres`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchResult = response.data.datas;
      });
    },


    closeMyModal(ev) {
      if (ev !== null) {
          

          this.disabledBeforeLoad=false;
          this.forms.master_driver_id=ev.master_driver_id;
          this.forms.blujay_order_id=ev.blujay_order_id;
          this.forms.load_number=ev.load_id;
          this.forms.order_detail_id=ev.order_detail_id;
          this.forms.order_number=ev.shipment_number;
          this.forms.shipment_date=ev.shipment_date;
          this.forms.customer_id=ev.customer_id;
          this.forms.customer_name=ev.customer_name
          this.forms.master_truck_id=ev.master_truck_id
          
          //this.forms.vehicle_no=ev.vehicle_no
          this.forms.load_number=ev.load_id
          this.vehicle_no={
            vehicle_no:ev.vehicle_no
          }
          this.forms.driver_nmk=ev.driver_nmk
          this.forms.driver_name=ev.driver_name
          this.forms.driver_hp=ev.driver_hp
          this.forms.route=ev.route
          this.forms.driver_id=ev.driver_id
          

      }
      // if (ev !== null) {
      //   this.forms.blujay_order_id = ev.blujay_order_id;
      //   this.forms.detail_order_id = ev.order_detail_id;
      //   this.forms.load_number = ev.load_id;
      //   this.forms.vehicle_no = ev.vehicle_no;
      //   this.forms.driver_nmk = ev.driver_nmk;
      //   this.forms.driver_name = ev.driver_name;
      //   this.forms.driver_hp = ev.driver_hp;
      //   this.forms.route = ev.name_rute;
      //   this.forms.shipment_number = ev.shipment_number;
      //   this.forms.shipment_date = ev.shipment_date;
      //   this.forms.tanggal_muat = ev.shipment_date;
      //   if(ev.leadtime){
      //     const cdate = new Date(ev.shipment_date);
      //     cdate.setDate(cdate.getDate() + ev.leadtime);
      //     this.forms.tanggal_bongkar = cdate;
      //     //this.forms.tanggal_bongkar = moment(String(tanggal_bongkar)).format('YYYY-MM-DD');
      //     console.log(this.forms.tanggal_bongkar);
      //   }else{
      //     this.forms.tanggal_bongkar = ev.shipment_date;
      //   }
        
      //   this.ProvinsiTujuan = this.fetchProvinsiTujuan.filter((item) => {
      //     return (item.province === ev.region)
      //   })
      //   if(this.ProvinsiTujuan[0]){
      //     this.ProvinsiTujuan.province = this.ProvinsiTujuan[0]['province']
      //     this.getKotaTujuan();
      //     this.KotaTujuan = this.fetchKotaTujuan.filter((item) => {
      //       return (item.city === this.capitalize(ev.city))
      //     })

      //   }
        

      // }

      this.showModalUnloading = false;
    },


    loadVehicleNo() {
      const baseURI =
        this.$settings.endPointCt + `master-truck`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchVehicleNo = response.data.datas.data;
      });
    },

    asyncSearchVehicleNo(ev){
      const baseURI =
        this.$settings.endPointCt + `master-truck?vehicle_no=`+ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchVehicleNo = response.data.datas.data;
      });
    },

    loadStatus() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=statv`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchStatus = response.data.datas;
      });
    },

  


    loadData(id) {
      this.isLoading = true
      const baseURI = this.$settings.endPointCt + "driver-tv/detail/" + id;
      return this.$http.get(baseURI,{ timeout: 10000 }).then((response) => {
      

        this.isLoading = false
        this.forms      = response.data.datas;  
        this.status=this.forms.tv_status ;
        this.category=this.forms.category;
        this.reason=this.forms.reason;
        this.vehicle_no={
          vehicle_no:this.forms.vehicle_no
        }
        this.result=this.forms.tv_result;
        this.driver_nmk={
          driver_id: this.forms.driver_nmk
        }
        
      }).catch(error => {
          this.isLoading = false;
          if(error.code == "ECONNABORTED"){
            this.error("Please Check Your Internet Connection");
          }else{

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
            
          }


        });
    },


    openModal() {
      const baseURI = this.$settings.endPointCt + "blujay-order/select";
      this.$http
        .get(baseURI)
        .then((response) => {

          this.dataModal = {
            detail: response.data.datas
          }
          this.showModalUnloading = true;
        });
    },

    resetForm() {
      this.forms=[];
    },

    loadDriverNmk() {
      const baseURI =
        this.$settings.endPointCt + `master-driver`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDriverNmk = response.data.datas.data;

      });
    },

    asyncSearchDriverNmk(ev){
      const baseURI =
        this.$settings.endPointCt + `master-driver?driver_id=`+ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDriverNmk = response.data.datas.data;
      });
    },



    loadCategory() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=cattv`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchCategory = response.data.datas;
      });
    },

    loadReason() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=reatv`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchReason = response.data.datas;
      });
    },

    backForm() {
      window.location.href = "/traffict-violation";
    },

    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },
    submitData() {
      this.isLoading = true

      const baseURI = this.$settings.endPointCt + "driver-tv/update/"+this.forms.driver_tv_id;
      this.forms.tv_status  =this.status;
      this.forms.category=this.category;
      this.forms.reason=this.reason;
      this.forms.vehicle_no=this.vehicle_no==null ? this.vehicle_no : this.vehicle_no.vehicle_no ;
      this.forms.status=this.status;
      this.forms.tv_result=this.result;
      this.forms.driver_nmk=this.driver_nmk==null ? this.driver_nmk : this.driver_nmk.driver_id ;

      this.$http
        .patch(baseURI, this.forms,{ timeout: 10000 })
        .then((response) => {
           this.isLoading = false
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            //console.log(response.data.datas.user_id);
            var params = this.$onRandom(this.forms.driver_tv_id);
            window.location.href = "/traffict-violation/detail/" + params + "?status=200&msg=Successfully";
            this.success('Berhasil');
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        }).catch(error => {
          this.isLoading = false;
          if(error.code == "ECONNABORTED"){
            this.error("Please Check Your Internet Connection");
          }else{

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
            
          }


        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    changeDriverNmk(driver){
      this.forms.driver_name=driver.driver_name;
      this.forms.driver_hp=driver.hp1;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "DriverTvController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.loadData(params);
    this.fetchIt();
    this.loadCategory();
    this.loadReason();
    this.loadStatus();
    this.loadVehicleNo();
    this.loadResult();
    this.loadDriverNmk()
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content{
  filter: blur(5px); 
  pointer-events: none;
  
}
</style>