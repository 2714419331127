<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TemplateOrderDetail"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12 pe-2">
            <div class="card">
              <form
                class="forms-sample"
                @submit.prevent="submitData"
                method="POST"
                ref="formElement"
              >
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="row">
                        <b>Customer</b>
                      </div>
                      <div class="row">
                        <b>{{ this.rateDetail.customer_name }}</b>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div>
                        <label class="form-label"
                          >Billable Tariff<span class="wajib">*</span></label
                        >
                        <v-select
                          :options="fetchBillableTariff"
                          v-model="billableTariff"
                          label="tariff_name"
                        >
                        </v-select>
                        <div
                          v-for="error in errors.billable_tariff_name"
                          :key="error"
                          class="alert alert-primary"
                          role="alert"
                        >
                          <i data-feather="alert-circle"></i>
                          {{ error }}
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div>
                        <label class="form-label"
                          >Opportunity Id<span class="wajib">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control"
                          :disabled="true"
                          v-model="forms.opportunity_id"
                        />
                        <div
                          v-for="error in errors.opportunity_id"
                          :key="error"
                          class="alert alert-primary"
                          role="alert"
                        >
                          <i data-feather="alert-circle"></i>
                          {{ error }}
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div>
                        <label class="form-label"
                          >Unit Type<span class="wajib">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control"
                          :disabled="true"
                          v-model="unitType"
                        />
                        <!-- <v-select
                          :options="fetchUnitType"
                          v-model="unitType"
                          :disabled="true"
                          label="unit_type"
                        >
                        </v-select> -->
                        <div
                          v-for="error in errors.unit_type"
                          :key="error"
                          class="alert alert-primary"
                          role="alert"
                        >
                          <i data-feather="alert-circle"></i>
                          {{ error }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <div
                    class="d-flex justify-content-between align-items-baseline mb-2"
                  ></div>
                  <div class="row mb-3">
                    <!-- Col -->
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="mb-3">
                          <label class="form-label"
                            >Geography <span class="wajib">*</span></label
                          >
                          <v-select
                            :options="fetchGeography"
                            v-model="geography"
                            label="Geography"
                            @option:selected="changeGeography"
                          >
                          </v-select>

                          <div
                            v-for="error in errors.geography"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="mb-3">
                          <label class="form-label">Division </label>
                          <v-select
                            :options="fetchDivision"
                            v-model="division"
                            label="Division"
                          ></v-select>

                          <div
                            v-for="error in errors.division"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="mb-3">
                          <label class="form-label">Order group</label>
                          <v-select
                            :options="fetchOrderGroup"
                            v-model="orderGroup"
                            label="Order Group"
                            @search="asyncSearchOrderGroup"
                          ></v-select>
                          <!-- <multiselect
                              @search-change="asyncSearchOrderGroup"
                              v-model="forms.orderGroup"
                              :options="fetchOrderGroup"
                              placeholder="Please Select"
                            >
                              <span slot="noResult">-</span>
                            </multiselect> -->

                          <div
                            v-for="error in errors.order_group"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-8">
                      <div v-if="this.geography == 'Stop Location'">
                        <div class="row">
                          <div class="mb-3">
                            <label class="form-label"
                              >Location <span class="wajib">*</span></label
                            >
                            <multiselect
                              @search-change="asyncSearchDestLocation"
                              v-model="geographyLocation"
                              :options="fetchLocation"
                              label="name"
                              track-by="name"
                              placeholder="Please Select"
                            >
                              <span slot="noResult">-</span>
                            </multiselect>

                            <div
                              v-for="error in errors.origin_location"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="row" v-if="geographyLocation.length > 0">
                          <div class="col-sm-6">
                            <p class="text-muted">
                              <b>{{ this.geographyLocation.name }}</b
                              ><br />
                              <i class="mdi mdi-map-marker"></i>Lat.{{
                                this.geographyLocation.latitude
                              }}
                              Long.{{ geographyLocation.longitude }} <br />
                              {{ geographyLocation.address1 }}
                              {{ geographyLocation.address2 }}
                              {{ geographyLocation.address2 }}
                              {{ geographyLocation.sub_district }},
                              {{ geographyLocation.district }},
                              {{ geographyLocation.city }},
                              {{ geographyLocation.province }}
                              {{ geographyLocation.postal_code }},
                              {{ geographyLocation.country }}<br />
                              {{ geographyLocation.contact_name }} -
                              {{ geographyLocation.phone_number }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        v-else-if="
                          this.geography == 'Country, City, State.Province'
                        "
                      >
                        <div class="row">
                          <div class="mb-3">
                            <label class="form-label">Country</label>
                            <v-select
                              :options="fetchCountryCode"
                              v-model="geographyCountry"
                              label="country"
                              return-object
                              @input="loadProvince"
                            ></v-select>

                            <div
                              v-for="error in errors.origin_country"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="mb-3">
                            <label class="form-label">State / Province</label>
                            <v-select
                              :options="fetchProvince"
                              v-model="geographyProvince"
                              label="province"
                              return-object
                              @input="loadCity"
                            ></v-select>

                            <div
                              v-for="error in errors.origin_state"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="mb-3">
                            <label class="form-label">City</label>
                            <v-select
                              :options="fetchCity"
                              v-model="geographyCity"
                              label="city"
                              return-object
                              @input="loadPostalCode"
                            ></v-select>
                            <div
                              v-for="error in errors.origin_city"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="mb-3">
                            <label class="form-label">Postal Code</label>
                            <div class="row">
                              <div class="col-6">
                                <v-select
                                  :options="fetchPostalCode"
                                  v-model="geographyStartPostalCode"
                                  label="postal_code"
                                  return-object
                                ></v-select>
                                <div
                                  v-for="error in errors.origin_start_postal_code"
                                  :key="error"
                                  class="alert alert-primary"
                                  role="alert"
                                >
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                              <div class="col-6">
                                <v-select
                                  :options="fetchPostalCode"
                                  v-model="geographyEndPostalCode"
                                  label="postal_code"
                                  return-object
                                ></v-select>
                                <div
                                  v-for="error in errors.origin_end_postal_code"
                                  :key="error"
                                  class="alert alert-primary"
                                  role="alert"
                                >
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else-if="this.geography == 'Region'">
                        <div class="row">
                          <div class="mb-3">
                            <label class="form-label"
                              >Region <span class="wajib">*</span></label
                            >
                            <multiselect
                              @search-change="asyncSearchRegion"
                              v-model="geographyRegion"
                              :options="fetchRegion"
                              label="region_code"
                              track-by="region_code"
                              placeholder="Please Select"
                            >
                              <span slot="noResult">-</span>
                            </multiselect>

                            <div
                              v-for="error in errors.origin_region"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Col -->
                  </div>
                  <div class="row mb-3">
                    <div class="row mb-3">
                      <h3>Destination</h3>
                    </div>
                    <div class="col-sm-6">
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="mb-3">
                            <label class="form-label"
                              >Tier <span class="wajib">*</span></label
                            >
                            <v-select
                              :options="fetchTier"
                              v-model="tier"
                              label="code"
                              return-object
                            ></v-select>

                            <div
                              v-for="error in errors.tier"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-2">
                          <div class="mb-3">
                            <label class="form-label">All Inclusive</label>
                            <div class="mb-4">
                              <div class="form-check mb-2">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  true-value="1"
                                  false-value="0"
                                  id="allInclusive"
                                  v-model="forms.all_inclusive"
                                />
                                <label
                                  class="form-check-label"
                                  for="allInclusive"
                                >
                                  Yes
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="mb-3">
                            <label class="form-label"
                              >Qty <span class="wajib">*</span>
                            </label>
                            <div class="mb-4">
                              <input
                                type="number"
                                id="qty"
                                name="qty"
                                v-model="forms.qty"
                                class="form-control"
                                placeholder="qty"
                                required
                                @keydown.space.prevent
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="mb-3 col-sm-10">
                          <label class="form-label"
                            >Location <span class="wajib">*</span></label
                          >
                          <multiselect
                            @search-change="asyncSearchDestLocation"
                            v-model="destLocation"
                            :options="fetchLocation"
                            label="name"
                            track-by="name"
                            placeholder="Please Select"
                            @input="checkfunction"
                            :disabled="
                              destCountry?.country !== undefined && 
                              destCountry?.country !== null &&
                              Array.isArray(destCountry) && destCountry.length > 0
                            "
                          >
                            <span slot="noResult">-</span>
                          </multiselect>


                          <div
                            v-for="error in errors.destination_location"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label">Country</label>
                            <v-select
                              :options="fetchCountryCode"
                              v-model="destCountry"
                              label="country"
                              return-object
                              :disabled="
                                destLocation?.name !== undefined && 
                                Array.isArray(destLocation) && destLocation.length > 0
                              "
                              @input="loadDestProvince"
                            ></v-select>


                            <div
                              v-for="error in errors.destination_country"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label">State / Province</label>
                            <v-select
                              :options="fetchDestProvince"
                              v-model="destProvince"
                              label="province"
                              return-object
                              @input="loadDestCity"
                              :disabled="
                                destLocation?.name !== undefined && 
                                Array.isArray(destLocation) && destLocation.length > 0
                              "
                            ></v-select>


                            <div
                              v-for="error in errors.destination_state"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="mb-3">
                            <label class="form-label">City</label>
                            <v-select
                              :options="fetchDestCity"
                              v-model="destCity"
                              label="city"
                              return-object
                              @input="loadDestPostalCode"
                              :disabled="
                                destLocation?.name !== undefined && 
                                Array.isArray(destLocation) && destLocation.length > 0
                              "
                            ></v-select>


                            <div
                              v-for="error in errors.destination_city"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="mb-3">
                            <label class="form-label">Start Postal Code</label>
                            <v-select
                              :options="fetchDestPostalCode"
                              v-model="destStartPostalCode"
                              label="postal_code"
                              return-object
                              @search="asyncDestPostalCode"
                              :disabled="
                                destLocation?.name !== undefined && 
                                Array.isArray(destLocation) && destLocation.length > 0
                              "
                            ></v-select>


                            <div
                              v-for="error in errors.destination_start_postal_code"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="mb-3">
                            <label class="form-label">End Postal Code</label>
                            <v-select
                                :options="fetchDestPostalCode"
                                v-model="destEndPostalCode"
                                label="postal_code"
                                return-object
                                @search="asyncDestPostalCode"
                                :disabled="
                                  destLocation?.name !== undefined && 
                                  Array.isArray(destLocation) && destLocation.length > 0
                                "
                              ></v-select>

                            <div
                              v-for="error in errors.destination_end_postal_code"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="mb-3 col-sm-10">
                          <label class="form-label"
                            >Region <span class="wajib">*</span></label
                          >
                          <multiselect
                            @search-change="asyncSearchRegion"
                            v-model="destRegion"
                            :options="fetchRegion"
                            label="region_code"
                            :readonly="false"
                            track-by="region_code"
                            placeholder="Please Select"
                          >
                            <span slot="noResult">-</span>
                          </multiselect>

                          <div
                            v-for="error in errors.destination_region"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label">Min</label>
                            <input
                              type="number"
                              id="min"
                              name="min"
                              v-model="forms.min"
                              class="form-control"
                              placeholder="Min"
                              @keydown.space.prevent
                            />
                            <div
                              v-for="error in errors.min"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label">Max</label>
                            <input
                              type="number"
                              id="max"
                              name="max"
                              v-model="forms.max"
                              class="form-control"
                              placeholder="Max"
                              @keydown.space.prevent
                            />
                            <div
                              v-for="error in errors.max"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Effective Date<span class="wajib">*</span></label
                            >
                            <date-range-picker
                              style="width: 100%"
                              :locale-data="locale"
                              v-model="effective_date"
                              :opens="opens"
                              :singleDatePicker="true"
                              :timePicker24Hour="true"
                              :timePicker="true"
                              :showDropdowns="true"
                              @update="EffectiveDateChange"
                            >
                            </date-range-picker>
                            <div
                              v-for="error in errors.effective_date"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Expiration Date<span class="wajib"
                                >*</span
                              ></label
                            >
                            <date-range-picker
                              style="width: 100%"
                              :locale-data="locale"
                              v-model="expiration_date"
                              :opens="opens"
                              :singleDatePicker="true"
                              :timePicker24Hour="true"
                              :timePicker="true"
                              :showDropdowns="true"
                              :date-format="dateFormatExpired"
                            >
                            </date-range-picker>
                            <div
                              v-for="error in errors.expiration_date"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Sub Tariff <span class="wajib">*</span></label
                            >
                            <v-select
                              :options="fetchSubTarif"
                              v-model="subTariff"
                              label="code"
                              return-object
                            ></v-select>

                            <div
                              v-for="error in errors.sub_tariff"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Currency <span class="wajib">*</span></label
                            >
                            <v-select
                              :options="fetchCurrency"
                              v-model="currency"
                              label="code"
                              return-object
                            ></v-select>

                            <div
                              v-for="error in errors.currency"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-10">
                          <div class="mb-3">
                            <label class="form-label"
                              >Accessorial Application Rule</label
                            >
                            <!-- <v-select
                              :options="fetchAccessorial"
                              v-model="accessorial"
                              label="code"
                              return-object
                            ></v-select> -->
                             <multiselect
                              @search-change="asyncAccessorialApplicationRule"
                              v-model="accessorial"
                              :options="fetchAccessorial"
                              placeholder="Please Select"
                              track-by="description"
                              label="description"
                            >
                              <span slot="noResult">-</span>
                            </multiselect>

                            <div
                              v-for="error in errors.accessorial_application_rule"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label">SKU</label>
                            <!-- <v-select
                              :options="fetchSKU"
                              v-model="destination.sku"
                              label="sku_code"
                              return-object
                              @search="asyncSearchSKU"
                            ></v-select> -->
                            <multiselect
                              @search-change="asyncSearchSKU"
                              v-model="sku"
                              :options="fetchSku"
                              placeholder="Please Select"
                              track-by="type_truck"
                              label="type_truck"
                            >
                              <span slot="noResult">-</span>
                            </multiselect>

                            <div
                              v-for="error in errors.sku"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Basis <span class="wajib">*</span></label
                            >
                            <v-select
                              :options="fetchBasis"
                              v-model="basis"
                              label="code"
                              return-object
                            ></v-select>

                            <div
                              v-for="error in errors.basis"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label">Rate</label>
                            <!-- <v-select
                              :options="fetchRate"
                              v-model="forms.rate"
                              label="code"
                              return-object
                            ></v-select> -->
                            <input
                              type="number"
                              id="rate"
                              name="rate"
                              v-model="forms.rate"
                              class="form-control"
                              placeholder="Rate"
                              step="any"
                              @keydown.space.prevent
                            />

                            <div
                              v-for="error in errors.rate"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Equipment Type
                              <span class="wajib">*</span></label
                            >
                            <v-select
                              :options="fetchEquipmentType"
                              v-model="equipmentType"
                              label="type_truck"
                              return-object
                            ></v-select>

                            <div
                              v-for="error in errors.equipment_type"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >No Intermediate Stop</label
                            >
                            <div class="mb-4">
                              <div class="form-check mb-2">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  id="intermediateStop"
                                  true-value="1"
                                  false-value="0"
                                  v-model="forms.no_intermediate_stops"
                                />
                                <label
                                  class="form-check-label"
                                  for="intermediateStop"
                                >
                                  Yes
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label">Allocation Method </label>
                            <v-select
                              :options="fetchAllocationMethod"
                              v-model="allocationMethod"
                              label="code"
                              return-object
                            ></v-select>

                            <div
                              v-for="error in errors.allocation_method"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <button
                        class="btn btn-warning btn-icon-text"
                        type="button"
                        @click="rateBreak"
                      >
                        <span class="btn-icon-prepend">
                          <svg
                            version="1.0"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14px"
                            height="14px"
                            viewBox="0 0 300.000000 300.000000"
                            preserveAspectRatio="xMidYMid meet"
                          >
                           
                            <g
                              transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
                              fill="#000000"
                              stroke="none"
                            >
                              <path
                                d="M350 2160 c0 -462 4 -840 8 -840 4 0 98 36 207 81 110 44 207 80 215 81 8 0 178 -76 378 -170 l362 -171 222 84 c123 47 228 85 235 85 7 0 62 -34 122 -75 l110 -76 220 103 221 102 0 476 0 475 -342 342 -343 343 -807 0 -808 0 0 -840z m1542 418 l3 -333 328 -3 327 -2 0 -408 0 -408 -166 -77 -166 -77 -109 75 c-59 41 -112 75 -118 75 -5 0 -110 -38 -233 -85 -123 -47 -227 -85 -233 -85 -5 0 -174 77 -374 171 l-363 172 -157 -63 c-86 -34 -162 -64 -168 -67 -10 -4 -13 144 -13 721 l0 726 720 0 720 0 2 -332z m348 7 l245 -245 -248 0 -247 0 0 245 c0 135 1 245 3 245 1 0 112 -110 247 -245z"
                              />
                              <path
                                d="M646 2654 c-22 -22 -20 -50 6 -68 18 -13 55 -16 198 -16 190 0 220 7 220 54 0 43 -13 46 -216 46 -162 0 -195 -3 -208 -16z"
                              />
                              <path
                                d="M647 2382 c-22 -24 -21 -45 1 -65 17 -15 68 -17 525 -17 446 0 507 2 521 16 9 8 16 24 16 34 0 10 -7 26 -16 34 -14 14 -75 16 -523 16 -479 0 -509 -1 -524 -18z"
                              />
                              <path
                                d="M646 2024 c-19 -18 -21 -45 -4 -62 17 -17 1639 -17 1656 0 15 15 15 51 0 66 -9 9 -206 12 -824 12 -725 0 -814 -2 -828 -16z"
                              />
                              <path
                                d="M646 1794 c-19 -18 -21 -45 -4 -62 17 -17 1639 -17 1656 0 15 15 15 51 0 66 -9 9 -206 12 -824 12 -725 0 -814 -2 -828 -16z"
                              />
                              <path
                                d="M558 1277 l-208 -91 0 -593 0 -593 1150 0 1150 0 0 630 c0 409 -3 630 -10 630 -5 0 -100 -42 -210 -94 l-200 -94 -127 74 c-71 40 -135 74 -143 74 -8 0 -113 -38 -233 -84 l-217 -84 -361 159 c-198 87 -365 159 -372 158 -7 0 -105 -41 -219 -92z m590 -171 c194 -86 356 -156 360 -156 4 0 106 38 227 85 l219 84 135 -77 c74 -42 138 -76 143 -74 4 1 76 34 158 72 83 39 152 70 155 70 3 0 5 -229 5 -510 l0 -510 -1050 0 -1050 0 0 518 0 517 163 72 c89 39 167 70 172 68 6 -2 169 -73 363 -159z"
                              />
                              <path
                                d="M642 798 c-17 -17 -15 -44 4 -62 14 -14 103 -16 828 -16 618 0 815 3 824 12 15 15 15 51 0 66 -17 17 -1639 17 -1656 0z"
                              />
                              <path
                                d="M646 474 c-22 -22 -20 -50 6 -68 20 -14 114 -16 818 -16 704 0 798 2 818 16 27 19 29 60 3 74 -13 7 -289 10 -825 10 -718 0 -806 -2 -820 -16z"
                              />
                            </g>
                          </svg>
                        </span>
                        Rate Break
                      </button> -->
                    </div>
                  </div>
                  <div class="card-footer">
                    <button
                      class="btn btn-warning text-black fw-bolder btn-icon-text"
                      @click="backForm"
                      type="button"
                    >
                      <i class="link-icon" data-feather="arrow-left"></i>
                      {{ $t("backMess") }}
                    </button>
                    <button
                      v-if="!isLoading"
                      class="btn btn-success float-end btn-sm"
                      type="submit"
                    >
                      <i class="link-icon" data-feather="save"></i>
                      {{ $t("submitOrderTxt") }}
                    </button>
                    <!-- <a
                      class="btn btn-success me-2 text-white fw-bolder btn-icon-text float-end"
                      @click="submitData()"
                    >
                      <i class="link-icon" data-feather="save"></i>
                      {{ $t("submitOrderTxt") }}
                    </a> -->
                  </div>
                </div>
              </form>
            </div>
            <modal-rate-break
              :showModal="showModalRateBreak"
              :data="passData"
              @closeModal="closeMyModal"
            ></modal-rate-break>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import multiselect from "vue-multiselect";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import modalRateBreak from "@/components/modal/modalRateBreak.vue";
import myMixinAddress from "@/middleware/mixinAddress.js";

import moment from "moment";

export default {
  name: "CommodityDetail",
  mixins: [myMixinAddress],
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "modal-rate-break": modalRateBreak,

    multiselect: multiselect,
    DateRangePicker,
  },
  data() {
    const startDate = new Date();
    const endDate = new Date();
    return {
      passData: [],
      fetchCountryCode: [],
      disabledLocation: false,
      fetchProvince: [],
      fetchPostalCode: [],
      fetchCity: [],

      fetchDestProvince: [],
      fetchDestPostalCode: [],
      fetchDestCity: [],

      fetchGeography: [],
      fetchDivision: [],
      fetchOrderGroup: [],
      fetchLocation: [],
      fetchDestLocation: [],
      fetchBillableTariff: [],
      fetchRegion: [],
      fetchBasis: [],
      fetchSku: [],
      fetchAccessorial: [],
      fetchSubTarif: [],
      fetchAllocationMethod: [],
      fetchCurrency: [],
      fetchRate: [],
      fetchTier: [],
      fetchEquipmentType: [],
      fetchUnitType: [],

      showModalRateBreak: false,
      locale: {
        format: "dd mmm yyyy H:MM:ss",
      },
      opens: "center",
      effective_date: {
        startDate,
        endDate,
      },
      expiration_date: {
        startDate,
        endDate,
      },

      billableTariff: [],
      unitType: "",
      sku: [],
      division: "All",
      orderGroup: "All",
      tier: "",
      subTariff: "DEFAULT",
      currency: "",
      accessorial: "",
      basis: "",
      equipmentType: "",
      allocationMethod: "",

      geography: [],
      geographyCountry: [],
      geographyProvince: [],
      geographyCity: [],
      geographyStartPostalCode: [],
      geographyEndPostalCode: [],
      geographyLocation: [],
      geographyRegion: [],

      destCountry: [],
      destProvince: [],
      destCity: [],
      destStartPostalCode: [],
      destEndPostalCode: [],
      destLocation: [],
      destRegion: [],

      location_address: false,
      detailAll: [],
      permision_role: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      rateDetail: {},
      errors: [],
      userData: "",
      companyCode: "",
      forms: {},
      destination: {},
    };
  },
  watch: {},
  methods: {
    resetForm() {},

    checkfunction(ev) {
      console.log(ev);
      // console.log(this.destLocation);
    },
    backForm() {
      window.location.href = "/billable-rate/detail/" + this.$route.params.id;
    },
    asyncSearchOrderGroup(ev) {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=rmog&search=` + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchOrderGroup = response.data.datas;
      });
    },
    asyncSearchDestLocation(ev) {
      let company_name = this.rateDetail.customer_name;
      const baseURI =
        this.$settings.endPoint +
        "location?status=ENABLED&company_name=" +
        company_name +
        "&location_name=" +
        ev;
      this.fetchLocation = [];
      return this.$http.get(baseURI).then((response) => {
        // console.log("search : " + ev);
        // console.log(response.data.datas.data);
        this.fetchLocation = response.data.datas.data;
      });
    },
    asyncSearchSKU(ev) {
      const baseURI = this.$settings.endPointCt + `type-truck?type_truck=` + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchSku = response.data.datas.data;
      });
    },
    asyncSearchRegion(ev) {
      const baseURI = this.$settings.endPoint + `region?region_code=${ev}&status=ENABLED`;
      this.fetchRegion = [];
      return this.$http.get(baseURI).then((response) => {
        this.fetchRegion = response.data.datas.data;
      });
    },
    asyncDestPostalCode(ev) {
      this.fetchDestPostalCode = [];
      const baseURI =
        this.$settings.endPoint +
        `postal-code?country=` +
        this.destCountry.country +
        "&province=" +
        this.destProvince.province +
        "&city=" +
        this.destCity.city +
        "&postal_code=" +
        ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDestPostalCode = response.data.datas.data;
      });
    },
    loadGeography() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmgeo`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchGeography = response.data.datas;
        this.fade(false);
      });
    },
    loadDivision() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmdiv`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchDivision = response.data.datas;
        this.fade(false);
      });
    },
    loadOrderGroup() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=rmog`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchOrderGroup = response.data.datas;
        this.fade(false);
      });
    },
    loadTier() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmtier`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchTier = response.data.datas;
        this.fade(false);
      });
    },
    loadSubTariff() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmstf`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchSubTarif = response.data.datas;
        this.fade(false);
      });
    },
    loadAccessorialApplicationRule() {
      const baseURI =
        this.$settings.endPoint + `rm-billable-acc-rule?status=ENABLED`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchAccessorial = response.data.datas.data;
        this.fade(false);
      });
    },
    asyncAccessorialApplicationRule(ev){
 const baseURI =
        this.$settings.endPoint + `rm-billable-acc-rule?status=ENABLED&description=`+ev;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchAccessorial = response.data.datas.data;
        this.fade(false);
      });
    },
    loadCurrency() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmcur`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchCurrency = response.data.datas;
        this.fade(false);
      });
    },
    loadDestCountry() {
      const baseURI = this.$settings.endPoint + `postal-code/select-country`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchDestCountry = response.data.datas;
        this.fade(false);
      });
    },
    loadProvince() {
      const baseURI =
        this.$settings.endPoint +
        "postal-code/select-province/" +
        this.geographyCountry.country;
      this.geographyProvince = [];
      this.geographyCity = [];
      this.geographyStartPostalCode = [];
      this.geographyEndPostalCode = [];
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchProvince = response.data.datas.data;
        this.fade(false);
      });
    },
    loadEquipmentType() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmet`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchEquipmentType = response.data.datas;
        this.fade(false);
      });
    },
    loadPostalCode() {
      const baseURI =
        this.$settings.endPoint +
        `postal-code?limit=10&country=` +
        this.geographyCountry.country +
        "&province=" +
        this.geographyProvince.province +
        "&city=" +
        this.geographyCity.city;
      this.geographyStartPostalCode = [];
      this.geographyEndPostalCode = [];
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchPostalCode = response.data.datas.data;
        this.fade(false);
      });
    },
    loadCity() {
      const baseURI =
        this.$settings.endPoint +
        "postal-code/select-city/" +
        this.geographyProvince.province;

      this.geographyCity = "";
      this.geographyStartPostalCode = "";
      this.geographyEndPostalCode = "";
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchCity = response.data.datas;
        this.fade(false);
      });
    },
    loadDestProvince() {
      const baseURI =
        this.$settings.endPoint +
        "postal-code/select-province/" +
        this.destCountry.country;

      this.destProvince = [];
      this.destCity = [];
      this.destEndPostalCode = [];
      this.destStartPostalCode = [];
      // console.log(this.destCountry);
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchDestProvince = response.data.datas.data;
        this.fade(false);
      });
    },
    loadDestPostalCode() {
      const baseURI =
        this.$settings.endPoint +
        `postal-code?country=` +
        this.destCountry.country +
        "&province=" +
        this.destProvince.province +
        "&city=" +
        this.destCity.city;
      this.destEndPostalCode = [];
      this.destStartPostalCode = [];
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchDestPostalCode = response.data.datas.data;
        this.fade(false);
      });
    },
    loadDestCity() {
      const baseURI =
        this.$settings.endPoint +
        "postal-code/select-city/" +
        this.destProvince.province;
      this.destCity = [];
      this.destEndPostalCode = [];
      this.destStartPostalCode = [];
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchDestCity = response.data.datas;
        this.fade(false);
      });
    },
    loadBasis() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmbas`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchBasis = response.data.datas;
        this.fade(false);
      });
    },
    loadAllocationMethod() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmam`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchAllocationMethod = response.data.datas;
        this.fade(false);
      });
    },
    loadRate() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=bmdr`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchRate = response.data.datas;
        this.fade(false);
      });
    },
    loadDestinationLocation() {
      const baseURI =
        this.$settings.endPoint +
        `location?status=ENABLED&company_name=` +
        this.rateDetail.customer_name;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchLocation = response.data.datas.data;
        this.fade(false);
      });
    },
    loadSku() {
      const baseURI = this.$settings.endPointCt + `type-truck`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchSku = response.data.datas.data;
        this.fade(false);
      });
    },
    loadRegion() {
      const baseURI = this.$settings.endPoint + "region?status=ENABLED";
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchRegion = response.data.datas.data;
        this.fade(false);
      });
    },
    loadBillableTariff() {
      const baseURI =
        this.$settings.endPoint +
        `rm-billable-tariff?customer_code=${this.rateDetail.customer_code}`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchBillableTariff = response.data.datas.data;
        this.fade(false);
      });
    },
    loadUnitType() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmut`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchUnitType = response.data.datas;
        this.fade(false);
      });
    },
    loadData(id) {
      this.fade(true);
      const baseURI =
        this.$settings.endPoint + "rm-billable-rate-header/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        this.rateDetail = response.data.datas.header;
        this.forms.min = 0;
        this.forms.max = 0;
        this.forms.qty = 1;
        this.forms.all_inclusive = "0";
        this.forms.no_intermediate_stops = "0";
        this.loadGeography();
        this.loadDivision();
        this.loadOrderGroup();
        this.loadTier();
        this.loadSubTariff();
        this.loadAccessorialApplicationRule();
        this.loadCurrency();
        this.loadBasis();
        this.loadAllocationMethod();
        this.loadRate();
        this.loadSku();
        this.loadDestinationLocation();
        this.loadCountryCode();
        this.loadEquipmentType();
        this.loadRegion();
        this.loadBillableTariff();
        this.loadUnitType();
        // this.company = response.data.datas;

        // this.detailAll = this.forms.transport_order_detail_template;

        // this.form={ name: 'asep', usernmae:res.username };
      });
    },
    changeGeography(ev) {
      if (ev !== "Country, City, State.Province") {
        this.geographyCountry = [];
        this.geographyProvince = [];
        this.geographyCity = [];
        this.geographyStartPostalCode = [];
        this.geographyEndPostalCode = [];
      }
      if (ev !== "Stop Location") {
        this.geographyLocation = [];
      }
      if (ev !== "Region") {
        this.geographyRegion = [];
      }
    },
    rateBreak() {
      this.showModalRateBreak = true;
    },
    closeMyModal() {
      this.showModalRateBreak = false;
    },
    validation() {
      if (this.destLocation == null) {
        this.destLocation = [];
      }
      if (this.geographyLocation == null) {
        this.geographyLocation = [];
      }
      if (this.billableTariff.length <= 0) {
        this.error("Billable Tariff Required");
        return false;
      }
      if (!this.geography || this.geography.length <= 0) {
        this.error("Geography Required");
        return false;
      }
      if (!this.tier || this.tier.length <= 0) {
        this.error("Tier Required");
        return false;
      }
      if (!this.subTariff || this.subTariff.length <= 0) {
        this.error("Sub Tariff Required");
        return false;
      }
      if (!this.currency || this.currency.length <= 0) {
        this.error("Currency Required");
        return false;
      }
      if (!this.basis || this.basis.length <= 0) {
        this.error("Basis Required");
        return false;
      }
      // if (
      //   !this.forms?.opportunity_id ||
      //   this.forms?.opportunity_id == "" ||
      //   this.forms?.opportunity_id == undefined
      // ) {
      //   this.error("Opportunity Id Required");
      //   return false;
      // }
      // if (!this.unitType || this.unitType.length <= 0) {
      //   this.error("Unit Type Required");
      //   return false;
      // }
      if (!this.equipmentType || this.equipmentType.length <= 0) {
        this.error("Equipment Type Required");
        return false;
      }
      if (
        (!this.destCountry || this.destCountry.length) <= 0 &&
        (!this.destProvince || this.destProvince.length) <= 0 &&
        (!this.destCity || this.destCity.length) <= 0 &&
        (!this.destStartPostalCode || this.destStartPostalCode.length) <= 0 &&
        (!this.destEndPostalCode || this.destEndPostalCode.length) <= 0 &&
        (!this.destLocation || this.destLocation.length) <= 0 &&
        (!this.destRegion || this.destRegion.length) <= 0
      ) {
        this.error(
          "Destination Location & Destination Region & Destination Country,Province,City,Postal Code Is Null"
        );
        return false;
      }

      if (
        (!this.geographyCountry || this.geographyCountry.length) <= 0 &&
        (!this.geographyProvince || this.geographyProvince.length) <= 0 &&
        (!this.geographyCity || this.geographyCity.length) <= 0 &&
        (!this.geographyStartPostalCode ||
          this.geographyStartPostalCode.length) <= 0 &&
        (!this.geographyEndPostalCode || this.geographyEndPostalCode.length) <=
          0 &&
        (!this.geographyLocation || this.geographyLocation.length) <= 0 &&
        (!this.geographyRegion || this.geographyRegion.length) <= 0
      ) {
        this.error("Origin Destination Empty");
        return false;
      }

      var effectiveDate = moment(this.effective_date.startDate).format(
        "YYYY-MM-DD"
      );
      var ExpDate = moment(this.expiration_date.startDate).format("YYYY-MM-DD");
      if (ExpDate < effectiveDate) {
        this.error("Expired Date must greater than Effective Date");
        return false;
      }

      return true;
    },
    submitData() {
      if (this.validation()) {
        var effective_date = moment(this.effective_date.startDate).format(
          "YYYY-MM-DD HH:mm"
        );
        var expiration_date = moment(this.expiration_date.startDate).format(
          "YYYY-MM-DD HH:mm"
        );
        this.forms.rm_billable_rate_header_id =
          this.rateDetail.rm_billable_rate_header_id;
        this.forms.rm_billable_tariff_id =
          this.billableTariff.rm_billable_tariff_id;
        this.forms.billable_tariff_name = this.billableTariff.tariff_name;
        this.forms.tariff_type = this.billableTariff.tariff_type;
        this.forms.type_truck_id = this.sku.type_truck_id || null;
        this.forms.sku = this.sku.type_truck || null;
        this.forms.tier = this.tier;
        this.forms.division = this.division;
        this.forms.order_group = this.orderGroup;

        this.forms.unit_type = this.unitType;

        this.forms.origin_location_id =
          this.geographyLocation.location_id || null;
        this.forms.origin_location = this.geographyLocation.name || null;
        this.forms.origin_city = this.geographyCity.city || null;
        this.forms.origin_state = this.geographyProvince.province || null;
        this.forms.origin_start_postal_code =
          this.geographyStartPostalCode.postal_code || null;
        this.forms.origin_end_postal_code =
          this.geographyEndPostalCode.postal_code || null;
        this.forms.origin_country = this.geographyCountry.country || null;
        this.forms.origin_region = this.geographyRegion.region_code || null;
        this.forms.origin_region_id = this.geographyRegion.region_id || null;

        this.forms.destination_location_id =
          this.destLocation?.location_id || null;
        this.forms.destination_location = this.destLocation?.name || null;
        this.forms.destination_city = this.destCity?.city || null;
        this.forms.destination_state = this.destProvince?.province || null;
        this.forms.destination_start_postal_code =
          this.destStartPostalCode?.postal_code || null;
        this.forms.destination_end_postal_code =
          this.destEndPostalCode?.postal_code || null;
        this.forms.destination_country = this.destCountry?.country || null;
        this.forms.destination_region = this.destRegion?.region_code || null;
        this.forms.destination_region_id = this.destRegion?.region_id || null;
        this.forms.currency = this.currency;
        this.forms.sub_tariff = this.subTariff;
        this.forms.effective_date = effective_date;
        this.forms.expiration_date = expiration_date;
        this.forms.equipment_type = this.equipmentType;
        this.forms.accessorial_application_rule = this.accessorial.description;
        this.forms.basis = this.basis;
        this.forms.allocation_method = this.allocationMethod;
        const baseURI =
          this.$settings.endPoint + "rm-billable-rate-header/create-detail";
        this.fade(true);
        this.$http
          .post(baseURI, this.forms)
          .then((response) => {
            if (response.data.status === 200) {
              this.errors = [];
              //(response);
              this.loading();
              var params = this.$onRandom(
                response.data.datas.rm_billable_rate_detail_id
              );
              window.location.href =
                "/billable-rate/detail/" +
                this.$route.params.id +
                "/detail/" +
                params;
              this.success("Berhasil");
            } else {
              this.loading();
              this.errors = response.data.errors;
              this.resultError(response.data.errors);
            }
          })
          .catch((error) => {
            this.loading();
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
          });
      }
    },
    EffectiveDateChange(ev) {
      var effectiveDate = moment(ev.startDate).format("YYYY-MM-DD");
      var ExpDate = moment(this.expiration_date.startDate).format("YYYY-MM-DD");
      if (ExpDate < effectiveDate) {
        this.expiration_date = {
          startDate: moment(effectiveDate).add(1, "days"),
          endDate: moment(effectiveDate).add(1, "days"),
        };
      }
    },
    dateFormatExpired(classes, date) {
      if (!classes.disabled) {
        classes.disabled =
          date.getTime() - 86400000 <= new Date(this.effective_date.startDate);
      }
      return classes;
    },
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RateManagementController") {
          rolePermision = permision;
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },
    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
    loadDetail(id) {
      this.fade(true);
      const baseURI =
        this.$settings.endPoint + "rm-billable-rate-header/detail-detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        var data = response.data.datas;
        this.division = data.division;
        this.orderGroup = data.order_group;
        this.tier = data.tier;
        // this.forms.all_inclusive = data.all_inclusive ?? false;
        this.forms.min = data.min;
        this.forms.max = data.max;

        this.subTariff = data.sub_tariff;
        this.currency = data.currency;
        // this.accessorial = data.accessorial_application_rule;
        this.accessorial = {
          description : data.accessorial_application_rule
        };
        this.basis = data.basis;
        this.forms.rate = data.rate;
        this.equipmentType = data.equipment_type;
        this.forms.all_inclusive = data.all_inclusive;
        this.forms.no_intermediate_stops = data.no_intermediate_stops;

        this.unitType = data.unit_type;
        this.forms.opportunity_id = data.opportunity_id;

        this.allocationMethod = data.allocation_method;
        this.forms.rm_billable_rate_detail_id = data.rm_billable_rate_detail_id;
        this.effective_date = {
          startDate: new Date(data.effective_date),
          endDate: new Date(data.effective_date),
        };
        this.expiration_date = {
          startDate: new Date(data.expiration_date),
          endDate: new Date(data.expiration_date),
        };
        if (
          data.destination_location_id !== "" &&
          data.destination_location_id
        ) {
          this.loadDestLocationDetail(data.destination_location_id);
        }
        this.destCountry = {
          country: data.destination_country,
        };
        this.destProvince = {
          province: data.destination_state,
        };
        this.destCity = {
          city: data.destination_city,
        };
        this.destEndPostalCode = {
          postal_code: data.destination_end_postal_code,
        };
        this.destStartPostalCode = {
          postal_code: data.destination_start_postal_code,
        };
        this.destRegion = {
          region_code: data.destination_region,
        };
        this.sku = {
          type_truck_id: data.type_truck_id,
          type_truck: data.sku,
        };
        this.billableTariff = {
          tariff_name: data.billable_tariff_name,
          tariff_type: data.tariff_type,
          rm_billable_tariff_id: data.rm_billable_tariff_id,
        };

        if (data.origin_location !== "" && data.origin_location !== "") {
          this.loadLocationDetail(data.origin_location_id);
          this.geography = "Stop Location";
        }

        if (data.origin_region) {
          this.geographyRegion = {
            region_code: data.origin_region,
          };
          this.geography = "Region";
        }

        if (
          data.origin_country &&
          data.origin_end_postal_code &&
          data.origin_state &&
          data.origin_start_postal_code &&
          data.origin_city
        ) {
          this.geographyCountry = {
            country: data.origin_country,
          };
          this.geographyProvince = {
            province: data.origin_state,
          };
          this.geographyCity = {
            city: data.origin_city,
          };
          this.geographyStartPostalCode = {
            postal_code: data.origin_end_postal_code,
          };
          this.geographyEndPostalCode = {
            postal_code: data.origin_start_postal_code,
          };
          this.geography = "Country, City, State.Province";
        }
        this.fade(false);
      });
    },
    loadDestLocationDetail(id) {
      const baseURI = this.$settings.endPoint + `location/detail/` + id;
      return this.$http.get(baseURI).then((response) => {
        this.destLocation = response.data.datas;
        this.destLocation.location_name = this.destLocation.name;
      });
    },
    loadLocationDetail(id) {
      const baseURI = this.$settings.endPoint + `location/detail/` + id;
      return this.$http.get(baseURI).then((response) => {
        this.geographyLocation = response.data.datas;
        this.geographyLocation.location_name = this.geographyLocation.name;
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.fetchIt();
    this.loadData(params[0]);
    if (this.$route.query.detailId && this.$route.query.detailId !== "") {
      const detailId = this.$onBehind(this.$route.query.detailId);
      this.loadDetail(detailId[0]);
    }


    const formElement = this.$refs.formElement;
    if (formElement) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'readonly') {
            const inputs = formElement.querySelectorAll('.multiselect__input');
            inputs.forEach(input => {
              input.removeAttribute('readonly');  // Hapus readonly setiap kali diubah
            });
          }
        });
      });

      const inputs = formElement.querySelectorAll('.multiselect__input');
      inputs.forEach(input => {
        observer.observe(input, { attributes: true });
      });
    }


    //console.log(this.destLocation);
    // this.getLocation();
  },
};
</script>
<style scoped></style>
