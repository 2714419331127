<template>
    <div>
        <div class="main-wrapper">
            <!--begin::sidebar-->
            <sidebar-component classMenu="Driver"></sidebar-component>
            <!--end::sidebar-->

            <div class="page-wrapper">
                <!--begin::navbar-->
                <navbar-component></navbar-component>
                <!--end::navbar-->

                <!--begin::content-->
                <div class="page-content">
                    <div class="
                d-flex
                justify-content-between
                align-items-center
                flex-wrap
                grid-margin
              ">
                        <div>
                            <h4 class="mb-12 mb-md-0"></h4>
                        </div>

                        <div class="col-lg-12 col-xl-12 stretch-card">
                            <div class="card">
                                <div class="card-header">
                                    <h4 style="margin-right: 5px" class="float-start">Driver Blacklist</h4>
                                    <button v-if="permision_role.method_create" class="btn btn-primary float-end btn-xs"
                                        style="margin-right: 5px" @click.prevent="createData()">
                                        <i class="link-icon" data-feather="plus"></i>
                                        {{ $t("createNew") }}
                                    </button>





                                    <button v-if="permision_role.method_read" class="btn btn-success float-end btn-xs"
                                        style="margin-right: 5px" @click.prevent="downloadData()">
                                        <i class="link-icon" data-feather="download"></i>
                                        {{ $t("downloadData") }} .xlsx
                                    </button>


                                    <button v-if="permision_role.method_read" class="btn btn-danger float-end btn-xs"
                                        style="margin-right: 5px" @click.prevent="uploadData()">
                                        <i class="link-icon" data-feather="upload"></i>
                                        {{ $t("uploadData") }} .xlsx
                                    </button>

                                </div>

                                <div class="card-body">
                                    <vue-good-table title="list-user" mode="remote" @on-page-change="onPageChange"
                                        @on-sort-change="onSortChange" @on-column-filter="onColumnFilter"
                                        @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
                                        :line-numbers="true" :isLoading.sync="isLoading" :pagination-options="{
                                        enabled: true,
                                    }" :rows="rows" :columns="columns">
                                        <template slot="table-row" slot-scope="props">

                                            <span v-if="props.column.field == 'actions'">
                                                <a href="#" v-if="permision_role.method_read" title="Detail"
                                                    @click.prevent="
                                        detailData(props.index, props.row)
                                        ">
                                                    <i class="mdi mdi-eye" style="font-size:16px"></i>

                                                </a>
                                                <a href="#" v-if="permision_role.method_update" title="Edit"
                                                    @click.prevent="editData(props.index, props.row)">
                                                    <i class="mdi mdi-square-edit-outline" style="font-size:16px"></i>

                                                </a>

                                                <a href="#" v-if="permision_role.method_delete" title="Delete"
                                                    @click.prevent="
                                        deleteData(props.index, props.row)
                                        ">
                                                    <i class="mdi mdi-trash-can-outline" style="font-size:16px"></i>
                                                </a>

                                            </span>

                                        </template>
                                    </vue-good-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end::content-->

                <!--begin::footer-->
                <footer-component></footer-component>

                <modalDownloadDepositDriver>

                </modalDownloadDepositDriver>

                <modalDownloadDepositDriver :showModal="showModalNow" @closeModal="closeMyModal">
                </modalDownloadDepositDriver>

                <!--end::footer-->
            </div>
        </div>
    </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import modalDownloadDepositDriver from "@/components/modal/modalDownloadDepositDriver"

export default {
    name: "DriverBlackListIndex",
    components: {
        "sidebar-component": sidebarComponent,
        "navbar-component": navbarComponent,
        "footer-component": footerComponent,
        modalDownloadDepositDriver
    },
    data() {
        return {
            maxToasts: 100,
            position: "up right",
            closeBtn: true,
            isLoading: false,
            showModalNow: false,
            errors: [],
            permision_role: [],
            langs: ["id", "en"],
            detailUser: [],
            detailDivisi: [],
            totalRecords: 0,
            serverParams: {
                columnFilters: {},
                sort: {
                    field: "",
                    type: "",
                },
                page: 1,
                per_page: 10,
            },
            columns: [
                {
                    label: "Type",
                    field: "type_list",
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        placeholder: "Filter By", // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: "NMK",
                    field: "driver_id",
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        placeholder: "Filter By Driver Id", // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },

                {
                    label: "Driver Name",
                    field: "driver_name",
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        placeholder: "Filter By ", // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: "ID Card",
                    field: "id_card_number",
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        placeholder: "Filter By", // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },

                {
                    label: "SIM",
                    field: "sim_no",
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        placeholder: "Filter By ", // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },


                {
                    label: "Birth Place",
                    field: "birth_place",
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        placeholder: "Filter By", // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },


                {
                    label: "Birth Date",
                    field: "birth_date",
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        placeholder: "Filter By ", // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },


                {
                    label: "Remarks",
                    field: "remarks",
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        placeholder: "Filter By ", // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },


                {
                    label: "Date Reported",
                    field: "date_reported",
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        placeholder: "Filter By ", // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },


                {
                    label: "Reported",
                    field: "pelapor",
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        placeholder: "Filter By ", // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },


                {
                    label: "Category",
                    field: "category",
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        placeholder: "Filter By ", // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },




                {
                    label: "Action",
                    field: "actions",
                    sortable: false,
                },
            ],
            rows: [],
        };
    },
    watch: {},
    methods: {
        sendToBlujay(index, row) {
            this.$swal({
                title: this.$t("areYouSure"),
                text: this.$t("yourDataSend"),
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes!",
            }).then((result) => {
                if (result.value) {
                    // this.loadingSubmiting();
                    this.fade(true);

                    const baseURI = this.$settings.endPointCt + "driver-nmk/send-to-nintex/" + row.master_driver_id;
                    this.$http
                        .post(baseURI,)
                        .then((response) => {
                            if (response.data.status === 200) {
                                this.success(response.data.datas.messages);
                                this.loadItems();
                                this.isloading = false;
                            } else {
                                this.errors = response.data.errors;
                                this.resultError(response.data.errors);
                            }
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            if (error.code == "ECONNABORTED") {
                                this.error(this.$t("check_internet_connection_txt"));
                            } else {
                                if (error.response) {
                                    if (error.response.status === 422) {
                                        this.errors = error.response.data.errors;
                                        this.resultError(error.response.data.errors);
                                    } else if (error.response.status === 500) {
                                        this.$router.push("/server-error");
                                    } else if (error.response.status === 401) {
                                        this.$router.push("/authorized-error");
                                    } else {
                                        this.$router.push("/page-not-found");
                                    }
                                }
                            }
                        });
                }
            });
        },
        fetchIt() {
            const userDatas = this.$getUserInfo();
            this.detailUser = userDatas.sub;
            // this.permision_role = userDatas.sub.permision_role;
            let rolePermision = [];
            userDatas.sub.permision_role.forEach(function (permision) {
                if (permision.permission.controller == 'DriverBlacklistController') {
                    rolePermision = permision;
                    // console.log(permision);
                }
            });

            if (!rolePermision.method_read) {
                this.$router.push("/authorized-error");
            }
            this.permision_role = rolePermision;

        },

        closeMyModal() {
            this.showModalNow = false;
            //this.loadData(this.vehicle_no);
        },

        downloadReportDeposit() {
            this.showModalNow = true;
        },

        downloadData() {
            const baseURI = this.$settings.endPointCt + "/driver-blacklist";
            var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
            var file_name = "download_" + CurrentDate + ".xlsx";

            //var columnFilters = this.serverParams.columnFilters;

            return this.$http
                .get(
                    baseURI +
                    // `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&driver_id=${this.serverParams.columnFilters.driver_id}&driver_name=${this.serverParams.columnFilters.driver_name}&driver_status=${this.serverParams.columnFilters.driver_status}&id_card_number=${this.serverParams.columnFilters.id_card_number}&nickname=${this.serverParams.columnFilters.nickname}&sim1_type=${this.serverParams.columnFilters.sim1_type}&sim1_expire_date=${this.serverParams.columnFilters.sim1_expire_date}&download=download`,
                    `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&type_list=${this.serverParams.columnFilters.type_list}&driver_id=${this.serverParams.columnFilters.driver_id}&driver_name=${this.serverParams.columnFilters.driver_name}&id_card_number=${this.serverParams.columnFilters.id_card_number}&sim_no=${this.serverParams.columnFilters.sim_no}&birth_place=${this.serverParams.columnFilters.birth_place}&birth_date=${this.serverParams.columnFilters.birth_date}&remarks=&date_reported=${this.serverParams.columnFilters.remarks}&pelapor=${this.serverParams.columnFilters.pelapor}&download=download`,
                    {
                        responseType: "blob",
                    }
                )
                .then((response) => {

                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement("a");

                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", file_name);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.fade(false);
                });
        },

        // downloadData() {
        //   this.fade(true);
        //   var baseURI = this.$settings.endPoint + "/user/download";
        //   var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
        //   var columnFilters = this.serverParams.columnFilters;
        //   var role = columnFilters["role.name"];
        //   var company_code = columnFilters["company_detail.code"];

        //   var sendData = {
        //     username: this.serverParams.columnFilters.username,
        //     name: this.serverParams.columnFilters.name,
        //     email: this.serverParams.columnFilters.email,
        //     level: this.serverParams.columnFilters.level,
        //     status: this.serverParams.columnFilters.status,
        //     role:role,
        //     company_code: company_code,
        //     file_name: "download_" + CurrentDate + ".xlsx",
        //   };
        //   this.$http({
        //     url: baseURI,
        //     method: "GET",
        //     data: sendData,
        //     responseType: "blob",
        //   })
        //     .then((response) => {
        //       this.errors = [];
        //       var filename = sendData.file_name;

        //       var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        //       var fileLink = document.createElement("a");

        //       fileLink.href = fileURL;
        //       fileLink.setAttribute("download", filename);
        //       document.body.appendChild(fileLink);
        //       fileLink.click();
        //       this.fade(false);
        //     })
        //     .catch((error) => {
        //       if (error.response) {
        //         if (error.response.status === 422) {
        //           this.errors = { message: ["File Not Found"] };
        //           this.error("File Not Found");
        //         } else if (error.response.status === 500) {
        //           this.$router.push("/server-error");
        //         } else {
        //           this.$router.push("/page-not-found");
        //         }
        //       }
        //     });
        // },
        createData() {
            window.location.href = "/driver/driver-blacklist/add";
        },

        uploadData() {
            window.location.href = "/driver/driver-blacklist/upload";
        },

        editData(index, row) {
            var params = this.$onRandom(row.driver_blacklist_id);
            window.location.href = "/driver/driver-blacklist/edit/" + params;
        },

        detailData(index, row) {
            var params = this.$onRandom(row.driver_blacklist_id);
            window.location.href = "/driver/driver-blacklist/detail/" + params;
        },

        deleteData(index, row, status) {
            this.$swal({
                title: this.$t("areYouSure"),
                text: this.$t("yourDataDelete"),
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes!",
            }).then((result) => {
                if (result.value) {
                    var formData = {
                        status: status,
                    };
                    const baseURI =
                        this.$settings.endPointCt + "driver-blacklist/delete/" + row.driver_blacklist_id;
                    this.$http
                        .delete(baseURI, formData)
                        .then((response) => {
                            this.loading();
                            if (response.data.status === 200) {
                                this.success(response.data.datas.messages);
                                this.loadItems();
                            } else {
                                this.errors = response.data.errors;
                                this.resultError(response.data.errors);
                            }
                        })
                        .catch((error) => {
                            this.loading();
                            if (error.response) {
                                if (error.response.status === 422) {
                                    this.errors = error.response.data.errors;
                                    this.resultError(error.response.data.errors);
                                } else if (error.response.status === 500) {
                                    this.$router.push("/server-error");
                                } else {
                                    this.$router.push("/page-not-found");
                                }
                            }
                        });
                }
            });
        },

        // load items is what brings back the rows from server
        loadItems() {
            const baseURI = this.$settings.endPointCt + "driver-blacklist";

            //var columnFilters = this.serverParams.columnFilters;


            return this.$http
                .get(
                    baseURI +
                    //  `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&driver_id=${this.serverParams.columnFilters.driver_id}&driver_name=${this.serverParams.columnFilters.driver_name}&driver_status=${this.serverParams.columnFilters.driver_status}&id_card_number=${this.serverParams.columnFilters.id_card_number}&nickname=${this.serverParams.columnFilters.nickname}&sim1_type=${this.serverParams.columnFilters.sim1_type}&sim1_expire_date=${this.serverParams.columnFilters.sim1_expire_date}&branch_name=${this.serverParams.columnFilters.branch_name}&project_name=${this.serverParams.columnFilters.project_name}`,
                    `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&type_list=${this.serverParams.columnFilters.type_list}&driver_id=${this.serverParams.columnFilters.driver_id}&driver_name=${this.serverParams.columnFilters.driver_name}&id_card_number=${this.serverParams.columnFilters.id_card_number}&sim_no=${this.serverParams.columnFilters.sim_no}&birth_place=${this.serverParams.columnFilters.birth_place}&birth_date=${this.serverParams.columnFilters.birth_date}&remarks=&date_reported=${this.serverParams.columnFilters.remarks}&pelapor=${this.serverParams.columnFilters.pelapor}&category=${this.serverParams.columnFilters.category}`,
                )
                .then((response) => {

                    this.rows = response.data.datas.data;
                    this.totalRecords = response.data.datas.total;
                });
        },

        updateParams(newProps) {

            this.serverParams = Object.assign({}, this.serverParams, newProps);
            this.loadItems();
        },

        onColumnFilter(params) {
            params.page = 1;
            this.updateParams(params);
        },

        onPageChange(params) {
            console.log(params);
            this.updateParams({ page: params.currentPage });
        },

        onSortChange(params) {
            this.updateParams({
                sort: {
                    type: params[0].type,
                    field: params[0].field,
                },
            });
        },

        onPerPageChange(params) {
            this.updateParams({ per_page: params.currentPerPage });
        },

        resultError(data) {
            var count = Object.keys(data).length;
            for (var x = 0; x < count; x++) {
                var nameOb = Object.keys(data)[x];
                var objectData = data[nameOb];
                for (var y = 0; y < objectData.length; y++) {
                    this.error(objectData[y]);
                }
            }
        },

        success(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "success",
                title: kata,
            });
        },

        error(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "error",
                title: kata,
            });
        },

        fade(sType) {
            this.isLoading = sType;
        },

        loading() {
            this.fade(true);
            setTimeout(() => {
                this.isLoading = false;
            }, 1000); // hide the message after 3 seconds
        },


        logout() {
            this.$logoutUser();
            this.$router.push("/");
        },
    },
    events: {},
    created: function () { },
    mounted() {
        this.fetchIt();
        this.loading();
    },
};
</script>
<style scoped></style>