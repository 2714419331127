<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="DocumentEdit"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            "
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left: 25px">
                    {{ $t("documentAccessEdit") }}
                  </h6>
                  <button
                    class="btn btn-default float-end"
                    @click="resetForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>

                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->
                    <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "
                    ></div>

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Type Document</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <v-select
                              :options="fetchTypeDocument"
                              v-model="type_document"
                              label="document_name"
                              return-object
                              @search="asyncSearchTypeDocument"
                            ></v-select>

                            <div v-if="errors.type_document_id">
                              <div
                                v-for="error in errors.type_document_id"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Date Of Expire </label
                            >
                          </div>


                          <div class="col-lg-8">
                            <Datepicker
                                :highlighted="highlighted"
                                v-model="forms.date_of_expire"
                                :bootstrap-styling=true
                                format="dd-MM-yyyy"
                                placeholder="Please Select Date.."
                              ></Datepicker>

                            <div v-if="errors.date_of_expire">
                              <div
                                v-for="error in errors.date_of_expire"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                            >
                              Document Reference</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <v-select
                              :options="fetchDocumentReference"
                              v-model="document_reference"
                              label="vehicle_no"
                              return-object
                              @search="asyncSearchDocumentReference"
                            ></v-select>
                            <div v-if="errors.document_reference">
                              <div
                                v-for="error in errors.document_reference"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Tax Date </label
                            >
                          </div>


                          <div class="col-lg-8">
                            <Datepicker
                                :highlighted="highlighted"
                                v-model="forms.tax_date"
                                :bootstrap-styling=true
                                format="dd-MM-yyyy"
                                placeholder="Please Select Date.."
                              ></Datepicker>

                            <div v-if="errors.tax_date">
                              <div
                                v-for="error in errors.tax_date"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                      

                      </div>
                    </div>

                    <div class="card-footer">
                      <button
                        class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        "
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button
                        class="btn btn-primary float-end btn-sm"
                        type="submit"
                      >
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import Datepicker from "vuejs-datepicker";

export default {
  name: "TypeDocumentEdit",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    Datepicker
  },
  data() {
    return {
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      permision_role:[],
      fetchTypeDocument: [],
      fetchDocumentReference:[],
     
      highlighted:{

          dates: [ // Highlight an array of dates
            new Date(),
          ],

      },
      type_document: "",
      document_reference: "",
      //document_source: "",
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      forms: [],
    };
  },
  watch: {},
  methods: {
    resetForm() {
      this.forms=[];
    },

    backForm() {
      window.location.href = "/dok";
    },

    loadData(id) {
      const baseURI = this.$settings.endPointCt + "master-document/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        
        this.forms = response.data.datas;
        this.type_document = response.data.datas.type_document;
        this.document_reference = response.data.datas.document_reference;
        //this.document_source = response.data.datas.document_source;
        // this.form={ name: 'asep', usernmae:res.username };
      });
    },

    fetchIt() {
      const userDatas     = this.$getUserInfo();
      this.detailUser     = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function(permision){
        if(permision.permission.controller == 'MasterDocumentController'){
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if(!rolePermision.method_update){
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;

    },

    loadTypeDocument() {
      const baseURI = this.$settings.endPointCt + "type-document";
      return this.$http.get(baseURI).then((response) => {
        this.fetchTypeDocument = response.data.datas.data;
      });
    },

    asyncSearchTypeDocument(ev) {
      const baseURI =
        this.$settings.endPoint + "type-document?document_name=" + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchTypeDocument = response.data.datas.data;
      });
    },


    loadDocumentReference() {
      const baseURI = this.$settings.endPointCt + "master-truck";
      return this.$http.get(baseURI).then((response) => {
        this.fetchDocumentReference = response.data.datas.data;
      });
    },

    asyncSearchDocumentReference(ev) {
      const baseURI =
        this.$settings.endPoint + "master-truck?vehicle_no=" + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchDocumentReference = response.data.datas.data;
      });
    },


    submitData() {
     this.fade(true);
      this.forms.type_document_id=this.type_document.type_document_id;
      this.forms.document_reference=this.document_reference;
      
          const baseURI =
            this.$settings.endPointCt + "master-document/update/" + this.forms.master_document_id;
          this.$http
            .patch(baseURI, this.forms)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                //this.resetForm();
                this.errors = [];
                this.success(response.data.datas.messages);

                //window.history.back();
                var params = this.$onRandom(this.forms.master_document_id);
                window.location.href = "/dok/detail/" + params + "?status=200&msg=Successfully";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                 this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
              //this.resetForm();
            });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.loadData(params[0]);
    this.fetchIt();
    this.loadTypeDocument();
    this.loadDocumentReference();
  },
};
</script>
<style scoped>
</style>