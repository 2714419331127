<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="DashboardFP"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
         
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            "
          >
            <div class="col-lg-12 col-xl-12 stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="row mb-3">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <h5>Dashboard Faktur Pajak</h5>
                    </div>

                  </div>

                  

                  <div class="row">
                    <p class="text-muted mb-3"></p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
// import multiselect from "vue-multiselect";
// import DirectionsRenderer from "@/middleware/DirectionsRenderer";
// import moment from "moment";

export default {
  name: "DashboardFP",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    //multiselect: multiselect,

    // DirectionsRenderer,
  },
  data() {
    //const mapMarkerIcon = require("../../assets/icontruck.png");
    return {
      columns: [],
      rows: [],
    };
  },
  watch: {},
  methods: {
    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },
  },
  events: {},
  created: function () {},
  mounted() {
    
  },
};
</script>
<style >
button.gm-ui-hover-effect {
  visibility: hidden;
}
</style>