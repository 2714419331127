import { render, staticRenderFns } from "./CommodityDetail.vue?vue&type=template&id=66d1b28c&scoped=true"
import script from "./CommodityDetail.vue?vue&type=script&lang=js"
export * from "./CommodityDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66d1b28c",
  null
  
)

export default component.exports