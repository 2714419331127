<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div :class="{ 'blur-content': this.isLoading }">
      <div class="main-wrapper">
        <!--begin::sidebar-->
        <sidebar-component classMenu="TransportOrderCt"></sidebar-component>
        <!--end::sidebar-->

        <div class="page-wrapper">
          <!--begin::navbar-->
          <navbar-component></navbar-component>
          <!--end::navbar-->

          <!--begin::content-->
          <div class="page-content">

            <div>
              <h4 style="margin-right: 5px" class="float-start"></h4>
              <h4 class="mb-12 mb-md-0">
                Truck Utilization Report
              </h4>
              <br />
            </div>

            <div class="col-lg-12 col-xl-12 stretch-card">
              <div class="card">
                <div class="card-header">
                  <button class="btn btn-default btn-xl" style="margin-right: 5px" @click.prevent>
                    {{ template }} | {{ dates }}
                  </button>

                  <button v-if="permision_role.method_read" class="btn btn-success float-end btn-xs"
                    style="margin-right: 5px" @click.prevent="downloadData()">
                    <i class="link-icon" data-feather="download"></i>
                    {{ $t("downloadData") }} .xlsx
                  </button>

                  <button class="btn btn-warning float-end btn-xs" style="margin-right:5px;" @click="backForm"
                    type="button">
                    <i class="link-icon" data-feather="arrow-left"></i>
                    {{ $t("backMess") }}
                  </button>

                </div>

                <div class="card-body">

                  <div v-if="template == 'No Driver' || template == 'AIS'" class="row">
                    <div class="col-sm-12">
                      <div class="table-responsive" style="overflow-y: auto;">
                        <vue-good-table title="list-order-ct" mode="remote" @on-column-filter="onColumnFilter" :totalRows="totalRecords"
                                                      :line-numbers="true" :isLoading.sync="isLoading" :pagination-options="{
                              enabled: false,
                            }" :rows="rows" :columns="template == 'No Driver'?columnsNoDriver:columnsAis">
                          <template slot="table-row" slot-scope="props">
                            <!--span v-if="props.column.field == 'last'">
                              {{ props.row.last_date.split(';')[0] }}
                            </span-->

                            <span v-if="props.column.field == 'age' && template == 'No Driver'">
                              <!-- {{ props.row.last_date.split(';')[1] }} hari -->
                              {{ props.row.aging }} hari
                            </span>
                            <span v-else>
                              {{ props.formattedRow[props.column.field] }}
                            </span>
                          </template>
                        </vue-good-table>
                      </div>
                    </div>
                  </div>

                  <div v-else-if="template == 'Service'" class="row">
                    <div class="col-sm-12">
                      <div class="table-responsive" style="overflow-y: auto;">
                        <vue-good-table title="list-order-ct" mode="remote" @on-column-filter="onColumnFilter" :totalRows="totalRecords"
                         :line-numbers="true" :isLoading.sync="isLoading" :pagination-options="{
                                    enabled: false,
                                  }" :rows="rows" :columns="columnsService">
                          <template slot="table-row" slot-scope="props">


                            <!-- <span v-if="props.column.field == 'action'">

                              <a style="color: #ab0635;text-decoration: none;" v-if="permision_role.method_update"
                                title="Edit" @click.prevent="editData(props.row)">
                                <i class="mdi mdi-square-edit-outline" style="font-size:16px"></i>

                              </a>

                            </span> -->

                            <span v-if="props.column.field == 'keluhan' && props.row.fppk_keluhan">
                              <!-- <ul>                                
                                <li v-for="item in arrayToLines(props.row.fppk_keluhan)" :key="item.id">
                                  {{ item }} 
                                </li>
                              </ul>-->
                            </span>

                            <span v-if="props.column.field == 'analisa' && props.row.fppk_analisa">
                              <ul>                                
                                <li v-for="item in arrayToLines(props.row.fppk_analisa)" :key="item.id">
                                  {{ item }} 
                                </li>
                              </ul> 
                            </span>

                            <span v-else>
                              {{ props.formattedRow[props.column.field] }}
                            </span>
                          </template>
                        </vue-good-table>
                      </div>
                    </div>
                  </div>

                  <div v-else-if="template == 'Idle Cap'" class="row">
                    <div class="col-sm-12">
                      <div class="table-responsive" style="overflow-y: auto;">
                        <vue-good-table title="list-order-ct" mode="remote" @on-column-filter="onColumnFilter" :totalRows="totalRecords"
                                                                :line-numbers="true" :isLoading.sync="isLoading" :pagination-options="{
                                        enabled: false,
                                      }" :rows="rows" :columns="columns">
                          <template slot="table-row" slot-scope="props">


                            <span v-if="props.column.field == 'action'">

                              <a style="color: #ab0635;text-decoration: none;" v-if="permision_role.method_update"
                                title="Edit" @click.prevent="editData(props.row)">
                                <i class="mdi mdi-square-edit-outline" style="font-size:16px"></i>

                              </a>

                            </span>

                            <span v-else>
                              {{ props.formattedRow[props.column.field] }}
                            </span>
                          </template>
                        </vue-good-table>
                      </div>
                    </div>
                  </div>
                  
                  <div v-else class="row">
                    <div class="col-sm-3">
                      <table class="table table-hover mb-0">
                        <thead>
                          <tr>
                            <th class="pt-0">Total Unit</th>
                            <th class="pt-0">Availabilty</th>
                          </tr>
                        </thead>
                        <tbody v-if="template=='Availibility'">
                          <tr>
                            <td style="text-align:right;">{{ total }}</td>
                            <td> 100 % Unit</td>
                          </tr>
                          <tr>
                            <td style="text-align:right;">{{ service }}</td>
                            <td> {{ persent_service }} % Service</td>
                          </tr>
                          <tr>
                            <td style="text-align:right;">{{ no_driver }}</td>
                            <td>{{ persent_no_driver }} % No Driver</td>
                          </tr>
                          <tr>
                            <td style="text-align:right;">{{ availibility }}</td>
                            <td>{{ persent_availibility }} % Availability</td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td style="text-align:right;">{{ total }}</td>
                            <td> 100 % Unit</td>
                          </tr>
                          <tr>
                            <td style="text-align:right;">{{ idle }}</td>
                            <td> {{ persent_idle }} % Idle</td>
                          </tr>
                          <tr>
                            <td style="text-align:right;">{{ utility }}</td>
                            <td>{{ persent_utility }} % Utility</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="col-sm-9">
                      <div class="table-responsive" style="overflow-y: auto;">
                        <vue-good-table title="list-order-ct" mode="remote" @on-column-filter="onColumnFilter"  :totalRows="totalRecords"
                                                                :line-numbers="true" :isLoading.sync="isLoading" :pagination-options="{
                                        enabled: false,
                                      }" :rows="rows" :columns="columns">
                          <template slot="table-row" slot-scope="props">


                            <span v-if="props.column.field == 'action'">

                              <a style="color: #ab0635;text-decoration: none;" v-if="permision_role.method_update"
                                title="Edit" @click.prevent="editData(props.row)">
                                <i class="mdi mdi-square-edit-outline" style="font-size:16px"></i>

                              </a>

                            </span>

                            <span v-else>
                              {{ props.formattedRow[props.column.field] }}
                            </span>
                          </template>
                        </vue-good-table>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
          <!--end::content-->
          <!--begin::footer-->
          <footer-component></footer-component>
          <!--end::footer-->
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
// import DateRangePicker from "vue2-daterange-picker";
import moment from 'moment';

export default {
  name: "DriverAttendance",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    //   DateRangePicker,
  },
  data() {
    return {
      total: 0,
      idle: 0,
      utility: 0,
      availibility: 0,
      no_driver: 0,
      service: 0,
      persent_idle: 0,
      persent_utility: 0,
      persent_no_driver: 0,
      persent_availibility: 0,
      persent_service: 0,
      template: '',
      dates: '',
      tgl: '',
      maxToasts: 100,
      selected: '',
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      permision_role: [],
      truck_utility_detail_ids: [],
      langs: ["id", "en"],
      detailUser: [],
      detailDivisi: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 1000,
      },
      columns: [
        {
          label: "Vehicle No",
          field: "vehicle_no",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Vehicle No", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
          sortable: false,
        },
        {
          label: "Ownership",
          field: "ownership",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Ownership", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
          sortable: false,
        },
        {
          label: "Carrier",
          field: "carrier",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Carrier", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
          sortable: false,
        },
        {
          label: "Truck Type",
          field: "type_truck",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Truck Type", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
          sortable: false,
        },
        {
          label: "GPS Vendor",
          field: "gps_vendor",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By GPS Vendor", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
          sortable: false,
        },

        {
          label: "Branch",
          field: "branch_name",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Branch", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
          sortable: false,
        },

        {
          label: "Project",
          field: "project",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Project", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
          sortable: false,
        },
      ],
      columnsNoDriver: [
        {
          label: "Vehicle No",
          field: "vehicle_no",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Vehicle No", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
          sortable: false,
        },
        {
          label: "Truck Type",
          field: "type_truck",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Truck Type", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
          sortable: false,
        },

        {
          label: "Last No Driver",
          field: "last_date",
          filterOptions: {
            enabled: false // enable filter for this column
          },
          sortable: false,
        },

        {
          label: "Aging",
          field: "age",
          filterOptions: {
            enabled: false, // enable filter for this column
          },
          sortable: false,
        },



      ],
      columnsAis: [
        {
          label: "Vehicle No",
          field: "vehicle_no",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Vehicle No", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
          sortable: false,
        },
        {
          label: "Truck Type",
          field: "type_truck",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Truck Type", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
          sortable: false,
        },

        {
          label: "AIS Date",
          field: "updated_at",
          filterOptions: {
            enabled: false // enable filter for this column
          },
          sortable: false,
        },

      ],
      columnsService: [
        {
          label: "Vehicle No",
          field: "vehicle_no",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Vehicle No", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
          sortable: false,
        },
        {
          label: "Truck Type",
          field: "type_truck",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Truck Type", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
          sortable: false,
        },

        {
          label: "FPPK No",
          field: "fppk_no",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By FPPK No", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
          sortable: false,
        },

        {
          label: "FPPK Date",
          field: "fppk_date",
          filterOptions: {
            enabled: false, // enable filter for this column
          },
          sortable: false,
        },

        {
          label: "FPPK Keluhan",
          field: "fppk_keluhan",
          filterOptions: {
            enabled: false, // enable filter for this column
          },
          sortable: false,
        },

        {
          label: "FPPK Analisa",
          field: "fppk_analisa",
          filterOptions: {
            enabled: false, // enable filter for this column
          },
          sortable: false,
        },

        {
          label: "Mulai Service",
          field: "start_service",
          filterOptions: {
            enabled: false, // enable filter for this column
          },
          sortable: false,
        },

        {
          label: "Estimasi Selesai Service",
          field: "estimate_finish_service",
          filterOptions: {
            enabled: false, // enable filter for this column
          },
          sortable: false,
        },

        {
          label: "Aging",
          field: "aging",
          filterOptions: {
            enabled: false, // enable filter for this column
          },
          sortable: false,
        },



      ],
      rows: [],
      // rowsSelection:[],
    };
  },
  watch: {},
  computed: {
  },
  methods: {
    arrayToLines(val){
        const array = val.split(';');
        const results = array.filter(element => {
          return element !== '';
        });
        // const list = array.map(item => `<li>${item}</li>`);
        return results;
    },
    backForm() {
      this.$router.push("/dashboardUtilitationReport");
    },
    pickupChange() { },
    selectionChanged(ev) {
      this.rowsSelection = [];
      this.truck_utility_detail_ids = [];
      this.rowsSelection = ev.selectedRows;

      this.rowsSelection.forEach((item) => {
        this.truck_utility_detail_ids.push(item.truck_utility_detail_id);
      });

    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == 'TruckUtilityDetailController') {
          rolePermision = permision;
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;

      if (this.$route.query.msg) {
        if (this.$route.query.status == 200) {
          this.success(this.$route.query.msg)
        } else {
          this.error(this.$route.query.msg)
        }

      }

    },

    editData(row) {
      // var params = this.$onRandom(row.vehicle_no);
      let vehicle = row.vehicle_no;
      let start = moment(String(this.dateFilter.startDate,)).format('YYYY-MM-DD'); //this.dateFilter.startDate,  // moment(String(this.forms.join_date)).format('YYYY-MM-DD');
      let end = moment(String(this.dateFilter.endDate,)).format('YYYY-MM-DD');
      window.location.href = "/truckUtilitationDetail/" + vehicle + "/" + start + "/" + end;
    },

    loadItems() {
      // let tanggal = this.filtertanggal.toISOString().split('T')[0]
      this.isLoading = true;
      //console.log("jalan");
      const baseURI = this.$settings.endPointCt + "truck-utility-detail/utilitation-date";

      return this.$http
        .get(
          baseURI
          +
          `?date=${this.tgl}&type=${this.template}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&type_truck=${this.serverParams.columnFilters.type_truck}&vehicle_no=${this.serverParams.columnFilters.vehicle_no}`,
          { timeout: 10000 }
        )
        .then((response) => {
          let temp = response.data.datas;
          this.total = response.data.datas.length;
         // availibility, idle cap, utility, 
        if(this.template=='Idle Cap'){
          this.rows = temp.filter(i => i.idle_cap === "1")
        }else if(this.template=='Idle'){
          this.rows = temp.filter(i => i.idle === "1")          
        }else if(this.template=='Availibility'){
          this.rows = temp.filter(function (obj) {
          return obj.no_driver === "0" && obj.service === "0";
          });
        }else if(this.template=='Utility'){
          this.rows = temp.filter(i => i.active === "1")
        }else{
          this.rows = temp;
        }

        if(this.template=='Availibility'){
            this.no_driver = temp.filter(i => i.no_driver === "1").length;
            this.service = temp.filter(i => i.service === "1").length;
            this.availibility = this.total - this.no_driver - this.service;
            this.persent_no_driver = (this.no_driver / this.total * 100).toFixed(1);
            this.persent_service = (this.service / this.total * 100).toFixed(1);
            this.persent_availibility = (this.availibility / this.total * 100).toFixed(1);
          }else{
            this.idle = temp.filter(i => i.idle === "1").length;
            this.utility = temp.filter(i => i.active === "1").length;
            this.persent_idle = (this.idle / this.total * 100).toFixed(1);
            this.persent_utility = (this.utility / this.total * 100).toFixed(1);
          }
          this.isLoading = false;
        }).catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    },

    downloadData() {
      this.isLoading = true;
      const baseURI = this.$settings.endPointCt + "truck-utility-detail/utilitation-date";
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = "download_" + CurrentDate + ".xlsx";

      return this.$http
        .get(
          baseURI +
          // `?download=download&start=${dataSend.start}&end=${dataSend.end}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&vehicle_no=${this.serverParams.columnFilters.vehicle_no}&ownership=${this.serverParams.columnFilters.ownership}&carrier=${this.serverParams.columnFilters.carrier}&type_truck=${this.serverParams.columnFilters.type_truck}&branch_name=${this.serverParams.columnFilters.branch_name}&project=${this.serverParams.columnFilters.project}`,
          `?download=download&date=${this.tgl}&type=${this.template}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&type_truck=${this.serverParams.columnFilters.type_truck}&vehicle_no=${this.serverParams.columnFilters.vehicle_no}`,

          {
            responseType: "blob",
          }
        )
        .then((response) => {

          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      //this.loadItems()
    },

    onColumnFilter(params) {
      params.page = 1;
      this.serverParams = Object.assign({}, this.serverParams, params);
      //this.loadItems()
      //this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    success2(msg) {
      // let pesan="";
      // msg.forEach((item) => {
      //     pesan=pesan+"<p>* "+item+"</p>";
      // });

      this.$swal({
        icon: 'info',
        backdrop: true,
        title: "Information",
        html: msg,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      })


    },


    loadingSubmiting() {


      this.$swal({
        icon: 'warning',
        backdrop: true,
        title: "Please wait",
        html: '<h2>SUBMITTING ORDER...</h2>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

    },


    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },

  },
  events: {},
  created: function () { },
  mounted() {
    this.fetchIt();
    this.loading();
    var date = this.$route.params.date;
    //var type = this.$route.params.type;
    this.template = this.$route.params.type;
    this.dates = moment(String(date)).format('DD MMM YYYY');
    this.tgl = date;
    this.loadItems();
  },

};
</script>
<style>
table.vgt-table {
  white-space: pre-line !important;
}

.blur-content {
  filter: blur(5px);
  pointer-events: none;
}

.card-body {
  padding: 5px 1.5rem 1rem;
}

.vgt-table th {
  text-align: left !important;
}

.toggleFilterClass {
  display: none !important;
}
</style>