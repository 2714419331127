<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="PackageEdit"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            "
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start">
                    {{ $t("packageAccessEdit") }}
                  </h6>
                  <button
                    class="btn btn-default btn-sm float-end"
                    @click="resetForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "
                    ></div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("packageCode") }}</label
                        >
                      </div>
                      <div class="col-lg-2">
                        <input
                          type="text"
                          class="form-control"
                          id="package code"
                          :placeholder="$t('packageCode')"
                          v-model="forms.package_code"
                          required
                        />
                        <div v-if="errors.package_code">
                          <div
                            v-for="error in errors.package_code"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <input
                          type="text"
                          class="form-control"
                          id="package_name"
                          :placeholder="$t('packageName')"
                          v-model="forms.package_name"
                          required
                        />
                        <div v-if="errors.package_name">
                          <div
                            v-for="error in errors.package_name"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("companyTxt") }}</label
                        >
                      </div>
                      <div class="col-lg-5">
                        <v-select
                          v-if="companyCode == 'LINC'"
                          :options="fetchCompany"
                          v-model="company"
                          label="code"
                          return-object
                          @input="changeCompany"
                          @search="asyncSearchCompany"
                          persistent-hint
                        ></v-select>

                        <v-select
                          v-if="companyCode != 'LINC'"
                          :options="fetchCompanyNotLinc"
                          v-model="company"
                          label="code"
                          return-object
                          @search="asyncSearchCompanyNotLinc"
                        ></v-select>
                        <div v-if="errors.company_id">
                          <div
                            v-for="error in errors.company_id"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    
                    
                    <div class="row mb-3">

                       <div class="col-lg-2">
                        
                      </div>

                      <div class="col-lg-1">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Qty</label
                        >
                      </div>


                      <div class="col-lg-1">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Code</label
                        >
                      </div>


                      
                       <div class="col-lg-1">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Length</label
                        >
                      </div>

                       <div class="col-lg-1">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Width</label
                        >
                      </div>



                      <div class="col-lg-1">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Height</label
                        >
                      </div>



                      <div class="col-lg-1">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Volume</label
                        >
                      </div>

                      <div class="col-lg-1">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Weight</label
                        >
                      </div>

                      <div class="col-lg-1">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Net Weight</label
                        >
                      </div>

                  

                      


                    

                    </div>


                    
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >PCS </label
                        >
                      </div>


                      <div class="col-lg-1">
                     
                      </div>

                      <div class="col-lg-1">
                        <input
                          type="text"
                          class="form-control"
                          id="pcs_code"
                          
                          v-model="forms.pcs_code"
                        />
                      </div>


                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="pcs_length"
                          step=".01"
                          v-model="forms.pcs_length"
                        />
                       
                      </div>

                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="pcs_width"
                          step=".01"
                          v-model="forms.pcs_width"
                        />
                       
                      </div>


                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="pcs_height"
                          step=".01"
                          v-model="forms.pcs_height"
                        />
                       
                      </div>

                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="pcs_volume"
                          step=".01"
                          v-model="forms.pcs_volume"
                        />
                        
                      </div>

                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="pcs_weight"
                          step=".01"
                          v-model="forms.pcs_weight"
                        />
                        
                      </div>


                       <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="pcs_net_weight"
                          step=".01"
                          v-model="forms.pcs_net_weight"
                        />
                      
                      </div>

                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >IP </label
                        >
                      </div>


                       <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="ip_qty"
                          step=".01"
                          v-model="forms.ip_qty"
                        />
                        
                      </div>

                      <div class="col-lg-1">
                        <input
                          type="text"
                          class="form-control"
                          id="ip_code"
                        
                          v-model="forms.ip_code"
                        />
                       
                      </div>

                     

                    
                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="ip_length"
                          step=".01"
                          v-model="forms.ip_length"
                        />
                       
                      </div>

                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="ip_width"
                          step=".01"
                          v-model="forms.ip_width"
                        />
                      
                      </div>



                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="ip_height"
                          step=".01"
                          v-model="forms.ip_height"
                        />
                       
                      </div>


                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="ip_volume"
                          step=".01"
                          v-model="forms.ip_volume"
                        />
                      
                      </div>

                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="ip_weight"
                          step=".01"
                          v-model="forms.ip_weight"
                        />
                       
                      </div>


                       <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="ip_net_weight"
                          step=".01"
                          v-model="forms.ip_net_weight"
                        />
                      
                      </div>

                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >CS </label
                        >
                      </div>
                   

                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="cs_qty"
                          step=".01"
                          v-model="forms.cs_qty"
                        />
                       
                      </div>


                      <div class="col-lg-1">
                        <input
                          type="text"
                          class="form-control"
                          id="cs_code"

                          v-model="forms.cs_code"
                        />
                        
                      </div>


                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="cs_length"
                          step=".01"
                          v-model="forms.cs_length"
                        />
                        
                      </div>

                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="cs_width"
                          step=".01"
                          v-model="forms.cs_width"
                        />
                      
                      </div>

                       <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="cs_height"
                          step=".01"
                          v-model="forms.cs_height"
                        />
                       
                      </div>

                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="cs_volume"
                          step=".01"
                          v-model="forms.cs_volume"
                        />
                        
                      </div>

                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="cs_weight"
                          step=".01"
                          v-model="forms.cs_weight"
                        />
                       
                      </div>

                    
                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="cs_net_weight"
                          step=".01"
                          v-model="forms.cs_net_weight"
                        />
                       
                      </div>

                    

                    </div>

                    


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >PL </label
                        >
                      </div>

                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="pl_qty"
                          step=".01"
                          v-model="forms.pl_qty"
                        />
                       
                      </div>


                      <div class="col-lg-1">
                        <input
                          type="text"
                          class="form-control"
                          id="pl_code"
                          
                          v-model="forms.pl_code"
                        />
                       
                      </div>


                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="pl_length"
                          step=".01"
                          v-model="forms.pl_length"
                        />
                       
                      </div>

                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="pl_width"
                          step=".01"
                          v-model="forms.pl_width"
                        />
                       
                      </div>


                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="pl_height"
                          step=".01"
                          v-model="forms.pl_height"
                        />
                       
                      </div>


                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="pl_volume"
                          step=".01"
                          v-model="forms.pl_volume"
                        />
                        
                      </div>

                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="pl_weight"
                          step=".01"
                          v-model="forms.pl_weight"
                        />
                       
                      </div>

                        <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="pl_net_weight"
                          step=".01"
                          v-model="forms.pl_net_weight"
                        />
                       
                      </div>

                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >OT </label
                        >
                      </div>
                     
                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="ot_qty"
                          step=".01"
                          v-model="forms.ot_qty"
                        />
                       
                      </div>


                       <div class="col-lg-1">
                        <input
                          type="text"
                          class="form-control"
                          id="ot_code"
                         
                          v-model="forms.ot_code"
                        />
                      
                      </div>



                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="ot_length"
                          step=".01"
                          v-model="forms.ot_length"
                        />
                       
                      </div>

                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="ot_width"
                          step=".01"
                          v-model="forms.ot_width"
                        />
                       
                      </div>



                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="ot_height"
                          step=".01"
                          v-model="forms.ot_height"
                        />
                        
                      </div>


                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="ot_volume"
                          step=".01"
                          v-model="forms.ot_volume"
                        />
                      
                      </div>

                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="ot_weight"
                          step=".01"
                          v-model="forms.ot_weight"
                        />
                       
                      </div>


                      <div class="col-lg-1">
                        <input
                          type="number"
                          class="form-control"
                          id="ot_net_weight"
                          step=".01"
                          v-model="forms.ot_net_weight"
                        />
                     
                      </div>

                    </div>

                    <div class="card-footer">
                      <button
                        class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        "
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>

                      &nbsp;&nbsp;&nbsp;
                      <button
                        class="btn btn-primary float-end btn-sm"
                        type="submit"
                      >
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";

export default {
  name: "PackageEdit",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      permision_role: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      fetchCompany: [],
      fetchCompanyNotLinc: [],
      company:[],
      forms: {
        package_code: "",
        package_name: "",
        company_id: "",
        cs_code: "",
        cs_qty: "",
        ip_code: "",
        ip_qty: "",
        ot_code: "",
        ot_qty: "",
        pl_code: "",
        pl_qty: "",
        pcs_code: "",
        companyId: [],
      },
    };
  },
  watch: {},
  methods: {
    resetForm() {
     this.forms.package_code = "";
      this.forms.package_name = "";
      this.forms.company_id = "";
      this.forms.cs_code = "";
      this.forms.cs_qty = "";
      this.forms.cs_width = "";
      this.forms.cs_height = "";
      this.forms.cs_weight = "";
      this.forms.cs_length = "";
      this.forms.cs_volume = "";
      this.forms.ip_code = "";
      this.forms.ip_qty = "";
      this.forms.ip_width = "";
      this.forms.ip_height = "";
      this.forms.ip_weight = "";
      this.forms.ip_length = "";
      this.forms.ip_volume = "";
      this.forms.ot_code = "";
      this.forms.ot_qty = "";
      this.forms.ot_width = "";
      this.forms.ot_height = "";
      this.forms.ot_weight = "";
      this.forms.ot_length = "";
      this.forms.ot_volume = "";
      this.forms.pl_code = "";
      this.forms.pl_qty = "";
      this.forms.pl_width = "";
      this.forms.pl_height = "";
      this.forms.pl_weight = "";
      this.forms.pl_length = "";
      this.forms.pl_volume = "";
      this.forms.pcs_code = "";
      this.forms.pcs_qty = 1;
      this.forms.pcs_width = "";
      this.forms.pcs_height = "";
      this.forms.pcs_weight = "";
      this.forms.pcs_length = "";
      this.forms.pcs_volume = "";
      
      this.company= [];
    },

    changeCompany(ev) {
      this.forms.companyId=ev;
    },

    loadCompany() {
      const baseURI = this.$settings.endPoint + "company?limit=10";
      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },

    loadCompanyNotLinc() {
      const baseURI =
        this.$settings.endPoint + `company/select`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },

    asyncSearchCompany(ev) {
      const baseURI = this.$settings.endPoint + "company/select?code=" + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },

    asyncSearchCompanyNotLinc(ev) {
      const baseURI =
        this.$settings.endPoint +
        "company?name=" +
        ev +
        "&code=" +
        this.companyCode;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },

    loadData(id) {
      const baseURI = this.$settings.endPoint + "package/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        this.forms = response.data.datas;
        this.company=response.data.datas.company;
        //this.forms.companyId=response.data.datas.company;
        //this.forms.companyId.push(response.data.datas.company);
        //console.log(this.fetchCompany);
        // this.forms.companyId = {
        //   company_id: this.forms.company_id,
        //   name: this.forms.company.name,
        // };
        // this.form={ name: 'asep', usernmae:res.username };
      });
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      this.companyCode = this.detailUser.company_detail.code;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "PackageController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },

    backForm() {
      window.location.href = "/package";
    },

    submitData() {
      this.fade(true);
      this.forms.company_id = this.company.company_id;
      const baseURI =
        this.$settings.endPoint + "package/update/" + this.forms.package_id;
      this.$http
        .patch(baseURI, this.forms)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            this.success(response.data.datas.messages);

            //window.history.back();
            var params = this.$onRandom(this.forms.package_id);
            window.location.href ="/package/detail/" + params + "?status=200&msg=Successfully";
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.loadData(params[0]);
    this.fetchIt();
    this.loadCompany();
    this.loadCompanyNotLinc();
  },
};
</script>
<style scoped>
</style>