<template>
  <div class="row">
    <vue-good-table
      title="list-of-gps-erorrs-reporting"
      mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      :sort-options="{
        enabled: false,
      }"
      :totalRows="totalRecords"
      :line-numbers="true"
      :isLoading.sync="isLoading"
      :pagination-options="{
        enabled: true,
      }"
      :filterOptions="{
        enabled: false,
      }"
      :rows="rowsTable"
      :columns="columnsOrder"
    >
      <div slot="table-actions">
        <div class="vgt-clearfix">
          <button class="btn btn-success btn-xs" style="font-size: 10pt; margin-top:-7px" @click.prevent="TambahFormModal()">
            <i class="link-icon" data-feather="plus"></i>
            {{ $t("createNew") }}
          </button>
        </div>
      </div>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'driver_id'">
          {{ props.row.driver.driver_name}}
          {{ props.row.driver.hp1}}
        </span>
        <span v-else-if="props.column.field == 'control'">
          <!-- <a
            href="#"
            title="Detail"
            @click.prevent="detailData(props.index, props.row)"
          >
            <i class="mdi mdi-eye" style="font-size: 16px"></i>
          </a> -->
          <a
            href="#"
            title="Edit"
            @click.prevent="EditFormModal(props.row)"
          >
            <i class="mdi mdi-square-edit-outline" style="font-size: 16px"></i>
          </a>

          <a
            href="#"
            title="Delete"
            @click.prevent="deleteData(props.index, props.row)"
          >
            <i class="mdi mdi-trash-can-outline" style="font-size: 16px"></i>
          </a>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>

    <modal-gps-erorr-reporting  
      :showModal="showModalNow"
      :data="detailGpsError"
      :vehicle_no="vehicle_no"
      @closeModal="closeMyModal"
    ></modal-gps-erorr-reporting>

  </div>
</template>


<script>
import modalGpsErrorReporting from "@/components/modal/modalGpsErrorReporting.vue";

export default {
  name: "gpsErrorReportingComponent",
  components: {
    "modal-gps-erorr-reporting": modalGpsErrorReporting,

    // DirectionsRenderer,
  },
  props: {
    data: [],
  },
  data() {
    return {
      vehicle_no:this.$route.params.vehicle_no,
      detailGpsError:"",
      showModalNow: false,
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      rowsTable: [],
      columnsOrder: [
        {
          label: "Created At",
          field: "created_at",
        },

        {
          label: "Driver",
          field: "driver_id",
        },

        {
          label: "Error Type",
          field: "error_type",
        },

        {
          label: "Remark",
          field: "remark",
        },        

        {
          label: "Last Edit",
          field: "updated_at",
        },

        {
          label: "Control",
          field: "control",
        },
      ],
      //
    };
  },
  methods: {
    createData() {},
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    closeMyModal() {
      this.showModalNow = false;
      this.loadData(this.vehicle_no);
    },

    TambahFormModal() {
      this.detailGpsError = '';
      this.showModalNow = !this.showModalNow;
    },


    EditFormModal(data) {
      this.detailGpsError =data;
      this.showModalNow = !this.showModalNow;
    },

    deleteData(index, row, status) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI =
            this.$settings.endPointCt + "gps-reporting/delete/" + row.gps_reporting_id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              
              if (response.data.status === 200) {
                this.loadSuccess(response.data.datas.messages);
                this.loadData(this.$route.params.vehicle_no);
              } else {
                this.errors = response.data.errors;
                this.loadError(response.data.errors);
              }
            })
            .catch((error) => {
             
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.loadError(this.errors);
                } 
              }
            });
        }
      });
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    loadData(vehicle_no) {
      const baseURI =
        this.$settings.endPointCt + `gps-reporting?vehicle_no=` + vehicle_no;
      return this.$http.get(baseURI).then((response) => {
        this.rowsTable = response.data.datas.data;
        
        //console.log(this.rowsTable);
      });
    },

    loadError(pesan) {
      this.$swal({
        backdrop: true,
        title: "Warning",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },




    loadSuccess(pesan) {
      this.$swal({
        backdrop: true,
        title: "Success",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },
  },
  mounted() {
    this.loadData(this.vehicle_no);
  },
};
</script>
