<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="BbmAdd"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left: 25px">
                    Payable Rate Detail
                  </h6>
                  <button class="btn btn-default btn-sm float-end" @click="resetForm" type="button">
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form class="forms-sample" @submit.prevent="submitData" method="POST">
                    <div class="d-flex justify-content-between align-items-baseline mb-2"></div>
                    <div class="row mb-3">
                      <div class="col-sm-4">
                        <label for="customer_name " class="col-form-label">Customer</label>
                        <input type="text" class="form-control" id="customer_name" placeholder="Customer Code"
                          :value="headers.customer_name" disabled />
                        <div v-if="errors.customer_name">
                          <div v-for="error in errors.customer_name" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-sm-4">
                        <div class="mb-3">
                          <label for="period_name" class="col-form-label">Carier</label>
                          <input type="text" class="form-control" disabled
                            :value="forms.carrier_no + '-' + forms.carrier_name">
                          <!-- <v-select
                            :options="fetchCarier"
                            v-model="forms.carier"
                            return-object
                          ></v-select> -->
                          <div v-if="errors.period_name">
                            <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="mb-3">
                          <label for="period_name" class="col-form-label">Divison</label>
                          <input type="text" class="form-control" disabled :value="forms.division">

                          <div v-if="errors.period_name">
                            <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="mb-3">
                          <label for="period_name" class="col-form-label">Load Group</label>
                          <input type="text" class="form-control" disabled :value="forms.load_group">

                          <div v-if="errors.period_name">
                            <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-4">
                            <div class="mb-1">
                              <label for="period_name" class="col-form-label">Tier</label>
                              <input type="text" class="form-control" disabled :value="forms.tier">
                              <div v-if="errors.period_name">
                                <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="mb-1">
                              <label for="period_name" class="col-form-label">All Inclusive</label>
                              <input type="text" class="form-control" disabled :value="forms.all_inclusive">

                              <div v-if="errors.all_inclusive">
                                <div v-for="error in errors.all_inclusive" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="mb-1">
                              <label for="period_name" class="col-form-label">Round Trip</label>
                              <input type="text" class="form-control" disabled :value="forms.round_trip">

                              <div v-if="errors.round_trip">
                                <div v-for="error in errors.round_trip" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="mb-2">
                          <label for="period_name" class="col-form-label">Origin Location</label>
                          <input type="text" class="form-control" disabled :value="forms.origin_location">

                          <!-- <multiselect
                            @search-change="asyncLocation"
                            v-model="originLocation"
                            :options="fetchLocation"
                            label="name"
                            track-by="name"
                            placeholder="Please Select"
                          >
                            <span slot="noResult">-</span>
                          </multiselect> -->

                          <div v-if="errors.period_name">
                            <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="mb-2">
                          <label for="period_name" class="col-form-label">Origin Region</label>
                          <input type="text" class="form-control" disabled :value="forms.origin_region">

                          <!-- <multiselect
                            @search-change="asyncSearchRegion"
                            v-model="originRegion"
                            :options="fetchRegion"
                            label="region_code"
                            track-by="region_code"
                            placeholder="Please Select"
                          >
                            <span slot="noResult">-</span>
                          </multiselect> -->

                          <div v-if="errors.period_name">
                            <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="mb-2">
                          <label for="period_name" class="col-form-label">Destination Location</label>
                          <!-- <multiselect
                            @search-change="asyncLocation"
                            v-model="destLocation"
                            :options="fetchLocation"
                            label="name"
                            track-by="name"
                            placeholder="Please Select"
                          >
                            <span slot="noResult">-</span>
                          </multiselect> -->
                          <input type="text" class="form-control" disabled :value="forms.destination_location">

                          <div v-if="errors.period_name">
                            <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="mb-2">
                          <label for="period_name" class="col-form-label">Destination Region</label>
                          <input type="text" class="form-control" disabled :value="forms.destination_region">


                          <div v-if="errors.period_name">
                            <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="mb-3">
                          <label for="period_name" class="col-form-label">Equipment</label>
                          <!-- <v-select
                            :options="fetchCarier"
                            v-model="carier"
                            return-object
                          ></v-select> -->
                          <input type="text" class="form-control" disabled :value="forms.equipment">

                          <!-- <multiselect
                              @search-change="asyncSearchSKU"
                              v-model="equipment"
                              :options="fetchSku"
                              placeholder="Please Select"
                              track-by="type_truck"
                              label="type_truck"
                            >
                              <span slot="noResult">-</span>
                            </multiselect> -->
                          <div v-if="errors.period_name">
                            <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="mb-2">
                          <label for="period_name" class="col-form-label">Effective Date</label>
                          <!-- <date-range-picker
                            style="width: 100%"
                            :locale-data="locale"
                            v-model="effectiveDate"
                            :opens="opens"
                            :singleDatePicker="true"
                            :timePicker24Hour="true"
                            :timePicker="true"
                          >
                          </date-range-picker> -->
                          <input type="text" class="form-control" disabled :value="forms.effective_date">

                          <div v-if="errors.period_name">
                            <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="mb-2">
                          <label for="period_name" class="col-form-label">Expired Date</label>
                          <!-- <date-range-picker
                            style="width: 100%"
                            :locale-data="locale"
                            v-model="expiredDate"
                            :opens="opens"
                            :singleDatePicker="true"
                            :timePicker24Hour="true"
                            :timePicker="true"
                          >
                          </date-range-picker> -->
                          <input type="text" class="form-control" disabled :value="forms.expiration_date">

                          <div v-if="errors.period_name">
                            <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="mb-3">
                          <div class="row">
                            <div class="col-sm-6">
                              <label for="period_name" class="col-form-label">Currency</label>
                              <input type="text" class="form-control" disabled :value="forms.currency">
                              <div v-if="errors.period_name">
                                <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <label for="period_name" class="col-form-label">Cost Basis / Rate Basis</label>
                              <input type="text" class="form-control" disabled :value="forms.cost_basis">


                              <div v-if="errors.period_name">
                                <div v-for="error in errors.period_name" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-lg-6">
                        <label for="period_code" class="col-form-label">{{
                          $t("periodCodeTxt")
                        }}</label>
                        <input
                          type="text"
                          class="form-control"
                          id="period_code"
                          :placeholder="$t('periodCodeTxt')"
                          v-model="forms.period_code"
                          readonly
                        />
                        <div v-if="errors.period_code">
                          <div
                            v-for="error in errors.period_code"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <label for="price" class="col-form-label">{{
                          $t("priceTxt")
                        }}</label>
                        <input
                          type="number"
                          class="form-control"
                          id="price"
                          v-model="forms.price"
                          :placeholder="$t('priceTxt')"
                          required
                        />
                        <div v-if="errors.price">
                          <div
                            v-for="error in errors.price"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div> -->
                    </div>
                    <div class="row mb-3">
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                          <a class="nav-link active" id="truckload-tab" data-bs-toggle="tab" href="#truckload" role="tab"
                            aria-controls="truckload" aria-selected="true">Truck Load</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" id="accessorial-tab" data-bs-toggle="tab" href="#accessorial" role="tab"
                            aria-controls="accessorial" aria-selected="true">Accessorial</a>
                        </li>
                      </ul>
                      <div class="tab-content border border-top-0 p-3" id="myTabContent">
                        <div class="tab-pane fade show active" id="truckload" role="tabpanel"
                          aria-labelledby="truckload-tab">
                          <!-- Truck Load Tabs -->
                          <div class="row">
                            <div class="col-sm-4" v-if="false">
                              <div class="row mb-3">
                                <label class="col-sm-3 col-form-label">Pool</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control" placeholder="Pool" v-model="truckLoad.pool"
                                    disabled />
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-3 col-form-label">Loading</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control" placeholder="Loading"
                                    v-model="truckLoad.loading" disabled />
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-3 col-form-label">Unloading</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control" placeholder="Unloading"
                                    v-model="truckLoad.unloading" disabled />
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-3 col-form-label">Back to Pool</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control" placeholder="Back to Pool"
                                    v-model="truckLoad.back_to_pool" disabled />
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-4" v-if="false">
                              <div class="row mb-3">
                                <label class="col-sm-3 col-form-label">KM (Basic)</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control" placeholder="KM (Basic)"
                                    v-model="forms.km_basis" />
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-3 col-form-label">KM (Margin)</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control" placeholder="KM (Margin)"
                                    v-model="forms.km_margin" />
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-3 col-form-label">Rasio</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control" placeholder="Rasio" v-model="forms.rasio" />
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-3 col-form-label">Solar (Liter)</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control" placeholder="Solar (Liter)"
                                    v-model="forms.solar_liter" />
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <div class="row mb-3">
                                <label class="col-sm-4 col-form-label">Rate</label>
                                <div class="col-sm-8">
                                  <input type="text" class="form-control" placeholder="Solar (Rupiah)"
                                    v-model="forms.solar_rupiah" disabled />
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-4 col-form-label">Lead Time</label>
                                <div class="col-sm-8">

                                  <input type="text" class="form-control" placeholder="Lead Time"
                                    v-model="forms.lead_time" disabled />
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-4 col-form-label">Service Level</label>

                                <div class="col-sm-8">
                                  <input type="text" class="form-control" disabled :value="forms.service_level">

                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-4 col-form-label">No Intermediate Stop</label>
                                <div class="col-sm-8">
                                  <input type="text" class="form-control" disabled :value="forms.no_intermediate_stop">

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="accessorial" role="tabpanel" aria-labelledby="accessorial-tab">
                          <div class="row table-responsive">
                            <table class="table">
                              <thead>
                                <th>No</th>
                                <th>Enabled</th>
                                <th>Rate Lane Type</th>
                                <th>Rate Mode</th>
                                <th>Charge Code</th>
                                <th>Quantity</th>
                                <th>Rate</th>
                                <th>Min Rate</th>
                                <th>Max Rate</th>
                                <th>Weight Rating</th>
                                <th>Auto Apply</th>
                              </thead>
                              <tr v-for="(data, index) in accessorial" :key="data.charge_code">
                                <td>{{ index + 1 }}</td>
                                <td>{{ data.enabled }}</td>
                                <td>{{ data.rate_line_type }}</td>
                                <td>{{ data.mode }}</td>
                                <!-- <td>{{ data.charge_code }}</td> -->
                                <td>
                                  {{ data.charge_code }}
                                </td>
                                <td>{{ data.cost_qty }}</td>
                                <!-- <td>{{ data.rate }}</td> -->
                                <td>
                                  {{ data.rate }}
                                </td>
                                <td>{{ data.min }}</td>
                                <td>{{ data.max }}</td>
                                <!-- <td>{{ data.weight_rating }}</td> -->
                                <td>{{ data.weight_rating == 0 ? "No" : "Yes" }}</td>
                                <td>{{ data.auto_apply == 0 ? "No" : "Yes" }}</td>
                              </tr>
                              <tr v-if="accessorial.length == 0">
                                <td colspan="11" style="text-align: center">
                                  Empty Data
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <button class="btn btn-warning text-black fw-bolder btn-icon-text" @click="backForm" type="button">
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>

                      &nbsp;&nbsp;&nbsp;
                      <button class="btn btn-primary float-end btn-sm" type="submit">
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>

                      <!-- <button
                        disabled
                        class="btn btn-success float-end btn-sm"
                        style="margin-right: 5px"
                        @click="calculate()"
                      >
                        <i class="mdi mdi-calculator"></i>
                        Calculate
                      </button> -->
                    </div>
                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import moment from "moment";

export default {
  name: "PayableRateAdd",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      headers: {},
      carier: null,
      fetchCarier: [],
      division: null,
      fetchDivision: [],
      loadGroup: null,
      fetchLoadGroup: ["All"],
      fetchBasis: [],
      fetchTier: [],
      fetchYN: ["Yes", "No"],
      fetchServiceLevel: ["TL STANDARD"],
      fetchCurrency: [],
      truckLoad: {},
      fetchLocation: [],
      fetchRegion: [],
      originLocation: null,
      originRegion: null,
      destLocation: null,
      destRegion: null,
      fetchSku: [],
      equipment: null,
      accessorial: [],
      locale: {
        format: "dd mmm yyyy H:MM:ss",
      },
      effectiveDate: {
        startDate: null,
        endDate: null,
      },
      expiredDate: {
        startDate: null,
        endDate: null,
      },
      opens: "center",
      permision_role: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      fetchCompany: [],
      fetchCompanyNotLinc: [],
      forms: {
      },
      company: [],
    };
  },
  watch: {},
  methods: {
    loadLocation() {
      const baseURI =
        this.$settings.endPoint +
        `location?status=ENABLED&company_name=` +
        this.headers?.customer_name;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchLocation = response.data.datas.data;
        this.fade(false);
      });
    },
    asyncLocation(ev) {
      const baseURI =
        this.$settings.endPoint +
        "location?status=ENABLED&company_name=" +
        this.headers?.customer_name +
        "&location_name=" +
        ev;
      this.fetchLocation = [];
      return this.$http.get(baseURI).then((response) => {
        // console.log("search : " + ev);
        // console.log(response.data.datas.data);
        this.fetchLocation = response.data.datas.data;
      });
    },
    loadRegion() {
      const baseURI = this.$settings.endPoint + `region?status=ENABLED`;
      this.fetchRegion = [];
      return this.$http.get(baseURI).then((response) => {
        this.fetchRegion = response.data.datas.data;
      });
    },
    asyncSearchRegion(ev) {
      const baseURI = this.$settings.endPoint + `region?region_code=${ev}&status=ENABLED`;
      this.fetchRegion = [];
      return this.$http.get(baseURI).then((response) => {
        this.fetchRegion = response.data.datas.data;
      });
    },
    loadCarier() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=carer`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchCarier = response.data.datas;
      });
    },
    loadTier() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmtier`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTier = response.data.datas;
      });
    },
    loadSku() {
      const baseURI = this.$settings.endPointCt + `type-truck`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchSku = response.data.datas.data;
        this.fade(false);
      });
    },
    asyncSearchSKU(ev) {
      const baseURI = this.$settings.endPointCt + `type-truck?type_truck=` + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchSku = response.data.datas.data;
      });
    },
    loadBasis() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmbas`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchBasis = response.data.datas;
      });
    },
    loadCurrency() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmcur`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchCurrency = response.data.datas;
      });
    },
    loadDivision() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmdiv`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDivision = response.data.datas;
      });
    },
    // loadDetail(){
    //   var params = this.$onBehind(this.$route.params.detailId);
    //   const baseURI = this.$settings.endPoint + "rm-payable-rate-header/detail-detail/"+params[0];
    //   return this.$http
    //     .get(baseURI)
    //     .then((response) => {
    //       console.log(response);
    //     });

    // },

    getAllFormatedDetail(index, name_field, value) {

      let isNegative = value < 0;
      let absoluteValue = Math.abs(value);

      //console.log(this.accessorial[index][name_field]);

      let formatted = parseFloat(absoluteValue).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      if (formatted.indexOf(',') === -1) {
        formatted += ',00';
      } else {
        let decimalPart = formatted.split(',')[1];
        if (decimalPart.length === 1) {
          formatted += '0';
        }
      }

      formatted = isNegative ? '-' + formatted : formatted;
      console.log(formatted);
      this.accessorial[index].rate = formatted;
      //this.accessorial[index].rate=formatted;

      // return  this.accessorial[index];

    },

    getAllFormated(name_field, value) {

      if (value != null) {

        let formatted = parseFloat(value).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        if (formatted.indexOf(',') === -1) {
          formatted += ',00';
        } else {
          let decimalPart = formatted.split(',')[1];
          if (decimalPart.length === 1) {
            formatted += '0';
          }
        }


        this.forms[name_field] = formatted;

      } else {
        this.forms[name_field] = 0
      }


      return this.forms[name_field]

    },

     getAllFormatedDetail2(index, name_field, value) {

      let isNegative = value < 0;
      let absoluteValue = Math.abs(value);

      //console.log(this.accessorial[index][name_field]);

      let formatted = parseFloat(absoluteValue).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      if (formatted.indexOf(',') === -1) {
        formatted += ',00';
      } else {
        let decimalPart = formatted.split(',')[1];
        if (decimalPart.length === 1) {
          formatted += '0';
        }
      }

      formatted = isNegative ? '-' + formatted : formatted;

      return formatted
      //this.accessorial[index].rate=formatted;

      // return  this.accessorial[index];

    },


    getSolarLiter(ev) {


      this.forms.solar_liter = parseFloat(ev).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      console.log(this.forms.solar_liter);
      return this.forms.solar_liter

    },




    loadData() {
      var params = this.$onBehind(this.$route.params.detailId);
      // console.log(params);
      const baseURI = this.$settings.endPoint + "rm-payable-rate-header/detail-detail/" + params[0];
      return this.$http
        .get(baseURI)
        .then((response) => {
          // &expired_date=${this.serverParams.columnFilters.expired_date}
          this.headers = response.data.datas.header;
          this.forms = response.data.datas;
          //this.forms.solar_liter = this.forms.solar_liter.toFixed(2);
          this.accessorial = response.data.datas.accessorial;
          console.log(this.accessorial);
          // this.getAllFormated('solar_rupiah', this.forms.solar_rupiah)
          // this.getAllFormated('km_basis', this.forms.km_basis)
          // this.getAllFormated('km_margin', this.forms.km_margin)
          // this.getAllFormated('solar_liter', this.forms.solar_liter)
          this.getSolarLiter(this.forms.solar_liter);
          
          this.forms.all_inclusive=this.forms.all_inclusive==1 ? 'Yes': 'No';
          
          // for (let index = 0; index < this.accessorial.length; index++) {
          // // console.log(this.forms.accessorial[index].rate)
          //   this.accessorial[index].rate = this.getAllFormatedDetail2(index, 'rate', this.accessorial[index].rate);

          // }

        });
    },
    resetForm() {
      this.forms.period_name = "";
      this.forms.price_gap = "";
      this.forms.price = "";
      this.forms.period_code = "";
      this.effectiveDate = {
        startDate: null,
        endDate: null,
      };
      this.expiredDate = {
        startDate: null,
        endDate: null,
      };
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      this.companyCode = this.detailUser.company_detail.code;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RmFuelMasterController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      this.permision_role = rolePermision;
    },

    backForm() {
      window.location.href = "/payable-rate/rute";
    },

    submitData() {
      // this.forms.company_id = this.company.company_id;
      this.forms.effective_date = moment(this.effectiveDate.startDate).format(
        "YYYY-MM-DD"
      );
      this.forms.expired_date = moment(this.expiredDate.startDate).format(
        "YYYY-MM-DD"
      );
      this.forms.origin_location = this.originRegion?.location_code ? this.originRegion?.location_code : "";
      this.forms.destination_location = this.destLocation?.location_code ? this.destLocation?.location_code : "";
      this.forms.origin_region = this.originRegion?.region_code ? this.originRegion?.region_code : "";
      this.forms.destination_region = this.destRegion?.region_code ? this.destRegion?.region_code : "";
      this.forms.equipment = this.equipment?.type_truck ? this.equipment?.type_truck : "";
      let carier = this.forms.carier;
      if (carier) {
        carier = carier.split("-");
        this.forms.carrier_no = carier[0];
        this.forms.carrier_name = carier[1];
      }
      var params = this.$onBehind(this.$route.params.id);
      this.forms.rm_payable_rate_header_id = params[0];
      // console.log(this.forms);
      this.fade(true);
      const baseURI = this.$settings.endPoint + "rm-payable-rate-header/create-detail";
      this.$http
        .post(baseURI, this.forms)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            //console.log(response.data.datas.user_id);
            var params = this.$onRandom(response.data.datas.rm_fuel_master_id);
            window.location.href =
              "/master-bbm/detail/" + params + "?status=200&msg=Successfully";
            // this.success('Berhasil');
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              if (this.errors.message) {
                this.error(this.errors.message);
              } else {
                this.resultError(error.response.data.errors);
              }
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },


    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () { },
  mounted() {
    this.fetchIt();
    this.loadCarier();
    this.loadDivision();
    this.loadLocation();
    this.loadRegion();
    this.loadTier();
    this.loadCurrency();
    this.loadBasis();
    this.loadSku();
    this.loadData();
    // this.loadCompany();
    // this.loadCompanyNotLinc();
  },
};
</script>
<style scoped></style>
