<template>
  <div :class="{ 'blur-content': isLoading }">
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="CompanyIndex"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div>
              <h4 class="mb-12 mb-md-0"></h4>
            </div>

            <div class="col-lg-12 col-xl-12 stretch-card">
              <div class="card">
                <div class="card-header">
                  <h4 style="margin-right: 5px" class="float-start">
                    {{ $t("companyTxt") }}
                  </h4>
                  <button
                    v-if="permision_role.method_create"
                    class="btn btn-primary float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="createData()"
                  >
                    <i class="link-icon" data-feather="plus"></i>
                    {{ $t("createNew") }}
                  </button>
                  <button
                    v-if="permision_role.method_upload"
                    class="btn btn-warning float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="uploadData()"
                  >
                    <i class="link-icon" data-feather="upload"></i>
                    {{ $t("uploadData") }} .xlsx
                  </button>
                  <button
                    v-if="permision_role.method_read"
                    class="btn btn-success float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="downloadData()"
                  >
                    <i class="link-icon" data-feather="download"></i>
                    {{ $t("downloadData") }} .xlsx
                  </button>
                </div>

                <div class="card-body">
                  <vue-good-table
                    title="list-user"
                    mode="remote"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    :select-options="{
                      enabled: true,
                      selectOnCheckboxOnly: true,
                      selectionText: $t('data_selected'),
                      clearSelectionText: $t('clear'),
                    }"
                    @on-selected-rows-change="selectionChanged"
                    :totalRows="totalRecords"
                    :line-numbers="true"
                    :isLoading.sync="isLoading"
                    :pagination-options="{
                      enabled: true,
                    }"
                    :rows="rows"
                    :columns="columns"
                  >
                    <div slot="selected-row-actions">
                      <button
                        class="btn btn-success btn-xs"
                        style="margin-right: 20px"
                        @click="sendToBlujay"
                      >
                        <i class="mdi mdi-send-outline"></i>
                        {{ $t("sendToBlujay") }}
                      </button>
                    </div>
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'actions'">
                        <a
                          href="#"
                          v-if="permision_role.method_read"
                          title="Detail"
                          @click.prevent="detailData(props.index, props.row)"
                        >
                          <i class="mdi mdi-eye" style="font-size: 16px"></i>
                        </a>
                        <a
                          href="#"
                          v-if="permision_role.method_update"
                          title="Edit"
                          @click.prevent="editData(props.index, props.row)"
                        >
                          <i
                            class="mdi mdi-square-edit-outline"
                            style="font-size: 16px"
                          ></i>
                        </a>

                        <a
                          href="#"
                          v-if="permision_role.method_delete"
                          title="Delete"
                          @click.prevent="deleteData(props.index, props.row)"
                        >
                          <i
                            class="mdi mdi-trash-can-outline"
                            style="font-size: 16px"
                          ></i>
                        </a>

                        <a
                          href="#"
                          v-if="permision_role.method_custom1 && props.row.status=='active'"
                          title="Deactived"
                          @click.prevent="showRejected(props.row.company_id)"
                        >

                          <i class="mdi mdi-close-circle" style="font-size: 16px; color:#ab0635"></i>
                        </a>

                        <a
                          href="#"
                          v-if="permision_role.method_custom1 && props.row.status!='active'"
                          title="Actived"
                          @click.prevent="showApprove(props.row.company_id)"
                        
                        >
                          <i class="mdi mdi-checkbox-marked-circle" style="font-size: 16px; color:blue"></i>
                        </a>

                      </span>
                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <modal-approve-company
              :showModal="showModal"
              :data="modalData"
              @closeModal="closeMyModal"
        ></modal-approve-company>


        <modal-reject-companny
              :showModal="showModalReject"
              :data="modalData"
              @closeModal="closeMyModal"
        ></modal-reject-companny>

        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import modalApproveCompany from "@/components/modal/modalApproveCompany";
import modalRejectCompany from "@/components/modal/modalRejectCompany";

export default {
  name: "CompanyIndex",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "modal-approve-company":modalApproveCompany,
    "modal-reject-companny":modalRejectCompany
  },
  data() {
    return {
      showModal: false,
      showModalReject:false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      modalData:[],
      errors: [],
      permision_role: [],
      langs: ["id", "en"],
      detailUser: [],
      detailDivisi: [],
      rowsSelection:[],
      companyIds:[],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [
          {
          label: "SoB",
          field: "sob",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By SoB", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: this.$t("company_type_txt"),
          field: "company_type",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t("company_type_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Company Code",
          field: "code",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Company Code", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Name",
          field: "name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Email",
          field: "email",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Email", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Sap Code",
          field: "sap_code",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Sap Code", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "SF Code",
          field: "sf_code",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By SF Code", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Status",
          field: "status",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Status", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: ["active", "deactived"], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
         {
          label: "Country",
          field: "country",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Country", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Action",
          field: "actions",
          sortable: false,
        },
      ],
      rows: [],
    };
  },
  watch: {},
  methods: {
    downloadData() {
      const baseURI = this.$settings.endPoint + "/company";
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = "download_" + CurrentDate + ".xlsx";

      //var columnFilters = this.serverParams.columnFilters;

      return this.$http
        .get(
          baseURI +
            `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&code=${this.serverParams.columnFilters.code}&name=${this.serverParams.columnFilters.name}&company_type=${this.serverParams.columnFilters.company_type}&email=${this.serverParams.columnFilters.email}&sap_code=${this.serverParams.columnFilters.sap_code}&download=download`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },

    closeMyModal(){
      this.showModal=false;
      this.showModalReject=false
      this.loadItems();
    },


    showApprove(id){
      this.modalData=[];
      this.showModal=true;
      this.modalData.push(id);
    },

    showRejected(id){
      this.modalData=[];
      this.showModalReject=true;
      this.modalData.push(id);
    },


    // downloadData() {
    //   this.fade(true);
    //   var baseURI = this.$settings.endPoint + "/user/download";
    //   var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
    //   var columnFilters = this.serverParams.columnFilters;
    //   var role = columnFilters["role.name"];
    //   var company_code = columnFilters["company_detail.code"];

    //   var sendData = {
    //     username: this.serverParams.columnFilters.username,
    //     name: this.serverParams.columnFilters.name,
    //     email: this.serverParams.columnFilters.email,
    //     level: this.serverParams.columnFilters.level,
    //     status: this.serverParams.columnFilters.status,
    //     role:role,
    //     company_code: company_code,
    //     file_name: "download_" + CurrentDate + ".xlsx",
    //   };
    //   this.$http({
    //     url: baseURI,
    //     method: "GET",
    //     data: sendData,
    //     responseType: "blob",
    //   })
    //     .then((response) => {
    //       this.errors = [];
    //       var filename = sendData.file_name;

    //       var fileURL = window.URL.createObjectURL(new Blob([response.data]));
    //       var fileLink = document.createElement("a");

    //       fileLink.href = fileURL;
    //       fileLink.setAttribute("download", filename);
    //       document.body.appendChild(fileLink);
    //       fileLink.click();
    //       this.fade(false);
    //     })
    //     .catch((error) => {
    //       if (error.response) {
    //         if (error.response.status === 422) {
    //           this.errors = { message: ["File Not Found"] };
    //           this.error("File Not Found");
    //         } else if (error.response.status === 500) {
    //           this.$router.push("/server-error");
    //         } else {
    //           this.$router.push("/page-not-found");
    //         }
    //       }
    //     });
    // },
    createData() {
      window.location.href = "/company/add";
    },

    uploadData() {
      window.location.href = "/company/upload";
    },

    editData(index, row) {
      var params = this.$onRandom(row.company_id);
      window.location.href = "/company/edit/" + params;
    },

    detailData(index, row) {
      var params = this.$onRandom(row.company_id);
      window.location.href = "/company/detail/" + params;
    },

    deleteData(index, row, status) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI =
            this.$settings.endPoint + "company/delete/" + row.company_id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },
    selectionChanged(ev) {
        this.rowsSelection = [];
    
      this.rowsSelection = ev.selectedRows;
      this.companyIds=[];
      this.rowsSelection.forEach((item) => {
        this.companyIds.push(item.company_id);
      });

    },
    sendToBlujay(){
       this.$swal({
            title: this.$t("areYouSure"),
            text: this.$t("yourDataSend"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!",
          }).then((result) => {
            if (result.value) {
              // this.loadingSubmiting();
              this.fade(true);
              let data = {
                
                company_ids: this.companyIds,
              }
              const baseURI =
                this.$settings.endPoint + "company/send-to-blujay";
              this.$http
                .post(baseURI, data, { timeout: 30000 })
                .then((response) => {
                  if (response.data.status === 200) {
                    this.success(response.data.datas.messages);
                    this.loadItems();
                    this.isloading = false
                  } else {
                    this.errors = response.data.errors;
                    this.resultError(response.data.errors);
                  }
                }).catch(error => {
                  this.isLoading = false;
                  if (error.code == "ECONNABORTED") {
                    this.error(this.$t("check_internet_connection_txt"));
                  } else {

                    if (error.response) {
                      if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                        this.resultError(error.response.data.errors);
                      } else if (error.response.status === 500) {
                        this.$router.push('/server-error');
                      } else if (error.response.status === 401) {
                        this.$router.push("/authorized-error");
                      } else {
                        this.$router.push('/page-not-found');
                      }
                    }

                  }

                });
            }
          });

    },
    // load items is what brings back the rows from server
    loadItems() {
      const baseURI = this.$settings.endPoint + "company";
      this.isLoading = true;
      //var columnFilters = this.serverParams.columnFilters;

      return this.$http
        .get(
          baseURI +
            `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&code=${this.serverParams.columnFilters.code}&name=${this.serverParams.columnFilters.name}&company_type=${this.serverParams.columnFilters.company_type}&email=${this.serverParams.columnFilters.email}&sap_code=${this.serverParams.columnFilters.sap_code}`,
          // `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&code=${this.serverParams.columnFilters.code}&name=${this.serverParams.columnFilters.name}&status=${this.serverParams.columnFilters.status}&company_type=${this.serverParams.columnFilters.company_type}`,
          { timeout: 10000 }
        )
        .then((response) => {
          this.isLoading = false;
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
        })
        .catch((error) => {
          if (error.code == "ECONNABORTED") {
            this.error("Please Check Your Internet Connection");
          }
        });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "CompanyController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },

    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },
  },
  events: {},
  created: function () {},
  mounted() {
    this.fetchIt();
    this.loading();
  },
};
</script>
<style scoped>
.blur-content {
  filter: blur(5px);
}
</style>
