<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading">
      {{ $t("loadingTxt") }}...
    </div>
    <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
      <!--begin::sidebar-->
      <sidebar-component classMenu="Users"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 25px"
                  >
                    Detail {{ $t("txtUsers") }}
                  </h6>
                  <button
                    class="btn btn-light float-end"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <i class="link-icon" data-feather="info"></i>
                    Audit Info
                  </button>

                  <div
                    class="row mb-3 collapse"
                    id="collapseExample"
                    style="padding-left: 20px"
                  >
                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created By:</label>
                      <p class="text-muted">{{ this.forms.create_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created At:</label>
                      <p class="text-muted">{{ this.forms.created_at }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated By:</label>
                      <p class="text-muted">{{ this.forms.update_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated At:</label>
                      <p class="text-muted">{{ this.forms.updated_at }}</p>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="d-flex justify-content-between align-items-baseline mb-2"
                    ></div>
                    
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("user_type_txt") }}</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="user_type"
                          :value="`${forms.user_type} | ${forms.user_type_detail?.description || ''}`"
                          readonly
                        />
                        <div v-if="errors.name">
                          <div
                            v-for="error in errors.name"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("nameTxt") }}</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="nameTxt"
                          :placeholder="$t('nameTxt')"
                          v-model="forms.name"
                          readonly
                        />
                        <div v-if="errors.name">
                          <div
                            v-for="error in errors.name"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("usernameTxt") }}</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="usernameTxt"
                          :placeholder="$t('usernameTxt')"
                          v-model="forms.username"
                          readonly
                        />
                        <div v-if="errors.username">
                          <div
                            v-for="error in errors.username"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("emailAddress") }}</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="email"
                          class="form-control"
                          id="emailAddress"
                          :placeholder="$t('emailAddress')"
                          v-model="forms.email"
                          readonly
                        />
                        <div v-if="errors.email">
                          <div
                            v-for="error in errors.email"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("customer_code") }}</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <select
                          class="form-control"
                          name="company_id"
                          id="company_id"
                          v-model="forms.company_id"
                          disabled
                        >
                          <option
                            v-for="(a, index) of fetchCompany"
                            :value="a.company_id"
                            :key="index"
                          >
                            {{ a.code }}
                          </option>
                        </select>

                        <div v-if="errors.company_id">
                          <div
                            v-for="error in errors.company_id"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Customer Name -->
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="customerName" class="col-form-label" style="padding-left: 25px">{{ $t("customer_name") }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="customerName"
                          v-model="forms.customer_name"
                          readonly
                        />
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("roleTxt") }}</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <select
                          class="form-control"
                          name="role_id"
                          id="role_id"
                          v-model="forms.role_id"
                          disabled
                        >
                          <option
                            v-for="(a, index) of fetchRole"
                            :value="a.role_id"
                            :key="index"
                          >
                            {{ a.name }}
                          </option>
                        </select>

                        <div v-if="errors.roleId">
                          <div
                            v-for="error in errors.roleId"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Row 1: Department -->
                    <div class="row mb-3" v-if="forms.user_type === 'MOPS'">
                      <div class="col-lg-2">
                        <label for="department" class="col-form-label" style="padding-left: 25px">{{ $t("department") }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="department" v-model="forms.department" placeholder="$t('department')" disabled/>
                      </div>
                    </div>

                    <!-- Vehicle No (Conditional for MDRV) -->
                    <div class="row mb-3" v-if="forms.user_type === 'MDRV'">
                      <div class="col-lg-2">
                        <label for="vehicle_no" class="col-form-label" style="padding-left: 25px">{{ $t("vehicle_no") }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="vehicle_no" v-model="forms.vehicle_no" readonly />
                      </div>
                    </div>

                    <!-- Carrier -->
                    <div class="row mb-3" v-if="forms.user_type === 'MDRV'">
                      <div class="col-lg-2">
                        <label for="carrier" class="col-form-label" style="padding-left: 25px">{{ $t("carrier") }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="carrier" v-model="forms.carrier" readonly />
                      </div>
                    </div>

                    <!-- Driver NMK (Conditional for MDRV) -->
                    <div class="row mb-3" v-if="forms.user_type === 'MDRV'">
                      <div class="col-lg-2">
                        <label for="driver_nmk" class="col-form-label" style="padding-left: 25px">{{ $t("driver_nmk") }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="driver_nmk" v-model="forms.driver_nmk" readonly />
                      </div>
                    </div>

                    <!-- User NMK List (Tabel) -->
                    <div class="row mb-3" v-if="(forms.user_nmk_list && forms.user_nmk_list.length > 0) && (forms.user_type === 'MDRV' || forms.user_type === 'MOPS')">
                      <div class="col-lg-2">
                        <label for="user_nmk" class="col-form-label" style="padding-left: 25px">{{ $t("driver_id") }}</label>
                      </div>
                      <div class="col-lg-8">
                        <table class="table table-hover custom-table">
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>NMK</th>
                              <th>Driver Number</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(nmk, index) in forms.user_nmk_list" :key="nmk.m_nmk_id">
                              <td>{{ index + 1 }}</td>
                              <td>{{ nmk.nmk }}</td>
                              <td>{{ nmk.driver_id }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="card-footer">
                      <button
                        class="btn btn-warning text-black fw-bolder btn-icon-text"
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>

                      <a
                        v-if="permision_role.method_delete"
                        class="btn btn-danger text-white fw-bolder btn-icon-text float-end"
                        @click="deleteData()"
                      >
                        <i class="link-icon" data-feather="trash"></i>
                        {{ $t("deleteMess") }}
                      </a>

                      <a
                        v-if="permision_role.method_update"
                        class="btn btn-primary me-2 text-white fw-bolder btn-icon-text float-end"
                        @click="editData()"
                      >
                        <i class="link-icon" data-feather="edit"></i>
                        {{ $t("editMess") }}
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";

export default {
  name: "UsersDetail",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      fetchRole: [],
      fetchCompany: [],
      permision_role: [],
      forms: [],
    };
  },
  watch: {},
  methods: {
    resetForm() {
      this.forms.name = "";
      this.forms.username = "";
      this.forms.password = "";
      this.forms.email = "";
      this.forms.company_id = "";
      this.forms.role_id = "";
    },

    cek_message(status, msg) {
      if (status == "200") {
        this.success(msg);
      }
    },

    editData() {
      var params = this.$route.params.id;
      window.location.href = "/users/edit/" + params;
    },

    deleteData() {
      var id = this.$onBehind(this.$route.params.id);
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI = this.$settings.endPoint + "users/delete/" + id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                window.location.href = "/users";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    backForm() {
      window.location.href = "/users";
    },

    loadData(id) {
      const baseURI = this.$settings.endPoint + "user/detail/" + id;
      this.isLoading = true;
      return this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          //this.forms = response.data.datas;
          const userMapping = response.data.datas.user_mapping_linc_mobile || {};
          this.forms = {
            ...response.data.datas,
            customer_name: response.data.datas.company_detail.name || '',
            department: userMapping.department || '',
            driver_nmk: userMapping.driver_nmk || '',
            vehicle_no: userMapping.vehicle_no || '',
            carrier: userMapping.carrier || '',
            user_nmk_list: userMapping.user_nmk || []
          };
          this.fetchRole = [response.data.datas.role];
          this.fetchCompany = [response.data.datas.company_detail];
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },

    submitData() {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourData"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          this.fade(true);
          // let params = this.$onBehind(this.$route.params.id);
          // let formData = new FormData();
          // formData.append("name", this.forms.name);
          // formData.append("username", this.forms.username);
          // formData.append("password", this.forms.password);
          // formData.append("email", this.forms.email);
          // formData.append("company_id", this.forms.company_id);
          // formData.append("role_id", this.forms.role_id);
          // formData.append("status", 'active');

          const baseURI =
            this.$settings.endPoint + "user/update/" + this.forms.user_id;
          this.$http
            .patch(baseURI, this.forms)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.resetForm();
                this.errors = [];
                this.success(response.data.datas.messages);

                //window.history.back();
                var params = this.$onRandom(this.forms.user_id);
                window.location.href = "/users/detail/" + params;
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
              this.resetForm();
            });
        }
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
    
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "UserController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.cek_message(this.$route.query.status, this.$route.query.msg);
    this.fetchIt();
    this.loadData(params[0]);
  },
};
</script>
<style scoped>
.blur-content {
  filter: blur(5px);
  pointer-events: none;
}
.custom-table {
  text-align: center;
}

.custom-table thead {
  background-color: #ab0635;
  color: white;
}

.custom-table th,
.custom-table td,
.custom-table tr {
  background-color: #ab0635;
  color: white;
}

.custom-table tbody tr:hover {
  background-color: #ab0635;
  color: white;
}
</style>