import { render, staticRenderFns } from "./InformasiBongkarEdit.vue?vue&type=template&id=4a5d871d&scoped=true"
import script from "./InformasiBongkarEdit.vue?vue&type=script&lang=js"
export * from "./InformasiBongkarEdit.vue?vue&type=script&lang=js"
import style0 from "./InformasiBongkarEdit.vue?vue&type=style&index=0&id=4a5d871d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a5d871d",
  null
  
)

export default component.exports