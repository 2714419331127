<template>
    <div>
        <div class="loader" v-if="this.isLoading"></div>
        <div class="loadertext" v-if="this.isLoading"> {{ $t('loadingTxt') }}...</div>
        <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
            <!--begin::sidebar-->
            <sidebar-component classMenu="DashboardUtility"></sidebar-component>
            <!--end::sidebar-->

            <div class="page-wrapper">
                <!--begin::navbar-->
                <navbar-component></navbar-component>
                <!--end::navbar-->
                <!--begin::content-->
                <div class="page-content">
                    <div class="
                d-flex
                justify-content-between
                align-items-center
                flex-wrap
                grid-margin
              ">
                        <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">

                            <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
                                <div class="col-lg-5 col-xl-5 col-sm-5 col-xs-5 col-md-5">
                                    <h4 class="mb-3 mb-md-0">Utility Dashboard Report</h4>
                                    <br />
                                    <h6 class="mb-3 mb-md-0">Based On Branch : {{ branchName }}</h6>

                                </div>

                                <div class="col-lg-7 col-xl-7 col-sm-7 col-xs-7 col-md-7">
                                    <div class="d-flex flex-wrap float-end">
                                        <div class="input-group flatpickr wd-200 me-2 mb-2 mb-md-0" id="dashboardDate">
                                            <date-range-picker style="width: 100%" @change="pickupChange"
                                                control-container-class="form-control" :locale-data="locale"
                                                v-model="dateFilter" :opens="opens" :singleDatePicker="false"
                                                :timePicker24Hour="true" :timePicker="true" :showWeekNumbers="false">
                                            </date-range-picker>
                                        </div>
                                        <div class="input-group flatpickr wd-200 me-2 mb-2 mb-md-0" id="pickBranch">
                                            <multiselect v-model="type_truck" :options="fetchTruck" :multiple="true"
                                                :close-on-select="true" :clear-on-select="true" :preserve-search="true"
                                                placeholder="Pick some Type Truck" label="type_truck"
                                                track-by="type_truck" :preselect-first="false"
                                                @select="onSelectTypeTruck" @remove="onUnselectTypeTruck">
                                                <template slot="selection" slot-scope="{ values, isOpen }"><span
                                                        class="multiselect__single" v-if="values.length && !isOpen">
                                                        {{ values.length }} Type truck selected</span></template>
                                            </multiselect>
                                        </div>

                                        <div class="input-group flatpickr wd-200 me-2 mb-2 mb-md-0" id="pickBranch">
                                            <multiselect v-model="branch" :options="fetchBranch" :multiple="true"
                                                :close-on-select="true" :clear-on-select="true" :preserve-search="true"
                                                placeholder="Pick some Branch" label="branch_name"
                                                track-by="branch_name" :preselect-first="false" @select="onSelectBranch"
                                                @remove="onUnselectBranch">
                                                <template slot="selection" slot-scope="{ values, isOpen }"><span
                                                        class="multiselect__single" v-if="values.length && !isOpen">
                                                        {{ values.length }} Branch selected</span></template>
                                            </multiselect>
                                        </div>

                                        <button type="button" style="max-height: 40px;" @click.prevent="getData()"
                                            class="btn btn-primary btn-icon-text mb-2 mb-md-0 btn-block btn-floating">
                                            GO
                                        </button>
                                        <span style="width:5px"> </span>
                                        <button v-if="permision_role.method_update"
                                            class="btn btn-warning btn-icon-text btn-block"
                                            style="margin-right: 5px;max-height: 40px;"
                                            @click.prevent="detailData()">Detail Data
                                        </button>
                                        <button v-if="permision_role.method_read"
                                            class="btn btn-success btn-icon-text btn-block"
                                            style="margin-right: 5px;max-height: 40px;"
                                            @click.prevent="downloadSummary()">Download
                                        </button>
                                    </div>
                                </div>


                            </div>
                            <div class="card">

                                <div class="card-body">

                                    <div class="row mb-3">

                                        <div class="col-lg-6">
                                            Availibility
                                            <LineChartGenerator :chart-options="chartOptions"
                                                :chart-data="chartDataAvailibility" :chart-id="chartId"
                                                :dataset-id-key="datasetIdKey" :plugins="plugins"
                                                :css-classes="cssClasses" :styles="myStyles" :width="width"
                                                :height="height" />

                                            <div v-if="this.availableChartDataKeSatu"
                                                style="text-align: center; left: 0; top: 100px; z-index: 20;color: black;">
                                                <b>{{ $t("no_data_available_txt") }}</b>
                                            </div>

                                            <div class="progress blue">
                                                <span class="progress-left">
                                                    <span class="progress-bar"></span>
                                                </span>
                                                <span class="progress-right">
                                                    <span class="progress-bar"></span>
                                                </span>
                                                <div class="progress-value">AVG {{ avg_availibility }}%</div>
                                            </div>

                                        </div>

                                        <div class="col-lg-6">
                                            No Driver
                                            <LineChartGenerator :chart-options="chartOptions"
                                                :chart-data="chartDataNoDriver" :chart-id="chartId"
                                                :dataset-id-key="datasetIdKey" :plugins="plugins"
                                                :css-classes="cssClasses" :styles="myStyles" :width="width"
                                                :height="height" />

                                            <div v-if="this.availableChartDataKeSatu"
                                                style="text-align: center; left: 0; top: 100px; z-index: 20;color: black;">
                                                <b>{{ $t("no_data_available_txt") }}</b>
                                            </div>

                                            <div class="progress blue">
                                                <span class="progress-left">
                                                    <span class="progress-bar"></span>
                                                </span>
                                                <span class="progress-right">
                                                    <span class="progress-bar"></span>
                                                </span>
                                                <div class="progress-value">AVG {{ avg_no_driver }}%</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <span style="height:80px;">
                                            <hr />
                                        </span>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-lg-6">
                                            Service
                                            <!-- <figure class="mb-12" style="transform: rotate(90deg);"> -->
                                            <LineChartGenerator :chart-options="chartOptions"
                                                :chart-data="chartDataService" :chart-id="chartId"
                                                :dataset-id-key="datasetIdKey" :plugins="plugins"
                                                :css-classes="cssClasses" :styles="myStyles" :width="width"
                                                :height="height" />

                                            <div v-if="this.availableChartDataKeSatu"
                                                style="text-align: center; left: 0; top: 100px; z-index: 20;color: black;">
                                                <b>{{ $t("no_data_available_txt") }}</b>
                                            </div>

                                            <div class="progress blue">
                                                <span class="progress-left">
                                                    <span class="progress-bar"></span>
                                                </span>
                                                <span class="progress-right">
                                                    <span class="progress-bar"></span>
                                                </span>
                                                <div class="progress-value">AVG {{ avg_service }}%</div>
                                            </div>

                                        </div>

                                        <div class="col-lg-6">
                                            Idle Cap
                                            <!-- <figure class="mb-12" style="transform: rotate(90deg);"> -->
                                            <LineChartGenerator :chart-options="chartOptions"
                                                :chart-data="chartDataIdleCap" :chart-id="chartId"
                                                :dataset-id-key="datasetIdKey" :plugins="plugins"
                                                :css-classes="cssClasses" :styles="myStyles" :width="width"
                                                :height="height" />

                                            <div v-if="this.availableChartDataKeSatu"
                                                style="text-align: center; left: 0; top: 100px; z-index: 20;color: black;">
                                                <b>{{ $t("no_data_available_txt") }}</b>
                                            </div>

                                            <div class="progress blue">
                                                <span class="progress-left">
                                                    <span class="progress-bar"></span>
                                                </span>
                                                <span class="progress-right">
                                                    <span class="progress-bar"></span>
                                                </span>
                                                <div class="progress-value">AVG {{ avg_idle_cap }}%</div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <span style="height:80px;">
                                            <hr />
                                        </span>
                                    </div>
                                    <div class="row mb-3">

                                        <div class="col-lg-6">
                                            Utility
                                            <!-- <figure class="mb-12" style="transform: rotate(90deg);"> -->
                                            <LineChartGenerator :chart-options="chartOptions"
                                                :chart-data="chartDataUtility" :chart-id="chartId"
                                                :dataset-id-key="datasetIdKey" :plugins="plugins"
                                                :css-classes="cssClasses" :styles="myStyles" :width="width"
                                                :height="height" />



                                            <div v-if="this.availableChartDataKeSatu"
                                                style="text-align: center; left: 0; top: 100px; z-index: 20;color: black;">
                                                <b>{{ $t("no_data_available_txt") }}</b>
                                            </div>



                                            <div class="progress blue">
                                                <span class="progress-left">
                                                    <span class="progress-bar"></span>
                                                </span>
                                                <span class="progress-right">
                                                    <span class="progress-bar"></span>
                                                </span>
                                                <div class="progress-value">AVG {{ avg_utility }}%</div>
                                            </div>



                                        </div>




                                        <div class="col-lg-6">
                                            Idle
                                            <LineChartGenerator :chart-options="chartOptions"
                                                :chart-data="chartDataIdle" chart-id="chartIdle"
                                                :dataset-id-key="datasetIdKey" :plugins="plugins"
                                                :css-classes="cssClasses" :width="width" :height="height" />
                                            <div>

                                            </div>
                                            <div v-if="this.availableChartDataKeSatu"
                                                style="text-align: center; left: 0; top: 100px; z-index: 20;color: black;">
                                                <b>{{ $t("no_data_available_txt") }}</b>
                                            </div>

                                            <div class="progress blue">
                                                <span class="progress-left">
                                                    <span class="progress-bar"></span>
                                                </span>
                                                <span class="progress-right">
                                                    <span class="progress-bar"></span>
                                                </span>
                                                <div class="progress-value">AVG {{ avg_idle }}%</div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row mb-3">
                                        <span style="height:80px;">
                                            <hr />
                                        </span>
                                    </div>

                                    <div class="row mb-3">

                                        <div class="col-lg-6">
                                            AIS
                                            <!-- <figure class="mb-12" style="transform: rotate(90deg);"> -->
                                            <LineChartGenerator :chart-options="chartOptions"
                                                :chart-data="chartDataAis" :chart-id="chartId"
                                                :dataset-id-key="datasetIdKey" :plugins="plugins"
                                                :css-classes="cssClasses" :styles="myStyles" :width="width"
                                                :height="height" />



                                            <div v-if="this.availableChartDataKeSatu"
                                                style="text-align: center; left: 0; top: 100px; z-index: 20;color: black;">
                                                <b>{{ $t("no_data_available_txt") }}</b>
                                            </div>



                                            <div class="progress blue">
                                                <span class="progress-left">
                                                    <span class="progress-bar"></span>
                                                </span>
                                                <span class="progress-right">
                                                    <span class="progress-bar"></span>
                                                </span>
                                                <div class="progress-value">AIS {{ avg_ais }}%</div>
                                            </div>



                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <span style="height:80px;">
                                            <hr />
                                        </span>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <!--end::content-->

                <!--begin::footer-->
                <footer-component></footer-component>
                <!--end::footer-->
            </div>
        </div>
    </div>
</template>
  
<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import DateRangePicker from "vue2-daterange-picker";
import { Line as LineChartGenerator } from 'vue-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import multiselect from "vue-multiselect";
import moment from 'moment';

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, LineElement, ArcElement, CategoryScale, LinearScale, PointElement, Filler } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, LineElement, ArcElement, CategoryScale, LinearScale, PointElement, Filler, ChartDataLabels);


export default {
    name: "DashboardUtilitation",
    props: {
        chartId: {
            type: String,
            default: 'line-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 500
        },
        height: {
            type: Number,
            default: 500
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => { }
        },
        plugins: {
            type: Array,
            default: () => { }
        },

        options: {
            tooltips: {
                enabled: false
            },


        }
        // plugins: {
        // datalabels: {
        //     align(context) {
        //         const index = context.dataIndex
        //         const value = context.dataset.data[index]
        //         return value < 10 ? 'top' : 'center'
        //     },
        // }
        // }

    },
    components: {
        "sidebar-component": sidebarComponent,
        "navbar-component": navbarComponent,
        "footer-component": footerComponent,
        LineChartGenerator,
        DateRangePicker,
        multiselect: multiselect
    },
    data() {
        const startDate = moment().clone().startOf('month').format('YYYY-MM-DD'); // new Date();
        const endDate = new Date();

        return {
            opens: "center",
            locale: {
                format: "yyyy-mm-dd",
            },
            permision_role: [],
            type_truck: [],
            branch: [],
            avg_availibility: 0,
            avg_no_driver: 0,
            avg_service: 0,
            avg_utility: 0,
            avg_idle: 0,
            avg_idle_cap: 0,
            avg_ais: 0,
            countData: [],
            fetchBranch: [],
            fetchTruck: [],
            type_truckFilter: [],
            branchFilter: [],
            branchName: [],
            dateFilter: {
                startDate,
                endDate,
            },
            availableChartDataKeSatu: false,

            chartDataUtility: {
                labels: [],


                datasets: [
                    {
                        label: 'Utility (%)',
                        borderColor: '#0eace6',
                        backgroundColor: '#0eace6', //#EF8F15'
                        data: [],

                    },
                    {
                        datalabels: {
                            display: function () {
                                return '';
                            },
                        },
                        label: 'Target(%)',
                        borderColor: '#EF8F15',
                        backgroundColor: '#EF8F15', //#EF8F15'
                        data: [98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98]
                    }
                ],
            },
            chartDataNoDriver: {
                labels: [],
                datasets: [
                    {
                        label: 'No Driver (%)',
                        borderColor: '#0eace6',
                        backgroundColor: '#0eace6', //#EF8F15'
                        data: []
                    },
                    {
                        datalabels: {
                            display: function () {
                                return '';
                            },
                        },
                        label: 'Target(%)',
                        borderColor: '#EF8F15',
                        backgroundColor: '#EF8F15', //#EF8F15'
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    }
                ]
            },
            chartDataService: {
                labels: [],
                datasets: [
                    {
                        label: 'Service (%)',
                        borderColor: '#0eace6',
                        backgroundColor: '#0eace6', //#EF8F15'
                        data: []
                    },
                    {
                        datalabels: {
                            display: function () {
                                return '';
                            },
                        },
                        label: 'Target(%)',
                        borderColor: '#EF8F15',
                        backgroundColor: '#EF8F15', //#EF8F15'
                        data: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3]
                    }
                ]
            },
            chartDataIdle: {
                labels: [],
                datasets: [
                    {
                        label: 'Idle (%)',
                        borderColor: '#0eace6',
                        backgroundColor: '#0eace6', //#EF8F15'
                        data: []
                    },
                    {
                        datalabels: {
                            display: function () {
                                return '';
                            },
                        },
                        label: 'Target(%)',
                        borderColor: '#EF8F15',
                        backgroundColor: '#EF8F15', //#EF8F15'
                        data: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]
                    }
                ]
            },

            chartDataIdleCap: {
                labels: [],
                datasets: [
                    {
                        label: 'Idle Cap (%)',
                        borderColor: '#0eace6',
                        backgroundColor: '#0eace6', //#EF8F15'
                        data: []
                    },
                    {
                        datalabels: {
                            display: function () {
                                return '';
                            },
                        },
                        label: 'Target(%)',
                        borderColor: '#EF8F15',
                        backgroundColor: '#EF8F15', //#EF8F15'
                        data: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
                    }
                ]
            },
            chartDataAvailibility: {
                labels: [],
                datasets: [
                    {
                        label: 'Availibility (%)',
                        borderColor: '#0eace6',
                        backgroundColor: '#0eace6', //#EF8F15'
                        data: []
                    },
                    {
                        datalabels: {
                            display: function () {
                                return '';
                            },
                        },
                        label: 'Target(%)',
                        borderColor: '#EF8F15',
                        backgroundColor: '#EF8F15', //#EF8F15'
                        data: [97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97]
                    }
                ]
            },
            chartDataAis: {
                labels: [],
                datasets: [
                    {
                        label: 'AIS (%)',
                        borderColor: '#0eace6',
                        backgroundColor: '#0eace6', //#EF8F15'
                        data: []
                    },
                    {
                        datalabels: {
                            display: function () {
                                return '';
                            },
                        },
                        label: 'Target(%)',
                        borderColor: '#EF8F15',
                        backgroundColor: '#EF8F15', //#EF8F15'
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    }
                ]
            },
            isLoading: false,
            chartOptions: {

                onClick: function (evt){
                    var tgl = evt.chart.tooltip.dataPoints[0].label
                    var type = evt.chart.tooltip.dataPoints[0].dataset.label
                    let typeS = type.replace(" (%)", "");


                //   console.log(moment(String(this.dateFilter.startDate,)).format('YYYY-MM-DD'))
                    window.location.href = "/truckUtilitationReportDate/"+tgl+"/"+typeS;
                    //console.log(tgl + " " + type);
                },
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                    datalabels: {
                        anchor: 'end',
                        align: 'right',
                        color: 'green',
                        formatter: function (value) {
                            return Math.round(value) + '%';
                        },
                        font: {
                            weight: 'bold',
                            size: 12,
                        }
                    },

                }
                // // tooltip: {
                // //     enabled: false
                // // //backgroundColor: "#227799"
                // // },
                // legend: {
                //     position: 'top',
                // },



            },
        };
    },
    computed: {
        myStyles() {
            return {
                height: '100%',
                position: 'relative'
            }
        }
    },
    watch: {},
    methods: {
        drawDatasetPointsLabels() {

        },
        detailData() {
            window.location.href = "/truckUtilitationReport";
        },

        downloadSummary() {
            let dataSend = {
                start: moment(String(this.dateFilter.startDate,)).format('YYYY-MM-DD'),
                end: moment(String(this.dateFilter.endDate,)).format('YYYY-MM-DD'),
            };
            this.isLoading = true;
            const baseURI = this.$settings.endPointCt + "truck-utility-detail/download-summary";
            var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
            var file_name = `summary_truck_utility_` + CurrentDate + `.xlsx`;

            return this.$http
                .get(
                    baseURI
                    +
                    `?start=${dataSend.start}&end=${dataSend.end}`,
                    { responseType: "blob" }
                )
                .then((response) => {

                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement("a");

                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", file_name);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.fade(false);
                });
        },

        loadTruck() {
            const baseURI = this.$settings.endPointCt + `type-truck/select?limit=10`;
            return this.$http.get(baseURI).then((response) => {
                this.fetchTruck = response.data.datas.data;
            });
        },
        loadBranch() {
            const baseURI = this.$settings.endPointCt + `branch/select`;
            return this.$http.get(baseURI).then((response) => {
                this.fetchBranch = response.data.datas.data;
            });
        },
        pickupChange() { },

        onSelectTypeTruck(ev) {
            this.type_truckFilter.push(ev.type_truck_id);
        },

        onUnselectTypeTruck(ev) {
            this.type_truckFilter.splice(this.type_truckFilter.indexOf(ev.type_truck_id), 1);
        },
        onUnselectBranch(ev) {
            this.branchFilter.splice(this.branchFilter.indexOf(ev.branch_id), 1);
            this.branchName.splice(this.branchFilter.indexOf(ev.branch_name), 1);
        },

        onSelectBranch(ev) {
            this.branchFilter.push(ev.branch_id);
            this.branchName.push(ev.branch_name);
        },

        getData() {
            let dataSend = {
                branch: this.branchFilter.toString(),
                type_truck: this.type_truckFilter.toString(),
                start: moment(String(this.dateFilter.startDate,)).format('YYYY-MM-DD'), //this.dateFilter.startDate,  // moment(String(this.forms.join_date)).format('YYYY-MM-DD');
                end: moment(String(this.dateFilter.endDate,)).format('YYYY-MM-DD'),
                // end: this.dateFilter.endDate,                
            };
            this.loadItems(dataSend);
        },

        loadItems(dataSend) {
            this.baseURI = this.$settings.endPointCt + "truck-utility-detail/dashboard?start=" + dataSend.start + "&end=" + dataSend.end + "&branch=" + dataSend.branch + "&type_truck=" + dataSend.type_truck;
            return this.$http
                .get(
                    this.baseURI
                    //  + `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}`,{ timeout: 10000 }
                )
                .then((response) => {
                    this.chartDataUtility.datasets[0].data = response.data.datas.active;
                    this.chartDataNoDriver.datasets[0].data = response.data.datas.no_driver;
                    this.chartDataService.datasets[0].data = response.data.datas.service;
                    this.chartDataAvailibility.datasets[0].data = response.data.datas.availibility;
                    this.chartDataIdle.datasets[0].data = response.data.datas.idle;
                    this.chartDataIdleCap.datasets[0].data = response.data.datas.idle_cap;
                    this.chartDataAis.datasets[0].data = response.data.datas.ais;
                    this.avg_availibility = response.data.datas.percent_availibility_summary;
                    this.avg_no_driver = response.data.datas.percent_no_driver_summary;
                    this.avg_service = response.data.datas.percent_service_summary;
                    this.avg_utility = response.data.datas.percent_active_summary;
                    this.avg_idle = response.data.datas.percent_idle_summary;
                    this.avg_idle_cap = response.data.datas.percent_idle_cap_summary;
                    this.avg_ais = response.data.datas.percent_ais_summary;
                    this.chartDataUtility.labels = response.data.datas.date;
                    this.chartDataNoDriver.labels = response.data.datas.date;
                    this.chartDataService.labels = response.data.datas.date;
                    this.chartDataAvailibility.labels = response.data.datas.date;
                    this.chartDataIdle.labels = response.data.datas.date;
                    this.chartDataIdleCap.labels = response.data.datas.date;
                    this.chartDataAis.labels = response.data.datas.date;
                }).catch(error => {
                    if (error.code == "ECONNABORTED") {
                        this.error(this.$t("check_internet_connection_txt"));
                    } else {

                        if (error.response) {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                                this.resultError(error.response.data.errors);
                            } else if (error.response.status === 500) {
                                this.$router.push('/server-error');
                            } else if (error.response.status === 401) {
                                this.$router.push('/authorized-error');
                            } else {
                                this.$router.push('/page-not-found');
                            }
                        }

                    }

                });
        },

        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
            this.loadItems();
        },

        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
        },

        fetchIt() {
            const userDatas = this.$getUserInfo();
            this.detailUser = userDatas.sub;
            let rolePermision = [];
            // console.log(userDatas.sub)
            userDatas.sub.permision_role.forEach(function (permision) {
                if (permision.permission.controller == 'TruckUtilityDetailController') {
                    rolePermision = permision;
                }
            });

            if (!rolePermision.method_read) {
                this.$router.push("/authorized-error");
            }

            this.permision_role = rolePermision;

        },
        fade(sType) {
            this.isLoading = sType;
        },

        loading() {
            this.fade(true);
            setTimeout(() => {
                this.fade(false);
            }, 1000); // hide the message after 3 seconds
        },

        resultError(data) {
            var count = Object.keys(data).length;
            for (var x = 0; x < count; x++) {
                var nameOb = Object.keys(data)[x];
                var objectData = data[nameOb];
                for (var y = 0; y < objectData.length; y++) {
                    this.error(objectData[y]);
                }
            }
        },

        success(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });

            Toast.fire({
                icon: "success",
                title: kata,
            });
        },

        error(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "error",
                title: kata,
            });
        },

    },
    events: {},
    created: function () { },
    mounted() {
        this.fetchIt();
        this.loadBranch();
        this.loadTruck();
        this.getData()
        // this.loadItems();
    },
};
</script>
<style>
button.gm-ui-hover-effect {
    visibility: hidden;
}

.blur-content {
    filter: blur(5px);
}

.box {
    width: 300px;
    height: 10%;
    border-style: solid;
    border-width: 0.5px;
    background-color: red;
    text-align: center;
    vertical-align: middle;
}


.progress {
    width: 150px;
    height: 150px;
    line-height: 150px;
    background: none;
    margin-top: -30%;
    margin-left: 70%;
    box-shadow: none;
    position: relative;
    opacity: 0.8;
}

.progress:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 12px solid #fff;
    position: absolute;
    top: 0;
    right: 0;
}

.progress>span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
}

.progress .progress-left {
    left: 0;
}

.progress .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 12px;
    border-style: solid;
    position: absolute;
    top: 0;
}

.progress .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
}

.progress .progress-right {
    right: 0;
}

.progress .progress-right .progress-bar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    animation: loading-1 1.8s linear forwards;
}

.progress .progress-value {
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: #eaf0f4;
    font-size: 24px;
    color: rgb(65, 65, 65);
    line-height: 135px;
    text-align: center;
    position: absolute;
    top: 5%;
    left: 5%;
}

.progress.blue .progress-bar {
    border-color: #097fc9;
}

.progress.blue .progress-left .progress-bar {
    animation: loading-2 1.5s linear forwards 1.8s;
}

.progress.yellow .progress-bar {
    border-color: #fdba04;
}

.progress.yellow .progress-left .progress-bar {
    animation: loading-3 1s linear forwards 1.8s;
}

.progress.pink .progress-bar {
    border-color: #ed687c;
}

.progress.pink .progress-left .progress-bar {
    animation: loading-4 0.4s linear forwards 1.8s;
}

.progress.green .progress-bar {
    border-color: #1abc9c;
}

.progress.green .progress-left .progress-bar {
    animation: loading-5 1.2s linear forwards 1.8s;
}

@keyframes loading-1 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

@keyframes loading-2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

@keyframes loading-3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}

@keyframes loading-4 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(36deg);
        transform: rotate(36deg);
    }
}

@keyframes loading-5 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(126deg);
        transform: rotate(126deg);
    }
}

@media only screen and (max-width: 990px) {
    .progress {
        margin-bottom: 20px;
    }
}
</style>