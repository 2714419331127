<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>   
    <div class="loadertext" v-if="this.isLoading"> {{$t('loadingTxt')}}...</div>   
    <div class="main-wrapper" :class="{'blur-content': this.isLoading}">
      <!--begin::sidebar-->
      <sidebar-component classMenu="InformasiBongkarIndex"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            "
          >
            <div>
              <h4 class="mb-12 mb-md-0"></h4>
            </div>

            <div class="col-lg-12 col-xl-12 stretch-card">
              <div class="card">
                <div class="card-header">
                  <h4 style="margin-right: 5px" class="float-start">Unloading Information</h4>
                  <button
                     v-if="permision_role.method_create"
                    class="btn btn-primary float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="createData()"
                  >
                    <i class="link-icon" data-feather="plus"></i>
                    {{ $t("createNew") }}
                  </button>

                  <button
                    class="btn btn-warning float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="uploadData()"
                  >
                     <i class="link-icon" data-feather="upload"></i>
                    {{ $t("uploadData") }} .xlsx
                  </button>
                  

                  <button
                    v-if="permision_role.method_read"
                    class="btn btn-success float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="downloadData()"
                  >
                     <i class="link-icon" data-feather="download"></i>
                    {{ $t("downloadData") }} .xlsx
                  </button>
                </div>

                <div class="card-body">
                  <vue-good-table
                    title="list-user"
                    mode="remote"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    :totalRows="totalRecords"
                    :line-numbers="true"
                    :isLoading.sync="isLoading"
                    :pagination-options="{
                      enabled: true,
                    }"
                    :rows="rows"
                    :columns="columns"
                   
                  >
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'actions'">
                            <a
                              href="#"
                              v-if="permision_role.method_read"
                              title="Detail"
                              @click.prevent="
                                detailData(props.index, props.row)
                              "
                            >
                              <i class="mdi mdi-eye" style="font-size:16px"></i>
                              
                            </a>
                            <a
                              href="#"
                              v-if="permision_role.method_update"
                              title="Edit"
                              @click.prevent="editData(props.index, props.row)"
                            >
                              <i class="mdi mdi-square-edit-outline" style="font-size:16px"></i>
                              
                            </a>

                            <a
                              href="#"
                              v-if="permision_role.method_delete"
                              title="Delete"
                              @click.prevent="
                                deleteData(props.index, props.row)
                              "
                            >
                              <i class="mdi mdi-trash-can-outline" style="font-size:16px"></i> 
                            </a>
                      </span>
                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";

export default {
  name: "InformasiBongkarIndex",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      permision_role:[],
      langs: ["id", "en"],
      detailUser: [],
      detailDivisi: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [    
        {
          label: "Load Number",
          field: "load_number",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Load Number", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: "Vehicle No",
          field: "vehicle_no",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Vehicle No", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: "Shipment Date",
          field: "shipment_date",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: "Shipment Number",
          field: "shipment_number",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Shipment Number", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: "Route",
          field: "route",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Route", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: "Driver NMK",
          field: "driver_nmk",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Driver NMK", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        

        {
          label: "Driver Name",
          field: "driver_name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Driver", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Tanggal Muat",
          field: "tanggal_muat",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Tanggal Bongkar",
          field: "tanggal_bongkar",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Provinsi Tujuan",
          field: "provinsi_tujuan",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Provinsi", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Kota Tujuan",
          field: "kota_tujuan",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Kota", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "SKU",
          field: "sku",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By SKU", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Qty",
          field: "qty",
          filterOptions: {
            enabled: false, 
          },
        },

        {
          label: "Qty Return",
          field: "qty_return",
          filterOptions: {
            enabled: false, 
          },
        },

        {
          label: "Sub Customer",
          field: "sub_customer",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Sub Customer", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Description",
          field: "description",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Description", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Action",
          field: "actions",
          sortable: false,
        },
      ],
      rows: [],
    };
  },
  watch: {},
  methods: {

    fetchIt() {
      const userDatas     = this.$getUserInfo();
      this.detailUser     = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function(permision){
        if(permision.permission.controller == 'InformasiBongkarController'){
          rolePermision = permision;
        }
      });

      if(!rolePermision.method_read){
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;

    },

    downloadData() {
      const baseURI = this.$settings.endPointCt + "/informasi-bongkar";
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = "download_" + CurrentDate + ".xlsx";

      //var columnFilters = this.serverParams.columnFilters;


      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}`+
          `&page=${this.serverParams.page}`+
          `&sort_field=${this.serverParams.sort.field}`+
          `&sort_type=${this.serverParams.sort.type}`+
          `&load_number=${this.serverParams.columnFilters.load_number}`+
          `&vehicle_no=${this.serverParams.columnFilters.vehicle_no}`+
          `&shipment_date=${this.serverParams.columnFilters.shipment_date}`+
          `&shipment_number=${this.serverParams.columnFilters.shipment_number}`+
          `&route=${this.serverParams.columnFilters.route}`+
          `&driver_nmk=${this.serverParams.columnFilters.driver_nmk}`+
          `&driver_name=${this.serverParams.columnFilters.driver_name}`+
          `&tanggal_muat=${this.serverParams.columnFilters.tanggal_muat}`+
          `&tanggal_bongkar=${this.serverParams.columnFilters.tanggal_bongkar}`+
          `&provinsi_tujuan=${this.serverParams.columnFilters.provinsi_tujuan}`+
          `&kota_tujuan=${this.serverParams.columnFilters.kota_tujuan}`+
          `&sku=${this.serverParams.columnFilters.sku}`+
          `&sub_customer=${this.serverParams.columnFilters.sub_customer}`+
          `&description=${this.serverParams.columnFilters.description}`+
          `&download=download`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
         
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },

    createData() {
      window.location.href = "/informasiBongkar/add";
    },

    uploadData(){
      window.location.href = "/informasiBongkar/upload";
    },

    editData(index, row) {
      var params = this.$onRandom(row.informasi_bongkar_id);
      window.location.href = "/informasiBongkar/edit/" + params;
    },

    detailData(index, row) {
      var params = this.$onRandom(row.informasi_bongkar_id);
      window.location.href = "/informasiBongkar/detail/" + params;
    },

    deleteData(index, row, status) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI =
            this.$settings.endPointCt + "informasi-bongkar/delete/" + row.informasi_bongkar_id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    // load items is what brings back the rows from server
    loadItems() {
      const baseURI = this.$settings.endPointCt + "informasi-bongkar";
      this.isLoading = true;
      return this.$http
        .get(
           baseURI +
         `?limit=${this.serverParams.per_page}`+
         `&page=${this.serverParams.page}`+
         `&sort_field=${this.serverParams.sort.field}`+
         `&sort_type=${this.serverParams.sort.type}`+
         `&load_number=${this.serverParams.columnFilters.load_number}`+
         `&vehicle_no=${this.serverParams.columnFilters.vehicle_no}`+
         `&shipment_date=${this.serverParams.columnFilters.shipment_date}`+
         `&shipment_number=${this.serverParams.columnFilters.shipment_number}`+
         `&route=${this.serverParams.columnFilters.route}`+
         `&driver_nmk=${this.serverParams.columnFilters.driver_nmk}`+
         `&driver_name=${this.serverParams.columnFilters.driver_name}`+
         `&tanggal_muat=${this.serverParams.columnFilters.tanggal_muat}`+
         `&tanggal_bongkar=${this.serverParams.columnFilters.tanggal_bongkar}`+
         `&provinsi_tujuan=${this.serverParams.columnFilters.provinsi_tujuan}`+
         `&kota_tujuan=${this.serverParams.columnFilters.kota_tujuan}`+
         `&sku=${this.serverParams.columnFilters.sku}`+
         `&sub_customer=${this.serverParams.columnFilters.sub_customer}`+
         `&description=${this.serverParams.columnFilters.description}`,
          { timeout: 10000 }
        )
        .then((response) => {
          this.isLoading = false;
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
        }).catch(error => {
           
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
            
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                      this.errors = error.response.data.errors;
                      this.resultError(error.response.data.errors);
              }else if (error.response.status === 500) {
                  this.$router.push('/server-error');
              }else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
              }else{
                  this.$router.push('/page-not-found');
              }
            }

          }
        });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },


    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },
  },
  events: {},
  created: function () {},
  mounted() {
    this.fetchIt();
    this.loading();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content{
  filter: blur(5px); 
  pointer-events: none;
  
}
</style>