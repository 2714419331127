<template>
  <!--begin::content-->
  <div class="page-content">
    <div
      class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
    >
      <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
        <div class="card">
          <div class="card-header">
            <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
            &nbsp;&nbsp;&nbsp;
            <h6 class="card-title mb-0 float-start" style="padding-left: 25px">
              Email Notification
            </h6>
            <button
              class="btn btn-default btn-sm float-end"
              @click="resetForm"
              type="button"
            >
              <i class="link-icon" data-feather="repeat"></i>
              {{ $t("resetFormTxt") }}
            </button>
          </div>
          <div class="card-body">
            <!--begin::loading-data-->
            <div v-if="isLoading" class="d-flex align-items-center">
              <div
                class="spinner-border ms-auto"
                role="status"
                aria-hidden="true"
              ></div>
              <strong>{{ $t("loadingTxt") }}...</strong>
            </div>
            <!--end::loading-data-->

            <form
              class="forms-sample"
              @submit.prevent="submitData"
              method="POST"
            >
              <div
                class="d-flex justify-content-between align-items-baseline mb-2"
              ></div>
              <div class="row ms-3">
                <div class="col-sm-8">
                  <div class="row mb-3">
                    <label for="period_code" class="col-form-label col-sm-3"
                      >Username</label
                    >
                    <div class="col-sm-6">
                      <input
                        type="text"
                        v-model="forms.username"
                        disabled
                        class="form-control"
                      />
                      <div v-if="errors.username">
                        <div
                          v-for="error in errors.username"
                          :key="error"
                          class="alert alert-primary"
                          role="alert"
                        >
                          <i data-feather="alert-circle"></i>
                          {{ error }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="period_code" class="col-form-label col-sm-3"
                      >Notification</label
                    >
                    <div class="col-sm-9">
                      <div>
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            v-model="forms.selfFlag"
                            class="form-check-input"
                            id="selfCheck"
                            true-value="Y"
                            false-value="N"
                            disabled
                          />
                          <label class="form-check-label" for="selfCheck">
                            Self
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            v-model="forms.jakartaFlag"
                            class="form-check-input"
                            id="jakartaFlag"
                            true-value="Y"
                            false-value="N"
                            disabled
                          />
                          <label class="form-check-label" for="jakartaFlag">
                            Jakarta
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            v-model="forms.medanFlag"
                            class="form-check-input"
                            id="medanFlag"
                            true-value="Y"
                            false-value="N"
                            disabled
                          />
                          <label class="form-check-label" for="medanFlag">
                            Medan
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            v-model="forms.surabayaFlag"
                            class="form-check-input"
                            id="surabayaFlag"
                            true-value="Y"
                            false-value="N"
                            disabled
                          />
                          <label class="form-check-label" for="surabayaFlag">
                            Surabaya
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            v-model="forms.semarangFlag"
                            class="form-check-input"
                            id="semarangFlag"
                            true-value="Y"
                            false-value="N"
                            disabled
                          />
                          <label class="form-check-label" for="semarangFlag">
                            Semarang
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            v-model="forms.daily_reportFlag"
                            class="form-check-input"
                            id="daily_reportFlag"
                            true-value="Y"
                            false-value="N"
                            disabled
                          />
                          <label
                            class="form-check-label"
                            for="daily_reportFlag"
                          >
                          Daily Report
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-footer">
                <button
                  class="btn btn-warning text-black fw-bolder btn-icon-text"
                  @click="backForm"
                  type="button"
                >
                  <i class="link-icon" data-feather="arrow-left"></i>
                  {{ $t("backMess") }}
                </button>

                &nbsp;&nbsp;&nbsp;
                <a
                  v-if="permision_role.method_delete"
                  class="btn btn-danger text-white fw-bolder btn-icon-text float-end"
                  @click="deleteData()"
                >
                  <i class="link-icon" data-feather="trash"></i>
                  {{ $t("deleteMess") }}
                </a>

                <a
                  v-if="permision_role.method_update"
                  class="btn btn-primary me-2 text-white fw-bolder btn-icon-text float-end"
                  @click="editData()"
                >
                  <i class="link-icon" data-feather="edit"></i>
                  {{ $t("editMess") }}
                </a>

                <!-- <button
                        disabled
                        class="btn btn-success float-end btn-sm"
                        style="margin-right: 5px"
                        @click="calculate()"
                      >
                        <i class="mdi mdi-calculator"></i>
                        Calculate
                      </button> -->
              </div>
            </form>
          </div>
        </div>
        <!--end::card-->
      </div>
    </div>
  </div>
  <!--end::content-->
</template>
  
<script>
export default {
  name: "FppkNotificationDetail",
  props: {},
  components: {},
  data() {
    return {
      forms: {
        selfFlag: "N",
        jakartaFlag: "N",
        medanFlag: "N",
        surabayaFlag: "N",
        semarangFlag: "N",
        daily_reportFlag: "N",
      },
      opens: "center",
      permision_role: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      fetchUser: [],
      username: null,
    };
  },
  watch: {},
  methods: {
    asyncUsername(ev) {
      const baseURI = this.$settings.endPoint + "user?username=" + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchUser = response.data.datas.data;
      });
    },
    loadUsername() {
      const baseURI = this.$settings.endPoint + "user";

      return this.$http.get(baseURI).then((response) => {
        this.fetchUser = response.data.datas.data;
      });
    },
    resetForm() {
      this.username = null;
      this.forms = {};
    },
    editData() {
      window.location.href = "/fppk-notification/edit/" + this.$route.params.id;
    },

    
    deleteData() {
      var id = this.$onBehind(this.$route.params.id);
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          const baseURI =
            this.$settings.endPointFppk + "v1/email-notif-fppk/delete/" + id;
          this.$http
            .delete(baseURI)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                window.location.href = "/fppk-notification";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      this.companyCode = this.detailUser.company_detail.code;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "EmailNotifFppkController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      this.permision_role = rolePermision;
    },

    backForm() {
      window.location.href = "/fppk-notification";
    },
    loadData(id) {
      const baseURI =
        this.$settings.endPointFppk + "v1/email-notif-fppk/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        // console.log(response);
        this.forms = response.data.datas;

        // this.form={ name: 'asep', usernmae:res.username };
      });
    },
    submitData() {
      this.fade(true);
      this.forms.username = this.username?.username;
      const baseURI =
        this.$settings.endPointFppk + "v1/email-notif-fppk/create";
      this.$http
        .post(baseURI, this.forms)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            var params = this.$onRandom(
              response.data.datas.email_notification_id
            );
            window.location.href =
              "/fppk-notification/detail/" +
              params +
              "?status=200&msg=Successfully";
            // this.success('Berhasil');
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              if (this.errors.message) {
                this.error(this.errors.message);
              } else {
                this.resultError(error.response.data.errors);
              }
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },
    cek_message(status, msg) {
      if (status == "200") {
        this.success(msg);
      }
    },
    calculate() {
      this.$swal({
        title: "Apakah Anda Yakin akan mengkalkulasi BBM ?",
        text: "Semua Record Payable dan Billable akan mengalami kenaikan sesuai dengan exponensial kenaikan harga BBM ini",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        console.log(result);
      });
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    this.fetchIt();
    var params = this.$onBehind(this.$route.params.id);
    this.cek_message(this.$route.query.status, this.$route.query.msg);
    this.loadData(params[0]);
    // this.loadCompany();
    // this.loadCompanyNotLinc();
  },
};
</script>
<style scoped></style>
