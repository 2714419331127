<template>
  <div class="row">
    <div class="row">
      <div class="col-sm-3">
        <div class="mb-3">
          <label class="form-label">Vehicle No</label>
          <p class="text-muted">
            <b>{{ data.vehicle_no }}</b>
          </p>
        </div>
      </div>

      <div class="col-sm-3">
        <div class="mb-3">
          <label class="form-label">Load ID</label>
          <p class="text-muted">
            <b>{{data.load_id}}</b>
          </p>
        </div>
      </div>

      <div class="col-sm-3">
        <div class="mb-3">
          <label class="form-label">Equipment</label>
          <p class="text-muted">
            <b>{{data.equipment}}</b>
          </p>
        </div>
      </div>

      <div class="col-sm-3">
        <div class="mb-3">
          <label class="form-label">Load Group</label>
          <p class="text-muted">
            <b>{{data.load_group}}</b>
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3">
        <div class="mb-3">
          <label class="form-label">Driver Name</label>
          <p class="text-muted">
            <b>{{data.driver_name}}</b>
          </p>
        </div>
      </div>

      <div class="col-sm-3">
        <div class="mb-3">
          <label class="form-label">Driver Hp</label>
          <p class="text-muted">
            <b>{{data.driver_hp}}</b>
          </p>
        </div>
      </div>

      <div class="col-sm-3">
        <div class="mb-3">
          <label class="form-label">Order No</label>
          <p class="text-muted">
            <b v-if="data.order_detail!=null">{{data.order_detail[0].order_number}}</b>
          </p>
        </div>
      </div>

      <div class="col-sm-3">
        <div class="mb-3">
          <label class="form-label">Customer</label>
          <p class="text-muted">
            <b v-if="data.order_detail!=null">{{data.order_detail[0].customer_name}}</b>
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3">
        <div class="mb-3">
          <label class="form-label">Order Group</label>
          <p class="text-muted">
            <b>{{data.order_group}}</b>
          </p>
        </div>
      </div>

      <div class="col-sm-3">
        <div class="mb-3">
          <label class="form-label">Last Update</label>
          <p class="text-muted">
            <b>{{data.updated_at}}</b>
          </p>
        </div>
      </div>

      <div class="col-sm-3">
        <div class="mb-3">
          <label class="form-label">Location</label>
          <p class="text-muted">
            <b>{{detailMonitoring.location}}</b>
          </p>
        </div>
      </div>

      <div class="col-sm-3">
        <div class="mb-3">
          <label class="form-label">Engine</label>
          <p class="text-muted">
            <b>{{detailMonitoring.engine_car}}</b>
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3">
        <div class="mb-3">
          <label class="form-label">Speed</label>
          <p class="text-muted">
            <b>{{detailMonitoring.speed}}</b>
          </p>
        </div>
      </div>

      <div class="col-sm-3">
        <div class="mb-3">
          <label class="form-label">AC</label>
          <p class="text-muted">
            <b>{{detailMonitoring.ac}}</b>
          </p>
        </div>
      </div>

      <div class="col-sm-3">
        <div class="mb-3">
          <label class="form-label">Temp</label>
          <p class="text-muted">
            <b>{{detailMonitoring.temperature}}</b>
          </p>
        </div>
      </div>

      <div class="col-sm-3">
        <div class="mb-3">
          <label class="form-label">Status</label>
          <p class="text-muted">
            <b>{{data.tms_status}}</b>
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <vue-good-table
        title="list-of-orders"
        mode="remote"
        :sort-options="{
          enabled: false,
        }"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :line-numbers="true"
        :isLoading.sync="isLoading"
        :pagination-options="{
          enabled: true,
        }"
        :filterOptions="{
          enabled: false,
        }"
        :rows="rowsTableOrder"
        :columns="columnsOrder"
      >
        <template slot="table-row" slot-scope="props">
          {{ props.formattedRow[props.column.field] }}


          <span v-if="props.column.field == 'pickup'">
                          <span>{{ props.row.name_rute.split(",")[0].replace("pickup :", "")  }}</span
                          >
          </span>
          <span v-else-if="props.column.field == 'delivery'">
                          <span>{{ props.row.name_rute.split(",")[1].replace("stop : ", "") }}</span
                          >
          </span>

        </template>
      </vue-good-table>
    </div>
  </div>
</template>


<script>
export default {
  name: "basicInfoComponent",
  props: {
    data: [],
    detailOrder: [],
    detailMonitoring: [],
  },
  watch: {
  
    detailOrder: function(val) {
      this.loadTable(val)
    },
  
  },
  data() {
    return {
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,

      },
      rowsTableOrder: '',
      columnsOrder: [
        

         {
          label: "Order Id",
          field: "order_number",
        },

        {
          label: "Customer",
          field: "customer_name",
        },

        {
          label: "Product Type",
          field: "product_type",
        },

        {
          label: "Pickup",
          field: "pickup",
        },

        {
          label: "Delivery",
          field: "delivery",
        },

      
        {
          label: "Pickup Date",
          field: "pickup_date",
        },

        {
          label: "Delivery Date",
          field: "delivery_date",
        },
      ],
      //
    };
  },
  methods: {
    loadTable(val){
        this.rowsTableOrder=val;
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadLocationTable();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },
  },
  mounted() {
    this.loadTable(this.detailOrder);
  },
};
</script>
