<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TemplateOrder"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="col-lg-12 col-xl-12 stretch-card">
            <div class="card">
              <div class="card-header">
                <div class="row" v-if="company_id != ''">
                  <table class="table">
                    <tr>
                      <th width="10px">Costumer Code</th>
                      <td width="5px">:</td>
                      <td>{{ company_code }}</td>
                    </tr>
                    <tr>
                      <th width="10px">Costumer Name</th>
                      <td width="5px">:</td>
                      <td>{{ company_name }}</td>
                    </tr>
                    <tr>
                      <th width="10px">SAP Code</th>
                      <td width="5px">:</td>
                      <td>{{ sap_code }}</td>
                    </tr>
                  </table>

                </div>
                <hr v-if="company_name != null">
                <div class="row">
                  <div class="col-lg-6">
                    <h4 style="margin-right: 5px" class="float-start">Billable Method</h4>
                  </div>

                  <div class="col-lg-6">

                    <button v-if="permision_role.method_read" class="btn btn-success float-end btn-xs"
                      style="margin-right: 5px" @click.prevent="createData()">
                      <i class="link-icon" data-feather="plus"></i>
                      {{ $t("createNew") }}
                    </button>
                    <button class="btn btn-warning float-end btn-xs" style="margin-right: 5px"
                      @click.prevent="downloadData()">
                      <i class="link-icon" data-feather="download"></i>
                      {{ $t("downloadData") }} .xlsx
                    </button>

                  </div>



                </div>

              </div>

              <div class="card-body">
                <vue-good-table title="list-parties" ref="list-parties" mode="remote" :select-options="{
                  enabled: true,
                  selectOnCheckboxOnly: true,
                  selectionText: $t('data_selected'),
                  clearSelectionText: $t('clear'),
                }" @on-selected-rows-change="selectionChanged" @on-page-change="onPageChange"
                  @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange"
                  :totalRows="totalRecords" :line-numbers="true" :isLoading.sync="isLoading" :pagination-options="{
                    enabled: true,
                  }" :rows="rows" :columns="columns">

                  <div slot="table-actions">
                    {{ $t("no_select_data") }}
                  </div>
                  <div slot="selected-row-actions">


                    <button class="btn btn-success btn-xs" style="margin-right:20px;" @click="sendToBlujay"> <i
                        class="mdi mdi-send-outline"></i>Send To Blujay</button>
                    <span>





                    </span>
                  </div>

                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'actions'">
                      <a href="#" title="Edit" @click.prevent="editData(props.index, props.row)">
                        <i class="mdi mdi-square-edit-outline" style="font-size:16px"></i>

                      </a>

                      <a v-if="props.row.enabled != 'ENABLED'" href="#" title="Status" @click.prevent="
                        confirmStatus(props.index, props.row)

                        ">
                        <i class="mdi mdi-checkbox-marked" style="font-size:16px"></i>
                      </a>


                      <a v-if="props.row.enabled == 'ENABLED'" href="#" title="Status" @click.prevent="
                        confirmStatus(props.index, props.row)

                        ">
                        <i class="mdi mdi-close-box-outline" style="font-size:16px"></i>
                      </a>

                      <!-- <a
                                href="#"
                                title="Billable Method"
                                @click.prevent="
                                  billableMethod(props.index, props.row)
                                "
                              >
                                <i class="mdi mdi-send" style="font-size:16px"></i> 
                              </a> -->
                    </span>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>
  
<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";

export default {
  name: "TemplateOrder",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      company_id: "",
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      company_name: '',
      company_code: '',
      sap_code: '',
      errors: [],
      rowsSelection: [],
      rm_billable_method_ids: [],
      permision_role: [],
      langs: ["id", "en"],
      detailUser: [],
      detailDivisi: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [],
      // rows: [],
      rows: [
        // {
        //   company_code:"ADS",
        //   company_name:"Andalan Dunia Semesta",
        //   sap_code:"3000014429",
        //   enteries:100,
        //   last_update:"20/03/2023"
        // }
      ],
    };
  },
  watch: {},
  methods: {

    selectionChanged(ev) {
      this.rowsSelection = [];

      this.rowsSelection = ev.selectedRows;
      // this.btnRejectOrder = true;
      // this.btnCancelGroup = true;
      // this.btnSubmit = true;
      this.rm_billable_method_ids = [];
      this.rowsSelection.forEach((item) => {

        this.rm_billable_method_ids.push(item.rm_billable_method_id);

      });

      // console.log(this.rm_billable_method_ids);

    },



    sendToBlujay() {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
          let data = {

            rm_billable_method_ids: this.rm_billable_method_ids,
          }
          const baseURI =
            this.$settings.endPoint + "rm-billable-method/send-to-blujay";
          this.$http
            .post(baseURI, data, { timeout: 30000 })
            .then((response) => {
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
                this.isloading = false
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            }).catch(error => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {

                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.$router.push('/server-error');
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push('/page-not-found');
                  }
                }

              }

            });
        }
      });



    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == 'RmBillableMethodController') {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;

    },

    downloadData() {
      const baseURI = this.$settings.endPoint + "/transport-order-template";
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = "download_" + CurrentDate + ".xlsx";



      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&company_name=${this.serverParams.columnFilters.company_name}&description=${this.serverParams.columnFilters.description}&file_upload=${this.serverParams.columnFilters.file_upload}&rating_method=${this.serverParams.columnFilters.rating_method}&status=${this.serverParams.columnFilters.status}&sob=${this.serverParams.columnFilters.sob}&download=download`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {

          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },

    // downloadData() {
    //   this.fade(true);
    //   var baseURI = this.$settings.endPoint + "/user/download";
    //   var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
    //   var columnFilters = this.serverParams.columnFilters;
    //   var role = columnFilters["role.name"];
    //   var company_code = columnFilters["company_detail.code"];

    //   var sendData = {
    //     username: this.serverParams.columnFilters.username,
    //     name: this.serverParams.columnFilters.name,
    //     email: this.serverParams.columnFilters.email,
    //     level: this.serverParams.columnFilters.level,
    //     status: this.serverParams.columnFilters.status,
    //     role:role,
    //     company_code: company_code,
    //     file_name: "download_" + CurrentDate + ".xlsx",
    //   };
    //   this.$http({
    //     url: baseURI,
    //     method: "GET",
    //     data: sendData,
    //     responseType: "blob",
    //   })
    //     .then((response) => {
    //       this.errors = [];
    //       var filename = sendData.file_name;

    //       var fileURL = window.URL.createObjectURL(new Blob([response.data]));
    //       var fileLink = document.createElement("a");

    //       fileLink.href = fileURL;
    //       fileLink.setAttribute("download", filename);
    //       document.body.appendChild(fileLink);
    //       fileLink.click();
    //       this.fade(false);
    //     })
    //     .catch((error) => {
    //       if (error.response) {
    //         if (error.response.status === 422) {
    //           this.errors = { message: ["File Not Found"] };
    //           this.error("File Not Found");
    //         } else if (error.response.status === 500) {
    //           this.$router.push("/server-error");
    //         } else {
    //           this.$router.push("/page-not-found");
    //         }
    //       }
    //     });
    // },
    createData() {
      // console.log(this.$route.params);
      if (this.company_id != '') {
        window.location.href = "/billable-method/add?company_id=" + this.$route.query.company_id;
      } else {
        window.location.href = "/billable-method/add";
      }
    },

    uploadData() {
      window.location.href = "/order/billable-method/upload";
    },

    editData(index, row) {
      var params = this.$onRandom(row.rm_billable_method_id);
      if (this.company_id != '') {
        window.location.href = "/billable-method/edit/" + params + "?company_id=" + this.$route.query.company_id;
      } else {
        window.location.href = "/billable-method/edit/" + params;
      }

    },


    detailData(index, row) {
      var params = this.$onRandom(row.transport_order_template_id);
      window.location.href = "/order/billable-method/detail/" + params;
    },

    confirmStatus(index, row) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: 'Your Ready Status to ' + row.enabled == 'ENABLED' ? 'DISABLED' : 'ENABLED',
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            enabled: row.enabled == 'ENABLED' ? 'DISABLED' : 'ENABLED'
          };
          const baseURI =
            this.$settings.endPoint + "rm-billable-method/change-enabled/" + row.rm_billable_method_id;
          this.$http
            .post(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },


    loadCompany(id) {
      const baseURI = this.$settings.endPoint + "company/detail/" + id;
      return this.$http.get(baseURI, { timeout: 10000 }).then((response) => {
        // this.isLoading = false;
        // this.forms = response.data.datas.name;
        this.company_name = response.data.datas.name;
        this.company_code = response.data.datas.code;
        this.sap_code = response.data.datas.sap_code;

        // this.form={ name: 'asep', usernmae:res.username };
      }).catch(error => {
        // this.isLoading = false;
        if (error.code == "ECONNABORTED") {
          this.error("Please Check Your Internet Connection");
        } else {

          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            }
          }

        }


      });
    },

    loadColoum() {
      if (this.company_id == null || this.company_id == '' || this.company_id == undefined) {
        this.columns = [{
          label: this.$t('companyTxt'),
          field: "company_name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t('companyTxt'), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: this.$t('descriptionTxt'),
          field: "description",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t('descriptionTxt'), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "File Upload Cross Reference",
          field: "file_reference",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By File Upload Cross Reference", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Rating Method",
          field: "rating_method",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Rating Method ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Status",
          field: "enabled",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Rating Status ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "SoB",
          field: "sob",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Rating SoB ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Action",
          field: "actions",
          sortable: false,
        }];
      } else {
        this.columns = [

          {
            label: this.$t('descriptionTxt'),
            field: "description",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By " + this.$t('descriptionTxt'), // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "File Upload Cross Reference",
            field: "file_reference",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By File Upload Cross Reference", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "Rating Method",
            field: "rating_method",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Rating Method ", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "Status",
            field: "enabled",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Rating Status ", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "SoB",
            field: "sob",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Rating SoB ", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "Action",
            field: "actions",
            sortable: false,
          },
        ]
      }
    },

    // load items is what brings back the rows from server
    loadItems() {


      const baseURI = this.$settings.endPoint + "rm-billable-method";
      //var columnFilters = this.serverParams.columnFilters;

      // console.log(this.serverParams.columnFilters);
      // var id='';


      //localhost:8081/rm-billable-method/?download=&description&file_reference&rating_method&sob&enabled&company_name=mapan
      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&company_id=${this.company_id == '' || this.company_id == undefined ? this.serverParams.columnFilters.company_name : this.company_id[0]}&description=${this.serverParams.columnFilters.description}&file_upload=${this.serverParams.columnFilters.file_upload}&rating_method=${this.serverParams.columnFilters.rating_method}&enabled=${this.serverParams.columnFilters.enabled}&sob=${this.serverParams.columnFilters.sob}`,
        )
        .then((response) => {

          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
        });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },
    billableMethod() {

      var params = this.$onRandom(1);
      window.location.href = "/billable-method/detail/" + params;
    },
    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },


    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },
  },
  events: {},
  created: function () { },
  mounted() {

    this.company_id = this.$route.query == undefined ? '' : this.$onBehind(this.$route.query.company_id);
    // console.log(this.company_id);
    if (this.company_id != '') {
      this.loadCompany(this.company_id);
    }
    // this.company_code=this.$route.query==undefined ? '' : this.$route.query.company_code;
    // this.sap_code=this.$route.query==undefined ? '' : this.$route.query.sap_code;


    this.loadColoum();
    this.fetchIt();
    this.loading();
  },
};
</script>
<style scoped></style>