<template>
  <div class="row">
  
    <vue-good-table
      title="list-of-orders"
      mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      :totalRows="totalRecords"
      :line-numbers="true"
      :isLoading.sync="isLoading"
      :pagination-options="{
        enabled: true,
      }"
      :filterOptions="{
        enabled: false}" 
      :rows="rowsTable"
      :columns="columnsOrder"
      :sort-options="{
        enabled: false,
      }"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'name_rute'">
          {{ props.row.pickup }} - {{ props.row.delivery }}
        </span>

        <span v-if="props.column.field == 'control'">
         
          <a
            href="#"
            title="Edit"
            @click.prevent="EditFormModal(props.row)"
          >
            <i class="mdi mdi-square-edit-outline" style="font-size: 16px"></i>
          </a>

        </span>

        <span v-if="props.column.field == 'mileage'">
           <h5 v-if="props.row.mileage!=null">{{props.row.mileage.toLocaleString()}}</h5>
        </span>


        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
        
      </template>
    </vue-good-table>



  </div>


</template>


<script>
import moment from "moment";
export default {
  name: "tripHistoryComponent",
  props: {
    dateRange: { type: [Object, Array] }
   
  },
  components: {
    // DirectionsRenderer,
  },
  watch: {
    
    dateRange: function() {
      // console.log(val);
      this.loadTable();
    },


  },
  data() {
    return {
      vehicle_no:this.$route.params.vehicle_no,
      gps_vendor:this.$route.params.gps_vendor,
      detailTripAnalysis:"",
      showModalNow: false,
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 50,
      },
      rowsTable: [],
      columnsOrder: [
        {
          label: "Last Update",
          field: "time_gps_2",
        },

        {
          label: "Vehicle No",
          field: "vehicle_no",
        },

        {
          label: "location",
          field: "location",
        },

        {
          label: "City",
          field: "city",
        },
        {
          label: "Geofence",
          field: "geofence",
        },

        {
          label: "Speed",
          field: "speed",
        },

        {
          label: "ENG",
          field: "engine_car",
        },

        {
          label: "KM",
          field: "mileage",
        },
        
      ],
      //
    };
  },


  methods: {

    loadTable(){
  
        let startDate=moment(String(this.dateRange.startDate)).format("YYYY-MM-DD HH:mm:ss");
        let endDate=moment(String(this.dateRange.endDate)).format("YYYY-MM-DD HH:mm:ss");

        const baseURI = this.$settings.endPointCt + "real-monitoring/history-detail/"+this.vehicle_no+"/"+this.gps_vendor+"/"+startDate+"/"+endDate;
      //var columnFilters = this.serverParams.columnFilters;

        return this.$http
        .get(
           baseURI +
         `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}`,
        )
        .then((response) => {
          this.isLoading=false;
          this.rowsTable = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
        });

        // this.rowsTableOrder=val;
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadTable();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },


    EditFormModal(data) {
      this.detailTripAnalysis=data;
      this.showModalNow = !this.showModalNow;
    },

    closeMyModal() {
      this.showModalNow = false;
      this.$emit("afterSaveTripAnalysis");
      //this.loadData(this.vehicle_no);
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },
  },

  mounted() {
    this.loadTable();
  }
};
</script>
