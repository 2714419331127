<template>
    <div>
      <div class="loader" v-if="this.isLoading"></div>
      <div class="loadertext" v-if="this.isLoading"> {{ $t('loadingTxt') }}...</div>
        <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
          <sidebar-component classMenu="DashboardFullfilment"></sidebar-component>
          <div class="page-wrapper">
                  <!--begin::navbar-->
                  <navbar-component></navbar-component>
                  <!--end::navbar-->
                  <!--begin::content-->
                  <div class="page-content">
                      <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
                          <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
                              <div class="card">
                                  <div class="card-header">
                                      &nbsp;&nbsp;&nbsp;
                                      <h6
                                          class="card-title mb-0 float-start"
                                          style="padding-left: 25px"
                                      >
                                          Update Edit DO Number
                                      </h6>
                                      <button
                                          class="btn btn-default btn-sm float-end"
                                          @click="resetForm"
                                          type="button"
                                      >
                                          <i class="link-icon" data-feather="repeat"></i>
                                          {{ $t("resetFormTxt") }}
                                      </button>
                                  </div>
                                  <form
                                    class="forms-sample"
                                    @submit.prevent="submitData"
                                    method="POST"
                                  >
                                    <div class="card-body">
                                        <!--begin::loading-data-->
                                        <div v-if="isLoading" class="d-flex align-items-center">
                                            <div
                                            class="spinner-border ms-auto"
                                            role="status"
                                            aria-hidden="true"
                                            ></div>
                                            <strong>{{ $t("loadingTxt") }}...</strong>
                                        </div>
                                        <div
                                            class="
                                                d-flex
                                                justify-content-between
                                                align-items-baseline
                                                mb-2
                                            "
                                        ></div>
                                       
                                          <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                              <div class="row mb-3">
                                                <div class="col-lg-4">
                                                    <label
                                                    for="load_id"
                                                    class="col-form-label"
                                                    style="padding-left: 25px"
                                                    >Load Id</label
                                                    >
                                                </div>
                                                <div class="col-lg-5">
                                                    <input
                                                    type="text"
                                                    class="form-control"
                                                    id="load_id"
                                                    :placeholder="'Load Id'"
                                                    v-model="forms.load_id"
                                                    disabled
                                                    />
                                                </div>
                                              </div>
                                              <div class="row mb-3">
                                                  <div class="col-lg-4">
                                                      <label
                                                      class="col-form-label"
                                                      style="padding-left: 25px"
                                                      >Existing Order No</label
                                                      >
                                                  </div>
                                                  <div class="col-lg-5">
                                                      <input
                                                      type="text"
                                                      class="form-control"
                                                      :placeholder="'Existing Order No'"
                                                      :value="forms.old_order_no"
                                                      disabled
                                                      />
                                                  </div>
                                                  <div class="col-lg-2">
                                                    <button class="btn btn-small btn-success" type="button" @click="openModal">Browse</button>
                                                  </div>
                                              </div>
                                              <div class="row mb-3">
                                                  <div class="col-lg-4">
                                                      <label
                                                      class="col-form-label"
                                                      style="padding-left: 25px"
                                                      >  {{ $t("new_order_number_txt") }}</label
                                                      >
                                                  </div>
                                                  <div class="col-lg-5">
                                                      <input
                                                      type="text"
                                                      class="form-control"
                                                      :placeholder="$t('new_order_number_txt')"
                                                      v-model="forms.order_no"
                                                      />
                                                  </div>
                                              </div>
                                              <div class="row mb-3">
                                                <div class="col-lg-4">
                                                    <label
                                                    for="load_id"
                                                    class="col-form-label"
                                                    style="padding-left: 25px"
                                                    >{{ $t("status_tms_txt") }}</label
                                                    >
                                                </div>
                                                <div class="col-lg-5">
                                                    <input
                                                    type="text"
                                                    class="form-control"
                                                    id="status_tms"
                                                    :placeholder="$t('status_tms_txt')"
                                                    v-model="forms.status_tms"
                                                    disabled
                                                    />
                                                </div>
                                              </div>
                                              <div class="row mb-3">
                                                <div class="col-lg-4">
                                                    <label
                                                    for="customer"
                                                    class="col-form-label"
                                                    style="padding-left: 25px"
                                                    >Customer</label
                                                    >
                                                </div>
                                                <div class="col-lg-8">
                                                    <input
                                                    type="text"
                                                    class="form-control"
                                                    id="customer"
                                                    :placeholder="'Customer'"
                                                    v-model="forms.company_code"
                                                    disabled
                                                    />
                                                </div>
                                              </div>
                                              <div class="row mb-3">
                                                <div class="col-lg-4">
                                                    <label
                                                    for="load_id"
                                                    class="col-form-label"
                                                    style="padding-left: 25px"
                                                    >{{ $t("shipwith_ref_txt") }}</label>
                                                </div>
                                                <div class="col-lg-5">
                                                    <input
                                                    type="text"
                                                    class="form-control"
                                                    id="load_id"
                                                    :placeholder="$t('shipwith_ref_txt')"
                                                    v-model="forms.shipwith_ref"
                                                    disabled
                                                    />
                                                </div>
                                              </div>
                                              <div class="row mb-3">
                                                <div class="col-lg-4">
                                                    <label
                                                    for="load_id"
                                                    class="col-form-label"
                                                    style="padding-left: 25px"
                                                    >{{ $t("tonnage_txt") }}</label>
                                                </div>
                                                <div class="col-lg-5">
                                                    <input
                                                    type="text"
                                                    class="form-control"
                                                    id="load_id"
                                                    :placeholder="$t('tonnage_txt')"
                                                    v-model="forms.tonnage"
                                                    disabled
                                                    />
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                              <div class="row mb-3">
                                                <div class="col-lg-4">
                                                    <label
                                                    for="load_id"
                                                    class="col-form-label"
                                                    style="padding-left: 25px"
                                                    >Pick Plan</label
                                                    >
                                                </div>
                                                <div class="col-lg-5">
                                                    <input
                                                    type="text"
                                                    class="form-control"
                                                    id="load_id"
                                                    :placeholder="$t('pickup_plan_txt')"
                                                    v-model="forms.pick_plan"
                                                    disabled
                                                    />
                                                </div>
                                              </div>
                                              <div class="row mb-3">
                                                  <div class="col-lg-4">
                                                      <label
                                                      class="col-form-label"
                                                      style="padding-left: 25px"
                                                      >Pick Location</label
                                                      >
                                                  </div>
                                                  <div class="col-lg-5">
                                                      <input
                                                      type="text"
                                                      class="form-control"
                                                      :placeholder="$t('pickup_location_txt')"
                                                      :value="forms.pick_name"
                                                      disabled
                                                      />
                                                  </div>
                                              </div>
                                              <div class="row mb-3">
                                                  <div class="col-lg-4">
                                                      <label
                                                      class="col-form-label"
                                                      style="padding-left: 25px"
                                                      >  Dest Plan</label
                                                      >
                                                  </div>
                                                  <div class="col-lg-5">
                                                      <input
                                                      type="text"
                                                      class="form-control"
                                                      :placeholder="'Dest Plan'"
                                                      :value="forms.dest_plan"
                                                      disabled
                                                      />
                                                  </div>
                                              </div>
                                              <div class="row mb-3">
                                                <div class="col-lg-4">
                                                    <label
                                                    for="load_id"
                                                    class="col-form-label"
                                                    style="padding-left: 25px"
                                                    >Dest Location</label
                                                    >
                                                </div>
                                                <div class="col-lg-5">
                                                    <input
                                                    type="text"
                                                    class="form-control"
                                                    id="status_tms"
                                                    :placeholder="'Dest Location'"
                                                    v-model="forms.dest_name"
                                                    disabled
                                                    />
                                                </div>
                                              </div>
                                              <div class="row mb-3">
                                                <div class="col-lg-4">
                                                    <label
                                                    for="customer"
                                                    class="col-form-label"
                                                    style="padding-left: 25px"
                                                    >Vehicle No</label
                                                    >
                                                </div>
                                                <div class="col-lg-5">
                                                    <input
                                                    type="text"
                                                    class="form-control"
                                                    id="customer"
                                                    :placeholder="'Vehicle No'"
                                                    v-model="forms.vehicle_no"
                                                    disabled
                                                    />
                                                </div>
                                              </div>
                                              <div class="row mb-3">
                                                <div class="col-lg-4">
                                                    <label
                                                    for="load_id"
                                                    class="col-form-label"
                                                    style="padding-left: 25px"
                                                    >Driver</label>
                                                </div>
                                                <div class="col-lg-5">
                                                    <input
                                                    type="text"
                                                    class="form-control"
                                                    id="load_id"
                                                    :placeholder="'Driver'"
                                                    v-model="forms.driver_name"
                                                    disabled
                                                    />
                                                </div>
                                              </div>
                                              <div class="row mb-3">
                                                <div class="col-lg-4">
                                                    <label
                                                    for="load_id"
                                                    class="col-form-label"
                                                    style="padding-left: 25px"
                                                    >Carier</label>
                                                </div>
                                                <div class="col-lg-5">
                                                    <input
                                                    type="text"
                                                    class="form-control"
                                                    id="load_id"
                                                    :placeholder="'Carier'"
                                                    v-model="forms.carrier"
                                                    disabled
                                                    />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                    </div>
                                    <div class="card-footer">
                                        <button
                                            class="
                                            btn btn-warning
                                            text-black
                                            fw-bolder
                                            btn-icon-text
                                            "
                                            @click="backForm"
                                            type="button"
                                        >
                                            <i class="link-icon" data-feather="arrow-left"></i>
                                            {{ $t("backMess") }}
                                        </button>
  
                                        &nbsp;&nbsp;&nbsp;
                                        <button
                                            v-if="!isLoading"
                                            class="btn btn-success float-end btn-sm"
                                            type="submit"
                                        >
                                            <i class="link-icon" data-feather="save"></i>
                                            {{ $t("submitOrderTxt") }}
                                        </button>
                                        <a v-if="isLoading" class="spinner-border text-primary float-end me-2" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </a>
                                    </div>
                                  </form>
  
                              </div>
                          </div>
                      </div>
                      <modal-EditDoNumber
                          :showModal="showModal" 
                          @closeModal="closeMyModal"
                          @pushData="pushDatas">
                      </modal-EditDoNumber>
                  </div>
                  <!--end::content-->
                  <!--begin::footer-->
                  <footer-component></footer-component>
                  <!--end::footer-->
              </div>
        </div>
    </div>
  </template>
  
  <script>
  import sidebarComponent from "@/components/_partials/_sidebar";
  import navbarComponent from "@/components/_partials/_navbar";
  import footerComponent from "@/components/_partials/_footer";
  import ModalEditDoNumber from "@/components/modal/modalEditDoNumber.vue";
  export default {
    components: {
      "sidebar-component": sidebarComponent,
      "navbar-component": navbarComponent,
      "footer-component": footerComponent,
      "modal-EditDoNumber":ModalEditDoNumber,
    },
    data() {
      return {
        closeBtn: true,
        isLoading: false,
        errors: [],
        proformaInvoiceNo:"",
        showModal:false,
        loadId:[],
        forms:{},
        datas:[],
        permision_role:[],
      }
    },
    methods: {
      fetchIt() {
        const userDatas     = this.$getUserInfo();
        this.detailUser     = userDatas.sub;
        // this.permision_role = userDatas.sub.permision_role;
        let rolePermision = [];
        userDatas.sub.permision_role.forEach(function(permision){
          if(permision.permission.controller == 'DoNumberController'){
            rolePermision = permision;
          }
        });
          if(!rolePermision.method_create){
            this.$router.push("/authorized-error");
          }
        this.permision_role = rolePermision;
  
        if(this.$route.query.msg){
          if(this.$route.query.status == 200){
            this.success(this.$route.query.msg)
          }else{
            this.error(this.$route.query.msg)
          }
  
        }
  
      },
      resetForm(){
        this.datas=[];
        this.forms=[];
      },
      backForm(){
        window.location.href = "/edit-do-number";
      },
      pushDatas(row){
        this.showModal = false;
        // this.forms.load_id = row.load_id;
        // this.forms.order_number = row.order_no;
        // this.forms.status_tms = row.status_tms;
        // this.forms.company_name = row.company.name;
        // this.forms.company_code = row.company.code;
        // this.forms.tonnage = row.tonnage;
        // this.forms.shipwith_ref = row.shipwith_ref;
        // this.forms.pick_plan = row.pick_plan;
        // this.forms.pick_location = row.pick_name;
        // this.forms.dest_plan = row.dest_plan;
        // this.forms.dest_location = row.dest_name;
        // this.forms.vehicle_no = row.vehicle_no;  
        // this.forms.driver_name = row.driver_name;  
        // this.forms.driver_id = row.driver_id;  
        // this.forms.carrier = row.carrier;  

        this.forms.transport_order_header_id = row.transport_order_header_id;
        this.forms.load_id = row.load_id;
        this.forms.old_order_no = row.order_no;
        this.forms.status_tms = row.status_tms;
        this.forms.company_id = row.company.company_id;
        this.forms.company_code = row.company.code;
        this.forms.tonnage = row.tonnage;
        this.forms.shipwith_ref = row.shipwith_ref;
        this.forms.pick_plan = row.pick_plan;
        this.forms.pick_location_id = row.pick_location_id;
        this.forms.pick_name = row.pick_name;
        this.forms.dest_plan = row.dest_plan;
        this.forms.dest_location_id = row.dest_location_id;
        this.forms.dest_name = row.dest_name;
        this.forms.vehicle_no = row.vehicle_no;  
        this.forms.driver_name = row.driver_name;  
        this.forms.driver_id = row.driver_id;  
        this.forms.carrier = row.carrier;  
        this.forms.transport_order_template_id = row.transport_order_template_id;  
        // this.forms = row;
      },
      closeMyModal(){
        this.showModal = false;
      },
      openModal(){
        this.showModal = true;
      },
      cek_message(status, msg) {
        if (status == "200") {
          this.success(msg);
        }
      },
      submitData(){
        this.isLoading = true
        const baseURI = this.$settings.endPoint + "do-number/update/"+this.forms.do_number_id;
        this.$http
          .patch(baseURI, this.forms)
          .then((response) => {
            if (response.data.status === 200) {
              this.resetForm();
              this.errors = [];
              this.success('Berhasil');
              window.location.href = "/edit-do-number?status=200&msg=Successfully";
            } else {
              this.loading();
              this.errors = response.data.errors;
              this.resultError(response.data.errors);
            }
          })
          .catch((error) => {
            this.loading();
            if (error.response) {
              if (error.response.status === 422) {              
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
          });
      
        
      },
      resultError(data) {
        var count = Object.keys(data).length;
        for (var x = 0; x < count; x++) {
          var nameOb = Object.keys(data)[x];
          var objectData = data[nameOb];
          for (var y = 0; y < objectData.length; y++) {
            this.error(objectData[y]);
          }
        }
      },
      loading() {
        this.fade(true);
        setTimeout(() => {
          this.fade(false);
        }, 1000); // hide the message after 3 seconds
      },
      fade(sType) {
        this.isLoading = sType;
      },
      success(kata) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
          // onShown: function() {
          //     console.log('asdasdasd');
          //     //window.location.href = '/';
          // }
        });
  
        Toast.fire({
          icon: "success",
          title: kata,
        });
      },
  
      error(kata) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: kata,
        });
      },
      loadData(id) {
        this.isLoading = true
        const baseURI = this.$settings.endPoint + "do-number/detail/" + id;
        return this.$http.get(baseURI,{ timeout: 10000 }).then((response) => {
          this.isLoading = false
          this.forms      = response.data.datas;
        }).catch(error => {
            this.isLoading = false;
            if(error.code == "ECONNABORTED"){
              this.error("Please Check Your Internet Connection");
            }else{

              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
              
            }


          });
      },
    },
    mounted(){
      this.fetchIt()
      var params = this.$onBehind(this.$route.params.id);
      this.loadData(params[0]);
    }
  }
  </script>
  
  <style>
  
  </style>