<template>
  <div
    ref="modal"
    class="modal fade"
    :class="{ show: active, 'd-block': active }"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Form Edit Ontime Analysis | <b>Order Number : {{forms.order_number}}</b> </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            v-on:click="$emit('closeModal')"
            aria-label="btn-close"
          ></button>
        </div>
        <div class="modal-body">
            <form class="forms-sample" @submit.prevent="submitData" method="POST">
            <div
                class="d-flex justify-content-between align-items-baseline mb-2"
            ></div>

            <div class="row mb-3">
                <div class="col-lg-4">
                <label
                    for="defaultconfig"
                    class="col-form-label"
                    style="padding-left: 25px"
                    >{{ $t("vehicle_no") }}</label
                >
                </div>
                <div class="col-lg-8">
                <input
                    type="text"
                    class="form-control"
                    id="description"
                    v-model="forms.vehicle_no"
                    readonly
                />
                <div v-if="errors.vehicle_no">
                    <div
                    v-for="error in errors.vehicle_no"
                    :key="error"
                    class="alert alert-primary"
                    role="alert"
                    >
                    <i data-feather="alert-circle"></i>
                    {{ error }}
                    </div>
                </div>
                </div>
            </div>

            <div class="row mb-3">
              <div class="col-lg-4">
                <label
                    for="defaultconfig"
                    class="col-form-label"
                    style="padding-left: 25px"
                    >CT Time</label
                >
                </div>

                <div class="col-lg-8">
                     <date-range-picker
                style="width: 100%"
                control-container-class="form-control"
                :locale-data="locale"
                v-model="ct_time"
                opens="center"
                :singleDatePicker="true"
                :timePicker24Hour="true"
                :timePicker="true"
                :showWeekNumbers="false"
              >
              </date-range-picker>
                </div>
             
            </div>

            <div class="row mb-3">
                <div class="col-lg-4">
                <label
                    for="defaultconfig"
                    class="col-form-label"
                    style="padding-left: 25px"
                    >CT Status</label
                >
                </div>
                <div class="col-lg-8">
                <input
                    type="text"
                    class="form-control"
                    id="ct_status"
                    v-model="forms.ct_status"
                    
                />
                <div v-if="errors.ct_status">
                    <div
                    v-for="error in errors.ct_status"
                    :key="error"
                    class="alert alert-primary"
                    role="alert"
                    >
                    <i data-feather="alert-circle"></i>
                    {{ error }}
                    </div>
                </div>
                </div>
            </div>


            <div class="row mb-3">
                <div class="col-lg-4">
                <label
                    for="defaultconfig"
                    class="col-form-label"
                    style="padding-left: 25px"
                    >System Status</label
                >
                </div>
                <div class="col-lg-8">
                <input
                    type="text"
                    class="form-control"
                    id="system_status"
                    v-model="forms.system_status"
                    
                />
                <div v-if="errors.system_status">
                    <div
                    v-for="error in errors.system_status"
                    :key="error"
                    class="alert alert-primary"
                    role="alert"
                    >
                    <i data-feather="alert-circle"></i>
                    {{ error }}
                    </div>
                </div>
                </div>
            </div>


            <div class="row mb-3">
                <div class="col-lg-4">
                <label
                    for="defaultconfig"
                    class="col-form-label"
                    style="padding-left: 25px"
                    >Process</label
                >
                </div>
                <div class="col-lg-8">
                <input
                    type="text"
                    class="form-control"
                    id="process"
                    v-model="forms.process"
                    
                />
                <div v-if="errors.process">
                    <div
                    v-for="error in errors.process"
                    :key="error"
                    class="alert alert-primary"
                    role="alert"
                    >
                    <i data-feather="alert-circle"></i>
                    {{ error }}
                    </div>
                </div>
                </div>
            </div>


             <div class="row mb-3">
                <div class="col-lg-4">
                <label
                    for="defaultconfig"
                    class="col-form-label"
                    style="padding-left: 25px"
                    > {{ $t("remark") }}</label
                >
                </div>
                <div class="col-lg-8">
                <textarea class="form-control" v-model="forms.remark" ></textarea>
                <div v-if="errors.remark">
                    <div
                    v-for="error in errors.remark"
                    :key="error"
                    class="alert alert-primary"
                    role="alert"
                    >
                    <i data-feather="alert-circle"></i>
                    {{ error }}
                    </div>
                </div>
                </div>
            </div>

            <div class="modal-footer">
                <button
                type="button"
                class="btn btn-secondary"
                v-on:click="$emit('closeModal')"
                >
                {{ $t("close_txt") }}
                </button>
                <button class="btn btn-primary" type="submit">
                {{ $t("save_change_txt") }}
                </button>
            </div>
            </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";


export default {
  components: {
    DateRangePicker,
  },
  name: "ModalTurnOffTripAlert",
  emits: ["closeModal"],
  props: {
    showModal: Boolean,
    data: null,
  },
  watch: {
    showModal: {
      handler(newVal) {
        const startDate = this.data.ct_time==null ? new Date() : this.data.ct_time;
        const endDate = new Date();

        this.ct_time = {
            startDate,
            endDate,
        };

        this.forms = {
            ontime_analysis_id:this.data.ontime_analysis_id,
            ct_time:this.data.ct_time,
            process:this.data.process,
            system_status:this.data.system_status,
            ct_status:this.data.ct_status,
            remark:this.data.remark,
            vehicle_no: this.data.vehicle_no,
            order_number:this.data.order_number
          };

        this.locale = {
          format: "dd-mm-yyyy HH:MM:ss",
        };
       
        this.active = newVal;
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      active: this.showModal,
      forms: [],
      errors: [],
      locale: [],
      report_date: [],
      ct_time: "",
      delivery_date: "",
      type_error: [],
    };
  },
  methods: {
    resetForm() {
      this.forms = [];
    },

    submitData() {
      this.forms.ct_time =  moment(
        String(this.ct_time.startDate)
      ).format("YYYY-MM-DD HH:m:s");
      const baseURI =
        this.$settings.endPointCt + "ontime-analysis/update/" + this.forms.ontime_analysis_id;
      this.$http
        .patch(baseURI, this.forms)
        .then((response) => {
          
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];

            this.loadSuccess(response.data.datas.messages);
            this.$emit("closeModal");
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
        
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    // loadDetail(data) {
    //   console.log(data);
    // },

    resultError(data) {
      var html = "";
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          html += objectData[y] + "<br>";
        }
      }

      this.loadError(html);
    },

    loadError(pesan) {
      this.$swal({
        backdrop: true,
        title: "Warning",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },

    loadSuccess(pesan) {
      this.$swal({
        backdrop: true,
        title: "Success",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },
  },
  mounted() {
    //tail(this.data);
  },
};
</script>

<style scoped>
.modal {
  background: rgba(0, 0, 0, 0.4);
}
.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left:0px !important;
}
</style>