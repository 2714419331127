<template>
  <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title badge bg-primary">Productivity Month to Date</h5>

          <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal', null)"
            aria-label="btn-close"></button>
        </div>


        <div class="modal-body">
          <div class="card">

            <div class="card-body">
              <!-- Jakarta -->
              <div class="row mb-3">
                <div class="col-lg-6">
                  <table class="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>Branch</th>
                        <th>Type of Truck</th>
                        <th>Unit</th>
                        <th>Trip</th>
                        <th>Actual Capacity (Days)</th>
                        <th>Active Day</th>
                        <th>Productivity</th>
                      </tr>
                    </thead>
                    <tbody id="stocksTerminal">
                      <tr v-for="data, index in dataArrayJakarta" :key="data.id">
                        <td style="text-align: center;vertical-align:middle;" v-if="(index === 0)" :rowspan="dataArrayJakarta.length">{{ data.branch_name }}</td>
                        <td><button type="button" class="btn btn-success btn-xs" @click.prevent="openChild(data.truck_category,data.branch_id)">{{ data.truck_category }}</button></td>
                        <td style="text-align:right">{{ data.unit }}</td>
                        <td style="text-align:right">{{ data.trip_blujay }}</td>
                        <td style="text-align:right">{{ data.trip }}</td>
                        <td style="text-align:right">{{ data.target_trip }}</td>
                        <td style="text-align:right">{{ data.productivity }} %</td>
                      </tr>
                      <tr>
                        <td colspan="6" style="text-align:center">AVERAGE</td>
                        <td style="text-align:right">{{ avgJakarta}} %</td>
                      </tr>
                    </tbody>
                  </table>


                </div>
                <div class="col-lg-6">
                  <LineChartGenerator :chart-options="chartOptions" :chart-data="chartJakarta" :chart-id="chartId"
                    :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses" 
                    :width="width" :height="height" />

                  <div v-if="this.availableChartDataKeSatu"
                    style="text-align: center; left: 0; top: 100px; z-index: 20;color: black;">
                    <b>{{ $t("no_data_available_txt") }}</b>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <span style="height:80px;">
                  <hr />
                </span>
              </div>

              <!-- Surabaya -->
              <div class="row mb-3">
                <div class="col-lg-6">
                  <table class="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>Branch</th>
                        <th>Type of Truck</th>
                        <th>Unit</th>
                        <th>Trip</th>
                        <th>Actual Capacity (Days)</th>
                        <th>Active Day</th>
                        <th>Productivity</th>
                      </tr>
                    </thead>
                    <tbody id="stocksTerminal">
                      <tr v-for="data, index in dataArraySurabaya" :key="data.id">
                        <td style="text-align: center;vertical-align:middle;" v-if="(index === 0)" :rowspan="data.branch_name.length">{{ data.branch_name }}</td>
                        <td><button type="button" class="btn btn-success btn-xs" @click.prevent="openChild(data.truck_category,data.branch_id)">{{ data.truck_category }}</button></td>
                        <td style="text-align:right">{{ data.unit }}</td>
                        <td style="text-align:right">{{ data.trip_blujay }}</td>
                        <td style="text-align:right">{{ data.trip }}</td>
                        <td style="text-align:right">{{ data.target_trip }}</td>
                        <td style="text-align:right">{{ data.productivity }} %</td>
                      </tr>
                      <tr>
                        <td colspan="5" style="text-align:center"> AVERAGE</td>
                        <td style="text-align:right">{{ avgSurabaya}} %</td>
                      </tr>
                    </tbody>
                  </table>


                </div>
                <div class="col-lg-6">
                  <LineChartGenerator :chart-options="chartOptions" :chart-data="chartSurabaya" :chart-id="chartId"
                    :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses" 
                    :width="width" :height="height" />

                  <div v-if="this.availableChartDataKeSatu"
                    style="text-align: center; left: 0; top: 100px; z-index: 20;color: black;">
                    <b>{{ $t("no_data_available_txt") }}</b>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <span style="height:80px;">
                  <hr />
                </span>
              </div>

              <!-- Medan -->
              <div class="row mb-3">
                <div class="col-lg-6">
                  <table class="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>Branch</th>
                        <th>Type of Truck</th>
                        <th>Unit</th>
                        <th>Trip</th>
                        <th>Actual Capacity (Days)</th>
                        <th>Active Day</th>
                        <th>Productivity</th>
                      </tr>
                    </thead>
                    <tbody id="stocksTerminal">
                      <tr v-for="data, index in dataArrayMedan" :key="data.id">
                        <td style="text-align: center;vertical-align:middle;" v-if="(index === 0)" :rowspan="data.branch_name.length">{{ data.branch_name }}</td>
                        <td><button type="button" class="btn btn-success btn-xs" @click.prevent="openChild(data.truck_category,data.branch_id)">{{ data.truck_category }}</button></td>
                        <td style="text-align:right">{{ data.unit }}</td>
                        <td style="text-align:right">{{ data.trip_blujay }}</td>
                        <td style="text-align:right">{{ data.trip }}</td>
                        <td style="text-align:right">{{ data.target_trip }}</td>
                        <td style="text-align:right">{{ data.productivity }} %</td>
                      </tr>
                    </tbody>
                  </table>


                </div>
                <div class="col-lg-6">
                  <LineChartGenerator :chart-options="chartOptions" :chart-data="chartMedan" :chart-id="chartId"
                    :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses" 
                    :width="width" :height="height" />

                  <div v-if="this.availableChartDataKeSatu"
                    style="text-align: center; left: 0; top: 100px; z-index: 20;color: black;">
                    <b>{{ $t("no_data_available_txt") }}</b>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <span style="height:80px;">
                  <hr />
                </span>
              </div>

              <!-- Semarang -->
              <div class="row mb-3">
                <div class="col-lg-6">
                  <table class="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>Branch</th>
                        <th>Type of Truck</th>
                        <th>Unit</th>
                        <th>Trip</th>
                        <th>Actual Capacity (Days)</th>
                        <th>Active Day</th>
                        <th>Productivity</th>
                      </tr>
                    </thead>
                    <tbody id="stocksTerminal">
                      <tr v-for="data,index in dataArraySemarang" :key="data.id">
                        <td style="text-align: center;vertical-align:middle;" v-if="(index === 0)" :rowspan="data.branch_name.length">{{ data.branch_name }}</td>
                        <td><button type="button" class="btn btn-success btn-xs" @click.prevent="openChild(data.truck_category,data.branch_id)">{{ data.truck_category }}</button></td>
                        <td style="text-align:right">{{ data.unit }}</td>
                        <td style="text-align:right">{{ data.trip_blujay }}</td>
                        <td style="text-align:right">{{ data.trip }}</td>
                        <td style="text-align:right">{{ data.target_trip }}</td>
                        <td style="text-align:right">{{ data.productivity }} %</td>
                      </tr>
                      <tr>
                        <td colspan="5" style="text-align:center"> AVERAGE</td>
                        <td style="text-align:right">{{ avgSemarang}} %</td>
                      </tr>
                    </tbody>
                  </table>


                </div>
                <div class="col-lg-6">
                  <LineChartGenerator :chart-options="chartOptions" :chart-data="chartSemarang" :chart-id="chartId"
                    :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses" 
                    :width="width" :height="height" />

                  <div v-if="this.availableChartDataKeSatu"
                    style="text-align: center; left: 0; top: 100px; z-index: 20;color: black;">
                    <b>{{ $t("no_data_available_txt") }}</b>
                  </div>
                </div>
              </div>

            </div>


          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal', null)">
            {{ $t("close_txt") }}
          </button>
        </div>

        <modal-productivity-detail-child :showModal="showModalDetailChild" :data="detailDataDetailChild"
            @closeModalChild="closeChildModal"></modal-productivity-detail-child>

      </div>
    </div>
  </div>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import modalProductivityDetailChild from "@/components/modal/modalProductivityNasionalChild.vue";

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, LineElement, ArcElement, CategoryScale, LinearScale, PointElement, Filler } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, LineElement, ArcElement, CategoryScale, LinearScale, PointElement, Filler, ChartDataLabels);


export default {  
  components: {
    LineChartGenerator,
    "modal-productivity-detail-child": modalProductivityDetailChild,
  },
  
  name: "DetailProductivity",
  emits: ["closeModal"],
  props: {   
    
    showModal: Boolean,
    data: {},
    chartId: {
      type: String,
      default: 'line-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 500
    },
    height: {
      type: Number,
      default: 500
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => { }
    },
    plugins: {
      type: Array,
      default: () => { }
    },

    options: {
      tooltips: {
        enabled: false
      },
    },
  },
  watch: {
    showModal: {      
      handler(newVal) {
        // console.log(newVal);
        this.loadItems(newVal);        
        this.active = newVal;
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");

      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      active: this.showModal,

      opens: "center",
      locale: {
        format: "yyyy-mm-dd",
      },

      showModalDetailChild : false,
      detailDataDetailChild : {},

      permision_role: [],
      dataArrayJakarta: [],
      dataArraySurabaya: [],
      dataArrayMedan: [],
      dataArraySemarang: [],
      avgJakarta:0,
      avgSurabaya:0,
      avgMedan:0,
      avgSemarang:0,
      countData: [],
      dateFilter: {
      },
      availableChartDataKeSatu: false,
      chartJakarta: {
        labels: [],
        datasets: [
          {
            label: 'Productivity (%)',
            borderColor: '#0eace6',
            backgroundColor: '#0eace6', //#EF8F15'
            data: []
          },
          {
            datalabels: {
              display: function () {
                return '';
              },
            },
            label: 'Target(%)',
            borderColor: '#EF8F15',
            backgroundColor: '#EF8F15', //#EF8F15'
            data: [98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98]
          }
        ],
      },
      chartSurabaya: {
        labels: [],
        datasets: [
          {
            label: 'Productivity (%)',
            borderColor: '#0eace6',
            backgroundColor: '#0eace6', //#EF8F15'
            data: []
          },
          {
            datalabels: {
              display: function () {
                return '';
              },
            },
            label: 'Target(%)',
            borderColor: '#EF8F15',
            backgroundColor: '#EF8F15', //#EF8F15'
            data: [98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98]
          }
        ]
      },
      chartMedan: {
        labels: [],
        datasets: [
          {
            label: 'Productivity (%)',
            borderColor: '#0eace6',
            backgroundColor: '#0eace6', //#EF8F15'
            data: []
          },
          {
            datalabels: {
              display: function () {
                return '';
              },
            },
            label: 'Target(%)',
            borderColor: '#EF8F15',
            backgroundColor: '#EF8F15', //#EF8F15'
            data: [98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98]
          }
        ]
      },
      chartSemarang: {
        labels: [],
        datasets: [
          {
            label: 'Productivity (%)',
            borderColor: '#0eace6',
            backgroundColor: '#0eace6', //#EF8F15'
            data: []
          },
          {
            datalabels: {
              display: function () {
                return '';
              },
            },
            label: 'Target(%)',
            borderColor: '#EF8F15',
            backgroundColor: '#EF8F15', //#EF8F15'
            data: [98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98]
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'right',
            color: 'green',
            formatter: function (value) {
              return Math.round(value) + '%';
            },
            font: {
              weight: 'bold',
              size: 12,
            }
          },

        }
      },

    };
  },
  computed: {
        myStyles() {
            return {
                height: '100%',
                position: 'relative'
            }
        }
    },
  methods: {
    closeChildModal(){
            this.showModalDetailChild = false;
            this.detailDataDetailChild = {};
        },
    openChild(truck_category,branch_id){
      let tgl = this.data['date']; 
      const baseURI = this.$settings.endPointCt + "truck-productivity/dashboard-detail-child?date=" + tgl + "&truck_category="+ truck_category + "&branch_id="+ branch_id;
            return this.$http.get(baseURI).then((response) => {
                this.detailDataDetailChild = response.data.datas;
                this.showModalDetailChild = true;
            }).catch(error => {
                if (error.code == "ECONNABORTED") {
                    this.error(this.$t("check_internet_connection_txt"));
                } else {

                    if (error.response) {
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors;
                            this.resultError(error.response.data.errors);
                        } else if (error.response.status === 500) {
                            this.$router.push('/server-error');
                        } else if (error.response.status === 401) {
                            this.$router.push("/authorized-error");
                        } else {
                            this.$router.push('/page-not-found');
                        }
                    }

                }

            });
    },
    submitParent(index, row) {
      this.$emit("closeModal", row);
    },
    loadItems(newVal) {
      if(newVal){
          this.dataArrayJakarta = this.data.filter(i => i.branch_name === 'JAKARTA');
          let tripJakarta = 0;
          let targetJakarta = 0;
          for(let item of this.dataArrayJakarta){
            tripJakarta += Number(item.trip);
            targetJakarta += Number(item.target_trip);
          }
          this.avgJakarta = (tripJakarta/targetJakarta * 100).toFixed(1);

          this.dataArraySurabaya = this.data.filter(i => i.branch_name === 'SURABAYA');
          let tripSurabaya = 0;
          let targetSurabaya = 0;
          for(let item of this.dataArraySurabaya){
            tripSurabaya += Number(item.trip);
            targetSurabaya += Number(item.target_trip);
          }
          this.avgSurabaya = (tripSurabaya/targetSurabaya * 100).toFixed(1);

          this.dataArrayMedan = this.data.filter(i => i.branch_name === 'MEDAN');
          let i = 0;
          for(let item of this.dataArrayMedan){
            i += item.productivity;
          }
          this.avgMedan = (i/this.dataArrayMedan.length).toFixed(1);

          this.dataArraySemarang = this.data.filter(i => i.branch_name === 'SEMARANG');
          let tripSemarang = 0;
          let targetSemarang = 0;0;
          for(let item of this.dataArraySemarang){
            tripSemarang += Number(item.trip);
            targetSemarang += Number(item.target_trip);
          }
          this.avgSemarang = (tripSemarang/targetSemarang * 100).toFixed(1);

          this.chartJakarta.labels = this.dataArrayJakarta.map(item => item.truck_category);
          this.chartSurabaya.labels = this.dataArraySurabaya.map(item => item.truck_category);
          this.chartMedan.labels = this.dataArrayMedan.map(item => item.truck_category);
          this.chartSemarang.labels = this.dataArraySemarang.map(item => item.truck_category);
          this.chartJakarta.datasets[0].data = this.dataArrayJakarta.map(item => item.productivity);
          this.chartSurabaya.datasets[0].data = this.dataArraySurabaya.map(item => item.productivity);
          this.chartMedan.datasets[0].data = this.dataArrayMedan.map(item => item.productivity);
          this.chartSemarang.datasets[0].data = this.dataArraySemarang.map(item => item.productivity);
      }else{
          this.dataArrayJakarta = [];
          this.dataArraySurabaya = [];
          this.dataArrayMedan = [];
          this.dataArraySemarang = [];
          this.chartJakarta.labels = [];
          this.chartSurabaya.labels = [];
          this.chartMedan.labels = [];
          this.chartSemarang.labels = [];
          this.chartJakarta.datasets[0].data = [];
          this.chartSurabaya.datasets[0].data = [];
          this.chartMedan.datasets[0].data = [];
          this.chartSemarang.datasets[0].data = [];
      }
          
          
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); 
    },
  },
  mounted() {    
    this.loadItems();
  },
};
</script>

<style scoped>
.blur-content {
  filter: blur(5px);
  pointer-events: none;
}

.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 120%;
  height: 100%;
  margin-left: -10%;
}
</style>