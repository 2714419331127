import GTUserMappingIndex from "@/components/dasboard-gt/user-mapping/GTUserMappingIndex.vue";
import GTUserMappingCreate from "@/components/dasboard-gt/user-mapping/GTUserMappingCreate.vue";
import GTUserMappingDetail from "@/components/dasboard-gt/user-mapping/GTUserMappingDetail.vue";
import GTUserMappingEdit from "@/components/dasboard-gt/user-mapping/GTUserMappingEdit.vue";

let routesUserMappingGT= [
    {
        path: '',
        meta: {
          title: "Gate System",
        },
        name: "GTUserMappingIndex",
        component: GTUserMappingIndex
    },
    {
        path: 'create',
        meta: {
          title: "Gate System",
        },
        name: "GTUserMappingCreate",
        component: GTUserMappingCreate
    },
     
    {
        path: 'detail/:id',
        meta: {
          title: "Gate System",
        },
        name: "GTUserMappingDetail",
        component: GTUserMappingDetail
    },
     
    {
        path: 'edit/:id',
        meta: {
          title: "Gate System",
        },
        name: "GTUserMappingEdit",
        component: GTUserMappingEdit
    },
    
]

export default routesUserMappingGT
  