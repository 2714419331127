<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TemplateOrderEdit"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 25px"
                  >
                    Template Order
                  </h6>
                  <button
                    class="btn btn-default float-end"
                    @click="resetForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>

                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->
                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "
                    >
                    
                    </div>
                    

                     <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("companyCode") }}</label
                        >
                      </div>
                      <div class="col-lg-4">
                    
                        <v-select
                          v-if="companyCode == 'LINC'"
                          :options="fetchCompany"
                          v-model="company"
                          label="code"
                          return-object
                          @search="asyncSearchCompany"
                        
                        ></v-select>

                        <v-select
                          v-if="companyCode != 'LINC'"
                          :options="fetchCompanyNotLinc"
                          v-model="company"
                          label="code"
                          return-object
                          @search="asyncSearchCompanyNotLinc"
                         
                        ></v-select>

                        <div v-if="errors.company_id">
                          <div
                            v-for="error in errors.company_id"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                          $t('descriptionTxt')
                        }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="description"
                          :placeholder="$t('descriptionTxt')"
                          v-model="forms.description"
                          required
                        />
                        <div v-if="errors.description">
                          <div
                            v-for="error in errors.description"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Order Group</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="order_group"
                          placeholder="order group"
                          v-model="forms.order_group"
                        />
                        <div v-if="errors.order_group">
                          <div
                            v-for="error in errors.order_group"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Order Type</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="order_type"
                          placeholder="Order Type"
                          v-model="forms.order_type"
                        />
                        <div v-if="errors.order_type">
                          <div
                            v-for="error in errors.order_type"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Direction Category</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="direction_category"
                          placeholder="Direction Category"
                          v-model="forms.direction_category"
                        />
                        <div v-if="errors.direction_category">
                          <div
                            v-for="error in errors.direction_category"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Sales Group</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="sales_group"
                          placeholder="Sales Group"
                          v-model="forms.sales_group"
                        />
                        <div v-if="errors.sales_group">
                          <div
                            v-for="error in errors.sales_group"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Workflow</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="workflow"
                          placeholder="Workflow"
                          v-model="forms.workflow"
                        />
                        <div v-if="errors.workflow">
                          <div
                            v-for="error in errors.workflow"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Billable Method</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="billable_method"
                          placeholder="billable_method"
                          v-model="forms.billable_method"
                        />
                        <div v-if="errors.billable_method">
                          <div
                            v-for="error in errors.billable_method"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                          <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Tms Destination</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="tms_destination"
                          placeholder="Tms Destination"
                          v-model="forms.tms_destination"
                        />
                        <div v-if="errors.tms_destination">
                          <div
                            v-for="error in errors.tms_destination"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Tonnage</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="number"
                          class="form-control"
                          id="tonnage"
                          placeholder="Tonnage"
                          v-model="forms.tonnage"
                        />
                        <div v-if="errors.tonnage">
                          <div
                            v-for="error in errors.tonnage"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Cubic</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="number"
                          class="form-control"
                          id="cubic"
                          placeholder="Cubic"
                          v-model="forms.cubic"
                        />
                        <div v-if="errors.cubic">
                          <div
                            v-for="error in errors.cubic"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row card">
                      <div class="card-header">
                        <h5 class="float-start">Detail <span class="wajib">*</span></h5>

                        <button
                        class="
                          btn btn-success btn-icon
                          text-white
                          fw-bolder
                          btn-icon-text
                          float-end
                        "
                        @click="addField()"
                        type="button"
                      > <i class="link-icon" data-feather="plus"></i>
                    </button>
                        
                      </div>

                      <div class="card-body">
                        <div class="table-responsive">
                          <table
                            class="table table-hover"
                            style="height: 300px"
                          >
                            <thead>
                              <tr>
                                <th>#</th>
                                <th width="300px">SKU</th>
                                <th width="200px">SKU Description</th>
                                <th>Qty</th>
                                <th>Package Type</th>
                                <th>Volume</th>
                                <th>Weight</th>
                                <th>Net Weight</th>
                             
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(input, index) in this.detailAll"
                                :key="`phoneInput-${index}`"
                              >
                                <th>{{ index + 1 }}</th>
                                <td width="200px">
                                  <!-- <v-select
                                    :options="fetchSKU"
                                    v-model="input.sku"
                                    label="sku_code"
                                    return-object
                                    @search="asyncSearchSKU"
                                    @input="cekData(index, detailAll)"
                                  ></v-select> -->
                                   <multiselect
                                  @search-change="asyncSearchSKU"
                                  v-model="input.sku"
                                  :options="fetchSKU"
                                  @input="cekData(index, detailAll)"
                                  label="sku_code"
                                  track-by="sku_code"
                                  placeholede="Please Select SKU"
                                >
                                  <span slot="noResult">-</span>
                                </multiselect>
                                </td>
                                <td  width="200px">{{ input.sku_description }}</td>
                                <td>
                                  <input
                                    type="number"
                                    v-model="input.qty"
                                    class="form-control"
                                    style="width: 100px"
                                   
                                  />
                                </td>

                                <td>
                                  <v-select
                                    :options="fetchpackageType"
                                    v-model="input.package_type"
                                    label="package_type"
                                    return-object
                                  
                                  ></v-select>

                                </td>


                                
                                <td>
                                 <input
                                    type="number"
                                    v-model="input.volume"
                                    class="form-control"
                                    style="width: 100px"
                                   
                                  />
                                </td>
                                <td>
                                 <input
                                    type="number"
                                    v-model="input.weight"
                                    class="form-control"
                                    style="width: 100px"
                                   
                                  />
                                </td>
                                <td>
                                 <input
                                    type="number"
                                    v-model="input.net_weight"
                                    class="form-control"
                                    style="width: 100px"
                                   
                                  />
                                </td>
                              
                                <td>
                                 

                                  <!--          Remove Svg Icon-->
                                  <svg
                                  
                                    @click="removeField(index, detailAll)"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    class="ml-2 cursor-pointer"
                                  >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path
                                      fill="red"
                                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                                    />
                                  </svg>
                                </td>
                              </tr>
                            </tbody>

                            <tfoot></tfoot>
                          </table>
                        </div>
                      </div>
                    </div>


                   
                    <div class="card-footer">
                     <button
                        class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        "
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                      
                      &nbsp;&nbsp;&nbsp;
                      <button class="btn btn-success float-end btn-sm" type="submit">
                           <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import multiselect from "vue-multiselect";

export default {
  name: "TemplateOrderEdit",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "multiselect":multiselect,
  },
  data() {
    return {
      detailAll: [],
      fetchSKU:[],
      fetchpackageType:[],
      fetchCompany: [],
      fetchCompanyNotLinc: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      permision_role: [],
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      company: [],
      forms: [],
    };
  },
  watch: {},
  methods: {
    resetForm() {
      this.forms.description = "";
      this.forms.order_group = "";
      this.forms.order_type = "";
      this.forms.direction_category="";
      this.sales_group="";
      this.workflow="";
      this.company="";
    },


    addField() {
      this.detailAll.push({
        barcode:"", 
        sku_code:"", 
        sku_description:"", 
        qty:0, 
        package_type:"", 
        weight:0, 
        net_weight:10, 
        volume: 10, 
        commodity_id:"",
      })
    },
    

    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },

    cekData(index, fieldType) {
      
          fieldType[index].sku_description=fieldType[index].sku.sku_description;
          fieldType[index].barcode = fieldType[index].sku.barcode;
          fieldType[index].commodity_id = fieldType[index].sku.commodity_id;

          fieldType[index].weight =0;
          fieldType[index].volume = 0;
          fieldType[index].net_weight = 0;
          fieldType[index].qty = 0;  


      this.loadLadingType(fieldType[index].sku.sku_id);
    },


    loadLadingType(sku_id) {
      const baseURI = this.$settings.endPoint + "package/select-package-type/"+sku_id;

      return this.$http.get(baseURI).then((response) => {
    
        this.fetchladingType=response.data.datas;
        this.fetchpackageType=response.data.datas;

      });
    },


    loadSKU(ev) {
  
      const baseURI =
        this.$settings.endPoint + `sku?company_name=` + ev.name;

      return this.$http.get(baseURI).then((response) => {
        this.fetchSKU = response.data.datas.data;
      });
    },

    asyncSearchSKU(ev) {
      const baseURI =
        this.$settings.endPoint +
        "sku?sku_code=" +
        ev +
        "&company_name=" +
        this.company.name;
      return this.$http.get(baseURI).then((response) => {
        this.fetchSKU = response.data.datas.data;
      });
    },

    backForm() {
       window.location.href = "/order/transport-template";
    },

    loadData(id) {
      const baseURI = this.$settings.endPoint + "transport-order-template/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        
        this.forms = response.data.datas;
        this.company = this.forms.company;
       
        this.loadSKU(this.company);

        this.forms.transport_order_detail_template.forEach((item) => {
          this.loadLadingType(item.sku.sku_id);
          const data={

                sku:{
                  sequence_no:"",
                  sku_id:item.sku_id,
                  barcode:item.barcode,
                  sku_code:item.sku_code,
                  sku_description:item.sku_description,
                  sku_long_description:item.sku_long_description,
                
                  commodity_id:item.commodity_id,
                },
                sku_description:item.sku_description,
              
                package_type: {package_type:item.package_type}, 
                volume: item.volume, 
                weight: item.weight,
                net_weight: item.net_weight, 
                
                qty:item.qty, 


          }
          
          this.detailAll.push(data);
        })

        // this.form={ name: 'asep', usernmae:res.username };
      });
    },


    loadCompany() {
      const baseURI = this.$settings.endPoint + "company?limit=10";

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },

    loadCompanyNotLinc() {
      const baseURI =
        this.$settings.endPoint + `company?code=${this.companyCode}`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },

    asyncSearchCompany(ev) {
      const baseURI = this.$settings.endPoint + "company?code=" + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },

    asyncSearchCompanyNotLinc(ev) {
      const baseURI =
        this.$settings.endPoint +
        "company?name=" +
        ev +
        "&code=" +
        this.companyCode;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
       this.companyCode = this.detailUser.company_detail.code;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "TransportOrderTemplateController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_update) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    submitData() {

      const items = [];

      this.detailAll.forEach((item) => {
        const data = {
          sku_id: item.sku.sku_id,
          barcode: item.sku.barcode,
          sku_code: item.sku.sku_code,
          sku_description: item.sku.sku_description,
          qty: item.qty,
          package_type: item.package_type.package_type,
          weight: item.weight,
          net_weight: item.net_weight,
          volume: item.volume,
          commodity_id: item.sku.commodity_id,
        };
        items.push(data);
      });

      this.fade(true);
      // let params = this.$onBehind(this.$route.params.id);
      // let formData = new FormData();
      // formData.append("name", this.forms.name);
      // formData.append("username", this.forms.username);
      // formData.append("password", this.forms.password);
      // formData.append("email", this.forms.email);
      // formData.append("company_id", this.forms.company_id);
      // formData.append("role_id", this.forms.role_id);
      // formData.append("status", 'active');
      this.forms.company_id = this.company.company_id;
      this.forms.transport_order_detail_template = items;
      const baseURI =
        this.$settings.endPoint + "transport-order-template/update/" + this.forms.transport_order_template_id;
      this.$http
        .patch(baseURI, this.forms)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            this.success(response.data.datas.messages);

            //window.history.back();
            var params = this.$onRandom(this.forms.transport_order_template_id);
            window.location.href = "/order/transport-template/detail/" + params + "?status=200&msg=Successfully";
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.loadData(params[0]);
    this.loadCompany();
    this.loadCompanyNotLinc();
    this.fetchIt();
  },
};
</script>
<style scoped>
</style>