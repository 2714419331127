<template>
    <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Form Driver ID</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
              aria-label="btn-close"></button>
          </div>
  
          <form class="forms-sample" method="POST" @submit.prevent="submitData">
            <div class="modal-body">
              <div v-if="isLoading" class="d-flex align-items-center">
                <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                <strong>Loading...</strong>
              </div>
              <!--end::loading-data-->  
              
            <div class="mb-3">
                <label class="form-label">Driver Number</label>
                <input type="text" id="driver_number" name="driver_number" v-model="forms.driver_number" class="form-control"
                     />
            </div>

          

            <div class="mb-3">
                <label class="form-label">NMK</label>
                <input type="text" id="nmk" name="nmk" v-model="forms.nmk" class="form-control"
                     />
            </div>
  
            
  
             
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
                Close
              </button>
              <button class="btn btn-primary" type="submit">Save changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>


  export default {
    

    name: "ModalDriverID",
    emits: ["closeModal"],
    props: {
      showModal: Boolean,
      data: null,
      master_driver_id: null,
    },
    watch: {
      showModal: {
        handler(newVal) {
          if (this.data != '') {
            console.log(this.data.master_driver_id);
            this.forms = {
              driver_nmk_id:this.data.driver_nmk_id,
              driver_number: this.data.driver_number,
              master_driver_id: this.data.master_driver_id,
              nmk: this.data.nmk,
              jenis: 'edit'
            };
  
          
  
          } else {
  
            this.forms = {
              driver_number: "",
              master_driver_id: this.data.master_driver_id,
              nmk: this.data.nmk,
              jenis: 'add'
            };
            
          }
  
        
          this.active = newVal;
          const body = document.querySelector("body");
          this.showModal
            ? body.classList.add("modal-open")
            : body.classList.remove("modal-open");
        },
        immediate: true,
        deep: true,
      },
    },
    data() {
      return {
        isLoading: false,
        active: this.showModal,
        forms: [],
        locale: [],
        return_date: [],
        delivery_date: [],
        equipment_name: [],
        fetchEquipmentName : [],
      };
    },
    methods: {
      resetForm() {
        this.forms = [];
      },
  
      submitData() {
        
        console.log(this.forms);
        if (this.forms.jenis == 'add') {
          //this.forms.master_driver_id = this.master_driver_id;
     

          const baseURI = this.$settings.endPointCt + "driver-nmk/create";
          this.$http
            .post(baseURI, this.forms)
            .then((response) => {
              this.isloading = true;
              if (response.data.status === 200) {
                this.resetForm();
                this.$emit("closeModal");
                this.loadSuccess("Success");
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.isloading = true;
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                }
              }
              //this.resetForm();
            });
  
        } else {
     
         

          const baseURI = this.$settings.endPointCt + "driver-nmk/update" + "/" + this.forms.driver_nmk_id;
          this.$http
            .patch(baseURI, this.forms)
            .then((response) => {
              this.isloading = true;
              if (response.data.status === 200) {
                this.resetForm();
                this.$emit("closeModal");
                this.loadSuccess("Success");
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.isloading = true;
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                }
              }
              //this.resetForm();
            });
        }
  
      },
  
      // loadDetail(data) {
      //   console.log(data);
      // },
  
      resultError(data) {
        var html = "";
        var count = Object.keys(data).length;
        for (var x = 0; x < count; x++) {
          var nameOb = Object.keys(data)[x];
          var objectData = data[nameOb];
          for (var y = 0; y < objectData.length; y++) {
            html += objectData[y] + "<br>";
          }
        }
  
        this.loadError(html);
      },
  
      loadError(pesan) {
        this.$swal({
          backdrop: true,
          title: "Warning",
          html: pesan,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      },
  

  
      loadSuccess(pesan) {
        this.$swal({
          backdrop: true,
          title: "Success",
          html: pesan,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      },
  

    },
    created: function () {
      //this.loadDriver();
  
    },
    mounted() {
      //tail(this.data);
     
    },
  };
  </script>
  
  <style scoped>
  .modal {
    background: rgba(0, 0, 0, 0.4);
  }
  
  .modal-content {
    background: #fff;
    border-radius: 0px;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 0px;
    width: 100%;
    height: 100%;
    margin-left:0px !important;
  }
  </style>