<template>
  <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Approval Request Reschedule</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
            aria-label="btn-close"></button>
        </div>

        <form class="forms-sample" method="POST" @submit.prevent="submitData">
          <div class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <p class="text-muted mb-3">Information</p>
								<div class="table-responsive">
										<table class="table table-hover">
											<thead>
												<tr>
													<th>#</th>
													<th>Existing</th>
													<th>Request</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<th>Pick Plan</th>
													<td>{{ data.pick_plan }}</td>
													<td>{{ data?.request_reschedule?.pick_plan }}</td>
												</tr>
												<tr>
													<th>Dest Plan</th>
													<td>{{ data.dest_plan }}</td>
													<td>{{ data?.request_reschedule?.dest_plan }}</td>
												</tr>												
											</tbody>
										</table>
								</div>
            </div>

            <div class="col-md-6">
              <div class="mb-3">              
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="forms.approval" type="radio" id="inlineCheckbox1" value="yes">
                  <label class="form-check-label" for="inlineCheckbox1">APPROVE</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="forms.approval" type="radio" id="inlineCheckbox2" value="no">
                  <label class="form-check-label" for="inlineCheckbox2">REJECT</label>
                </div>
                <div v-if="errors.approval">
                  <div v-for="error in errors.approval" :key="error" class="alert alert-primary" role="alert">
                    <i data-feather="alert-circle"></i>
                    {{ error }}
                  </div>
                </div>
              </div>

              <div class="mb-3" v-if="data.request_reschedule_by === 'chain' && forms.approval === 'no'">
                <label class="form-label">{{
                        $t("reject_description_txt")
                      }}</label>  
                  <v-select
                  :options="fetchSystemCode"
                  :placeholder=" $t('type_reject_description_txt')"
                  v-model="reject_reason"
                  label="value"
                  :required="!reject_reason"
                  return-object
                  @search="asyncSearchSystemCode"
                  ></v-select>
                  
  
                  <div v-if="errors.reject_reason">
                  <div
                    v-for="error in errors.reject_reason"
                    :key="error"
                    class="alert alert-primary"
                    role="alert"
                  >
                    <i data-feather="alert-circle"></i>
                    {{ error }}
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <label class="form-label">Notes:</label>
                <textarea class="form-control" v-model="forms.description" placeholder="">
                </textarea>
                <div v-if="errors.description">
                  <div v-for="error in errors.description" :key="error" class="alert alert-primary" role="alert">
                    <i data-feather="alert-circle"></i>
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>

            
          </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
              {{ $t("close_txt") }}
            </button>
            <button class="btn btn-primary" type="submit"><span v-if="isLoading" class="spinner-border spinner-border-sm ms-auto" role="status"
              aria-hidden="true"></span> {{ $t("save_change_txt") }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  components: {
  },
  name: "ModalTransportOrderCtReject",
  emits: ["closeModal"],
  props: {
    showModal: Boolean,
    data: {},
  },
  watch: {
    showModal: {
      handler(newVal) {
        this.getSystemCode();
        this.active = newVal;
        this.forms.transport_order_header_id = this.data.transport_order_header_id;
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      fetchSystemCode: [],
      reject_reason: "",
      isLoading: false,
      active: this.showModal,
      forms: {
        "approval": "yes"
      },
      errors: [],
      locale: [],
      report_date: [],
      type_error: [],
      rules: {
        select: [(v) => !!v || "Item is required"]
      }

    };
  },
  methods: {
    resultError(data) {
      var html = "";
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          html += objectData[y] + "<br>";
        }
      }

      this.loadError(html);
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    loadError(pesan) {
      this.$swal({
        backdrop: true,
        title: "Warning",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },

    loadSuccess(pesan) {
      this.$swal({
        backdrop: true,
        title: "Success",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },

    success2(msg) {

      this.$swal({
        icon: 'info',
        backdrop: true,
        title: "Information",
        html: "<p>* " + msg + "</p>",
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      })


    },


    loadingSubmiting() {
      this.$swal({
        icon: 'warning',
        backdrop: true,
        title: "Please wait",
        html: '<h2>SUBMITTING ORDER...</h2>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

    },
    submitData() {
      
      if (!this.forms.description  && this.forms.approval === "no" ) {
        this.errors = { description: ["notes is required"] };
        this.resultError(this.errors);
      } else if (!this.reject_reason  && this.forms.approval === "no" && this.data.request_reschedule_by === "chain") {
        this.errors = { reject_reason: ["reason is required"] };
        this.resultError(this.errors);
      } else {
        this.fade(true);
        this.forms.reject_reason = this.reject_reason.value;
        let baseURI ='';
        // if(this.data.source){
        //   baseURI =
        //   this.$settings.endPoint + "transport-order-header/approve-reschedule/" + this.data.transport_order_header_id ;
        // }else{
        //   baseURI =
        //   this.$settings.endPoint + "control-tower/transport-order-header/approve-reschedule/" + this.data.transport_order_header_id ;
        // }

        if(this.data.source=='CT'){
        baseURI =
        this.$settings.endPoint + "control-tower/transport-order-header/approve-reschedule/" + this.data.transport_order_header_id ;
        }else if(this.data.source=='CHAIN'){
          baseURI =
          this.$settings.endPoint + "transport-order-header/approve-reschedule/" + this.data.transport_order_header_id ;
        }else if(this.data.source=='MULTI'){
          baseURI =
          this.$settings.endPoint + "transport-order-header-multiple/approve-reschedule/"+ this.data.transport_order_header_id ; 
        }

        //const baseURI = this.$settings.endPoint + "control-tower/transport-order-header/approve-reschedule/" + this.data.transport_order_header_id;
        this.$http
          .post(baseURI, this.forms, { timeout: 10000 })
          .then((response) => {
            this.fade(false);
            this.errors = [];
            this.$emit("closeModal");
            this.success2(response.data.datas.messages);
          })
          .catch(error => {
            this.fade(false);
            if (error.code == "ECONNABORTED") {
              this.error(this.$t("check_internet_connection_txt"));
            } else {

              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push('/server-error');
                } else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
                } else {
                  this.$router.push('/page-not-found');
                }
              }

            }

          });

      }

    },

    getSystemCode() {
      const baseURI = this.$settings.endPoint + "system-code/select?system_code=ORDER_CANCEL_REASON";

      return this.$http
        .get(
          baseURI,
          { timeout: 10000 }
        )
        .then((response) => {
          var rows = response.data.datas.data;
          this.fetchSystemCode = rows;


        }).catch(error => {

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    },

    asyncSearchSystemCode(ev) {

      const baseURI = this.$settings.endPoint + "system-code/select?system_code=ORDER_CANCEL_REASON&value=" + ev;

      return this.$http
        .get(
          baseURI,
          { timeout: 10000 }
        )
        .then((response) => {
          var rows = response.data.datas.data;
          this.fetchSystemCode = rows;


        }).catch(error => {

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    }

  },
  mounted() {

  },
};
</script>

<style scoped>
.blur-content {
  filter: blur(5px);
  pointer-events: none;
}

.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left:0px !important;
}
</style>