<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h5>Accessorial</h5>
        <button
          class="btn btn-success text-white fw-bolder btn-icon-text float-end me-2"
          @click="addData()"
          type="button"
        >
          <i class="link-icon" data-feather="plus"></i>
          {{ $t("CreateTxt") }}
        </button>
      </div>
      <div class="card-body">
        <vue-good-table
          title="list-user"
          mode="remote"
          @on-selected-rows-change="selectionChanged"
          @on-per-page-change="onPerPageChange"
          @on-sort-change="onSortChange"
          @on-page-change="onPageChange"
          :totalRows="totalRecords"
          :line-numbers="true"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true,
            selectionText: $t('data_selected'),
            clearSelectionText: $t('clear'),
          }"
          :isLoading.sync="isLoading"
          :pagination-options="{
            enabled: true,
          }"
          :rows="rows"
          :columns="columns"
        >
          <div slot="table-actions">
            {{ $t("no_select_data") }}
          </div>
          <div slot="selected-row-actions">
            <!-- <button
              type="button"
              class="btn btn-warning btn-xs me-2"
              style="margin-right: 20px"
              @click="updateDate"
            >
              <i class="mdi mdi-calendar"></i>
              {{ $t("dateUpdate") }}
            </button> -->

            <!-- <button
              type="button"
              class="btn btn-primary btn-xs me-2"
              style="margin-right: 20px"
              @click="sendOrder('sf')"
            >
              <i class="mdi mdi-send-outline"></i>
              {{ $t("sendToSF") }}
            </button> -->

            <button
              type="button"
              class="btn btn-twitter btn-xs me-2"
              style="margin-right: 20px"
              @click="sendOrder"
            >
              <i class="mdi mdi-send-outline"></i>
              {{ $t("sendToBlujay") }}
            </button>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'last_modified'">
              {{ props.row.updated_at }} - {{ props.row.update_by }}
            </span>
            <span v-if="props.column.field == 'actions'">
              <!-- <a
                            href="#"
                            title="Detail"
                            @click.prevent="detailData(props.index, props.row)"
                          >
                            <i
                              class="mdi mdi-eye"
                              style="font-size: 16px"
                            ></i>
                          </a> -->
              <a
                href="#"
                title="Edit"
                @click.prevent="editData(props.index, props.row)"
              >
                <i
                  class="mdi mdi-square-edit-outline"
                  style="font-size: 16px"
                ></i>
              </a>
              <a
                href="#"
                title="Copy"
                @click.prevent="copyData(props.index, props.row)"
              >
                <i class="mdi mdi-content-copy" style="font-size: 16px"></i>
              </a>
               <a
                href="#"
                v-if="permision_role.method_delete"
                title="Delete"
                @click.prevent="
                  deleteData(props.index, props.row)
                "
              >
                <i class="mdi mdi-trash-can-outline" style="font-size:16px"></i> 
              </a>
            </span>
            <span v-else-if="props.column.field == 'rate'">
              {{ props.row.rate || toLocaleString }}
            </span>
            <span v-else-if="props.column.field == 'all_inclusive'">
              {{ props.row.all_inclusive == 1 ? "Yes" : "No" }}
            </span>
            <span v-else-if="props.column.field == 'no_intermediate_stops'">
              {{ props.row.no_intermediate_stops == 1 ? "Yes" : "No" }}
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
    <modal-update-date
      :showModal="showModalUpdateDate"
      :data="passData"
      @closeModal="closeMyModal"
    ></modal-update-date>
    <modal-rate-break
      :showModal="showModalRateBreak"
      :data="passDataRate"
      @closeModal="closeMyModal"
    ></modal-rate-break>
  </div>
</template>

<script>
import modalUpdateDate from "@/components/modal/modalUpdateDate.vue";
import modalRateBreak from "@/components/modal/modalRateBreak.vue";
export default {
  name: "RateManagementAccessorial",
  props: {
    filter: { type: [Object, Array] },
  },
  components: {
    "modal-update-date": modalUpdateDate,
    "modal-rate-break": modalRateBreak,
  },
  data() {
    return {
      showModalUpdateDate: false,
      showModalRateBreak: false,
      passDataRate: null,
      isLoading: false,
      totalRecords: "",
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      permision_role: [],
      rows: [],
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [
        {
          label: "Action",
          field: "actions",
          width: "80px",
        },
        {
          label: "SoB",
          field: "sob",
        },
        {
          label: "Tier",
          field: "tier",
          width: "70px",
        },
         {
          label: this.$t("division"),
          field: "division",
        },
        {
          label: "Order Group",
          field: "order_group",
          width: "200px",
        },
        {
          label: "Sub Tarif",
          field: "sub_tariff",
        },
          {
          label: "Equipment Type",
          field: "equipment_type",
          width: "150px",
        },
        {
          label: "Origin Location",
          field: "origin_location",
          width: "200px",
        },
          {
          label: "Origin Region",
          field: "origin_region",
          width: "100px",
        },
        {
          label: "Destination Location",
          field: "destination_location",
          width: "200px",
        },
         {
          label: "Destination Region",
          field: "destination_region",
          width: "150px",
        },
        {
          label: "Charge Code",
          field: "charge_code",
        },
         {
          label: "Basis",
          field: "basis",
        },
         {
          label: "Rate",
          field: "rate",
        },
        {
          label: "Cost Quantity",
          field: "cost_quantity",
        },

        {
          label: "Min",
          field: "min",
        },
        {
          label: "Max",
          field: "max",
        },
         {
          label: "Effective Date",
          field: "effective_date",
          width: "150px",
        },
        {
          label: "Expiration Date",
          field: "expired_date",
          width: "150px",
        },
        {
          label: "Currency",
          field: "currency",
        },
      ],
      rowsSelection: null,
      billableRateIds: null,
      passData: null,
    };
  },
  watch: {
    filter: {
      handler(newVal) {
        console.log(newVal);
        this.loadItems();
      },
    },
  },
  methods: {
     deleteData(index, row, status) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI =
            this.$settings.endPoint + "rm-billable-rate-acc/disable/" + row.rm_billable_rate_acc_id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    addData() {
      window.location.href = "/billable-rate/add-acc/" + this.$route.params.id;
    },
    editData(index, row) {
      var params = this.$onRandom(row.rm_billable_rate_acc_id);
      window.location.href =
        "/billable-rate/detail/" +
        this.$route.params.id +
        "/edit-acc/" +
        params;
    },
    rateBreak(index, row) {
      this.passDataRate = {
        id: row.rm_billable_rate_detail_id,
      };
      this.showModalRateBreak = true;
    },
    updateDate() {
      this.showModalUpdateDate = true;
    },
    closeMyModal() {
      this.showModalUpdateDate = false;
      this.showModalRateBreak = false;
      this.loadItems();
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },
    selectionChanged(ev) {
      this.rowsSelection = [];
      this.billableRateIds = [];
      this.passData = [];
      this.rowsSelection = ev.selectedRows;
      this.rowsSelection.forEach((item) => {
        this.billableRateIds.push(item.rm_billable_rate_acc_id);
        this.passData.push(item.rm_billable_rate_acc_id);
      });
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },
    sendOrder() {
      let data = {};
        let baseURI =
          this.$settings.endPoint + "rm-billable-rate-acc/send-to-blujay";
      
      data = {
        rm_billable_rate_acc_ids: this.billableRateIds,
      };

      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
          this.$http
            .post(baseURI, data, { timeout: 30000 })
            .then((response) => {
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
                this.isloading = false;
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {
                if (error.response) {
                  // console.log(error.response.data.errors.message);
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.errors = error.response.data.errors;
                    this.error(error.response.data.errors.message);
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push("/page-not-found");
                  }
                }
              }
            });
        }
      });
    },
    loadItems() {
      var params = this.$onBehind(this.$route.params.id);
      //   console.log(this.filter);

      //   console.log(this.filter.sku ? this.filter?.sku : "");
      const baseURI =
        this.$settings.endPoint +
        "rm-billable-rate-acc/" +
        `?rm_billable_rate_header_id=${params}&limit=${
          this.serverParams.per_page
        }&page=${this.serverParams.page}&sort_field=${
          this.serverParams.sort.field
        }&sort_type=${this.serverParams.sort.type}&billable_tariff_name=${
          this.filter?.billableTariff ? this.filter.billableTariff : ""
        }&sku=${this.filter?.sku ? this.filter.sku : ""}&origin_location=${
          this.filter?.origin ? this.filter.origin : ""
        }&destination_location=${
          this.filter?.destination ? this.filter.destination : ""
        }&sf_source=${
          this.filter?.sf_source ? this.filter.sf_source : ""
        }&status=${this.filter?.status ? this.filter.status : ""}&statusExt=ENABLED&basis=${this.filter?.basis ? this.filter.basis : ""}`;

      return this.$http.get(baseURI).then((response) => {
        this.rows = response.data.datas.data;
        this.totalRecords = response.data.datas.total;
        this.loading();
      });
    },
    copyData(index, row) {
      this.fade(true);
      var params = this.$onRandom(row.rm_billable_rate_acc_id);
      window.location.href =
        "/billable-rate/add-acc/" +
        this.$route.params.id +
        "?detailId=" +
        params;
    },
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      this.companyCode = this.detailUser.company_detail.code;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RateManagementController") {
          rolePermision = permision;
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },
    detailData(index, row) {
      var params = this.$onRandom(row.rm_billable_rate_detail_id);
      window.location.href =
        "/billable-rate/detail/" + this.$route.params.id + "/detail/" + params;
    },
    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },
    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
    fade(sType) {
      this.isLoading = sType;
    },
    success2(msg) {
      let pesan = "";
      msg.forEach((item) => {
        pesan = pesan + "<p>* " + item + "</p>";
      });

      this.$swal({
        icon: "info",
        backdrop: true,
        title: "Information",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },
  },
  mounted() {
    this.fetchIt();
    this.loading();
    // this.loadItems();
  },
};
</script>

<style></style>
