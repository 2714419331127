<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TemplateOrder"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="col-lg-12 col-xl-12 stretch-card">
            <div class="card">
              <div class="card-header">
                <h4 style="margin-right: 5px" class="float-start">
                  Billable Tariff Upload
                </h4>
              </div>

              <div class="card-body">
                <!--begin::loading-data-->
                <div v-if="isLoading" class="d-flex align-items-center">
                  <div
                    class="spinner-border ms-auto"
                    role="status"
                    aria-hidden="true"
                  ></div>
                  <strong>{{ $t("loadingTxt") }}...</strong>
                </div>
                <!--end::loading-data-->

                <div
                  class="d-flex justify-content-between align-items-baseline mb-2"
                ></div>
                <div class="row">
                  <div class="col-lg-3" v-if="!this.company_code">
                    <div class="mb-3">
                      <label class="form-label">Company</label>
                      <multiselect
                        @search-change="asyncSearchCompany"
                        v-model="company"
                        :options="fetchCompany"
                        label="name"
                        track-by="name"
                        placeholder="Please Select"
                      >
                        <span slot="noResult">-</span>
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="mb-3">
                      <label class="form-label">Date Range</label>
                      <date-range-picker
                        style="width: 100%"
                        control-container-class="form-control"
                        :locale-data="locale"
                        v-model="dateRange"
                        @update="loadItems"
                        :opens="opens"
                        :ranges="customRange"
                        :singleDatePicker="false"
                        :timePicker24Hour="true"
                        :timePicker="true"
                        :showWeekNumbers="false"
                      >
                      </date-range-picker>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="mb-3">
                      <label class="form-label">Status</label>
                      <div class="mb-4">
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="active"
                            value="active"
                            name="active"
                            v-model="status"
                          />
                          <label class="form-check-label" for="active">
                            Active
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="complete"
                            value="complete"
                            name="complete"
                            v-model="status"
                          />
                          <label class="form-check-label" for="complete">
                            Complete
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="canceled"
                            value="canceled"
                            name="canceled"
                            v-model="status"
                          />
                          <label class="form-check-label" for="canceled">
                            Canceled
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="mb-3">
                      <button
                        class="btn btn-primary"
                        type="button"
                        @click="searchData"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row card">
                  <div class="card-header">
                    <button
                      class="btn btn-warning float-end btn-xs"
                      style="margin-right: 5px"
                      @click.prevent="uploadData()"
                    >
                      <i class="link-icon" data-feather="upload"></i>
                      Upload New Billable Tariff
                    </button>

                    <button
                      v-if="permision_role.method_read"
                      class="btn btn-success float-end btn-xs"
                      style="margin-right: 5px"
                      @click.prevent="downloadData()"
                    >
                      <i class="link-icon" data-feather="download"></i>
                      {{ $t("downloadData") }} .xlsx
                    </button>
                  </div>
                  <div class="card-body">
                    <vue-good-table
                      title="list-user"
                      mode="remote"
                      @on-page-change="onPageChange"
                      @on-sort-change="onSortChange"
                      @on-column-filter="onColumnFilter"
                      @on-per-page-change="onPerPageChange"
                      :totalRows="totalRecords"
                      :line-numbers="true"
                      :isLoading.sync="isLoading"
                      :pagination-options="{
                        enabled: true,
                      }"
                      :rows="rows"
                      :columns="columns"
                    >
                    <!-- <template slot="column-filter" slot-scope="props">
                      <div>
                        
                      </div>
                    </template> -->
                      <template slot="table-row" slot-scope="props">
                        <span
                          v-if="props.column.field == 'effective_expiration_date'"
                        >
                          {{ props.row.effective_date }} -
                          {{ props.row.expiration_date }}
                        </span>
                        <span v-if="props.column.field == 'actions'">
                          <a
                            href="#"
                            title="Detail"
                            @click.prevent="DetailData(props.index, props.row)"
                          >
                            <i class="mdi mdi-eye" style="font-size: 16px"></i>
                          </a>
                        </span>
                        <span v-else>
                          {{ props.formattedRow[props.column.field] }}
                        </span>
                      </template>
                    </vue-good-table>
                  </div>
                </div>
              </div>
            </div>
            <modal-tariff-file-upload
              :showModal="showModalTariffUpload"
              :data="passData"
              @closeModal="closeMyModal"
            ></modal-tariff-file-upload>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import multiselect from "vue-multiselect";

import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import modalTariffFileUpload from "@/components/modal/modalTariffFileUpload.vue";
export default {
  name: "BillableTariffUpload",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    DateRangePicker,
    multiselect: multiselect,
    "modal-tariff-file-upload": modalTariffFileUpload,
  },
  data() {
    const startDate = moment().subtract(7, "d").format("YYYY-MM-DD hh:mm:ss");
    //const startDate = new Date();
    const endDate = moment(Date.now() + 7 * 24 * 3600 * 1000).format(
      "YYYY-MM-DD"
    );
    let today = new Date();
    today.setHours(0, 0, 0, 0);

    let yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);

    const current = new Date();
    let firstDay = current.getDate() - current.getDay();
    return {
      maxToasts: 100,
      position: "up right",
      locale: {
        format: "yyyy-mm-dd",
      },
      dateRange: {
        startDate,
        endDate,
      },
      customRange: {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        "This Week": [
          new Date(current.setDate(firstDay)),
          new Date(current.setDate(current.getDate() + 6)),
        ],
        "Last  Week": [
          new Date(current.setDate(firstDay - 7)),
          new Date(current.setDate(firstDay - 1)),
        ],
        "This Month": [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth() + 1, 0),
        ],
        "Last month": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "This year": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
      },
      showModalTariffUpload: false,
      opens: "center",
      closeBtn: true,
      isLoading: false,
      errors: [],
      passData: [],
      company_code: "",
      fetchCompany: [],
      company: [],

      permision_role: [],
      langs: ["id", "en"],
      detailUser: [],
      detailDivisi: [],
      totalRecords: 0,
      status: [],
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [],
      // rows: [],
      rows: [],
    };
  },
  methods: {
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RateManagementController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },
    searchData() {
      this.loadItems();
    },
    asyncSearchCompany(ev) {
      this.fetchCompany = [];
      const baseURI = this.$settings.endPoint + "company?name=" + ev;
      //this.isLoading = true;

      return this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          this.fetchCompany = response.data.datas.data;
        })
        .catch((error) => {
          //this.isLoading = false;

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },
    loadColoum() {
      if (
        this.company_code == null ||
        this.company_code == "" ||
        this.company_code == undefined
      ) {
        this.columns = [
          {
            label: this.$t("companyCodeTxt"),
            field: "customer_code",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By " + this.$t("companyCodeTxt"), // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "Phase",
            field: "current_phase",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Billable Tariff Name", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "Status",
            field: "current_status",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Sub Tariff Name", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "Billable Tariff Name",
            field: "tariff_name",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Billable Tariff Name", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },

          {
            label: "Effective - Expiration",
            field: "effective_expiration_date",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Effective - Expiration", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "Action",
            field: "actions",
          },
        ];
      } else {
        this.columns = [
          {
            label: "Phase",
            field: "current_phase",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Billable Tariff Name", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "Status",
            field: "current_status",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Sub Tariff Name", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "Billable Tariff Name",
            field: "tariff_name",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Billable Tariff Name", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },

          {
            label: "Effective - Expiration",
            field: "effective_expiration_date",
            type: "date",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Effective - Expiration", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "Action",
            field: "actions",
          },
        ];
      }
    },

    loadItems() {
      var effective_expiration_date = '';
      if (this.serverParams.columnFilters.effective_expiration_date != '') {
        effective_expiration_date = this.$moment(this.serverParams.columnFilters.effective_expiration_date).format("YYYY-MM-DD");
      }
      this.fade(true);
      const baseURI = this.$settings.endPoint + "rm-billable-tariff";
      return this.$http
        .get(
          baseURI +
            `?limit=${this.serverParams.per_page}&page=${
              this.serverParams.page
            }&sort_field=${this.serverParams.sort.field}&sort_type=${
              this.serverParams.sort.type
            }&customer_code=${
              this.company_code == "" || this.company_code == undefined
                ? this.serverParams.columnFilters.customer_code ?? ""
                : this.company_code
            }&current_phase=${
              this.serverParams.columnFilters.current_phase
            }&current_status=${
              this.serverParams.columnFilters.current_status
            }&tariff_name=${this.serverParams.columnFilters.tariff_name}&effective_expiration_date=${effective_expiration_date}&current_phase=${this.serverParams.columnFilters.current_phase}&current_status=${this.serverParams.columnFilters.current_status}`
        )
        .then((response) => {
          // console.log(response);
          this.loading();
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
        });
    },
    submitData() {
      // console.log("test");
    },
    closeMyModal() {
      this.showModalTariffUpload = false;
      // console.log("trigger", this.showModalTariffUpload);
    },
    uploadData() {
      this.showModalTariffUpload = true;
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },
    loadCompany() {
      const baseURI = this.$settings.endPoint + "company";
      //this.isLoading = true;

      return this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          this.fetchCompany = response.data.datas.data;
          // console.log(response);
        })
        .catch((error) => {
          this.isLoading = false;

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },
    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },
    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },

    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },
  },
  mounted() {
    this.company_code =
      this.$route.query == undefined ? "" : this.$route.query.company_code;
    this.loadColoum();
    this.loadCompany();
    this.fetchIt();



  },
};
</script>

<style>
</style>