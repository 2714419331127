import { render, staticRenderFns } from "./PostalCodeEdit.vue?vue&type=template&id=16d25c37&scoped=true"
import script from "./PostalCodeEdit.vue?vue&type=script&lang=js"
export * from "./PostalCodeEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16d25c37",
  null
  
)

export default component.exports