<template>
  <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Request Reroute Order : {{ data.order_no }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
            aria-label="btn-close"></button>
        </div>
        <div class="modal-body">
          <!--begin::loading-data-->

          <form class="forms-sample" @submit.prevent="submitData" method="POST">
            <div class="d-flex justify-content-between align-items-baseline mb-2"></div>

            <div class="row">
              <div class="col-lg-6">
                <div class="mb-3">
                  <label class="form-label">New Pickup Location <span class="wajib">*</span></label>
                  <v-select :options="fetchLocation" v-model="location" label="name" @search="asyncSearchLocation"
                    @input="getLocationPickup">

                    <template slot="option" slot-scope="option">

                      {{ option.location_code }} | {{ option.name }}
                    </template>

                    <template slot="selected-option" slot-scope="option">

                      {{ option.location_code }} | {{ option.name }}
                    </template>

                  </v-select>
                </div>

              </div>

              <div class="col-lg-6">
                <div class="mb-3">
                  <label class="form-label">New Destination Location <span class="wajib">*</span></label>
                  <v-select :options="fetchLocationDest" v-model="location_dest" label="name" persistent-hint
                    @search="asyncSearchLocationDest" @input="getLocationDest">
                    <template slot="option" slot-scope="option">

                      {{ option.location_code }} | {{ option.name }}
                    </template>

                    <template slot="selected-option" slot-scope="option">

                      {{ option.location_code }} | {{ option.name }}
                    </template>
                  </v-select>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12">                
                <b>New Pickup & Destination Location</b>                
              </div>
              <div class="col-lg-6">
                <div class="row" v-if="pickup_address">                 
                    <p class="text-muted">
                      <b>{{ forms.pick_name }}</b>
                      <i class="mdi mdi-map-marker"></i>Lat.{{
                        forms.pick_latitude
                      }}
                      Long.{{ forms.pick_longitude }} <br />
                      {{ forms.pick_address1 }}
                      {{ forms.pick_address2 }}
                      {{ forms.pick_address2 }}
                      {{ forms.pick_sub_district }},
                      {{ forms.pick_district }},
                      {{ forms.pick_city }},
                      {{ forms.pick_province }}
                      {{ forms.pick_postal_code }},
                      {{ forms.pick_country }}<br />
                      {{ forms.pick_contact_name }} -
                      {{ forms.pick_phone_number }}
                    </p>                  
                </div>
              </div>

              <div class="col-lg-6">
                <div class="row" v-if="destination_address">                  
                    <p class="text-muted">
                      <b>{{ forms.dest_name }}</b>
                      <i class="mdi mdi-map-marker"></i>Lat.{{
                        forms.dest_latitude
                      }}
                      Long.{{ forms.dest_longitude }} <br />
                      {{ forms.dest_address1 }}
                      {{ forms.dest_address2 }}
                      {{ forms.dest_address2 }}
                      {{ forms.dest_sub_district }},
                      {{ forms.dest_district }},
                      {{ forms.dest_city }},
                      {{ forms.dest_province }}
                      {{ forms.dest_postal_code }},
                      {{ forms.dest_country }}<br />
                      {{ forms.dest_contact_name }} -
                      {{ forms.dest_phone_number }}
                    </p>
                  
                </div>
              </div>
              <div class="col-lg-12"> <hr /></div>
              
            </div>

            <div class="row">
              <div class="col-lg-12">                
                <b>Existing Pickup & Destination Location</b>                
              </div>

              <div class="col-lg-6">
                <p class="text-muted">
                  <b>{{ data.pick_name }}</b>
                  <i class="mdi mdi-map-marker"></i>Lat.{{
                    data.pick_latitude
                  }}
                  Long.{{ data.pick_longitude }} <br />
                  {{ data.pick_address1 }}
                  {{ data.pick_address2 }}
                  {{ data.pick_address2 }}
                  {{ data.pick_sub_district }},
                  {{ data.pick_district }},
                  {{ data.pick_city }},
                  {{ data.pick_province }}
                  {{ data.pick_postal_code }},
                  {{ data.pick_country }}<br />
                  {{ data.pick_contact_name }} -
                  {{ data.pick_phone_number }}
                </p>


              </div>
              <div class="col-lg-6">

                <p class="text-muted">
                  <b>{{ data.dest_name }}</b>
                  <i class="mdi mdi-map-marker"></i>Lat.{{
                    data.dest_latitude
                  }}
                  Long.{{ data.dest_longitude }} <br />
                  {{ data.dest_address1 }}
                  {{ data.dest_address2 }}
                  {{ data.dest_address2 }}
                  {{ data.dest_sub_district }},
                  {{ data.dest_district }},
                  {{ data.dest_city }},
                  {{ data.dest_province }}
                  {{ data.dest_postal_code }},
                  {{ data.dest_country }}<br />
                  {{ data.dest_contact_name }} -
                  {{ data.dest_phone_number }}
                </p>

              </div>
            </div>

            <div class="modal-footer">

              <!--end::loading-data-->
              <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
                {{ $t("close_txt") }}
              </button>
              <button class="btn btn-primary" type="submit">
                <span v-if="isLoading" class="spinner-border spinner-border-sm ms-auto" role="status"
                  aria-hidden="true"></span> Send Request
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  name: "ModalTurnOffTripAlert",
  emits: ["closeModal"],
  props: {
    showModal: Boolean,
    data: null,
  },
  watch: {
    showModal: {
      handler(newVal) {
        this.forms = {
          transport_order_header_ids: [this.data.transport_order_header_id],
        };

        this.active = newVal;
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      pickup_address: false,
      destination_address: false,
      isLoading: false,
      active: this.showModal,
      forms: [],
      errors: [],
      type_error: [],
      fetchLocation: [],
      fetchLocationDest: [],
      location: "",
      location_dest: ""
    };
  },
  methods: {
    getLocation() {
      this.fetchLocation = [];
      this.fetchLocationDest = [];
      const baseURI =
        this.$settings.endPoint + "location?company_id=" + this.data.company_id;

      return this.$http.get(baseURI).then((response) => {
        this.fetchLocation = response.data.datas.data;
        this.fetchLocationDest = response.data.datas.data;
      });
    },

    getLocationPickup(ev) {
      this.pickup_address = true;
      this.forms.pick_name = ev.name;
      this.forms.pick_country = ev.country;
      this.forms.pick_province = ev.province;
      this.forms.pick_city = ev.city;
      this.forms.pick_district = ev.district;
      this.forms.pick_sub_district = ev.sub_district;
      this.forms.pick_postal_code = ev.postal_code;
      this.forms.pick_address1 = ev.address1;
      this.forms.pick_address2 = ev.address2;
      this.forms.pick_address3 = ev.address3;
      this.forms.pick_latitude = ev.latitude;
      this.forms.pick_longitude = ev.longitude;
      this.forms.pick_radius = ev.radius;
      this.forms.pick_contact_name = ev.contact_name;
      this.forms.pick_phone_number = ev.phone_number;
    },

    getLocationDest(ev) {
      this.destination_address = true;
      this.forms.dest_name = ev.name;
      this.forms.dest_country = ev.country;
      this.forms.dest_province = ev.province;
      this.forms.dest_city = ev.city;
      this.forms.dest_district = ev.district;
      this.forms.dest_sub_district = ev.sub_district;
      this.forms.dest_postal_code = ev.postal_code;
      this.forms.dest_address1 = ev.address1;
      this.forms.dest_address2 = ev.address2;
      this.forms.dest_address3 = ev.address3;
      this.forms.dest_latitude = ev.latitude;
      this.forms.dest_longitude = ev.longitude;
      this.forms.dest_radius = ev.radius;
      this.forms.dest_contact_name = ev.contact_name;
      this.forms.dest_phone_number = ev.phone_number;
    },

    asyncSearchLocation(ev) {
      let company_id = this.data.company_id;
      const baseURI =
        this.$settings.endPoint +
        "location?company_id=" +
        company_id +
        "&name=" +
        ev;
      this.fetchLocation = [];
      return this.$http.get(baseURI).then((response) => {
        this.fetchLocation = response.data.datas.data;
      });
    },

    asyncSearchLocationDest(ev) {
      let company_id = this.data.company_id;
      const baseURI =
        this.$settings.endPoint +
        "location?company_id=" +
        company_id +
        "&name=" +
        ev;
      this.fetchLocationDest = [];
      return this.$http.get(baseURI).then((response) => {
        this.fetchLocationDest = response.data.datas.data;

      });
    },

    resetForm() {
      this.forms = [];
    },

    submitData() {
      this.fade(true);
      this.forms.pick_location_id = this.location.location_id;
      this.forms.dest_location_id = this.location_dest.location_id;
      const baseURI =
        this.$settings.endPoint + "transport-order-header/reroute";
      this.$http
        .post(baseURI, this.forms)
        .then((response) => {
          this.fade(false);
          if (response.data.status === 200) {
            this.forms =[];
            this.errors = [];

            this.loadSuccess(response.data.datas.messages);
            this.$emit("closeModal");
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.fade(false);
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var html = "";
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          html += objectData[y] + "<br>";
        }
      }

      this.loadError(html);
    },

    loadError(pesan) {
      this.$swal({
        backdrop: true,
        title: "Warning",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },

    loadSuccess(pesan) {
      this.$swal({
        backdrop: true,
        title: "Success",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },
  },
  mounted() {
    //tail(this.data);
  },
};
</script>

<style scoped>
.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left:0px !important;
}
</style>