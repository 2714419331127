<template>
    <div>
      <div class="loader" v-if="this.isLoading"></div>
      <div :class="{'blur-content': this.isLoading}">
        <div class="main-wrapper">
          <!--begin::sidebar-->
          <sidebar-component classMenu="TransportOrderCt"></sidebar-component>
          <!--end::sidebar-->
  
          <div class="page-wrapper">
            <!--begin::navbar-->
            <navbar-component></navbar-component>
            <!--end::navbar-->
  
            <!--begin::content-->
            <div class="page-content">
  
              <div>
                <h4 style="margin-right: 5px" class="float-start"></h4>
                <h4 class="mb-12 mb-md-0">
                  Turn Off Alert
                </h4>
                <br />
              </div>
  
              <div class="col-lg-12 col-xl-12 stretch-card">
                <div class="card">
                  <div class="card-header">
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="input-group flatpickr wd-220 me-2 mb-2 mb-md-0" id="dashboardDate">
                          <date-range-picker style="width: 100%" @change="pickupChange"
                            control-container-class="form-control" :locale-data="locale" v-model="dateFilter"
                            :opens="opens" :singleDatePicker="false" :timePicker24Hour="true" :timePicker="true"
                            :showWeekNumbers="false">
                          </date-range-picker>
                        </div>
                      </div>
  
                      <div class="col-lg-4"><button class="button btn-lg btn-success" @click="getData()">Go</button></div>
                      <div class="col-lg-5">
                        <button v-if="permision_role.method_read" class="btn btn-success float-end btn-xs"
                          style="margin-right: 5px" @click.prevent="downloadData()">
                          <i class="link-icon" data-feather="download"></i>
                          {{ $t("downloadData") }} .xlsx
                        </button>
                      </div>
  
                    </div>
  
  
                  </div>
  
                  <div class="card-body">
  
                    <div class="table-responsive" style="overflow-y: auto;">
                      <div class="col-sm-12">
  
                        <vue-good-table title="list-order-ct" mode="remote" 
                          @on-column-filter="onColumnFilter" 
                          @on-page-change="onPageChange" 
                          @on-sort-change="onSortChange"
                          @on-per-page-change="onPerPageChange" 
                          :totalRows="totalRecords" 
                          :line-numbers="true"
                          :isLoading.sync="isLoading" :pagination-options="{
                            enabled: true,
                          }" :rows="rows" :columns="columns">
                          <template slot="table-row" slot-scope="props">
  
  
                            <span v-if="props.column.field == 'alert_status'">
  
                              {{props.row.alert_status == 1?'ON':'OFF'}}
  
                            </span>
  
                            <span v-else>
                              {{ props.formattedRow[props.column.field] }}
                            </span>
                          </template>
                        </vue-good-table>
  
                      </div>
                    </div>
                  </div>
  
                </div>
              </div>
            </div>
            <!--end::content-->
            <!--begin::footer-->
            <footer-component></footer-component>
            <!--end::footer-->
          </div>
        </div>
      </div>
    </div>
  </template>
    
  <script>
  import sidebarComponent from "@/components/_partials/_sidebar";
  import navbarComponent from "@/components/_partials/_navbar";
  import footerComponent from "@/components/_partials/_footer";
  import DateRangePicker from "vue2-daterange-picker";
  import moment from 'moment';
  
  export default {
    name: "AlertTurnOff",
    components: {
      "sidebar-component": sidebarComponent,
      "navbar-component": navbarComponent,
      "footer-component": footerComponent,
      DateRangePicker,
    },
    data() {
      const startDate = moment().clone().startOf('month').format('YYYY-MM-DD'); // new Date();
      const endDate = new Date();
      return {
        dateFilter: {
          startDate,
          endDate,
        },
        opens:"center",
        locale:{
                format:"yyyy-mm-dd",            
            },
        maxToasts: 100,
        selected: '',
        position: "up right",
        closeBtn: true,
        isLoading: false,
        errors: [],
        permision_role: [],
        langs: ["id", "en"],
        detailUser: [],
        detailDivisi: [],
        totalRecords: 0,
        serverParams: {
          columnFilters: {},
          sort: {
            field: "",
            type: "",
          },
          page: 1,
          per_page: 10,
        },
        columns: [
          
          {
            label: "Vehicle No",
            field: "vehicle_no",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Vehicle No", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "Alert Type",
            field: "alert_type",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Alert Type", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "Alert Subtype",   
            field: "alert_subtype",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Alert Subtype", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "Alert Time",
            field: "alert_time",
            filterOptions: {
              enabled: false, // enable filter for this column
              placeholder: "Filter By Truck Type", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "Alert Status",
            field: "alert_status",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Alert Status", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: ['On','Off'], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
  
          {
            label: "Alert Off Time",
            field: "alert_off_time",
            filterOptions: {
              enabled: false, // enable filter for this column
              placeholder: "Filter By Branch", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
  
          {
            label: "Alert Off User",
            field: "alert_off_user",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Alert Off User", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },

          {
            label: "Alert Off Reason",
            field: "alert_off_reason",
            filterOptions: {
              enabled: false, // enable filter for this column
              placeholder: "Filter By Project", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
  
        ],
        rows: [],
        // rowsSelection:[],
      };
    },
    watch: {},
    computed: {
    },
    methods: {
      pickupChange() { },      
  
      fetchIt() {
        const userDatas = this.$getUserInfo();
        this.detailUser = userDatas.sub;
        // this.permision_role = userDatas.sub.permision_role;
        let rolePermision = [];
        userDatas.sub.permision_role.forEach(function (permision) {
          if (permision.permission.controller == 'AlertController') {
            rolePermision = permision;
          }
        });
  
        if (!rolePermision.method_read) {
          this.$router.push("/authorized-error");
        }
        this.permision_role = rolePermision;
  
        if (this.$route.query.msg) {
          if (this.$route.query.status == 200) {
            this.success(this.$route.query.msg)
          } else {
            this.error(this.$route.query.msg)
          }
  
        }
  
      },
    
      getData() {
        let dataSend = {
          start: moment(String(this.dateFilter.startDate,)).format('YYYY-MM-DD'), //this.dateFilter.startDate,  // moment(String(this.forms.join_date)).format('YYYY-MM-DD');
          end: moment(String(this.dateFilter.endDate,)).format('YYYY-MM-DD'),             
        };
        this.loadItems(dataSend);
      },
  
      // load items is what brings back the rows from server
      loadItems(dataSend) {
        // let tanggal = this.filtertanggal.toISOString().split('T')[0]
        this.isLoading = true;
        const baseURI = this.$settings.endPointCt + "alert";
  
        return this.$http
          .get(
            baseURI
            +
            `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&start=${dataSend.start}&end=${dataSend.end}`+
            `&sort_field=${this.serverParams.sort.field}`+
            `&sort_type=${this.serverParams.sort.type}`+
            `&vehicle_no=${this.serverParams.columnFilters.vehicle_no}`+
            `&alert_subtype=${this.serverParams.columnFilters.alert_subtype}`+
            `&alert_status=${this.serverParams.columnFilters.alert_status}`+
            `&alert_off_user=${this.serverParams.columnFilters.alert_off_user}`,
            { timeout: 10000 }
          )
          .then((response) => {
            this.rows = response.data.datas.data;
            this.totalRecords = response.data.datas.total;
            this.isLoading = false;
          }).catch(error => {
            this.isLoading = false;
            if (error.code == "ECONNABORTED") {
              this.error(this.$t("check_internet_connection_txt"));
            } else {
  
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push('/server-error');
                } else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
                } else {
                  this.$router.push('/page-not-found');
                }
              }
  
            }
  
          });
      },
  
      downloadData() {
        this.isLoading = true;
        const baseURI = this.$settings.endPointCt + "alert";
        var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
        var file_name = "download_" + CurrentDate + ".xlsx";
  
        let dataSend = {
          start: moment(String(this.dateFilter.startDate,)).format('YYYY-MM-DD'), //this.dateFilter.startDate,  // moment(String(this.forms.join_date)).format('YYYY-MM-DD');
          end: moment(String(this.dateFilter.endDate,)).format('YYYY-MM-DD'),
          // end: this.dateFilter.endDate,                
        };
  
        return this.$http
          .get(
            baseURI +
            `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&start=${dataSend.start}&end=${dataSend.end}`+
            `&download=download`+
            `&sort_field=${this.serverParams.sort.field}`+
            `&sort_type=${this.serverParams.sort.type}`+
            `&vehicle_no=${this.serverParams.columnFilters.vehicle_no}`+
            `&alert_subtype=${this.serverParams.columnFilters.alert_subtype}`+
            `&alert_status=${this.serverParams.columnFilters.alert_status}`+
            `&alert_off_user=${this.serverParams.columnFilters.alert_off_user}`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
  
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");
  
            fileLink.href = fileURL;
            fileLink.setAttribute("download", file_name);
            document.body.appendChild(fileLink);
            fileLink.click();
            this.fade(false);
          });
      },
  
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
        this.getData();
      },
  
      onColumnFilter(params) {
        params.page = 1;
        this.updateParams(params);
      },
  
      onPageChange(params) {
        this.updateParams({ page: params.currentPage });
      },
  
      onSortChange(params) {
        this.updateParams({
          sort: {
            type: params[0].type,
            field: params[0].field,
          },
        });
      },
  
      onPerPageChange(params) {
        this.updateParams({ per_page: params.currentPerPage });
      },
  
      resultError(data) {
        var count = Object.keys(data).length;
        for (var x = 0; x < count; x++) {
          var nameOb = Object.keys(data)[x];
          var objectData = data[nameOb];
          for (var y = 0; y < objectData.length; y++) {
            this.error(objectData[y]);
          }
        }
      },
  
      success(kata) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: kata,
        });
      },
  
      success2(msg) {
        this.$swal({
          icon: 'info',
          backdrop: true,
          title: "Information",
          html: msg,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      },
    
      error(kata) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: kata,
        });
      },
  
      fade(sType) {
        this.isLoading = sType;
      },
  
      loading() {
        this.fade(true);
        setTimeout(() => {
          this.isLoading = false;
        }, 1000); // hide the message after 3 seconds
      },
  
    },
    events: {},
    created: function () { },
    mounted() {
      this.fetchIt();
      this.loading();
      this.getData(); 
  
    },
  };
  </script>
  <style scoped>
  .blur-content {
    filter: blur(5px);
    pointer-events: none;
  }
  
  .card-body {
    padding: 5px 1.5rem 1rem;
  }
  
  .vgt-table th {
    text-align: left !important;
  }
  
  
  </style>