<template>
  <div>
    <div
      ref="modal"
      class="modal fade"
      :class="{ show: active, 'd-block': active }"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-if="this.data.type == 'create'">
              Create Charge Code
            </h5>
            <h5 class="modal-title" v-else>Update Charge Code</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              v-on:click="$emit('closeModal')"
              aria-label="btn-close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-lg-4">
                <label
                  for="defaultconfig"
                  class="col-form-label"
                  style="padding-left: 25px"
                  >Charge Code
                </label>
              </div>
              <div class="col-lg-7">
                <!-- <v-select
                    :options="fetchCompany"
                    v-model="company"
                    label="name"
                    return-object
                    @search="asyncSearchCompany"    
                  ></v-select> -->
                <multiselect
                  @search-change="asyncChargeCode"
                  v-model="chargeCode"
                  :options="fetchChargeCode"
                  label="value"
                  track-by="value"
                  placeholder="Please Select"
                >
                  <span slot="noResult">-</span>
                </multiselect>
              </div>
              <div
                v-for="error in errors.charge_code"
                :key="error"
                class="alert alert-primary"
                role="alert"
              >
                <i data-feather="alert-circle"></i>
                {{ error }}
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-lg-4">
                <label
                  for="defaultconfig"
                  class="col-form-label"
                  style="padding-left: 25px"
                >Description Override
                </label>
              </div>
              <div class="col-lg-7">
                <input
                  type="text"
                  class="form-control"
                  v-model="forms.description_overide"
                />
                <div
                  v-for="error in errors.description_overide"
                  :key="error"
                  class="alert alert-primary"
                  role="alert"
                >
                  <i data-feather="alert-circle"></i>
                  {{ error }}
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-lg-4">
                <label
                  for="defaultconfig"
                  class="col-form-label"
                  style="padding-left: 25px"
                >Transportation Account Code
                </label>
              </div>
              <div class="col-lg-7">
                <input
                  type="text"
                  class="form-control"
                  v-model="forms.transportation_account_code"
                />
                <div
                  v-for="error in errors.transportation_account_code"
                  :key="error"
                  class="alert alert-primary"
                  role="alert"
                >
                  <i data-feather="alert-circle"></i>
                  {{ error }}
                </div>
              </div>
            </div>
             <div class="row mb-3">
              <div class="col-lg-4">
                <label
                  for="defaultconfig"
                  class="col-form-label"
                  style="padding-left: 25px"
                  >Instruction For Carrier
                </label>
              </div>
              <div class="col-lg-7">
                <input
                  type="text"
                  class="form-control"
                  v-model="forms.instructions_for_carrier"
                />
                <div
                  v-for="error in errors.instructions_for_carrier"
                  :key="error"
                  class="alert alert-primary"
                  role="alert"
                >
                  <i data-feather="alert-circle"></i>
                  {{ error }}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              v-on:click="$emit('closeModal', null, null)"
            >
              cancel
            </button>
            <div v-if="this.data.type == 'create'">
              <button
                type="button"
                class="btn btn-primary me-2"
                v-on:click="submitData('close')"
              >
                Save
              </button>
            </div>
            <div v-else>
              <button
                type="button"
                class="btn btn-primary me-2"
                v-on:click="updateData('close')"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import multiselect from "vue-multiselect";
export default {
  name: "modalUpdateDate",
  emits: ["closeModal"],
  components: {
    multiselect: multiselect,
  },
  props: {
    showModal: Boolean,
    data: null,
  },
  watch: {
    showModal: {
      handler(newVal) {
       
        this.loadChargeCode();
        this.active = newVal;
        this.forms = {};
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
        if (this.active == true) {
          if (this.data.type !== "create") {
            this.forms = this.data.row;
            console.log(this.forms);
            this.chargeCode={
              "value":this.forms.charge_code
            }
            // this.company_code = this.data.customer_code;
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      opens: "center",
      active: this.showModal,
      chargeCode: null,
      fetchChargeCode: [],
      forms: {},
      errors: [],
      locale: {
        format: "dd mmm yyyy H:MM:ss",
      },
    };
  },
  methods: {
    submitData(type) {
      // var company = this.company_code;
      // if (!this.company_code) {
      //   company = this.company.code;
      // }
      this.forms.charge_code = this.chargeCode.value;
      var baseURI = this.$settings.endPoint + "rm-charge-code/create";
      return this.$http
        .post(baseURI, this.forms)
        .then((response) => {
          if (response.data.status === 200) {
            this.$emit(
              "closeModal",
              type,
              response.data.datas.rm_charge_code_id
            );
            this.success2("Data Berhasil Disimpan");
            this.errors = [];
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },
    updateData(type) {
      var baseURI =
        this.$settings.endPoint +
        "rm-charge-code/update/" +
        this.data.row.rm_charge_code_id;
      return this.$http
        .patch(baseURI, this.forms)
        .then((response) => {
          if (response.data.status === 200) {
            this.$emit("closeModal", type, this.data.row.rm_charge_code_id);
            this.success2("Data Berhasil Disimpan");
            this.errors = [];
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },
   
 
    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },
    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },


    asyncChargeCode(ev) {
      this.fetchChargeCode=[];
      const baseURI = this.$settings.endPointCt + "master-lookup/index-view?unique_id=mscc&value="+ev;
      //this.isLoading = true;
     
      return this.$http
        .get(
          baseURI,{timeout: 10000 }
        )
        .then((response) => {
            this.fetchChargeCode=response.data.datas.data;
        }).catch(error => {
          //this.isLoading = false;
           
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              }
            }

          }

        });
    },


    loadChargeCode() {
      const baseURI = this.$settings.endPointCt + "master-lookup/index-view?unique_id=mscc";
      //this.isLoading = true;
     
      return this.$http
        .get(
          baseURI,{timeout: 10000 }
        )
        .then((response) => {
            this.fetchChargeCode=response.data.datas.data;
        }).catch(error => {
          //this.isLoading = false;
           
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              }
            }

          }

        });
    },


    success2(msg) {
      this.$swal({
        icon: "info",
        backdrop: true,
        title: "Information",
        html: "<p>* " + msg + "</p>",
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },
  },
  mounted() {},
};
</script>
<style scoped>
.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left: 0px !important;
}
</style>
