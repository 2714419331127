<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading"> {{ $t('loadingTxt') }}...</div>
    <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
      <!--begin::sidebar-->
      <sidebar-component classMenu="InformasiBongkarAdd"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            ">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left: 25px">
                    Add Unloading Information
                  </h6>
                  <button class="btn btn-default btn-sm float-end" @click="resetForm" type="button">
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form class="forms-sample" style="padding-left: 25px" @submit.prevent="submitData" method="POST">
                    <div class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "></div>

                    <div class="row mb-2">
                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Load ID<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-6">
                            <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                            <input readonly type="text" id="load_number" name="load_number" v-model="forms.load_number"
                              class="form-control" placeholder="Load ID" read-only />


                            <div v-if="errors.load_number">
                              <div v-for="error in errors.load_number" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <button class="btn btn-success" @click.prevent="openModal()">Browse</button>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Tanggal Muat<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <Datepicker v-model="forms.tanggal_muat" :bootstrap-styling=true format="dd MMM yyyy"
                              placeholder="Please Select Date.."></Datepicker>
                            <div v-if="errors.tanggal_muat">
                              <div v-for="error in errors.tanggal_muat" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Shipment Number<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="shipment_number" name="shipment_number" readonly
                              v-model="forms.shipment_number" class="form-control" placeholder="Shipment Number" />
                            <div v-if="errors.shipment_number">
                              <div v-for="error in errors.shipment_number" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Tanggal Bongkar<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <Datepicker v-model="forms.tanggal_bongkar" :bootstrap-styling=true format="dd MMM yyyy"
                              placeholder="Please Select Date.."></Datepicker>
                            <div v-if="errors.tanggal_bongkar">
                              <div v-for="error in errors.tanggal_bongkar" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Shipment Date<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input readonly type="text" id="shipment_date" name="shipment_date" v-model="forms.shipment_date"
                              class="form-control" placeholder="Shipment Date" />
                            <div v-if="errors.shipment_date">
                              <div v-for="error in errors.shipment_date" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Provinsi Tujuan<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <v-select :options="fetchProvinsiTujuan" v-model="ProvinsiTujuan" label="province"
                              return-object @input="getKotaTujuan"></v-select>
                            <div v-if="errors.provinsi_tujuan">
                              <div v-for="error in errors.provinsi_tujuan" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Vehicle No<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="vehicle_no" name="vehicle_no" v-model="forms.vehicle_no"
                              class="form-control" placeholder="Vehicle No" readonly />
                            <div v-if="errors.vehicle_no">
                              <div v-for="error in errors.vehicle_no" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Kota Tujuan<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <v-select :options="fetchKotaTujuan" v-model="KotaTujuan" label="city" return-object>
                            </v-select>
                            <div v-if="errors.kota_tujuan">
                              <div v-for="error in errors.kota_tujuan" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Driver NMK<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="driver_nmk" name="driver_nmk" v-model="forms.driver_nmk"
                              class="form-control" placeholder="Driver NMK" readonly />
                            <div v-if="errors.driver_nmk">
                              <div v-for="error in errors.driver_nmk" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Qty<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="number" id="qty" name="qty" v-model="forms.qty" class="form-control"
                              placeholder="Qty" />
                            <div v-if="errors.qty">
                              <div v-for="error in errors.qty" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Driver Name<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="driver_name" name="driver_name" v-model="forms.driver_name"
                              class="form-control" placeholder="Driver Name" readonly />
                            <div v-if="errors.driver_name">
                              <div v-for="error in errors.driver_name" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Qty Return<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="number" id="qty_return" name="qty_return" v-model="forms.qty_return"
                              class="form-control" placeholder="Qty Return" />
                            <div v-if="errors.qty_return">
                              <div v-for="error in errors.qty_return" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Driver HP<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="driver_hp" name="driver_hp" v-model="forms.driver_hp"
                              class="form-control" placeholder="Driver HP" readonly />
                            <div v-if="errors.driver_hp">
                              <div v-for="error in errors.driver_hp" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Sub Customer<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <v-select :options="fetchSubCustomer" v-model="forms.sub_customer" label="sub_customer"
                              return-object></v-select>

                            <div v-if="errors.sub_customer">
                              <div v-for="error in errors.sub_customer" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Route<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="route" name="route" v-model="forms.route" class="form-control"
                              placeholder="Route" readonly />
                            <div v-if="errors.route">
                              <div v-for="error in errors.route" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Description<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <textarea id="description" name="description" v-model="forms.description"
                              class="form-control" placeholder="Description" rows="2" />
                            <div v-if="errors.description">
                              <div v-for="error in errors.description" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">SKU<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="sku" name="sku" v-model="forms.sku" class="form-control"
                              placeholder="SKU" />
                            <div v-if="errors.sku">
                              <div v-for="error in errors.sku" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="card-footer">
                      <button class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        " @click="backForm" type="button">
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button class="btn btn-primary float-end btn-sm" type="submit">
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>

                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->
        <modal-unloading :showModal="showModalUnloading" :data="this.dataModal" @closeModal="closeMyModal">
        </modal-unloading>

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import myMixinAddress from '@/middleware/mixinAddress.js';
import Datepicker from "vuejs-datepicker";
import moment from 'moment'
import modalUnloading from "@/components/modal/modalUnloading.vue";

export default {
  name: "UnloadingInformationAdd",
  props: {},
  mixins: [myMixinAddress],
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "modal-unloading": modalUnloading,
    Datepicker
  },
  data() {
    return {
      fetchLoadNumber: [],
      loadNumber: [],

      fetchProvinsiTujuan: [],
      ProvinsiTujuan: [],

      fetchKotaTujuan: [],
      KotaTujuan: [],

      fetchSubCustomer: ['Industrial', 'Consumer', 'MT', 'Pallet', 'E-COM'],
      SubCustomer: [],

      showModalUnloading: false,

      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      permision_role: [],
      userData: "",
      companyCode: "",
      forms: {},
      dataModal: {}
    };
  },


  watch: {},
  methods: {
    openModal() {
      const baseURI = this.$settings.endPointCt + "blujay-order/select";
      this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {

          this.dataModal = {
            detail: response.data.datas
          }
          this.showModalUnloading = true;
        });
    },

    loadBlujay() {
      const baseURI = this.$settings.endPointCt + `blujay-order/select`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchLoadNumber = response.data.datas.data;
        //console.log(this.fetchLoadNumber);
      });

    },

    loadProvinsi() {
      const baseURI = this.$settings.endPoint + `postal-code/select-province/Indonesia`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchProvinsiTujuan = response.data.datas;
        //console.log(this.fetchLoadNumber);
      });

    },

    asyncSearchLoadNumber(ev) {
      const baseURI = this.$settings.endPointCt + "blujay-order/select?load_id=" + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchLoadNumber = response.data.datas.data;
      });
    },

    getKotaTujuan() {
      let province = this.ProvinsiTujuan.province;
      this.KotaTujuan = [];
      const baseURI = this.$settings.endPoint + `postal-code/select-city/` + province;

      return this.$http.get(baseURI).then((response) => {
        this.fetchKotaTujuan = response.data.datas;
      });

    },

    // asyncSearchProvinsiTujuan() {
    //   const baseURI = this.$settings.endPoint + "postal-code/select-province/Indonesia";

    //   return this.$http.get(baseURI).then((response) => {
    //     this.fetchProvinsiTujuan = response.data.datas;
    //   });
    // },

    // "blujay_order_id",
    //       "load_number",
    //       "detail_order_id",
    //       "shipment_number",
    //       "shipment_date",
    //       "vehicle_no",
    //       "master_driver_id",
    //       "driver_nmk",
    //       "driver_name",
    //       "driver_hp",
    //       "tanggal_muat",
    //       "tanggal_bongkar",
    //       "provinsi_tujuan",
    //       "kota_tujuan",
    //       "sku",
    //       "route",
    //       "qty",
    //       "qty_return",
    //       "sub_customer",
    //       "description",

    // "blujay_order_id": 3539,
    //             "load_id": "60530857",
    //             "vehicle_no": "B9153FXS",
    //             "driver_nmk": null,
    //             "driver_name": null,
    //             "driver_hp": null,
    //             "name_rute": "pickup :LTLCIKARANG BARAT(GUDANG CML) - LTLLEGOK(PT JAYA SWARASA AGUNG TBK)",
    //             "drop": "LTLLEGOK(PT JAYA SWARASA AGUNG TBK)",
    //             "order_detail_id": 3515,
    //             "shipment_number": "2100437094",
    //             "shipment_date": "2000-01-01 01:01:01",
    //             "state_pick": "BT",
    //             "city": "TANGERANG",
    //             "region": "LEGOK",
    //             "state_drop": "BT",
    //             "vehicle_status": "ACTIVE",
    //             "driver_status": null,
    //             "route": "BT-BT",
    //             "leadtime": 1
    closeMyModal(ev) {
      if (ev !== null) {
        this.forms.blujay_order_id = ev.blujay_order_id;
        this.forms.detail_order_id = ev.order_detail_id;
        this.forms.load_number = ev.load_id;
        this.forms.vehicle_no = ev.vehicle_no;
        this.forms.driver_nmk = ev.driver_nmk;
        this.forms.driver_name = ev.driver_name;
        this.forms.driver_hp = ev.driver_hp;
        this.forms.route = ev.name_rute;
        this.forms.shipment_number = ev.shipment_number;
        this.forms.shipment_date = ev.shipment_date;
        this.forms.tanggal_muat = ev.shipment_date;
        if(ev.leadtime){
          const cdate = new Date(ev.shipment_date);
          cdate.setDate(cdate.getDate() + ev.leadtime);
          this.forms.tanggal_bongkar = cdate;
          //this.forms.tanggal_bongkar = moment(String(tanggal_bongkar)).format('YYYY-MM-DD');
        }else{
          this.forms.tanggal_bongkar = ev.shipment_date;
        }
        
        this.ProvinsiTujuan = this.fetchProvinsiTujuan.filter((item) => {
          return (item.province === ev.region)
        })
        if(this.ProvinsiTujuan[0]){
          this.ProvinsiTujuan.province = this.ProvinsiTujuan[0]['province']
          this.getKotaTujuan();
          this.KotaTujuan = this.fetchKotaTujuan.filter((item) => {
            return (item.city === this.capitalize(ev.city))
          })

        }
        

      }

      this.showModalUnloading = false;
    },

    capitalize(value) {
      if (!value) return ''
      value = value.toLowerCase()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },

    getProvinsiTujuan(ev) {
      // console.log(ev)
      this.forms.blujay_order_id = ev.blujay_order_id;
      this.forms.load_number = ev.load_number;
      this.forms.vehicle_no = ev.vehicle_no;
      this.forms.driver_nmk = ev.driver_id;
      this.forms.driver_name = ev.driver_name;
      this.forms.driver_hp = ev.driver_hp;
    },

    isNumberLatLong(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 45) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    resetForm() {
      this.forms = "";
    },

    backForm() {
      window.location.href = "/informasiBongkar";
    },

    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },
    submitData() {
      this.isLoading = true

      const baseURI = this.$settings.endPointCt + "informasi-bongkar/create";
      this.forms.tanggal_bongkar = moment(String(this.forms.tanggal_bongkar)).format('YYYY-MM-DD');
      this.forms.tanggal_muat = moment(String(this.forms.tanggal_muat)).format('YYYY-MM-DD');
      this.forms.provinsi_tujuan = this.ProvinsiTujuan.province;
      this.forms.kota_tujuan = this.KotaTujuan.city;
      this.$http
        .post(baseURI, this.forms, { timeout: 10000 })
        .then((response) => {
          this.isLoading = false
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            //console.log(response.data.datas.user_id);
            var params = this.$onRandom(response.data.datas.informasi_bongkar_id);
            window.location.href = "/informasiBongkar/detail/" + params + "?status=200&msg=Successfully";
            // this.success('Berhasil');
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        }).catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error("Please Check Your Internet Connection");
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }

          }


        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "InformasiBongkarController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () { },
  mounted() {
    this.fetchIt();
    this.loadBlujay();
    this.loadProvinsi();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content {
  filter: blur(5px);
  pointer-events: none;

}
</style>