<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
        <div class="loadertext" v-if="this.isLoading"> {{ $t('loadingTxt') }}...</div>
        <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
            <!--begin::sidebar-->
            <sidebar-component classMenu="DashboardFullfilment"></sidebar-component>
            <!--end::sidebar-->

            <div class="page-wrapper">
                <!--begin::navbar-->
                <navbar-component></navbar-component>
                <!--end::navbar-->
                <!--begin::content-->
                <div class="page-content">
                    <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
                        <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    &nbsp;&nbsp;&nbsp;
                                    <h6
                                        class="card-title mb-0 float-start"
                                        style="padding-left: 25px"
                                    >
                                        Create Proforma Invoice
                                    </h6>
                                    <button
                                        class="btn btn-default btn-sm float-end"
                                        @click="resetForm"
                                        type="button"
                                    >
                                        <i class="link-icon" data-feather="repeat"></i>
                                        {{ $t("resetFormTxt") }}
                                    </button>
                                    <button
                                        class="btn btn-default btn-sm float-end"
                                        @click="recalculate"
                                        type="button"
                                    >
                                        <i class="link-icon" data-feather="refresh-cw"></i>
                                        Recalculate
                                    </button>
                                </div>
                                <div class="card-body">
                                    <!--begin::loading-data-->
                                    <div v-if="isLoading" class="d-flex align-items-center">
                                        <div
                                        class="spinner-border ms-auto"
                                        role="status"
                                        aria-hidden="true"
                                        ></div>
                                        <strong>{{ $t("loadingTxt") }}...</strong>
                                    </div>
                                    <div
                                        class="
                                            d-flex
                                            justify-content-between
                                            align-items-baseline
                                            mb-2
                                        "
                                    ></div>

                                    <div class="row mb-3" v-if="forms.proforma_invoice_number">
                                        <div class="col-lg-2">
                                            <label
                                            for="proforma_invoice_number"
                                            class="col-form-label"
                                            style="padding-left: 25px"
                                            >Proforma Invoice No</label
                                            >
                                        </div>
                                        <div class="col-lg-4">
                                            <input
                                            type="text"
                                            class="form-control"
                                            id="proforma_invoice_number"
                                            :placeholder="'Proforma Invoice No'"
                                            v-model="forms.proforma_invoice_number"
                                            disabled
                                            />
                                            <div v-if="errors.proforma_invoice_number">
                                            <div
                                                v-for="error in errors.proforma_invoice_number"
                                                :key="error"
                                                class="alert alert-primary"
                                                role="alert"
                                            >
                                                <i data-feather="alert-circle"></i>
                                                {{ error }}
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-lg-2">
                                            <label
                                            class="col-form-label"
                                            style="padding-left: 25px"
                                            >Enteries / Load Id</label
                                            >
                                        </div>
                                        <div class="col-lg-4">
                                            <input
                                            type="text"
                                            class="form-control"
                                            :placeholder="'Total Load Id'"
                                            :value="loadId.length"
                                            disabled
                                            />
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-lg-2">
                                            <label
                                            for="proforma_invoice_number"
                                            class="col-form-label"
                                            style="padding-left: 25px"
                                            >TOTAL</label
                                            >
                                        </div>
                                        <div class="col-lg-4">
                                            <input
                                            type="text"
                                            class="form-control"
                                            :placeholder="'TOTAL'"
                                            :value="this.totals"
                                            disabled
                                            />
                                        </div>
                                    </div>
                                    <!--end::loading-data-->
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <!-- @on-selected-rows-change="selectionChanged" 
                                                @on-page-change="onPageChange"
                                                @on-sort-change="onSortChange"
                                                @on-column-filter="onColumnFilter"
                                                @on-per-page-change="onPerPageChange" 
                                            -->
                                            <vue-good-table
                                                title="list-order-ct"
                                                mode="remote"
                                                :select-options="{ 
                                                                enabled: true,
                                                                selectOnCheckboxOnly: true, 
                                                                selectionText: $t('data_selected'), 
                                                                clearSelectionText: $t('clear'), }"
                                                :totalRows="totalRecords"
                                                :line-numbers="true"
                                                :isLoading.sync="isLoading"
                                                :pagination-options="{
                                                    enabled: true,
                                                }"
                                                :rows="rows"
                                                :columns="columns"
                                            >
                                            <div slot="table-actions">
                                                <button class="btn btn-success btn-xs" @click="addData">Add</button>
                                            </div>
                                                <div slot="selected-row-actions" >
                                                    <button class="btn btn-primary btn-xs" @click="deleteSelected" style="margin-right: 10px;">Delete</button>
                                                    <button class="btn btn-success btn-xs" @click="addData">Add</button>
                                                </div>
                                                <template slot="table-row" slot-scope="props">
                                                    <span v-if="props.column.field == 'entries'">
                                                        <a style="color: #ab0635;text-decoration: none; cursor: pointer; "
                                                            title="Detail Load Id"
                                                            @click.prevent="
                                                                detailPi(props.row)
                                                            "
                                                        >
                                                            {{ props.row.entries }}
                                                        </a>
                                                    </span>
                                                    <span v-else>
                                                        {{ props.formattedRow[props.column.field] }}
                                                    </span>
                                                </template>
                                            </vue-good-table>

                                        </div>
                                    </div>

                                </div>
                                <div class="card-footer">
                                    <button
                                        class="
                                        btn btn-warning
                                        text-black
                                        fw-bolder
                                        btn-icon-text
                                        "
                                        @click="backForm"
                                        type="button"
                                    >
                                        <i class="link-icon" data-feather="arrow-left"></i>
                                        {{ $t("backMess") }}
                                    </button>

                                    &nbsp;&nbsp;&nbsp;
                                    <button
                                        v-if="!isLoading"
                                        class="btn btn-success float-end btn-sm"
                                        type="submit"
                                    >
                                        <i class="link-icon" data-feather="save"></i>
                                        {{ $t("submitOrderTxt") }}
                                    </button>
                                    <a v-if="isLoading" class="spinner-border text-primary float-end me-2" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                    <modal-proformaInvoiceDetail
                        :showModal="showModalPI" 
                        :data="this.detailData"
                        @closeModal="closeMyModal">
                    </modal-proformaInvoiceDetail>
                </div>
                <!--end::content-->

                <!--begin::footer-->
                <footer-component></footer-component>
                <!--end::footer-->
            </div>
        </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import ModalProformaInvoiceDetail from "@/components/modal/modalProformaInvoiceDetail.vue";

export default {
    name:"proformaInvoiceCreate",
    components: {
        "sidebar-component": sidebarComponent,
        "navbar-component": navbarComponent,
        "footer-component": footerComponent,
        "modal-proformaInvoiceDetail":ModalProformaInvoiceDetail,
    },
    data() {
        return {
            closeBtn: true,
            isLoading: false,
            errors: [],
            proformaInvoiceNo:"",
            showModalPI:false,
            loadId:[],
            totalRecords:0,
            forms:[],
            detailData:[],
            columns: [
                {
                    label: "Sold to Party Name",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("companyTxt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: "Load Id",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("companyTxt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: "Nopol",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("companyTxt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: "Equipment Description",
                    type: 'date',
                    dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
                    dateOutputFormat: 'dd MMM yyyy h:mm:ss a',//'dd MMM yyyy',
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("create_at_txt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: "Loading Date",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("companyTxt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: "Unloading date",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("companyTxt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: "Origin",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("companyTxt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: "Destination",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("companyTxt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: "Delivery Order",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("companyTxt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: "Shipment Numbers",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("companyTxt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: "UOM",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("companyTxt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: "Qty / Ton",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("companyTxt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: "Billable Rate",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("companyTxt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: "Charge Loading",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("companyTxt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: "Charge Unloading",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("companyTxt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: "Charge Multi Pickup",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("companyTxt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: "Charge Multi drop",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("companyTxt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: "Charge Overnight ",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("companyTxt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: "Other",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("companyTxt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: "Total",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("companyTxt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: "Product Name",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("companyTxt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: "Outlet Name",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("companyTxt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },  
            ],
           
        }
    },  
    methods: {
        detailPi(data){
            console.log(data);
        },
        deleteData(index,data){
            console.log(data,index);
        },
        editData(index,data){
            console.log(data,index);
        },
        addData(){
            this.detailData['method'] = "create";
            this.showModalPI = true;
            this.showModalPI = true;
        },
        resetForm(){
            console.log("test");
        },
        recalculate(){
            console.log("recalculate");
        },
        backForm(){
            window.location.href = "/proforma-invoice/created"
        },
        closeMyModal() {
            this.showModalPI = false;
            this.loadItems();
        },
    },
    computed:{
        totals(){
            var total = 0;
            this.loadId.forEach((value)=>{
                total += value.total;
            });
           return total;
        }
    }
}
</script>

<style>

</style>