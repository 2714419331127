<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="CustomerCTEdit"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            "
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left: 25px">
                    {{ $t("customerEditTxt") }}
                  </h6>
                  <button
                    class="btn btn-default float-end"
                    @click="resetForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>

                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->
                    <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "
                    ></div>


                    <div class="row">
                    

                      <div class="col-lg-6">
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                            >
                              {{ $t("location_reference_number_txt") }} </label
                            >
                          </div>
                          <div class="col-lg-8">
                     
                            <input
                              type="text"
                              class="form-control"
                              id="location_reference_number"
                              :placeholder="$t('location_reference_number_txt')"
                              v-model="forms.location_reference_number"
                              @keyup="uppercase"
                              @keydown.space="removeSpace"
                              maxlength="55"
                              required
                            />

                            <div v-if="errors.location_reference_number">
                              <div
                                v-for="error in errors.location_reference_number"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                  

                      </div>

                      <div class="col-lg-6">
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                            >
                              {{ $t("nameTxt") }} </label
                            >
                          </div>
                          <div class="col-lg-8">
                     
                            <input
                              type="text"
                              class="form-control"
                              id="name"
                              :placeholder="$t('nameTxt')"
                              v-model="forms.name"
                              maxlength="55"
                              required
                            />

                            <div v-if="errors.name">
                              <div
                                v-for="error in errors.name"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                  

                      </div>

                      <div class="col-lg-6">
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                            >
                              {{ $t("countryTxt") }} </label
                            >
                          </div>
                          <div class="col-lg-8">
                     
                            <input
                              type="text"
                              class="form-control"
                              id="country"
                              :placeholder="$t('countryTxt')"
                              v-model="forms.country"
                              maxlength="55"
                              required
                            />

                            <div v-if="errors.country">
                              <div
                                v-for="error in errors.country"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                  

                      </div>

                      <div class="col-lg-6">
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                            >
                              {{ $t("stateTxt") }} </label
                            >
                          </div>
                          <div class="col-lg-8">
                     
                            <input
                              type="text"
                              class="form-control"
                              id="state"
                              :placeholder="$t('stateTxt')"
                              v-model="forms.state"
                              maxlength="55"
                            />

                            <div v-if="errors.state">
                              <div
                                v-for="error in errors.state"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                  

                      </div>

                      <div class="col-lg-6">
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                            >
                              {{ $t("regionCode") }} </label
                            >
                          </div>
                          <div class="col-lg-8">
                     
                            <input
                              type="text"
                              class="form-control"
                              id="region"
                              :placeholder="$t('regionCode')"
                              v-model="forms.region"
                              maxlength="55"
                            />

                            <div v-if="errors.region">
                              <div
                                v-for="error in errors.region"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                  

                      </div>

                      <div class="col-lg-6">
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                            >
                              {{ $t("companyCity") }} </label
                            >
                          </div>
                          <div class="col-lg-8">
                     
                            <input
                              type="text"
                              class="form-control"
                              id="city"
                              :placeholder="$t('companyCity')"
                              v-model="forms.city"
                              maxlength="55"
                              required
                            />

                            <div v-if="errors.city">
                              <div
                                v-for="error in errors.city"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                  

                      </div>

                      <div class="col-lg-6">
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                            >
                              {{ $t("address") }} </label
                            >
                          </div>
                          <div class="col-lg-8">
                     
                            <input
                              type="text"
                              class="form-control"
                              id="address"
                              :placeholder="$t('address')"
                              v-model="forms.address"
                              maxlength="255"
                            />

                            <div v-if="errors.address">
                              <div
                                v-for="error in errors.address"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                  

                      </div>

                      <div class="col-lg-6">
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                            >
                              {{ $t("companyPostalCode") }} </label
                            >
                          </div>
                          <div class="col-lg-8">
                     
                            <input
                              type="text"
                              class="form-control"
                              id="postal_code"
                              :placeholder="$t('companyPostalCode')"
                              v-model="forms.postal_code"
                              maxlength="10"
                              @keypress="isNumber"
                              required
                            />

                            <div v-if="errors.postal_code">
                              <div
                                v-for="error in errors.postal_code"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                  

                      </div>

                      <div class="col-lg-6">
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                            >
                              Latitude </label
                            >
                          </div>
                          <div class="col-lg-8">
                     
                            <input
                              type="text"
                              class="form-control"
                              id="latitude"
                              placeholder="Latitude"
                              v-model="forms.latitude"
                              @keypress="isNumber"
                            />

                            <div v-if="errors.latitude">
                              <div
                                v-for="error in errors.latitude"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                  

                      </div>

                      <div class="col-lg-6">
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                            >
                              Longitude </label
                            >
                          </div>
                          <div class="col-lg-8">
                     
                            <input
                              type="text"
                              class="form-control"
                              id="Longitude"
                              placeholder="Longitude"
                              v-model="forms.longitude"
                              @keypress="isNumber"
                            />

                            <div v-if="errors.longitude">
                              <div
                                v-for="error in errors.longitude"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                  

                      </div>

                      
                    </div>


                    <div class="card-footer">
                      <button
                        class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        "
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button
                        class="btn btn-primary float-end btn-sm"
                        type="submit"
                      >
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
 
export default {
  name: "CustomerCTEdit",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent
  },
  data() {
    return {
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      permision_role:[],
      fetchTypeDocument: [],
      fetchDocumentReference:[],
     
      highlighted:{

          dates: [ // Highlight an array of dates
            new Date(),
          ],

      },
      type_document: "",
      document_reference: "",
      //document_source: "",
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      forms: {
        master_customer_id : "",
        location_reference_number : "",
        name : "",
        address : "",
        city : "",
        region : "",
        state : "",
        country : "",
        postal_code : "",
        latitude : "",
        longitude : "",
        create_by : "",
        created_at : "",
        update_by : "",
        updated_at : ""
      },
    };
  },
  watch: {},
  methods: {

    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 45) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    uppercase() { 
        this.forms.location_reference_number  = this.forms.location_reference_number.toUpperCase();
    },
    removeSpace(event) {
       if(event.keyCode === 32){
        event.preventDefault();
      }
    },

    resetForm() {
      this.forms={
        master_customer_id : "",
        location_reference_number : "",
        name : "",
        address : "",
        city : "",
        region : "",
        state : "",
        country : "",
        postal_code : "",
        latitude : "",
        longitude : "",
        create_by : "",
        created_at : "",
        update_by : "",
        updated_at : ""
      };
    },

    backForm() {
      window.location.href = "/master-customer-ct";
    },

    loadData(id) {
      const baseURI = this.$settings.endPointCt + "master-customer/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        
        this.forms = response.data.datas;
        
      });
    },

    fetchIt() {
      const userDatas     = this.$getUserInfo();
      this.detailUser     = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function(permision){
        if(permision.permission.controller == 'MasterCustomerController'){
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if(!rolePermision.method_update){
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;

    },
  

    submitData() {
     this.fade(true);
      
          const baseURI =
            this.$settings.endPointCt + "master-customer/update/" + this.forms.master_customer_id;
          this.$http
            .patch(baseURI, this.forms)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                //this.resetForm();
                this.errors = [];
                this.success(response.data.datas.messages);

                //window.history.back();
                var params = this.$onRandom(this.forms.master_customer_id);
                window.location.href = "/master-customer-ct/detail/" + params + "?status=200&msg=Successfully";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                 this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
              //this.resetForm();
            });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.loadData(params[0]);
    this.fetchIt();
  },
};
</script>
<style scoped>
</style>