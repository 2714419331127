<template>
    <div class="row">
      <vue-good-table
        title="list-of-gps-erorrs-reporting"
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :sort-options="{
          enabled: false,
        }"
        :totalRows="totalRecords"
        :line-numbers="true"
        :isLoading.sync="isLoading"
        :pagination-options="{
          enabled: true,
        }"
        :filterOptions="{
          enabled: false,
        }"
        :rows="rowsTable"
        :columns="columnsOrder"
      >
        <!-- <div slot="table-actions">
          <div class="vgt-clearfix">
            <button class="btn btn-success btn-xs" style="font-size: 10pt; margin-top:-7px" @click.prevent="TambahFormModal()">
              <i class="link-icon" data-feather="plus"></i>
              {{ $t("createNew") }}
            </button>
          </div>
        </div> -->
        <template slot="table-row" slot-scope="props">
          
          <span v-if="props.column.field == 'control'">
            <!-- <a
              href="#"
              title="Detail"
              @click.prevent="detailData(props.index, props.row)"
            >
              <i class="mdi mdi-eye" style="font-size: 16px"></i>
            </a> -->
            <a
              href="#"
              title="Edit"
              @click.prevent="EditFormModal(props.row)"
            >
              <i class="mdi mdi-square-edit-outline" style="font-size: 16px"></i>
            </a>
  
            <a
              href="#"
              title="Delete"
              @click.prevent="deleteData(props.index, props.row)"
            >
              <i class="mdi mdi-trash-can-outline" style="font-size: 16px"></i>
            </a>
          </span>
  
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
  
      <modal-traffict-violation
        :showModal="showModalNow"
        :data="detail"
        :vehicle_no="vehicle_no"
        @closeModal="closeMyModal"
      ></modal-traffict-violation>
  
    </div>
  </template>
  
  
  <script>
  import modalTraffictViolation from "@/components/modal/modalTraffictViolation.vue";
  import moment from "moment";
  export default {
    name: "traffictViolationComponent",
    components: {
      "modal-traffict-violation": modalTraffictViolation,
  
      // DirectionsRenderer,
    },
    props: {
      data: [],
      dateRange: { type: [Object, Array] }
    },

    watch: {
    
        dateRange: function(val) {
        this.loadTable(val)
        },
  
    },

    data() {
      return {
        vehicle_no:this.$route.params.vehicle_no,
        detail:"",
        showModalNow: false,
        isLoading: false,
        totalRecords: 0,
        serverParams: {
          columnFilters: {},
          sort: {
            field: "",
            type: "",
          },
          page: 1,
          per_page: 10,
        },
        rowsTable: [],
        columnsOrder: [
        {
            label: "Vehicle No",
            field: "vehicle_no",
          },

          {
            label: "Category",
            field: "category",
          },

          {
            label: "Reason",
            field: "reason",
          },

          {
            label: "Location",
            field: "location",
          },

          {
            label: "Driver NMK",
            field: "driver_nmk",
          },

          {
            label: "Driver Name",
            field: "driver_name",
          },
          {
            label: "Driver Hp",
            field: "driver_hp",
          },
          {
            label: "Load ID",
            field: "load_number",
          },

          {
            label: "Date",
            field: "tv_date",
          },

          {
            label: "Result",
            field: "tv_result",
          },
  
          {
            label: "Control",
            field: "control",
          },
        ],
        //
      };
    },
    methods: {
      createData() {},
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
  
      closeMyModal() {
        this.showModalNow = false;
        this.loadData(this.vehicle_no);
      },
  
      TambahFormModal() {
        this.detail = '';
        this.showModalNow = !this.showModalNow;
      },
  
  
      EditFormModal(data) {
        this.detail =data;
        this.showModalNow = !this.showModalNow;
      },
  
      deleteData(index, row, status) {
        this.$swal({
          title: this.$t("areYouSure"),
          text: this.$t("yourDataDelete"),
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        }).then((result) => {
          if (result.value) {
            var formData = {
              status: status,
            };
            const baseURI =
              this.$settings.endPointCt + "gps-reporting/delete/" + row.gps_reporting_id;
            this.$http
              .delete(baseURI, formData)
              .then((response) => {
                
                if (response.data.status === 200) {
                  this.loadSuccess(response.data.datas.messages);
                  this.loadData(this.$route.params.vehicle_no);
                } else {
                  this.errors = response.data.errors;
                  this.loadError(response.data.errors);
                }
              })
              .catch((error) => {
               
                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.loadError(this.errors);
                  } 
                }
              });
          }
        });
      },
  
      onColumnFilter(params) {
        params.page = 1;
        this.updateParams(params);
      },
  
      onPageChange(params) {
        this.updateParams({ page: params.currentPage });
      },
  
      loadData(ev) {
        let startDate=moment(String(ev.startDate)).format("YYYY-MM-DD HH:mm:ss");
        let endDate=moment(String(ev.endDate)).format("YYYY-MM-DD HH:mm:ss");
        const baseURI =
          this.$settings.endPointCt + `driver-tv?vehicle_no=` + this.vehicle_no+`start=`+startDate+`end=`+endDate;
        return this.$http.get(baseURI).then((response) => {
          this.rowsTable = response.data.datas.data;
          
          //console.log(this.rowsTable);
        });
      },
  
      loadError(pesan) {
        this.$swal({
          backdrop: true,
          title: "Warning",
          html: pesan,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      },
  
  
  
  
      loadSuccess(pesan) {
        this.$swal({
          backdrop: true,
          title: "Success",
          html: pesan,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      },
  
      onSortChange(params) {
        this.updateParams({
          sort: {
            type: params[0].type,
            field: params[0].field,
          },
        });
      },
  
      onPerPageChange(params) {
        this.updateParams({ per_page: params.currentPerPage });
      },
    },
    mounted() {
      this.loadData(this.vehicle_no);
    },
  };
  </script>
  