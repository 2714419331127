<template>
    <div class="col-lg-12 col-xl-12 stretch-card">
      <div class="card">
        <div class="card-header">
          <h4 style="margin-right: 5px" class="float-start">TRANSPORT ORDER | STATUS: PNL NEED APPROVAL</h4>
                          
  
          <button
            v-if="permision_role.method_read"
            class="btn btn-success float-end btn-xs"
            style="margin-right: 5px"
            @click.prevent="downloadData()"
          >
              <i class="link-icon" data-feather="download"></i>
            {{ $t("downloadData") }} .xlsx
          </button>
        </div>
  
        <div class="card-body">
  
          <div class="table-responsive" style="overflow-y: auto;">
            <div class="col-sm-12">
                  
              <vue-good-table
                title="list-order-ct"
                ref="list-order-ct"
                mode="remote"
                :select-options="{ 
                                enabled: true,
                                selectOnCheckboxOnly: true, 
                                selectionText: $t('data_selected'), 
                                clearSelectionText: $t('clear'), }"
                @on-selected-rows-change="selectionChanged" 
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                :totalRows="totalRecords"
                :line-numbers="true"
                :isLoading.sync="isLoading"
                :pagination-options="{
                  enabled: true,
                }"
                
                :rows="rows"
                :columns="columns"
              >

                
                <div slot="table-actions">
                  {{ $t("no_select_data") }}
                </div>
                  <div slot="selected-row-actions">
                    <button class="btn btn-success btn-xs" style="margin-right:20px;" @click="sendOrder"> <i class="mdi mdi-check"></i> Margin Approval</button>
  
                    <!-- <button class="btn btn-danger btn-xs" @click="cancelOrder"><i class="mdi mdi-close"></i>  Rejected </button> -->
                  </div>
  
              
  
                <template slot="table-row" slot-scope="props">
                                    
  
                    <span v-if="props.column.field == 'actions'">
                          <a style="color: #ab0635;text-decoration: none;"
                            v-if="permision_role.method_read"
                            title="Detail"
                            @click.prevent="
                              detailData(props.index, props.row)
                            "
                          >
                            <i class="mdi mdi-eye" style="font-size:16px"></i>
                            
                          </a>
  
                          
                          <!--a style="color: #ab0635;text-decoration: none;"
                            v-if="permision_role.method_update"
                            title="Edit"
                            @click.prevent="editData(props.index, props.row)"
                          >
                            <i class="mdi mdi-square-edit-outline" style="font-size:16px"></i>
                            
                          </a-->
  
                    </span>

                    <span v-else-if="props.column.field == 'order_no'">
                      <a style="color: #ab0635;text-decoration: none; cursor: pointer; "
                            v-if="permision_role.method_read"
                            title="Detail Maps"
                            @click.prevent="
                              clickOrder(props.row)
                            "
                          >
                            {{ props.row.order_no }}
                            
                          </a>


                    </span>


                    <span v-else-if="props.column.field == 'pick_plan'">
                      <div :style="checkPickPlanDate(props.row.pick_plan)">{{$moment(props.row.pick_plan).format("D MMM yyyy h:mm:ss A")}}</div> 
  
                    </span>
                    <span v-else-if="props.column.field == 'submit_time'">
  
                      <div :style="checkSubmitDate(props.row)">{{$moment(props.row.submit_time).format("D MMM yyyy h:mm:ss A")}}</div> 
  
                    </span>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>
                </vue-good-table>
  
            </div>
          </div>
          
  
  
        </div>
        
      </div>


      <modal-margin-approval 
          :showModal="showModal" 
          :data="selectedData"
          :status="status_decision"
          @closeModal="closeMyModal">
      </modal-margin-approval>


      <modal-margin-approval-all 
          :showModal="showModalAll" 
          :data="transport_order_header_ids"
          :status="status_decision"
          @closeModal="closeMyModalAll">
      </modal-margin-approval-all>

    </div>
    
       
    
    
    
    </template>
    
    <script>

  import ModalMarginApproval from "@/components/modal/modalMarginApproval";
  import modalMarginApprovalAll from "@/components/modal/modalMarginApprovalAll";

    export default {
      name: "TransportOrderCtPnl",
      components: {
        "modal-margin-approval": ModalMarginApproval,
        "modal-margin-approval-all": modalMarginApprovalAll,
      },
      props: {
        dateRange: { type: [Object, Array] },
        status: String
      },
     
      data() {
        return {
          selectedData:'',
          showModal:false,
          showModalAll:false,
          maxToasts: 100,
          ORDER_RESPONSE_TIME:0,
          position: "up right",
          closeBtn: true,
          isLoading: false,
          errors: [],
          status_decision:'',
          permision_role:[],
          transport_order_header_ids:[],
          langs: ["id", "en"],
          detailUser: [],
          detailDivisi: [],
          totalRecords: 0,
          serverParams: {
            columnFilters: {},
            sort: {
              field: "",
              type: "",
            },
            page: 1,
            per_page: 10,
          },
          columns: [

         
            {
              label:"Customer",
              field: "customer_name",
              filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By Customer", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
              },
            },
            {
              label:"Load Id",
              field: "loadid",
              filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By Load Id", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
              },
            },
         

            {
              label:"Shipwithref",
              field: "shipwith_ref",
              filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By Type Truck", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
              },
            },

         

            {
          label: this.$t("pickup_plan_txt"),
          field: "pick_location",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By "+this.$t("pickup_plan_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("dest_plan_txt"),
          field: "drop_location",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By "+this.$t("dest_plan_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

       
       

            {
              label:"Carrier",
              field: "carrier_name",
              filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By carrier", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
              },
            },



            {
              label:"PNL Value",
              field: "pnl_value",
              filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By ", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
              },
            },


            {
              label:"PNL Percent",
              field: "pnl_percent",
              filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By ", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
              },
            },


            {
              label:"Branch",
              field: "branch",
              filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By ", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
              },
            },


            {
              label:"Vehicle Type",
              field: "vehicle_type",
              filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By ", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
              },
            },


            {
              label:"Reason",
              field: "reason",
              filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By ", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
              },
            },

            {
              label: this.$t("action_txt"),
              field: "actions",
              sortable: false,
            },
          ],
          rows: [],
          rowsSelection:[],
        };
      },
      watch: {
        dateRange: {
          handler(newVal) {
            console.log(newVal);
            this.loadItems();
          },
         
        },
      },
      computed: {
      },
      methods: {
        closeMyModal() {
          this.showModal = false;
          this.loadItems();
        },
        closeMyModalAll() {
          this.showModalAll = false;
          this.loadItems();
        },
        checkSubmitDate(row){
          const dateNow             = this.$moment(new Date()).format("YYYY-MM-DD h:mm:ss");
          const dateData            = this.$moment(row.submit_time).format("YYYY-MM-DD h:mm:ss");
          const dateDataPickup      = this.$moment(row.pick_plan);
          const diff_in_millis      = Math.abs(new Date(dateData) - new Date(dateNow))
          const diff_in_minutes     = diff_in_millis/60000
          const precentaceData      = (diff_in_minutes * 100) / 25
     
        
          var hoursDataPickup = dateDataPickup.diff(dateNow, 'hours')
          if(hoursDataPickup > 48){
            return "";
          }else{
    
            if(diff_in_minutes > this.ORDER_RESPONSE_TIME){
    
              return {
                "background-color": "red",
                "color":"white"
              }
    
            }else if(precentaceData > 25){
              return {
                "background-color": "yellow",
                "color":"black"
              }
            }else{
              return "";
            }
    
          }
        },

        clickOrder(order){
          this.$emit('show_order', order);

          
        },
    
        checkPickPlanDate (val) {
          const dateNow   = this.$moment(new Date()).format("YYYY-MM-DD")+" 23:59:59";
          const dateData  = this.$moment(val).format("YYYY-MM-DD")+" 23:59:59";
          if(new Date(dateData) < new Date(dateNow)){
    
             return {
              "background-color": "red",
              "color":"white"
            }
    
          }else if(new Date(dateData) > new Date(dateNow)){
    
            return ""
    
          }else{
    
             return {
              "background-color": "yellow",
              "color":"black"
            }
    
          }
        },
    
        selectionChanged(ev){
        
          this.rowsSelection=[];
          this.transport_order_header_ids=[];
          this.rowsSelection=ev.selectedRows;
    
          this.rowsSelection.forEach((item) => {
            this.transport_order_header_ids.push(item.transport_order_header_id);
           
            
          });

          console.log(this.transport_order_header_ids);
    
        },
    
        fetchIt() {
          const userDatas     = this.$getUserInfo();
          this.detailUser     = userDatas.sub;
          // this.permision_role = userDatas.sub.permision_role;
          let rolePermision = [];
          userDatas.sub.permision_role.forEach(function(permision){
            if(permision.permission.controller == 'TransportOrderMarginController'){
              rolePermision = permision;
            }
          });
    
          if(!rolePermision.method_read){
            this.$router.push("/authorized-error");
          }
          this.permision_role = rolePermision;
    
          if(this.$route.query.msg){
            if(this.$route.query.status == 200){
              this.success(this.$route.query.msg)
            }else{
              this.error(this.$route.query.msg)
            }
    
          }
    
        },
    
    
        sendOrder(){
          this.showModalAll=true;
          this.status_decision="APPROVE" 
        },
    
    
        cancelOrder(){
          this.showModal=true;
          this.status_decision="REJECT" 
        },
    
        updateFormatDate(date){
          let date_format = this.$moment(date).format("dd mmm yyyy");
          return date_format;
        },
    
        downloadData() {
          this.isLoading = true;
          var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
          var file_name = "download_" + CurrentDate + ".xlsx";
    
               const baseURI = this.$settings.endPoint + "transport-order-margin";
          //var columnFilters = this.serverParams.columnFilters;
          //var date_order = '';
        //   if(this.serverParams.columnFilters.order_date!=''){
        //     date_order =this.$moment(this.serverParams.columnFilters.order_date).format("YYYY-MM-DD");
        //   }
          //var columnFilters = this.serverParams.columnFilters;
          // var pick_plan = '';
          // if(this.serverParams.columnFilters.pick_plan!=''){
          //   pick_plan =this.$moment(this.serverParams.columnFilters.pick_plan).format("YYYY-MM-DD");
          // }
          // //var columnFilters = this.serverParams.columnFilters;
          // var dest_plan = '';
          // if(this.serverParams.columnFilters.dest_plan!=''){
          //   dest_plan =this.$moment(this.serverParams.columnFilters.dest_plan).format("YYYY-MM-DD");
          // }
         
          //  var company_name = this.serverParams.columnFilters["company.name"];
        //    var transport_order_template_description = this.serverParams.columnFilters["transport_order_template.description"];
        //    var transport_order_template_tonnage     = this.serverParams.columnFilters["transport_order_template.tonnage"];
        //    var transport_order_template_cubic       = this.serverParams.columnFilters["transport_order_template.cubic"];
           var startDate= this.dateRange.startDate;
           var endDate= this.dateRange.endDate;
           var pnl_date=startDate+'~'+endDate;
    
          return this.$http
            .get(
              baseURI +
              `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&customer_name=${this.serverParams.columnFilters.customer_name}&shipwith_ref=${this.serverParams.columnFilters.shipwith_ref}&branch=${this.serverParams.columnFilters.branch}&pnl_date=${pnl_date}&reason=${this.serverParams.columnFilters.reason}&vehicle_type=${this.serverParams.columnFilters.vehicle_type}&pick_location=${this.serverParams.columnFilters.pick_location}`,
              {
                responseType: "blob",
                timeout: 200000
              }
            )
            .then((response) => {
             
              var fileURL = window.URL.createObjectURL(new Blob([response.data]));
              var fileLink = document.createElement("a");
    
              fileLink.href = fileURL;
              fileLink.setAttribute("download", file_name);
              document.body.appendChild(fileLink);
              fileLink.click();
              this.isLoading = false;
            }).catch(error => {
              this.isLoading = false;
              if(error.code == "ECONNABORTED"){
                this.error(this.$t("check_internet_connection_txt"));
              }else{
    
                if (error.response) {
                  if(error.response.status === 422) {
                          this.errors = error.response.data.errors;
                          this.resultError(error.response.data.errors);
                  }else if (error.response.status === 500) {
                      this.$router.push('/server-error');
                  }else if (error.response.status === 401) {
                      this.$router.push("/authorized-error");
                  }else{
                      this.$router.push('/page-not-found');
                  }
                }
    
              }
    
            });
        },
    
     
    
        editData(index, row) {
          var params = this.$onRandom(row.transport_order_header_id);
          window.location.href = "/ct-orders/edit/" + params;
        },
    
        detailData(index, row) {
          var params = this.$onRandom(row.transport_order_header_id);
          window.location.href = "/ct-orders/detail/" + params;
        },
     
    
        // load items is what brings back the rows from server
        loadItems() {
          this.isLoading = true;
          const baseURI = this.$settings.endPoint + "transport-order-margin";
          //var columnFilters = this.serverParams.columnFilters;
          //var date_order = '';
          // if(this.serverParams.columnFilters.order_date!=''){
          //   date_order =this.$moment(this.serverParams.columnFilters.order_date).format("YYYY-MM-DD");
          // }
          //var columnFilters = this.serverParams.columnFilters;
          // var pick_plan = '';
          // if(this.serverParams.columnFilters.pick_plan!=''){
          //   pick_plan =this.$moment(this.serverParams.columnFilters.pick_plan).format("YYYY-MM-DD");
          // }
          // //var columnFilters = this.serverParams.columnFilters;
          // var dest_plan = '';
          // if(this.serverParams.columnFilters.dest_plan!=''){
          //   dest_plan =this.$moment(this.serverParams.columnFilters.dest_plan).format("YYYY-MM-DD");
          // }

          // var reject_time = '';
          // if(this.serverParams.columnFilters.reject_time!=''){
          //   reject_time =this.$moment(this.serverParams.columnFilters.reject_time).format("YYYY-MM-DD");
          // }
          //  var company_name = this.serverParams.columnFilters["company.name"];
          //  var transport_order_template_description = this.serverParams.columnFilters["transport_order_template.description"];
          //  var transport_order_template_tonnage     = this.serverParams.columnFilters["transport_order_template.tonnage"];
          //  var transport_order_template_cubic       = this.serverParams.columnFilters["transport_order_template.cubic"];
       
           var startDate=this.$moment(this.dateRange.startDate).format("YYYY-MM-DD");
           var endDate= this.$moment(this.dateRange.endDate).format("YYYY-MM-DD");
           var pnl_date=startDate+'~'+endDate;
          // var status=this.status
          //  console.log(this.status);
     
          return this.$http
            .get(
               baseURI +
               `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&customer_name=${this.serverParams.columnFilters.customer_name}&shipwith_ref=${this.serverParams.columnFilters.shipwith_ref}&branch=${this.serverParams.columnFilters.branch}&pnl_date=${pnl_date}&reason=${this.serverParams.columnFilters.reason}&vehicle_type=${this.serverParams.columnFilters.vehicle_type}&pick_location=${this.serverParams.columnFilters.pick_location}`,
              { timeout: 200000 }
            )
            .then((response) => { 
              this.rows = response.data.datas.data;
              this.totalRecords = response.data.datas.total;
            //   if(this.status=='NEW'){
            //     this.$emit('passDataNew', this.totalRecords);
            //   }
    
          
                this.$emit('passData', this.totalRecords);
              
    
              // if(this.status=='REJECTED'){
              //   this.$emit('passDataRejected', this.totalRecords);
              // }
    
              // if(this.status=='COMPLETED'){
              //   this.$emit('passDataCompleted', this.totalRecords);
              // }
    
              // if(this.status=='RESCHEDULE'){
              //   this.$emit('passDataReschedule', this.totalRecords);
              // }
    
              // if(this.status=='REROUTE'){
              //   this.$emit('passDataReroute', this.totalRecords);
              // }
              
              this.isLoading = false;
            }).catch(error => {
              this.isLoading = false;
              if(error.code == "ECONNABORTED"){
                this.error(this.$t("check_internet_connection_txt"));
              }else{
    
                if (error.response) {
                  if(error.response.status === 422) {
                          this.errors = error.response.data.errors;
                          this.resultError(error.response.data.errors);
                  }else if (error.response.status === 500) {
                      this.$router.push('/server-error');
                  }else if (error.response.status === 401) {
                      this.$router.push("/authorized-error");
                  }else{
                      this.$router.push('/page-not-found');
                  }
                }
    
              }
    
            });
        },
    
        updateParams(newProps) {
          this.serverParams = Object.assign({}, this.serverParams, newProps);
          this.loadItems();
        },
    
        onColumnFilter(params) {
          params.page = 1;
          this.updateParams(params);
        },
    
        onPageChange(params) {
          this.updateParams({ page: params.currentPage });
        },
    
        onSortChange(params) {
          this.updateParams({
            sort: {
              type: params[0].type,
              field: params[0].field,
            },
          });
        },
    
        onPerPageChange(params) {
          this.updateParams({ per_page: params.currentPerPage });
        },
       
    
        resultError(data) {
          var count = Object.keys(data).length;
          for (var x = 0; x < count; x++) {
            var nameOb = Object.keys(data)[x];
            var objectData = data[nameOb];
            for (var y = 0; y < objectData.length; y++) {
              this.error(objectData[y]);
            }
          }
        },
    
        success(kata) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: kata,
          });
        },
    
        success2(msg){
          let pesan="";
          msg.forEach((item) => {
              pesan=pesan+"<p>* "+item+"</p>";
          });
    
          this.$swal({
            icon: 'info',
            backdrop:true,
            title: "Information",
            html:pesan,
            showConfirmButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false
          })
    
          
        },
    
    
        loadingSubmiting(){
    
    
          this.$swal({
            icon: 'warning',
            backdrop:true,
            title: "Please wait",
            html:'<h2>SUBMITTING ORDER...</h2>',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false
          })
    
        },
        
    
        error(kata) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "error",
            title: kata,
          });
        },
    
        fade(sType) {
          this.isLoading = sType;
        },
    
        loading() {
          this.fade(true);
          setTimeout(() => {
            this.isLoading = false;
          }, 1000); // hide the message after 3 seconds
        },
    
    
        logout() {
          this.$logoutUser();
          this.$router.push("/");
        },
    
        getSystemCode() {
          const baseURI = this.$settings.endPoint + "system-code/select?system_code=ORDER_RESPONSE_TIME";
           
          return this.$http
            .get(
               baseURI,
              { timeout: 200000 }
            )
            .then((response) => {
              var rows = response.data.datas.data;
              if(rows.length > 0){
                this.ORDER_RESPONSE_TIME = rows[0].value;
              }
              
            }).catch(error => {
               
              if(error.code == "ECONNABORTED"){
                this.error(this.$t("check_internet_connection_txt"));
              }else{
    
                if (error.response) {
                  if(error.response.status === 422) {
                          this.errors = error.response.data.errors;
                          this.resultError(error.response.data.errors);
                  }else if (error.response.status === 500) {
                      this.$router.push('/server-error');
                  }else if (error.response.status === 401) {
                      this.$router.push("/authorized-error");
                  }else{
                      this.$router.push('/page-not-found');
                  }
                }
    
              }
    
            });
        },
    
      },
      events: {},
      mounted() {
        this.fetchIt();
        this.loading();
        this.getSystemCode();
    
     
      },
    };
    </script>
    
    
    
    