<template>
  <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Form Gps error Reporting</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
            aria-label="btn-close"></button>
        </div>

        <form class="forms-sample" method="POST" @submit.prevent="submitData">
          <div class="modal-body">
            <div v-if="isLoading" class="d-flex align-items-center">
              <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
              <strong>Loading...</strong>
            </div>
            <!--end::loading-data-->

            <div class="mb-3">
              <label class="form-label">Vehicle No</label>
              <input type="text" id="vehicle_no" name="vehicle_no" v-model="forms.vehicle_no" class="form-control"
                readonly />
            </div>

            <div class="mb-3">
              <label class="form-label">Report Date </label>
              <date-range-picker style="width: 100%" control-container-class="form-control" :locale-data="locale"
                v-model="report_date" opens="center" :singleDatePicker="true" :timePicker24Hour="true"
                :timePicker="true" :showWeekNumbers="false">
              </date-range-picker>
            </div>

            <div class="mb-3">
              <label class="form-label">Error Type</label>
              <select class="form-select form-select-sm mb-3" v-model="forms.error_type">
                <option v-for="(od, index) in type_error" :key="index" :value="od.type_error">
                  {{ od.type_error }}
                </option>
              </select>
            </div>

            <div class="mb-3">
                <label class="form-label">Driver Name</label>
                <v-select :options="fetchDriver" v-model="driver" label="driver_name" return-object
                  @search="asyncLoadDriver"></v-select> 
            </div>

            <div class="mb-3">
              <label class="form-label">Remark</label>
              <textarea class="form-control" id="remark" v-model="forms.remark" rows="5"></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
              Close
            </button>
            <button class="btn btn-primary" type="submit">Save changes</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";

export default {
  
  components: {
    DateRangePicker,
    // DateRangePicker
  },
  name: "ModalGpsErrorReporting",
  emits: ["closeModal"],
  props: {
    showModal: Boolean,
    data: null,
    vehicle_no: null,
  },
  watch: {
    showModal: {
      handler(newVal) {
        if (this.data != '') {

          const startDate = this.data.report_date;
          const endDate = new Date();
          this.forms = {
            gps_reporting_id: this.data.gps_reporting_id,
            vehicle_no: this.vehicle_no,
            error_type: this.data.error_type,
            remark: this.data.remark,
            driver_id: this.data.driver_id,
            jenis: 'edit'
          };

          this.report_date = {
            startDate,
            endDate,
          };


        } else {

          const startDate = new Date();
          const endDate = new Date();
          this.forms = {
            vehicle_no: this.vehicle_no,
            error_type: "",
            remark: "",
            jenis: 'add',
            driver_id: ""
          };

          this.report_date = {
            startDate,
            endDate,
          };
        }

        this.locale = {
          format: "yyyy-mm-dd HH:MM:ss",
        };

        this.active = newVal;
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      active: this.showModal,
      forms: [],
      locale: [],
      report_date: [],
      type_error: [],
      fetchDriver : [],
      driver: [],
    };
  },
  methods: {
    resetForm() {
      this.forms = [];
    },

    submitData() {
      if (this.forms.jenis == 'add') {
        this.forms.driver_id = this.driver.driver_id;
        this.forms.report_date = moment(
          String(this.report_date.startDate)
        ).format("YYYY-MM-DD HH:mm:ss");
        const baseURI = this.$settings.endPointCt + "gps-reporting/create";
        this.$http
          .post(baseURI, this.forms)
          .then((response) => {
            this.isloading = true;
            if (response.data.status === 200) {
              this.resetForm();
              this.$emit("closeModal");
              this.loadSuccess("Success");
            } else {
              this.errors = response.data.errors;
              this.resultError(response.data.errors);
            }
          })
          .catch((error) => {
            this.isloading = true;
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              }
            }
            //this.resetForm();
          });

      } else {
        this.forms.driver_id = this.driver.driver_id;
        this.forms.report_date = moment(
          String(this.report_date.startDate)
        ).format("YYYY-MM-DD HH:mm:ss");
        const baseURI = this.$settings.endPointCt + "gps-reporting/update" + "/" + this.forms.gps_reporting_id;
        this.$http
          .patch(baseURI, this.forms)
          .then((response) => {
            this.isloading = true;
            if (response.data.status === 200) {
              this.resetForm();
              this.$emit("closeModal");
              this.loadSuccess("Success");
            } else {
              this.errors = response.data.errors;
              this.resultError(response.data.errors);
            }
          })
          .catch((error) => {
            this.isloading = true;
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              }
            }
            //this.resetForm();
          });
      }

    },

    // loadDetail(data) {
    //   console.log(data);
    // },

    resultError(data) {
      var html = "";
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          html += objectData[y] + "<br>";
        }
      }

      this.loadError(html);
    },

    loadError(pesan) {
      this.$swal({
        backdrop: true,
        title: "Warning",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },

    loadDriver() {
      const baseURI = this.$settings.endPointCt + `master-driver`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchDriver = response.data.datas.data;
      });
    },

    asyncLoadDriver(ev) {
      const baseURI = this.$settings.endPointCt + `master-driver?driver_name=` + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchDriver = response.data.datas.data;
        //console.log(this.fetchDriver);
      });
    },

    loadSuccess(pesan) {
      this.$swal({
        backdrop: true,
        title: "Success",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },

    loadTypErorr() {
      const baseURI = this.$settings.endPointCt + `type-error`;
      return this.$http.get(baseURI).then((response) => {
        this.type_error = response.data.datas.data;
      });
    },
  },
  created: function () {
    this.loadDriver();

  },
  mounted() {
    //tail(this.data);
    this.loadTypErorr();
  },
};
</script>

<style scoped>
.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left:0px !important;
}
</style>