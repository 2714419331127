import { render, staticRenderFns } from "./PackageDetail.vue?vue&type=template&id=0b8b4ad6&scoped=true"
import script from "./PackageDetail.vue?vue&type=script&lang=js"
export * from "./PackageDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b8b4ad6",
  null
  
)

export default component.exports