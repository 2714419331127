<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="Role"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            "
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                 <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left:25px">Detail {{ $t("roleTxt") }}</h6>
                  <button
                    class="btn btn-light float-end"
                    type="button"
                    data-bs-toggle="collapse" 
                    data-bs-target="#collapseExample" 
                    aria-expanded="false" 
                    aria-controls="collapseExample"
                  >
                    <i class="link-icon" data-feather="info"></i>
                    Audit Info
                  </button>

                  <div class="row mb-3 collapse" id="collapseExample" style="padding-left:20px">
                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0 ">Created By:</label>
                      <p class="text-muted">{{this.forms.create_by}}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created At:</label>
                      <p class="text-muted">{{this.forms.created_at}}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated By:</label>
                      <p class="text-muted">{{this.forms.update_by}}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated At:</label>
                      <p class="text-muted">{{this.forms.updated_at}}</p>
                    </div>

                    
                    
                  </div>

                </div>

                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->
                 <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "
                    >
                    
                    </div>
                    
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                          $t('roleNameTxt')
                        }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          :placeholder="$t('roleNameTxt')"
                          v-model="forms.name"
                          disabled
                        />
                        <div v-if="errors.name">
                          <div
                            v-for="error in errors.name"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                          $t('descriptionTxt')
                        }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="description"
                          :placeholder="$t('descriptionTxt')"
                          v-model="forms.description"
                          disabled
                        />
                        <div v-if="errors.description">
                          <div
                            v-for="error in errors.description"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                       <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                          $t("branchTxt") 
                        }} </label>
                      </div>
                      <div class="col-lg-8">

                        <v-select
                          :options="fetchBranch"
                          v-model="branch"
                          label="branch_name"
                          return-object
                          @search="asyncSearchBranch"
                          multiple 
                          disabled
                        ></v-select>

                        <div v-if="errors.branch">
                          <div
                            v-for="error in errors.branch"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Truck Type </label>
                      </div>
                      <div class="col-lg-8">
                      
                        <v-select
                          :options="fetchTruckType"
                          v-model="truck_type"
                          label="type_truck"
                          return-object
                          @search="asyncSearchTruckType"
                          multiple 
                          disabled
                        ></v-select>

                        <div v-if="errors.truck_type">
                          <div
                            v-for="error in errors.truck_type"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>



                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Truck </label>
                      </div>
                      <div class="col-lg-8">
                      
                        <v-select
                          :options="fetchTruck"
                          v-model="truck"
                          label="vehicle_no"
                          return-object
                          @search="asyncSearchTruck"
                          multiple 
                          disabled
                        ></v-select>

                        <div v-if="errors.truck">
                          <div
                            v-for="error in errors.truck"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                       <div class="row card">
                      <div class="card-header">
                        <h5 class="float-start">Permission <span class="wajib">*</span></h5>

                    
                        
                      </div>


                      <div class="card-body">
                        <div class="table-responsive">
                          <table
                            class="table table-hover"
                            style="height: 300px"
                          >
                            <thead>
                              <tr>
                                <th>#</th>
                                <th width="300px">Permission</th>
                                <th>Create</th>
                                <th>Read</th>
                                <th>Update</th>
                                <th>Delete</th>
                                <th>Upload</th>
                                <th>custom1</th>
                                <th>custom2</th>
                                <th>custom3</th>
                                <th>custom4</th>
                                <th>custom5</th>
                               
                                
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(input, index) in this.detailAll"
                                :key="`phoneInput-${index}`"
                              >
                                <th>{{ index + 1 }}</th>
                                <td width="200px">
                                    {{ input.permission.controller }}
                                   <!-- <v-select
                                      :options="fetchPermission"
                                      v-model="input.permission"
                                      label="controller"
                                      return-object
                                      @search="asyncSearchPermission"
                                     disabled
                                    ></v-select> -->
                                </td>

                                <td width="200px">
                                  <span v-if="input.method_create==1">Yes</span>
                                  <span v-if="input.method_create==0">No</span>
                                   
                                </td>


                                <td width="200px">
                                  <span v-if="input.method_read==1">Yes</span>
                                  <span v-if="input.method_read==0">No</span>
                                </td>


                                <td width="200px">
                                  <span v-if="input.method_update==1">Yes</span>
                                  <span v-if="input.method_update==0">No</span>
                                  
                                </td>

                                 <td width="200px">
                                  <span v-if="input.method_delete==1">Yes</span>
                                  <span v-if="input.method_delete==0">No</span>
                                  
                                  
                                </td>


                                <td width="200px">
                                  <span v-if="input.method_upload==1">Yes</span>
                                  <span v-if="input.method_upload==0">No</span>
                                 
                                </td>


                                <td width="200px">
                                  <span v-if="input.method_custom1==1">Yes</span>
                                  <span v-if="input.method_custom1==0">No</span>
                                 
                                  
                                </td>

                                <td width="200px">
                                  <span v-if="input.method_custom2==1">Yes</span>
                                  <span v-if="input.method_custom2==0">No</span>
                                  
                                </td>


                                <td width="200px">
                                  <span v-if="input.method_custom3==1">Yes</span>
                                  <span v-if="input.method_custom3==0">No</span>
                                </td>

                                <td width="200px">
                                  <span v-if="input.method_custom4==1">Yes</span>
                                  <span v-if="input.method_custom4==0">No</span>
                                </td>


                                <td width="200px">
                                  <span v-if="input.method_custom5==1">Yes</span>
                                  <span v-if="input.method_custom5==0">No</span>
                                </td>

                                
                               
                              </tr>
                            </tbody>

                            <tfoot></tfoot>
                          </table>
                        </div>
                      </div>
                    </div>

                   
                   <div class="card-footer">
                      <button
                        class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        "
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>

                      <a
                        v-if="permision_role.method_delete"
                        class="
                          btn btn-danger
                          text-white
                          fw-bolder
                          btn-icon-text
                          float-end
                        "
                        @click="deleteData()"
                      >
                        <i class="link-icon" data-feather="trash"></i>
                        {{ $t("deleteMess") }}
                      </a>

                      <a
                        v-if="permision_role.method_update"
                        class="
                          btn btn-primary
                          me-2
                          text-white
                          fw-bolder
                          btn-icon-text
                          float-end
                        "
                        @click="editData()"
                      >
                        <i class="link-icon" data-feather="edit"></i>
                        {{ $t("editMess") }}
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";

export default {
  name: "RoleDetail",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      fetchBranch: [],
      fetchTruckType: [],
      fetchTruck: [],
      branch:[],
      truck_type:[],
      truck:[],
      permision_role:[],
      fetchPermission:[],
      fetchOptionSelect:[
        {
          id:1,
          name:'Yes'
        },
        {
          id:0,
          name:'No'
        },
       
      ],
      permission:[],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      forms: [],
      detailAll: [],
    };
  },
  watch: {},
  methods: {
    resetForm() {

    },


    cek_message(status,msg){
      if(status=='200'){
        this.success(msg);
      }
    },


    editData(){
      var params = this.$route.params.id
      window.location.href = "/role/edit/"+ params;
    },


    deleteData() {
      var id = this.$onBehind(this.$route.params.id);
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI =
            this.$settings.endPoint + "role/delete/" + id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                window.location.href = "/role";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    backForm() {
      window.location.href = "/role";
    },

    loadData(id) {
      const baseURI = this.$settings.endPoint + "role/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        this.loadTruckType();
        this.loadTruck();
        this.loadBranch();
        this.loadPermission();
        this.forms = response.data.datas;


          this.forms.permission_role.forEach((item) => {
          
            const data = {
                permission: item.permission,
                method_create:item.method_create,
                method_read:item.method_read,
                method_update:item.method_update,
                method_delete:item.method_delete,
                method_upload:item.method_upload,
                method_custom1:item.method_custom1,
                method_custom2:item.method_custom2,
                method_custom3:item.method_custom3,
                method_custom4:item.method_custom4,
                method_custom5:item.method_custom5,
            };

            this.detailAll.push(data);
          });

          this.forms.role_branch_permission.forEach((item) => {
            const data = {
              branch_id: item.branch.branch_id,
              branch_name: item.branch.branch_name,
            
            };
            this.branch.push(data);
          });


          this.forms.role_truck_permission.forEach((item) => {
            const data = {
              master_truck_id: item.master_truck.master_truck_id,
              vehicle_no: item.master_truck.vehicle_no,
            
            };
            this.truck.push(data);
          });


          this.forms.role_type_truck_permission.forEach((item) => {
            const data = {
              type_truck_id: item.type_truck.type_truck_id,
              type_truck: item.type_truck.type_truck,
            
            };
            this.truck_type.push(data);
          });

        // this.form={ name: 'asep', usernmae:res.username };
      });
    },

    cek_method(method){
      if(method=='1'){
        return 'Yes';
      }else{
        return 'No';
      }
    },


    fetchIt() {
      const userDatas     = this.$getUserInfo();
      this.detailUser     = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function(permision){
        if(permision.permission.controller == 'RoleController'){
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if(!rolePermision.method_read){
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;

    },





    loadBranch() {
      const baseURI = this.$settings.endPointCt + `branch`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchBranch = response.data.datas.data;
      });
    },


    asyncSearchBranch  (ev) {
      const baseURI = this.$settings.endPointCt + `branch?branch_name=` + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchBranch = response.data.datas.data;
      });
    },


    loadTruckType() {
      const baseURI = this.$settings.endPointCt + `type-truck`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchTruckType= response.data.datas.data;
      });
    },


    asyncSearchTruckType  (ev) {
      const baseURI = this.$settings.endPointCt + `type-truck?type_truck=` + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchTruckType = response.data.datas.data;
      });
    },


    loadTruck() {
      const baseURI = this.$settings.endPointCt + `master-truck`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchTruck= response.data.datas.data;
      });
    },


    asyncSearchTruck  (ev) {
      const baseURI = this.$settings.endPointCt + `master-truck?vehicle_no=` + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchTruck = response.data.datas.data;
      });
    },




    loadPermission() {
      const baseURI = this.$settings.endPoint + `permission`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchPermission= response.data.datas.data;
      });
    },


    asyncSearchPermission  (ev) {
      const baseURI = this.$settings.endPointCt + `permission?controller=` + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchPermission = response.data.datas.data;
      });
    },
    


    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.cek_message(this.$route.query.status,this.$route.query.msg);
    this.loadData(params[0]);
    this.fetchIt();

    
  },
};
</script>
<style scoped>
</style>