<template>
    

    <div >
    <div class="loader" v-if="this.isLoadingTripAlert"></div>   
    <div class="loadertext" v-if="this.isLoadingTripAlert">{{ $t("loading") }}...</div>  
    <div class="main-wrapper" :class="{'blur-content': this.isLoadingTripAlert}">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TripAlert" ></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
         

            <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
              <div>
                <h4 class="mb-3 mb-md-0">{{ $t("GateAlertTxt") }}</h4>
              </div>
              <div class="d-flex align-items-center flex-wrap text-nowrap">
               
                <button type="button" v-if="isMaps" class="btn btn-primary wd-200 me-2 mb-2 mb-md-0 col-xs-12"   @click="btnMaps(false)">
                  Maps: {{ $t("OffTxt") }}
                </button>
                <button type="button" v-if="!isMaps" class="btn btn-success wd-200 me-2 mb-2 mb-md-0 col-xs-12"   @click="btnMaps(true)">
                  Maps: {{ $t("OnTxt") }}
                </button>

              
                <button type="button" class="btn btn-success btn-icon-text mb-2 mb-md-0 col-xs-12" @click="downloadData">
                  <i class="btn-icon-prepend" data-feather="download-cloud"></i>
                  {{ $t("downloadData") }}
                </button>
              </div>
            </div>

          <div class="col-lg-12 col-xl-12 stretch-card">
              <div class="card">
                <div class="card-body">
                  
                   <div class="row mb-3">
                    
                    <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                      <div class="input-group">
                        <multiselect
                          v-model="billable"
                          :options="fetchBillable"
                          :multiple="true"
                          :close-on-select="true"
                          :clear-on-select="true"
                          :preserve-search="true"
                          placeholder="Pick some Billable"
                          label="name"
                          track-by="name"
                          :preselect-first="true"
                          @select="onSelectBillable"
                          @remove="onUnselectBillable"
                        >
                          <template
                            slot="selection"
                            slot-scope="{ values, isOpen }"
                            ><span
                              class="multiselect__single"
                              v-if="values.length &amp;&amp; !isOpen"
                              >{{ values.length }} selected</span
                            ></template
                          >
                        </multiselect>
                      </div>
                    </div>
                    
                    <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                      <div class="input-group">
                        <multiselect
                          v-model="branch"
                          :options="fetchBranch"
                          :multiple="true"
                          :close-on-select="true"
                          :clear-on-select="true"
                          :preserve-search="true"
                          placeholder="Pick some Branch"
                          label="branch_name"
                          track-by="branch_name"
                          :preselect-first="true"
                          @select="onSelectBranch"
                          @remove="onUnselectBranch"
                        >
                          <template
                            slot="selection"
                            slot-scope="{ values, isOpen }"
                            ><span
                              class="multiselect__single"
                              v-if="values.length &amp;&amp; !isOpen"
                              >{{ values.length }} Branch selected</span
                            ></template
                          >
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                      <div class="input-group">
                        <multiselect
                          v-model="truck"
                          :options="fetchTruck"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          :preserve-search="true"
                          placeholder="Pick some Type Truck"
                          label="type_truck"
                          track-by="type_truck"
                          :preselect-first="true"
                          @select="onSelectTypeTruck"
                          @remove="onUnselectTypeTruck"
                          @search-change="onSearchChange"
                        >
                          <template
                            slot="selection"
                            slot-scope="{ values, isOpen }"
                            ><span
                              class="multiselect__single"
                              v-if="values.length &amp;&amp; !isOpen"
                              >{{ values.length }} Truck selected</span
                            >
                          </template>
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                      <div class="input-group">
                        <multiselect
                          v-model="status"
                          :options="fetchStatus"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          :preserve-search="true"
                          placeholder="Pick some Status"
                          label="status_name"
                          track-by="status_name"
                          :preselect-first="true"
                          @select="onSelectStatus"
                          @remove="onUnselectStatus"
                        >
                          <template
                            slot="selection"
                            slot-scope="{ values, isOpen }"
                            ><span
                              class="multiselect__single"
                              v-if="values.length &amp;&amp; !isOpen"
                              >{{ values.length }} Status selected</span
                            ></template
                          >
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                      <div class="input-group">
                       
                       <!-- <multiselect
                          v-model="vehicle_no"
                          :options="fetchTruckVehicleNo"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          :preserve-search="true"
                          placeholder="Vehicle No"
                          label="vehicle_no"
                          track-by="vehicle_no"
                          :preselect-first="true"
                          @select="onSelectVehicleNo"
                          @remove="onUnselectVehicleNo"
                          @search-change="onSearchChangeTruckVehicleNo"
                        >
                          <template
                            slot="selection"
                            slot-scope="{ values, isOpen }"
                            ><span
                              class="multiselect__single"
                              v-if="values.length &amp;&amp; !isOpen"
                              >{{ values.length }} selected</span
                            >
                          </template>
                        </multiselect> -->

                        <textarea class="form-control" :rows="rowsTextarea" placeholder="Vehicle No" v-model="vehicle_no" @change="selectVehicleNo"></textarea>
                      </div>
                    </div>

                    <div class="col-lg-1 col-md-12 col-sm-12 col-xs-12">
                      <button
                        class="btn btn-primary float-end"
                        @click="resetFilterLocation"
                      >
                        Reset Filter
                      </button>
                    </div>

                  </div>

                  <div class="row" v-if="isMaps">
                    <gmap-map
                      :center="center"
                      :zoom="zoom"
                      style="width: 100%; height: 500px"
                    >
                      <gmap-marker
                        :key="index"
                        v-for="(gmp, index) in locations"
                        :position="gmp"
                        :icon="gmp.icon"
                        :clickable="true"
                        @click="openMarker(gmp)"
                      >
                        <gmap-info-window :opened="true">
                          <span
                            class="badge"
                            :style="{
                              'background-color': gmp.status_color,
                              color: 'white',
                            }"
                            :opened="false"
                          >
                            {{ gmp.label }}
                          </span>
                          <!-- <span :class="gmp.color" style="margin:20px"> {{ gmp.label }} </span> -->
                        </gmap-info-window>
                      </gmap-marker>

                    </gmap-map>
                  </div>

                  <div class="row">
                    <p class="text-muted mb-3"></p>

                    <vue-good-table
                      title="list-dashboard"
                      mode="remote"
                      @on-page-change="onPageChange"
                      @on-sort-change="onSortChange"
                      @on-column-filter="onColumnFilter"
                      @on-per-page-change="onPerPageChange"
                      :totalRows="totalRecords"
                      :line-numbers="true"
                      :isLoadingTripAlert.sync="isLoadingTripAlert"
                      :pagination-options="{
                        enabled: false,
                      }"
                      :rows="rowsTable"
                      :columns="columns"
                    >
                      <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'vehicle_no'">
                          <span
                            @click="searchVehicleNo(props.row)"
                            :style="{
                              'background-color': props.row.status_color,
                              cursor: 'pointer',
                            }"
                            class="badge"
                            >{{ props.formattedRow[props.column.field] }}
                           
                            </span
                          >
                        </span>
                     

                        <span v-else-if="props.column.field == 'driver_name'">
                          {{ props.row.driver_name }}<br>
                          {{ props.row.driver_hp }}
                        </span>

                        <span v-else-if="props.column.field == 'engine_car'">
                          <span
                            style="background-color: red"
                            class="badge"
                            v-if="props.row.engine_car == 'OFF'"
                            >{{ props.row.engine_car }}</span
                          >
                          <span
                            style="background-color: green"
                            class="badge"
                            v-else
                            >{{ props.row.engine_car }}</span
                          >
                        </span>

                         <span v-else-if="props.column.field == 'detail'">
                          
                          <a
                          href="#"
                          
                          title="Detail"
                          @click.prevent="detailData(props.index, props.row)"
                        >
                          <i class="mdi mdi-eye" style="font-size: 16px"></i>
                          </a>
                          
                          
                        </span>


                        <span v-else-if="props.column.field=='gps_vendor'">
                          {{ props.row.gps_vendor.substring(0, 3) }}
                        </span>

                        <span v-else>
                          {{ props.formattedRow[props.column.field] }}
                        </span>
                      </template>
                    </vue-good-table>
                  </div>
                </div>
              </div>
            </div>

        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>

  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import multiselect from "vue-multiselect";
// import DirectionsRenderer from "@/middleware/DirectionsRenderer";
import moment from "moment";

export default {
  name: "TripAlert",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    multiselect: multiselect,
    
    
    // DirectionsRenderer,
  },
  data() {
    const mapMarkerIcon = require("../../assets/icontruck.png");
    return {
      rowsTextarea:1,
      detailTripAlert:'',
      zoom: 5,
      isLoadingTripAlert: false,
      totalRecords: 0,
      openedMarkerID: true,
      markerOption: {
        url: mapMarkerIcon,
        size: { width: 30, height: 30, f: "20px", b: "20px" },
        scaledSize: { width: 30, height: 30, f: "20px", b: "20px" },
        rotation: 45,
      },

      branch: [],
      vehicle_no: [],
      vehicle_no_Filter: [],
      fetchTruckVehicleNo: [],
      currentDate: moment(String(new Date())).format("YYYY-MM-DD"),
      truck: [],
      status: [],
      billable: [],
      fetchBranch: [],
      fetchTruck: [],
      fetchStatus: [],
      fetchBillable: [],
      options: [],
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isMaps: false,
      errors: [],
      dialog: false,
      fieldTrunOff: "",
      featchTruck: [],
      featchStatus: [],
      featchBranch: [],
      langs: ["id", "en"],
      name: "",
      startLocation: null,
      endLocation: null,
      center: {
        lat: -2.548926,
        lng: 118.0148634,
      },
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      forms: [{
        vehicle_no:'',
        alert_time:'',
        alert_type: '',
        alert_value: '',
        alert_subtype: '',
        alert_off_reason: '',
      }],
      locationMarkers: [],
      locations: [],
      locPlaces: [],
      realMonitoring: [],
      branchFilter: [],
      type_truckFilter: [],
      billableFilter: [],
      statusFilter: [],
      searchVhicle: "",
      rowsTable: [],
      featchLoadType:[],
      load_id:"",
      equipment:"",
      load_group:"",
      driver_name:"",
      deposit:0,
      driver_hp:"",
      date_time:"",
      address:"",
      orders:"",
      htmlTable:"",
      columns: [
        {
          label: "Last Updated",
          field: "transaction_date",
        },

        {
          label: "Status",
          field: "truck_status",
        },

        {
          label: "Vehicle No",
          field: "vehicle_no",
        },


         {
          label: "OWN",
          field: "ownership",
        },

        {
          label: "Branch",
          field: "branch_name",
        },
        
        {
          label: "Pool",
          field: "pool_name",
        },

        {
          label: "Carrier",
          field: "carrier",
        },

        {
          label: "Type",
          field: "type_truck",
        },

         {
          label: "Driver Name",
          field: "driver_name",
        },      

        {
          label: "GPS",
          field: "gps_vendor",
        },

        {
          label: "Last Location",
          field: "location",
        },

        {
          label: "City",
          field: "city",
        },

        {
          label: "Speed",
          field: "speed",
        },       

        {
          label: "ENG",
          field: "engine_car",
        },

        {
          label: "KM",
          field: "km_gps",
        },

        {
          label: "Date Time",
          field: "transaction_date",
        },

        {
          label: "IN/OUT",
          field: "status",
        },

        {
          label: "Document",
          field: "document",
        },

        {
          label: "Load Permit",
          field: "permit_status",
        },

        {
          label: "Needs",
          field: "keperluan",
        },

        {
          label: "Reason",
          field: "reason",
        },

        /*status: res.status,
          document : res.document,
          permit_status : res.permit_status, 
          keperluan : res.keperluan, 
          reason : res.reason, 
          muatan : res.muatan, */


        
        /*
        {
          label: "",
          field: "detail",
        },
        */
      ],
      rows: [],
      permissionTabsBasic : '',
      permissionTabsShipment : '',
      permissionTabsDriver : '',
      permision_role: '',
    };
  },
  watch: {},
  methods: {
    cek_permission_tabs() {
      const userDatas     = this.$getUserInfo();
      this.detailUser     = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function(permision){
        if(permision.permission.controller == 'BlujayOrderController'){
          rolePermision = permision;
          // console.log(permision);
        }
      });

      this.permision_role = rolePermision;

      if(!this.permision_role.method_custom1){
        this.permissionTabsBasic=" hidden";
      }

      if(!this.permision_role.method_custom2){
        this.permissionTabsShipment=" hidden";
      }


      if(!this.permision_role.method_custom3){
        this.permissionTabsDriver=" hidden";
      }

    },

    btnMaps(stype) {
      this.isMaps = stype;
      this.loadLocationTable();
      // setInterval(() => {
      //   this.loadLocationTable();
      // }, 500000);

    },

    fade(sType) {
      this.isLoadingTripAlert = sType;
    },

    openMarker(detail) {
      let sts_engine = "";
      if (detail.engine_car == "ON") {
        sts_engine = '<a style="color:green;">' + detail.engine_car + "<a>";
      } else {
        sts_engine = '<a style="color:red;">' + detail.engine_car + "<a>";
      }
      this.$swal({
        position: "top-end",
        title: false,
        icon: false,
        showCloseButton: true,
        showConfirmButton: false,
        confirmButtonColor: "#ab0635",
        cancelButtonColor: "#d33",
        header: '<a href="">Why do I have this issue?</a>',
        html:
          '<ul class="nav nav-tabs" id="myTab" role="tablist">' +
          '<li class="nav-item" '+this.permissionTabsBasic+'>' +
          '<a class="nav-link active" id="home-tab" data-bs-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Basic</a>' +
          "</li>" +
          '<li class="nav-item" '+this.permissionTabsShipment+'>' +
          '<a class="nav-link" id="profile-tab" data-bs-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" >Shipment</a>' +
          "</li>" +
          '<li class="nav-item">' +
          '<span style="margin: 20px; color:">Eng:' +
          sts_engine +
          '<span > AC: <a style="color:red;">OFF</a> </span>' +
          "</li>" +
          "</ul>" +
          '<div class="tab-content border border-top-0 p-3" id="myTabContent">' +
          '<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">' +
          '<img src="'+detail.images+'" width="150px" height="150px"  />'  +
          "<table>" +
          "<tr>" +
          "<td style='text-align:left' width='120px'>Last Update</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.transaction_date +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Vehicle No</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.vehicle_no +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Equipment</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.equipment +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
            "<td style='text-align:left'>Driver Name</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.driver_name +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Driver Hp</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.driver_hp +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Date Time</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.time_server +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Address</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.location +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Speed</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left;background:"+ detail.background_color +";color:"+ detail.color +"'><b>" +
          detail.speed +
          " Km/Jam </b></td>" +
          "</tr>" +
          
          "<tr>" +
          "<td colspan='3' style='text-align:left'><b>GATE ALERT :</b></td>" +
          "</tr>" +

          "<tr>" +
          "<td colspan='3' style='text-align:left;border-top: 2px solid black'></td>" +
          "</tr>" +

          
          "<tr>" +
          "<td style='text-align:left'>Datetime</td>" +
          "<td style='text-align:left'>:</td>" +
         "<td style='text-align:left;'><b>" +
          detail.time_server +
          "</b></td>" +
          "</tr>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left' width='120px'>In/Out</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" 
          + detail.status +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left' width='120px'>Document</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" 
          + detail.document +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left' width='120px'>Load</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" 
          + detail.muatan +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left' width='120px'>In/Out Permit</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" 
          + detail.permit_status +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left' width='120px'>Reason If No</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" 
          + detail.reason +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left' width='120px'>Needs</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" 
          + detail.keperluan +
          "</b></td>" +
          "</tr>" +
          "</table>" +
          "</div>" +
          '<div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">' +
          detail.htmlTable +
          "</div>" +
          '<div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">' +
          "<table>" +
          "<tr>" +
          "<td style='text-align:left' width='120px'>Deposit</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          this.formatNumber(detail.deposit) +
          "</b></td>" +
          "</tr>" +
          "</table>" +
          "</div>" +
          "</div>"
      })
    },



    formatNumber(value){
      return parseInt(value).toLocaleString()
    },

    initMarker(loc) {
      this.existingPlace = loc;
    },


    //filter dashboard
    onUnselectBranch(ev) {
      this.branchFilter.splice(this.branchFilter.indexOf(ev.branch_id),1);
      this.loadLocationTable();
    },

    onSelectBranch(ev) {
      this.branchFilter.push(ev.branch_id);
 
      this.loadLocationTable();
    },

    onSelectTypeTruck(ev) {
      this.type_truckFilter.push(ev.type_truck_id);
      this.loadLocationTable();
    },

    onUnselectTypeTruck(ev) {
      this.type_truckFilter.splice(this.type_truckFilter.indexOf(ev.type_truck_id),1);
      this.loadLocationTable();
    },

    onSelectVehicleNo(ev) {
      this.vehicle_no_Filter.push(ev.vehicle_no);
     this.loadLocationTable();
    },

    onUnselectVehicleNo(ev) {
      this.vehicle_no_Filter.splice(this.vehicle_no_Filter.indexOf(ev.vehicle_no),1);
      this.loadLocationTable();
    },

    onSelectBillable(ev) {
      this.billableFilter.push(ev.name);
      this.loadLocationTable();
    },

    onUnselectBillable(ev) {
      this.billableFilter.splice(this.billableFilter.indexOf(ev.id),1);
      this.loadLocationTable();
    },
    
    onSelectStatus(ev) {
      this.statusFilter.push(ev.status_name);
      this.loadLocationTable();
    },
    
    onUnselectStatus(ev) {
      this.statusFilter.splice(this.statusFilter.indexOf(ev.status_name),1);
      this.loadLocationTable();
    },


    setLocationLatLng: function () {
      navigator.geolocation.getCurrentPosition((geolocation) => {
        this.center = {
          lat: geolocation.coords.latitude,
          lng: geolocation.coords.longitude,
        };
      });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },


    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    detailData(index, row) {
      //console.log(row);
      var params = this.$onRandom(row.id);
      //console.log(params);
      window.location.href = "/monitoring/detail/" + params + "/" + row.vehicle_no+ "/" + row.gps_vendor;
    },

    downloadData() {
      let branch = this.branchFilter.toString();

      let type_truck=this.type_truckFilter.toString();
      let status =this.statusFilter.toString();
      let vehicle_no=this.vehicle_no_Filter.toString();
      let billable=this.billableFilter.toString();

      const baseURI = this.$settings.endPointCt + `real-monitoring/gate-transaction-alert?branch=${branch}&status=${status}&type_truck=${type_truck}&vehicle_no=${vehicle_no}&billable=${billable}&download=download`;
    
      var file_name = `gate_alert_.xlsx`;

      //var columnFilters = this.serverParams.columnFilters;
      return this.$http
        .get(
          baseURI,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
         
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },

    selectVehicleNo() {
      this.rowsTextarea=1;
     // console.log(this.vehicle_no.length);
      if(this.vehicle_no.length > 120){
        this.rowsTextarea=10;
      }
      this.vehicle_no_Filter=[];
      var pisah=this.vehicle_no.split(', ');
     // console.log(pisah);
      this.vehicle_no_Filter.push(pisah);
      this.loadLocationTable();
    },
    
    //awal Filter vehicle no
    loadTruckVehicleNo() {
      const baseURI = this.$settings.endPointCt + `master-truck/select`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTruckVehicleNo = response.data.datas.data;
      });
    },

    onSearchChangeTruckVehicleNo(ev) {
      const baseURI = this.$settings.endPointCt + `master-truck/select?vehicle_no=` + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTruckVehicleNo = response.data.datas.data;
      });
      
    },

   
    //akhir filter vehicle no

    changeFilterLocation() {
      let dataSend = {
        branch: this.branchFilter,
        type_truck: this.type_truckFilter,
        status: this.statusFilter,
        vehicle_no: this.vehicle_no_Filter,
      };
      //this.realMonitoring =[];
      this.locations = [];
      this.rowsTable = [];

      this.loadFilter(dataSend);
    },

    loadFilter(data) {
      const baseURI = this.$settings.endPointCt + `real-monitoring/trip-alert`;
      return this.$http.post(baseURI, data).then((response) => {
        this.realMonitoring = response.data.datas.data;
        this.totalRecords = response.data.datas.total;
        this.rows = response.data.datas.data;
        this.loadFetchLocation();
        this.loadFetchLocationTable();
      });
    },

    loadFilterClick(data) {
      const baseURI = this.$settings.endPointCt + `real-monitoring/trip-alert`;
      return this.$http.post(baseURI, data).then((response) => {
        this.realMonitoring = response.data.datas.data;
        this.totalRecords = response.data.datas.total;

        this.loadFetchLocation();
        //this.loadFetchLocationTable();
      });
    },

    searchVehicleNo(ev) {
      this.isMaps=true;
      this.zoom=15;
      this.center= {
        lat: ev.lat,
        lng: ev.lng,
      },
    
        this.realMonitoring=[];
       
        this.realMonitoring.push(ev);
        this.loadFetchLocation_single_select();
    },

    // searchVehicleNo(ev) {
    //   this.isMaps = true;
    //   this.zoom = 15;
    //   (this.center = {
    //     lat: ev.lat,
    //     lng: ev.lng,
    //   }),
    //     (
    //       this.locations = []
    //     );
    //   let dataSend2 = {
    //     vehicle_no: ev.vehicle_no,
    //   };

    //   this.loadFilterClick(dataSend2);
    // },

    searchVehicleNoByText() {
      this.locations = [];
      let dataSend2 = {
        vehicle_no: this.searchVhicle,
      };
      this.loadFilter(dataSend2);
    },

  
    locateGeoLocation(latitude, longitude) {
      const marker = {
        lat: latitude,
        lng: longitude,
      };
      this.locationMarkers.push({ position: marker });
      this.locPlaces.push(this.existingPlace);
      this.center = marker;
      this.existingPlace = null;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoadingTripAlert = false;
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      // const userDatas = this.$getUserInfo();
      // this.name = userDatas.sub.name;
    },

    loadBranch() {
      const baseURI = this.$settings.endPointCt + `branch/select`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchBranch = response.data.datas.data;
      });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadLocationTable();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    loadStatus() {
      const baseURI = this.$settings.endPointCt + `master-status?limit=100`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchStatus = response.data.datas.data;
      });
    },

    loadTruck() {
      const baseURI = this.$settings.endPointCt + `type-truck/select`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTruck = response.data.datas.data;
      });
    },

    onSearchChange(ev) {
     const baseURI = this.$settings.endPointCt + `type-truck/select?type_truck=`+ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTruck = response.data.datas.data;
      });
    },

    resetFilterLocation() {
      this.rowsTextarea=1;
      this.searchVhicle = "";
      this.branch = "";
      this.truck = "";
      this.status = "";
      this.billable="";
      this.branchFilter = [];
      this.billableFilter = [];
      this.type_truckFilter = [];
      this.vehicle_no_Filter=[];
      this.vehicle_no="";
      this.statusFilter = [];
      this.loadLocation();
      this.loadLocationTable();
      this.zoom = 5;
    },

    loadLocation() {
      const baseURI =
        this.$settings.endPointCt + `real-monitoring/gate-transaction-alert?download=all`;
      return this.$http.get(baseURI).then((response) => {
        this.realMonitoring = response.data.datas.data;
        //this.totalRecords=response.data.datas.total;
    
        this.loadFetchLocation();
        // navigator.geolocation.getCurrentPosition((geolocation) => {
        //   this.center = {
        //     lat: geolocation.coords.latitude,
        //     lng: geolocation.coords.longitude,
        //   };
        // });
        // let color="";

        // console.log(this.realMonitoring);
      });
    },

    loadLocationTable() {
      this.isLoadingTripAlert=true;
      this.rows = [];
      this.realMonitoring = [];
      this.locations=[];
      this.rowsTable=[];

      let branch = this.branchFilter.toString();

      let type_truck=this.type_truckFilter.toString();
      let status =this.statusFilter.toString();
      let vehicle_no=this.vehicle_no_Filter.toString();
      let billable=this.billableFilter.toString();


      const baseURI = this.$settings.endPointCt + `real-monitoring/gate-transaction-alert`;
      return this.$http
        .get(
          baseURI +
            `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&branch=${branch}&status=${status}&type_truck=${type_truck}&vehicle_no=${vehicle_no}&billable=${billable}&download=all`
        )
        .then((response) => {
          this.isLoadingTripAlert=false;
          this.rows = response.data.datas;
          this.realMonitoring = response.data.datas;
          this.totalRecords = 0;
          this.loadFetchLocationTable();
          this.loadFetchLocation();
          // navigator.geolocation.getCurrentPosition((geolocation) => {
          //   this.center = {
          //     lat: geolocation.coords.latitude,
          //     lng: geolocation.coords.longitude,
          //   };
          // });
          // let color="";

          // console.log(this.realMonitoring);
        }).catch(error => {
          this.isLoadingTripAlert = false;
          //console.log(this.isLoadingTripAlert_dashboard);
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{
            this.isLoadingTripAlert = false;
            if (error.response) {
              if(error.response.status === 422) {
                      this.errors = error.response.data.errors;
                      this.resultError(error.response.data.errors);
              }else if (error.response.status === 500) {
                  this.$router.push('/server-error');
              }else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
              }else{
                  this.$router.push('/page-not-found');
              }
            }

          }

        });
    },


 

    loadBlujay(blujayorder){
        if (blujayorder!=null) {
          this.load_id = blujayorder.load_id;
          this.equipment = blujayorder.equipment;
          this.load_group = blujayorder.load_group;
          this.driver_name = blujayorder.driver_name;
          this.driver_hp = blujayorder.driver_hp;
          this.date_time = blujayorder.created_at;
          this.address = "";
          this.htmlTable="";
          if(blujayorder.driver!=null){
            this.deposit=blujayorder.driver.amount;
          }
          
          this.orders = blujayorder.order_detail;
          if (this.orders != null) {
            this.htmlTable +="<table>";
            this.orders.forEach((reso) => {
              let pecah=reso.name_rute.split(",");
              this.htmlTable +=
                "<tr>" +
                "<td style='text-align:left' width='120px'>Order Number</td>" +
                "<td style='text-align:left'>:</td>" +
                "<td style='text-align:left'>" +reso.order_number +"</td>" +
                "</tr>" +
                "<tr>" +
                "<td style='text-align:left'>Customer</td>" +
                "<td style='text-align:left'>:</td>" +
                "<td style='text-align:left'>" +reso.customer_name +"</td>" +
                "</tr>" +
                "<tr>" +
                "<td style='text-align:left'>Order Group</td>" +
                "<td style='text-align:left'>:</td>" +
               "<td style='text-align:left'>" +reso.order_group +"</td>" +
                "</tr>" +
                "<tr>" +
                "<td style='text-align:left'>Status</td>" +
                "<td style='text-align:left'>:</td>" +
               "<td style='text-align:left'>" +reso.tms_status +"</td>" +
                "</tr>" +
                "<tr>" +
                "<td style='text-align:left'>Target Suhu</td>" +
                "<td style='text-align:left'>:</td>" +
                "<td style='text-align:left'></td>" +
                "</tr>" +
                "<tr>" +
                "<td style='text-align:left'>Rute</td>" +
                "<td style='text-align:left'>:</td>" +
                "<td style='text-align:left'>"+pecah[0]?.slice(8)+' - '+pecah[1]?.slice(8)+"</td>"+
                "</tr>" +
                "<tr>" +
                "<td colspan='3'>_____________________________</td>" +
                "</tr>" ;
            });
             this.htmlTable +="</table>";
          }
        }
    },


    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },


    loadFetchLocation_single_select() {
      this.locations = [];
      this.realMonitoring.forEach((res) => {
        this.locations.push({
          id: res.real_monitoring_id,
          lat: res.lat,
          lng: res.lng,
          label: res.vehicle_no,
          rotate: res.rotate,
          stnk: res.stnk,
          kir: res.kir,
          delay:res.delay,
          location: res.location,
          transaction_date: res.transaction_date,
          truck_status: res.truck_status,
          vehicle_no: res.vehicle_no,
          type_truck: res.type_truck,
          gps_vendor: res.gps_vendor,
          city: res.city,
          province: res.province,
          sub_alert: res.alert_subtype,
          curvew: res.curvew,
          non_stop: res.non_stop,
          curvew_time:res.curvew_time,
          non_stop_time:res.non_stop_time,
          speed: res.speed,
          cbillable: res.billable,
          engine_car: res.engine_car,
          km_gps: res.km_gps,
          status_color: res.status_color,
          status: res.status,
          document: res.document,
          muatan: res.muatan,
          permit_status: res.permit_status,
          reason: res.reason,
          keperluan: res.keperluan,
          branch_name: res.branch_name,
          carrier: res.carrier,
          time_server:res.time_server,
          background_color:res.background_color,
          color:res.color,
          background_color_delay: res.background_color_delay,
          color_delay:res.color_delay,
          load_id: res.load_id,
          equipment: res.equipment,
          load_group: res.load_group,
          driver_name: res.driver_name,
          driver_hp: res.driver_hp,
          date_time: res.date_time,
          address: res.address,
          orders: res.orders,
          temp: res.temp,
          htmlTable: res.htmlTable,
          deposit:res.deposit,
          images:res.images,
          icon:res.icon,
          ownership:res.ownership,
        });
      });
    },


    loadFetchLocation() {
      //var svgIcon = '<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 551 687" ><title>Proyek Baru</title><path id="Bentuk 1" class="s0" d="m283.1 23l120.9 374.4q2.1 6.5 0.4 11.1-1.7 4.6-6 4.6l-244.8-1.2q-4.3 0-5.9-4.7-1.7-4.6 0.5-11.1l124.6-373.1q2.2-6.5 5.2-6.5 3 0 5.1 6.5z"/><path id="Lapisan 1" fill-rule="evenodd" class="s1" d="m234.4 562.7q-2.9 0-4.9-2.1-2.1-2.2-2.1-5.1 0-3 2.1-5.2 2-2.1 4.9-2.1 2.8 0 4.9 2.1 2 2.2 2 5.2 0 2.9-2 5.1-2.1 2.1-4.9 2.1zm-14-38q0-0.8 0.5-1.3l10.6-11.1q0.5-0.5 1.2-0.5h8.6v14.6h-20.9zm62.7 38q-2.8 0-4.9-2.1-2.1-2.2-2.1-5.1 0-3 2.1-5.2 2.1-2.1 4.9-2.1 2.8 0 4.9 2.1 2.1 2.2 2.1 5.2 0 2.9-2.1 5.1-2.1 2.1-4.9 2.1zm19.9-71.6q-1.1-1.1-2.5-1.1h-55.7q-1.4 0-2.4 1.1-1.1 1.1-1.1 2.5v10.9h-8.7q-1.4 0.1-3.2 0.8-1.7 0.7-2.7 1.8l-10.8 11.3q-0.7 0.7-1.2 1.7-0.5 0.9-0.8 1.7-0.2 0.8-0.3 2.1-0.1 1.3-0.1 2-0.1 0.6 0 2.1 0 1.6 0 2v18.2q-1.4 0-2.5 1.1-1 1-1 2.5 0 0.9 0.2 1.5 0.2 0.7 0.8 1.1 0.5 0.4 0.8 0.6 0.4 0.3 1.3 0.4 0.9 0.1 1.3 0.1 0.3 0 1.3 0 1.1 0 1.3 0h3.4q0 6 4.1 10.2 4.1 4.3 9.9 4.3 5.7 0 9.8-4.3 4.1-4.2 4.1-10.2h20.9q0 6 4.1 10.2 4 4.3 9.8 4.3 5.8 0 9.9-4.3 4-4.2 4-10.2 0.2 0 1.3 0 1 0 1.3 0 0.4 0 1.3-0.1 0.9-0.1 1.3-0.4 0.3-0.2 0.8-0.6 0.6-0.4 0.8-1.1 0.2-0.6 0.2-1.5v-58.2q0-1.4-1-2.5z"/><path id="Bentuk 2" class="s0" d="m373 476.3q16 28.7 16 62.7 0 34-16 62.7-15.9 28.8-43.3 45.5-27.3 16.8-59.7 16.8-32.4 0-59.7-16.8-27.4-16.7-43.3-45.5-16-28.7-16-62.7 0-34 16-62.7 15.9-28.8 43.3-45.5 27.3-16.8 59.7-16.8 32.4 0 59.7 16.8 27.4 16.7 43.3 45.5z"/></svg>';
      this.realMonitoring.forEach((res) => {
        let stnk = "";
        let kir = "";
        let images=require("../../assets/no-image.png");
        let background_color="";
        let color="black";
        let background_color_delay="";
        let color_delay="black";
       
        if(res.speed>=res.speed_limit){
          background_color="#ff0000";
          color="white";
        }

        if(res.delay!="" && res.delay!=null){
          background_color_delay="#ff0000";
          color_delay="white";
        }

        if(res.images!=null){
          images=res.images;
        }
          this.load_id = "";
          this.equipment =  "";
          this.load_group =  "";
          this.driver_name =  "";
          this.driver_hp =  "";
          this.date_time =  "";
          this.address = "";
          this.orders="";
          this.htmlTable="";

          
        this.loadBlujay(res.blujay_order);
        this.locations.push({
          id: res.real_monitoring_id,
          lat: res.latitude,
          lng: res.longitude,
          label: res.vehicle_no,
          rotate: 40,
          stnk: stnk,
          kir: kir,
          delay:res.delay,
          location: res.location,
          transaction_date: res.transaction_date,
          truck_status: res.truck_status,
          vehicle_no: res.vehicle_no,
          type_truck: res.type_truck,
          gps_vendor: res.gps_vendor,
          city: res.city,
          province: res.province,
          sub_alert:res.alert_subtype,
          curvew:res.alert_subtype=='curvew'? 'Yes':'',
          non_stop:res.alert_subtype=='non stop'? 'Yes':'',
          curvew_time:res.alert_subtype=='curvew'? moment(res.time_server).subtract({days:0, hours:4}).format("DD-MM-YYYY HH:mm:ss")+' - '+moment(String(res.time_server)).format("DD-MM-YYYY HH:mm:ss"):'',
          non_stop_time:res.alert_subtype=='DWH'?  moment(res.time_server).subtract({days:0, hours:4}).format("DD-MM-YYYY HH:mm:ss")+' - '+moment(String(res.time_server)).format("DD-MM-YYYY HH:mm:ss"):'',
          speed: res.speed,
          cbillable: res.billable,
          engine_car: res.engine_car,
          km_gps: res.km_gps!=null?res.km_gps.toLocaleString():0,
          status_color: res.status_color,
          branch_name: res.branch_name,
          carrier: res.carrier,
          time_server:res.transaction_date,
          background_color:background_color,
          color:color,
          background_color_delay: background_color_delay,
          color_delay:color_delay,
          load_id: this.load_id,
          equipment: this.equipment,
          load_group: this.load_group,
          driver_name: this.driver_name!=null && this.driver_name!=""? this.driver_name:res.driver_name,
          driver_hp: this.driver_hp!=null && this.driver_hp!=""? this.driver_hp:res.driver_hp,
          date_time: this.date_time,
          address: this.address,
          orders: this.orders,
          temp: "",
          icon: {
            path: 'M 0 0 C 6.625 -7.69 5.825 -2.318 10.132 0.035 L 4.627 -15.371',
            fillColor: res.status_color,
            fillOpacity: 1,
            rotation: res.heading,
            strokeWeight: 1,
            strokeColor: res.status_color,
            scale: 1.5,
          },
          htmlTable: this.htmlTable,
          deposit:this.deposit,
          ownership:res.ownership,
          images:images,
        });
      });

    
    },

    loadFetchLocationTable() {
      this.rowsTable = [];
      this.rows.forEach((res) => {
        let stnk = "";
        let kir = "";
        let images=require("../../assets/no-image.png");
        let background_color="";
        let color="black";
        let background_color_delay="";
        let color_delay="black";

         if(res.speed>=res.speed_limit){
          background_color="#ff0000";
          color="white";
        }

        if(res.delay!="" && res.delay!=null){
          background_color_delay="#ff0000";
          color_delay="white";
        }

        if(res.images!=null){
          images=res.images;
        }


          this.load_id = "";
          this.equipment =  "";
          this.load_group =  "";
          this.driver_name =  "";
          this.driver_hp =  "";
          this.date_time =  "";
          this.address = "";
          this.orders="";
          this.htmlTable="";
  /* 
master_pool_id, 
STATUS`,
document, 
permit_status, 
keperluan, 
reason, 
muatan */

        this.loadBlujay(res.blujay_order);
        this.rowsTable.push({
          id: res.real_monitoring_id,
          lat: res.latitude,
          lng: res.longitude,
          pool_name: res.pool_name,
          label: res.vehicle_no,
          rotate: 20,
          stnk: stnk,
          kir: kir,
          delay:res.delay,
          location: res.location,
          transaction_date: res.transaction_date,
          truck_status: res.truck_status,
          vehicle_no: res.vehicle_no,
          type_truck: res.type_truck,
          gps_vendor: res.gps_vendor,
          city: res.city,
          status: res.status,
          document : res.document,
          permit_status : res.permit_status, 
          keperluan : res.keperluan, 
          reason : res.reason, 
          muatan : res.muatan,
          province: res.province,
          sub_alert: res.alert_subtype,
          curvew:res.alert_subtype=='curvew'? 'Yes':'',
          non_stop:res.alert_subtype=='non stop'? 'Yes':'',
          curvew_time:res.alert_subtype=='curvew'? moment(res.time_server).subtract({days:0, hours:4}).format("DD-MM-YYYY HH:mm:ss")+' - '+moment(String(res.time_server)).format("DD-MM-YYYY HH:mm:ss"):'',
          non_stop_time:res.alert_subtype=='DWH'?  moment(res.time_server).subtract({days:0, hours:4}).format("DD-MM-YYYY HH:mm:ss")+' - '+moment(String(res.time_server)).format("DD-MM-YYYY HH:mm:ss"):'',
          speed: res.speed,
          cbillable: res.billable,
          engine_car: res.engine_car,
          km_gps: res.km_gps!=null?res.km_gps.toLocaleString():0,
          status_color: res.status_color,
          branch_name: res.branch_name,          
          carrier: res.carrier,
          time_server:res.transaction_date,
          background_color:background_color,
          color:color,
          background_color_delay: background_color_delay,
          color_delay:color_delay,
          load_id: this.load_id,
          equipment: this.equipment,
          load_group: this.load_group,
          driver_name: this.driver_name!=null && this.driver_name!=""? this.driver_name:res.driver_name,
          driver_hp: this.driver_hp!=null && this.driver_hp!=""? this.driver_hp:res.driver_hp,
          date_time: this.date_time,
          address: this.address,
          orders: this.orders,
          temp: "",
          images:images,
          ownership:res.ownership,
          htmlTable: this.htmlTable,
          deposit:this.deposit,
          icon: {
            path: 'M 0 0 C 6.625 -7.69 5.825 -2.318 10.132 0.035 L 4.627 -15.371',
            fillColor: res.status_color,
            fillOpacity: 1,
            rotation: res.heading,
            strokeWeight: 1,
            strokeColor: res.status_color,
            scale: 1.5,
          },
        });
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    const start = {
      lat: -6.2066575,
      lng: 106.7759629,
    };

    this.fetchBillable.push({
      
        id:1,
        name:"LOGISTIK",
      },
      {
        id:2,
        name:"RENTAL",
      
    })

    this.startLocation = start;

    const end = {
      lat: -6.1810517,
      lng: 106.7679362,
    };

    this.endLocation = end;
    //this.setLocationLatLng();
    //this.locateGeoLocation(5.54829, 95.323753);
    this.fetchIt();
    //this.loading();
    this.loadBranch();
    this.loadStatus();
    this.loadTruck();
    this.loadTruckVehicleNo();

    this.loadLocationTable();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content{
  filter: blur(5px); 
  pointer-events: none;
}
</style>