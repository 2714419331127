<template>
  <div
    ref="modal"
    class="modal fade"
    :class="{ show: active, 'd-block': active }"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title badge bg-primary">Billable Rate</h5>

          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            v-on:click="$emit('closeModal', null)"
            aria-label="btn-close"
          ></button>
        </div>

        <div class="modal-body">
          <vue-good-table
            title="list-route"
            ref="myTable"
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            :totalRows="totalRecords"
            :line-numbers="true"
            :isLoading.sync="isLoading"
            :pagination-options="{
              enabled: true,
            }"
            :rows="rows"
            :columns="columns"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'actions'">
                <a
                  href="#"
                  title="Insert"
                  @click.prevent="submitParent(props.index, props.row)"
                >
                  <i class="mdi mdi-check" style="font-size: 16px"></i>
                </a>
              </span>
            </template>
          </vue-good-table>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            v-on:click="$emit('closeModal', null)"
          >
            {{ $t("close_txt") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "ModalRouteTransport",
  emits: ["closeModal"],
  props: {
    showModal: Boolean,
    data: {},
  },
  watch: {
    showModal: {
      handler(newVal) {
        this.active = newVal;
        console.log(this.active);
        if (this.active) {
          console.log(this.data);
          // if(this.data.truck_type || this.data.truck_type !== "" || this.data.truck_type !== []){
          //   this.serverParams.columnFilters.sku = this.data.truck_type.type_truck
          //   console.log("ada type truck");
          // }else{
          //   console.log("kaga ada type truck");
          // }
          var type_truck = "";
          if (
            this.data.truck_type &&
            this.data.truck_type.length == undefined &&
            this.data.truck_type?.type_truck !== "" && this.data.truck_type?.type_truck !==''
          ) {
            type_truck = this.data.truck_type.type_truck;
            console.log(this.data);
          }
          this.$set(this.serverParams.columnFilters, "sku", type_truck);
          this.loadItems();
        }
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      active: this.showModal,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [
        {
          label: "Action",
          field: "actions",
          sortable: false,
        },
        {
          label: "Billable Tariff",
          field: "billable_tariff_name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Region Code", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
          {
          label: "Basis",
          field: "basis",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Basis", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Pick Location",
          field: "origin_location",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Description", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Pick Region",
          field: "origin_region",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Country", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Dest Location",
          field: "destination_location",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Province", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Dest Region",
          field: "destination_region",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By City", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Sku",
          field: "sku",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By District", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Effective Date",
          field: "effective_date",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Postal Code", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Expiration Date",
          field: "expiration_date",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Postal Code", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
      ],
      rows: [],
      rowsSelection: [],
      locale: [],
      rules: {
        select: [(v) => !!v || "Item is required"],
      },
    };
  },
  methods: {
    submitParent(index, row) {
      this.$emit("closeModal", row);
    },

    removeOrderHeaderIds() {
      this.transport_order_header_ids = [];
      this.$refs["list-transport-orders"].unselectAllInternal();
      // console.log(this.$refs['list-transport-orders'].selectedRows)
    },

    selectionChanged(ev) {
      this.rowsSelection = [];
      this.transport_order_header_ids = [];
      this.rowsSelection = ev.selectedRows;

      this.rowsSelection.forEach((item) => {
        this.transport_order_header_ids.push(item.transport_order_header_id);
      });
    },

    loadItems() {
      const baseURI =
        this.$settings.endPoint +
        "rm-billable-rate-header/detail-rate/" +
        this.data.company.company_id;
      // const baseURI = this.$settings.endPoint + "rm-billable-rate-header/detail/2";
      //var columnFilters = this.serverParams.columnFilters;
      //   customer_code=${this.data}&
      return this.$http
        .get(
          baseURI +
            `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&billable_tariff_name=${this.serverParams.columnFilters.billable_tariff_name}&origin_location=${this.serverParams.columnFilters.origin_location}&origin_region=${this.serverParams.columnFilters.origin_region}&destination_location=${this.serverParams.columnFilters.destination_location}&destination_region=${this.serverParams.columnFilters.destination_region}&sku=${this.serverParams.columnFilters.sku}&effective_date=${this.serverParams.columnFilters.effective_date}&expiration_date=${this.serverParams.columnFilters.expiration_date}&basis=${this.serverParams.columnFilters.basis}&pick_plan=${this.data.pick_plan}`
        )
        .then((response) => {
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              console.log(this.errors);
              this.resultError(error.response.data.errors);
              this.$emit("closeModal", null);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
        });
    },

    updateParams(newProps) {
      console.log(newProps);
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      if (this.active) {
        this.loadItems();
      }
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },
    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },
  },
  mounted() {
    //this.loadItems();
    // this.rows = this.props.data;
  },
};
</script>

<style scoped>
.blur-content {
  filter: blur(5px);
  pointer-events: none;
}

.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 120%;
  height: 100%;
  /* margin-left: -10%; */
}
</style>
