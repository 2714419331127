import { render, staticRenderFns } from "./modalTruckDetailUtility.vue?vue&type=template&id=4909a581&scoped=true"
import script from "./modalTruckDetailUtility.vue?vue&type=script&lang=js"
export * from "./modalTruckDetailUtility.vue?vue&type=script&lang=js"
import style0 from "./modalTruckDetailUtility.vue?vue&type=style&index=0&id=4909a581&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4909a581",
  null
  
)

export default component.exports