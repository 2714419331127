<template>
  <div>
    <div
      ref="modal"
      class="modal fade"
      :class="{ show: active, 'd-block': active }"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Billable Tariff Upload Details</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              v-on:click="$emit('closeModal')"
              aria-label="btn-close"
            ></button>
          </div>
          <div class="modal-body">
            <!--begin::loading-data-->
            <div v-if="isLoading" class="d-flex align-items-center">
              <div
                class="spinner-border ms-auto"
                role="status"
                aria-hidden="true"
              ></div>
              <strong>{{ $t("loadingTxt") }}...</strong>
            </div>
            <!--end::loading-data-->
            <div class="row mb-3">
              <div class="col-lg-6">
                <div class="row mb-2">
                  <div class="col-sm-5">Customer</div>
                  <div class="col-sm-1">:</div>
                  <div class="col-sm-6">
                    {{ this.company.name }}
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-5">Tariff Name</div>
                  <div class="col-sm-1">:</div>
                  <div class="col-sm-6">
                    {{ this.forms.tariff_name }}
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-5">Sub-Tariff Name</div>
                  <div class="col-sm-1">:</div>
                  <div class="col-sm-6">
                    {{ this.forms.subtariff_name }}
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-5">Description</div>
                  <div class="col-sm-1">:</div>
                  <div class="col-sm-6">
                    {{ this.forms.description }}
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-5">Format</div>
                  <div class="col-sm-1">:</div>
                  <div class="col-sm-6">
                    {{ this.forms.file_format }}
                  </div>
                </div>
                <div class="row mb-2" v-if="type == 'upload'">
                  <div class="col-sm-5">File Name</div>
                  <div class="col-sm-1">:</div>
                  <div class="col-sm-6">
                    <input
                      type="file"
                      ref="file"
                      required
                      class="form-control"
                      @change="handleFileUpload($event)"
                    />
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-5">Effective Range</div>
                  <div class="col-sm-1">:</div>
                  <div class="col-sm-6">
                    {{ this.forms.effective_date }} -
                    {{ this.forms.expiration_date }}
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-5">Notification Email</div>
                  <div class="col-sm-1">:</div>
                  <div class="col-sm-6">
                    {{ this.forms.email }}
                  </div>
                </div>
                <div class="row mb-2" v-if="type == 'open'">
                  <div class="col-sm-5">Upload Status</div>
                  <div class="col-sm-1">:</div>
                  <div class="col-sm-6">
                    {{ this.forms.upload_status }}
                  </div>
                </div>
                <div class="row mb-2" v-if="type == 'open'">
                  <div class="col-sm-5">Current Phase</div>
                  <div class="col-sm-1">:</div>
                  <div class="col-sm-6">
                    {{ this.forms.current_phase }}
                  </div>
                </div>
                <div class="row mb-2" v-if="type == 'open'">
                  <div class="col-sm-5">Current Status</div>
                  <div class="col-sm-1">:</div>
                  <div
                    class="col-sm-6"
                    v-bind:style="
                      this.forms.current_status == 'FAILED' ||
                      this.forms.current_status == 'FAILED (Canceled)'
                        ? 'color:red;'
                        : this.forms.current_status == 'SUCCESS' ||
                          this.forms.current_status == 'SUCCESS (Complete)'
                        ? 'color:green'
                        : ''
                    "
                  >
                    {{ this.forms.current_status }}
                  </div>
                </div>
                <div class="row mb-2" v-if="type == 'upload'">
                  <div class="col-sm-6">Activate Rates</div>
                  <!-- <div class="col-sm-1"></div> -->
                  <div class="col-sm-6">
                    <input
                      type="checkbox"
                      v-model="forms.activate_rate"
                      :disabled="true"
                      true-value="1"
                      false-value="0"
                    />
                    <!-- {{ this.forms.current_status }} -->
                  </div>
                </div>
              </div>
              <div class="col-lg-6" v-if="type == 'open'">
                <div class="row mb-3" v-if="forms.current_status == 'SUCCESS'">
                  <h5>Instructions</h5>
                  <p>
                    This tariff requires your approval in order to be imported.
                    Additional rates may be uploaded and included with this
                    tariff. This tariff may be canceled.
                  </p>
                </div>
                <div class="row mb-3" v-if="forms.upload_status == 'FAILED'">
                  <h5>Instructions</h5>
                  <p>
                    Additional rates may be uploaded and included with this
                    tariff, this tariff may be cancelled.
                  </p>
                </div>
                <div
                  class="row"
                  v-if="
                    forms.current_status !== 'SUCCESS ( Complete )' &&
                    this.forms.upload_status !== 'CANCELLED'
                  "
                >
                  <h5>Action</h5>
                  <div class="row col-md-3 me-3">
                    <button
                      class="btn btn-secondary mb-1"
                      v-if="this.forms.upload_status == 'SUCCESS'"
                      @click="approve"
                    >
                      Approve
                    </button>
                    <button
                      v-if="this.forms.current_status == 'FAILED'"
                      class="btn btn-secondary mb-1"
                      @click="upload"
                    >
                      Upload
                    </button>
                    <button class="btn btn-secondary mb-1" @click="cancel">
                      Cancel
                    </button>
                    <button class="btn btn-secondary mb-1" @click="loadDetail">
                      Refresh
                    </button>
                    <button
                      class="btn btn-secondary mb-1"
                      @click="$emit('closeModal')"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-5" v-if="type == 'open'">
              <h5>Tariff Validation - Structural Validation of the file</h5>
              <vue-good-table
                title="list-user"
                mode="remote"
                :line-numbers="true"
                :isLoading.sync="isLoading"
                :rows="rowTariffValidation"
                :columns="columns"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'errors_log'">
                    <a
                      v-if="
                        props.row.error_log_file &&
                        props.row.error_log_file !== ''
                      "
                      href="#"
                      @click.prevent="checkError(props.index, props.row)"
                      >Download</a
                    >
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </div>
            <!-- <div class="row mb-5" v-if="type == 'open'">
              <h5>File Validation</h5>
              <vue-good-table
                title="list-user"
                mode="remote"
                :line-numbers="true"
                :isLoading.sync="isLoading"
                :rows="rowFileValidation"
                :columns="columns"
              >
              </vue-good-table>
            </div> -->
            <div class="row mb-5" v-if="type == 'open'">
              <h5>Rate Validation</h5>
              <vue-good-table
                title="list-user"
                mode="remote"
                :line-numbers="true"
                :isLoading.sync="isLoading"
                :rows="rowRateValidation"
                :columns="columns"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'errors_log'">
                    {{ props.row.error_log_file }}
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </div>
            <!-- <div class="row mb-5" v-if="type == 'open'">
              <h5>Rate Import</h5>
              <vue-good-table
                title="list-user"
                mode="remote"
                :line-numbers="true"
                :isLoading.sync="isLoading"
                :rows="rowRateImport"
                :columns="columns"
              >
              </vue-good-table>
            </div> -->
          </div>
          <div class="modal-footer">
            <button
              v-if="type == 'upload'"
              v-on:click="submitData"
              type="submit"
              class="btn btn-primary"
              :disabled="isLoading"
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modalTariffFileUpload",
  emits: ["closeModal"],
  components: {},
  props: {
    showModal: Boolean,
    data: null,
  },
  watch: {
    showModal: {
      handler(newVal) {
        this.active = newVal;
        this.forms = {};
        this.file = "";
        this.company = [];
        this.type = "open";
        this.detailAll = [];
        const body = document.querySelector("body");
        if (this.active) {
          this.loadDetail();
        }
        console.log(this.active, this.forms);

        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    const startDate = new Date();
    const endDate = new Date();
    return {
      fetchRateBreakType: [],
      isLoading: false,
      file: "",
      opens: "center",
      type: "open",
      tariffType: "Manual",
      fetchTariffType: ["Manual", "Managed"],

      supportedFormat: "Excel",
      fetchFormat: ["Excel", "Csv"],
      company: [],
      fetchCompany: [],
      active: this.showModal,
      effective_date: {
        startDate,
        endDate,
      },
      errors: [],
      forms: {},
      locale: {
        format: "dd mmm yyyy H:MM:ss",
      },
      columns: [
        {
          label: "File Name",
          field: "filename",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Schedule / Run Time",
          field: "runtime",
        },
        {
          label: "Records",
          field: "records",
        },
        {
          label: "Errors",
          field: "errors",
        },
        {
          label: "Error Log File",
          field: "errors_log",
          width: "100px",
        },
      ],
      rowTariffValidation: [],
      rowRateValidation: [],
      rowFileValidation: [],
      rowRateImport: [],
    };
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    upload() {
      this.type = "upload";
      console.log("test");
    },
    asyncSearchCompany(ev) {
      console.log(ev);
      this.fetchCompany = [];
      const baseURI = this.$settings.endPoint + "company?name=" + ev;
      //this.isLoading = true;

      return this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          this.fetchCompany = response.data.datas.data;
        })
        .catch((error) => {
          //this.isLoading = false;

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },
    loadCompany() {
      const baseURI = this.$settings.endPoint + "company";
      //this.isLoading = true;

      return this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          this.fetchCompany = response.data.datas.data;
          console.log(response);
        })
        .catch((error) => {
          this.isLoading = false;

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },
    checkError(index, row) {
      console.log(index, row);
      this.fade(true);
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      const baseURI =
        this.$settings.endPoint +
        "rm-billable-tariff/log-error/" +
        row.rm_billable_tariff_detail_id;
      return this.$http
        .get(baseURI, {
          responseType: "blob",
        })
        .then((response) => {
          this.loading();
          var file_name = "download_" + CurrentDate + ".txt";
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        })
        .catch((error) => {
          this.loading();
          console.log(error.response);
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              if (error.response.data.errors.length >= 1) {
                this.showModalTransportError = true;
              } else {
                this.resultError(error.response.data.errors);
              }
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
        });
    },
    loadDetail() {
      this.fade(true);
      const baseURI =
        this.$settings.endPoint + "rm-billable-tariff/detail/" + this.data;
      return this.$http.get(baseURI).then((response) => {
        let datas = response.data.datas;
        console.log(datas);
        this.rowTariffValidation = [];
        this.rowRateImport = [];
        this.rowRateValidation = [];
        this.company = datas.customer;
        datas.detail.forEach((arr) => {
          if (
            arr.detail_type == "Tariff Approval" ||
            arr.detail_type == "File Validation" ||
            arr.detail_type == "FILE VALIDATION"
          ) {
            this.rowTariffValidation.push(arr);
          }
          if (
            arr.detail_type == "Rate Validation" ||
            arr.detail_type == "Rate Import"
          ) {
            this.rowRateValidation.push(arr);
          }
        });
        this.forms = datas;
        this.loading();
      });
    },
    submitData() {
      let formData = new FormData();
      formData.append("file_name", this.file);
      const baseURI =
        this.$settings.endPoint + "rm-billable-tariff/upload/" + this.data;
      this.$http
        .post(baseURI, formData)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            this.errors = [];
            this.$emit("closeModal");
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          this.resetForm();
        });
    },
    cancel() {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataCancel"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          const baseURI =
            this.$settings.endPoint +
            "rm-billable-tariff/cancel-upload/" +
            this.data;
          this.$http
            .post(baseURI)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadDetail();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
      console.log(this.data);
    },
    approve() {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataApprove"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          const baseURI =
            this.$settings.endPoint + "rm-billable-tariff/approve/" + this.data;
          this.$http
            .post(baseURI)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadDetail();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
      console.log("test");
    },
    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },
    fade(sType) {
      this.isLoading = sType;
    },
    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },
    // resultError(data) {
    //   var html = "";
    //   var count = Object.keys(data).length;
    //   for (var x = 0; x < count; x++) {
    //     var nameOb = Object.keys(data)[x];
    //     var objectData = data[nameOb];
    //     for (var y = 0; y < objectData.length; y++) {
    //       html += objectData[y] + "<br>";
    //     }
    //   }

    //   this.loadError(html);
    // },
    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },
    success2(msg) {
      this.$swal({
        icon: "info",
        backdrop: true,
        title: "Information",
        html: "<p>* " + msg + "</p>",
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },
  },
  mounted() {
    this.loadCompany();
  },
};
</script>
<style scoped>
.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  /* min-height: 600px; */
  height: 100%;
  margin-left: 0px !important;
}
</style>
