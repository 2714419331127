import TransportOrderCt from "@/components/orders/control-tower/TransportOrderCt.vue";
import TransportOrderCtDetail from "@/components/orders/control-tower/TransportOrderCtDetail.vue";
import TransportOrderCtEdit from "@/components/orders/control-tower/TransportOrderCtEdit.vue";
import PodIndex from "@/components/orders/control-tower/pod/podIndex.vue";
import PodForm from "@/components/orders/control-tower/pod/podForm.vue";

let routesTransportOrderCT = [
    {
      path: '',
      meta: {
        title: "Orders",
      },
      name: "TransportOrderCt",
      component: TransportOrderCt
    },
    
    {
      path: 'detail/:id',
      meta: {
        title: "Order Detail",
      },
      name: "TransportOrderCtDetail",
      component: TransportOrderCtDetail
    },
    
    {
      path: 'edit/:id',
      meta: {
        title: "Order Edit",
      },
      name: "TransportOrderCtEdit",
      component: TransportOrderCtEdit
    },


    {
      path: 'pod',
      meta: {
        title: "POD",
      },
      name: "PodIndex",
      component: PodIndex
    },

    {
      path: 'pod-form-create',
      meta: {
        title: "POD",
      },
      name: "PodForm",
      component: PodForm
    },

    {
      path: 'pod-form-update/:id',
      meta: {
        title: "POD",
      },
      name: "PodFormUpdate",
      component: PodForm
    },


    {
      path: 'pod-form-view/:id',
      meta: {
        title: "POD",
      },
      name: "PodFormView",
      component: PodForm
    },
     
    
]

export default routesTransportOrderCT
  