<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TemplateOrderDetail"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
           <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left:25px">Detail Template Order </h6>
                  <button
                    class="btn btn-light float-end"
                    type="button"
                    data-bs-toggle="collapse" 
                    data-bs-target="#collapseExample" 
                    aria-expanded="false" 
                    aria-controls="collapseExample"
                  >
                    <i class="link-icon" data-feather="info"></i>
                    Audit Info
                  </button>

                  <div class="row mb-3 collapse" id="collapseExample" style="padding-left:20px">
                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0 ">Created By:</label>
                      <p class="text-muted">{{this.forms.create_by}}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created At:</label>
                      <p class="text-muted">{{this.forms.created_at}}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated By:</label>
                      <p class="text-muted">{{this.forms.update_by}}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated At:</label>
                      <p class="text-muted">{{this.forms.updated_at}}</p>
                    </div>

                    
                    
                  </div>

                </div>

                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->
                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "
                    >
                    
                    </div>
                    

                     <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("companyTxt") }}</label
                        >
                      </div>
                      <div class="col-lg-4">
                    
                        <input
                          type="text"
                          class="form-control"
                          id="company"
                          v-model="company"
                          disabled
                        />

                     
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                          $t('descriptionTxt')
                        }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="description"
                          :placeholder="$t('descriptionTxt')"
                          v-model="forms.description"
                          disabled
                        />
                        <div v-if="errors.description">
                          <div
                            v-for="error in errors.description"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Order Group</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="order_group"
                          placeholder="order group"
                          v-model="forms.order_group"
                          disabled
                        />
                        <div v-if="errors.order_group">
                          <div
                            v-for="error in errors.order_group"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Order Type</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="order_type"
                          placeholder="Order Type"
                          v-model="forms.order_type"
                          disabled
                        />
                        <div v-if="errors.order_type">
                          <div
                            v-for="error in errors.order_type"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Direction Category</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="direction_category"
                          placeholder="Direction Category"
                          v-model="forms.direction_category"
                          disabled
                        />
                        <div v-if="errors.direction_category">
                          <div
                            v-for="error in errors.direction_category"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Sales Group</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="sales_group"
                          placeholder="Sales Group"
                          v-model="forms.sales_group"
                          disabled
                        />
                        <div v-if="errors.sales_group">
                          <div
                            v-for="error in errors.sales_group"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Workflow</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="workflow"
                          placeholder="Workflow"
                          v-model="forms.workflow"
                          disabled
                        />
                        <div v-if="errors.workflow">
                          <div
                            v-for="error in errors.workflow"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Billable Method</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="billable_method"
                          placeholder="billable_method"
                          v-model="forms.billable_method"
                          disabled
                        />
                        <div v-if="errors.billable_method">
                          <div
                            v-for="error in errors.billable_method"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Tms Destination</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          readonly
                          class="form-control"
                          id="tms_destination"
                          placeholder="Tms Destination"
                          v-model="forms.tms_destination"
                        />
                        <div v-if="errors.tms_destination">
                          <div
                            v-for="error in errors.tms_destination"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Tonnage</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="number"
                          class="form-control"
                          id="tonnage"
                          readonly
                          placeholder="Tonnage"
                          v-model="forms.tonnage"
                        />
                        <div v-if="errors.tonnage">
                          <div
                            v-for="error in errors.tonnage"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Cubic</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="cubic"
                          readonly
                          placeholder="Cubic"
                          v-model="forms.cubic"
                        />
                        <div v-if="errors.cubic">
                          <div
                            v-for="error in errors.cubic"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row card">
                      <div class="card-header">
                        <h5>Detail <span class="wajib">*</span></h5>
                      </div>

                      <div class="card-body">
                        <div class="table-responsive">
                          <table
                            class="table table-hover"
                            style="height: 300px"
                          >
                            <thead>
                              <tr>
                                <th>#</th>
                                <th width="300px">SKU</th>
                                <th width="200px">SKU Description</th>
                                <th>Qty</th>
                                <th>Package Type</th>
                                <th>Volume</th>
                                <th>Weight</th>
                                <th>Net Weight</th>
                             
                               
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(input, index) in this.detailAll"
                                :key="`phoneInput-${index}`"
                              >
                                <th>{{ index + 1 }}</th>
                                <td width="200px">
                                 {{input.sku_code}}
                                </td>
                                <td  width="200px">{{ input.sku_description }}</td>
                                <td>
                                  {{input.qty}}
                                </td>

                                <td>
                                 {{input.package_type}}

                                </td>


                                
                                <td>
                                  {{input.volume}}
                                </td>
                                <td>
                                  {{input.weight}}
                                </td>
                                <td>
                                  {{input.net_weight}}
                                </td>
                             
                              </tr>
                            </tbody>

                            <tfoot></tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                   
                   <div class="card-footer">
                    <button
                      class="btn btn-warning text-black   fw-bolder btn-icon-text"
                      @click="backForm"
                      type="button"
                     
                    >
                      <i class="link-icon" data-feather="arrow-left"></i>
                      {{ $t("backMess") }}
                    </button>


                    <a
                      v-if="permision_role.method_delete"
                      class="btn btn-danger text-white  fw-bolder btn-icon-text float-end"
                      @click="deleteData()"
                    >
                      <i class="link-icon" data-feather="trash"></i>
                      {{ $t("deleteMess") }}
                    </a>


                    <a
                      v-if="permision_role.method_update"
                      class="btn btn-primary me-2 text-white fw-bolder btn-icon-text float-end"
                      @click="editData()"
                    >
                      <i class="link-icon" data-feather="edit"></i>
                      {{ $t("editMess") }}
                    </a>
                  </div>
                  </form>
                </div>
              </div>
            </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";

export default {
  name: "CommodityDetail",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      detailAll:[],
      company:"",
      permision_role:[],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      forms: [],
    };
  },
  watch: {},
  methods: {
    resetForm() {

    },


    cek_message(status,msg){
      if(status=='200'){
        this.success(msg);
      }
    },


    editData(){
      var params = this.$route.params.id
      window.location.href = "/order/transport-template/edit/"+ params;
    },


    deleteData() {
      var id = this.$onBehind(this.$route.params.id);
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI =
            this.$settings.endPoint + "transport-order-template/delete/" + id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                window.location.href = "/order/transport-template";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },


    backForm() {
      window.location.href = "/order/transport-template";
    },

    loadData(id) {
      const baseURI = this.$settings.endPoint + "transport-order-template/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        
        this.forms = response.data.datas;
        this.company = this.forms.company.name;

        this.detailAll=this.forms.transport_order_detail_template

        // this.form={ name: 'asep', usernmae:res.username };
      });
    },


    fetchIt() {
      const userDatas     = this.$getUserInfo();
      this.detailUser     = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function(permision){
        if(permision.permission.controller == 'TransportOrderTemplateController'){
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if(!rolePermision.method_read){
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;

    },


    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.cek_message(this.$route.query.status,this.$route.query.msg);
    this.loadData(params[0]);
    this.fetchIt();
  },
};
</script>
<style scoped>
</style>