<template>
  <div class="col-lg-12 col-xl-12 stretch-card">
    <div class="card">
      <div class="card-header">
        <h4 style="margin-right: 5px" class="float-start">TRANSPORT ORDER | STATUS: COMPLETED</h4>


        <button v-if="permision_role.method_read" class="btn btn-success float-end btn-xs" style="margin-right: 5px"
          @click.prevent="downloadData()">
          <i class="link-icon" data-feather="download"></i>
          {{ $t("downloadData") }} .xlsx
        </button>
      </div>

      <div class="card-body">

        <div class="table-responsive" style="overflow-y: auto;">
          <div class="col-sm-12">

            <vue-good-table title="list-order-ct" mode="remote" @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :line-numbers="true"
              :isLoading.sync="isLoading" :pagination-options="{
                enabled: true,
              }" :rows="rows" :columns="filteredColumns">
              <div slot="table-actions">
                {{ $t("no_select_data") }}
              </div>
              <div slot="selected-row-actions">
                <button class="btn btn-success btn-xs" style="margin-right:20px;" @click="sendOrder"> <i
                    class="mdi mdi-send-outline"></i> {{ $t("send_order_txt") }}</button>

                <button class="btn btn-danger btn-xs" @click="cancelOrder"><i class="mdi mdi-close"></i> {{
                  $t("reject_order_txt") }} </button>
              </div>



              <template slot="table-row" slot-scope="props">


                <span v-if="props.column.field == 'actions'">
                  <a style="color: #ab0635;text-decoration: none;" v-if="permision_role.method_read" title="Detail"
                    @click.prevent="
                      detailData(props.index, props.row)
                      ">
                    <i class="mdi mdi-eye" style="font-size:16px"></i>

                  </a>


                  <!--a style="color: #ab0635;text-decoration: none;"
                              v-if="permision_role.method_update"
                              title="Edit"
                              @click.prevent="editData(props.index, props.row)"
                            >
                              <i class="mdi mdi-square-edit-outline" style="font-size:16px"></i>
                              
                            </a-->

                </span>

                <span v-else-if="props.column.field == 'order_no'">
                  <a style="color: #ab0635;text-decoration: none; cursor: pointer; " title="Detail Maps" @click.prevent="
                    clickOrder(props.row)
                    ">
                    {{ props.row.order_no }}

                  </a>


                </span>


                <span v-else-if="props.column.field == 'pick_plan'">
                  <div :style="checkPickPlanDate(props.row.pick_plan)">{{ $moment(props.row.pick_plan).format("D MMM yyyy h:mm:ss A")}}</div>

                </span>
                <span v-else-if="props.column.field == 'submit_time'">

                  <div :style="checkSubmitDate(props.row)">{{ $moment(props.row.submit_time).format("D MMM yyyy h:mm:ss A")}}</div>

                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>

          </div>
        </div>



      </div>

    </div>
    <modal-reject-data :showModal="showModalRejectData" :data="this.transport_order_header_ids"
      @closeModal="closeMyModal"></modal-reject-data>
  </div>





</template>

<script>
import ModalTransportOrderCtRejectMass from "@/components/modal/modalTransportOrderCtRejectMass.vue";



export default {
  name: "TransportOrderCtAssigned",
  components: {
    "modal-reject-data": ModalTransportOrderCtRejectMass,
  },
  props: {
    dateRange: { type: [Object, Array] },
    status: String
  },

  data() {
    return {
      showModalRejectData: false,
      maxToasts: 100,
      ORDER_RESPONSE_TIME: 0,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      permision_role: [],
      transport_order_header_ids: [],
      langs: ["id", "en"],
      detailUser: [],
      detailDivisi: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [
        {
          label: "Live Status",
          field: "status_tms",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Live Status", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Customer",
          field: "company.name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Customer", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Vehicle No",
          field: "vehicle_no",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Vehicle No", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Type Truck",
          field: "type_truck",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Type Truck", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: "Driver",
          field: "driver_name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Type Truck", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: "Load Id",
          field: "load_id",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Load Id", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Shipment Number",
          field: "order_no",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Order No", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Planing Pada",
          field: "plan_pada",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Planing", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("pickup_plan_txt"),
          field: "pick_plan",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t("pickup_plan_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Pick Location",
          field: "pick_address1",
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd MMM yyyy h:mm:ss a',//'dd MMM yyyy',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t("pick_plan_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: "Dest Location",
          field: "dest_location",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Destination", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: this.$t("dest_plan_txt"),
          field: "dest_plan",
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd MMM yyyy h:mm:ss a',//'dd MMM yyyy',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t("dest_plan_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Actual Duration",
          field: "actual_duration",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Destination", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: "Tonnage (kg)",
          field: "tonnage",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Tonnage", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },



        // {
        //   label:"Location",
        //   field: "dest_location",
        //   filterOptions: {
        //     enabled: true, // enable filter for this column
        //     placeholder: "Filter By Tonnage", // placeholder for filter input
        //     filterValue: "", // initial populated value for this filter
        //     filterDropdownItems: [], // dropdown (with selected values) instead of text input
        //     trigger: "enter", //only trigger on enter not on keyup
        //   },
        // },


        {
          label: "Assign Time",
          field: "assign_time",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Assign by",
          field: "assign_by",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: this.$t("action_txt"),
          field: "actions",
          sortable: false,
        },
      ],
      rows: [],
      rowsSelection: [],
    };
  },
  watch: {
    dateRange: {
      handler(newVal) {
        console.log(newVal);
        this.loadItems();
      },

    },
  },
  computed: {
    filteredColumns() {
      return this.columns.map(column => {
        if ((column.field === 'actual_duration'
          || column.field === 'assign_time'
          || column.field === 'assign_by'
          || column.field === 'actions'
          // column.field !== 'request'

        ) &&
          !(this.detailUser.company_detail.code === 'BP' || this.detailUser.company_detail.code === 'LINC')) {
          return { ...column, hidden: true }; // Tandai kolom sebagai `hidden`
        }
        return { ...column, hidden: false }; // Kolom lain tidak tersembunyi
      });
    },
  },
  methods: {
    closeMyModal() {
      this.showModalRejectData = false;
      this.loadItems();
    },
    checkSubmitDate(row) {
      const dateNow = this.$moment(new Date()).format("YYYY-MM-DD h:mm:ss");
      const dateData = this.$moment(row.submit_time).format("YYYY-MM-DD h:mm:ss");
      const dateDataPickup = this.$moment(row.pick_plan);
      const diff_in_millis = Math.abs(new Date(dateData) - new Date(dateNow))
      const diff_in_minutes = diff_in_millis / 60000
      const precentaceData = (diff_in_minutes * 100) / 25


      var hoursDataPickup = dateDataPickup.diff(dateNow, 'hours')
      if (hoursDataPickup > 48) {
        return "";
      } else {

        if (diff_in_minutes > this.ORDER_RESPONSE_TIME) {

          return {
            "background-color": "red",
            "color": "white"
          }

        } else if (precentaceData > 25) {
          return {
            "background-color": "yellow",
            "color": "black"
          }
        } else {
          return "";
        }

      }
    },

    checkPickPlanDate(val) {
      const dateNow = this.$moment(new Date()).format("YYYY-MM-DD") + " 23:59:59";
      const dateData = this.$moment(val).format("YYYY-MM-DD") + " 23:59:59";
      if (new Date(dateData) < new Date(dateNow)) {

        return {
          "background-color": "red",
          "color": "white"
        }

      } else if (new Date(dateData) > new Date(dateNow)) {

        return ""

      } else {

        return {
          "background-color": "yellow",
          "color": "black"
        }

      }
    },

    selectionChanged(ev) {
      this.rowsSelection = [];
      this.transport_order_header_ids = [];
      this.rowsSelection = ev.selectedRows;

      this.rowsSelection.forEach((item) => {
        this.transport_order_header_ids.push(item.transport_order_header_id);
        // if(item.order_status=='NEW'){
        //   this.error('Order No:'+item.order_status+' Status Not Submited, because status order not NEW or SUBMITED');
        // }else{

        // }

      });

    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == 'TransportOrderHeaderCtController') {
          rolePermision = permision;
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;

      if (this.$route.query.msg) {
        if (this.$route.query.status == 200) {
          this.success(this.$route.query.msg)
        } else {
          this.error(this.$route.query.msg)
        }

      }

    },


    sendOrder() {

      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
          let data = {
            transport_order_header_ids: this.transport_order_header_ids
          }
          const baseURI =
            this.$settings.endPoint + "control-tower/transport-order-header/send-order-to-blujay";
          this.$http
            .post(baseURI, data, { timeout: 200000 })
            .then((response) => {
              // this.loading();
              if (response.data.status === 200) {

                // response.data.datas.messages.forEach((item) => {
                //   this.success(item);
                // });
                this.success2(response.data.datas.messages);
                this.loadItems();
                this.isloading = false;
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            }).catch(error => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {

                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    if (this.errors == "carrier_deactived") {
                      var datas = this.error.response.data.datas;
                      var textResponse =
                        "<p style='text-align:center'>Order ini tidak di perkenankan untuk dikirim karena carrier ini sudah di BLOCK <br> Informasi Block :</p> ";
                      datas.forEach(function (data) {
                        textResponse += "<br> Status : " + datas[0].status + "<br> waktu : " + data.deactived_date +
                          "<br>Alasan di block : " + data.deactived_reason + " <br><hr>";
                      });
                      textResponse += "<br><p>Mohon hubungi Department FA untuk membuka Block</p>";

                      this.$swal({
                        width: "600px",
                        icon: "warning",
                        title: "Warning !!!!",
                        html: textResponse,
                      })
                    } else {
                      this.resultError(this.error.response.data.errors);
                    }

                  } else if (error.response.status === 500) {
                    this.$router.push('/server-error');
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push('/page-not-found');
                  }
                }

              }

            });
        }
      });




    },


    cancelOrder() {
      this.showModalRejectData = true;
    },

    updateFormatDate(date) {
      let date_format = this.$moment(date).format("dd mmm yyyy");
      return date_format;
    },

    downloadData() {
      this.isLoading = true;
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = "download_" + CurrentDate + ".xlsx";

      const baseURI = this.$settings.endPoint + "control-tower/transport-order-header";
      //var columnFilters = this.serverParams.columnFilters;
      //var date_order = '';
      // if(this.serverParams.columnFilters.order_date!=''){
      //   date_order =this.$moment(this.serverParams.columnFilters.order_date).format("YYYY-MM-DD");
      // }
      //var columnFilters = this.serverParams.columnFilters;
      var pick_plan = '';
      if (this.serverParams.columnFilters.pick_plan != '') {
        pick_plan = this.$moment(this.serverParams.columnFilters.pick_plan).format("YYYY-MM-DD");
      }
      //var columnFilters = this.serverParams.columnFilters;
      var dest_plan = '';
      if (this.serverParams.columnFilters.dest_plan != '') {
        dest_plan = this.$moment(this.serverParams.columnFilters.dest_plan).format("YYYY-MM-DD");
      }
      var assign_time = '';
      if (this.serverParams.columnFilters.assign_time != '') {
        assign_time = this.$moment(this.serverParams.columnFilters.assign_time).format("YYYY-MM-DD");
      }
      var company_name = this.serverParams.columnFilters["company.name"];
      //  var transport_order_template_description = this.serverParams.columnFilters["transport_order_template.description"];
      //  var transport_order_template_tonnage     = this.serverParams.columnFilters["transport_order_template.tonnage"];
      //  var transport_order_template_cubic       = this.serverParams.columnFilters["transport_order_template.cubic"];
      var startDate = this.dateRange.startDate;
      var endDate = this.dateRange.endDate;
      //var status=this.status

      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&company_name=${company_name}&start=${startDate}&end=${endDate}&status_tms=${this.serverParams.columnFilters.status_tms}&vehicle_no=${this.serverParams.columnFilters.vehicle_no}&type_truck=${this.serverParams.columnFilters.type_truck}&driver_name=${this.serverParams.columnFilters.driver_name}&load_id=${this.serverParams.columnFilters.load_id}&order_no=${this.serverParams.columnFilters.order_no}&pick_plan=${pick_plan}&dest_plan=${dest_plan}&tonnage=${this.serverParams.columnFilters.tonnage}&dest_location=${this.serverParams.columnFilters.dest_location}&order_status=${this.status}&assign_time=${assign_time}&assign_by=${this.serverParams.columnFilters.assign_by}&actual_duration=${this.serverParams.columnFilters.actual_duration}&pick_address1=${this.serverParams.columnFilters.pick_address1}&plan_pada=${this.serverParams.columnFilters.plan_pada}&download=download`,
          {
            responseType: "blob",
            timeout: 200000
          }
        )
        .then((response) => {

          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.isLoading = false;
        }).catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    },



    editData(index, row) {
      var params = this.$onRandom(row.transport_order_header_id);
      window.location.href = "/ct-orders/edit/" + params;
    },

    detailData(index, row) {
      var params = this.$onRandom(row.transport_order_header_id);
      window.location.href = "/ct-orders/detail/" + params;
    },

    clickOrder(order) {
      this.$emit('show_order', order);


    },


    // load items is what brings back the rows from server
    loadItems() {
      this.isLoading = true;
      const baseURI = this.$settings.endPoint + "control-tower/transport-order-header";
      //var columnFilters = this.serverParams.columnFilters;
      //var date_order = '';
      // if(this.serverParams.columnFilters.order_date!=''){
      //   date_order =this.$moment(this.serverParams.columnFilters.order_date).format("YYYY-MM-DD");
      // }
      //var columnFilters = this.serverParams.columnFilters;
      var pick_plan = '';
      if (this.serverParams.columnFilters.pick_plan != '') {
        pick_plan = this.$moment(this.serverParams.columnFilters.pick_plan).format("YYYY-MM-DD");
      }
      //var columnFilters = this.serverParams.columnFilters;
      var dest_plan = '';
      if (this.serverParams.columnFilters.dest_plan != '') {
        dest_plan = this.$moment(this.serverParams.columnFilters.dest_plan).format("YYYY-MM-DD");
      }
      var assign_time = '';
      if (this.serverParams.columnFilters.assign_time != '') {
        assign_time = this.$moment(this.serverParams.columnFilters.assign_time).format("YYYY-MM-DD");
      }
      var company_name = this.serverParams.columnFilters["company.name"];
      //  var transport_order_template_description = this.serverParams.columnFilters["transport_order_template.description"];
      //  var transport_order_template_tonnage     = this.serverParams.columnFilters["transport_order_template.tonnage"];
      //  var transport_order_template_cubic       = this.serverParams.columnFilters["transport_order_template.cubic"];
      var startDate = this.$moment(this.dateRange.startDate).format("YYYY-MM-DD");
      var endDate = this.$moment(this.dateRange.endDate).format("YYYY-MM-DD");
      // var status=this.status
      //  console.log(this.status);

      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&company_name=${company_name}&start=${startDate}&end=${endDate}&status_tms=${this.serverParams.columnFilters.status_tms}&vehicle_no=${this.serverParams.columnFilters.vehicle_no}&type_truck=${this.serverParams.columnFilters.type_truck}&driver_name=${this.serverParams.columnFilters.driver_name}&load_id=${this.serverParams.columnFilters.load_id}&order_no=${this.serverParams.columnFilters.order_no}&pick_plan=${pick_plan}&dest_plan=${dest_plan}&tonnage=${this.serverParams.columnFilters.tonnage}&dest_location=${this.serverParams.columnFilters.dest_location}&order_status=${this.status}&assign_time=${assign_time}&assign_by=${this.serverParams.columnFilters.assign_by}&actual_duration=${this.serverParams.columnFilters.actual_duration}&pick_address1=${this.serverParams.columnFilters.pick_address1}&plan_pada=${this.serverParams.columnFilters.plan_pada}`,
          { timeout: 200000 }
        )
        .then((response) => {
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
          //   if(this.status=='NEW'){
          //     this.$emit('passDataNew', this.totalRecords);
          //   }

          //   if(this.status=='ASSIGNED'){
          //     this.$emit('passDataAssigned', this.totalRecords);
          //   }

          // if(this.status=='REJECTED'){
          //   this.$emit('passDataRejected', this.totalRecords);
          // }

          if (this.status == 'COMPLETED') {
            this.$emit('passDataCompleted', this.totalRecords);
          }

          // if(this.status=='RESCHEDULE'){
          //   this.$emit('passDataReschedule', this.totalRecords);
          // }

          // if(this.status=='REROUTE'){
          //   this.$emit('passDataReroute', this.totalRecords);
          // }

          this.isLoading = false;
        }).catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },


    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    success2(msg) {
      let pesan = "";
      msg.forEach((item) => {
        pesan = pesan + "<p>* " + item + "</p>";
      });

      this.$swal({
        icon: 'info',
        backdrop: true,
        title: "Information",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      })


    },


    loadingSubmiting() {


      this.$swal({
        icon: 'warning',
        backdrop: true,
        title: "Please wait",
        html: '<h2>SUBMITTING ORDER...</h2>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

    },


    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },


    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },

    getSystemCode() {
      const baseURI = this.$settings.endPoint + "system-code/select?system_code=ORDER_RESPONSE_TIME";

      return this.$http
        .get(
          baseURI,
          { timeout: 200000 }
        )
        .then((response) => {
          var rows = response.data.datas.data;
          if (rows.length > 0) {
            this.ORDER_RESPONSE_TIME = rows[0].value;
          }

        }).catch(error => {

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    },

  },
  events: {},
  mounted() {
    this.fetchIt();
    this.loading();
    this.getSystemCode();


  },
};
</script>