
import PostalCode from "@/components/master/postal_code/PostalCode.vue";
import PostalCodeAdd from "@/components/master/postal_code/PostalCodeAdd.vue";
import PostalCodeDetail from "@/components/master/postal_code/PostalCodeDetail.vue";
import PostalCodeEdit from "@/components/master/postal_code/PostalCodeEdit.vue";
import PostalCodeUpload from "@/components/master/postal_code/PostalCodeUpload.vue";



let routesPostalCode= [
    
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "PostalCodeIndex",
        component: PostalCode
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "PostalCodeAdd",
        component: PostalCodeAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "PostalCodeEdit",
        component: PostalCodeEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "PostalCodeDetail",
        component: PostalCodeDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "PostalCodeUpload",
        component: PostalCodeUpload
      },

      
  ]
  
  export default routesPostalCode
  