var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('vue-good-table',{attrs:{"title":"list-of-one-time-analysis","mode":"remote","totalRows":_vm.totalRecords,"line-numbers":true,"isLoading":_vm.isLoading,"pagination-options":{
      enabled: true,
    },"sort-options":{
      enabled: false,
    },"filterOptions":{
      enabled: false
    },"rows":_vm.rowsTableOrder,"columns":_vm.columnsOrder},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }