import Rute from "@/components/master/rute/Rute.vue";
import RuteAdd from "@/components/master/rute/RuteAdd.vue";
import RuteDetail from "@/components/master/rute/RuteDetail.vue";
import RuteEdit from "@/components/master/rute/RuteEdit.vue";
import RuteUpload from "@/components/master/rute/RuteUpload.vue";

let routesRute= [
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "Rute",
        component: Rute
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "RuteAdd",
        component: RuteAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "RuteEdit",
        component: RuteEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "RuteDetail",
        component: RuteDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "RuteUpload",
        component: RuteUpload
      },
    
]

export default routesRute
  