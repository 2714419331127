<template>
  <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Eskalasi FPPK</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
            aria-label="btn-close"></button>
        </div>

        <form class="forms-sample" method="POST" @submit.prevent="submitData">
          <div class="modal-body">
           
            <!--end::loading-data-->
            <h5>{{ $t("areYouSure") }}</h5>
            <div class="mb-3">
              <label class="form-label">{{ $t("txtAlasanEskalasiFppk") }}</label>
              <textarea class="form-control" required v-model="forms.alasan_eskalasi" height="200px"></textarea>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
              Cancel
            </button>
            
            <div v-if="isLoading" class="d-flex align-items-center">
              <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
              <strong>Loading...</strong>
            </div>

            <button v-if="!isLoading" class="btn btn-primary" type="submit">Save </button>

          </div>
        </form>
      </div>
    </div>
  </div>
</template>
  
<script>

export default {

  components: {
    // DateRangePicker
  },
  name: "ModalFormCancelFPPK",
  emits: ["closeModal"],

  props: {
    showModal: Boolean,
    data: null,
    fppk_id: null,
  },
  watch: {
    showModal: {
      handler(newVal) {
        console.log(this.data);
        this.forms=this.data;
        this.active = newVal;
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      active: this.showModal,
      forms: [],
      locale: [],
      return_date: [],
      delivery_date: [],
      equipment_name: [],
      fetchEquipmentName: [],
    };
  },
  methods: {
    resetForm() {
      this.forms = [];
    },

    submitData() {
      
      this.isLoading=true;
      const baseURI = this.$settings.endPointFppk + "v1/penerimaan-fppk/create-eskalasi-fppk";
        this.$http
          .post(baseURI, this.forms)
          .then((response) => {
            this.isLoading=false;
            if (response.data.status === 200) {
             
              this.resetForm();
              this.$emit("closeModal");
              this.loadSuccess("Success");
            } else {
             
              this.errors = response.data.errors;
              this.resultError(response.data.errors);
            }


          })
          .catch((error) => {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              
              }
            }
            this.isloading = false;
          });

    },

    resultError(data) {
      var html = "";
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          html += objectData[y] + "<br>";
        }
      }

      this.loadError(html);
    },

    loadError(pesan) {
      this.$swal({
        backdrop: true,
        title: "Warning",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading=false;
        }
      })
    },



    loadSuccess(pesan) {
      this.$swal({
        backdrop: true,
        title: "Success",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading=false;
        }
      })
    },

   
  },
  created: function () {
    //this.loadDriver();

  },
  mounted() {
    //tail(this.data);
  },
};
</script>
  
<style scoped>
.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left: 0px !important;
}
</style>