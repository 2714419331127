<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading">
      {{ $t("loadingTxt") }}...
    </div>
    <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TransportOrder"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->

        <div class="page-content">
          <div class="col-lg-12 col-xl-12 stretch-card">
            <div class="card">



              <div class="card-header">
                <div class="row">
                  <div class="col-lg-3 col-md-6">
                    <h4 class="float-start">New Transport Order</h4>
                  </div>

                  <div class="col-lg-4 col-md-6 mr-0">
                    <div class="input-group">
                      <date-range-picker class="form-control" @change="loadItems" :locale-data="locale"
                        v-model="dateFilter" opens="center" :singleDatePicker="false" :timePicker24Hour="true"
                        :timePicker="true" :showWeekNumbers="false"></date-range-picker>
                      <button class="btn btn-primary" @click.prevent="loadItems()">Go</button>
                    </div>
                  </div>

                  <div class="col-lg-5 col-md-12">
                    <div class="d-flex flex-wrap justify-content-end">
                      <button v-if="permision_role.method_create" class="btn btn-primary btn-xs me-2 mb-2"
                        @click.prevent="createData()">
                        <i class="link-icon" data-feather="plus"></i> {{ $t("createNew") }}
                      </button>
                      <button class="btn btn-warning btn-xs me-2 mb-2" @click.prevent="uploadData()">
                        <i class="link-icon" data-feather="upload"></i> {{ $t("uploadData") }} .xlsx
                      </button>
                      <button v-if="permision_role.method_read" class="btn btn-success btn-xs mb-2"
                        @click.prevent="downloadData()">
                        <i class="link-icon" data-feather="download"></i> {{ $t("downloadData") }} .xlsx
                      </button>
                    </div>
                  </div>
                </div>
              </div>




              <div class="card-body">
                <!--div class="vgt-selection-info-row-active">
                  <div v-if="this.transport_order_header_ids.length > 0" class="vgt-selection-info-row clearfix">
                    {{  this.transport_order_header_ids.length  }} {{  $t('data_selected')  }}
                    <a @click.prevent="removeOrderHeaderIds()" style="color:red;">
                      {{  $t('clear')  }}
                    </a>
                    <div class="vgt-selection-info-row__actions vgt-pull-right">
                      <button class="btn btn-success btn-xs" style="margin-right:20px;" @click="sendOrder"> <i
                          class="mdi mdi-send-outline"></i> {{  this.$t('send_order_txt')  }} </button>

                      <button class="btn btn-danger btn-xs" @click="cancelOrder"><i class="mdi mdi-close"></i>
                        {{  this.$t('cancel_order_txt')  }} </button>
                    </div>
                  </div>

                </div-->
                <!-- , disableSelectInfo: true, -->
                <vue-good-table title="list-transport-orders" ref="list-transport-orders" mode="remote" :select-options="{
      enabled: true,
      selectOnCheckboxOnly: true,
      selectionText: $t('data_selected'),
      clearSelectionText: $t('clear'),
    }" @on-selected-rows-change="selectionChanged" @on-page-change="onPageChange" @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
                  :line-numbers="true" :isLoading.sync="isLoading" :pagination-options="{
      enabled: true,
    }" :rows="rows" :columns="columns">
                  <div slot="table-actions">
                    {{ $t("no_select_data") }}
                  </div>
                  <div slot="selected-row-actions">
                    <button v-if="btnSubmit" class="btn btn-success btn-xs" style="margin-right: 20px"
                      @click="sendOrder">
                      <i class="mdi mdi-send-outline"></i>
                      {{ $t("send_order_txt") }}
                    </button>
                    <span v-if="btnCancelGroup">
                      <button class="btn btn-warning btn-xs" v-if="permision_role.method_custom1" style="margin-right: 20px" @click="cancelOrder('void')">
                        <i class="mdi mdi-close"></i>
                        <!-- {{$t("cancel_order_txt")}}  -->
                        Void Order
                      </button>

                      <button class="btn btn-danger btn-xs" @click="cancelOrder('cancel_from_costumer')">
                        <i class="mdi mdi-close"></i>
                        {{ $t("cancel_order_costumer_txt") }}
                      </button>
                    </span>
                  </div>

                  <!-- <template slot="column-filter" slot-scope="props">
                      <span v-if="props.filterOptions.customFilter">TES</span>
                    </template>
-->

                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'actions'">
                      <a href="#" v-if="permision_role.method_read" title="Detail"
                        @click.prevent="detailData(props.index, props.row)">
                        <i class="mdi mdi-eye" style="font-size: 16px"></i>
                      </a>

                      <a href="#" v-if="permision_role.method_update &&
      props.row.order_status == 'NEW'
      " title="Edit" @click.prevent="editData(props.index, props.row)">
                        <i class="mdi mdi-square-edit-outline" style="font-size: 16px"></i>
                      </a>

                      <a href="#" v-if="permision_role.method_delete &&
      props.row.order_status == 'NEW'
      " title="Delete" @click.prevent="deleteData(props.index, props.row)">
                        <i class="mdi mdi-trash-can-outline" style="font-size: 16px"></i>
                      </a>
                    </span>
                    <span v-else-if="props.column.field == 'order_status'">
                      {{ orderStatus(props.row.order_status) }}
                    </span>
                    <span v-else-if="props.column.field == 'request'">
                      <!-- && props.row.load_id -->
                      <!-- <a href="#" v-if="!props.row.load_id && (props.row.order_status == 'SUBMITTED' || props.row.order_status == 'ASSIGNED')" title="Reschedule" @click.prevent="
                        requestRescheduleder(props.row)
                      "><i class="mdi mdi-calendar-clock" style="font-size:16px"></i>
                      </a>

                      <a href="#" v-if="!props.row.load_id && (props.row.order_status == 'SUBMITTED' || props.row.order_status == 'ASSIGNED')" title="Reroute"
                        @click.prevent="requestReroute(props.row)">
                        <i class="mdi mdi-google-maps" style="font-size:16px"></i>
                      </a> -->

                      <a href="#" v-if="!props.row.load_id &&
      props.row.order_status ==
      'RESCHEDULE - WAITING APPROVAL'
      " title="Approval Request" @click.prevent="showApproval(props.row)">
                        <i class="mdi mdi-alarm-light" style="font-size: 16px"></i>
                      </a>
                    </span>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
        <!-- Cancel data-->

        <modal-cancel-order :showModal="showModalCancelOrder" :data="this.transport_order_header_ids"
          :status="this.status_order" @closeModal="closeMyModal">
        </modal-cancel-order>

        <modal-request-reschedule :showModal="showModalRequestReschedule" :data="passData" @closeModal="closeMyModal">
        </modal-request-reschedule>

        <modal-request-reroute :showModal="showModalRequestReroute" :data="passData" @closeModal="closeMyModal">
        </modal-request-reroute>

        <modal-approval-reschedule :showModal="showModalReschedule" :data="passData"
          @closeModal="closeMyModal"></modal-approval-reschedule>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import moment from "moment";
import ModalTransportOrderCancelOrder from "@/components/modal/modalTransportOrderCancelOrder.vue";
import ModalRequestReroute from "@/components/modal/modalRequestReroute.vue";
import ModalRequestReschedule from "@/components/modal/modalRequestReschedule.vue";
import ModalApprovalReschedule from "@/components/modal/modalApprovalReschedule.vue";
import DateRangePicker from "vue2-daterange-picker";


export default {
  name: "TransportOrder",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "modal-cancel-order": ModalTransportOrderCancelOrder,
    "modal-request-reroute": ModalRequestReroute,
    "modal-request-reschedule": ModalRequestReschedule,
    "modal-approval-reschedule": ModalApprovalReschedule,
    DateRangePicker
  },
  data() {
    const startDate = moment().format('YYYY-MM-DD'); // Today's date
    const endDate = moment().add(7, 'days').format('YYYY-MM-DD');
    return {

      locale: {
        format: "yyyy-mm-dd",
      },
      passData: [],
      showModalCancelOrder: false,
      showModalRequestReschedule: false,
      showModalRequestReroute: false,
      showModalReschedule: false,
      btnRejectOrder: true,
      btnSubmit: true,
      btnCancelGroup: true,
      moment: moment,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      status_order: "",
      errors: [],
      permision_role: [],
      transport_order_header_ids: [],
      transport_order_header_status: [],
      langs: ["id", "en"],
      detailUser: [],
      dateFilter: {
        startDate,
        endDate,
      },
      detailDivisi: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [
        {
          label: "Company",
          field: "company_name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Company", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Order No",
          field: "order_no",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Order No", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "ShipWithRef",
          field: "shipwith_ref",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Order Date",
          field: "order_date",
          thClass: "text-left",
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd MMM yyyy h:mm:ss a", //'dd MMM yyyy',
          filterOptions: {
            customFilter: true,
            enabled: true, // enable filter for this column
            placeholder: "Filter By Order Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Order Status",
          field: "order_status",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Order Status", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [
              "NEW",
              "SUBMITTED",
              "ASSIGNED",
              "CANCELLED",
              "RESCHEDULE - NEED APPROVAL",
              "RESCHEDULE - WAITING APPROVAL",
              "RESCHEDULE - APPROVED",
              "REROUTE - NEED APPROVAL",
              "REROUTE - WAITING APPROVAL",
              "REROUTE - APPROVED",
              "REJECTED",
              "COMPLETED",
              "VOID",
            ], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Pick Plan",
          field: "pick_plan",
          thClass: "text-left",
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd MMM yyyy h:mm:ss a", //'dd MMM yyyy',
          filterOptions: {
            customFilter: true,
            enabled: true, // enable filter for this column
            placeholder: "Filter By Pick Plan", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Pick Name",
          field: "pick_name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Direction", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Dest Plan",
          field: "dest_plan",
          thClass: "text-left",
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd MMM yyyy h:mm:ss a", //'dd MMM yyyy',
          filterOptions: {
            customFilter: true,
            enabled: true, // enable filter for this column
            placeholder: "Filter By Dest Plan", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Dest Name",
          field: "dest_name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Dest Name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Billable Tariff",
          field: "billable_tariff",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Billable Tariff", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Type Truck",
          field: "type_truck",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Type Truck", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Vehicle No",
          field: "vehicle_no",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Vehicle No", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Driver",
          field: this.driverIdName,
          width: "100px",
          sortable: false,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Driver Name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Status TMS",
          field: "status_tms",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Dest Name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Shipment Rate",
          field: "shipment_rate",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Remarks",
          field: "remarks",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Action",
          field: "actions",
          sortable: false,
        },

        {
          label: "Request",
          field: "request",
          sortable: false,
        },
      ],
      rows: [],
      rowsSelection: [],
    };
  },
  watch: {},
  methods: {
    orderStatus(value) {
      if (value === "RESCHEDULE - WAITING APPROVAL") {
        return "RESCHEDULE - NEED APPROVAL";
      } else if (value === "RESCHEDULE - NEED APPROVAL") {
        return "RESCHEDULE - WAITING APPROVAL";
      } else if (value === "REROUTE - NEED APPROVAL") {
        return "REROUTE - WAITING APPROVAL";
      } else {
        return value;
      }
    },

    closeMyModal() {
      this.showModalCancelOrder = false;
      this.showModalRequestReschedule = false;
      this.showModalRequestReroute = false;
      this.showModalReschedule = false;
      this.loadItems();
    },

    requestRescheduleder(row) {
      this.passData = row;
      this.passData.source = "CHAIN";
      this.showModalRequestReschedule = true;
    },

    requestReroute(row) {
      this.passData = row;
      this.showModalRequestReroute = true;
    },

    showApproval(row) {
      this.showModalReschedule = true;
      this.passData = row;
      this.passData.source = "CHAIN";
    },

    cancelOrder(status) {
      this.status_order = status;
      this.showModalCancelOrder = true;
    },

    removeOrderHeaderIds() {
      this.transport_order_header_ids = [];
      this.transport_order_header_status = [];
      this.$refs["list-transport-orders"].unselectAllInternal();
      // console.log(this.$refs['list-transport-orders'].selectedRows)
    },
    driverIdName(data) {
      if (data.driver_name == null && data.driver_hp == null) {
        return "";
      } else {
        var hp = data.driver_hp;
        if (hp == null) {
          hp = "";
        }
        return data.driver_name + " - " + hp;
      }
    },
    selectionChanged(ev) {
      this.rowsSelection = [];
      this.transport_order_header_ids = [];
      this.transport_order_header_status = [];
      this.rowsSelection = ev.selectedRows;
      this.btnRejectOrder = true;
      this.btnCancelGroup = true;
      this.btnSubmit = true;
      const cancelGroupCondition = ["ACCEPTED", "NEW", "PLANNED", "SUBMITTED"];
      this.rowsSelection.forEach((item) => {
        this.transport_order_header_ids.push(item.transport_order_header_id);
        this.transport_order_header_status.push(item.order_status);
        // if(item.order_status == 'VOID' || item.order_status == 'ASSIGNED' || item.order_status == 'CANCELLED'){
        if (item.order_status !== "NEW") {
          this.btnSubmit = false;
          this.btnCancelGroup = false;
        }
        if (cancelGroupCondition.includes(item.order_status)) {
          this.btnCancelGroup = true;
        }
        if (item.load_id != null) {
          this.btnRejectOrder = false;
        }
        console.log(this.btnRejectOrder);

        // if(item.order_status=='NEW'){
        //   this.error('Order No:'+item.order_status+' Status Not Submited, because status order not NEW or SUBMITED');
        // }else{

        // }
      });
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (
          permision.permission.controller == "TransportOrderHeaderNewController"
        ) {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },

    sendOrder() {
      let ask = this.transport_order_header_status.includes("REJECTED")
        ? this.$t("rejectResubmit")
        : this.$t("yourDataSend");
      this.$swal({
        title: this.$t("areYouSure"),
        text: ask,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          //this.loadingSubmiting();
          this.isLoading = true;
          let data = {
            transport_order_header_ids: this.transport_order_header_ids,
          };
          const baseURI =
            this.$settings.endPoint + "transport-order-header-new/send-order";
          this.$http
            .post(baseURI, data)
            .then((response) => {
              //this.loading();
              if (response.data.status === 200) {
                this.isLoading = false;
                // response.data.datas.messages.forEach((item) => {
                //   this.success(item);
                // });
                this.success2(response.data.datas.messages);
                this.loadItems();
              } else {
                this.isLoading = false;
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  if (
                    this.errors.message ==
                    "You cannot submit this order because limit of lumpsum, please take another billabe tariff"
                  ) {
                    this.$swal({
                      icon: "error",
                      backdrop: true,
                      title: "PERHATIAN ORDER TIDAK BISA DI SUBMIT !!",
                      html: "Qty Lumpsump (basis ECH) Untuk shipment ini sudah tercapai<br> Mohon untuk memilih rute dengan basis FLT untuk meneruskan atau buat order baru dengan basis FLT",
                      showConfirmButton: true,
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                    });
                  } else if (error.response.data.errors == "company_deactived") {
                    var datas = error.response.data.datas;
                    var textResponse =
                      "<p style='text-align:center'>Customer ini di block dan tidak di perkenankan untuk di jalankan order / shipmentnya <br> Detail Block :</p> ";
                    datas.forEach(function (data) {
                      textResponse += "<br> Customer : " + datas[0].name + "<br> Blocking pada Tanggal : " + data.deactived_date +
                        "<br>Alasan di block : " + data.deactived_reason + " <br><hr>";
                    });
                    textResponse += "<br><p>Mohon hubungi Department FA untuk membuka Block</p>";

                    this.$swal({
                      width: "600px",
                      icon: "warning",
                      title: "Warning !!!!",
                      html: textResponse,
                    })
                  } else {
                    this.resultError(error.response.data.errors);
                  }

                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    cancelOrderOld() {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataCancel"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          this.loadingSubmiting();
          let data = {
            transport_order_header_ids: this.transport_order_header_ids,
          };
          const baseURI =
            this.$settings.endPoint +
            "transport-order-header/send-order-cancel";
          this.$http
            .post(baseURI, data)
            .then((response) => {
              //this.loading();
              if (response.data.status === 200) {
                // response.data.datas.messages.forEach((item) => {
                //   this.success(item);
                // });
                this.success2(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    cancelSubmit() {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataCancel"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          this.loadingSubmiting();
          let data = {
            transport_order_header_ids: this.transport_order_header_ids,
          };
          const baseURI =
            this.$settings.endPoint + "transport-order-header/cancel-submit";
          this.$http
            .post(baseURI, data)
            .then((response) => {
              //this.loading();
              if (response.data.status === 200) {
                // response.data.datas.messages.forEach((item) => {
                //   this.success(item);
                // });
                this.success2(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    updateFormatDate(date) {
      let date_format = this.$moment(date).format("dd mmm yyyy");
      return date_format;
    },

    downloadData() {
      const baseURI = this.$settings.endPoint + "/transport-order-header-new";
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var date_order = "";
      if (this.serverParams.columnFilters.order_date != "") {
        date_order = this.$moment(
          this.serverParams.columnFilters.order_date
        ).format("YYYY-MM-DD");
      }
      // var date_order = this.$moment(this.serverParams.columnFilters.order_date).format("YYYY-MM-DD");
      var file_name = "download_" + CurrentDate + ".xlsx";

      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&company_name=${this.serverParams.columnFilters.company_name}&shipwith_ref=${this.serverParams.columnFilters.shipwith_ref}&order_status=${this.serverParams.columnFilters.order_status}&order_no=${this.serverParams.columnFilters.order_no}&status_tms=${this.serverParams.columnFilters.status_tms}&dest_name=${this.serverParams.columnFilters.dest_name}&status_tms=${this.serverParams.columnFilters.status_tms}&order_date=${date_order}&download=download`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },

    // downloadData() {
    //   this.fade(true);
    //   var baseURI = this.$settings.endPoint + "/user/download";
    //   var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
    //   var columnFilters = this.serverParams.columnFilters;
    //   var role = columnFilters["role.name"];
    //   var company_code = columnFilters["company_detail.code"];

    //   var sendData = {
    //     username: this.serverParams.columnFilters.username,
    //     name: this.serverParams.columnFilters.name,
    //     email: this.serverParams.columnFilters.email,
    //     level: this.serverParams.columnFilters.level,
    //     status: this.serverParams.columnFilters.status,
    //     role:role,
    //     company_code: company_code,
    //     file_name: "download_" + CurrentDate + ".xlsx",
    //   };
    //   this.$http({
    //     url: baseURI,
    //     method: "GET",
    //     data: sendData,
    //     responseType: "blob",
    //   })
    //     .then((response) => {
    //       this.errors = [];
    //       var filename = sendData.file_name;

    //       var fileURL = window.URL.createObjectURL(new Blob([response.data]));
    //       var fileLink = document.createElement("a");

    //       fileLink.href = fileURL;
    //       fileLink.setAttribute("download", filename);
    //       document.body.appendChild(fileLink);
    //       fileLink.click();
    //       this.fade(false);
    //     })
    //     .catch((error) => {
    //       if (error.response) {
    //         if (error.response.status === 422) {
    //           this.errors = { message: ["File Not Found"] };
    //           this.error("File Not Found");
    //         } else if (error.response.status === 500) {
    //           this.$router.push("/server-error");
    //         } else {
    //           this.$router.push("/page-not-found");
    //         }
    //       }
    //     });
    // },
    createData() {
      window.location.href = "/order/new-transport-order/add";
    },

    uploadData() {
      window.location.href = "/order/new-transport-order/upload";
    },

    editData(index, row) {
      var params = this.$onRandom(row.transport_order_header_id);
      window.location.href = "/order/new-transport-order/edit/" + params;
    },

    detailData(index, row) {
      var params = this.$onRandom(row.transport_order_header_id);
      window.location.href = "/order/new-transport-order/detail/" + params;
    },

    // updateFilters(col,value) {

    // },

    deleteData(index, row, status) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI =
            this.$settings.endPoint +
            "transport-order-header-new/delete/" +
            row.transport_order_header_id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    // load items is what brings back the rows from server
    loadItems() {
      const baseURI = this.$settings.endPoint + "transport-order-header-new";
      //var columnFilters = this.serverParams.columnFilters;
      var date_order = "";
      if (this.serverParams.columnFilters.order_date != "") {
        date_order = this.$moment(
          this.serverParams.columnFilters.order_date
        ).format("YYYY-MM-DD");
      }
      let orderstatus = this.orderStatus(
        this.serverParams.columnFilters.order_status
      );
      let range_order_date = moment(String(this.dateFilter.startDate)).format('YYYY-MM-DD') + '~' + moment(String(this.dateFilter.endDate)).format('YYYY-MM-DD')
      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&company_name=${this.serverParams.columnFilters.company_name}&order_status=${orderstatus}&shipwith_ref=${this.serverParams.columnFilters.shipwith_ref}&order_no=${this.serverParams.columnFilters.order_no}&pick_name=${this.serverParams.columnFilters.pick_name}&dest_name=${this.serverParams.columnFilters.dest_name}&status_tms=${this.serverParams.columnFilters.status_tms}&order_date=${date_order}&shipment_rate=${this.serverParams.columnFilters.shipment_rate}&billable_tariff=${this.serverParams.columnFilters.billable_tariff}&type_truck=${this.serverParams.columnFilters.type_truck}&remarks=${this.serverParams.columnFilters.remarks}&pick_plan=${this.serverParams.columnFilters.pick_plan}&dest_plan=${this.serverParams.columnFilters.dest_plan}&vehicle_no=${this.serverParams.columnFilters.vehicle_no}&range_order_date=${range_order_date}`,
          { timeout: 10000 }
        )
        .then((response) => {
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    success2(msg) {
      let pesan = "";
      msg.forEach((item) => {
        pesan = pesan + "<p>* " + item + "</p>";
      });

      this.$swal({
        icon: "info",
        backdrop: true,
        title: "Information",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },

    loadingSubmiting() {
      this.$swal({
        icon: "warning",
        backdrop: true,
        title: "Please wait",
        html: "<h2>SUBMITTING ORDER...</h2>",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 5000); // hide the message after 3 seconds
    },

    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },
  },
  events: {},
  created: function () { },
  mounted() {
    this.fetchIt();
    this.loading();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content {
  filter: blur(5px);
}

.card-body {
  padding: 5px 1.5rem 1rem;
}

.main-wrapper .page-wrapper .page-content {
  margin-top: 40px;
}

.vgt-table th {
  text-align: left;
}
</style>
