<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
        <div class="loadertext" v-if="this.isLoading"> {{ $t('loadingTxt') }}...</div>
        <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
            <!--begin::sidebar-->
            <sidebar-component classMenu="DashboardFullfilment"></sidebar-component>
            <!--end::sidebar-->

            <div class="page-wrapper">
                <!--begin::navbar-->
                <navbar-component></navbar-component>
                <!--end::navbar-->
                <!--begin::content-->
                <div class="page-content">
                    <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
                        <div>
                            <h4 class="mb-3 mb-md-0">Proforma Invoice : Posting</h4>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
                        <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
                            <div class="card">

                                <div class="card-body">
                                    <div class="row mb-3">
                                        <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                                            <div class="input-group">
                                                <input class="form-control" v-model="proformaInvoiceNo" @change="loadLocationTable()" type="text" placeholder="PI Number"/>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                                            <div class="input-group">
                                                <input class="form-control" v-model="loadId" @change="loadLocationTable()" type="text" placeholder="Load Id"/>
                                            </div>
                                        </div>
                                        <div class="col-lg-1 col-md-12 col-sm-12 col-xs-12">
                                            <button
                                                class="btn btn-primary float-end">
                                                Reset Filter
                                            </button>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <!--  
                                                @on-page-change="onPageChange"
                                                @on-sort-change="onSortChange"
                                                @on-column-filter="onColumnFilter"
                                                @on-per-page-change="onPerPageChange" 
                                            -->
                                            <vue-good-table
                                                title="list-order-ct"
                                                mode="remote"
                                                :select-options="{ 
                                                                enabled: true,
                                                                selectOnCheckboxOnly: true, 
                                                                selectionText: $t('data_selected'), 
                                                                clearSelectionText: $t('clear'), }"
                                                :totalRows="totalRecords"
                                                :line-numbers="true"
                                                :isLoading.sync="isLoading"
                                                @on-selected-rows-change="selectionChanged"
                                                :pagination-options="{
                                                    enabled: true,
                                                }"
                                                :rows="rows"
                                                :columns="columns"
                                            >
                                                <div slot="selected-row-actions" >
                                                    <button class="btn btn-primary btn-xs" style="margin-right:20px;"><i class="mdi mdi-file-document"></i>{{ $t("downloadData") }} PDF  </button>
                                                    <button class="btn btn-warning btn-xs">Confirm</button>
                                                </div>
                                                <template slot="table-row" slot-scope="props">
                                                    <span v-if="props.column.field == 'actions'">
                                                        <button type="button" class="btn btn-warning btn-icon mx-1" style="color: white;text-decoration: none;  cursor: pointer;"
                                                        title="Edit"
                                                        @click="
                                                            backNew(props.index, props.row)
                                                        "
                                                        >
                                                            <i class="mdi mdi-undo-variant" style="font-size:16px"></i>
                                                        </button>
                                                    </span>
                                                    <span v-else-if="props.column.field == 'entries'">
                                                        <a style="color: #ab0635;text-decoration: none; cursor: pointer; "
                                                            title="Detail Load Id"
                                                            @click.prevent="
                                                                detailPi(props.row)
                                                            "
                                                        >
                                                            {{ props.row.entries }}
                                                        </a>
                                                    </span>
                                                    <span v-else>
                                                        {{ props.formattedRow[props.column.field] }}
                                                    </span>
                                                </template>
                                            </vue-good-table>

                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>

                    <modal-proformaInvoiceDetail
                        :showModal="showModalPI" 
                        :data="this.detailData"
                        @closeModal="closeMyModal">
                    </modal-proformaInvoiceDetail>
                </div>
                <!--end::content-->
             
                <!--begin::footer-->
                <footer-component></footer-component>
                <!--end::footer-->
            </div>
        </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import ModalProformaInvoiceDetail from "@/components/modal/modalProformaInvoiceDetail.vue";

export default {
    name:"proformaInvoiceCreated",
    components: {
        "sidebar-component": sidebarComponent,
        "navbar-component": navbarComponent,
        "footer-component": footerComponent,
        "modal-proformaInvoiceDetail":ModalProformaInvoiceDetail,
    },
    data() {
        return {
            closeBtn: true,
            isLoading: false,
            errors: [],
            proformaInvoiceNo:"",
            loadId:"",
            totalRecords:0,
            showModalPI:false,
            rowsSelection:[],
            detailData:[],
            columns: [
                {
                    label: "Proforma Invoice Number",
                    field: "pinumber",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("companyTxt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: "Entries",
                    field: "entries",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("companyTxt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: "Total",
                    field: "total",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("companyTxt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: this.$t("create_at_txt"),
                    field: "create_at",
                    type: 'date',
                    dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
                    dateOutputFormat: 'dd MMM yyyy h:mm:ss a',//'dd MMM yyyy',
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: "Filter By "+this.$t("create_at_txt"), // placeholder for filter input
                        filterValue: "", // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        trigger: "enter", //only trigger on enter not on keyup
                    },
                },
                {
                    label: this.$t("action_txt"),
                    field: "actions",
                    sortable: false,
                },
            ],
            rows:[
                {
                    pinumber:"PI-20230522-00001",
                    entries:4,
                    total:40000000,
                    create_at:"2023-05-22 14:33:00",
                },
                {
                    pinumber:"PI-20230522-00002",
                    entries:1,
                    total:5000000,
                    create_at:"2023-05-22 14:33:00",
                },
            ],
        }
    },  
    methods: {
        closeMyModal() {
            this.showModalPI = false;
            this.loadItems();
        },
        detailPi(row){
            this.detailData['method'] = "detail";
            this.detailData['data'] = row;
            this.showModalPI = true;
        },
        backNew(index,data){
            console.log(data,index);
        },
        loadItems(){
            console.log("test");
        },
        selectionChanged(ev){
            this.rowsSelection=[];
            this.rowsSelection=ev.selectedRows;
        },
    },
}
</script>

<style>

</style>