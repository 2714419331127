<template>

  <div class="row">
    <div class="row">
      <div class="col-lg-6">

      </div>
      <div class="col-lg-6">
        <!-- <button
                    
                    class="btn btn-primary float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="createData()"
                  >
                    <i class="link-icon" data-feather="plus"></i>
                    {{ $t("createNew") }}
                  </button> -->
      </div>
    </div>

    <vue-good-table title="list-of-orders" mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :line-numbers="true"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: false,
      }" :filterOptions="{
        enabled: false
      }" :rows="rowsTableOrder" :columns="columnsOrder" :sort-options="{
        enabled: false,
      }">
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'branch_name'">

          <p v-if="props.row.nmk.substring(0, 1) == 'N'"> Jakarta </p>
          <p v-if="props.row.nmk.substring(0, 1) == 'M'"> Medan </p>
          <p v-if="props.row.nmk.substring(0, 1) == 'H'"> Semarang </p>
          <p v-if="props.row.nmk.substring(0, 1) == 'S'"> Surabaya </p>
          <p v-if="props.row.nmk.substring(0, 1) == 'I'"> Jakarta </p>
          <!-- <p v-if="props.row.nmk.substring(0,1) == 'N'"> Jakarta </p> -->
        </span>



        <span v-if="props.column.field == 'control'">
          <!-- <a
              href="#"
              title="Detail"
              @click.prevent="detailData(props.index, props.row)"
            >
              <i class="mdi mdi-eye" style="font-size: 16px"></i>
            </a> -->
          <a href="#" title="Edit" @click.prevent="EditFormModal(props.row)">
            <i class="mdi mdi-square-edit-outline" style="font-size: 16px"></i>
          </a>


        </span>




        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>

      </template>
    </vue-good-table>

    <modal-driver-id :showModal="showModalNow" :data="detailData" @closeModal="closeMyModal"></modal-driver-id>



  </div>


</template>


<script>
import modalDriverID from "@/components/modal/modalDriverID.vue";

export default {
  name: "tripAnalysisComponent",
  props: {
    detail: [],
  },
  components: {
    "modal-driver-id": modalDriverID,
    // DirectionsRenderer,
  },
  watch: {

    detail: function (val) {
      console.log(val);
      this.loadTable(val)
    },

  },
  data() {
    return {
      //vehicle_no:this.$route.params.vehicle_no,
      detailData: "",
      detailTripAnalysis: "",
      showModalNow: false,
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      rowsTableOrder: [],
      columnsOrder: [
        {
          label: "Driver Number",
          field: "driver_number",
        },

        {
          label: "Branch",
          field: "branch_name",
        },

        {
          label: "NMK",
          field: "nmk",
        },


        {
          label: "Control",
          field: "control",
        },
      ],
      //
    };
  },
  methods: {

    loadTable(val) {
      this.rowsTableOrder = val;
      console.log(this.rowsTableOrder);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },


    EditFormModal(data) {
      this.detailData = data;
      this.showModalNow = !this.showModalNow;
    },

    closeMyModal() {
      this.showModalNow = false;
      this.$emit("afterSaveTableDriverId");
      //this.loadData(this.vehicle_no);
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },
  },
};
</script>