import Location from "@/components/master/location/Location.vue";
import LocationAdd from "@/components/master/location/LocationAdd.vue";
import LocationDetail from "@/components/master/location/LocationDetail.vue";
import LocationEdit from "@/components/master/location/LocationEdit.vue";
import LocationUpload from "@/components/master/location/LocationUpload.vue";

let routesLocation = [
    
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "LocationIndex",
        component: Location
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "LocationAdd",
        component: LocationAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "LocationEdit",
        component: LocationEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "LocationDetail",
        component: LocationDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "LocationUpload",
        component: LocationUpload
      },
  ]
  
  export default routesLocation
  