import Mel from "@/components/master/mel/Mel.vue";
import MelAdd from "@/components/master/mel/MelAdd.vue";
import MelDetail from "@/components/master/mel/MelDetail.vue";
import MelEdit from "@/components/master/mel/MelEdit.vue";
import MelUpload from "@/components/master/mel/MelUpload.vue";


let routesMel= [
    
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "MelIndex",
        component: Mel
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "MelAdd",
        component: MelAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "MelEdit",
        component: MelEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "MelDetail",
        component: MelDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "MelUpload",
        component: MelUpload
      },
  ]
  
  export default routesMel
  