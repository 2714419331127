<template>
  <div >
    <div class="loader" v-if="this.isLoadingDocAlert"></div>   
    <div class="loadertext" v-if="this.isLoadingDocAlert">{{ $t("loading") }}...</div>   
    <div class="main-wrapper" :class="{'blur-content': this.isLoadingDocAlert}">
      <!--begin::sidebar-->
      <sidebar-component classMenu="DocAlert"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <!-- <nav class="page-breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Alert</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                Doc Alert
              </li>
            </ol>
          </nav> -->
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            "
          >
            <div>
              <h4 class="mb-3 mb-md-0">{{ $t("DocAlertTxt") }}</h4>
            </div>
            <div class="d-flex align-items-center flex-wrap text-nowrap">
               <button type="button" v-if="isMaps" class="btn btn-primary wd-200 me-2 mb-2 mb-md-0 col-xs-12"   @click="btnMaps(false)">
                  Maps: {{ $t("OffTxt") }}
                </button>
                <button type="button" v-if="!isMaps" class="btn btn-success wd-200 me-2 mb-2 mb-md-0 col-xs-12"   @click="btnMaps(true)">
                  Maps: {{ $t("OnTxt") }}
                </button>

              
                <button type="button" class="btn btn-success btn-icon-text mb-2 mb-md-0 col-xs-12" @click="downloadData">
                  <i class="btn-icon-prepend" data-feather="download-cloud"></i>
                  {{ $t("downloadData") }}
                </button>
            </div>
          </div>

          <div class="col-lg-12 col-xl-12 stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="row mb-3">
                  <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                    <div class="input-group">
                      <multiselect
                        v-model="billable"
                        :options="fetchBillable"
                        :multiple="true"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :preserve-search="true"
                        placeholder="Pick some Billable"
                        label="name"
                        track-by="name"
                        :preselect-first="true"
                        @select="onSelectBillable"
                        @remove="onUnselectBillable"
                      >
                        <template
                          slot="selection"
                          slot-scope="{ values, isOpen }"
                          ><span
                            class="multiselect__single"
                            v-if="values.length &amp;&amp; !isOpen"
                            >{{ values.length }} selected</span
                          ></template
                        >
                      </multiselect>
                    </div>
                  </div>

                  <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                    <div class="input-group">
                      <multiselect
                        v-model="branch"
                        :options="fetchBranch"
                        :multiple="true"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :preserve-search="true"
                        placeholder="Pick some Branch"
                        label="branch_name"
                        track-by="branch_name"
                        :preselect-first="true"
                        @select="onSelectBranch"
                        @remove="onUnselectBranch"
                      >
                        <template
                          slot="selection"
                          slot-scope="{ values, isOpen }"
                          ><span
                            class="multiselect__single"
                            v-if="values.length &amp;&amp; !isOpen"
                            >{{ values.length }} Branch selected</span
                          ></template
                        >
                      </multiselect>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                    <div class="input-group">
                      <multiselect
                        v-model="truck"
                        :options="fetchTruck"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        placeholder="Pick some Type Truck"
                        label="type_truck"
                        track-by="type_truck"
                        :preselect-first="true"
                        @select="onSelectTypeTruck"
                        @remove="onUnselectTypeTruck"
                        @search-change="onSearchChange"
                      >
                        <template
                          slot="selection"
                          slot-scope="{ values, isOpen }"
                          ><span
                            class="multiselect__single"
                            v-if="values.length &amp;&amp; !isOpen"
                            >{{ values.length }} Truck selected</span
                          >
                        </template>
                      </multiselect>
                    </div>
                  </div>

                  <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                    <div class="input-group">
                      <multiselect
                        v-model="status"
                        :options="fetchStatus"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        placeholder="Pick some Status"
                        label="status_name"
                        track-by="status_name"
                        :preselect-first="true"
                        @select="onSelectStatus"
                        @remove="onUnselectStatus"
                      >
                        <template
                          slot="selection"
                          slot-scope="{ values, isOpen }"
                          ><span
                            class="multiselect__single"
                            v-if="values.length &amp;&amp; !isOpen"
                            >{{ values.length }} Status selected</span
                          ></template
                        >
                      </multiselect>
                    </div>
                  </div>

                  <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                    <div class="input-group">
                      <!-- <multiselect
                        v-model="vehicle_no"
                        :options="fetchTruckVehicleNo"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        placeholder="Vehicle No"
                        label="vehicle_no"
                        track-by="vehicle_no"
                        :preselect-first="true"
                        @select="onSelectVehicleNo"
                        @remove="onUnselectVehicleNo"
                        @search-change="onSearchChangeTruckVehicleNo"
                      >
                        <template
                          slot="selection"
                          slot-scope="{ values, isOpen }"
                          ><span
                            class="multiselect__single"
                            v-if="values.length &amp;&amp; !isOpen"
                            >{{ values.length }} selected</span
                          >
                        </template>
                      </multiselect> -->
                      <textarea class="form-control" :rows="rowsTextarea" placeholder="Vehicle No" v-model="vehicle_no" @change="selectVehicleNo"></textarea>
                    </div>
                  </div>

                  <div class="col-lg-1 col-md-12 col-sm-12 col-xs-12">
                    <button
                      class="btn btn-primary float-end"
                      @click="resetFilterLocation"
                    >
                      Reset Filter
                    </button>
                  </div>
                </div>

                <div class="row" v-if="isMaps">
                  <gmap-map
                    :center="center"
                    :zoom="zoom"
                    style="width: 100%; height: 500px"
                  >
                    <gmap-marker
                      :key="index"
                      v-for="(gmp, index) in locations"
                      :position="gmp"
                      :icon="gmp.icon"
                      :clickable="true"
                      @click="openMarker(gmp)"
                    >
                      <gmap-info-window :opened="true">
                        <span
                          class="badge"
                          :style="{
                            'background-color': gmp.color,
                            color: 'white',
                          }"
                          :opened="false"
                        >
                          {{ gmp.label }}
                        </span>
                        <!-- <span :class="gmp.color" style="margin:20px"> {{ gmp.label }} </span> -->
                      </gmap-info-window>
                    </gmap-marker>

                    <!-- <DirectionsRenderer
                        travelMode="DRIVING"
                        :origin="startLocation"
                        :destination="endLocation"
                      /> -->

                    <!-- <gmap-info-window :position="{ lat:-6.1850691, lng: 106.795189 }">
                        Hello world!
                      </gmap-info-window> -->
                  </gmap-map>
                </div>

                <div class="row">
                  <p class="text-muted mb-3"></p>

                  <vue-good-table
                    title="list-dashboard"
                    mode="remote"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    :totalRows="totalRecords"
                    :line-numbers="true"
                    :isLoadingDocAlert.sync="isLoadingDocAlert"
                    :pagination-options="{
                      enabled: false,
                    }"
                    :rows="rowsTable"
                    :columns="columns"
                  >
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'vehicle_no'">
                        <span
                          @click="searchVehicleNo(props.row)"
                          :style="{
                            'background-color': props.row.color,
                            cursor: 'pointer',
                          }"
                          class="badge"
                          >{{ props.row.vehicle_no }}
                        </span>
                      </span>

                      <span v-else-if="props.column.field == 'engine_car'">
                        <span
                          style="background-color: red"
                          class="badge"
                          v-if="props.row.engine_car == 'OFF'"
                          >{{ props.row.engine_car }}</span
                        >
                        <span
                          style="background-color: green"
                          class="badge"
                          v-else
                          >{{ props.row.engine_car }}</span
                        >
                      </span>

                      <span v-else-if="props.column.field == 'driver_name'">
                        {{ props.row.driver_name }}<br />
                        {{ props.row.driver_hp }}
                      </span>

                      <span v-else-if="props.column.field == 'stnk'">
                        <span
                          :style="{
                            'background-color': props.row.background_color_stnk,
                            color: props.row.color_stnk,
                          }"
                          class="badge"
                          >{{ props.row.stnk }}
                        </span>
                      </span>

                      <span v-else-if="props.column.field == 'kir'">
                        <span
                          :style="{
                            'background-color': props.row.background_color_kir,
                            color: props.row.color_kir,
                          }"
                          class="badge"
                          >{{ props.row.kir }}
                        </span>
                      </span>

                      <span v-else-if="props.column.field == 'delay_pod'">
                        <span
                          style="background-color: red; color:white;"
                          class="badge"
                          v-if="props.row.delay_pod != null"
                          >{{ props.row.delay_pod }}</span
                        >
                      </span>

                      <span v-else-if="props.column.field == 'detail'">
                        <a
                          href="#"
                          title="Detail"
                          @click.prevent="detailData(props.index, props.row)"
                        >
                          <i class="mdi mdi-eye" style="font-size: 16px"></i>
                        </a>
                      </span>

                      <span v-else-if="props.column.field=='gps_vendor'">
                          {{ props.row.gps_vendor.substring(0, 3) }}
                      </span>


                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import multiselect from "vue-multiselect";
// import DirectionsRenderer from "@/middleware/DirectionsRenderer";
import moment from "moment";

export default {
  name: "DocAlert",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    multiselect: multiselect,

    // DirectionsRenderer,
  },
  data() {
    const mapMarkerIcon = require("../../assets/icontruck.png");
    return {
      zoom: 5,
      isLoadingDocAlert: false,
      totalRecords: 0,
      rowsTextarea: 1,
      openedMarkerID: true,
      markerOption: {
        url: mapMarkerIcon,
        size: { width: 30, height: 30, f: "20px", b: "20px" },
        scaledSize: { width: 30, height: 30, f: "20px", b: "20px" },
        rotation: 45,
      },
      vehicle_no: [],
      vehicle_no_Filter: [],
      fetchTruckVehicleNo: [],
      billable: [],
      fetchBillable: [],
      billableFilter: [],
      branch: [],
      currentDate: moment(String(new Date())).format("YYYY-MM-DD"),
      truck: [],
      status: [],
      fetchBranch: [],
      fetchTruck: [],
      fetchStatus: [],
      options: [],
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isMaps: false,
      errors: [],
      featchTruck: [],
      featchStatus: [],
      featchBranch: [],
      langs: ["id", "en"],
      name: "",
      startLocation: null,
      endLocation: null,
      center: {
        lat: -2.548926,
        lng: 118.0148634,
      },
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      forms: [],
      locationMarkers: [],
      locations: [],
      locPlaces: [],
      realMonitoring: [],
      branchFilter: [],
      type_truckFilter: [],
      statusFilter: [],
      searchVhicle: "",
      rowsTable: [],

      columns: [
        {
          label: "Last Updated",
          field: "time_server",
        },

        {
          label: "Status",
          field: "truck_status",
        },

        {
          label: "Vehicle No",
          field: "vehicle_no",
        },

        {
          label: "Branch",
          field: "branch_name",
        },

        {
          label: "Own",
          field: "ownership",
        },

        {
          label: "Carrier",
          field: "carrier",
        },

        {
          label: "Type",
          field: "type_truck",
        },

        {
          label: "Driver Name",
          field: "driver_name",
        },

        {
          label: "GPS",
          field: "gps_vendor",
        },

        {
          label: "Last Location",
          field: "location",
        },

        {
          label: "Billable",
          field: "billable",
        },

        {
          label: "Speed",
          field: "speed",
        },

        {
          label: "ENG",
          field: "engine_car",
        },

        {
          label: "KM",
          field: "mileage",
        },


        {
          label: "KIR",
          field: "kir",
        },

        {
          label: "STNK",
          field: "stnk",
        },

        {
          label: "Outstanding Doc",
          field: "pod",
        },

        {
          label: "Delay POD",
          field: "delay_pod",
        },

        {
          label: "Detail",
          field: "detail",
          sortable: false,
        },
      ],
      rows: [],
      load_id: "",
      equipment: "",
      load_group: "",
      driver_name: "",
      driver_hp: "",
      date_time: "",
      address: "",
      deposit: 0,
      orders: "",
      htmlTable: "",
      permissionTabsBasic: "",
      permissionTabsShipment: "",
      permissionTabsDriver: "",
      permision_role: "",
    };
  },
  watch: {},
  methods: {
    downloadData() {
      let branch = this.branchFilter.toString();

      let type_truck = this.type_truckFilter.toString();
      let status = this.statusFilter.toString();
      let vehicle_no = this.vehicle_no_Filter.toString();
      let billable = this.billableFilter.toString();

      const baseURI =
        this.$settings.endPointCt +
        `real-monitoring/doc-alert?branch=${branch}&status=${status}&type_truck=${type_truck}&vehicle_no=${vehicle_no}&billable=${billable}&download=download`;

      var file_name = `doc_alert_.xlsx`;

      //var columnFilters = this.serverParams.columnFilters;
      return this.$http
        .get(baseURI, {
          responseType: "blob",
        })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },

    

    openDelayPOD(detail) {
      this.forms=[];
      const baseURI = this.$settings.endPointCt + "alert/detail/"+detail.vehicle_no+"/TRIP";
      return this.$http.get(baseURI).then((response) => {
        
        this.detailTripAlert = response.data.datas;
        this.showModalNow = !this.showModalNow;

        // this.form={ name: 'asep', usernmae:res.username };
      }).catch(error => {
          
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{
           
            if (error.response) {
              if(error.response.status === 422) {
                      this.errors = error.response.data.errors;
                      this.resultError(error.response.data.errors);
              }else if (error.response.status === 500) {
                  this.$router.push('/server-error');
              }else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
              }else{
                  this.$router.push('/page-not-found');
              }
            }

          }

        });
      
    },

    cek_permission_tabs() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "BlujayOrderController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      this.permision_role = rolePermision;

      if (!this.permision_role.method_custom1) {
        this.permissionTabsBasic = " hidden";
      }

      if (!this.permision_role.method_custom2) {
        this.permissionTabsShipment = " hidden";
      }

      if (!this.permision_role.method_custom3) {
        this.permissionTabsDriver = " hidden";
      }
    },

    btnMaps(stype) {
      this.isMaps = stype;
    },

    fade(sType) {
      this.isLoadingDocAlert = sType;
    },

    openMarker(detail) {
      let sts_engine = "";
      let information = "";
      let detail_information = "";
      if (detail.engine_car == "ON") {
        sts_engine = '<a style="color:green;">' + detail.engine_car + "<a>";
      } else {
        sts_engine = '<a style="color:red;">' + detail.engine_car + "<a>";
      }
      if (detail.information !== null) {
        detail_information = detail.information.replace(",","       ");  
        information = '<button type="button" class="btn btn-secondary" data-bs-html="true" data-bs-toggle="tooltip" title="'+
          detail_information +
          '">' +
          detail.pod +
          "</button>" ;
      } 
      this.$swal({
        position: "top-end",
        title: false,
        icon: false,
        showCloseButton: true,
        showConfirmButton: false,        
        header: '<a href="">Why do I have this issue?</a>',
        html:
          '<ul class="nav nav-tabs" id="myTab" role="tablist">' +
          '<li class="nav-item" ' +
          this.permissionTabsBasic +
          ">" +
          '<a class="nav-link active" id="home-tab" data-bs-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Basic</a>' +
          "</li>" +
          '<li class="nav-item" ' +
          this.permissionTabsShipment +
          ">" +
          '<a class="nav-link" id="profile-tab" data-bs-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" >Shipment</a>' +
          "</li>" +
          '<li class="nav-item" ' +
          this.permissionTabsDriver +
          ">" +
          '<a class="nav-link" id="contact-tab" data-bs-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Driver</a>' +
          "</li>" +
          '<li class="nav-item">' +
          '<span style="margin: 20px; color:">Eng:' +
          sts_engine +
          '<span > AC: <a style="color:red;">OFF</a> </span>' +
          "</li>" +
          "</ul>" +
          '<div class="tab-content border border-top-0 p-3" id="myTabContent">' +
          '<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">' +
          '<img src="' +
          detail.images +
          '" width="150px" height="150px"  />' +
          "<table>" +
          "<tr>" +
          "<td style='text-align:left' width='120px'>Last Update</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.updated_at +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Vehicle No</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.vehicle_no +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Load ID</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.load_id +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Equipment</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.equipment +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Load Group</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.load_group +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Driver Name</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.driver_name +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Driver Hp</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.driver_hp +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Date Time</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.time_server +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Address</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.location +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>STNK</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left;background:" +
          detail.background_color_stnk +
          ";color:" +
          detail.color_stnk +
          "'><b>" +
          detail.stnk +
          " </b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>KIR</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left;background:" +
          detail.background_color_kir +
          ";color:" +
          detail.color_kir +
          "'><b>" +
          detail.kir +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left' width='150px'>Outstanding Doc</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left;'><b>" +          
          information +
          "</b></td>" +
          "</tr>" +
          "</table>" +
          "</div>" +
          '<div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">' +
          detail.htmlTable +
          "</div>" +
          '<div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">' +
          "<table>" +
          "<tr>" +
          "<td style='text-align:left' width='120px'>Deposit</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          this.formatNumber(detail.deposit) +
          "</b></td>" +
          "</tr>" +
          "</table>" +
          "</div>" +
          "</div>",
      });
    },

    formatNumber(value) {
      //console.log(value);
      return parseInt(value).toLocaleString();
    },

    loadBlujay(blujayorder) {
      if (blujayorder != null) {
        this.load_id = blujayorder.load_id;
        this.equipment = blujayorder.equipment;
        this.load_group = blujayorder.load_group;
        this.driver_name = blujayorder.driver_name;
        this.driver_hp = blujayorder.driver_hp;
        this.date_time = blujayorder.created_at;
        this.deposit = blujayorder.amount;
        if(!blujayorder.amount){
          this.deposit = 0;
        }
        // if (blujayorder.driver_alert != null) {
        //   this.deposit = blujayorder.driver_alert.amount;
        // }
        this.address = "";
        this.htmlTable = "";
        this.orders = blujayorder.order_detail_alert;
        if (this.orders != null) {
          this.htmlTable += "<table>";
          this.orders.forEach((reso) => {
            let pecah = reso.name_rute.split(",");
            this.htmlTable +=
              "<tr>" +
              "<td style='text-align:left' width='120px'>Order Number</td>" +
              "<td style='text-align:left'>:</td>" +
              "<td style='text-align:left'>" +
              reso.order_number +
              "</td>" +
              "</tr>" +
              "<tr>" +
              "<td style='text-align:left'>Customer</td>" +
              "<td style='text-align:left'>:</td>" +
              "<td style='text-align:left'>" +
              reso.customer_name +
              "</td>" +
              "</tr>" +
              "<tr>" +
              "<td style='text-align:left'>Order Group</td>" +
              "<td style='text-align:left'>:</td>" +
              "<td style='text-align:left'>" +
              reso.order_group +
              "</td>" +
              "</tr>" +
              "<tr>" +
              "<td style='text-align:left'>Status</td>" +
              "<td style='text-align:left'>:</td>" +
              "<td style='text-align:left'>" +
              reso.tms_status +
              "</td>" +
              "</tr>" +
              "<tr>" +
              "<td style='text-align:left'>Target Suhu</td>" +
              "<td style='text-align:left'>:</td>" +
              "<td style='text-align:left'></td>" +
              "</tr>" +
              "<tr>" +
              "<td style='text-align:left'>Rute</td>" +
              "<td style='text-align:left'>:</td>" +
              "<td style='text-align:left'>" +
              pecah[0]?.slice(8) +
              " - " +
              pecah[1]?.slice(8) +
              "</td>" +
              "</tr>" +
              "<tr>" +
              "<td colspan='3'>__________________________________</td>" +
              "</tr>";
          });
          this.htmlTable += "</table>";
        }
      }
    },

    //awal Filter vehicle no
    loadTruckVehicleNo() {
      const baseURI = this.$settings.endPointCt + `master-truck/select`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTruckVehicleNo = response.data.datas.data;
      });
    },

    onSearchChangeTruckVehicleNo(ev) {
      const baseURI =
        this.$settings.endPointCt + `master-truck/select?vehicle_no=` + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTruckVehicleNo = response.data.datas.data;
      });
    },

    //akhir filter vehicle no

    initMarker(loc) {
      this.existingPlace = loc;
    },

    setLocationLatLng: function () {
      navigator.geolocation.getCurrentPosition((geolocation) => {
        this.center = {
          lat: geolocation.coords.latitude,
          lng: geolocation.coords.longitude,
        };
      });
      // this.locations = [
      //   {
      //     id:1,
      //     lat: -6.2066575,
      //     lng: 106.7759629,
      //     label: "B 123123 EG",
      //     rotate:20,
      //     color:"badge bg-primary"
      //   },
      //   {
      //     id:2,
      //     lat: -6.181783879012301,
      //     lng: 106.79797850513704,
      //     label: "B 22222 EH",
      //     rotate:40,
      //     color:"badge bg-success"
      //   },
      //   {
      //     id:3,
      //     lat: -6.1893281,
      //     lng: 106.764631,
      //     label: "B 12223 EJ",
      //     rotate:90,
      //     color:"badge bg-warning"
      //   },
      //   {
      //     id:4,
      //     lat: -6.1810517,
      //     lng: 106.7679362,
      //     label: "F 231312 EG",
      //     rotate:70,
      //     color:"badge bg-info"
      //   },
      // ];
    },

    changeFilterLocation() {
      let dataSend = {
        branch: this.branchFilter,
        type_truck: this.type_truckFilter,
        status: this.statusFilter,
        vehicle_no: this.vehicle_no_Filter,
      };
      //this.realMonitoring =[];
      this.locations = [];
      this.rowsTable = [];

      this.loadFilter(dataSend);
    },

    loadFilter(data) {
      const baseURI = this.$settings.endPointCt + `real-monitoring/doc-alert`;
      return this.$http.post(baseURI, data).then((response) => {
        this.realMonitoring = response.data.datas.data;
        this.totalRecords = response.data.datas.total;
        this.rows = response.data.datas.data;
        this.loadFetchLocation();
        this.loadFetchLocationTable();
      });
    },

    onUnselectBranch(ev) {
      this.branchFilter.splice(this.branchFilter.indexOf(ev.branch_id), 1);
      this.loadLocationTable();
    },

    onSelectBranch(ev) {
      this.branchFilter.push(ev.branch_id);
      //console.log(this.branchFilter);
      this.loadLocationTable();
    },

    onSelectTypeTruck(ev) {
      this.type_truckFilter.push(ev.type_truck_id);
      this.loadLocationTable();
    },

    onSelectBillable(ev) {
      this.billableFilter.push(ev.name);
      this.loadLocationTable();
    },

    onUnselectBillable(ev) {
      this.billableFilter.splice(this.billableFilter.indexOf(ev.id), 1);
      this.loadLocationTable();
    },

    onUnselectTypeTruck(ev) {
      this.type_truckFilter.splice(
        this.type_truckFilter.indexOf(ev.type_truck_id),
        1
      );
      this.loadLocationTable();
    },

    onSelectVehicleNo(ev) {
      this.vehicle_no_Filter.push(ev.vehicle_no);
      this.loadLocationTable();
    },

    onUnselectVehicleNo(ev) {
      this.vehicle_no_Filter.splice(
        this.vehicle_no_Filter.indexOf(ev.vehicle_no),
        1
      );
      this.loadLocationTable();
    },

    onSelectStatus(ev) {
      this.statusFilter.push(ev.status_name);
      this.loadLocationTable();
    },

    onUnselectStatus(ev) {
      this.statusFilter.splice(this.statusFilter.indexOf(ev.status_name), 1);
      this.loadLocationTable();
    },

    loadFilterClick(data) {
      const baseURI = this.$settings.endPointCt + `real-monitoring/doc-alert`;
      return this.$http.post(baseURI, data).then((response) => {
        this.realMonitoring = response.data.datas.data;
        this.totalRecords = response.data.datas.total;

        this.loadFetchLocation();
      });
    },

    searchVehicleNo(ev) {
      this.isMaps = true;
      this.zoom = 15;
      (this.center = {
        lat: ev.lat,
        lng: ev.lng,
      }),
        (this.realMonitoring = []);

      this.realMonitoring.push(ev);

      this.loadFetchLocation_single_select();
    },

    searchVehicleNoByText() {
      this.locations = [];
      let dataSend2 = {
        vehicle_no: this.searchVhicle,
      };
      this.loadFilter(dataSend2);
    },

    locateGeoLocation(latitude, longitude) {
      const marker = {
        lat: latitude,
        lng: longitude,
      };
      this.locationMarkers.push({ position: marker });
      this.locPlaces.push(this.existingPlace);
      this.center = marker;
      this.existingPlace = null;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoadingDocAlert = false;
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      // const userDatas = this.$getUserInfo();
      // this.name = userDatas.sub.name;
    },

    loadBranch() {
      const baseURI = this.$settings.endPointCt + `branch/select`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchBranch = response.data.datas.data;
      });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadLocationTable();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    loadStatus() {
      const baseURI = this.$settings.endPointCt + `master-status?limit=100`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchStatus = response.data.datas.data;
      });
    },

    loadTruck() {
      const baseURI = this.$settings.endPointCt + `type-truck/select`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTruck = response.data.datas.data;
      });
    },

    onSearchChange(ev) {
      const baseURI =
        this.$settings.endPointCt + `type-truck/select?type_truck=` + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTruck = response.data.datas.data;
      });
    },


    selectVehicleNo() {
      this.rowsTextarea=1;
    //  console.log(this.vehicle_no.length);
      if(this.vehicle_no.length > 120){
        this.rowsTextarea=10;
      }
      this.vehicle_no_Filter=[];
      var pisah=this.vehicle_no.split(', ');
    //  console.log(pisah);
      this.vehicle_no_Filter.push(pisah);
      this.loadLocationTable();
    },

    resetFilterLocation() {
      this.rowsTextarea=1;
      this.searchVhicle = "";
      this.branch = "";
      this.truck = "";
      this.status = "";
      this.billable = "";
      this.vehicle_no = "";
      this.branchFilter = [];
      this.type_truckFilter = [];
      this.statusFilter = [];
      this.vehicle_no_Filter = [];
      this.billableFilter = [];
      this.loadLocation();
      this.loadLocationTable();
      this.zoom = 5;
    },

    detailData(index, row) {
      //console.log(row);
      var params = this.$onRandom(row.id);
      //console.log(params);
      window.location.href =
        "/monitoring/detail/" +
        params +
        "/" +
        row.vehicle_no +
        "/" +
        row.gps_vendor;
    },

    loadLocation() {
      const baseURI =
        this.$settings.endPointCt + `real-monitoring/doc-alert?download=all`;
      return this.$http.get(baseURI).then((response) => {
        this.realMonitoring = response.data.datas.data;
        //this.totalRecords=response.data.datas.total;

        this.loadFetchLocation();
        // navigator.geolocation.getCurrentPosition((geolocation) => {
        //   this.center = {
        //     lat: geolocation.coords.latitude,
        //     lng: geolocation.coords.longitude,
        //   };
        // });
        // let color="";

        // console.log(this.realMonitoring);
      });
    },

    loadLocationTable() {
      this.isLoadingDocAlert=true;
      this.rows = [];
      this.realMonitoring = [];
      this.locations = [];
      this.rowsTable = [];

      let branch = this.branchFilter.toString();

      let type_truck = this.type_truckFilter.toString();
      let status = this.statusFilter.toString();
      let vehicle_no = this.vehicle_no_Filter.toString();
      let billable = this.billableFilter.toString();

      const baseURI = this.$settings.endPointCt + `real-monitoring/doc-alert`;
      return this.$http
        .get(
          baseURI +
            `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&branch=${branch}&status=${status}&type_truck=${type_truck}&vehicle_no=${vehicle_no}&billable=${billable}&download=all`
        )
        .then((response) => {
          this.isLoadingDocAlert=false;
          this.rows = response.data.datas;
          this.realMonitoring = response.data.datas;
          this.totalRecords = 0;
          this.loadFetchLocationTable();
          this.loadFetchLocation();
          // navigator.geolocation.getCurrentPosition((geolocation) => {
          //   this.center = {
          //     lat: geolocation.coords.latitude,
          //     lng: geolocation.coords.longitude,
          //   };
          // });
          // let color="";

          // console.log(this.realMonitoring);
        }).catch(error => {
          this.isLoadingDocAlert = false;
          //console.log(this.isLoadingDocAlert_dashboard);
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{
            this.isLoadingDocAlert= false;
            if (error.response) {
              if(error.response.status === 422) {
                      this.errors = error.response.data.errors;
                      this.resultError(error.response.data.errors);
              }else if (error.response.status === 500) {
                  this.$router.push('/server-error');
              }else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
              }else{
                  this.$router.push('/page-not-found');
              }
            }

          }

        });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },

    loadFetchLocation_single_select() {
      this.locations = [];
      this.realMonitoring.forEach((res) => {
      

        this.locations.push({
          id: res.id,
          lat: res.lat,
          lng: res.lng,
          label: res.vehicle_no,
          rotate: res.rotate,
          stnk: res.stnk,
          stnk_color_expired: res.stnk_color_expired,
          stnk_expired: res.stnk_expired,
          stnk_expired_in: res.stnk_expired_in,
          stnk_expired_totalday: res.stnk_expired_totalday,
          stnk_grid_color: res.stnk_grid_color,
          location: res.location,
          updated_at: res.updated_at,
          truck_status: res.truck_status,
          vehicle_no: res.vehicle_no,
          type_truck: res.type_truck,
          gps_vendor: res.gps_vendor,
          city: res.city,
          province: res.province,
          temperature: res.temperature,
          speed: res.speed,
          billable: res.billable,
          engine_car: res.engine_car,
          mileage: res.mileage,
          color: res.color,
          branch_name: res.branch_name,
          pod: res.pod,
          delay_pod: res.delay_pod,
          information: res.information,
          carrier: res.carrier,
          delay: res.delay,
          kir: res.kir,
          kir_color_expired: res.kir_color_expired,
          kir_expired: res.kir_expired,
          kir_expired_in: res.kir_expired_in,
          kir_expired_totalday: res.kir_expired_totalday,
          kir_grid_color: res.kir_grid_color,
          background_color_stnk: res.background_color_stnk,
          color_stnk: res.color_stnk,
          background_color_kir: res.background_color_kir,
          color_kir: res.color_kir,
          time_server: res.time_server,
          load_id: res.load_id,
          equipment: res.equipment,
          load_group: res.load_group,
          driver_name: res.driver_name,
          driver_hp: res.driver_hp,
          date_time: res.date_time,
          address: res.address,
          orders: res.orders,
          temp: res.temp,
          deposit: res.deposit,
          ownership: res.ownership,
          images: res.images,
          icon:res.icon,
          htmlTable: this.htmlTable,
        });
      });
    },

    loadFetchLocation() {
      this.locations = [];
      this.realMonitoring.forEach((res) => {
        let background_color_stnk = "";
        let color_stnk = "white";

        let background_color_kir = "";
        let color_kir = "white";
        let images = require("../../assets/no-image.png");

        if (res.stnk_expired_totalday <= 0) {
          background_color_stnk = res.stnk_color_expired;
          color_stnk = "white";
        } else if (
          res.stnk_expired_totalday > 0 &&
          res.stnk_expired_totalday <= 30
        ) {
          background_color_stnk = res.stnk_grid_color;
          color_stnk = "white";
        } else {
          color_stnk = "black";
        }

        if (res.images != null) {
          images = res.images;
        }

        if (res.kir_expired_totalday <= 0) {
          background_color_kir = res.kir_color_expired;
          color_kir = "white";
        } else if (
          res.kir_expired_totalday > 0 &&
          res.kir_expired_totalday <= 30
        ) {
          background_color_kir = res.kir_grid_color;
          color_kir = "white";
        } else {
          color_kir = "black";
        }

          this.load_id = "";
          this.equipment =  "";
          this.load_group =  "";
          this.driver_name =  "";
          this.driver_hp =  "";
          this.date_time =  "";
          this.address = "";
          this.orders="";
          this.htmlTable="";

        this.loadBlujay(res.blujay_order);

        this.locations.push({
          id: res.real_monitoring_id,
          lat: res.latitude,
          lng: res.longitude,
          label: res.vehicle_no,
          rotate: 20,
          stnk: moment(String(res.stnk)).format("YYYY-MM-DD"),
          stnk_color_expired: res.stnk_color_expired,
          stnk_expired: res.stnk_expired,
          stnk_expired_in: res.stnk_expired_in,
          stnk_expired_totalday: res.stnk_expired_totalday,
          stnk_grid_color: res.stnk_grid_color,
          location: res.location,
          updated_at: res.updated_at,
          truck_status: res.truck_status,
          vehicle_no: res.vehicle_no,
          type_truck: res.type_truck,
          gps_vendor: res.gps_vendor,
          city: res.city,
          province: res.province,
          temperature: res.temperature,
          billable: res.billable,
          speed: res.speed,
          engine_car: res.engine_car,
          mileage: res.mileage!=null?res.mileage.toLocaleString():0,
          color: res.status_color,
          branch_name: res.branch_name,
          pod: res.pod,
          delay_pod: res.delay_pod,
          information: res.information,
          carrier: res.carrier,
          delay: "",
          kir: moment(String(res.kir)).format("YYYY-MM-DD"),
          kir_color_expired: res.kir_color_expired,
          kir_expired: res.kir_expired,
          kir_expired_in: res.kir_expired_in,
          kir_expired_totalday: res.kir_expired_totalday,
          kir_grid_color: res.kir_grid_color,
          background_color_stnk: background_color_stnk,
          color_stnk: color_stnk,
          background_color_kir: background_color_kir,
          color_kir: color_kir,
          time_server: res.time_server,
          load_id: this.load_id,
          equipment: this.equipment,
          load_group: this.load_group,
          driver_name: this.driver_name!=null && this.driver_name!=""? this.driver_name:res.driver_name,
          driver_hp: this.driver_hp!=null && this.driver_hp!=""? this.driver_hp:res.driver_hp,
          date_time: this.date_time,
          address: this.address,
          orders: this.orders,
          deposit: this.deposit,
          temp: "",
          images: images,
          ownership: res.ownership,
          htmlTable: this.htmlTable,
          icon: {
            path: 'M 0 0 C 6.625 -7.69 5.825 -2.318 10.132 0.035 L 4.627 -15.371',
            fillColor: res.status_color,
            fillOpacity: 1,
            rotation: res.heading,
            strokeWeight: 1,
            strokeColor: res.status_color,
            scale: 1.5,
          },
        });
      });
    },

    loadFetchLocationTable() {
      this.rows.forEach((res) => {
        let background_color_stnk = "";
        let color_stnk = "";

        let background_color_kir = "";
        let color_kir = "";
        let images = require("../../assets/no-image.png");
        if (res.stnk_expired_totalday <= 0) {
          background_color_stnk = res.stnk_color_expired;
          color_stnk = "white";
        } else if (
          res.stnk_expired_totalday > 0 &&
          res.stnk_expired_totalday <= 30
        ) {
          background_color_stnk = res.stnk_grid_color;
          color_stnk = "white";
        } else {
          color_stnk = "black";
        }

        if (res.kir_expired_totalday <= 0) {
          background_color_kir = res.kir_color_expired;
          color_kir = "white";
        } else if (
          res.kir_expired_totalday > 0 &&
          res.kir_expired_totalday <= 30
        ) {
          background_color_kir = res.kir_grid_color;
          color_kir = "white";
        } else {
          color_kir = "black";
        }

        if (res.images != null) {
          images = res.images;
        }
          this.load_id = "";
          this.equipment =  "";
          this.load_group =  "";
          this.driver_name =  "";
          this.driver_hp =  "";
          this.date_time =  "";
          this.address = "";
          this.orders="";
          this.htmlTable="";

        this.loadBlujay(res.blujay_order);
        this.rowsTable.push({
          id: res.real_monitoring_id,
          lat: res.latitude,
          lng: res.longitude,
          label: res.vehicle_no,
          rotate: 20,
          stnk: moment(String(res.stnk)).format("YYYY-MM-DD"),
          stnk_color_expired: res.stnk_color_expired,
          stnk_expired: res.stnk_expired,
          stnk_expired_in: res.stnk_expired_in,
          stnk_expired_totalday: res.stnk_expired_totalday,
          stnk_grid_color: res.stnk_grid_color,
          location: res.location,
          updated_at: res.updated_at,
          truck_status: res.truck_status,
          vehicle_no: res.vehicle_no,
          type_truck: res.type_truck,
          gps_vendor: res.gps_vendor,
          city: res.city,
          province: res.province,
          temperature: res.temperature,
          billable: res.billable,
          speed: res.speed,
          engine_car: res.engine_car,
          mileage: res.mileage!=null?res.mileage.toLocaleString():0,
          color: res.status_color,
          branch_name: res.branch_name,
          carrier: res.carrier,
          pod: res.pod,
          delay_pod: res.delay_pod,
          information: res.information,
          kir: moment(String(res.kir)).format("YYYY-MM-DD"),
          kir_color_expired: res.kir_color_expired,
          kir_expired: res.kir_expired,
          kir_expired_in: res.kir_expired_in,
          kir_expired_totalday: res.kir_expired_totalday,
          kir_grid_color: res.kir_grid_color,
          background_color_stnk: background_color_stnk,
          color_stnk: color_stnk,
          background_color_kir: background_color_kir,
          color_kir: color_kir,
          time_server: res.time_server,
          load_id: this.load_id,
          equipment: this.equipment,
          load_group: this.load_group,
          driver_name: this.driver_name!=null && this.driver_name!=""? this.driver_name:res.driver_name,
          driver_hp: this.driver_hp!=null && this.driver_hp!=""? this.driver_hp:res.driver_hp,
          date_time: this.date_time,
          address: this.address,
          orders: this.orders,
          temp: "",
          htmlTable: this.htmlTable,
          deposit: this.deposit,
          ownership: res.ownership,
          self_insurace: "",
          images: images,
          icon: {
            path: 'M 0 0 C 6.625 -7.69 5.825 -2.318 10.132 0.035 L 4.627 -15.371',
            fillColor: res.status_color,
            fillOpacity: 1,
            rotation: res.heading,
            strokeWeight: 1,
            strokeColor: res.status_color,
            scale: 1.5,
          },
        });
      });
      //console.log(this.rowsTable);
    },
  },
  events: {},
  created: function () {},
  mounted() {
    setInterval(() => {
      this.loadLocationTable();
    }, 500000);

    this.fetchBillable.push(
      {
        id: 1,
        name: "LOGISTIK",
      },
      {
        id: 2,
        name: "RENTAL",
      }
    );

    this.cek_permission_tabs();
    const start = {
      lat: -6.2066575,
      lng: 106.7759629,
    };

    this.startLocation = start;

    const end = {
      lat: -6.1810517,
      lng: 106.7679362,
    };

    this.endLocation = end;
    //this.setLocationLatLng();
    //this.locateGeoLocation(5.54829, 95.323753);
    this.fetchIt();
    //this.loading();
    this.loadBranch();
    this.loadStatus();
    this.loadTruck();
    this.loadTruckVehicleNo();
    this.loadLocationTable();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}
.blur-content{
  filter: blur(5px); 
  pointer-events: none;
}





</style>