import Commodity from "@/components/master/commodity/Commodity.vue";
import CommodityAdd from "@/components/master/commodity/CommodityAdd.vue";
import CommodityDetail from "@/components/master/commodity/CommodityDetail.vue";
import CommodityEdit from "@/components/master/commodity/CommodityEdit.vue";
import CommodityUpload from "@/components/master/commodity/CommodityUpload.vue";

let routesCommodity = [
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "CommodityIndex",
        component: Commodity
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "CommodityAdd",
        component: CommodityAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "CommodityEdit",
        component: CommodityEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "CommodityDetail",
        component: CommodityDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "CommodityUpload",
        component: CommodityUpload
      },
   
  ]
  
  export default routesCommodity
  