<template>
  <div
    ref="modal"
    class="modal fade"
    :class="{ show: active, 'd-block': active }"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            v-on:click="$emit('closeModal')"
            aria-label="btn-close"
          ></button>
        </div>

        <form class="forms-sample" method="POST" @submit.prevent="submitData">
          <div class="modal-body">
            <div v-if="this.data.length > 1" class="mb-3">
              <h3 style="color:red">
                {{ 
                  $t('cancelConfirmation',{total:this.data.length})  
                }}
              </h3>
            </div>
            <div class="mb-3" v-if="this.status !== 'cancel_from_costumer'">
              <label class="form-label" v-if="this.status == 'cancel_from_costumer'">{{
                $t("reject_description_txt")
              }}</label>
              <label class="form-label" v-else>Void Reason</label>


              <v-select :options="fetchSystemCode" :searchable="false" :placeholder="$t('reject_description_txt')"
                v-model="reject_reason" :required="!reject_reason" label="value" :disabled="this.status == 'cancel_from_costumer'"></v-select>


              <div v-if="errors.reject_reason">
                <div v-for="error in errors.reject_reason" :key="error" class="alert alert-primary" role="alert">
                  <i data-feather="alert-circle"></i>
                  {{ error }}
                </div>
              </div>
            </div>
            <div class="mb-3">
                <label class="form-label" v-if="this.status == 'cancel_from_costumer'">{{
                  $t("cancel_desc_txt")
                }}</label>
                <label class="form-label" v-else>Void Description</label>
                <textarea class="form-control" v-model="remark" placeholder="">
                </textarea>                

                <div v-if="errors.remark">
                <div
                  v-for="error in errors.remark"
                  :key="error"
                  class="alert alert-primary"
                  role="alert"
                >
                  <i data-feather="alert-circle"></i>
                  {{ error }}
                </div>
              </div>
            </div>
            <!-- <div v-if="this.reject_reason == '' || this.reject_reason == 'Reject by customer'" class="mb-3">
              <label class="form-label">Notes:</label>

              <textarea class="form-control" v-model="reject_description" placeholder="">
              </textarea>
              <div v-if="errors.reject_description">
                <div v-for="error in errors.reject_description" :key="error" class="alert alert-primary" role="alert">
                  <i data-feather="alert-circle"></i>
                  {{ error }}
                </div>
              </div>
            </div>
            <div v-else class="mb-3">
              <label class="form-label">Reject Group</label>


              <v-select :options="fetchRejectGroup" :searchable="false" :placeholder="'Reject Group'"
                v-model="reject_group" label="value"></v-select>


              <div v-if="errors.reject_group">
                <div v-for="error in errors.reject_group" :key="error" class="alert alert-primary" role="alert">
                  <i data-feather="alert-circle"></i>
                  {{ error }}
                </div>
              </div>
            </div> -->
            
            
       
 

            
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              v-on:click="$emit('closeModal')"
            >
              {{ $t("close_txt") }}
            </button>
            <button class="btn btn-primary" type="submit">{{ $t("save_change_txt") }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
 

export default {
  components: {
  },
  name: "ModalTransportOrderCtRejectMass",
  emits: ["closeModal"],
  props: {
    showModal: Boolean,
    data: {},
    status:String,
  },
  watch: {
    showModal: {
      
      handler(newVal) { 
        this.remark='';
        this.reject_reason='';
        if(this.status == 'cancel_from_costumer'){
          this.reject_reason = 'Reject by customer';
        }
        this.active = newVal;
        this.getSystemCode();
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");

        // console.log(this.data.length);
        if(this.status=='cancel_from_costumer'){
          this.title='Cancel From Costumer';
        }else{
          this.title='Void Order';
        }
       
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
        fetchRejectGroup: [],
        reject_description: "",
        reject_reason: "",
        reject_group:"",  
        title:'',
        fetchSystemCode:[],
        remark:"",
        isLoading: false,
        active: this.showModal,
        forms: [],
        errors: [],
        rules: {
            select: [(v) => !!v || "Item is required"]
        }
      
    };
  },
  methods: {
    resultError(data) {
      var html = "";
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          html += objectData[y] + "<br>";
        }
      }

      this.loadError(html);
    },

    loadError(pesan) {
      this.$swal({
        backdrop: true,
        title: "Warning",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },

    loadSuccess(pesan) {
      this.$swal({
        backdrop: true,
        title: "Success",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },
    // getRejectGroup(){
    //   if(this.reject_reason !== 'Reject by customer'){
    //     const baseURI = this.$settings.endPointCt + "master-lookup/dependent?unique_id=rejec&parent="+this.reject_reason;
    //     this.reject_description = null;
    //     return this.$http
    //       .get(
    //         baseURI,
    //         { timeout: 10000 }
    //       )
    //       .then((response) => {
    //         var rows = response.data.datas;
    //         this.fetchRejectGroup = rows;
    //       })
    //       .catch(error => {

    //         if (error.code == "ECONNABORTED") {
    //           this.error(this.$t("check_internet_connection_txt"));
    //         } else {

    //           if (error.response) {
    //             if (error.response.status === 422) {
    //               this.errors = error.response.data.errors;
    //               this.resultError(error.response.data.errors);
    //             } else if (error.response.status === 500) {
    //               this.$router.push('/server-error');
    //             } else if (error.response.status === 401) {
    //               this.$router.push("/authorized-error");
    //             } else {
    //               this.$router.push('/page-not-found');
    //             }
    //           }

    //         }

    //       });
    //   }else{
    //     this.reject_group = null;
    //   }
    // },  

    success2(msg){
     
      this.$swal({
        icon: 'info',
        backdrop:true,
        title: "Information",
        html:"<p>* "+msg+"</p>",
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      
    },


    loadingSubmiting(){


      this.$swal({
        icon: 'warning',
        backdrop:true,
        title: "Please wait",
        html:'<h2>CANCELLING ORDER...</h2>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

    },

    submitData() {
 
        this.loadingSubmiting();
        // if (this.reject_description == "" && this.reject_reason == "Reject by customer") {
        //   this.errors = { reject_description: ["notes is required"] };
        //   this.resultError(this.errors);
        // }else if(this.reject_reason == ""){
        //   this.errors = { reject_reason: ["reject description is required"] };
        //   this.resultError(this.errors);
        // } else if (this.reject_group == "" && this.reject_reason == "Reject Internal"){
        //   this.errors = { reject_group: ["reject group is required"] };
        //   this.resultError(this.errors);
        // }else {
        if(this.remark == "" && this.reject_reason == ""){
            this.errors = {remark:["remark is required"]};
            this.resultError(this.errors);
        }else{
          var baseURI="";
          var forms = {remark:this.remark, transport_order_header_ids:this.data} 
          if(this.status=='void'){
            baseURI = this.$settings.endPoint + "transport-order-header/void";
            forms['void_reason'] = this.reject_reason;
          }else{
            baseURI = this.$settings.endPoint + "transport-order-header/send-order-cancel";
            forms['reject_reason'] = this.reject_reason;
          }
          this.$http
          .post(baseURI, forms,{ timeout: 30000 })
          .then((response) => {
              this.isLoading = false;
              this.errors         = [];
              this.$emit("closeModal");
              this.success2(response.data.datas.messages);
          })
          .catch(error => {
            this.isLoading = false;
            if(error.code == "ECONNABORTED"){
                this.error(this.$t("check_internet_connection_txt"));
            }else{

                if (error.response) {
                if(error.response.status === 422) {
                        this.errors = error.response.data.errors;
                        this.resultError(error.response.data.errors);
                }else if (error.response.status === 500) {
                    this.$router.push('/server-error');
                }else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                }else{
                    this.$router.push('/page-not-found');
                }
                }

            }

          });

        }
        
    },
  
    getSystemCode() {
      // const baseURI = this.$settings.endPoint + "system-code/select?system_code=ORDER_CANCEL_REASON";
      const baseURI = this.$settings.endPointCt + "master-lookup?unique_id=voidr";

       
      return this.$http
        .get(
           baseURI,
          { timeout: 10000 }
        )
        .then((response) => {
            var rows = response.data.datas;
            this.fetchSystemCode = rows;
          
        }).catch(error => {
           
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                      this.errors = error.response.data.errors;
                      this.resultError(error.response.data.errors);
              }else if (error.response.status === 500) {
                  this.$router.push('/server-error');
              }else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
              }else{
                  this.$router.push('/page-not-found');
              }
            }

          }

        });
    },

    asyncSearchSystemCode(ev){

      const baseURI = this.$settings.endPoint + "system-code/select?system_code=ORDER_CANCEL_REASON&value="+ev;
       
      return this.$http
        .get(
           baseURI,
          { timeout: 10000 }
        )
        .then((response) => {
            var rows = response.data.datas.data;
            this.fetchSystemCode = rows;
          
        }).catch(error => {
           
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                      this.errors = error.response.data.errors;
                      this.resultError(error.response.data.errors);
              }else if (error.response.status === 500) {
                  this.$router.push('/server-error');
              }else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
              }else{
                  this.$router.push('/page-not-found');
              }
            }

          }

        });
    }
 
  },
  mounted() { 
     
  },
};
</script>

<style scoped>
.blur-content{
  filter: blur(5px); 
  pointer-events: none;
}
.modal {
  background: rgba(0, 0, 0, 0.4);
}
.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left:0px !important;
}
</style>