<template>
    <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Payable Accessorial Form</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
                        aria-label="btn-close"></button>
                </div>

                <div class="modal-body">




                    <div class="row">
                        <div class="col-lg-6">


                            <div class="col-lg-12">
                                <label for="defaultconfig" class="col-form-label">Mode</label>
                                <input type="text" id="mode" readonly class="form-control" name="mode" v-model="forms.mode" />
                                <div v-if="errors.mode">
                                    <div v-for="error in errors.type_truck" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <label for="defaultconfig" class="col-form-label">Charge Code</label>
                                <input type="text" id="mode" readonly class="form-control" name="mode" v-model="forms.charge_code" />
                                <div v-if="errors.mode">
                                    <div v-for="error in errors.type_truck" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-6">


                            <div class="col-lg-12">
                                <label for="defaultconfig" class="col-form-label">Penyebrangan
                                </label>
                                <multiselect @search-change="asyncPenyebrangan" @input="changeRoute" v-model="masterRoute" :show-labels="false"
                                    :options="fetchMasterRoute" placeholder="Please Select Type Truck" track-by="type_truck"
                                    label="nama"></multiselect>
                                <!-- <div v-if="errors.type_truck">
                                    <div v-for="error in errors.m" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                    </div>
                                </div> -->
                            </div>


                            <div class="col-lg-12">
                                <label for="defaultconfig" class="col-form-label">Rate</label>
                                <input type="text" id="mode" readonly class="form-control" name="rate" v-model="forms.rate" />
                                <div v-if="errors.rate">
                                    <div v-for="error in errors.type_truck" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>






                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
                        cancel
                    </button>
                    <button class="btn btn-success" type="submit" v-on:click="saveAssign">
                        <span v-if="isLoading" class="spinner-border spinner-border-sm ms-auto" role="status"
                            aria-hidden="true"></span>Finish
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>
  
<script>
import multiselect from "vue-multiselect";
export default {
    components: {
        "multiselect": multiselect
    },
    name: "ModalAccesorialFormPayable",
    emits: ["closeModal"],
    props: {
        showModal: Boolean,
        data: null,
        categoryTruck:null,
        indexModal:null,
    },
    watch: {
        showModal: {
            handler(newVal) {
                this.forms = this.data;
                // this.getAllFormated(this.forms.rate)
                // this.forms.rate=this.getAllFormated(this.forms.rate)
                this.loadPenyeberangan(this.categoryTruck)
                //   this.forms = {
                //       alert_id:this.data.alert_id,
                //       alert_setting_id:this.data.alert_setting_id,
                //       vehicle_no: this.data.vehicle_no,
                //       alert_time: this.data.alert_time,
                //       alert_type: this.data.alert_type,
                //       alert_value: this.data.alert_value,
                //       alert_subtype: this.data.alert_subtype,
                //       alert_off_reason: this.data.alert_off_reason,
                //     };


                this.active = newVal;
                const body = document.querySelector("body");
                this.showModal
                    ? body.classList.add("modal-open")
                    : body.classList.remove("modal-open");
            },
            immediate: true,
            deep: true,
        },
    },
    data() {
        return {
            classColapsed: '',
            isLoading: false,
            active: this.showModal,
            forms: [],
            errors: [],
            locale: [],
            fetch_auto_load: [],
            auto_load: "",
            report_date: [],
            pickup_date: "",
            delivery_date: "",
            groupByCategory: [],
            type_error: [],
            rows: [],
            fetchMasterRoute: [],
            masterRoute: '',

        };
    },
    methods: {
        resetForm() {
            this.forms = [];
        },

        reset() {
            this.groupByCategory = [];
            this.loadItems(this.data[0].transport_order_header_id);
        },

        customLabel(ev) {
            return `${ev.driver_id} – ${ev.driver_name}`
        },
        customLabel2(ev) {
            if (ev.name == 'Yes') {
                return "YES - Shipment - Load Id - Appoint Load";
            } else if (ev.name == 'No') {
                return "NO - Shipment - Load Id";
            } else {
                return "Manual - Shipment Only";
            }
        },
        customLabelTransporter(ev) {
            return ev;
        },
        loadDriver() {
            const baseURI =
                this.$settings.endPointCt + `master-driver/select`;
            return this.$http.get(baseURI).then((response) => {
                this.fetchDriver = response.data.datas.data;

            });
        },

        success2(msg) {
            this.$swal({
                icon: 'info',
                backdrop: true,
                title: "Information",
                html: "<p>* " + msg + "</p>",
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false
            })
        },


        errorAlert(msg) {
            this.$swal({
                icon: 'danger',
                backdrop: true,
                title: "Information",
                html: "<p>* " + msg + "</p>",
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false
            })
        },


        asyncSearchDriver(ev) {
            this.fetchDriver = [];
            const baseURI =
                this.$settings.endPointCt + `master-driver/select?driver_id=` + ev;
            return this.$http.get(baseURI).then((response) => {
                this.fetchDriver = response.data.datas.data;
            });
        },

        getAllFormated(value) {
   

            let formatted = parseFloat(value).toFixed(2);
            return  this.forms.rate=formatted;

        },


        loadTransporter() {
            const baseURI =
                this.$settings.endPointCt + `master-lookup?unique_id=carer`;
            return this.$http.get(baseURI).then((response) => {
                this.fetchTransporter = response.data.datas;
            });
        },
        asyncTransporter(ev) {
            this.fetchTransporter = [];
            const baseURI =
                this.$settings.endPointCt + `master-lookup?unique_id=carer&search=` + ev;
            return this.$http.get(baseURI).then((response) => {
                this.fetchTransporter = response.data.datas;
            });
        },
        loadPenyeberangan() {
            // console.log(ev)
            const baseURI =
                this.$settings.endPoint + `rm-ferry-master?type_truck=`+this.categoryTruck;
            return this.$http.get(baseURI).then((response) => {
                this.fetchMasterRoute = response.data.datas.data;
            });
        },
        asyncPenyebrangan(ev) {
          
            this.fetchMasterRoute = [];
            const baseURI =
                this.$settings.endPoint + `rm-ferry-master?name=`+ev+`&type_truck=`+this.categoryTruck;
            return this.$http.get(baseURI).then((response) => {
                this.fetchMasterRoute = response.data.datas.data;
            });
        },
        changeRadio() {
            this.auto_load = null;
            this.vehicle_no = null;
            this.driver = null;
            this.transporter = null;
            this.typeTruck = null;
            this.errors = [];
        },
        setPriority() {

            console.log(this.groupByCategory[0]);
            // console.log(ev);
            // var data={
            //   vehicle_no:row2.vehicle_no,
            //   value:'',
            // }
            // console.log(data);
        },


        saveAssign() {
            this.$emit("closeModal");
            // this.$emit("pushDatas",{
            //     rate:this.forms.rate,
            //     index:this.indexModal
            // });
            
          
        },

      

        changeRoute(ev){
           
            this.forms.rate=this.getAllFormated(ev==null ? 0 : ev.biaya);
        },

        loadItems(transport_order_header_id) {
            this.rows = [];
            const baseURI = this.$settings.endPointCt + "truck-priority/select/" + transport_order_header_id;
            return this.$http
                .get(
                    baseURI,
                    { timeout: 10000 }
                )
                .then((response) => {

                    this.rows = response.data.datas;






                    // this.totalRecords = response.data.datas.total;
                    // this.isLoading = false;
                }).catch(error => {
                    this.isLoading = false;
                    if (error.code == "ECONNABORTED") {
                        this.error(this.$t("check_internet_connection_txt"));
                    } else {

                        if (error.response) {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                                this.resultError(error.response.data.errors);
                            } else if (error.response.status === 500) {
                                this.$router.push('/server-error');
                            } else if (error.response.status === 401) {
                                this.$router.push("/authorized-error");
                            } else {
                                this.$router.push('/page-not-found');
                            }
                        }

                    }

                });
        },




    },
    mounted() {
        //tail(this.data);
    },
};
</script>
  
<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background: #fff;
    border-radius: 0px;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 0px;
    width: 100%;
    height: 100%;
    margin-left: 0px !important;
}
</style>