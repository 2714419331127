<template>
  <div class="row">
    <vue-good-table
      title="list-of-one-time-analysis"
      mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      :totalRows="totalRecords"
      :line-numbers="true"
      :isLoading.sync="isLoading"
      :pagination-options="{
        enabled: true,
      }"
      :sort-options="{
        enabled: false,
      }"
      :filterOptions="{
        enabled: false
      }" 
      :rows="rowsTableOrder"
      :columns="columnsOrder"
    >
      <template slot="table-row" slot-scope="props">
        {{ props.formattedRow[props.column.field] }}
      </template>
    </vue-good-table>
  </div>
</template>


<script>
export default {
  name: "oneTimeAnalysisComponent",
  data() {
    return {
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      rowsTableOrder: [],
      columnsOrder: [
        {
          label: "System Time",
          field: "sytem_time",
        },

        {
          label: "CT Time",
          field: "ct_time",
        },

        {
          label: "Process",
          field: "process",
        },

        {
          label: "System Status",
          field: "system_status",
        },

        {
          label: "CT Status",
          field: "ct_status",
        },

        {
          label: "Remark",
          field: "remark",
        },

        {
          label: "Last Edit",
          field: "last_edit",
        },

        {
          label: "Control",
          field: "control",
        },
      ],
      //
    };
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadLocationTable();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },
  },
};
</script>
