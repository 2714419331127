import { render, staticRenderFns } from "./Profile.vue?vue&type=template&id=b2a48960&scoped=true"
import script from "./Profile.vue?vue&type=script&lang=js"
export * from "./Profile.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2a48960",
  null
  
)

export default component.exports