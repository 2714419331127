<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="CustomerCTDetail"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            "
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
               <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 25px"
                  >
                     {{ $t("customerDetailTxt") }}
                  </h6>

                  <button
                    class="btn btn-light float-end"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <i class="link-icon" data-feather="info"></i>
                    Audit Info
                  </button>

                  <div
                    class="row mb-3 collapse"
                    id="collapseExample"
                    style="padding-left: 20px"
                  >
                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created By:</label>
                      <p class="text-muted">{{ this.forms.create_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created At:</label>
                      <p class="text-muted">{{ this.forms.created_at }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated By:</label>
                      <p class="text-muted">{{ this.forms.update_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated At:</label>
                      <p class="text-muted">{{ this.forms.updated_at }}</p>
                    </div>
                  </div>
                </div>

                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->
                    
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "
                    ></div>

              
                    <div class="row">
                    

                      <div class="col-lg-6">
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">
                              Carrier Code </label>
                          </div>
                          <div class="col-lg-4">

                            <input type="text" class="form-control" id="Carrier Code" placeholder="Carrier Code"
                              v-model="forms.carrier_code" required readonly />

                            <div v-if="errors.carrier_code">
                              <div v-for="error in errors.carrier_code" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>




                      </div>



                    

                      <div class="col-lg-6">
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">
                              Carrier Name</label>
                          </div>
                          <div class="col-lg-8">

                            <input readonly type="text" class="form-control" id="name" placeholder="Carrier Name"
                              v-model="forms.carrier_name" required />

                            <div v-if="errors.carrier_name">
                              <div v-for="error in errors.carrier_name" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>




                      </div>

                 
                      <div class="col-lg-6">
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">
                              Carrier Email </label>
                          </div>
                          <div class="col-lg-8">

                            <input readonly type="text" class="form-control" id="state" placeholder="Carrier Email"
                              v-model="forms.carrier_email" />

                            <div v-if="errors.carrier_email">
                              <div v-for="error in errors.carrier_email" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      


                     



                      <div class="col-lg-6">
                      <div class="row mb-3">
                        <div class="col-lg-4">
                          <label
                            for="defaultconfig"
                            class="col-form-label"
                            style="padding-left: 25px"
                          >
                            Location </label
                          >
                        </div>
                        <div class="col-lg-8">
                   
                          <input
                            type="text"
                            class="form-control"
                            id="location"
                            placeholder="Location"
                            v-model="forms.location"
                            readonly
                          
                          />

                          <div v-if="errors.location">
                            <div
                              v-for="error in errors.location"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>



                      


                

                    </div>



                  










                  

                  
                    
                    </div>

                  <div class="row card">
                    <div class="card-header">
                      <h5 class="float-start">
                        Truck
                      </h5>


                      <button class="
                          btn btn-success 
                          text-white
                          float-end
                        " @click="sendToBlujayTruck()" type="button">
                          Send To Blujay
                        </button>


                     


                     

                    </div>

                    <div class="card-body">
                      <div class="table-responsive">
                        <table
                          class="table"
                          style="height: 300px"
                        >
                          <thead>
                            <tr>
                              <th></th>
                              <th>#</th>
                              <th style="width:500px">Nopol</th>
                              <th>Truck Type</th>
                              <th>Is Enabled</th>
                              <th>Status</th>
                          
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            
                            <tr
                              v-for="(input, index) in this.detailTruck"
                              :key="`phoneInput-${index}`"
                            >

                            <td width="200px">
                                 
                                 <input type="checkbox" class="form-check-input"  @change="updateTruckIsSelected($event, index)" :id="'isEnabled-' + index" />

                            </td>

                              <th>{{ index + 1 }}</th>
                              

                              <td width="200px">
                                {{input.vehicle_no}}
                              </td>

                              <td width="200px">
                               {{input.truck_type}}
                              </td>

                              <td width="200px">
                                {{ input.isEnabled }}
                                <!-- <input
                                  type="text"
                                  class="form-control"
                                  id="driver_name"
                                 
                                  v-model="input.driver_name"
                                
                                /> -->
                              </td>


                              <td width="200px">
                                {{ input.sob_status }}
                                <!-- <input
                                  type="text"
                                  class="form-control"
                                  id="driver_name"
                                 
                                  v-model="input.driver_name"
                                
                                /> -->
                              </td>


                            


                         



                           


                          


                          

                          





                              <td>
                                <!--          Remove Svg Icon-->
                               
                                

                               
                              </td>
                            </tr>
                          </tbody>

                          <tfoot></tfoot>
                        </table>
                      </div>
                    </div>
                  </div>

                  <br>

                  <div class="row card">
                    <div class="card-header">
                      <h5 class="float-start">
                        Driver
                      </h5>

                    
                      <button class="
                          btn btn-success 
                          text-white
                          float-end
                        " @click="sendToBlujayDriver()" type="button">
                          Send To Blujay
                        </button>
                     

                    </div>

                    <div class="card-body">
                      <div class="table-responsive">
                        <table
                          class="table"
                          style="height: 300px"
                        >
                          <thead>
                            <tr>
                              <th></th>
                              <th>#</th>
                              <th style="width:500px">Driver Name</th>
                              <th>Driver Number</th>
                              <th>Is Enabled</th>
                              <th>Status</th>
                             
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            
                            <tr
                              v-for="(input, index) in this.detailDriver"
                              :key="`phoneInput-${index}`"
                            >
                              <th>{{ index + 1 }}</th>
                              

                              <td width="200px">
                                 
                                 <input type="checkbox" class="form-check-input"  @change="updateDriverIsSelected($event, index)" :id="'isEnabled-' + index" />

                               </td>


                              <td width="200px">
                                {{ input.driver_name }}
                                <!-- <input
                                  type="text"
                                  class="form-control"
                                  id="driver_number"
                                 
                                  v-model="input.driver_number"
                                
                                /> -->
                              </td>

                              <td width="200px">
                                {{ input.driver_number }}
                                <!-- <input
                                  type="text"
                                  class="form-control"
                                  id="driver_name"
                                 
                                  v-model="input.driver_name"
                                
                                /> -->
                              </td>


                              <td width="200px">
                                {{ input.isEnabled }}
                                <!-- <input
                                  type="text"
                                  class="form-control"
                                  id="driver_name"
                                 
                                  v-model="input.driver_name"
                                
                                /> -->
                              </td>


                              <td width="200px">
                                {{ input.sob_status }}
                                <!-- <input
                                  type="text"
                                  class="form-control"
                                  id="driver_name"
                                 
                                  v-model="input.driver_name"
                                
                                /> -->
                              </td>


                              


                         



                           


                          


                


                              <td>
                                <!--          Remove Svg Icon-->
                                

                                

                               
                              </td>
                            </tr>
                          </tbody>

                          <tfoot></tfoot>
                        </table>
                      </div>
                    </div>
                  </div>

                     <div class="card-footer">
                      <button
                        class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        "
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>

                      <a
                        v-if="permision_role.method_delete"
                        class="
                          btn btn-danger
                          text-white
                          fw-bolder
                          btn-icon-text
                          float-end
                        "
                        @click="deleteData()"
                      >
                        <i class="link-icon" data-feather="trash"></i>
                        {{ $t("deleteMess") }}
                      </a>

                      <a
                        v-if="permision_role.method_update"
                        class="
                          btn btn-primary
                          me-2
                          text-white
                          fw-bolder
                          btn-icon-text
                          float-end
                        "
                        @click="editData()"
                      >
                        <i class="link-icon" data-feather="edit"></i>
                        {{ $t("editMess") }}
                      </a>
                    </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";

export default {
  name: "CustomerCTDetail",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      permision_role:[],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      forms: [],
      detailTruck:[],
      detailDriver:[],
      selectedIdsDriver:[],
      selectedIdsTruck:[],
    };
  },
  watch: {},
  methods: {

    updateDriverIsSelected(event, index) {
      const driverId = this.detailDriver[index].master_carrier_driver_id;
      if (event.target.checked) {
        // Checkbox checked, add driverId to selectedIds
        this.selectedIdsDriver.push(driverId);
      } else {
        // Checkbox unchecked, remove driverId from selectedIds
        const indexToRemove = this.selectedIdsDriver.indexOf(driverId);
        if (indexToRemove !== -1) {
          this.selectedIdsDriver.splice(indexToRemove, 1);
        }
      }
     
    },

    updateTruckIsSelected(event, index) {
      const truckId = this.detailTruck[index].master_carrier_truck_id;
      if (event.target.checked) {
        // Checkbox checked, add driverId to selectedIds
        this.selectedIdsTruck.push(truckId);
      } else {
        // Checkbox unchecked, remove driverId from selectedIds
        const indexToRemove = this.selectedIdsTruck.indexOf(truckId);
        if (indexToRemove !== -1) {
          this.selectedIdsTruck.splice(indexToRemove, 1);
        }
      }
      
    },


    sendToBlujayTruck(){
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
         
          const baseURI = this.$settings.endPointCt + "master-carrier/send-to-nintex";
          this.$http
            .post(baseURI,{
                  "ids": this.selectedIdsTruck, // array dari truck(master_carrier_truck_id) atau driver(master_carrier_driver_id)
                  "type": "truck", // driver atau truck
                  "master_carrier_id": this.forms.master_carrier_id 
            })
            .then((response) => {
              this.fade(false);
              this.isLoading = false;
              if (response.data.status === 200) {
                this.success(response.data.datas.success[0]);
                this.loadData(this.forms.master_carrier_id);
                this.isloading = false;
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {
                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.$router.push("/server-error");
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push("/page-not-found");
                  }
                }
              }
            });
        }
      });
    },

    sendToBlujayDriver(){
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
         
          const baseURI = this.$settings.endPointCt + "master-carrier/send-to-nintex";
          this.$http
            .post(baseURI,{
                  "ids": this.selectedIdsDriver, // array dari truck(master_carrier_truck_id) atau driver(master_carrier_driver_id)
                  "type": "driver", // driver atau truck
                  "master_carrier_id": this.forms.master_carrier_id 
            })
            .then((response) => {
              this.fade(false);
              this.isLoading = false;
              if (response.data.status === 200) {
                this.success(response.data.datas.success[0]);
                this.loadData(this.forms.master_carrier_id);
                this.isloading = false;
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {
                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.$router.push("/server-error");
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push("/page-not-found");
                  }
                }
              }
            });
        }
      });
    },

    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 45) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    uppercase() { 
        this.forms.location_reference_number  = this.forms.location_reference_number.toUpperCase();
    },
    removeSpace(event) {
       if(event.keyCode === 32){
        event.preventDefault();
      }
    },
    resetForm() {

    },

    cek_message(status, msg) {
      if (status == "200") {
        this.success(msg);
      }
    },

    editData() {
      var params = this.$route.params.id;
      window.location.href = "/master-carrier/edit/" + params;
    },

    addFieldTruck() {
      //fieldType.push({ value: "" });
      this.detailTruck.push({
    
        vehicle_no: "",
        truck_type: "",

      });
    },

    removeFieldTruck(index, fieldType) {
      fieldType.splice(index, 1);
    },


    addFieldDriver() {
      //fieldType.push({ value: "" });
      this.detailDriver.push({
    
        driver_number: "",
        driver_name: "",

      });
    },

    removeFieldDriver(index, fieldType) {
      fieldType.splice(index, 1);
    },


    deleteData() {
      var id = this.$onBehind(this.$route.params.id);
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI = this.$settings.endPointCt + "master-carrier/delete/" + id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                window.location.href = "/master-carrier";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },



    backForm() {
      window.location.href = "/master-carrier";
    },

    loadData(id) {
      const baseURI = this.$settings.endPointCt + "master-carrier/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        
        this.forms = response.data.datas;
        this.detailDriver=this.forms.driver
        this.detailTruck=this.forms.truck

 
      });
    },


    fetchIt() {
      const userDatas     = this.$getUserInfo();
      this.detailUser     = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function(permision){
        if(permision.permission.controller == 'MasterCarrierController'){
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if(!rolePermision.method_read){
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;

    },


    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.cek_message(this.$route.query.status, this.$route.query.msg);
    this.loadData(params[0]);
    this.fetchIt();
  },
};
</script>
<style scoped>
</style>