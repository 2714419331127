import TolRoute from "@/components/master/tol_route/TolRoute.vue";
import TolRouteAdd from "@/components/master/tol_route/TolRouteAdd.vue";
import TolRouteDetail from "@/components/master/tol_route/TolRouteDetail.vue";
import TolRouteEdit from "@/components/master/tol_route/TolRouteEdit.vue";
import TolRouteUpload from "@/components/master/tol_route/TolRouteUpload.vue";

let routesTolRoute= [
    
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "TolRouteIndex",
        component: TolRoute
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "TolRouteAdd",
        component: TolRouteAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "TolRouteEdit",
        component: TolRouteEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "TolRouteDetail",
        component: TolRouteDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "TolRouteUpload",
        component: TolRouteUpload
      },
    
]

export default routesTolRoute
  