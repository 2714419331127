<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading">
      {{ $t("loadingTxt") }}...
    </div>
    <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
      <sidebar-component classMenu="DashboardFullfilment"></sidebar-component>
      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div
                class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
              >
                <div class="col-lg-2 col-xl-3 col-sm-3 col-xs-3 col-md-3">
                  <h4 class="mb-3 mb-md-0">OMS Kanban</h4>
                </div>
                <div class="col-lg-10 col-xl-9 col-sm-9 col-xs-9 col-md-9">
                  <div class="d-flex flex-wrap">
                    <div
                      class="input-group flatpickr wd-250 me-2 mb-2 mb-md-0"
                      id="dashboardDate"
                    >
                      <date-range-picker
                        style="width: 100%"
                        @change="loadData"
                        control-container-class="form-control"
                        :locale-data="locale"
                        v-model="dateFilter"
                        :opens="opens"
                        :singleDatePicker="false"
                        :timePicker24Hour="true"
                        :timePicker="true"
                        :showWeekNumbers="false"
                      >
                      </date-range-picker>
                    </div>


                    <div class="form-check form-check-inline me-2 mb-2 mt-2 mb-md-0">
                        <input 
                          class="form-check-input" 
                          type="radio" 
                          id="user" 
                          value="user" 
                          v-model="by"
                          @change="loadData"
                        >
                        <label class="form-check-label" for="assignBy">User</label>
                      </div>

                      <div class="form-check form-check-inline me-2 mt-2 mb-2 mb-md-0">
                        <input 
                          class="form-check-input" 
                          type="radio" 
                          id="all" 
                          value="all" 
                          v-model="by"
                          @change="loadData"
                        >
                        <label class="form-check-label" for="acceptBy">All</label>
                      </div>

                    <button
                      type="button"
                      style="max-height: 40px"
                      @click.prevent="loadData"
                      class="btn btn-primary btn-icon-text mb-2 mb-md-0 btn-block btn-floating"
                    >
                      GO
                    </button>
                    <!-- <span style="width: 5px"> </span>
                    <button
                      class="btn btn-success btn-icon-text btn-block"
                      style="margin-right: 5px; max-height: 40px"
                      @click.prevent="downloadData()"
                    >
                      Download
                    </button>
                    <button
                      class="btn btn-secondary btn-icon-text btn-block"
                      style="margin-right: 5px; max-height: 40px"
                      @click.prevent="downloadDataDetail()"
                    >
                      Download Detail Fulfillment
                    </button> -->
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-12" style="height: 500px">
                      <Bar
                        :chart-options="chartOptions"
                        :chart-data="chartDataLine"
                        :chart-id="chartId"
                        :dataset-id-key="datasetIdKey"
                        :plugins="plugins"
                        :css-classes="cssClasses"
                        :styles="myStyles"
                        :width="width"
                        :height="height"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
    <modal-fullfilment-detail
      :showModal="showModalDetail"
      :data="detailData"
      :by="by"
      @closeModal="closeMyModal"
    ></modal-fullfilment-detail>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import DateRangePicker from "vue2-daterange-picker";
import modalFullfilmentKanbanDetail from "@/components/modal/modalFulfillmentKanbanDetail.vue";

import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

import moment from "moment";

export default {
  props: {
    chartId: {
      type: String,
      default: "bar",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 200,
    },
  },
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    DateRangePicker,
    "modal-fullfilment-detail": modalFullfilmentKanbanDetail,

    Bar,
  },
  data() {
    const startDate = moment().subtract(1,"month").format("YYYY-MM-DD");
    const endDate = moment().format("YYYY-MM-DD");
    return {
      chartOptions: {
        scales: {
          y: {
            stacked: true,
            ticks: {
              beginAtZero: true,
              min: 0,
              stepSize: 5,
            },
            gridLines: {
              display: true,
            },
          },
          x: {
            stacked: true,
            gridLines: {
              display: false,
            },
          },
        },
        legend: {
          display: true,
          position: "left",
        },
        responsive: true,
        maintainAspectRatio: false,
        onClick: (c, i) => {
          var labelDate = c.chart.config._config.data.labels[i[0].index];

          var label = this.chartDataLine.datasets[i[0].datasetIndex].label;
          this.clickDetailModal(label, labelDate);
        },
      },
      isLoading: false,
      chartDataLine: {
        labels: [],
        datasets: [],
      },
      label: {},
      data: [],
      dataSets: [],
      dateFilter: {
        startDate,
        endDate,
      },
      locale: {
        format: "yyyy-mm-dd",
      },
      opens: "center",
      showModalDetail: false,
      detailData: {},
      by:"all",
      username:"",
    };
  },
  computed: {
    myStyles() {
      return {
        height: "100%",
        position: "relative",
      };
    },
  },
  methods: {
    loadData() {
      // console.log("Triggered");
      let dataSend = {
        start: moment(String(this.dateFilter.startDate)).format("YYYY-MM-DD"),
        end: moment(String(this.dateFilter.endDate)).format("YYYY-MM-DD"),
      };

      // let urlAdd="";
      // if(this.by=='accept_by'){
      //   urlAdd="&accept_by="+this.username;
      // }

      // if(this.by=='assign_by'){
      //   urlAdd="&assign_by="+this.username;
      // }
      const baseURI =
        this.$settings.endPoint +
        "control-tower/transport-order-header/kanban-order?start=" +
        dataSend.start +
        "&end=" +
        dataSend.end+'&by='+this.by
      this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          this.isLoading = false;
          if (response.status == 200) {
            var labelstemp = Object.keys(response.data.datas);
            delete labelstemp[labelstemp.length - 1];
            this.dataSets = [];
            labelstemp.forEach((datas) => {

              var data = {
                label: datas.toUpperCase().replace("_", " ").toUpperCase().replace("_", " "),
                // borderColor: this.generateColor(datas),
                pointBackgroundColor: "white",
                pointBorderColor: "white",
                borderWidth: 1,
                // backgroundColor: this.getRandomColorHexRgbA(),
                backgroundColor: this.generateColor(datas),
                data: response.data.datas[datas],
              };
              console.log(datas);
              if(this.by=='user'){
                if(datas!='submitted' && datas!="new"){
                  this.dataSets.push(data);
                }

              }else{
                this.dataSets.push(data);
              }
             
             
            });
            console.log(this.dataSets);
            this.labels = response.data.datas["date"];
            this.chartDataLine = {
              labels: this.labels,
              datasets: this.dataSets,
            };

            console.log(this.dataSets);
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error("Please Check Your Internet Connection");
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                //this.$router.push("/page-not-found");
              }
            }
          }
        });
    },
    clickDetailModal(label, labelDate) {
      this.showModalDetail = true;
      this.detailData = {
        order_status: label,
        pick_plan: labelDate,
      };
    },
    generateColor(datas) {
      if (datas == "new") {
        return "#e74c3c";
      } else if (datas == "accepted") {
        return "#95a5a6";
      } else if (datas == "submitted") {
        return "#e67e22";
      } else if (datas == "assigned") {
        return "#f1c40f";
      } else if (datas == "completed") {
        return "#2ecc71";
      } else if (datas == "blujay_accepted") {
        return "#2980b9";
      } else {
        return "#" + Math.floor(Math.random() * 16777215).toString(16);
      }
    },
    getRandomColorHexRgbA() {
      var r = Math.floor(Math.random() * 255);
      var g = Math.floor(Math.random() * 255);
      var b = Math.floor(Math.random() * 255);
      return "rgba(" + r + "," + g + "," + b + ",0.2)";
    },
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
    
      this.username=this.detailUser.name;
      console.log(this.username);
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "TransportOrderHeaderCtController") {
          rolePermision = permision;
        }
      });

      // if (!rolePermision.method_custom1) {
      //   this.$router.push("/authorized-error");
      // }

      this.permision_role = rolePermision;
    },
    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
    closeMyModal() {
      this.showModalDetail = false;
    },
  },
  mounted() {
    this.fetchIt();
    this.loadData();
    setInterval(() => {
      this.loadData();
    }, 300000);
  },
};
</script>

<style>
</style>