var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"main-wrapper"},[_c('sidebar-component',{attrs:{"classMenu":"newRate"}}),_c('div',{staticClass:"page-wrapper"},[_c('navbar-component'),_c('div',{staticClass:"page-content"},[_c('div',{staticClass:"col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12 pe-2"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"float-start",staticStyle:{"margin-right":"5px"}},[_vm._v(" Stop Location ")]),(_vm.permision_role.method_read)?_c('button',{staticClass:"btn btn-success float-end btn-xs",staticStyle:{"margin-right":"5px"},on:{"click":function($event){$event.preventDefault();return _vm.createData()}}},[_c('i',{staticClass:"link-icon",attrs:{"data-feather":"plus"}}),_vm._v(" "+_vm._s(_vm.$t("createNew"))+" ")]):_vm._e(),_c('button',{staticClass:"btn btn-warning float-end btn-xs",staticStyle:{"margin-right":"5px"},on:{"click":function($event){$event.preventDefault();return _vm.downloadData()}}},[_c('i',{staticClass:"link-icon",attrs:{"data-feather":"download"}}),_vm._v(" "+_vm._s(_vm.$t("downloadData"))+" .xlsx ")])]),_c('div',{staticClass:"card-body"},[_c('vue-good-table',{attrs:{"title":"list-user","mode":"remote","select-options":{
                  enabled: true,
                  selectOnCheckboxOnly: true,
                  selectionText: _vm.$t('data_selected'),
                  clearSelectionText: _vm.$t('clear'),
                },"totalRows":_vm.totalRecords,"line-numbers":true,"isLoading":_vm.isLoading,"pagination-options":{
  enabled: true,
},"rows":_vm.rows,"columns":_vm.columns},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[(_vm.permision_role.method_read)?_c('a',{attrs:{"href":"#","title":"Detail"},on:{"click":function($event){$event.preventDefault();return _vm.detailData(props.index, props.row)}}},[_c('i',{staticClass:"mdi mdi-eye",staticStyle:{"font-size":"16px"}})]):_vm._e(),(_vm.permision_role.method_update)?_c('a',{attrs:{"href":"#","title":"Edit"},on:{"click":function($event){$event.preventDefault();return _vm.editData(props.index, props.row)}}},[_c('i',{staticClass:"mdi mdi-square-edit-outline",staticStyle:{"font-size":"16px"}})]):_vm._e(),(_vm.permision_role.method_delete)?_c('a',{attrs:{"href":"#","title":"Delete"},on:{"click":function($event){$event.preventDefault();return _vm.deleteData(props.index, props.row)}}},[_c('i',{staticClass:"mdi mdi-trash-can-outline",staticStyle:{"font-size":"16px"}})]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_vm._v(" "+_vm._s(_vm.$t("no_select_data"))+" ")]),_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[_c('button',{staticClass:"btn btn-success btn-xs",staticStyle:{"margin-right":"20px"},on:{"click":_vm.sendOrder}},[_c('i',{staticClass:"mdi mdi-send"}),_vm._v(" Send To Blujay ")]),_c('button',{staticClass:"btn btn-success btn-xs",staticStyle:{"margin-right":"20px"},on:{"click":_vm.sendOrder}},[_c('i',{staticClass:"mdi mdi-check-outline"}),_vm._v(" Enable ")]),_c('button',{staticClass:"btn btn-danger btn-xs",on:{"click":function($event){return _vm.cancelOrder('cancel_from_costumer')}}},[_c('i',{staticClass:"mdi mdi-close-octagon"}),_vm._v(" Disable ")])])])],1)])])]),_c('footer-component')],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }