<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading"> {{ $t('loadingTxt') }}...</div>
    <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TransportOrder"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->

        
        <div class="page-content">
          <div class="col-lg-12 col-xl-12 stretch-card">
            <div class="card">
              <div class="card-header">
                <h4 style="margin-right: 5px" class="float-start">Transport Order Multiple</h4>
                <button v-if="permision_role.method_create" class="btn btn-primary float-end btn-xs"
                  style="margin-right: 5px" @click.prevent="createData()">
                  <i class="link-icon" data-feather="plus"></i>
                  {{ $t("createNew") }}
                </button>

                <!-- <button class="btn btn-warning float-end btn-xs" style="margin-right: 5px"
                  @click.prevent="uploadData()">
                  <i class="link-icon" data-feather="upload"></i>
                  {{ $t("uploadData") }} .xlsx
                </button> -->


                <button v-if="permision_role.method_read" class="btn btn-success float-end btn-xs"
                  style="margin-right: 5px" @click.prevent="downloadData()">
                  <i class="link-icon" data-feather="download"></i>
                  {{ $t("downloadData") }} .xlsx
                </button>
              </div>

              <div class="card-body">
                <!--div class="vgt-selection-info-row-active">
                  <div v-if="this.transport_order_header_ids.length > 0" class="vgt-selection-info-row clearfix">
                    {{  this.transport_order_header_ids.length  }} {{  $t('data_selected')  }}
                    <a @click.prevent="removeOrderHeaderIds()" style="color:red;">
                      {{  $t('clear')  }}
                    </a>
                    <div class="vgt-selection-info-row__actions vgt-pull-right">
                      <button class="btn btn-success btn-xs" style="margin-right:20px;" @click="sendOrder"> <i
                          class="mdi mdi-send-outline"></i> {{  this.$t('send_order_txt')  }} </button>

                      <button class="btn btn-danger btn-xs" @click="cancelOrder"><i class="mdi mdi-close"></i>
                        {{  this.$t('cancel_order_txt')  }} </button>
                    </div>
                  </div>

                </div-->
                <!-- , disableSelectInfo: true, -->
                <vue-good-table title="list-transport-orders" ref="list-transport-orders" mode="remote" :select-options="{
                  enabled: true,
                  selectAllByGroup:true,
                  selectOnCheckboxOnly: true,
                  selectionText: $t('data_selected'),
                  clearSelectionText: $t('clear'),
                }" @on-selected-rows-change="selectionChanged" @on-page-change="onPageChange"
                  @on-sort-change="onSortChange" @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :line-numbers="true"
                  :isLoading.sync="isLoading" :pagination-options="{
                    enabled: true,
                  }" :rows="rows" :columns="columns" :group-options="{
                    enabled: true,
                    collapsable: true
                  }">
                  <div slot="table-actions">
                    {{ $t("no_select_data") }}
                  </div>
                  <div slot="selected-row-actions">
                    <button class="btn btn-success btn-xs" style="margin-right:20px;" @click="sendOrder"> <i
                        class="mdi mdi-send-outline"></i> {{ $t("send_order_txt") }}</button>

                    <button class="btn btn-warning btn-xs" style="margin-right:20px;" @click="cancelOrder('void')"><i class="mdi mdi-close"></i> {{
                        $t("cancel_order_txt")
                    }} </button>


                    <button class="btn btn-danger btn-xs" @click="cancelOrder('cancel_from_costumer')"><i class="mdi mdi-close"></i> {{
                        $t("cancel_order_costumer_txt")
                    }} </button>
                  </div>

                  <!-- <template slot="column-filter" slot-scope="props">
                      <span v-if="props.filterOptions.customFilter">TES</span>
                    </template>
                    -->
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'download'">
                        <a href="#" v-if="permision_role.method_read" title="download" @click.prevent="
                        downloadDataFile(props.index, props.row)
                      ">
                        <i class="mdi mdi-download" style="font-size:16px"></i>

                      </a>
                      </span>
                    
                    </template>
                  <template slot="table-header-row" slot-scope="props">

                    <span v-if="props.column.field == 'actions'">
                      <a href="#" v-if="permision_role.method_read" title="Detail" @click.prevent="
                        detailData(props.index, props.row)
                      ">
                        <i class="mdi mdi-eye" style="font-size:16px"></i>

                      </a>


                      <a href="#" v-if="permision_role.method_update && props.row.order_status == 'NEW' " title="Edit"
                        @click.prevent="editData(props.index, props.row)">
                        <i class="mdi mdi-square-edit-outline" style="font-size:16px"></i>

                      </a>

                      <a href="#" v-if="permision_role.method_delete && props.row.order_status == 'NEW'" title="Delete"
                        @click.prevent="
                          deleteData(props.index, props.row)
                        ">
                        <i class="mdi mdi-trash-can-outline" style="font-size:16px"></i>
                      </a>
                    </span>
                    <span v-else-if="props.column.field == 'order_status'">                      
                      {{ orderStatus(props.row.order_status)}}
                    </span>
                    <span v-else-if="props.column.field == 'request'">
                      <!-- && props.row.load_id -->
                      <!-- <a href="#" v-if="!props.row.load_id && (props.row.order_status == 'SUBMITTED' || props.row.order_status == 'ASSIGNED')" title="Reschedule" @click.prevent="
                        requestRescheduleder(props.row)
                      "><i class="mdi mdi-calendar-clock" style="font-size:16px"></i>
                      </a> -->

                      <!-- <a href="#" v-if="!props.row.load_id && (props.row.order_status == 'SUBMITTED' || props.row.order_status == 'ASSIGNED')" title="Reroute"
                        @click.prevent="requestReroute(props.row)">
                        <i class="mdi mdi-google-maps" style="font-size:16px"></i>
                      </a> -->

                      <a href="#" v-if="!props.row.load_id && props.row.order_status == 'RESCHEDULE - WAITING APPROVAL'" title="Approval Request"
                        @click.prevent="showApproval(props.row)">
                        <i class="mdi mdi-alarm-light" style="font-size:16px"></i>
                      </a>

                    </span>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
        <!-- Cancel data-->

        <modal-cancel-order 
          :showModal="showModalCancelOrder" 
          :data="this.transport_order_header_ids"
          :status="this.status_order"
          @closeModal="closeMyModal">
        </modal-cancel-order>

        <modal-request-reschedule 
          :showModal="showModalRequestReschedule" 
          :data="passData"
          @closeModal="closeMyModal">
        </modal-request-reschedule>

        <modal-request-reroute 
          :showModal="showModalRequestReroute" 
          :data="passData"
          @closeModal="closeMyModal">
        </modal-request-reroute>

        <modal-approval-reschedule  
          :showModal="showModalReschedule"
          :data="passData"
          @closeModal="closeMyModal"
        ></modal-approval-reschedule >
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import moment from 'moment';
import ModalTransportOrderCancelOrder from "@/components/modal/modalTransportOrderCancelOrder.vue";
import ModalRequestReroute from "@/components/modal/modalRequestReroute.vue";
import ModalRequestReschedule from "@/components/modal/modalRequestReschedule.vue";
import ModalApprovalReschedule from "@/components/modal/modalApprovalReschedule.vue";
    
export default {
  name: "TransportOrder",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "modal-cancel-order": ModalTransportOrderCancelOrder,
    "modal-request-reroute": ModalRequestReroute,
    "modal-request-reschedule": ModalRequestReschedule,
    "modal-approval-reschedule" : ModalApprovalReschedule,
  },
  data() {
    return {
      passData:[],
      status_order:'',
      showModalCancelOrder:false,
      showModalRequestReschedule:false,
      showModalRequestReroute:false,
      showModalReschedule:false,
      moment: moment,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      permision_role: [],
      transport_order_header_ids: [],
      transport_order_header_status: [],
      langs: ["id", "en"],
      detailUser: [],
      detailDivisi: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [
        {
          label: "Company",
          field: "company_name",
          width: '200px',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Company", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Order No",
          field: "order_no",
          width: '200px',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Order No", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "ShipWithRef",
          field: "shipwith_ref",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Order Date",
          field: "order_date",
          width: '200px',
          thClass: 'text-left',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd MMM yyyy h:mm:ss a',//'dd MMM yyyy',
          filterOptions: {
            customFilter: true,
            enabled: true, // enable filter for this column
            placeholder: "Filter By Order Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Order Status",
          field: "order_status",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Order Status", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: ["NEW","SUBMITTED","ASSIGNED","CANCELLED",
            // "RESCHEDULE - NEED APPROVAL","RESCHEDULE - WAITING APPROVAL","RESCHEDULE - APPROVED",
            // "REROUTE - NEED APPROVAL","REROUTE - WAITING APPROVAL","REROUTE - APPROVED",
            "REJECTED","COMPLETED"
          ], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Pick Plan",
          field: "pick_plan",
          thClass: 'text-left',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd MMM yyyy h:mm:ss a',//'dd MMM yyyy',
          filterOptions: {
            customFilter: true,
            enabled: true, // enable filter for this column
            placeholder: "Filter By Pick Plan", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Pick Name",
          field: "pick_name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Direction", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Dest Plan",
          field: "dest_plan",
          thClass: 'text-left',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd MMM yyyy h:mm:ss a',//'dd MMM yyyy',
          filterOptions: {
            customFilter: true,
            enabled: true, // enable filter for this column
            placeholder: "Filter By Dest Plan", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        
        {
          label: "Dest Name",
          field: "dest_name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Dest Name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: "Status TMS",
          field: "status_tms",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Dest Name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Remarks",
          field: "remarks",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Action",
          field: "actions",
          sortable: false,
        },

        {
          label: "Request",
          field: "request",
          sortable: false,
        },

        {
          label: "Download",
          field: "download",
          sortable: false,
        },
      ],
      rows: [],
      rowsSelection: [],
    };
  },
  watch: {},
  methods: {
    orderStatus(value){
      if(value === "RESCHEDULE - WAITING APPROVAL"){
        return "RESCHEDULE - NEED APPROVAL";
      }else if(value === "RESCHEDULE - NEED APPROVAL"){
        return "RESCHEDULE - WAITING APPROVAL";
      }else if(value === "REROUTE - NEED APPROVAL"){
        return "REROUTE - WAITING APPROVAL";
      }else{
        return value;
      }
    },

    closeMyModal() {
      this.showModalCancelOrder = false;
      this.showModalRequestReschedule = false;
      this.showModalRequestReroute = false;
      this.showModalReschedule = false;
      this.loadItems();
    },

    requestRescheduleder(row){
      this.passData = row;
      this.passData.source = "MULTI";
      this.showModalRequestReschedule = true;
    },

    requestReroute(row){
      this.passData = row;
      this.showModalRequestReroute = true;
    },

    showApproval(row){
       this.showModalReschedule = true;
       this.passData = row;
       this.passData.source = "MULTI";
    },

    cancelOrder(status){
      this.status_order=status;
      this.showModalCancelOrder = true;
    },

    removeOrderHeaderIds() {
      this.transport_order_header_ids = [];
      this.transport_order_header_status = [];
      this.$refs['list-transport-orders'].unselectAllInternal()
      // console.log(this.$refs['list-transport-orders'].selectedRows)
    },

    selectionChanged(ev) {
  
      this.rowsSelection = [];
      this.transport_order_header_ids = [];
      this.transport_order_header_status = [];
      this.rowsSelection = ev.selectedRows;

      this.rowsSelection.forEach((item) => {
        this.transport_order_header_ids.push(item.transport_order_header_id);
        this.transport_order_header_status.push(item.order_status);
        // if(item.order_status=='NEW'){
        //   this.error('Order No:'+item.order_status+' Status Not Submited, because status order not NEW or SUBMITED');
        // }else{

        // }

      });

    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == 'TransportOrderHeaderController') {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;

    },


    sendOrder() {
      let ask = this.transport_order_header_status.includes('REJECTED')?this.$t("rejectResubmit"):this.$t("yourDataSend");
      this.$swal({
        title: this.$t("areYouSure"),
        text: ask,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          //this.loadingSubmiting();
          this.loading();
          let data = {
            transport_order_header_ids: this.transport_order_header_ids
          }
          const baseURI =
            this.$settings.endPoint + "transport-order-header-multiple/send-order";
          this.$http
            .post(baseURI, data)
            .then((response) => {
              //this.loading();
              if (response.data.status === 200) {
                // response.data.datas.messages.forEach((item) => {
                //   this.success(item);
                // });
                this.success2(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {

                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },


    cancelOrderOld() {

      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataCancel"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          this.loadingSubmiting();
          let data = {
            transport_order_header_ids: this.transport_order_header_ids
          }
          const baseURI =
            this.$settings.endPoint + "transport-order-header-multiple/send-order-cancel";
          this.$http
            .post(baseURI, data)
            .then((response) => {
              //this.loading();
              if (response.data.status === 200) {
                // response.data.datas.messages.forEach((item) => {
                //   this.success(item);
                // });
                this.success2(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {

                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    cancelSubmit() {

      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataCancel"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          this.loadingSubmiting();
          let data = {
            transport_order_header_ids: this.transport_order_header_ids
          }
          const baseURI =
            this.$settings.endPoint + "transport-order-header-multiple/cancel-submit";
          this.$http
            .post(baseURI, data)
            .then((response) => {
              //this.loading();
              if (response.data.status === 200) {
                // response.data.datas.messages.forEach((item) => {
                //   this.success(item);
                // });
                this.success2(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {

                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    updateFormatDate(date) {
      let date_format = this.$moment(date).format("dd mmm yyyy");
      return date_format;
    },

    downloadData() {
      const baseURI = this.$settings.endPoint + "/transport-order-header-multiple";
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var date_order = '';
      if(this.serverParams.columnFilters.order_date !== ''){
        date_order = this.$moment(this.serverParams.columnFilters.order_date).format("YYYY-MM-DD");
      }
      var file_name = "download_" + CurrentDate + ".xlsx";

      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&company_name=${this.serverParams.columnFilters.company_name}&shipwith_ref=${this.serverParams.columnFilters.shipwith_ref}&order_status=${this.serverParams.columnFilters.order_status}&order_no=${this.serverParams.columnFilters.order_no}&status_tms=${this.serverParams.columnFilters.status_tms}&dest_name=${this.serverParams.columnFilters.dest_name}&pick_name=${this.serverParams.columnFilters.pick_name}&order_date=${date_order}&download=download`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {

          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },

    // downloadData() {
    //   this.fade(true);
    //   var baseURI = this.$settings.endPoint + "/user/download";
    //   var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
    //   var columnFilters = this.serverParams.columnFilters;
    //   var role = columnFilters["role.name"];
    //   var company_code = columnFilters["company_detail.code"];

    //   var sendData = {
    //     username: this.serverParams.columnFilters.username,
    //     name: this.serverParams.columnFilters.name,
    //     email: this.serverParams.columnFilters.email,
    //     level: this.serverParams.columnFilters.level,
    //     status: this.serverParams.columnFilters.status,
    //     role:role,
    //     company_code: company_code,
    //     file_name: "download_" + CurrentDate + ".xlsx",
    //   };
    //   this.$http({
    //     url: baseURI,
    //     method: "GET",
    //     data: sendData,
    //     responseType: "blob",
    //   })
    //     .then((response) => {
    //       this.errors = [];
    //       var filename = sendData.file_name;

    //       var fileURL = window.URL.createObjectURL(new Blob([response.data]));
    //       var fileLink = document.createElement("a");

    //       fileLink.href = fileURL;
    //       fileLink.setAttribute("download", filename);
    //       document.body.appendChild(fileLink);
    //       fileLink.click();
    //       this.fade(false);
    //     })
    //     .catch((error) => {
    //       if (error.response) {
    //         if (error.response.status === 422) {
    //           this.errors = { message: ["File Not Found"] };
    //           this.error("File Not Found");
    //         } else if (error.response.status === 500) {
    //           this.$router.push("/server-error");
    //         } else {
    //           this.$router.push("/page-not-found");
    //         }
    //       }
    //     });
    // },
    createData() {
      window.location.href = "/order/transport-order-multi/add";
    },

    uploadData() {
      window.location.href = "/order/transport-order-multi/upload";
    },

    editData(index, row) {
      var params = this.$onRandom(row.transport_order_header_id);
      window.location.href = "/order/transport-order-multi/edit/" + params;
    },

    detailData(index, row) {
      var params = this.$onRandom(row.transport_order_header_id);
      window.location.href = "/order/transport-order-multi/detail/" + params;
    },

    downloadDataFile(index, row) {
      var transport_order_header_detail_id=row.transport_order_header_detail_id
      const baseURI = this.$settings.endPoint + "transport-order-header-multiple/download-file/"+transport_order_header_detail_id;
      
      return this.$http
        .get(
          baseURI,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          // console.log(response);
          var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          var file_name = "download_" + CurrentDate + ".pdf";
          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        }).catch((error) => {
            //  console.log(error.response);
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.errorMsg('File Not Found');
                } else {
                  this.errorMsg(error.response.data.errors);
                }
              }
            });
  
    },

    // updateFilters(col,value) {

    // },

    deleteData(index, row, status) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI =
            this.$settings.endPoint + "transport-order-header-multiple/delete/" + row.transport_order_header_id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    // load items is what brings back the rows from server
    loadItems() {
      const baseURI = this.$settings.endPoint + "transport-order-header-multiple";
      //var columnFilters = this.serverParams.columnFilters;
      var date_order = '';
      if (this.serverParams.columnFilters.order_date != '') {
        date_order = this.$moment(this.serverParams.columnFilters.order_date).format("YYYY-MM-DD");
      }
      let orderstatus = this.orderStatus(this.serverParams.columnFilters.order_status);

      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&company_name=${this.serverParams.columnFilters.company_name}&order_status=${orderstatus}&shipwith_ref=${this.serverParams.columnFilters.shipwith_ref}&order_no=${this.serverParams.columnFilters.order_no}&status_tms=${this.serverParams.columnFilters.status_tms}&dest_name=${this.serverParams.columnFilters.dest_name}&pick_name=${this.serverParams.columnFilters.pick_name}&order_date=${date_order}`,
          { timeout: 10000 }
        )
        .then((response) => {

          var data = response.data.datas.data;

          var rowsData=[];
          for (let index = 0; index < data.length; index++) {
           
            data[index]['children']=data[index]['transport_order_detail_chain'];
            // data[index]['children'].push(data[index][0]);
           
            
            

            rowsData.push(data[index]);
            // data[index]['children'].push({
            //   shipwith_ref:data[index]['shipwith_ref']
            // });
          }
          this.rows=rowsData;
         
          // let i = 0;
          // for (i = 0; i < 10; i++) {
          //   if (this.rows[i].order_status !== "NEW" || this.rows[i].order_status !== "SUBMITTED") {
          //     this.$set(this.rows[i], 'vgtDisabled', true);
          //     this.$set(this.rows[i], 'vgtSelected', false);
          //   }
          // }
          this.totalRecords = response.data.datas.total;

        }).catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push('/authorized-error');
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

        onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    errorMsg(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    success2(msg) {
      let pesan = "";
      msg.forEach((item) => {
        pesan = pesan + "<p>* " + item + "</p>";
      });

      this.$swal({
        icon: 'info',
        backdrop: true,
        title: "Information",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      })


    },


    loadingSubmiting() {


      this.$swal({
        icon: 'warning',
        backdrop: true,
        title: "Please wait",
        html: '<h2>SUBMITTING ORDER...</h2>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

    },


    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },


    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },


  },
  events: {},
  created: function () { },
  mounted() {
    this.fetchIt();
    this.loading();



  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}


.vgt-table.bordered th.vgt-row-header{
  font-size: 12px !important;
}

.blur-content {
  filter: blur(5px);
}

.card-body {
  padding: 5px 1.5rem 1rem;
}

.main-wrapper .page-wrapper .page-content {
  margin-top: 40px;
}

.vgt-table th {
  text-align: left;
}
</style>