<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="AlertSetting"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            "
          >
            <div>
              <h4 class="mb-12 mb-md-0"></h4>
            </div>

            <div class="col-lg-12 col-xl-12 stretch-card">
              <div class="card">
                <div class="card-header">
                  <h4 style="margin-right: 5px" class="float-start">
                    Alert Setting
                  </h4>
                </div>

                 <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-6">
                        <h5>Trip Alert</h5>
                        <hr />

                        <div class="row mb-3">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-4 col-form-label"
                            >Alert When Trip Is Delay >
                          </label>
                          <div class="col-sm-2">
                            <input
                              type="number"
                              class="form-control"
                              id="exampleInputUsername2"
                              v-model="forms.is_delay"
                            />
                          </div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-1 col-form-label"
                            >Or
                          </label>
                        </div>
                        

                        <div class="row mb-3">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-4 col-form-label"
                            >Alert When Truck Speed Is >
                          </label>
                          <div class="col-sm-2">
                            <input
                              type="number"
                              class="form-control"
                              id="exampleInputUsername2"
                              v-model="forms.speed"
                            />
                          </div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-1 col-form-label"
                            >for
                          </label>
                          <div class="col-sm-2">
                            <input
                              type="number"
                              class="form-control"
                              id="exampleInputUsername2"
                              v-model="forms.speed_for_minutes"
                            />
                          </div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-3 col-form-label"
                            >Minutes
                          </label>
                        </div>

                        <div class="row mb-3">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-4 col-form-label"
                            >Alert When DWH 
                          </label>
                          <div class="col-sm-2">
                            <input
                              type="number"
                              class="form-control"
                              id="exampleInputUsername2"
                              v-model="forms.dwh"
                            />
                          </div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-2 col-form-label"
                            >Hours
                          </label>                          
                        </div>

                        <h5>Document Alert</h5>
                        <hr />

                        <div class="row mb-3">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-2 col-form-label"
                            >Alert When
                          </label>
                          <div class="col-sm-2">
                            <select class="form-select">
                              <option>STNK</option>
                            </select>
                          </div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-3 col-form-label"
                            >Is About Expired In</label
                          >
                          <div class="col-sm-2">
                            <input
                              type="number"
                              class="form-control"
                              id="exampleInputUsername2"
                              v-model="forms.stnk_expired_in"
                            />
                          </div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-2 col-form-label"
                            >Days</label
                          >
                        </div>

                        <div class="row mb-3">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-2 col-form-label"
                            >Grid Color
                          </label>
                          <div class="col-sm-2">
                            <v-swatches v-model="forms.stnk_grid_color"></v-swatches>
                          </div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-3 col-form-label"
                            >Dokumen Expired
                          </label>
                          <div class="col-sm-2">
                            <input
                              type="number"
                              class="form-control"
                              id="exampleInputUsername2"
                              v-model="forms.stnk_expired"
                            />
                          </div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-2 col-form-label"
                            >Days</label
                          >
                        </div>

                        <div class="row mb-3">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-2 col-form-label"
                          >
                          </label>
                          <div class="col-sm-2"></div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-3 col-form-label"
                            >Dokumen Is Expired
                          </label>
                          <div class="col-sm-2"> <v-swatches v-model="forms.stnk_color_expired" ></v-swatches></div>
                        </div>

                        <div class="row mb-3">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-2 col-form-label"
                            >Alert When
                          </label>
                          <div class="col-sm-2">
                            <select class="form-select">
                              <option>KIR</option>
                            </select>
                          </div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-3 col-form-label"
                            >Is About Expired In</label
                          >
                          <div class="col-sm-2">
                            <input
                              type="number"
                              class="form-control"
                              id="exampleInputUsername2"
                              v-model="forms.kir_expired_in"
                            />
                          </div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-2 col-form-label"
                            >Days</label
                          >
                        </div>

                        <div class="row mb-3">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-2 col-form-label"
                            >Grid Color
                          </label>
                          <div class="col-sm-2"> <v-swatches v-model="forms.kir_grid_color"></v-swatches></div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-3 col-form-label"
                            >Dokumen Expired
                          </label>
                          <div class="col-sm-2">
                            <input
                              type="number"
                              class="form-control"
                              id="exampleInputUsername2"
                              v-model="forms.kir_expired"
                            />
                          </div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-2 col-form-label"
                            >Days</label
                          >
                        </div>

                        <div class="row mb-3">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-2 col-form-label"
                          >
                          </label>
                          <div class="col-sm-2"></div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-3 col-form-label"
                            >Dokumen Is Expired
                          </label>
                          <div class="col-sm-2"> <v-swatches v-model="forms.kir_color_expired"></v-swatches></div>
                        </div>

                        <div class="row mb-3">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-3 col-form-label"
                            >Alert When Driver Have
                          </label>
                          <div class="col-sm-2">
                            <input
                              type="number"
                              class="form-control"
                              id="exampleInputUsername2"
                              v-model="forms.outstanding_document"
                            />
                          </div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-4 col-form-label"
                            >Out Standing Document / POD</label
                          >
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <h5>Service Alert</h5>
                        <hr />

                        <div class="row mb-3">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-4 col-form-label"
                            >Alert When Truck Km Is reach
                          </label>
                          <div class="col-sm-2">
                            <input
                              class="form-control"
                              type="number"
                              id="exampleInputUsername2"
                              v-model="forms.service_persentage_km_max"
                            />
                          </div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-3 col-form-label"
                            >% of Max KM</label
                          >
                        </div>

                        <div class="row mb-3">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-2 col-form-label"
                            >Grid Color
                          </label>
                          <div class="col-sm-2"> <v-swatches v-model="forms.service_persentage_km_grid_color"></v-swatches></div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-2 col-form-label"
                            >Truck Km >
                          </label>
                          <div class="col-sm-2">
                            <input
                              type="number"
                              class="form-control"
                              id="exampleInputUsername2"
                              v-model="forms.service_persentage_km_max_start"
                            />
                          </div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-1 col-form-label"
                            >%
                          </label>

                          <div class="col-sm-2">
                            <input
                              type="number"
                              class="form-control"
                              id="exampleInputUsername2"
                              v-model="forms.service_persentage_km_max_end"
                            />
                          </div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-1 col-form-label"
                            >%
                          </label>
                        </div>

                        <div class="row mb-3">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-2 col-form-label"
                          >
                          </label>
                          <div class="col-sm-2"> <v-swatches v-model="forms.service_percentage_km_over_color"></v-swatches></div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-3 col-form-label"
                            >Truck Km > Max Hm</label
                          >
                        </div>

                        <div class="row mb-3">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-4 col-form-label"
                            >Alert When Truck Hm Is reach
                          </label>
                          <div class="col-sm-2">
                            <input
                              class="form-control"
                              type="number"
                              id="exampleInputUsername2"
                              v-model="forms.service_persentage_hm_max"
                            />
                          </div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-3 col-form-label"
                            >% of Max HM</label
                          >
                        </div>

                        <div class="row mb-3">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-2 col-form-label"
                            >Grid Color
                          </label>
                          <div class="col-sm-2"> <v-swatches v-model="forms.service_persentage_hm_grid_color"></v-swatches></div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-2 col-form-label"
                            >Truck Hm >
                          </label>
                          <div class="col-sm-2">
                            <input
                              type="number"
                              class="form-control"
                              id="exampleInputUsername2"
                              v-model="forms.service_persentage_hm_max_start"
                            />
                          </div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-1 col-form-label"
                            >%
                          </label>

                          <div class="col-sm-2">
                            <input
                              type="number"
                              class="form-control"
                              id="exampleInputUsername2"
                              v-model="forms.service_persentage_hm_max_end"
                            />
                          </div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-1 col-form-label"
                            >%
                          </label>
                        </div>

                        <div class="row mb-3">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-2 col-form-label"
                          >
                          </label>
                          <div class="col-sm-2"> <v-swatches v-model="forms.service_percentage_hm_over_color"></v-swatches></div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-3 col-form-label"
                            >Truck Hm > Max Hm</label
                          >
                        </div>

                        <h5>PNL</h5>
                         <hr />
                         <div class="row mb-3">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-4 col-form-label"
                            >Alert When PNL
                          </label>
                          <div class="col-sm-2">
                            <input
                              class="form-control"
                              type="number"
                              id="exampleInputUsername2"
                              v-model="forms.pnl_persentage"
                            />
                          </div>
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-3 col-form-label"
                            >% </label
                          >
                        </div>

                         <h5>Gate Alert</h5>
                         <hr />
                         <div class="row mb-3">
                           <div class="form-check mb-2">
                              <input type="checkbox" class="form-check-input" id="checkDefault" v-model="forms.gate_alert_truck_in">
                              <label class="form-check-label" for="checkDefault">
                                Alert When Truck In
                              </label>
                            </div>

                            <div class="form-check mb-2">
                              <input type="checkbox" class="form-check-input" id="checkDefault" v-model="forms.gate_alert_truck_out">
                              <label class="form-check-label" for="checkDefault">
                                Alert When Truck out
                              </label>
                            </div>

                            <div class="form-check mb-2">
                              <input type="checkbox" class="form-check-input" id="checkDefault" v-model="forms.gate_alert_hold">
                              <label class="form-check-label" for="checkDefault">
                                Alert When One Of Document Hold By Autorities
                              </label>
                            </div>

                             <div class="form-check mb-2">
                              <input type="checkbox" class="form-check-input" id="checkDefault" v-model="forms.gate_alert_document_expired">
                              <label class="form-check-label" for="checkDefault">
                                Alert When Document Is Expired
                              </label>
                            </div>

                             <div class="form-check mb-2">
                              <input type="checkbox" class="form-check-input" id="checkDefault" v-model="forms.gate_alert_cannot_pass_gate">
                              <label class="form-check-label" for="checkDefault">
                                Alert When Truck Cannot Pas Gate
                              </label>
                            </div>

                         </div>


                       
                      </div>
                    </div>
                  </div>

                  <div class="card-footer">
                     <button
                        class="
                          btn btn-default
                          text-black
                          fw-bolder
                          btn-icon-text
                        "
                        @click="resetForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="repeat"></i>
                        Reset
                      </button>
                      
                      &nbsp;&nbsp;&nbsp;
                      <button class="btn btn-primary float-end btn-sm" type="submit">
                           <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>
                    
                </form>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";

export default {
  name: "AlertSetting",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      color: '#1CA085',
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      permision_role: [],
      langs: ["id", "en"],
      detailUser: [],
      detailDivisi: [],
      totalRecords: 0,
      forms: { },
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
  
      rows: [],
    };
  },
  watch: {},
  methods: {
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function(permision){
        if(permision.permission.controller == 'AlertSettingController'){
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if(!rolePermision.method_read){
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },

    resetForm(){
      this.forms={}
    },


    submitData() {
      this.fade(true);

      if(this.forms.gate_alert_cannot_pass_gate==true){
        this.forms.gate_alert_cannot_pass_gate=1;
      }else{
        this.forms.gate_alert_cannot_pass_gate=0;
      }

      if(this.forms.gate_alert_document_expired==true){
        this.forms.gate_alert_document_expired=1;
      }else{
        this.forms.gate_alert_document_expired=0;
      }

      if(this.forms.gate_alert_hold==true){
        this.forms.gate_alert_hold=1;
      }else{
        this.forms.gate_alert_hold=0;
      }

      if(this.forms.gate_alert_truck_in==true){
        this.forms.gate_alert_truck_in=1;
      }else{
        this.forms.gate_alert_truck_in=0;
      }

      if(this.forms.gate_alert_truck_out==true){
        this.forms.gate_alert_truck_out=1;
      }else{
        this.forms.gate_alert_truck_out=0;
      }

      const baseURI =
        this.$settings.endPointCt + "alert-setting/update/1";
      this.$http
        .patch(baseURI, this.forms)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            this.success(response.data.datas.messages);

            //window.history.back();
            //var params = this.$onRandom(this.forms.commodity_id);
            //window.location.href = "/commodity/detail/" + params + "?status=200&msg=Successfully";
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },


    // load items is what brings back the rows from server
    loadItems() {
      const baseURI = this.$settings.endPointCt + "alert-setting/detail/1";
      return this.$http
        .get(
          baseURI
        )
        .then((response) => {
          this.forms=response.data.datas;
       

        });
    },

  
   

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },

    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },
  },
  events: {},
  created: function () {},
  mounted() {
    this.loadItems();
    this.fetchIt();
    //this.loading();
  },
};
</script>
<style scoped>
</style>