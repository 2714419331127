<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="FakturPajakUpload"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            ">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left: 25px">{{ $t("FakturPajakAdd") }}</h6>
                  <button class="btn btn-success btn-xs float-end" @click="downloadData" type="button">
                    <i class="link-icon" data-feather="download"></i>
                    {{ $t("downloadData") }} Template
                  </button>


                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form class="forms-sample" @submit.prevent="submitData" method="POST" enctype="multipart/form-data">
                    <div class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      ">

                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-4">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">File Excel</label>
                      </div>
                      <div class="col-lg-8">

                        <input type="file" ref="fileXlx" class="form-control" @change="handleFileUpload($event)" />

                        <div v-if="errors.code">
                          <div v-for="error in errors.code" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-4">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{ $t("file") }} (ZIP)</label>
                      </div>
                      <div class="col-lg-8">

                        <input type="file" ref="file" class="form-control"
                          @change="handleFileUploads($event)" />

                        <div v-if="errors.code">
                          <div v-for="error in errors.code" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3" v-if="results">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">File Name</th>
                            <th scope="col">Status</th>
                            <th scope="col">Message</th>
                          </tr>
                        </thead>
                        <tbody>

                          <tr v-for="(result, index) in results"  :key="index">
                            <td>{{index+1}}</td>
                            <td>{{result.filename}}</td>
                            <td><span v-if="result.status == 'success'" style="color:#0000FF;"> {{result.status}} </span><span v-else style="color:#DC143C;"> {{result.status}} </span></td>
                            <td>{{result.message}}</td>
                          </tr>
                          
                        </tbody>
                      </table>
                    </div>




                    <div class="card-footer">
                      <button class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        " @click="backForm" type="button">
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>

                      &nbsp;&nbsp;&nbsp;
                      <button class="btn btn-primary float-end btn-sm" type="submit">
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>

                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";

export default {
  name: "FakturPajakUpload",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      file_xlsx: '',
      file_pdf: '',
      permision_role: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      results: false,
      userData: "",
      companyCode: "",
      forms: { name: "", usernmae: "" },
    };
  },
  watch: {},
  methods: {

    resetForm() {
      this.$refs.fileXlx.value = null;
      this.$refs.file.value = null;
    },

    handleFileUpload(event) {
      this.file_xlsx = event.target.files[0];
    },

    handleFileUploads(event) {
      this.file_pdf = event.target.files[0];

      // multiple file     
      // this.file_pdf = event.target.files;
    },

    downloadData() {
      const baseURI = this.$settings.endPointFP + "/finance/root/faktur-pajak/download-template";

      var file_name = "template_upload_faktur_pajak.xlsx";

      //var columnFilters = this.serverParams.columnFilters;


      return this.$http
        .get(
          baseURI,
          {
            responseType: "blob",
          }
        )
        .then((response) => {

          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },


    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == 'FakturPajakController') {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_upload) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;

    },

    backForm() {
      window.location.href = "/faktur-pajak";
    },



    loadingSubmiting(){


      this.$swal({
        icon: 'warning',
        backdrop:true,
        title: "Pleasee Wait",
        html:'<h2>SUBMITTING...</h2>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

    },

    submitData() {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourData"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          this.fade(true);
          this.loadingSubmiting();

          let formData = new FormData();
          //console.log(this.file);
          // single xlsx
          formData.append("file_xlsx", this.file_xlsx);
          formData.append("file_pdf", this.file_pdf);
/*
          // multiple pdf     
          for (var i = 0; i < this.file_pdf.length; i++) {
            let file_pdf = this.file_pdf[i];
            formData.append('file_pdf[' + i + ']', file_pdf);
          }
*/

          const baseURI = this.$settings.endPointFP + "finance/root/faktur-pajak/upload";
          this.$http.post(baseURI, formData).then((response) => {
            this.loading();
            if (response.data.status === 200) {
              this.resetForm();
              this.errors = [];
              
              //console.log(response);
              //var params = this.$onRandom(response.data.datas.company_id);
              // window.location.href = "/faktur-pajak";
              this.success(response.data.datas);
              this.results = false
              // this.results = response.data.datas;
            } else {
              this.errors = response.data.errors;
              this.resultError(response.data.errors);
            }
          }).catch(error => {
            this.loading();
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else {
                this.$router.push('/page-not-found');
              }
            }
            this.resetForm();
          });
        }
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () { },
  mounted() {
    this.fetchIt();
  },
};
</script>
<style scoped>
</style>