var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(this.isLoading)?_c('div',{staticClass:"loader"}):_vm._e(),(this.isLoading)?_c('div',{staticClass:"loadertext"},[_vm._v(" "+_vm._s(_vm.$t("loadingTxt"))+"... ")]):_vm._e(),_c('div',{staticClass:"main-wrapper",class:{ 'blur-content': this.isLoading }},[_c('sidebar-component',{attrs:{"classMenu":"BranchDetailGT"}}),_c('div',{staticClass:"page-wrapper"},[_c('navbar-component'),_c('div',{staticClass:"page-content"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center flex-wrap grid-margin"},[_c('div',{staticClass:"col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h2',[_c('span',{staticClass:"badge bg-primary"},[_vm._v(_vm._s(_vm.$t("branchTxt"))+" : "+_vm._s(this.forms.branch_name))])]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-lg-4"},[_vm._m(0),_c('div',{staticClass:"card",staticStyle:{"cursor":"pointer","-webkit-box-shadow":"0 0 10px 0 rgb(26 28 32 / 20%)"},on:{"click":function($event){return _vm.goTo('/gt/branch-transactions/' +
                                        _vm.$route.params.id +
                                        '/PARKING/all')}}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-8 col-md-12 col-xl-8"},[_c('h3',{staticClass:"mb-2 text-primary"},[_vm._v(_vm._s(_vm.$t("parking")))]),_c('div',{staticClass:"d-flex align-items-baseline"},[_c('h5',[_vm._v(_vm._s(this.totalParkir))])])])])])]),_c('br'),_c('div',{staticClass:"card",staticStyle:{"cursor":"pointer","-webkit-box-shadow":"0 0 10px 0 rgb(26 28 32 / 20%)"},on:{"click":function($event){return _vm.goTo('/gt/branch-transactions/' +
                                        _vm.$route.params.id +
                                        '/KIR/all')}}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col-8 col-md-12 col-xl-8"},[_c('h3',{staticClass:"mb-2 text-primary"},[_vm._v(_vm._s(_vm.$t("kir")))]),_c('div',{staticClass:"d-flex align-items-baseline"},[_c('h5',[_vm._v(_vm._s(this.totalKIR))])])])])])]),_c('br'),_c('div',{staticClass:"card",staticStyle:{"cursor":"pointer","-webkit-box-shadow":"0 0 10px 0 rgb(26 28 32 / 20%)"},on:{"click":function($event){return _vm.goTo(
                          '/gt/branch-transactions/' +
                                        _vm.$route.params.id +
                                        '/Corrective Maintenance/all'
                        )}}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_vm._m(3),_c('div',{staticClass:"col-8 col-md-12 col-xl-8"},[_c('h3',{staticClass:"mb-2 text-primary"},[_vm._v(" "+_vm._s(_vm.$t("corrective_maintenance"))+" ")]),_c('div',{staticClass:"d-flex align-items-baseline"},[_c('h5',[_vm._v(_vm._s(this.totalCorrectiveMaintenance))])])])])])]),_c('br'),_c('div',{staticClass:"card",staticStyle:{"cursor":"pointer","-webkit-box-shadow":"0 0 10px 0 rgb(26 28 32 / 20%)"},on:{"click":function($event){return _vm.goTo(
                          '/gt/branch-transactions/' +
                                        _vm.$route.params.id +
                                        '/Preventive Maintenance/all'
                        )}}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_vm._m(4),_c('div',{staticClass:"col-8 col-md-12 col-xl-8"},[_c('h3',{staticClass:"mb-2 text-primary"},[_vm._v(" "+_vm._s(_vm.$t("preventive_maintenance"))+" ")]),_c('div',{staticClass:"d-flex align-items-baseline"},[_c('h5',[_vm._v(_vm._s(this.totalPreventiveMaintenance))])])])])])])]),_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"mb-12"},[_c('LineChartGenerator',{attrs:{"chart-options":_vm.chartOptions,"chart-data":_vm.chartDataKeSatu,"chart-id":_vm.chartId,"dataset-id-key":_vm.datasetIdKey,"plugins":_vm.plugins,"css-classes":_vm.cssClasses,"styles":_vm.myStyles,"width":_vm.width,"height":_vm.height}}),(this.availableChartDataKeSatu)?_c('div',{staticStyle:{"text-align":"center","left":"0","top":"100px","z-index":"20"}},[_c('b',[_vm._v(_vm._s(_vm.$t("no_data_available_txt")))])]):_vm._e()],1),_c('div',{staticClass:"d-flex align-items-center justify-content-between p-3 border-bottom tx-16"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$t("truck_record_by_permit_status")))])]),_c('div',[_c('span',{staticStyle:{"color":"green"}},[_c('a',{attrs:{"href":'/gt/branch-transactions/' +
                              _vm.$route.params.id +
                              '/all/all'}},[_vm._v(" "+_vm._s(_vm.$t("more_record"))+" >>> ")])])])]),_c('vue-good-table',{attrs:{"title":"list-user","mode":"remote","select-options":{ enabled: false },"totalRows":_vm.totalRecords,"line-numbers":true,"isLoading":_vm.isLoading,"pagination-options":{
                        enabled: false,
                      },"rows":_vm.rows,"columns":_vm.columns},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[(_vm.permision_role.method_read)?_c('a',{attrs:{"href":"#","title":"Detail"},on:{"click":function($event){$event.preventDefault();return _vm.detailData(props.index, props.row)}}},[_c('i',{staticClass:"mdi mdi-eye",staticStyle:{"font-size":"16px"}})]):_vm._e()]):_vm._e(),(props.column.field == 'actions_date')?_c('span',[_vm._v(" "+_vm._s(_vm.convertDate(props.row))+" ")]):(props.column.field == 'actions_time')?_c('span',[_vm._v(" "+_vm._s(_vm.convertTime(props.row))+" ")]):(props.column.field == 'permit_status')?_c('span',[(props.row.permit_status == 'Rejected')?_c('span',{staticStyle:{"background-color":"red","color":"white"}},[_vm._v(_vm._s(props.row.permit_status))]):_c('span',[_vm._v(_vm._s(props.row.permit_status))])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])])])])])]),_c('footer-component')],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('figure',{staticClass:"mb-12"},[_c('img',{staticClass:"img-fluid rounded",attrs:{"src":"/assets/icon/truck.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 col-md-12 col-xl-4 align-items-center"},[_c('img',{attrs:{"src":"/assets/icon/parking_1.png","height":"70px","width":"70px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 col-md-12 col-xl-4 align-items-center"},[_c('img',{attrs:{"src":"/assets/icon/kir_1.png","height":"70px","width":"70px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 col-md-12 col-xl-4 align-items-center"},[_c('img',{attrs:{"src":"/assets/icon/corrective_maintenance.png","height":"70px","width":"70px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 col-md-12 col-xl-4 align-items-center"},[_c('img',{attrs:{"src":"/assets/icon/preventive maintenance_1.png","height":"70px","width":"70px"}})])
}]

export { render, staticRenderFns }