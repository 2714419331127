<template>
  <div>
    <modal-rekomendasi-verifikasi 
    :showModalVerifikasi="showVerifikasi" 
    :rm_payable_rate_detail_id_copy="rm_payable_rate_detail_id_copy"
    :rm_payable_rate_detail_id="rm_payable_rate_detail_id"
    :filterBy="filterBy"
    @closeModalVerifikasi="closeModalVerifikasi">
  </modal-rekomendasi-verifikasi>

    <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title badge bg-primary">
              Rekomendasi by {{ filterBy }}
            </h5>

            <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal', null)"
              aria-label="btn-close"></button>
          </div>

          <div class="modal-body">
            <vue-good-table title="list-user" mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
              :line-numbers="true" :isLoading.sync="isLoading" :pagination-options="{
                enabled: false,
              }" :rows="rows" :columns="columns">
              <template slot="table-row" slot-scope="props">
  
                  <span v-if="props.column.field == 'actions'">
                    <a href="#"                   
                      title="Detail" @click.prevent="openModal(props.row)">
                      <i class="mdi mdi-eye" style="font-size:16px"></i>
  
                    </a>
                  </span>
                </template>
            </vue-good-table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal', null)">
              {{ $t("close_txt") }}
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
  
<script>
import modalRekomendasiVerifikasi from "@/components/modal/modalPayableRekomendasiVerifikasi.vue";

export default {
  components: {
    "modal-rekomendasi-verifikasi": modalRekomendasiVerifikasi
  },
  name: "ModalUnloading",
  emits: ["closeModal"],
  props: {
    showModal: Boolean,
    data: {},
    filterBy:String,
  },
  watch: {
    showModal: {
      handler(newVal) {
        this.active = newVal;
       
        if (this.active) {
          this.rm_payable_rate_detail_id=this.data;
          this.loadItems(this.rm_payable_rate_detail_id);
        }
        
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
     
      showVerifikasi: false,
      rm_payable_rate_detail_id: null,
      rm_payable_rate_detail_id_copy: null,
      isLoading: false,
      active: this.showModal,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 1000,
      },
      columns: [
      {
          label: "Action",
          field: "actions",
          sortable: false,
          width:"100px",
        },
        {
          label: "Status",
          field: "status_rekomendasi",
        },
        {
          label: "Equipment",
          field: "equipment",
        },
        {
          label: "Carrier",
          field: "carrier_name",
        },
        {
          label: "Origin Location",
          field: "origin_location",
        },
        {
          label: "Origin Region",
          field: "origin_region",
        },
        {
          label: "Destination Location",
          field: "destination_location",
        },
        {
          label: "Destination Region",
          field: "destination_region",
        },
        {
          label: "Effective Date",
          field: "effective_date",
        },
        {
          label: "Expiration Date",
          field: "expiration_date",
        },
        {
          label: "Currency",
          field: "currency",
        },
        {
          label: "Basis",
          field: "km_basis",
        },
        {
          label: "Rate",
          field: "km_margin",
        },
        {
          label: "Penyebrangan",
          field: "penyebrangan",
        },
        {
          label: "Deposit",
          field: "deposit",
        },
        {
          label: "Self Insurance",
          field: "self_insurance",
        },
        {
          label: "Lead Time",
          field: "lead_time",
        },
        {
          label: "Tol",
          field: "tol",
        },
        {
          label: "Komisi",
          field: "komisi",
        },
        {
          label: "Mel",
          field: "mel",
        },
      
      ],
      columnsChain: [
        {
          label: "Company",
          field: "company_name",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Company", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Order No",
          field: "order_no",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Order No", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "ShipWithRef",
          field: "shipwith_ref",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Order Date",
          field: "order_date",
          thClass: "text-left",
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd MMM yyyy h:mm:ss a", //'dd MMM yyyy',
          filterOptions: {
            customFilter: true,
            enabled: false, // enable filter for this column
            placeholder: "Filter By Order Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Order Status",
          field: "order_status",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Order Status", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [
              "NEW",
              "SUBMITTED",
              "ASSIGNED",
              "CANCELLED",
              "RESCHEDULE - NEED APPROVAL",
              "RESCHEDULE - WAITING APPROVAL",
              "RESCHEDULE - APPROVED",
              "REROUTE - NEED APPROVAL",
              "REROUTE - WAITING APPROVAL",
              "REROUTE - APPROVED",
              "REJECTED",
              "COMPLETED",
              "VOID",
            ], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Pick Plan",
          field: "pick_plan",
          thClass: "text-left",
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd MMM yyyy h:mm:ss a", //'dd MMM yyyy',
          filterOptions: {
            customFilter: true,
            enabled: false, // enable filter for this column
            placeholder: "Filter By Pick Plan", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Pick Name",
          field: "pick_name",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Direction", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Dest Plan",
          field: "dest_plan",
          thClass: "text-left",
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd MMM yyyy h:mm:ss a", //'dd MMM yyyy',
          filterOptions: {
            customFilter: true,
            enabled: false, // enable filter for this column
            placeholder: "Filter By Dest Plan", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Dest Name",
          field: "dest_name",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Dest Name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Vehicle No",
          field: "vehicle_no",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Vehicle No", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Driver",
          field: this.driverIdName,
          width: "100px",
          sortable: false,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Driver Name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Status TMS",
          field: "status_tms",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Dest Name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Shipment Rate",
          field: "shipment_rate",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Remarks",
          field: "remarks",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
      ],
      columnsNew: [
        {
          label: this.$t("submit_time_txt"),
          field: "submit_time",
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd MMM yyyy h:mm:ss a", //'dd MMM yyyy',
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("submit_time_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        // {
        //   label: this.$t("postpone_time_txt"),
        //   field: "postpone_time",
        //   type: 'date',
        //   dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
        //   dateOutputFormat: 'dd MMM yyyy h:mm:ss a',//'dd MMM yyyy',
        //   filterOptions: {
        //     enabled: false, // enable filter for this column
        //     placeholder: "Filter By "+this.$t("postpone_time_txt"), // placeholder for filter input
        //     filterValue: "", // initial populated value for this filter
        //     filterDropdownItems: [], // dropdown (with selected values) instead of text input
        //     trigger: "enter", //only trigger on enter not on keyup
        //   },
        // },
        {
          label: this.$t("companyTxt"),
          field: "company.name",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("companyTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: this.$t("order_no_txt"),
          field: "order_no",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("order_no_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "ShipWithRef",
          field: "shipwith_ref",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Shipment Rate",
          field: "shipment_rate",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("order_date_txt"),
          field: "order_date",
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd MMM yyyy h:mm:ss a", //'dd MMM yyyy',
          // dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          // dateOutputFormat: 'dd MMM yyyy',
          filterOptions: {
            customFilter: true,
            enabled: false, // enable filter for this column
            placeholder: "Filter By Order Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("order_status_txt"),
          field: "order_status",
          //   filterOptions: {
          //     enabled: false, // enable filter for this column
          //     placeholder: "Filter By "+this.$t("order_status_txt"), // placeholder for filter input
          //     filterValue: "", // initial populated value for this filter
          //     filterDropdownItems: ['SUBMITTED','POSTPONE'], // dropdown (with selected values) instead of text input
          //     trigger: "enter", //only trigger on enter not on keyup
          //   },
        },

        {
          label: "Status Tms",
          field: "status_tms",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("order_status_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter

            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("pick_name_txt"),
          field: "pick_name",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("pick_name_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("dest_name_txt"),
          field: "dest_name",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("dest_name_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Type Truck",
          field: "type_truck",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Type Truck", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("transport_order_template_description_txt"),
          field: "transport_order_template.description",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder:
              "Filter By " +
              this.$t("transport_order_template_description_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("tonnage_txt"),
          field: "tonnage",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "> 0", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("cubic_txt"),
          field: "transport_order_template.cubic",
          sortable: false,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "> 0", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("pickup_plan_txt"),
          field: "pick_plan",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("pickup_plan_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("dest_plan_txt"),
          field: "dest_plan",
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd MMM yyyy h:mm:ss a", //'dd MMM yyyy',
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("dest_plan_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Vehicle No",
          field: "vehicle_no",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By vehicle no", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Driver",
          field: "driver_name",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Type Truck", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Auto Load",
          field: "auto_load",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Auto Load",
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Carier",
          field: "carrier",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Carier", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Remarks",
          field: "remarks",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Create by",
          field: "create_by",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
      ],
      columnsAssgined: [
        {
          label: "Status TMS",
          field: "status_tms",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Status", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Customer",
          field: "company.name",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Customer", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Vehicle No",
          field: "vehicle_no",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Vehicle No", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Type Truck",
          field: "type_truck",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Type Truck", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Driver",
          field: "driver_name",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Type Truck", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Load Id",
          field: "load_id",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Load Id", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Shipment Number",
          field: "order_no",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Order No", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "ShipWithRef",
          field: "shipwith_ref",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By ShipWithRef", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Planing Pada",
          field: "accept_time",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Planing", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("pickup_plan_txt"),
          field: "pick_plan",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("pickup_plan_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Pick Location",
          field: "pick_name",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd MMM yyyy h:mm:ss a", //'dd MMM yyyy',
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("pick_plan_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Dest Location",
          field: "dest_name",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Destination", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("dest_plan_txt"),
          field: "dest_plan",
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd MMM yyyy h:mm:ss a", //'dd MMM yyyy',
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("dest_plan_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Actual Duration",
          field: "actual_duration",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Destination", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Tonnage (kg)",
          field: "tonnage",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Tonnage", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Location",
          field: "dest_location",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Tonnage", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Auto Load",
          field: "auto_load",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Auto Load", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Carier",
          field: "carrier",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Carier", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Assign Time",
          field: "assign_time",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Assign by",
          field: "assign_by",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Remarks",
          field: "remarks",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
      ],
      rows: [],
      rowsSelection: [],
      locale: [],
      loadId: "",
      orderStatus: "",
      rules: {
        select: [(v) => !!v || "Item is required"],
      },
    };
  },

  methods: {
    submitParent(index, row) {
      this.$emit("closeModal", row);
    },


    closeModalVerifikasi() {
      this.active=true;
      this.showVerifikasi = false;
    },

    openModal(row){
      this.active=false;
      this.rm_payable_rate_detail_id_copy=row.rm_payable_rate_detail_id;
      this.showVerifikasi=true;
    },

    removeOrderHeaderIds() {
      this.transport_order_header_ids = [];
      this.$refs["list-transport-orders"].unselectAllInternal();
      // console.log(this.$refs['list-transport-orders'].selectedRows)
    },

    selectionChanged(ev) {
      this.rowsSelection = [];
      this.transport_order_header_ids = [];
      this.rowsSelection = ev.selectedRows;

      this.rowsSelection.forEach((item) => {
        this.transport_order_header_ids.push(item.transport_order_header_id);
      });
    },

    loadItems(id) {
      this.rows = [];
      const baseURI =
        this.$settings.endPoint + "rm-payable-rate-header/recomendation/" + id;
      return this.$http
        .get(
          baseURI
        )
        .then((response) => {
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                // this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                // this.$router.push('/page-not-found');
              }
            }
          }
        });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    closeMyModal(){
      this.showVerifikasi=false;
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },
    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  mounted() {
   
    //   this.loadItems();
    // this.rows = this.props.data;
  },
};
</script>
  
<style scoped>
.blur-content {
  filter: blur(5px);
  pointer-events: none;
}

.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 120%;
  height: 100%;
  margin-left: -10%;
}
</style>