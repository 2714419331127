<template>
  <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ type }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
            aria-label="btn-close"></button>
        </div>

        <form class="forms-sample" method="POST" @submit.prevent="submitData">
          <div class="modal-body">
           

            <div class="mb-3" >
              <label class="col-form-label"><b>Carrier</b></label>
              <p>{{ data.transporter}}</p>
            </div>

            <div class="mb-3" >
              <label class="col-form-label"><b>Type Truck</b></label>
             
              <p>{{ data.typeTruck?.type_truck }}</p>
            </div>
            <!--end::loading-data-->
            <!-- <h5>{{ $t("areYouSure") }}</h5> -->
            <div class="mb-3" v-if="type=='truck'">
              <label class="col-form-label"><b>Vehicle No</b></label>
              <input class="form-control" name="vehicle_no" v-model="vehicle_no" />
            </div>


            <div class="mb-3" v-if="type=='driver'">
              <label class="col-form-label"><b>Driver NMK</b></label>
              <input class="form-control" name="driver_nmk" readonly v-model="driver_nmk" />
            </div>

            <div class="mb-3" v-if="type=='driver'">
              
              <label class="col-form-label"><b>Driver Name</b></label>
              <input class="form-control" name="driver_name" v-model="driver_name" />
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
              Cancel
            </button>
            
            <div v-if="isLoading" class="d-flex align-items-center">
              <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
              <strong>Loading...</strong>
            </div>

            <button v-if="!isLoading" class="btn btn-primary" type="submit">Save </button>

          </div>
        </form>
      </div>
    </div>
  </div>
</template>
  
<script>

export default {

  components: {
    // DateRangePicker
  },
  name: "modalAddFreePick",
  emits: ["closeModal"],

  props: {
    showModal: Boolean,
    data: null,
    type: null,
  },
  watch: {
    showModal: {
      handler(newVal) {
        console.log(this.data);
        this.forms=this.data;
        if(this.type=='driver'){
          this.driver_name=this.forms?.driver?.driver_name
          this.driver_nmk=this.forms?.driver?.driver_number
        }else{
          this.vehicle_no=this.forms?.vehicle_no?.vehicle_no
        }
        
       
        this.loadForCarrier(this.forms.transporter)
        this.active = newVal;
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {

      vehicle_no:'',
      driver_name:'',
      driver_nmk:'',
      isLoading: false,
      active: this.showModal,
      forms: [],
      locale: [],
      return_date: [],
      delivery_date: [],
      equipment_name: [],
      fetchEquipmentName: [],
    };
  },
  methods: {
    resetForm() {
      this.forms = [];
    },

    loadForCarrier(code) {
      const baseURI =
        this.$settings.endPointCt + `master-carrier/?carrier_code=`+code;
      return this.$http.get(baseURI).then((response) => {
        // console.log(response.data.datas.data);
          this.forms.master_carrier_id=response.data.datas.data[0].master_carrier_id
          console.log(this.forms.master_carrier_id);
      });
    },

    submitData() {
      console.log(this.type)
      console.log(this.vehicle_no)
      var dataSend={
          "master_carrier_id" : this.forms.master_carrier_id,
          "type"              : this.type,
          "value"             : this.type=="truck" ? this.vehicle_no : this.driver_name,
          "truck_type"        : this.forms.typeTruck?.type_truck
      }
      
      this.isLoading=true;
      const baseURI = this.$settings.endPointCt + "master-carrier/create";
        this.$http
          .post(baseURI, dataSend)
          .then((response) => {
            this.isLoading=false;
            if (response.data.status === 200) {
             
              this.resetForm();
              this.$emit("closeModal");
              this.loadSuccess("Success");
              this.driver_name='';
              this.vehicle_no='';
            } else {
              
              this.errors = response.data.errors;
              //this.resultError(response.data.errors);
              this.loadError(this.errors.message)
            }


          })
          .catch((error) => {
            this.isloading = false;
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              
              }
            }
            
          });

    },

    resultError(data) {
      var html = "";
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          html += objectData[y] + "<br>";
        }
      }

      this.loadError(html);
    },

    loadError(pesan) {
      this.$swal({
        backdrop: true,
        title: "Warning",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading=false;
        }
      })
    },



    loadSuccess(pesan) {
      this.$swal({
        backdrop: true,
        title: "Success",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading=false;
        }
      })
    },

   
  },
  created: function () {
    //this.loadDriver();

  },
  mounted() {
    //tail(this.data);
  },
};
</script>
  
<style scoped>
.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left: 0px !important;
}
</style>