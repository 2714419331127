import RequestRg from "@/components/dasboard-rm/request_rg/RequestRg.vue";
import RequestRgAdd from "@/components/dasboard-rm/request_rg/RequestRgAdd.vue";
import RequestRgDetail from "@/components/dasboard-rm/request_rg/RequestRgDetail.vue";
import RequestRgEdit from "@/components/dasboard-rm/request_rg/RequestRgEdit.vue";
import RequestRgApprove from "@/components/dasboard-rm/request_rg/RequestRgApprove.vue";
import RequestRgUpload from "@/components/dasboard-rm/request_rg/RequestRgUpload.vue";

let routesRequestRg= [
    
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "RequestRgIndex",
        component: RequestRg
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "RequestRgAdd",
        component: RequestRgAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "RequestRgEdit",
        component: RequestRgEdit
      },
      {
        path: 'approve/:id',
        meta: {
          title: "Chain System",
        },
        name: "RequestRgApprove",
        component: RequestRgApprove
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "RequestRgDetail",
        component: RequestRgDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "RequestRgUpload",
        component: RequestRgUpload
      },
  ]
  
  export default routesRequestRg
  