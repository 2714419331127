import { render, staticRenderFns } from "./TransactionDetail.vue?vue&type=template&id=31fd9755&scoped=true"
import script from "./TransactionDetail.vue?vue&type=script&lang=js"
export * from "./TransactionDetail.vue?vue&type=script&lang=js"
import style0 from "./TransactionDetail.vue?vue&type=style&index=0&id=31fd9755&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31fd9755",
  null
  
)

export default component.exports