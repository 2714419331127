import SystemCode from "@/components/master/system_code/SystemCode.vue";
import SystemCodeAdd from "@/components/master/system_code/SystemCodeAdd.vue";
import SystemCodeDetail from "@/components/master/system_code/SystemCodeDetail.vue";
import SystemCodeEdit from "@/components/master/system_code/SystemCodeEdit.vue";
import SystemCodeUpload from "@/components/master/system_code/SystemCodeUpload.vue";

let routesSystemCode= [
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "SystemCode",
        component: SystemCode
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "SystemCodeAdd",
        component: SystemCodeAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "SystemCodeEdit",
        component: SystemCodeEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "SystemCodeDetail",
        component: SystemCodeDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "SystemCodeUpload",
        component: SystemCodeUpload
      },
    
]

export default routesSystemCode
  