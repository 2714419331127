import TypeDocument from "@/components/master/type_document/TypeDocument.vue";
import TypeDocumentAdd from "@/components/master/type_document/TypeDocumentAdd.vue";
import TypeDocumentDetail from "@/components/master/type_document/TypeDocumentDetail.vue";
import TypeDocumentEdit from "@/components/master/type_document/TypeDocumentEdit.vue";
import TypeDocumentUpload from "@/components/master/type_document/TypeDocumentUpload.vue";

let routesTypeDocument= [
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "TypeDocument",
        component: TypeDocument
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "TypeDocumentAdd",
        component: TypeDocumentAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "TypeDocumentEdit",
        component: TypeDocumentEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "TypeDocumentDetail",
        component: TypeDocumentDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "TypeDocumentUpload",
        component: TypeDocumentUpload
      },
    
]

export default routesTypeDocument
  