<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="BbmIndex"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div>
              <h4 class="mb-12 mb-md-0"></h4>
            </div>

            <div class="col-lg-12 col-xl-12 stretch-card">
              <div class="card">
                <div class="card-header">
                  <h4 style="margin-right: 5px" class="float-start">
                    Manage Charge Code
                  </h4>
                  <button
                    v-if="permision_role.method_create"
                    class="btn btn-primary float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="createData()"
                  >
                    <i class="link-icon" data-feather="plus"></i>
                    {{ $t("createNew") }}
                  </button>
                  <button
                    v-if="permision_role.method_read"
                    class="btn btn-success float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="downloadData()"
                  >
                    <i class="link-icon" data-feather="download"></i>
                    {{ $t("downloadData") }} .xlsx
                  </button>
                </div>

                <div class="card-body">
                  <vue-good-table
                    title="list-parties"
                    ref="list-parties"
                    mode="remote"
                    :select-options="{
                      enabled: true,
                      selectOnCheckboxOnly: true,
                      selectionText: $t('data_selected'),
                      clearSelectionText: $t('clear'),
                    }"
                    @on-selected-rows-change="selectionChanged"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    :totalRows="totalRecords"
                    :line-numbers="true"
                    :isLoading.sync="isLoading"
                    :pagination-options="{
                      enabled: true,
                    }"
                    :rows="rows"
                    :columns="columns"
                  >

                  <div slot="table-actions">
                      {{ $t("no_select_data") }}
                    </div>
                    <div slot="selected-row-actions">
                      <button
                        class="btn btn-success btn-xs"
                        style="margin-right: 20px"
                        @click="sendToBlujay"
                      >
                        <i class="mdi mdi-send-outline"></i>Send To Blujay
                      </button>
                      <span> </span>
                    </div>

                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'actions'">
                        <!-- <a
                          href="#"
                          v-if="permision_role.method_read"
                          title="Detail"
                          @click.prevent="detailData(props.index, props.row)"
                        >
                          <i class="mdi mdi-eye" style="font-size: 16px"></i>
                        </a> -->
                        <a
                          href="#"
                          v-if="permision_role.method_update"
                          title="Edit"
                          @click.prevent="editData(props.row)"
                        >
                          <i
                            class="mdi mdi-square-edit-outline"
                            style="font-size: 16px"
                          ></i>
                        </a>
                        <a
                          href="#"
                          v-if="permision_role.method_delete"
                          title="Delete"
                          @click.prevent="deleteData(props.index, props.row)"
                        >
                          <i
                            class="mdi mdi-trash-can-outline"
                            style="font-size: 16px"
                          ></i>
                        </a>
                      </span>
                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
            <modal-charge-code
              :showModal="showModalChargeCode"
              :data="passData"
              @closeModal="closeMyModal"
            ></modal-charge-code>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import mocalChargeCode from "@/components/modal/modalChargeCodeAdd";

export default {
  name: "BbmIndex",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "modal-charge-code": mocalChargeCode,
  },
  data() {
    return {
      maxToasts: 100,
      position: "up right",
      showModalChargeCode: false,
      passData: {
        type:'',
      },
      closeBtn: true,
      isLoading: false,
      errors: [],
      permision_role: [],
      langs: ["id", "en"],
      detailUser: [],
      detailDivisi: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [
        {
          label: "SOB",
          field: "sob",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By SOB", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Active Charge Code",
          field: "charge_code",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Active Charge Code", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Description Override",
          field: "description_overide",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Description Override", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Transportation Account Code",
          field: "transportation_account_code",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Transportation Account Code", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "EDI Code",
          field: "edi_code",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By EDI Code", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Instruction For Carrier",
          field: "instructions_for_carrier",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Instruction For Carrier", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Action",
          field: "actions",
          sortable: false,
        },
      ],
      rows: [],
    };
  },
  watch: {},
  methods: {
    downloadData() {
      const baseURI = this.$settings.endPoint + "/rm-fuel-master";
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = "download_" + CurrentDate + ".xlsx";

      return this.$http
        .get(
          baseURI +
            `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&period_code=${this.serverParams.columnFilters.period_code}&period_name=${this.serverParams.columnFilters.period_name}&price=${this.serverParams.columnFilters.price}&price_gap=${this.serverParams.columnFilters.price_gap}&effective_date=${this.serverParams.columnFilters.effective_date}&expired_date=${this.serverParams.columnFilters.expired_date}&download=download`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },
    createData() {
      this.passData = {
        type:'create'
      };
      this.showModalChargeCode = true;
    },
    closeMyModal() {
      this.showModalChargeCode = false;
    },
    uploadData() {
      window.location.href = "/master-bbm/upload";
    },

    editData(row) {
      this.passData = {
        type:'update',
        row:row
      };
      this.showModalChargeCode = true;
      // var params = this.$onRandom(row.rm_fuel_master_id);
      // window.location.href = "/master-bbm/edit/" + params;
    },

    detailData(index, row) {
      var params = this.$onRandom(row.rm_fuel_master_id);
      window.location.href = "/master-bbm/detail/" + params;
    },

    deleteData(index, row, status) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI =
            this.$settings.endPoint +
            "rm-charge-code/delete/" +
            row.rm_charge_code_id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    selectionChanged(ev) {
      this.rowsSelection = [];

      this.rowsSelection = ev.selectedRows;
    
      // this.rm_freight_billable_parties_ids = [];
      // this.rowsSelection.forEach((item) => {
      //   this.rm_freight_billable_parties_ids.push(
      //     item.rm_freight_billable_parties_id
      //   );
      // });
    },

    sendToBlujay(){

    },

    calculate() {
      this.$swal({
        title: "Apakah Anda Yakin akan mengkalkulasi BBM ?",
        text: "Semua Record Payable dan Billable akan mengalami kenaikan sesuai dengan exponensial kenaikan harga BBM ini",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        console.log(result);
      });
    },
    // load items is what brings back the rows from server
    loadItems() {
      const baseURI = this.$settings.endPoint + "rm-charge-code";
      return this.$http
        .get(
          baseURI +
           `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&sob=${this.serverParams.columnFilters.sob}&charge_code=${this.serverParams.columnFilters.charge_code}&description_overide=${this.serverParams.columnFilters.description_overide}&transportation_account_code=${this.serverParams.columnFilters.transportation_account_code}&instructions_for_carrier=${this.serverParams.columnFilters.instructions_for_carrier}&edi_code=${this.serverParams.columnFilters.edi_code}`,
        )
        .then((response) => {
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
        });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RmChargeCodeController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      this.permision_role = rolePermision;
    },

    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },
  },
  events: {},
  created: function () {},
  mounted() {
    this.fetchIt();
    this.loading();
  },
};
</script>
<style scoped></style>
