<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="StopLocationEdit"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 25px"
                  >
                    Edit Billable Acc Rules
                  </h6>
                  <button
                    class="btn btn-default float-end"
                    @click="resetForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>

                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="d-flex justify-content-between align-items-baseline mb-2"
                    ></div>

                          <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Description <span style="color: red">*</span></label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="description"
                          v-model="forms.description"
                          required
                        />
                        <div v-if="errors.description">
                          <div
                            v-for="error in errors.description"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >File Upload Cross Reference
                        </label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="file_upload"
                          v-model="forms.file_upload"
                          required
                        />
                        <div v-if="errors.file_upload">
                          <div
                            v-for="error in errors.file_upload"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Enabled</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="enabled"
                          v-model="forms.status"
                          true-value="ENABLED"
                          false-value="DISABLED"
                        />
                        <div v-if="errors.status">
                          <div
                            v-for="error in errors.status"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
    <div class="row card">
                      <div class="card-header">
                        <button
                          class="btn btn-success btn-icon text-white fw-bolder btn-icon-text float-end"
                          @click="addField()"
                          type="button"
                        >
                          <i class="link-icon" data-feather="plus"></i>
                        </button>
                      </div>
                      <div class="card-body">
                        <div class="table-responsive" style="height: 300px">
                          <table class="table table-hover">
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>Criteria</th>
                                <th>Charge Code</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody v-if="this.detailAll.length > 0">
                              <tr
                                v-for="(input, index) in this.detailAll"
                                :key="`phoneInput-${index}`"
                              >
                                <td>{{ index + 1 }}</td>
                                <td>
                                  <v-select
                                    :options="fetchCriteria"
                                    v-model="input.criteria"
                                  >
                                  </v-select>
                                </td>
                                <td>
                                  <multiselect
                                    @search-change="asyncChargeCode"
                                    v-model="input.chargeCodes"
                                    :id="'multiselect'+index"
                                    :options="fetchChargeCode"
                                    label="description_overide"
                                    track-by="description_overide"
                                    placeholder="Please Select"
                                    @input="inputChargeCode($event, index)"
                                  >
                                    <span slot="noResult">-</span>
                                  </multiselect>
                                </td>
                                <td>
                                  <!--          Remove Svg Icon-->
                                  <svg
                                    @click="removeField(index, detailAll)"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    class="ml-2 cursor-pointer"
                                  >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path
                                      fill="red"
                                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                                    />
                                  </svg>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td colspan="6">
                                  {{ $t("no_data_available_txt") }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <button
                        class="btn btn-warning text-black fw-bolder btn-icon-text"
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button
                        class="btn btn-primary float-end btn-sm"
                        type="submit"
                      >
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>
  
  
  <script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import multiselect from "vue-multiselect";

export default {
  name: "BillablePartiesEdit",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    multiselect: multiselect,
  },
  data() {
    return {
      fetchTimeZone: [
        {
          name: "(GMT + 07.00 Asia / Jakarta)",
        },
      ],
      timeZone: "",
      fetchCountry: [
        {
          name: "Indonesia",
        },
      ],
      fetchCountryCode: [],
      featchProvinceCode: [],
      featchCityCode: [],
      fetchCompany: [],
      reference: [],
      city: "",
      province: "",
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      permision_role: [],
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      forms: [],

      fetchChargeCode: [],
      fetchCriteria: [],
      detailAll: [],
    };
  },
  watch: {},
  methods: {
    loadChargeCode() {
      const baseURI = this.$settings.endPoint + `rm-charge-code`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchChargeCode = response.data.datas.data;
      });
    },
    asyncChargeCode(ev) {
      const baseURI =
        this.$settings.endPoint + `rm-charge-code?description_overide=` + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchChargeCode = response.data.datas.data;
      });
    },
     inputChargeCode(ev,index){
      // console.log(ev);
      if(ev){
        this.detailAll[index]['charge_code'] = ev.description_overide;
      }
    },
    loadCriteria() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=msar`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchCriteria = response.data.datas;
      });
    },
    resetForm() {
      this.forms = [];
    },
 addField() {
      this.detailAll.push({
        criteria: null,
        charge_code: null,
        chargeCodes:null,
      });
    },
    backForm() {
      window.location.href = "/billable-acc-rule";
    },

     loadData(id) {
      const baseURI =
        this.$settings.endPoint + "rm-billable-acc-rule/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
         this.forms = response.data.datas;
         let detail = this.forms.detail;
          detail.forEach((data)=>{
            this.detailAll.push({
              chargeCodes: {
                description_overide:data.charge_code
              },
              criteria:data.criteria,
              charge_code:data.charge_code
            });
          });
        // this.detailAll = response.data.datas.detail;
        // this.detailAll.forEach((data , key)=>{
        //   this.detailAll[key]['chargeCodes'] = {
        //     description_overide : data.charge_code
        //   };  
        // });
        // this.form={ name: 'asep', usernmae:res.username };
      });
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          setTimeout(() => {
            // if(this.company_id==null || this.company_id=='' || this.company_id==undefined){
            //   window.location.href = '/billable-method';
            // }else{
            //   var params = this.$onRandom(this.company_id);
            //   window.location.href = '/billable-method?company_id='+params;
            // }
          }, 3000);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (
          permision.permission.controller ==
          "RmFreightBillablePartiesController"
        ) {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_update) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    submitData() {
      this.forms.detail = this.detailAll;
    var baseURI =
        this.$settings.endPoint +
        `rm-billable-acc-rule/update/` +
        this.forms.rm_billable_acc_rules_id;
      this.$http
        .patch(baseURI, this.forms)
        .then((response) => {
          if (response.data.status === 200) {
           var params = this.$onRandom(this.forms.rm_billable_acc_rules_id);
                  window.location.href = "/billable-acc-rule/detail/" + params + "?status=200&msg=Successfully";
                
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    loadCompany() {
      const baseURI = this.$settings.endPoint + "company";
      //this.isLoading = true;

      return this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          this.fetchCompany = response.data.datas.data;
        })
        .catch((error) => {
          //this.isLoading = false;

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              // if(error.response.status === 422) {
              //   this.errors = error.response.data.errors;
              //   this.resultError(error.response.data.errors);
              // }else if (error.response.status === 500) {
              //     this.$router.push('/server-error');
              // }else if (error.response.status === 401) {
              //     this.$router.push("/authorized-error");
              // }else{
              //     this.$router.push('/page-not-found');
              // }
            }
          }
        });
    },

    asyncSearchCompany(ev) {
      this.fetchCompany = [];
      const baseURI = this.$settings.endPoint + "company?sap_code=" + ev;
      //this.isLoading = true;

      return this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          this.fetchCompany = response.data.datas.data;
        })
        .catch((error) => {
          //this.isLoading = false;

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {
     var params = this.$onBehind(this.$route.params.id);
    this.loadData(params[0]);
  },
  mounted() {
    this.fetchIt();
    this.loadCriteria();
    this.loadChargeCode();
  },
};
</script>
  <style scoped>
</style>