<template>
  <div>
    <div class="main-wrapper">
        <div class="loader" v-if="this.isLoading"></div>   
        <div class="loadertext" v-if="this.isLoading"> {{$t('loadingTxt')}}...</div>   
        <sidebar-component classMenu="BranchIndex"></sidebar-component>
            <div class="page-wrapper">
                <navbar-component></navbar-component>
                    <div class="page-content">
                    <div
                        class="
                        d-flex
                        justify-content-between
                        align-items-center
                        flex-wrap
                        grid-margin
                        "
                    >
                        <div>
                        <h4 class="mb-12 mb-md-0"></h4>
                        </div>

                        <div class="col-lg-12 col-xl-12 stretch-card">
                        <div class="card">
                            <div class="card-header">
                            <h4 style="margin-right: 5px" class="float-start">SKU</h4>
                            <button
                                v-if="permision_role.method_create"
                                class="btn btn-primary float-end btn-xs"
                                style="margin-right: 5px"
                                @click.prevent="createData()"
                            >
                                <i class="link-icon" data-feather="plus"></i>
                                {{ $t("createNew") }}
                            </button>

                            <button
                                class="btn btn-warning float-end btn-xs"
                                style="margin-right: 5px"
                                @click.prevent="uploadData()"
                            >
                                <i class="link-icon" data-feather="upload"></i>
                                {{ $t("uploadData") }} .xlsx
                            </button>
                            

                            <button
                                v-if="permision_role.method_read"
                                class="btn btn-success float-end btn-xs"
                                style="margin-right: 5px"
                                @click.prevent="downloadData()"
                            >
                                <i class="link-icon" data-feather="download"></i>
                                {{ $t("downloadData") }} .xlsx
                            </button>
                            </div>

                            <div class="card-body">
                            <vue-good-table
                                title="list-user"
                                mode="remote"
                                @on-page-change="onPageChange"
                                @on-sort-change="onSortChange"
                                @on-column-filter="onColumnFilter"
                                @on-per-page-change="onPerPageChange"
                                :totalRows="totalRecords"
                                :line-numbers="true"
                                :isLoading.sync="isLoading"
                                :pagination-options="{
                                enabled: true,
                                }"
                                :rows="rows"
                                :columns="columns"
                            
                            >
                                <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field == 'actions'">
                                        <a
                                        href="#"
                                        v-if="permision_role.method_read"
                                        title="Detail"
                                        @click.prevent="
                                            detailData(props.index, props.row)
                                        "
                                        >
                                        <i class="mdi mdi-eye" style="font-size:16px"></i>
                                        
                                        </a>
                                        <a
                                        href="#"
                                        v-if="permision_role.method_update"
                                        title="Edit"
                                        @click.prevent="editData(props.index, props.row)"
                                        >
                                        <i class="mdi mdi-square-edit-outline" style="font-size:16px"></i>
                                        
                                        </a>

                                        <a
                                        href="#"
                                        v-if="permision_role.method_delete"
                                        title="Delete"
                                        @click.prevent="
                                            deleteData(props.index, props.row)
                                        "
                                        >
                                        <i class="mdi mdi-trash-can-outline" style="font-size:16px"></i> 
                                        </a>
                                </span>
                                <span v-else>
                                    {{ props.formattedRow[props.column.field] }}
                                </span>
                                </template>
                            </vue-good-table>
                            </div>
                        </div>
                        </div>
                    </div>
                </div> 
            <footer-component></footer-component>
            </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
export default {
    name:"SkuComponent",
    components: {
        "sidebar-component": sidebarComponent,
        "navbar-component": navbarComponent,
        "footer-component": footerComponent,
    },
    data(){
        return {
            isLoading:false,
            errors: [],
            permision_role:[],
            langs: ["id", "en"],
            detailUser: [],
            totalRecords: 0,
            serverParams: {
                columnFilters: {},
                sort: {
                field: "",
                type: "",
                },
                page: 1,
                per_page: 10,
            },
            columns: [
                {
                label: "Company",
                field: "company_name",
                filterOptions: {
                    enabled: true, // enable filter for this column
                    placeholder: "Filter By Company ", // placeholder for filter input
                    filterValue: "", // initial populated value for this filter
                    filterDropdownItems: [], // dropdown (with selected values) instead of text input
                    trigger: "enter", //only trigger on enter not on keyup
                },
                },

                {
                label: "Sku Code",
                field: "sku_code",
                filterOptions: {
                    enabled: true, // enable filter for this column
                    placeholder: "Filter By Code", // placeholder for filter input
                    filterValue: "", // initial populated value for this filter
                    filterDropdownItems: [], // dropdown (with selected values) instead of text input
                    trigger: "enter", //only trigger on enter not on keyup
                },
                },
                {
                label: "Description",
                field: "sku_description",
                filterOptions: {
                    enabled: true, // enable filter for this column
                    placeholder: "Filter By Description", // placeholder for filter input
                    filterValue: "", // initial populated value for this filter
                    filterDropdownItems: [], // dropdown (with selected values) instead of text input
                    trigger: "enter", //only trigger on enter not on keyup
                },
                },


            

                {
                label: "SKU Long Description",
                field: "sku_long_description",
                filterOptions: {
                    enabled: true, // enable filter for this column
                    placeholder: "Filter By Sku Long Description", // placeholder for filter input
                    filterValue: "", // initial populated value for this filter
                    filterDropdownItems: [], // dropdown (with selected values) instead of text input
                    trigger: "enter", //only trigger on enter not on keyup
                },
                },

                {
                label: "Barcode",
                field: "barcode",
                filterOptions: {
                    enabled: true, // enable filter for this column
                    placeholder: "Filter By Barcode ", // placeholder for filter input
                    filterValue: "", // initial populated value for this filter
                    filterDropdownItems: [], // dropdown (with selected values) instead of text input
                    trigger: "enter", //only trigger on enter not on keyup
                },
                },

                {
                label: "Package",
                field: "package_name",
                filterOptions: {
                    enabled: true, // enable filter for this column
                    placeholder: "Filter By Package ", // placeholder for filter input
                    filterValue: "", // initial populated value for this filter
                    filterDropdownItems: [], // dropdown (with selected values) instead of text input
                    trigger: "enter", //only trigger on enter not on keyup
                },
                },

                {
                label: "Commodity",
                field: "commodity_code",
                filterOptions: {
                    enabled: true, // enable filter for this column
                    placeholder: "Filter By Commodity ", // placeholder for filter input
                    filterValue: "", // initial populated value for this filter
                    filterDropdownItems: [], // dropdown (with selected values) instead of text input
                    trigger: "enter", //only trigger on enter not on keyup
                },
                },

                {
                label: "Stackable",
                field: "stackable",
                filterOptions: {
                    enabled: true, // enable filter for this column
                    placeholder: "Filter By Commodity ", // placeholder for filter input
                    filterValue: "", // initial populated value for this filter
                    filterDropdownItems: [], // dropdown (with selected values) instead of text input
                    trigger: "enter", //only trigger on enter not on keyup
                },
                },

            
                
                {
                label: "Action",
                field: "actions",
                sortable: false,
                },
            ],
            rows: [],
        }
    },
    methods:{
        fetchIt() {
            const userDatas     = this.$getUserInfo();
            this.detailUser     = userDatas.sub;
            // this.permision_role = userDatas.sub.permision_role;
            let rolePermision = [];
            userDatas.sub.permision_role.forEach(function(permision){
                if(permision.permission.controller == 'SkuController'){
                rolePermision = permision;
                console.log(permision);
                }
            });

            if(!rolePermision.method_read){
                this.$router.push("/authorized-error");
            }
            this.permision_role = rolePermision;
        },
        createData(){
            window.location.href = "/sku-test/add";
        },
        loadItems() {
            const baseURI = this.$settings.endPoint + "sku";

            //var columnFilters = this.serverParams.columnFilters;
            var columnFilters = this.serverParams.columnFilters;
            var package_name = columnFilters["package_name"];
            var company_name = columnFilters["company_name"];
            var commodity_code = columnFilters["commodity_code"];

            return this.$http
                .get(
                baseURI +
                    `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&company_name=${company_name}&package_name=${package_name}&commodity_code=${commodity_code}&sku_code=${this.serverParams.columnFilters.sku_code}&sku_description=${this.serverParams.columnFilters.sku_description}&sku_long_description=${this.serverParams.columnFilters.sku_long_description}&barcode=${this.serverParams.columnFilters.barcode}`,
                )
                .then((response) => {
            
                this.rows = response.data.datas.data;
                this.totalRecords = response.data.datas.total;
                });
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
            this.loadItems();
        },
        onColumnFilter(params) {
            this.updateParams(params);
        },

        onPageChange(params) {
        this.updateParams({ page: params.currentPage });
        },

        onSortChange(params) {
        this.updateParams({
            sort: {
            type: params[0].type,
            field: params[0].field,
            },
        });
        },

        onPerPageChange(params) {
        this.updateParams({ per_page: params.currentPerPage });
        },

        resultError(data) {
        var count = Object.keys(data).length;
        for (var x = 0; x < count; x++) {
            var nameOb = Object.keys(data)[x];
            var objectData = data[nameOb];
            for (var y = 0; y < objectData.length; y++) {
            this.error(objectData[y]);
            }
        }
        },

        success(kata) {
        const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
        });
        Toast.fire({
            icon: "success",
            title: kata,
        });
        },
        editData(index,row){
            var param = this.$onRandom(row.sku_id);
            window.location.href = "/sku-test/edit/"+param;
        },
        error(kata) {
        const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
        });
        Toast.fire({
            icon: "error",
            title: kata,
        });
        },
        deleteData(index, row) {
        this.$swal({
          title: this.$t("areYouSure"),
          text: this.$t("yourDataDelete"),
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        }).then((result) => {
          if (result.value) {
            var formData = {
              status: status,
            };
            const baseURI = this.$settings.endPoint + "sku/delete/" + row.sku_id;
            this.$http
              .delete(baseURI, formData)
              .then((response) => {
                this.loading();
                if (response.data.status === 200) {
                  this.success(response.data.datas.messages);
                  window.location.href = "/sku-test";
                } else {
                  this.errors = response.data.errors;
                  this.resultError(response.data.errors);
                }
              })
              .catch((error) => {
                this.loading();
                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.error(error.response.data.errors.message);
                  } else {
                    this.$router.push("/page-not-found");
                  }
                }
              });
          }
        });
      },
  
        fade(sType) {
        this.isLoading = sType;
        },
        detailData(index,row){
            var hashId = this.$onRandom(row.sku_id);
            window.location.href =
              "/sku-test/detail/" + hashId + "?status=200&msg=Successfully";
        },
        loading() {
        this.fade(true);
        setTimeout(() => {
            this.isLoading = false;
        }, 1000); // hide the message after 3 seconds
        },


        logout() {
        this.$logoutUser();
        this.$router.push("/");
        },
    },
    mounted(){
        this.fetchIt();
    }
}
</script>

<style>

</style>