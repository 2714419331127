<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TolRouteEdit"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 25px"
                  >
                  Edit Rute Penyebrangan
                  </h6>
                  <button
                    class="btn btn-default float-end"
                    @click="resetForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>

                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="d-flex justify-content-between align-items-baseline mb-2"
                    ></div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Nama Rute Penyebrangan</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="nama"
                          :placeholder="'Nama Rute Tol'"
                          v-model="forms.nama"
                        />
                        <div v-if="errors.nama">
                          <div
                            v-for="error in errors.nama"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Type Truck</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <multiselect
                          @search-change="asyncTypeTruck"
                          v-model="typeTruck"
                          :options="fetchTypeTruck"
                          label="type_truck"
                          track-by="type_truck"
                          placeholder="Please Select Type Truck"
                          @input="loadPenyebrangan"
                        >
                          <span slot="noResult">-</span>
                        </multiselect>
                        <div v-if="errors.type_truck">
                          <div
                            v-for="error in errors.type_truck"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Rute</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          :value="getRoute"
                        />
                        <div v-if="errors.rute">
                          <div
                            v-for="error in errors.rute"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Penyebrangan</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          :value="getPenyebrangan"
                        />
                        <div v-if="errors.penyeberangan">
                          <div
                            v-for="error in errors.penyeberangan"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Total Biaya</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          :value="'Rp.' + getBiaya.toLocaleString()"
                        />
                        <div v-if="errors.total_biaya">
                          <div
                            v-for="error in errors.total_biaya"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-sm-6">
                        <div class="card">
                          <div class="card-header">
                            <h6>Rute</h6>
                            <button
                              class="btn btn-success btn-sm float-end"
                              @click="addData(route)"
                              type="button"
                            >
                              <i class="link-icon" data-feather="plus"></i>
                              Add
                            </button>
                          </div>
                          <div class="card-boy">
                            <div class="row">
                              <div class="table-responsive">
                                <table
                                  class="table table-bordered mb-3"
                                  style="min-height: 400px"
                                >
                                  <thead>
                                    <tr>
                                      <td>Rute</td>
                                      <td>Action</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(item, index) in route"
                                      :key="index"
                                    >
                                      <td>
                                        <multiselect
                                          @search-change="asyncRoute"
                                          v-model="item.route"
                                          :options="fetchRoute"
                                          @input="
                                            assignRoute(index, route, $event)
                                          "
                                          label="route_name"
                                          track-by="route_name"
                                          placeholder="Please Select Rute"
                                        >
                                          <span slot="noResult">-</span>
                                        </multiselect>
                                      </td>
                                      <td>
                                        <a
                                          href="#"
                                          title="Delete"
                                          @click.prevent="
                                            removeField(index, route)
                                          "
                                        >
                                          <i
                                            class="mdi mdi-trash-can-outline"
                                            style="font-size: 16px"
                                          ></i>
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="card">
                          <div class="card-header">
                            <h6>Penyebrangan</h6>
                            <button
                              class="btn btn-success btn-sm float-end"
                              @click="addData(penyebrangan)"
                              type="button"
                              :disabled="this.typeTruck == null"
                            >
                              <i class="link-icon" data-feather="plus"></i>
                              Add
                            </button>
                          </div>
                          <div class="card-boy">
                            <div class="row">
                              <div class="table-responsive">
                                <table
                                  class="table table-bordered mb-3"
                                  style="min-height: 400px"
                                >
                                  <thead>
                                    <tr>
                                      <td>Nama Penyebrangan</td>
                                      <td>Harga</td>
                                      <td>Action</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(item, index) in penyebrangan"
                                      :key="index"
                                    >
                                      <td>
                                        <multiselect
                                          @search-change="asyncPenyebrangan"
                                          v-model="item.penyebrangan"
                                          :options="fetchPenyebrangan"
                                          @input="
                                            assignPenyebrangan(
                                              index,
                                              penyebrangan,
                                              $event
                                            )
                                          "
                                          label="nama"
                                          track-by="nama"
                                          placeholder="Please Select Rute"
                                        >
                                          <span slot="noResult">-</span>
                                        </multiselect>
                                      </td>
                                      <td>
                                        Rp.
                                        {{
                                          item.harga
                                            ? item.harga
                                            : 0 | format_number
                                        }}
                                      </td>
                                      <td>
                                        <a
                                          href="#"
                                          title="Delete"
                                          @click.prevent="
                                            removeField(index, penyebrangan)
                                          "
                                        >
                                          <i
                                            class="mdi mdi-trash-can-outline"
                                            style="font-size: 16px"
                                          ></i>
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <button
                        class="btn btn-warning text-black fw-bolder btn-icon-text"
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button
                        class="btn btn-primary float-end btn-sm"
                        type="submit"
                      >
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import multiselect from "vue-multiselect";

export default {
  name: "SKUEdit",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    multiselect: multiselect,
  },
  computed: {
    getRoute() {
      let text = "";
      this.route.forEach(function (rute, index) {
        if (rute.rute) {
          if (index !== 0) {
            text += "; ";
          }
          text += rute.rute;
        }
      });
      return text;
    },
    getPenyebrangan() {
      let text = "";
      this.penyebrangan.forEach(function (rute, index) {
        if (rute.nama) {
          if (index !== 0) {
            text += "; ";
          }
          text += rute.nama;
        }
      });
      return text;
    },
    getBiaya() {
      let text = 0;
      this.penyebrangan.forEach(function (rute) {
        if (rute.harga) {
          text += rute.harga;
        }
      });
      return text;
    },
  },
  data() {
    return {
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      permision_role: [],
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      company: [],
      packageData: [],
      commodity: [],
      forms: [],
      tolBerangkat: [],
      tolPulang: [],
      fetchGerbangTol: [],

      route: [],
      fetchRoute: [],

      penyebrangan: [],
      fetchPenyebrangan: [],

      typeTruck: null,
      fetchTypeTruck: [],
    };
  },
  watch: {},
  methods: {
    resetForm() {
      this.forms.name = "";
      this.tolBerangkat = [];
      this.tolPulang = [];
    },

   
    backForm() {
      window.location.href = "/master-rute-penyebrangan";
    },
    
    addData(field) {
      field.push({
        tol: "",
      });
    },
    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },
    assignTol(index, fieldType, ev) {
      let error = 0;
      fieldType.forEach(function (field) {
        if (field.toll_gate_name == ev.toll_gate_name) {
          error = 0; // dirubah awalnya 1 by andez
        }
      });
      if (error === 0) {
        fieldType[index].rm_toll_gate_master_id = ev.rm_toll_gate_master_id;
        fieldType[index].toll_gate_name = ev.toll_gate_name;
      } else {
        this.removeField(index, fieldType);
        this.error("Duplicate Value !!!");
      }
    },
    assignRoute(index, fieldType, ev) {
      let error = 0;
      console.log(ev);
      fieldType.forEach(function (field) {
        if (field.rute == ev.route_name) {
          error = 1;
        }
      });
      if (error === 0) {
        fieldType[index].rm_route_master_id = ev.rm_route_master_id;
        fieldType[index].rute = ev.route_name;
      } else {
        this.removeField(index, fieldType);
        this.error("Duplicate Value !!!");
      }
    },
    assignPenyebrangan(index, fieldType, ev) {
      let error = 0;
      fieldType.forEach(function (field) {
        if (field.nama == ev.nama) {
          error = 1;
        }
      });
      if (error === 0) {
        fieldType[index].rm_ferry_master_id = ev.rm_ferry_master_id;
        fieldType[index].nama = ev.nama;
        fieldType[index].harga = ev.biaya;
      } else {
        this.removeField(index, fieldType);
        this.error("Duplicate Value !!!");
      }
    },
    loadGerbangTol() {
      const baseURI = this.$settings.endPoint + "rm-toll-gate-master";
      this.$http.get(baseURI).then((response) => {
        this.loading();
        this.fetchGerbangTol = response.data.datas.data;
      });
    },
    asyncGerbangTol(ev) {
      const baseURI = this.$settings.endPoint + "rm-toll-gate-master";
      this.$http.get(baseURI + "?toll_gate_name=" + ev).then((response) => {
        this.loading();
        this.fetchGerbangTol = response.data.datas.data;
      });
    },
    loadRoute() {
      const baseURI = this.$settings.endPoint + "rm-route-master";
      this.$http.get(baseURI).then((response) => {
        this.loading();
        this.fetchRoute = response.data.datas.data;
      });
    },
    asyncRoute(ev) {
      const baseURI = this.$settings.endPoint + "rm-route-master";
      this.$http.get(baseURI + "?route_name=" + ev).then((response) => {
        this.loading();
        this.fetchRoute = response.data.datas.data;
      });
    },
    loadPenyebrangan() {
      this.fetchPenyebrangan = [];
      if (this.typeTruck !== null) {
        const baseURI =
          this.$settings.endPoint +
          "rm-ferry-master/select?type_truck=" +
          this.typeTruck.type_truck;
        this.$http.get(baseURI).then((response) => {
          this.loading();
          this.fetchPenyebrangan = response.data.datas.data;
        });
      }
    },
    asyncPenyebrangan(ev) {
      const baseURI = this.$settings.endPoint + "rm-ferry-master/select?type_truck="+this.typeTruck.type_truck;
      this.$http.get(baseURI + "&nama=" + ev).then((response) => {
        this.loading();
        this.fetchRoute = response.data.datas.data;
      });
    },
    loadTypeTruck() {
      const baseURI = this.$settings.endPointCt + `type-truck`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTypeTruck = response.data.datas.data;
      });
    },
    asyncTypeTruck(ev) {
      const baseURI = this.$settings.endPointCt + `type-truck?type_truck=${ev}`;
      this.fetchTruckType = [];
      return this.$http.get(baseURI).then((response) => {
        this.fetchTypeTruck = response.data.datas.data;
      });
    },
    loadData(id) {
      const baseURI = this.$settings.endPoint + "rm-ferry-rute/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        this.forms = response.data.datas;
        this.typeTruck = {
          type_truck: this.forms.type_truck,
          category: this.forms.category,
        };
        this.forms.detail.forEach((data, index) => {
          data["route_name"] = data.rute;
          this.route.push({
            route: data,
          });
          this.assignRoute(index, this.route, data);
        });
        this.forms.ferry_master.forEach((data, index) => {
          data["biaya"] = data.harga;
          this.penyebrangan.push({
            penyebrangan: data,
          });
          this.assignPenyebrangan(index, this.penyebrangan, data);
        });

        this.loadPenyebrangan()
      });
    },

    loadCompany() {
      const baseURI = this.$settings.endPoint + "company?limit=10";

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },

    loadCompanyNotLinc() {
      const baseURI = this.$settings.endPoint + `company/select`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },

    loadPackage(company_code) {
      const baseURI =
        this.$settings.endPoint + "package/select-package/" + company_code;

      return this.$http.get(baseURI).then((response) => {
        this.fetchPackage = response.data.datas.data;
      });
    },

    companyChange(ev) {
      let company_code = ev.code;
      const baseURI =
        this.$settings.endPoint + "package/select-package/" + company_code;
      return this.$http
        .get(baseURI)
        .then((response) => {
          this.fetchPackage = response.data.datas;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 422) {
              this.packageData = [];
            } else {
              this.packageData = [];
            }
          }
        });
    },

    asyncSearchPackage(ev) {
      const baseURI = this.$settings.endPoint + "package?package_name=" + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchPackage = response.data.datas.data;
      });
    },

    loadCommodity() {
      const baseURI = this.$settings.endPoint + "commodity?limit=10";

      return this.$http.get(baseURI).then((response) => {
        this.fetchCommodity = response.data.datas.data;
      });
    },

    asyncSearchCommodity(ev) {
      const baseURI =
        this.$settings.endPoint + "commodity?commodity_name=" + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchCommodity = response.data.datas.data;
      });
    },

    asyncSearchCompany(ev) {
      const baseURI = this.$settings.endPoint + "company/select?code=" + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },

    asyncSearchCompanyNotLinc(ev) {
      const baseURI =
        this.$settings.endPoint +
        "company?name=" +
        ev +
        "&code=" +
        this.companyCode;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RmFerryRuteController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_update) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    submitData() {


      this.$swal({
          title: 'Apakah anda yakin akan mengkalkulasi Toll Route ?',
          text: '',
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
          html: `
          <p>Semua record Payable akan mengalamin kenaikan sesuai dengan exponensial kenaikan harga toll Route ini</p><br>
                          <input type="checkbox" id="kalkulasiRekon" >
                          <label for="kalkulasiRekon">Kalkulasi Rekon</label>
                      `,
        }).then((result) => {
          if (result.value) {
            const checkbox = document.getElementById('kalkulasiRekon');
            var rekon = checkbox.checked;

            this.fade(true);
            const baseURI =
              this.$settings.endPoint +
              "rm-ferry-rute/update/" +
              this.forms.rm_ferry_rute_id;
            this.forms.rute = this.getRoute;
            this.forms.penyeberangan = this.getPenyebrangan;
            this.forms.total_biaya = this.getBiaya;
            this.forms.type_truck = this.typeTruck.type_truck;
            this.forms.category = this.typeTruck.category;
            this.forms.detail = this.route;
            this.forms.ferry_master = this.penyebrangan;
            this.forms.rekon=rekon==true ? 'yes': 'no';
            this.$http
              .patch(baseURI, this.forms)
              .then((response) => {
                this.loading();
                if (response.data.status === 200) {
                  //this.resetForm();
                  this.errors = [];
                  this.success(response.data.datas.messages);

                  //window.history.back();
                  var params = this.$onRandom(this.forms.rm_ferry_rute_id);
                  window.location.href =
                    "/master-rute-penyebrangan/detail/" + params + "?status=200&msg=Successfully";
                } else {
                  this.errors = response.data.errors;
                  this.resultError(response.data.errors);
                }
              })
              .catch((error) => {
                this.loading();
                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.$router.push("/server-error");
                  } else {
                    this.$router.push("/page-not-found");
                  }
                }
                //this.resetForm();
              });

          }
        });

      
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.loadData(params[0]);
    this.fetchIt();
    this.loadRoute();
    this.loadTypeTruck();
  },
};
</script>
<style scoped></style>
