<template>
    <div :class="{'blur-content': isLoading}">
      <div class="main-wrapper">
        <!--begin::sidebar-->
        <sidebar-component classMenu="Company"></sidebar-component>
        <!--end::sidebar-->
  
        <div class="page-wrapper">
          <!--begin::navbar-->
          <navbar-component></navbar-component>
          <!--end::navbar-->
  
          <!--begin::content-->
          <div class="page-content">
            <div
              class="
                d-flex
                justify-content-between
                align-items-center
                flex-wrap
                grid-margin
              "
            >
              <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
                <div class="card">
                  <div class="card-header">
                        <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                         &nbsp;&nbsp;&nbsp;
                        <h6 class="card-title mb-0 float-start" style="padding-left: 25px">{{ $t("vendorUpload") }}</h6>
                        <button
                          class="btn btn-success btn-xs float-end"
                          @click="downloadData"
                          type="button"
                        >
                        <i class="link-icon" data-feather="download"></i>
                          {{ $t("downloadData") }} Template
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                          class="btn btn-default btn-sm float-end"
                          @click="resetForm"
                          type="button"
                        >
                        <i class="link-icon" data-feather="repeat"></i>
                          {{ $t("resetFormTxt") }}
                        </button>
                 
                  </div>
                  <div class="card-body">
                    <!--begin::loading-data-->
                    <div v-if="isLoading" class="d-flex align-items-center">
                      <div
                        class="spinner-border ms-auto"
                        role="status"
                        aria-hidden="true"
                      ></div>
                      <strong>{{ $t("loadingTxt") }}...</strong>
                    </div>
                    <!--end::loading-data-->
  
                    <form
                      class="forms-sample"
                      @submit.prevent="submitData"
                      method="POST"
                      enctype="multipart/form-data"
                    >
                      <div
                        class="
                          d-flex
                          justify-content-between
                          align-items-baseline
                          mb-2
                        "
                      >
                      
                      </div>
                      
                      
                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{ $t("file") }}</label>
                        </div>
                        <div class="col-lg-8">
                         
                          <input type="file" ref="file" class="form-control" @change="handleFileUpload($event)" />
  
                          <div v-if="errors.code">
                            <div
                              v-for="error in errors.code"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
  
  
  
  
  
  
  
                     
                      <div class="card-footer">
                        <button
                          class="
                            btn btn-warning
                            text-black
                            fw-bolder
                            btn-icon-text
                          "
                          @click="backForm"
                          type="button"
                        >
                          <i class="link-icon" data-feather="arrow-left"></i>
                          {{ $t("backMess") }}
                        </button>
  
                        &nbsp;&nbsp;&nbsp;
                        <button
                          class="btn btn-primary float-end btn-sm"
                          type="submit"
                        >
                          <i class="link-icon" data-feather="save"></i>
                          {{ $t("submitFormTxt") }}
                        </button>
                      </div>
  
                    </form>
                  </div>
                </div> <!--end::card-->
              </div>
            </div>
          </div>
          <!--end::content-->
  
          <!--begin::footer-->
          <footer-component></footer-component>
          <!--end::footer-->
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import sidebarComponent from "@/components/_partials/_sidebar";
  import navbarComponent from "@/components/_partials/_navbar";
  import footerComponent from "@/components/_partials/_footer";
  
  export default {
    name: "FakturPajakVendorUpload",
    props: {},
    components: {
      "sidebar-component": sidebarComponent,
      "navbar-component": navbarComponent,
      "footer-component": footerComponent,
    },
    data() {
      return {
        file: '',
        permision_role:[],
        isLoading: false,
        maxToasts: 100,
        position: "up right",
        closeBtn: true,
        errors: [],
        userData: "",
        companyCode: "",
        forms: { name: "", usernmae: "" },
        fetchSystemCode:[],
        company_type:"",
        userRole: "",
      };
    },
    watch: {},
    methods: {
      resetForm() {
  
        this.$refs.file.value = null;
      },
  
      
      handleFileUpload( event ){
        this.file = event.target.files[0];
      },
  
      downloadData() {
        const baseURI = this.$settings.endPoint + "/company-faktur-pajak/download-template";
  
        var file_name = "template_upload_vendor.xlsx";
  
        //var columnFilters = this.serverParams.columnFilters;
  
  
        return this.$http
          .get(
            baseURI,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
           
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");
  
            fileLink.href = fileURL;
            fileLink.setAttribute("download", file_name);
            document.body.appendChild(fileLink);
            fileLink.click();
            this.fade(false);
          });
      },
  
  
      fetchIt() {
        const userDatas     = this.$getUserInfo();
        this.userData       = userDatas.sub;
        this.userRole       = userDatas.sub.role;
        // this.permision_role = userDatas.sub.permision_role;
        let rolePermision = [];
        userDatas.sub.permision_role.forEach(function(permision){
          if(permision.permission.controller == 'VendorFPController'){
            rolePermision = permision;
            // console.log(permision);
          }
        });
  
        if(!rolePermision.method_upload){
          this.$router.push("/authorized-error");
        }
        this.permision_role = rolePermision;
  
      },
  
      backForm() {
         window.location.href = "/faktur-pajak/vendor";
      },
  
  
      submitData() {
        this.$swal({
          title: this.$t("areYouSure"),
          text: this.$t("yourData"),
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        }).then((result) => {
          if (result.value) {
            this.fade(true);
  
            let formData = new FormData();
            formData.append("file", this.file);
           
            const baseURI  =  this.$settings.endPoint+"company-faktur-pajak/upload";
            this.$http.post(baseURI,formData).then((response) => {
                this.loading();
                if(response.data.status === 200) {
                this.resetForm();
                    this.errors = [];
                    window.location.href = "/faktur-pajak/vendor";
                }else{
                    this.errors = response.data.errors;
                    this.resultError(response.data.errors);
                }
            }).catch(error => {
                this.loading();
                if (error.response) {
                if(error.response.status === 422) {
                        this.errors = error.response.data.errors;
                        this.resultError(error.response.data.errors);
                }else if (error.response.status === 500) {
                    this.$router.push('/server-error');
                }else{
                    this.$router.push('/page-not-found');
                }
                }
                this.resetForm();
            });
          }
        });
      },
  
      fade(sType) {
        this.isLoading = sType;
      },
  
      loading() {
        this.fade(true);
        setTimeout(() => {
          this.fade(false);
        }, 1000); // hide the message after 3 seconds
      },
  
      resultError(data) {
        var count = Object.keys(data).length;
        for (var x = 0; x < count; x++) {
          var nameOb = Object.keys(data)[x];
          var objectData = data[nameOb];
          for (var y = 0; y < objectData.length; y++) {
            this.error(objectData[y]);
          }
        }
      },
  
      success(kata) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: kata,
        });
      },
  
      error(kata) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: kata,
        });
      },
    },
    events: {},
    created: function () {},
    mounted() {
      this.fetchIt();
    },
  };
  </script>
  <style scoped>
  .blur-content{
    filter: blur(5px); 
  }
  </style>