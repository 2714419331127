<template>
  <div class="col-lg-12 col-xl-12 stretch-card">
    <div class="card">
      <div class="card-header">
        <h4 style="margin-right: 5px" class="float-start">TRANSPORT ORDER | STATUS: SUBMITTED </h4>


        <button v-if="permision_role.method_read" class="btn btn-success float-end btn-xs" style="margin-right: 5px"
          @click.prevent="downloadData()">
          <i class="link-icon" data-feather="download"></i>
          {{ $t("downloadData") }} .xlsx
        </button>
      </div>

      <div class="card-body">

        <div class="table-responsive" style="overflow-y: auto;">
          <div class="col-sm-12">

            <vue-good-table title="list-order-ct" ref="list-order-ct" mode="remote" :select-options="{
          enabled: selectOnCheckboxOnly,
          selectOnCheckboxOnly: true,
          selectionText: $t('data_selected'),
          clearSelectionText: $t('clear'),
        }" @on-selected-rows-change="selectionChanged" @on-page-change="onPageChange" @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
              :line-numbers="true" :isLoading.sync="isLoading" :pagination-options="{
          enabled: true,
        }" :rows="rows" :columns="columns">
              <div slot="table-actions">
                {{ $t("no_select_data") }}
              </div>
              <div slot="selected-row-actions">

                <button class="btn btn-warning btn-xs" v-if="btnPriority" style="margin-right:20px;"
                  @click="priorityModal"><i class="mdi mdi-file-document"></i> Priority </button>

                <button class="btn btn-secondary btn-xs" v-if="btnFreePick" style="margin-right:20px;"
                  @click="freePick"><i class="mdi mdi-file-delimited"></i> Free Pick </button>

                <button class="btn btn-warning btn-xs" v-if="btnMassFreePick" style="margin-right:20px;"
                  @click="massfreePick"><i class="mdi mdi-file-delimited"></i> Mass Free Pick </button>

                <button class="btn btn-success btn-xs" v-if="btnMassAccepted" style="margin-right:20px;"
                  @click="acceptMassOrder"><i class="mdi mdi-check"></i> Mass Accepted </button>

                <button class="btn btn-secondary btn-xs" v-if="btnClearSelection" style="margin-right:20px;"
                  @click="clearSelection"><i class="mdi mdi-refresh"></i> Clear Selection </button>

                <button class="btn btn-success btn-xs" style="margin-right:20px;" @click="sendOrder"
                  v-if="btnSendOrder"> <i class="mdi mdi-send-outline"></i> {{ $t("send_order_txt") }} </button>

                <button class="btn btn-danger btn-xs" @click="cancelOrder" v-if="btnRejected"><i
                    class="mdi mdi-close"></i> {{ $t("reject_order_txt") }} </button>
              </div>



              <template slot="table-row" slot-scope="props">


                <span v-if="props.column.field == 'actions'">

                  <template v-if="company_code === 'LINC' || company_code === 'BP'">
                    <a style="color: #ab0635;text-decoration: none;  cursor: pointer"
                      v-if="permision_role.method_read && props.row.order_status != 'SUBMITTED'" title="Detail"
                      @click.prevent="
          detailData(props.index, props.row)
          ">
                      <i class="mdi mdi-eye" style="font-size:16px"></i>

                    </a>
                  </template>



                  <a style="color: #ab0635;text-decoration: none; cursor: pointer;"
                    v-if="permision_role.method_read && props.row.order_status == 'SUBMITTED'" title="Accept Order"
                    @click.prevent="
          acceptOrder(props.row)
          ">
                    <i class="mdi mdi-check-outline" style="font-size:16px"></i>

                  </a>
                  <a style="color: #ab0635;text-decoration: none; cursor: pointer;"
                    v-if="permision_role.method_read && props.row.order_status == 'SUBMITTED'" title="Reject Order"
                    @click.prevent="
          rejectOrder(props.row)
          ">
                    <i class="mdi mdi-close" style="font-size:16px"></i>

                  </a>

                  <!--a style="color: #ab0635;text-decoration: none;"
                          v-if="permision_role.method_update"
                          title="Edit"
                          @click.prevent="editData(props.index, props.row)"
                        >
                          <i class="mdi mdi-square-edit-outline" style="font-size:16px"></i>
                          
                        </a-->

                </span>
                <span v-else-if="props.column.field == 'pick_plan'">
                  <div :style="checkPickPlanDate(props.row.pick_plan)">
                    {{ $moment(props.row.pick_plan).format("D MMM yyyy h:mm:ss A") }}
                  </div>

                </span>


                <span v-else-if="props.column.field == 'request'">
                  <template v-if="company_code === 'LINC' || company_code === 'BP'">
                    <a style="color: #ab0635;text-decoration: none; cursor: pointer;"
                      v-if="permision_role.method_read && props.row.status_tms == 'NEW'" title="Update Order Number"
                      @click.prevent="editOrderNo(props.row)">
                      <i class="mdi mdi-square-edit-outline" style="font-size:16px"></i>

                    </a>
                  </template>


                </span>

                <span v-else-if="props.column.field == 'order_no'">
                  <a style="color: #ab0635;text-decoration: none; cursor: pointer; " v-if="permision_role.method_read"
                    title="Detail Maps" @click.prevent="clickOrder(props.row)">
                    {{ props.row.order_no }}

                  </a>


                </span>

                <span v-else-if="props.column.field == 'submit_time'">

                  <div :style="checkSubmitDate(props.row)">
                    {{ $moment(props.row.submit_time).format("D MMM yyyy h:mm:ss A") }}</div>

                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>

          </div>
        </div>



      </div>

    </div>
    <modal-reject-data :showModal="showModalRejectData" :data="this.transport_order_header_ids"
      @closeModal="closeMyModal"></modal-reject-data>
    <modal-request-reschedule :showModal="showModalRequestReschedule" :data="passData" @closeModal="closeMyModal">
    </modal-request-reschedule>

    <modal-priority :showModal="showModalPriority" :data="this.rowsSelection" @closeModal="closeMyModal">
    </modal-priority>

    <modal-freepick :showModal="showModalFreePick" :data="this.rowsSelection" @closeModal="closeMyModal">
    </modal-freepick>

    <modal-change-orderno :showModal="showModalChangeOrderno" :data="this.changeOrderNoData" @closeModal="closeMyModal">
    </modal-change-orderno>


    <modal-mass-freepick :showModal="showModalFreePickMass" :data="this.rowsSelection" @closeModal="closeMyModal">
    </modal-mass-freepick>

    <!-- <modal-rpa
          :showModal="showModalRpa" 
          :data="{
            'shipwithref':shipwithref
          }"
          @closeModal="closeMyModal">
        </modal-rpa> -->



  </div>





</template>

<script>
import ModalTransportOrderCtRejectMass from "@/components/modal/modalTransportOrderCtRejectMass.vue";
import ModalRequestReschedule from "@/components/modal/modalRequestReschedule.vue";
import ModalPriority from "@/components/modal/modalPriority.vue";
import ModalFreePick from "@/components/modal/modalFreepick.vue";
import ModalMassFreePick from "@/components/modal/modalMassFreepick.vue";
import ModalChangeOrderNo from "@/components/modal/modalChangeOrderNo.vue";
// import ModalRpa from "@/components/modal/modalRpa";

export default {
  name: "TransportOrderCt",
  components: {
    "modal-reject-data": ModalTransportOrderCtRejectMass,
    "modal-request-reschedule": ModalRequestReschedule,
    "modal-priority": ModalPriority,
    "modal-freepick": ModalFreePick,
    "modal-mass-freepick": ModalMassFreePick,
    "modal-change-orderno": ModalChangeOrderNo,
    // "modal-rpa" : ModalRpa,
  },
  props: {
    dateRange: { type: [Object, Array] },
    status: String
  },

  data() {
    return {
      company_code: "",
      selectOnCheckboxOnly: true,
      shipwithref: '',
      btnSendOrder: false,
      btnFreePick: false,
      btnMassFreePick: false,
      btnMassAccepted: false,
      btnRejected: false,
      btnPriority: false,
      btnClearSelection: false,
      showModalRpa: false,
      showModalRejectData: false,
      showModalRequestReschedule: false,
      showModalPriority: false,
      showModalFreePick: false,
      showModalFreePickMass: false,
      showModalChangeOrderno: false,
      maxToasts: 100,
      passData: [],
      ORDER_RESPONSE_TIME: 0,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      permision_role: [],
      transport_order_header_ids: [],
      langs: ["id", "en"],
      detailUser: [],
      changeOrderNoData: [],
      detailDivisi: [],
      totalRecords: 0,
      order_no_new: "",
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [
        {
          label: this.$t("submit_time_txt"),
          field: "submit_time",
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd MMM yyyy h:mm:ss a',//'dd MMM yyyy',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t("submit_time_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        // {
        //   label: this.$t("postpone_time_txt"),
        //   field: "postpone_time",
        //   type: 'date',
        //   dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
        //   dateOutputFormat: 'dd MMM yyyy h:mm:ss a',//'dd MMM yyyy',
        //   filterOptions: {
        //     enabled: true, // enable filter for this column
        //     placeholder: "Filter By "+this.$t("postpone_time_txt"), // placeholder for filter input
        //     filterValue: "", // initial populated value for this filter
        //     filterDropdownItems: [], // dropdown (with selected values) instead of text input
        //     trigger: "enter", //only trigger on enter not on keyup
        //   },
        // },
        {
          label: this.$t("companyTxt"),
          field: "company.name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t("companyTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: this.$t("order_no_txt"),
          field: "order_no",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t("order_no_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "ShipWithRef",
          field: "shipwith_ref",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Shipment Rate",
          field: "shipment_rate",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("order_date_txt"),
          field: "order_date",
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd MMM yyyy h:mm:ss a',//'dd MMM yyyy',
          // dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          // dateOutputFormat: 'dd MMM yyyy',
          filterOptions: {
            customFilter: true,
            enabled: true, // enable filter for this column
            placeholder: "Filter By Order Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("order_status_txt"),
          field: "order_status",
          //   filterOptions: {
          //     enabled: true, // enable filter for this column
          //     placeholder: "Filter By "+this.$t("order_status_txt"), // placeholder for filter input
          //     filterValue: "", // initial populated value for this filter
          //     filterDropdownItems: ['SUBMITTED','POSTPONE'], // dropdown (with selected values) instead of text input
          //     trigger: "enter", //only trigger on enter not on keyup
          //   },
        },

        {
          label: 'Status Tms',
          field: "status_tms",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t("order_status_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter

            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("pick_name_txt"),
          field: "pick_name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t("pick_name_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("dest_name_txt"),
          field: "dest_name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t("dest_name_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: 'Type Truck',
          field: "type_truck",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Type Truck", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("transport_order_template_description_txt"),
          field: "transport_order_template.description",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t("transport_order_template_description_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("tonnage_txt"),
          field: "tonnage",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "> 0", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("cubic_txt"),
          field: "transport_order_template.cubic",
          sortable: false,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "> 0", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("pickup_plan_txt"),
          field: "pick_plan",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t("pickup_plan_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("dest_plan_txt"),
          field: "dest_plan",
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd MMM yyyy h:mm:ss a',//'dd MMM yyyy',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t("dest_plan_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: "Vehicle No",
          field: "vehicle_no",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By vehicle no", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: "Driver",
          field: "driver_name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Type Truck", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: "Auto Load",
          field: "auto_load",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Auto Load",
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Carier",
          field: "carrier",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Carier", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Remarks",
          field: "remarks",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Create by",
          field: "create_by",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: this.$t("action_txt"),
          field: "actions",
          sortable: false,
        },

        {
          label: "Request",
          field: "request",
          sortable: false,
        },
      ],
      rows: [],
      rowsSelection: [],
      shipwith_ref_send: [],
    };
  },
  watch: {
    dateRange: {
      handler(newVal) {
        console.log(newVal);
        this.loadItems();
      },

    },
  },
  computed: {
  },
  methods: {
    closeMyModal() {
      this.showModalFreePickMass = false;
      this.showModalRejectData = false;
      this.showModalRequestReschedule = false;
      this.showModalPriority = false;
      this.showModalFreePick = false;
      this.showModalChangeOrderno = false;
      // this.showModalRpa=false;
      this.loadItems();
    },

    requestRescheduleder(row) {
      this.passData = row;
      this.passData.source = "CT";
      this.showModalRequestReschedule = true;
    },


    checkSubmitDate(row) {
      const dateNow = this.$moment(new Date()).format("YYYY-MM-DD h:mm:ss");
      const dateData = this.$moment(row.submit_time).format("YYYY-MM-DD h:mm:ss");
      const dateDataPickup = this.$moment(row.pick_plan);
      const diff_in_millis = Math.abs(new Date(dateData) - new Date(dateNow))
      const diff_in_minutes = diff_in_millis / 60000
      const precentaceData = (diff_in_minutes * 100) / 25


      var hoursDataPickup = dateDataPickup.diff(dateNow, 'hours')
      if (hoursDataPickup > 48) {
        return "";
      } else {
        //console.log(diff_in_minutes);
        if (diff_in_minutes > this.ORDER_RESPONSE_TIME) {

          return {
            "background-color": "red",
            "color": "white"
          }

        } else if (precentaceData > 25) {
          return {
            "background-color": "yellow",
            "color": "black"
          }
        } else {
          return "";
        }

      }
    },

    clearSelection() {

      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
          let data = {
            shipwith_ref: this.shipwith_ref_send
          }
          const baseURI =
            this.$settings.endPointCt + "planning/delete";
          this.$http
            .post(baseURI, data, { timeout: 200000 })
            .then((response) => {
              // this.loading();
              if (response.data.status == 200) {

                // response.data.datas.messages.forEach((item) => {
                //   this.success(item);
                // });
                this.success3(response.data.datas.messages);
                this.loadItems();
                this.isloading = false;
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            }).catch(error => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {

                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.$router.push('/server-error');
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push('/page-not-found');
                  }
                }

              }

            });
        }
      });




    },
    editOrderNo(row) {
      this.changeOrderNoData = row;
      this.showModalChangeOrderno = true;
    },
    checkPickPlanDate(val) {
      const dateNow = this.$moment(new Date()).format("YYYY-MM-DD") + " 23:59:59";
      const dateData = this.$moment(val).format("YYYY-MM-DD") + " 23:59:59";
      if (new Date(dateData) < new Date(dateNow)) {

        return {
          "background-color": "red",
          "color": "white"
        }

      } else if (new Date(dateData) > new Date(dateNow)) {

        return ""

      } else {

        return {
          "background-color": "yellow",
          "color": "black"
        }

      }
    },
    rejectOrder(ev) {
      this.$refs['list-order-ct'].unselectAllInternal();
      this.transport_order_header_ids = [];
      this.transport_order_header_ids.push(ev.transport_order_header_id);
      this.showModalRejectData = true;
    },

    // selectedShifWithReff(ev){
    //   this.shipwithref=ev;
    //   this.showModalRpa=true;
    // },

    selectionChanged(ev) {

      this.rowsSelection = [];
      this.transport_order_header_ids = [];
      this.shipwith_ref_send = [];
      this.rowsSelection = ev.selectedRows;
      this.btnFreePick = false;
      this.btnPriority = false;
      this.btnMassFreePick = false;
      this.btnMassAccepted = false;
      this.btnSendOrder = false;
      this.btnClearSelection = false;
      this.btnRejected = false;

      const hasInvalidItem = this.rowsSelection.some(item =>
        item.order_status !== 'ACCEPTED' || item.vehicle_no !== null
      );


      // const hasInvalidItemAccepted = this.rowsSelection.some(item => 
      //   item.order_status !== 'SUBMITTED' || item.vehicle_no !== null
      // );


      const allItemsMassAcceptValid = this.rowsSelection.every(item =>
        item.order_status === 'SUBMITTED' && item.vehicle_no === null
      );

      const allItemsValid = this.rowsSelection.every(item =>
        item.order_status === 'ACCEPTED' && item.vehicle_no !== null || item.carrier !== '0027-BAHANA PRESTASI' && item.carrier !== null && item.order_status === 'ACCEPTED' && item.vehicle_no === null
      );

      const allItemsValidAccepted = this.rowsSelection.every(item =>
        item.order_status === 'ACCEPTED' && item.vehicle_no === null && item.carrier === null
      );


      const allItemsValidAssigned = this.rowsSelection.every(item =>
        item.order_status === 'ASSIGNED'
      );

      if (allItemsValidAssigned) {
        this.btnSendOrder = true;
        this.btnRejected = true;
      }


      if (allItemsValid) {
        this.btnRejected = true;
        this.btnClearSelection = true;
        this.btnSendOrder = true; // Set btnSendOrder to true when all items meet the condition
      }

      if (allItemsValidAccepted) {
        this.btnRejected = true;
        this.btnPriority = true;
      }

      // const allItemsValidAccepted027 = this.rowsSelection.every(item =>
      //   item.order_status === 'ACCEPTED' && item.vehicle_no === null && item.carrier!=='0027-BAHANA PRESTASI' ||  item.order_status === 'ACCEPTED' && item.vehicle_no === null && item.carrier!==''
      // );

      // if(allItemsValidAccepted027){
      //   this.btnPriority=false;
      //   this.btnFreePick=false;
      //   this.btnRejected=true;
      //   this.btnClearSelection = true;
      //   this.btnSendOrder = true; 
      // }


      //console.log(this.btnSendOrder);

      this.rowsSelection.forEach((item) => {
        //console.log(item);
        if (item.vehicle_no != null) {
          //this.btnPriority=false;
          // this.btnClearSelection=true;
        }

        if (this.rowsSelection.length == 1 && item.order_status == 'ACCEPTED' && item.vehicle_no == null) {
          if (item.carrier !== '0027-BAHANA PRESTASI' && item.carrier !== null) {
            this.btnFreePick = false;
          } else {
            this.btnFreePick = true;
          }


        }







        this.transport_order_header_ids.push(item.transport_order_header_id);
        this.shipwith_ref_send.push(item.shipwith_ref);
        // if(item.order_status=='NEW'){
        //   this.error('Order No:'+item.order_status+' Status Not Submited, because status order not NEW or SUBMITED');
        // }else{

        // }

      });


      if (!hasInvalidItem && this.rowsSelection.length > 1) {
        this.btnMassFreePick = true;
        // this.btnPriority=true;
      }



      if (allItemsMassAcceptValid) {
        this.btnMassAccepted = true;
      }






    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      this.company_code = userDatas.sub.company_detail.code;
      if (this.company_code === 'LINC' || this.company_code === 'BP') {
        this.selectOnCheckboxOnly = true;
      } else {
        this.selectOnCheckboxOnly = false;
      }


      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == 'TransportOrderHeaderCtController') {
          rolePermision = permision;
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;

      if (this.$route.query.msg) {
        if (this.$route.query.status == 200) {
          this.success(this.$route.query.msg)
        } else {
          this.error(this.$route.query.msg)
        }

      }

    },

    checkShipReff(id) {
      const baseURI =
        this.$settings.endPoint + "control-tower/transport-order-header/check-order-no-shipwithref/" + id;
      return this.$http
        .get(baseURI, {
          timeout: 200000
        }).then((response) => {
          this.countShipReff = response.data.datas;
          this.isLoading = false;
        }).catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    },

    async acceptOrder(row) {
      await this.checkShipReff(row.transport_order_header_id);

      var html = "";
      if (this.countShipReff.countData == 1) {
        html += "<p>You want to Accept Order ? </p><br>";
        html += "<p>Any Change Data ?</p>";
        html += "<p>Order Number Existing : <input class='form-control' value='" + row.order_no + "'  type='text' readonly><br>"
        html += "Order Number New  <input id='order_no_new' value='" + row.order_no + "' class='form-control' type='text'></p>"

      }
      var transport_order_header_ids = [];
      transport_order_header_ids.push(row.transport_order_header_id);
      this.$swal({
        title: this.$t("areYouSure"),
        text: "You want to Accept Order ?",
        html: html,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
        preConfirm: () => {
          if (this.countShipReff.countData == 1) {
            return [
              document.getElementById('order_no_new').value,
            ]
          }

        },
      })
        .then((result) => {
          if (result.value) {
            var formData = {
              transport_order_header_ids: transport_order_header_ids,
            };
            if (this.countShipReff.countData == 1) {
              formData.order_no_new = result.value[0];
            }
            const baseURI =
              this.$settings.endPoint + "control-tower/transport-order-header/accept"
            this.$http
              .post(baseURI, formData)
              .then((response) => {
                this.loading();
                this.loadItems();
                if (response.data.status === 200) {
                  this.success(response.data.datas.messages);

                } else {
                  this.errors = response.data.errors;
                  this.resultError(response.data.errors);
                }
              })
              .catch((error) => {
                this.loading();
                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.$router.push("/server-error");
                  } else {
                    //this.$router.push("/page-not-found");
                  }
                }
              });
          }
        });
    },



    acceptMassOrder() {
      //await this.checkShipReff(row.transport_order_header_id);

      var html = "";

      this.$swal({
        title: this.$t("areYouSure"),
        text: "You want to All Accept Order ?",
        html: html,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",

      })
        .then((result) => {
          if (result.value) {
            var formData = {
              transport_order_header_ids: this.transport_order_header_ids,
            };
            // if(this.countShipReff.countData ==1){
            //   formData.order_no_new = result.value[0];
            // }
            const baseURI =
              this.$settings.endPoint + "control-tower/transport-order-header/accept"
            this.$http
              .post(baseURI, formData)
              .then((response) => {
                this.loading();
                this.loadItems();
                if (response.data.status === 200) {
                  this.success(response.data.datas.messages);

                } else {
                  this.errors = response.data.errors;
                  this.resultError(response.data.errors);
                }
              })
              .catch((error) => {
                this.loading();
                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.$router.push("/server-error");
                  } else {
                    //this.$router.push("/page-not-found");
                  }
                }
              });
          }
        });
    },


    sendOrder() {

      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
          let data = {
            transport_order_header_ids: this.transport_order_header_ids
          }
          const baseURI =
            this.$settings.endPoint + "control-tower/transport-order-header/send-order-to-blujay";
          this.$http
            .post(baseURI, data, { timeout: 200000 })
            .then((response) => {
              // this.loading();
              if (response.data.status === 200) {

                // response.data.datas.messages.forEach((item) => {
                //   this.success(item);
                // });
                this.success2(response.data.datas.messages);
                this.loadItems();
                this.isloading = false;
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            }).catch(error => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {

                if (error.response) {

                  this.errors = error.response.data.errors;

                  if (error.response.status === 422) {
                    if (this.errors == "carrier_deactived") {
                      var datas = error.response.data.datas;
                      var textResponse =
                        "<p style='text-align:center'>Order ini tidak di perkenankan untuk dikirim karena carrier ini sudah di BLOCK <br> Informasi Block :</p> ";
                      datas.forEach(function (data) {
                        textResponse += "<br> Status : " + datas[0].status + "<br> waktu : " + data.deactived_date +
                          "<br>Alasan di block : " + data.deactived_reason + " <br><hr>";
                      });
                      textResponse += "<br><p>Mohon hubungi Department FA untuk membuka Block</p>";

                      this.$swal({
                        width: "600px",
                        icon: "warning",
                        title: "Warning !!!!",
                        html: textResponse,
                      })
                    } else {
                      this.resultError(error.response.data.errors);
                    }


                  } else if (error.response.status === 500) {
                    this.$router.push('/server-error');
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push('/page-not-found');
                  }
                }

              }

            });
        }
      });




    },


    cancelOrder() {
      this.showModalRejectData = true;
    },

    priorityModal() {
      if (this.rowsSelection.length > 1) {
        this.$swal({
          icon: 'warning',
          backdrop: true,
          title: "Information",
          html: this.$t('txtpilihSatu'),
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      } else {
        this.showModalPriority = true;
      }

    },

    freePick() {


      if (this.rowsSelection.length > 1) {
        this.$swal({
          icon: 'warning',
          backdrop: true,
          title: "Information",
          html: this.$t('txtpilihSatu'),
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      } else {
        this.showModalFreePick = true;
      }
    },



    massfreePick() {


      if (this.rowsSelection.length == 1) {
        this.$swal({
          icon: 'warning',
          backdrop: true,
          title: "Information",
          html: 'Please select many order !',
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      } else {
        this.showModalFreePickMass = true;
      }
    },

    updateFormatDate(date) {
      let date_format = this.$moment(date).format("dd mmm yyyy");
      return date_format;
    },

    downloadData() {
      this.isLoading = true;
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = "download_" + CurrentDate + ".xlsx";

      const baseURI = this.$settings.endPoint + "control-tower/transport-order-header";
      //var columnFilters = this.serverParams.columnFilters;
      var date_order = '';
      if (this.serverParams.columnFilters.order_date != '') {
        date_order = this.$moment(this.serverParams.columnFilters.order_date).format("YYYY-MM-DD");
      }
      //var columnFilters = this.serverParams.columnFilters;
      var pick_plan = '';
      if (this.serverParams.columnFilters.pick_plan != '') {
        pick_plan = this.$moment(this.serverParams.columnFilters.pick_plan).format("YYYY-MM-DD");
      }
      //var columnFilters = this.serverParams.columnFilters;
      var dest_plan = '';
      if (this.serverParams.columnFilters.dest_plan != '') {
        dest_plan = this.$moment(this.serverParams.columnFilters.dest_plan).format("YYYY-MM-DD");
      }
      var company_name = this.serverParams.columnFilters["company.name"];
      var transport_order_template_description = this.serverParams.columnFilters["transport_order_template.description"];
      var transport_order_template_tonnage = this.serverParams.columnFilters.tonnage;
      var transport_order_template_cubic = this.serverParams.columnFilters["transport_order_template.cubic"];
      var startDate = this.dateRange.startDate;
      var endDate = this.dateRange.endDate;
      var status = this.status

      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&company_name=${company_name}&transport_order_template_tonnage=${transport_order_template_tonnage}&transport_order_template_cubic=${transport_order_template_cubic}&transport_order_template_description=${transport_order_template_description}&order_status=${status}&order_no=${this.serverParams.columnFilters.order_no}&type_truck=${this.serverParams.columnFilters.type_truck}&shipwith_ref=${this.serverParams.columnFilters.shipwith_ref}&dest_name=${this.serverParams.columnFilters.dest_name}&order_date=${date_order}&pick_plan=${pick_plan}&dest_plan=${dest_plan}&start=${startDate}&end=${endDate}&driver_name=${this.serverParams.columnFilters.driver_name}&vehicle_no=${this.serverParams.columnFilters.vehicle_no}&shipment_rate=${this.serverParams.columnFilters.shipment_rate}&status_tms=${this.serverParams.columnFilters.status_tms}&pick_name=${this.serverParams.columnFilters.pick_name}&auto_load=${this.serverParams.columnFilters.auto_load}&download=download`,
          {
            responseType: "blob",
            timeout: 200000
          }
        )
        .then((response) => {

          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.isLoading = false;
        }).catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    },



    editData(index, row) {
      var params = this.$onRandom(row.transport_order_header_id);
      window.location.href = "/ct-orders/edit/" + params;
    },

    detailData(index, row) {
      var params = this.$onRandom(row.transport_order_header_id);
      window.location.href = "/ct-orders/detail/" + params;
    },

    clickOrder(order) {
      this.$emit('show_order', order);


    },


    // load items is what brings back the rows from server
    loadItems() {
      this.isLoading = true;
      const baseURI = this.$settings.endPoint + "control-tower/transport-order-header";
      //var columnFilters = this.serverParams.columnFilters;
      var date_order = '';
      if (this.serverParams.columnFilters.order_date != '') {
        date_order = this.$moment(this.serverParams.columnFilters.order_date).format("YYYY-MM-DD");
      }
      //var columnFilters = this.serverParams.columnFilters;
      var pick_plan = '';
      if (this.serverParams.columnFilters.pick_plan != '') {
        pick_plan = this.$moment(this.serverParams.columnFilters.pick_plan).format("YYYY-MM-DD");
      }
      //var columnFilters = this.serverParams.columnFilters;
      var dest_plan = '';
      if (this.serverParams.columnFilters.dest_plan != '') {
        dest_plan = this.$moment(this.serverParams.columnFilters.dest_plan).format("YYYY-MM-DD");
      }
      var company_name = this.serverParams.columnFilters["company.name"];
      var transport_order_template_description = this.serverParams.columnFilters["transport_order_template.description"];
      var transport_order_template_tonnage = this.serverParams.columnFilters.tonnage;
      var transport_order_template_cubic = this.serverParams.columnFilters["transport_order_template.cubic"];
      var startDate = this.$moment(this.dateRange.startDate).format("YYYY-MM-DD");
      var endDate = this.$moment(this.dateRange.endDate).format("YYYY-MM-DD");
      var status = this.status

      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&company_name=${company_name}&transport_order_template_tonnage=${transport_order_template_tonnage}&transport_order_template_cubic=${transport_order_template_cubic}&transport_order_template_description=${transport_order_template_description}&order_status=${status}&order_no=${this.serverParams.columnFilters.order_no}&type_truck=${this.serverParams.columnFilters.type_truck}&shipwith_ref=${this.serverParams.columnFilters.shipwith_ref}&dest_name=${this.serverParams.columnFilters.dest_name}&order_date=${date_order}&pick_plan=${pick_plan}&dest_plan=${dest_plan}&start=${startDate}&end=${endDate}&driver_name=${this.serverParams.columnFilters.driver_name}&vehicle_no=${this.serverParams.columnFilters.vehicle_no}&shipment_rate=${this.serverParams.columnFilters.shipment_rate}&status_tms=${this.serverParams.columnFilters.status_tms}&pick_name=${this.serverParams.columnFilters.pick_name}&auto_load=${this.serverParams.columnFilters.auto_load}`,
          { timeout: 200000 }
        )
        .then((response) => {
          //this.rows = response.data.datas.data;
          this.rows = [];
          var datas = response.data.datas.data;
          datas.forEach((value) => {
            if (value.order_status == 'SUBMITTED') {
              // value.vgtDisabled=true;
              // value.vgtSelected=false;
            }
            this.rows.push(value);
          });
          // this.totalRecords = this.rows.length;
          this.totalRecords = response.data.datas.total;
          console.log(this.totalRecords);
          this.$emit('passDataSubmitted', this.totalRecords);

          // if(this.status=='ASSIGNED'){
          //   this.$emit('passDataAssigned', this.totalRecords);
          // }

          // if(this.status=='REJECTED'){
          //   this.$emit('passDataRejected', this.totalRecords);
          // }

          // if(this.status=='COMPLETED'){
          //   this.$emit('passDataCompleted', this.totalRecords);
          // }

          // if(this.status=='RESCHEDULE'){
          //   this.$emit('passDataReschedule', this.totalRecords);
          // }

          // if(this.status=='REROUTE'){
          //   this.$emit('passDataReroute', this.totalRecords);
          // }

          this.isLoading = false;
        }).catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },


    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    success2(msg) {
      let pesan = "";
      msg.forEach((item) => {
        pesan = pesan + "<p>* " + item + "</p>";
      });

      this.$swal({
        icon: 'info',
        backdrop: true,
        title: "Information",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      })


    },


    success3(msg) {


      this.$swal({
        icon: 'info',
        backdrop: true,
        title: "Information",
        html: msg,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      })


    },


    loadingSubmiting() {


      this.$swal({
        icon: 'warning',
        backdrop: true,
        title: "Please wait",
        html: '<h2>SUBMITTING ORDER...</h2>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

    },


    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },


    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },

    getSystemCode() {
      const baseURI = this.$settings.endPoint + "system-code/select?system_code=ORDER_RESPONSE_TIME";

      return this.$http
        .get(
          baseURI,
          { timeout: 200000 }
        )
        .then((response) => {
          var rows = response.data.datas.data;
          if (rows.length > 0) {
            this.ORDER_RESPONSE_TIME = rows[0].value;
          }

        }).catch(error => {

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    },

  },
  events: {},

  mounted() {
    this.fetchIt();
    this.loading();
    this.getSystemCode();


  },
};
</script>
