<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading">
      {{ $t("loadingTxt") }}...
    </div>
    <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
      <!--begin::sidebar-->
      <sidebar-component classMenu="DashboardFullfilment"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->
        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div
                class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
              >
                <div class="col-lg-2 col-xl-3 col-sm-3 col-xs-3 col-md-3">
                  <h4 class="mb-3 mb-md-0">Dashboard Fullfillment</h4>
                </div>

                <div class="col-lg-10 col-xl-9 col-sm-9 col-xs-9 col-md-9">
                  <div class="d-flex flex-wrap">
                    <div
                      class="input-group flatpickr wd-250 me-2 mb-2 mb-md-0"
                      id="dashboardDate"
                    >
                      <date-range-picker
                        style="width: 100%"
                        @change="loadData"
                        control-container-class="form-control"
                        :locale-data="locale"
                        v-model="dateFilter"
                        :opens="opens"
                        :singleDatePicker="false"
                        :timePicker24Hour="true"
                        :timePicker="true"
                        :showWeekNumbers="false"
                      >
                      </date-range-picker>
                    </div>

                    <button
                      type="button"
                      style="max-height: 40px"
                      @click.prevent="loadData"
                      class="btn btn-primary btn-icon-text mb-2 mb-md-0 btn-block btn-floating"
                    >
                      GO
                    </button>
                    <span style="width: 5px"> </span>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <!-- Jakarta -->
                  <div class="row" style="height: 200px">
                    <div class="col-lg-12">
                      <!-- <LineChartGenerator :chart-options="chartOptions"
                                                :chart-data="chartDataLine" :chart-id="chartId"
                                                :dataset-id-key="datasetIdKey" :plugins="plugins"
                                                :css-classes="cssClasses" :styles="myStyles" :width="width"
                                                :height="height" /> -->
                      <Bar
                        :chart-options="chartOptions"
                        :chart-data="chartDataLine"
                        :chart-id="1"
                        :dataset-id-key="datasetIdKey"
                        :plugins="plugins"
                        :css-classes="cssClasses"
                        :styles="myStyles"
                        :width="width"
                        :height="height"
                      />
                    </div>
                  </div>
                  <br />
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">PI Number</label>
                            <input
                              type="text"
                              v-model="search.piNumber"
                              v-on:keyup.enter="loadData"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Load Id</label>
                            <input
                              type="text"
                              v-model="search.loadId"
                              v-on:keyup.enter="loadData"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Status</label>
                            <input
                              type="text"
                              v-model="search.status"
                              v-on:keyup.enter="loadData"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Created At</label>
                            <date-range-picker
                              style="width: 100%"
                              @change="loadData"
                              control-container-class="form-control"
                              :locale-data="locale"
                              v-model="dateFilterSearch"
                              :opens="opens"
                              :singleDatePicker="false"
                              :timePicker24Hour="true"
                              :timePicker="true"
                              :showWeekNumbers="false"
                            >
                            </date-range-picker>
                          </div>
                        </div>
                        <div class="col-lg-2 mt-4">
                          <div class="mb-3 mt-1">
                            <button
                              class="btn btn-primary"
                              type="button"
                              @click="loadData"
                            >
                              Search
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <vue-good-table
                      title="dashboard-pi"
                      mode="remote"
                      :totalRows="totalRecords"
                      :line-numbers="true"
                      :isLoading.sync="isLoading"
                      :pagination-options="{
                        enabled: true,
                      }"
                      :rows="rows"
                      :columns="columns"
                    >
                      <div slot="table-actions">
                        <button class="btn btn-success btn-xs">
                          Export To Excel
                        </button>
                      </div>
                      <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'entries'">
                          <a
                            style="
                              color: #ab0635;
                              text-decoration: none;
                              cursor: pointer;
                            "
                            title="Detail Load Id"
                            @click.prevent="detailPi(props.row)"
                          >
                            {{ props.row.entries }}
                          </a>
                        </span>
                        <span v-else>
                          {{ props.formattedRow[props.column.field] }}
                        </span>
                      </template>
                    </vue-good-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>

    <!-- <modal-fullfilment-detail
      :showModal="showModalDetail"
      :data="detailData"
      @closeModal="closeMyModal"
    ></modal-fullfilment-detail> -->
    <modal-proformaInvoiceDetail
                        :showModal="showModalPI" 
                        :data="this.detailData"
                        @closeModal="closeMyModal">
                    </modal-proformaInvoiceDetail>
    <!-- <modal-productivity-detail :showModal="showModalDetail" :data="detailData"
            @closeModal="closeMyModal"></modal-productivity-detail>

        <modal-productivity-nasional :showModal="showModalNasional" :data="detailData"
            @closeModal="closeMyModal"></modal-productivity-nasional> -->
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import DateRangePicker from "vue2-daterange-picker";
import { Bar } from "vue-chartjs/legacy";
// import { Line as LineChartGenerator } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  LineElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  LineElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
);

// import modalFullfilmentDetail from "@/components/modal/modalFullfilmentDetail.vue";
// import modalProductivityNasional from "@/components/modal/modalProductivityNasional.vue";
import ModalProformaInvoiceDetail from "@/components/modal/modalProformaInvoiceDetail.vue";

import moment from "moment";

export default {
  name: "DashboardProformaInvoice",
  props: {
    chartId: {
      type: String,
      default: "bar",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 200,
    },
  },
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    // LineChartGenerator,
    Bar,
    // "modal-fullfilment-detail": modalFullfilmentDetail,
    "modal-proformaInvoiceDetail":ModalProformaInvoiceDetail,
    // "modal-productivity-nasional": modalProductivityNasional,
    DateRangePicker,
  },
  data() {
    const startDate = moment().subtract(1, "months").format("YYYY-MM-DD");
    const endDate = moment().add(1, "months").calendar();

    return {
      chartDataLine: {
        labels: [],
        datasets: [],
      },
      fetchTruck: [],
      fetchBranch: [],
      type_truck: "",
      branch: "",
      type_truckFilter: [],
      branchFilter: [],
      search: [],
      chartOptions: {
        scales: {
          y: {
            stacked: true,
            ticks: {
              beginAtZero: true,
              min: 0,
              stepSize: 5,
            },
            gridLines: {
              display: true,
            },
          },
          x: {
            stacked: true,
            gridLines: {
              display: false,
            },
          },
        },
        legend: {
          display: true,
          position: "left",
        },
        responsive: true,
        maintainAspectRatio: false,
        onClick: (c, i) => {
          var labelDate = c.chart.config._config.data.labels[i[0].index];

          var label = this.chartDataLine.datasets[i[0].datasetIndex].label;
          this.clickDetailModal(label, labelDate);
        },
      },
      detailOpen: false,
      showModalDetail: false,
      showModalNasional: false,
      showModalPI:false,
      opens: "center",
      detailData: {},
      locale: {
        format: "yyyy-mm-dd",
      },
      permision_role: [],
      countData: [],
      dateFilter: {
        startDate,
        endDate,
      },
      dateFilterSearch: {
        startDate,
        endDate,
      },
      isLoading: false,
      rowsTable: [],
      totalRecords: 0,
      totalRecordsDetail: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },

      columns: [
        {
          label: "PI No",
          field: "pinumber",
        },
        {
          label: "#Enteries",
          field: "entries",
        },
        {
          label: "Total",
          field: "total",
        },
        {
          label: "Created At",
          field: "created_at",
        },
        {
          label: "Status",
          field: "status",
        },
      ],
      columnsDetail: [
        {
          label: "Sold to Party Name",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("companyTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Load Id",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("companyTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Nopol",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("companyTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Equipment Description",
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd MMM yyyy h:mm:ss a", //'dd MMM yyyy',
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("create_at_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Loading Date",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("companyTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Unloading date",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("companyTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Origin",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("companyTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Destination",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("companyTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Delivery Order",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("companyTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Shipment Numbers",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("companyTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "UOM",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("companyTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Qty / Ton",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("companyTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Billable Rate",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("companyTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Charge Loading",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("companyTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Charge Unloading",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("companyTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Charge Multi Pickup",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("companyTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Charge Multi drop",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("companyTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Charge Overnight ",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("companyTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Other",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("companyTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Total",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("companyTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Product Name",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("companyTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Outlet Name",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("companyTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
      ],
      bulan: [],
      cancel_from_customer: [],
      fullfill: [],
      not_fullfill: [],
      billable_not_found: [],
      billable_payable_not_found: [],
      deleted: [],
      edit_shipment: [],
      new_order: [],
      payable_not_found: [],
      total: [],
      rows: [
        {
          pinumber: "PI-20230522-00001",
          entries: 4,
          total: 40000000,
          created_at: "2023-05-22 14:33:00",
          status: "Create",
        },
        {
          pinumber: "PI-20230522-00002",
          entries: 1,
          total: 5000000,
          created_at: "2023-05-22 14:33:00",
          status: "Post",
        },
      ],
      rowsDetail: [],
    };
  },
  computed: {
    myStyles() {
      return {
        height: "100%",
        position: "relative",
      };
    },
  },
  watch: {},
  methods: {
    clickDetailModal(jenis, monthDetail) {
      this.showModalDetail = true;
      this.detailData = {
        jenis: jenis,
        start: moment(String(this.dateFilter.startDate)).format("YYYY-MM-DD"),
        end: moment(String(this.dateFilter.endDate)).format("YYYY-MM-DD"),
        branch: this.branch.branch_id == undefined ? "" : this.branch.branch_id,
        type:
          this.type_truck.type_truck == undefined
            ? ""
            : this.type_truck.type_truck,
        monthDetail: monthDetail,
      };
    },
    detailPi(row) {
      this.detailData["method"] = "detail";
      this.detailData["data"] = row;
      this.showModalPI = true;
      console.log(row);
    },
    closeMyModal() {
      this.showModalPI = false;
      // this.showModalNasional = false;
      // this.detailData = {};
    },

    loadData() {
      let dataSend = {
        start: moment(String(this.dateFilter.startDate)).format("YYYY-MM-DD"),
        end: moment(String(this.dateFilter.endDate)).format("YYYY-MM-DD"),
        branch: this.branch == undefined ? null : this.branch.branch_id,
        type: this.type_truck == undefined ? null : this.type_truck.type_truck,
      };

      const baseURI =
        this.$settings.endPoint +
        "control-tower/transport-order-header/dashboard"; // ?start=" + dataSend.start + "&end=" + dataSend.end
      this.$http
        .post(baseURI, dataSend, { timeout: 10000 })
        .then((response) => {
          this.isLoading = false;
          if (response.status == 200) {
            this.bulan = response.data.bulan;
            this.cancel_from_customer = response.data.cancel_from_customer;
            this.fullfill = response.data.fullfill;
            this.not_fullfill = response.data.not_fullfill;

            this.billable_not_found = response.data.billable_not_found;
            this.billable_payable_not_found =
              response.data.billable_payable_not_found;

            this.deleted = response.data.deleted;
            this.edit_shipment = response.data.edit_shipment;
            this.new_order = response.data.new_order;
            this.payable_not_found = response.data.payable_not_found;

            this.total = response.data.total;
            this.loadGrafik();
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error("Please Check Your Internet Connection");
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                //this.$router.push("/page-not-found");
              }
            }
          }
        });
    },

    downloadData() {
      let dataSend = {
        start: moment(String(this.dateFilter.startDate)).format("YYYY-MM-DD"),
        end: moment(String(this.dateFilter.endDate)).format("YYYY-MM-DD"),
        branch: this.branch.branch_id == undefined ? "" : this.branch.branch_id,
        type:
          this.type_truck.type_truck == undefined
            ? ""
            : this.type_truck.type_truck,
      };

      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = `dashboard_fullfilment_` + CurrentDate + `.xlsx`;

      const baseURI =
        this.$settings.endPoint +
        "/control-tower/transport-order-header/dashboard-download";

      this.$http
        .get(
          baseURI +
            `?start=${dataSend.start}&end=${dataSend.end}&type=${dataSend.type}&branch=${dataSend.branch}`,
          {
            responseType: "blob",
          },
          { timeout: 10000 }
        )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error("Please Check Your Internet Connection");
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                //this.$router.push("/page-not-found");
              }
            }
          }
        });
    },

    downloadDataDetail() {
      let dataSend = {
        start: moment(String(this.dateFilter.startDate)).format("YYYY-MM-DD"),
        end: moment(String(this.dateFilter.endDate)).format("YYYY-MM-DD"),
        branch: this.branch.branch_id == undefined ? "" : this.branch.branch_id,
        type:
          this.type_truck.type_truck == undefined
            ? ""
            : this.type_truck.type_truck,
      };

      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = `dashboard_detail_fullfilment_` + CurrentDate + `.xlsx`;

      const baseURI =
        this.$settings.endPoint +
        "/control-tower/transport-order-header/dashboard-download-detail";

      this.$http
        .get(
          baseURI +
            `?start=${dataSend.start}&end=${dataSend.end}&type=${dataSend.type}&branch=${dataSend.branch}`,
          {
            responseType: "blob",
          },
          { timeout: 10000 }
        )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error("Please Check Your Internet Connection");
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                //this.$router.push("/page-not-found");
              }
            }
          }
        });
    },

    drawDatasetPointsLabels() {},
    downloadSummary() {
      let dataSend = {
        start: moment(String(this.dateFilter.startDate)).format("YYYY-MM-DD"),
        end: moment(String(this.dateFilter.endDate)).format("YYYY-MM-DD"),
      };
      this.isLoading = true;
      const baseURI =
        this.$settings.endPointCt + "truck-utility-detail/download-summary";
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = `summary_truck_utility_` + CurrentDate + `.xlsx`;

      return this.$http
        .get(baseURI + `?start=${dataSend.start}&end=${dataSend.end}`, {
          responseType: "blob",
        })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSelectTypeTruck(ev) {
      this.type_truckFilter.push(ev.type_truck_id);
    },

    onUnselectTypeTruck(ev) {
      this.type_truckFilter.splice(
        this.type_truckFilter.indexOf(ev.type_truck_id),
        1
      );
    },
    onUnselectBranch(ev) {
      this.branchFilter.splice(this.branchFilter.indexOf(ev.branch_id), 1);
      this.branchName.splice(this.branchFilter.indexOf(ev.branch_name), 1);
    },

    onSelectBranch(ev) {
      this.branchFilter.push(ev.branch_id);
      this.branchName.push(ev.branch_name);
    },

    loadGrafik() {
      this.chartDataLine = {
        labels: this.bulan,
        datasets: [
          {
            label: "Data One",
            backgroundColor: "#f87979",
            data: [40, 20],
          },
        ],
      };
    },

    loadTruck() {
      const baseURI = this.$settings.endPointCt + `type-truck/select?limit=10`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTruck = response.data.datas.data;
      });
    },
    loadBranch() {
      const baseURI = this.$settings.endPointCt + `branch/select`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchBranch = response.data.datas.data;
      });
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      let rolePermision = [];
      // console.log(userDatas.sub)
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "TruckUtilityDetailController") {
          rolePermision = permision;
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },
    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    this.fetchIt();
    this.loadData();
    this.loadBranch();
    this.loadTruck();
  },
};
</script>
<style>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content {
  filter: blur(5px);
}

.box {
  width: 300px;
  height: 10%;
  border-style: solid;
  border-width: 0.5px;
  background-color: red;
  text-align: center;
  vertical-align: middle;
}

.progress {
  width: 150px;
  height: 150px;
  line-height: 150px;
  background: none;
  margin-top: -30%;
  margin-left: 70%;
  box-shadow: none;
  position: relative;
  opacity: 0.8;
}

.progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 12px solid #fff;
  position: absolute;
  top: 0;
  right: 0;
}

.progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress .progress-left {
  left: 0;
}

.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 12px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progress .progress-right {
  right: 0;
}

.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards;
}

.progress .progress-value {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background: #eaf0f4;
  font-size: 24px;
  color: rgb(65, 65, 65);
  line-height: 135px;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 5%;
}

.progress.blue .progress-bar {
  border-color: #097fc9;
}

.progress.blue .progress-left .progress-bar {
  animation: loading-2 1.5s linear forwards 1.8s;
}

.progress.yellow .progress-bar {
  border-color: #fdba04;
}

.progress.yellow .progress-left .progress-bar {
  animation: loading-3 1s linear forwards 1.8s;
}

.progress.pink .progress-bar {
  border-color: #ed687c;
}

.progress.pink .progress-left .progress-bar {
  animation: loading-4 0.4s linear forwards 1.8s;
}

.progress.green .progress-bar {
  border-color: #1abc9c;
}

.progress.green .progress-left .progress-bar {
  animation: loading-5 1.2s linear forwards 1.8s;
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(36deg);
    transform: rotate(36deg);
  }
}

@keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(126deg);
    transform: rotate(126deg);
  }
}

@media only screen and (max-width: 990px) {
  .progress {
    margin-bottom: 20px;
  }
}
</style>
