import Region from "@/components/master/region/Region.vue";
import RegionAdd from "@/components/master/region/RegionAdd.vue";
import RegionDetail from "@/components/master/region/RegionDetail.vue";
import RegionEdit from "@/components/master/region/RegionEdit.vue";
import RegionUpload from "@/components/master/region/RegionUpload.vue";


let routesRegion= [
    
    {
        path: '/',
        meta: {
          title: "Chain System",
        },
        name: "RegionIndex",
        component: Region
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "RegionAdd",
        component: RegionAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "RegionEdit",
        component: RegionEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "RegionDetail",
        component: RegionDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "RegionUpload",
        component: RegionUpload
      },
    
]

export default routesRegion
  