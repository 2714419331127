import Document from "@/components/master/document/Document.vue";
import DocumentAdd from "@/components/master/document/DocumentAdd.vue";
import DocumentDetail from "@/components/master/document/DocumentDetail.vue";
import DocumentEdit from "@/components/master/document/DocumentEdit.vue";
import DocumentUpload from "@/components/master/document/DocumentUpload.vue";

let routesDocument= [
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "Document",
        component: Document
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "DocumentAdd",
        component: DocumentAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "DocumentEdit",
        component: DocumentEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "DocumentDetail",
        component: DocumentDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "DocumentUpload",
        component: DocumentUpload
      },
    
]

export default routesDocument
  