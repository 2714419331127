<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="DetailMonitoring"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            "
          >
            <div class="col-lg-12 col-xl-12 stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="row mb-3">
                    <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                      <h5 class="mb-12 mb-md-0">Detail Monitoring</h5>
                    </div>

                    <!-- <div class="col-lg-1 col-md-12 col-sm-12 col-xs-12">
                      <div class="input-group float-end">
                        <button
                          v-if="isMaps"
                          class="btn btn-primary btn-float"
                          @click="btnMaps(false)"
                        >
                          Hide Maps
                        </button>
                        <button
                          v-if="!isMaps"
                          class="btn btn-success btn-float"
                          @click="btnMaps(true)"
                        >
                          Show Maps
                        </button>
                      </div>
                    </div> -->

                    <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          id="navbarForm"
                          v-model="vehicle_no"
                          readonly
                        />
                      </div>
                    </div>

                    <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                      <div class="input-group">
                        <date-range-picker
                          style="width: 100%"
                          @change="pickupChange"
                          control-container-class="form-control"
                          :locale-data="locale"
                          v-model="pickupdestination"
                          :opens="opens"
                          :singleDatePicker="false"
                          :timePicker24Hour="true"
                          :timePicker="true"
                          :showWeekNumbers="false"
                        >
                        </date-range-picker>
                      </div>
                    </div>

                    <div class="col-lg-1 col-md-12 col-sm-12 col-xs-12">
                      <div class="input-group float-end">
                        <button
                          class="btn btn-primary btn-float"
                          @click="filterMap()"
                        >
                          Go
                        </button>
                      </div>
                    </div>

                    <div
                      class="col-lg-3 col-md-12 col-sm-12 col-xs-12 float-end"
                    >
                      <div class="input-group">
                        <!-- <v-select
                          :options="fetchStatus"
                          v-model="status"
                          label="status_name"
                          return-object
                        >
                        </v-select> -->

                        <select
                          class="form-select form-select-lg"
                          name="status"
                          v-model="forms.tms_status"
                        >
                          <option
                            v-for="(item, index) in fetchStatus"
                            v-bind:key="index"
                            :style="{
                              color: item.status_color,
                            }"
                          >
                            {{ item.status_name }}
                          </option>
                        </select>

                        <button
                          class="btn btn-primary btn-xs"
                          type="button"
                          @click="updateStatus"
                        >
                          Update Status
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="row" v-if="isMaps">
                    <gmap-map
                      :zoom="5"
                      :center="center"
                      style="width: 100%; height: 400px"
                      map-type-id="terrain"
                    >
                      <!-- <gmap-marker
                        :key="index"
                        v-for="(gmp, index) in markers"
                        :position="gmp.position"
                        @click="center = gmp.position"
                      >
                        <gmap-info-window :opened="true">
                          <span
                            class="badge"
                            :style="{
                              'background-color': 'white',
                              color: 'black',
                            }"
                            :opened="false"
                          >
                            {{ gmp.position.label }}
                          </span>
                        </gmap-info-window>
                      </gmap-marker> -->
                      <gmap-polygon :paths="paths"></gmap-polygon>
                      <DirectionsRenderer
                        travelMode="DRIVING"
                        :origin="startLocation"
                        :destination="endLocation"
                        :waypoints="waypnt"
                        :optimizeWaypoints="true"
                      />
                    </gmap-map>
                  </div>

                  <br />

                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        id="basicinfo-tab"
                        data-bs-toggle="tab"
                        href="#basicinfo"
                        role="tab"
                        aria-controls="basicinfo"
                        aria-selected="true"
                        >Basic Info</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="tripanalysis-tab"
                        data-bs-toggle="tab"
                        href="#tripanalysis"
                        role="tab"
                        aria-controls="tripanalysis"
                        aria-selected="false"
                        >Trip Analysis</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="onetime-tab"
                        data-bs-toggle="tab"
                        href="#triphistory"
                        role="tab"
                        aria-controls="onetime"
                        aria-selected="false"
                        >Trip history</a
                      >
                    </li>

                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="gpserror-tab"
                        data-bs-toggle="tab"
                        href="#gpserror"
                        role="tab"
                        aria-controls="gpserror"
                        aria-selected="false"
                        >Anomali Reporting</a
                      >
                    </li>


                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="traffic-violation-tab"
                        data-bs-toggle="tab"
                        href="#traffic_violation"
                        role="tab"
                        aria-controls="traffic_violation"
                        aria-selected="false"
                        >Traffic Violation</a
                      >
                    </li>


                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="pok-tab"
                        data-bs-toggle="tab"
                        href="#pok"
                        role="tab"
                        aria-controls="pok"
                        aria-selected="false"
                        >POK Reporting</a
                      >
                    </li>

                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="accident-tab"
                        data-bs-toggle="tab"
                        href="#accident"
                        role="tab"
                        aria-controls="accident"
                        aria-selected="false"
                        >Accident Reporting</a
                      >
                    </li>


                    <!-- <li class="nav-item">
                      <a
                        class="nav-link"
                        id="gpserror-tab"
                        data-bs-toggle="tab"
                        href="#triphistory"
                        role="tab"
                        aria-controls="triphistory"
                        aria-selected="false"
                        >Trip history</a
                      >
                    </li> -->
                  </ul>
                  <div
                    class="tab-content border border-top-0 p-3"
                    id="myTabContent"
                  >
                    <div
                      class="tab-pane fade show active"
                      id="basicinfo"
                      role="tabpanel"
                      aria-labelledby="basicinfo-tab"
                    >
                      <basic-info-component
                        :data="forms"
                        :detailMonitoring="detailMonitoring"
                        :detailOrder="fetchOrder"
                      ></basic-info-component>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="tripanalysis"
                      role="tabpanel"
                      aria-labelledby="tripanalysis-tab"
                    >
                      <trip-analysis-component :detailOrder="fetchTripAnalysis" @afterSaveTripAnalysis="afterSaveTripAnalysis"  ></trip-analysis-component>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="triphistory"
                      role="tabpanel"
                      aria-labelledby="trihistory-tab"
                    >
                      <trip-history-component :dateRange="pickupdestination"  ></trip-history-component>
                    </div>

                    <!-- <div
                      class="tab-pane fade"
                      id="onetime"
                      role="tabpanel"
                      aria-labelledby="onetime-tab"
                    >
                      <on-time-analysis-component-1></on-time-analysis-component-1>
                      <br />
                      <br />

                      <on-time-analysis-component-2></on-time-analysis-component-2>
                    </div> -->
                    <div
                      class="tab-pane fade"
                      id="gpserror"
                      role="tabpanel"
                      aria-labelledby="gpserror-tab"
                    >
                      <gps-error-reporting-component></gps-error-reporting-component>
                    </div>


                    <div
                      class="tab-pane fade"
                      id="traffic_violation"
                      role="tabpanel"
                      aria-labelledby="traffic-violation-tab"
                    >
                     <!-- Underconstruction -->
                     <div class="row">
                                <div class="alert alert-primary alert-dismissible fade show" role="alert">
                                    Modul <strong>Under Construction!</strong>
                                  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                              </div>
                      <traffict-violation :dateRange="pickupdestination" ></traffict-violation>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="pok"
                      role="tabpanel"
                      aria-labelledby="pok-tab"
                    >
                    <div class="row">
                                <div class="alert alert-primary alert-dismissible fade show" role="alert">
                                    Modul <strong>Under Construction!</strong>
                                  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                              </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="accident"
                      role="tabpanel"
                      aria-labelledby="accident-tab"
                    >
                    <div class="row">
                                <div class="alert alert-primary alert-dismissible fade show" role="alert">
                                    Modul <strong>Under Construction!</strong>
                                  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                              </div>
                    </div>

                    <!-- <div
                      class="tab-pane fade"
                      id="triphistory"
                      role="tabpanel"
                      aria-labelledby="triphistory-tab"
                    >
                      <trip-history-component></trip-history-component>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import basicInfoComponent from "@/components/dasboard-ct/basicinfo.vue";
import tripAnalysisComponent from "@/components/dasboard-ct/tripAnalysis.vue";
// import onTimeAnalysisComponent from "@/components/dasboard-ct/onTimeAnalysis.vue";
// import onTimeAnalysisComponent_ from "@/components/dasboard-ct/onTimeAnalysis_.vue";
import DirectionsRenderer from "@/middleware/DirectionsRenderer_";
import gpsErrorReportingComponent from "@/components/dasboard-ct/gpsErrorReporting.vue";
import tripHistoryComponent from "@/components/dasboard-ct/tripHistoryTable.vue";
import traffictViolationComponent from "@/components/dasboard-ct/traffictviolation.vue";


import moment from "moment";

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "DetailMonitoring",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "basic-info-component": basicInfoComponent,
    "trip-analysis-component": tripAnalysisComponent,
    // "on-time-analysis-component-1": onTimeAnalysisComponent,
    // "on-time-analysis-component-2": onTimeAnalysisComponent_,
    "gps-error-reporting-component": gpsErrorReportingComponent,
    "trip-history-component": tripHistoryComponent,
    // "trip-history-component":tripHistoryComponent,
    "traffict-violation":traffictViolationComponent,
    // multiselect: multiselect,
    DirectionsRenderer,
    DateRangePicker,
  },
  data() {
    const startDate = new Date();
    const endDate = new Date();
    startDate.setDate(startDate.getDate() - 1);

    const mapMarkerIcon = require("../../assets/icontruck.png");
    return {
      markers: [],
      waypnt: [],
      opens: "center",
      locale: {
        format: "dd-mm-yyyy H:MM:ss",
      },
      pickupdestination: {
        startDate,
        endDate,
      },
      dataAll: "",
      paths: [],
      zoom: 3,
      isLoading: false,
      totalRecords: 0,
      openedMarkerID: true,
      markerOption: {
        url: mapMarkerIcon,
        size: { width: 30, height: 30, f: "20px", b: "20px" },
        scaledSize: { width: 30, height: 30, f: "20px", b: "20px" },
        rotation: 45,
      },

      branch: [],
      currentDate: moment(String(new Date())).format("YYYY-MM-DD"),
      truck: [],
      status: [],
      fetchBranch: [],
      fetchTruck: [],
      fetchStatus: [],
      fetchOrder: [],
      options: [],
      start_date:"",
      end_date:"",
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isMaps: false,
      errors: [],
      featchTruck: [],
      featchStatus: [],
      featchBranch: [],
      langs: ["id", "en"],
      name: "",
      startLocation: null,
      endLocation: null,
      center: {
        lat: -2.548926,
        lng: 118.0148634,
      },
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },

      locationMarkers: [],
      locations: [],
      locPlaces: [],
      realMonitoring: [],
      branchFilter: [],
      type_truckFilter: [],
      statusFilter: [],
      searchVhicle: "",
      rowsTable: [],
      fetchTripAnalysis: [],
      rows: [],
      vehicle_no: this.$route.params.vehicle_no,
      forms: [],
      detailMonitoring: [],
    };
  },
  watch: {},
  methods: {
    btnMaps(stype) {
      this.isMaps = stype;
      this.filterMap();
    },

    filterMap() {
      this.isMaps = true;
      //this.btnMaps(true);
      this.markers = [];
      this.loadDataOrder();
      this.loadTripAnalysis();
      // const markerPickup = {
      //     lat: -6.265,
      //     lng: 107.132,
      //     label:"PICKUP",
      //   };

      //   const markerDestination = {
      //     lat: -6.78078,
      //     lng: 107.256,
      //     label:"DESTINATION",
      //   };

      // this.center = markerPickup;
      // this.startLocation = markerPickup;
      // this.endLocation = markerDestination;

      let gps_vendor = this.$route.params.gps_vendor;
      let vehicle_no = this.$route.params.vehicle_no;
      //let gps_vendor = "INOVATRACK";
      //let vehicle_no = "B9902UEH";
      let startDate = moment(String(this.pickupdestination.startDate)).format(
        "YYYY-MM-DD H:mm:ss"
      );
      let endDate = moment(String(this.pickupdestination.endDate)).format(
        "YYYY-MM-DD H:mm:ss"
      );

      const baseURI =
        this.$settings.endPointCt +
        `real-monitoring/history/` +
        vehicle_no +
        `/` +
        gps_vendor +
        `/` +
        startDate +
        `/` +
        endDate;

      return this.$http.get(baseURI).then((response) => {
        this.startLocation = [];
        this.endLocation = [];
        this.waypnt = [];

        this.dataAll = response.data.datas;

        if (this.dataAll.length > 0) {
          const markerPickup = {
            lat: this.dataAll[0].latitude,
            lng: this.dataAll[0].longitude,
            label: "PICKUP",
          };
          this.startLocation = markerPickup;
          var first_real_monitoring_id =this.dataAll[0].real_monitoring_id;
          var end_real_monitoring_id =this.dataAll[0].real_monitoring_id;
          
          this.dataAll.forEach((res) => {
           
           if(res.real_monitoring_id != first_real_monitoring_id  && res.real_monitoring_id != end_real_monitoring_id){
              const markerWay = {
                lat: res.latitude,
                lng: res.longitude,
              };

              this.waypnt.push({
                location: {
                  lat: markerWay.lat,
                  lng: markerWay.lng,
                },
                stopover: true,
              });

           }
           

            // this.endLocation = markerDestination;

            
          });

          const markerDestination = {
            lat: this.dataAll[this.dataAll.length - 1].latitude,
            lng: this.dataAll[this.dataAll.length - 1].longitude,
            label: "DESTINATION",
          };

          this.endLocation = markerDestination;
          




        }else{
          this.dataAll = [];
          this.loadError('No Data Selected');
        }
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loadingSubmiting() {
      this.$swal({
        icon: "warning",
        backdrop: true,
        title: "Pleasee Wait",
        html: "<h2>SUBMITTING...</h2>",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },


    loadTripAnalysis(){
      let startDate = moment(String(this.pickupdestination.startDate)).format(
        "YYYY-MM-DD H:mm:ss"
      );
      let endDate = moment(String(this.pickupdestination.endDate)).format(
        "YYYY-MM-DD H:mm:ss"
      );
       const baseURI = this.$settings.endPointCt + `trip-analysis/` + startDate + `/` + endDate + `?vehicle_no=` + this.vehicle_no;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTripAnalysis = response.data.datas.data;
      });

    },

    afterSaveTripAnalysis(){
      this.loadTripAnalysis();
    },

    updateStatus() {
      this.loadingSubmiting();
      let dataSend = {
        truck_status: this.forms.tms_status,
      };
      const baseURI =
        this.$settings.endPointCt +
        `master-truck/update-status/` +
        this.$route.params.vehicle_no;
      return this.$http.post(baseURI, dataSend).then((response) => {
        var msg = response.data.datas.messages;
        this.success(msg);
      });
    },

    initMarker(loc) {
      this.existingPlace = loc;
    },

    setLocationLatLng: function () {
      navigator.geolocation.getCurrentPosition((geolocation) => {
        this.center = {
          lat: geolocation.coords.latitude,
          lng: geolocation.coords.longitude,
        };
      });
      // this.locations = [
      //   {
      //     id:1,
      //     lat: -6.2066575,
      //     lng: 106.7759629,
      //     label: "B 123123 EG",
      //     rotate:20,
      //     color:"badge bg-primary"
      //   },
      //   {
      //     id:2,
      //     lat: -6.181783879012301,
      //     lng: 106.79797850513704,
      //     label: "B 22222 EH",
      //     rotate:40,
      //     color:"badge bg-success"
      //   },
      //   {
      //     id:3,
      //     lat: -6.1893281,
      //     lng: 106.764631,
      //     label: "B 12223 EJ",
      //     rotate:90,
      //     color:"badge bg-warning"
      //   },
      //   {
      //     id:4,
      //     lat: -6.1810517,
      //     lng: 106.7679362,
      //     label: "F 231312 EG",
      //     rotate:70,
      //     color:"badge bg-info"
      //   },
      // ];
    },

    onSelectBranch(ev) {
      this.branchFilter.push({
        branch_id: ev.branch_id,
      });
      this.changeFilterLocation();
    },

    changeFilterLocation() {
      let dataSend = {
        branch: this.branchFilter,
        type_truck: this.type_truckFilter,
        status: this.statusFilter,
      };
      //this.realMonitoring =[];
      this.locations = [];
      this.rowsTable = [];

      this.loadFilter(dataSend);
    },

    loadFilter(data) {
      const baseURI = this.$settings.endPointCt + `real-monitoring`;
      return this.$http.post(baseURI, data).then((response) => {
        this.realMonitoring = response.data.datas.data;
        this.totalRecords = response.data.datas.total;
        this.rows = response.data.datas.data;
        this.loadFetchLocation();
        this.loadFetchLocationTable();
      });
    },

    loadFilterClick(data) {
      const baseURI = this.$settings.endPointCt + `real-monitoring`;
      return this.$http.post(baseURI, data).then((response) => {
        this.realMonitoring = response.data.datas.data;
        this.totalRecords = response.data.datas.total;

        this.loadFetchLocation();
        this.loadFetchLocationTable();
      });
    },

    onUnselectBranch(ev) {
      this.branchFilter = this.branchFilter.filter(
        (res) => res.branch_id !== ev.branch_id
      );
      this.loadFetchLocation();
    },

    onUnselectTypeTruck(ev) {
      this.typeTruckFilter = this.typeTruckFilter.filter(
        (res) => res.type_truck_id !== ev.type_truck_id
      );
      this.loadFetchLocation();
    },

    onUnselectStatus(ev) {
      this.statusFilter = this.statusFilter.filter(
        (res) => res.status_name !== ev.status_name
      );
      this.loadFetchLocation();
    },

    detailData(index, row) {
      var params = this.$onRandom(row.id);
      window.location.href = "/monitoring/detail/" + params;
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    searchVehicleNo(ev) {
      this.isMaps = true;
      this.zoom = 15;
      (this.center = {
        lat: ev.lat,
        lng: ev.lng,
      }),
        (this.locations = []);
      let dataSend2 = {
        vehicle_no: ev.vehicle_no,
      };

      this.loadFilterClick(dataSend2);
    },

    searchVehicleNoByText() {
      this.locations = [];
      let dataSend2 = {
        vehicle_no: this.searchVhicle,
      };
      this.loadFilter(dataSend2);
    },

    onSelectTypeTruck(ev) {
      this.type_truckFilter.push({
        type_truck_id: ev.type_truck_id,
      });
      this.changeFilterLocation();
    },

    onSelectStatus(ev) {
      this.statusFilter.push({
        status_name: ev.status_name,
      });
      this.changeFilterLocation();
    },

    locateGeoLocation(latitude, longitude) {
      const marker = {
        lat: latitude,
        lng: longitude,
      };
      this.locationMarkers.push({ position: marker });
      this.locPlaces.push(this.existingPlace);
      this.center = marker;
      this.existingPlace = null;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      // const userDatas = this.$getUserInfo();
      // this.detailUser = userDatas.sub;
      // // this.permision_role = userDatas.sub.permision_role;
      // let rolePermision = [];
      // userDatas.sub.permision_role.forEach(function (permision) {
      //   if (permision.permission.controller == "PackageController") {
      //     rolePermision = permision;
      //     // console.log(permision);
      //   }
      // });
      // if (!rolePermision.method_read) {
      //   this.$router.push("/authorized-error");
      // }
      // this.permision_role = rolePermision;
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadLocationTable();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    loadStatus() {
      const baseURI = this.$settings.endPointCt + `master-status`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchStatus = response.data.datas.data;
      });
    },

    pickupChange() {
      
    },

    loadDataOrder() {
      let startDate = moment(String(this.pickupdestination.startDate)).format(
        "YYYY-MM-DD H:mm:ss"
      );
      let endDate = moment(String(this.pickupdestination.endDate)).format(
        "YYYY-MM-DD H:mm:ss"
      );

      const baseURI =
        this.$settings.endPointCt +
        `blujay-order/history/` +
        startDate +
        `/` +
        endDate +
        `?vehicle_no=` +
        this.forms.vehicle_no;

      //http://localhost:8080/blujay-order/history/2022-01-01%09:00:00/2022-08-01%09:00:00?vehicle_no=B9340IG&tms_status=ON DUTY

      return this.$http.get(baseURI).then((response) => {

        this.fetchOrder = [];

        response.data.datas.data.forEach((res) => {
          res.order_detail.forEach((res2) => {
             var data={
              order_number: res2.order_number,
              customer_name: res2.customer_name,
              product_type:res2.product_type,
              name_rute:res.name_rute,
              pickup_date:res.pickup_date,
              delivery_date:res2.requested_delivery
             }

              this.fetchOrder.push(data);
          });
          
        });
   
  
      });
    },

    loadData(vehicle_no) {
      const baseURI =
        this.$settings.endPointCt + `blujay-order/detail-active/` + vehicle_no;

      return this.$http
        .get(baseURI)
        .then((response) => {
          this.loadDataDetail(response.data.datas.blujay_order_id);

          //console.log(this.forms);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 422) {
              this.loadError(
                "No Blujay Order with this vehicle number:" + vehicle_no
              );
            } else if (error.response.status === 500) {
              this.$router.push("/server-error");
            } else {
              this.$router.push("/page-not-found");
            }
          }
        });
    },

    loadError(pesan) {
      this.$swal({
        icon: "warning",
        backdrop: true,
        title: "Warning",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },

    loadDataDetail(blujay_order_id) {
      const baseURI =
        this.$settings.endPointCt + `blujay-order/detail/` + blujay_order_id;

      return this.$http.get(baseURI).then((response) => {
        this.forms = response.data.datas;

        this.loadDataOrder();
      });
    },


    loadDataDetailMonitoring(id) {
      const baseURI =
        this.$settings.endPointCt + `real-monitoring/detail/`+id;

      return this.$http.get(baseURI).then((response) => {
         this.detailMonitoring = response.data.datas;
    
      });
    },

    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.loadDataDetailMonitoring(params[0]);
    this.loadData(this.$route.params.vehicle_no);
    this.loadStatus();
    this.loadTripAnalysis();
    // const start = {
    //   lat: -6.2066575,
    //   lng: 106.7759629,
    // };

    // this.startLocation = start;

    // const end = {
    //   lat: -6.1810517,
    //   lng: 106.7679362,
    // };

    // this.endLocation = end;
    //this.setLocationLatLng();
    //this.locateGeoLocation(5.54829, 95.323753);
    this.fetchIt();
    this.loading();
  },
};
</script>
<style >
button.gm-ui-hover-effect {
  visibility: hidden;
}
</style>