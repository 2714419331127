import Status from "@/components/master/status/Status.vue";
import StatusAdd from "@/components/master/status/StatusAdd.vue";
import StatusDetail from "@/components/master/status/StatusDetail.vue";
import StatusEdit from "@/components/master/status/StatusEdit.vue";
import StatusUpload from "@/components/master/status/StatusUpload.vue";

let routesStatus= [
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "Status",
        component: Status
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "StatusAdd",
        component: StatusAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "StatusEdit",
        component: StatusEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "StatusDetail",
        component: StatusDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "StatusUpload",
        component: StatusUpload
      },
    
]

export default routesStatus
  