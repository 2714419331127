<template>
    <div>
      <div class="loader" v-if="this.isLoading"></div>   
      <div class="loadertext" v-if="this.isLoading"> {{$t('loadingTxt')}}...</div>   
      <div class="main-wrapper" :class="{'blur-content': this.isLoading}">
        <!--begin::sidebar-->
        <sidebar-component classMenu="allBranchTransactions"></sidebar-component>
        <!--end::sidebar-->
  
        <div class="page-wrapper">
          <!--begin::navbar-->
          <navbar-component></navbar-component>
          <!--end::navbar-->
  
          <!--begin::content-->
          <div class="page-content">
            
              <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
                <div class="card">
                  
  
                  <div class="card-body">

                      
                
                      <div class="row mb-3">
  
                          <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                              
                                  {{$t('period')}} : {{$t('from')}}
                                  <Datepicker
                                  :highlighted="highlighted"
                                  v-model="period_from"
                                  :bootstrap-styling=true
                                  format="dd/MM/yyyy"
                                  :placeholder="$t('period_from')"
                                ></Datepicker>
                               
                          </div>
  
                          <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                               
                                  {{$t('period')}} : {{$t('sampai')}}
                                  <Datepicker
                                  :highlighted="highlighted"
                                  v-model="period_to"
                                  :bootstrap-styling=true
                                  format="dd/MM/yyyy"
                                  :placeholder="$t('period_from')"
                                ></Datepicker>
                               
                          </div>
  
                          <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                              {{$t('pool_txt')}}
                             
                          <multiselect
                            v-model="master_pool_id"
                            :options="fetchPools"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            :placeholder="$t('pick_some_pool_name')"
                            label="pool_name"
                            track-by="pool_name"
                            :preselect-first="true"
                            @select="onSelectTypePools"
                            @remove="onUnselectTypePools"
                          >
                            <template
                              slot="selection"
                              slot-scope="{ values, isOpen }"
                              ><span
                                class="multiselect__single"
                                v-if="values.length &amp;&amp; !isOpen"
                                >{{ values.length }} {{$t('branch_selected')}}</span
                              >
                            </template>
                          </multiselect>
  
                          </div>
  
                          <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                              
                              {{$t('truck_type')}}
                              
                          <multiselect
                            v-model="vehicle_type"
                            :options="fetchTruck"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            :placeholder="$t('pick_some_type_truck')"
                            label="type_truck"
                            track-by="type_truck"
                            :preselect-first="true"
                            @select="onSelectTypeTruck"
                            @remove="onUnselectTypeTruck"
                            @search-change="onSearchChange"
                          >
                            <template
                              slot="selection"
                              slot-scope="{ values, isOpen }"
                              ><span
                                class="multiselect__single"
                                v-if="values.length &amp;&amp; !isOpen"
                                >{{ values.length }} {{$t('truck_selected')}}</span
                              >
                            </template>
                          </multiselect>
  
                          </div>
  
                         
  
  
                      </div>

                      <div class="row mb-3">
                        <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                              
                              {{$t('result_txt')}}
                             <select class="form-select" v-model="permit_status">
                                 <option value="">{{$t('all')}}</option>
                                 <option v-for="item in permit_status_datas" :key="item.value" :value="item.value">{{item.value}}</option>
                             </select>

                     </div>

                     <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                             {{$t('in_out_txt')}}
                             <select class="form-select" v-model="in_out">
                                 <option value="">{{$t('all')}}</option>
                                 <option v-for="item in in_out_datas" :key="item.value" :value="item.value">{{item.value}}</option>
                             </select>
                        
                     </div>

                     <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                         {{$t('vehicle_no')}} 
                          <input type="text" class="form-control" v-model="vehicle_no" :placeholder="$t('vehicle_no')">
                     </div>

                     <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                         <br>
                        <div class="input-group">

                         <button
                         class="btn btn-primary float-end" 
                         style="margin-left:20px"
                         @click="loadItems"
                         type="button">
                         <i class="link-icon" data-feather="search"></i>
                         {{ $t("search_txt") }}
                         </button> 

                         <button
                         class="btn btn-light float-end"
                         @click="clearSearch"
                         style="margin-left:20px"
                         type="button">
                         <i class="link-icon" data-feather="refresh-ccw"></i>
                         {{ $t("clear") }}
                         </button> 
                        
                         <button
                         class="btn btn-success float-end" 
                         style="margin-left:20px"
                         @click="downloadExcel"
                         type="button">
                         <i class="link-icon" data-feather="download"></i>
                         {{ $t("download") }}
                         </button> 
                         
                         <button
                        @click="$router.back()"
                        style="margin-left:20px"
                        class="btn btn-warning float-end"
                        type="button"
                      >
                        <i
                          class="link-icon"
                          size="10px"
                          data-feather="arrow-left"
                        ></i>
                        {{ $t("backMess") }}
                      </button>


                     

                        </div>
                         
                     </div>
                      </div>
  
                      
                      <div class="row">
                        <h3 > 
                            <span class="badge bg-primary mr-5" style="margin-left:20px">{{$t('branchTxt')}} : {{$t('all')}} </span>  
                            <span class="badge bg-success mr-5" style="margin-left:20px" v-if="this.$route.params.vehicletype!='all'">{{$t('type_truck')}} : {{this.$route.params.vehicletype}} </span>
                            <span class="badge bg-warning mr-5" style="margin-left:20px" v-if="this.$route.params.keperluan!='all'">{{$t('needs')}} : {{this.$route.params.keperluan}} </span>
                        </h3>

                        <!-- | {{$t('type_truck')}}: {{this.$route.params.vehicletype}} | {{$t('needs')}}: {{this.$route.params.keperluan}}      -->
                      </div>
                      
                      <br>
  
  
                      <vue-good-table title="gate-transaction-by-branch-dashboard" mode="remote" @on-page-change="onPageChange"
                      :select-options="{ enabled: false }"
                      @on-sort-change="onSortChange" @on-column-filter="onColumnFilter"
                      @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :line-numbers="true"
                      :isLoading.sync="isLoading" :pagination-options="{
                      enabled: true,
                      }" :rows="rows" :columns="columns">
  
                      <template slot="table-row" slot-scope="props">
                       <span v-if="props.column.field == 'actions'">
                          <a href="#" v-if="permision_role.method_read" title="Detail" @click.prevent="
                            detailData(props.index, props.row)
                          ">
                            <i class="mdi mdi-eye" style="font-size:16px"></i>
  
                          </a>
                      </span>
                      <span v-else-if="props.column.field == 'flag_out'">
                          <span v-if="props.row.flag_out == '0'">{{$t('yes_txt')}}</span>
                          <span v-else>{{$t('no_txt')}}</span>
                      </span>

                      <span v-else-if="props.column.field == 'permit_status'">
                        <h6 v-if="props.row.permit_status == 'APPROVED'"><span class="badge bg-success">{{props.row.permit_status}}</span></h6>
                        <h6 v-else><span class="badge bg-danger">{{props.row.permit_status}}</span></h6>
                      
                      </span>

                      <span v-else-if="props.column.field == 'document'">
                        <!-- /<li  v-for="(a, index) of props.row.status_surat_detail"  :key="index">{{a.nama_surat}} | {{a.status}}</li> -->
                            <span v-if="props.row.status_surat_detail != null || props.row.status_surat_detail.length >0">
                                
                                <span  v-for="(a, index) of props.row.status_surat_detail"  :key="index">
                                 
                                    <label class="tx-11 fw-bolder mb-0 text-uppercase">{{a.nama_surat}} : </label>
                                    <p class="text-muted">{{a.status}}</p>
                                </span>
                            </span>
                         
                        
                      </span>
                      <!-- <span v-else-if="props.column.field == 'kir'">
                           {{convertKir(props.row)}}
                      </span>
                      <span v-else-if="props.column.field == 'ktp'">
                           {{convertKtp(props.row)}}
                      </span> -->
                      <span
                            v-else-if="props.column.field == 'driver_name'"
                          >
                              {{ props.row.driver_name == '' ? props.row.nama_driver2  :  props.row.driver_name}}
                          </span>
                      <span v-else-if="props.column.field == 'rute'">
                           {{convertRute(props.row)}}
                      </span>
                      <span v-else>
                          {{ props.formattedRow[props.column.field] }}
                      </span>
                      </template>
                  </vue-good-table>
  
                     
  
                  </div>
  
                  
                </div>
              </div>
             
          </div>
          <!--end::content-->
  
          <!--begin::footer-->
          <footer-component></footer-component>
          <!--end::footer-->
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import sidebarComponent from "@/components/_partials/_sidebar";
  import navbarComponent from "@/components/_partials/_navbar";
  import footerComponent from "@/components/_partials/_footer";
  import Datepicker from "vuejs-datepicker";
  import multiselect from "vue-multiselect";
  export default {
      name: "allBranchTransactions",
      props: {},
      components: {
          "sidebar-component": sidebarComponent,
          "navbar-component": navbarComponent,
          "footer-component": footerComponent,
          Datepicker,
          multiselect: multiselect,
      },
      data() {
          return {
              highlighted:{
  
                  dates: [ // Highlight an array of dates
                      new Date(),
                  ],
  
              },
              vehicle_type:"",
              vehicle_types:[],
              fetchTruck: [],
              fetchPools: [],
              permit_status:"",
              permit_status_datas:"",
              permision_role: [],
              isLoading: false,
              maxToasts: 100,
              position: "up right",
              closeBtn: true,
              errors: [],
              userData: "",
              master_pool_id:"",
              master_pool_ids:[],
              vehicle_no:"",
              companyCode: "",
              forms: {},
              pools: [],
              branch_detail:{},
              period_from:"",
              in_out:"",
              in_out_datas:[],
              period_to:"",
              totalRecords: 0,
              serverParams: {
                  columnFilters: {},
                  sort: {
                  field: "",
                  type: "",
                  },
                  page: 1,
                  per_page: 10,
              },
              columns: [
                  {
                  label: this.$t('action_txt'),
                  field: "actions",
                  sortable: false,
                  },

                  {
                      label: this.$t('truck_type'),
                      field: "vehicle_type",
                      sortable: true,
                      filterOptions: {
                          enabled: false, // enable filter for this column
                          placeholder: "Filter By "+this.$t('truck_type'), // placeholder for filter input
                          filterValue: "", // initial populated value for this filter
                          filterDropdownItems: [], // dropdown (with selected values) instead of text input
                          trigger: "enter", //only trigger on enter not on keyup
                      },
                  },

                  {
                      label: this.$t('pool_location'),
                      field: "pool_name",
                      sortable: true,
                      filterOptions: {
                          enabled: false, // enable filter for this column
                          placeholder: "Filter By "+this.$t('pool_name'), // placeholder for filter input
                          filterValue: "", // initial populated value for this filter
                          filterDropdownItems: [], // dropdown (with selected values) instead of text input
                          trigger: "enter", //only trigger on enter not on keyup
                      },
                  },

                     {
                      label: this.$t('permit_status'),
                      field: "permit_status",
                      sortable: true,
                      filterOptions: {
                          enabled: false, // enable filter for this column
                          placeholder: "Filter By "+this.$t('permit_status'), // placeholder for filter input
                          filterValue: "", // initial populated value for this filter
                          filterDropdownItems: [], // dropdown (with selected values) instead of text input
                          trigger: "enter", //only trigger on enter not on keyup
                      },
                  },

                  {
                      label: 'In/Out',
                      field: "status",
                      sortable: true,
                      filterOptions: {
                          enabled: false, // enable filter for this column
                          placeholder: "Filter By In/Out", // placeholder for filter input
                          filterValue: "", // initial populated value for this filter
                          filterDropdownItems: [], // dropdown (with selected values) instead of text input
                          trigger: "enter", //only trigger on enter not on keyup
                      },
                  },

                  {
                      label: this.$t('result_txt'),
                      field: "flag_out",
                      sortable: true,
                      filterOptions: {
                          enabled: false, // enable filter for this column
                          placeholder: "Filter By "+this.$t('result_txt'), // placeholder for filter input
                          filterValue: "", // initial populated value for this filter
                          filterDropdownItems: [], // dropdown (with selected values) instead of text input
                          trigger: "enter", //only trigger on enter not on keyup
                      },
                  },

               

                  
                 
                  {
                      label: this.$t('date_time'),
                      field: "transaction_date",
                      sortable: true,
                      filterOptions: {
                          enabled: false, // enable filter for this column
                          placeholder: "Filter By "+this.$t('transaction_date'), // placeholder for filter input
                          filterValue: "", // initial populated value for this filter
                          filterDropdownItems: [], // dropdown (with selected values) instead of text input
                          trigger: "enter", //only trigger on enter not on keyup
                      },
                  },
                  {
                      label: this.$t('needs'),
                      field: "keperluan",
                      sortable: true,
                      filterOptions: {
                          enabled: false, // enable filter for this column
                          placeholder: "Filter By "+this.$t('needs'), // placeholder for filter input
                          filterValue: "", // initial populated value for this filter
                          filterDropdownItems: [], // dropdown (with selected values) instead of text input
                          trigger: "enter", //only trigger on enter not on keyup
                      },
                  },
                  {
                      label: this.$t('km'),
                      field: "km_real",
                      sortable: true,
                      filterOptions: {
                          enabled: false, // enable filter for this column
                          placeholder: "Filter By "+this.$t('km'), // placeholder for filter input
                          filterValue: "", // initial populated value for this filter
                          filterDropdownItems: [], // dropdown (with selected values) instead of text input
                          trigger: "enter", //only trigger on enter not on keyup
                      },
                  },

                  {
                      label: this.$t('muatan'),
                      field: "muatan",
                      sortable: true,
                      filterOptions: {
                          enabled: false, // enable filter for this column
                          placeholder: "Filter By "+this.$t('muatan'), // placeholder for filter input
                          filterValue: "", // initial populated value for this filter
                          filterDropdownItems: [], // dropdown (with selected values) instead of text input
                          trigger: "enter", //only trigger on enter not on keyup
                      },
                  },

                  {
                      label: this.$t('documentTxt'),
                      field: "document",
                      sortable: true,
                    //   filterOptions: {
                    //       enabled: false, // enable filter for this column
                    //       placeholder: "Filter By "+this.$t('muatan'), // placeholder for filter input
                    //       filterValue: "", // initial populated value for this filter
                    //       filterDropdownItems: [], // dropdown (with selected values) instead of text input
                    //       trigger: "enter", //only trigger on enter not on keyup
                    //   },
                  },
                
                 
                  {
                      label: this.$t('vehicle_no'),
                      field: "vehicle_no",
                      sortable: true,
                      filterOptions: {
                          enabled: false, // enable filter for this column
                          placeholder: "Filter By "+this.$t('vehicle_no'), // placeholder for filter input
                          filterValue: "", // initial populated value for this filter
                          filterDropdownItems: [], // dropdown (with selected values) instead of text input
                          trigger: "enter", //only trigger on enter not on keyup
                      },
                  },
                  
                  {
                      label: this.$t('driver_name'),
                      field: "driver_name",
                      sortable: true,
                      filterOptions: {
                          enabled: false, // enable filter for this column
                          placeholder: "Filter By "+this.$t('driver_name'), // placeholder for filter input
                          filterValue: "", // initial populated value for this filter
                          filterDropdownItems: [], // dropdown (with selected values) instead of text input
                          trigger: "enter", //only trigger on enter not on keyup
                      },
                  },
                  {
                      label: this.$t('last_order'),
                      field: "rute",
                      sortable: true,
                      filterOptions: {
                          enabled: false, // enable filter for this column
                          placeholder: "Filter By "+this.$t('last_order'), // placeholder for filter input
                          filterValue: "", // initial populated value for this filter
                          filterDropdownItems: [], // dropdown (with selected values) instead of text input
                          trigger: "enter", //only trigger on enter not on keyup
                      },
                  },
                  {
                      label: this.$t('surveyor'),
                      field: "create_by",
                      sortable: true,
                      filterOptions: {
                          enabled: false, // enable filter for this column
                          placeholder: "Filter By "+this.$t('surveyor'), // placeholder for filter input
                          filterValue: "", // initial populated value for this filter
                          filterDropdownItems: [], // dropdown (with selected values) instead of text input
                          trigger: "enter", //only trigger on enter not on keyup
                      },
                  },
                  {
                      label: this.$t('remarks_txt'),
                      field: "keterangan",
                      sortable: true,
                      filterOptions: {
                          enabled: false, // enable filter for this column
                          placeholder: "Filter By "+this.$t('remarks_txt'), // placeholder for filter input
                          filterValue: "", // initial populated value for this filter
                          filterDropdownItems: [], // dropdown (with selected values) instead of text input
                          trigger: "enter", //only trigger on enter not on keyup
                      },
                  },
              ],
              rows: [],
              paramId:0,
              params:"",
          };
      },
      computed: {
        myStyles () {
          return {
            height: '500px',
            position: 'relative'
          }
        }
      },
      watch: {},
      methods: {
  
        loadData() {
        
        this.isLoading = true;
        var baseURI = this.$settings.endPointCt + "pool-branch?download=all";

        return this.$http.get(baseURI,{ timeout: 10000 }).then((response) => {

        this.fetchPools    = response.data.datas

        this.isLoading = false;
        }).catch(error => {
            this.isLoading = false;
            if(error.code == "ECONNABORTED"){
                this.error(this.$t("check_internet_connection_txt"));
            }else{

                if (error.response) {
                    if(error.response.status === 422) {
                        this.errors = error.response.data.errors;
                        this.resultError(error.response.data.errors);
                    }else if (error.response.status === 500) {
                        this.$router.push('/server-error');
                    }else if (error.response.status === 401) {
                        this.$router.push('/authorized-error');
                    }else{
                        this.$router.push('/page-not-found');
                    }
                }

            }

        });
    },

    convertRute(data){
        return data.customer+ " | "+ data.rute
    },

    detailData(index, row){
        var params = this.$onRandom(row.gate_transaction_id);
        window.location.href = "/gt/transaction/detail/" + params;
    },

    clearSearch(){
        this.period_to = ""
        this.period_from = ""
        this.in_out = ""
        this.permit_status = ""
        this.vehicle_type = ""
        this.vehicle_types = [];
        this.master_pool_id = ""
        this.master_pool_ids = [];
        this.vehicle_no="";
        this.loadItems()
    },

    convertStnk(data){
        var status_surat_detail = data.status_surat_detail
        if(status_surat_detail.length > 0){
            var responseData = ""
            status_surat_detail.forEach(function (dstnk) {
                if (dstnk.nama_surat == 'STNK') {
                    responseData = dstnk.status
                }
            });
            return responseData
        }else{
            return ""
        }
    },

    convertKir(data){
        var status_surat_detail = data.status_surat_detail
        if(status_surat_detail.length > 0){
            var responseData = ""
            status_surat_detail.forEach(function (dstnk) {
                if (dstnk.nama_surat == 'KIR') {
                    responseData = dstnk.status
                }
            });
            return responseData
        }else{
            return ""
        }
    },

    // convertAllDocument(data){
    //     var response=[];
    //     var status_surat_detail = data.status_surat_detail
    //     if(status_surat_detail.length > 0){
    //         var responseData = ""
    //         status_surat_detail.forEach(function (res) {
    //             responseData = res.nama_surat+' : '+res.status
    //             push
    //         });
    //         return responseData
    //     }else{
    //         return ""
    //     }
    // },

    convertKtp(data){
        var status_surat_detail = data.status_surat_detail
        if(status_surat_detail.length > 0){
            var responseData = ""
            status_surat_detail.forEach(function (dstnk) {
                if (dstnk.nama_surat == 'KTP') {
                    responseData = dstnk.status
                }
            });
            return responseData
        }else{
            return ""
        }
    },
    convertTime(data){
        return this.$moment(data.transaction_date).format("HH:mm");
    },

    convertDate(data){
        return this.$moment(data.transaction_date).format("DD/MM/YYYY");
    },

    

    fetchIt() {
        const userDatas = this.$getUserInfo();
        this.detailUser = userDatas.sub;
        // this.permision_role = userDatas.sub.permision_role;
        let rolePermision = [];
        userDatas.sub.permision_role.forEach(function (permision) {
            if (permision.permission.controller == 'GateTransactionController') {
            rolePermision = permision;
            // console.log(permision);
            }
        });

        if (!rolePermision.method_read) {
            this.$router.push("/authorized-error");
        }

        this.permision_role = rolePermision;

    },
  

        fade(sType) {
            this.isLoading = sType;
        },

        loading() {
            this.fade(true);
            setTimeout(() => {
                this.fade(false);
            }, 1000); // hide the message after 3 seconds
        },

        resultError(data) {
            var count = Object.keys(data).length;
            for (var x = 0; x < count; x++) {
                var nameOb = Object.keys(data)[x];
                var objectData = data[nameOb];
                for (var y = 0; y < objectData.length; y++) {
                this.error(objectData[y]);
                }
            }
        },

        success(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });

            Toast.fire({
                icon: "success",
                title: kata,
            });
        },

        error(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "error",
                title: kata,
            });
        },

        getSystemCodeInOut(){
            var baseURI = this.$settings.endPoint + "system-code/select?download=all&system_code=GTSTATUS&sort_field=sequence&sort_type=ASC";
    
            return this.$http.get(baseURI,{ timeout: 10000 }).then((response) => {

            this.in_out_datas = response.data.datas;

            }).catch(error => {
                this.isLoading = false;
                if(error.code == "ECONNABORTED"){
                    this.error(this.$t("check_internet_connection_txt"));
                }
            });
        },

        getSystemCodePermitStatus(){
            var baseURI = this.$settings.endPoint + "system-code/select?download=all&system_code=GTPERMITSTATUS&sort_field=sequence&sort_type=ASC";
    
            return this.$http.get(baseURI,{ timeout: 10000 }).then((response) => {

            this.permit_status_datas = response.data.datas;

            }).catch(error => {
                this.isLoading = false;
                if(error.code == "ECONNABORTED"){
                    this.error(this.$t("check_internet_connection_txt"));
                }
            });
        },




        updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
        this.loadItems();
        },

        onColumnFilter(params) {
            params.page=1;
        this.updateParams(params);
        },

        onPageChange(params) {
        this.updateParams({ page: params.currentPage });
        },

        onSortChange(params) {
        this.updateParams({
            sort: {
            type: params[0].type,
            field: params[0].field,
            },
        });
        },


        loadTypeTruck(){
            const baseURI = this.$settings.endPointCt + `type-truck`;
            return this.$http.get(baseURI).then((response) => {
                this.fetchTruck = response.data.datas.data;
            });
        },

        onPerPageChange(params) {
            this.updateParams({ per_page: params.currentPerPage });
        },

        
        downloadExcel() {
            var vehicletype=this.$route.params.vehicletype;
            var status = this.in_out
            var flag_in=""
            var flag_out=""
            var vehicle_no = this.vehicle_no
            var permit_status = this.permit_status
            
            if(vehicletype!='all' && this.period_from=="" && this.period_to==""){    
                this.vehicle_types.push(vehicletype);
                status="in";
                flag_out=0;
                permit_status='APPROVED'
            }

            var keperluan=this.$route.params.keperluan;
            this.isLoading = true;
     
        
            this.baseURI = this.$settings.endPointGT + "gate-transaction/index";
            var date_from = this.period_from

            if(this.period_from != "" && vehicletype!='all'){
                date_from = this.$moment(this.period_from).format("YYYY-MM-DD")
                status="in";
            }

            if(this.period_from != "" && vehicletype=='all'){
                date_from = this.$moment(this.period_from).format("YYYY-MM-DD")
            }

            var date_to = this.period_to
            if(this.period_to != "" && vehicletype!='all'){
                date_to = this.$moment(this.period_to).format("YYYY-MM-DD")
                status="in";
            }

            if(this.period_to != "" && vehicletype=='all'){
                date_to = this.$moment(this.period_to).format("YYYY-MM-DD")
            }
            var vehicle_type_multiple = ""
            if(this.vehicle_types?.length > 0){
                vehicle_type_multiple = this.vehicle_types.toString();
            }

            var pools = ""
            if(this.master_pool_ids?.length > 0){
                pools = this.master_pool_ids.toString();
            }
     
           
           
           
            // var link="";
            // if(keperluan=='all'){
            //     link=`?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&branch_id=${branch_id}&date_from=${date_from}&date_to=${date_to}&vehicle_no=${vehicle_no}&permit_status=${permit_status}&status=${status}&vehicle_type_multiple=${vehicle_type_multiple}&master_pool_ids=${pools}`;
            // }else if(keperluan=='KIR'){
            //     link=`?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&branch_id=${branch_id}&date_from=${date_from}&date_to=${date_to}&vehicle_no=${vehicle_no}&vehicle_type_multiple=${vehicle_type_multiple}&master_pool_ids=${pools}&keperluan=${keperluan}&permit_status=APPROVED&flag_in=0&status=out`;
            // } else{
            //     link=`?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&branch_id=${branch_id}&date_from=${date_from}&date_to=${date_to}&vehicle_no=${vehicle_no}&status=${status}&vehicle_type_multiple=${vehicle_type_multiple}&master_pool_ids=${pools}&keperluan=${keperluan}&permit_status=APPROVED&flag_out=0`;
            // }

            if(keperluan=='all'){
                keperluan="";
            }

            if(keperluan=='KIR'){
                permit_status='APPROVED'
                flag_out=0
                status='out'
            }

            if(keperluan=='PARKING' || keperluan=='Corrective Maintenance' || keperluan=='Preventive Maintenance'){
                permit_status='APPROVED'
                flag_out=0
            }


            var link=`?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&date_from=${date_from}&date_to=${date_to}&vehicle_no=${vehicle_no}&permit_status=${permit_status}&status=${status}&vehicle_type_multiple=${vehicle_type_multiple}&master_pool_ids=${pools}&keperluan=${keperluan}&permit_status=${permit_status}&flag_in=${flag_in}&flag_out=${flag_out}&download=download`;


            var file_name = `transaction_`+keperluan+'_'+vehicletype+'.xlsx';

            //var columnFilters = this.serverParams.columnFilters;
            return this.$http
                .get(
                    this.baseURI +
                link,
                {
                    responseType: "blob",
                }
                )
                .then((response) => {
                this.isLoading = false;
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement("a");

                fileLink.href = fileURL;
                fileLink.setAttribute("download", file_name);
                document.body.appendChild(fileLink);
                fileLink.click();
                //this.fade(false);
                });

            

            
        },

        loadItems() {
            var vehicletype=this.$route.params.vehicletype;
            var status = this.in_out
            var flag_in=""
            var flag_out=""
            var vehicle_no = this.vehicle_no
            var permit_status = this.permit_status
            
            if(vehicletype!='all' && this.period_from=="" && this.period_to==""){    
                this.vehicle_types.push(vehicletype);
                status="in";
                flag_out=0;
                permit_status='APPROVED'
            }

            var keperluan=this.$route.params.keperluan;
            this.isLoading = true;
     
        
            this.baseURI = this.$settings.endPointGT + "gate-transaction/index";
            var date_from = this.period_from

            if(this.period_from != "" && vehicletype!='all'){
                date_from = this.$moment(this.period_from).format("YYYY-MM-DD")
                status="in";
            }

            if(this.period_from != "" && vehicletype=='all'){
                date_from = this.$moment(this.period_from).format("YYYY-MM-DD")
            }

            var date_to = this.period_to
            if(this.period_to != "" && vehicletype!='all'){
                date_to = this.$moment(this.period_to).format("YYYY-MM-DD")
                status="in";
            }

            if(this.period_to != "" && vehicletype=='all'){
                date_to = this.$moment(this.period_to).format("YYYY-MM-DD")
            }
            var vehicle_type_multiple = ""
            if(this.vehicle_types?.length > 0){
                vehicle_type_multiple = this.vehicle_types.toString();
            }

            var pools = ""
            if(this.master_pool_ids?.length > 0){
                pools = this.master_pool_ids.toString();
            }
     
           
           
           
            // var link="";
            // if(keperluan=='all'){
            //     link=`?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&branch_id=${branch_id}&date_from=${date_from}&date_to=${date_to}&vehicle_no=${vehicle_no}&permit_status=${permit_status}&status=${status}&vehicle_type_multiple=${vehicle_type_multiple}&master_pool_ids=${pools}`;
            // }else if(keperluan=='KIR'){
            //     link=`?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&branch_id=${branch_id}&date_from=${date_from}&date_to=${date_to}&vehicle_no=${vehicle_no}&vehicle_type_multiple=${vehicle_type_multiple}&master_pool_ids=${pools}&keperluan=${keperluan}&permit_status=APPROVED&flag_in=0&status=out`;
            // } else{
            //     link=`?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&branch_id=${branch_id}&date_from=${date_from}&date_to=${date_to}&vehicle_no=${vehicle_no}&status=${status}&vehicle_type_multiple=${vehicle_type_multiple}&master_pool_ids=${pools}&keperluan=${keperluan}&permit_status=APPROVED&flag_out=0`;
            // }

            if(keperluan=='all'){
                keperluan="";
            }

            if(keperluan=='KIR'){
                permit_status='APPROVED'
                flag_out=0
                status='out'
            }

            if(keperluan=='PARKING' || keperluan=='Corrective Maintenance' || keperluan=='Preventive Maintenance'){
                permit_status='APPROVED'
                flag_out=0
            }


            var link=`?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&date_from=${date_from}&date_to=${date_to}&vehicle_no=${vehicle_no}&permit_status=${permit_status}&status=${status}&vehicle_type_multiple=${vehicle_type_multiple}&master_pool_ids=${pools}&keperluan=${keperluan}&permit_status=${permit_status}&flag_in=${flag_in}&flag_out=${flag_out}`;

            return this.$http
                .get(
                this.baseURI +
                link,{ timeout: 10000 }
                )
                .then((response) => {
                    this.isLoading = false;
                    this.rows = response.data.datas.data;
                    this.totalRecords = response.data.datas.total;
                }).catch(error => {
                    this.isLoading = false;
                if(error.code == "ECONNABORTED"){
                    this.error(this.$t("check_internet_connection_txt"));
                }else{

                    if (error.response) {
                        if(error.response.status === 422) {
                                this.errors = error.response.data.errors;
                                this.resultError(error.response.data.errors);
                        }else if (error.response.status === 500) {
                            this.$router.push('/server-error');
                        }else if (error.response.status === 401) {
                                    this.$router.push('/authorized-error');
                        }else{
                            this.$router.push('/page-not-found');
                        }
                    }

                }

            });
        },

        onSelectTypePools(ev) {
            this.master_pool_ids.push(ev.master_pool_id);
        },

        onUnselectTypePools(ev) {
            this.master_pool_ids.splice(this.master_pool_ids.indexOf(ev.master_pool_id),1);
        },

        onSelectTypeTruck(ev) {
            this.vehicle_types.push(ev.type_truck);
        },

        onUnselectTypeTruck(ev) {
            this.vehicle_types.splice(this.vehicle_types.indexOf(ev.type_truck),1);
        },


        onSearchChange(ev) {
            const baseURI = this.$settings.endPointCt + `type-truck?select=select&type_truck=` + ev;
            return this.$http.get(baseURI).then((response) => {
                this.fetchTruck = response.data.datas.data;
            });
        },
   
  
      },
      events: {},
      created: function () { },
      mounted() {
          this.fetchIt();
          this.getSystemCodeInOut();
          this.getSystemCodePermitStatus();
          this.loadData();
          this.loadItems();
          this.loadTypeTruck();
      },
  };
  </script>
  <style scoped>
  button.gm-ui-hover-effect {
    visibility: hidden;
  }
  .blur-content{
    filter: blur(5px); 
  }
  .box {
    width: 200px;
    height: 10%;
    border-style: solid;
    border-width: 0.5px;
    background-color: red;
    text-align: center;
    vertical-align: middle;
  }
  </style>