<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TemplateOrderDetail"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12 pe-2">
            <div class="card">
              <div class="card-header">
                <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                <div class="row">
                  <div class="col-sm-3">
                    <div class="row">
                      <b>Customer</b>
                    </div>
                    <div class="row">
                      <b>{{ this.detailData.customer_name }}</b>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div>
                      <label class="form-label"
                        >Billable Tariff<span class="wajib">*</span></label
                      >
                      <input
                        type="text"
                        v-model="forms.billable_tariff_name"
                        class="form-control"
                        disabled
                      />
                      <div
                        v-for="error in errors.billable_tariff_name"
                        :key="error"
                        class="alert alert-primary"
                        role="alert"
                      >
                        <i data-feather="alert-circle"></i>
                        {{ error }}
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div>
                      <label class="form-label"
                        >Opportunity Id<span class="wajib">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="forms.opportunity_id"
                        :disabled="true"
                      />
                      <div
                        v-for="error in errors.opportunity_id"
                        :key="error"
                        class="alert alert-primary"
                        role="alert"
                      >
                        <i data-feather="alert-circle"></i>
                        {{ error }}
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div>
                      <label class="form-label"
                        >Unit Type<span class="wajib">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="unitType"
                        :disabled="true"
                      />
                      <div
                        v-for="error in errors.unit_type"
                        :key="error"
                        class="alert alert-primary"
                        role="alert"
                      >
                        <i data-feather="alert-circle"></i>
                        {{ error }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <!--begin::loading-data-->
                <div v-if="isLoading" class="d-flex align-items-center">
                  <div
                    class="spinner-border ms-auto"
                    role="status"
                    aria-hidden="true"
                  ></div>
                  <strong>{{ $t("loadingTxt") }}...</strong>
                </div>
                <!--end::loading-data-->
                <form
                  class="forms-sample"
                  @submit.prevent="submitData"
                  method="POST"
                >
                  <div
                    class="d-flex justify-content-between align-items-baseline mb-2"
                  ></div>
                  <div class="row mb-3">
                    <!-- Col -->
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="mb-3">
                          <label class="form-label"
                            >Geography <span class="wajib">*</span></label
                          >
                          <input
                            type="text"
                            v-model="geography"
                            class="form-control"
                            disabled
                          />

                          <div
                            v-for="error in errors.geography"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="mb-3">
                          <label class="form-label">Division </label>
                          <input
                            type="text"
                            v-model="forms.division"
                            class="form-control"
                            disabled
                          />

                          <div
                            v-for="error in errors.division"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="mb-3">
                          <label class="form-label">Order group</label>
                          <input
                            type="text"
                            v-model="forms.order_group"
                            class="form-control"
                            disabled
                          />
                          <div
                            v-for="error in errors.order_group"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-8">
                      <div v-if="this.geography == 'Stop Location'">
                        <div class="row">
                          <div class="mb-3">
                            <label class="form-label"
                              >Location <span class="wajib">*</span></label
                            >
                            <input
                              type="text"
                              v-model="forms.origin_location"
                              class="form-control"
                              disabled
                            />

                            <div
                              v-for="error in errors.origin_location"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="row" v-if="this.geographyLocation">
                          <div class="col-sm-6">
                            <p class="text-muted">
                              <b>{{ this.geographyLocation.name }}</b
                              ><br />
                              <i class="mdi mdi-map-marker"></i>Lat.{{
                                this.geographyLocation.latitude
                              }}
                              Long.{{ geographyLocation.longitude }} <br />
                              {{ geographyLocation.address1 }}
                              {{ geographyLocation.address2 }}
                              {{ geographyLocation.address2 }}
                              {{ geographyLocation.sub_district }},
                              {{ geographyLocation.district }},
                              {{ geographyLocation.city }},
                              {{ geographyLocation.province }}
                              {{ geographyLocation.postal_code }},
                              {{ geographyLocation.country }}<br />
                              {{ geographyLocation.contact_name }} -
                              {{ geographyLocation.phone_number }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        v-else-if="
                          this.geography == 'Country, City, State.Province'
                        "
                      >
                        <div class="row">
                          <div class="mb-3">
                            <label class="form-label">Country</label>
                            <v-select
                              :options="fetchCountryCode"
                              v-model="forms.origin_country"
                              label="country"
                              return-object
                              @input="loadProvince"
                            ></v-select>

                            <div
                              v-for="error in errors.origin_country"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="mb-3">
                            <label class="form-label">State / Province</label>
                            <input
                              type="text"
                              v-model="forms.origin_country"
                              class="form-control"
                              disabled
                            />

                            <div
                              v-for="error in errors.origin_state"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="mb-3">
                            <label class="form-label">City</label>
                            <input
                              type="text"
                              v-model="forms.origin_city"
                              class="form-control"
                              disabled
                            />
                            <div
                              v-for="error in errors.origin_city"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="mb-3">
                            <label class="form-label">Postal Code</label>
                            <div class="row">
                              <div class="col-6">
                                <input
                                  type="text"
                                  v-model="forms.origin_start_postal_code"
                                  class="form-control"
                                  disabled
                                />
                                <div
                                  v-for="error in errors.origin_start_postal_code"
                                  :key="error"
                                  class="alert alert-primary"
                                  role="alert"
                                >
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                              <div class="col-6">
                                <input
                                  type="text"
                                  v-model="forms.origin_end_postal_code"
                                  class="form-control"
                                  disabled
                                />
                                <div
                                  v-for="error in errors.origin_end_postal_code"
                                  :key="error"
                                  class="alert alert-primary"
                                  role="alert"
                                >
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else-if="this.geography == 'Region'">
                        <div class="row">
                          <div class="mb-3">
                            <label class="form-label"
                              >Region <span class="wajib">*</span></label
                            >
                            <input
                              type="text"
                              v-model="forms.origin_region"
                              class="form-control"
                              disabled
                            />
                            <div
                              v-for="error in errors.origin_region"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Col -->
                  </div>
                  <div class="row mb-3">
                    <div class="row mb-3">
                      <h3>Destination</h3>
                    </div>
                    <div class="col-sm-6">
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="mb-3">
                            <label class="form-label"
                              >Tier <span class="wajib">*</span></label
                            >
                            <input
                              type="text"
                              v-model="forms.tier"
                              class="form-control"
                              disabled
                            />
                            <div
                              v-for="error in errors.tier"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-2">
                          <div class="mb-3">
                            <label class="form-label">All Inclusive</label>
                            <div class="mb-4">
                              <div class="form-check mb-2">
                                <input
                                  type="checkbox"
                                  true-value="1"
                                  false-value="0"
                                  disabled
                                  class="form-check-input"
                                  id="future"
                                  v-model="forms.all_inclusive"
                                />
                                <label class="form-check-label" for="future">
                                  Yes
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="mb-3">
                            <label class="form-label">Qty</label>
                            <div class="mb-4">
                              <input
                                type="number"
                                disabled
                                id="future"
                                v-model="forms.qty"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="mb-3 col-sm-10">
                          <label class="form-label"
                            >Location <span class="wajib">*</span></label
                          >
                          <input
                            type="text"
                            v-model="forms.destination_location"
                            class="form-control"
                            disabled
                          />
                          <div
                            v-for="error in errors.destination_location"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label">Country</label>
                            <input
                              type="text"
                              v-model="forms.destination_country"
                              class="form-control"
                              disabled
                            />
                            <div
                              v-for="error in errors.destination_country"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label">State / Province</label>
                            <input
                              type="text"
                              v-model="forms.destination_state"
                              class="form-control"
                              disabled
                            />
                            <div
                              v-for="error in errors.destination_state"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="mb-3">
                            <label class="form-label">City</label>
                            <input
                              type="text"
                              v-model="forms.destination_city"
                              class="form-control"
                              disabled
                            />

                            <div
                              v-for="error in errors.destination_city"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="mb-3">
                            <label class="form-label">Start Postal Code</label>
                            <input
                              type="text"
                              v-model="forms.destination_start_postal_code"
                              class="form-control"
                              disabled
                            />

                            <div
                              v-for="error in errors.destination_start_postal_code"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="mb-3">
                            <label class="form-label">End Postal Code</label>
                            <input
                              type="text"
                              v-model="forms.destination_end_postal_code"
                              class="form-control"
                              disabled
                            />

                            <div
                              v-for="error in errors.destination_end_postal_code"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="mb-3 col-sm-10">
                          <label class="form-label"
                            >Region <span class="wajib">*</span></label
                          >
                          <input
                            type="text"
                            v-model="forms.destination_region"
                            class="form-control"
                            disabled
                          />
                          <div
                            v-for="error in errors.destination_region"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label">Min</label>
                            <input
                              type="number"
                              id="min"
                              name="min"
                              v-model="forms.min"
                              class="form-control"
                              placeholder="Min"
                              disabled
                            />
                            <div
                              v-for="error in errors.min"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label">Max</label>
                            <input
                              type="number"
                              id="max"
                              name="max"
                              v-model="forms.max"
                              class="form-control"
                              placeholder="Max"
                              disabled
                              @keydown.space.prevent
                            />
                            <div
                              v-for="error in errors.max"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Effective Date<span class="wajib">*</span></label
                            >
                            <input
                              type="text"
                              v-model="forms.effective_date"
                              class="form-control"
                              disabled
                            />
                            <!-- <date-range-picker
                              style="width: 100%"
                              :locale-data="locale"
                              v-model="effective_date"
                              :opens="opens"
                              :singleDatePicker="true"
                              :timePicker24Hour="true"
                              :timePicker="true"
                              @update="EffectiveDateChange"
                            >
                            </date-range-picker> -->
                            <div
                              v-for="error in errors.effective_date"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Expiration Date<span class="wajib"
                                >*</span
                              ></label
                            >
                            <input
                              type="text"
                              v-model="forms.expiration_date"
                              class="form-control"
                              disabled
                            />
                            <div
                              v-for="error in errors.expiration_date"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Sub Tariff <span class="wajib">*</span></label
                            >
                            <input
                              type="text"
                              v-model="forms.sub_tariff"
                              class="form-control"
                              disabled
                            />
                            <div
                              v-for="error in errors.sub_tariff"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Currency <span class="wajib">*</span></label
                            >
                            <input
                              type="text"
                              v-model="forms.currency"
                              class="form-control"
                              disabled
                            />

                            <div
                              v-for="error in errors.currency"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-10">
                          <div class="mb-3">
                            <label class="form-label"
                              >Accessorial Application Rule</label
                            >
                            <input
                              type="text"
                              v-model="forms.accessorial_application_rule"
                              class="form-control"
                              disabled
                            />
                            <div
                              v-for="error in errors.accessorial_application_rule"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label">SKU</label>
                            <!-- <v-select
                              :options="fetchSKU"
                              v-model="destination.sku"
                              label="sku_code"
                              return-object
                              @search="asyncSearchSKU"
                            ></v-select> -->
                            <input
                              type="text"
                              v-model="forms.sku"
                              class="form-control"
                              disabled
                            />
                            <div
                              v-for="error in errors.sku"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Basis <span class="wajib">*</span></label
                            >
                            <input
                              type="text"
                              v-model="forms.basis"
                              class="form-control"
                              disabled
                            />
                            <div
                              v-for="error in errors.basis"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label">Rate</label>
                            <!-- <v-select
                              :options="fetchRate"
                              v-model="forms.rate"
                              label="code"
                              return-object
                            ></v-select> -->
                            <input
                              type="number"
                              id="rate"
                              name="rate"
                              disabled
                              v-model="forms.rate"
                              class="form-control"
                              placeholder="Rate"
                              @keydown.space.prevent
                            />

                            <div
                              v-for="error in errors.rate"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Equipment Type
                              <span class="wajib">*</span></label
                            >
                            <input
                              type="text"
                              v-model="forms.equipment_type"
                              class="form-control"
                              disabled
                            />

                            <div
                              v-for="error in errors.equipment_type"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >No Intermediate Stop</label
                            >
                            <div class="mb-4">
                              <div class="form-check mb-2">
                                <input
                                  type="checkbox"
                                  true-value="1"
                                  false-value="0"
                                  class="form-check-input"
                                  id="intermediateStop"
                                  disabled
                                  v-model="forms.no_intermediate_stops"
                                />
                                <label
                                  class="form-check-label"
                                  for="intermediateStop"
                                >
                                  Yes
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label">Allocation Method </label>
                            <input
                              type="text"
                              v-model="forms.allocation_method"
                              class="form-control"
                              disabled
                            />

                            <div
                              v-for="error in errors.allocation_method"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        class="btn btn-warning btn-icon-text"
                        type="button"
                        @click="rateBreak"
                      >
                        <span class="btn-icon-prepend">
                          <svg
                            version="1.0"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14px"
                            height="14px"
                            viewBox="0 0 300.000000 300.000000"
                            preserveAspectRatio="xMidYMid meet"
                          >
                            <!-- <path fill="none" d="M0 0h24v24H0z" />
                                  <path fill="red"
                                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" /> -->
                            <g
                              transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
                              fill="#000000"
                              stroke="none"
                            >
                              <path
                                d="M350 2160 c0 -462 4 -840 8 -840 4 0 98 36 207 81 110 44 207 80 215 81 8 0 178 -76 378 -170 l362 -171 222 84 c123 47 228 85 235 85 7 0 62 -34 122 -75 l110 -76 220 103 221 102 0 476 0 475 -342 342 -343 343 -807 0 -808 0 0 -840z m1542 418 l3 -333 328 -3 327 -2 0 -408 0 -408 -166 -77 -166 -77 -109 75 c-59 41 -112 75 -118 75 -5 0 -110 -38 -233 -85 -123 -47 -227 -85 -233 -85 -5 0 -174 77 -374 171 l-363 172 -157 -63 c-86 -34 -162 -64 -168 -67 -10 -4 -13 144 -13 721 l0 726 720 0 720 0 2 -332z m348 7 l245 -245 -248 0 -247 0 0 245 c0 135 1 245 3 245 1 0 112 -110 247 -245z"
                              />
                              <path
                                d="M646 2654 c-22 -22 -20 -50 6 -68 18 -13 55 -16 198 -16 190 0 220 7 220 54 0 43 -13 46 -216 46 -162 0 -195 -3 -208 -16z"
                              />
                              <path
                                d="M647 2382 c-22 -24 -21 -45 1 -65 17 -15 68 -17 525 -17 446 0 507 2 521 16 9 8 16 24 16 34 0 10 -7 26 -16 34 -14 14 -75 16 -523 16 -479 0 -509 -1 -524 -18z"
                              />
                              <path
                                d="M646 2024 c-19 -18 -21 -45 -4 -62 17 -17 1639 -17 1656 0 15 15 15 51 0 66 -9 9 -206 12 -824 12 -725 0 -814 -2 -828 -16z"
                              />
                              <path
                                d="M646 1794 c-19 -18 -21 -45 -4 -62 17 -17 1639 -17 1656 0 15 15 15 51 0 66 -9 9 -206 12 -824 12 -725 0 -814 -2 -828 -16z"
                              />
                              <path
                                d="M558 1277 l-208 -91 0 -593 0 -593 1150 0 1150 0 0 630 c0 409 -3 630 -10 630 -5 0 -100 -42 -210 -94 l-200 -94 -127 74 c-71 40 -135 74 -143 74 -8 0 -113 -38 -233 -84 l-217 -84 -361 159 c-198 87 -365 159 -372 158 -7 0 -105 -41 -219 -92z m590 -171 c194 -86 356 -156 360 -156 4 0 106 38 227 85 l219 84 135 -77 c74 -42 138 -76 143 -74 4 1 76 34 158 72 83 39 152 70 155 70 3 0 5 -229 5 -510 l0 -510 -1050 0 -1050 0 0 518 0 517 163 72 c89 39 167 70 172 68 6 -2 169 -73 363 -159z"
                              />
                              <path
                                d="M642 798 c-17 -17 -15 -44 4 -62 14 -14 103 -16 828 -16 618 0 815 3 824 12 15 15 15 51 0 66 -17 17 -1639 17 -1656 0z"
                              />
                              <path
                                d="M646 474 c-22 -22 -20 -50 6 -68 20 -14 114 -16 818 -16 704 0 798 2 818 16 27 19 29 60 3 74 -13 7 -289 10 -825 10 -718 0 -806 -2 -820 -16z"
                              />
                            </g>
                          </svg>
                        </span>
                        Rate Break
                      </button>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button
                      class="btn btn-warning text-black fw-bolder btn-icon-text"
                      @click="backForm"
                      type="button"
                    >
                      <i class="link-icon" data-feather="arrow-left"></i>
                      {{ $t("backMess") }}
                    </button>
                    <button
                      v-if="!isLoading"
                      class="btn btn-success float-end btn-sm"
                      type="button"
                      @click="sendToBlujay"
                    >
                      <i class="link-icon" data-feather="save"></i>
                      Process To Blujay
                    </button>
                    <!-- <a
                      class="btn btn-success me-2 text-white fw-bolder btn-icon-text float-end"
                      @click="submitData()"
                    >
                      <i class="link-icon" data-feather="save"></i>
                      {{ $t("submitOrderTxt") }}
                    </a> -->
                  </div>
                </form>
              </div>
            </div>
            <modal-rate-break
              :showModal="showModalRateBreak"
              :data="passData"
              @closeModal="closeMyModal"
            ></modal-rate-break>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import modalRateBreak from "@/components/modal/modalRateBreak.vue";
import myMixinAddress from "@/middleware/mixinAddress.js";

import moment from "moment";

export default {
  name: "CommodityDetail",
  mixins: [myMixinAddress],
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "modal-rate-break": modalRateBreak,
  },
  data() {
    const startDate = new Date();
    const endDate = new Date();
    return {
      passData: [],
      fetchCountryCode: [],

      fetchProvince: [],
      fetchPostalCode: [],
      fetchCity: [],

      fetchDestProvince: [],
      fetchDestPostalCode: [],
      fetchDestCity: [],

      fetchGeography: [],
      fetchDivision: [],
      fetchOrderGroup: [],
      fetchLocation: [],
      fetchDestLocation: [],
      fetchBillableTariff: [],
      fetchRegion: [],
      fetchBasis: [],
      fetchSku: [],
      fetchAccessorial: [],
      fetchSubTarif: [],
      fetchAllocationMethod: [],
      fetchCurrency: [],
      fetchTier: [],
      fetchEquipmentType: [],
      fetchUnitType: [],

      showModalRateBreak: false,
      locale: {
        format: "dd mmm yyyy H:MM:ss",
      },
      opens: "center",
      effective_date: {
        startDate,
        endDate,
      },
      expiration_date: {
        startDate,
        endDate,
      },

      billableTariff: [],
      sku: [],
      division: "",
      orderGroup: "",
      unitType: "",
      tier: "",
      subTariff: "",
      currency: "",
      accessorial: "",
      basis: "",
      equipmentType: "",
      allocationMethod: "",

      geography: [],
      geographyCountry: [],
      geographyProvince: [],
      geographyCity: [],
      geographyStartPostalCode: [],
      geographyEndPostalCode: [],
      geographyLocation: [],
      geographyRegion: [],

      destCountry: [],
      destProvince: [],
      destCity: [],
      destStartPostalCode: [],
      destEndPostalCode: [],
      destLocation: [],
      destRegion: [],

      detailData: [],
      location_address: false,
      permision_role: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      forms: {},
      destination: {},
    };
  },
  watch: {},
  methods: {
    resetForm() {},
    checkData() {
      // console.log(this.destCountry);
    },
    backForm() {
      window.location.href = "/billable-rate/detail/" + this.$route.params.id;
    },
    // Load & Async
    loadDestLocationDetail(id) {
      this.fade(true);
      const baseURI = this.$settings.endPoint + `location/detail/` + id;
      return this.$http
        .get(baseURI)
        .then((response) => {
          this.destLocation = response.data.datas;
          this.destLocation.location_name = this.destLocation.name;
          this.loading();
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              // console.log(error.response.data.errors.message);
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.errors = error.response.data.errors;
                this.error(error.response.data.errors.message);
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },
    loadLocationDetail(id) {
      this.fade(true);
      const baseURI = this.$settings.endPoint + `location/detail/` + id;
      return this.$http
        .get(baseURI)
        .then((response) => {
          this.geographyLocation = response.data.datas;
          this.geographyLocation.location_name = this.geographyLocation.name;
          this.loading();
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              // console.log(error.response.data.errors.message);
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.errors = error.response.data.errors;
                this.error(error.response.data.errors.message);
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },
    // End Load & Async
    loadData(id) {
      this.fade(true);
      const baseURI =
        this.$settings.endPoint + "rm-billable-rate-header/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        this.detailData = response.data.datas.header;
        this.forms.min = 0;
        this.forms.max = 0;
        this.forms.all_inclusive = "0";
        this.forms.no_intermediate_stops = "0";
        this.loadDetail();
      });
    },
    changeGeography(ev) {
      if (ev !== "Country, City, State.Province") {
        this.geographyCountry = [];
        this.geographyProvince = [];
        this.geographyCity = [];
        this.geographyStartPostalCode = [];
        this.geographyEndPostalCode = [];
      }
      if (ev !== "Stop Location") {
        this.geographyLocation = [];
      }
      if (ev !== "Region") {
        this.geographyRegion = [];
      }
    },
    rateBreak() {
      // console.log(this.forms);
      this.passData = {
        id: this.forms.rm_billable_rate_detail_id,
      };
      this.showModalRateBreak = true;
    },
    closeMyModal() {
      this.showModalRateBreak = false;
    },
    sendToBlujay() {
      var baseURI =
        this.$settings.endPoint + "rm-billable-rate-header/send-to-blujay-nintex";
      var data = {
        rm_billable_rate_detail_ids: [this.forms.rm_billable_rate_detail_id],
      };
      // console.log(data);
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
          this.$http
            .post(baseURI, data, { timeout: 30000 })
            .then((response) => {
              if (response.data.status === 200) {
                // console.log("asdsadsadas");
                this.backForm();
                this.success(response.data.datas.messages);
                this.loadItems();
                this.isloading = false;
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {
                if (error.response) {
                  // console.log(error.response.data.errors.message);
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.errors = error.response.data.errors;
                    this.error(error.response.data.errors.message);
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push("/page-not-found");
                  }
                }
              }
            });
        }
      });
    },

    loadDetail() {
      this.fade(true);
      var paramsDetail = this.$onBehind(this.$route.params.detailId);
      const baseURI =
        this.$settings.endPoint +
        "rm-billable-rate-header/detail-detail/" +
        paramsDetail[0];
      return this.$http.get(baseURI).then((response) => {
        var data = response.data.datas;
        this.forms = data;
        // console.log(this.forms);
        // this.loadDestLocationDetail(data.destination_location_id);
        if (
          data.destination_location_id !== "" &&
          data.destination_location_id
        ) {
          this.loadDestLocationDetail(data.destination_location_id);
        }
        if (data.origin_location && data.origin_location) {
          this.loadLocationDetail(data.origin_location_id);
          this.geography = "Stop Location";
        }

        if (data.origin_region) {
          this.geographyRegion = {
            region_code: data.origin_region,
          };
          this.geography = "Region";
        }

        if (
          data.origin_country &&
          data.origin_end_postal_code &&
          data.origin_state &&
          data.origin_start_postal_code &&
          data.origin_city
        ) {
          this.geographyCountry = {
            country: data.origin_country,
          };
          this.geographyProvince = {
            province: data.origin_state,
          };
          this.geographyCity = {
            city: data.origin_city,
          };
          this.geographyStartPostalCode = {
            postal_code: data.origin_end_postal_code,
          };
          this.geographyEndPostalCode = {
            postal_code: data.origin_start_postal_code,
          };
          this.geography = "Country, City, State.Province";
        }
        this.loading();
      });
    },
    submitData() {
      if (this.validation()) {
        var effective_date = moment(this.effective_date.startDate).format(
          "YYYY-MM-DD HH:mm"
        );
        var expiration_date = moment(this.expiration_date.startDate).format(
          "YYYY-MM-DD HH:mm"
        );
        this.forms.rm_billable_rate_header_id =
          this.detailData.rm_billable_rate_header_id;
        this.forms.rm_billable_tariff_id =
          this.billableTariff.rm_billable_tariff_id;
        this.forms.billable_tariff_name = this.billableTariff.tariff_name;
        this.forms.tariff_type = this.billableTariff.tariff_type;
        this.forms.type_truck_id = this.sku.type_truck_id;
        this.forms.sku = this.sku.type_truck;
        this.forms.tier = this.tier;
        this.forms.division = this.division;
        this.forms.order_group = this.orderGroup;

        this.forms.unit_type = this.unitType;

        this.forms.origin_location_id =
          this.geographyLocation.location_id || null;
        this.forms.origin_location = this.geographyLocation.name || null;
        this.forms.origin_city = this.geographyCity.city || null;
        this.forms.origin_state = this.geographyProvince.province || null;
        this.forms.origin_start_postal_code =
          this.geographyStartPostalCode.postal_code || null;
        this.forms.origin_end_postal_code =
          this.geographyEndPostalCode.postal_code || null;
        this.forms.origin_country = this.geographyCountry.country || null;
        this.forms.origin_region = this.geographyRegion.region_code || null;

        this.forms.destination_location_id =
          this.destLocation?.location_id || null;
        this.forms.destination_location = this.destLocation?.name || null;
        this.forms.destination_city = this.destCity?.city || null;
        this.forms.destination_state = this.destProvince?.province || null;
        this.forms.destination_start_postal_code =
          this.destStartPostalCode?.postal_code || null;
        this.forms.destination_end_postal_code =
          this.destEndPostalCode?.postal_code || null;
        this.forms.destination_country = this.destCountry?.country || null;
        this.forms.destination_region = this.destRegion?.region_code || null;

        this.forms.currency = this.currency;
        this.forms.sub_tariff = this.subTariff;
        this.forms.effective_date = effective_date;
        this.forms.expiration_date = expiration_date;
        this.forms.equipment_type = this.equipmentType;
        this.forms.accessorial_application_rule = this.accessorial;
        this.forms.basis = this.basis;
        this.forms.allocation_method = this.allocationMethod;

        var params = this.$onBehind(this.$route.params.detailId);

        const baseURI =
          this.$settings.endPoint +
          "rm-billable-rate-header/update-detail/" +
          params[0];
        this.$http
          .patch(baseURI, this.forms)
          .then((response) => {
            if (response.data.status === 200) {
              //this.resetForm();
              this.errors = [];

              this.loading();
              window.location.href =
                "/billable-rate/detail/" + this.$route.params.id;
              this.success("Berhasil");
            } else {
              this.loading();
              this.errors = response.data.errors;
              this.resultError(response.data.errors);
            }
          })
          .catch((error) => {
            this.loading();
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
          });
      }
    },
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RateManagementController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);

    this.fetchIt();
    this.loadData(params[0]);
    // this.getLocation();
  },
};
</script>
<style scoped></style>
