import { render, staticRenderFns } from "./AlertSetting.vue?vue&type=template&id=1eeca10e&scoped=true"
import script from "./AlertSetting.vue?vue&type=script&lang=js"
export * from "./AlertSetting.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1eeca10e",
  null
  
)

export default component.exports