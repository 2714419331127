import LeadTime from "@/components/master/lead_time/LeadTime.vue";
import LeadTimeAdd from "@/components/master/lead_time/LeadTimeAdd.vue";
import LeadTimeDetail from "@/components/master/lead_time/LeadTimeDetail.vue";
import LeadTimeEdit from "@/components/master/lead_time/LeadTimeEdit.vue";
import LeadTimeUpload from "@/components/master/lead_time/LeadTimeUpload.vue";

let routesLeadTime= [
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "LeadTime",
        component: LeadTime
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "LeadTimeAdd",
        component: LeadTimeAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "LeadTimeEdit",
        component: LeadTimeEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "LeadTimeDetail",
        component: LeadTimeDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "LeadTimeUpload",
        component: LeadTimeUpload
      },
    
]

export default routesLeadTime
  