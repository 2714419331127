<template>
    <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Auto Accept </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
              aria-label="btn-close"></button>
          </div>
  
          <div class="modal-body">
           
            <div class="row">
              <!-- Tabs Navigation -->
              <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link" :class="{ active: activeTab === 'order' }" @click="activeTab = 'order'">Data Order</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" :class="{ active: activeTab === 'auto_accept' }" @click="activeTab = 'auto_accept'">Data Auto Accept</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" :class="{ active: activeTab === 'tracking' }" @click="activeTab = 'tracking'">Data Tracking</button>
                </li>
              </ul>

              <!-- Tabs Content -->
              <div class="tab-content">
                <div v-if="activeTab === 'order'" class="tab-pane fade" :class="{ 'show active': activeTab === 'order' }">
                  <h4>Data Order</h4>
                  <pre>{{ formattedOrderData }}</pre>
                </div>

                <div v-if="activeTab === 'auto_accept'" class="tab-pane fade" :class="{ 'show active': activeTab === 'auto_accept' }">
                  <h4>Data Auto Accept</h4>
                  <pre>{{ formattedAutoAcceptData }}</pre>
                </div>

                <div v-if="activeTab === 'tracking'" class="tab-pane fade" :class="{ 'show active': activeTab === 'tracking' }">
                  <h4>Data Tracking</h4>
                  <pre>{{ formattedTrackingData }}</pre>
                </div>
              </div>


            </div>
          
  
  
          </div>
  
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
              close
            </button>
            <!-- <button class="btn btn-success" type="submit" v-on:click="saveAssign">
              <span v-if="isLoading" class="spinner-border spinner-border-sm ms-auto" role="status"
                aria-hidden="true"></span>Save
            </button> -->
          </div>
  
        </div>
      </div>
  
          <!-- <modal-add-free-pick
            :showModal="showModalAdd" 
            :data="dataSendToModalAdd"
            :type="type_"
            @closeModal="closeMyModal">
          </modal-add-free-pick> -->
  
    </div>
  
    
  </template>
  
  <script>
//   import multiselect from "vue-multiselect";
//   import ModalAddFreePick from "@/components/modal/modalAddFreePick.vue";
  
  export default {
    components: {
    //   "multiselect": multiselect,
    //   "modal-add-free-pick":ModalAddFreePick
    },
    name: "ModalFreePick",
    emits: ["closeModal"],
    props: {
      showModal: Boolean,
      data: [],
    },
    watch: {
      showModal: {
        handler(newVal) {
            // console.log(this.data?.shipwithref);
          //   this.forms = {
          //       alert_id:this.data.alert_id,
          //       alert_setting_id:this.data.alert_setting_id,
          //       vehicle_no: this.data.vehicle_no,
          //       alert_time: this.data.alert_time,
          //       alert_type: this.data.alert_type,
          //       alert_value: this.data.alert_value,
          //       alert_subtype: this.data.alert_subtype,
          //       alert_off_reason: this.data.alert_off_reason,
          //     };
        
          this.active = newVal;

          if (this.active) {
            this.loadItems(this.data?.shipwithref);
          }
          const body = document.querySelector("body");
          this.showModal
            ? body.classList.add("modal-open")
            : body.classList.remove("modal-open");
        },
        immediate: true,
        deep: true,
      },
    },
    data() {
      return {
        activeTab:'order',
        dataSendToModalAdd:'',
        type_:'',
        formattedOrderData:'',
        formattedAutoAcceptData:'',
        formattedTrackingData:'',
        showModalAdd:false,
        classColapsed: '',
        isLoading: false,
        active: this.showModal,
        forms: [],
        errors: [],
        locale: [],
        fetch_auto_load:[],
        auto_load:"",
        report_date: [],
        pickup_date: "",
        delivery_date: "",
        groupByCategory: [],
        type_error: [],
        rows: "",
        prioritycek: [],
        status_queue:'',
        status_processing:'',
        date_last_schedule_processing:'',
  
      };
    },
    methods: {
      resetForm() {
        this.forms = [];
      },
  
          loadDriver() {
        const baseURI =
          this.$settings.endPointCt + `master-driver/select`;
        return this.$http.get(baseURI).then((response) => {
          this.fetchDriver = response.data.datas.data;
  
        });
      },
  
      success2(msg) {
        this.$swal({
          icon: 'info',
          backdrop: true,
          title: "Information",
          html: "<p>* " + msg + "</p>",
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      },
  
  
      errorAlert(msg) {
        this.$swal({
          icon: 'danger',
          backdrop: true,
          title: "Information",
          html: "<p>* " + msg + "</p>",
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      },
  
      OpenNewDriver(){
        this.showModalAdd=this.$route.name;
        this.type_="driver";
        this.dataSendToModalAdd={
          transporter:this.transporter.carrier_code,
          vehicle_no:this.vehicle_no_vendor,
          driver:this.driver_vendor,
          typeTruck:this.typeTruck
        }
      },
  
      OpenNewVehicleNo(){
        this.showModalAdd=true;
        this.type_="truck";
        this.dataSendToModalAdd={
          transporter:this.transporter.carrier_code,
          vehicle_no:this.vehicle_no_vendor,
          driver:this.driver_vendor,
          typeTruck:this.typeTruck
        }
      },
  
   
  
      closeMyModal(){
        this.showModalAdd=false;
        this.fetchTransporter=[];
        this.loadTransporter();
  
        this.loadForCarrier(this.transporter.carrier_code);
        // this.transporter=[];
        // this.vehicle_no_vendor="";
        // this.driver_vendor="";
        // this.typeTruck="";
        // this.fetchDriverVendor='';
        // this.fetchVehicleNoVendor='';
        //this.loadTransporter();
      },
  
  
      
   
     
     
  
     
     
  
     
  
      loadItems(shipwith_ref) {
       
      
        const baseURI = this.$settings.endPoint + "control-tower/transport-order-header/get-datas-auto-accept";
        return this.$http
          .post(
            baseURI,
            {
              "shipwith_ref":shipwith_ref
            }
          )
          .then((response) => {
  
            

            const orderData = {
              company_id: response.data.datas.messages.company_id,
              pick_plan: response.data.datas.messages.pick_plan,
              vehicle_no: response.data.datas.messages.vehicle_no,
              driver_id: response.data.datas.messages.driver_id,
              driver_name: response.data.datas.messages.driver_name,
              type_truck: response.data.datas.messages.type_truck,
              order_type: response.data.datas.messages.order_type,
              branch_name: response.data.datas.messages.branch_name,
              pick_latitude: response.data.datas.messages.pick_latitude,
              pick_longitude: response.data.datas.messages.pick_longitude,
              pick_name: response.data.datas.messages.pick_name,
              dest_name: response.data.datas.messages.dest_name,
              cutoff_date: response.data.datas.messages.cutoff_date,
              limit_radius_km: response.data.datas.messages.limit_radius_km,
              limit_radius_km_onduty: response.data.datas.messages.limit_radius_km_onduty,
              limit_max_onduty_hour: response.data.datas.messages.limit_max_onduty_hour,
              limit_min_margin_vendor: response.data.datas.messages.limit_min_margin_vendor,
              shipwith_ref: response.data.datas.shipwith_ref
            };
      


            this.formattedOrderData=JSON.stringify(orderData, null, 2);
            this.formattedAutoAcceptData=JSON.stringify(response.data.datas.messages.datas_auto_accept, null, 2);
            this.formattedTrackingData=JSON.stringify(response.data.datas.messages.tracking_auto_accept, null, 2);
           
            // console.log(this.rows.length);
         
  
  
  
  
            // this.totalRecords = response.data.datas.total;
            // this.isLoading = false;
          }).catch(error => {
            this.isLoading = false;
            if (error.code == "ECONNABORTED") {
              this.error(this.$t("check_internet_connection_txt"));
            } else {
  
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push('/server-error');
                } else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
                } else {
                  this.$router.push('/page-not-found');
                }
              }
  
            }
  
          });
      },
  
  
  
  
    },
    mounted() {
      //tail(this.data);
    },
  };
  </script>
  
  <style scoped>
  .modal {
    background: rgba(0, 0, 0, 0.4);
  }
  
  .modal-content {
    background: #fff;
    border-radius: 0px;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 0px;
    width: 100%;
    height: 100%;
    margin-left:0px !important;
  }
  
  .table-container {
      max-height: 100px; /* Set the maximum height for the table container */
      overflow-y: auto; /* Enable vertical scrolling */
  }
  </style>