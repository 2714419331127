<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="RegionDetail"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            "
          >
            <div class="col-lg-22 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left:25px">Detail {{ $t("regionTxt") }}</h6>
                  <button
                    class="btn btn-light float-end"
                    type="button"
                    data-bs-toggle="collapse" 
                    data-bs-target="#collapseExample" 
                    aria-expanded="false" 
                    aria-controls="collapseExample"
                  >
                    <i class="link-icon" data-feather="info"></i>
                    Audit Info
                  </button>

                  <div class="row mb-3 collapse" id="collapseExample" style="padding-left:20px">
                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0 ">Created By:</label>
                      <p class="text-muted">{{this.forms.create_by}}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created At:</label>
                      <p class="text-muted">{{this.forms.created_at}}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated By:</label>
                      <p class="text-muted">{{this.forms.update_by}}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated At:</label>
                      <p class="text-muted">{{this.forms.updated_at}}</p>
                    </div>

                    
                    
                  </div>

                </div>

                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->
                

                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "
                    ></div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left:25px">{{
                          $t("regionCode")
                        }}</label>
                      </div>
                      <div class="col-lg-4">
                        <input
                          type="text"
                          class="form-control"
                          id="region code"
                          :placeholder="$t('regionCode')"
                          v-model="forms.region_code"
                          readonly
                        />
                        <div v-if="errors.region_code">
                          <div
                            v-for="error in errors.region_code"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left:25px">{{
                          $t("descriptionTxt")
                        }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="region_description"
                          :placeholder="$t('descriptionTxt')"
                          v-model="forms.region_description"
                          readonly
                        />
                        <div v-if="errors.region_description">
                          <div
                            v-for="error in errors.region_description"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left:25px">{{
                          $t("countryTxt")
                        }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="country"
                          :placeholder="$t('countryTxt')"
                          v-model="forms.country"
                          readonly
                        />
                        <div v-if="errors.country">
                          <div
                            v-for="error in errors.country"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left:25px">{{
                          $t("provinceTxt")
                        }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="province"
                          :placeholder="$t('provinceTxt')"
                          v-model="forms.province"
                          readonly
                        />
                        <div v-if="errors.province">
                          <div
                            v-for="error in errors.province"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left:25px">{{
                          $t("cityTxt")
                        }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="city"
                          :placeholder="$t('cityTxt')"
                          v-model="forms.city"
                          readonly
                        />
                        <div v-if="errors.city">
                          <div
                            v-for="error in errors.city"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-lg-4">
                        <input
                          type="text"
                          class="form-control"
                          id="city_type"
                          :placeholder="$t('cityTypeTxt')"
                          v-model="forms.city_type"
                          readonly
                        />
                        <div v-if="errors.city_type">
                          <div
                            v-for="error in errors.city_type"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div> -->
                    </div>

                    <!-- <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left:25px">{{
                          $t("districtTxt")
                        }}</label>
                      </div>
                      <div class="col-lg-4">
                        <input
                          type="text"
                          class="form-control"
                          id="district"
                          :placeholder="$t('districtTxt')"
                          v-model="forms.district"
                          readonly
                        />
                        <div v-if="errors.district">
                          <div
                            v-for="error in errors.district"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <input
                          type="text"
                          class="form-control"
                          id="sub_district"
                          :placeholder="$t('subDistrictTxt')"
                          v-model="forms.sub_district"
                          readonly
                        />
                        <div v-if="errors.sub_district">
                          <div
                            v-for="error in errors.sub_district"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left:25px">{{
                          $t("postalCodeTxt")
                        }}</label>
                      </div>
                      <div class="col-lg-4">
                        <input
                          type="text"
                          class="form-control"
                          id="postal_code"
                          :placeholder="$t('postalCodeTxt')"
                          v-model="forms.postal_code"
                          readonly
                        />
                        <div v-if="errors.postal_code">
                          <div
                            v-for="error in errors.postal_code"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Division</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="division"
                          :placeholder="'division'"
                          v-model="forms.division"
                          readonly
                        />
                        <div v-if="errors.division">
                          <div
                            v-for="error in errors.division"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="file_upload"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >File Upload Cross Reference</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="file_upload"
                          :placeholder="'File Upload'"
                          v-model="forms.file_upload"
                          readonly
                          
                        />
                        <div v-if="errors.file_upload">
                          <div
                            v-for="error in errors.file_upload"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-12" style="padding-left: 45px">
                        <div class="row mb-3">Region Available In :</div>
                        <div class="row">
                          <div class="col-sm-3">
                            <div class="form-check mb-2">
                              <input
                                type="checkbox"
                                v-model="forms.execution_status"
                                true-value="yes"
                                false-value="no"
                                class="form-check-input"
                                id="execution_status"
                                disabled
                              />
                              <label
                                class="form-check-label"
                                for="execution_status"
                              >
                                Execution Status
                              </label>
                            </div>
                            <div class="form-check mb-2">
                              <input
                                type="checkbox"
                                v-model="forms.shipment_status"
                                class="form-check-input"
                                id="shipment_status"
                                true-value="yes"
                                false-value="no"
                                disabled
                              />
                              <label
                                class="form-check-label"
                                for="shipment_status"
                              >
                                Shipment Status
                              </label>
                            </div>
                            <div class="form-check mb-2">
                              <input
                                type="checkbox"
                                v-model="forms.optimizer"
                                class="form-check-input"
                                id="optimizer"
                                true-value="yes"
                                false-value="no"
                                disabled
                              />
                              <label class="form-check-label" for="optimizer">
                                Optimizer
                              </label>
                            </div>
                            <div class="form-check mb-2">
                              <input
                                type="checkbox"
                                v-model="forms.payable_rate_estimation"
                                class="form-check-input"
                                id="payable_rate_estimation"
                                true-value="yes"
                                false-value="no"
                                disabled
                              />
                              <label
                                class="form-check-label"
                                for="payable_rate_estimation"
                              >
                                Payable Rate Estimation
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="form-check mb-2">
                              <input
                                type="checkbox"
                                v-model="forms.freight_builder"
                                true-value="yes"
                                false-value="no"
                                class="form-check-input"
                                id="freight_builder"
                                disabled
                              />
                              <label
                                class="form-check-label"
                                for="freight_builder"
                              >
                                Freight Builder
                              </label>
                            </div>
                            <div class="form-check mb-2">
                              <input
                                type="checkbox"
                                v-model="forms.rate_management"
                                class="form-check-input"
                                id="rate_management"
                                true-value="yes"
                                false-value="no"
                                disabled
                              />
                              <label
                                class="form-check-label"
                                for="rate_management"
                              >
                                Rate Management
                              </label>
                            </div>
                            <div class="form-check mb-2">
                              <input
                                type="checkbox"
                                v-model="forms.pooling"
                                class="form-check-input"
                                id="pooling"
                                true-value="yes"
                                false-value="no"
                                disabled
                              />
                              <label class="form-check-label" for="pooling">
                                Pooling
                              </label>
                            </div>
                            <div class="form-check mb-2">
                              <input
                                type="checkbox"
                                v-model="forms.waypoints"
                                class="form-check-input"
                                id="waypoints"
                                true-value="yes"
                                false-value="no"
                                disabled
                              />
                              <label class="form-check-label" for="waypoints">
                                Waypoints
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                  <div class="card-footer">
                    <button
                      class="btn btn-warning text-black   fw-bolder btn-icon-text"
                      @click="backForm"
                      type="button"
                     
                    >
                      <i class="link-icon" data-feather="arrow-left"></i>
                      {{ $t("backMess") }}
                    </button>


                    <a
                      v-if="permision_role.method_delete"
                      class="btn btn-danger text-white  fw-bolder btn-icon-text float-end"
                      @click="deleteData()"
                    >
                      <i class="link-icon" data-feather="trash"></i>
                      {{ $t("deleteMess") }}
                    </a>


                    <a
                      v-if="permision_role.method_update"
                      class="btn btn-primary me-2 text-white fw-bolder btn-icon-text float-end"
                      @click="editData()"
                    >
                      <i class="link-icon" data-feather="edit"></i>
                      {{ $t("editMess") }}
                    </a>
                  </div>
                    

                   
                  </form>


                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";

export default {
  name: "regionDetail",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      permision_role: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      regionCode: "",
      regionName: "",
      forms: [],
    };
  },
  watch: {},
  methods: {
    resetForm() {
      this.forms.region_code = "";
      this.forms.region_description = "";
      this.forms.country = "";
      this.forms.province = "";
      this.forms.city = "";
      this.forms.city_type = "";
      this.forms.district = "";
      this.forms.sub_district = "";
      this.forms.postal_code = "";
    },

    backForm() {
      window.location.href = "/region";
    },


    editData(){
      var params = this.$route.params.id
      window.location.href = "/region/edit/"+ params;
    },


    deleteData() {
      var id = this.$onBehind(this.$route.params.id);
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI =
            this.$settings.endPoint + "region/delete/" + id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                window.location.href = "/region";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    loadData(id) {
      const baseURI = this.$settings.endPoint + "region/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        this.forms = response.data.datas;

        // this.form={ name: 'asep', usernmae:res.username };
      });
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RegionController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },

    submitData() {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourData"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          this.fade(true);
          // let params = this.$onBehind(this.$route.params.id);
          // let formData = new FormData();
          // formData.append("name", this.forms.name);
          // formData.append("username", this.forms.username);
          // formData.append("password", this.forms.password);
          // formData.append("email", this.forms.email);
          // formData.append("region_id", this.forms.region_id);
          // formData.append("role_id", this.forms.role_id);
          // formData.append("status", 'active');

          const baseURI =
            this.$settings.endPoint + "region/update/" + this.forms.region_id;
          this.$http
            .patch(baseURI, this.forms)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.resetForm();
                this.errors = [];
                this.success(response.data.datas.messages);

                //window.history.back();
                var params = this.$onRandom(this.forms.region_id);
                window.location.href = "/region/detail/" + params;
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
              this.resetForm();
            });
        }
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    cek_message(status,msg){
      if(status=='200'){
        this.success(msg);
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.cek_message(this.$route.query.status,this.$route.query.msg);
    this.loadData(params[0]);
    this.fetchIt();
  },
};
</script>
<style scoped>
</style>