<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading"> {{ $t('loadingTxt') }}...</div>
    <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
      <!--begin::sidebar-->
      <sidebar-component classMenu="AccidentAdd"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            ">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left: 25px">
                    Add Accident
                  </h6>
                  <button class="btn btn-default btn-sm float-end" @click="resetForm" type="button">
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form class="forms-sample" style="padding-left: 25px" @submit.prevent="submitData" method="POST">
                    <div class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "></div>

                    <div class="row mb-2">
                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Load ID</label>
                          </div>
                          <div class="col-md-4">
                            <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                            <input readonly type="text" id="load_number" name="load_number" v-model="forms.load_number"
                              class="form-control" placeholder="Load ID" read-only />


                            <div v-if="errors.load_number">
                              <div v-for="error in errors.load_number" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <button class="btn btn-success btn-sm" @click.prevent="openModal()"
                              style="margin-right: 1px ;">Browse</button>
                            <button class="btn btn-danger btn-sm" @click.prevent="clearLoad()">No Load</button>
                          </div>
                        </div>

                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Shipment Number</label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="order_number" name="order_number" readonly
                              v-model="forms.order_number" class="form-control" placeholder="Shipment Number" />
                            <div v-if="errors.order_number">
                              <div v-for="error in errors.order_number" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Shipment Date</label>
                          </div>
                          <div class="col-md-8">
                            <input readonly type="text" id="shipment_date" name="shipment_date"
                              v-model="forms.shipment_date" class="form-control" placeholder="Shipment Date" />
                            <div v-if="errors.shipment_date">
                              <div v-for="error in errors.shipment_date" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Vehicle No<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <!-- <input type="text" id="vehicle_no" name="vehicle_no" v-model="forms.vehicle_no"
                              class="form-control" placeholder="Vehicle No" readonly /> -->

                              <v-select :options="fetchVehicleNo" v-model="vehicle_no" @input="selectDriverNmk(vehicle_no)" :disabled="disabledBeforeLoad" label="vehicle_no" return-object
                               @search="asyncSearchVehicleNo"></v-select>

                            <div v-if="errors.vehicle_no">
                              <div v-for="error in errors.vehicle_no" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Driver NMK<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <!-- <input type="text" id="vehicle_no" name="vehicle_no" v-model="forms.vehicle_no"
                              class="form-control" placeholder="Vehicle No" readonly /> -->

                              <v-select :options="fetchDriverNmk" @input="changeDriverNmk(driver_nmk)"  v-model="driver_nmk" :disabled="disabledBeforeLoad" label="driver_id" return-object
                               @search="asyncSearchDriverNmk"></v-select>

                            <div v-if="errors.driver_nmk">
                              <div v-for="error in errors.driver_nmk" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Driver Name<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="driver_name" name="driver_name" v-model="forms.driver_name"
                              class="form-control" placeholder="Driver Name" :disabled="disabledBeforeLoad" />
                            <div v-if="errors.driver_name">
                              <div v-for="error in errors.driver_name" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        

                       

                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Driver HP</label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="driver_hp" name="driver_hp" v-model="forms.driver_hp"
                              class="form-control" placeholder="Driver HP" :disabled="disabledBeforeLoad" />
                            <div v-if="errors.driver_hp">
                              <div v-for="error in errors.driver_hp" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Route</label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="route" name="route" v-model="forms.route" class="form-control"
                              placeholder="Route" readonly />
                            <div v-if="errors.route">
                              <div v-for="error in errors.route" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Accident Date<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-4">
                            <!-- <Datepicker v-model="accident_date" :bootstrap-styling=true format="dd MMM yyyy"
                              placeholder="Please Select Date.."></Datepicker> -->
                              
                              <Datepicker v-model="forms.accident_date" :bootstrap-styling=true format="dd-MM-yyyy" placeholder="Please Select Date..." @input="changeDisabled"></Datepicker>

                            <div v-if="errors.tanggal_muat">
                              <div v-for="error in errors.accident_date" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>

                          <div class="col-md-4">
                            <input type="time" id="accident_hour" name="accident_hour" v-model="forms.accident_hour" class="form-control"
                             />
                          </div>
                        </div>


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Accident Location<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="accident_location" name="accident_location" v-model="forms.accident_location" class="form-control"
                              placeholder="Accident Location"  />
                            <div v-if="errors.accident_location">
                              <div v-for="error in errors.accident_location" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Vehicle Owner<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="vehicle_owner" name="vehicle_owner" v-model="forms.vehicle_owner" class="form-control"
                              placeholder="Vehicle Owner"  />
                            <div v-if="errors.vehicle_owner">
                              <div v-for="error in errors.vehicle_owner" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Accident Area<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <v-select
                              :options="fetchAccidentArea"
                              v-model="accident_area"
                              label="name"
                              return-object
                              @input="selectAccidentArea(accident_area)"
                            ></v-select>

                            <div v-if="errors.accident_area">
                              <div v-for="error in errors.accident_area" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Haul<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <v-select
                              :options="fetchHaul"
                              v-model="haul"
                              label="name"
                              return-object
                              
                            ></v-select>

                            <div v-if="errors.haul">
                              <div v-for="error in errors.haul" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-2" v-if="this.permision_role.method_custom1">
                          <div class="col-md-4">
                            <label class="form-label">Direct Cause</label>
                          </div>
                          <div class="col-md-8">
                            <v-select
                              :options="fetchPenyebabLangsung"
                              v-model="penyebab_langsung"
                              label="name"
                              return-object
                              
                            ></v-select>

                            <div v-if="errors.penyebab_langsung">
                              <div v-for="error in errors.haul" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                            <div class="row mb-2" v-if="this.permision_role.method_custom1">
                          <div class="col-md-4">
                            <label class="form-label">Direct Cause Detail</label>
                          </div>
                          <div class="col-md-8">
                            <v-select
                              :options="fetchDirectCauseDetail"
                              v-model="directCauseDetail"
                              label="name"
                              return-object
                              
                            ></v-select>

                            <div v-if="errors.direct_cause_detail">
                              <div v-for="error in errors.direct_cause_detail" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-2"  v-if="this.permision_role.method_custom1">
                          <div class="col-md-4">
                            <label class="form-label">Direct Cause Remark</label>
                          </div>
                          <div class="col-md-8">
                            <textarea class="form-control" v-model="forms.keterangan_penyebab_langsung" rows="5" ></textarea>
                            <div v-if="errors.keterangan_penyebab_langsung">
                              <div v-for="error in errors.keterangan_penyebab_langsung" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Project</label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="project" name="project" v-model="forms.project" class="form-control"
                              placeholder="Project"  />
                            <div v-if="errors.project">
                              <div v-for="error in errors.project" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Truck Active Date</label>
                          </div>
                          <div class="col-md-8">
                              
                            
                              <Datepicker v-model="forms.active_date" :bootstrap-styling=true format="dd-MM-yyyy" placeholder="Please Select Date..."></Datepicker>
                            <div v-if="errors.active_date">
                              <div v-for="error in errors.active_date" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                      <div class="col-md-6">

                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Latitude</label>
                          </div>
                          <div class="col-md-4">
                            <input type="text" id="latitude" name="latitude" v-model="forms.latitude" class="form-control"
                              placeholder=""  />
                            <div v-if="errors.latitude">
                              <div v-for="error in errors.latitude" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <button class="btn btn-success btn-sm" @click.prevent="openMap()"
                              style="margin-right: 1px ;">Browse Map</button>
                          </div>
                        </div>


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Longitude</label>
                          </div>
                          <div class="col-md-4">
                            <input type="text" id="longitude" name="longitude" v-model="forms.longitude" class="form-control"
                              placeholder=""  />
                            <div v-if="errors.longitude">
                              <div v-for="error in errors.longitude" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Accident Category<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <v-select
                              :options="fetchCategory"
                              v-model="category"
                              label="name"
                              return-object
                              
                            ></v-select>

                            <div v-if="errors.accident_category">
                              <div v-for="error in errors.accident_category" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Accident Damage</label>
                          </div>
                          <div class="col-md-8">
                            <v-select
                              :options="fetchKerusakan"
                              v-model="kerusakans"
                              label="name"
                              return-object
                              
                            ></v-select>

                            <div v-if="errors.kerusakan">
                              <div v-for="error in errors.kerusakan" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Accident Cause<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <v-select
                              :options="fetchCaused"
                              v-model="caused"
                              label="name"
                              return-object
                              
                            ></v-select>

                            <div v-if="errors.caused">
                              <div v-for="error in errors.caused" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Event Type</label>
                          </div>
                          <div class="col-md-8">
                            <v-select
                              :options="fetchTipeKejadian"
                              v-model="tipe_kejadians"
                              label="name"
                              return-object
                              
                            ></v-select>

                            <div v-if="errors.tipe_kejadian">
                              <div v-for="error in errors.tipe_kejadian" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                             <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Event Type Remarks</label>
                          </div>
                          <div class="col-md-8">
                            <textarea class="form-control" v-model="forms.event_type_remark" rows="3" ></textarea>
                            <div v-if="errors.event_type_remark">
                              <div v-for="error in errors.event_type_remark" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-2" v-if="this.permision_role.method_custom1">
                          <div class="col-md-4">
                            <label class="form-label">Basic Cause</label>
                          </div>
                          <div class="col-md-8">
                            <v-select
                              :options="fetchPenyebabDasar"
                              v-model="penyebab_dasars"
                              label="name"
                              return-object
                              
                            ></v-select>

                            <div v-if="errors.penyebab_dasar">
                              <div v-for="error in errors.penyebab_dasar" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Total Claim (Rp.)</label>
                          </div>
                          <div class="col-md-8">
                            <!-- <input type="number" id="or_value" name="or_value" v-model="forms.or_value" class="form-control" placeholder=""  /> -->
                            <CurrencyInput
                              v-model.number="forms.or_value"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                            />
                            <div v-if="errors.or_value">
                              <div v-for="error in errors.or_value" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Total Beban Driver (Rp.)</label>
                          </div>
                          <div class="col-md-8">
                            <!-- <input type="number" id="insurance_value" name="insurance_value" v-model="forms.insurance_value" class="form-control" placeholder=""  /> -->
                            <CurrencyInput
                              v-model.number="forms.insurance_value"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                            />
                            <div v-if="errors.insurance_value">
                              <div v-for="error in errors.insurance_value" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <!-- <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Accident Rate<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <v-select
                              :options="fetchRate"
                              v-model="rate"
                              label="name"
                              return-object
                              
                            ></v-select>

                            <div v-if="errors.accident_rate">
                              <div v-for="error in errors.accident_rate" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div> -->


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Accident Detail</label>
                          </div>
                          <div class="col-md-8">
                            <textarea class="form-control" v-model="forms.accident_detail" rows="5" ></textarea>
                            <div v-if="errors.accident_detail">
                              <div v-for="error in errors.accident_detail" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                         <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Action Plan</label>
                          </div>
                          <div class="col-md-8">
                            <textarea class="form-control" v-model="forms.action_plan" rows="5" ></textarea>
                            <div v-if="errors.action_plan">
                              <div v-for="error in errors.action_plan" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Case Result<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <v-select
                              :options="fetchCaseResult"
                              v-model="case_result"
                              label="name"
                              return-object
                              
                            ></v-select>

                            <div v-if="errors.case_result">
                              <div v-for="error in errors.case_result" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Date Complete</label>
                          </div>
                          <div class="col-md-8">
                           
                            <!-- <date-range-picker style="width:100%;"  :locale-data="locale"  v-model="accident_date_complete" :opens="opens" :singleDatePicker="true" :timePicker24Hour="true" :timePicker="true"  >
                                
                              </date-range-picker> -->
                              <Datepicker v-model="forms.complete_date" :bootstrap-styling=true format="dd-MM-yyyy" placeholder="Please Select Date..." :disabledDates="disabledDate" ></Datepicker>
                            <div v-if="errors.tanggal_muat">
                              <div v-for="error in errors.complete_date" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>




                      </div>


                    </div>














                    <div class="card-footer">
                      <button class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        " @click="backForm" type="button">
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button class="btn btn-primary float-end btn-sm" type="submit">
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>

                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->
        <modal-unloading :showModal="showModalUnloading" :data="this.dataModal" @closeModal="closeMyModal">
        </modal-unloading>

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import myMixinAddress from '@/middleware/mixinAddress.js';
import Datepicker from "vuejs-datepicker";

import moment from 'moment'
import modalUnloading from "@/components/modal/modalUnloading.vue";
import CurrencyInput from "@/components/_partials/inputCurrency";

export default {
  name: "UnloadingInformationAdd",
  props: {},
  mixins: [myMixinAddress],
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "modal-unloading": modalUnloading,
    CurrencyInput,
    Datepicker
  },
  data() {
    const startDate =new Date();
    const endDate =new Date();
    return {
      disabledBeforeLoad:true,
      vehicle_no:"",
      fetchVehicleNo:[],
      opens:"center",
      category:'',
      fetchCategory:[],

      driver_nmk:"",
      fetchDriverNmk:[],
      fetchAccidentArea:[],
      fetchHaul:['Short Haul','Long Haul'],
      haul:'',
      penyebab_langsung:'',
      accident_area:'',
      fetchPenyebabLangsung:[],
      caused:'',
      fetchCaused:[],

      fetchKerusakan: [],
      kerusakans:'',
      fetchTipeKejadian:[],
      tipe_kejadians:'',
      fetchPenyebabDasar:[],
      penyebab_dasars:'',

      locale:{
        format:"dd mmm yyyy H:MM:ss",
        separator: ' - ',
      },
      
      accident_date: {
        startDate,
        endDate,
      },

      active_date: {
        startDate: null,
        endDate: null
      },

      accident_date_complete:{
        startDate: null,
        endDate: null
      },


      fetchCaseResult:[],
      case_result:"",

      rate:'',
      fetchRate:[],

      fetchLoadNumber: [],
      loadNumber: [],

      fetchDirectCauseDetail:[],
      directCauseDetail:"",

      fetchProvinsiTujuan: [],
      ProvinsiTujuan: [],

      fetchKotaTujuan: [],
      KotaTujuan: [],

      fetchSubCustomer: ['Industrial', 'Consumer', 'MT', 'Pallet', 'E-COM'],
      SubCustomer: [],

      showModalUnloading: false,

      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      permision_role: [],
      userData: "",
      companyCode: "",
      forms: {
          master_driver_id:"",
          blujay_order_id:"",
          load_number:"",
          order_detail_id:"",
          order_number:"",
          shipment_date:"",
          customer_id:"",
          customer_name:"",
          master_truck_id:"",
          vehicle_no:"",
          driver_nmk:"",
          driver_name:"",
          driver_id:"",
          driver_hp:"",
          route:"",
          accident_date:"",
      },
      disabledDate:{},
      dataModal: {},

    };
  },


  watch: {},
  methods: {
    openMap(){

    },
    changeDisabled(ev){
      this.forms.complete_date = "";
      this.disabledDate = {
        to:ev,
      }
    },
    clearLoad(){
      this.disabledBeforeLoad=false;
      this.forms.master_driver_id="";
      this.forms.blujay_order_id="";
      this.forms.load_number="";
      this.forms.order_detail_id="";
      this.forms.order_number="";
      this.forms.shipment_date="";
      this.forms.customer_id="";
      this.forms.customer_name="";
      this.forms.master_truck_id="";
      this.forms.vehicle_no="";
      this.forms.driver_nmk="";
      this.forms.driver_name="";
      this.forms.driver_id="";
      this.forms.driver_hp="";
      this.forms.route="";
      this.forms.shipment_number="";
      
    },

    loadVehicleNo() {
      const baseURI =
        this.$settings.endPointCt + `master-truck`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchVehicleNo = response.data.datas.data;
      });
    },

    loadDriverNmk() {
      const baseURI =
        this.$settings.endPointCt + `master-driver`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDriverNmk = response.data.datas.data;

      });
    },

    selectDriverNmk(driver){
      this.forms.vehicle_owner=driver.branch_name;
    },

    selectAccidentArea(accident){
      if(this.forms.vehicle_owner==accident){
        this.haul="Short Haul";
      }
    },

    loadCategory() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=catac`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchCategory = response.data.datas;
      });
    },
    
    loadCause() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=cauac`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchCaused = response.data.datas;
      });
    },

    loadRate() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=ratac`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchRate = response.data.datas;
      });
    },

    loadPenyebabLangsung() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=dirca`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchPenyebabLangsung = response.data.datas;
      });
    },
    
    loadDirectCauseDetail(){
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=penla`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDirectCauseDetail = response.data.datas;
      });
    },
    loadCaseResult() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=casre`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchCaseResult = response.data.datas;
      });
    },
    
    loadTipeKejadian() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=kejad`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTipeKejadian = response.data.datas;
      });
    },

    loadKerusakan() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=kerus`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchKerusakan = response.data.datas;
      });
    },

    loadPenyebabDasar() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=sebab`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchPenyebabDasar = response.data.datas;
      });
    },

    loadAccidentArea() {
      const baseURI =
        this.$settings.endPointCt + `branch`;
      return this.$http.get(baseURI).then((response) => {
        var resp=response.data.datas;
        for (let index = 0; index < resp.data.length; index++) {
          const branch = resp.data[index].branch_name;
          this.fetchAccidentArea.push(branch);
        }
        // var data={
        //   resp.data.branch_name
        // }
        //this.fetchAccidentArea = response.data.datas;
      });
    },

    asyncSearchDriverNmk(ev){
      const baseURI =
        this.$settings.endPointCt + `master-driver?driver_id=`+ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDriverNmk = response.data.datas.data;
      });
    },


    asyncSearchVehicleNo(ev){
      const baseURI =
        this.$settings.endPointCt + `master-truck?vehicle_no=`+ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchVehicleNo = response.data.datas.data;
      });
    },

    openModal() {
      const baseURI = this.$settings.endPointCt + "blujay-order/select";
      this.$http
        .get(baseURI)
        .then((response) => {

          this.dataModal = {
            detail: response.data.datas
          }
          this.showModalUnloading = true;
        });
    },

    loadBlujay() {
      const baseURI = this.$settings.endPointCt + `blujay-order/select`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchLoadNumber = response.data.datas.data;
      });

    },

    asyncSearchLoadNumber(ev) {
      const baseURI = this.$settings.endPointCt + "blujay-order/select?load_id=" + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchLoadNumber = response.data.datas.data;
      });
    },



    // asyncSearchProvinsiTujuan() {
    //   const baseURI = this.$settings.endPoint + "postal-code/select-province/Indonesia";

    //   return this.$http.get(baseURI).then((response) => {
    //     this.fetchProvinsiTujuan = response.data.datas;
    //   });
    // },

    // "blujay_order_id",
    //       "load_number",
    //       "detail_order_id",
    //       "shipment_number",
    //       "shipment_date",
    //       "vehicle_no",
    //       "master_driver_id",
    //       "driver_nmk",
    //       "driver_name",
    //       "driver_hp",
    //       "tanggal_muat",
    //       "tanggal_bongkar",
    //       "provinsi_tujuan",
    //       "kota_tujuan",
    //       "sku",
    //       "route",
    //       "qty",
    //       "qty_return",
    //       "sub_customer",
    //       "description",

    // "blujay_order_id": 3539,
    //             "load_id": "60530857",
    //             "vehicle_no": "B9153FXS",
    //             "driver_nmk": null,
    //             "driver_name": null,
    //             "driver_hp": null,
    //             "name_rute": "pickup :LTLCIKARANG BARAT(GUDANG CML) - LTLLEGOK(PT JAYA SWARASA AGUNG TBK)",
    //             "drop": "LTLLEGOK(PT JAYA SWARASA AGUNG TBK)",
    //             "order_detail_id": 3515,
    //             "shipment_number": "2100437094",
    //             "shipment_date": "2000-01-01 01:01:01",
    //             "state_pick": "BT",
    //             "city": "TANGERANG",
    //             "region": "LEGOK",
    //             "state_drop": "BT",
    //             "vehicle_status": "ACTIVE",
    //             "driver_status": null,
    //             "route": "BT-BT",
    //             "leadtime": 1
    closeMyModal(ev) {
      console.log(ev.master_driver_id)
      if (ev !== null) {
        this.disabledBeforeLoad=false;
        this.forms.master_driver_id=ev.master_driver_id;
        this.forms.blujay_order_id = ev.blujay_order_id;
        this.forms.detail_order_id = ev.order_detail_id;
        this.forms.load_number = ev.load_id;
        
        this.vehicle_no={
            vehicle_no:ev.vehicle_no
        }

        this.forms.driver_nmk = ev.driver_nmk;
        this.forms.driver_name = ev.driver_name;
        this.forms.driver_hp = ev.driver_hp;
        this.forms.route = ev.name_rute;
        this.forms.shipment_number = ev.shipment_number;
        this.forms.shipment_date = ev.shipment_date;
        this.forms.tanggal_muat = ev.shipment_date;
        this.forms.order_number=ev.shipment_number;
        this.forms.driver_id=ev.driver_nmk;
        this.forms.vehicle_owner=ev.branch_name;
        this.driver_id=ev.driver_nmk;
        this.driver_nmk=ev.driver_nmk;
        


      }

      this.showModalUnloading = false;
    },

    capitalize(value) {
      if (!value) return ''
      value = value.toLowerCase()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },



    isNumberLatLong(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 45) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    resetForm() {
      this.forms = "";
    },

    backForm() {
      window.location.href = "/accident";
    },

    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },

    submitData() {
      this.isLoading = true
      
      
      if(typeof(this.forms.accident_date) != 'undefined' || this.forms.accident_date != null){
        this.forms.accident_date = moment(String(this.forms.accident_date)).format('YYYY-MM-DD');        
      }else{
        this.forms.accident_date = null;
      }

      if(typeof(this.forms.complete_date) != 'undefined' || this.forms.complete_date != null){
        this.forms.complete_date = moment(String(this.forms.complete_date)).format('YYYY-MM-DD');        
      }else{
        this.forms.complete_date = null;
      }

      if(typeof(this.forms.active_date) != 'undefined' || this.forms.active_date != null){
        this.forms.active_date= moment(String(this.forms.active_date)).format('YYYY-MM-DD');        
      }else{
        this.forms.active_date = null;
      }

     
      this.forms.accident_category = this.category;
      this.forms.caused = this.caused;
      this.forms.accident_rate=this.rate;
     
      this.forms.driver_id=this.driver_nmk==null ? this.driver_nmk : this.driver_nmk.driver_id ;
      this.forms.driver_nmk=this.driver_nmk==null ? this.driver_nmk : this.driver_nmk.driver_id ;
      this.forms.case_result=this.case_result;
      this.forms.accident_area=this.accident_area;
      this.forms.penyebab_langsung=this.penyebab_langsung;
      this.forms.direct_cause_detail=this.directCauseDetail;
      this.forms.haul=this.haul;
      console.log(this.forms);
      this.forms.kerusakan=this.kerusakans;
      this.forms.tipe_kejadian=this.tipe_kejadians;
      this.forms.penyebab_dasar=this.penyebab_dasars;

      this.forms.vehicle_no=this.vehicle_no==null ? this.vehicle_no : this.vehicle_no.vehicle_no ;
      const baseURI = this.$settings.endPointCt + "driver-accident/create";
      this.$http
        .post(baseURI, this.forms, { timeout: 50000 })
        .then((response) => {
          this.isLoading = false
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            var params = this.$onRandom(response.data.datas.driver_accident_id);
            window.location.href = "/accident/detail/" + params + "?status=200&msg=Successfully";
            this.success('Berhasil');
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        }).catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error("Please Check Your Internet Connection");
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }

          }


        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    changeDriverNmk(driver){
      this.forms.driver_name=driver.driver_name;
      this.forms.driver_hp=driver.hp1;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "DriverAccidentController") {
          rolePermision = permision;
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () { },
  mounted() {
    this.loadAccidentArea();
    this.loadCategory();
    this.loadCause();
    this.loadRate();
    this.fetchIt();
    this.loadBlujay();
    this.loadVehicleNo();
    this.loadDriverNmk();
    this.loadPenyebabLangsung();
    this.loadCaseResult();
    this.loadKerusakan();
    this.loadTipeKejadian();
    this.loadPenyebabDasar();
    this.loadDirectCauseDetail();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}



.blur-content {
  filter: blur(5px);
  pointer-events: none;

}

.calendars[data-v-1ebd09d2]{display:-webkit-box;display:-ms-flexbox;}
 @media screen and (min-width:339px){.daterangepicker.single[data-v-1ebd09d2]{min-width:auto}.daterangepicker.single.show-ranges.show-weeknumbers[data-v-1ebd09d2],.daterangepicker.single.show-ranges[data-v-1ebd09d2]{min-width:360px !important}}
.input-group > .form-control, .input-group > .typeahead.tt-input, .input-group > .typeahead.tt-hint, .select2-container--default .select2-search--dropdown .input-group > .select2-search__field, .input-group > .form-select{
  background: white;
}

.datetimepicker-c{
  height: 35px;
}
</style>