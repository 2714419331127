import Kriminalitas from "@/components/dasboard-ct/kriminalitas/Kriminalitas.vue";
import KriminalitasAdd from "@/components/dasboard-ct/kriminalitas/KriminalitasAdd.vue";
import KriminalitasDetail from "@/components/dasboard-ct/kriminalitas/KriminalitasDetail.vue";
import KriminalitasEdit from "@/components/dasboard-ct/kriminalitas/KriminalitasEdit.vue";

let routesKriminalitas= [
      {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "Kriminalitas",
        component: Kriminalitas
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "KriminalitasAdd",
        component: KriminalitasAdd
      },
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "KriminalitasEdit",
        component: KriminalitasEdit
      },
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "KriminalitasDetail",
        component: KriminalitasDetail
      },
    
   
    
]

export default routesKriminalitas
  