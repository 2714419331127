<template>
  <div>
    <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Details</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
              aria-label="btn-close"></button>
          </div>
          <div class="modal-body">
            <!-- :rows="rows"  -->
            <vue-good-table
                title="list-order-ct"
                mode="remote"
                :totalRows="totalRecords"
                :line-numbers="true"
                :pagination-options="{
                    enabled: true,
                }"
                :isLoading.sync="isLoading"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                :columns="columns"
                :rows="rows"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'actions'">
                  <a style="color: #ab0635;  "
                    title="Insert"
                    @click.prevent="
                      detailData(props.index, props.row)
                    "
                  >
                    <i class="mdi mdi-check" style="font-size:16px"></i>
                    
                  </a>
                </span>
              </template>
            </vue-good-table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
              cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name:"modalProformaInvoiceDetail",
    emits: ["closeModal","pushData"],
    props: {
      showModal: Boolean,
      data: null,
    },
    watch: {
        showModal: {
        handler(newVal) {
            this.active = newVal;
            const body = document.querySelector("body");
            this.showModal
            ? body.classList.add("modal-open")
            : body.classList.remove("modal-open");
            this.loadItems();
          },
        immediate: true,
        deep: true,
        },
    },
    data() {
      return {
          isLoading: false,
          opens: "center",
          active: this.showModal,
          fetchCompany: [],
          totalRecords:0,
          forms:[],
          status:"ASSIGNED",
          columns: [
            {
              label:"Status TMS",
              field: "status_tms",
              filterOptions: {
                enabled: false, // enable filter for this column
                placeholder: "Filter By Status", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
              },
            },

            {
              label:"Customer",
              field: "company.name",
              filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By Customer", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
              },
            },

            {
              label:"Vehicle No",
              field: "vehicle_no",
              filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By Vehicle No", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
              },
            },

            {
              label:"Type Truck",
              field: "type_truck",
              filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By Type Truck", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
              },
            },


            {
              label:"Driver",
              field: "driver_name",
              filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By Type Truck", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
              },
            },


            {
              label:"Load Id",
              field: "load_id",
              filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By Load Id", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
              },
            },

            {
              label:"Shipment Number",
              field: "order_no",
              filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By Order No", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
              },
            },
            {
              label:"ShipWithRef",
              field: "shipwith_ref",
              filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By ShipWithRef", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
              },
            },
            
            {
              label:"Planing Pada",
              field: "accept_time",
              filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By Planing", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
              },
            },

            {
                label: this.$t("pickup_plan_txt"),
                field: "pick_plan",
                filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By "+this.$t("pickup_plan_txt"), // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
                },
            },
            {
                label:"Pick Location",
                field: "pick_name",
                dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
                dateOutputFormat: 'dd MMM yyyy h:mm:ss a',//'dd MMM yyyy',
                filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By "+this.$t("pick_plan_txt"), // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
                },
            },


            {
                label:"Dest Location",
                field: "dest_name",
                filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By Destination", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
                },
            },


            {
                label: this.$t("dest_plan_txt"),
                field: "dest_plan",
                type: 'date',
                dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
                dateOutputFormat: 'dd MMM yyyy h:mm:ss a',//'dd MMM yyyy',
                filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By "+this.$t("dest_plan_txt"), // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
                },
            },

            {
                label:"Actual Duration",
                field: "actual_duration",
                filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By Destination", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
                },
            },


            {
                label:"Tonnage (kg)",
                field: "tonnage",
                filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By Tonnage", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
                },
            },



            {
                label:"Location",
                field: "dest_location",
                filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By Tonnage", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
                },
            },

            {
                label:"Auto Load",
                field: "auto_load",
                filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By Auto Load", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
                },
            },
            {
                label: "Carier",
                field: "carrier",
                filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By Carier", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
                },
            },
            
            {
                label:"Assign Time",
                field: "assign_time",
                filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter ", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
                },
            },

            {
                label:"Assign by",
                field: "assign_by",
                filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter ", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
                },
            },
            {
                label:"Remarks",
                field: "remarks",
                filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter ", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
                },
            },
            {
                label: this.$t("action_txt"),
                field: "actions",
                sortable: false,
            },  
          ],
          serverParams: {
            columnFilters: {},
            sort: {
              field: "",
              type: "",
            },
            page: 1,
            per_page: 10,
          },
          rows:[]
      }
  },
  methods: {
    loadItems() {
          this.isLoading = true;
          const baseURI = this.$settings.endPoint + "control-tower/transport-order-header";
          var pick_plan = '';
          if(this.serverParams.columnFilters.pick_plan!=''){
            pick_plan =this.$moment(this.serverParams.columnFilters.pick_plan).format("YYYY-MM-DD");
          }
          //var columnFilters = this.serverParams.columnFilters;
          var dest_plan = '';
          if(this.serverParams.columnFilters.dest_plan!=''){
            dest_plan =this.$moment(this.serverParams.columnFilters.dest_plan).format("YYYY-MM-DD");
          }
           var company_name = this.serverParams.columnFilters["company.name"];
     
          return this.$http
            .get(
               baseURI +
                `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&company_name=${company_name}&status_tms=ACCEPTED&vehicle_no=${this.serverParams.columnFilters.vehicle_no}&type_truck=${this.serverParams.columnFilters.type_truck}&driver_name=${this.serverParams.columnFilters.driver_name}&load_id=${this.serverParams.columnFilters.load_id}&order_no=${this.serverParams.columnFilters.order_no}&pick_plan=${pick_plan}&dest_plan=${dest_plan}&tonnage=${this.serverParams.columnFilters.tonnage}&dest_location=${this.serverParams.columnFilters.dest_location}&order_status=${this.status}&accept_time=${this.serverParams.columnFilters.accept_time}&pick_name=${this.serverParams.columnFilters.pick_name}&dest_name=${this.serverParams.columnFilters.dest_name}&actual_duration=${this.serverParams.columnFilters.actual_duration}&assign_time=${this.serverParams.columnFilters.assign_time}&assign_by=${this.serverParams.columnFilters.assign_by}&auto_load=${this.serverParams.columnFilters.auto_load}&shipwith_ref=${this.serverParams.columnFilters.shipwith_ref}`,
              { timeout: 10000 }
            )
            .then((response) => { 
              this.rows = response.data.datas.data;
              this.totalRecords = response.data.datas.total;
              
              this.isLoading = false;
            }).catch(error => {
              this.isLoading = false;
              if(error.code == "ECONNABORTED"){
                this.error(this.$t("check_internet_connection_txt"));
              }else{
    
                if (error.response) {
                  if(error.response.status === 422) {
                          this.errors = error.response.data.errors;
                          this.resultError(error.response.data.errors);
                  }else if (error.response.status === 500) {
                      this.$router.push('/server-error');
                  }else if (error.response.status === 401) {
                      this.$router.push("/authorized-error");
                  }else{
                      this.$router.push('/page-not-found');
                  }
                }
    
              }
    
            });
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },
    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },
    detailData(index,row){
      this.$emit('pushData',row);
    }
    
  },
}
</script>

<style scoped>
.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left:0px !important;
}
</style>