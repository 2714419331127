<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading">
      {{ $t("loadingTxt") }}...
    </div>
    <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
      <!--begin::sidebar-->
      <sidebar-component classMenu="poolTransactions"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="row">
            <div class="col-12 grid-margin">
              <div class="card">
                <div class="position-relative">
                  <div v-if="this.transactionData.permit_status == 'APPROVED'" style="
                          text-align: center;
                          position: absolute;
                          left: 0;
                          top: 100px;
                          z-index: 20;
                        ">
                    <img src="/assets/icon/approved.png" class="rounded-top" style="max-width: 10%" />
                  </div>
                  <div v-else style="
                          text-align: center;
                          width: 100%;
                          height: 100%;
                          position: absolute;
                          left: 0;
                          top: 100px;
                          z-index: 20;
                        ">
                    <img src="/assets/icon/denied.png" class="rounded-top" style="max-width: 10%" />
                  </div>

                  <figure class="overflow-hidden mb-0 d-flex justify-content-center">
                    <img src="/assets/images/header_image_1.png" width="1560px" height="370px" class="rounded-top"
                      alt="" />
                  </figure>

                  <div class="
                          d-flex
                          justify-content-between
                          align-items-center
                          position-absolute
                          top-60
                          w-100
                          px-2 px-md-4
                          mt-n4
                        " style="
                          padding-left: 2.5rem !important;
                          margin-top: -4.5rem !important;
                        ">
                    <div>
                      <div v-if="this.driverData.photo">
                        <img class="rounded-circle" height="200px" width="200px" :src="this.driverData.photo" alt="" />
                      </div>
                      <div v-else>
                        <img class="rounded-circle" height="200px" width="200px" src="/assets/icon/no-image.png" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-content-left p-3 rounded-bottom">
                  <ul class="d-flex align-items-center m-0 p-0">
                    <li class="d-flex align-items-center active">
                      <button @click="$router.back()" class="btn btn-warning btn-xs" type="button">
                        <i class="link-icon" size="10px" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                    </li>

                    <li class="ms-3 ps-3 border-start d-flex align-items-center" style="font-size: 12px">
                      {{ $t("driver_name") }} :
                      {{ this.transactionData.driver_name }} -
                      {{ this.transactionData.driver_nmk }}
                    </li>
                    <li class="ms-3 ps-3 border-start d-flex align-items-center" style="font-size: 12px">
                      {{ $t("handphone") }} :
                      {{ this.transactionData.driver_hp }}
                    </li>
                    <li class="
                            d-none d-lg-block
                            ms-3
                            ps-3
                            border-start
                            d-flex
                            align-items-center
                          " style="font-size: 12px">
                      {{ $t("sim") }} : {{ this.getSimData() }}
                    </li>

                    <li class="
                            d-none d-lg-block
                            ms-3
                            ps-3
                            border-start
                            d-flex
                            align-items-center
                          " style="font-size: 12px">
                      {{ $t("transaction_date") }} :
                      {{ this.transactionData.transaction_date }}
                    </li>
                    <li class="
                            d-none d-lg-block
                            ms-3
                            ps-3
                            border-start
                            d-flex
                            align-items-center
                          " style="font-size: 12px">
                      {{ $t("statusTxt") }}
                      <span class="badge bg-warning text-dark" v-if="this.transactionData.status == 'IN'">{{
                        $t("enter_the_pool") }}</span>
                      <span v-else class="badge bg-primary text-light">{{
                        $t("exit_from_pool")
                      }}</span>
                    </li>
                  </ul>
                </div>

                <div class="
                        d-flex
                        justify-content-left
                        p-3
                        rounded-bottom
                        d-md-none
                      ">
                  <ul class="d-flex align-items-center m-0 p-0">
                    <li class="ms-3 ps-3 border-start d-flex align-items-center" style="font-size: 12px">
                      {{ $t("sim") }} : {{ this.getSimData() }}
                    </li>

                    <li class="ms-3 ps-3 border-start d-flex align-items-center" style="font-size: 12px">
                      {{ $t("transaction_date") }} :
                      {{ this.transactionData.transaction_date }}
                    </li>
                    <li class="ms-3 ps-3 border-start d-flex align-items-center" style="font-size: 12px">
                      <span class="badge bg-warning text-dark" v-if="this.transactionData.status == 'IN'">{{
                        $t("exit_from_pool") }}</span>
                      <span v-else class="badge bg-primary text-light">{{
                        $t("exit_from_pool")
                      }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="row profile-body">
            <!-- left wrapper start -->
            <div class="d-none d-md-block col-md-4 col-xl-3 left-wrapper">
              <div class="card rounded">
                <img v-if="this.truckData.images" :src="this.truckData.images" class="card-img-top" alt="..." />
                <img v-else src="/assets/icon/no-image.png" class="card-img-top" alt="..." />
                <div class="card-body">
                  <div class="
                          d-flex
                          align-items-center
                          justify-content-between
                          mb-2
                        ">
                    <h6 class="card-title mb-0">{{ $t("vehicle_no") }}</h6>
                  </div>

                  <p>{{ this.transactionData.vehicle_no }}</p>
                  <!-- 
             
                <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                    {{$t('kir')}} <br>
                    {{this.getKirData()}}
                </div>

                <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                    {{$t('km_real')}} <br>
                    {{this.transactionData.km_real}}
                </div>

                <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                    {{$t('km_gps')}} <br>
                    {{this.transactionData.km_gps}}
                </div>

                <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                    {{$t('km_service')}} <br>
                    {{this.transactionData.km_service}}
                </div> -->

                  <div class="mt-3">
                    <label class="tx-11 fw-bolder mb-0 text-uppercase">{{
                      $t("truck_type")
                    }}</label>
                    <p class="text-muted">
                      {{ this.transactionData.vehicle_type }}
                    </p>
                  </div>
                  <div class="mt-3">
                    <label class="tx-11 fw-bolder mb-0 text-uppercase">{{
                      $t("stnk")
                    }}</label>
                    <p class="text-muted">{{ this.getStnkData() }}</p>
                  </div>
                  <div class="mt-3">
                    <label class="tx-11 fw-bolder mb-0 text-uppercase">
                      {{ $t("kir") }}</label>
                    <p class="text-muted">{{ this.getKirData() }}</p>
                  </div>
                  <div class="mt-3">
                    <label class="tx-11 fw-bolder mb-0 text-uppercase">
                      {{ $t("km_real") }}</label>
                    <p class="text-muted">{{ this.transactionData.km_real }}</p>
                  </div>
                  <div class="mt-3">
                    <label class="tx-11 fw-bolder mb-0 text-uppercase">
                      {{ $t("km_gps") }}</label>
                    <p class="text-muted">{{ this.transactionData.km_gps }}</p>
                  </div>
                  <div class="mt-3">
                    <label class="tx-11 fw-bolder mb-0 text-uppercase">{{
                      $t("km_service")
                    }}</label>
                    <p class="text-muted">
                      {{ this.transactionData.km_service }}
                    </p>
                  </div>

                  <div class="mt-3">
                    <label class="tx-11 fw-bolder mb-0 text-uppercase">{{
                      $t("status_remarks")
                    }}</label>
                    <p class="text-muted">
                      {{ this.transactionData.keterangan }}
                    </p>
                  </div>

                  <div class="mt-3" :style="{ 'background-color': this.statusFppk.color, 'padding': 'inherit' }">
                    <label class="tx-11 text-white fw-bolder mb-0 text-uppercase">
                      Status FPPK
                    </label>
                    <p class="tx-11 text-white ">
                      {{ this.statusFppk.fppk.status_fppk }}
                    </p>


                  </div>

                  <div class="mt-3">
                    <div class="row">
                      <div class="col-12">
                        <img class="rounded-circle" height="200px" width="200px" :src="this.transactionData.foto_driver_2"
                          alt="" />
                      </div>
                    </div>
                    <div class="row">

                      <div class="col-12">
                        <label class="tx-11 fw-bolder mb-0 text-uppercase">{{ this.transactionData.flag_driver2 }}</label>
                        <p class="text-muted">
                          Nama : <b>{{ this.transactionData.nama_driver2 }}</b><br>
                          SIM / KTP : <b>{{ this.transactionData.dokumen_driver2 }}</b><br>
                          Hp: <b>{{ this.transactionData.hp_driver2 }}</b><br>
                        </p>
                      </div>
                    </div>

                  </div>


                </div>
              </div>
            </div>
            <!-- left wrapper end -->
            <!-- middle wrapper start -->
            <div class="col-md-8 col-xl-6 middle-wrapper">
              <div class="row">
                <div class="col-md-12 grid-margin">
                  <div class="card rounded">
                    <div class="card-header">
                      <div class="
                              d-flex
                              align-items-center
                              justify-content-between
                            ">
                        <div class="d-flex align-items-center">
                          <h6 class="card-title mb-0">
                            {{ $t("detail_transport_order_txt") }}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th>{{ $t("nomor") }}</th>
                              <th>{{ $t("shipment_number") }}</th>
                              <th>{{ $t("customer") }}</th>
                              <th>{{ $t("route") }}</th>
                              <th>{{ $t("muatan") }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(detail, index) in this.blujayData
                              .order_detail" :key="detail.order_detail_id" :value="detail.order_detail_id">
                              <td>{{ index + 1 }}</td>
                              <td>{{ detail.order_number }}</td>
                              <td>{{ detail.customer_name }}</td>
                              <td>{{ detail.name_rute }}</td>
                              <td>{{ detail.product_type }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="card rounded">
                    <div class="card-header">
                      <div class="
                              d-flex
                              align-items-center
                              justify-content-between
                            ">
                        <div class="d-flex align-items-center">
                          <!-- <img class="img-xs rounded-circle" src="../../../assets/images/faces/face1.jpg" alt="">													 -->
                          <h6 class="card-title mb-0">
                            {{ $t("equipment") }}
                          </h6>
                        </div>
                        <div class="dropdown">
                          <a type="button" id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <i class="icon-lg pb-3px" data-feather="more-horizontal"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-lg-3">
                          <h4>{{ $t("dongkrak") }}</h4>
                        </div>

                        <div class="col-lg-3">
                          <h4>
                            <span v-if="this.transactionData.dongkrak == 'Ada'" class="badge bg-success">{{
                              this.transactionData.dongkrak }}</span>
                            <span v-else class="badge bg-red text-white">{{
                              this.transactionData.dongkrak
                            }}</span>
                          </h4>
                        </div>

                        <div class="col-lg-3">
                          <h4>{{ $t("kunci_roda") }}</h4>
                        </div>

                        <div class="col-lg-3">
                          <h4>
                            <span v-if="this.transactionData.kunci_roda == 'Ada'" class="badge bg-success">{{
                              this.transactionData.kunci_roda }}</span>
                            <span v-else class="badge bg-danger text-white">{{
                              this.transactionData.kunci_roda
                            }}</span>
                          </h4>
                        </div>
                      </div>

                      <br />

                      <div class="row">
                        <div class="col-lg-3">
                          <h4>{{ $t("spion_kanan") }}</h4>
                        </div>

                        <div class="col-lg-3">
                          <h4>
                            <span v-if="this.transactionData.spion_kanan == 'Ada'" class="badge bg-success">{{
                              this.transactionData.spion_kanan }}</span>
                            <span v-else class="badge bg-danger text-white">{{
                              this.transactionData.spion_kanan
                            }}</span>
                          </h4>
                        </div>

                        <div class="col-lg-3">
                          <h4>{{ $t("spion_kiri") }}</h4>
                        </div>

                        <div class="col-lg-3">
                          <h4>
                            <span v-if="this.transactionData.spion_kiri == 'Ada'" class="badge bg-success">{{
                              this.transactionData.spion_kiri }}</span>
                            <span v-else class="badge bg-danger text-white">{{
                              this.transactionData.spion_kiri
                            }}</span>
                          </h4>
                        </div>
                      </div>

                      <br>

                      <div class="row">
                        <div class="col-lg-3">
                          <h4>{{ $t("ban_stip") }}</h4>
                        </div>

                        <div class="col-lg-3">
                          <h4>
                            <span v-if="this.transactionData.ban_stip == 'Ada'" class="badge bg-success">{{
                              this.transactionData.ban_stip }}</span>
                            <span v-else class="badge bg-danger text-white">{{
                              this.transactionData.ban_stip
                            }}</span>
                          </h4>
                        </div>

                        <div class="col-lg-3">
                          <h4>{{ $t("kotak_p3k") }}</h4>
                        </div>

                        <div class="col-lg-3">
                          <h4>
                            <span v-if="this.transactionData.kotak_p3k == 'Ada'" class="badge bg-success">{{
                              this.transactionData.kotak_p3k }}</span>
                            <span v-else class="badge bg-danger text-white">{{
                              this.transactionData.kotak_p3k
                            }}</span>
                          </h4>
                        </div>
                      </div>

                      <br>

                      <div class="row">
                        <div class="col-lg-3">
                          <h4>Cek Kabin</h4>
                        </div>

                        <div class="col-lg-3">
                          <h4>
                            <span v-if="this.transactionData.ban_serep == 'Ada'" class="badge bg-success">Sudah</span>
                            <span v-else class="badge bg-danger text-white">Belum</span>
                          </h4>
                        </div>

                        <div class="col-lg-3">
                          <h4>{{ $t("radio_tape") }}</h4>
                        </div>

                        <div class="col-lg-3">
                          <h4>
                            <span v-if="this.transactionData.radio_tape == 'Ada'" class="badge bg-success">{{
                              this.transactionData.radio_tape }}</span>
                            <span v-else class="badge bg-danger text-white">{{
                              this.transactionData.radio_tape
                            }}</span>
                          </h4>
                        </div>
                      </div>

                      <br>

                      <div class="row">
                        <div class="col-lg-3">
                          <h4>{{ $t("speedometer") }}</h4>
                        </div>

                        <div class="col-lg-3">
                          <h4>
                            <span v-if="this.transactionData.speedometer == 'Ada'" class="badge bg-success">{{
                              this.transactionData.speedometer }}</span>
                            <span v-else class="badge bg-danger text-white">{{
                              this.transactionData.speedometer
                            }}</span>
                          </h4>
                        </div>

                        <div class="col-lg-3">
                          <h4>APAR</h4>
                        </div>

                        <div class="col-lg-3">
                          <h4>
                            <span v-if="this.transactionData.apar == 'Ada'" class="badge bg-success">{{
                              this.transactionData.apar }}</span>
                            <span v-else class="badge bg-danger text-white">{{
                              this.transactionData.apar
                            }}</span>
                          </h4>
                        </div>
                      </div>





                      <!-- <img class="img-fluid" src="../../../assets/images/photos/img1.jpg" alt=""> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- middle wrapper end -->
            <!-- right wrapper start -->
            <div class="d-none d-xl-block col-xl-3">
              <div class="row">
                <div class="col-md-12 grid-margin">
                  <div class="card rounded">
                    <div class="card-body">
                      <!-- <h6 class="card-title"></h6> -->
                      <div class="row ms-0 me-0">
                        <div class="mt-3">
                          <label class="tx-11 fw-bolder mb-0 text-uppercase">{{
                            $t("load_id")
                          }}</label>
                          <p class="text-muted">
                            {{ this.blujayData.load_id }}
                          </p>
                        </div>
                        <div class="mt-3">
                          <label class="tx-11 fw-bolder mb-0 text-uppercase">{{
                            $t("load_group")
                          }}</label>
                          <p class="text-muted">
                            {{ this.blujayData.load_group }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 grid-margin">
                  <div class="card rounded">
                    <div class="card-header">
                      <div class="
                              d-flex
                              align-items-center
                              justify-content-between
                            ">
                        <div class="d-flex align-items-center">
                          <!-- <img class="img-xs rounded-circle" src="../../../assets/images/faces/face1.jpg" alt="">													 -->
                          <h6 class="card-title mb-0">
                            Photo Truck
                          </h6>


                        </div>
                        <div class="dropdown">
                          <a type="button" id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <i class="icon-lg pb-3px" data-feather="more-horizontal"></i>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="card-body">

                      <div class="row ">
                        <img :src="this.transactionData.foto_truck" width="300px" height="200px" class="rounded-top"
                          alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 grid-margin">
                  <div class="card rounded">
                    <div class="card-header">
                      <div class="
                              d-flex
                              align-items-center
                              justify-content-between
                            ">
                        <div class="d-flex align-items-center">
                          <!-- <img class="img-xs rounded-circle" src="../../../assets/images/faces/face1.jpg" alt="">													 -->
                          <h6 class="card-title mb-0">
                            Photo Ban Stip
                          </h6>


                        </div>
                        <div class="dropdown">
                          <a type="button" id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <i class="icon-lg pb-3px" data-feather="more-horizontal"></i>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="card-body">

                      <div class="row ">
                        <img :src="this.transactionData.foto_ban_stip" width="300px" height="200px" class="rounded-top"
                          alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <!-- right wrapper end -->
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import moment from "moment";
export default {
  name: "poolTransactions",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      permision_role: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      driverData: {},
      truckData: {},
      blujayData: {},
      transactionData: "",
      statusFppk: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "GateTransactionController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    loadData() {
      var paramId = this.$onBehind(this.$route.params.id);
      this.isLoading = true;
      var baseURI =
        this.$settings.endPointGT + "gate-transaction/detail/" + paramId;

      return this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          this.transactionData = response.data.datas;

          this.loadStatusFppk(this.transactionData.vehicle_no);
          this.loadTruck();
          this.loadBlujay();

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },


    loadStatusFppk(vehicle_no) {

      this.isLoading = true;
      var baseURI =
        this.$settings.endPointGT + "status-fppk/detail/" + vehicle_no;

      return this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          this.statusFppk = response.data.datas;

        })
        .catch((error) => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },

    getSimData() {
      const status_surat_detail = this.transactionData.status_surat_detail;

      if (status_surat_detail?.length > 0) {
        var responseData = "";
        status_surat_detail.forEach(function (dstnk) {
          if (dstnk.nama_surat == "SIM") {
            const date_of_expire = moment(dstnk.date_of_expire).format(
              "DD/MM/YYYY"
            );
            responseData = `(${dstnk.status}) - ${date_of_expire}`;
          }
        });
        return responseData;
      } else {
        return "";
      }
    },

    getStnkData() {
      const status_surat_detail = this.transactionData.status_surat_detail;

      if (status_surat_detail?.length > 0) {
        var responseData = "";
        status_surat_detail.forEach(function (dstnk) {
          if (dstnk.nama_surat == "STNK") {
            const date_of_expire = moment(dstnk.date_of_expire).format(
              "DD/MM/YYYY"
            );
            responseData = `(${dstnk.status}) - ${date_of_expire}`;
          }
        });
        return responseData;
      } else {
        return "";
      }
    },

    getKirData() {
      const status_surat_detail = this.transactionData.status_surat_detail;

      if (status_surat_detail?.length > 0) {
        var responseData = "";
        status_surat_detail.forEach(function (dstnk) {
          if (dstnk.nama_surat == "KIR") {
            const date_of_expire = moment(dstnk.date_of_expire).format(
              "DD/MM/YYYY"
            );
            responseData = `(${dstnk.status}) - ${date_of_expire}`;
          }
        });
        return responseData;
      } else {
        return "";
      }
    },

    loadDriver() {
      var baseURI = "";
      baseURI =
        this.$settings.endPointCt +
        "master-driver?driver_id=" +
        this.transactionData.driver_nmk;
      return this.$http.get(baseURI, { timeout: 10000 }).then((response) => {
        this.driverData = response.data.datas.data[0];
      });
      // if(this.transactionData.branch_id==null){
      //   baseURI =
      //   this.$settings.endPointCt +
      //   "master-driver/detail/" +
      //   this.transactionData.master_driver_id;
      //   return this.$http.get(baseURI, { timeout: 10000 }).then((response) => {
      //     this.driverData = response.data.datas;
      //   });   
      // }else{
      //   baseURI =
      //   this.$settings.endPointCt +
      //   "master-driver/?driver_id=" +
      //   this.blujayData.driver_id;
      //   return this.$http.get(baseURI, { timeout: 10000 }).then((response) => {
      //     this.driverData = response.data.datas.data;
      //   });
      // }


    },

    loadTruck() {
      var baseURI =
        this.$settings.endPointCt +
        "master-truck/detail/" +
        this.transactionData.master_truck_id;
      return this.$http.get(baseURI, { timeout: 10000 }).then((response) => {
        this.truckData = response.data.datas;
      });
    },

    loadBlujay() {
      var baseURI =
        this.$settings.endPointCt +
        "blujay-order/checkdata-vehicle-no/" +
        this.transactionData.vehicle_no;
      return this.$http.get(baseURI, { timeout: 10000 }).then((response) => {
        this.blujayData = response.data.datas;
        this.loadDriver();
      });
    },
  },
  events: {},
  created: function () { },
  mounted() {
    this.loadData();
    this.fetchIt();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content {
  filter: blur(5px);
}
</style>