<template>
    <div>
      <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Order Import Status</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
                aria-label="btn-close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col">
                <button
                    class="btn btn-success float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="downloadData()"
                  >
                    <i class="link-icon" data-feather="download"></i>
                    {{ $t("downloadData") }} .xlsx
                </button>
                </div>
              </div>
              <!-- :rows="rows"  -->
              <vue-good-table
                  title="transport-order-error"
                  :totalRows="data.length"
                  :line-numbers="true"
                  :isLoading.sync="isLoading"
                  :columns="columns"
                  :rows="data"
              >
  
              </vue-good-table>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
                cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
      name:"modalTransportOrderError",
      emits: ["closeModal"],
      props: {
        showModal: Boolean,
        data: null,
      },
      watch: {
      showModal: {
        handler(newVal) {
          this.active = newVal;
          const body = document.querySelector("body");
          this.showModal
            ? body.classList.add("modal-open")
            : body.classList.remove("modal-open");
        },
        immediate: true,
        deep: true,
      },
    },
    data() {
        return {
            isLoading: false,
            opens: "center",
            active: this.showModal,
            forms:[],
            columns:[
                {
                label: "Row No",
                field: "row_no",
                },
                {
                label: "Status",
                field: "status",
                },
                {
                label: "Description",
                field: "description",
                },
            ],
        }
    },
    methods: {
      downloadData(){
        var uniqueid = this.data[0].unique_id;
        this.isLoading = true;
        var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
        var file_name = "download_" + CurrentDate + ".xlsx";
        const baseURI = this.$settings.endPoint + "transport-order-header/download-log-error-upload";
        return this.$http
        .get(
          baseURI +
            `?unique_id=`+uniqueid,
          {
            responseType: "blob",
            timeout: 20000
          }
        )
        .then((response) => {
         
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.isLoading = false;
        }).catch(error => {
          this.isLoading = false;
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                      this.errors = error.response.data.errors;
                      this.resultError(error.response.data.errors);
              }else if (error.response.status === 500) {
                  this.$router.push('/server-error');
              }else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
              }else{
                  this.$router.push('/page-not-found');
              }
            }

          }

        });
      }
    },
    mounted() {
    },
  
  
  }
  </script>
  
  <style scoped>
  .modal {
    background: rgba(0, 0, 0, 0.4);
  }
  
  .modal-content {
    background: #fff;
    border-radius: 0px;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 0px;
    width: 100%;
    height: 100%;
    margin-left:0px !important;
  }
  </style>