<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>   
    <div class="loadertext" v-if="this.isLoading"> {{$t('loadingTxt')}}...</div>   
    <div class="main-wrapper" :class="{'blur-content': this.isLoading}">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TransportOrderDetail"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
           <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 25px"
                  >
                    Detail Transport Order
                  </h6>
                  <button
                    class="btn btn-light float-end"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <i class="link-icon" data-feather="info"></i>
                    Audit Info
                  </button>

                  <button
                    class="btn btn-primary float-end"
                    type="button"
                    style="margin-right: 20px"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <i class="link-icon" data-feather="info"></i>
                    Tracking Status
                  </button>


                  <button
                    v-if="!isMaps"
                    class="btn btn-success float-end"
                    type="button"
                    style="margin-right: 20px"
                    @click="onClickShowMaps(true)"
                  >
                    <i class="mdi mdi-google-maps"></i>
                    Show Maps
                  </button>

                  <button
                    v-if="isMaps"
                    class="btn btn-danger float-end"
                    type="button"
                    style="margin-right: 20px"
                    @click="onClickShowMaps(false)"
                  >
                    <i class="mdi mdi-google-maps"></i>
                    Hide Maps
                  </button>

                  <div
                    class="row mb-3 collapse"
                    id="collapseExample"
                    style="padding-left: 20px"
                  >
                    <div class="row">
                       <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created By:</label>
                      <p class="text-muted">{{ this.forms.create_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created At:</label>
                      <p class="text-muted">{{ ubahTgl(this.forms.created_at) }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated By:</label>
                      <p class="text-muted">{{ this.forms.update_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated At:</label>
                      <p class="text-muted">{{ ubahTgl(this.forms.updated_at) }}</p>
                    </div>

                    </div>


                     <div class="row">
                       <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Integration Status:</label>
                      <p class="text-muted" v-if="this.forms.integration_status==1">True</p>
                      <p class="text-muted" v-else>False</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Integration Description:</label>
                      <p class="text-muted">{{ this.forms.integration_description }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Load Id:</label>
                      <p class="text-muted">{{ this.forms.load_id }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Tms Id:</label>
                      <p class="text-muted">{{ this.forms.tms_id }}</p>
                    </div>

                    </div>


                     <div class="row">
                       <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Status TMS:</label>
                     <p class="text-muted">{{ this.forms.status_tms }}</p>
                    </div>

                   

                    </div>
                   

                    
                  </div>
                </div>

                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->
                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "
                    ></div>

                    <div class="row card">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-3">
                            <div class="mb-3">
                              <label class="form-label">Company</label>
                              <p class="text-muted">
                                <b>{{ company.name }}</b>
                              </p>
                            </div>
                          </div>
                          <!-- Col -->
                          <div class="col-sm-3">
                            <div class="mb-3">
                              <label class="form-label">Order No</label>
                              <p class="text-muted">
                                <b>{{ forms.order_no }}</b>
                              </p>
                            </div>
                          </div>
                          <!-- Col -->

                          <div class="col-sm-3">
                            <div class="mb-3">
                              <label class="form-label">Order Date</label>
                              <p class="text-muted">
                                <b>{{ ubahTglOrder(forms.order_date) }}</b>
                              </p>
                            </div>
                          </div>

                          <div class="col-sm-3">
                            <div class="mb-3">
                              <label class="form-label">Branch / Truck Type</label>
                              <p class="text-muted">
                                <b>{{ template_order.description }}</b>
                              </p>
                            </div>
                          </div>
                        </div>

                        <br>

                        <div class="row">
                          <div class="col-sm-6">
                            <div class="mb-3">
                              <label class="form-label">Remarks</label>
                              <p class="text-muted">
                                <b>{{ forms.remarks }}</b>
                                
                              </p>
                            </div>
                          </div>


                          <div class="col-sm-6">
                            <div class="mb-3">
                              <label class="form-label">Shipment Rate</label>
                              <p class="text-muted">
                                <b>{{ forms.shipment_rate }}</b>
                                
                              </p>
                            </div>
                          </div>
                          <!-- Col -->
                          
                          <!-- Col -->

                       

                    
                        </div>
                        
                        
                        <div class="row">
                          <div class="col-sm-3">
                            <div class="mb-3">
                              <label class="form-label">Shipwith ref <span class="wajib">*</span></label>
                              <p class="text-muted">
                                <b v-if="forms.shipwith_ref!=''">{{ forms.shipwith_ref }}</b>
                                <b v-else>-</b>
                              </p>
                            </div>
                          </div>
                          <!-- Col -->
                          <div class="col-sm-3">
                            <div class="mb-3">
                              <label class="form-label">Tonnage</label>
                              <p class="text-muted">
                                <b v-if="forms.tonnage!=''">{{ forms.tonnage }}</b>
                                <b v-else>-</b>
                              </p>
                            </div>
                          </div>
                          <!-- Col -->

                          <div class="col-sm-3">
                            <div class="mb-3">
                              <label class="form-label">Cubic</label>
                              <p class="text-muted">
                                 <b v-if="forms.cubic!=''">{{ forms.cubic }}</b>
                                <b v-else>-</b>
                              </p>
                            </div>
                          </div>


                          <div class="col-sm-3">
                            <div class="mb-3">
                              <label class="form-label">Order Status</label>
                              <p class="text-muted">
                                 <b >{{ forms.order_status }}</b>
                                
                              </p>
                            </div>
                          </div>

                    
                        </div>
                      </div>

                      <!-- Col -->
                    </div>
                    <!-- Row -->
                    <br />

                    <div class="row">
                      <div class="col-sm-6">
                        <div class="card">
                          <div class="card-header">
                            <h5>Pickup</h5>
                          </div>

                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="mb-3">
                                  <label class="form-label"
                                    >Pickup Location</label
                                  >
                                   <p class="text-muted" v-if="pickup_address">
                                  <b>{{ forms.pick_name }}</b>
                                  <i class="mdi mdi-map-marker"></i>Lat.{{
                                    forms.pick_latitude
                                  }}
                                  Long.{{ forms.pick_longitude }} <br />
                                  {{ forms.pick_address1 }}
                                  {{ forms.pick_address2 }}
                                  {{ forms.pick_address2 }}
                                  {{ forms.pick_sub_district }},
                                  {{ forms.pick_district }},
                                  {{ forms.pick_city }},
                                  {{ forms.pick_province }}
                                  {{ forms.pick_postal_code }},
                                  {{ forms.pick_country }}<br />
                                  {{ forms.pick_contact_name }} -
                                  {{ forms.pick_phone_number }}
                                </p>
                                </div>
                              </div>

                              <div class="col-sm-6">
                                <div class="mb-3">
                                  <label class="form-label">Pickup Plan</label>
                                  <p class="text-muted">
                                    <b>{{ ubahTgl(forms.pick_plan) }}</b>
                                  </p>
                                </div>
                              </div>
                            </div>
                           
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-6">
                        <div class="card">
                          <div class="card-header">
                            <h5>Destination</h5>
                          </div>

                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="mb-3">
                                  <label class="form-label"
                                    >Destination Location</label
                                  >
                                   <p class="text-muted" v-if="destination_address">
                                  <b>{{ forms.dest_name }}</b>
                                  <i class="mdi mdi-map-marker"></i>Lat.{{
                                    forms.dest_latitude
                                  }}
                                  Long.{{ forms.dest_longitude }} <br />
                                  {{ forms.dest_address1 }}
                                  {{ forms.dest_address2 }}
                                  {{ forms.dest_address2 }}
                                  {{ forms.dest_sub_district }},
                                  {{ forms.dest_district }},
                                  {{ forms.dest_city }},
                                  {{ forms.dest_province }}
                                  {{ forms.dest_postal_code }},
                                  {{ forms.dest_country }}<br />
                                  {{ forms.dest_contact_name }} -
                                  {{ forms.dest_phone_number }}
                                </p>
                                </div>
                              </div>

                              <div class="col-sm-6">
                                <div class="mb-3">
                                  <label class="form-label"
                                    >Destination Plan</label
                                  >
                                  <p class="text-muted">
                                    <b>{{ ubahTgl(forms.dest_plan) }}</b>
                                  </p>
                                </div>
                              </div>
                            </div>

                           
                          </div>
                        </div>
                      </div>
                    </div>

                    <br />


                     <div class="row" v-if="isMaps">
                      <div class="col-sm-12">
                        <div class="card">
                          <div class="card-header">
                            <h5>Maps</h5>
                          </div>

                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-12">

                                <!-- <GmapMap :center="center" :zoom="8" map-type-id="terrain" style="width: 100%; height: 400px">
                                <GmapMarker
                                  :key="index"
                                  v-for="(m, index) in markers"
                                  :position="m.position"
                                  @click="center = m.position"
                                />
                                <DirectionsRenderer
                                  travelMode="DRIVING"
                                  :origin="startLocation"
                                  :destination="endLocation"
                                  :waypoints="waypnt"
                                  :optimizeWaypoints="true"
                                />
                              </GmapMap> -->

                              <div class="mb-3">
                                  <gmap-map :zoom="8" :center="center" style="width: 100%; height: 400px" map-type-id="terrain">

                                     <gmap-marker
                                      :key="index"
                                      v-for="(gmp, index) in markers"
                                      :position="gmp.position"
                              
                                      @click="center = gmp.position"
                                    >
                                      <gmap-info-window :opened="true">
                                        <span
                                          class="badge"
                                          :style="{
                                            'background-color': 'white',
                                            color: 'black',
                                          }"
                                          :opened="false"
                                        >
                                          {{ gmp.position.label }}
                                        </span>
                                        
                                      </gmap-info-window>

                                      

                                    </gmap-marker>

                                  <DirectionsRenderer
                                    travelMode="DRIVING"
                                    :origin="startLocation"
                                    :destination="endLocation"
                                    :waypoints="waypnt"
                                    
                                  />
                                  </gmap-map>
                                    


                                  
                                  


                                </div> 
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>

                    <br/>

                    <div class="row card">
                    
                      <div class="card-header">
                        <h5>Detail</h5>
                      </div>

                      <div class="card-body">
                        <div class="table-responsive">
                          <table class="table table-hover">
                          

                            <thead>
                              <tr>
                                <th>#</th>
                                <th width="300px">SKU</th>
                                <th width="200px">SKU Description</th>
                                <th>Qty</th>
                                <th>Package Type</th>
                                <th width="300px">Lading Type</th>
                                
                                <th>Lading Length</th>
                                <th>Lading Width</th>
                                <th>Lading Height</th>
                              
                               
                                <th>Volume</th>
                                <th>Weight</th>
                                <th>Net Weight</th>
                                <th>Lading Qty</th>
                                <th></th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr
                                v-for="(input, index) in detailAll"
                                :key="`phoneInput-${index}`"
                              >
                                <th>{{ index + 1 }}</th>
                                <td>{{ input.sku.sku_code }}</td>
                                <td>{{ input.sku.sku_description }}</td>
                                 <td>{{ input.qty }}</td>
                                <td>{{ input.package_type }}</td>
                                <td>{{ input.lading_type.name }}</td>
                                <td>{{ input.lading_length }}</td>
                                <td>{{ input.lading_width }}</td>
                                <td>{{ input.lading_height }}</td>
                             
                               
                                <td>{{ input.volume }}</td>
                                <td>{{ input.weight }}</td>
                                <td>{{ input.net_weight }}</td>
                                <td>{{ input.lading_qty }}</td>
                              </tr>
                            </tbody>

                            <tfoot></tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                    <br />


                    <div class="card-footer">
                      <button
                        class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        "
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>

                      &nbsp;&nbsp;&nbsp;

                    <a
                      v-if="permision_role.method_delete && forms.order_status == 'NEW'"
                      class="btn btn-danger text-white  fw-bolder btn-icon-text float-end"
                      @click="deleteData()"
                    >
                      <i class="link-icon" data-feather="trash"></i>
                      {{ $t("deleteMess") }}
                    </a>


                    <a
                      v-if="permision_role.method_update && (forms.order_status == 'NEW')"
                      class="btn btn-primary me-2 text-white fw-bolder btn-icon-text float-end"
                      @click="editData()"
                    >
                      <i class="link-icon" data-feather="edit"></i>
                      {{ $t("editMess") }}
                    </a>


                    <a
                      v-if="!loadingSendOrder && permision_role.method_update && (forms.order_status == 'NEW')"
                      class="btn btn-success me-2 text-white fw-bolder btn-icon-text float-end"
                      @click="submitOrder(forms.transport_order_header_id)"
                    >
                      <i class="mdi mdi-send"></i>
                      Submit Order
                    </a>

                    <a v-if="loadingSendOrder" class="spinner-border text-primary float-end me-2" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-md" >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Tracking Status</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <div class="modal-body">
              <div id="content">
                  <!-- <ul class="timeline" v-for="(input, index) in fetchTrackingStatus "
                                :key="`status-${index}`">
                    
                    <li class="event" :data-date="ubahTgl(input.tracking_date)" >
                      <h3 class="title">{{input.order_status}}</h3>
                      <p></p>
                    </li>
                   
                  
                  </ul> -->

                  <div class="timeline2 p-4 block mb-4">
                    <div class="tl-item" v-for="(input, index) in fetchTrackingStatus "
                                :key="`status-${index}`">
                        <div class="tl-dot b-danger"></div>
                        <div class="tl-content">
                            <div class="">{{input.order_status}}</div>
                            <div class="tl-date text-muted mt-1">{{ubahTgl(input.tracking_date)}}</div>
                            <div v-if="input.remarks" class="text-muted mt-1">Reason : {{input.remarks}}</div>
                        </div>
                    </div>
                   
                  
                </div>

                </div>

             <!-- <div class="table-responsive">
                          <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Create By</th>
                        <th>Update By</th>
                      </tr>
                      
                    </thead>

                    <tbody>
                        <tr  v-for="(input, index) in fetchTrackingStatus "
                                :key="`status-${index}`" >
                         
                            <td>{{ index+1 }}</td>
                              <td>{{ input.tracking_date }}</td>
                              <td>{{ input.order_status }}</td>
                              <td>{{ input.create_by }}</td>
                              <td>{{ input.update_by }}</td> 
                         
                        </tr>
                    </tbody>
                  </table>
              </div> -->
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import DirectionsRenderer from "@/middleware/DirectionsRenderer";

import moment from 'moment'


export default {
  name: "TemplateOrderEdit",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    DirectionsRenderer
  },
  data() {
    const mapMarkerIcon = require("../../../assets/icontruck.png");
    return {
      startLocation:null,
      endLocation:null,
      path: [],
      destinationPlaces: [],
      originPlaceLocation: [],
      isMaps: false,
      markers:[],
      loadingSendOrder:false,
      pickup_address: true,
      destination_address: true,
      fetchTrackingStatus:[],
      transport_order_header_ids:[],
      waypnt: [],
      fetchladingType: [
        {
          name: "pcs_code",
        },
        {
          name: "pl_code",
        },
        {
          name: "ot_code",
        },
        {
          name: "lp_code",
        },
        {
          name: "cs_code",
        },
      ],
      markerOption: {
        url: mapMarkerIcon,
        size: { width: 30, height: 30, f: "20px", b: "20px" },
        scaledSize: { width: 30, height: 30, f: "20px", b: "20px" },
        rotation: 45,
      },
      detailAll: [],
      fetchSKU: [],
      fetchCompany: [],
      fetchTemplateOrder: [],
      fetchCompanyNotLinc: [],
      fetchLocation: [],
      fetchLocationDest: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      permision_role: [],
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      company: [],
      template_order: [],
      location: [],
      location_dest: [],
      forms: [],
      center: {
        lat: -2.548926,
        lng: 118.0148634,
      },
    };
  },
  watch: {},
  methods: {
    addField(value, fieldType) {
      fieldType.push({ value: "" });
    },
    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },

    ubahTgl(tgl){
      return moment(String(tgl)).format('DD MMM YYYY HH:mm:ss');
    },

    ubahTglOrder(tgl){
      return moment(String(tgl)).format('DD MMM YYYY HH:mm:ss');
    },

    cekData(index, fieldType) {
      fieldType[index].lading_length = fieldType[index].sku.pcs_length;
      fieldType[index].lading_width = fieldType[index].sku.pcs_width;
      fieldType[index].lading_height = fieldType[index].sku.pcs_height;
      fieldType[index].package_type = fieldType[index].sku.package_code;
    },

    cekQty(index, fieldType) {
      let qty = fieldType[index].qty;
      let lading_type = fieldType[index].lading_type.name;
      let package_type = fieldType[index].package_type;

      let data = {
        qty: qty,
        lading_type: lading_type,
        package_type: package_type,
      };
      const baseURI = this.$settings.endPoint + "package/calculate";

      return this.$http.post(baseURI, data).then((response) => {
        let data = response.data.datas[0];
        fieldType[index].lading_qty = data.lading_qty;
        fieldType[index].net_weight = data.net_weight;
        fieldType[index].weight = data.weight;
        fieldType[index].volume = data.volume;
      });
    },

    onClickShowMaps(stype){
      this.isMaps = stype;
        this.markers=[];
       
        const markerPickup = {
          lat: this.location.latitude,
          lng: this.location.longitude,
          label:"PICKUP",
        };

        const markerDestination = {
          lat: this.location_dest.latitude,
          lng: this.location_dest.longitude,
          label:"DESTINATION",
        };
        
        

        this.center = markerPickup; 
        this.startLocation = markerPickup;
        this.endLocation = markerDestination;
        

    },

    resetForm() {
      this.forms = "";
      this.company = "";
      this.location = "";
      this.location_dest = "";
      this.pickup_address = false;
      this.destination_address = false;
      this.template_order = "";
      this.detailAll = [
        {
          sku: [],
          qty: 0,
          lading_length: 0,
          lading_width: 0,
          lading_height: 0,
          lading_type: "-",
          package_type: "-",
          volume: 0,
          weight: 0,
          net_weight: 0,
          lading_qty: 0,
        },
      ];
    },

    getLocation(ev) {
      this.fetchLocation = [];
      this.fetchLocationDest = [];
      this.company_name = ev.name;
      const baseURI =
        this.$settings.endPoint + "location?company_name=" + ev.name;

      return this.$http.get(baseURI).then((response) => {
        this.fetchLocation = response.data.datas.data;
        this.fetchLocationDest = response.data.datas.data;
        this.loadSKU(this.company_name);
        this.loadTemplateOrder(this.company_name);
      });
    },

    loadLocation(company_name) {
      this.fetchLocation = [];
      this.fetchLocationDest = [];
      const baseURI =
        this.$settings.endPoint + "location?company_name=" + company_name;

      return this.$http.get(baseURI).then((response) => {
        this.fetchLocation = response.data.datas.data;
        this.fetchLocationDest = response.data.datas.data;
      });
    },


    loadTrackingStatus(id) {
      const baseURI =
        this.$settings.endPoint + "transport-order-tracking?transport_order_header_id=" + id;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTrackingStatus = response.data.datas;
      });
    },

    getLocationPickup(ev) {
      this.forms.pick_name = ev.name;
      this.forms.pick_country = ev.country;
      this.forms.pick_province = ev.province;
      this.forms.pick_city = ev.city;
      this.forms.pick_district = ev.district;
      this.forms.pick_sub_district = ev.sub_district;
      this.forms.pick_postal_code = ev.postal_code;
      this.forms.pick_address1 = ev.address1;
      this.forms.pick_address2 = ev.address2;
      this.forms.pick_address3 = ev.address3;
      this.forms.pick_latitude = ev.latitude;
      this.forms.pick_longitude = ev.longitude;
      this.forms.pick_radius = ev.radius;
      this.forms.pick_contact_name = ev.contact_name;
      this.forms.pick_phone_number = ev.phone_number;
    },

    getLocationDest(ev) {
      this.forms.dest_name = ev.name;
      this.forms.dest_country = ev.country;
      this.forms.dest_province = ev.province;
      this.forms.dest_city = ev.city;
      this.forms.dest_district = ev.district;
      this.forms.dest_sub_district = ev.sub_district;
      this.forms.dest_postal_code = ev.postal_code;
      this.forms.dest_address1 = ev.address1;
      this.forms.dest_address2 = ev.address2;
      this.forms.dest_address3 = ev.address3;
      this.forms.dest_latitude = ev.latitude;
      this.forms.dest_longitude = ev.longitude;
      this.forms.dest_radius = ev.radius;
      this.forms.dest_contact_name = ev.contact_name;
      this.forms.dest_phone_number = ev.phone_number;
    },

    asyncSearchLocation(ev) {
      let company_id = this.company.company_id;
      const baseURI =
        this.$settings.endPoint +
        "location?company_id=" +
        company_id +
        "&name=" +
        ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchLocation = response.data.datas.data;
      });
    },

    backForm() {
      window.location.href = "/order/transport-order";
    },

    loadCompany() {
      const baseURI = this.$settings.endPoint + "company?limit=10";

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },
    

    submitOrder(item){
      this.transport_order_header_ids=[];
      this.transport_order_header_ids.push(item);
      this.sendOrder(item);
    },

    sendOrder(item){
      let ask = this.forms.order_status == 'REJECTED'?this.$t("rejectResubmit"):this.$t("yourDataSend");
      this.$swal({
        title: this.$t("areYouSure"),
        text: ask,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          //this.loadingSubmiting();
          this.fade(true);
          let data={
            transport_order_header_ids:this.transport_order_header_ids
          }
          const baseURI =
            this.$settings.endPoint + "transport-order-header/send-order";
          this.$http
            .post(baseURI,data)
            .then((response) => {
              
              if (response.data.status === 200) {
                this.fade(false);
                // response.data.datas.messages.forEach((item) => {
                //   this.success(item);
                // });
                //this.loadingSendOrder=false;
                this.success2(response.data.datas.messages);
                this.loadData(item);
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              //this.loadingSendOrder=false;
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                   this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });

     


    },

    deleteData() {
      var id = this.$onBehind(this.$route.params.id);
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI =
            this.$settings.endPoint + "transport-order-header/delete/" + id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                window.location.href = "/order/transport-order";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    editData(){
      var params = this.$route.params.id
      window.location.href = "/order/transport-order/edit/"+ params;
    },


    loadCompanyNotLinc() {
      const baseURI =
        this.$settings.endPoint + `company?code=${this.companyCode}`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },

    asyncSearchCompany(ev) {
      const baseURI = this.$settings.endPoint + "company?name=" + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },

    asyncSearchCompanyNotLinc(ev) {
      const baseURI =
        this.$settings.endPoint +
        "company?name=" +
        ev +
        "&code=" +
        this.companyCode;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },

    loadSKU(company_name) {
      const baseURI =
        this.$settings.endPoint + `sku?company_name=` + company_name;

      return this.$http.get(baseURI).then((response) => {
        this.fetchSKU = response.data.datas.data;
      });
    },

    asyncSearchSKU(ev) {
      const baseURI =
        this.$settings.endPoint +
        "sku?sku_code=" +
        ev +
        "?company_name=" +
        this.company_name;
      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },

    loadTemplateOrder(company_name) {
      const baseURI =
        this.$settings.endPoint +
        `transport-order-template?company_name=` +
        company_name;

      return this.$http.get(baseURI).then((response) => {
        this.fetchTemplateOrder = response.data.datas.data;
      });
    },

    asyncSearchTemplateOrder(ev) {
      const baseURI =
        this.$settings.endPoint +
        "transport-order-template?description=" +
        ev +
        "?company_name=" +
        this.company_name;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTemplateOrder = response.data.datas.data;
      });
    },

     loadData(id) {
      this.detailAll=[];
      const baseURI = this.$settings.endPoint + "transport-order-header/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        
        this.forms = response.data.datas;
        this.company = this.forms.company;
        this.company_name=this.company.name;
        this.template_order=this.forms.transport_order_template
        this.loadSKU(this.company_name);
        this.loadTemplateOrder(this.company_name);
        this.loadLocation(this.company_name);
        this.location=this.forms.pick_location;
        this.location_dest=this.forms.dest_location;

      

        this.forms.transport_order_detail.forEach((item) => {
          const data={

                sku:{
                  sequence_no:"",
                  sku_id:item.sku_id,
                  barcode:item.barcode,
                  sku_code:item.sku_code,
                  sku_description:item.sku_description,
                  sku_long_description:item.sku_long_description,
                  // package_type:item.package_type, 
                  // lading_qty:item.lading_qty, 
                  // lading_type:item.lading_type, 
                  // price:item.price, 
                  // amount:item.amount,  
                  // lading_width:item.lading_width, 
                  // lading_height:item.lading_height, 
                  // weight:item.weight, 
                  // net_weight:item.net_weight, 
                  // volume:item.volume, 
                  stackable:item.stackable,
                  commodity_id:item.commodity_id,
                },

                lading_length: item.lading_length, 
                lading_width: item.lading_width, 
                lading_height: item.lading_height, 
                lading_type: {name:item.lading_type}, 
                package_type: item.package_type, 
                volume: item.volume, 
                weight: item.weight,
                net_weight: item.net_weight, 
                lading_qty: item.lading_qty,

                qty:item.qty, 
            
                

          }
          
          this.detailAll.push(data);
        })

     
        // this.form={ name: 'asep', usernmae:res.username };
      });
    },


    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (
          permision.permission.controller == "TransportOrderHeaderController"
        ) {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_update) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    submitData() {
      const items = [];

      this.detailAll.forEach((item) => {
        const data = {
          sequence_no: item.sku.sequence_no,
          sku_id: item.sku.sku_id,
          barcode: item.sku.barcode,
          sku_code: item.sku.sku_code,
          sku_description: item.sku.sku_description,
          sku_long_description: item.sku.sku_long_description,
          qty: item.qty,
          package_type: item.sku.package_name,
          lading_qty: item.sku.lading_qty,
          lading_type: item.sku.type,
          price: item.sku.price,
          amount: item.sku.amount,
          lading_width: item.sku.lading_width,
          lading_height: item.sku.lading_height,
          weight: item.sku.weight,
          net_weight: item.sku.net_weight,
          volume: item.sku.volume,
          stackable: item.sku.stackable,
          commodity_id: item.sku.commodity_id,
        };
        items.push(data);
      });

      this.fade(true);

      this.forms.company_id = this.company.company_id;
      this.forms.pick_location_id = this.location.location_id;
      this.forms.dest_location_id = this.location_dest.location_id;
      this.forms.transport_order_detail = items;
      this.forms.order_source_id = "DATABASE";
      this.forms.order_status = "NEW";
      this.forms.status_tms = "NEW";
      this.forms.transport_order_template_id =
        this.template_order.transport_order_template_id;

      const baseURI =
        this.$settings.endPoint +
        "transport-order-header/update/" +
        this.forms.transport_order_header_id;
      this.$http
        .patch(baseURI, this.forms)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            this.resetForm();
            this.errors = [];
            this.success(response.data.datas.messages);

            //window.history.back();
            // var params = this.$onRandom(this.forms.transport_order_header_id);
            // window.location.href =
            //   "/order/transport-template/detail/" +
            //   params +
            //   "?status=200&msg=Successfully";
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          this.resetForm();
        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },


    success2(msg){
      let pesan="";
      msg.forEach((item) => {
          pesan=pesan+"<p>* "+item+"</p>";
      });

      this.$swal({
        icon: 'info',
        backdrop:true,
        title: "Information",
        html:pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      
    },


    loadingSubmiting(){


      this.$swal({
        icon: 'warning',
        backdrop:true,
        title: "Please wait",
        html:'<h2>SUBMITTING ORDER...</h2>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.loadData(params[0]);
    this.loadTrackingStatus(params[0]);
    this.loadCompany();
    this.loadCompanyNotLinc();
    this.fetchIt();
  },
};
</script>

<style scoped>
.w-32 {
    width: 32px !important;
    height: 32px !important;
    font-size: .85em
}

.tl-item .avatar {
    z-index: 2
}

.circle {
    border-radius: 500px
}

.gd-warning {
    color: #fff;
    border: none;
    background: #f4c414 linear-gradient(45deg, #f4c414, #f45414)
}

.timeline2 {
    position: relative;
    border-color: rgba(160, 175, 185, .15);
    padding: 0;
    margin: 0
}

.p-4 {
    padding: 1.5rem !important
}

.block,
.card {
    background: #fff;
    border-width: 0;
    border-radius: .25rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .05);
    margin-bottom: 1.5rem
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
    margin-left:140px
}

.tl-item {
    border-radius: 3px;
    position: relative;
    display: -ms-flexbox;
    display: flex
}

.tl-item>* {
    padding: 10px
}

.tl-item .avatar {
    z-index: 2
}

.tl-item:last-child .tl-dot:after {
    display: none
}

.tl-item.active .tl-dot:before {
    border-color: #448bff;
    box-shadow: 0 0 0 4px rgba(68, 139, 255, .2)
}

.tl-item:last-child .tl-dot:after {
    display: none
}

.tl-item.active .tl-dot:before {
    border-color: #448bff;
    box-shadow: 0 0 0 4px rgba(68, 139, 255, .2)
}

.tl-dot {
    position: relative;
    border-color: rgba(160, 175, 185, .15)
}

.tl-dot:after,
.tl-dot:before {
    content: '';
    position: absolute;
    border-color: inherit;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    top: 15px;
    left: 50%;
    transform: translateX(-50%)
}

.tl-dot:after {
    width: 0;
    height: auto;
    top: 25px;
    bottom: -15px;
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    border-radius: 0
}

tl-item.active .tl-dot:before {
    border-color: #448bff;
    box-shadow: 0 0 0 4px rgba(68, 139, 255, .2)
}

.tl-dot {
    position: relative;
    border-color: rgba(160, 175, 185, .15)
}

.tl-dot:after,
.tl-dot:before {
    content: '';
    position: absolute;
    border-color: inherit;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    top: 15px;
    left: 50%;
    transform: translateX(-50%)
}

.tl-dot:after {
    width: 0;
    height: auto;
    top: 25px;
    bottom: -15px;
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    border-radius: 0
}

.tl-content p:last-child {
    margin-bottom: 0
}

.tl-date {
    font-size: .85em;
    margin-top: 2px;
    min-width: 125px;
    max-width: 125px
}

.avatar {
    position: relative;
    line-height: 1;
    border-radius: 500px;
    white-space: nowrap;
    font-weight: 700;
    border-radius: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-radius: 500px;
    box-shadow: 0 5px 10px 0 rgba(50, 50, 50, .15)
}

.b-warning {
    border-color: #f4c414!important;
}

.b-primary {
    border-color: #448bff!important;
}

.b-danger {
    border-color: #ab0635!important;
}
</style>