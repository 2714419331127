<template>
  <div>
    <div class="main-wrapper">
      <sidebar-component classMenu="newRate"></sidebar-component>
      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->
        <div class="page-content">
          <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12 pe-2">
            <div class="card">
              <div class="card-header">
                <h4 style="margin-right: 5px" class="float-start">
                  New Rate Approved By SF
                </h4>
                <button class="btn btn-warning float-end btn-xs" style="margin-right: 5px"
                  @click.prevent="downloadData()">
                  <i class="link-icon" data-feather="download"></i>
                  {{ $t("downloadData") }} .xlsx
                </button>
                <div class="float-end me-5">
                  <date-range-picker style="width: 100%" control-container-class="form-control" :locale-data="locale"
                    v-model="dateRange" @update="loadItems" :opens="opens" :singleDatePicker="false"
                    :timePicker24Hour="true" :timePicker="true" :showWeekNumbers="false">
                  </date-range-picker>
                </div>
              </div>
              <div class="card-body">
                <vue-good-table title="list-user" mode="remote" :totalRows="totalRecords" :line-numbers="true"
                  @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange" :isLoading.sync="isLoading" :pagination-options="{
                    enabled: true,
                  }" :rows="rows" :columns="columns">
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'actions'">
                      <span v-if="props.row.status == 'FAILED SENT TO BL'">
                        <a href="#" title="Send To Blujay" @click.prevent="sendBlujay(props.index, props.row)">
                          <i class="mdi mdi-send" style="font-size: 16px; color: blue"></i>
                        </a>
                      </span>
                      <span v-else-if="props.row.status == 'FAILED SENT TO SF'">
                        <a href="#" title="Send To SF" @click.prevent="sendSF(props.index, props.row)">
                          <i class="mdi mdi-send" style="font-size: 16px"></i>
                        </a>
                      </span>
                      <span v-else>
                        <a href="#" title="" @click.prevent="successSend(props.index, props.row)">
                          <i class="mdi mdi-window-close" style="font-size: 16px"></i>
                        </a>
                      </span>
                      <a href="#" title="Detail" @click.prevent="detailData(props.index, props.row)"><i
                          class="mdi mdi-eye" style="font-size: 16px; color: black"></i></a>
                    </span>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>
                </vue-good-table>
              </div>
            </div>
            <modal-rate-detail :showModal="showModalRateIntegrationDetail" :data="this.detailParams"
              @closeModal="closeMyModal">
            </modal-rate-detail>
          </div>
        </div>
        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";
import ModalRateIntegrationDetail from "@/components/modal/modalRateIntegrationDetail.vue";
export default {
  name: "NewRate",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "modal-rate-detail": ModalRateIntegrationDetail,
    DateRangePicker,
  },
  data() {
    const startDate = moment().subtract(7, "d").format("YYYY-MM-DD hh:mm:ss");
    //const startDate = new Date();
    const endDate = moment(Date.now() + 7 * 24 * 3600 * 1000).format(
      "YYYY-MM-DD"
    );
    return {
      showModalRateIntegrationDetail: false,
      locale: {
        format: "yyyy-mm-dd",
      },
      dateRange: {
        startDate,
        endDate,
      },
      opens: "center",
      closeBtn: true,
      isLoading: false,
      errors: [],
      detailParams: [],
      permision_role: [],
      serverParams: {
        columnFilters: {},
        sort: {
          field: "send_at",
          type: "desc",
        },
        page: 1,
        per_page: 10,
      },
      totalRecords: "",
      columns: [
        {
          label: "Action",
          field: "actions",
          sortable: false,
        },
        {
          label: "Type",
          field: "type",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Type ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Customer",
          field: "customer",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Customer ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Status",
          field: "status",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Rating Status ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Send Date",
          field: "send_at",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Send Date ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
      ],
      rows: [],
    };
  },
  methods: {
    loadItems() {
      this.fade(true);
      const baseURI = this.$settings.endPoint + "rm-billable-rate-sf/grouping";
      var startDate = this.$moment(this.dateRange.startDate).format(
        "YYYY-MM-DD"
      );
      var endDate = this.$moment(this.dateRange.endDate).format("YYYY-MM-DD");
      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&start=${startDate}&end=${endDate}&type=${this.serverParams.columnFilters.type}&customer=${this.serverParams.columnFilters.customer}&status=${this.serverParams.columnFilters.status}&send_at=${this.serverParams.columnFilters.send_at}`
        )
        .then((response) => {
          this.loading();
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
        });
    },
    closeMyModal() {
      this.showModalRateIntegrationDetail = false;
      this.loadItems();
    },
    detailData(index, row) {
      this.detailParams = row;
      this.detailParams.start = this.$moment(this.dateRange.startDate).format(
        "YYYY-MM-DD"
      );
      this.detailParams.end = this.$moment(this.dateRange.endDate).format(
        "YYYY-MM-DD"
      );

      this.showModalRateIntegrationDetail = true;
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RmBillableRateSfController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },
    downloadData() {
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = "download_" + CurrentDate + ".xlsx";
      this.fade(true);
      const baseURI = this.$settings.endPoint + "rm-billable-rate-sf/grouping";
      var startDate = this.$moment(this.dateRange.startDate).format(
        "YYYY-MM-DD"
      );
      var endDate = this.$moment(this.dateRange.endDate).format("YYYY-MM-DD");
      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&start=${startDate}&end=${endDate}&type=${this.serverParams.columnFilters.type}&customer=${this.serverParams.columnFilters.customer}&status=${this.serverParams.columnFilters.status}&send_at=${this.serverParams.columnFilters.send_at}&download=download`
        )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.loading();
        });
    },
    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    successSend(index, row) {
      // console.log(row);
      this.$swal({
        icon: "warning",
        backdrop: true,
        title: "Warning",
        html: '<p>Customer :' + row.customer + ', has been successfully sent</p>',
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },
  },
  mounted() {
    this.fetchIt();
    this.loading();
  },
};
</script>

<style></style>