<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="CompanyEdit"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            "
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start">
                    {{ $t("locationEdit") }}
                  </h6>
                  <button
                    class="btn btn-default float-end"
                    @click="resetForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>

                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->
                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "
                    ></div>

                    <div class="card">
                      <div class="card-body">
                        <h5 class="General">General</h5>
                        <hr>
                          <div class="row mb-3">
                           <div class="col-lg-2">
                            <label for="defaultconfig" class="col-form-label">Type<span class="wajib">*</span></label>
                          </div>
                          <div class="col-lg-3">
                           <div class="form-check form-check-inline">
                            <input type="radio" value="a" class="form-check-input" v-model="forms.type_sl" id="radioA">
                            <label class="form-check-label" for="radioA">
                              A - REGION
                            </label>
                          </div>
                           <div class="form-check form-check-inline">
                            <input type="radio" value="b"  class="form-check-input" v-model="forms.type_sl" id="radioB">
                            <label class="form-check-label" for="radioB">
                              B - Stop Location
                            </label>
                          </div>
                            <div v-if="errors.location_code">
                              <div
                                v-for="error in errors.location_code"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label for="defaultconfig" class="col-form-label">{{
                              $t("locationCodeTxt")
                            }} <span class="wajib">*</span></label>
                          </div>
                          <div class="col-lg-3">
                            <input
                              type="text"
                              class="form-control"
                              id="sku_code"
                              :placeholder="$t('locationCodeTxt')"
                              v-model="forms.location_code"
                              required
                            />
                            <div v-if="errors.location_code">
                              <div
                                v-for="error in errors.location_code"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-1">
                            <label for="defaultconfig" class="col-form-label">{{
                              $t("locationNameTxt")
                            }} <span class="wajib">*</span></label>
                          </div>
                          <div class="col-lg-4">
                            <input
                              type="text"
                              class="form-control"
                              id="name"
                              :placeholder="$t('locationNameTxt')"
                              v-model="forms.name"
                              @keyup="loadSuggestion"
                              @blur="removeSuggestion"
                              @focus="loadSuggestion"
                            />
                            <div v-if="errors.name">
                              <div
                                v-for="error in errors.name"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-2"  v-if="suggestionData.length">
                            <div
                              class="suggestionData" style="max-width:300px;"
                            >
                              <ul class="list-group">
                                <li
                                  class="list-group-item"
                                  v-for="(item, index) in suggestionData"
                                  :key="index"
                                >
                                  {{ item.name }}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label for="defaultconfig" class="col-form-label"
                              >Int Code <span class="wajib">*</span></label
                            >
                          </div>
                          <div class="col-lg-3">
                            <input
                              type="text"
                              class="form-control"
                              id="int_code"
                              placeholder=""
                              v-model="forms.int_code"
                            />
                            <div v-if="errors.int_code">
                              <div
                                v-for="error in errors.int_code"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-1">
                            <label for="defaultconfig" class="col-form-label"
                              >Type</label
                            >
                          </div>
                          <div class="col-lg-4">
                            <!-- <input
                              type="text"
                              class="form-control"
                              id="type"
                              placeholder=""
                              v-model="forms.type"
                            /> -->
                            <v-select
                              :options="fetchSystemCode"
                              v-model="type"
                              label="description"
                              return-object
                              @search="asyncSearchSystemCode"
                            ></v-select>

                            <div v-if="errors.type">
                              <div
                                v-for="error in errors.type"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label for="defaultconfig" class="col-form-label">{{
                              $t("companyCode")
                            }} <span class="wajib">*</span></label>
                          </div>
                          <div class="col-lg-3">
                            <!-- <select
                              class="form-control"
                              name="company_id"
                              id="company_id"
                              v-model="forms.company_id"
                            >
                              <option
                                v-for="(a, index) of fetchCompany"
                                :value="a.company_id"
                                :key="index"
                              >
                                {{ a.code }} | {{ a.name }}
                              </option>
                            </select> -->

                            <v-select
                              v-if="companyCode == 'LINC'"
                              :options="fetchCompany"
                              v-model="company"
                              label="code"
                              return-object
                              @search="asyncSearchCompany"
                            ></v-select>

                            <v-select
                              v-if="companyCode != 'LINC'"
                              :options="fetchCompanyNotLinc"
                              v-model="company"
                              label="code"
                              return-object
                              @search="asyncSearchCompanyNotLinc"
                            ></v-select>

                            <div v-if="errors.company_id">
                              <div
                                v-for="error in errors.company_id"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-1">
                            <label for="defaultconfig" class="col-form-label">{{
                              $t("regionTxt")
                            }} <span class="wajib">*</span></label>
                          </div>
                          <div class="col-lg-4">
                            <!-- <select
                              class="form-control"
                              name="region_id"
                              id="region_id"
                              v-model="forms.region_id"
                            >
                              <option
                                v-for="(a, index) of fetchRegion"
                                :value="a.region_id"
                                :key="index"
                              >
                                {{ a.region_code }}
                              </option>
                            </select> -->
                            <v-select
                              :options="fetchRegion"
                              v-model="region"
                              label="region_code"
                              return-object
                              @search="asyncSearchRegion"
                            ></v-select>

                            <div v-if="errors.region_id">
                              <div
                                v-for="error in errors.region_id"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-2">
                            <span class="btn btn-danger" @click.prevent="openModal()">Browse Region</span>
                          </div>

                        </div>
                      </div>
                    </div>

                    <br />

                    <!-- <div class="card">
                      <div class="card-body">
                        <h5 class="General">Contact</h5>
                        <hr>
                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label for="defaultconfig" class="col-form-label">{{
                              $t("locationContactName")
                            }}</label>
                          </div>
                          <div class="col-lg-3">
                            <input
                              type="text"
                              class="form-control"
                              id="contact_name"
                              placeholder=""
                              v-model="forms.contact_name"
                            />
                            <div v-if="errors.contact_name">
                              <div
                                v-for="error in errors.contact_name"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-1">
                            <label for="defaultconfig" class="col-form-label">{{
                              $t("locationPhoneNumber")
                            }}</label>
                          </div>
                          <div class="col-lg-4">
                            <input
                              type="text"
                              class="form-control"
                              id="phone_number"
                              placeholder=""
                              v-model="forms.phone_number"
                            />
                            <div v-if="errors.phone_number">
                              <div
                                v-for="error in errors.phone_number"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <br /> -->

                    <div class="card">
                      <div class="card-body">
                        <h5 class="General">Address</h5>
                        <hr>
                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              
                              >{{ $t("countryTxt") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <v-select
                              :options="fetchCountryCode"
                              v-model="country"
                              label="country"
                              return-object
                              @input="contryCodeChange"
                            ></v-select>
                            <div v-if="errors.country">
                              <div
                                v-for="error in errors.country"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              
                              >{{ $t("provinceTxt") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <v-select
                              :options="featchProvinceCode"
                              v-model="province"
                              @input="provinceCodeChange"
                              label="province"
                              return-object
                            ></v-select>
                            <div v-if="errors.province">
                              <div
                                v-for="error in errors.province"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              
                              >{{ $t("cityTxt") }}</label
                            >
                          </div>
                          <div class="col-lg-4">
                            <v-select
                              :options="featchCityCode"
                              v-model="city"
                              label="city"
                              return-object
                              @input="cityChange"
                            ></v-select>
                            <div v-if="errors.city">
                              <div
                                v-for="error in errors.city"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <input
                              type="text"
                              class="form-control"
                              id="city_type"
                              :placeholder="$t('cityTypeTxt')"
                              v-model="city_type"
                            />
                            <div v-if="errors.city_type">
                              <div
                                v-for="error in errors.city_type"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              
                              >{{ $t("districtTxt") }}</label
                            >
                          </div>
                          <div class="col-lg-4">
                            <v-select
                              :options="featchDistrictCode"
                              v-model="district"
                              label="district"
                              return-object
                              @input="districtCodeChange"
                            ></v-select>
                            <div v-if="errors.district">
                              <div
                                v-for="error in errors.district"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <v-select
                              :options="featchSubDistrictCode"
                              v-model="sub_district"
                              @input="subDistrictChange"
                              label="sub_district"
                              return-object
                            ></v-select>
                            <div v-if="errors.sub_district">
                              <div
                                v-for="error in errors.sub_district"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              
                              >{{ $t("postalCodeTxt") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <v-select
                              :options="fetchPostalCode"
                              v-model="postal_code"
                              @search="asyncGeneratePostalCode"
                              label="postal_code"
                              @input="postalCodeChange"
                              return-object
                            ></v-select>

                            <div v-if="errors.postal_code">
                              <div
                                v-for="error in errors.postal_code"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label for="defaultconfig" class="col-form-label"
                              >{{ $t("address") }} 1</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text"
                              class="form-control"
                              id="address1"
                              :placeholder="$t('address')"
                              v-model="forms.address1"
                            />
                            <div v-if="errors.address1">
                              <div
                                v-for="error in errors.address1"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label for="defaultconfig" class="col-form-label"
                              >{{ $t("address") }} 2</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text"
                              class="form-control"
                              id="address2"
                              :placeholder="$t('address')"
                              v-model="forms.address2"
                            />
                            <div v-if="errors.address2">
                              <div
                                v-for="error in errors.address2"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label for="defaultconfig" class="col-form-label"
                              >{{ $t("address") }} 3</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text"
                              class="form-control"
                              id="address3"
                              :placeholder="$t('address')"
                              v-model="forms.address3"
                            />
                            <div v-if="errors.address3">
                              <div
                                v-for="error in errors.address3"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label for="defaultconfig" class="col-form-label"
                              >Latitude</label
                            >
                          </div>
                          <div class="col-lg-3">
                            <input
                              type="text"
                              class="form-control"
                              id="latitude"
                              placeholder=""
                              v-model="forms.latitude"
                            />
                            <div v-if="errors.latitude">
                              <div
                                v-for="error in errors.latitude"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-1">
                            <label for="defaultconfig" class="col-form-label"
                              >Longitude</label
                            >
                          </div>
                          <div class="col-lg-4">
                            <input
                              type="text"
                              class="form-control"
                              id="longitude"
                              placeholder=""
                              v-model="forms.longitude"
                            />
                            <div v-if="errors.longitude">
                              <div
                                v-for="error in errors.longitude"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label for="defaultconfig" class="col-form-label"
                              >Radius <span class="wajib">*</span></label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text"
                              class="form-control"
                              id="radius"
                              placeholder=""
                              v-model="forms.radius"
                            />
                            <div v-if="errors.radius">
                              <div
                                v-for="error in errors.radius"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />

                    <div class="card-footer">
                      <button
                        class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        "
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button
                        class="btn btn-primary float-end btn-sm"
                        type="submit"
                      >
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <modal-region :showModal="showModalRegion"  @closeModal="closeMyModal">
          </modal-region>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import myMixinAddress from '@/middleware/mixinAddress.js';
import modalRegion from "@/components/modal/modalRegion.vue";


export default {
  name: "LocationEdit",
  mixins: [myMixinAddress],
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "modal-region":modalRegion
  },
  data() {
    return {
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      permision_role: [],
      fetchCompany: [],
      fetchRegion: [],
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      company: [],
      region: [],
      postalCode: [],
      showModalRegion:false,
      // countryCode: [],
      // provinceCode: [],
      // cityCode: [],
      // districtCode: [],
      // subDistrictCode: [],
      fetchSystemCode: [],
      fetchPostalCode: [],
      fetchCountryCode: [],
      featchProvinceCode: [],
      featchCityCode: [],
      featchDistrictCode: [],
      featchSubDistrictCode: [],
      featchCompany: [],
      forms: [],
      postal_code: [],
      country: [],
      province: [],
      city: [],
      district: [],
      sub_district: [],
      city_type: "",
      type:"",

      suggestionData: [],

    };
  },
  watch: {
    // city: {
    //   handler(newVal) {
    //     if (newVal) {
    //       this.forms.address3 = newVal.city ? newVal.city : newVal;
    //     } else {
    //       this.forms.address3 = "";
    //     }
    //   },
    // },
    // sub_district: {
    //   handler(newVal) {
    //     if (newVal) {
    //       this.forms.address2 = newVal.sub_district
    //         ? newVal.sub_district
    //         : newVal;
    //     } else {
    //       this.forms.address2 = "";
    //     }
    //   },
    // },
  },
  methods: {
    loadSuggestion() {
      if (!this.forms.name || this.forms.name == "") {
        this.suggestionData = [];
      } else {
        const baseURI =
          this.$settings.endPoint +
          "location?limit=10&name=" +
          this.forms.name;

        return this.$http.get(baseURI).then((response) => {
          this.suggestionData = response.data.datas.data;
        });
      }
    },
    removeSuggestion(){
      this.suggestionData = [];
    },
    cityChange(ev){
      this.cityCodeChange(ev);
      this.forms.address3 = ev.city;
    },
    subDistrictChange(ev){
      this.subDistrictCodeChange(ev);
      this.forms.address2 = ev.sub_district;
    },
    resetForm() {
      this.forms.location_code = "";
      this.forms.name = "";
      this.forms.int_code = "";
      this.forms.company_id = "";
      this.forms.region_id = "";
      this.forms.country = "";
      this.forms.city = "";
      this.forms.province = "";
      this.forms.district = "";
      this.forms.sub_district = "";
      this.forms.postal_code = "";
      this.forms.address1 = "";
      this.forms.address2 = "";
      this.forms.address3 = "";
      this.forms.latitude = "";
      this.forms.longitude = "";
      this.forms.radius = "";
      this.forms.type = "";
      this.forms.contact_name = "";
      this.forms.phone_number = "";
      this.country = [];
      this.region = [];
      this.type=[];
      // this.postalCode = [];
      // this.countryCode = [];
      // this.provinceCode = [];
      // this.cityCode = [];
      // this.districtCode = [];
      // this.subDistrictCode = [];
      this.country = [];
      this.province = [];
      this.city = [];
      this.district = [];
      this.sub_district = [];
      this.postal_code = [];
      this.city_type = "";
    },

 
    backForm() {
      window.location.href = "/location";
    },

    loadData(id) {
      const baseURI = this.$settings.endPoint + "location/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        this.forms = response.data.datas;
        this.company=this.forms.company;
        this.region=this.forms.region;
        this.country = this.forms.country;
        this.province = this.forms.province;
        this.city = this.forms.city;
        this.district = this.forms.district;
        this.sub_district = this.forms.sub_district;   
        this.postal_code = this.forms.postal_code;
        this.type=this.forms.system_code;
        this.loadProvinceCode(this.country);
        this.loadCityCode(this.province);
        this.loadDistrictCode(this.city);
        this.loadSubDistrictCode(this.district);
        this.loadPostalCodeOne(this.sub_district);


        // this.form={ name: 'asep', usernmae:res.username };
      });
    },

    loadCompany() {
      const baseURI = this.$settings.endPoint + "company?limit=10";

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },

    loadCompanyNotLinc() {
      const baseURI =
        this.$settings.endPoint + `company/select`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },

    asyncSearchCompany(ev) {
      const baseURI = this.$settings.endPoint + "company?code=" + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },



    loadSystemCode() {
      const baseURI = this.$settings.endPoint + "system-code?system_code=LOCATION_TYPE";
      return this.$http.get(baseURI).then((response) => {
        this.fetchSystemCode = response.data.datas.data;
      });
    },

    asyncSearchSystemCode(ev) {
      const baseURI = this.$settings.endPoint + "system-code?system_code=LOCATION_TYPE&description=" + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchRegion = response.data.datas.data;
      });
    },

    asyncSearchCompanyNotLinc(ev) {
      const baseURI =
        this.$settings.endPoint +
        "company/select?code="+ev
        this.companyCode;
      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },

    asyncSearchRegion(ev) {
      const baseURI = this.$settings.endPoint + "region?region_code=" + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchRegion = response.data.datas.data;
      });
    },

    loadRegion() {
      const baseURI = this.$settings.endPoint + "region?limit=10";

      return this.$http.get(baseURI).then((response) => {
        this.fetchRegion = response.data.datas.data;
      });
    },


    
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      this.companyCode = this.detailUser.company_detail.code;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "LocationController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_update) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    closeMyModal(ev) {
      if (ev !== null) {
        console.log(ev);
        this.region={
          region_id:ev.region_id,
          region_code:ev.region_code
        }
        this.showModalRegion=false;
      }else{
        this.showModalRegion = false;
      }
    },


    openModal() {
      this.showModalRegion = true;
    },


    submitData() {
      this.fade(true);
      this.forms.company_id = this.company.company_id;
      this.forms.region_id = this.region.region_id;

      this.forms.country = this.country;
      this.forms.province = this.province;
      this.forms.city = this.city;
      this.forms.district = this.district;
      this.forms.sub_district = this.sub_district;
      this.forms.city_type = this.city_type;
      this.forms.postal_code = this.postal_code;
      this.forms.type=this.type.value;
      // this.forms.sob='NEW';
      // let params = this.$onBehind(this.$route.params.id);
      // let formData = new FormData();
      // formData.append("name", this.forms.name);
      // formData.append("username", this.forms.username);
      // formData.append("password", this.forms.password);
      // formData.append("email", this.forms.email);
      // formData.append("company_id", this.forms.company_id);
      // formData.append("role_id", this.forms.role_id);
      // formData.append("status", 'active');

      const baseURI =
        this.$settings.endPoint + "location/update/" + this.forms.location_id;
      this.$http
        .patch(baseURI, this.forms)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            this.success(response.data.datas.messages);

            //window.history.back();
            var params = this.$onRandom(this.forms.location_id);
            window.location.href =
              "/location/detail/" + params + "?status=200&msg=Successfully";
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.$router.push("/server-error");
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.loadData(params[0]);
    this.fetchIt();
    this.loadRegion();
    this.loadCompany();
    this.loadCountryCode();
    this.loadSystemCode();
  },
};
</script>
<style scoped>
.suggestionData {
  position: absolute;
  z-index: 10;
}
</style>
