
import LookupCode from "@/components/master/lookup_code/LookupCode.vue";
import LookupCodeAdd from "@/components/master/lookup_code/LookupCodeAdd.vue";
import LookupCodeDetail from "@/components/master/lookup_code/LookupCodeDetail.vue";
import LookupCodeEdit from "@/components/master/lookup_code/LookupCodeEdit.vue";
import LookupCodeUpload from "@/components/master/lookup_code/LookupCodeUpload.vue";



let routesLookupCode= [
    
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "LookupCodeIndex",
        component: LookupCode
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "LookupCodeAdd",
        component: LookupCodeAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "LookupCodeEdit",
        component: LookupCodeEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "LookupCodeDetail",
        component: LookupCodeDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "LookupCodeUpload",
        component: LookupCodeUpload
      },

      
  ]
  
  export default routesLookupCode
  