<template>
  <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title badge bg-primary">Region</h5>

          <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal',null)"
            aria-label="btn-close"></button>
        </div>

        
          <div class="modal-body">
            <vue-good-table title="list-region" mode="remote" @on-page-change="onPageChange"
              @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange"
              :totalRows="totalRecords" :line-numbers="true" :isLoading.sync="isLoading" :pagination-options="{
                enabled: true,
              }" :rows="rows" :columns="columns">

              <template slot="table-row" slot-scope="props">

                <span v-if="props.column.field == 'actions'">
                  <a href="#"                   
                    title="Insert" @click.prevent="submitParent(props.index, props.row)">
                    <i class="mdi mdi-check" style="font-size:16px"></i>

                  </a>
                </span>
              </template>

            </vue-good-table>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal',null)">
              {{ $t("close_txt") }}
            </button>
          </div>
       
      </div>
    </div>
  </div>
</template>

<script>


export default {
  components: {
  },
  name: "ModalRegion",
  emits: ["closeModal"],
  props: {
    showModal: Boolean,
    data: {},
  },
  watch: {
    showModal: {
      handler(newVal) {
        this.active = newVal;
        this.loadItems();
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");

      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      active: this.showModal,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [
        {
          label: "Region Code",
          field: "region_code",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Region Code", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Description",
          field: "region_description",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Description", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Country",
          field: "country",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Country", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Province",
          field: "province",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Province", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "City",
          field: "city",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By City", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "District",
          field: "district",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By District", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Sub District",
          field: "sub_district",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Sub District", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Postal Code",
          field: "postal_code",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Postal Code", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Action",
          field: "actions",
          sortable: false,
        },
      ],
      rows: [],
      rowsSelection: [],
      locale: [],
      rules: {
        select: [(v) => !!v || "Item is required"]
      }

    };
  },
  methods: {
    submitParent(index, row){
      this.$emit("closeModal", row);   
    },

    removeOrderHeaderIds() {
      this.transport_order_header_ids = [];
      this.$refs['list-transport-orders'].unselectAllInternal()
      // console.log(this.$refs['list-transport-orders'].selectedRows)
    },

    selectionChanged(ev) {
      this.rowsSelection = [];
      this.transport_order_header_ids = [];
      this.rowsSelection = ev.selectedRows;

      this.rowsSelection.forEach((item) => {
        this.transport_order_header_ids.push(item.transport_order_header_id);

      });

    },

    loadItems() {
      const baseURI = this.$settings.endPoint + "region";

      //var columnFilters = this.serverParams.columnFilters;

      return this.$http
        .get(
          baseURI +
            `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&region_code=${this.serverParams.columnFilters.region_code}&region_description=${this.serverParams.columnFilters.region_description}&country=${this.serverParams.columnFilters.country}&province=${this.serverParams.columnFilters.province}&city=${this.serverParams.columnFilters.city}&district=${this.serverParams.columnFilters.district}&sub_district=${this.serverParams.columnFilters.sub_district}&postal_code=${this.serverParams.columnFilters.postal_code}`
        )
        .then((response) => {
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
        });
    },


    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

        onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },
  },
  mounted() {
    //this.loadItems();
    // this.rows = this.props.data;

  },
};
</script>

<style scoped>
.blur-content {
  filter: blur(5px);
  pointer-events: none;
}

.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 120%;
  height: 100%;
  margin-left: -10%;
}
</style>