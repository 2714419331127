import Bbm from "@/components/master/bbm/Bbm.vue";
import BbmAdd from "@/components/master/bbm/BbmAdd.vue";
import BbmDetail from "@/components/master/bbm/BbmDetail.vue";
import BbmEdit from "@/components/master/bbm/BbmEdit.vue";
import BbmUpload from "@/components/master/bbm/BbmUpload.vue";


let routesBbm= [
    
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "BbmIndex",
        component: Bbm
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "BbmAdd",
        component: BbmAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "BbmEdit",
        component: BbmEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "BbmDetail",
        component: BbmDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "BbmUpload",
        component: BbmUpload
      },
  ]
  
  export default routesBbm
  