<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="RasioDetail"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left: 25px">
                    Detail Rasio
                  </h6>

                  <button class="btn btn-light float-end" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    <i class="link-icon" data-feather="info"></i>
                    Audit Info
                  </button>

                  <div class="row mb-3 collapse" id="collapseExample" style="padding-left: 20px">
                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created By:</label>
                      <p class="text-muted">{{ this.forms.create_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created At:</label>
                      <p class="text-muted">{{ this.forms.created_at }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated By:</label>
                      <p class="text-muted">{{ this.forms.update_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated At:</label>
                      <p class="text-muted">{{ this.forms.updated_at }}</p>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form class="forms-sample" @submit.prevent="submitData" method="POST">
                    <div class="d-flex justify-content-between align-items-baseline mb-2"></div>

                    <div class="row">
                      <div class="col-lg-6">
                        <label for="defaultconfig" class="col-form-label">Kode Rasio</label>
                        <input type="text" class="form-control" id="ratio_kode" readonly v-model="forms.ratio_kode"
                          required />
                        <div v-if="errors.ratio_kode">
                          <div v-for="error in errors.ratio_kode" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <label for="defaultconfig" class="col-form-label">Golongan</label>
                        <input type="text" class="form-control" id="ratio_kode" readonly v-model="forms.class" required />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <label for="defaultconfig" class="col-form-label">Nama Rasio</label>
                        <input type="text" class="form-control" id="ratio_name" readonly v-model="forms.ratio_name"
                          required />
                        <div v-if="errors.ratio_name">
                          <div v-for="error in errors.ratio_name" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <label for="defaultconfig" class="col-form-label">Komisi Supir</label>
                        <CurrencyInput v-model.number="forms.driver_commision" :options="{ currency: 'IDR' }"
                          class="form-control" placeholder="Komisi Supir" readonly />
                        <div v-if="errors.driver_commision">
                          <div v-for="error in errors.driver_commision" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <label for="defaultconfig" class="col-form-label">Cabang</label>

                        <input type="text" class="form-control" id="ratio_kode" readonly v-model="forms.branch_name"
                          required />

                        <div v-if="errors.branch_id">
                          <div v-for="error in errors.branch_id" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <label for="defaultconfig" class="col-form-label">Deposit</label>
                        <CurrencyInput v-model.number="forms.driver_deposit" :options="{ currency: 'IDR' }"
                          class="form-control" placeholder="Deposit" readonly />
                        <div v-if="errors.driver_deposit">
                          <div v-for="error in errors.driver_deposit" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-6">
                        <label for="defaultconfig" class="col-form-label">Kategory Truck</label>

                        <input type="text" class="form-control" id="truck_category" readonly
                          v-model="forms.truck_category" required />

                        <div v-if="errors.truck_category">
                          <div v-for="error in errors.truck_category" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <label for="defaultconfig" class="col-form-label">Self Insurance</label>
                        <!-- <input
                          type="number"
                          class="form-control"
                          id="driver_self_insurance"
                          v-model="forms.driver_self_insurance"
                          readonly
                        /> -->
                        <CurrencyInput v-model.number="forms.driver_self_insurance" :options="{ currency: 'IDR' }"
                          class="form-control" placeholder="Self Insurance" readonly />
                        <div v-if="errors.driver_self_insurance">
                          <div v-for="error in errors.driver_self_insurance" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12">
                        <div class="row card">
                          <div class="card-header">
                            <h5 class="float-start">
                              Rasio <span class="wajib">*</span>
                            </h5>

                            <!-- <button class="
      btn btn-success btn-icon
      text-white
      fw-bolder
      btn-icon-text
      float-end
    " @click="addField('rasio')" type="button">
        <i class="link-icon" data-feather="plus"></i>
      </button> -->
                          </div>

                          <div class="card-body">
                            <div class="table-responsive">
                              <table class="table" style="height: 300px">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th style="width: 500px">Nama Rasio</th>
                                    <th>Rasio</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(input, index) in this
                                    .detailAllRatio" :key="`rasio-${index}`">
                                    <th>{{ index + 1 }}</th>

                                    <td width="200px">
                                      {{ input.name }}
                                    </td>

                                    <td width="200px">
                                      {{ input.ratio }}
                                    </td>
                                  </tr>
                                </tbody>

                                <tfoot></tfoot>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-lg-6">
  <div class="row card" style="margin-left: 5px;">
    <div class="card-header">
      <h5 class="float-start">
        Biaya Kapal <span class="wajib">*</span>
      </h5>

      <! -- <button class="
      btn btn-success btn-icon
      text-white
      fw-bolder
      btn-icon-text
      float-end
    " @click="addField('kapal')" type="button">
        <i class="link-icon" data-feather="plus"></i>
      </button> -- >
    </div>

    <div class="card-body">
      <div class="table-responsive">
        <table class="table" style="height: 300px">
          <thead>
            <tr>
              <th>#</th>
              <th style="width:500px">Nama Penyebrangan</th>
              <th>Biaya Kapal (Rp)</th>
            
            </tr>
          </thead>
          <tbody>

            <tr v-for="(input, index) in this.detailAllKapal" :key="`kapal-${index}`">
              <th>{{ index + 1 }}</th>



              <td width="200px">

                {{ input.name }}
              </td>


              <td width="200px">
                {{ input.price }}

              </td>

           
            </tr>
          </tbody>

          <tfoot></tfoot>
        </table>
      </div>
    </div>
  </div>
</div> -->
                    </div>

                    <div class="card-footer">
                      <button v-if="!isLoadingCalculate" class="btn btn-warning text-black fw-bolder btn-icon-text" @click="backForm" type="button">
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>

                      <a v-if="permision_role.method_delete && !isLoadingCalculate"
                        class="btn btn-danger text-white fw-bolder btn-icon-text float-end" @click="deleteData()">
                        <i class="link-icon" data-feather="trash"></i>
                        {{ $t("deleteMess") }}
                      </a>

                      <a v-if="permision_role.method_update && !isLoadingCalculate"
                        class="btn btn-primary me-2 text-white fw-bolder btn-icon-text float-end" @click="editData()">
                        <i class="link-icon" data-feather="edit"></i>
                        {{ $t("editMess") }}
                      </a>

                      <div v-if="isLoadingCalculate" class="d-flex align-items-center">
                        <div
                          class="spinner-border ms-auto"
                          role="status"
                          aria-hidden="true"
                        ></div>
                        <strong>{{ $t("loadingTxt") }}...</strong>
                      </div>

                      <span v-if="!isLoadingCalculate" class="btn btn-success float-end btn-sm" style="margin-right: 5px" @click="calculate()">
                        <i class="mdi mdi-calculator"></i>
                        Calculate
                      </span>

                    </div>
                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import CurrencyInput from '@/components/_partials/inputCurrency.vue';

export default {
  name: "PackageDetail",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    CurrencyInput
  },
  data() {
    return {
      detailAllRatio: [],
      detailAllKapal: [],
      permision_role: [],
      isLoading: false,
      isLoadingCalculate:false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      fetchCompany: [],
      fetchCompanyNotLinc: [],
      fetchGolongan: [
        {
          name: 1,
        },
        {
          name: 2,
        },
        {
          name: 3,
        },
        {
          name: 4,
        },
        {
          name: 5,
        },
      ],
      golongan: "",
      fetchCabang: [],
      fetchCategoryTruck: [],
      truck_category: "",
      cabang: "",
      forms: {},
    };
  },
  watch: {},
  methods: {
    resetForm() {
      this.forms.package_code = "";
      this.forms.package_name = "";
      this.forms.company_id = "";
      this.forms.cs_code = "";
      this.forms.cs_qty = "";
      this.forms.ip_code = "";
      this.forms.ip_qty = "";
      this.forms.ot_code = "";
      this.forms.ot_qty = "";
      this.forms.pl_code = "";
      this.forms.pl_qty = "";
      this.forms.pcs_code = "";
    },

    cek_message(status, msg) {
      if (status == "200") {
        this.success(msg);
      }
    },

    editData() {
      var params = this.$route.params.id;
      window.location.href = "/master-rasio/edit/" + params;
    },

    deleteData() {
      var id = this.$onBehind(this.$route.params.id);
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI =
            this.$settings.endPoint + "rm-ratio-master/delete/" + id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                window.location.href = "/master-rasio";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    loadCompany() {
      const baseURI = this.$settings.endPoint + "company?limit=100";

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },

    loadData(id) {
      const baseURI = this.$settings.endPoint + "rm-ratio-master/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        this.forms = response.data.datas;

        this.detailAllKapal = this.forms.ferry;
        this.detailAllRatio = this.forms.detail;

        // this.form={ name: 'asep', usernmae:res.username };
      });
    },

    loadCompanyNotLinc() {
      const baseURI =
        this.$settings.endPoint + `company?code=${this.companyCode}`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      this.companyCode = this.detailUser.company_detail.code;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RmRatioMasterController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },

    backForm() {
      window.location.href = "/master-rasio";
    },

    submitData() {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourData"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          this.fade(true);

          //let formData = new FormData();

          const baseURI = this.$settings.endPoint + "package/edit";
          this.$http
            .patch(baseURI, this.forms)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.resetForm();
                this.errors = [];
                //console.log(response.data.datas.user_id);
                var params = this.$onRandom(response.data.datas.package_id);
                window.location.href = "/package/detail/" + params;
                // this.success('Berhasil');
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
              this.resetForm();
            });
        }
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },


    calculate() {
      this.$swal({
        title: "Apakah Anda Yakin akan mengkalkulasi RASIO ?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
        html: `<p>Semua Record Payable akan mengalami kenaikan sesuai dengan exponensial kenaikan prubahan rasio</p><br><input type="checkbox" id="kalkulasiRekon" > <label for="kalkulasiRekon">Kalkulasi Rekon</label>`
      }).then((result) => {
       
        const checkbox = document.getElementById('kalkulasiRekon');
        this.rekon = checkbox.checked;

        // console.log(result);
        if (result.isConfirmed) {
          this.isLoadingCalculate=true;
          this.fade(true);
          const baseURI =
            this.$settings.endPoint +
            "rm-ratio-master/calculation/" +
            this.forms.rm_ratio_master_id;
          this.$http
            .patch(baseURI, {
              "rekon": this.rekon == true ? 'yes' : 'no', // yes or no
              // "link_page_pay_rate_archiving_detail_id" : window.location.origin+"/payable-rate/archiving/detail/"+encode
            })
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.isLoadingCalculate=false;
                //this.resetForm();
                this.errors = [];
                this.success(response.data.datas.messages);

                //window.history.back();
                this.loadData(this.forms.rm_ratio_master_id);
                // var params = this.$onRandom(this.forms.rm_fuel_master_id);
                // window.location.href =
                //   "/master-bbm/detail/" + params + "?status=200&msg=Successfully";
              } else {
                this.isLoadingCalculate=false;
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              this.isLoadingCalculate=false;
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  // this.resultError(error.response.data.errors);
                  if (this.errors.message) {
                    this.error(this.errors.message);
                  } else {
                    this.resultError(error.response.data.errors);
                  }
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
              //this.resetForm();
            });

        }

      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () { },
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.cek_message(this.$route.query.status, this.$route.query.msg);
    this.loadData(params[0]);
    this.fetchIt();
    this.loadCompany();
    this.loadCompanyNotLinc();
  },
};
</script>
<style scoped></style>
