<template>
  <div :class="{ 'blur-content': isLoading }">
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="Company"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 25px"
                  >
                    {{ $t("companyAccessAdd") }}
                  </h6>
                  <button
                    class="btn btn-default btn-sm float-end"
                    @click="resetForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="d-flex justify-content-between align-items-baseline mb-2"
                    ></div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("company_type_txt") }}</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <div v-if="this.userRole.name != 'superadmin'">
                          <input
                            type="text"
                            class="form-control"
                            id="company_type"
                            :placeholder="$t('company_type_txt')"
                            :value="addCompanyTypeValue()"
                            readonly
                          />
                        </div>
                        <div v-else>
                          <v-select
                            :options="fetchSystemCode"
                            v-model="company_type"
                            label="description"
                            return-object
                            @search="asyncSearchSystemCode"
                          ></v-select>
                        </div>

                        <div v-if="errors.company_type">
                          <div
                            v-for="error in errors.company_type"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("companyCode") }}</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="code"
                          :placeholder="$t('companyCode')"
                          v-model="forms.code"
                          required
                        />
                        <div v-if="errors.code">
                          <div
                            v-for="error in errors.code"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("companyName") }}</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          :placeholder="$t('companyName')"
                          v-model="forms.name"
                          required
                        />
                        <div v-if="errors.name">
                          <div
                            v-for="error in errors.name"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Email</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="country"
                          placeholder="Email"
                          v-model="forms.email"
                        />
                        <div v-if="errors.email">
                          <div
                            v-for="error in errors.email"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Sap Code</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="sap_code"
                          placeholder="SAP CODE"
                          v-model="forms.sap_code"
                        />
                        <div v-if="errors.sap_code">
                          <div
                            v-for="error in errors.sap_code"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >SF Code</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="sf_code"
                          placeholder="SF CODE"
                          v-model="forms.sf_code"
                        />
                        <div v-if="errors.sf_code">
                          <div
                            v-for="error in errors.sf_code"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          style="padding-left: 25px"
                          class="col-form-label"
                          >{{ $t("countryTxt") }}</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <v-select
                          :options="fetchCountryCode"
                          v-model="country"
                          label="country"
                          return-object
                          @input="contryCodeChange"
                        ></v-select>
                        <div v-if="errors.country">
                          <div
                            v-for="error in errors.country"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          style="padding-left: 25px"
                          class="col-form-label"
                          >{{ $t("provinceTxt") }}</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <v-select
                          :options="featchProvinceCode"
                          v-model="province"
                          @input="provinceCodeChange"
                          label="province"
                          @search="asyncLoadProvinceCode"
                          return-object
                        ></v-select>
                        <div v-if="errors.province">
                          <div
                            v-for="error in errors.province"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          style="padding-left: 25px"
                          class="col-form-label"
                          >{{ $t("cityTxt") }}</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <v-select
                          :options="featchCityCode"
                          v-model="city"
                          label="city"
                          return-object
                          @input="loadKodePos"
                        ></v-select>
                        <div v-if="errors.city">
                          <div
                            v-for="error in errors.city"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          style="padding-left: 25px"
                          class="col-form-label"
                          >{{ $t("postalCodeTxt") }}</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <v-select
                          :options="fetchPostalCode"
                          v-model="postal_code"
                          @search="asyncGeneratePostalCode"
                          label="postal_code"
                          @input="postalCodeChange"
                          return-object
                        ></v-select>

                        <div v-if="errors.postal_code">
                          <div
                            v-for="error in errors.postal_code"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card-footer">
                      <button
                        class="btn btn-warning text-black fw-bolder btn-icon-text"
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button
                        class="btn btn-primary float-end btn-sm"
                        type="submit"
                      >
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import myMixinAddress from "@/middleware/mixinAddress.js";

export default {
  name: "CompanyAdd",
  props: {},
  mixins: [myMixinAddress],
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      fetchPostalCode: [],
      fetchCountryCode: [],
      featchProvinceCode: [],
      featchCityCode: [],

      postal_code: [],
      country: [],
      province: [],
      city: [],

      permision_role: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      fetchSystemCode: [],
      company_type: "",
      userData: "",
      userRole: "",
      companyCode: "",
      forms: { name: "", usernmae: "" },
    };
  },
  watch: {},
  methods: {
    asyncSearchSystemCode() {
      const baseURI =
        this.$settings.endPoint +
        "/system-code?download=all&system_code=COMPANY_TYPE";

      return this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          this.fetchSystemCode = response.data.datas;
        })
        .catch((error) => {
          if (error.code == "ECONNABORTED") {
            this.error("Please Check Your Internet Connection");
          }
        });
    },
    loadKodePos(){
      this.postal_code = null;
      const baseURI =
        this.$settings.endPoint +
        `/postal-code?city=${this.city?.city ? this.city?.city : this.city}&country=${this.country?.country ? this.country.country : this.country}&province=${this.province?.province ? this.province.province : this.province}`
          

      return this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          this.fetchPostalCode = response.data.datas.data;
          console.log(response);
        })
        .catch((error) => {
          if (error.code == "ECONNABORTED") {
            this.error("Please Check Your Internet Connection");
          }
        });
    },
    addCompanyTypeValue() {
      this.company_type = this.userData.user_type;
      return this.userData.user_type;
    },

    resetForm() {
      this.forms.code = "";
      this.forms.name = "";
      this.forms.email = "";
      this.forms.sap_code = "";
      this.postal_code = [];
      this.country = [];
      this.city = [];
      this.province = [];
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.userData = userDatas.sub;
      this.userRole = userDatas.sub.role;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "CompanyController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },

    backForm() {
      window.location.href = "/company";
    },

    submitData() {
      this.isLoading = true;

      if (typeof this.company_type == "object") {
        this.forms.company_type = this.company_type.value;
      } else {
        this.forms.company_type = this.company_type;
      }
      this.forms.status='active';
      // this.forms.country = this.country.country ? this.country.country : this.country;
      // this.forms.city = this.city.city ? this.city.city : this.city;
      // this.forms.state = this.province.province ? this.province.province : this.province;
      // this.forms.postal_code = this.postal_code.postal_code ? this.postal_code.postal_code : this.postal_code;
      if(this.country){
        this.forms.country = this.country.country ? this.country.country : this.country;
      }else{
        this.forms.country = "";
      }
      if(this.city){
        this.forms.city = this.city.city ? this.city.city : this.city;
      }else{
        this.forms.city = "";
      }
      if(this.province){
        this.forms.state = this.province.province ? this.province.province : this.province;
      }else{
        this.forms.state = "";
      }
      if(this.postal_code){
        this.forms.postal_code = this.postal_code.postal_code ? this.postal_code.postal_code : this.postal_code;
      }else{
        this.forms.postal_code = "";
      }
     
      const baseURI = this.$settings.endPoint + "company/create";
      this.$http
        .post(baseURI, this.forms, { timeout: 10000 })
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            //this.resetForm();
            this.isLoading = false;
            this.errors = [];
            //console.log(response.data.datas.user_id);
            var params = this.$onRandom(response.data.datas.company_id);
            window.location.href =
              "/company/detail/" + params + "?status=200&msg=Successfully";
            // this.success('Berhasil');
          } else {
            this.isLoading = false;
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error("Please Check Your Internet Connection");
          }

          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.$router.push("/server-error");
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    this.fetchIt();
    this.loadPostalCode();
    this.loadCountryCode();
  },
};
</script>
<style scoped>
.blur-content {
  filter: blur(5px);
}
</style>
