<template>
    <div>
        <div class="main-wrapper">
            <!--begin::sidebar-->
            <sidebar-component></sidebar-component>
            <!--end::sidebar-->
            <div class="page-wrapper">
                <!--begin::navbar-->
                <navbar-component></navbar-component>
                <!--end::navbar-->

                <router-view />
                <!--begin::footer-->
                <footer-component></footer-component>
                <!--end::footer-->
            </div>
        </div>
    </div>
  </template>
  <script>
  
    import sidebarComponent from "@/components/_partials/_sidebar";
    import navbarComponent from "@/components/_partials/_navbar";
    import footerComponent from "@/components/_partials/_footer";
  export default {
    name: "FppkPenerimaanIndex",
    components:{
        "sidebar-component": sidebarComponent,
        "navbar-component": navbarComponent,
        "footer-component": footerComponent,
    },
    data () {
      return {}
    },
    created () {
  
    },
    methods: {
    
    },
  }
  
  </script>
  