import TraffictViolation from "@/components/dasboard-ct/traffict-violation/TraffictViolation.vue";
import TraffictViolationAdd from "@/components/dasboard-ct/traffict-violation/TraffictViolationAdd.vue";
import TraffictViolationDetail from "@/components/dasboard-ct/traffict-violation/TraffictViolationDetail.vue";
import TraffictViolationEdit from "@/components/dasboard-ct/traffict-violation/TraffictViolationEdit.vue";
import TraffictViolationUpload from "@/components/dasboard-ct/traffict-violation/TraffictViolationUpload.vue";

let routesTraffictViolation= [
      {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "TraffictViolation",
        component: TraffictViolation
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "TraffictViolationAdd",
        component: TraffictViolationAdd
      },
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "TraffictViolationEdit",
        component: TraffictViolationEdit
      },
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "TraffictViolationDetail",
        component: TraffictViolationDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "TraffictViolationUpload",
        component: TraffictViolationUpload
      },
    
]

export default routesTraffictViolation
  