<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>   
    <div class="loadertext" v-if="this.isLoading"> {{$t('loadingTxt')}}...</div>   
    <div class="main-wrapper" :class="{'blur-content': this.isLoading}">
      <!--begin::sidebar-->
      <sidebar-component classMenu="AccidentDetail"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            "
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
               <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 25px"
                  >
                     Accident Detail
                  </h6>

                  <button
                    class="btn btn-light float-end"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <i class="link-icon" data-feather="info"></i>
                    Audit Info
                  </button>

                  <div
                    class="row mb-3 collapse"
                    id="collapseExample"
                    style="padding-left: 20px"
                  >
                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created By:</label>
                      <p class="text-muted">{{ this.forms.create_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created At:</label>
                      <p class="text-muted">{{ this.forms.created_at }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated By:</label>
                      <p class="text-muted">{{ this.forms.update_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated At:</label>
                      <p class="text-muted">{{ this.forms.updated_at }}</p>
                    </div>
                  </div>
                </div>

                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->
                   <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "
                    ></div>

                    <div class="row mb-2">
                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Load ID</label>
                          </div>
                          <div class="col-md-4">
                            <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                            <input readonly type="text" id="load_number" name="load_number" v-model="forms.load_number"
                              class="form-control" placeholder="Load ID" read-only />


                            <div v-if="errors.load_number">
                              <div v-for="error in errors.load_number" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <button class="btn btn-success btn-sm" @click.prevent="openModal()"
                              style="margin-right: 1px ;">Browse</button>
                            <button class="btn btn-danger btn-sm" @click.prevent="clearLoad()">No Load</button>
                          </div>
                        </div>

                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Shipment Number</label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="order_number" name="order_number" readonly
                              v-model="forms.order_number" class="form-control" placeholder="Shipment Number" />
                            <div v-if="errors.order_number">
                              <div v-for="error in errors.order_number" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Shipment Date</label>
                          </div>
                          <div class="col-md-8">
                            <input readonly type="text" id="shipment_date" name="shipment_date"
                              v-model="forms.shipment_date" class="form-control" placeholder="Shipment Date" />
                            <div v-if="errors.shipment_date">
                              <div v-for="error in errors.shipment_date" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Vehicle No<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="vehicle_no" name="vehicle_no" v-model="forms.vehicle_no"
                              class="form-control" placeholder="Vehicle No" readonly />
                            <div v-if="errors.vehicle_no">
                              <div v-for="error in errors.vehicle_no" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Driver Name<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="driver_name" name="driver_name" v-model="forms.driver_name"
                              class="form-control" placeholder="Driver Name" readonly />
                            <div v-if="errors.driver_name">
                              <div v-for="error in errors.driver_name" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Driver NMK<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="driver_nmk" name="driver_nmk" v-model="forms.driver_nmk"
                              class="form-control" placeholder="Driver NMK" readonly />
                            <div v-if="errors.driver_nmk">
                              <div v-for="error in errors.driver_nmk" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Driver HP</label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="driver_hp" name="driver_hp" v-model="forms.driver_hp"
                              class="form-control" placeholder="Driver HP" readonly />
                            <div v-if="errors.driver_hp">
                              <div v-for="error in errors.driver_hp" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Route</label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="route" name="route" v-model="forms.route" class="form-control"
                              placeholder="Route" readonly />
                            <div v-if="errors.route">
                              <div v-for="error in errors.route" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Accident Date<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-4">
                            <input type="text" disabled id="accident_date" name="accident_date" v-model="forms.accident_date" class="form-control"
                              />

                            <!-- <Datepicker v-model="accident_date" :bootstrap-styling=true format="dd MMM yyyy"
                              placeholder="Please Select Date.."></Datepicker> -->
                             
                            <div v-if="errors.accident_date">
                              <div v-for="error in errors.accident_date" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>

                          <div class="col-md-4">
                            <input type="time" disabled id="accident_hour" name="accident_hour" v-model="forms.accident_hour" class="form-control"
                             />
                          </div>

                        </div>


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Accident Location<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="accident_location" disabled name="accident_location" v-model="forms.accident_location" class="form-control"
                              placeholder="Accident Location"  />
                            <div v-if="errors.accident_location">
                              <div v-for="error in errors.accident_location" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Vehicle Owner<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="vehicle_no" disabled name="vehicle_no" v-model="forms.vehicle_owner" class="form-control"
                              />
                            <div v-if="errors.vehicle_no">
                              <div v-for="error in errors.vehicle_owner" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Accident Area<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="accident_area" disabled name="accident_area" v-model="forms.accident_area" class="form-control"
                              />
                            <div v-if="errors.accident_area">
                              <div v-for="error in errors.accident_area" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Haul<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="haul" disabled name="haul" v-model="forms.haul" class="form-control"
                              />
                            <div v-if="errors.haul">
                              <div v-for="error in errors.haul" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-2" v-if="this.permision_role.method_custom1">
                          <div class="col-md-4">
                            <label class="form-label">Direct Cause<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="penyebab_langsung" disabled name="penyebab_langsung" v-model="forms.penyebab_langsung" class="form-control"
                              />
                            <div v-if="errors.penyebab_langsung">
                              <div v-for="error in errors.penyebab_langsung" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                          <div class="row mb-2" v-if="this.permision_role.method_custom1">
                          <div class="col-md-4">
                            <label class="form-label">Direct Cause Detail<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="penyebab_langsung" disabled name="penyebab_langsung" v-model="forms.direct_cause_detail" class="form-control"
                              />
                            <div v-if="errors.direct_cause_detail">
                              <div v-for="error in errors.direct_cause_detail" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-2" v-if="this.permision_role.method_custom1">
                          <div class="col-md-4">
                            <label class="form-label">Direct Cause Remark</label>
                          </div>
                          <div class="col-md-8">
                            <textarea class="form-control" disabled v-model="forms.keterangan_penyebab_langsung" rows="5" ></textarea>
                            <div v-if="errors.keterangan_penyebab_langsung">
                              <div v-for="error in errors.keterangan_penyebab_langsung" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Project</label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="project" disabled name="project" v-model="forms.project" class="form-control"
                              placeholder="Project"  />
                            <div v-if="errors.project">
                              <div v-for="error in errors.project" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Truck Active Date</label>
                          </div>
                          <div class="col-md-8">
                            
                              <input type="text" disabled id="active_date"  name="active_date" v-model="forms.active_date" class="form-control"
                              />
                            <div v-if="errors.active_date">
                              <div v-for="error in errors.active_date" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                      <div class="col-md-6">

                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Latitude</label>
                          </div>
                          <div class="col-md-4">
                            <input type="text" id="latitude" disabled name="latitude" v-model="forms.latitude" class="form-control"
                              placeholder=""  />
                            <div v-if="errors.latitude">
                              <div v-for="error in errors.latitude" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <button class="btn btn-success btn-sm" @click.prevent="openMap()"
                              style="margin-right: 1px ;">Browse Map</button>
                          </div>
                        </div>


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Longitude</label>
                          </div>
                          <div class="col-md-4">
                            <input type="text" id="longitude" disabled name="longitude" v-model="forms.longitude" class="form-control"
                              placeholder=""  />
                            <div v-if="errors.longitude">
                              <div v-for="error in errors.longitude" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Accident Category<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <v-select
                              :options="fetchCategory"
                              v-model="category"
                              label="name"
                              return-object
                              disabled
                            ></v-select>

                            <div v-if="errors.accident_category">
                              <div v-for="error in errors.accident_category" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Accident Damage</label>
                          </div>
                          <div class="col-md-8">
                            <v-select
                              :options="fetchKerusakan"
                              v-model="kerusakans"
                              label="name"
                              return-object
                              disabled
                            ></v-select>

                            <div v-if="errors.kerusakan">
                              <div v-for="error in errors.kerusakan" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Accident Cause<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <v-select
                              :options="fetchCaused"
                              v-model="caused"
                              label="name"
                              return-object
                              disabled
                            ></v-select>

                            <div v-if="errors.caused">
                              <div v-for="error in errors.caused" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Event Type</label>
                          </div>
                          <div class="col-md-8">
                            <v-select
                              :options="fetchTipeKejadian"
                              v-model="tipe_kejadians"
                              label="name"
                              return-object
                              disabled
                            ></v-select>

                            <div v-if="errors.tipe_kejadian">
                              <div v-for="error in errors.tipe_kejadian" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Event Type Remarks</label>
                          </div>
                          <div class="col-md-8">
                            <textarea class="form-control" v-model="forms.event_type_remark" disabled rows="3" ></textarea>
                            <div v-if="errors.event_type_remark">
                              <div v-for="error in errors.event_type_remark" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-2" v-if="this.permision_role.method_custom1">
                          <div class="col-md-4">
                            <label class="form-label">Basic Cause</label>
                          </div>
                          <div class="col-md-8">
                            <v-select
                              :options="fetchPenyebabDasar"
                              v-model="penyebab_dasars"
                              label="name"
                              return-object
                              disabled
                            ></v-select>

                            <div v-if="errors.penyebab_dasar">
                              <div v-for="error in errors.penyebab_dasar" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                       


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Total Claim<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="number" id="or_value" disabled name="or_value" v-model="forms.or_value" class="form-control"
                              />
                            <div v-if="errors.or_value">
                              <div v-for="error in errors.or_value" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Total Beban Driver (Rp.)</label>
                          </div>
                          <div class="col-md-8">
                            <input type="number" disabled id="insurance_value" name="insurance_value" v-model="forms.insurance_value" class="form-control"
                              placeholder=""  />
                            <div v-if="errors.insurance_value">
                              <div v-for="error in errors.insurance_value" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>



                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Accident Detail</label>
                          </div>
                          <div class="col-md-8">
                            <textarea class="form-control" disabled v-model="forms.accident_detail" rows="10" ></textarea>
                            <div v-if="errors.accident_detail">
                              <div v-for="error in errors.accident_detail" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Action Plan</label>
                          </div>
                          <div class="col-md-8">
                            <textarea class="form-control" disabled v-model="forms.action_plan" rows="10" ></textarea>
                            <div v-if="errors.action_plan">
                              <div v-for="error in errors.action_plan" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Case Result</label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" disabled id="case_result" name="case_result" v-model="forms.case_result" class="form-control"
                              placeholder=""  />
                            <div v-if="errors.case_result">
                              <div v-for="error in errors.case_result" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Date Complete</label>
                          </div>
                          <div class="col-md-8">
                           

                              <input type="text" disabled id="complete_date" name="complete_date" v-model="forms.complete_date" class="form-control"
                              />
                            <div v-if="errors.accident_date_complete">
                              <div v-for="error in errors.accident_date_complete" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>




                      </div>


                    </div>

                    <div class="card-footer">
                      <button
                        class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        "
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>

                      <a
                        v-if="permision_role.method_delete"
                        class="
                          btn btn-danger
                          text-white
                          fw-bolder
                          btn-icon-text
                          float-end
                        "
                        @click="deleteData()"
                      >
                        <i class="link-icon" data-feather="trash"></i>
                        {{ $t("deleteMess") }}
                      </a>

                      <a
                        v-if="permision_role.method_update"
                        class="
                          btn btn-primary
                          me-2
                          text-white
                          fw-bolder
                          btn-icon-text
                          float-end
                        "
                        @click="editData()"
                      >
                        <i class="link-icon" data-feather="edit"></i>
                        {{ $t("editMess") }}
                      </a>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
// import DateRangePicker from 'vue2-daterange-picker'
// import "vue2-daterange-picker/dist/vue2-daterange-picker.css";



export default {
  name:"AccidentDetail",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    // DateRangePicker
  },
  data() {
    const startDate = new Date();
    const endDate = new Date();
    return {
      opens:"center",
      category:'',
      fetchCategory:[],
      locale:{
        format:"dd mmm yyyy H:MM:ss",
       
      },
      caused:'',
      fetchCaused:[],

      fetchKerusakan: [],
      kerusakans:'',
      fetchTipeKejadian:[],
      tipe_kejadians:'',
      fetchPenyebabDasar:[],
      penyebab_dasars:'',

      accident_date: {
        startDate,
        endDate,
      },

      active_date: {
        startDate,
        endDate,
      },

      accident_date_complete:{
        startDate,
        endDate,
      },



      rate:'',
      fetchRate:[],

      fetchLoadNumber: [],
      loadNumber: [],

      fetchProvinsiTujuan: [],
      ProvinsiTujuan: [],

      fetchKotaTujuan: [],
      KotaTujuan: [],

      fetchSubCustomer: ['Industrial', 'Consumer', 'MT', 'Pallet', 'E-COM'],
      SubCustomer: [],

      showModalUnloading: false,

      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      permision_role: [],
      userData: "",
      companyCode: "",
      forms: {
          master_driver_id:"",
          blujay_order_id:"",
          load_number:"",
          order_detail_id:"",
          order_number:"",
          shipment_date:"",
          customer_id:"",
          customer_name:"",
          master_truck_id:"",
          vehicle_no:"",
          driver_nmk:"",
          driver_name:"",
          driver_id:"",
          driver_hp:"",
          route:"",
      },
      dataModal: {}
    };
  },
  watch: {},
  methods: {
      isNumberLatLong(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 45) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    resetForm() {

    },

    cek_message(status, msg) {
      if (status == "200") {
        this.success(msg);
      }
    },

    editData() {
      var params = this.$route.params.id;
      window.location.href = "/accident/edit/" + params;
    },

    deleteData() {
      var id = this.$onBehind(this.$route.params.id);
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
         
          const baseURI = this.$settings.endPointCt + "driver-accident/delete/" + id;
          this.$http
            .delete(baseURI)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                window.location.href = "/accident";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },



    backForm() {
      window.location.href = "/accident";
    },

    loadData(id) {
      this.isLoading = true
      const baseURI = this.$settings.endPointCt + "driver-accident/detail/" + id;
      return this.$http.get(baseURI,{ timeout: 10000 }).then((response) => {
          this.isLoading = false
          this.forms    = response.data.datas;
          this.forms.driver_nmk=this.forms.driver_id;

          if(this.forms.complete_date=='0000-00-00'){
            this.forms.complete_date="";
          }

          if(this.forms.active_date=='0000-00-00'){
            this.forms.active_date="";
          }

      this.vehicle_no={
          vehicle_no:this.forms.vehicle_no
        }


      this.rate=this.forms.accident_rate;
      this.caused=this.forms.caused;
      this.category=this.forms.accident_category;

      this.kerusakans = this.forms.kerusakan;
      this.tipe_kejadians = this.forms.tipe_kejadian;
      this.penyebab_dasars = this.forms.penyebab_dasar;

   

      }).catch(error => {
          this.isLoading = false;
          if(error.code == "ECONNABORTED"){
            this.error("Please Check Your Internet Connection");
          }else{

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
            
          }


        });
    },


    fetchIt() {
      const userDatas     = this.$getUserInfo();
      this.detailUser     = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function(permision){
        if(permision.permission.controller == 'DriverAccidentController'){
          rolePermision = permision;
        }
      });

      if(!rolePermision.method_read){
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;

    },


    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.cek_message(this.$route.query.status, this.$route.query.msg);
    this.loadData(params[0]);
    this.fetchIt();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content{
  filter: blur(5px); 
  pointer-events: none;
}
</style>