<template>
    <div>
      <div class="loader" v-if="this.isLoading"></div>   
      <div class="loadertext" v-if="this.isLoading"> {{$t('loadingTxt')}}...</div>   
      <div class="main-wrapper" :class="{'blur-content': this.isLoading}">
        <!--begin::sidebar-->
        <sidebar-component classMenu="GTNewsPortalEdit"></sidebar-component>
        <!--end::sidebar-->
  
        <div class="page-wrapper">
          <!--begin::navbar-->
          <navbar-component></navbar-component>
          <!--end::navbar-->
  
          <!--begin::content-->
          <div class="page-content">
            <div class="
                d-flex
                justify-content-between
                align-items-center
                flex-wrap
                grid-margin
              ">
              <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
                <div class="card">
                  <div class="card-header">
                    <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                    &nbsp;&nbsp;&nbsp;
                    <h6 class="card-title mb-0 float-start" style="padding-left: 25px">
                      {{ $t("news_portal_edit_txt") }}
                    </h6>
                    <button class="btn btn-default btn-sm float-end" @click="resetForm" type="button">
                      <i class="link-icon" data-feather="repeat"></i>
                      {{ $t("resetFormTxt") }}
                    </button>
                  </div>
                  <div class="card-body">
               
  
                    <form class="forms-sample" @submit.prevent="submitData" method="POST">
                     
  
                      <div class="row mb-3">
   
  
                        <div class="col-lg-12">
  
                          <div class="row mb-3">

                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 25px"> {{$t('statusTxt')}}</label>
                            </div>
                            <div class="col-lg-4">
                               
                                <select class="form-control" name="active_status" id="active_status" v-model="forms.active_status" required>
                                    <option  v-for="(a, index) of fetchStatus" :value="a.value" :key="index">{{a.text}}</option>
                                </select>
  
                              <div v-if="errors.active_status">
                                <div v-for="error in errors.active_status" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>


                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 25px"> {{$t('pool_choose_txt')}}</label>
                            </div>
                            <div class="col-lg-4">
                               
                            <v-select
                            :options="poolDatas"
                            v-model="poolData"
                            label="pool_name"
                            return-object
                            @input="asyncLoadPool"
                            @search="asyncLoadPool"
                            ></v-select>
   
  
                              <div v-if="errors.master_pool_id">
                                <div v-for="error in errors.master_pool_id" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>

                          </div>
     
  
                          <div class="row mb-3">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 25px"> {{$t('news_from_date')}}</label>
                            </div>

                            <div class="col-lg-4">
 
                                <date-range-picker style="width:100%" :date-format="dateFormat" @select="fromDatesChange" @update="fromDatesChange" control-container-class="form-control" :locale-data="locale" v-model="fromDates" :opens="opens" :singleDatePicker="true" :timePicker24Hour="true" :timePicker="true" :showWeekNumbers="false"></date-range-picker>


                              <div v-if="errors.news_from">
                                <div v-for="error in errors.news_from" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 25px"> {{$t('news_to_date')}}</label>
                            </div>
                            
                            <div class="col-lg-4">

                                <date-range-picker style="width:100%" :date-format="dateFormatEnd" @select="endDatesChange" @update="endDatesChange" control-container-class="form-control" :locale-data="locale" v-model="endDates" :opens="opens" :singleDatePicker="true" :timePicker24Hour="true" :timePicker="true" :showWeekNumbers="false"></date-range-picker>


                                <div v-if="errors.news_to">
                                    <div v-for="error in errors.news_to" :key="error" class="alert alert-primary"
                                    role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                    </div>
                              </div>
                            </div>


                          </div>
     
  
                          <div class="row mb-3">


                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 25px"> {{$t('title')}}</label>
                            </div>

                            <div class="col-lg-4">
                              <input
                                type="text"
                                v-model="forms.title"
                                class="form-control"
                                required
                              />

                              <div v-if="errors.title">
                                <div v-for="error in errors.title" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 25px"> {{$t('brief')}}</label>
                            </div>
                            
                            <div class="col-lg-4">
                              <input
                                type="text"
                                v-model="forms.brief"
                                class="form-control"
                                required
                              />

                              <div v-if="errors.brief">
                                <div v-for="error in errors.brief" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>


                          </div>
     
  
                          <div class="row mb-3">


                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 25px"> {{$t('images_txt')}}</label>
                            </div>

                       
                            <div class="col-lg-4">

                                <div v-if="images_temporary" class="dropify-wrapper has-preview">
                                <div class="dropify-message"> 
                                <span class="file-icon" v-if="!images_temporary"> 
                                    <p>{{$t('drop_and_drop_file')}}</p>
                                </span>
                                <p class="dropify-error">Ooops, {{$t('something_wrong')}}.</p>
                                </div>
                                <div class="dropify-loader" style="display: none;"></div>
                                <div class="dropify-errors-container">
                                <ul></ul>
                                </div>
                                <input type="file" ref="images" accept="image/*" class="form-control" @change="handleFileUpload($event)">
                                <button type="button" class="dropify-clear" @click="removeImages">{{$t('remove_txt')}}</button>
                               
                                 <button type="button"  class="dropify-download" @click="downloadImages">{{$t('download_file')}}</button>
                                 
                                <div  class="dropify-preview" style="display: block;">
                                <img :src="images_temporary" width="90%" height="80%"/>
                                <span class="dropify-render"></span>
                                <div class="dropify-infos">
                                <div class="dropify-infos-inner">
                                    <p class="dropify-filename">
                                    </p><p class="dropify-infos-message">{{$t('drop_and_drop_file')}}</p>
                                </div>
                                </div>
                                </div>

                                </div>
                                <div v-else class="dropify-wrapper">
                                <div class="dropify-message"> 
                                <span class="file-icon"> 
                                    <p>{{$t('drop_and_drop_file')}}</p>
                                </span>
                                </div>
                                <div class="dropify-loader" style="display: none;"></div>
                                <div class="dropify-errors-container">
                                <ul></ul>
                                </div>
                                <input type="file" ref="images" accept="image/*" class="form-control" @change="handleFileUpload($event)">
                                <button type="button" class="dropify-clear"  @click="removeImages">{{$t('remove_txt')}}</button>
                                <div class="dropify-preview" style="display: none;">
                                <img :src="images_temporary" width="90%" height="80%"/>
                                <span class="dropify-render"></span>
                                <div class="dropify-infos">
                                <div class="dropify-infos-inner">
                                    <p class="dropify-filename">
                                    </p><p class="dropify-infos-message">D{{$t('drop_and_drop_file')}}</p>
                                </div>
                                </div>
                                </div>

                                </div>

                                <div v-if="errors.news_picture">
                                <div v-for="error in errors.news_picture" :key="error" class="alert alert-primary"
                                    role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                </div>
                                </div>
                            </div>

                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 25px"> {{$t('news_detail')}}</label>
                            </div>
                            
                            <div class="col-lg-4">
                                
                              <vue2-tinymce-editor v-model="forms.detail"></vue2-tinymce-editor>

                              <!-- <textarea class="form-control" v-model="forms.detail" name="tinymce" id="simpleMdeExample" rows="10"></textarea> -->
                              <div v-if="errors.detail">
                                <div v-for="error in errors.detail" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>


                          </div>
  
   
                        </div>
  
                      </div>
  
                      <div class="card-footer">
                        <button class="
                            btn btn-warning
                            text-black
                            fw-bolder
                            btn-icon-text
                          " @click="backForm" type="button">
                          <i class="link-icon" data-feather="arrow-left"></i>
                          {{ $t("backMess") }}
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button class="btn btn-success float-end btn-sm" type="submit">
                          <i class="link-icon" data-feather="save"></i>
                          {{ $t("submitFormTxt") }}
                        </button>
                        
                      </div>
                    </form>

                  </div>
                </div>
                <!--end::card-->
              </div>
            </div>
          </div>
          <!--end::content-->
  
          <!--begin::footer-->
          <footer-component></footer-component>
          <!--end::footer-->
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import sidebarComponent from "@/components/_partials/_sidebar";
  import navbarComponent from "@/components/_partials/_navbar";
  import footerComponent from "@/components/_partials/_footer";
  import myMixinAddress from '@/middleware/mixinAddress.js';
  import DateRangePicker from 'vue2-daterange-picker'
 
  export default {
      name: "GTNewsPortalEdit",
      props: {},
      mixins: [myMixinAddress],
      components: {
          "sidebar-component": sidebarComponent,
          "navbar-component": navbarComponent,
          "footer-component": footerComponent,
          DateRangePicker
      },
      data() {

          const startDate = new Date();
          const endDate = new Date();
          endDate.setDate(endDate.getDate() + 24);

          return {
            description:"",
            opens:"center",
            locale:{
              format:"dd mmm yyyy H:MM:ss",
            
            },
            fromDates: {
              startDate,
              endDate,
            },
            endDates: {
              startDate,
              endDate,
            },
            highlighted:{

                dates: [ // Highlight an array of dates
                  new Date(),
                ],

            },
            disabledAfter:new Date(2019, 10, 6),
            fetchStatus:[{ value: '1', text: this.$t('active_txt') },{ value: '0', text: this.$t('deactive_txt') }],
            file: '',
            isLoading: false,
            maxToasts: 100,
            position: "up right",
            closeBtn: true,
            errors: [],
            permision_role: [],
            forms: {news_portal_id:"",master_pool_id:"",pool_name:"",news_picture:"",active_status:"",title:"",brief:"",detail:"",news_from:"",news_to:"",create_by:"",update_by:"",created_at:"",updated_at:""},
            userDatas:[],
            poolDatas:[],
            userData:{},
            poolData:{master_pool_id:"",pool_name:"All"},
            images: '',
            images_temporary: null,
          };
      },
      watch: {},
      methods: {
           
        removeImages(){
          this.$refs.images.value="";
          this.images_temporary = null;
        },
        downloadImages(){
            this.isLoading = true;
            var baseURI = this.$settings.endPointGT + "news-portal/download-images/" + this.forms.news_portal_id;

            var file_name = this.forms.news_picture
            file_name = file_name.replace('https://storage.googleapis.com/chain_image/','')
            return this.$http
            .get(
                baseURI
            , {
                responseType: "blob",
                timeout:3000,
            }
            ).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement("a");

                fileLink.href = fileURL;
                fileLink.setAttribute("download", file_name);
                document.body.appendChild(fileLink);
                fileLink.click();
                this.isLoading = false;
            }).catch(error => {
                  this.isLoading = false;
                  if(error.code == "ECONNABORTED"){
                      this.error(this.$t("check_internet_connection_txt"));
                  }else{
  
                      if (error.response) {
                      if(error.response.status === 422) {
                              this.errors = error.response.data.errors;
                              this.resultError(error.response.data.errors);
                      }else if (error.response.status === 500) {
                          this.$router.push('/server-error');
                      }else if (error.response.status === 401) {
                          this.$router.push('/authorized-error');
                      }else{
                          this.$router.push('/page-not-found');
                      }
                      }
  
                  }
  
              });
        },

        handleFileUpload(event) {
          this.images = event.target.files[0];
          this.images_temporary = URL.createObjectURL(this.images);
        },
        dateFormat (classes, date) {
          if (!classes.disabled) {
            classes.disabled = date.getTime() < new Date();
          }
          return classes
        },

        dateFormatEnd(classes, date){
          if (!classes.disabled) {
            classes.disabled = date.getTime() < new Date(this.forms.news_from);
          }
          return classes
        },

        fromDatesChange(){
          this.forms.news_from=this.$moment(String(this.fromDates.startDate)).format('YYYY-MM-DD hh:mm:ss');
        },
        
        endDatesChange(){
          this.forms.news_to=this.$moment(String(this.endDates.startDate)).format('YYYY-MM-DD hh:mm:ss');
        },

        asyncLoadPool(ev) {
            const baseURI =
                this.$settings.endPointCt +
                `pool-branch?pool_name=`+ev;

            return this.$http.get(baseURI).then((response) => {
                this.poolDatas = response.data.datas.data;
                this.poolDatas.push({master_pool_id:"",pool_name:"All"});
            });
        },
  
          resetForm() {

            const startDate   = new Date();
            const endDate     = new Date();
            endDate.setDate(endDate.getDate() + 24);

            this.forms        = {news_portal_id:"",master_pool_id:"",pool_name:"",news_picture:"",active_status:"",title:"",brief:"",detail:"",news_from:"",news_to:"",create_by:"",update_by:"",created_at:"",updated_at:""};
          
            this.fromDates    =  {
              startDate,
              endDate,
            };

            this.endDates     = {
              startDate,
              endDate,
            };

            this.poolData = {master_pool_id:"",pool_name:"All"}

          },
  
          
          backForm() {
            window.location.href = "/gt/news-portal";
          },
  
          submitData() {

         
              this.fade(true);

              var news_from = this.forms.news_from
              if(news_from == ''){
                news_from = this.$moment(String(this.fromDates.startDate)).format('YYYY-MM-DD hh:mm:ss')
              }

              var news_to = this.forms.news_to
              if(news_to == ''){
                news_to = this.$moment(String(this.endDates.startDate)).format('YYYY-MM-DD hh:mm:ss')
              }
              
  
              let formData = new FormData();          
              formData.append("active_status", this.forms.active_status);
              formData.append("master_pool_id", this.poolData.master_pool_id);
              formData.append("pool_name", this.poolData.pool_name);
              formData.append("news_from", news_from);
              formData.append("news_to", news_to); 
              formData.append("title", this.forms.title); 
              formData.append("brief", this.forms.brief); 
              formData.append("detail", this.forms.detail);

              if(this.images != ''){

                formData.append("news_picture", this.images);
 
              }
    
              this.isLoading = true;
              const baseURI = this.$settings.endPointGT + "news-portal/update/"+this.forms.news_portal_id;
              this.$http.post(baseURI, formData,{ timeout: 10000 }).then((response) => {
                  this.isLoading = false;
                  if (response.data.status === 200) {
                    this.errors = [];
                    var params = this.$onRandom(this.forms.news_portal_id);
                    window.location.href = "/gt/news-portal/detail/" + params + "?status=200&msg=Successfully";
                  } else {
                    this.errors = response.data.errors;
                    this.resultError(response.data.errors);
                  }
              }).catch(error => {
                  this.isLoading = false;
                  if(error.code == "ECONNABORTED"){
                      this.error(this.$t("check_internet_connection_txt"));
                  }else{
  
                      if (error.response) {
                      if(error.response.status === 422) {
                              this.errors = error.response.data.errors;
                              this.resultError(error.response.data.errors);
                      }else if (error.response.status === 500) {
                          this.$router.push('/server-error');
                      }else if (error.response.status === 401) {
                          this.$router.push('/authorized-error');
                      }else{
                          this.$router.push('/page-not-found');
                      }
                      }
  
                  }
  
              });

          },
  
          fade(sType) {
          this.isLoading = sType;
          },
  
          loading() {
          this.fade(true);
          setTimeout(() => {
              this.fade(false);
          }, 1000); // hide the message after 3 seconds
          },
  
          fetchIt() {
          const userDatas = this.$getUserInfo();
          this.detailUser = userDatas.sub;
          // this.permision_role = userDatas.sub.permision_role;
          let rolePermision = [];
          userDatas.sub.permision_role.forEach(function (permision) {
              if (permision.permission.controller == "NewsPortalController") {
              rolePermision = permision;
              }
          });
  
          if (!rolePermision.method_update) {
              this.$router.push("/authorized-error");
          }
  
          this.permision_role = rolePermision;

            var status = this.$route.query.status
            if(status == "200"){
              this.success(this.$route.query.msg)
            }

          },
  
          resultError(data) {
          var count = Object.keys(data).length;
          for (var x = 0; x < count; x++) {
              var nameOb = Object.keys(data)[x];
              var objectData = data[nameOb];
              for (var y = 0; y < objectData.length; y++) {
              this.error(objectData[y]);
              }
          }
          },
  
          success(kata) {
          const Toast = this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
          });
          Toast.fire({
              icon: "success",
              title: kata,
          });
          },
  
          error(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "error",
                title: kata,
            });
          },
          
          loadingData(){
            
            var params = this.$onBehind(this.$route.params.id);

            this.isLoading = true;
            var baseURI = this.$settings.endPointGT + "news-portal/detail/" + params[0];
    
            return this.$http.get(baseURI,{ timeout: 10000 }).then((response) => {

            this.forms = response.data.datas;
 
            this.fromDates= {
                startDate: new Date(this.forms.news_from),
                endDate: new Date(this.forms.news_from),
            };

            this.endDates= {
                startDate: new Date(this.forms.news_to),
                endDate: new Date(this.forms.news_to),
            };

            if(this.forms.news_picture){
                this.images_temporary = this.forms.news_picture
            }
 
            this.poolData = {master_pool_id:this.forms.master_pool_id,pool_name:this.forms.pool_name}

            this.isLoading = false;

            }).catch(error => {
                this.isLoading = false;
                if(error.code == "ECONNABORTED"){
                    this.error(this.$t("check_internet_connection_txt"));
                }else{

                    if (error.response) {
                    if(error.response.status === 422) {
                            this.errors = error.response.data.errors;
                            this.resultError(error.response.data.errors);
                    }else if (error.response.status === 500) {
                        this.$router.push('/server-error');
                    }else if (error.response.status === 401) {
                        this.$router.push('/authorized-error');
                    }else{
                        this.$router.push('/page-not-found');
                    }
                    }

                }

            });

          },

      },
      events: {},
      created: function () { },
    mounted() {
      
        this.fetchIt();
        this.loading();
        this.loadingData();
        // this.asyncLoadPool('');
    },
  };
  </script>
  <style scoped>
  button.gm-ui-hover-effect {
    visibility: hidden;
  }
  .blur-content{
    filter: blur(5px); 
  }
  </style>