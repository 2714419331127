<template>
    <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Margin Approval</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
              aria-label="btn-close"></button>
          </div>
          <div class="modal-body">
            <!--begin::loading-data-->
            
            <form class="forms-sample" @submit.prevent="submitData" method="POST">
              <div class="d-flex justify-content-between align-items-baseline mb-2"></div>
  
              
              <div class="row">
              <div class="col-md-12">
                <p class="text-muted mb-3">Detail Decission PNL</p>
                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Customer</th>
                        <th>Truck Type</th>
                        <th>Pickup Info</th>
                        <th>Destination Info</th>
                        <th>Payable Rate Total</th>
                        <th>Billable Rate Total</th>
                        <th>PNL</th>
                      </tr>
                    </thead>
                    <tbody>
                     
                      <tr v-for="(row, index) in rows" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ row.name }}</td>
                        <td>{{ row.type_truck }}</td>
                        <td>{{ row.pick_name }}</td>
                        <td>{{ row.dest_name }}</td>
                        <td>{{ row.payable_rate }}</td>
                        <td>{{ row.billable_rate }}</td>
                        <td>{{ row.pnl_value }}</td>
                      </tr>
                     
                    </tbody>
                  </table>
                </div>
              </div>

            </div>


              

            

            <br>

             

              <div class="row ">
                <div class="col-lg-4">
                  <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Decission</label>
                </div>
                <div class="col-lg-8">
                   
                    <v-select
                        :options="fetchDecisionStatus"
                        v-model="decission_status"
                        label="value"

                        return-object
                      
                    ></v-select>
                        
                </div>
              </div>


              <div class="row ">
                <div class="col-lg-4">
                  <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Reason</label>
                </div>
                <div class="col-lg-8">
                  <textarea class="form-control" required v-model="remarks" rows="5"></textarea>
                  <!-- <div v-if="errors.pick_plan">
                    <div v-for="error in errors.pick_plan" :key="error" class="alert alert-primary" role="alert">
                      <i data-feather="alert-circle"></i>
                      {{ error }}
                    </div>
                  </div> -->
                </div>
              </div>

              <br>

              <br>
             
              <div class="modal-footer">
                
                <!--end::loading-data-->
                <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
                  {{ $t("close_txt") }}
                </button>
                <button class="btn btn-primary" type="submit">
                  <span v-if="isLoading" class="spinner-border spinner-border-sm ms-auto"
                  role="status"
                  aria-hidden="true"></span>Send Request 
                </button>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    name: "ModalMarginApproval",
    emits: ["closeModal"],
    props: {
      status:String,
      showModal: Boolean,
      data: null,
    },
    watch: {
      showModal: {
        handler(newVal) {
          //this.forms = this.data
          this.remarks='';
          this.decission_status='';
          if(this.showModal==true){
            //console.log(this.data.length);
            for (let index = 0; index < this.data.length; index++) {
                this.loadItems(this.data[index]);
              
            }
            // this.loadItems(this.data.transport_order_header_id);
          }
          
          // console.log(this.forms)
          this.active = newVal;
          const body = document.querySelector("body");
          this.showModal
            ? body.classList.add("modal-open")
            : body.classList.remove("modal-open");
        },
        immediate: true,
        deep: true,
      },
    },
    data() {
      return {
        rows:[],
        remarks:'',
        decission_status:'',
        fetchDecisionStatus:['APPROVE','REJECT'],
        opens:"center",
        isLoading: false,
        active: this.showModal,
        forms: [],
        errors: [],
        type_error: [],
      };
    },
    methods: {
      resetForm() {
        this.forms = [];
      },
  
      submitData() {
        this.$swal({
        title: "WARNING !!",
        text: "Are you sure to "+this.decission_status+" this order when it's done it cannot be undo ",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          this.fade(true);
          
          let baseURI = this.$settings.endPoint + "control-tower/transport-order-header/decission-pnl-need-approval";

          this.$http
            .post(baseURI,{
                "transport_order_header_ids" : this.data,
                "decission_status" : this.decission_status, // 'required|in:APPROVE,REJECT',
                "reason" : this.remarks
            })
            .then((response) => {
              this.fade(false);
              if (response.data.status === 200) {
                //this.resetForm();
                this.errors = [];

                this.loadSuccess(response.data.datas.messages);
                this.$emit("closeModal");
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.fade(false);
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
              //this.resetForm();
            });
        }
       
      })

       
      },
  
      fade(sType) {
        this.isLoading = sType;
      },

      loadItems(transport_order_header_id) {
        console.log(transport_order_header_id);
            this.rows = [];
            const baseURI = this.$settings.endPoint + "control-tower/transport-order-header/detail-decission-pnl-need-approval/" + transport_order_header_id;
            return this.$http
                .get(
                    baseURI,
                    { timeout: 10000 }
                )
                .then((response) => {
                   
                    this.rows.push(response.data.datas);
                    console.log(this.rows);
                    // this.forms=response.data.datas;
                    // this.forms.decission_status=this.status;
                    // this.forms.reason="";
                    //this.rows = response.data.datas;






                    // this.totalRecords = response.data.datas.total;
                    // this.isLoading = false;
                }).catch(error => {
                    this.isLoading = false;
                    if (error.code == "ECONNABORTED") {
                        this.error(this.$t("check_internet_connection_txt"));
                    } else {

                        if (error.response) {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                                this.resultError(error.response.data.errors);
                            } else if (error.response.status === 500) {
                                this.$router.push('/server-error');
                            } else if (error.response.status === 401) {
                                this.$router.push("/authorized-error");
                            } else {
                                //this.$router.push('/page-not-found');
                            }
                        }

                    }

                });
        },
  
      loading() {
        this.fade(true);
        setTimeout(() => {
          this.fade(false);
        }, 1000); // hide the message after 3 seconds
      },
  
      resultError(data) {
        var html = "";
        var count = Object.keys(data).length;
        for (var x = 0; x < count; x++) {
          var nameOb = Object.keys(data)[x];
          var objectData = data[nameOb];
          for (var y = 0; y < objectData.length; y++) {
            html += objectData[y] + "<br>";
          }
        }
  
        this.loadError(html);
      },
  
      loadError(pesan) {
        this.$swal({
          backdrop: true,
          title: "Warning",
          html: pesan,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      },
  
      loadSuccess(pesan) {
        this.$swal({
          backdrop: true,
          title: "Success",
          html: pesan,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      },
    },
    mounted() {
      //tail(this.data);
    },
  };
  </script>
  
  <style scoped>
  .modal {
    background: rgba(0, 0, 0, 0.4);
  }
  
  .modal-content {
    background: #fff;
    border-radius: 0px;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 0px;
    width: 100%;
    height: 100%;
    margin-left:0px !important;
  }
  </style>