<template>
    
    <div class="row">
       

      <vue-good-table
        title="list-of-orders"
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :line-numbers="true"
        :isLoading.sync="isLoading"
        :pagination-options="{
          enabled: false,
        }"
        :filterOptions="{
          enabled: false}" 
        :rows="rowsTableOrder"
        :columns="columnsOrder"
        :sort-options="{
          enabled: false,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'name_rute'">
            {{ props.row.pickup }} - {{ props.row.delivery }}
          </span>
  
          <span v-if="props.column.field == 'control'">
           
            <a
              href="#"
              title="Edit"
              @click.prevent="EditFormModal(props.row)"
            >
              <i class="mdi mdi-square-edit-outline" style="font-size: 16px"></i>
            </a>
  
          </span>
  
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
          
        </template>
      </vue-good-table>
  
      <!-- <modal-trip-analysis  
        :showModal="showModalNow"
        :data="detailTripAnalysis"
        :vehicle_no="vehicle_no"
        @closeModal="closeMyModal"
      ></modal-trip-analysis> -->
  
  
  
    </div>
  
  
  </template>
  
  
  <script>
//   import modalTripAnalysis from "@/components/modal/modalTripAnalysis.vue";
  
  export default {
    name: "onTimeDeliveryComponent",
    props: {
      detail: [],
    },
    // components: {
    //   "modal-trip-analysis":  modalTripAnalysis ,
    //   // DirectionsRenderer,
    // },
    watch: {
    
      detailOrder: function(val) {
        this.loadTable(val)
      },
    
    },
    data() {
      return {
        //vehicle_no:this.$route.params.vehicle_no,
        detailTripAnalysis:"",
        showModalNow: false,
        isLoading: false,
        totalRecords: 0,
        serverParams: {
          columnFilters: {},
          sort: {
            field: "",
            type: "",
          },
          page: 1,
          per_page: 10,
        },
        rowsTableOrder: [],
        columnsOrder: [
          {
            label: "Shipment Date",
            field: "shipment_date",
          },
  
          {
            label: "Ontime Delivery",
            field: "ontime_Delivery",
          },
  
       
          {
            label: "Load Number",
            field: "load_number",
          },


         


          {
            label: "Customer",
            field: "customer",
          },

          {
            label: "Vehicle No",
            field: "vehicle_no",
          },


          {
            label: "Pickup Datetime",
            field: "pickup_datetime",
          },

          {
            label: "Delivery Datetime",
            field: "delivery_datetime",
          },


         
          {
            label: "Delay",
            field: "delay",
          },
        ],
        //
      };
    },
    methods: {
  
      loadTable(val){
          this.rowsTableOrder=val;
      },
  
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
  
      onColumnFilter(params) {
        params.page = 1;
        this.updateParams(params);
      },
  
      onPageChange(params) {
        this.updateParams({ page: params.currentPage });
      },
  
      onSortChange(params) {
        this.updateParams({
          sort: {
            type: params[0].type,
            field: params[0].field,
          },
        });
      },
  
  
      EditFormModal(data) {
        this.detailTripAnalysis=data;
        this.showModalNow = !this.showModalNow;
      },
  
      closeMyModal() {
        this.showModalNow = false;
        this.$emit("afterSaveTripAnalysis");
        //this.loadData(this.vehicle_no);
      },
  
      onPerPageChange(params) {
        this.updateParams({ per_page: params.currentPerPage });
      },
    },
  };
  </script>
  