import FakturPajak from "@/components/dasboard-fp/faktur_pajak/FakturPajak.vue";
import FakturPajakAdd from "@/components/dasboard-fp/faktur_pajak/FakturPajakAdd.vue";
import FakturPajakDetail from "@/components/dasboard-fp/faktur_pajak/FakturPajakDetail.vue";
import FakturPajakEdit from "@/components/dasboard-fp/faktur_pajak/FakturPajakEdit.vue";
import FakturPajakUpload from "@/components/dasboard-fp/faktur_pajak/FakturPajakUpload.vue";
import FakturPajakByCompany from "@/components/dasboard-fp/faktur_pajak/FakturPajakByCompany.vue";
import FakturPajakVendor from "@/components/dasboard-fp/faktur_pajak/FakturPajakVendor.vue";
import FakturPajakVendorAdd from "@/components/dasboard-fp/faktur_pajak/FakturPajakVendorAdd.vue";
import FakturPajakVendorDetail from "@/components/dasboard-fp/faktur_pajak/FakturPajakVendorDetail.vue";
import FakturPajakVendorEdit from "@/components/dasboard-fp/faktur_pajak/FakturPajakVendorEdit.vue";
import FakturPajakVendorUpload from "@/components/dasboard-fp/faktur_pajak/FakturPajakVendorUpload.vue";
import FakturPajakUserAccess from "@/components/dasboard-fp/faktur_pajak/FakturPajakUserAccess.vue";
import FakturPajakUserAccessAdd from "@/components/dasboard-fp/faktur_pajak/FakturPajakUserAccessAdd.vue";
import FakturPajakUserAccessDetail from "@/components/dasboard-fp/faktur_pajak/FakturPajakUserAccessDetail.vue";
import FakturPajakUserAccessEdit from "@/components/dasboard-fp/faktur_pajak/FakturPajakUserAccessEdit.vue";
import FakturPajakUserAccessUpload from "@/components/dasboard-fp/faktur_pajak/FakturPajakUserAccessUpload.vue";

let routesFakturPajak= [
    {
        path: '',
        meta: {
          title: "Faktur Pajak",
        },
        name: "FakturPajak",
        component: FakturPajak
      },
    
      {
        path: 'bycompany/:id',
        meta: {
          title: "Faktur Pajak",
        },
        name: "FakturPajakByCompany",
        component: FakturPajakByCompany
      },
      {
        path: 'add',
        meta: {
          title: "Faktur Pajak",
        },
        name: "FakturPajakAdd",
        component: FakturPajakAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Faktur Pajak",
        },
        name: "FakturPajakEdit",
        component: FakturPajakEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Faktur Pajak",
        },
        name: "FakturPajakDetail",
        component: FakturPajakDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Faktur Pajak",
        },
        name: "FakturPajakUpload",
        component: FakturPajakUpload
      },

      {
          path: 'vendor',
          meta: {
            title: "Vendor",
          },
          name: "FakturPajakVendor",
          component: FakturPajakVendor
      },

      {
          path: 'vendor/add',
          meta: {
            title: "Vendor",
          },
          name: "FakturPajakVendorAdd",
          component: FakturPajakVendorAdd
      },

      {
          path: 'vendor/detail/:id',
          meta: {
            title: "Vendor",
          },
          name: "FakturPajakVendorDetail",
          component: FakturPajakVendorDetail
      },

      {
          path: 'vendor/edit/:id',
          meta: {
            title: "Vendor",
          },
          name: "FakturPajakVendorEdit",
          component: FakturPajakVendorEdit
      },

      {
          path: 'vendor/upload',
          meta: {
            title: "Vendor",
          },
          name: "FakturPajakVendorUpload",
          component: FakturPajakVendorUpload
      },

      {
          path: 'user-access',
          meta: {
            title: "User Access",
          },
          name: "FakturPajakUserAccess",
          component: FakturPajakUserAccess
      },

      {
          path: 'user-access/add',
          meta: {
            title: "User Access",
          },
          name: "FakturPajakUserAccessAdd",
          component: FakturPajakUserAccessAdd
      },

      {
          path: 'user-access/detail/:id',
          meta: {
            title: "User Access",
          },
          name: "FakturPajakUserAccessDetail",
          component: FakturPajakUserAccessDetail
      },

      {
          path: 'user-access/edit/:id',
          meta: {
            title: "User Access",
          },
          name: "FakturPajakUserAccessEdit",
          component: FakturPajakUserAccessEdit
      },

      {
          path: 'user-access/upload',
          meta: {
            title: "User Access",
          },
          name: "FakturPajakUserAccessUpload",
          component: FakturPajakUserAccessUpload
      },
    
]

export default routesFakturPajak
  