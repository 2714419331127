<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TemplateOrder"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <h4 style="margin-right: 5px" class="float-start">Edit Do Number</h4>
                  <button class="btn btn-primary float-end btn-xs"
                    style="margin-right: 5px" @click.prevent="createData()">
                    <i class="link-icon" data-feather="plus"></i>
                    {{ $t("createNew") }}
                  </button>
                </div>

                <div class="card-body">
                  <div class="row">
                      <div class="col-lg-12">
                          <!-- 
                          -->
                          <vue-good-table
                              title="list-order-ct"
                              mode="remote"
                              :select-options="{ 
                                              enabled: true,
                                              selectOnCheckboxOnly: true, 
                                              selectionText: $t('data_selected'), 
                                              clearSelectionText: $t('clear'), }"
                              :totalRows="totalRecords"
                              :line-numbers="true"
                              @on-page-change="onPageChange"
                              @on-sort-change="onSortChange"
                              @on-column-filter="onColumnFilter"
                              @on-per-page-change="onPerPageChange" 
                              @on-selected-rows-change="selectionChanged"
                              :isLoading.sync="isLoading"
                              :pagination-options="{
                                  enabled: true,
                              }"
                              :rows="rows"
                              :columns="columns"
                          >
                              <div slot="selected-row-actions" >
                                  <!-- <button class="btn btn-success btn-xs" style="margin-right:20px;"><i class="mdi mdi-file-document"></i>{{ $t("downloadData") }} .xlsx  </button> -->
                                  <button class="btn btn-warning btn-xs" v-if="btnProccess" @click="ProsesOrders">Proccess</button>
                              </div>
                              <template slot="table-row" slot-scope="props">
                                  <span v-if="props.column.field == 'actions'">
                                    <div class="btn-group" v-if="props.row.status == 'NEW'">
                                      <!-- <button type="button" class="btn btn-warning btn-icon mx-1" style="color: white;text-decoration: none;  cursor: pointer;"
                                      title="Edit"
                                      @click="
                                          editData(props.index, props.row)
                                      "
                                      >
                                          <i class="mdi mdi-pencil" style="font-size:16px"></i>
                                      </button>
                                      <button type="button" class="btn btn-primary btn-icon" style="color: white;text-decoration: none;  cursor: pointer;"
                                      title="Delete"
                                      v-if="permision_role.method_delete"
                                      @click="
                                          deleteData(props.index, props.row)
                                      "
                                      >
                                          <i class="mdi mdi-delete" style="font-size:16px"></i>
                                      </button> -->
                                      <a
                                        href="#"
                                        v-if="permision_role.method_update"
                                        title="Edit"
                                        @click.prevent="editData(props.index, props.row)"
                                      >
                                        <i class="mdi mdi-square-edit-outline" style="font-size:16px"></i>
                                        
                                      </a>

                                      <a
                                        href="#"
                                        v-if="permision_role.method_delete"
                                        title="Delete"
                                        @click.prevent="
                                          deleteData(props.index, props.row)
                                        "
                                      >
                                        <i class="mdi mdi-trash-can-outline" style="font-size:16px"></i> 
                                      </a>
                                    </div>
                                  </span>
                                  <span v-else-if="props.column.field == 'entries'">
                                      <a style="color: #ab0635;text-decoration: none; cursor: pointer; "
                                          title="Detail Load Id"
                                          @click.prevent="
                                              detailPi(props.row)
                                          "
                                      >
                                          {{ props.row.entries }}
                                      </a>
                                  </span>
                                  <span v-else>
                                      {{ props.formattedRow[props.column.field] }}
                                  </span>
                              </template>
                          </vue-good-table>

                      </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
export default {
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      closeBtn: true,
      isLoading: false,
      errors: [],
      proformaInvoiceNo:"",
      loadId:"",
      totalRecords:0,
      rowsSelection:[],
      btnProccess:true,
      detailData:[],
      editDoIds:[],
      columns: [
        {
            label: "Status",
            field: "status",
            filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By Status", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
            },
        },
        {
            label: "Load Id",
            field: "load_id",
            filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By Load Id", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
            },
        },
        {
            label: this.$t("status_tms_txt"),
            field: "status_tms",
            filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By Status TMS", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
            },
        },
        {
            label: this.$t("new_order_number_txt"),
            field: "order_no",
            filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By "+this.$t("new_order_number_txt"), // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
            },
        },
        {
            label: this.$t("old_order_number_txt"),
            field: "old_order_no",
            filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By "+this.$t("old_order_number_txt"), // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
            },
        },
        {
            label: this.$t("shipwith_ref_txt"),
            field: "shipwith_ref",
            filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By "+this.$t("shipwith_ref_txt"), // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
            },
        },
        {
            label: this.$t("tonnage_txt")+" (Kg)",
            field: "tonnage",
            filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By "+this.$t("tonnage_txt"), // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
            },
        },
        {
            label: this.$t("pickup_plan_txt"),
            field: "pick_plan",
            thClass: 'text-left',
            type: 'date',
            dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
            dateOutputFormat: 'dd MMM yyyy h:mm:ss a',//'dd MMM yyyy',
            filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By "+this.$t("pickup_plan_txt"), // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
            },
        },
        {
            label: this.$t("pickup_location_txt"),
            field: "pick_name",
            filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By "+this.$t("pickup_location_txt"), // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
            },
        },
        {
            label: this.$t("dest_plan_txt"),
            field: "dest_plan",
            thClass: 'text-left',
            type: 'date',
            dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
            dateOutputFormat: 'dd MMM yyyy h:mm:ss a',//'dd MMM yyyy',
            filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By "+this.$t("dest_plan_txt"), // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
            },
        },
        {
            label: this.$t("destination_location_txt"),
            field: "dest_name",
            filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By "+this.$t("destination_location_txt"), // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
            },
        },
        {
            label: "Nopol",
            field: "vehicle_no",
            filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By Status", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
            },
        },
        {
            label: "Driver",
            field: "driver_name",
            filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By Status", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
            },
        },
        {
            label: "Carier",
            field: "carrier",
            filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By Status", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
            },
        },
        {
            label: "Action",
            field: "actions",
            filterOptions: {
                enabled: false, // enable filter for this column
            },
        },
      ],
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      rows:[],
    }
  },  
  methods: {
    fetchIt() {
      const userDatas     = this.$getUserInfo();
      this.detailUser     = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function(permision){
        if(permision.permission.controller == 'DoNumberController'){
          rolePermision = permision;
        }
      });
        if(!rolePermision.method_read){
          this.$router.push("/authorized-error");
        }
      this.permision_role = rolePermision;

      if(this.$route.query.msg){
        if(this.$route.query.status == 200){
          this.success(this.$route.query.msg)
        }else{
          this.error(this.$route.query.msg)
        }

      }

    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },
    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },
    loadItems() {
      this.isLoading = true;
      const baseURI = this.$settings.endPoint + "do-number";
     
      return this.$http
        .get(
            baseURI +
            `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&status=${this.serverParams.columnFilters.status}&load_id=${this.serverParams.columnFilters.load_id}&carrier=${this.serverParams.columnFilters.carrier}&dest_location=${this.serverParams.columnFilters.dest_location}&dest_plan=${this.serverParams.columnFilters.dest_plan}&driver_name=${this.serverParams.columnFilters.driver_name}&new_order_number=${this.serverParams.columnFilters.new_order_number}&order_number=${this.serverParams.columnFilters.order_number}&pick_location=${this.serverParams.columnFilters.pick_location}&pick_plan=${this.serverParams.columnFilters.pick_plan}&shipwith_ref=${this.serverParams.columnFilters.shipwith_ref}&status_tms=${this.serverParams.columnFilters.status_tms}&tonnage=${this.serverParams.columnFilters.tonnage}&vehicle_no=${this.serverParams.columnFilters.vehicle_no}`,
          { timeout: 10000 }
        )
        .then((response) => { 
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
          
          this.isLoading = false;
        }).catch(error => {
          this.isLoading = false;
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                      this.errors = error.response.data.errors;
                      this.resultError(error.response.data.errors);
              }else if (error.response.status === 500) {
                  this.$router.push('/server-error');
              }else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
              }else{
                  this.$router.push('/page-not-found');
              }
            }

          }

        });
    },
    deleteData(index, row, status) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          this.isLoading = true;
          const baseURI =
            this.$settings.endPoint + "do-number/delete/" + row.do_number_id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },
    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },
    fade(sType) {
      this.isLoading = sType;
    },
    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },  
    editData(index,row){
      var params = this.$onRandom(row.do_number_id);
      window.location.href = "/edit-do-number/edit/" + params;
    },
    createData(){
        window.location.href = "/edit-do-number/add";
    },
    selectionChanged(ev){
        this.rowsSelection=[];
        this.rowsSelection=ev.selectedRows;
        this.btnProccess = true;
        this.editDoIds=[];
        this.rowsSelection.forEach((item) => {
          if(item.status !== 'NEW'){
            this.btnProccess = false;
          }
          this.editDoIds.push(item.do_number_id);
        // if(item.order_status=='NEW'){
        //   this.error('Order No:'+item.order_status+' Status Not Submited, because status order not NEW or SUBMITED');
        // }else{

        // }

      });
      console.log(this.editDoIds);
    },
    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },
    ProsesOrders(){
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
          let data={
            do_number_ids:this.editDoIds
          }
          const baseURI =
            this.$settings.endPoint + "do-number/send-order";
          this.$http
            .post(baseURI,data,{ timeout: 30000 })
            .then((response) => {
              // this.loading();
              if (response.data.status === 200) {
                
                // response.data.datas.messages.forEach((item) => {
                //   this.success(item);
                // });
                this.success2(response.data.datas.messages);
                this.loadItems();
                this.isloading = false;
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            }).catch(error => {
            this.isLoading = false;
            if(error.code == "ECONNABORTED"){
                this.error(this.$t("check_internet_connection_txt"));
            }else{

                if (error.response) {
                if(error.response.status === 422) {
                        this.errors = error.response.data.errors;
                        this.resultError(error.response.data.errors);
                }else if (error.response.status === 500) {
                    this.$router.push('/server-error');
                }else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                }else{
                    this.$router.push('/page-not-found');
                }
                }

            }

            });
        }
      });
    },
    success2(msg){
      let pesan="";
      msg.forEach((item) => {
          pesan=pesan+"<p>* "+item+"</p>";
      });

      this.$swal({
        icon: 'info',
        backdrop:true,
        title: "Information",
        html:pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      
    },
  },
  mounted() {
    this.fetchIt();
    this.loadItems();

  },
}
</script>

<style>

</style>