<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading">
      {{ $t("loadingTxt") }}...
    </div>
    <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
      <sidebar-component classMenu="AccidentAdd"></sidebar-component>
      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 25px"
                  >
                    Edit Kriminalitas
                  </h6>
                  <button
                    class="btn btn-default btn-sm float-end"
                    @click="resetForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->
                  <form
                    class="forms-sample"
                    style="padding-left: 25px"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="d-flex justify-content-between align-items-baseline mb-2"
                    ></div>
                    <div class="row mb-2">
                      <div class="col-sm-3">
                        <label class="form-label">Load ID</label>
                      </div>
                      <div class="col-md-6">
                        <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                        <input
                          readonly
                          type="text"
                          id="load_id"
                          name="load_id"
                          v-model="forms.load_id"
                          class="form-control"
                          placeholder="Load ID"
                          read-only
                        />

                        <div v-if="errors.load_number">
                          <div
                            v-for="error in errors.load_number"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <button
                          class="btn btn-success btn-sm"
                          @click.prevent="openModal()"
                          style="margin-right: 1px"
                        >
                          Browse
                        </button>
                        <button
                          class="btn btn-danger btn-sm"
                          @click.prevent="clearLoad()"
                        >
                          No Load
                        </button>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-3">
                        <label class="form-label"
                          >Vehicle No<span class="wajib">*</span></label
                        >
                      </div>
                      <div class="col-md-8">
                        <v-select
                          :options="fetchVehicleNo"
                          v-model="vehicle_no"
                          :disabled="disabledBeforeLoad"
                          label="vehicle_no"
                          return-object
                          @search="asyncSearchVehicleNo"
                        ></v-select>

                        <div v-if="errors.vehicle_no">
                          <div
                            v-for="error in errors.vehicle_no"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-3">
                        <label class="form-label">Shipment Date</label>
                      </div>
                      <div class="col-md-8">
                        <!-- <input type="text" id="shipment_date" name="shipment_date" v-model="forms.shipment_date" :disabled="disabledBeforeLoad" class="form-control" placeholder="Shipment Date" /> -->
                        <Datepicker
                          v-model="forms.shipment_date"
                          :disabled="disabledBeforeLoad"
                          :bootstrap-styling="true"
                          format="dd-MM-yyyy"
                          placeholder="Please Select Date..."
                        ></Datepicker>
                        <div v-if="errors.shipment_date">
                          <div
                            v-for="error in errors.shipment_date"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-3">
                        <label class="form-label">Order Number</label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          id="order_number"
                          name="order_number"
                          v-model="forms.order_number"
                          :disabled="disabledBeforeLoad"
                          class="form-control"
                          placeholder="Shipment Number"
                        />
                        <div v-if="errors.order_number">
                          <div
                            v-for="error in errors.order_number"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-md-3">
                        <label class="form-label"
                          >Driver NMK<span class="wajib">*</span></label
                        >
                      </div>
                      <div class="col-md-8">
                        <!-- <input type="text" id="vehicle_no" name="vehicle_no" v-model="forms.vehicle_no"
                              class="form-control" placeholder="Vehicle No" readonly /> -->

                        <v-select
                          :options="fetchDriverNmk"
                          @input="changeDriverNmk(driver_nmk)"
                          v-model="driver_nmk"
                          :disabled="disabledBeforeLoad"
                          label="driver_id"
                          return-object
                          @search="asyncSearchDriverNmk"
                        ></v-select>

                        <div v-if="errors.driver_nmk">
                          <div
                            v-for="error in errors.driver_nmk"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-md-3">
                        <label class="form-label"
                          >Driver Name<span class="wajib">*</span></label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          id="driver_name"
                          name="driver_name"
                          v-model="forms.driver_name"
                          class="form-control"
                          placeholder="Driver Name"
                          :disabled="disabledBeforeLoad"
                        />
                        <div v-if="errors.driver_name">
                          <div
                            v-for="error in errors.driver_name"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-md-3">
                        <label class="form-label">Driver HP</label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          id="driver_hp"
                          name="driver_hp"
                          v-model="forms.driver_hp"
                          class="form-control"
                          placeholder="Driver HP"
                          :disabled="disabledBeforeLoad"
                        />
                        <div v-if="errors.driver_hp">
                          <div
                            v-for="error in errors.driver_hp"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-md-3">
                        <label class="form-label">Kategori</label>
                      </div>
                      <div class="col-md-8">
                        <v-select
                          :options="fetchCategory"
                          v-model="forms.category"
                          label="code"
                          return-object
                        ></v-select>
                        <div v-if="errors.category">
                          <div
                            v-for="error in errors.category"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-3">
                        <label class="form-label"
                          >Date<span class="wajib">*</span></label
                        >
                      </div>
                      <div class="col-md-8">
                        <date-range-picker
                          style="width: 100%"
                          control-container-class="form-control"
                          :locale-data="locale"
                          v-model="dates"
                          :opens="opens"
                          :singleDatePicker="true"
                          :timePicker24Hour="true"
                          :timePicker="true"
                          :showWeekNumbers="false"
                          @update="changeDisabled"
                        >
                        </date-range-picker>

                        <div v-if="errors.date">
                          <div
                            v-for="error in errors.date"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-3">
                        <label class="form-label">Location</label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          id="location"
                          name="location"
                          v-model="forms.location"
                          class="form-control"
                          placeholder="Location"
                        />
                        <div v-if="errors.location">
                          <div
                            v-for="error in errors.location"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-3">
                        <label class="form-label">Investigation</label>
                      </div>
                      <div class="col-md-8">
                        <textarea
                          name="investigation"
                          class="form-control"
                          v-model="forms.investigation"
                          rows="3"
                        ></textarea>
                        <div v-if="errors.investigation">
                          <div
                            v-for="error in errors.investigation"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-3">
                        <label class="form-label">Action Plan</label>
                      </div>
                      <div class="col-md-8">
                        <textarea
                          name="action_plan"
                          class="form-control"
                          v-model="forms.action_plan"
                          rows="3"
                        ></textarea>
                        <div v-if="errors.action_plan">
                          <div
                            v-for="error in errors.action_plan"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                       <div class="row mb-2">
                      <div class="col-md-3">
                        <label class="form-label">Date Complete</label>
                      </div>
                      <div class="col-md-8">
                        <Datepicker
                          v-model="forms.date_complete"
                          :bootstrap-styling="true"
                          format="dd-MM-yyyy"
                          placeholder="Please Select Date..."
                           :disabledDates="disabledDate"
                        ></Datepicker>
                        <div v-if="errors.date_complete">
                          <div
                            v-for="error in errors.date_complete"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                     <div class="card-footer">
                  <button
                    class="btn btn-warning text-black fw-bolder btn-icon-text"
                    @click="backForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="arrow-left"></i>
                    {{ $t("backMess") }}
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    class="btn btn-primary float-end btn-sm"
                    type="submit"
                  >
                    <i class="link-icon" data-feather="save"></i>
                    {{ $t("submitFormTxt") }}
                  </button>
                </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <modal-unloading
          :showModal="showModalUnloading"
          :data="this.dataModal"
          @closeModal="closeMyModal"
        >
        </modal-unloading>
        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import modalUnloading from "@/components/modal/modalUnloading.vue";
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";

import moment from 'moment'
export default {
  name: "KriminalitasAdd",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "modal-unloading": modalUnloading,
    DateRangePicker,
    Datepicker,
  },
  data() {
    const startDate = new Date();
    const endDate = new Date();
    return {
      disabledBeforeLoad: true,

      driver_nmk: "",
      fetchDriverNmk: [],

      fetchCategory: [],
      category: "",

      vehicle_no: "",
      fetchVehicleNo: [],

      showModalUnloading: false,
      dataModal: {},
      locale: {
        format: "yyyy-mm-dd H:MM:ss",
      },
      dates: {
        startDate,
        endDate,
      },
      // disabledDate:{},

      opens: "center",
      forms: {},
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      permision_role: [],
      userData: "",
      companyCode: "",
    };
  },
  computed:{
    disabledDate(){
      return {
          to:new Date(this.dates.startDate),
        };
    }
  },
  methods: {
    changeDisabled(ev){
      if(new Date(ev.startDate) > new Date(this.forms.date_complete)){
        this.forms.date_complete = "";
      }
    },
    submitData(){
      let date_complete = moment(this.forms.date_complete).format("Y-M-DD");
      let date = moment(this.dates.startDate).format("Y-M-DD HH:mm:ss");
      let shipment_date = moment(this.forms.shipment_date).format("Y-M-DD");

      this.forms.vehicle_no = this.vehicle_no.vehicle_no;
      this.forms.driver_nmk = this.driver_nmk.driver_id;
      this.forms.date_complete = date_complete
      this.forms.date = date;
      this.forms.shipment_date = shipment_date;
      const baseURI = this.$settings.endPointCt + "criminality/update/"+this.forms.criminality_id;
      this.$http
        .patch(baseURI, this.forms, { timeout: 10000 })
        .then((response) => {
          this.isLoading = false
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
             var params = this.$onRandom(this.forms.criminality_id);
            window.location.href = "/kriminalitas/detail/" + params + "?status=200&msg=Successfully";
            this.success('Berhasil');
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        }).catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error("Please Check Your Internet Connection");
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }

          }


        });
      console.log(this.forms);
    },
    loadKategori(){
       const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=kricat`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchCategory = response.data.datas;
      });
    },
    openModal() {
      const baseURI = this.$settings.endPointCt + "blujay-order/select";
      this.$http.get(baseURI).then((response) => {
        this.dataModal = {
          detail: response.data.datas,
        };
        this.showModalUnloading = true;
      });
    },
    changeDriverNmk(driver) {
      this.forms.driver_name = driver.driver_name;
      this.forms.driver_hp = driver.hp1;
    },
    asyncSearchDriverNmk(ev) {
      const baseURI =
        this.$settings.endPointCt + `master-driver?driver_id=` + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDriverNmk = response.data.datas.data;
      });
    },
    loadDriverNmk() {
      const baseURI = this.$settings.endPointCt + `master-driver`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDriverNmk = response.data.datas.data;
      });
    },
    clearLoad() {
      this.disabledBeforeLoad = false;
      this.forms.load_id = "";
      this.forms.order_number = "";
      this.forms.shipment_date = "";
      this.forms.driver_nmk = "";
      this.driver_nmk = null;
      this.vehicle_no = null;
      this.forms.driver_name = "";
      this.forms.driver_id = "";
      this.forms.driver_hp = "";
    },
    loadData(id){
      this.isLoading = true
      const baseURI = this.$settings.endPointCt + "criminality/detail/" + id;
      return this.$http.get(baseURI,{ timeout: 10000 }).then((response) => {
          this.isLoading = false
          let data = response.data.datas;
          this.forms    = data;   
          this.vehicle_no = {
            vehicle_no: data.vehicle_no,
          };
          this.dates = {
            startDate : data.date,
            endDate : data.date,
          }
          this.driver_nmk = {
            driver_id: data.driver_nmk,
          };
      }).catch(error => {
          this.isLoading = false;
          if(error.code == "ECONNABORTED"){
            this.error("Please Check Your Internet Connection");
          }else{

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
            
          }


        });
    },
    loadVehicleNo() {
      const baseURI = this.$settings.endPointCt + `master-truck`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchVehicleNo = response.data.datas.data;
      });
    },
    asyncSearchVehicleNo(ev) {
      const baseURI =
        this.$settings.endPointCt + `master-truck?vehicle_no=` + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchVehicleNo = response.data.datas.data;
      });
    },
    resetForm() {
      this.forms = {};
    },
    backForm() {
      window.location.href = "/kriminalitas";
    },

    fade(sType) {
      this.isLoading = sType;
    },
    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "CriminalityController") {
          rolePermision = permision;
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
    closeMyModal(ev) {
      if (ev !== null) {
        console.log(ev);
        this.disabledBeforeLoad = false;
        this.forms.master_driver_id = ev.master_driver_id;
        this.forms.blujay_order_id = ev.blujay_order_id;
        this.forms.detail_order_id = ev.order_detail_id;
        this.forms.load_id = ev.load_id;

        this.vehicle_no = {
          vehicle_no: ev.vehicle_no,
        };
        this.driver_nmk = {
          driver_id : ev.driver_nmk
        }
        this.forms.driver_nmk = ev.driver_nmk;
        this.forms.driver_name = ev.driver_name;
        this.forms.driver_hp = ev.driver_hp;
        this.forms.route = ev.name_rute;
        this.forms.shipment_number = ev.shipment_number;
        this.forms.shipment_date = ev.shipment_date;
        this.forms.order_number = ev.shipment_number;
        // this.driver_nmk = ev.driver_nmk;
      }

      this.showModalUnloading = false;
    },
  },
  mounted() {
    this.fetchIt();
    this.loadVehicleNo();
    this.loadDriverNmk();
    this.loadKategori();
        var params = this.$onBehind(this.$route.params.id);
    this.loadData(params[0]);
  },
};
</script>

<style></style>
