import { render, staticRenderFns } from "./TemplateOrder.vue?vue&type=template&id=379f9a15&scoped=true"
import script from "./TemplateOrder.vue?vue&type=script&lang=js"
export * from "./TemplateOrder.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "379f9a15",
  null
  
)

export default component.exports