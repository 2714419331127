<template>
    <div>
      <!-- <div class="loader" v-if="this.isLoading"></div>
      <div class="loadertext" v-if="this.isLoading">
        {{ $t("loadingTxt") }}...
      </div> -->
      <div
        class="main-wrapper"
        
      >
        <!--begin::sidebar-->
        <sidebar-component classMenu="Dashboard"></sidebar-component>
        <!--end::sidebar-->
  
        <div class="page-wrapper">
          <!--begin::navbar-->
          <navbar-component></navbar-component>
          <!--end::navbar-->
  
          <!--begin::content-->
          <div class="page-content">
            <div
              class="
                d-flex
                justify-content-between
                align-items-center
                flex-wrap
                grid-margin
              "
            >
              <div>
                <h4 class="mb-3 mb-md-0">Welcome to Vendor Analyst Dashboard</h4>
              </div>
            </div>
  
            <div class="row">
                <iframe title="Vendor_Analyst_Report" width="1140" height="800" src="https://app.powerbi.com/reportEmbed?reportId=2fd0b865-3695-442b-88c3-1d83513fa35a&autoAuth=true&ctid=124bede5-2b80-42b5-8bee-f720a5bdfcb9"  frameborder="0" allowFullScreen="true"></iframe>
            </div>
  
           
          </div>
          <!--end::content-->
  
          <!--begin::footer-->
          <footer-component></footer-component>
          <!--end::footer-->
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import sidebarComponent from "./_partials/_sidebar";
  import navbarComponent from "./_partials/_navbar";
  import footerComponent from "./_partials/_footer";
  import moment from "moment";
  
  // import DateRangePicker from 'vue2-daterange-picker'
  // import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
  
  // import { Bar, Line as LineChartGenerator,Pie } from 'vue-chartjs'



  
  export default {
    name: "UserDashboard",
    components: {
      "sidebar-component": sidebarComponent,
      "navbar-component": navbarComponent,
      "footer-component": footerComponent,
      // Bar,LineChartGenerator,Pie,
    },
    props: {
     
      chartId: {
        type: String,
        default: "bar-chart",
      },
      datasetIdKey: {
        type: String,
        default: "label",
      },
      width: {
        type: Number,
        default: 200,
      },
      height: {
        type: Number,
        default: 300,
      },
      cssClasses: {
        default: "",
        type: String,
      },
      styles: {
        type: Object,
        default: () => {},
      },
      plugins: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        isLoadingGrafik1:true,
        isLoadingGrafik2:true,
        isLoadingGrafik3:true,
        isLoadingGrafik4:true,
        dateRange: "",
        opens: "center",
        locale: {
          format: "yyyy-mm-dd",
        },
  
        pickupDates: {
          startDate: null,
          endDate: null,
        },
        chartDataPie: {
          labels: ["VueJs", "EmberJs", "ReactJs", "AngularJs"],
          datasets: [
            {
              backgroundColor: ["#41B883", "#E46651", "#00D8FF", "#DD1B16"],
              data: [40, 20, 80, 10],
            },
          ],
        },
        chartDataLine: {
          labels: [],
          datasets: [],
        },
        availableChartDataLineAvailable: false,
        availableChartDataLineAvailableKeDua: false,
        availableChartDataLineAvailableKeTiga: false,
        availableChartDataLineAvailableKeEmpat: false,
        chartDataLineKeDua: {
          labels: [],
          datasets: [],
        },
        chartDataLineKeTiga: {
          labels: [],
          datasets: [],
        },
        chartDataLineKeEmpat: {
          labels: [],
          datasets: [],
        },
        chartDataKeDua: {
          labels: [
           
          ],
          datasets: [
            
          ],
        },
        chartData: {
          labels: [],
          datasets: [
            
          ],
        },
        chartOptionsPie: {
          responsive: true,
          maintainAspectRatio: false,
        },
        chartOptions: {
          scales: {
            y: {
              stacked: true,
              ticks: {
                beginAtZero: true,
                min: 0,
                stepSize: 10,
              },
              gridLines: {
                display: true,
              },
            },
            x: {
              stacked: true,
              gridLines: {
                display: false,
              },
            },
          },
          legend: {
            display: true,
            position: "left",
          },
          responsive: true,
          maintainAspectRatio: false,
        },
        maxToasts: 100,
        position: "up right",
        closeBtn: true,
        isLoading: false,
        errors: [],
        detailUser: {},
        companyCode: "",
        company: "",
        langs: ["id", "en"],
        fetchCompany: [],
        name: "",
        lineChart: {
          labels: ["COBA 1", "COBA 2", "COBA 3"],
          datas: [100, 50, 70],
        },
      };
    },
    computed: {
      myStyles() {
        return {
          height: "500px",
          position: "relative",
        };
      },
    },
    watch: {},
    methods: {
      formatDate(val) {
        return val ? moment(val).format("YYYY-MM-DD") : "";
      },
  
      submitData() {
        this.fade(true);
    
     
        const startDate = this.formatDate(this.pickupDates.startDate);
        const endDate = this.formatDate(this.pickupDates.endDate);
        const baseURI =
          this.$settings.endPoint +
          "chart/one?company_id=" +
          this.company.company_id +
          "&start_date=" +
          startDate +
          "&end_date=" +
          endDate;
  
        return this.$http
          .get(baseURI, { timeout: 10000 })
          .then((response) => {
            const rows = response.data.datas;
  
            if (rows.labels.length) {
              // this.chartDataLine = {
              //   labels:rows.labels,
              //   datasets:rows.dataSet
              // }
            } else {
              // this.chartDataLine = {
              //   labels:[],
              //   datasets:[]
              // }
              this.availableChartDataLineAvailable = true;
            }
  
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.code == "ECONNABORTED") {
              this.error(this.$t("check_internet_connection_txt"));
            } else {
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            }
          });
      },
      generatorColor(){
          var mycolor = '#'+(Math.random()*0xFFFFFF<<0).toString(16);
          return mycolor;
      },
  
      loadData30DaysOrder() {
        this.isLoadingGrafik1=true;
        const baseURI =
          this.$settings.endPoint +
          "transport-order-header/dashboard-status-order-day"; // ?start=" + dataSend.start + "&end=" + dataSend.end
        this.$http
          .get(baseURI, { timeout: 10000 })
          .then((response) => {
            this.isLoading = false;
            
            if (response.status == 200) {
              var data30DaysOrder = response.data.datas;
              this.chartDataLine = {
                labels: data30DaysOrder.day,
                datasets: [
                  {
                    label: "ASSIGNED",
                    borderColor: "#05CBE1",
                    pointBackgroundColor: "white",
                    pointBorderColor: "white",
                    borderWidth: 1,
                    backgroundColor: this.getRandomColorHexRgbA(),
                    data: data30DaysOrder["ASSIGNED"],
                  },
  
                  {
                    label: "CANCELLED",
                    borderColor: "#05CBE1",
                    pointBackgroundColor: "white",
                    pointBorderColor: "white",
                    borderWidth: 1,
                    backgroundColor: this.getRandomColorHexRgbA(),
                    data: data30DaysOrder["CANCELLED"],
                  },
  
                  {
                    label: "NEW",
                    borderColor: "#05CBE1",
                    pointBackgroundColor: "white",
                    pointBorderColor: "white",
                    borderWidth: 1,
                    backgroundColor:this.getRandomColorHexRgbA(),
                    data: data30DaysOrder["NEW"],
                  },
                  {
                    label: "SUBMITTED",
                    borderColor: "#05CBE1",
                    pointBackgroundColor: "white",
                    pointBorderColor: "white",
                    borderWidth: 1,
                    backgroundColor: this.getRandomColorHexRgbA(),
                    data: data30DaysOrder["SUBMITTED"],
                  },
                  {
                    label: "RESCHEDULE - WAITING APPROVAL",
                    borderColor: "#05CBE1",
                    pointBackgroundColor: "white",
                    pointBorderColor: "white",
                    borderWidth: 1,
                    backgroundColor: this.getRandomColorHexRgbA(),
                    data: data30DaysOrder["RESCHEDULE - WAITING APPROVAL"],
                  },
  
                  {
                    label: "RESCHEDULE - NEED APPROVAL",
                    borderColor: "#05CBE1",
                    pointBackgroundColor: "white",
                    pointBorderColor: "white",
                    borderWidth: 1,
                    backgroundColor: this.getRandomColorHexRgbA(),
                    data: data30DaysOrder["RESCHEDULE - NEED APPROVAL"],
                  },
  
                  {
                    label: "RESCHEDULE - APPROVED",
                    borderColor: "#05CBE1",
                    pointBackgroundColor: "white",
                    pointBorderColor: "white",
                    borderWidth: 1,
                    backgroundColor: this.getRandomColorHexRgbA(),
                    data: data30DaysOrder["RESCHEDULE - APPROVED"],
                  },
  
                  {
                    label: "REROUTE - APPROVED",
                    borderColor: "#05CBE1",
                    pointBackgroundColor: "white",
                    pointBorderColor: "white",
                    borderWidth: 1,
                    backgroundColor:this.getRandomColorHexRgbA(),
                    data: data30DaysOrder["REROUTE - APPROVED"],
                  },
  
                  {
                    label: "REJECTED",
                    borderColor: "#05CBE1",
                    pointBackgroundColor: "white",
                    pointBorderColor: "white",
                    borderWidth: 1,
                    backgroundColor: this.getRandomColorHexRgbA(),
                    data: data30DaysOrder["REJECTED"],
                  },
                ],
              };
  
              this.isLoadingGrafik1=false;
            }
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.code == "ECONNABORTED") {
              this.error("Please Check Your Internet Connection");
            } else {
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  //this.$router.push("/page-not-found");
                }
              }
            }
          });
      },
  
  
  
  
      loadData30DaysOrderByTypeTruck() {
        this.isLoadingGrafik3=true;
        const baseURI =
          this.$settings.endPoint +
          "transport-order-header/dashboard-type-truck-day"; // ?start=" + dataSend.start + "&end=" + dataSend.end
        this.$http
          .get(baseURI, { timeout: 10000 })
          .then((response) => {
            this.isLoading = false;
  
            if (response.status == 200) {
              var data30DaysOrderTypeTruck = response.data.datas;
              var data30DaysOrderTypeTruckDataSet = response.data.datas.data;
              
              //var name= Object.keys(data30DaysOrderTypeTruckDataSet);
              //console.log(data30DaysOrderTypeTruckDataSet);
  
              this.chartDataLineKeTiga= {
                labels: data30DaysOrderTypeTruck.day,
                datasets: [],
              };
              //console.log(data30DaysOrderTypeTruckDataSet);
            
              for (const key in data30DaysOrderTypeTruckDataSet) {
  
                var dataSet={
                    label: key,
                    borderColor: "#05CBE1",
                    pointBackgroundColor: "white",
                    pointBorderColor: "white",
                    borderWidth: 1,
                    backgroundColor: this.getRandomColorHexRgbA(),
                    data: data30DaysOrderTypeTruckDataSet[key],
                };
                this.chartDataLineKeTiga.datasets.push(dataSet);
                // console.log(`${key}: ${data30DaysOrderTypeTruckDataSet[key]}`);
              }
  
              
              // this.chartDataLine = {
              //   labels: data30DaysOrder.day,
              //   datasets: [],
              // };
              this.isLoadingGrafik3=false;
            }
  
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.code == "ECONNABORTED") {
              this.error("Please Check Your Internet Connection");
            } else {
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  //this.$router.push("/page-not-found");
                }
              }
            }
          });
      },
  
  
      loadData12MonthOrderByTypeTruck() {
        this.isLoadingGrafik4=true;
        const baseURI =
          this.$settings.endPoint +
          "transport-order-header/dashboard-type-truck-month"; // ?start=" + dataSend.start + "&end=" + dataSend.end
        this.$http
          .get(baseURI, { timeout: 10000 })
          .then((response) => {
            this.isLoading = false;
  
            if (response.status == 200) {
              var data12MonthOrderTypeTruck = response.data.datas;
              var data12MonthOrderTypeTruckDataSet = response.data.datas.data;
              
          
              //console.log(data12MonthOrderTypeTruck);
  
              this.chartDataLineKeEmpat= {
                labels: data12MonthOrderTypeTruck.month,
                datasets: [],
              };
              
            
              for (const key in data12MonthOrderTypeTruckDataSet) {
  
                var dataSet={
                    label: key,
                    borderColor: "#05CBE1",
                    pointBackgroundColor: "white",
                    pointBorderColor: "white",
                    borderWidth: 1,
                    backgroundColor: this.getRandomColorHexRgbA(),
                    data: data12MonthOrderTypeTruckDataSet[key],
                };
                this.chartDataLineKeEmpat.datasets.push(dataSet);
                // console.log(`${key}: ${data12MonthOrderTypeTruckDataSet[key]}`);
              }
  
              
              this.isLoadingGrafik4=false;
            }
            
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.code == "ECONNABORTED") {
              this.error("Please Check Your Internet Connection");
            } else {
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  //this.$router.push("/page-not-found");
                }
              }
            }
          });
      },
  
      loadData12MonthOrder() {
        this.isLoadingGrafik2=true;
        const baseURI =
          this.$settings.endPoint +
          "transport-order-header/dashboard-status-order-month"; // ?start=" + dataSend.start + "&end=" + dataSend.end
        this.$http
          .get(baseURI, { timeout: 10000 })
          .then((response) => {
            this.isLoading = false;
  
            if (response.status == 200) {
              var data12MonthOrder = response.data.datas;
              this.chartDataLineKeDua = {
                labels: data12MonthOrder.month,
                datasets: [
                  {
                    label: "ASSIGNED",
                    borderColor: "#05CBE1",
                    pointBackgroundColor: "white",
                    pointBorderColor: "white",
                    borderWidth: 1,
                    backgroundColor:  this.getRandomColorHexRgbA(),
                    data: data12MonthOrder["ASSIGNED"],
                  },
  
                  {
                    label: "CANCELLED",
                    borderColor: "#05CBE1",
                    pointBackgroundColor: "white",
                    pointBorderColor: "white",
                    borderWidth: 1,
                    backgroundColor: this.getRandomColorHexRgbA(),
                    data: data12MonthOrder["CANCELLED"],
                  },
  
                  {
                    label: "NEW",
                    borderColor: "#05CBE1",
                    pointBackgroundColor: "white",
                    pointBorderColor: "white",
                    borderWidth: 1,
                    backgroundColor:  this.getRandomColorHexRgbA(),
                    data: data12MonthOrder["NEW"],
                  },
                  {
                    label: "SUBMITTED",
                    borderColor: "#05CBE1",
                    pointBackgroundColor: "white",
                    pointBorderColor: "white",
                    borderWidth: 1,
                    backgroundColor:  this.getRandomColorHexRgbA(),
                    data: data12MonthOrder["SUBMITTED"],
                  },
                  {
                    label: "RESCHEDULE - WAITING APPROVAL",
                    borderColor: "#05CBE1",
                    pointBackgroundColor: "white",
                    pointBorderColor: "white",
                    borderWidth: 1,
                    backgroundColor:  this.getRandomColorHexRgbA(),
                    data: data12MonthOrder["RESCHEDULE - WAITING APPROVAL"],
                  },
  
                  {
                    label: "RESCHEDULE - NEED APPROVAL",
                    borderColor: "#05CBE1",
                    pointBackgroundColor: "white",
                    pointBorderColor: "white",
                    borderWidth: 1,
                    backgroundColor:  this.getRandomColorHexRgbA(),
                    data: data12MonthOrder["RESCHEDULE - NEED APPROVAL"],
                  },
  
                  {
                    label: "RESCHEDULE - APPROVED",
                    borderColor: "#05CBE1",
                    pointBackgroundColor: "white",
                    pointBorderColor: "white",
                    borderWidth: 1,
                    backgroundColor: this.getRandomColorHexRgbA(),
                    data: data12MonthOrder["RESCHEDULE - APPROVED"],
                  },
  
                  {
                    label: "REROUTE - APPROVED",
                    borderColor: "#05CBE1",
                    pointBackgroundColor: "white",
                    pointBorderColor: "white",
                    borderWidth: 1,
                    backgroundColor: this.getRandomColorHexRgbA(),
                    data: data12MonthOrder["REROUTE - APPROVED"],
                  },
  
                  {
                    label: "REJECTED",
                    borderColor: "#05CBE1",
                    pointBackgroundColor: "white",
                    pointBorderColor: "white",
                    borderWidth: 1,
                    backgroundColor:  this.getRandomColorHexRgbA(),
                    data: data12MonthOrder["REJECTED"],
                  },
                ],
              };
  
              this.isLoadingGrafik2=false;
            }
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.code == "ECONNABORTED") {
              this.error("Please Check Your Internet Connection");
            } else {
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  //this.$router.push("/page-not-found");
                }
              }
            }
          });
      },
  
  
    
  
      asyncSearchCompany(ev) {
        const baseURI = this.$settings.endPoint + "company/select?code=" + ev;
  
        return this.$http.get(baseURI).then((response) => {
          this.fetchCompany = response.data.datas.data;
        });
      },
  
      getRandomColorHexRgb() {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        return "rgb(" + r + "," + g + "," + b + ")";
      },
      getRandomColorHexRgbA() {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        return "rgba(" + r + "," + g + "," + b + ",0.2)";
      },
      fade(sType) {
        this.isLoading = sType;
      },
  
      loading() {
        this.fade(true);
        setTimeout(() => {
          this.isLoading = false;
        }, 1000); // hide the message after 3 seconds
      },
  
      fetchIt() {
      const userDatas     = this.$getUserInfo();
        this.detailUser     = userDatas.sub;
        // this.permision_role = userDatas.sub.permision_role;
        let rolePermision = [];
        userDatas.sub.permision_role.forEach(function(permision){
          if(permision.permission.controller == 'VendorAnalystDashboardController'){
            rolePermision = permision;
            // console.log(permision);
          }
        });

        if(!rolePermision.method_read){
          this.$router.push("/authorized-error");
        }
        this.permision_role = rolePermision;

      },
  
      logout() {
        this.$logoutUser();
        this.$router.push("/");
      },
  
      resultError(data) {
        var count = Object.keys(data).length;
        for (var x = 0; x < count; x++) {
          var nameOb = Object.keys(data)[x];
          var objectData = data[nameOb];
          for (var y = 0; y < objectData.length; y++) {
            this.error(objectData[y]);
          }
        }
      },
  
      success(kata) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: kata,
        });
      },
  
      error(kata) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: kata,
        });
      },
    },
    events: {},
    created: function () {},
    mounted() {
      this.fetchIt();
      this.loading();
      // this.loadData30DaysOrder();
      // this.loadData12MonthOrder();
      // this.loadData30DaysOrderByTypeTruck();
      // this.loadData12MonthOrderByTypeTruck();
    },
  };
  </script>
  <style scoped>
  button.gm-ui-hover-effect {
    visibility: hidden;
  }
  .blur-content {
    filter: blur(5px);
  }
  </style>