var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"main-wrapper"},[_c('sidebar-component',{attrs:{"classMenu":"TemplateOrder"}}),_c('div',{staticClass:"page-wrapper"},[_c('navbar-component'),_c('div',{staticClass:"page-content"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center flex-wrap grid-margin"},[_c('div',{staticClass:"col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"float-start",staticStyle:{"margin-right":"5px"}},[_vm._v("Edit Do Number")]),_c('button',{staticClass:"btn btn-primary float-end btn-xs",staticStyle:{"margin-right":"5px"},on:{"click":function($event){$event.preventDefault();return _vm.createData()}}},[_c('i',{staticClass:"link-icon",attrs:{"data-feather":"plus"}}),_vm._v(" "+_vm._s(_vm.$t("createNew"))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('vue-good-table',{attrs:{"title":"list-order-ct","mode":"remote","select-options":{ 
                                            enabled: true,
                                            selectOnCheckboxOnly: true, 
                                            selectionText: _vm.$t('data_selected'), 
                                            clearSelectionText: _vm.$t('clear'), },"totalRows":_vm.totalRecords,"line-numbers":true,"isLoading":_vm.isLoading,"pagination-options":{
                                enabled: true,
                            },"rows":_vm.rows,"columns":_vm.columns},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"on-selected-rows-change":_vm.selectionChanged,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[(props.row.status == 'NEW')?_c('div',{staticClass:"btn-group"},[(_vm.permision_role.method_update)?_c('a',{attrs:{"href":"#","title":"Edit"},on:{"click":function($event){$event.preventDefault();return _vm.editData(props.index, props.row)}}},[_c('i',{staticClass:"mdi mdi-square-edit-outline",staticStyle:{"font-size":"16px"}})]):_vm._e(),(_vm.permision_role.method_delete)?_c('a',{attrs:{"href":"#","title":"Delete"},on:{"click":function($event){$event.preventDefault();return _vm.deleteData(props.index, props.row)}}},[_c('i',{staticClass:"mdi mdi-trash-can-outline",staticStyle:{"font-size":"16px"}})]):_vm._e()]):_vm._e()]):(props.column.field == 'entries')?_c('span',[_c('a',{staticStyle:{"color":"#ab0635","text-decoration":"none","cursor":"pointer"},attrs:{"title":"Detail Load Id"},on:{"click":function($event){$event.preventDefault();return _vm.detailPi(props.row)}}},[_vm._v(" "+_vm._s(props.row.entries)+" ")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[(_vm.btnProccess)?_c('button',{staticClass:"btn btn-warning btn-xs",on:{"click":_vm.ProsesOrders}},[_vm._v("Proccess")]):_vm._e()])])],1)])])])])])]),_c('footer-component')],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }