<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="LookupCodeEdit"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            ">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left: 25px">
                    Edit Lookup Code
                  </h6>
                  <button class="btn btn-default float-end" @click="resetForm" type="button">
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>

                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->
                  <form class="forms-sample" @submit.prevent="submitData" method="POST">
                    <div class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "></div>

                    <div class="row mb-3">
                      <div class="col-lg-3">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Unique Id</label>
                      </div>
                      <div class="col-lg-4">
                        <multiselect @search-change="asyncSearchUnique" @select="onSelectUnique" v-model="unique"
                          :options="fetchUnique" label="name" track-by="master_lookup_id" :custom-label="customLabel"
                          placeholede="Please Select">
                          <span slot="noResult">-</span>
                        </multiselect>

                        <div v-if="errors.unique_id">
                          <div v-for="error in errors.unique_id" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-3">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Dependent</label>
                      </div>
                      <div class="col-lg-8">

                        <multiselect @search-change="asyncSearchDependent" v-model="dependent" :options="fetchDependent"
                          label="dependent_name" track-by="dependent" placeholede="Please Select">
                          <span slot="noResult">-</span>
                        </multiselect>

                        <div v-if="errors.dependent">
                          <div v-for="error in errors.dependent" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-3">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px"> Sequential
                        </label>
                      </div>
                      <div class="col-lg-2">
                        <input type="number" class="form-control" id="Sequential" readonly v-model="forms.seq" />
                        <div v-if="errors.seq">
                          <div v-for="error in errors.seq" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-3">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Value</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="value" v-model="forms.value" />
                        <div v-if="errors.value">
                          <div v-for="error in errors.value" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>



                    <div class="row mb-3">
                      <div class="col-lg-3">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Active</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="checkbox" id="active" name="active" v-model="forms.active">
                        <!-- <input
                          type="checkbox"
                          class="form-control"
                          id="value"
                         
                          v-model="forms.active"
                        /> -->
                        <div v-if="errors.active">
                          <div v-for="error in errors.active" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card-footer">
                      <button class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        " @click="backForm" type="button">
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>

                      &nbsp;&nbsp;&nbsp;
                      <button class="btn btn-primary float-end btn-sm" type="submit">
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import multiselect from "vue-multiselect";

export default {
  name: "LookupCodeEdit",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "multiselect": multiselect
  },
  data() {
    return {
      fetchDependent: [],
      fetchUnique: [],
      unique: '',
      dependent: '',
      permision_role: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      regionCode: "",
      forms: [],
    };
  },
  watch: {},
  methods: {
    resetForm() {
      this.forms = [];
    },

    backForm() {
      window.location.href = "/lookup-code";
    },

    loadData(id) {
      const baseURI = this.$settings.endPointCt + "master-lookup/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        this.forms = response.data.datas;
        this.unique = this.forms.master_lookup;
        this.dependent = {
          'dependent': this.forms.dependent.master_lookup_detail_id,
          'dependent_name': this.forms.dependent.value,
        }

        this.onSelectUnique(this.unique);


        // this.form={ name: 'asep', usernmae:res.username };
      });
    },


    loadDependent() {

      const baseURI = this.$settings.endPointCt + "master-lookup/select-lookup-detail?unique_id=" + this.unique.unique_id;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDependent = response.data.datas.data;
      });
    },


    onSelectUnique(ev) {
      const baseURI = this.$settings.endPointCt + "master-lookup/select-lookup-detail?unique_id=" + ev.unique_id;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDependent = response.data.datas.data;
      });
    },


    asyncSearchDependent(ev) {
      console.log(this.unique.unique_id);
      const baseURI =
        this.$settings.endPointCt + "master-lookup/select-lookup-detail?dependent_search=" + ev + '&unique_id=' + this.unique.unique_id;

      return this.$http.get(baseURI).then((response) => {
        this.fetchDependent = response.data.datas.data;
      });
    },


    loadUnique() {
      let baseURI = ""
      if (this.permision_role.method_custom5 == 0) {
        baseURI = this.$settings.endPointCt + "master-lookup/select-lookup?unique_id_search=custan";
      } else {
        baseURI = this.$settings.endPointCt + "master-lookup/select-lookup";
      }

      return this.$http.get(baseURI).then((response) => {
        this.fetchUnique = response.data.datas.data;
      });
    },

    asyncSearchUnique(ev) {

      let baseURI = ""
      if (this.permision_role.method_custom5 == 0) {
        baseURI = this.$settings.endPointCt + "master-lookup/select-lookup?unique_id_search=custan";
      } else {
        baseURI = this.$settings.endPointCt + "master-lookup/select-lookup?unique_id_search=" + ev;
      }

      return this.$http.get(baseURI).then((response) => {
        this.fetchUnique = response.data.datas.data;
      });
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "MasterLookupController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_update) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },

    submitData() {
      this.fade(true);
      this.forms.active = this.forms.active == true ? 1 : 0;
      this.forms.dependent = this.dependent.dependent;
      this.forms.master_lookup_id = this.unique.master_lookup_id;

      const baseURI =
        this.$settings.endPointCt +
        "master-lookup/update/" +
        this.forms.master_lookup_detail_id;
      this.$http
        .patch(baseURI, this.forms)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            this.success(response.data.datas.messages);

            //window.history.back();
            var params = this.$onRandom(this.forms.master_lookup_detail_id);
            window.location.href = "/lookup-code/detail/" + params + "?status=200&msg=Successfully";
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.$router.push("/server-error");
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    customLabel(item) {

      return `${item.unique_id} - ${item.description}`;
    },


    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () { },
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.loadData(params[0]);


    this.loadUnique();
    this.fetchIt();
  },
};
</script>
<style scoped></style>