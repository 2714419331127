<template>
  <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title badge bg-primary">
            {{ data.order_status.toUpperCase().replace("_", " ") }}
          </h5>
          <div class="loadertext" v-if="this.isLoading2"> {{ $t('loadingTxt') }}...</div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal', null)"
            aria-label="btn-close"></button>
        </div>

        <div class="modal-body">
          <vue-good-table title="list-user" mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
            :line-numbers="true" :isLoading.sync="isLoading" :pagination-options="{
    enabled: false,
  }" :rows="rows" :columns="columns">
            <template slot="table-row" slot-scope="props">

              <span v-if="props.column.field == 'action'">
                <a href="#" title="Edit" @click.prevent="checkManifest(props.index, props.row)">
                  Check Manifest

                </a>
              </span>
            </template>
          </vue-good-table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal', null)">
            {{ $t("close_txt") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment';
// import { getAuthToken } from '@/middleware/auth';
export default {

  components: {},
  name: "ModalUnloading",
  emits: ["closeModal"],
  props: {
    showModal: Boolean,
    data: {},
    by: String,
  },
  watch: {
    showModal: {
      handler(newVal) {
        this.active = newVal;
        this.orderStatus = this.data.order_status;



        if (this.active) {
          if (this.data.order_status == "new") {
            this.columns = this.columnsChain;
          } else if (
            this.data.order_status == "submitted" ||
            this.data.order_status == "accepted"
          ) {
            this.columns = this.columnsNew;
          } else {
            this.columns = this.columnsAssgined;
          }
          this.loadItems();
        }
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      isLoading2: false,
      isLoading: false,
      active: this.showModal,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 1000,
      },
      columns: [],
      columnsPNL: [
        {
          label: "Action",
          field: "action",
          filterOptions: {
            enabled: false, // enable filter for this column
            filterValue: "", // initial populated value for this filter
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Customer",
          field: "customer_name",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Customer", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Load Id",
          field: "loadid",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Load Id", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: "Shipwithref",
          field: "shipwith_ref",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Type Truck", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },



        {
          label: this.$t("pickup_plan_txt"),
          field: "pick_location",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("pickup_plan_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("dest_plan_txt"),
          field: "drop_location",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("dest_plan_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },




        {
          label: "Carrier",
          field: "carrier_name",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By carrier", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },



        {
          label: "PNL Value",
          field: "pnl_value",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: "PNL Percent",
          field: "pnl_percent",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: "Branch",
          field: "branch",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: "Vehicle Type",
          field: "vehicle_type",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: "Reason",
          field: "reason",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


      ],
      columnsChain: [
        {
          label: "Action",
          field: "action",
          filterOptions: {
            enabled: false, // enable filter for this column
            filterValue: "", // initial populated value for this filter
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Company",
          field: "company_name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Company", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Order No",
          field: "order_no",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Order No", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "ShipWithRef",
          field: "shipwith_ref",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Order Date",
          field: "order_date",
          thClass: "text-left",
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd MMM yyyy h:mm:ss a", //'dd MMM yyyy',
          filterOptions: {
            customFilter: true,
            enabled: false, // enable filter for this column
            placeholder: "Filter By Order Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Order Status",
          field: "order_status",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Order Status", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [
              "NEW",
              "SUBMITTED",
              "ASSIGNED",
              "CANCELLED",
              "RESCHEDULE - NEED APPROVAL",
              "RESCHEDULE - WAITING APPROVAL",
              "RESCHEDULE - APPROVED",
              "REROUTE - NEED APPROVAL",
              "REROUTE - WAITING APPROVAL",
              "REROUTE - APPROVED",
              "REJECTED",
              "COMPLETED",
              "VOID",
            ], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Pick Plan",
          field: "pick_plan",
          thClass: "text-left",
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd MMM yyyy h:mm:ss a", //'dd MMM yyyy',
          filterOptions: {
            customFilter: true,
            enabled: false, // enable filter for this column
            placeholder: "Filter By Pick Plan", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Pick Name",
          field: "pick_name",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Direction", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Dest Plan",
          field: "dest_plan",
          thClass: "text-left",
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd MMM yyyy h:mm:ss a", //'dd MMM yyyy',
          filterOptions: {
            customFilter: true,
            enabled: false, // enable filter for this column
            placeholder: "Filter By Dest Plan", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Dest Name",
          field: "dest_name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Dest Name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Vehicle No",
          field: "vehicle_no",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Vehicle No", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Driver",
          field: this.driverIdName,
          width: "100px",
          sortable: false,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Driver Name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Status",
          field: "order_status",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Dest Name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Status",
          field: "order_status",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Status TMS",
          field: "status_tms",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Dest Name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },




        {
          label: "Shipment Rate",
          field: "shipment_rate",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Remarks",
          field: "remarks",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
      ],
      columnsNew: [
        {
          label: "Action",
          field: "action",
          filterOptions: {
            enabled: false, // enable filter for this column
            filterValue: "", // initial populated value for this filter
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("submit_time_txt"),
          field: "submit_time",
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd MMM yyyy h:mm:ss a", //'dd MMM yyyy',
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("submit_time_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        // {
        //   label: this.$t("postpone_time_txt"),
        //   field: "postpone_time",
        //   type: 'date',
        //   dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
        //   dateOutputFormat: 'dd MMM yyyy h:mm:ss a',//'dd MMM yyyy',
        //   filterOptions: {
        //     enabled: false, // enable filter for this column
        //     placeholder: "Filter By "+this.$t("postpone_time_txt"), // placeholder for filter input
        //     filterValue: "", // initial populated value for this filter
        //     filterDropdownItems: [], // dropdown (with selected values) instead of text input
        //     trigger: "enter", //only trigger on enter not on keyup
        //   },
        // },
        {
          label: this.$t("companyTxt"),
          field: "company.name",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("companyTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: this.$t("order_no_txt"),
          field: "order_no",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("order_no_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "ShipWithRef",
          field: "shipwith_ref",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Shipment Rate",
          field: "shipment_rate",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("order_date_txt"),
          field: "order_date",
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd MMM yyyy h:mm:ss a", //'dd MMM yyyy',
          // dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          // dateOutputFormat: 'dd MMM yyyy',
          filterOptions: {
            customFilter: true,
            enabled: false, // enable filter for this column
            placeholder: "Filter By Order Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("order_status_txt"),
          field: "order_status",
          //   filterOptions: {
          //     enabled: false, // enable filter for this column
          //     placeholder: "Filter By "+this.$t("order_status_txt"), // placeholder for filter input
          //     filterValue: "", // initial populated value for this filter
          //     filterDropdownItems: ['SUBMITTED','POSTPONE'], // dropdown (with selected values) instead of text input
          //     trigger: "enter", //only trigger on enter not on keyup
          //   },
        },

        {
          label: "Status Tms",
          field: "status_tms",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("order_status_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter

            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("pick_name_txt"),
          field: "pick_name",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("pick_name_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("dest_name_txt"),
          field: "dest_name",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("dest_name_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Type Truck",
          field: "type_truck",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Type Truck", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("transport_order_template_description_txt"),
          field: "transport_order_template.description",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder:
              "Filter By " +
              this.$t("transport_order_template_description_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("tonnage_txt"),
          field: "tonnage",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "> 0", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("cubic_txt"),
          field: "transport_order_template.cubic",
          sortable: false,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "> 0", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("pickup_plan_txt"),
          field: "pick_plan",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("pickup_plan_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("dest_plan_txt"),
          field: "dest_plan",
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd MMM yyyy h:mm:ss a", //'dd MMM yyyy',
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("dest_plan_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Vehicle No",
          field: "vehicle_no",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By vehicle no", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Driver",
          field: "driver_name",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Type Truck", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Auto Load",
          field: "auto_load",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Auto Load",
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Carier",
          field: "carrier",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Carier", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Remarks",
          field: "remarks",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Create by",
          field: "create_by",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
      ],
      columnsAssgined: [
        {
          label: "Action",
          field: "action",
          filterOptions: {
            enabled: false, // enable filter for this column
            filterValue: "", // initial populated value for this filter
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {

          label: "Status Queue",
          field: "queue_rpa.status",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Pick Plan",
          field: "pick_plan",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Date Send RPA",
          field: "queue_rpa.date_send_rpa",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Date Schedule RPA",
          field: "queue_rpa.schedule_send_rpa",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: "Date Receipt RPA",
          field: "queue_rpa.date_receipt_rpaToCt",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Status",
          field: "order_status",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Status", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Status TMS",
          field: "status_tms",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Status", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Customer",
          field: "company.name",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Customer", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Vehicle No",
          field: "vehicle_no",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Vehicle No", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Type Truck",
          field: "type_truck",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Type Truck", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Driver",
          field: "driver_name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Type Truck", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Load Id",
          field: "load_id",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Load Id", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Shipment Number",
          field: "order_no",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Order No", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "ShipWithRef",
          field: "shipwith_ref",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ShipWithRef", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Planing Pada",
          field: "accept_time",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Planing", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("pickup_plan_txt"),
          field: "pick_plan",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("pickup_plan_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Pick Location",
          field: "pick_name",

          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t("pick_plan_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Dest Location",
          field: "dest_name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Destination", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("dest_plan_txt"),
          field: "dest_plan",
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd MMM yyyy h:mm:ss a", //'dd MMM yyyy',
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("dest_plan_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Actual Duration",
          field: "actual_duration",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Destination", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Tonnage (kg)",
          field: "tonnage",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Tonnage", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Location",
          field: "dest_location",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Tonnage", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Auto Load",
          field: "auto_load",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Auto Load", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Carier",
          field: "carrier",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Carier", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Assign Time",
          field: "assign_time",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Assign by",
          field: "assign_by",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Remarks",
          field: "remarks",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },




      ],
      rows: [],
      rowsSelection: [],
      locale: [],
      loadId: "",
      orderStatus: "",
      rules: {
        select: [(v) => !!v || "Item is required"],
      },
    };
  },
  methods: {
    submitParent(index, row) {
      this.$emit("closeModal", row);
    },

    checkMiddleware(row) {
      // let token = getAuthToken();
      this.isLoading2 = true;
      var pick_plan = moment(String(row.pick_plan)).format('YYYY-MM-DD');
      const baseURI = this.$settings.endPointMiddleware + `v2/chain/bca-bank-transaction?type=DP&date=${pick_plan}&load_id=` + row.load_id;

      return this.$http
        .get(baseURI, {
          timeout: 10000
        })
        .then((response) => {

          var data = response.data.datas;
          var pesan = ``;
          if (data.length != 0) {

            if (data[0].status_code == 'NEW') {

              pesan = `
                  <table>
                    <tr>
                      <th style="text-align:left;">Load ID (Blujay)</th>
                      <td>:</td>
                      <td align="left">${row.load_id}</td>
                    </tr>
                    <tr>
                      <th style="text-align:left;">Status Manifest (SM)</th>
                      <td>:</td>
                      <td align="left">${row.status_}</td>
                    </tr>
                    <tr>
                      <th style="text-align:left;">Check Middleware</th>
                      <td>:</td>
                      <td align="left" style="background:green;color:white;">${data[0].status_code}</span></td>
                    </tr>

                     <tr>
                        <th style="text-align:left;">Information</th>
                        <td>:</td>
                        <td align="left">Please wait the transaction is being Processed</td>
                      </tr>

                  </table>`;

            } else if (data[0].status_code == 'SUCCESS') {
              pesan = `
                  <table>
                    <tr>
                      <th style="text-align:left;">Load ID (Blujay)</th>
                      <td>:</td>
                      <td align="left">${row.load_id}</td>
                    </tr>
                    <tr>
                      <th style="text-align:left;">Status Manifest (SM)</th>
                      <td>:</td>
                      <td align="left">${row.status_}</td>
                    </tr>
                    <tr>
                      <th style="text-align:left;">Check Middleware</th>
                      <td>:</td>
                      <td align="left" style="background:green;color:white;">${data[0].status_code}</span></td>
                    </tr>

                      <tr>
                        <th style="text-align:left;">Information</th>
                        <td>:</td>
                        <td align="left">The Inquiry status state that driver already receive the payment please proceed with the shipment</td>
                      </tr>
                  </table>`;
            } else if (data[0].status_code == 'WAITING FOR APPROVAL') {
              pesan = `
                  <table>
                    <tr>
                      <th style="text-align:left;">Load ID (Blujay)</th>
                      <td>:</td>
                      <td align="left">${row.load_id}</td>
                    </tr>
                    <tr>
                      <th style="text-align:left;">Status Manifest (SM)</th>
                      <td>:</td>
                      <td align="left">${row.status_}</td>
                    </tr>
                    <tr>
                      <th style="text-align:left;">Check Middleware</th>
                      <td>:</td>
                      <td align="left" style="background:green;color:white;">${data[0].status_code}</span></td>
                    </tr>

                      <tr>
                        <th style="text-align:left;">Information</th>
                        <td>:</td>
                        <td align="left">
                            <p>Please contact the Higher Ups to approve this payment</p>
                            <p>JAKARTA - ROVIANSYAH WARGANEGARA</p>
                            <p>SURABAYA - SONY SONAKA</p>
                            <p>SEMARANG - TRI BUDI MULYANTO</p>
                            <p>MEDAN - TOTI MANUARA / DANIEL SIHOMBING</p>
                          </td>
                      </tr>
                  </table>`;
            } else if (data[0].status_code == 'FAILED') {
              pesan = `
                  <table>
                    <tr>
                      <th style="text-align:left;">Load ID (Blujay)</th>
                      <td>:</td>
                      <td align="left">${row.load_id}</td>
                    </tr>
                    <tr>
                      <th style="text-align:left;">Status Manifest (SM)</th>
                      <td>:</td>
                      <td align="left">${row.status_}</td>
                    </tr>
                    <tr>
                      <th style="text-align:left;">Check Inquiry BCA</th>
                      <td>:</td>
                      <td align="left"><span class="btn btn-primary check-inqury-button" >Check Inquiry</button></span></td>
                    </tr>
                  </table>`;
            }



          } else {

            pesan = `
              <table>
                <tr>
                  <th style="text-align:left;">Load ID (Blujay)</th>
                  <td>:</td>
                  <td align="left">${row.load_id}</td>
                </tr>
                <tr>
                  <th style="text-align:left;">Check Middleware</th>
                  <td>:</td>
                  <td align="left" style="background:red;color:white">NO Load ID Found</td>
                </tr>
                <tr>
                  <th style="text-align:left;">Information</th>
                  <td>:</td>
                  <td align="left">Please Contact IT</td>
                </tr>
              </table>`;



          }


          if (response.status == 200) {
            this.isLoading2 = false;
            //this.isLoading = false;

            this.$swal({
              backdrop: true,
              width: 600,
              height: 600,
              title: "Information",
              html: pesan,
              showConfirmButton: false,
              showCloseButton: true,
              allowOutsideClick: true,
              allowEscapeKey: true,

            });
            if (data.length != 0 && data[0].status_code == 'FAILED') {
              this.attachInquryListener(row, data[0].bank_transaction_id);
            }

          }



          console.log(this.rows);
        }).catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                // this.resultError(error.response.data.errors);
                this.error(this.errors)
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });

    },

    checkManifest(index, row) {
      this.isLoading2 = true;

      const baseURI = this.$settings.endPoint + "control-tower/transport-order-header/get-manifest-state/" + row.load_id;

      return this.$http
        .get(
          baseURI,
          { timeout: 10000 }
        )
        .then((response) => {


          var pesan = ``;
          // row.status_ = response.data.datas;

          var status = response.data.datas;
          row['status_'] = response.data.datas;
          if (status == 'Requested' || status == 'Downloaded' || status == 'Reconciled') {
            pesan = `
            <table>
              <tr>
                <th style="text-align:left;">Load ID (Blujay)</th>
                <td>:</td>
                <td align="left">${row.load_id}</td>
              </tr>
              <tr>
                <th style="text-align:left;">Status Manifest (SM)</th>
                <td>:</td>
                <td align="left">${status}</td>
              </tr>
              <tr>
                <th style="text-align:left;">Check Middleware</th>
                <td>:</td>
                <td align="left"><span class="btn btn-primary check-middleware-button" >Check Middleware</button></span>
              </tr>
            </table>`;


          } else if (status == 'Completed') {

            pesan = `
            <table>
              <tr>
                <th style="text-align:left;">Load ID (Blujay)</th>
                <td>:</td>
                <td align="left">${row.load_id}</td>
              </tr>
              <tr>
                <th style="text-align:left;">Status Manifest (SM)</th>
                <td>:</td>
                <td align="left">${status}</td>
              </tr>
              <tr>
                <th style="text-align:left;">Information</th>
                <td>:</td>
                <td align="left">This Load Already Completed <p>if the driver haven’t received the money please Void the load, make another shipment and contact IT to void the manifest</p></td>
              </tr>
            
            </table>`;



          } else {


            pesan = `
            <table>
              <tr>
                <th style="text-align:left;">Load ID (Blujay)</th>
                <td>:</td>
                <td align="left">${row.load_id}</td>
              </tr>
              <tr>
                <th style="text-align:left;">Status Manifest (SM)</th>
                <td>:</td>
                <td align="left">${status}</td>
              </tr>
              <tr>
                <th style="text-align:left;">Information</th>
                <td>:</td>
                <td align="left">This Load has not downloaded <p>Please Contact driver to download the load first</p></td>
              </tr>
            </table>`;



          }


          if (response.status == 200) {
            this.isLoading2 = false;
            //this.isLoading = false;

            this.$swal({
              backdrop: true,
              title: "Information",
              html: pesan,
              width: 600,
              height: 600,
              showConfirmButton: false,
              showCloseButton: true,
              allowOutsideClick: true,
              allowEscapeKey: true,


            });
            this.attachMiddlewareListener(row);
          }



          console.log(this.rows);
        }).catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {



            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                // this.resultError(error.response.data.errors);
                var pesan = `
              <table>
                <tr>
                  <th style="text-align:left;" width="200px">Load ID (Blujay)</th>
                  <td>:</td>
                  <td align="left">${row.load_id}</td>
                </tr>
                <tr>
                  <th style="text-align:left;" width="200px">Status Manifest</th>
                  <td>:</td>
                  <td align="left" style="background:red;color:white">${this.errors}</td>
                </tr>
                <tr>
                  <th style="text-align:left;" width="200px">Information</th>
                  <td>:</td>
                  <td align="left">This Load has not been Send to MobileStar Please Appoint The Driver / Check The Rate As the Payable / Billable is not found </td>
                </tr>
              </table>`;


                this.$swal({
                  backdrop: true,
                  title: "Information",
                  html: pesan,
                  width: 600,
                  height: 600,
                  showConfirmButton: false,
                  showCloseButton: true,
                  allowOutsideClick: true,
                  allowEscapeKey: true,
                });

                //this.error(this.errors)

              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });

    },



    checkInqury(row, bank_transaction_id) {
      this.isLoading2 = true;

      const baseURI = this.$settings.endPointMiddleware + `v2/chain/bca-inquery/` + bank_transaction_id;

      return this.$http
        .get(
          baseURI,
          { timeout: 10000 }
        )
        .then((response) => {

          var datas = response.data.datas;
          var pesan = ``;
          if (datas.responseMessage == 'Transaction Not Found') {
            pesan = `
            <table>
              <tr>
                <th style="text-align:left;">Load ID (Blujay)</th>
                <td>:</td>
                <td align="left">${row.load_id}</td>
              </tr>
              <tr>
                <th style="text-align:left;">Status Manifest (SM)</th>
                <td>:</td>
                <td align="left">Requested / Downloaded</td>
              </tr>
              <tr>
                <th style="text-align:left;">Check Inquiry BCA</th>
                <td>:</td>
                <td align="left" style="background:red;color:white;">NO TRANSACTION FOUND</span>
              </tr>
            </table>`;


          } else {


            pesan = `
            <table>
              <tr>
                <th style="text-align:left;">Load ID (Blujay)</th>
                <td>:</td>
                <td align="left">${row.load_id}</td>
              </tr>
              <tr>
                <th style="text-align:left;">Status Manifest (SM)</th>
                <td>:</td>
                <td align="left">REQUESTED / DOWNLOADED</td>
              </tr>
               <tr>
               <th style="text-align:left;">Check Inquiry BCA</th>
                <td>:</td>
                <td align="left" style="background:green; color:white;">Transaction Succes</td>
              </tr>
              <tr>
               <th style="text-align:left;">Information</th>
                <td>:</td>
                <td align="left"><p>This Load has not downloaded <p>Please Contact driver to download the load first</p></td>
              </tr>
            </table>`;



          }


          if (response.status == 200) {
            this.isLoading2 = false;
            //this.isLoading = false;

            this.$swal({
              backdrop: true,
              width: 600,
              height: 600,
              title: "Information",
              html: pesan,
              showConfirmButton: false,
              showCloseButton: true,
              allowOutsideClick: true,
              allowEscapeKey: true,

            });
            this.attachMiddlewareListener(row);
          }



          console.log(this.rows);
        }).catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                // this.resultError(error.response.data.errors);
                this.error(this.errors)
              } else if (error.response.status === 500) {

                var pesan = `
              <table>
                <tr>
                  <th style="text-align:left;" width="200px">Load ID (Blujay)</th>
                  <td>:</td>
                  <td align="left">${row.load_id}</td>
                </tr>
              
                <tr>
                  <th style="text-align:left;" width="200px">Information</th>
                  <td>:</td>
                  <td align="left">This transaction has not been sent to Bank, please contact IT</td>
                </tr>
              </table>`;


                this.$swal({
                  backdrop: true,
                  title: "Information",
                  html: pesan,
                  width: 600,
                  height: 600,
                  showConfirmButton: false,
                  showCloseButton: true,
                  allowOutsideClick: true,
                  allowEscapeKey: true,
                });


                // this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });

    },


    attachMiddlewareListener(row) {
      // Attach event listener to the dynamically created button
      document.querySelector('.check-middleware-button').addEventListener('click', () => {
        this.checkMiddleware(row);
      });
    },

    attachInquryListener(row, bank_transaction_id) {
      // Attach event listener to the dynamically created button
      document.querySelector('.check-inqury-button').addEventListener('click', () => {
        this.checkInqury(row, bank_transaction_id);
      });
    },

    removeOrderHeaderIds() {
      this.transport_order_header_ids = [];
      this.$refs["list-transport-orders"].unselectAllInternal();
      // console.log(this.$refs['list-transport-orders'].selectedRows)
    },

    selectionChanged(ev) {
      this.rowsSelection = [];
      this.transport_order_header_ids = [];
      this.rowsSelection = ev.selectedRows;

      this.rowsSelection.forEach((item) => {
        this.transport_order_header_ids.push(item.transport_order_header_id);
      });
    },

    loadItems() {
      this.rows = [];
      this.loadId = "";
      const baseURI =
        this.$settings.endPoint + "control-tower/transport-order-header";
      if (this.data.order_status == "ASSIGNED") {
        this.loadId = "UNEXIST";
      } else if (this.data.order_status == "BLUJAY ACCEPTED") {
        this.orderStatus = "BLUJAY ACCEPTED";
        this.loadId = "EXIST";
      } else if (this.data.order_status == 'PNL NEED APPROVAL') {
        this.orderStatus = 'PNL_NEED_APPROVAL'
      }
      // this.serverParams.columnFilters
      return this.$http
        .get(
          baseURI +
          `?is_exist=${this.loadId}&tonnage=${this.serverParams.columnFilters.tonnage}&pick_name=${this.serverParams.columnFilters.pick_name}&dest_name=${this.serverParams.columnFilters.dest_name}&carrier=${this.serverParams.columnFilters.carrier}&shipwith_ref=${this.serverParams.columnFilters.shipwith_ref}&driver_name=${this.serverParams.columnFilters.driver_name}&type_truck=${this.serverParams.columnFilters.type_truck}&vehicle_no=${this.serverParams.columnFilters.vehicle_no}&order_no=${this.serverParams.columnFilters.order_no}&pick_plan=${this.data.pick_plan}&order_status=${this.orderStatus}&load_id=${this.serverParams.columnFilters.load_id}&page=${this.serverParams.page}&limit=${this.serverParams.per_page}&detail_kanban=yes&by=` + this.by
        )
        .then((response) => {
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;


        })
        .catch((error) => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                // this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                // this.$router.push('/page-not-found');
              }
            }
          }
        });
    },

    loadItemsPNL(date) {

      this.rows = [];
      const baseURI = this.$settings.endPoint + "control-tower/transport-order-header";



      // var status=this.status
      //  console.log(this.status);

      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&pick_plan=${date}`,
          { timeout: 10000 }
        )
        .then((response) => {
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;

          this.isLoading = false;
        }).catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },
    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  mounted() {
    //   this.loadItems();
    // this.rows = this.props.data;
  },
};
</script>

<style scoped>
.blur-content {
  filter: blur(5px);
  pointer-events: none;
}

.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 120%;
  height: 100%;
  margin-left: -10%;
}
</style>