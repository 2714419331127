<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="RasioEdit"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start">Ubah BBM</h6>
                  <button
                    class="btn btn-default btn-sm float-end"
                    @click="resetForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="d-flex justify-content-between align-items-baseline mb-2"
                    ></div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="period_code" class="col-form-label">Truck Category</label>
                      </div>
                      <div class="col-lg-4">
                        
                        <v-select
                              :options="fetchTruckCategory"
                              v-model="truck_category"
                              label="Truck CateGory"
                              return-object
                             
                            ></v-select>
                        <div v-if="errors.truck_category">
                          <div
                            v-for="error in errors.truck_category"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      
                    </div>




                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="period_code" class="col-form-label">Origin State</label>
                      </div>
                      <div class="col-lg-4">
                        
                        <v-select
                              :options="fetchOriginState"
                              v-model="state_origin"
                              label="Origin State"
                              return-object
                             
                            ></v-select>
                        <div v-if="errors.state_origin">
                          <div
                            v-for="error in errors.state_origin"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="period_code" class="col-form-label">Destination State</label>
                      </div>
                      <div class="col-lg-4">
                        
                        <v-select
                              :options="fetchDestinationState"
                              v-model="state_destination"
                              label="Destination State"
                              return-object
                             
                            ></v-select>
                        <div v-if="errors.state_destination">
                          <div
                            v-for="error in errors.state_destination"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>                      
                    </div>



                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="period_code" class="col-form-label">Value</label>
                      </div>
                      <div class="col-lg-4">
                        <input
                          type="number"
                          class="form-control"
                          id="value"
                          v-model="forms.value"
                          
                        
                        />
                      
                        <div v-if="errors.value">
                          <div
                            v-for="error in errors.value"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>                      
                    </div>

                   
                    <div class="card-footer">
                      <button
                        class="btn btn-warning text-black fw-bolder btn-icon-text"
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                     

                      &nbsp;&nbsp;&nbsp;
                      <button
                        class="btn btn-primary float-end btn-sm"
                        type="submit"
                        style="margin-right: 5px"
                      >
                      <i class="mdi mdi-content-save" style="font-size:16px"></i>
                        {{ $t("submitFormTxt") }}
                      </button>

                     

                    </div>
                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
// import DateRangePicker from "vue2-daterange-picker";
// import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
// import moment from "moment";
export default {
  name: "PackageEdit",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    // DateRangePicker,
  },
  data() {
    return {
      locale: {
        format: "dd mmm yyyy H:MM:ss",
      },
      effectiveDate: {
        startDate: null,
        endDate: null,
      },
      expiredDate: {
        startDate: null,
        endDate: null,
      },
      showBtnCalculate:false,
      opens: "center",
      permision_role: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      fetchTruckCategory: [],
      fetchDestinationState: [],
      fetchOriginState: [],
      truck_category:"",
      state_destination:"",
      state_origin:"",
      forms: { value: 0 },
    };
  },
  watch: {},
  methods: {
    resetForm() {
      this.forms="";
    },

    changeCompany(ev) {
      this.forms.companyId = ev;
    },

   

    loadCompany() {
      const baseURI = this.$settings.endPoint + "company?limit=10";
      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },

    loadCompanyNotLinc() {
      const baseURI = this.$settings.endPoint + `company/select`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },

    asyncSearchCompany(ev) {
      const baseURI = this.$settings.endPoint + "company/select?code=" + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },

    asyncSearchCompanyNotLinc(ev) {
      const baseURI =
        this.$settings.endPoint +
        "company?name=" +
        ev +
        "&code=" +
        this.companyCode;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },

    loadData(id) {
      const baseURI = this.$settings.endPoint + "rm-mel-master/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        this.forms = response.data.datas;
        this.state_destination=this.forms.state_destination;
        this.truck_category=this.forms.truck_category;
        this.state_origin=this.forms.state_origin;


        
       
        //this.forms.companyId=response.data.datas.company;
        //this.forms.companyId.push(response.data.datas.company);
        //console.log(this.fetchCompany);
        // this.forms.companyId = {
        //   company_id: this.forms.company_id,
        //   name: this.forms.company.name,
        // };
        // this.form={ name: 'asep', usernmae:res.username };
      });
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      this.companyCode = this.detailUser.company_detail.code;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RmMelMasterController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },

    backForm() {
      window.location.href = "/master-mel";
    },

    calculate() {
      this.$swal({
        title: "Apakah Anda Yakin akan mengkalkulasi BBM ?",
        text: "Semua Record Payable akan mengalami kenaikan sesuai dengan exponensial kenaikan harga BBM ini",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        console.log(result)
        this.fade(true);
        const baseURI =
        this.$settings.endPoint +
        "rm-fuel-master/calculation/" +
        this.forms.rm_fuel_master_id;
      this.$http
        .patch(baseURI)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            this.success(response.data.datas.messages);

            //window.history.back();
            this.loadData(this.forms.rm_fuel_master_id);
            // var params = this.$onRandom(this.forms.rm_fuel_master_id);
            // window.location.href =
            //   "/master-bbm/detail/" + params + "?status=200&msg=Successfully";
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              // this.resultError(error.response.data.errors);
              if (this.errors.message) {
                this.error(this.errors.message);
              } else {
                this.resultError(error.response.data.errors);
              }
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
        
      });
    },


   

    canceled() {
      this.$swal({
        title: "Apakah Anda Yakin akan Cancel Calculate BBM ?",
        text: "Semua Record Payable dan Billable akan mengalami kembali sesuai harga BBM sebelumnya ",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        console.log(result);
        this.fade(true);
        const baseURI =
        this.$settings.endPoint +
        "rm-fuel-master/rollback-calculation/" +
        this.forms.rm_fuel_master_id;
      this.$http
        .patch(baseURI)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            this.success(response.data.datas.messages);

            //window.history.back();
            this.loadData(this.forms.rm_fuel_master_id);
            // var params = this.$onRandom(this.forms.rm_fuel_master_id);
            // window.location.href =
            //   "/master-bbm/detail/" + params + "?status=200&msg=Successfully";
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              // this.resultError(error.response.data.errors);
              if (this.errors.message) {
                this.error(this.errors.message);
              } else {
                this.resultError(error.response.data.errors);
              }
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
        
      });
    },

    submitData() {
      
      this.forms.truck_category=this.truck_category;
      this.forms.state_destination=this.state_destination;
      this.forms.state_origin=this.state_origin;

      this.fade(true);
   
      const baseURI =
        this.$settings.endPoint +
        "rm-mel-master/update/" +
        this.forms.rm_mel_master_id;
      this.$http
        .patch(baseURI, this.forms)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            this.success(response.data.datas.messages);

            //window.history.back();
            var params = this.$onRandom(this.forms.rm_mel_master_id);
            window.location.href =
              "/master-mel/detail/" + params + "?status=200&msg=Successfully";
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              // this.resultError(error.response.data.errors);
              if (this.errors.message) {
                this.error(this.errors.message);
              } else {
                this.resultError(error.response.data.errors);
              }
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loadPostalCode() {
      const baseURI = this.$settings.endPoint + "postal-code/select-distinct-state";

      return this.$http.get(baseURI).then((response) => {
        this.fetchDestinationState=response.data.datas[0];
        this.fetchOriginState=response.data.datas[0];
       
      });
    },

    loadTruckCategory() {
      const baseURI = this.$settings.endPoint + "rm-mel-master/select-truck-category";

      return this.$http.get(baseURI).then((response) => {
        this.fetchTruckCategory = response.data.datas[0];
      });
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.loadData(params[0]);
    this.fetchIt();

    this.loadPostalCode();
    this.loadTruckCategory();
   
  },
};
</script>
<style scoped></style>
