<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TripAlert" ></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            "
          >
            <div class="col-lg-12 col-xl-12 stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="row mb-3">
                    <div class="col-lg-1 col-md-12 col-sm-12 col-xs-12">
                      <h5 class="mb-12 mb-md-0">Trip Alert</h5>
                    </div>

                    <div class="col-lg-1 col-md-12 col-sm-12 col-xs-12">
                      <div class="input-group">
                        <label
                          for="exampleInputUsername2"
                          class="col-form-label"
                          >Maps: &nbsp;
                        </label>

                        <button
                          v-if="isMaps"
                          class="btn btn-primary"
                          @click="btnMaps(false)"
                        >
                          OFF
                        </button>
                        <button
                          v-if="!isMaps"
                          class="btn btn-success"
                          @click="btnMaps(true)"
                        >
                          ON
                        </button>
                      </div>
                    </div>

                    <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                      <div class="input-group">
                        <multiselect
                          v-model="branch"
                          :options="fetchBranch"
                          :multiple="true"
                          :close-on-select="true"
                          :clear-on-select="true"
                          :preserve-search="true"
                          placeholder="Pick some Branch"
                          label="branch_name"
                          track-by="branch_name"
                          :preselect-first="true"
                          @select="onSelectBranch"
                          @remove="onUnselectBranch"
                        >
                          <template
                            slot="selection"
                            slot-scope="{ values, isOpen }"
                            ><span
                              class="multiselect__single"
                              v-if="values.length &amp;&amp; !isOpen"
                              >{{ values.length }} Branch selected</span
                            ></template
                          >
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                      <div class="input-group">
                        <multiselect
                          v-model="truck"
                          :options="fetchTruck"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          :preserve-search="true"
                          placeholder="Pick some Truck"
                          label="type_truck"
                          track-by="type_truck"
                          :preselect-first="true"
                          @select="onSelectTypeTruck"
                          @remove="onUnselectTypeTruck"
                          @search-change="onSearchChange"

                        >
                          <template
                            slot="selection"
                            slot-scope="{ values, isOpen }"
                            ><span
                              class="multiselect__single"
                              v-if="values.length &amp;&amp; !isOpen"
                              >{{ values.length }} Truck selected</span
                            >
                          </template>
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                      <div class="input-group">
                        <multiselect
                          v-model="status"
                          :options="fetchStatus"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          :preserve-search="true"
                          placeholder="Pick some status"
                          label="status_name"
                          track-by="status_name"
                          :preselect-first="true"
                          @select="onSelectStatus"
                          @remove="onUnselectStatus"
                        >
                          <template
                            slot="selection"
                            slot-scope="{ values, isOpen }"
                            ><span
                              class="multiselect__single"
                              v-if="values.length &amp;&amp; !isOpen"
                              >{{ values.length }} Status selected</span
                            ></template
                          >
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                       <multiselect
                          v-model="vehicle_no"
                          :options="fetchTruckVehicleNo"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          :preserve-search="true"
                          placeholder="Vehicle No"
                          label="vehicle_no"
                          track-by="vehicle_no"
                          :preselect-first="true"
                          @select="onSelectVehicleNo"
                          @remove="onUnselectVehicleNo"
                          @search-change="onSearchChangeTruckVehicleNo"
                        >
                          <template
                            slot="selection"
                            slot-scope="{ values, isOpen }"
                            ><span
                              class="multiselect__single"
                              v-if="values.length &amp;&amp; !isOpen"
                              >{{ values.length }} selected</span
                            >
                          </template>
                        </multiselect>
                    </div>

                    <div class="col-lg-1 col-md-12 col-sm-12 col-xs-12">
                      <button
                        class="btn btn-primary"
                        @click="resetFilterLocation"
                      >
                        Reset Filter
                      </button>

                      

                    </div>

                     <div class="col-lg-1 col-md-12 col-sm-12 col-xs-12">
                      <button
                        class="btn btn-success btn-xs float-end"
                        @click="downloadData"
                        type="button"
                      >
                      <i class="link-icon" data-feather="download"></i>
                        
                      </button>
                     </div>
                     

                  </div>




<div id="map" class="map"></div>


   <!-- <h1>Panorama at Pembroke College, Cambridge, facing North, looking slightly upwards (10 degrees)</h1> Point-of-view updates when you pan around

    <gmap-street-view-panorama class="pano" :position="{lat: 52.201272, lng: 0.118720}"
      :pov="pov" :zoom="1" @pano_changed="updatePano" @pov_changed="updatePov">
    </gmap-street-view-panorama>
    <ul>
      <li>Heading: {{pov && pov.heading}} <button @click="pov.heading = Math.random() * 360">Change</button></li>
      <li>Pitch: {{pov && pov.pitch}} <button @click="pov.pitch = Math.random() * 180 - 90">Change</button></li>
      <li><button @click="pov.pitch = Math.random() * 180 - 90, pov.heading = Math.random() * 360">Change Both</button></li>
      <li>Pano ID: {{pano}}</li>
    </ul> -->

 





                  <div class="row" v-if="isMaps">
                    <gmap-map
                      :center="center"
                      :zoom="zoom"
                      style="width: 100%; height: 500px"
                    >
                      <gmap-marker
                        :key="index"
                        v-for="(gmp, index) in locations"
                        :position="gmp"
                        :icon="markerOption"
                        :clickable="true" :shape="shape"
                        @click="openMarker(gmp)"
                      >
                        <gmap-info-window :opened="true">
                          <span
                            class="badge"
                            :style="{
                              'background-color': gmp.status_color,
                              color: 'white',
                            }"
                            :opened="false"
                          >
                            {{ gmp.label }}
                          </span>
                          <!-- <span :class="gmp.color" style="margin:20px"> {{ gmp.label }} </span> -->
                        </gmap-info-window>
                      </gmap-marker>

                      <!-- <DirectionsRenderer
                        travelMode="DRIVING"
                        :origin="startLocation"
                        :destination="endLocation"
                      /> -->

                      <!-- <gmap-info-window :position="{ lat:-6.1850691, lng: 106.795189 }">
                        Hello world!
                      </gmap-info-window> -->
                    </gmap-map>
                  </div>

                  <div class="row">
                    <p class="text-muted mb-3"></p>

                    <vue-good-table
                      title="list-dashboard"
                      mode="remote"
                      @on-page-change="onPageChange"
                      @on-sort-change="onSortChange"
                      @on-column-filter="onColumnFilter"
                      @on-per-page-change="onPerPageChange"
                      :totalRows="totalRecords"
                      :line-numbers="true"
                      :isLoading.sync="isLoading"
                      :pagination-options="{
                        enabled: false,
                      }"
                      :rows="rowsTable"
                      :columns="columns"
                    >
                      <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'vehicle_no'">
                          <span
                            @click="searchVehicleNo(props.row)"
                            :style="{
                              'background-color': props.row.status_color,
                              cursor: 'pointer',
                            }"
                            class="badge"
                            >{{ props.formattedRow[props.column.field] }}
                           
                            </span
                          >
                        </span>

                        <span v-else-if="props.column.field == 'delay'">
                          <span
                           :style="{
                              'background-color': props.row.background_color_delay,
                              cursor: 'pointer',
                              'color': props.row.color_delay,
                            }"
                            class="badge"
                            >{{ props.row.delay }}</span
                          >
                        </span>

                        <span v-else-if="props.column.field == 'speed'">
                          <span
                             :style="{
                              'background-color': props.row.background_color,
                              cursor: 'pointer',
                              'color': props.row.color,
                            }"
                            class="badge"
                            @click="turnOffAlert(props.row)"
                            >{{ props.row.speed }}</span
                          >
                        </span>

                        <!-- <span v-else-if="props.column.field=='stnk'">      
                          <span style="background-color:red;" class="badge" v-if="currentDate > props.row.stnk " >{{props.row.stnk}}</span>
                          <span style="color:black;" class="badge" v-else >{{props.row.stnk}}</span>
                      </span> -->
                        <span v-else-if="props.column.field == 'driver_name'">
                          {{ props.row.driver_name }}<br>
                          {{ props.row.driver_hp }}
                        </span>

                        <span v-else-if="props.column.field == 'engine_car'">
                          <span
                            style="background-color: red"
                            class="badge"
                            v-if="props.row.engine_car == 'OFF'"
                            >{{ props.row.engine_car }}</span
                          >
                          <span
                            style="background-color: green"
                            class="badge"
                            v-else
                            >{{ props.row.engine_car }}</span
                          >
                        </span>

                         <span v-else-if="props.column.field == 'detail'">
                          
                          <a
                          href="#"
                          
                          title="Detail"
                          @click.prevent="detailData(props.index, props.row)"
                        >
                          <i class="mdi mdi-eye" style="font-size: 16px"></i>
                          </a>
                          
                          
                        </span>


                        <!-- <span v-else-if="props.column.field=='kir'">
                          <span style="background-color:red;" class="badge" v-if="currentDate > props.row.kir " >{{props.row.kir}}</span>
                          <span style="color:black;" class="badge" v-else >{{props.row.kir}}</span>
                      </span> -->

                        <span v-else>
                          {{ props.formattedRow[props.column.field] }}
                        </span>
                      </template>
                    </vue-good-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>

    <modal name="my-trip-modal" height="450px">
      <div class="card">
        <div class="card-header">
          <h6
                    class="card-title mb-0 float-start"
                  > Turn Off Trip Alert </h6>
        </div>

         <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "
                    >
                    
                    </div>
                    
                    <div class="row mb-3">
                      <div class="col-lg-4">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Vehicle No</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="description"
                          v-model="forms.vehicle_no"
                          readonly
                        />
                        <div v-if="errors.vehicle_no">
                          <div
                            v-for="error in errors.vehicle_no"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>



                    <div class="row mb-3">
                      <div class="col-lg-4">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Alert Time</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="description"
                          v-model="forms.alert_time"
                          readonly
                        />
                        <div v-if="errors.alert_time">
                          <div
                            v-for="error in errors.alert_time"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-4">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Alert Type</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="description"
                          v-model="forms.alert_type"
                          readonly
                        />
                        <div v-if="errors.alert_type">
                          <div
                            v-for="error in errors.alert_type"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-4">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Alert Sub Type</label>
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="description"
                          v-model="forms.alert_subtype"
                          readonly
                        />
                        <div v-if="errors.alert_subtype">
                          <div
                            v-for="error in errors.alert_subtype"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-4">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Off Alert Reason</label>
                      </div>
                      <div class="col-lg-8">
                        <textarea v-model="forms.alert_off_reason" class="form-control" >
                        </textarea>
                        <div v-if="errors.alert_off_reason">
                          <div
                            v-for="error in errors.alert_off_reason"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                   
                    <div class="card-footer">
                     <button
                        class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        "
                        @click="hideModalTrip"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        
                        
                       Close
                      </button>
                    
                      &nbsp;&nbsp;&nbsp;
                      <button class="btn btn-primary float-end btn-sm" type="submit">
                           <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>
                  </form>
                </div>

      </div>
    </modal>




  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import multiselect from "vue-multiselect";
// import DirectionsRenderer from "@/middleware/DirectionsRenderer";
import moment from "moment";



  

export default {
  name: "IndexTesting",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    multiselect: multiselect, 
    // DirectionsRenderer,
  },
  data() {
    const mapMarkerIcon = require("../../assets/icontruck.png");
    const mapMarkerIconSvg = require("../../assets/icontruck.svg");
    return {
      map:[],
      mapOptions:{
  center: {
    lat: -2.548926,
    lng: 118.0148634
  },
  zoom: 5
},
          shape: {
            coords: [10, 10, 10, 15, 15, 15, 15, 10],
            type: 'poly'
          },
          pov: null,
          pano: {heading:0},
      zoom: 5,
      mapMarkerIcon:mapMarkerIcon,
      mapMarkerIconSvg:mapMarkerIconSvg,
      heading:0,
      isLoading: false,
      totalRecords: 0,
      openedMarkerID: true,
      markerOption: {
        url: mapMarkerIcon,
        size: { width: 30, height: 30, f: "20px", b: "20px" },
        scaledSize: { width: 30, height: 30, f: "20px", b: "20px" },
        rotation: 45,
      },

      branch: [],
      vehicle_no: [],
      vehicle_no_Filter: [],
      fetchTruckVehicleNo: [],
      currentDate: moment(String(new Date())).format("YYYY-MM-DD"),
      truck: [],
      status: [],
      fetchBranch: [],
      fetchTruck: [],
      fetchStatus: [],
      options: [],
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isMaps: false,
      errors: [],
      dialog: false,
      fieldTrunOff: "",
      featchTruck: [],
      featchStatus: [],
      featchBranch: [],
      langs: ["id", "en"],
      name: "",
      startLocation: null,
      endLocation: null,
      center: {
        lat: -2.548926,
        lng: 118.0148634,
      },
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      forms: [],
      locationMarkers: [],
      locations: [],
      locPlaces: [],
      realMonitoring: [],
      branchFilter: [],
      type_truckFilter: [],
      statusFilter: [],
      searchVhicle: "",
      rowsTable: [],
      featchLoadType:[],
      load_id:"",
      equipment:"",
      load_group:"",
      driver_name:"",
      deposit:0,
      driver_hp:"",
      date_time:"",
      address:"",
      orders:"",
      htmlTable:"",
      columns: [
        {
          label: "Last Updated",
          field: "time_server",
        },

        {
          label: "Status",
          field: "truck_status",
        },

        {
          label: "Vehicle No",
          field: "vehicle_no",
        },

        {
          label: "Branch",
          field: "branch_name",
        },

        {
          label: "Carrier",
          field: "carrier",
        },

        {
          label: "Type",
          field: "type_truck",
        },

         {
          label: "Driver Name",
          field: "driver_name",
        },

      

        {
          label: "GPS",
          field: "gps_vendor",
        },

        {
          label: "Last Location",
          field: "location",
        },

       

        {
          label: "Temp",
          field: "temperature",
        },

        {
          label: "ENG",
          field: "engine_car",
        },

        {
          label: "KM",
          field: "mileage",
        },

        {
          label: "Delay",
          field: "delay",
        },

        {
          label: "Speed",
          field: "speed",
        },

        {
          label: "",
          field: "detail",
        },
      ],
      rows: [],
      permissionTabsBasic : '',
      permissionTabsShipment : '',
      permissionTabsDriver : '',
      permision_role: '',
    };
  },
  watch: {},
  methods: {
    updatePov(pov) {
            this.pov = pov;
          },
          updatePano(pano) {
            this.pano = pano;
          },
    cek_permission_tabs() {
      const userDatas     = this.$getUserInfo();
      this.detailUser     = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function(permision){
        if(permision.permission.controller == 'BlujayOrderController'){
          rolePermision = permision;
          // console.log(permision);
        }
      });

      this.permision_role = rolePermision;

      if(!this.permision_role.method_custom1){
        this.permissionTabsBasic=" hidden";
      }

      if(!this.permision_role.method_custom2){
        this.permissionTabsShipment=" hidden";
      }


      if(!this.permision_role.method_custom3){
        this.permissionTabsDriver=" hidden";
      }

    },

    btnMaps(stype) {
      this.isMaps = stype;
      this.loadLocationTable();
      // setInterval(() => {
      //   this.loadLocationTable();
      // }, 500000);

    },

    showModalTrip() {
      this.$modal.show("my-trip-modal");
    },


    hideModalTrip() {
      this.$modal.hide("my-trip-modal");
    },

    fade(sType) {
      this.isLoading = sType;
    },


    
    openMarker(detail) {
      let sts_engine = "";
      if (detail.engine_car == "ON") {
        sts_engine = '<a style="color:green;">' + detail.engine_car + "<a>";
      } else {
        sts_engine = '<a style="color:red;">' + detail.engine_car + "<a>";
      }
      this.$swal({
        position: "top-end",
        title: false,
        icon: false,
        showCloseButton: true,
        showConfirmButton: true,
        confirmButtonColor: "#ab0635",
        cancelButtonColor: "#d33",
        confirmButtonText: "Turn Off Alert",
        header: '<a href="">Why do I have this issue?</a>',
        html:
          '<ul class="nav nav-tabs" id="myTab" role="tablist">' +
          '<li class="nav-item" '+this.permissionTabsBasic+'>' +
          '<a class="nav-link active" id="home-tab" data-bs-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Basic</a>' +
          "</li>" +
          '<li class="nav-item" '+this.permissionTabsShipment+'>' +
          '<a class="nav-link" id="profile-tab" data-bs-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" >Shipment</a>' +
          "</li>" +
          '<li class="nav-item" '+this.permissionTabsDriver+'>' +
          '<a class="nav-link" id="contact-tab" data-bs-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Driver</a>' +
          "</li>" +
          '<li class="nav-item">' +
          '<span style="margin: 20px; color:">Eng:' +
          sts_engine +
          '<span > AC: <a style="color:red;">OFF</a> </span>' +
          "</li>" +
          "</ul>" +
          '<div class="tab-content border border-top-0 p-3" id="myTabContent">' +
          '<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">' +
          "<table>" +
          "<tr>" +
          "<td style='text-align:left'>Last Update</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.updated_at +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Vehicle No</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.vehicle_no +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Load ID</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.load_id +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Equipment</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.equipment +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Load Group</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.load_group +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Driver Name</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.driver_name +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Driver Hp</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.driver_hp +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Date Time</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.time_server +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Address</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.location +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Speed</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left;background:"+ detail.background_color +";color:"+ detail.color +"'><b>" +
          detail.speed +
          " Km/Jam </b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Delay</td>" +
          "<td style='text-align:left'>:</td>" +
         "<td style='text-align:left;background:"+ detail.background_color_delay +";color:"+ detail.color_delay +"'><b>" +
          detail.delay +
          "</b></td>" +
          "</tr>" +
          "</table>" +
          "</div>" +
          '<div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">'+detail.htmlTable+'</div>' +
         '<div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">'+
          "<table>" +
          "<tr>" +
          "<td style='text-align:left'>Deposit</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" 
          + this.formatNumber(detail.deposit) +
          "</b></td>" +
          "</tr>" +
          "</table>" +
          "</div>" +
          "</div>",
      }).then((result) => {
        if (result.value) {
          this.turnOffAlert(detail);
        }
      })
    },



    formatNumber(value){
      return parseInt(value).toLocaleString()
    },

    initMarker(loc) {
      this.existingPlace = loc;
    },


    //filter dashboard
    onUnselectBranch(ev) {
      this.branchFilter.splice(this.branchFilter.indexOf(ev.branch_id),1);
      this.loadLocationTable();
    },

    onSelectBranch(ev) {
      this.branchFilter.push(ev.branch_id);
   
      this.loadLocationTable();
    },

    onSelectTypeTruck(ev) {
      this.type_truckFilter.push(ev.type_truck_id);
      this.loadLocationTable();
    },

    onUnselectTypeTruck(ev) {
      this.type_truckFilter.splice(this.type_truckFilter.indexOf(ev.type_truck_id),1);
      this.loadLocationTable();
    },

    onSelectVehicleNo(ev) {
      this.vehicle_no_Filter.push(ev.vehicle_no);
     this.loadLocationTable();
    },

    onUnselectVehicleNo(ev) {
      this.vehicle_no_Filter.splice(this.vehicle_no_Filter.indexOf(ev.vehicle_no),1);
      this.loadLocationTable();
    },
    
    onSelectStatus(ev) {
      this.statusFilter.push(ev.status_name);
      this.loadLocationTable();
    },
    
    onUnselectStatus(ev) {
      this.statusFilter.splice(this.statusFilter.indexOf(ev.status_name),1);
      this.loadLocationTable();
    },


    setLocationLatLng: function () {
      navigator.geolocation.getCurrentPosition((geolocation) => {
        this.center = {
          lat: geolocation.coords.latitude,
          lng: geolocation.coords.longitude,
        };
      });
      // this.locations = [
      //   {
      //     id:1,
      //     lat: -6.2066575,
      //     lng: 106.7759629,
      //     label: "B 123123 EG",
      //     rotate:20,
      //     color:"badge bg-primary"
      //   },
      //   {
      //     id:2,
      //     lat: -6.181783879012301,
      //     lng: 106.79797850513704,
      //     label: "B 22222 EH",
      //     rotate:40,
      //     color:"badge bg-success"
      //   },
      //   {
      //     id:3,
      //     lat: -6.1893281,
      //     lng: 106.764631,
      //     label: "B 12223 EJ",
      //     rotate:90,
      //     color:"badge bg-warning"
      //   },
      //   {
      //     id:4,
      //     lat: -6.1810517,
      //     lng: 106.7679362,
      //     label: "F 231312 EG",
      //     rotate:70,
      //     color:"badge bg-info"
      //   },
      // ];
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },


    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    detailData(index, row) {
     
      var params = this.$onRandom(row.id);
      //console.log(params);
      window.location.href = "/monitoring/detail/" + params + "/" + row.vehicle_no+ "/" + row.gps_vendor;
    },


    submitData() {
      this.fade(true);
      this.forms.alert_status=0;
      const baseURI = this.$settings.endPointCt + "alert/update/"+this.forms.alert_id;
      this.$http
        .patch(baseURI, this.forms)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            //console.log(response.data.datas.user_id);
            // var params = this.$onRandom(response.data.datas.branch_id);
            // window.location.href = "/branch/detail/" + params + "?status=200&msg=Successfully";
            this.success('Success Turn Off Alert');
            this.hideModalTrip();
            this.loadLocationTable();
            this.loadLocation();
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },


    downloadData() {
      let branch = this.branchFilter.toString();

      let type_truck=this.type_truckFilter.toString();
      let status =this.statusFilter.toString();
      let vehicle_no=this.vehicle_no_Filter.toString();

      const baseURI = this.$settings.endPointCt + `real-monitoring/trip-alert?branch=${branch}&status=${status}&type_truck=${type_truck}&vehicle_no=${vehicle_no}&download=download`;
    
      var file_name = `trip_alert_.xlsx`;

      //var columnFilters = this.serverParams.columnFilters;
      return this.$http
        .get(
          baseURI,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
         
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },

    turnOffAlert(detail) {
      this.forms=[];
      const baseURI = this.$settings.endPointCt + "alert/detail/"+detail.vehicle_no+"/TRIP";
      return this.$http.get(baseURI).then((response) => {
        
        this.forms = response.data.datas;
        this.showModalTrip();

        // this.form={ name: 'asep', usernmae:res.username };
      });
      
    },

    //awal Filter vehicle no
    loadTruckVehicleNo() {
      const baseURI = this.$settings.endPointCt + `master-truck/select`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTruckVehicleNo = response.data.datas.data;
      });
    },

    onSearchChangeTruckVehicleNo(ev) {
      const baseURI = this.$settings.endPointCt + `master-truck/select?vehicle_no=` + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTruckVehicleNo = response.data.datas.data;
      });
      
    },

   
    //akhir filter vehicle no

    changeFilterLocation() {
      let dataSend = {
        branch: this.branchFilter,
        type_truck: this.type_truckFilter,
        status: this.statusFilter,
        vehicle_no: this.vehicle_no_Filter,
      };
      //this.realMonitoring =[];
      this.locations = [];
      this.rowsTable = [];

      this.loadFilter(dataSend);
    },

    loadFilter(data) {
      const baseURI = this.$settings.endPointCt + `real-monitoring/trip-alert`;
      return this.$http.post(baseURI, data).then((response) => {
        this.realMonitoring = response.data.datas.data;
        this.totalRecords = response.data.datas.total;
        this.rows = response.data.datas.data;
        this.loadFetchLocation();
        this.loadFetchLocationTable();
      });
    },

    loadFilterClick(data) {
      const baseURI = this.$settings.endPointCt + `real-monitoring/trip-alert`;
      return this.$http.post(baseURI, data).then((response) => {
        this.realMonitoring = response.data.datas.data;
        this.totalRecords = response.data.datas.total;

        this.loadFetchLocation();
        //this.loadFetchLocationTable();
      });
    },

    searchVehicleNo(ev) {
      this.isMaps=true;
      this.zoom=15;
      this.heading=90
      this.center= {
        lat: ev.lat,
        lng: ev.lng,
      },
    
        this.realMonitoring=[];
       
        this.realMonitoring.push(ev);
        this.loadFetchLocation_single_select();
    },

    // searchVehicleNo(ev) {
    //   this.isMaps = true;
    //   this.zoom = 15;
    //   (this.center = {
    //     lat: ev.lat,
    //     lng: ev.lng,
    //   }),
    //     (
    //       this.locations = []
    //     );
    //   let dataSend2 = {
    //     vehicle_no: ev.vehicle_no,
    //   };

    //   this.loadFilterClick(dataSend2);
    // },

    searchVehicleNoByText() {
      this.locations = [];
      let dataSend2 = {
        vehicle_no: this.searchVhicle,
      };
      this.loadFilter(dataSend2);
    },

  
    locateGeoLocation(latitude, longitude) {
      const marker = {
        lat: latitude,
        lng: longitude,
      };
      this.locationMarkers.push({ position: marker });
      this.locPlaces.push(this.existingPlace);
      this.center = marker;
      this.existingPlace = null;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      // const userDatas = this.$getUserInfo();
      // this.name = userDatas.sub.name;
    },

    loadBranch() {
      const baseURI = this.$settings.endPointCt + `branch/select`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchBranch = response.data.datas.data;
      });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadLocationTable();
    },

        onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    loadStatus() {
      const baseURI = this.$settings.endPointCt + `master-status?limit=100`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchStatus = response.data.datas.data;
      });
    },

    loadTruck() {
      const baseURI = this.$settings.endPointCt + `type-truck/select`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTruck = response.data.datas.data;
      });
    },

    onSearchChange(ev) {
     const baseURI = this.$settings.endPointCt + `type-truck/select?type_truck=`+ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTruck = response.data.datas.data;
      });
    },

    resetFilterLocation() {
      this.searchVhicle = "";
      this.branch = "";
      this.truck = "";
      this.status = "";
      this.branchFilter = [];
      this.type_truckFilter = [];
      this.vehicle_no_Filter=[];
      this.vehicle_no="";
      this.statusFilter = [];
      this.loadLocation();
      this.loadLocationTable();
      this.zoom = 5;
    },

    loadLocation() {
      const baseURI =
        this.$settings.endPointCt + `real-monitoring/trip-alert?download=all`;
      return this.$http.get(baseURI).then((response) => {
        this.realMonitoring = response.data.datas.data;
        //this.totalRecords=response.data.datas.total;
        
        this.loadFetchLocation();
        // navigator.geolocation.getCurrentPosition((geolocation) => {
        //   this.center = {
        //     lat: geolocation.coords.latitude,
        //     lng: geolocation.coords.longitude,
        //   };
        // });
        // let color="";

        // console.log(this.realMonitoring);
      });
    },

    loadLocationTable() {
     
      this.rows = [];
      this.realMonitoring = [];
      this.locations=[];
      this.rowsTable=[];

      let branch = this.branchFilter.toString();

      let type_truck=this.type_truckFilter.toString();
      let status =this.statusFilter.toString();
      let vehicle_no=this.vehicle_no_Filter.toString();



      const baseURI = this.$settings.endPointCt + `real-monitoring/trip-alert`;
      return this.$http
        .get(
          baseURI +
            `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&branch=${branch}&status=${status}&type_truck=${type_truck}&vehicle_no=${vehicle_no}&download=all`
        )
        .then((response) => {
          this.rows = response.data.datas;
          this.realMonitoring = response.data.datas;
          this.totalRecords = 0;
          this.loadFetchLocationTable();
          this.loadFetchLocation();
          // navigator.geolocation.getCurrentPosition((geolocation) => {
          //   this.center = {
          //     lat: geolocation.coords.latitude,
          //     lng: geolocation.coords.longitude,
          //   };
          // });
          // let color="";

          // console.log(this.realMonitoring);
        });
    },



    loadBlujay(blujayorder){
        if (blujayorder!=null) {
          this.load_id = blujayorder.load_id;
          this.equipment = blujayorder.equipment;
          this.load_group = blujayorder.load_group;
          this.driver_name = blujayorder.driver_name;
          this.driver_hp = blujayorder.driver_hp;
          this.date_time = blujayorder.created_at;
          this.address = "";
          this.htmlTable="";
          if(blujayorder.driver!=null){
            this.deposit=blujayorder.driver.amount;
          }
          
          this.orders = blujayorder.order_detail;
          if (this.orders != null) {
            this.htmlTable +="<table>";
            this.orders.forEach((reso) => {
              let pecah=reso.name_rute.split(",");
              this.htmlTable +=
                "<tr>" +
                "<td style='text-align:left'>Order Number</td>" +
                "<td style='text-align:left'>:</td>" +
                "<td style='text-align:left'>" +reso.order_number +"</td>" +
                "</tr>" +
                "<tr>" +
                "<td style='text-align:left'>Customer</td>" +
                "<td style='text-align:left'>:</td>" +
                "<td style='text-align:left'>" +reso.customer_name +"</td>" +
                "</tr>" +
                "<tr>" +
                "<td style='text-align:left'>Order Group</td>" +
                "<td style='text-align:left'>:</td>" +
               "<td style='text-align:left'>" +reso.order_group +"</td>" +
                "</tr>" +
                "<tr>" +
                "<td style='text-align:left'>Status</td>" +
                "<td style='text-align:left'>:</td>" +
               "<td style='text-align:left'>" +reso.tms_status +"</td>" +
                "</tr>" +
                "<tr>" +
                "<td style='text-align:left'>Target Suhu</td>" +
                "<td style='text-align:left'>:</td>" +
                "<td style='text-align:left'></td>" +
                "</tr>" +
                "<tr>" +
                "<td style='text-align:left'>Rute</td>" +
                "<td style='text-align:left'>:</td>" +
                "<td style='text-align:left'>"+pecah[0].slice(12)+' - '+pecah[1].slice(11)+"</td>"+
                "</tr>" +
                "<tr>" +
                "<td colspan='3'>_____________________________</td>" +
                "</tr>" ;
            });
             this.htmlTable +="</table>";
          }
        }
    },


    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },


    loadFetchLocation_single_select() {
      this.locations = [];
      this.realMonitoring.forEach((res) => {
        this.loadBlujay(res.blujay_order);
        this.locations.push({
          id: res.real_monitoring_id,
          lat: res.lat,
          lng: res.lng,
          label: res.vehicle_no,
          rotate: res.rotate,
          stnk: res.stnk,
          kir: res.kir,
          delay:res.delay,
          location: res.location,
          updated_at: res.updated_at,
          truck_status: res.truck_status,
          vehicle_no: res.vehicle_no,
          type_truck: res.type_truck,
          gps_vendor: res.gps_vendor,
          city: res.city,
          province: res.province,
          temperature: res.temperature,
          speed: res.speed,
          engine_car: res.engine_car,
          mileage: res.mileage,
          status_color: res.status_color,
          branch_name: res.branch_name,
          carrier: res.carrier,
          time_server:res.time_server,
          background_color:res.background_color,
          color:res.color,
          background_color_delay: res.background_color_delay,
          color_delay:res.color_delay,
          load_id: res.load_id,
          equipment: res.equipment,
          load_group: res.load_group,
          driver_name: res.driver_name,
          driver_hp: res.driver_hp,
          date_time: res.date_time,
          address: res.address,
          orders: res.orders,
          temp: res.temp,
          htmlTable: res.htmlTable,
          deposit:res.deposit,
        });
      });
        this.loadMapsGoogle();
    },


    loadFetchLocation() {
    
      this.realMonitoring.forEach((res) => {
        let stnk = "";
        let kir = "";

        let background_color="";
        let color="black";
        let background_color_delay="";
        let color_delay="black";
       
        if(res.speed>=res.speed_limit){
          background_color="#ff0000";
          color="white";
        }

        if(res.delay!="" && res.delay!=null){
          background_color_delay="#ff0000";
          color_delay="white";
        }

        this.loadBlujay(res.blujay_order);
        this.locations.push({
          id: res.real_monitoring_id,
          lat: res.latitude,
          lng: res.longitude,
          label: res.vehicle_no,
          rotate: 20,
          stnk: stnk,
          kir: kir,
          delay:res.delay,
          location: res.location,
          updated_at: res.updated_at,
          truck_status: res.truck_status,
          vehicle_no: res.vehicle_no,
          type_truck: res.type_truck,
          gps_vendor: res.gps_vendor,
          city: res.city,
          province: res.province,
          temperature: res.temperature,
          speed: res.speed,
          engine_car: res.engine_car,
          mileage: res.mileage.toLocaleString(),
          status_color: res.status_color,
          branch_name: res.branch_name,
          carrier: res.carrier,
          time_server:res.time_server,
          background_color:background_color,
          color:color,
          background_color_delay: background_color_delay,
          color_delay:color_delay,
          load_id: this.load_id,
          equipment: this.equipment,
          load_group: this.load_group,
          driver_name: this.driver_name,
          driver_hp: this.driver_hp,
          date_time: this.date_time,
          address: this.address,
          orders: this.orders,
          temp: "",
          htmlTable: this.htmlTable,
          deposit:this.deposit,
        });
      });

        // console.log(this.locations);
        this.loadMapsGoogle();
    },

    loadFetchLocationTable() {
      this.rowsTable = [];
      this.rows.forEach((res) => {
        let stnk = "";
        let kir = "";
        // res.master_document.forEach((resp) => {
        //   if (resp.type_document_id == 1) {
        //     stnk = resp.date_of_expire;
        //   }

        //   if (resp.type_document_id == 2) {
        //     kir = resp.date_of_expire;
        //   }
        // });
        let background_color="";
        let color="black";
        let background_color_delay="";
        let color_delay="black";

         if(res.speed>0){
          background_color="#ff0000";
          color="white";
        }

        if(res.delay!="" && res.delay!=null){
          background_color_delay="#ff0000";
          color_delay="white";
        }

        this.loadBlujay(res.blujay_order);
        this.rowsTable.push({
          id: res.real_monitoring_id,
          lat: res.latitude,
          lng: res.longitude,
          label: res.vehicle_no,
          rotate: 20,
          stnk: stnk,
          kir: kir,
          delay:res.delay,
          location: res.location,
          updated_at: res.updated_at,
          truck_status: res.truck_status,
          vehicle_no: res.vehicle_no,
          type_truck: res.type_truck,
          gps_vendor: res.gps_vendor.substring(0, 3),
          city: res.city,
          province: res.province,
          temperature: res.temperature,
          speed: res.speed,
          engine_car: res.engine_car,
          mileage: res.mileage.toLocaleString(),
          status_color: res.status_color,
          branch_name: res.branch_name,
          carrier: res.carrier,
          time_server:res.time_server,
          background_color:background_color,
          color:color,
          background_color_delay: background_color_delay,
          color_delay:color_delay,
          load_id: this.load_id,
          equipment: this.equipment,
          load_group: this.load_group,
          driver_name: this.driver_name,
          driver_hp: this.driver_hp,
          date_time: this.date_time,
          address: this.address,
          orders: this.orders,
          temp: "",
          htmlTable: this.htmlTable,
          deposit:this.deposit,
        });
      });
    },
    loadMapsGoogle(){
      this.$loaderMaps
  .load()
  .then((google) => {

      var myLatLng = { lat: -2.548926, lng: 118.0148634 };
      if(this.zoom > 10){

        myLatLng = { lat:this.locations[0].lat, lng: this.locations[0].lng };

      }
      const map = new google.maps.Map(document.getElementById("map"), {
          zoom: this.zoom,
          center: myLatLng,
          draggable: true,
      });
 
    // const svgMarker = {
    //     path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
      
    //     fillColor: "red",
    //     fillOpacity: 0.5,
    //     strokeWeight: 0,
    //     rotation: 0,
    //     scale: 2,
    //     anchor: new google.maps.Point(15, 30),
    //   };

    //   new google.maps.Marker({
    //     position: myLatLng,
    //     map,
    //     icon:svgMarker,
    //     title: "Hello World!",
    //   });


      // const image = {
      //     url: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
      //     // This marker is 20 pixels wide by 32 pixels high.
      //     size: new google.maps.Size(30, 30),
      //     // The origin for this image is (0, 0).
      //     origin: new google.maps.Point(0, 0),
      //     // The anchor for this image is the base of the flagpole at (0, 32).
      //     anchor: new google.maps.Point(0, 32),
      //   };
        
      // const marker = new google.maps.Marker({
      //   position: myLatLng,
      //   map,
      //   icon:image,
      //   title: "Hello World!",
      //   zIndex: 20,
      //   optimized: false,
      // });

      //   const infowindow = new google.maps.InfoWindow({
      //     content: "Hello World!",
      //     maxWidth: 200,
      //   });


      //   infowindow.open({
      //         anchor: marker,
      //         map,
      //         shouldFocus: false,
      //       });



      const image = {
          url: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
          // This marker is 20 pixels wide by 32 pixels high.
          size: new google.maps.Size(30, 30),
          // The origin for this image is (0, 0).
          origin: new google.maps.Point(0, 0),
          // The anchor for this image is the base of the flagpole at (0, 32).
          anchor: new google.maps.Point(0, 32),
        };


      for (let i = 0; i < this.locations.length; i++) {
          var data = this.locations[i];
          var marker = new google.maps.Marker({
            position: new google.maps.LatLng(data.lat, data.lng),
            icon: image,
            map: map,
            title:data.vehicle_no,
            clickable: true
          });
          const infowindow = new google.maps.InfoWindow({
              content: `<span class="badge" style="background-color:${data.status_color};color:white;">${data.vehicle_no}</span>`,
              maxWidth: 200,
            });
              infowindow.open({
              anchor: marker,
              map,
              shouldFocus: false,
            });

              if(this.zoom > 10){
            
                if(data.heading){
                  
                  marker.setIcon({
                    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    scale: 6,
                    rotation: data.heading
                  });
 
                }else{

                  marker.setIcon({
                    url: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
                    scale: 6,
                    rotation: data.heading
                  });

                }
                
                // this.openMarker(data);
              }
           

            marker.addListener('click', () => {
 this.openMarker(data);
              });
          // //open onclick
          //   google.maps.event.addListener(marker, 'click', function() {
          //     this.openMarker(data);
          //     // console.log(data)
          //   });
       

        }


  })
  .catch(e => {
    console.log(e)
  });
      
    },

  },
  events: {},
  created: function () {},
  mounted() {
    const start = {
      lat: -6.2066575,
      lng: 106.7759629,
    };

    this.startLocation = start;

    const end = {
      lat: -6.1810517,
      lng: 106.7679362,
    };

    this.endLocation = end;
    //this.setLocationLatLng();
    //this.locateGeoLocation(5.54829, 95.323753);
    this.fetchIt();
    this.loading();
    this.loadBranch();
    this.loadStatus();
    this.loadTruck();
    this.loadTruckVehicleNo();

    this.loadLocationTable();
  },
};
</script>
<style >
button.gm-ui-hover-effect {
  visibility: hidden;
}
 #map {
    width: 100%;
    height: 500px;
    margin: 0;
    padding: 0;
  }
</style> 