<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading"> {{ $t('loadingTxt') }}...</div>
    <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
      <!--begin::sidebar-->
      <sidebar-component classMenu="BranchAdd"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            ">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left: 25px">
                    {{ $t("branchAccessAdd") }}
                  </h6>
                  <button class="btn btn-default btn-sm float-end" @click="resetForm" type="button">
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form class="forms-sample" @submit.prevent="submitData" method="POST">
                    <div class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "></div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
      $t("branch_name") }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="branch_name" :placeholder="$t('branch_name')"
                          v-model="forms.branch_name" required />
                        <div v-if="errors.branch_name">
                          <div v-for="error in errors.branch_name" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
      $t("branch_province") }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="branch_province"
                          :placeholder="$t('branch_province')" v-model="forms.branch_province" />


                        <div v-if="errors.branch_province">
                          <div v-for="error in errors.branch_province" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
      $t("branch_city") }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="branch_city" :placeholder="$t('branch_city')"
                          v-model="forms.branch_city" />
                        <div v-if="errors.branch_city">
                          <div v-for="error in errors.branch_city" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
      $t("branch_region") }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="branch_region" :placeholder="$t('branch_region')"
                          v-model="forms.branch_region" />
                        <div v-if="errors.branch_region">
                          <div v-for="error in errors.branch_region" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>



                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
      $t("branch_address") }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="branch_address" :placeholder="$t('branch_address')"
                          v-model="forms.branch_address" />
                        <div v-if="errors.branch_address">
                          <div v-for="error in errors.branch_address" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label"
                          style="padding-left: 25px">{{ $t('branch_latitude') }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="branch_latitude"
                          :placeholder="$t('branch_latitude')" v-model="forms.branch_latitude" />
                        <div v-if="errors.branch_latitude">
                          <div v-for="error in errors.branch_latitude" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label"
                          style="padding-left: 25px">{{ $t('branch_longitude') }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="branch_longitude"
                          :placeholder="$t('branch_longitude')" v-model="forms.branch_longitude" />
                        <div v-if="errors.branch_longitude">
                          <div v-for="error in errors.branch_longitude" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row card">
                      <div class="card-header">
                        <h5 class="float-start">
                          {{ $t('pool_txt') }}
                        </h5>

                        <button class="
                            btn btn-success btn-icon
                            text-white
                            fw-bolder
                            btn-icon-text
                            float-end
                          " @click="addField()" type="button">
                          <i class="link-icon" data-feather="plus"></i>
                        </button>
                      </div>

                      <div class="card-body">
                        <div class="table-responsive">
                          <table class="table table-hover" style="height: 300px">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>{{ $t("pool_name") }}</th>
                                <th>{{ $t("pool_province") }}</th>
                                <th>{{ $t("pool_city") }}</th>
                                <th>{{ $t("pool_postalcode") }}</th>
                                <th>{{ $t("pool_address") }}</th>
                                <th>{{ $t("pool_latitude") }}</th>
                                <th>{{ $t("pool_longitude") }}</th>

                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(input, index) in this.poolAll" :key="`phoneInput-${index}`">
                                <th>{{ index + 1 }}</th>
                                <td width="200px">
                                  <input type="text" v-model="input.pool_name" class="form-control" required>
                                </td>

                                <td width="200px">
                                  <input type="text" v-model="input.pool_province" class="form-control">
                                </td>

                                <td width="200px">
                                  <input type="text" v-model="input.pool_city" class="form-control">
                                </td>

                                <td width="200px">
                                  <input type="number" maxlength="5" v-model="input.pool_postalcode"
                                    class="form-control">
                                </td>

                                <td width="200px">
                                  <input type="text" v-model="input.pool_address" class="form-control">
                                </td>

                                <td width="200px">
                                  <input type="text" v-model="input.pool_latitude" class="form-control"
                                    @keypress="isNumberLatLong($event)">
                                </td>

                                <td width="200px">
                                  <input type="text" v-model="input.pool_longitude" class="form-control"
                                    @keypress="isNumberLatLong($event)">
                                </td>

                                <td>
                                  <!--          Remove Svg Icon-->
                                  <svg @click="removeField(index, poolAll)" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24" width="24" height="24" class="ml-2 cursor-pointer">
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path fill="red"
                                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                  </svg>
                                </td>
                              </tr>
                            </tbody>

                            <tfoot></tfoot>
                          </table>
                        </div>
                      </div>
                    </div>


                    <div class="card-footer">
                      <button class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        " @click="backForm" type="button">
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button class="btn btn-primary float-end btn-sm" type="submit">
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>

                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import myMixinAddress from '@/middleware/mixinAddress.js';


export default {
  name: "BranchAdd",
  props: {},
  mixins: [myMixinAddress],
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {

      isLoading: false,

      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      poolAll: [],
      permision_role: [],
      userData: "",
      companyCode: "",
      forms: { name: "", usernmae: "" },
    };
  },
  watch: {},
  methods: {
    isNumberLatLong(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 45) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    addField() {
      //fieldType.push({ value: "" });
      this.poolAll.push({
        pool_name: "",
        pool_province: "",
        pool_address: "",
        pool_city: "",
        pool_postalcode: "",
        method_upload: "",
        pool_latitude: "",
        pool_longitude: "",
      });
    },
    resetForm() {
      this.forms.branch_name = "";
      this.forms.description = "";
      this.forms.sequence = "";
      this.forms.value = "";
    },

    backForm() {
      window.location.href = "/branch";
    },

    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },
    submitData() {
      this.isLoading = true

      const baseURI = this.$settings.endPointCt + "branch/create";
      this.forms.pools = this.poolAll
      this.$http
        .post(baseURI, this.forms, { timeout: 30000 })
        .then((response) => {
          this.isLoading = false
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            //console.log(response.data.datas.user_id);
            var params = this.$onRandom(response.data.datas.branch_id);
            window.location.href = "/branch/detail/" + params + "?status=200&msg=Successfully";
            // this.success('Berhasil');
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        }).catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error("Please Check Your Internet Connection");
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }

          }


        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "BranchController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () { },
  mounted() {
    this.fetchIt();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content {
  filter: blur(5px);
  pointer-events: none;

}
</style>