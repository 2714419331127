<template>
    <div>
      <div class="main-wrapper">
        <!--begin::sidebar-->
        <sidebar-component classMenu="BillableManageService"></sidebar-component>
        <!--end::sidebar-->
  
        <div class="page-wrapper">
          <!--begin::navbar-->
          <navbar-component></navbar-component>
          <!--end::navbar-->
  
          <!--begin::content-->
          <div class="page-content">
            <div
              class="
                d-flex
                justify-content-between
                align-items-center
                flex-wrap
                grid-margin
              "
            >
              <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
                <div class="card">
                  <div class="card-header">
                    <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                    &nbsp;&nbsp;&nbsp;
                    <h6 class="card-title mb-0 float-start" style="padding-left:25px">Detail Manage Service</h6>
                    <button
                      class="btn btn-light float-end"
                      type="button"
                      data-bs-toggle="collapse" 
                      data-bs-target="#collapseExample" 
                      aria-expanded="false" 
                      aria-controls="collapseExample"
                    >
                      <i class="link-icon" data-feather="info"></i>
                      Audit Info
                    </button>
  
                    <div class="row mb-3 collapse" id="collapseExample" style="padding-left:20px">
                      <div class="col-lg-3">
                        <label class="tx-11 fw-bolder mb-0 ">Created By:</label>
                        <p class="text-muted">{{this.forms.create_by}}</p>
                      </div>
  
                      <div class="col-lg-3">
                        <label class="tx-11 fw-bolder mb-0">Created At:</label>
                        <p class="text-muted">{{this.forms.created_at}}</p>
                      </div>
  
                      <div class="col-lg-3">
                        <label class="tx-11 fw-bolder mb-0">Updated By:</label>
                        <p class="text-muted">{{this.forms.update_by}}</p>
                      </div>
  
                      <div class="col-lg-3">
                        <label class="tx-11 fw-bolder mb-0">Updated At:</label>
                        <p class="text-muted">{{this.forms.updated_at}}</p>
                      </div>
  
                      
                      
                    </div>
  
                  </div>
  
                  <div class="card-body">
                    <!--begin::loading-data-->
                    <div v-if="isLoading" class="d-flex align-items-center">
                      <div
                        class="spinner-border ms-auto"
                        role="status"
                        aria-hidden="true"
                      ></div>
                      <strong>{{ $t("loadingTxt") }}...</strong>
                    </div>
                    <!--end::loading-data-->
                    <form
                      class="forms-sample"
                      @submit.prevent="submitData"
                      method="POST"
                    >
                      <div
                        class="
                          d-flex
                          justify-content-between
                          align-items-baseline
                          mb-2
                        "
                      ></div>


                      <div class="row">
                      <div class="col-sm-6">
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >File Upload Cross Reference
                              <span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-lg-7">
                            <input
                              type="text"
                              class="form-control"
                              id="reference"
                              v-model="forms.file_upload_cross_reference"
                              readonly
                            />

                            <div v-if="errors.file_upload_cross_reference">
                              <div
                                v-for="error in errors.file_upload_cross_reference"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Effective Date</label
                            >
                          </div>
                          <div class="col-lg-3">
                            <input
                              type="text"
                              class="form-control"
                              id="reference"
                              v-model="forms.effective_date"
                              readonly
                            />


                            
                          </div>

                          <div class="col-lg-3">
                            <input
                              type="text"
                              class="form-control"
                              id="reference"
                              v-model="forms.effective_month"
                              readonly
                            />


                            
                          </div>

                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Expiration Date
                              <span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-lg-3">
                         
                            <input
                              type="text"
                              class="form-control"
                              id="reference"
                              v-model="forms.expiration_date"
                              readonly
                            />
                          
                          </div>

                          <div class="col-lg-3">
                         
                         <input
                           type="text"
                           class="form-control"
                           id="reference"
                           v-model="forms.expiration_month"
                           readonly
                         />
                       
                       </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Order Group
                              <span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-lg-7">
                            <input
                           type="text"
                           class="form-control"
                           id="reference"
                           v-model="forms.order_group"
                           readonly
                         />

                          
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Origin Regions
                              <span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-lg-7">
                            <input
                           type="text"
                           class="form-control"
                           id="reference"
                           v-model="forms.origin_regions"
                           readonly
                         />


                            
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Customers
                              <span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-lg-7">
                            <input
                           type="text"
                           class="form-control"
                           id="reference"
                           v-model="forms.customers"
                           readonly
                         />


                           
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Service Request Code
                              <span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-lg-7">
                            <input
                           type="text"
                           class="form-control"
                           id="reference"
                           v-model="forms.service_request_code"
                           readonly
                         />


                          
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Stop Type</label
                            >
                          </div>
                          <div class="col-lg-7">
                            <input
                           type="text"
                           class="form-control"
                           id="reference"
                           v-model="forms.stop_type"
                           readonly
                         />


                            <div v-if="errors.stop_type">
                              <div
                                v-for="error in errors.stop_type"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Stop Type</label
                            >
                          </div>
                          <div class="col-lg-7">
                            <input
                              type="text"
                              class="form-control"
                              id="reference"
                              v-model="forms.reference"
                              required
                            />

                            <div v-if="errors.reference">
                              <div
                                v-for="error in errors.reference"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div> -->
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Destination Region</label
                            >
                          </div>
                          <div class="col-lg-7">
                            <input
                           type="text"
                           class="form-control"
                           id="reference"
                           v-model="forms.destination_regions"
                           readonly
                         />

                            <div v-if="errors.reference">
                              <div
                                v-for="error in errors.reference"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Suppliers</label
                            >
                          </div>
                          <div class="col-lg-7">
                            <input
                           type="text"
                           class="form-control"
                           id="reference"
                           v-model="forms.suppliers"
                           readonly
                         />

                            <div v-if="errors.reference">
                              <div
                                v-for="error in errors.reference"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Apply Level</label
                            >
                          </div>
                          <div class="col-lg-7">
                            <input
                           type="text"
                           class="form-control"
                           id="reference"
                           v-model="forms.apply_level"
                           readonly
                         />

                            <div v-if="errors.reference">
                              <div
                                v-for="error in errors.reference"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Stop Locations</label
                            >
                          </div>
                          <div class="col-lg-7">
                            <input
                           type="text"
                           class="form-control"
                           id="reference"
                           v-model="forms.stop_locations"
                           readonly
                         />

                            <div v-if="errors.reference">
                              <div
                                v-for="error in errors.reference"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Commodities</label
                            >
                          </div>
                          <div class="col-lg-7">
                            <input
                           type="text"
                           class="form-control"
                           id="reference"
                           v-model="forms.commodities"
                           readonly
                         />

                            <div v-if="errors.reference">
                              <div
                                v-for="error in errors.reference"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
  
                      
  
                      <div class="card-footer">
                        <button
                          class="
                            btn btn-warning
                            text-black
                            fw-bolder
                            btn-icon-text
                          "
                          @click="backForm"
                          type="button"
                        >
                          <i class="link-icon" data-feather="arrow-left"></i>
                          {{ $t("backMess") }}
                        </button>
  
                       
                        <a
                          v-if="permision_role.method_update"
                          class="
                            btn btn-primary
                            me-2
                            text-white
                            fw-bolder
                            btn-icon-text
                            float-end
                          "
                          @click="editData()"
                        >
                          <i class="link-icon" data-feather="edit"></i>
                          {{ $t("editMess") }}
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end::content-->
  
          <!--begin::footer-->
          <footer-component></footer-component>
          <!--end::footer-->
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import sidebarComponent from "@/components/_partials/_sidebar";
  import navbarComponent from "@/components/_partials/_navbar";
  import footerComponent from "@/components/_partials/_footer";
  
  export default {
    name: "LocationDetail",
    props: {},
    components: {
      "sidebar-component": sidebarComponent,
      "navbar-component": navbarComponent,
      "footer-component": footerComponent,
    },
    data() {
      return {
        fetchTimeZone:[{
          name:'(GMT + 07.00 Asia / Jakarta)'
        }],
        timeZone:'',
        fetchCountry:[{
          name:'Indonesia'
        }],
        country:'',
        permision_role:[],
        isLoading: false,
        maxToasts: 100,
        position: "up right",
        closeBtn: true,
        errors: [],
        fetchCompany:[],
        fetchRegion:[],
        userData: "",
        companyCode: "",
        forms: [],
        center: { 
          lat: 39.7837304,
          lng: -100.4458825
        },
        locationMarkers: [],
        locPlaces: [],
        existingPlace: null
      };
    },
  
    
    watch: {},
    methods: {
      resetForm() {
  
      },
  
      cek_message(status,msg){
        if(status=='200'){
          this.success(msg);
        }
      },
  
  
      editData(){
        var params = this.$route.params.id
        window.location.href = "/billable-manage-service/edit/"+ params;
      },
  
  
      deleteData() {
        var id = this.$onBehind(this.$route.params.id);
        this.$swal({
          title: this.$t("areYouSure"),
          text: this.$t("yourDataDelete"),
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        }).then((result) => {
          if (result.value) {
            var formData = {
              status: status,
            };
            const baseURI =
              this.$settings.endPoint + "rm-freight-billable-parties/change/" + id;
            this.$http
              .delete(baseURI, formData)
              .then((response) => {
                this.loading();
                if (response.data.status === 200) {
                  this.success(response.data.datas.messages);
                  window.location.href = "/billable-parties";
                } else {
                  this.errors = response.data.errors;
                  this.resultError(response.data.errors);
                }
              })
              .catch((error) => {
                this.loading();
                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.error(error.response.data.errors.message);
                  } else {
                    this.$router.push("/page-not-found");
                  }
                }
              });
          }
        });
      },
  
      initMarker(loc) {
        this.existingPlace = loc;
      },
  
      backForm() {
        window.location.href = "/billable-manage-service";
      },
  
  
     
  
  
      loadData(id) {
        const baseURI = this.$settings.endPoint + "rm-service-request-app/detail/" + id;
        return this.$http.get(baseURI).then((response) => {
          
          this.forms = response.data.datas;
         
          // this.form={ name: 'asep', usernmae:res.username };
        });
      },
  
  
    
      fetchIt() {
        const userDatas     = this.$getUserInfo();
        this.detailUser     = userDatas.sub;
        // this.permision_role = userDatas.sub.permision_role;
        let rolePermision = [];
        userDatas.sub.permision_role.forEach(function(permision){
          if(permision.permission.controller == 'RmServiceRequestAppController'){
            rolePermision = permision;
            // console.log(permision);
          }
        });
  
        if(!rolePermision.method_read){
          this.$router.push("/authorized-error");
        }
  
        this.permision_role = rolePermision;
  
      },
  
  
      fade(sType) {
        this.isLoading = sType;
      },
  
      loading() {
        this.fade(true);
        setTimeout(() => {
          this.fade(false);
        }, 1000); // hide the message after 3 seconds
      },
  
      resultError(data) {
        var count = Object.keys(data).length;
        for (var x = 0; x < count; x++) {
          var nameOb = Object.keys(data)[x];
          var objectData = data[nameOb];
          for (var y = 0; y < objectData.length; y++) {
            this.error(objectData[y]);
          }
        }
      },
  
      success(kata) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
          // onShown: function() {
          //     console.log('asdasdasd');
          //     //window.location.href = '/';
          // }
        });
  
        Toast.fire({
          icon: "success",
          title: kata,
        });
      },
  
      error(kata) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: kata,
        });
      },
    },
    events: {},
    created: function () {},
    mounted() {
      var params = this.$onBehind(this.$route.params.id);
      this.cek_message(this.$route.query.status,this.$route.query.msg);
      this.loadData(params[0]);
      this.fetchIt();
    
    },
  };
  </script>
  <style scoped>
  </style>