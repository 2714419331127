import BillableRateManagement from "@/components/dasboard-rm/billableRateManagement/BillableRateManagement.vue";
// import BillableRateManagementDetail from "@/components/dasboard-rm/billableRateManagement/BillableRateManagementDetail.vue";
import BillableRateManagementDetailNew from "@/components/dasboard-rm/billableRateManagement/BillableRateManagementDetailNew.vue";
import BillableRateManagementAdd from "@/components/dasboard-rm/billableRateManagement/BillableRateManagementAdd.vue";
import BillableRateManagementEdit from "@/components/dasboard-rm/billableRateManagement/BillableRateManagementEdit.vue";
import BillableRateManagementDetailDetail from "@/components/dasboard-rm/billableRateManagement/BillableRateManagementDetailDetail.vue";
import BillableRateManagementDetailAcc from "@/components/dasboard-rm/billableRateManagement/BillableRateManagementDetailAcc.vue";
import BillableRateManagementAddAcc from "@/components/dasboard-rm/billableRateManagement/BillableRateManagementAddAcc.vue";
import BillableRateManagementEditAcc from "@/components/dasboard-rm/billableRateManagement/BillableRateManagementEditAcc.vue";

let routesBillableRateManagement = [
  {
    path: "",
    meta: {
      title: "BillableRateManagement",
    },
    name: "BillableRateManagement",
    component: BillableRateManagement,
  },
  {
    path: "detail/:id",
    meta: {
      title: "BillableRateManagementDetail",
    },
    name: "BillableRateManagementDetail",
    // component: BillableRateManagementDetail
    component: BillableRateManagementDetailNew,
  },
  {
    path: "acc/:id",
    meta: {
      title: "BillableRateManagementDetailAcc",
    },
    name: "BillableRateManagementDetailAcc",
    component: BillableRateManagementDetailAcc,
  },
  {
    path: "detail/:id/edit/:detailId",
    meta: {
      title: "BillableRateManagementEdit",
    },
    name: "BillableRateManagementEdit",
    component: BillableRateManagementEdit,
  },
  {
    path: "detail/:id/detail/:detailId",
    meta: {
      title: "BillableRateManagementDetailDetail",
    },
    name: "BillableRateManagementDetailDetail",
    component: BillableRateManagementDetailDetail,
  },
  {
    path: "add/:id",
    meta: {
      title: "BillableRateManagementAdd",
    },
    name: "BillableRateManagementAdd",
    component: BillableRateManagementAdd,
  },
  {
    path: "add-acc/:id",
    meta: {
      title: "BillableRateManagementAddAcc",
    },
    name: "BillableRateManagementAddAcc",
    component: BillableRateManagementAddAcc,
  },
  {
    path: "detail/:id/edit-acc/:detailId",
    meta: {
      title: "BillableRateManagementEditAcc",
    },
    name: "BillableRateManagementEditAcc",
    component: BillableRateManagementEditAcc,
  },
];

export default routesBillableRateManagement;
