<template>
    <div>
      <div class="loader" v-if="this.isLoading"></div>   
      <div class="loadertext" v-if="this.isLoading"> {{$t('loadingTxt')}}...</div>   
      <div class="main-wrapper" :class="{'blur-content': this.isLoading}">
        <!--begin::sidebar-->
        <sidebar-component classMenu="Users"></sidebar-component>
        <!--end::sidebar-->
  
        <div class="page-wrapper">
          <!--begin::navbar-->
          <navbar-component></navbar-component>
          <!--end::navbar-->
  
          <!--begin::content-->
          <div class="page-content">
            <div
              class="
                d-flex
                justify-content-between
                align-items-center
                flex-wrap
                grid-margin
              "
            >
              <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
                <div class="card">
                  
                   <div class="card-header">
                    <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                    &nbsp;&nbsp;&nbsp;
                    <h6 class="card-title mb-0 float-start" style="padding-left:25px">Detail {{ $t("txtUsers") }}</h6>
                    <button
                      class="btn btn-light float-end"
                      type="button"
                      data-bs-toggle="collapse" 
                      data-bs-target="#collapseExample" 
                      aria-expanded="false" 
                      aria-controls="collapseExample"
                    >
                      <i class="link-icon" data-feather="info"></i>
                      Audit Info
                    </button>
  
                    <div class="row mb-3 collapse" id="collapseExample" style="padding-left:20px">
                      <div class="col-lg-3">
                        <label class="tx-11 fw-bolder mb-0 ">Created By:</label>
                        <p class="text-muted">{{this.forms.create_by}}</p>
                      </div>
  
                      <div class="col-lg-3">
                        <label class="tx-11 fw-bolder mb-0">Created At:</label>
                        <p class="text-muted">{{this.forms.created_at}}</p>
                      </div>
  
                      <div class="col-lg-3">
                        <label class="tx-11 fw-bolder mb-0">Updated By:</label>
                        <p class="text-muted">{{this.forms.update_by}}</p>
                      </div>
  
                      <div class="col-lg-3">
                        <label class="tx-11 fw-bolder mb-0">Updated At:</label>
                        <p class="text-muted">{{this.forms.updated_at}}</p>
                      </div>
  
                      
                      
                    </div>
  
                  </div>
                  <div class="card-body">
                    <!--begin::loading-data-->
                    <div v-if="isLoading" class="d-flex align-items-center">
                      <div
                        class="spinner-border ms-auto"
                        role="status"
                        aria-hidden="true"
                      ></div>
                      <strong>{{ $t("loadingTxt") }}...</strong>
                    </div>
                    <!--end::loading-data-->
  
                    <form
                      class="forms-sample"
                      @submit.prevent="submitData"
                      method="POST"
                    >
                      <div
                        class="
                          d-flex
                          justify-content-between
                          align-items-baseline
                          mb-2
                        "
                      >
                      
                      </div>
                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">User Type</label>
                        </div>
                        <div class="col-lg-8">
                          <input
                            type="text"
                            class="form-control"
                            id="user_type"
                            v-model="forms.user_type"
                            readonly
                          />
                          <div v-if="errors.name">
                            <div
                              v-for="error in errors.name"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
  
  
                       <div class="row mb-3">
                        <div class="col-lg-2">
                          <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                            $t("nameTxt")
                          }}</label>
                        </div>
                        <div class="col-lg-8">
                          <input
                            type="text"
                            class="form-control"
                            id="nameTxt"
                            :placeholder="$t('nameTxt')"
                            v-model="forms.name"
                            readonly
                          />
                          <div v-if="errors.name">
                            <div
                              v-for="error in errors.name"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
  
                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                            $t("usernameTxt")
                          }}</label>
                        </div>
                        <div class="col-lg-8">
                          <input
                            type="text"
                            class="form-control"
                            id="usernameTxt"
                            :placeholder="$t('usernameTxt')"
                            v-model="forms.username"
                            readonly
                          />
                          <div v-if="errors.username">
                            <div
                              v-for="error in errors.username"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
  
  
                     
  
  
  
  
                       <div class="row mb-3">
                        <div class="col-lg-2">
                          <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                            $t("emailAddress")
                          }}</label>
                        </div>
                        <div class="col-lg-8">
                          <input
                            type="email"
                            class="form-control"
                            id="emailAddress"
                            :placeholder="$t('emailAddress')"
                            v-model="forms.email"
                            readonly
                          />
                          <div v-if="errors.email">
                            <div
                              v-for="error in errors.email"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
  
  
                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                            $t("companyCode")
                          }}</label>
                        </div>
                        <div class="col-lg-8">
                          
                           <select class="form-control" name="company_id" id="company_id" v-model="forms.company_id" disabled>
                            <option  v-for="(a, index) of fetchCompany" :value="a.company_id" :key="index">{{a.code}} | {{a.name}}</option>
                          </select>
                         
                          <div v-if="errors.company_id">
                            <div
                              v-for="error in errors.company_id"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
  
  
                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                            $t("roleTxt")
                          }}</label>
                        </div>
                        <div class="col-lg-8">
  
                          <select class="form-control" name="role_id" id="role_id" v-model="forms.role_id" disabled>
                              <option  v-for="(a, index) of fetchRole" :value="a.role_id" :key="index">{{a.name}}</option>
                          </select>
  
                          <div v-if="errors.roleId">
                            <div
                              v-for="error in errors.roleId"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
  
  
                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                            $t("statusTxt")
                          }}</label>
                        </div>
                        <div class="col-lg-8">
  
                          <select class="form-control" name="status" id="status" v-model="forms.status" disabled>
                              <option  v-for="(a, index) of fetchStatus" :value="a" :key="index">{{a}}</option>
                          </select>
  
                          <div v-if="errors.roleId">
                            <div
                              v-for="error in errors.roleId"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      
  
  
  
  
  
  
  
                     
                      <div class="card-footer">
                        <button
                          class="
                            btn btn-warning
                            text-black
                            fw-bolder
                            btn-icon-text
                          "
                          @click="backForm"
                          type="button"
                        >
                          <i class="link-icon" data-feather="arrow-left"></i>
                          {{ $t("backMess") }}
                        </button>
  
                        <a
                          v-if="permision_role.method_delete"
                          class="
                            btn btn-danger
                            text-white
                            fw-bolder
                            btn-icon-text
                            float-end
                          "
                          @click="deleteData()"
                        >
                          <i class="link-icon" data-feather="trash"></i>
                          {{ $t("deleteMess") }}
                        </a>
  
                        <a
                          v-if="permision_role.method_update"
                          class="
                            btn btn-primary
                            me-2
                            text-white
                            fw-bolder
                            btn-icon-text
                            float-end
                          "
                          @click="editData()"
                        >
                          <i class="link-icon" data-feather="edit"></i>
                          {{ $t("editMess") }}
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end::content-->
  
          <!--begin::footer-->
          <footer-component></footer-component>
          <!--end::footer-->
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import sidebarComponent from "@/components/_partials/_sidebar";
  import navbarComponent from "@/components/_partials/_navbar";
  import footerComponent from "@/components/_partials/_footer";
  
  export default {
    name: "FakturPajakUserAccessDetail",
    props: {},
    components: {
        "sidebar-component": sidebarComponent,
        "navbar-component": navbarComponent,
        "footer-component": footerComponent,
    },
    data() {
        return {
            fetchStatus:["active","deactived"],
            isLoading: false,
            maxToasts: 100,
            position: "up right",
            closeBtn: true,
            errors: [],
            userData: "",
            companyCode: "",
            fetchRole:[],
            fetchCompany:[],
            permision_role:[],
            forms: { 
                user_id:"",
                user_type:"",
                company_id:"",
                role_id:"",
                name:"",
                email:"",
                username:"",
                email_verified_at:"",
                password:"",
                remember_token:"",
                status:"",
                create_by:"",
                update_by:"",
                created_at:"",
                updated_at:""
            },
        };
    },
    watch: {},
    methods: {

        resetForm() {
            this.forms.user_id=""
            this.forms.user_type=""
            this.forms.company_id=""
            this.forms.role_id=""
            this.forms.name=""
            this.forms.email=""
            this.forms.username=""
            this.forms.email_verified_at=""
            this.forms.password=""
            this.forms.remember_token=""
            this.forms.status=""
            this.forms.create_by=""
            this.forms.update_by=""
            this.forms.created_at=""
            this.forms.updated_at=""
            this.company=[];
            this.role=[];
        },
    
    
    
        cek_message(status,msg){
            if(status=='200'){
            this.success(msg);
            }
        },
    
    
        editData(){
            var params = this.$route.params.id
            window.location.href = "/faktur-pajak/user-access/edit/"+ params;
        },
    
    
        deleteData() {
            var id = this.$onBehind(this.$route.params.id);
            this.$swal({
            title: this.$t("areYouSure"),
            text: this.$t("yourDataDelete"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!",
            }).then((result) => {
            if (result.value) {
                var formData = {
                status: status,
                };
                const baseURI =
                this.$settings.endPoint + "user-faktur-pajak/delete/" + id;
                this.$http
                .delete(baseURI, formData)
                .then((response) => {
                    this.loading();
                    if (response.data.status === 200) {
                    this.success(response.data.datas.messages);
                    window.location.href = "/faktur-pajak/user-access";
                    } else {
                    this.errors = response.data.errors;
                    this.resultError(response.data.errors);
                    }
                })
                .catch((error) => {
                    this.loading();
                    if (error.response) {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                        this.resultError(error.response.data.errors);
                    } else if (error.response.status === 500) {
                        this.error(error.response.data.errors.message);
                    } else {
                        this.$router.push("/page-not-found");
                    }
                    }
                });
            }
            });
        },
    
        backForm() {
            window.location.href = "/faktur-pajak/user-access";
        },
    
    
        loadData(id) {
            const baseURI = this.$settings.endPoint + "user-faktur-pajak/detail/"+id;
            this.isLoading = true;
            return this.$http
            .get(
                baseURI,{ timeout: 10000 }
            )
            .then((response) => {
                this.forms      = response.data.datas;
                this.fetchRole  = [response.data.datas.role]
                this.fetchCompany  = [response.data.datas.company_detail]
                this.isLoading = false;
                
            }).catch(error => {
                this.isLoading = false;
                if(error.code == "ECONNABORTED"){
                this.error(this.$t("check_internet_connection_txt"));
                
                }else{
    
                if (error.response) {
                    if(error.response.status === 422) {
                            this.errors = error.response.data.errors;
                            this.resultError(error.response.data.errors);
                    }else if (error.response.status === 500) {
                        this.$router.push('/server-error');
                    }else if (error.response.status === 401) {
                        this.$router.push("/authorized-error");
                    }else{
                        this.$router.push('/page-not-found');
                    }
                }
    
                }
            });
        },
    
    
        submitData() {
            this.$swal({
            title: this.$t("areYouSure"),
            text: this.$t("yourData"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!",
            }).then((result) => {
            if (result.value) {
                this.fade(true);
                const baseURI  =  this.$settings.endPoint+"user-faktur-pajak/update/"+this.forms.user_id;
                this.$http.patch(baseURI,this.forms).then((response) => {
                    this.loading();
                    if(response.data.status === 200) {
                    this.resetForm();
                        this.errors = [];
                        this.success(response.data.datas.messages);
    
                        //window.history.back();
                        var params = this.$onRandom(this.forms.user_id);
                        window.location.href = "/faktur-pajak/user-access/detail/" + params;
    
                    }else{
                        this.errors = response.data.errors;
                        this.resultError(response.data.errors);
                    }
                }).catch(error => {
                    this.loading();
                    if (error.response) {
                    if(error.response.status === 422) {
                            this.errors = error.response.data.errors;
                            this.resultError(error.response.data.errors);
                    }else if (error.response.status === 500) {
                        this.$router.push('/server-error');
                    }else{
                        this.$router.push('/page-not-found');
                    }
                    }
                    this.resetForm();
                });
            }
            });
        },
    
        fade(sType) {
            this.isLoading = sType;
        },
    
        loading() {
            this.fade(true);
            setTimeout(() => {
            this.fade(false);
            }, 1000); // hide the message after 3 seconds
        },
    
        resultError(data) {
            var count = Object.keys(data).length;
            for (var x = 0; x < count; x++) {
            var nameOb = Object.keys(data)[x];
            var objectData = data[nameOb];
            for (var y = 0; y < objectData.length; y++) {
                this.error(objectData[y]);
            }
            }
        },
    
        success(kata) {
            const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
            // onShown: function() {
            //     console.log('asdasdasd');
            //     //window.location.href = '/';
            // }
            });
    
            Toast.fire({
            icon: "success",
            title: kata,
            });
    
        },
    
        error(kata) {
            const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
            });
            Toast.fire({
            icon: "error",
            title: kata,
            });
        },

        fetchIt() {
            const userDatas     = this.$getUserInfo();
            this.detailUser     = userDatas.sub;
            // this.permision_role = userDatas.sub.permision_role;
            let rolePermision = [];
            userDatas.sub.permision_role.forEach(function(permision){
            if(permision.permission.controller == 'UserFpController'){
                rolePermision = permision;
                // console.log(permision);
            }
            });
    
            if(!rolePermision.method_read){
            this.$router.push("/authorized-error");
            }
            this.permision_role = rolePermision;
    
        },
    
    },
    events: {},
    created: function () {},
    mounted() {
  
        var params = this.$onBehind(this.$route.params.id);
        this.cek_message(this.$route.query.status,this.$route.query.msg);
        this.fetchIt();
        this.loadData(params[0]);
    },
  };
  </script>
  <style scoped>
  button.gm-ui-hover-effect {
    visibility: hidden;
  }
  
  .blur-content{
    filter: blur(5px); 
    pointer-events: none;
    
  }
  </style>