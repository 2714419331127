<template>
    <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Download Deposit Driver</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
              aria-label="btn-close"></button>
          </div>
  
          <form class="forms-sample" method="POST" @submit.prevent="submitData">
            <div class="modal-body">
              <div v-if="isLoading" class="d-flex align-items-center">
                <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                <strong>Loading...</strong>
              </div>
              <!--end::loading-data-->  
              <div class="mb-3">
                <label class="form-label">Date Deposit </label>
                <Datepicker
                  v-model="forms.tanggal"
                  :bootstrap-styling="true"
                  format="MM-yyyy"
                  placeholder="Please Select Date..."
                  minimum-view="month" 
                ></Datepicker>
               
              </div>

            <!-- <div class="mb-3">
                <label class="form-label">{{ $t("teguran") }}</label>
                <input type="text" id="teguran" name="teguran" v-model="forms.teguran" class="form-control"
                     />
            </div> -->

            

          
             
  
             
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
                Close
              </button>
              <button class="btn btn-primary" type="submit">Download</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Datepicker from "vuejs-datepicker";
  import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
  import moment from "moment";
  
  export default {
    
    components: {
      Datepicker
      // DateRangePicker
    },
    name: "ModalDownloadDepositDriver",
    emits: ["closeModal"],
    props: {
      showModal: Boolean,
      data: null,
    },
    watch: {
      showModal: {
        handler(newVal) {
          this.forms = {
            
            tanggal: '',
          
          };
  
          this.active = newVal;
          const body = document.querySelector("body");
          this.showModal
            ? body.classList.add("modal-open")
            : body.classList.remove("modal-open");
        },
        immediate: true,
        deep: true,
      },
    },
    data() {
      return {
        isLoading: false,
        active: this.showModal,
        forms: [],
        locale: [],
        return_date: [],
        delivery_date: [],
        equipment_name: [],
        fetchEquipmentName : [],
      };
    },
    methods: {
      resetForm() {
        this.forms = [];
      },
  
      submitData() {
        this.forms.tanggal = moment(
            String(this.forms.tanggal)
          ).format("YYYY-MM");
        
      const baseURI = this.$settings.endPointCt + "/driver-deposit-detail/download-driver-deposit";
      var file_name = "download_" + this.forms.tanggal + ".xlsx";

        return this.$http
        .get(
          baseURI +
          `?date=`+this.forms.tanggal,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
         
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.$emit("closeModal");
          
        });
      },
  
      // loadDetail(data) {
      //   console.log(data);
      // },
  
      resultError(data) {
        var html = "";
        var count = Object.keys(data).length;
        for (var x = 0; x < count; x++) {
          var nameOb = Object.keys(data)[x];
          var objectData = data[nameOb];
          for (var y = 0; y < objectData.length; y++) {
            html += objectData[y] + "<br>";
          }
        }
  
        this.loadError(html);
      },
  
      loadError(pesan) {
        this.$swal({
          backdrop: true,
          title: "Warning",
          html: pesan,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      },
  

  
      loadSuccess(pesan) {
        this.$swal({
          backdrop: true,
          title: "Success",
          html: pesan,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      },
  
    //   loadEquipment() {
    //   const baseURI =
    //     this.$settings.endPointCt + `master-lookup?unique_id=equip `;

    //   return this.$http.get(baseURI).then((response) => {
    //     this.equipment_name = response.data.datas;
    //   });
    // },
    },
    created: function () {
      //this.loadDriver();
  
    },
    mounted() {
      //tail(this.data);
      // this.loadEquipment();
    },
  };
  </script>
  
  <style scoped>
  .modal {
    background: rgba(0, 0, 0, 0.4);
  }
  
  .modal-content {
    background: #fff;
    border-radius: 0px;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 0px;
    width: 100%;
    height: 100%;
    margin-left:0px !important;
  }
  </style>