<template>
    <div>
      <router-view />
    </div>
  </template>
  <script>
  
  export default {
    name: "NewsPortalGTIndex",
    data () {
      return {}
    },
    created () {
  
    },
    methods: {
    
    },
  }
  
  </script>
  