import decode from 'jwt-decode';
import axios from 'axios';

const AUTH_TOKEN_KEY = process.env.VUE_APP_JWT_SECRET

export function logoutUser() {
    clearAuthToken()
}
export function setAuthToken(token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    localStorage.setItem(AUTH_TOKEN_KEY, token)
}

export function setAuthLang(lang) {
    axios.defaults.headers.common['Accept-Language'] = lang
}

export function getAuthToken() {
    return localStorage.getItem(AUTH_TOKEN_KEY)    
}

export function clearAuthToken() {
    axios.defaults.headers.common['Authorization'] = ''
    localStorage.removeItem(AUTH_TOKEN_KEY)
}

export function isLoggedIn() {
    let authToken = getAuthToken()
    return !!authToken && !isTokenExpired(authToken)
}

export function getUserInfo() {
  

    if (isLoggedIn()) {
        let token = decode(getAuthToken())
        token.sub = JSON.parse(atob(token.sub)) 
        return token
    }

}

// export function getUserInfo() {
//     if (isLoggedIn()) {
//         return decode(getAuthToken())
//     }
// }

export function getCoba() {
    return 'ok';
}

function getTokenExpirationDate(encodedToken) {
    let token = decode(encodedToken)
    if (!token.exp) {
        return null
    }
  
    let date = new Date(0)
    date.setUTCSeconds(token.exp)
  
    return date
}
  
function isTokenExpired(token) {
    let expirationDate = getTokenExpirationDate(token)
    return expirationDate < new Date()
}