<template>
  <div :class="{'blur-content': isLoading}">
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TransportOrderCtDetail"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          

            <form
            class="forms-sample"
            @submit.prevent="submitData"
            method="POST"
            >

            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 25px"
                  >
                    {{$t('edit_transport_order_txt')}}
                  </h6>
                  <button
                    class="btn btn-light float-end"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <i class="link-icon" data-feather="info"></i>
                    {{$t('audit_info_txt')}}
                  </button>

                  <button
                    class="btn btn-primary float-end"
                    type="button"
                    style="margin-right: 20px"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <i class="link-icon" data-feather="info"></i>
                    {{$t('tracking_status_txt')}}
                  </button>


                  <button
                    v-if="!isMaps"
                    class="btn btn-success float-end"
                    type="button"
                    style="margin-right: 20px"
                    @click="onClickShowMaps(true)"
                  >
                    <i class="mdi mdi-google-maps"></i>
                    {{$t('show_map_txt')}}
                  </button>

                  <button
                    v-if="isMaps"
                    class="btn btn-danger float-end"
                    type="button"
                    style="margin-right: 20px"
                    @click="onClickShowMaps(false)"
                  >
                    <i class="mdi mdi-google-maps"></i>
                    {{$t('hide_map_txt')}}
                  </button>

                  <div
                    class="row mb-3 collapse"
                    id="collapseExample"
                    style="padding-left: 20px"
                  >
                    <div class="row">
                       <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">{{$t('create_by_txt')}}:</label>
                      <p class="text-muted">{{ this.forms.create_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">{{$t('create_at_txt')}}:</label>
                      <p class="text-muted">{{ ubahTgl(this.forms.created_at) }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">{{$t('update_by_txt')}}:</label>
                      <p class="text-muted">{{ this.forms.update_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">{{$t('update_at_txt')}}:</label>
                      <p class="text-muted">{{ ubahTgl(this.forms.updated_at) }}</p>
                    </div>

                    </div>


                     <div class="row">
                       <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">{{$t('integration_status_txt')}}:</label>
                      <p class="text-muted" v-if="this.forms.integration_status==1">True</p>
                      <p class="text-muted" v-else>False</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">{{$t('integration_description_txt')}}:</label>
                      <p class="text-muted">{{ this.forms.integration_description }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">{{$t('load_id_txt')}} :</label>
                      <p class="text-muted">{{ this.forms.load_id }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">{{$t('tms_id_txt')}} :</label>
                      <p class="text-muted">{{ this.forms.tms_id }}</p>
                    </div>

                    </div>


                     <div class="row">
                       <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">{{$t('status_tms_txt')}} :</label>
                     <p class="text-muted">{{ this.forms.status_tms }}</p>
                    </div>

                   

                    </div>
                   

                    
                  </div>
                </div>

                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->
                
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "
                    ></div>

                    <div class="row card">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-3">
                            <div class="mb-3">
                              <label class="form-label">{{$t('companyTxt')}}</label>
                              <p class="text-muted">
                                <b>{{ company.name }}</b>
                              </p>
                            </div>
                          </div>
                          <!-- Col -->
                          <div class="col-sm-3">
                            <div class="mb-3">
                              <label class="form-label">{{$t('order_no_txt')}}</label>
                              <p class="text-muted">
                                <b>{{ forms.order_no }}</b>
                              </p>
                            </div>
                          </div>
                          <!-- Col -->

                          <div class="col-sm-3">
                            <div class="mb-3">
                              <label class="form-label">{{$t('order_date_txt')}} </label>
                              <p class="text-muted">
                                <b>{{ ubahTglOrder(forms.order_date) }}</b>
                              </p>
                            </div>
                          </div>

                          <div class="col-sm-3">
                            <div class="mb-3">
                              <label class="form-label">Branch / Truck Type </label> <!--{{$t('template_order_txt')}} -->
                             <v-select
                                :options="fetchTemplateOrder"
                                v-model="template_order"
                                label="description"
                                return-object
                                @search="asyncSearchTemplateOrder"
                              ></v-select>
                              <div
                                v-for="error in errors.template_order_id"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>

                            </div>
                          </div>
                        </div>

                        <br>

                        <div class="row">
                          <div class="col-sm-12">
                            <div class="mb-3">
                              <label class="form-label">{{$t('remarks_txt')}}</label>
                              <p class="text-muted">
                                <b>{{ forms.remarks }}</b>
                                
                              </p>
                            </div>
                          </div>
                          <!-- Col -->
                          
                          <!-- Col -->

                       

                    
                        </div>
                        
                        
                        <div class="row">
                          <div class="col-sm-3">
                            <div class="mb-3">
                              <label class="form-label">{{$t('shipwith_ref_txt')}}</label>
                              <p class="text-muted">
                                <b v-if="forms.shipwith_ref!=''">{{ forms.shipwith_ref }}</b>
                                <b v-else>-</b>
                              </p>
                            </div>
                          </div>
                          <!-- Col -->
                          <div class="col-sm-3">
                            <div class="mb-3">
                              <label class="form-label">{{$t('tonnage_txt')}}</label>
                              <p class="text-muted">
                                <b v-if="forms.tonnage!=''">{{ forms.tonnage }}</b>
                                <b v-else>-</b>
                              </p>
                            </div>
                          </div>
                          <!-- Col -->

                          <div class="col-sm-3">
                            <div class="mb-3">
                              <label class="form-label">{{$t('cubic_txt')}}</label>
                              <p class="text-muted">
                                 <b v-if="forms.cubic!=''">{{ forms.cubic }}</b>
                                <b v-else>-</b>
                              </p>
                            </div>
                          </div>


                          <div class="col-sm-3">
                            <div class="mb-3">
                              <label class="form-label">{{$t('order_status_txt')}}</label>
                              <p class="text-muted">
                                 <b >{{ forms.order_status }}</b>
                                
                              </p>
                            </div>
                          </div>

                    
                        </div>
                      </div>

                      <!-- Col -->
                    </div>
                    <!-- Row -->
                    <br />

                    <div class="row">
                      <div class="col-sm-6">
                        <div class="card">
                          <div class="card-header">
                            <h5>{{$t('pickup_txt')}}</h5>
                          </div>

                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="mb-3">
                                  <label class="form-label"
                                    >{{$t('pickup_location_txt')}}</label
                                  >
                                   <p class="text-muted" v-if="pickup_address">
                                  <b>{{ forms.pick_name }}</b>
                                  <i class="mdi mdi-map-marker"></i>{{$t('latitude_txt')}}.{{
                                    forms.pick_latitude
                                  }}
                                  {{$t('longitude_txt')}}.{{ forms.pick_longitude }} <br />
                                  {{ forms.pick_address1 }}
                                  {{ forms.pick_address2 }}
                                  {{ forms.pick_address2 }}
                                  {{ forms.pick_sub_district }},
                                  {{ forms.pick_district }},
                                  {{ forms.pick_city }},
                                  {{ forms.pick_province }}
                                  {{ forms.pick_postal_code }},
                                  {{ forms.pick_country }}<br />
                                  {{ forms.pick_contact_name }} -
                                  {{ forms.pick_phone_number }}
                                </p>
                                </div>
                              </div>

                              <div class="col-sm-6">
                                <div class="mb-3">
                                  <label class="form-label">{{$t('pickup_plan_txt')}}</label>
                                  

                                  <date-range-picker style="width:100%" :date-format="dateFormat"  control-container-class="form-control" :locale-data="locale" v-model="pickupDates" :opens="opens" :singleDatePicker="true" :timePicker24Hour="true" :timePicker="true" :showWeekNumbers="false" >
                                
                                  </date-range-picker>

                                  <!-- <input type="text" id="order_date" name="order_date" v-model="forms.order_date" class="form-control" placeholder="Order Date"> -->
                                  <div
                                    v-for="error in errors.pick_plan"
                                    :key="error"
                                    class="alert alert-primary"
                                    role="alert"
                                  >
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>

                                </div>
                              </div>
                            </div>
                           
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-6">
                        <div class="card">
                          <div class="card-header">
                            <h5>{{$t('destination_txt')}}</h5>
                          </div>

                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="mb-3">
                                  <label class="form-label"
                                    >{{$t('destination_location_txt')}}</label
                                  >
                                   <p class="text-muted" v-if="destination_address">
                                  <b>{{ forms.dest_name }}</b>
                                  <i class="mdi mdi-map-marker"></i>{{$t('latitude_txt')}}.{{
                                    forms.dest_latitude
                                  }}
                                  {{$t('longitude_txt')}}.{{ forms.dest_longitude }} <br />
                                  {{ forms.dest_address1 }}
                                  {{ forms.dest_address2 }}
                                  {{ forms.dest_address2 }}
                                  {{ forms.dest_sub_district }},
                                  {{ forms.dest_district }},
                                  {{ forms.dest_city }},
                                  {{ forms.dest_province }}
                                  {{ forms.dest_postal_code }},
                                  {{ forms.dest_country }}<br />
                                  {{ forms.dest_contact_name }} -
                                  {{ forms.dest_phone_number }}
                                </p>
                                </div>
                              </div>

                              <div class="col-sm-6">
                                <div class="mb-3">
                                  <label class="form-label"
                                    >{{$t('destination_plan_txt')}}</label
                                  >
                                  
                                  <date-range-picker style="width:100%" :date-format="dateFormatDest"  :locale-data="locale" v-model="destDates" :opens="opens" :singleDatePicker="true" :timePicker24Hour="true" :timePicker="true"  >
                                
                                  </date-range-picker>

                                   <div
                                    v-for="error in errors.dest_plan"
                                    :key="error"
                                    class="alert alert-primary"
                                    role="alert"
                                  >
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>
                                </div>
                              </div>
                            </div>

                           
                          </div>
                        </div>
                      </div>
                    </div>



                    <br />

                    <div class="row">
                      <div class="col-sm-12">
                        <div class="card">
                          <div class="card-header">
                            <h5>{{$t('ct_operation_performance_txt')}}</h5>
                          </div>

                          <div class="card-body">
                            <div class="row">

                              <div class="col-sm-3">
                                <div class="mb-3">
                                  <label class="form-label"
                                    >{{$t('submit_time_txt')}}</label
                                  >
                                   <p class="text-muted">
                                    <b>{{ ubahTgl(forms.submit_time) }}</b>
                                  </p>
                                </div>
                              </div>

                              <div class="col-sm-3">
                                <div class="mb-3">
                                  <label class="form-label">{{$t('postpone_time_txt')}}</label>
                                  <p class="text-muted">
                                    <b>{{ ubahTgl(forms.postpone_time) }}</b>
                                  </p>
                                </div>
                              </div>

                              <div class="col-sm-3">
                                <div class="mb-3">
                                  <label class="form-label">{{$t('reject_time_txt')}}</label>
                                  <p class="text-muted">
                                    <b>{{ ubahTgl(forms.reject_time) }}</b>
                                  </p>
                                </div>
                              </div>

                              <div class="col-sm-3">
                                <div class="mb-3">
                                  <label class="form-label">{{$t('reject_description_txt')}}</label>
                                  <p class="text-muted">
                                    <b>{{ forms.reject_description }}</b>
                                  </p>
                                </div>
                              </div>



                            </div>
                           
                          </div>
                        </div>
                      </div>
 


                    </div>

                    <br /> 


                     <div class="row" v-if="isMaps">
                      <div class="col-sm-12">
                        <div class="card">
                          <div class="card-header">
                            <h5>{{$t('maps_txt')}}</h5>
                          </div>

                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-12">
 

                              <div class="mb-3">
                                  <gmap-map :zoom="8" :center="center" style="width: 100%; height: 400px" map-type-id="terrain">

                                     <gmap-marker
                                      :key="index"
                                      v-for="(gmp, index) in markers"
                                      :position="gmp.position"
                              
                                      @click="center = gmp.position"
                                    >
                                      <gmap-info-window :opened="true">
                                        <span
                                          class="badge"
                                          :style="{
                                            'background-color': 'white',
                                            color: 'black',
                                          }"
                                          :opened="false"
                                        >
                                          {{ gmp.position.label }}
                                        </span>
                                        
                                      </gmap-info-window>

                                      

                                    </gmap-marker>

                                  <DirectionsRenderer
                                    travelMode="DRIVING"
                                    :origin="startLocation"
                                    :destination="endLocation"
                                    :waypoints="waypnt"
                                    
                                  />
                                  </gmap-map>
                                    


                                  
                                  


                                </div> 
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>

                    <br/>

                    <div class="row card">
                    
                      <div class="card-header">
                        <h5>{{$t('detail_txt')}}</h5>
                      </div>

                      <div class="card-body">
                        <div class="table-responsive">
                          <table class="table table-hover">
                          

                            <thead>
                              <tr>
                                <th>#</th>
                                <th width="300px">{{$t('sku_txt')}}</th>
                                <th width="200px">{{$t('sku_description_txt')}}</th>
                                <th>{{$t('qty_txt')}}</th>
                                <th>{{$t('package_type_txt')}}</th>
                                <th width="300px"> {{$t('lading_type_txt')}} </th>
                                
                                <th> {{$t('lading_length_txt')}} </th>
                                <th> {{$t('lading_width_txt')}} </th>
                                <th> {{$t('lading_height_txt')}} </th>
                              
                               
                                <th> {{$t('volume_txt')}} </th>
                                <th> {{$t('weight_txt')}} </th>
                                <th> {{$t('net_weight_txt')}} </th>
                                <th> {{$t('lading_qty_txt')}} </th>
                                <th></th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr
                                v-for="(input, index) in detailAll"
                                :key="`phoneInput-${index}`"
                              >
                                <th>{{ index + 1 }}</th>
                                <td>{{ input.sku.sku_code }}</td>
                                <td>{{ input.sku.sku_description }}</td>
                                 <td>{{ input.qty }}</td>
                                <td>{{ input.package_type }}</td>
                                <td>{{ input.lading_type.name }}</td>
                                <td>{{ input.lading_length }}</td>
                                <td>{{ input.lading_width }}</td>
                                <td>{{ input.lading_height }}</td>
                             
                               
                                <td>{{ input.volume }}</td>
                                <td>{{ input.weight }}</td>
                                <td>{{ input.net_weight }}</td>
                                <td>{{ input.lading_qty }}</td>
                              </tr>
                            </tbody>

                            <tfoot></tfoot>
                          </table>
                        </div>
                      </div>
                    </div>



                    
                    <br />



                    <div class="card-footer">
                      <button
                        class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        "
                        @click="backForm"
                        type="button"
                      >
                         <i class="mdi mdi-keyboard-backspace"></i>
                        {{ $t("backMess") }}
                      </button>

                      &nbsp;&nbsp;&nbsp;
                      <button
                        class="btn btn-success float-end btn-sm"
                        type="submit"
                      >
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitOrderTxt") }}
                      </button>
 

                    
                    </div>
                    
                </div>
              </div>
            </div>
          
            </form>

        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>

    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-md" >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{$t('tracking_status_txt')}}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <div class="modal-body">
              <div id="content">
  

                  <div class="timeline2 p-4 block mb-4">
                    <div class="tl-item" v-for="(input, index) in fetchTrackingStatus "
                                :key="`status-${index}`">
                        <div class="tl-dot b-danger"></div>
                        <div class="tl-content">
                            <div class="">{{input.order_status}}</div>
                            <div class="tl-date text-muted mt-1">{{ubahTgl(input.tracking_date)}}</div>
                        </div>
                    </div>
                   
                  
                </div>

                </div>
 
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              {{$t('close_txt')}}
            </button>
          </div>
        </div>
      </div>
    </div>

 

<!-- @postpone --->
    <modal-postpone-data  
      :showModal="showModalPostpone"
      :data="this.detailPostpone"
      @closeModal="closeMyModal"
    ></modal-postpone-data>

     
<!-- @rejectData --->
    <modal-reject-data  
      :showModal="showModalRejectData"
      :data="this.detailPostpone"
      @closeModal="closeMyModal"
    ></modal-reject-data>



  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import DirectionsRenderer from "@/middleware/DirectionsRenderer";
import ModalTransportOrderCtDetailPostpone from "@/components/modal/modalTransportOrderCtDetailPostpone.vue";
import ModalTransportOrderCtReject from "@/components/modal/modalTransportOrderCtReject.vue";
 
import DateRangePicker from 'vue2-daterange-picker' 
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "TransportOrderCtEdit",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    DirectionsRenderer,
    "modal-postpone-data": ModalTransportOrderCtDetailPostpone,
    "modal-reject-data": ModalTransportOrderCtReject,
    DateRangePicker
  },
  data() {
    const mapMarkerIcon = require("../../../assets/icontruck.png");
    const startDate = new Date();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 6);

    return {
      detailRejectData:{},
      detailPostpone:{},
      reason_category:"",
      reason_message:"",
      opens:"center",
      locale:{
        format:"dd mmm yyyy H:MM:ss",
       
      },   
      highlighted:{

          dates: [ // Highlight an array of dates
            new Date(),
          ],

      },
      pickupDates: {
        startDate,
        endDate,
      },
      destDates: {
        startDate,
        endDate,
      },
      startLocation:null,
      endLocation:null,
      path: [],
      postpone_time:0,
      destinationPlaces: [],
      originPlaceLocation: [],
      isMaps: false,
      markers:[],
      loadingSendOrder:false,
      pickup_address: true,
      destination_address: true,
      fetchTrackingStatus:[],
      transport_order_header_ids:[],
      waypnt: [],
      fetchladingType: [
        {
          name: "pcs_code",
        },
        {
          name: "pl_code",
        },
        {
          name: "ot_code",
        },
        {
          name: "lp_code",
        },
        {
          name: "cs_code",
        },
      ],
      markerOption: {
        url: mapMarkerIcon,
        size: { width: 30, height: 30, f: "20px", b: "20px" },
        scaledSize: { width: 30, height: 30, f: "20px", b: "20px" },
        rotation: 45,
      },
      showModalPostpone: false,
      showModalRejectData:false,
      detailAll: [],
      fetchSKU: [],
      fetchCompany: [],
      fetchTemplateOrder: [],
      fetchCompanyNotLinc: [],
      fetchLocation: [],
      fetchLocationDest: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      permision_role: [],
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      company: [],
      template_order: [],
      location: [],
      location_dest: [],
      forms: [],
      ORDER_CANCEL_REASONS:[],
      center: {
        lat: -2.548926,
        lng: 118.0148634,
      },
    };
  },
  watch: {},
  methods: {

    dateFormatDest (classes, date) {
      if (!classes.disabled) {
        classes.disabled = date.getTime() < this.pickupDates.startDate;
      }
      return classes
    },
    submitData() {
      const datas = {transport_order_template_id:this.template_order.transport_order_template_id , order_date: this.$moment(this.forms.order_date).format('YYYY-MM-DD HH:mm:ss'), pick_plan : this.$moment(String(this.pickupDates.startDate)).format('YYYY-MM-DD HH:mm:ss') , dest_plan : this.$moment(String(this.destDates.startDate)).format('YYYY-MM-DD HH:mm:ss')};
       
      this.fade(true);
      const baseURI = this.$settings.endPoint + "control-tower/transport-order-header/update-order/"+this.forms.transport_order_header_id;
      this.$http
        .post(baseURI, datas,{ timeout: 30000 })
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
              
            var params = this.$onRandom(this.forms.transport_order_header_id);
            window.location.href = "/ct-orders/detail/" + params + "?status=200&msg="+response.data.datas.messages;
          } else { 
            this.resultError(response.data.errors);
          }
        })
        .catch(error => {
            this.isLoading = false;
            if(error.code == "ECONNABORTED"){
                this.error(this.$t("check_internet_connection_txt"));
            }else{

                if (error.response) {
                if(error.response.status === 422) {
                        this.errors = error.response.data.errors;
                        this.resultError(error.response.data.errors);
                }else if (error.response.status === 500) {
                    this.$router.push('/server-error');
                }else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                }else{
                    this.$router.push('/page-not-found');
                }
                }

            }

        });
 
    },

    closeMyModal() {
      this.showModalPostpone = false;
      this.showModalRejectData = false;
      this.loadData(this.forms.transport_order_header_id);
    },
    getSystemCode() {
      const baseURI = this.$settings.endPoint + "system-code/select?system_code=ORDER_CANCEL_REASON";
       
      return this.$http
        .get(
           baseURI,
          { timeout: 10000 }
        )
        .then((response) => {
          var rows = response.data.datas.data;
          if(rows.length > 0){
            this.ORDER_CANCEL_REASONS = rows;
          }
          
        }).catch(error => {
           
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                      this.errors = error.response.data.errors;
                      this.resultError(error.response.data.errors);
              }else if (error.response.status === 500) {
                  this.$router.push('/server-error');
              }else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
              }else{
                  this.$router.push('/page-not-found');
              }
            }

          }

        });
    },

    dateFormat (classes, date) {
     
      if (!classes.disabled) {
        classes.disabled = date.getTime() < new Date(this.forms.order_date);
      }
      return classes
    },
    dateFormatPostpone (classes, date) {
      if (!classes.disabled) {
        classes.disabled = date.getTime() < this.postpone_time.startDate;
      }
      return classes
    },
    postponeData() {
        this.showModalPostpone = true;
    },
    rejectData() {
        this.showModalRejectData = true;
    }, 
    addField(value, fieldType) {
      fieldType.push({ value: "" });
    },
    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },

    ubahTgl(tgl){
        if(tgl && tgl.length > 0){
            return this.$moment(String(tgl)).format('DD MMM YYYY HH:mm:ss');
        }else{
            return ""
        }
      
    },

    ubahTglOrder(tgl){
      return this.$moment(String(tgl)).format('DD MMM YYYY HH:mm:ss');
    },

    cekData(index, fieldType) {
      fieldType[index].lading_length = fieldType[index].sku.pcs_length;
      fieldType[index].lading_width = fieldType[index].sku.pcs_width;
      fieldType[index].lading_height = fieldType[index].sku.pcs_height;
      fieldType[index].package_type = fieldType[index].sku.package_code;
    },

    cekQty(index, fieldType) {
      let qty = fieldType[index].qty;
      let lading_type = fieldType[index].lading_type.name;
      let package_type = fieldType[index].package_type;

      let data = {
        qty: qty,
        lading_type: lading_type,
        package_type: package_type,
      };
      const baseURI = this.$settings.endPoint + "package/calculate";

      return this.$http.post(baseURI, data).then((response) => {
        let data = response.data.datas[0];
        fieldType[index].lading_qty = data.lading_qty;
        fieldType[index].net_weight = data.net_weight;
        fieldType[index].weight = data.weight;
        fieldType[index].volume = data.volume;
      });
    },

    onClickShowMaps(stype){
      this.isMaps = stype;
        this.markers=[];
       
        const markerPickup = {
          lat: this.location.latitude,
          lng: this.location.longitude,
          label:"PICKUP",
        };

        const markerDestination = {
          lat: this.location_dest.latitude,
          lng: this.location_dest.longitude,
          label:"DESTINATION",
        };
        
        

        this.center = markerPickup; 
        this.startLocation = markerPickup;
        this.endLocation = markerDestination;
        

    },
 

    getLocation(ev) {
      this.fetchLocation = [];
      this.fetchLocationDest = [];
      this.company_name = ev.name;
      const baseURI =
        this.$settings.endPoint + "location?company_name=" + ev.name;

      return this.$http.get(baseURI).then((response) => {
        this.fetchLocation = response.data.datas.data;
        this.fetchLocationDest = response.data.datas.data;
        this.loadSKU(this.company_name);
        this.loadTemplateOrder(this.company_name);
      });
    },

    loadLocation(company_name) {
      this.fetchLocation = [];
      this.fetchLocationDest = [];
      const baseURI =
        this.$settings.endPoint + "location?company_name=" + company_name;

      return this.$http.get(baseURI).then((response) => {
        this.fetchLocation = response.data.datas.data;
        this.fetchLocationDest = response.data.datas.data;
      });
    },

 
    getLocationPickup(ev) {
      this.forms.pick_name = ev.name;
      this.forms.pick_country = ev.country;
      this.forms.pick_province = ev.province;
      this.forms.pick_city = ev.city;
      this.forms.pick_district = ev.district;
      this.forms.pick_sub_district = ev.sub_district;
      this.forms.pick_postal_code = ev.postal_code;
      this.forms.pick_address1 = ev.address1;
      this.forms.pick_address2 = ev.address2;
      this.forms.pick_address3 = ev.address3;
      this.forms.pick_latitude = ev.latitude;
      this.forms.pick_longitude = ev.longitude;
      this.forms.pick_radius = ev.radius;
      this.forms.pick_contact_name = ev.contact_name;
      this.forms.pick_phone_number = ev.phone_number;
    },

    getLocationDest(ev) {
      this.forms.dest_name = ev.name;
      this.forms.dest_country = ev.country;
      this.forms.dest_province = ev.province;
      this.forms.dest_city = ev.city;
      this.forms.dest_district = ev.district;
      this.forms.dest_sub_district = ev.sub_district;
      this.forms.dest_postal_code = ev.postal_code;
      this.forms.dest_address1 = ev.address1;
      this.forms.dest_address2 = ev.address2;
      this.forms.dest_address3 = ev.address3;
      this.forms.dest_latitude = ev.latitude;
      this.forms.dest_longitude = ev.longitude;
      this.forms.dest_radius = ev.radius;
      this.forms.dest_contact_name = ev.contact_name;
      this.forms.dest_phone_number = ev.phone_number;
    },

    asyncSearchLocation(ev) {
      let company_id = this.company.company_id;
      const baseURI =
        this.$settings.endPoint +
        "location?company_id=" +
        company_id +
        "&name=" +
        ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchLocation = response.data.datas.data;
      });
    },

    backForm() {
      window.location.href = "/ct-orders";
    },
   
    sendOrder(item){
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          this.loadingSubmiting();
          let data={
            transport_order_header_ids:this.transport_order_header_ids
          }
          const baseURI =
            this.$settings.endPoint + "transport-order-header/send-order";
          this.$http
            .post(baseURI,data)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                // response.data.datas.messages.forEach((item) => {
                //   this.success(item);
                // });
                //this.loadingSendOrder=false;
                this.success2(response.data.datas.messages);
                this.loadData(item);
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              //this.loadingSendOrder=false;
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                   this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });

     


    },
  
   
    loadData(id) {
        this.isLoading = true;

        this.detailAll=[];
        const baseURI = this.$settings.endPoint + "control-tower/transport-order-header/detail/" + id;
        return this.$http.get(baseURI,{ timeout: 10000 }).then((response) => {

            this.forms                  = response.data.datas;
            this.company                = this.forms.company;
            this.company_name           = this.company.name;
            this.template_order         = this.forms.transport_order_template
            this.location               = this.forms.pick_location;
            this.location_dest          = this.forms.dest_location;
            
            this.fetchTrackingStatus    = response.data.datas.transport_order_trackings;
            this.pickupDates= {
                startDate: new Date(this.forms.pick_plan),
                endDate: new Date(this.forms.pick_plan),
            },
            this.destDates= {
            startDate: new Date(this.forms.dest_plan),
            endDate: new Date(this.forms.dest_plan)
            },

            this.forms.transport_order_detail.forEach((item) => {
            const data={

                    sku:{
                    sequence_no:"",
                    sku_id:item.sku_id,
                    barcode:item.barcode,
                    sku_code:item.sku_code,
                    sku_description:item.sku_description,
                    sku_long_description:item.sku_long_description,
                    // package_type:item.package_type, 
                    // lading_qty:item.lading_qty, 
                    // lading_type:item.lading_type, 
                    // price:item.price, 
                    // amount:item.amount,  
                    // lading_width:item.lading_width, 
                    // lading_height:item.lading_height, 
                    // weight:item.weight, 
                    // net_weight:item.net_weight, 
                    // volume:item.volume, 
                    stackable:item.stackable,
                    commodity_id:item.commodity_id,
                    },

                    lading_length: item.lading_length, 
                    lading_width: item.lading_width, 
                    lading_height: item.lading_height, 
                    lading_type: {name:item.lading_type}, 
                    package_type: item.package_type, 
                    volume: item.volume, 
                    weight: item.weight,
                    net_weight: item.net_weight, 
                    lading_qty: item.lading_qty,

                    qty:item.qty, 
                
                    

            }
            
            this.detailAll.push(data);
            })

            // this.getTotalPostpone(response.data.datas.submit_time,response.data.datas.postpone_time);
            this.detailPostpone ={transport_order_header_id:response.data.datas.transport_order_header_id,submit_time:response.data.datas.submit_time, postpone_time: response.data.datas.postpone_time};
            this.isLoading              = false;
            
        }).catch(error => {
          this.isLoading = false;
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                      this.errors = error.response.data.errors;
                      this.resultError(error.response.data.errors);
              }else if (error.response.status === 500) {
                  this.$router.push('/server-error');
              }else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
              }else{
                  this.$router.push('/page-not-found');
              }
            }

          }

        });
    },


    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (
          permision.permission.controller == "TransportOrderHeaderCtController"
        ) {
          rolePermision = permision;
        }
      });

      if (!rolePermision.method_update) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
      
      if(this.$route.query.msg){
        if(this.$route.query.status == 200){
          this.success(this.$route.query.msg)
        }else{
          this.error(this.$route.query.msg)
        }

      }
    },


    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },


    success2(msg){
      let pesan="";
      msg.forEach((item) => {
          pesan=pesan+"<p>* "+item+"</p>";
      });

      this.$swal({
        icon: 'info',
        backdrop:true,
        title: "Information",
        html:pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      
    },


    loadingSubmiting(){


      this.$swal({
        icon: 'warning',
        backdrop:true,
        title: "Please wait",
        html:'<h2>SUBMITTING ORDER...</h2>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    asyncSearchTemplateOrder(ev) {
      const baseURI = this.$settings.endPoint + "transport-order-template?description=" + ev + "&company_name="+this.company_name;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTemplateOrder = response.data.datas.data;
      });
    },
    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.loadData(params[0]);
    this.fetchIt();
  },
};
</script>

<style scoped>
.blur-content{
  filter: blur(5px); 
  pointer-events: none;
}
.w-32 {
    width: 32px !important;
    height: 32px !important;
    font-size: .85em
}

.tl-item .avatar {
    z-index: 2
}

.circle {
    border-radius: 500px
}

.gd-warning {
    color: #fff;
    border: none;
    background: #f4c414 linear-gradient(45deg, #f4c414, #f45414)
}

.timeline2 {
    position: relative;
    border-color: rgba(160, 175, 185, .15);
    padding: 0;
    margin: 0
}

.p-4 {
    padding: 1.5rem !important
}

.block,
.card {
    background: #fff;
    border-width: 0;
    border-radius: .25rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .05);
    margin-bottom: 1.5rem
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
    margin-left:140px
}

.tl-item {
    border-radius: 3px;
    position: relative;
    display: -ms-flexbox;
    display: flex
}

.tl-item>* {
    padding: 10px
}

.tl-item .avatar {
    z-index: 2
}

.tl-item:last-child .tl-dot:after {
    display: none
}

.tl-item.active .tl-dot:before {
    border-color: #448bff;
    box-shadow: 0 0 0 4px rgba(68, 139, 255, .2)
}

.tl-item:last-child .tl-dot:after {
    display: none
}

.tl-item.active .tl-dot:before {
    border-color: #448bff;
    box-shadow: 0 0 0 4px rgba(68, 139, 255, .2)
}

.tl-dot {
    position: relative;
    border-color: rgba(160, 175, 185, .15)
}

.tl-dot:after,
.tl-dot:before {
    content: '';
    position: absolute;
    border-color: inherit;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    top: 15px;
    left: 50%;
    transform: translateX(-50%)
}

.tl-dot:after {
    width: 0;
    height: auto;
    top: 25px;
    bottom: -15px;
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    border-radius: 0
}

tl-item.active .tl-dot:before {
    border-color: #448bff;
    box-shadow: 0 0 0 4px rgba(68, 139, 255, .2)
}

.tl-dot {
    position: relative;
    border-color: rgba(160, 175, 185, .15)
}

.tl-dot:after,
.tl-dot:before {
    content: '';
    position: absolute;
    border-color: inherit;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    top: 15px;
    left: 50%;
    transform: translateX(-50%)
}

.tl-dot:after {
    width: 0;
    height: auto;
    top: 25px;
    bottom: -15px;
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    border-radius: 0
}

.tl-content p:last-child {
    margin-bottom: 0
}

.tl-date {
    font-size: .85em;
    margin-top: 2px;
    min-width: 125px;
    max-width: 125px
}

.avatar {
    position: relative;
    line-height: 1;
    border-radius: 500px;
    white-space: nowrap;
    font-weight: 700;
    border-radius: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-radius: 500px;
    box-shadow: 0 5px 10px 0 rgba(50, 50, 50, .15)
}

.b-warning {
    border-color: #f4c414!important;
}

.b-primary {
    border-color: #448bff!important;
}

.b-danger {
    border-color: #ab0635!important;
}
</style>