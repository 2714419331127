import Vue from "vue";
import VueRouter from "vue-router";
import { isLoggedIn } from "@/middleware/auth";

import UserLogin from "@/components/UserLogin.vue";
import ForgotPassword from "@/components/ForgotPassword.vue";
import ResetPassword from "@/components/ResetUserPassword.vue";
import PageNotFound from "@/components/UserPageNotFound.vue";
import ServerErrorPage from "@/components/UserPageServerError.vue";
import AuthorizedErrorPage from "@/components/UserPageAuthorized.vue";
import UserDashboard from "@/components/UserDashboard.vue";
import VendorAnalystDashboard from "@/components/VendorAnalstDashboard.vue";
import DmDashboard from "@/components/DmDashboard.vue";
import DashboardKpi from "@/components/DashboardKpi.vue";
import UserProfile from "@/components/users/Profile.vue";
import ChangePassword from "@/components/users/ChangePassword.vue";
import ContactUs from "@/components/support/ContactUs.vue";

import IndexTesting from "@/components/testing/Index.vue";
import MapsTesting from "@/components/testing/maps.vue";
import CalenderTesting from "@/components/testing/CalenderTesting.vue";

import Users from "@/components/administration/users/Users.vue";
import UsersAdd from "@/components/administration/users/UsersAdd.vue";
import UsersEdit from "@/components/administration/users/UsersEdit.vue";
import UsersDetail from "@/components/administration/users/UsersDetail.vue";
import UsersUpload from "@/components/administration/users/UsersUpload.vue";

import Company from "@/components/administration/company/Company.vue";
import CompanyAdd from "@/components/administration/company/CompanyAdd.vue";
import CompanyDetail from "@/components/administration/company/CompanyDetail.vue";
import CompanyEdit from "@/components/administration/company/CompanyEdit.vue";
import CompanyUpload from "@/components/administration/company/CompanyUpload.vue";

import Role from "@/components/administration/role/Role.vue";
import RoleAdd from "@/components/administration/role/RoleAdd.vue";
import RoleDetail from "@/components/administration/role/RoleDetail.vue";
import RoleEdit from "@/components/administration/role/RoleEdit.vue";
import RoleUpload from "@/components/administration/role/RoleUpload.vue";





import Permission from "@/components/administration/permission/Permission.vue";
import PermissionAdd from "@/components/administration/permission/PermissionAdd.vue";
import PermissionDetail from "@/components/administration/permission/PermissionDetail.vue";
import PermissionEdit from "@/components/administration/permission/PermissionEdit.vue";
import PermissionUpload from "@/components/administration/permission_role/PermissionUpload.vue";

import PermissionRole from "@/components/administration/permission_role/PermissionRole.vue";
import PermissionRoleAdd from "@/components/administration/permission_role/PermissionRoleAdd.vue";
import PermissionRoleEdit from "@/components/administration/permission_role/PermissionRoleEdit.vue";
import PermissionRoleDetail from "@/components/administration/permission_role/PermissionRoleDetail.vue";

// import Location from "@/components/administration/Location.vue";

import AlertSetting from "@/components/dasboard-ct/alertSetting/AlertSetting.vue";
import AlertSettingAdd from "@/components/dasboard-ct/alertSetting/AlertSettingAdd.vue";
import AlertSettingDetail from "@/components/dasboard-ct/alertSetting/AlertSettingDetail.vue";
import AlertSettingEdit from "@/components/dasboard-ct/alertSetting/AlertSettingEdit.vue";
import AlertSettingUpload from "@/components/dasboard-ct/alertSetting/AlertSettingUpload.vue";

import TemplateOrder from "@/components/orders/template-order/TemplateOrder.vue";
import TemplateOrderAdd from "@/components/orders/template-order/TemplateOrderAdd.vue";
import TemplateOrderDetail from "@/components/orders/template-order/TemplateOrderDetail.vue";
import TemplateOrderEdit from "@/components/orders/template-order/TemplateOrderEdit.vue";
import TemplateOrderUpload from "@/components/orders/template-order/TemplateOrderUpload.vue";

import TemplateOrderMulti from "@/components/orders/template-order-multi/TemplateOrderMulti.vue";
import TemplateOrderMultiAdd from "@/components/orders/template-order-multi/TemplateOrderMultiAdd.vue";
import TemplateOrderMultiDetail from "@/components/orders/template-order-multi/TemplateOrderMultiDetail.vue";
import TemplateOrderMultiEdit from "@/components/orders/template-order-multi/TemplateOrderMultiEdit.vue";
import TemplateOrderMultiUpload from "@/components/orders/template-order-multi/TemplateOrderMultiUpload.vue";

import TransportOrder from "@/components/orders/transport-order/TransportOrder.vue";
import TransportOrderAdd from "@/components/orders/transport-order/TransportOrderAdd.vue";
import TransportOrderDetail from "@/components/orders/transport-order/TransportOrderDetail.vue";
import TransportOrderEdit from "@/components/orders/transport-order/TransportOrderEdit.vue";
import TransportOrderUpload from "@/components/orders/transport-order/TransportOrderUpload.vue";

import NewTransportOrder from "@/components/orders/new-transport-order/TransportOrder.vue";
import NewTransportOrderAdd from "@/components/orders/new-transport-order/TransportOrderAdd.vue";
import NewTransportOrderDetail from "@/components/orders/new-transport-order/TransportOrderDetail.vue";
import NewTransportOrderEdit from "@/components/orders/new-transport-order/TransportOrderEdit.vue";
import NewTransportOrderUpload from "@/components/orders/new-transport-order/TransportOrderUpload.vue";

import NewTransportOrderMulti from "@/components/orders/new-transport-order-multi/TransportOrderMulti.vue";
import NewTransportOrderMultiAdd from "@/components/orders/new-transport-order-multi/TransportOrderMultiAdd.vue";
import NewTransportOrderMultiDetail from "@/components/orders/new-transport-order-multi/TransportOrderMultiDetail.vue";
import NewTransportOrderMultiEdit from "@/components/orders/new-transport-order-multi/TransportOrderMultiEdit.vue";
import NewTransportOrderMultiUpload from "@/components/orders/new-transport-order-multi/TransportOrderMultiUpload.vue";

import TransportOrderMulti from "@/components/orders/transport-order-multi/TransportOrderMulti.vue";
import TransportOrderMultiAdd from "@/components/orders/transport-order-multi/TransportOrderMultiAdd.vue";
import TransportOrderMultiDetail from "@/components/orders/transport-order-multi/TransportOrderMultiDetail.vue";
import TransportOrderMultiEdit from "@/components/orders/transport-order-multi/TransportOrderMultiEdit.vue";
import TransportOrderMultiUpload from "@/components/orders/transport-order-multi/TransportOrderMultiUpload.vue";

import routesDriver from "@/components/master/driver/routes";
import routesCommodity from "@/components/master/commodity/routes";
import routesLocation from "@/components/master/location/routes";
import routesPackage from "@/components/master/package/routes";
import routesGerbangTol from "@/components/master/gerbangTol/routes";
import routesRasio from "@/components/master/rasio/routes";
import routesBbm from "@/components/master/bbm/routes";
import routesMel from "@/components/master/mel/routes";
import routesPostalCode from "@/components/master/postal_code/routes";
import routesRegion from "@/components/master/region/routes";
import routesSKU from "@/components/master/sku/routes";
import routesSystemCode from "@/components/master/system_code/routes";
import routesTruck from "@/components/master/truck/routes";
import routesBranch from "@/components/master/branch/routes";
import routesTypeDocument from "@/components/master/type_document/routes";
import routesDocument from "@/components/master/document/routes";
import routesCustomerCT from "@/components/master/customerCT/routes";
import routesCarrier from "@/components/master/carrier/routes";
import routesStatus from "@/components/master/status/routes";
import routesStopLocation from "@/components/master/stop_location/routes";
import routesLeadTime from "@/components/master/lead_time/routes";
import routesInformasiBongkar from "@/components/dasboard-ct/informasiBongkar/routes";

import routesFakturPajak from "@/components/dasboard-fp/faktur_pajak/routes";
import routesTransportOrderCT from "@/components/orders/control-tower/routes";

import Driver from "@/components/master/driver/Index";
import Truck from "@/components/master/truck/Index";
import Commodity from "@/components/master/commodity/Index";
import Location from "@/components/master/location/Index";
import Package from "@/components/master/package/Index";
import GerbangTol from "@/components/master/gerbangTol/Index";
import Rasio from "@/components/master/rasio/Index";
import Bbm from "@/components/master/bbm/Index";
import Mel from "@/components/master/mel/Index";
import PostalCode from "@/components/master/postal_code/Index";
import LookupCode from "@/components/master/lookup_code/Index";
import Region from "@/components/master/region/Index";
import SKU from "@/components/master/sku/Index";
import SystemCode from "@/components/master/system_code/Index";
import Branch from "@/components/master/branch/Index";
import TypeDocument from "@/components/master/type_document/Index";
import Document from "@/components/master/document/Index";
import CustomerCT from "@/components/master/customerCT/Index";
import Carrier from "@/components/master/carrier/Index";
import Status from "@/components/master/status/Index";
import StopLocation from "@/components/master/stop_location/Index";
import LeadTime from "@/components/master/lead_time/Index";

import DashboardRM from "@/components/dasboard-rm/dashboard.vue";

import DashboardCT from "@/components/dasboard-ct/dashboard.vue";
import OneStopReport from "@/components/dasboard-ct/oneStopReport.vue";
import oneStopReportShipwithreff from "@/components/dasboard-ct/oneStopReportShipwithreff.vue";
import DashboardFullfilment from "@/components/dasboard-ct/dashboardFullfilment.vue";
import TripAlert from "@/components/dasboard-ct/tripAlert.vue";
import DocAlert from "@/components/dasboard-ct/docAlert.vue";
import ServiceAlert from "@/components/dasboard-ct/serviceAlert.vue";
import GateAlert from "@/components/dasboard-ct/gateAlert.vue";
import OntimeAnalysis from "@/components/dasboard-ct/onTimeAnalysis.vue";
import DetailMonitoring from "@/components/dasboard-ct/detailmonitoring.vue";
import AbsensiDriver from "@/components/dasboard-ct/absensidriver.vue";
import AbsensiRecap from "@/components/dasboard-ct/absensirecap.vue";
import DashboardUtility from "@/components/dasboard-ct/dashboardUtilitationReport.vue";
import DashboardProductivity from "@/components/dasboard-ct/dashboardProductivity.vue";

import TruckUtility from "@/components/dasboard-ct/truckUtilitationReport.vue";
import TruckUtilityDetail from "@/components/dasboard-ct/truckUtilitationDetail.vue";
import TruckUtilityDate from "@/components/dasboard-ct/truckUtilitationReportDate.vue";
import Alert from "@/components/dasboard-ct/alertTurnOff.vue";
import InformasiBongkar from "@/components/dasboard-ct/informasiBongkar/Index";
import routesTraffictViolation from "@/components/dasboard-ct/traffict-violation/routes";
import TraffictViolation from "@/components/dasboard-ct/traffict-violation/Index";
import Accident from "@/components/dasboard-ct/accident/Index";
import routesAccident from "@/components/dasboard-ct/accident/routes";
import CustomerTripOrders from "@/components/dasboard-ct/customerTripOrders/Index";
import routesCustomerTripOrders from "@/components/dasboard-ct/customerTripOrders/routes";

import DashboardFP from "@/components/dasboard-fp/dashboard.vue";
import FakturPajak from "@/components/dasboard-fp/faktur_pajak/Index";
import TransportOrderCT from "@/components/orders/control-tower/Index";


import DashboardGT from "@/components/dasboard-gt/dashboard.vue";
import UserMappingGT from "@/components/dasboard-gt/user-mapping/Index";
import routesUserMappingGT from "@/components/dasboard-gt/user-mapping/routes";
import PoolDetailGT from "@/components/dasboard-gt/poolDetail.vue";
import BranchDetailGT from "@/components/dasboard-gt/branchDetail.vue";
import poolTransactions from "@/components/dasboard-gt/poolTransactions.vue";
import branchTransactions from "@/components/dasboard-gt/branchTransactions.vue";
import TransactionDetail from "@/components/dasboard-gt/TransactionDetail.vue";
import allBranchTransactions from "@/components/dasboard-gt/allBranchTransactions.vue";
import NewsPortalGT from "@/components/dasboard-gt/news-portal/Index";
import routesNewsPortalGT from "@/components/dasboard-gt/news-portal/routes";

import testSkuRoutes from "@/components/testing/sku/routes";
import testSku from "@/components/testing/sku/Index.vue";
import routesProformaInvoice from "@/components/dasboard-ct/proformaInvoice/routes";
import ProformaInvoice from "@/components/dasboard-ct/proformaInvoice/Index.vue";

// import routesCustomerCharge from "@/components/master/customerCharge/routes";
// import CustomerCharge from "@/components/master/customerCharge/Index.vue"

import EditDoNumber from "@/components/dasboard-ct/DoNumber/DoNumber.vue";
import EditDoNumberAdd from "@/components/dasboard-ct/DoNumber/DoNumberAdd.vue";
import EditDoNumberDetail from "@/components/dasboard-ct/DoNumber/DoNumberDetail.vue";
import EditDoNumberEdit from "@/components/dasboard-ct/DoNumber/DoNumberEdit.vue";
import EditDoNumberUpload from "@/components/dasboard-ct/DoNumber/DoNumberUpload.vue";

import DashboardFullfilmentKanban from "@/components/dasboard-ct/dashboardFullfilmentKanban.vue";
import DashboardFppk from "@/components/dashboard-fppk/dashboardFppk.vue";


import RoutesTolRoute from "@/components/master/tol_route/routes";
import RoutesLookupCode from "@/components/master/lookup_code/routes";
import TolRoute from "@/components/master/tol_route/Index.vue";

import RoutesRute from "@/components/master/rute/routes";
import RuteRoute from "@/components/master/rute/Index.vue";




import RoutesMarginList from "@/components/master/margin_list/routes";
import MarginListRoute from "@/components/master/margin_list/Index.vue";

import RoutesBillableRM from "@/components/dasboard-rm/billableRateManagement/routes";
import BillableRM from "@/components/dasboard-rm/billableRateManagement/Index.vue";

import BillableMethod from "@/components/dasboard-rm/BillableMethod/BillableMethod.vue";
import BillableMethodAdd from "@/components/dasboard-rm/BillableMethod/BillableMethodAdd.vue";
import BillableMethodEdit from "@/components/dasboard-rm/BillableMethod/BillableMethodEdit.vue";

import BillableNewRate from "@/components/dasboard-rm/newRate.vue";
import BillableTariff from "@/components/dasboard-rm/BillableTariff/BillableTariff.vue";
import BillableTariffUpload from "@/components/dasboard-rm/BillableTariff/BillableTariffUpload.vue";


import PayableTariff from "@/components/dasboard-rm/payable_tariff/PayableTariff.vue";
import PayableTariffUpload from "@/components/dasboard-rm/payable_tariff/PayableTariffUpload.vue";

import BillableAcc from "@/components/dasboard-rm/billable_acc/BillableAcc.vue";

import StopLocationRm from "@/components/dasboard-rm/stopLocation.vue";

import BillableParties from "@/components/dasboard-rm/billable_parties/BillableParties.vue";
import BillablePartiesAdd from "@/components/dasboard-rm/billable_parties/BillablePartiesAdd.vue";
import BillablePartiesEdit from "@/components/dasboard-rm/billable_parties/BillablePartiesEdit.vue";
import BillablePartiesDetail from "@/components/dasboard-rm/billable_parties/BillablePartiesDetail.vue";



import BillableManageService from "@/components/dasboard-rm/billable_manage_service/BillableManageService.vue";
import BillableManageServiceAdd from "@/components/dasboard-rm/billable_manage_service/BillableManageServiceAdd.vue";
import BillableManageServiceEdit from "@/components/dasboard-rm/billable_manage_service/BillableManageServiceEdit.vue";
import BillableManageServiceDetail from "@/components/dasboard-rm/billable_manage_service/BillableManageServiceDetail.vue";


import RoutesMasterUjo from "@/components/master/ujo/routes";
import MasterUjo from "@/components/master/ujo/Index.vue";

import RoutesMasterPenyebrangan from "@/components/master/penyebrangan/routes";
import MasterPenyebrangan from "@/components/master/penyebrangan/Index.vue";

import SequenceDriverSummary from "@/components/dasboard-ct/sequenceDriverSummary.vue";

import RoutesMasterRutePenyebrangan from "@/components/master/rutePenyebrangan/routes";
import MasterRutePenyebrangan from "@/components/master/rutePenyebrangan/Index.vue";

import salesProject from "@/components/dasboard-rm/salesProject/salesProject.vue";
import salesProjectDetail from "@/components/dasboard-rm/salesProject/salesProjectDetail.vue";
import wonRate from "@/components/dasboard-rm/salesProject/wonRate.vue";

import chargeCode from "@/components/dasboard-rm/chargeCode.vue";

import RoutesKriminalitas from "@/components/dasboard-ct/kriminalitas/routes";
import Kriminalitas from "@/components/dasboard-ct/kriminalitas/Index.vue";

import BillableAccRule from "@/components/dasboard-rm/BillableAccRule/BillableAccRule.vue";
import BillableAccRuleAdd from "@/components/dasboard-rm/BillableAccRule/BillableAccRuleAdd.vue";
import BillableAccRuleEdit from "@/components/dasboard-rm/BillableAccRule/BillableAccRuleEdit.vue";
import BillableAccRuleDetail from "@/components/dasboard-rm/BillableAccRule/BillableAccRuleDetail.vue";

import routesRequestRg from "@/components/dasboard-rm/request_rg/routes";
import RequestRg from "@/components/dasboard-rm/request_rg/Index.vue";

import routesPayableRate from "@/components/dasboard-rm/payable_rate/routes";
import PayableRate from "@/components/dasboard-rm/payable_rate/Index.vue";

import routesFppkPenerimaan from "@/components/fppk/penerimaan/routes";
import FppkPenerimaan from "@/components/fppk/penerimaan/Index.vue";

import routesFppkPengajuan from "@/components/fppk/pengajuan/routes";
import FppkPengajuan from "@/components/fppk/pengajuan/Index.vue";

import routesFppkNotification from "@/components/fppk/notifikasi/routes";
import FppkNotification from "@/components/fppk/notifikasi/Index.vue";


Vue.use(VueRouter);

const routes = [
  /**
   * Testing
   */
  {
    path: "/coba",
    meta: {
      title: "Chain System",
      allowAnonymous: true,
    },
    name: "IndexTesting",
    component: IndexTesting,
  },

 

  {
    path: "/calender-testing",
    meta: {
      title: "Chain System",
      allowAnonymous: true,
    },
    name: "CalenderTesting",
    component: CalenderTesting,
  },

  {
    path: "/mapstesting",
    meta: {
      title: "Chain System",
      allowAnonymous: true,
    },
    name: "MapsTesting",
    component: MapsTesting,
  },

  {
    path: "/",
    meta: {
      title: "Chain System",
      allowAnonymous: true,
    },
    name: "UserLogin",
    component: UserLogin,
  },
  {
    path: "/forgot-password",
    meta: {
      title: "Chain System",
      allowAnonymous: true,
    },
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/reset-password/:email/:token",
    meta: {
      title: "Chain System",
      allowAnonymous: true,
    },
    name: "ResetPassword",
    component: ResetPassword,
    props: true,
  },
  {
    path: "/server-error",
    meta: {
      title: "Chain System",
      allowAnonymous: true,
    },
    name: "ServerErrorPage",
    component: ServerErrorPage,
  },
  {
    path: "/authorized-error",
    meta: {
      title: "Chain System",
      allowAnonymous: true,
    },
    name: "AuthorizedErrorPage",
    component: AuthorizedErrorPage,
  },
  {
    path: "/page-not-found",
    meta: {
      title: "Chain System",
      allowAnonymous: true,
    },
    name: "PageNotFound",
    component: PageNotFound,
  },
  {
    path: "*",
    meta: {
      title: "Chain System",
    },
    name: "PageNotFoundArea",
    component: PageNotFound,
  },
  {
    path: "/dashboard",
    meta: {
      title: "Chain System",
    },
    name: "UserDashboard",
    component: UserDashboard,
  },

  {
    path: "/monitoring/detail/:id/:vehicle_no/:gps_vendor",
    meta: {
      title: "Chain System",
    },
    name: "DetailMonitoring",
    component: DetailMonitoring,
  },

  {
    path: "/absensidriver/:date",
    meta: {
      title: "Chain System",
    },
    name: "AbsensiDriver",
    component: AbsensiDriver,
  },

  {
    path: "/absensirecap",
    meta: {
      title: "Chain System",
    },
    name: "AbsensiRecap",
    component: AbsensiRecap,
  },

  {
    path: "/dashboardUtilitationReport",
    meta: {
      title: "Chain System",
    },
    name: "DashboardUtility",
    component: DashboardUtility,
  },

  {
    path: "/vendor-analyst-dashboard",
    meta: {
      title: "Chain System",
    },
    name: "VendorAnalystDashboard",
    component: VendorAnalystDashboard,
  },


  {
    path: "/dm-dashboard",
    meta: {
      title: "CT System",
    },
    name: "DmDashboard",
    component: DmDashboard,
  },



  {
    path: "/dashboard-kpi",
    meta: {
      title: "CT System",
    },
    name: "DashboardKpi",
    component: DashboardKpi,
  },

  {
    path: "/dashboardProductivity",
    meta: {
      title: "Chain System",
    },
    name: "DashboardProductivity",
    component: DashboardProductivity,
  },

  {
    path: "/truckUtilitationReport",
    meta: {
      title: "Chain System",
    },
    name: "TruckUtility",
    component: TruckUtility,
  },

  {
    path: "/truckUtilitationDetail/:vehicle/:start/:end",
    meta: {
      title: "Chain System",
    },
    name: "TruckUtilityDetail",
    component: TruckUtilityDetail,
  },

  {
    path: "/truckUtilitationReportDate/:date/:type",
    meta: {
      title: "Chain System",
    },
    name: "TruckUtilityDate",
    component: TruckUtilityDate,
  },

  {
    path: "/dashboard-rm",
    meta: {
      title: "Chain System",
    },
    name: "DashboardRM",
    component: DashboardRM,
  },

  {
    path: "/dashboard-gt",
    meta: {
      title: "Chain System",
    },
    name: "DashboardGT",
    component: DashboardGT,
  },

  {
    path: "/dashboard-ct",
    meta: {
      title: "Chain System",
    },
    name: "DashboardCT",
    component: DashboardCT,
  },

  {
    path: "/one-stop-report",
    meta: {
      title: "Chain System",
    },
    name: "OneStopReport",
    component: OneStopReport,
  },
  {
    path: "/one-stop-report-shipwithreff",
    meta: {
      title: "Chain System",
    },
    name: "OneStopReport2",
    component: oneStopReportShipwithreff,
  },

  {
    path: "/dashboard-fullfilment",
    meta: {
      title: "Chain System",
    },
    name: "DashboardFullfilment",
    component: DashboardFullfilment,
  },

  {
    path: "/alertTurnOff",
    meta: {
      title: "Chain System",
    },
    name: "AlertTurnOff",
    component: Alert,
  },

  {
    path: "/trip-alert",
    meta: {
      title: "Chain System",
    },
    name: "TripAlert",
    component: TripAlert,
  },

  {
    path: "/ontime-analysis",
    meta: {
      title: "Chain System",
    },
    name: "OntimeAnalysis",
    component: OntimeAnalysis,
  },

  {
    path: "/service-alert",
    meta: {
      title: "Chain System",
    },
    name: "ServiceAlert",
    component: ServiceAlert,
  },

  {
    path: "/gate-alert",
    meta: {
      title: "Chain System",
    },
    name: "GateAlert",
    component: GateAlert,
  },

  {
    path: "/doc-alert",
    meta: {
      title: "Chain System",
    },
    name: "DocAlert",
    component: DocAlert,
  },

  /**
   * Tax Invoice
   */
  {
    path: "/dashboard-fp",
    meta: {
      title: "Tax Invoice",
    },
    name: "DashboardFP",
    component: DashboardFP,
  },

  /**
   * User
   */
  {
    path: "/profile",
    meta: {
      title: "Chain System",
    },
    name: "UserProfile",
    component: UserProfile,
  },
  {
    path: "/change-password",
    meta: {
      title: "Chain System",
    },
    name: "ChangePassword",
    component: ChangePassword,
  },

  /**
   * Support
   */
  {
    path: "/contact-us",
    meta: {
      title: "Chain System",
    },
    name: "ContactUs",
    component: ContactUs,
  },

  /**
   * Administration/Users
   */
  {
    path: "/users",
    meta: {
      title: "Chain System",
    },
    name: "Users",
    component: Users,
  },
  {
    path: "/users/add",
    meta: {
      title: "Chain System",
    },
    name: "UsersAdd",
    component: UsersAdd,
  },

  {
    path: "/users/edit/:id",
    meta: {
      title: "Chain System",
    },
    name: "UsersEdit",
    component: UsersEdit,
  },

  {
    path: "/users/detail/:id",
    meta: {
      title: "Chain System",
    },
    name: "UsersDetail",
    component: UsersDetail,
  },

  {
    path: "/users/upload",
    meta: {
      title: "Chain System",
    },
    name: "UsersUpload",
    component: UsersUpload,
  },

  /**
   * Administration/Company
   */

  {
    path: "/status",
    meta: {
      title: "Chain System",
    },
    children: routesStatus,
    component: (resolve) => resolve(Status),
  },

  {
    path: "/stop-location",
    meta: {
      title: "Chain System",
    },
    children: routesStopLocation,
    component: (resolve) => resolve(StopLocation),
  },

  {
    path: "/lead-time",
    meta: {
      title: "Chain System",
    },
    children: routesLeadTime,
    component: (resolve) => resolve(LeadTime),
  },

  {
    path: "/type-document",
    meta: {
      title: "Chain System",
    },
    children: routesTypeDocument,
    component: (resolve) => resolve(TypeDocument),
  },

  {
    path: "/dok",
    meta: {
      title: "Chain System",
    },
    children: routesDocument,
    component: (resolve) => resolve(Document),
  },

  {
    path: "/master-customer-ct",
    meta: {
      title: "Chain System",
    },
    children: routesCustomerCT,
    component: (resolve) => resolve(CustomerCT),
  },

  {
    path: "/master-carrier",
    meta: {
      title: "Chain System",
    },
    children: routesCarrier,
    component: (resolve) => resolve(Carrier),
  },

  {
    path: "/driver",
    meta: {
      title: "Chain System",
    },
    children: routesDriver,
    component: (resolve) => resolve(Driver),
  },
  {
    path: "/truck",
    meta: {
      title: "Chain System",
    },
    children: routesTruck,
    component: (resolve) => resolve(Truck),
  },
  {
    path: "/company",
    meta: {
      title: "Chain System",
    },
    name: "Company",
    component: Company,
  },
  {
    path: "/company/add",
    meta: {
      title: "Chain System",
    },
    name: "CompanyAdd",
    component: CompanyAdd,
  },

  {
    path: "/company/edit/:id",
    meta: {
      title: "Chain System",
    },
    name: "CompanyEdit",
    component: CompanyEdit,
  },

  {
    path: "/company/detail/:id",
    meta: {
      title: "Chain System",
    },
    name: "CompanyDetail",
    component: CompanyDetail,
  },

  {
    path: "/company/upload",
    meta: {
      title: "Chain System",
    },
    name: "CompanyUpload",
    component: CompanyUpload,
  },

  /**
   * Administration/Role
   */
  {
    path: "/role",
    meta: {
      title: "Chain System",
    },
    name: "Role",
    component: Role,
  },

  {
    path: "/role/add",
    meta: {
      title: "Chain System",
    },
    name: "RoleAdd",
    component: RoleAdd,
  },
  {
    path: "/role/add/:id",
    meta: {
      title: "Chain System",
    },
    name: "RoleCopy",
    component: RoleAdd,
  },

  {
    path: "/role/edit/:id",
    meta: {
      title: "Chain System",
    },
    name: "RoleEdit",
    component: RoleEdit,
  },

  {
    path: "/role/detail/:id",
    meta: {
      title: "Chain System",
    },
    name: "RoleDetail",
    component: RoleDetail,
  },

  {
    path: "/role/upload",
    meta: {
      title: "Chain System",
    },
    name: "RoleUpload",
    component: RoleUpload,
  },

  /**
   * Administration/Permission
   */
  {
    path: "/permission",
    meta: {
      title: "Chain System",
    },
    name: "Permission",
    component: Permission,
  },

  {
    path: "/permission/add",
    meta: {
      title: "Chain System",
    },
    name: "PermissionAdd",
    component: PermissionAdd,
  },

  {
    path: "/permission/edit/:id",
    meta: {
      title: "Chain System",
    },
    name: "PermissionEdit",
    component: PermissionEdit,
  },

  {
    path: "/permission/detail/:id",
    meta: {
      title: "Chain System",
    },
    name: "PermissionDetail",
    component: PermissionDetail,
  },

  {
    path: "/permission/upload",
    meta: {
      title: "Chain System",
    },
    name: "PermissionUpload",
    component: PermissionUpload,
  },

  /**
   * Administration/Permission
   */
  {
    path: "/rolpermis",
    meta: {
      title: "Chain System",
    },
    name: "Permission Role",
    component: PermissionRole,
  },

  {
    path: "/rolpermis/add",
    meta: {
      title: "Chain System",
    },
    name: "PermissionRoleAdd",
    component: PermissionRoleAdd,
  },

  {
    path: "/rolpermis/edit/:id",
    meta: {
      title: "Chain System",
    },
    name: "PermissionRoleEdit",
    component: PermissionRoleEdit,
  },

  {
    path: "/rolpermis/detail/:id",
    meta: {
      title: "Chain System",
    },
    name: "PermissionRoleDetail",
    component: PermissionRoleDetail,
  },

  /**
   * Master/Region
   */
  {
    path: "/region",
    meta: {
      title: "Chain System",
    },
    children: routesRegion,
    component: Region,
  },

  /**
   * Master/Gerbang Tol
   */
  {
    path: "/gerbang-tol",
    meta: {
      title: "Chain System",
    },
    children: routesGerbangTol,
    component: GerbangTol,
  },

  {
    path: "/master-rasio",
    meta: {
      title: "Chain System",
    },
    children: routesRasio,
    component: Rasio,
  },

  {
    path: "/master-bbm",
    meta: {
      title: "Chain System",
    },
    children: routesBbm,
    component: Bbm,
  },

  {
    path: "/master-mel",
    meta: {
      title: "Chain System",
    },
    children: routesMel,
    component: Mel,
  },

  /**
   * Master/Package
   */
  {
    path: "/package",
    meta: {
      title: "Chain System",
    },
    children: routesPackage,
    component: Package,
  },

  /**
   * Master/PostalCode
   */
  {
    path: "/postal-code",
    meta: {
      title: "Chain System",
    },
    children: routesPostalCode,
    component: PostalCode,
  },

  {
    path: "/lookup-code",
    meta: {
      title: "Chain System",
    },
    children: RoutesLookupCode,
    component: LookupCode,
  },
  /**
   * Master/SystemCode
   */
  {
    path: "/system-code",
    meta: {
      title: "Chain System",
    },
    children: routesSystemCode,
    component: SystemCode,
  },

  /**
   * Master/Commodity
   */
  {
    path: "/commodity",
    meta: {
      title: "Chain System",
    },
    children: routesCommodity,
    component: Commodity,
  },

  {
    path: "/branch",
    meta: {
      title: "Chain System",
    },
    children: routesBranch,
    component: Branch,
  },

  /** Tax Invoice */

  {
    path: "/informasiBongkar",
    meta: {
      title: "Chain System",
    },
    children: routesInformasiBongkar,
    component: InformasiBongkar,
  },

  /** Tax Invoice */

  {
    path: "/faktur-pajak",
    meta: {
      title: "Tax Invoice",
    },
    children: routesFakturPajak,
    component: FakturPajak,
  },

  /** Control Tower Orders */

  {
    path: "/ct-orders",
    meta: {
      title: "Orders Control Tower",
    },
    children: routesTransportOrderCT,
    component: TransportOrderCT,
  },

  /**
   * Order/Transport
   */
  {
    path: "/order/transport-template",
    meta: {
      title: "Chain System",
    },
    name: "TemplateOrderIndex",
    component: TemplateOrder,
  },

  {
    path: "/order/transport-template/add",
    meta: {
      title: "Chain System",
    },
    name: "TemplateOrderAdd",
    component: TemplateOrderAdd,
  },

  {
    path: "/order/transport-template/upload",
    meta: {
      title: "Chain System",
    },
    name: "TemplateOrderUpload",
    component: TemplateOrderUpload,
  },

  {
    path: "/order/transport-template/edit/:id",
    meta: {
      title: "Chain System",
    },
    name: "TemplateOrderEdit",
    component: TemplateOrderEdit,
  },

  {
    path: "/order/transport-template/detail/:id",
    meta: {
      title: "Chain System",
    },
    name: "TemplateOrderDetail",
    component: TemplateOrderDetail,
  },

  {
    path: "/order/transport-template-multi",
    meta: {
      title: "Chain System",
    },
    name: "TemplateOrderMultiIndex",
    component: TemplateOrderMulti,
  },

  {
    path: "/order/transport-template-multi/add",
    meta: {
      title: "Chain System",
    },
    name: "TemplateOrdeMultiAdd",
    component: TemplateOrderMultiAdd,
  },

  {
    path: "/order/transport-template-multi/upload",
    meta: {
      title: "Chain System",
    },
    name: "TemplateOrderMultiUpload",
    component: TemplateOrderMultiUpload,
  },

  {
    path: "/order/transport-template-multi/edit/:id",
    meta: {
      title: "Chain System",
    },
    name: "TemplateOrderMultiEdit",
    component: TemplateOrderMultiEdit,
  },

  {
    path: "/order/transport-template-multi/detail/:id",
    meta: {
      title: "Chain System",
    },
    name: "TemplateOrderMultiDetail",
    component: TemplateOrderMultiDetail,
  },

  /**
   * Order/Transport Order
   */
  {
    path: "/order/transport-order",
    meta: {
      title: "Chain System",
    },
    name: "TransportOrderIndex",
    component: TransportOrder,
  },

  {
    path: "/order/transport-order/add",
    meta: {
      title: "Chain System",
    },
    name: "TransportOrderAdd",
    component: TransportOrderAdd,
  },

  {
    path: "/order/transport-order/upload",
    meta: {
      title: "Chain System",
    },
    name: "TransportOrderUpload",
    component: TransportOrderUpload,
  },

  {
    path: "/order/transport-order/edit/:id",
    meta: {
      title: "Chain System",
    },
    name: "TransportOrderEdit",
    component: TransportOrderEdit,
  },

  {
    path: "/order/transport-order/detail/:id",
    meta: {
      title: "Chain System",
    },
    name: "TransportOrderDetail",
    component: TransportOrderDetail,
  },

  {
    path: "/order/new-transport-order",
    meta: {
      title: "Chain System",
    },
    name: "NewTransportOrderIndex",
    component: NewTransportOrder,
  },

  {
    path: "/order/new-transport-order/add",
    meta: {
      title: "Chain System",
    },
    name: "NewTransportOrderAdd",
    component: NewTransportOrderAdd,
  },

  {
    path: "/order/new-transport-order/upload",
    meta: {
      title: "Chain System",
    },
    name: "NewTransportOrderUpload",
    component: NewTransportOrderUpload,
  },

  {
    path: "/order/new-transport-order/edit/:id",
    meta: {
      title: "Chain System",
    },
    name: "NewTransportOrderEdit",
    component: NewTransportOrderEdit,
  },

  {
    path: "/order/new-transport-order/detail/:id",
    meta: {
      title: "Chain System",
    },
    name: "NewTransportOrderDetail",
    component: NewTransportOrderDetail,
  },

  {
    path: "/order/transport-order-multi",
    meta: {
      title: "Chain System",
    },
    name: "TransportOrderMultiIndex",
    component: TransportOrderMulti,
  },

  {
    path: "/order/transport-order-multi/add",
    meta: {
      title: "Chain System",
    },
    name: "TransportOrderMultiAdd",
    component: TransportOrderMultiAdd,
  },

  {
    path: "/order/transport-order-multi/upload",
    meta: {
      title: "Chain System",
    },
    name: "TransportOrderMultiUpload",
    component: TransportOrderMultiUpload,
  },

  {
    path: "/order/transport-order-multi/edit/:id",
    meta: {
      title: "Chain System",
    },
    name: "TransportOrderMultiEdit",
    component: TransportOrderMultiEdit,
  },

  {
    path: "/order/transport-order-multi/detail/:id",
    meta: {
      title: "Chain System",
    },
    name: "TransportOrderMultiDetail",
    component: TransportOrderMultiDetail,
  },

  {
    path: "/order/new-transport-order-multi",
    meta: {
      title: "Chain System",
    },
    name: "NewTransportOrderMultiIndex",
    component: NewTransportOrderMulti,
  },

  {
    path: "/order/new-transport-order-multi/add",
    meta: {
      title: "Chain System",
    },
    name: "NewTransportOrderMultiAdd",
    component: NewTransportOrderMultiAdd,
  },

  {
    path: "/order/new-transport-order-multi/upload",
    meta: {
      title: "Chain System",
    },
    name: "NewTransportOrderMultiUpload",
    component: NewTransportOrderMultiUpload,
  },

  {
    path: "/order/new-transport-order-multi/edit/:id",
    meta: {
      title: "Chain System",
    },
    name: "NewTransportOrderMultiEdit",
    component: NewTransportOrderMultiEdit,
  },

  {
    path: "/order/new-transport-order-multi/detail/:id",
    meta: {
      title: "Chain System",
    },
    name: "NewTransportOrderMultiDetail",
    component: NewTransportOrderMultiDetail,
  },

  /**
   * Master/SKU
   */
  {
    path: "/sku",
    meta: {
      title: "Chain System",
    },
    children: routesSKU,
    component: SKU,
  },
  /**
   * Master/Location
   */
  {
    path: "/location",
    meta: {
      title: "Chain System",
    },
    children: routesLocation,
    component: Location,
  },

  /**
   * Alert Setting
   */
  {
    path: "/alert-setting",
    meta: {
      title: "Chain System",
    },
    name: "AlertSetting",
    component: AlertSetting,
  },
  {
    path: "/alert-setting/add",
    meta: {
      title: "Chain System",
    },
    name: "AlertSettingAdd",
    component: AlertSettingAdd,
  },

  {
    path: "/alert-setting/edit/:id",
    meta: {
      title: "Chain System",
    },
    name: "AlertSettingEdit",
    component: AlertSettingEdit,
  },

  {
    path: "/alert-setting/detail/:id",
    meta: {
      title: "Chain System",
    },
    name: "AlertSettingDetail",
    component: AlertSettingDetail,
  },

  {
    path: "/alert-setting/upload",
    meta: {
      title: "Chain System",
    },
    name: "AlertSettingUpload",
    component: AlertSettingUpload,
  },

  /** GT */

  {
    path: "/gt/user-mapping",
    meta: {
      title: "User Mapping",
    },
    children: routesUserMappingGT,
    component: UserMappingGT,
  },

  {
    path: "/gt/pool-dashboard/:id",
    meta: {
      title: "Pool Detail",
    },
    component: PoolDetailGT,
  },

  {
    path: "/gt/branch-dashboard/:id",
    meta: {
      title: "Branch Detail",
    },
    component: BranchDetailGT,
  },

  {
    path: "/gt/pool-transactions/:id/:keperluan/:vehicletype",
    meta: {
      title: "Pool Transactions",
    },
    component: poolTransactions,
  },

  {
    path: "/gt/branch-transactions/:id/:keperluan/:vehicletype",
    meta: {
      title: "Branch Transactions",
    },
    component: branchTransactions,
  },

  {
    path: "/gt/transaction/detail/:id",
    meta: {
      title: "Transaction Detail",
    },
    component: TransactionDetail,
  },

  {
    path: "/gt/all-branch-transactions/:keperluan/:vehicletype",
    meta: {
      title: "All Branch Transactions",
    },
    component: allBranchTransactions,
  },
  {
    path: "/gt/news-portal",
    meta: {
      title: "News Portal",
    },
    children: routesNewsPortalGT,
    component: NewsPortalGT,
  },
  {
    path: "/traffict-violation",
    meta: {
      title: "traffictViolation",
    },
    children: routesTraffictViolation,
    component: TraffictViolation,
  },

  {
    path: "/accident",
    meta: {
      title: "Accidendt",
    },
    children: routesAccident,
    component: Accident,
  },

  {
    path: "/customer-trip-orders",
    meta: {
      title: "Customer Trip Orders",
    },
    children: routesCustomerTripOrders,
    component: CustomerTripOrders,
  },

  {
    path: "/sku-test",
    meta: {
      title: "Chain System",
    },
    children: testSkuRoutes,
    component: testSku,
  },
  {
    path: "/proforma-invoice",
    meta: {
      title: "Chain System",
    },
    children: routesProformaInvoice,
    component: ProformaInvoice,
  },
  // {
  //   path: '/customer-charge',
  //   meta: {
  //     title: "Chain System",
  //   },
  //   children:routesCustomerCharge,
  //   component: CustomerCharge
  // },
  {
    path: "/edit-do-number",
    meta: {
      title: "Chain System",
    },
    name: "EditDoNumber",
    component: EditDoNumber,
  },
  {
    path: "/edit-do-number/add",
    meta: {
      title: "Chain System",
    },
    name: "EditDoNumberAdd",
    component: EditDoNumberAdd,
  },
  {
    path: "/edit-do-number/edit/:id",
    meta: {
      title: "Chain System",
    },
    name: "EditDoNumberEdit",
    component: EditDoNumberEdit,
  },
  {
    path: "/edit-do-number/detail/:id",
    meta: {
      title: "Chain System",
    },
    name: "EditDoNumberDetail",
    component: EditDoNumberDetail,
  },
  {
    path: "/edit-do-number/upload",
    meta: {
      title: "Chain System",
    },
    name: "EditDoNumberUpload",
    component: EditDoNumberUpload,
  },
  {
    path: "/dashboard-fullfilment-kanban",
    meta: {
      title: "Chain System",
    },
    name: "DashboardFullfilmentKanban",
    component: DashboardFullfilmentKanban,
  },
  {
    path: "/dashboard-fppk",
    meta: {
      title: "Chain System",
    },
    name: "DashboardFpkk",
    component: DashboardFppk,
  },
  {
    path: "/tol-route",
    meta: {
      title: "Chain System",
    },
    children: RoutesTolRoute,
    component: TolRoute,
  },
  {
    path: "/rute",
    meta: {
      title: "Chain System",
    },
    children: RoutesRute,
    component: RuteRoute,
  },

  

  {
    path: "/margin-list",
    meta: {
      title: "Chain System",
    },
    children: RoutesMarginList,
    component: MarginListRoute,
  },

  {
    path: "/billable-rate-acc",
    meta: {
      title: "Chain System",
    },
    component: BillableAcc,
  },
  {
    path: "/billable-rate",
    meta: {
      title: "Chain System",
    },
    children: RoutesBillableRM,
    component: BillableRM,
  },
  {
    path: "/billable-method",
    meta: {
      title: "Chain System",
    },
    component: BillableMethod,
  },
  {
    path: "/billable-method/add",
    meta: {
      title: "Chain System",
    },
    component: BillableMethodAdd,
  },

  {
    path: "/billable-method/edit/:id",
    meta: {
      title: "Chain System",
    },
    component: BillableMethodEdit,
  },

  {
    path: "/billable-method/:companyId",
    meta: {
      title: "Chain System",
    },
    component: BillableMethod,
  },
  {
    path: "/billable-method/:companyId/add",
    meta: {
      title: "Chain System",
    },
    component: BillableMethodAdd,
  },
  {
    path: "/billable-method/:companyId/edit/:id",
    meta: {
      title: "Chain System",
    },
    component: BillableMethodEdit,
  },
  {
    path: "/new-rate",
    meta: {
      title: "Chain System",
    },
    component: BillableNewRate,
  },
  {
    path: "/stop-location-rm",
    meta: {
      title: "Chain System",
    },
    component: StopLocationRm,
  },

  {
    path: "/billable-parties",
    meta: {
      title: "Chain System",
    },
    component: BillableParties,
  },

  {
    path: "/billable-parties/add",
    meta: {
      title: "Chain System",
    },
    component: BillablePartiesAdd,
  },

  {
    path: "/billable-parties/edit/:id",
    meta: {
      title: "Chain System",
    },
    component: BillablePartiesEdit,
  },

  {
    path: "/billable-parties/detail/:id",
    meta: {
      title: "Chain System",
    },
    component: BillablePartiesDetail,
  },



//
  {
    path: "/billable-manage-service",
    meta: {
      title: "Chain System",
    },
    component: BillableManageService,
  },

  {
    path: "/billable-manage-service/add",
    meta: {
      title: "Chain System",
    },
    component: BillableManageServiceAdd,
  },

  {
    path: "/billable-manage-service/edit/:id",
    meta: {
      title: "Chain System",
    },
    component: BillableManageServiceEdit,
  },

  {
    path: "/billable-manage-service/detail/:id",
    meta: {
      title: "Chain System",
    },
    component: BillableManageServiceDetail,
  },
  //

  {
    path: "/billable-tariff",
    meta: {
      title: "Chain System",
    },
    component: BillableTariff,
  },
  {
    path: "/billable-tariff/upload",
    meta: {
      title: "Chain System",
    },
    component: BillableTariffUpload,
  },


  {
    path: "/payable-tariff",
    meta: {
      title: "Chain System",
    },
    component: PayableTariff,
  },
  {
    path: "/payable-tariff/upload",
    meta: {
      title: "Chain System",
    },
    component: PayableTariffUpload,
  },

  {
    path: "/master-ujo",
    meta: {
      title: "Chain System",
    },
    children: RoutesMasterUjo,
    component: MasterUjo,
  },
  {
    path: "/master-penyebrangan",
    meta: {
      title: "Chain System",
    },
    children: RoutesMasterPenyebrangan,
    component: MasterPenyebrangan,
  },
  {
    path: "/sequence-driver-summary",
    meta: {
      title: "Chain System",
    },
    name: "SequenceDriverSummary",
    component: SequenceDriverSummary,
  },
  {
    path: "/master-rute-penyebrangan",
    meta: {
      title: "Chain System",
    },
    children: RoutesMasterRutePenyebrangan,
    component: MasterRutePenyebrangan,
  },
  {
    path: "/sales-project",
    meta: {
      title: "Chain System",
    },
    component: salesProject,
  },
  {
    path: "/sales-project/detail/:id",
    meta: {
      title: "Chain System",
    },
    component: salesProjectDetail,
  },
  {
    path: "/sales-project/detail/:id/won-rate/:idDetail",
    meta: {
      title: "Chain System",
    },
    component: wonRate,
  },
   {
    path: "/charge-code",
    meta: {
      title: "Chain System",
    },
    component: chargeCode,
  },
  {
    path: "/kriminalitas",
    meta: {
      title: "Chain System",
    },
    children: RoutesKriminalitas,
    component: Kriminalitas,
  },
   {
    path: "/billable-acc-rule",
    meta: {
      title: "Chain System",
    },
    component: BillableAccRule,
  },

  {
    path: "/billable-acc-rule/add",
    meta: {
      title: "Chain System",
    },
    component: BillableAccRuleAdd,
  },

  {
    path: "/billable-acc-rule/edit/:id",
    meta: {
      title: "Chain System",
    },
    component: BillableAccRuleEdit,
  },

  {
    path: "/billable-acc-rule/detail/:id",
    meta: {
      title: "Chain System",
    },
    component: BillableAccRuleDetail,
  },

  {
    path: "/request-rg",
    meta: {
      title: "Chain System",
    },
    children: routesRequestRg,
    component: RequestRg,
  },
  {
    path: "/payable-rate",
    meta: {
      title: "Chain System",
    },
    children: routesPayableRate,
    component: PayableRate,
  },
  {
    path: "/fppk-penerimaan",
    meta: {
      title: "Chain System",
    },
    children: routesFppkPenerimaan,
    component: FppkPenerimaan,
  },
  {
    path: "/fppk-pengajuan",
    meta: {
      title: "Chain System",
    },
    children: routesFppkPengajuan,
    component: FppkPengajuan,
  },
  {
    path: "/fppk-notification",
    meta: {
      title: "Chain System",
    },
    children: routesFppkNotification,
    component: FppkNotification,
  },

  //
];

const router = new VueRouter({
  mode: "history",
  routes,
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;

  if (
    (to.name == "UserLogin" && isLoggedIn()) ||
    (to.name == "ForgotPassword" && isLoggedIn()) ||
    (to.name == "ResetPassword" && isLoggedIn())
  ) {
    var userModel = JSON.parse(localStorage.getItem("userModels"));

    if (userModel.sub.role.name == "root_ct") {
      // next({ path: "/dashboard-ct" });
      next({ path: "/dashboard-fullfilment-kanban" });
    } else if (
      userModel.sub.role.name == "root_faktur_pajak" &&
      userModel.sub.role.name == "user_faktur_pajak"
    ) {
      next({ path: "/dashboard-fp" });
    } else {
      next({ path: "/dashboard" });
    }
  } else if (!to.meta.allowAnonymous && !isLoggedIn()) {
    next({
      path: "/",
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
});

export default router;
