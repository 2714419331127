import GerbangTol from "@/components/master/gerbangTol/GerbangTol.vue";
import GerbangTolAdd from "@/components/master/gerbangTol/GerbangTolAdd.vue";
import GerbangTolDetail from "@/components/master/gerbangTol/GerbangTolDetail.vue";
import GerbangTolEdit from "@/components/master/gerbangTol/GerbangTolEdit.vue";
import GerbangTolUpload from "@/components/master/gerbangTol/GerbangTolUpload.vue";


let routesGerbangTol= [
    
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "GerbangTolIndex",
        component: GerbangTol
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "GerbangTolAdd",
        component: GerbangTolAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "GerbangTolEdit",
        component: GerbangTolEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "GerbangTolDetail",
        component: GerbangTolDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "GerbangTolUpload",
        component: GerbangTolUpload
      },
  ]
  
  export default routesGerbangTol
  