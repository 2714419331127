<template>
  <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title badge bg-primary">Detail</h5>

          <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModalChild', null)"
            aria-label="btn-close"></button>
        </div>


        <div class="modal-body">
          <div class="card">

            <div class="card-body">
              <!-- Jakarta -->
              <div class="row mb-3">
                <div class="col-lg-12">
                  <table class="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>Vehicle No</th>
                        <th>Type of Truck</th>
                        <th>Billable</th>
                        <th>Trip</th>
                        <th>Actual Capacity (Days)</th>
                        <th>Active Day</th>
                        <th>Productivity</th>
                      </tr>
                    </thead>
                    <tbody id="stocksTerminal">
                      <tr v-for="data in dataArrayNasional" :key="data.id">
                        <td>{{ data.vehicle_no }}</td>
                        <td>{{ data.truck_category }}</td>
                        <td>{{ data.billable }}</td>
                        <td style="text-align:right">{{ data.trip_blujay }}</td>
                        <td style="text-align:right">{{ data.trip }}</td>
                        <td style="text-align:right">{{ data.target_trip }}</td>
                        <td style="text-align:right">{{ data.productivity }} %</td>
                      </tr>
                      <tr>
                        <td colspan="6" style="text-align:center">AVERAGE</td>
                        <td style="text-align:right">{{ avgNasional }} %</td>
                      </tr>
                    </tbody>
                  </table>


                </div>
              </div>
            </div>


          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModalChild', null)">
            {{ $t("close_txt") }}
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
//import moment from "moment";
export default {  
  components: {
  },
  
  name: "DetailProductivity",
  emits: ["closeModalChild"],
  props: {   
    
    showModal: Boolean,
    data: {},
   
  },
  watch: {
    showModal: {      
      handler(newVal) {
        this.loadItems(newVal);        
        this.active = newVal;
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");

      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      active: this.showModal,

      opens: "center",
      locale: {
        format: "yyyy-mm-dd",
      },
      permision_role: [],
      dataArrayNasional: [],
      countData: [],
      avgNasional:0,      

    };
  },
  computed: {
        myStyles() {
            return {
                height: '100%',
                position: 'relative'
            }
        }
    },
  methods: {
    submitParent(index, row) {
      this.$emit("closeModalChild", row);
    },
    loadItems(newVal) {
      if(newVal){
        this.dataArrayNasional = this.data;
        
        let trips = 0;
        let targets = 0;
        for(let item of this.dataArrayNasional){
          trips += Number(item.trip);
          targets += Number(item.target_trip);

        }
        // console.log (i);
        this.avgNasional = (trips/targets * 100).toFixed(1);
       
      }else{
        this.dataArrayNasional = [];
      }         
          
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); 
    },
  },
  mounted() {    
    this.loadItems();
  },
};
</script>

<style scoped>
.blur-content {
  filter: blur(5px);
  pointer-events: none;
}

.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 120%;
  height: 100%;
  margin-left: -10%;
}
</style>