<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="LocationDetail"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 25px"
                  >
                    Detail {{ $t("locationTxt") }}
                  </h6>
                  <button
                    class="btn btn-light float-end"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <i class="link-icon" data-feather="info"></i>
                    Audit Info
                  </button>

                  <div
                    class="row mb-3 collapse"
                    id="collapseExample"
                    style="padding-left: 20px"
                  >
                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created By:</label>
                      <p class="text-muted">{{ this.forms.create_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created At:</label>
                      <p class="text-muted">{{ this.forms.created_at }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated By:</label>
                      <p class="text-muted">{{ this.forms.update_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated At:</label>
                      <p class="text-muted">{{ this.forms.updated_at }}</p>
                    </div>
                  </div>
                </div>

                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->
                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="d-flex justify-content-between align-items-baseline mb-2"
                    ></div>

                    <div class="card">
                      <div class="card-body">
                        <h5 class="General">General</h5>
                        <hr />
                          <div class="row mb-3">
                           <div class="col-lg-2">
                            <label for="defaultconfig" class="col-form-label">Type<span class="wajib">*</span></label>
                          </div>
                          <div class="col-lg-3">
                           <div class="form-check form-check-inline">
                            <input type="radio" value="a" class="form-check-input" v-model="forms.type_sl" id="radioA" disabled> 
                            <label class="form-check-label" for="radioA">
                              A - REGION
                            </label>
                          </div>
                           <div class="form-check form-check-inline">
                            <input type="radio" value="b"  class="form-check-input" v-model="forms.type_sl" id="radioB" disabled>
                            <label class="form-check-label" for="radioB">
                              B - Stop Location
                            </label>
                          </div>
                            <div v-if="errors.location_code">
                              <div
                                v-for="error in errors.location_code"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label for="defaultconfig" class="col-form-label">{{
                              $t("locationCodeTxt")
                            }}</label>
                          </div>
                          <div class="col-lg-3">
                            <input
                              type="text"
                              class="form-control"
                              id="sku_code"
                              :placeholder="$t('locationCodeTxt')"
                              v-model="forms.location_code"
                              disabled
                            />
                            <div v-if="errors.location_code">
                              <div
                                v-for="error in errors.location_code"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-1">
                            <label for="defaultconfig" class="col-form-label">{{
                              $t("locationNameTxt")
                            }}</label>
                          </div>
                          <div class="col-lg-4">
                            <input
                              type="text"
                              class="form-control"
                              id="name"
                              :placeholder="$t('locationNameTxt')"
                              v-model="forms.name"
                              disabled
                            />
                            <div v-if="errors.name">
                              <div
                                v-for="error in errors.name"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label for="defaultconfig" class="col-form-label"
                              >Int Code</label
                            >
                          </div>
                          <div class="col-lg-3">
                            <input
                              type="text"
                              class="form-control"
                              id="int_code"
                              placeholder=""
                              v-model="forms.int_code"
                              disabled
                            />
                            <div v-if="errors.int_code">
                              <div
                                v-for="error in errors.int_code"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-1">
                            <label for="defaultconfig" class="col-form-label"
                              >Type</label
                            >
                          </div>
                          <div class="col-lg-4">
                            <input
                              type="text"
                              class="form-control"
                              id="type"
                              placeholder=""
                              :value="forms?.system_code?.description"
                              disabled
                            />
                            <div v-if="errors.type">
                              <div
                                v-for="error in errors.type"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label for="defaultconfig" class="col-form-label">{{
                              $t("companyCode")
                            }}</label>
                          </div>
                          <div class="col-lg-3">
                            <input
                              type="text"
                              class="form-control"
                              id="company_code"
                              v-model="forms.company.code"
                              disabled
                            />
                          </div>

                          <div class="col-lg-1">
                            <label for="defaultconfig" class="col-form-label">{{
                              $t("regionTxt")
                            }}</label>
                          </div>
                          <div class="col-lg-4">
                            <input
                              type="text"
                              class="form-control"
                              id="company_code"
                              v-model="forms.region.region_code"
                              disabled
                            />
                            <!-- <select
                              class="form-control"
                              name="region_id"
                              id="region_id"
                              v-model="forms.region_id"
                              disabled
                            >
                              <option
                                v-for="(a, index) of fetchRegion"
                                :value="a.region_id"
                                :key="index"
                              >
                                {{ a.region_code }}
                              </option>
                            </select> -->

                            <div v-if="errors.region_id">
                              <div
                                v-for="error in errors.region_id"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <br />

                    <!-- <div class="card">
                      <div class="card-body">
                        <h5 class="General">Contact</h5>
                        <hr>
                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label for="defaultconfig" class="col-form-label">{{
                              $t("locationContactName")
                            }}</label>
                          </div>
                          <div class="col-lg-3">
                            <input
                              type="text"
                              class="form-control"
                              id="contact_name"
                              placeholder=""
                              v-model="forms.contact_name"
                              disabled
                            />
                            <div v-if="errors.contact_name">
                              <div
                                v-for="error in errors.contact_name"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-1">
                            <label for="defaultconfig" class="col-form-label">{{
                              $t("locationPhoneNumber")
                            }}</label>
                          </div>
                          <div class="col-lg-4">
                            <input
                              type="text"
                              class="form-control"
                              id="phone_number"
                              placeholder=""
                              v-model="forms.phone_number"
                              disabled
                            />
                            <div v-if="errors.phone_number">
                              <div
                                v-for="error in errors.phone_number"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <br /> -->

                    <div class="card">
                      <div class="card-body">
                        <h5 class="General">Address</h5>
                        <hr />

                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label for="defaultconfig" class="col-form-label">{{
                              $t("countryTxt")
                            }}</label>
                          </div>
                          <div class="col-lg-3">
                            <input
                              type="text"
                              class="form-control"
                              id="country"
                              :placeholder="$t('countryTxt')"
                              v-model="forms.country"
                              disabled
                            />
                            <div v-if="errors.country">
                              <div
                                v-for="error in errors.country"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-1">
                            <label for="defaultconfig" class="col-form-label">{{
                              $t("provinceTxt")
                            }}</label>
                          </div>
                          <div class="col-lg-4">
                            <input
                              type="text"
                              class="form-control"
                              id="province"
                              :placeholder="$t('provinceTxt')"
                              v-model="forms.province"
                              disabled
                            />
                            <div v-if="errors.province">
                              <div
                                v-for="error in errors.province"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label for="defaultconfig" class="col-form-label">{{
                              $t("cityTxt")
                            }}</label>
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text"
                              class="form-control"
                              id="city"
                              :placeholder="$t('cityTxt')"
                              v-model="forms.city"
                              disabled
                            />
                            <div v-if="errors.city">
                              <div
                                v-for="error in errors.city"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label for="defaultconfig" class="col-form-label">{{
                              $t("districtTxt")
                            }}</label>
                          </div>
                          <div class="col-lg-4">
                            <input
                              type="text"
                              class="form-control"
                              id="district"
                              :placeholder="$t('districtTxt')"
                              v-model="forms.district"
                              disabled
                            />
                            <div v-if="errors.district">
                              <div
                                v-for="error in errors.district"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <input
                              type="text"
                              class="form-control"
                              id="sub_district"
                              :placeholder="$t('subDistrictTxt')"
                              v-model="forms.sub_district"
                              disabled
                            />
                            <div v-if="errors.sub_district">
                              <div
                                v-for="error in errors.sub_district"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label for="defaultconfig" class="col-form-label">{{
                              $t("postalCodeTxt")
                            }}</label>
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text"
                              class="form-control"
                              id="postal_code"
                              :placeholder="$t('postalCodeTxt')"
                              v-model="forms.postal_code"
                              disabled
                            />
                            <div v-if="errors.postal_code">
                              <div
                                v-for="error in errors.postal_code"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label for="defaultconfig" class="col-form-label"
                              >{{ $t("address") }} 1</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text"
                              class="form-control"
                              id="address1"
                              :placeholder="$t('address')"
                              v-model="forms.address1"
                              disabled
                            />
                            <div v-if="errors.address1">
                              <div
                                v-for="error in errors.address1"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label for="defaultconfig" class="col-form-label"
                              >{{ $t("address") }} 2</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text"
                              class="form-control"
                              id="address2"
                              :placeholder="$t('address')"
                              v-model="forms.address2"
                              disabled
                            />
                            <div v-if="errors.address2">
                              <div
                                v-for="error in errors.address2"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label for="defaultconfig" class="col-form-label"
                              >{{ $t("address") }} 3</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text"
                              class="form-control"
                              id="address3"
                              :placeholder="$t('address')"
                              v-model="forms.address3"
                              disabled
                            />
                            <div v-if="errors.address3">
                              <div
                                v-for="error in errors.address3"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label for="defaultconfig" class="col-form-label"
                              >Latitude</label
                            >
                          </div>
                          <div class="col-lg-3">
                            <input
                              type="text"
                              class="form-control"
                              id="latitude"
                              placeholder=""
                              v-model="forms.latitude"
                              disabled
                            />
                            <div v-if="errors.latitude">
                              <div
                                v-for="error in errors.latitude"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-1">
                            <label for="defaultconfig" class="col-form-label"
                              >Longitude</label
                            >
                          </div>
                          <div class="col-lg-4">
                            <input
                              type="text"
                              class="form-control"
                              id="longitude"
                              placeholder=""
                              v-model="forms.longitude"
                              disabled
                            />
                            <div v-if="errors.longitude">
                              <div
                                v-for="error in errors.longitude"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-2">
                            <label for="defaultconfig" class="col-form-label"
                              >Radius</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text"
                              class="form-control"
                              id="radius"
                              placeholder=""
                              v-model="forms.radius"
                              disabled
                            />
                            <div v-if="errors.radius">
                              <div
                                v-for="error in errors.radius"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <gmap-map
                            :zoom="14"
                            :center="center"
                            style="width: 100%; height: 500px"
                          >
                            <gmap-marker
                              :key="index"
                              v-for="(m, index) in locationMarkers"
                              :position="m.position"
                              @click="center = m.position"
                            ></gmap-marker>
                          </gmap-map>
                        </div>
                      </div>
                    </div>
                    <br />

                    <div class="card-footer">
                      <button
                        class="btn btn-warning text-black fw-bolder btn-icon-text"
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>

                      <a
                        v-if="permision_role.method_delete"
                        class="btn btn-danger text-white fw-bolder btn-icon-text float-end"
                        @click="deleteData()"
                      >
                        <i class="link-icon" data-feather="trash"></i>
                        {{ $t("deleteMess") }}
                      </a>

                      <a
                        v-if="permision_role.method_update"
                        class="btn btn-primary me-2 text-white fw-bolder btn-icon-text float-end"
                        @click="editData()"
                      >
                        <i class="link-icon" data-feather="edit"></i>
                        {{ $t("editMess") }}
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";

export default {
  name: "LocationDetail",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      permision_role: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      fetchCompany: [],
      fetchRegion: [],
      userData: "",
      companyCode: "",
      forms: {
        company:{},
        region:{}
      },
      center: {
        lat: 39.7837304,
        lng: -100.4458825,
      },
      locationMarkers: [],
      locPlaces: [],
      existingPlace: null,
    };
  },

  watch: {},
  methods: {
    resetForm() {},

    cek_message(status, msg) {
      if (status == "200") {
        this.success(msg);
      }
    },

    editData() {
      var params = this.$route.params.id;
      window.location.href = "/location/edit/" + params;
    },

    deleteData() {
      var id = this.$onBehind(this.$route.params.id);
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI = this.$settings.endPoint + "location/delete/" + id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                window.location.href = "/location";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    initMarker(loc) {
      this.existingPlace = loc;
    },

    backForm() {
      window.location.href = "/location";
    },

    loadCompany() {
      const baseURI = this.$settings.endPoint + "company?limit=100";

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },

    loadRegion() {
      const baseURI = this.$settings.endPoint + "region?limit=100";

      return this.$http.get(baseURI).then((response) => {
        this.fetchRegion = response.data.datas.data;
      });
    },

    loadData(id) {
      const baseURI = this.$settings.endPoint + "location/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        this.forms = response.data.datas;
        this.locateGeoLocation(this.forms.latitude, this.forms.longitude);
        // this.form={ name: 'asep', usernmae:res.username };
      });
    },

    locateGeoLocation(latitude, longitude) {
      const marker = {
        lat: latitude,
        lng: longitude,
      };
      console.log(marker);
      this.locationMarkers.push({ position: marker });
      this.locPlaces.push(this.existingPlace);
      this.center = marker;
      this.existingPlace = null;
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "LocationController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.cek_message(this.$route.query.status, this.$route.query.msg);
    this.loadData(params[0]);
    this.fetchIt();
    this.loadRegion();
    this.loadCompany();
  },
};
</script>
<style scoped></style>
