<template>
  <div>
    <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Details</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
              aria-label="btn-close"></button>
          </div>
          <div class="modal-body">
            <div v-if="this.data.method == 'create'">
              <div class="row mb-3">
                  <div class="col-lg-2">
                    <label
                    for="load_id"
                    class="col-form-label"
                    style="padding-left: 25px"
                    >Load Id</label
                    >
                  </div>
                  <div class="col-lg-4">
                    <input
                    type="text"
                    class="form-control"
                    id="load_id"
                    :placeholder="'Load Id'"
                    v-model="forms.load_id"
                    />
                  </div>
              </div>
              <div class="row mb-3">
                  <div class="col-lg-2">
                    <label
                    for="load_id"
                    class="col-form-label"
                    style="padding-left: 25px"
                    >Loading Date</label
                    >
                  </div>
                  <div class="col-lg-4">
                    <date-range-picker style="width: 100%"
                        control-container-class="form-control" :locale-data="locale"
                        v-model="dateFilter" :opens="opens" :singleDatePicker="false"
                        :timePicker24Hour="true" :timePicker="true" :showWeekNumbers="false">
                    </date-range-picker>
                  </div>
              </div>
              <div class="row mb-3">
                  <div class="col-lg-2">
                    <label
                    for="load_id"
                    class="col-form-label"
                    style="padding-left: 25px"
                    >Customer</label
                    >
                  </div>
                  <div class="col-lg-4">
                    <multiselect @search-change="asyncSearchCompany" v-model="company" :options="fetchCompany"
                      label="name" track-by="name"
                      placeholder="Please Select Company">
                      <span slot="noResult">-</span>
                    </multiselect>
                  </div>
              </div>
            </div>
            <!-- :rows="rows"  -->
            <vue-good-table
                title="list-order-ct"
                mode="remote"
                :select-options="{ 
                                enabled: true,
                                selectOnCheckboxOnly: true, 
                                selectionText: $t('data_selected'), 
                                clearSelectionText: $t('clear'), }"
                :totalRows="totalRecords"
                :line-numbers="true"
                :isLoading.sync="isLoading"
                :pagination-options="{
                    enabled: true,
                }"
                :columns="columns"
            >

            </vue-good-table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
              cancel
            </button>
            <button class="btn btn-success" type="submit" v-if="this.data.method == 'create'">
              <span v-if="isLoading" class="spinner-border spinner-border-sm ms-auto" role="status"
                aria-hidden="true"></span>Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import multiselect from "vue-multiselect";
export default {
    name:"modalProformaInvoiceDetail",
    emits: ["closeModal"],
    props: {
      showModal: Boolean,
      data: null,
    },
    components: {
      DateRangePicker,
      "multiselect":multiselect
    },
    watch: {
    showModal: {
      handler(newVal) {
        console.log(this.data);
        this.active = newVal;
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
          console.log(this.forms);
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
      const startDate = new Date();
      const endDate = new Date();
      return {
          isLoading: false,
          opens: "center",
          locale: {
              format: "yyyy-mm-dd",
          },
          active: this.showModal,
          fetchCompany: [],
          totalRecords:0,
          dateFilter: {
              startDate,
              endDate,
          },
          forms:[],
          columns:[
              {
              label: "Sold to Party Name",
              field: "",
              },
              {
              label: "Load Id",
              field: "",
              },
              {
              label: "Nopol",
              field: "",
              },
              {
              label: "Equipment Description",
              field: "",
              },
              {
              label: "Loading Date",
              field: "",
              },
              {
              label: "Unloading date",
              field: "",
              },
              {
              label: "Origin",
              field: "",
              },  
              {
              label: "Destination",
              field: "",
              }, 
              {
              label: "Delivery Order",
              field: "",
              }, 
              {
              label: "Shipment Numbers",
              field: "",
              }, 
              {
              label: "UOM",
              field: "",
              }, 
              {
              label: "Qty / Ton",
              field: "",
              }, 
              {
              label: "Billable Rate",
              field: "",
              }, 
              {
              label: "Charge Loading",
              field: "",
              }, 
              {
              label: "Charge Unloading",
              field: "",
              }, 
              {
              label: "Charge Multi Pickup",
              field: "",
              }, 
              {
              label: "Charge Multi drop",
              field: "",
              }, 
              {
              label: "Charge Overnight",
              field: "",
              }, 
              {
              label: "Charge Over Tonase",
              field: "",
              },
              {
              label: "Other",
              field: "",
              }, 
              {
              label: "Total",
              field: "",
              }, 
              {
              label: "Product Name",
              field: "",
              }, 
              {
              label: "Outlet Name",
              field: "",
              }, 

          ]
      }
  },
  methods: {
    asyncSearchCompany(ev) {
      const baseURI = this.$settings.endPointCt + "master-customer?limit=10&name=" + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },
    loadCompany() {
      const baseURI = this.$settings.endPointCt + "master-customer?limit=10";

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },
  },
  mounted() {
    this.loadCompany();
  },


}
</script>

<style scoped>
.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left:0px !important;
}
</style>