<template>
    <div>
        <div class="main-wrapper">
            <!--begin::sidebar-->
            <sidebar-component classMenu="GerbangTolAdd"></sidebar-component>
            <!--end::sidebar-->

            <div class="page-wrapper">
                <!--begin::navbar-->
                <navbar-component></navbar-component>
                <!--end::navbar-->

                <!--begin::content-->
                <div class="page-content">
                    <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
                        <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                                    &nbsp;&nbsp;&nbsp;
                                    <h6 class="card-title mb-0 float-start" style="padding-left: 25px">
                                        Driver Blacklist Detail
                                    </h6>
                                    <button class="btn btn-default btn-sm float-end" @click="resetForm" type="button">
                                        <i class="link-icon" data-feather="repeat"></i>
                                        {{ $t("resetFormTxt") }}
                                    </button>
                                </div>
                                <div class="card-body">
                                    <!--begin::loading-data-->
                                    <div v-if="isLoading" class="d-flex align-items-center">
                                        <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                                        <strong>{{ $t("loadingTxt") }}...</strong>
                                    </div>
                                    <!--end::loading-data-->

                                    <form class="forms-sample" method="POST">
                                        <div class="d-flex justify-content-between align-items-baseline mb-2"></div>

                                        <div class="row mb-3">
                                            <div class="col-lg-2">
                                                <label for="defaultconfig" class="col-form-label"
                                                    style="padding-left: 15px">List Type</label>
                                            </div>
                                            <div class="col-lg-3">

                                                <input type="text" disabled class="form-control" id="type_list"
                                                    name="type_list" placeholder="" v-model="forms.type_list" />

                                            </div>
                                        </div>





                                        <div class="row mb-3">
                                            <div class="col-lg-2">
                                                <label for="defaultconfig" class="col-form-label"
                                                    style="padding-left:15px">Driver </label>
                                            </div>
                                            <div class="col-lg-10">
                                                <input type="text" class="form-control" id="driver_name"
                                                    name="driver_name" placeholder="" disabled
                                                    v-model="forms.driver_name" />


                                                <div v-if="errors.driver_id">
                                                    <div v-for="error in errors.driver_id" :key="error"
                                                        class="alert alert-primary" role="alert">
                                                        <i data-feather="alert-circle"></i>
                                                        {{ error }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-3">
                                            <div class="col-lg-2">
                                                <label for="defaultconfig" class="col-form-label"
                                                    style="padding-left: 15px">SIM No</label>
                                            </div>

                                            <div class="col-lg-4">
                                                <input type="text" disabled class="form-control" id="no_rekening"
                                                    name="sim" placeholder="" v-model="forms.sim_no" />

                                                <div v-if="errors.sim_no">
                                                    <div v-for="error in errors.sim_no" :key="error"
                                                        class="alert alert-primary" role="alert">
                                                        <i data-feather="alert-circle"></i>
                                                        {{ error }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row mb-3">
                                            <div class="col-lg-2">
                                                <label for="defaultconfig" class="col-form-label"
                                                    style="padding-left: 15px">ID Card</label>
                                            </div>

                                            <div class="col-lg-4">
                                                <input type="text" class="form-control" id="no_rekening"
                                                    name="id_card_number" disabled placeholder=""
                                                    v-model="forms.id_card_number" />

                                                <div v-if="errors.sim_no">
                                                    <div v-for="error in errors.id_card_number" :key="error"
                                                        class="alert alert-primary" role="alert">
                                                        <i data-feather="alert-circle"></i>
                                                        {{ error }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row mb-3">
                                            <div class="col-lg-2">
                                                <label for="defaultconfig" class="col-form-label"
                                                    style="padding-left: 15px">{{ $t("place_dob") }}</label>
                                            </div>
                                            <div class="col-lg-4">
                                                <input type="text" class="form-control" id="place_dob" name="place_dob"
                                                    placeholder="Birth Place" disabled v-model="forms.birth_place"
                                                    required />

                                                <div v-if="errors.education">
                                                    <div v-for="error in errors.education" :key="error"
                                                        class="alert alert-primary" role="alert">
                                                        <i data-feather="alert-circle"></i>
                                                        {{ error }}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-6">


                                                <input type="text" disabled class="form-control" id="birth_date"
                                                    name="birth_date" placeholder="" v-model="forms.birth_date" />


                                                <div v-if="errors.birth_date">
                                                    <div v-for="error in errors.birth_date" :key="error"
                                                        class="alert alert-primary" role="alert">
                                                        <i data-feather="alert-circle"></i>
                                                        {{ error }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row mb-3">
                                            <div class="col-lg-2">
                                                <label for="defaultconfig" class="col-form-label"
                                                    style="padding-left: 15px">Remarks</label>
                                            </div>

                                            <div class="col-lg-4">
                                                <textarea disabled class="form-control" id="remarks" name="remarks"
                                                    placeholder="" v-model="forms.remarks"></textarea>

                                                <div v-if="errors.remarks">
                                                    <div v-for="error in errors.remarks" :key="error"
                                                        class="alert alert-primary" role="alert">
                                                        <i data-feather="alert-circle"></i>
                                                        {{ error }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row mb-3">
                                            <div class="col-lg-2">
                                                <label for="defaultconfig" class="col-form-label"
                                                    style="padding-left: 15px">Date Reported</label>
                                            </div>

                                            <div class="col-lg-4">


                                                <input type="text" disabled class="form-control" id="date_reported"
                                                    name="date_reported" placeholder="" v-model="forms.date_reported" />

                                                <div v-if="errors.date_reported">
                                                    <div v-for="error in errors.date_reported" :key="error"
                                                        class="alert alert-primary" role="alert">
                                                        <i data-feather="alert-circle"></i>
                                                        {{ error }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row mb-3">
                                            <div class="col-lg-2">
                                                <label for="defaultconfig" class="col-form-label"
                                                    style="padding-left: 15px">Reported</label>
                                            </div>

                                            <div class="col-lg-4">
                                                <input type="text" disabled class="form-control" id="pelapor"
                                                    name="pelapor" placeholder="" v-model="forms.pelapor" />

                                                <div v-if="errors.sim_no">
                                                    <div v-for="error in errors.pelapor" :key="error"
                                                        class="alert alert-primary" role="alert">
                                                        <i data-feather="alert-circle"></i>
                                                        {{ error }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div class="row mb-3">
                                            <div class="col-lg-2">
                                                <label for="defaultconfig" class="col-form-label"
                                                    style="padding-left: 15px">Category</label>
                                            </div>

                                            <div class="col-lg-4">
                                                <input type="text" disabled class="form-control" id="category"
                                                    name="category" placeholder="" v-model="forms.category" />

                                                <div v-if="errors.sim_no">
                                                    <div v-for="error in errors.category" :key="error"
                                                        class="alert alert-primary" role="alert">
                                                        <i data-feather="alert-circle"></i>
                                                        {{ error }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>














                                        <div class="card-footer">
                                            <button class="btn btn-warning text-black fw-bolder btn-icon-text"
                                                @click="backForm" type="button">
                                                <i class="link-icon" data-feather="arrow-left"></i>
                                                {{ $t("backMess") }}
                                            </button>


                                        </div>
                                    </form>
                                </div>
                            </div>
                            <!--end::card-->
                        </div>
                    </div>
                </div>
                <!--end::content-->

                <!--begin::footer-->
                <footer-component></footer-component>
                <!--end::footer-->
            </div>
        </div>
    </div>
</template>

<script>

// import multiselect from "vue-multiselect";
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";

// import moment from "moment";

export default {
    name: "DriverBlackListAdd",
    props: {},
    components: {
        // "multiselect": multiselect,
        "sidebar-component": sidebarComponent,
        "navbar-component": navbarComponent,
        "footer-component": footerComponent,
    },
    data() {
        return {
            // fetchCity: [],
            // city: "",

            fetchDriver: [],
            fetchCategory: [
                {
                    name: 'High'
                },
                {
                    name: 'Medium'
                },
                {
                    name: 'Low'
                }
            ],
            category: "",
            driver: "",
            permision_role: [],
            isLoading: false,
            maxToasts: 100,
            position: "up right",
            closeBtn: true,
            errors: [],
            type_list: "INTERNAL",
            userData: "",
            companyCode: "",
            fetchCompany: [],
            fetchCompanyNotLinc: [],
            forms: {},
            company: [],
        };
    },
    watch: {},
    methods: {
        resetForm() {
            this.forms.class_1 = "";
            this.forms.class_2 = "";
            this.forms.class_3 = "";
            this.forms.class_4 = "";
            this.forms.class_5 = "";
            this.forms.remarks = "";
            this.forms.toll_gate_name = "";
        },

        loadCompany() {
            const baseURI = this.$settings.endPoint + "company?limit=10";

            return this.$http.get(baseURI).then((response) => {
                this.fetchCompany = response.data.datas.data;
            });
        },

        loadCompanyNotLinc() {
            const baseURI = this.$settings.endPoint + `company/select`;

            return this.$http.get(baseURI).then((response) => {
                this.fetchCompanyNotLinc = response.data.datas.data;
            });
        },

        asyncSearchCompany(ev) {
            const baseURI = this.$settings.endPoint + "company?code=" + ev;

            return this.$http.get(baseURI).then((response) => {
                this.fetchCompany = response.data.datas.data;
            });
        },

        asyncSearchCompanyNotLinc(ev) {
            const baseURI = this.$settings.endPoint + "company/select/code=" + ev;

            return this.$http.get(baseURI).then((response) => {
                this.fetchCompanyNotLinc = response.data.datas.data;
            });
        },




        // loadCity() {

        //     const baseURI =
        //         this.$settings.endPoint + `postal-code`;
        //     return this.$http.get(baseURI).then((response) => {
        //         this.fetchCity = response.data.datas.data;
        //     });
        // },

        // searchCity(ev) {
        //     const baseURI =
        //         this.$settings.endPoint + `postal-code?city=` + ev;
        //     return this.$http.get(baseURI).then((response) => {
        //         this.fetchCity = response.data.datas.data;
        //     });
        // },

        changeRadio() {
            console.log(this.forms.type_list);
        },

        fetchIt() {
            const userDatas = this.$getUserInfo();
            this.detailUser = userDatas.sub;
            this.companyCode = this.detailUser.company_detail.code;
            // this.permision_role = userDatas.sub.permision_role;
            let rolePermision = [];
            userDatas.sub.permision_role.forEach(function (permision) {
                if (permision.permission.controller == "DriverBlacklistController") {
                    rolePermision = permision;
                    // console.log(permision);
                }
            });

            this.permision_role = rolePermision;
        },

        customLabel(ev) {
            return `${ev.driver_id} – ${ev.driver_name}`
        },

        backForm() {
            if (this.$route.query?.from == 'approval') {
                window.location.href = "/driver/driver-approval";
            } else {
                window.location.href = "/driver/driver-blacklist";
            }

        },

        loadDriver() {
            const baseURI =
                this.$settings.endPointCt + `master-driver/select`;
            return this.$http.get(baseURI).then((response) => {
                this.fetchDriver = response.data.datas.data;

            });
        },

        loadData() {

            var id = this.$onBehind(this.$route.params.id);
            const baseURI =
                this.$settings.endPointCt + `driver-blacklist/detail/` + id;
            return this.$http.get(baseURI).then((response) => {
                this.forms = response.data.datas;

            });
        },

        asyncSearchDriver(ev) {
            this.fetchDriver = [];
            const baseURI =
                this.$settings.endPointCt + `master-driver/select?driver_id=` + ev;
            return this.$http.get(baseURI).then((response) => {
                this.fetchDriver = response.data.datas.data;
            });
        },

        submitData() {
            this.forms.company_id = this.company.company_id;
            this.fade(true);
            const baseURI = this.$settings.endPoint + "driver-blacklist/create";
            this.$http
                .post(baseURI, this.forms)
                .then((response) => {
                    this.loading();
                    if (response.data.status === 200) {
                        //this.resetForm();
                        this.errors = [];
                        //console.log(response.data.datas.user_id);
                        var params = this.$onRandom(
                            response.data.datas.rm_toll_gate_master_id
                        );
                        window.location.href =
                            "/gerbang-tol/detail/" + params + "?status=200&msg=Successfully";
                        // this.success('Berhasil');
                    } else {
                        this.errors = response.data.errors;
                        this.resultError(response.data.errors);
                    }
                })
                .catch((error) => {
                    this.loading();
                    if (error.response) {
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors;
                            this.resultError(error.response.data.errors);
                        } else if (error.response.status === 500) {
                            this.error(error.response.data.errors.message);
                        } else {
                            this.$router.push("/page-not-found");
                        }
                    }
                    //this.resetForm();
                });
        },

        fade(sType) {
            this.isLoading = sType;
        },

        loading() {
            this.fade(true);
            setTimeout(() => {
                this.fade(false);
            }, 1000); // hide the message after 3 seconds
        },

        resultError(data) {
            var count = Object.keys(data).length;
            for (var x = 0; x < count; x++) {
                var nameOb = Object.keys(data)[x];
                var objectData = data[nameOb];
                for (var y = 0; y < objectData.length; y++) {
                    this.error(objectData[y]);
                }
            }
        },

        success(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "success",
                title: kata,
            });
        },

        error(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "error",
                title: kata,
            });
        },
    },
    events: {},
    created: function () { },
    mounted() {
        this.fetchIt();
        this.loadData();
        // this.loadCity();


        // this.loadCompany();
        // this.loadCompanyNotLinc();
    },
};
</script>
<style scoped></style>