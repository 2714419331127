import fppkNotification from "@/components/fppk/notifikasi/fppkNotification.vue";
import fppkNotificationAdd from "@/components/fppk/notifikasi/fppkNotificationAdd.vue";
import fppkNotificationDetail from "@/components/fppk/notifikasi/fppkNotificationDetail.vue";
import fppkNotificationEdit from "@/components/fppk/notifikasi/fppkNotificationEdit.vue";
let routes= [
    
    {
      path: '/',
      meta: {
        title: "Chain System",
      },
      name: "fppkNotification",
      component: fppkNotification
    },
    {
      path: 'add',
      meta: {
        title: "Chain System",
      },
      name: "fppkNotificationAdd",
      component: fppkNotificationAdd
    },
    {
      path: 'detail/:id',
      meta: {
        title: "Chain System",
      },
      name: "fppkNotificationDetail",
      component: fppkNotificationDetail
    },
    {
      path: 'edit/:id',
      meta: {
        title: "Chain System",
      },
      name: "fppkNotificationEdit",
      component: fppkNotificationEdit
    },
  ]
  
  export default routes
  