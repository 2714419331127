<template>
  <div>
    <div
      ref="modal"
      class="modal fade"
      :class="{ show: active, 'd-block': active }"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Rate Breaks</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              v-on:click="$emit('closeModal')"
              aria-label="btn-close"
            ></button>
          </div>
          <div class="modal-body">
            <!--begin::loading-data-->
            <div v-if="isLoading" class="d-flex align-items-center">
              <div
                class="spinner-border ms-auto"
                role="status"
                aria-hidden="true"
              ></div>
              <strong>{{ $t("loadingTxt") }}...</strong>
            </div>
            <!--end::loading-data-->
            <div class="row mb-3">
              <div class="col-lg-8">
                <div class="row">
                  <div class="col-lg-4">
                    <label
                      for="defaultconfig"
                      class="col-form-label"
                      style="padding-left: 25px"
                      >Rate Break Type
                    </label>
                  </div>
                  <div class="col-lg-8">
                    <v-select
                      :options="fetchRateBreakType"
                      v-model="rate_break_type"
                    ></v-select>
                    <div
                      v-for="error in errors.rate_break_type"
                      :key="error"
                      class="alert alert-primary"
                      role="alert"
                    >
                      <i data-feather="alert-circle"></i>
                      {{ error }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="row">
                  <div class="col-lg-12">
                    <div>
                      <div class="form-check">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="checkDefault"
                          v-model="forms.cummulative"
                        />
                        <label class="form-check-label" for="checkDefault">
                          Cummulative
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row card">
              <div class="card-header">
                <button
                  class="btn btn-success btn-icon text-white fw-bolder btn-icon-text float-end"
                  @click="addField()"
                  type="button"
                >
                  <i class="link-icon" data-feather="plus"></i>
                </button>
              </div>
              <div class="card-body">
                <div class="table-responsive" style="height: 300px">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Break Start <span class="wajib">*</span></th>
                        <th>Break End <span class="wajib">*</span></th>
                        <th style="width: 200px">Rate Basis <span class="wajib">*</span></th>
                        <th>Cost <span class="wajib">*</span></th>
                        <th>Base Cost %</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody v-if="this.detailAll.length > 0">
                      <tr
                        v-for="(input, index) in this.detailAll"
                        :key="`phoneInput-${index}`"
                      >
                        <td>
                          <input
                            type="number"
                            v-model.number="input.break_start"
                            required
                            class="form-control"
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            v-model.number="input.break_end"
                            class="form-control"
                            required
                          />
                        </td>
                        <td>
                          <!-- <input
                            type="text"
                            v-model="input.rate_basis"
                            class="form-control"
                          /> -->
                          <v-select
                            :options="fetchRateBasis"
                            v-model="input.rate_basis"
                            required
                            return-object
                          ></v-select>
                        </td>
                        <td>
                          <input
                            type="number"
                            v-model.number="input.cost"
                            class="form-control"
                            required
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            v-model="input.base_cost_percent"
                            class="form-control"
                          />
                        </td>
                        <td>
                          <!--          Remove Svg Icon-->
                          <svg
                            @click="removeField(index, detailAll)"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            class="ml-2 cursor-pointer"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              fill="red"
                              d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                            />
                          </svg>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="6">{{ $t("no_data_available_txt") }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              v-on:click="$emit('closeModal')"
            >
              cancel
            </button>
            <button
              v-if="!isUpdate"
              type="button"
              class="btn btn-primary"
              :disabled="isLoading"
              v-on:click="submitData"
            >
              Save
            </button>
            <button
              v-else
              type="button"
              class="btn btn-primary"
              :disabled="isLoading"
              v-on:click="updateData"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modalRateBreak",
  emits: ["closeModal"],
  components: {},
  props: {
    showModal: Boolean,
    data: null,
  },
  watch: {
    showModal: {
      handler(newVal) {
        console.log(this.data);
        this.active = newVal;
        this.forms = {};
        this.detailAll = [];
        this.hasData = false;
        if (this.active) {
          this.loadData();
        }
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      fetchRateBreakType: [],
      isLoading: false,
      opens: "center",
      fetchRateBasis: [],
      rate_break_type: "",
      active: this.showModal,
      detailAll: [],
      errors: [],
      isUpdate: false,
      forms: {},
      locale: {
        format: "dd mmm yyyy H:MM:ss",
      },
    };
  },
  methods: {
    validate(){
      var error = 0;
      this.detailAll.forEach((item,index) => {
          if(item.rate_basis == "" || !item.rate_basis){
            this.error("Rate Basis on Row "+index+" Empty");
            error = 1;
          }
          if((item.break_start == "" || !item.break_start) && index !== 0){
            this.error("Break Start on Row "+index+" Empty");
            error = 1;
          }
          if(item.break_end == "" || !item.break_end){
            this.error("Break End on Row "+index+" Empty");
            error = 1;
          }
          if(item.cost == "" || !item.cost){
            this.error("Cost on Row "+index+" Empty");
            error = 1;
          }
        });
        if(error == 0){
          return true;
        }else{
          return false;
        }
    },
    submitData() {
      if(!this.validate()){
        return;
      }
      var sendData = {
        rm_billable_rate_detail_id: this.data.id,
        rate_break_type: this.rate_break_type,
        cumulative: this.forms.cummulative ?? false,
        rate_break: this.detailAll,
      };
      var baseURI =
        this.$settings.endPoint + `rm-billable-rate-header/create-rate-break`;
      this.$http
        .post(baseURI, sendData)
        .then((response) => {
          if (response.data.status === 200) {
            this.$emit("closeModal");
            this.success2(response.data.datas.messages ?? "Success");
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
        });
    },
    updateData() {
      if(!this.validate()){
        return;
      }
      var sendData = {
        rate_break_type: this.rate_break_type,
        cumulative: this.forms.cummulative ?? false,
        rate_break: this.detailAll,
      };
      var baseURI =
        this.$settings.endPoint +
        `rm-billable-rate-header/update-rate-break/` +
        this.forms.rm_billable_rate_detail_id;
      this.$http
        .patch(baseURI, sendData)
        .then((response) => {
          if (response.data.status === 200) {
            this.$emit("closeModal");
            this.success2(response.data.datas.messages ?? "Success");
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
        });
    },
    loadRateBasis() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmbas`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchRateBasis = response.data.datas;
      });
    },
    loadData() {
      this.fade(true);
      // const baseURI =
      //   this.$settings.endPoint +
      //   `rm-billable-rate-header/detail-detail/` +
      //   this.data.id;
      this.isUpdate = false;

      // return this.$http.get(baseURI).then((response) => {
      //   this.detailAll = response.data.datas.billable_rate_break;
      //   var detail = response.data.datas.billable_rate_break;
      //   if (detail.length > 0) {
      //     this.rate_break_type = detail[0].rate_break_type;
      //     var cummulative = false;
      //     this.isUpdate = true;
      //     if (detail[0].cummulative !== "0") {
      //       cummulative = true;
      //     }
      //     this.forms.rate_break_type = detail[0].rate_break_type;
      //     this.forms.cummulative = cummulative;
      //     this.forms.rm_billable_rate_detail_id =
      //       response.data.datas.rm_billable_rate_detail_id;
      //   }
      //   this.loadRateBasis();
      //this.loading();
      // });

      this.loading();
    },
    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },
    loadRateBreakType() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmrb`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchRateBreakType = response.data.datas;
      });
    },
    addField() {
      if (this.detailAll.length > 0) {
        var data = this.detailAll[this.detailAll.length - 1];
        this.detailAll.push({
          rate_basis: "",
          break_start: parseInt(data.break_end),
          break_end: 0,
          cost: 0,
          base_cost_percent: 0,
        });
        console.log(this.detailAll, this.detailAll.length);
      } else {
        this.detailAll.push({
          rate_basis: "",
          break_start: 0,
          break_end: 0,
          cost: 0,
          base_cost_percent: 0,
        });
      }
      //fieldType.push({ value: "" });
    },
    fade(sType) {
      this.isLoading = sType;
    },
    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },
    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },
    success2(msg) {
      this.$swal({
        icon: "info",
        backdrop: true,
        title: "Information",
        html: "<p>* " + msg + "</p>",
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },
  },
  mounted() {
    this.loadRateBreakType();
  },
};
</script>
<style scoped>
.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  /* min-height: 600px; */
  height: 100%;
  margin-left:0px !important;
}
</style>
