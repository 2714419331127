<template>
  <div>
    <div
      ref="modal"
      class="modal fade"
      :class="{ show: active, 'd-block': active }"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-if="this.data.type == 'create'">
              Create Payable Tariff
            </h5>
            <h5 class="modal-title" v-else>Update Payable Tariff</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              v-on:click="$emit('closeModal',null,null)"
              aria-label="btn-close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row" >
              <div class="row mb-3">
                <div class="col-lg-3">
                  <label
                    for="defaultconfig"
                    class="col-form-label"
                    style="padding-left: 25px"
                    >Tariff Type
                  </label>
                </div>
                <div class="col-lg-8">
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    v-model="forms.tariff_type"
                  />
                  <!-- <v-select
                    :options="fetchTarifType"
                    
                    v-model="tariff_type"
                    label="value"
                    disabled
                    return-object
                    
                    ></v-select> -->
                </div>
                <div
                  v-for="error in errors.tariff_type"
                  :key="error"
                  class="alert alert-primary"
                  role="alert"
                >
                  <i data-feather="alert-circle"></i>
                  {{ error }}
                </div>
              </div>
            </div>
           
            <div class="row" >
              <div class="row mb-3">
                <div class="col-lg-3">
                  <label
                    for="defaultconfig"
                    class="col-form-label"
                    style="padding-left: 25px"
                    >Tariff Type
                  </label>
                </div>
                <div class="col-lg-8">
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    v-model="forms.mode"
                  />
                  <!-- <v-select
                    :options="fetchTarifType"
                    
                    v-model="tariff_type"
                    label="value"
                    disabled
                    return-object
                    
                    ></v-select> -->
                </div>
                <div
                  v-for="error in errors.tariff_mode"
                  :key="error"
                  class="alert alert-primary"
                  role="alert"
                >
                  <i data-feather="alert-circle"></i>
                  {{ error }}
                </div>
              </div>
            </div>

            <div class="row" >
              <div class="row mb-3">
                <div class="col-lg-3">
                  <label
                    for="defaultconfig"
                    class="col-form-label"
                    style="padding-left: 25px"
                    >Carrier
                  </label>
                </div>
                <div class="col-lg-8">
                  <!-- <v-select
                    :options="fetchCompany"
                    v-model="company"
                    label="name"
                    return-object
                    @search="asyncSearchCompany"
                  ></v-select> -->
                  <multiselect
                    @search-change="asyncSearchCompany"
                    v-model="company"
                    :options="fetchCompany"
                    label="carrier_name"
                    track-by="carrier_name"
                    placeholder="Please Select"
                  >
                    <span slot="noResult">-</span>
                  </multiselect>
                </div>
                <div
                  v-for="error in errors.carrier_name"
                  :key="error"
                  class="alert alert-primary"
                  role="alert"
                >
                  <i data-feather="alert-circle"></i>
                  {{ error }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="row mb-3">
                <div class="col-lg-3">
                  <label
                    for="defaultconfig"
                    class="col-form-label"
                    style="padding-left: 25px"
                    >Tariff Name
                  </label>
                </div>
                <div class="col-lg-8">
                  <input
                    type="text"
                    class="form-control"
                    v-model="forms.tariff_name"
                  />
                  <div
                    v-for="error in errors.tariff_name"
                    :key="error"
                    class="alert alert-primary"
                    role="alert"
                  >
                    <i data-feather="alert-circle"></i>
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="row mb-3">
                <div class="col-lg-3">
                  <label
                    for="defaultconfig"
                    class="col-form-label"
                    style="padding-left: 25px"
                    >Description
                  </label>
                </div>
                <div class="col-lg-8">
                  <textarea
                    name="desription"
                    id="desription"
                    v-model="forms.description"
                    class="form-control"
                    cols="30"
                    rows="3"
                  ></textarea>
                  <div
                    v-for="error in errors.description"
                    :key="error"
                    class="alert alert-primary"
                    role="alert"
                  >
                    <i data-feather="alert-circle"></i>
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              v-on:click="$emit('closeModal',null,null)"
            >
              cancel
            </button>
            <div v-if="this.data.type == 'create'">
              <button 
              type="button"
              class="btn btn-primary me-2"
              v-on:click="submitData('close')"
            >
              Save
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-on:click="submitData('open')"
            >
              Save & Upload
            </button>
            </div>
            <div v-else>
              <button
              type="button"
              class="btn btn-primary me-2"
              v-on:click="updateData('close')"
            >
              Update
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-on:click="updateData('open')"
            >
              Update & Upload
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import multiselect from "vue-multiselect";
export default {
  name: "modalUpdateDate",
  emits: ["closeModal"],
  components: {
    multiselect: multiselect,
  },
  props: {
    showModal: Boolean,
    data: null,
  },
  watch: {
    showModal: {
      handler(newVal) {
        this.active = newVal;
        this.forms = {};
        this.company = {};
        this.company_code = "";
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
        if (this.active == true) {
          if (this.data.type !== "create") {
            this.forms = this.data;
            this.company={
              carrier_code:this.data.carrier_id,
              carrier_name:this.data.carrier_name
            }
            // this.company_code = this.data.carrier_name;
          }else{
            this.forms.tariff_type="Manual";
            this.forms.mode="TL";

          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      fetchTarifType:[],
      fetchMode:[],
      mode:'',
      tariff_type:'',
      isLoading: false,
      opens: "center",
      active: this.showModal,
      company_code: "",
      fetchCompany: [],
      company: [],
      forms: {
        "tariff_name": "",
        "carrier_id": "",
        "carrier_name":"",
        "mode":"TL",
        "tariff_type":"",
        "tariff_carrier":""
      },
      errors: [],
      locale: {
        format: "dd mmm yyyy H:MM:ss",
      },
    };
  },
  methods: {
    submitData(type) {
      // var company = this.company_code;
      // if (!this.company_code) {
      //   company = this.company.code;
      // }
      this.forms.carrier_id = this.company.carrier_code;
      this.forms.carrier_name = this.company.carrier_name;
      var baseURI = this.$settings.endPoint + "rm-payable-tariff/create";
      return this.$http
        .post(baseURI, this.forms)
        .then((response) => {
          if (response.data.status === 200) {
            this.$emit("closeModal",type,response.data.datas.rm_payable_tariff_id);
            this.success2("Data Berhasil Disimpan");
            this.errors = [];
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },
    updateData(type) {
      this.forms.carrier_id = this.company.carrier_code;
      this.forms.carrier_name = this.company.carrier_name;
      var baseURI =
          this.$settings.endPoint +
          "rm-payable-tariff/update/" +
          this.data.rm_payable_tariff_id;
      return this.$http
        .patch(baseURI, this.forms)
        .then((response) => {
          if (response.data.status === 200) {
            this.$emit("closeModal",type,this.data.rm_payable_tariff_id);
            this.success2("Data Berhasil Disimpan");
            this.errors = [];
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },
    loadCompany() {
      const baseURI = this.$settings.endPoint + "rm-payable-tariff/get-carrier";
      //this.isLoading = true;

      return this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          // this.fetchCompany = response.data.datas;
          this.fetchCompany = response.data.datas.map((company) => {
                // Membagi carrier_name menjadi array berdasarkan tanda "-"
                const parts = company.carrier_name.split('-');
                // Mengambil elemen kedua dari array sebagai carrier_name yang diinginkan
                company.carrier_name = parts.length > 1 ? parts[1].trim() : company.carrier_name;
                return company;
            });

        })
        .catch((error) => {
          this.isLoading = false;

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },
    asyncSearchCompany(ev) {
      this.fetchCompany = [];
      const baseURI = this.$settings.endPoint + "rm-payable-tariff/get-carrier?name=" + ev;
      //this.isLoading = true;

      return this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          this.fetchCompany = response.data.datas.map((company) => {
                // Membagi carrier_name menjadi array berdasarkan tanda "-"
                const parts = company.carrier_name.split('-');
                // Mengambil elemen kedua dari array sebagai carrier_name yang diinginkan
                company.carrier_name = parts.length > 1 ? parts[1].trim() : company.carrier_name;
                return company;
            });

        })
        .catch((error) => {
          //this.isLoading = false;

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },
    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },
    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success2(msg) {
      this.$swal({
        icon: "info",
        backdrop: true,
        title: "Information",
        html: "<p>* " + msg + "</p>",
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },
  },
  mounted() {
    this.company_code =
      this.$route.query == undefined ? "" : this.$route.query.company_code;
      
    if (!this.company_code) {
      this.loadCompany();
    }
  },
};
</script>
  <style scoped>
.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left:0px !important;
}
</style>