<template>
    <!--begin::content-->
    <div class="page-content">
      <div
        class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
      >
        <div>
          <h4 class="mb-12 mb-md-0"></h4>
        </div>

        <div class="col-lg-12 col-xl-12 stretch-card">
          <div class="card">
            <div class="card-header">
              <h4 style="margin-right: 5px" class="float-start">
                Email Notification
              </h4>
              <button
                v-if="permision_role.method_create"
                class="btn btn-primary float-end btn-xs"
                style="margin-right: 5px"
                @click.prevent="createData()"
              >
                <i class="link-icon" data-feather="plus"></i>
                {{ $t("createNew") }}
              </button>
            </div>

            <div class="card-body">
              <vue-good-table
                title="list-region"
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                :totalRows="totalRecords"
                :line-numbers="true"
                :isLoading.sync="isLoading"
                :pagination-options="{
                  enabled: true,
                }"
                :rows="rows"
                :columns="columns"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'actions'">
                    <a
                      href="#"
                      v-if="permision_role.method_read"
                      title="Detail"
                      @click.prevent="detailData(props.index, props.row)"
                    >
                      <i class="mdi mdi-eye" style="font-size: 16px"></i>
                    </a>
                    <a
                      href="#"
                      v-if="permision_role.method_update"
                      title="Edit"
                      @click.prevent="editData(props.index, props.row)"
                    >
                      <i
                        class="mdi mdi-square-edit-outline"
                        style="font-size: 16px"
                      ></i>
                    </a>
                    <a
                      href="#"
                      v-if="permision_role.method_delete"
                      title="Delete"
                      @click.prevent="deleteData(props.index, props.row)"
                    >
                      <i
                        class="mdi mdi-trash-can-outline"
                        style="font-size: 16px"
                      ></i>
                    </a>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::content-->
</template>
  
  <script>
export default {
  name: "FppkNotification",
  components: {},
  data() {
    return {
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      permision_role: [],
      langs: ["id", "en"],
      detailUser: [],
      detailDivisi: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [
        {
          label: "Username",
          field: "username",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Username", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Self",
          field: "selfFlag",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Flag", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: ["Y", "N"], // dropdown (with selected values) instead of text input
          },
        },

        {
          label: "Jakarta",
          field: "jakartaFlag",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Flag", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: ["Y", "N"], // dropdown (with selected values) instead of text input
          },
        },

        {
          label: "Semarang",
          field: "semarangFlag",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Flag", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: ["Y", "N"], // dropdown (with selected values) instead of text input
          },
        },

        {
          label: "Surabaya",
          field: "surabayaFlag",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Flag", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: ["Y", "N"], // dropdown (with selected values) instead of text input
          },
        },

        {
          label: "Medan",
          field: "medanFlag",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Flag", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: ["Y", "N"], // dropdown (with selected values) instead of text input
          },
        },

        {
          label: "Daily Report",
          field: "daily_reportFlag",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Flag", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: ["Y", "N"], // dropdown (with selected values) instead of text input
          },
        },
        {
          label: "Action",
          field: "actions",
          sortable: false,
        },
      ],
      rows: [],
    };
  },
  watch: {},
  methods: {
    downloadData() {
      const baseURI = this.$settings.endPoint + "/rm-fuel-master";
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = "download_" + CurrentDate + ".xlsx";

      return this.$http
        .get(
          baseURI +
            `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&period_code=${this.serverParams.columnFilters.period_code}&period_name=${this.serverParams.columnFilters.period_name}&price=${this.serverParams.columnFilters.price}&price_gap=${this.serverParams.columnFilters.price_gap}&effective_date=${this.serverParams.columnFilters.effective_date}&expired_date=${this.serverParams.columnFilters.expired_date}&download=download`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },

    // downloadData() {
    //   this.fade(true);
    //   var baseURI = this.$settings.endPoint + "/user/download";
    //   var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
    //   var columnFilters = this.serverParams.columnFilters;
    //   var role = columnFilters["role.name"];
    //   var region_code = columnFilters["region_detail.code"];

    //   var sendData = {
    //     username: this.serverParams.columnFilters.username,
    //     name: this.serverParams.columnFilters.name,
    //     email: this.serverParams.columnFilters.email,
    //     level: this.serverParams.columnFilters.level,
    //     status: this.serverParams.columnFilters.status,
    //     role:role,
    //     region_code: region_code,
    //     file_name: "download_" + CurrentDate + ".xlsx",
    //   };
    //   this.$http({
    //     url: baseURI,
    //     method: "GET",
    //     data: sendData,
    //     responseType: "blob",
    //   })
    //     .then((response) => {
    //       this.errors = [];
    //       var filename = sendData.file_name;

    //       var fileURL = window.URL.createObjectURL(new Blob([response.data]));
    //       var fileLink = document.createElement("a");

    //       fileLink.href = fileURL;
    //       fileLink.setAttribute("download", filename);
    //       document.body.appendChild(fileLink);
    //       fileLink.click();
    //       this.fade(false);
    //     })
    //     .catch((error) => {
    //       if (error.response) {
    //         if (error.response.status === 422) {
    //           this.errors = { message: ["File Not Found"] };
    //           this.error("File Not Found");
    //         } else if (error.response.status === 500) {
    //           this.$router.push("/server-error");
    //         } else {
    //           this.$router.push("/page-not-found");
    //         }
    //       }
    //     });
    // },
    createData() {
      window.location.href = "/fppk-notification/add";
    },

    editData(index, row) {
      var params = this.$onRandom(row.email_notification_id);
      window.location.href = "/fppk-notification/edit/" + params;
    },

    detailData(index, row) {
      var params = this.$onRandom(row.email_notification_id);
      window.location.href = "/fppk-notification/detail/" + params;
    },

    deleteData(index, row) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          const baseURI =
            this.$settings.endPointFppk +
            "v1/email-notif-fppk/delete/" +
            row.email_notification_id;
          this.$http
            .delete(baseURI)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },
    // load items is what brings back the rows from server
    loadItems() {
      const baseURI = this.$settings.endPointFppk + "v1/email-notif-fppk";

      return this.$http
        .get(
          baseURI +
            `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&username=${this.serverParams.columnFilters.username}&selfFlag=${this.serverParams.columnFilters.selfFlag}&jakartaFlag=${this.serverParams.columnFilters.jakartaFlag}&semarangFlag=${this.serverParams.columnFilters.semarangFlag}&surabayaFlag=${this.serverParams.columnFilters.surabayaFlag}&medanFlag=${this.serverParams.columnFilters.medanFlag}&daily_reportFlag=${this.serverParams.columnFilters.daily_reportFlag}`
        )
        .then((response) => {
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
        });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "EmailNotifFppkController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      this.permision_role = rolePermision;
    },

    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },
  },
  events: {},
  created: function () {},
  mounted() {
    this.fetchIt();
    this.loading();
  },
};
</script>
  <style scoped>
</style>