<template>
    <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Change Order No</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
              aria-label="btn-close"></button>
          </div>
          <div class="modal-body">
            <!--begin::loading-data-->
            
            <form class="forms-sample" @submit.prevent="submitData" method="POST">
              <div class="d-flex justify-content-between align-items-baseline mb-2"></div>
  
              <div class="row mb-3">
                <div class="col-lg-4">
                  <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Current Order No</label>
                </div>
                <div class="col-lg-8">
                    <input type="text" id="driver_number" name="driver_number" :value="data.order_no" readonly class="form-control"
                     />
                  <div v-if="errors.pick_plan">
                    <div v-for="error in errors.pick_plan" :key="error" class="alert alert-primary" role="alert">
                      <i data-feather="alert-circle"></i>
                      {{ error }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3"> 
                <div class="col-lg-4">
                  <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">New Order No</label>
                </div>
                <div class="col-lg-8">
                    <input type="text" name="order_no_new" v-model="forms.order_no_new" class="form-control"
                     />
                  <div v-if="errors.order_no_new">
                    <div v-for="error in errors.order_no_new" :key="error" class="alert alert-primary" role="alert">
                      <i data-feather="alert-circle"></i>
                      {{ error }}
                    </div>
                  </div>
                </div>
              </div>



      
            <div class="row mb-3">
              <div class="col-lg-4">
                <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Customer Dest</label>
              </div>
              <div class="col-lg-8">
                <multiselect @search-change="asyncLookupCode" v-model="remark_dest_customer" :show-labels="false"
                  :options="fetchLookupCode" placeholder="Please Select Customer Dest" label="value" track-by="value">
                </multiselect>

                <div v-if="errors.remark_dest_customer">
                  <div v-for="error in errors.remark_dest_customer" :key="error" class="alert alert-primary"
                    role="alert">
                    <i data-feather="alert-circle"></i>
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>

        



              


              

              <div class="modal-footer">
                
                <!--end::loading-data-->
                <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
                  {{ $t("close_txt") }}
                </button>
                <button class="btn btn-primary" type="submit">
                  <span v-if="isLoading" class="spinner-border spinner-border-sm ms-auto"
                  role="status"
                  aria-hidden="true"></span>Send Request 
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import multiselect from "vue-multiselect";
  export default {
    name: "ModalChangeOrderNo",
    emits: ["closeModal"],
    components: {
    "multiselect": multiselect,
  },
    props: {
      showModal: Boolean,
      data: null,
    },
    watch: {
      showModal: {
        handler(newVal) {
          this.forms = {
            order_no_new : this.data.order_no,
          };
          this.remark_dest_customer="",
          this.loadLookupCode();
          this.active = newVal;
          const body = document.querySelector("body");
          this.showModal
            ? body.classList.add("modal-open")
            : body.classList.remove("modal-open");
        },
        immediate: true,
        deep: true,
      },
    },
    data() {
      return {
        order_no_new:"",
        opens:"center",
        isLoading: false,
        active: this.showModal,
        forms: [],
        errors: [],
        type_error: [],
        remark_dest_customer:"",
        fetchLookupCode:[],
      };
    },
    methods: {
      resetForm() {
        this.forms = [];
      },


      loadLookupCode() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup/index-view?unique_id=custan`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchLookupCode = response.data.datas.data;

      });
    },

      asyncLookupCode(ev) {
        this.fetchLookupCode = [];
        const baseURI =
          this.$settings.endPointCt + `master-lookup/index-view?unique_id=custan&value=` + ev;
        return this.$http.get(baseURI).then((response) => {
          this.fetchLookupCode = response.data.datas.data;

        });
      },

  
      submitData() {
        this.fade(true);
      
        let baseURI = this.$settings.endPoint + "control-tower/transport-order-header/update-order-no/"+this.data.transport_order_header_id;
        this.forms.remark_dest_customer=this.remark_dest_customer.value,
        this.$http
          .post(baseURI, this.forms)
          .then((response) => {
            this.fade(false);
            if (response.data.status === 200) {
              //this.resetForm();
              this.errors = [];
  
              this.loadSuccess(response.data.datas.messages);
              this.$emit("closeModal");
            } else {
              this.errors = response.data.errors;
              this.resultError(response.data.errors);
            }
          })
          .catch((error) => {
            this.fade(false);
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
            //this.resetForm();
          });
      },
  
      fade(sType) {
        this.isLoading = sType;
      },
  
      loading() {
        this.fade(true);
        setTimeout(() => {
          this.fade(false);
        }, 1000); // hide the message after 3 seconds
      },
  
      resultError(data) {
        var html = "";
        var count = Object.keys(data).length;
        for (var x = 0; x < count; x++) {
          var nameOb = Object.keys(data)[x];
          var objectData = data[nameOb];
          for (var y = 0; y < objectData.length; y++) {
            html += objectData[y] + "<br>";
          }
        }
  
        this.loadError(html);
      },
  
      loadError(pesan) {
        this.$swal({
          backdrop: true,
          title: "Warning",
          html: pesan,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      },
  
      loadSuccess(pesan) {
        this.$swal({
          backdrop: true,
          title: "Success",
          html: pesan,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      },
    },
    mounted() {
      //tail(this.data);
    },
  };
  </script>
  
  <style scoped>
  .modal {
    background: rgba(0, 0, 0, 0.4);
  }
  
  .modal-content {
    background: #fff;
    border-radius: 0px;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 0px;
    width: 100%;
    height: 100%;
    margin-left:0px !important;
  }
  </style>