<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>   
    <div class="loadertext" v-if="this.isLoading"> {{$t('loadingTxt')}}...</div>   
    <div class="main-wrapper" :class="{'blur-content': this.isLoading}">
      <!--begin::sidebar-->
      <sidebar-component classMenu="FakturPajakIndex"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">

          <div class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            ">
            <div>
              <h4 class="mb-12 mb-md-0"></h4>
            </div>

            <div class="col-lg-12 col-xl-12 stretch-card">
              <div class="card">

                <div class="card-header">
                  <h4 style="margin-right: 5px" class="float-start">{{ $t("FakturPajakTxt") }}</h4>
                  <button v-if="permision_role.method_create" class="btn btn-primary float-end btn-xs"
                    style="margin-right: 5px" @click.prevent="createData()">
                    <i class="link-icon" data-feather="plus"></i>
                    {{ $t("createNew") }}
                  </button>

                  <button v-if="permision_role.method_upload" class="btn btn-warning float-end btn-xs"
                    style="margin-right: 5px" @click.prevent="uploadData()">
                    <i class="link-icon" data-feather="upload"></i>
                    {{ $t("uploadData") }}
                  </button>


                  <button v-if="permision_role.method_read" class="btn btn-success float-end btn-xs"
                    style="margin-right: 5px" @click.prevent="downloadData()">
                    <i class="link-icon" data-feather="download"></i>
                    {{ $t("downloadData") }} .xlsx
                  </button>
                </div>

                <div class="card-body">
                  <vue-good-table title="list-user" mode="remote" @on-page-change="onPageChange"
                    :select-options="{ enabled: true }"
                    @on-selected-rows-change="selectionChanged" 
                    @on-sort-change="onSortChange" 
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange" 
                    :totalRows="totalRecords" 
                    :line-numbers="true"
                    :isLoading.sync="isLoading" :pagination-options="{
                      enabled: true,
                    }" :rows="rows" :columns="columns">

                    <div slot="selected-row-actions">
                      <button class="btn btn-success btn-xs" @click="downloadFiles"> Download Files <i class="mdi mdi-send-outline"></i></button>
                    </div>

                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'actions'">
                        <a href="#" v-if="permision_role.method_read" @click.prevent="downloadFile(props.index, props.row)"
                          title="Download File">
                          <i class="mdi mdi-download" style="font-size:16px"></i>
                        </a>
                        <a href="#" v-if="permision_role.method_read" title="Detail" @click.prevent="
                          detailData(props.index, props.row)
                        ">
                          <i class="mdi mdi-eye" style="font-size:16px"></i>

                        </a>
                        <a href="#" v-if="permision_role.method_update" title="Edit"
                          @click.prevent="editData(props.index, props.row)">
                          <i class="mdi mdi-square-edit-outline" style="font-size:16px"></i>

                        </a>

                        <a href="#" v-if="permision_role.method_delete" title="Delete" @click.prevent="
                          deleteData(props.index, props.row)
                        ">
                          <i class="mdi mdi-trash-can-outline" style="font-size:16px"></i>
                        </a>
                      </span>
                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
 

export default {
  name: "FakturPajakIndex",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      baseURI: "",
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      permision_role: [],
      tax_slip_ids:[],
      langs: ["id", "en"],
      detailUser: [],
      detailDivisi: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [
        {
          label: "ID Pemotong",
          field: "id_pemotong",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ID Pemotong", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Nama Pemotong",
          field: "nama_pemotong",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Nama Pemotong", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "ID Dipotong",
          field: "id_dipotong",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ID Dipotong", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Nama Dipotong",
          field: "nama_dipotong",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Nama Dipotong", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: "Pasal",
          field: "pasal",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Pasal", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: "Kode Objek Pajak",
          field: "kode_objek_pajak",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Kode Objek Pajak", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: "No Bukti Potong",
          field: "no_bukti_potong",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By No Bukti Potong", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Tanggal Bukti Potong",
          field: "tanggal_bukti_potong",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Tanggal Bukti Potong", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "PPH Dipotong",
          field: "pph_dipotong",
          type: "number",
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By PPH Dipotong", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input            
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Jumlah Bruto",
          field: "jumlah_bruto",
          formatFn: this.formatFn,
          type: "number",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Jumlah Bruto", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup

          },
        },
        {
          label: "Action",
          field: "actions",
          sortable: false,
        },
      ],
      rows: [],
    };
  },
  watch: {},
  methods: {

    selectionChanged(ev){
      this.rowsSelection=[];
      this.tax_slip_ids=[];
      this.rowsSelection=ev.selectedRows;

      this.rowsSelection.forEach((item) => {
        this.tax_slip_ids.push(item.tax_slip_id);
        // if(item.order_status=='NEW'){
        //   this.error('Order No:'+item.order_status+' Status Not Submited, because status order not NEW or SUBMITED');
        // }else{
          
        // }
        
      });

    },
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      //this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == 'FakturPajakController' || permision.permission.controller == 'FakturPajakUserController') {
          rolePermision = permision;
          //console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;

    },

    formatFn: function (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      //return parseFloat(value).toFixed(2)
    },

    downloadData() {
      if (this.detailUser.role.name == 'user_faktur_pajak') {
        this.baseURI = this.$settings.endPointFP + "finance/user/faktur-pajak/index";
      } else {
        this.baseURI = this.$settings.endPointFP + "finance/root/faktur-pajak/index";
      }
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = "download_" + CurrentDate + ".xlsx";

      this.isLoading = true;


      return this.$http
        .get(
          this.baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&nama_dipotong=${this.serverParams.columnFilters.nama_dipotong}&pasal=${this.serverParams.columnFilters.pasal}&kode_objek_pajak=${this.serverParams.columnFilters.kode_objek_pajak}&no_bukti_potong=${this.serverParams.columnFilters.no_bukti_potong}&tanggal_bukti_potong=${this.serverParams.columnFilters.tanggal_bukti_potong}&pph_dipotong=${this.serverParams.columnFilters.pph_dipotong}&jumlah_bruto=${this.serverParams.columnFilters.jumlah_bruto}&id_pemotong=${this.serverParams.columnFilters.id_pemotong}&nama_pemotong=${this.serverParams.columnFilters.nama_pemotong}&id_dipotong=${this.serverParams.columnFilters.id_dipotong}&download=download`,
          {
            responseType: "blob",
            timeout: 30000
          }
        )

        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.isLoading = false;
        }).catch(error => {
          this.isLoading = false;
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                      this.errors = error.response.data.errors;
                      this.resultError(error.response.data.errors);
              }else if (error.response.status === 500) {
                  this.$router.push('/server-error');
              }else{
                  this.$router.push('/page-not-found');
              }
            }

          }

        });
    },

    createData() {
      window.location.href = "/faktur-pajak/add";
    },

    uploadData() {
      window.location.href = "/faktur-pajak/upload";
    },

    editData(index, row) {
      var params = this.$onRandom(row.tax_slip_id);
      window.location.href = "/faktur-pajak/edit/" + params;
    },

    detailData(index, row) {
      var params = this.$onRandom(row.tax_slip_id);
      window.location.href = "/faktur-pajak/detail/" + params;
    },

    /*async downloadFile(index, row) {
      const response = await this.$http.get(row.link_file,{
            responseType: 'blob'});
      // console.log(response)
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = row.file_name;
      link.click();
      URL.revokeObjectURL(link.href);
    },*/
    downloadFile(index, row) {
      var UrlNya = ""
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      if (this.detailUser.role.name == 'user_faktur_pajak') {
        UrlNya = this.$settings.endPointFP + "finance/user/faktur-pajak/download-files/"+row.tax_slip_id;
      } else {
        UrlNya = this.$settings.endPointFP + "finance/root/faktur-pajak/download-files/"+row.tax_slip_id;
      }

      return this.$http
        .get(
          UrlNya
          , {
            responseType: "blob"
          }
        ).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", row.file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        }).catch((error) => {
            this.errors = error.response.data.errors;
            this.error(error.response.data.errors.messages);
        });
    },

    downloadFiles() {
      var datas   = this.tax_slip_ids.join(",");
      var UrlNya  = ""

      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      if (this.detailUser.role.name == 'user_faktur_pajak') {
        UrlNya = this.$settings.endPointFP + "finance/user/faktur-pajak/download-files/"+datas;
      } else {
        UrlNya = this.$settings.endPointFP + "finance/root/faktur-pajak/download-files/"+datas;
      }
      return this.$http
        .get(
          UrlNya
          , {
            responseType: "blob"
          }
        ).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "Files.zip");
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        }).catch((error) => {
            this.errors = error.response.data.errors;
            this.error(error.response.data.errors.messages);
        });

    },
    deleteData(index, row, status) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          this.baseURI =
            this.$settings.endPointFP + "finance/root/faktur-pajak/delete/" + row.tax_slip_id;
          this.$http
            .delete(this.baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    // load items is what brings back the rows from server
    loadItems() {
      this.isLoading = true;
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      if (this.detailUser.role.name == 'user_faktur_pajak') {
        this.baseURI = this.$settings.endPointFP + "finance/user/faktur-pajak/index";
      } else {
        this.baseURI = this.$settings.endPointFP + "finance/root/faktur-pajak/index";
      }

      return this.$http 
        .get(
          this.baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&nama_dipotong=${this.serverParams.columnFilters.nama_dipotong}&pasal=${this.serverParams.columnFilters.pasal}&kode_objek_pajak=${this.serverParams.columnFilters.kode_objek_pajak}&no_bukti_potong=${this.serverParams.columnFilters.no_bukti_potong}&tanggal_bukti_potong=${this.serverParams.columnFilters.tanggal_bukti_potong}&pph_dipotong=${this.serverParams.columnFilters.pph_dipotong}&jumlah_bruto=${this.serverParams.columnFilters.jumlah_bruto}&id_pemotong=${this.serverParams.columnFilters.id_pemotong}&nama_pemotong=${this.serverParams.columnFilters.nama_pemotong}&id_dipotong=${this.serverParams.columnFilters.id_dipotong}`,{ timeout: 10000 }
        )
        .then((response) => {
          this.isLoading = false;
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
        }).catch(error => {
          this.isLoading = false;
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                      this.errors = error.response.data.errors;
                      this.resultError(error.response.data.errors);
              }else if (error.response.status === 500) {
                  this.$router.push('/server-error');
              }else{
                  this.$router.push('/page-not-found');
              }
            }

          }

        });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },


    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },
  },
  events: {},
  created: function () { },
  mounted() {
    this.fetchIt();
    this.loading();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}
.blur-content{
  filter: blur(5px); 
}
</style>