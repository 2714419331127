<template>
    
    <div class="row">
       

      <vue-good-table
        title="list-of-orders"
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :line-numbers="true"
        :isLoading.sync="isLoading"
        :pagination-options="{
          enabled: false,
        }"
        :filterOptions="{
          enabled: false}" 
        :rows="rowsTableOrder"
        :columns="columnsOrder"
        :sort-options="{
          enabled: false,
        }"
      >
        <template slot="table-row" slot-scope="props">
        
  
          <span >
            {{ props.formattedRow[props.column.field] }}
          </span>
          
        </template>
      </vue-good-table>
  
      <!-- <modal-trip-analysis  
        :showModal="showModalNow"
        :data="detailTripAnalysis"
        :vehicle_no="vehicle_no"
        @closeModal="closeMyModal"
      ></modal-trip-analysis> -->
  
  
  
    </div>
  
  
  </template>
  
  
  <script>
//   import modalTripAnalysis from "@/components/modal/modalTripAnalysis.vue";
  
  export default {
    name: "accidentHistoryComponent",
    props: {
      detail: [],
    },
    // components: {
    //   "modal-trip-analysis":  modalTripAnalysis ,
    //   // DirectionsRenderer,
    // },
    watch: {
    
      detail: function(val) {
        this.loadTable(val)
      },
    
    },
    data() {
      return {
        //vehicle_no:this.$route.params.vehicle_no,
        detailTripAnalysis:"",
        showModalNow: false,
        isLoading: false,
        totalRecords: 0,
        serverParams: {
          columnFilters: {},
          sort: {
            field: "",
            type: "",
          },
          page: 1,
          per_page: 10,
        },
        rowsTableOrder: [],
        columnsOrder: [
          {
            label: "Shipment Date",
            field: "shipment_date",
          },
  
          {
            label: "Load Number",
            field: "load_number",
          },

          {
            label: "Vehicle No",
            field: "vehicle_no",
          },

          {
            label: "Accident Date",
            field: "accident_date",
          },

          {
            label: "Accident Location",
            field: "accident_location",
          },

          {
            label: "Latitude",
            field: "latitude",
          },

          {
            label: "Longitude",
            field: "longitude",
          },

          {
            label: "Accident Rate",
            field: "accident_rate",
          },
          
          {
            label: "Accident Detail",
            field: "accident_detail",
          },

          {
            label: "Caused",
            field: "caused",
          },
  
        ],
        //
      };
    },
    methods: {
  
      loadTable(val){
          this.rowsTableOrder=val;
      },
  
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
  
      onColumnFilter(params) {
        params.page = 1;
        this.updateParams(params);
      },
  
      onPageChange(params) {
        this.updateParams({ page: params.currentPage });
      },
  
      onSortChange(params) {
        this.updateParams({
          sort: {
            type: params[0].type,
            field: params[0].field,
          },
        });
      },
  
  
      EditFormModal(data) {
        this.detailTripAnalysis=data;
        this.showModalNow = !this.showModalNow;
      },
  
      closeMyModal() {
        this.showModalNow = false;
        this.$emit("afterSaveTripAnalysis");
        //this.loadData(this.vehicle_no);
      },
  
      onPerPageChange(params) {
        this.updateParams({ per_page: params.currentPerPage });
      },
    },
  };
  </script>
  