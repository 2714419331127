import MarginList from "@/components/master/margin_list/MarginList.vue";
import MarginListAdd from "@/components/master/margin_list/MarginListAdd.vue";
import MarginListDetail from "@/components/master/margin_list/MarginListDetail.vue";
import MarginListEdit from "@/components/master/margin_list/MarginListEdit.vue";
import MarginListUpload from "@/components/master/margin_list/MarginListUpload.vue";

let routesMarginList= [
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "MarginList",
        component: MarginList
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "MarginListAdd",
        component: MarginListAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "MarginListEdit",
        component: MarginListEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "MarginListDetail",
        component: MarginListDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "MarginListUpload",
        component: MarginListUpload
      },
    
]

export default routesMarginList
  