<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TransportOrderAdd"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
            <div class="card">
              <div class="card-header">
                <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                &nbsp;&nbsp;&nbsp;
                <h6 class="card-title mb-0 float-start">
                  Transport Order Multiple Add
                </h6>
                <button class="btn btn-default btn-sm float-end" @click="resetForm" type="button">
                  <i class="link-icon" data-feather="repeat"></i>
                  {{ $t("resetFormTxt") }}
                </button>
              </div>
              <div class="card-body">
                <!--begin::loading-data-->
                <!-- <div v-if="isLoading" class="d-flex align-items-center">
                  <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                  <strong>{{ $t("loadingTxt") }}...</strong>
                </div> -->
                <!--end::loading-data-->

                <form class="forms-sample" @submit.prevent="submitData" method="POST">
                  <div class="
                            d-flex
                            justify-content-between
                            align-items-baseline
                            mb-2
                          "></div>

                  <div class="row card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="mb-3">
                            <label class="form-label">Company Code <span class="wajib">*</span></label>
                            <!-- <v-select v-if="companyCode == 'LINC'" :options="fetchCompany" v-model="company" label="code"
                              return-object @input="getLocation" @search="asyncSearchCompany"></v-select>

                            <v-select v-if="companyCode != 'LINC'" :options="fetchCompanyNotLinc" v-model="company"
                              label="code" return-object @input="getLocation"
                              @search="asyncSearchCompanyNotLinc"></v-select> -->

                              <multiselect  v-if="companyCode == 'LINC'" @search-change="asyncSearchCompany" v-model="company" :options="fetchCompany"
                                  label="code" track-by="code"  @input="getLocation"
                                  placeholeder="Please Select Company">
                                  <span slot="noResult">-</span>
                                </multiselect>

                                <multiselect  v-if="companyCode != 'LINC'" @search-change="asyncSearchCompanyNotLinc" v-model="company" :options="fetchCompanyNotLinc"
                                  label="code" track-by="code"  @input="getLocation"
                                  placeholeder="Please Select Company">
                                  <span slot="noResult">-</span>
                                </multiselect>


                          </div>

                          <div v-if="errors.company_id">
                            <div v-for="error in errors.company_id" :key="error" class="alert alert-primary" role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <!-- Col -->
                        <!-- <div class="col-sm-3">
                            <div class="mb-3">
                              <label class="form-label ">Order No <span class="wajib">*</span></label>
                              <input
                                type="text"
                                id="order_no"
                                name="order_no"
                                v-model="forms.order_no"
                                class="form-control"
                                placeholder="Order No"
                                
                              />
                              <div
                                v-for="error in errors.order_no"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div> -->
                        <!-- Col -->

                        <div class="col-sm-4">
                          <div class="mb-3">
                            <label class="form-label">Order Date <span class="wajib">*</span></label>
                            <!-- <Datepicker :highlighted="highlighted" v-model="forms.order_date" :bootstrap-styling=true
                              format="dd MMM yyyy" :disabledDates="disabledDates" placeholder="Please Select Date..">
                            </Datepicker> -->
                            <date-range-picker 
                              style="width:100%" 
                              control-container-class="form-control" 
                              :locale-data="locale" 
                              v-model="order_date"
                              :opens="opens" 
                              :singleDatePicker="true" 
                              :timePicker24Hour="true" 
                              :timePicker="true"
                              :date-format="dateFormatOrder"
                              :showWeekNumbers="false">
                            </date-range-picker>
                            <!-- <input type="text" id="order_date" name="order_date" v-model="forms.order_date" class="form-control" placeholder="Order Date"> -->
                            <div v-for="error in errors.order_date" :key="error" class="alert alert-primary" role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-4">
                          <div class="mb-3">
                            <label class="form-label">Branch / Truck Type <span class="wajib">*</span></label>
                                <multiselect  @search-change="asyncSearchTemplateOrder" v-model="template_order" :options="fetchTemplateOrder"
                                  label="description" track-by="description"  @input="getPickUpLocation"
                                  placeholeder="Please Select">
                                  <span slot="noResult">-</span>
                                </multiselect>
                            <!-- <v-select :options="fetchTemplateOrder" v-model="template_order" label="description"
                              return-object @search="asyncSearchTemplateOrder"  @input="getPickUpLocation"></v-select> -->

                            <!-- <input type="text" id="order_date" name="order_date" v-model="forms.order_date" class="form-control" placeholder="Order Date"> -->
                            <div v-for="error in errors.template_order_id" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12">
                          <div class="mb-3">
                            <label class="form-label">Remarks</label>
                            <textarea id="remark" name="remark" v-model="forms.remarks" class="form-control"
                              placeholder="Remark"></textarea>
                            <div v-for="error in errors.remarks" :key="error" class="alert alert-primary" role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>


                      

                      <div class="row">
                        <div class="col-sm-3">
                          <div class="mb-3">
                            <label class="form-label">Shipwith Ref <span class="wajib">*</span></label>
                            <input readonly type="text" id="shipwith_ref" name="shipwith_ref" v-model="forms.shipwith_ref"
                              class="form-control" placeholder="Shipwith Ref" />
                            <div v-for="error in errors.shipwith_ref" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-3">
                          <div class="mb-3">
                            <label class="form-label">Tonnage</label>
                            <input type="number" id="tonnage" name="tonnage" v-model="forms.tonnage" class="form-control"
                              placeholder="Tonnage" readonly />
                            <div v-for="error in errors.tonnage" :key="error" class="alert alert-primary" role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>


                        <div class="col-sm-3">
                          <div class="mb-3">
                            <label class="form-label">Cubic</label>
                            <input type="number" id="cubic" name="cubic" v-model="forms.cubic" class="form-control"
                              placeholder="Cubic" readonly />
                            <div v-for="error in errors.cubic" :key="error" class="alert alert-primary" role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>


                        <div class="col-sm-3">
                            <div class="mb-3">
                              <label class="form-label">Shipment Rate</label>
                              <div >
                                <div class="form-check form-check-inline">
                              <input @input="changeShipmentRate('Sameday')" type="radio" v-model="forms.shipment_rate" value="Sameday" class="form-check-input" name="shipment_rate" id="shipment_rate">
                              <label class="form-check-label" for="shipment_rate">
                                Sameday
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input @input="changeShipmentRate('Regular')" type="radio" v-model="forms.shipment_rate"  value="Regular" class="form-check-input" name="shipment_rate" id="shipment_rate">
                              <label class="form-check-label" for="shipment_rate">
                                Regular
                              </label>
                            </div>
                              </div>
                              <div
                                v-for="error in errors.cubic"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                        </div>




                      </div>
                    </div>

                    <!-- Col -->
                  </div>


                  <!-- Row -->
                  <br />

                  <div class="row">
                    <div class="col-sm-6">
                      <div class="card">
                        <div class="card-header">
                          <h5>Pickup </h5>
                        </div>

                        <div class="card-body">
                          <div class="row">
                            <!-- <div class="col-sm-6">
                              <div class="mb-3">
                                <label class="form-label">Pickup Location <span class="wajib">*</span></label>
                                <v-select :options="fetchLocation" v-model="location" label="name"
                                  @search="asyncSearchLocation" @input="getLocationPickup">

                                  <template slot="option" slot-scope="option">

                                    {{ option.location_code }} | {{ option.name }}
                                  </template>

                                  <template slot="selected-option" slot-scope="option">

                                    {{ option.location_code }} | {{ option.name }}
                                  </template>

                                </v-select>
                              </div>
                            </div> -->

                            <div class="col-sm-6">
                              <div class="mb-3">
                                <label class="form-label">Pickup Plan <span class="wajib">*</span></label>

                                <date-range-picker style="width:100%" :date-format="dateFormat" @change="pickupChange"
                                  control-container-class="form-control" :locale-data="locale" v-model="pickupDates"
                                  :opens="opens" :singleDatePicker="true" :timePicker24Hour="true" :timePicker="true"
                                  :showWeekNumbers="false" @update="pickDateChange">

                                </date-range-picker>

                               
                                <div v-for="error in errors.pick_plan" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <div class="row" v-if="pickup_address">
                            <div class="col-sm-6">
                              <p class="text-muted">
                                <b>{{ forms.pick_name }}</b>
                                <i class="mdi mdi-map-marker"></i>Lat.{{
                                  forms.pick_latitude
                                }}
                                Long.{{ forms.pick_longitude }} <br />
                                {{ forms.pick_address1 }}
                                {{ forms.pick_address2 }}
                                {{ forms.pick_address2 }}
                                {{ forms.pick_sub_district }},
                                {{ forms.pick_district }},
                                {{ forms.pick_city }},
                                {{ forms.pick_province }}
                                {{ forms.pick_postal_code }},
                                {{ forms.pick_country }}<br />
                                {{ forms.pick_contact_name }} -
                                {{ forms.pick_phone_number }}
                              </p>
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="card">
                        <div class="card-header">
                          <h5>Destination</h5>
                        </div>

                        <div class="card-body">
                          <div class="row">
                            <!-- <div class="col-sm-6">
                              <div class="mb-3">
                                <label class="form-label">Destination Location <span class="wajib">*</span></label>
                                <v-select :options="fetchLocationDest" v-model="location_dest" label="name"
                                  persistent-hint @search="asyncSearchLocationDest" @input="getLocationDest">


                                  <template slot="option" slot-scope="option">

                                    {{ option.location_code }} | {{ option.name }}
                                  </template>

                                  <template slot="selected-option" slot-scope="option">

                                    {{ option.location_code }} | {{ option.name }}
                                  </template>
                                </v-select>
                              </div>
                            </div> -->

                            <div class="col-sm-6">
                              <div class="mb-3">
                                <label class="form-label">Destination Plan <span class="wajib">*</span></label>
                              
                                <date-range-picker style="width:100%" :locale-data="locale" :date-format="dateFormatDest"
                                  v-model="destDates" :opens="opens" :singleDatePicker="true" :timePicker24Hour="true"
                                  :timePicker="true" @update="destDateChange">

                                </date-range-picker>

                               
                                <div v-for="error in errors.dest_plan" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- <div class="row" v-if="destination_address">
                            <div class="col-sm-6">
                              <p class="text-muted">
                                <b>{{ forms.dest_name }}</b>
                                <i class="mdi mdi-map-marker"></i>Lat.{{
                                  forms.dest_latitude
                                }}
                                Long.{{ forms.dest_longitude }} <br />
                                {{ forms.dest_address1 }}
                                {{ forms.dest_address2 }}
                                {{ forms.dest_address2 }}
                                {{ forms.dest_sub_district }},
                                {{ forms.dest_district }},
                                {{ forms.dest_city }},
                                {{ forms.dest_province }}
                                {{ forms.dest_postal_code }},
                                {{ forms.dest_country }}<br />
                                {{ forms.dest_contact_name }} -
                                {{ forms.dest_phone_number }}
                              </p>
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div> 

                  <br>

                  <div class="row card">
                    <div class="card-header">
                      <h5 class="float-start">Order Location<span class="wajib">*</span></h5>

                      <button class="
                              btn btn-success btn-icon
                              text-white
                              fw-bolder
                              btn-icon-text
                              float-end
                            " @click="addFieldOrderLocation()" type="button"> <i class="link-icon" data-feather="plus"></i>
                      </button>


                    </div>


                    <div class="card-body">
                      <div class="table-responsive">
                        <table class="table table-hover" style="height: 300px">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th width="300px">Order No</th>
                              <th width="200px">Pickup Location</th>
                              <!-- <th width="200px">Pickup Plan</th> -->
                              <th width="500px">Dest Location</th>
                              <th width="300px">Tonnage</th>
                              <th width="300px">Cubic</th>
                              <!-- <th width="200px">Dest Plan</th> -->
                              <th width="400px">File</th>

                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(input, index) in this.orderLocation" :key="`phoneInput-${index}`">
                              <th>{{ index + 1 }}</th>
                              <td width="200px">

                                <input type="text" id="order_no" name="order_no" v-model="input.order_no" class="form-control" disabled
                               />
                                <!-- <multiselect @search-change="asyncSearchSKU" v-model="input.sku" :options="fetchSKU"
                                  @input="cekData(index, detailAll)" label="sku_code" track-by="sku_code"
                                  placeholede="Please Select SKU">
                                  <span slot="noResult">-</span>
                                </multiselect> -->


                              </td>
                              <td width="300px">
                                <multiselect @search-change="asyncSearchLocation" v-model="input.pick_location_id" :options="fetchLocation"
                                  @input="getLocationPickupDetail(index,$event,orderLocation)" label="name" track-by="location_code" :custom-label="customLabel"
                                  placeholede="Please Select">
                                  <span slot="noResult">-</span>
                                </multiselect>

                               
                              </td>
                              <!-- <td width="200px">
                                <date-range-picker style="width:100%" :date-format="dateFormat" @change="pickupChange(index,$event,orderLocation)"
                                  control-container-class="form-control" :locale-data="locale" v-model="input.pick_plan"
                                  :opens="opens" :singleDatePicker="true" :timePicker24Hour="true" :timePicker="true"
                                  :showWeekNumbers="false">

                                </date-range-picker>
                              </td> -->

                             
                              <td>
                                <!-- <v-select :options="fetchLocationDest" v-model="input.dest_location_id" label="name"
                                  persistent-hint @search="asyncSearchLocationDest" @input="getLocationDestDetail(index,$event,orderLocation)">
                                  
                                  <template slot="option" slot-scope="option">
                                    {{ option.location_code }} | {{ option.name }}
                                  </template>

                                  <template slot="selected-option" slot-scope="option">
                                    {{ option.location_code }} | {{ option.name }}
                                  </template>
                                </v-select> -->

                                <multiselect style="min-width:500px" @search-change="asyncSearchLocationDest($event,index,orderLocation)" v-model="input.dest_location_id" 
                                :options="fetchLocationDest" 
                                @open="asyncSearchLocationDest($event,index,orderLocation)"
                                  @input="getLocationDestDetail(index,$event,orderLocation)" label="name" track-by="location_code" :custom-label="customLabel"
                                  placeholeder="Please Select">
                                  <span slot="noResult">-</span>
                                </multiselect>

                              </td>
                              <!-- <td width="200px">
                                
                                <date-range-picker style="width:100%" :locale-data="locale" :date-format="dateFormatDest"
                                  v-model="input.dest_plan" :opens="opens" :singleDatePicker="true" :timePicker24Hour="true"
                                  :timePicker="true" @change="destChange(index,$event,orderLocation)">

                                </date-range-picker>

                              </td> -->
                              <td width="300px">
                                <input type="number" id="tonnage" name="tonnage" v-model="input.tonnage" class="form-control" required/>
                              </td>

                              <td width="300px">
                                <input type="number" id="cubic" name="cubic" v-model="input.cubic" class="form-control" required/>
                              </td>

                              <td width="200px">

                                <input
                                      type="file"
                                      ref="images"
                                     
                                      class="form-control"
                                      @change="handleFileUpload(index,$event,orderLocation)"
                                    />
                                    <!-- <button
                                      type="button"
                                      class="dropify-clear"
                                      @click="removeImages"
                                    >
                                      Remove
                                    </button> -->

                              </td>
                                
                              <td>


                            <!--          Remove Svg Icon-->
                            <svg @click="removeField(index, orderLocation)" xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24" width="24" height="24" class="ml-2 cursor-pointer">
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path fill="red"
                                d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                            </svg>
                            </td>
                            </tr>
                          </tbody>

                          <tfoot></tfoot>
                        </table>
                      </div>
                    </div>
                  </div>


                  <br />

                  <div class="row card">
                    <div class="card-header">
                      <h5 class="float-start">Detail <span class="wajib">*</span></h5>

                      <button class="
                              btn btn-success btn-icon
                              text-white
                              fw-bolder
                              btn-icon-text
                              float-end
                            " @click="addField()" type="button"> <i class="link-icon" data-feather="plus"></i>
                      </button>

                    </div>


                    <div class="card-body">
                      <div class="table-responsive">
                        <table class="table table-hover" style="height: 300px">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th width="500px">Order No</th>
                              <th width="300px">SKU</th>
                              <th width="200px">SKU Description</th>
                              <th>Qty</th>
                              <th>Package Type</th>
                              <th width="300px">Lading Type</th>

                              <th>Lading Length</th>
                              <th>Lading Width</th>
                              <th>Lading Height</th>


                              <th>Volume</th>
                              <th>Weight</th>
                              <th>Net Weight</th>
                              <th>Lading Qty</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(input, index) in this.detailAll" :key="`phoneInput-${index}`">
                              <th>{{ index + 1 }}</th>
                              <td width="500px">


                                <v-select :options="orderLocation" v-model="input.order_no" label="order_no"
                                  return-object></v-select>


                                </td>
                              <td width="200px">


                                <multiselect @search-change="asyncSearchSKU" v-model="input.sku" :options="fetchSKU"
                                  @input="cekData(index, detailAll)" label="sku_code" track-by="sku_code"
                                  placeholede="Please Select SKU">
                                  <span slot="noResult">-</span>
                                </multiselect>


                              </td>
                              <td width="200px">{{ input.sku_description }}</td>
                              <td>
                                <input type="number" v-model="input.qty" class="form-control" style="width: 100px" />
                              </td>

                              <td>
                                <v-select :options="fetchpackageType" v-model="input.package_type" label="package_type"
                                  return-object></v-select>

                              </td>

                              <td width="200px">
                                <v-select :options="fetchladingType" v-model="input.lading_type" label="package_type"
                                  return-object @input="cekQty(index, detailAll)"></v-select>
                              </td>



                              <td>{{ input.lading_length }}</td>
                              <td>{{ input.lading_width }}</td>
                              <td>{{ input.lading_height }}</td>


                              <td>{{ input.volume }}</td>
                              <td>{{ input.weight }}</td>
                              <td>{{ input.net_weight }}</td>
                              <td>{{ input.lading_qty }}</td>
                              <td>


                                <!--          Remove Svg Icon-->
                                <svg @click="removeField(index, detailAll)" xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24" width="24" height="24" class="ml-2 cursor-pointer">
                                  <path fill="none" d="M0 0h24v24H0z" />
                                  <path fill="red"
                                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                </svg>
                              </td>
                            </tr>
                          </tbody>

                          <tfoot></tfoot>
                        </table>
                      </div>
                    </div>
                  </div>


                  <div class="card-footer">
                    <button class="
                              btn btn-warning
                              text-black
                              fw-bolder
                              btn-icon-text
                            " @click="backForm" type="button">
                      <i class="link-icon" data-feather="arrow-left"></i>
                      {{ $t("backMess") }}
                    </button>

                    &nbsp;&nbsp;&nbsp;
                    <button v-if="!isLoading" class="btn btn-success float-end btn-sm" type="submit">
                      <i class="link-icon" data-feather="save"></i>
                      {{ $t("submitOrderTxt") }}
                    </button>

                    <div v-if="isLoading" class="d-flex align-items-center">
                      <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                      <strong>{{ $t("loadingTxt") }}...</strong>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <!--end::card-->
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
// import Datepicker from "vuejs-datepicker";
// import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'

import DateRangePicker from 'vue2-daterange-picker'
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import multiselect from "vue-multiselect";

export default {
  name: "TransportOrderAdd",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    // Datepicker,
    DateRangePicker,
    "multiselect": multiselect
    // DateRangePicker
  },
  data() {
    const startDate = new Date();
    const startDateDest = new Date();
    startDateDest.setDate(startDateDest.getDate() - 1);
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 6);

    return {
      disabledDates: {
        to: new Date(startDate.getTime() - 86400000)
      },
      opens: "center",
      locale: {
        format: "dd mmm yyyy H:MM:ss",

      },
      fetchOrderGroup:[],
      fetchBillableMethod:[],
      billable_method:"",
      order_group:"",
      pickupDates: {
        // startDate,
        // endDate,
      },
      destDates: {
        // startDate,
        // endDate,
      },
      order_date: {
        startDate,
        endDate
      },
      pickup_address: false,
      destination_address: false,
      fetchladingType: [],
      fetchpackageType: [],

      highlighted: {

        dates: [ // Highlight an array of dates
          new Date(),
        ],

      },
      detailAll: [

      ],
      orderLocation: [

      ],
      fetchSKU: [],
      fetchCompany: [],
      fetchTemplateOrder: [],
      fetchCompanyNotLinc: [],
      fetchLocation: [],
      fetchLocationDest: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      permision_role: [],
      userData: "",
      companyCode: "",
      company_name: "",
      company: [],
      template_order: [],
      location: [],
      location_dest: [],
      forms: { shipment_rate:'Regular' },
    };
  },
  watch: {},
  methods: {

    dateFormat(classes, date) {
      if (!classes.disabled) {
        if(this.forms.shipment_rate=='Regular'){
          classes.disabled = date.getTime() < new Date();
        }else if(this.forms.shipment_rate=='Sameday'){
          // classes.disabled = (date.getTime() + 8640000) <= new Date(this.order_date.startDate);
          classes.disabled = (date.getTime() + 43200000) <= new Date();
        }
       
      }
      return classes
    },
    dateFormatDest(classes, date) {
      // console.log(this.pickupDates.startDate - 8640000);
      if (!classes.disabled) {
        if(this.forms.shipment_rate=='Regular'){
          classes.disabled = date.getTime() <  new Date() ;
        }else if(this.forms.shipment_rate=='Sameday'){
          // classes.disabled = (date.getTime() + 8640000) <= new Date(this.order_date.startDate);
          classes.disabled = (date.getTime() + 43200000) <= new Date();
        }
      }

      return classes
    },


    addFieldOrderLocation() {
      //fieldType.push({ value: "" });
      this.orderLocation.push({
        order_no: "",
        order_status: "",
        status_tms: "",
        pick_location_id: "",
        pick_name: "",
        pick_address1: "",
        pick_address2: "",
        pick_address3: "",
        pick_country: "",
        pick_city: "",
        pick_province: "",
        pick_district: "",
        pick_sub_district: "",
        pick_postal_code: "",
        pick_latitude: "",
        pick_longitude: "",
        pick_radius: "",
        pick_contact_name: "",
        pick_phone_number: "",
        // pick_plan: this.pickupDates,
        dest_location_id: "",
        dest_name: "",
        dest_address1: "",
        dest_address2: "",
        dest_address3: "",
        dest_country: "",
        dest_city: "",
        dest_province: "",
        dest_district: "",
        dest_sub_district: "",
        dest_postal_code: "",
        dest_latitude: "",
        dest_longitude: "",
        dest_radius: "",
        dest_contact_name: "",
        dest_phone_number: "",
        upload:"",
        tonnage: 0,
        cubic: 0,
        // dest_plan: this.destDates
      })
    },

    changeShipmentRate(ev){
      this.forms.shipment_rate=ev;
    
    },

    addField() {
      //fieldType.push({ value: "" });
      this.detailAll.push({
        order_no:"",
        sku: "",
        sku_description: '',
        qty: 0,
        lading_length: 0,
        lading_width: 0,
        lading_height: 0,
        lading_type: "",
        package_type: "",
        volume: 0,
        weight: 0,
        net_weight: 0,
        lading_qty: 0,
       
      })
    },


    handleFileUpload(index,event,fieldType) {
      fieldType[index].upload = event.target.files[0];
    },

    dateFormatOrder (classes, date) {
      if (!classes.disabled) {
          classes.disabled = (date.getTime() + 86400000) < new Date() ;   
      }

      return classes
    },



    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },

    customLabel(ev) {
      return `${ev.location_code} – ${ev.name}`
    },

    resetForm() {
      this.forms = "";
      this.company = "";
      this.location = "";
      this.location_dest = "";
      this.pickup_address = false;
      this.destination_address = false;
      this.template_order = "";
      this.detailAll = [
        {
          order_no:"",
          sku: [],
          qty: 0,
          lading_length: 0,
          lading_width: 0,
          lading_height: 0,
          lading_type: [],
          package_type: "-",
          volume: 0,
          weight: 0,
          net_weight: 0,
          lading_qty: 0,
        },
      ];
    },


    getBillabelandOrderGroup(ev){
      this.fetchBillableMethod=[];
      this.fetchOrderGroup=[];
      this.billable_method="";
      this.order_group="";
      var fetchBillableMethod=[];
      var fetchOrderGroup=[];

      var data=ev.transport_order_detail_template_multiple;
      for (let index = 0; index < data.length; index++) {
        // var dataBillableMethod={
        //   id:data[index]['billable_method'],
        //   name:data[index]['billable_method']
        // }

        // var dataOrderGroup={
        //   id:data[index]['order_group'],
        //   name:data[index]['order_group']
        // }

        fetchBillableMethod.push(data[index]['billable_method']);
        fetchOrderGroup.push(data[index]['order_group']);
      }
      this.fetchBillableMethod=[...new Set(fetchBillableMethod)];
      this.fetchOrderGroup=[...new Set(fetchOrderGroup)];
    },

    // orderDateChange(ev){
    //   var orderDate = moment(ev.startDate).format('YYYY-MM-DD HH:mm');
    //   var currentDate = moment(new Date()).format("YYYY-MM-DD HH:mm");
    //   if(orderDate < currentDate){
    //     this.order_date.startDate = currentDate;
    //     this.error(this.$t("validateOrderDate"));
    //   }
    // },

    pickDateChange(ev){
      var pickPlan = moment(ev.startDate).format('YYYY-MM-DD HH:mm');
      var currentDate = moment(new Date()).format("YYYY-MM-DD HH:mm");
      if(pickPlan < currentDate){
        this.pickupDates.startDate = currentDate;
        this.error(this.$t("validatePickDate"));
      }
    },
    destDateChange(ev){
      var destPlan = moment(ev.startDate).format('YYYY-MM-DD HH:mm');
      var currentDate = moment(new Date()).format("YYYY-MM-DD HH:mm");
      if(destPlan < currentDate){
        this.destDates.startDate = currentDate;
        this.error(this.$t("validateDestDate"));
      }
    },
    getPickUpLocation(ev) {
      this.forms.tonnage = ev.tonnage;
      this.forms.cubic = ev.cubic;
      this.fetchLocation=[];
      var transport_order_template_id = ev.transport_order_template_id;
      const baseURI =
        this.$settings.endPoint + "transport-order-template/pickup-multiple?transport_order_template_id="+transport_order_template_id+"";

      return this.$http.get(baseURI).then((response) => {
        var res=response.data.datas;
          var fetchLocation=[];
          for (let index = 0; index < res.length; index++) {

             
              fetchLocation.push(res[index].pickup);
            
          }


          this.fetchLocation = fetchLocation.reduce((accumulator,currentValue) => {
              const index = accumulator.findIndex(item => item.location_id === currentValue.location_id);
              if (index === -1) {
                accumulator.push(currentValue);
              } 
              return accumulator;
            
          }, []);

          //this.fetchLocation=[...new Set(fetchLocation)];
      });
     
    },


    getDestLocation(ev) {
      this.fetchLocationDest=[];
    
      var pickup_location_id=ev.location_code;
      var transport_order_template_id=this.template_order.transport_order_template_id;
      //http://localhost:8081/transport-order-template/dest-multiple?transport_order_template_id=33&pickup_location=2&dest_location=CEK
      const baseURI =
        this.$settings.endPoint + "transport-order-template/dest-multiple?transport_order_template_id="+transport_order_template_id+"&pickup_location="+pickup_location_id;

      return this.$http.get(baseURI).then((response) => {
        var res=response.data.datas;
        var fetchLocationDest=[]
          for (let index = 0; index < res.length; index++) {
              res[index].dest.billable_method=res[index].billable_method;
              res[index].dest.order_group=res[index].order_group;

              fetchLocationDest.push(res[index].dest); 
          }

          this.fetchLocationDest = fetchLocationDest.reduce((accumulator,currentValue) => {
              const index = accumulator.findIndex(item => item.location_id === currentValue.location_id);
              if (index === -1) {
                accumulator.push(currentValue);
              } 
              return accumulator;
            
          }, []);


      });
      
    },

    getLocation(ev) {
      // console.log(ev);
      this.template_order = [];
      this.loadSKU(ev.name);
      this.loadTemplateOrder(ev.name);
      // this.fetchLocation = [];
      // this.fetchLocationDest = [];
      // this.company_name = ev.name;
      // const baseURI =
      //   this.$settings.endPoint + "location?company_name=" + ev.name;

      // return this.$http.get(baseURI).then((response) => {
      //   this.fetchLocation = response.data.datas.data;
      //   this.fetchLocationDest = response.data.datas.data;
      //   this.loadSKU(this.company_name);
      //   this.loadTemplateOrder(this.company_name);
      // });
    },

    pickupChange(index,ev,orderLocation) {
      
      orderLocation[index].pick_plan = moment(String(ev.startDate)).format('YYYY-MM-DD HH:mm:ss');
      // console.log(orderLocation[index].pick_plan);
    },

    destChange(index,ev,orderLocation) {
      orderLocation[index].dest_plan = moment(String(ev.startDate)).format('YYYY-MM-DD HH:mm:ss');
    },

    

    


    getLocationPickup(ev) {
      this.forms.pick_name = ev.name;
      this.forms.pick_country = ev.country;
      this.forms.pick_province = ev.province;
      this.forms.pick_city = ev.city;
      this.forms.pick_district = ev.district;
      this.forms.pick_sub_district = ev.sub_district;
      this.forms.pick_postal_code = ev.postal_code;
      this.forms.pick_address1 = ev.address1;
      this.forms.pick_address2 = ev.address2;
      this.forms.pick_address3 = ev.address3;
      this.forms.pick_latitude = ev.latitude;
      this.forms.pick_longitude = ev.longitude;
      this.forms.pick_radius = ev.radius;
      this.forms.pick_contact_name = ev.contact_name;
      this.forms.pick_phone_number = ev.phone_number;
    },

    getLocationDest(ev) {
      this.destination_address = true;
      this.forms.dest_name = ev.name;
      this.forms.dest_country = ev.country;
      this.forms.dest_province = ev.province;
      this.forms.dest_city = ev.city;
      this.forms.dest_district = ev.district;
      this.forms.dest_sub_district = ev.sub_district;
      this.forms.dest_postal_code = ev.postal_code;
      this.forms.dest_address1 = ev.address1;
      this.forms.dest_address2 = ev.address2;
      this.forms.dest_address3 = ev.address3;
      this.forms.dest_latitude = ev.latitude;
      this.forms.dest_longitude = ev.longitude;
      this.forms.dest_radius = ev.radius;
      this.forms.dest_contact_name = ev.contact_name;
      this.forms.dest_phone_number = ev.phone_number;
    },

    getLocationDestDetail(index,ev,orderLocation) {
      //this.pickup_address = true;
      orderLocation[index].dest_name = ev.name;
      orderLocation[index].dest_country = ev.country;
      orderLocation[index].dest_province = ev.province;
      orderLocation[index].dest_city = ev.city;
      orderLocation[index].dest_district = ev.district;
      orderLocation[index].dest_sub_district = ev.sub_district;
      orderLocation[index].dest_postal_code = ev.postal_code;
      orderLocation[index].dest_address1 = ev.address1;
      orderLocation[index].dest_address2 = ev.address2;
      orderLocation[index].dest_address3 = ev.address3;
      orderLocation[index].dest_latitude = ev.latitude;
      orderLocation[index].dest_longitude = ev.longitude;
      orderLocation[index].dest_radius = ev.radius;
      orderLocation[index].dest_contact_name = ev.contact_name;
      orderLocation[index].dest_phone_number = ev.phone_number;

      orderLocation[index].billable_method = ev.billable_method;
      orderLocation[index].order_group = ev.order_group;

    },


    getLocationPickupDetail(index,ev,orderLocation) {
      this.getDestLocation(ev);
      //this.pickup_address = true;
      orderLocation[index].pick_name = ev.name;
      orderLocation[index].pick_country = ev.country;
      orderLocation[index].pick_province = ev.province;
      orderLocation[index].pick_city = ev.city;
      orderLocation[index].pick_district = ev.district;
      orderLocation[index].pick_sub_district = ev.sub_district;
      orderLocation[index].pick_postal_code = ev.postal_code;
      orderLocation[index].pick_address1 = ev.address1;
      orderLocation[index].pick_address2 = ev.address2;
      orderLocation[index].pick_address3 = ev.address3;
      orderLocation[index].pick_latitude = ev.latitude;
      orderLocation[index].pick_longitude = ev.longitude;
      orderLocation[index].pick_radius = ev.radius;
      orderLocation[index].pick_contact_name = ev.contact_name;
      orderLocation[index].pick_phone_number = ev.phone_number;

      

      
    },

    asyncSearchLocation(ev) {
      this.fetchLocation=[];

      var transport_order_template_id=this.template_order.transport_order_template_id;

      const baseURI =
        this.$settings.endPoint + "transport-order-template/pickup-multiple?transport_order_template_id="+transport_order_template_id+"&pickup_location="+ev;

      return this.$http.get(baseURI).then((response) => {
        var res=response.data.datas;

            var fetchLocation=[];
            for (let index = 0; index < res.length; index++) {

              
                fetchLocation.push(res[index].pickup);
              
            }


          this.fetchLocation = fetchLocation.reduce((accumulator,currentValue) => {
              const index = accumulator.findIndex(item => item.location_id === currentValue.location_id);
              if (index === -1) {
                accumulator.push(currentValue);
              } 
              return accumulator;
            
          }, []);
          //this.fetchLocation=[...new Set(fetchLocation)];
            
          
      });

    },

    asyncSearchLocationDest(ev,index,detailAll) {
      this.fetchLocationDest=[];
      var transport_order_template_id=this.template_order.transport_order_template_id;
      var pick_location_id=detailAll[index].pick_location_id.location_code
      var dest_location = ev ? ev : '';
      const baseURI =
        this.$settings.endPoint + "transport-order-template/dest-multiple?transport_order_template_id="+transport_order_template_id+"&pickup_location="+pick_location_id+"&dest_location="+dest_location;
 
      return this.$http.get(baseURI).then((response) => {
        var res=response.data.datas;

          var fetchLocationDest=[]
          for (let index = 0; index < res.length; index++) {
              res[index].dest.billable_method=res[index].billable_method;
              res[index].dest.order_group=res[index].order_group;
              fetchLocationDest.push(res[index].dest);
          }

          this.fetchLocationDest = fetchLocationDest.reduce((accumulator,currentValue) => {
              const index = accumulator.findIndex(item => item.location_id === currentValue.location_id);
              if (index === -1) {
                accumulator.push(currentValue);
              } 
              return accumulator;
            
          }, []);

      });
    },

   
    cekData(index, fieldType) {

      fieldType[index].sku_description = fieldType[index].sku.sku_description;
      fieldType[index].lading_length = fieldType[index].sku.pcs_length;
      fieldType[index].lading_width = fieldType[index].sku.pcs_width;
      fieldType[index].lading_height = fieldType[index].sku.pcs_height;

      fieldType[index].lading_qty = 0;
      fieldType[index].net_weight = 0;
      fieldType[index].weight = 0;
      fieldType[index].volume = 0;
      fieldType[index].qty = 0;

      this.loadLadingType(fieldType[index].sku.sku_id);
    },

    cekQty(index, fieldType) {

      let qty = fieldType[index].qty;
      let sku_id = fieldType[index].sku.sku_id;
      let lading_type = fieldType[index].lading_type;
      let package_type = fieldType[index].package_type;

      let dataSend = {
        sku_id: sku_id,
        qty: qty,
        lading_type: lading_type.package_type,
        package_type: package_type.package_type,
      };


      const baseURI = this.$settings.endPoint + "package/calculate";

      this.$http.post(baseURI, dataSend).then((response) => {


        let data = response.data.datas[0];

        this.detailAll[index].lading_height = data.lading_height;
        this.detailAll[index].lading_length = data.lading_length;
        this.detailAll[index].lading_qty = data.lading_qty;
        this.detailAll[index].lading_width = data.lading_width;
        this.detailAll[index].net_weight = data.net_weight;
        this.detailAll[index].volume = data.volume;
        this.detailAll[index].weight = data.weight;

      }).catch(() => {

        fieldType[index].lading_height = 0;
        fieldType[index].lading_length = 0
        fieldType[index].lading_qty = 0
        fieldType[index].lading_width = 0
        fieldType[index].net_weight = 0
        fieldType[index].volume = 0
        fieldType[index].weight = 0;

      });
    },

    loadLadingType(sku_id) {
      const baseURI = this.$settings.endPoint + "package/select-package-type/" + sku_id;

      return this.$http.get(baseURI).then((response) => {

        this.fetchladingType = response.data.datas;
        this.fetchpackageType = response.data.datas;

      });
    },

    backForm() {
      this.$swal({
        title: this.$t("areYouSure"),
        text: "Leave page? All changes will not be saved, please confirm ?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          window.location.href = "/order/transport-order-multi";
        }
      });
    },

    loadCompany() {
      const baseURI = this.$settings.endPoint + "company?limit=10";

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },



    loadCompanyNotLinc() {
      const baseURI =
        this.$settings.endPoint + `company/select`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },

    asyncSearchCompany(ev) {
      const baseURI = this.$settings.endPoint + "company?code=" + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },

    asyncSearchCompanyNotLinc(ev) {
      const baseURI =
        this.$settings.endPoint +
        "company/select?code=" + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },

    loadSKU(company_name) {
      const baseURI =
        this.$settings.endPoint + `sku?company_name=` + company_name;

      return this.$http.get(baseURI).then((response) => {
        this.fetchSKU = response.data.datas.data;
      });
    },

    asyncSearchSKU(ev) {
      const baseURI =
        this.$settings.endPoint +
        "sku?sku_code=" +
        ev +
        "?company_name=" +
        this.company_name;
      return this.$http.get(baseURI).then((response) => {
        this.fetchSKU = response.data.datas.data;
      });
    },

    loadTemplateOrder(company_name) {
      this.template_order = [];
      const baseURI =
        this.$settings.endPoint +
        `transport-order-template?multiple=multiple&company_name=` +
        company_name;

      return this.$http.get(baseURI).then((response) => {
        this.fetchTemplateOrder = response.data.datas.data;
      });
    },

    asyncSearchTemplateOrder(ev) {
      const baseURI =
        this.$settings.endPoint +
        "transport-order-template?multiple=multiple&description=" +
        ev +
        "&company_name=" +
        this.company.name;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTemplateOrder = response.data.datas.data;
      });
    },

    submitData() {
      this.fade(true);
      var pickPlan = moment(this.pickupDates.startDate).format('YYYY-MM-DD HH:mm');
      var destPlan = moment(this.destDates.startDate).format('YYYY-MM-DD HH:mm');
      var currentDate = moment(new Date()).format("YYYY-MM-DD HH:mm");
      // if(orderDate < currentDate || pickPlan < currentDate || destPlan < currentDate ){
      //   this.error(this.$t("validateOrderDate"));
      //   this.fade(false);
      //   return true;
      // }
      if(pickPlan < currentDate){
        this.error(this.$t("validatePickDate"));
        this.fade(false);
        return true;
      }

      if(destPlan < currentDate){
        this.error(this.$t("validateDestDate"));
        this.fade(false);
        return true;
      }
      const items = [];
      // console.log(this.orderLocation);
      this.detailAll.forEach((item) => {
        const data = {
          order_no:item.order_no.order_no,
          sequence_no: "",
          sku_id: item.sku.sku_id,
          barcode: item.sku.barcode,
          sku_code: item.sku.sku_code,
          sku_description: item.sku.sku_description,
          sku_long_description: item.sku.sku_long_description,
          qty: item.qty,
          package_type: item.package_type.package_type,
          lading_qty: item.lading_qty,
          lading_type: item.lading_type.package_type,
          lading_length: item.lading_length,
          price: "",
          amount: "",
          lading_width: item.lading_width,
          lading_height: item.lading_height,
          weight: item.weight,
          net_weight: item.net_weight,
          volume: item.volume,
          stackable: item.sku.stackable,
          commodity_id: item.sku.commodity_id,
        };
        items.push(data);
      });


      this.forms.company_id = this.company.company_id;
     
      this.forms.transport_order_detail = items;
      this.forms.order_source_id = "DATABASE";
      this.forms.order_status = "NEW";
      this.forms.status_tms = "NEW";
      this.forms.transport_order_template_id = this.template_order.transport_order_template_id;
      this.forms.pick_plan = moment(String(this.pickupDates.startDate)).format('YYYY-MM-DD HH:mm:ss');
      this.forms.dest_plan = moment(String(this.destDates.startDate)).format('YYYY-MM-DD HH:mm:ss');
      this.forms.order_date = moment(String(this.order_date.startDate)).format('YYYY-MM-DD HH:mm:ss');
      this.forms.transport_order_detail_chain=this.orderLocation;
      // console.log(this.forms);

      let formData = new FormData();
      Object.keys(this.forms).forEach((key1) => {
       
        if(key1=='transport_order_detail_chain'){
          // console.log('kesini aja');
         
          for (let index = 0; index < this.forms[key1].length; index++) {
       
            Object.keys(this.forms[key1][index]).forEach((key2) => {
              if(key2=='pick_location_id'){
                formData.append(`transport_order_detail_chain[${index}][${key2}]`, this.forms[key1][index][key2].location_id);
              }else if(key2=='dest_location_id'){
                formData.append(`transport_order_detail_chain[${index}][${key2}]`, this.forms[key1][index][key2].location_id);
              }else{
                formData.append(`transport_order_detail_chain[${index}][${key2}]`, this.forms[key1][index][key2]);
              }
             
            });
           
          }

        }else if(key1=='transport_order_detail'){
           
          for (let index = 0; index < this.forms[key1].length; index++) {
            Object.keys(this.forms[key1][index]).forEach((key2) => {
              formData.append(`transport_order_detail[${index}][${key2}]`, this.forms[key1][index][key2]);
             
            });

          }
         
        }else{
          formData.append(key1, this.forms[key1]);
        }

      });


      // console.log(formData);

      const baseURI = this.$settings.endPoint + "transport-order-header-multiple/create";
      this.$http
        .post(baseURI, formData)
        .then((response) => {
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            this.fade(false);

            var params = this.$onRandom(
              response.data.datas.transport_order_header_id
            );
            this.loading();
            window.location.href = "/order/transport-order-multi/detail/" + params + "?status=200&msg=Successfully";
            this.success('Berhasil');
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
            this.loading();
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      this.companyCode = this.detailUser.company_detail.code;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (
          permision.permission.controller == "TransportOrderHeaderController"
        ) {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {

  },
  mounted() {
    this.fetchIt();
    this.loadCompany();
    this.loadCompanyNotLinc();
  },
};
</script>
<style >
.wajib {
  content: "*";
  color: red;
}

.calendars[data-v-1ebd09d2] {
  display: -webkit-box;
  display: -ms-flexbox;
}

@media screen and (min-width:339px) {
  .daterangepicker.single[data-v-1ebd09d2] {
    min-width: auto
  }

  .daterangepicker.single.show-ranges.show-weeknumbers[data-v-1ebd09d2],
  .daterangepicker.single.show-ranges[data-v-1ebd09d2] {
    min-width: 360px !important
  }
}

.input-group>.form-control,
.input-group>.typeahead.tt-input,
.input-group>.typeahead.tt-hint,
.select2-container--default .select2-search--dropdown .input-group>.select2-search__field,
.input-group>.form-select {
  background: white;
}
</style>