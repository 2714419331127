<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading">
      {{ $t("loadingTxt") }}...
    </div>
    <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
      <!--begin::sidebar-->
      <sidebar-component classMenu="OnStopRepoert"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->
        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-body">
                  <br />
                  <div class="row">
                    <div class="col-lg-3 col-xl-3 col-sm-3 col-xs-3 col-md-3">
                      <h4 class="mb-3 mb-md-0">Sequence Driver Summary</h4>
                    </div>

                    <div class="col-lg-9 col-xl-9 col-sm-9 col-xs-9 col-md-9">
                      <div class="d-flex flex-wrap">
                        <div
                          class="input-group flatpickr wd-300 me-2 mb-2 mb-md-0"
                          id="dashboardDate"
                        >
                          <date-range-picker
                            style="width: 100%"
                            @change="pickupChange"
                            control-container-class="form-control"
                            :locale-data="locale"
                            v-model="dateFilter"
                            :opens="opens"
                            :singleDatePicker="false"
                            :timePicker24Hour="true"
                            :timePicker="true"
                            :showWeekNumbers="false"
                          >
                          </date-range-picker>
                        </div>

                        <button
                          type="button"
                          style="max-height: 40px"
                          @click.prevent="download()"
                          class="btn btn-primary btn-icon-text mb-2 mb-md-0 btn-block btn-floating"
                        >
                          GO
                        </button>
                        <span style="width: 5px"> </span>
                        <button v-if="permision_role.method_read"
                                            class="btn btn-success btn-icon-text btn-block"
                                            style="margin-right: 5px;max-height: 40px;"
                                            @click.prevent="downloadSummary()">Download
                      </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import DateRangePicker from "vue2-daterange-picker";
// import modalProductivityDetail from "@/components/modal/modalProductivityDetail.vue";
// import modalProductivityNasional from "@/components/modal/modalProductivityNasional.vue";

import moment from "moment";

export default {
  name: "OneStopReport",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    // "modal-productivity-detail": modalProductivityDetail,
    // "modal-productivity-nasional": modalProductivityNasional,
    DateRangePicker,
  },
  data() {
    const startDate = moment().clone().startOf("month").format("YYYY-MM-DD"); // new Date();
    const endDate = new Date();

    return {
      showModalDetail: false,
      showModalNasional: false,
      opens: "center",
      detailData: {},
      locale: {
        format: "yyyy-mm-dd",
      },
      permision_role: [],
      countData: [],
      dateFilter: {
        startDate,
        endDate,
      },
      isLoading: false,
      rowsTable: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [
        {
          label: "Date",
          field: "date",
          sortable: false,
        },

        {
          label: "Unit",
          field: "unit",
          sortable: false,
        },

        {
          label: "Trip",
          field: "trip",
          sortable: false,
        },

        {
          label: "Actual Days",
          field: "actual_days",
          sortable: false,
        },

        {
          label: "Active Days",
          field: "target_trip",
          sortable: false,
        },

        {
          label: "Productivity",
          field: "productivity",
          sortable: false,
        },
        {
          label: "Action",
          field: "actions",
          sortable: false,
        },
      ],
      rows: [],
    };
  },
  computed: {
    myStyles() {
      return {
        height: "100%",
        position: "relative",
      };
    },
  },
  watch: {},
  methods: {
    closeMyModal() {
      this.showModalDetail = false;
      this.showModalNasional = false;
      this.detailData = {};
    },
    showDetail(value) {
      const baseURI =
        this.$settings.endPointCt +
        "truck-productivity/dashboard-detail?date=" +
        value.date;
      return this.$http
        .get(baseURI)
        .then((response) => {
          this.detailData = response.data.datas;
          this.detailData["date"] = value.date;

          // console.log(this.detailData['date']);
          this.showModalDetail = true;
        })
        .catch((error) => {
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },

    showNasional(value) {
      const baseURI =
        this.$settings.endPointCt +
        "truck-productivity/dashboard-nasional?date=" +
        value.date;
      return this.$http
        .get(baseURI)
        .then((response) => {
          this.detailData = response.data.datas;
          this.detailData["date"] = value.date;
          // console.log(this.detailData);
          this.showModalNasional = true;
        })
        .catch((error) => {
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },

    drawDatasetPointsLabels() {},
    download() {
      let dataSend = {
        start: moment(String(this.dateFilter.startDate))
          .hours(0)
          .minutes(0)
          .seconds(1)
          .format("YYYY-MM-DD HH:mm:ss"),
        end: moment(String(this.dateFilter.endDate))
          .hours(23)
          .minutes(59)
          .seconds(59)
          .format("YYYY-MM-DD HH:mm:ss"),
      };
      this.isLoading = true;
      const baseURI = this.$settings.endPointCt + "reporting/download";
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = `Sequence_Driver_Summary` + CurrentDate + `.xlsx`;

      return this.$http
        .get(baseURI + `?start=${dataSend.start}&end=${dataSend.end}`, {
          responseType: "blob",
        })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },


    downloadSummary() {
      let dataSend = {
        start: moment(String(this.dateFilter.startDate))
          .hours(0)
          .minutes(0)
          .seconds(1)
          .format("YYYY-MM-DD HH:mm:ss"),
        end: moment(String(this.dateFilter.endDate))
          .hours(23)
          .minutes(59)
          .seconds(59)
          .format("YYYY-MM-DD HH:mm:ss"),
      };
      this.isLoading = true;
      const baseURI = this.$settings.endPointCt + "reporting/kpi";
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = `Sequence_Driver_Summary` + CurrentDate + `.xlsx`;

      return this.$http
        .get(baseURI + `?start=${dataSend.start}&end=${dataSend.end}`, {
          responseType: "blob",
        })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },

    pickupChange() {},

    getData() {
      let dataSend = {
        start: moment(String(this.dateFilter.startDate)).format("YYYY-MM-DD"),
        end: moment(String(this.dateFilter.endDate)).format("YYYY-MM-DD"),
      };
      this.loadItems(dataSend);
    },

    loadItems(dataSend) {
      this.baseURI =
        this.$settings.endPointCt +
        "one-stop-report/download?start=" +
        dataSend.start +
        "&end=" +
        dataSend.end;
      return this.$http
        .get(this.baseURI)
        .then((response) => {
          this.totalRecords = response.data.datas.total;
          this.rowsTable = response.data.datas.data;
        })
        .catch((error) => {
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      let rolePermision = [];
      // console.log(userDatas.sub)
      userDatas.sub.permision_role.forEach(function (permision) {
        if (
          permision.permission.controller == "TransportOrderHeaderCtController"
        ) {
          rolePermision = permision;
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },
    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    this.fetchIt();
    // this.getData();
  },
};
</script>
<style>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content {
  filter: blur(5px);
}

.box {
  width: 300px;
  height: 10%;
  border-style: solid;
  border-width: 0.5px;
  background-color: red;
  text-align: center;
  vertical-align: middle;
}

.progress {
  width: 150px;
  height: 150px;
  line-height: 150px;
  background: none;
  margin-top: -30%;
  margin-left: 70%;
  box-shadow: none;
  position: relative;
  opacity: 0.8;
}

.progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 12px solid #fff;
  position: absolute;
  top: 0;
  right: 0;
}

.progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress .progress-left {
  left: 0;
}

.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 12px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progress .progress-right {
  right: 0;
}

.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards;
}

.progress .progress-value {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background: #eaf0f4;
  font-size: 24px;
  color: rgb(65, 65, 65);
  line-height: 135px;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 5%;
}

.progress.blue .progress-bar {
  border-color: #097fc9;
}

.progress.blue .progress-left .progress-bar {
  animation: loading-2 1.5s linear forwards 1.8s;
}

.progress.yellow .progress-bar {
  border-color: #fdba04;
}

.progress.yellow .progress-left .progress-bar {
  animation: loading-3 1s linear forwards 1.8s;
}

.progress.pink .progress-bar {
  border-color: #ed687c;
}

.progress.pink .progress-left .progress-bar {
  animation: loading-4 0.4s linear forwards 1.8s;
}

.progress.green .progress-bar {
  border-color: #1abc9c;
}

.progress.green .progress-left .progress-bar {
  animation: loading-5 1.2s linear forwards 1.8s;
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(36deg);
    transform: rotate(36deg);
  }
}

@keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(126deg);
    transform: rotate(126deg);
  }
}

@media only screen and (max-width: 990px) {
  .progress {
    margin-bottom: 20px;
  }
}
</style>
