<template>
    <div>
      <div class="main-wrapper">
        <!--begin::sidebar-->
        <sidebar-component classMenu="SkuDetail"></sidebar-component>
        <!--end::sidebar-->
  
        <div class="page-wrapper">
          <!--begin::navbar-->
          <navbar-component></navbar-component>
          <!--end::navbar-->
  
          <!--begin::content-->
          <div class="page-content">
            <div
              class="
                d-flex
                justify-content-between
                align-items-center
                flex-wrap
                grid-margin
              "
            >
              <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
                <div class="card">
                <div class="card-header">
                    <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                    &nbsp;&nbsp;&nbsp;
                    <h6 class="card-title mb-0 float-start" style="padding-left:25px">Detail Sku</h6>
                    <button
                      class="btn btn-light float-end"
                      type="button"
                      data-bs-toggle="collapse" 
                      data-bs-target="#collapseExample" 
                      aria-expanded="false" 
                      aria-controls="collapseExample"
                    >
                      <i class="link-icon" data-feather="info"></i>
                      Audit Info
                    </button>
  
                    <div class="row mb-3 collapse" id="collapseExample" style="padding-left:20px">
                      <div class="col-lg-3">
                        <label class="tx-11 fw-bolder mb-0 ">Created By:</label>
                        <p class="text-muted">{{this.forms.create_by}}</p>
                      </div>
  
                      <div class="col-lg-3">
                        <label class="tx-11 fw-bolder mb-0">Created At:</label>
                        <p class="text-muted">{{this.forms.created_at}}</p>
                      </div>
  
                      <div class="col-lg-3">
                        <label class="tx-11 fw-bolder mb-0">Updated By:</label>
                        <p class="text-muted">{{this.forms.update_by}}</p>
                      </div>
  
                      <div class="col-lg-3">
                        <label class="tx-11 fw-bolder mb-0">Updated At:</label>
                        <p class="text-muted">{{this.forms.updated_at}}</p>
                      </div>
  
                      
                      
                    </div>
  
                  </div>
  
                  <div class="card-body">
                    <!--begin::loading-data-->
                    <div v-if="isLoading" class="d-flex align-items-center">
                      <div
                        class="spinner-border ms-auto"
                        role="status"
                        aria-hidden="true"
                      ></div>
                      <strong>{{ $t("loadingTxt") }}...</strong>
                    </div>
                    <!--end::loading-data-->
                    <form
                      class="forms-sample"
                      @submit.prevent="submitData"
                      method="POST"
                    >
                      <div
                        class="
                          d-flex
                          justify-content-between
                          align-items-baseline
                          mb-2
                        "
                      >
                      
                      </div>
                      
                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label for="defaultconfig" class="col-form-label" style="padding-left:25px">{{
                            $t('skuCodeTxt')
                          }}</label>
                        </div>
                        <div class="col-lg-4">
                          <input
                            type="text"
                            class="form-control"
                            id="sku_code"
                            :placeholder="$t('skuCodeTxt')"
                            v-model="forms.sku_code"
                            disabled
                          />
                          <div v-if="errors.sku_code">
                            <div
                              v-for="error in errors.sku_code"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
  
  
                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label for="defaultconfig" class="col-form-label" style="padding-left:25px">{{
                            $t('skuDescriptionTxt')
                          }}</label>
                        </div>
                        <div class="col-lg-8">
                          <input
                            type="text"
                            class="form-control"
                            id="sku_description"
                            :placeholder="$t('skuDescriptionTxt')"
                            v-model="forms.sku_description"
                            disabled
                          />
                          <div v-if="errors.sku_description">
                            <div
                              v-for="error in errors.sku_description"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
  
  
                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label for="defaultconfig" class="col-form-label" style="padding-left:25px">{{
                            $t('skuLongDescriptionTxt')
                          }}</label>
                        </div>
                        <div class="col-lg-8">
                          <input
                            type="text"
                            class="form-control"
                            id="sku_long_description"
                            :placeholder="$t('skuLongDescriptionTxt')"
                            v-model="forms.sku_long_description"
                            disabled
                          />
                          <div v-if="errors.sku_long_description">
                            <div
                              v-for="error in errors.sku_long_description"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
  
  
                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label for="defaultconfig" class="col-form-label" style="padding-left:25px">Barcode</label>
                        </div>
                        <div class="col-lg-8">
                          <input
                            type="text"
                            class="form-control"
                            id="barcode"
                            placeholder="Barcode"
                            v-model="forms.barcode"
                            disabled
                          />
                          <div v-if="errors.barcode">
                            <div
                              v-for="error in errors.barcode"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
  
  
                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label for="defaultconfig" class="col-form-label" style="padding-left:25px">{{
                            $t("companyCode")
                          }}</label>
                        </div>
                        <div class="col-lg-8">
                          
                      
                          <input
                            type="text"
                            class="form-control"
                            id="company_code"
                            v-model="forms.company.code"
                            disabled
                          />
  
                        </div>
                      </div>
  
  
                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label for="defaultconfig" class="col-form-label" style="padding-left:25px">{{
                            $t("packageName")
                          }}</label>
                        </div>
                        <div class="col-lg-8">
                          
                       <input
                            type="text"
                            class="form-control"
                            id="package_code"
                            v-model="forms.package.package_name"
                            disabled
                          />
                         
                        
                        </div>
                      </div>
  
  
                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label for="defaultconfig" class="col-form-label" style="padding-left:25px">{{
                            $t("commodityTxt")
                          }}</label>
                        </div>
                        <div class="col-lg-8">
                          
                      
                           <input
                            type="text"
                            class="form-control"
                            id="commodity"
                            v-model="forms.commodity.commodity_code"
                            disabled
                          />
                         
                       
                        </div>
                      </div>
  
                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label
                            for="defaultconfig"
                            class="col-form-label"
                            style="padding-left: 25px"
                            >Stackable</label
                          >
                        </div>
                        <div class="col-lg-4">
                          <input class="form-check-input" disabled type="radio" name="stackable" value="yes" v-model="forms.stackable"> Yes &nbsp;&nbsp;&nbsp; 
                          <input class="form-check-input" disabled type="radio" name="stackable" value="no" v-model="forms.stackable"> No
                        </div>
  
                         <div v-if="errors.stackable">
                            <div
                              v-for="error in errors.stackable"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
  
                      </div>
                  
  
                     
                     <div class="card-footer">
                      <button
                        class="btn btn-warning text-black   fw-bolder btn-icon-text"
                        @click="backForm"
                        type="button"
                       
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
  
  
                      <a
                        v-if="permision_role.method_delete"
                        class="btn btn-danger text-white  fw-bolder btn-icon-text float-end"
                        @click="deleteData()"
                      >
                        <i class="link-icon" data-feather="trash"></i>
                        {{ $t("deleteMess") }}
                      </a>
  
  
                      <a
                        v-if="permision_role.method_update"
                        class="btn btn-primary me-2 text-white fw-bolder btn-icon-text float-end"
                        @click="editData()"
                      >
                        <i class="link-icon" data-feather="edit"></i>
                        {{ $t("editMess") }}
                      </a>
                    </div>
  
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end::content-->
  
          <!--begin::footer-->
          <footer-component></footer-component>
          <!--end::footer-->
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import sidebarComponent from "@/components/_partials/_sidebar";
  import navbarComponent from "@/components/_partials/_navbar";
  import footerComponent from "@/components/_partials/_footer";
  
  export default {
    name: "SkuDetail",
    props: {},
    components: {
      "sidebar-component": sidebarComponent,
      "navbar-component": navbarComponent,
      "footer-component": footerComponent,
    },
    data() {
      return {
        permision_role:[],
        isLoading: false,
        maxToasts: 100,
        position: "up right",
        closeBtn: true,
        errors: [],
        fetchCompany:[],
        fetchPackage:[],
        fetchCommodity:[],
        userData: "",
        companyCode: "",
        forms: [],
      };
    },
    watch: {},
    methods: {
      resetForm() {
  
      },
  
  
      cek_message(status, msg) {
        if (status == "200") {
          this.success(msg);
        }
      },
  
      editData() {
        var params = this.$route.params.id;
        window.location.href = "/sku-test/edit/" + params;
      },
  
      deleteData() {
        var id = this.$onBehind(this.$route.params.id);
        this.$swal({
          title: this.$t("areYouSure"),
          text: this.$t("yourDataDelete"),
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        }).then((result) => {
          if (result.value) {
            var formData = {
              status: status,
            };
            const baseURI = this.$settings.endPoint + "sku/delete/" + id;
            this.$http
              .delete(baseURI, formData)
              .then((response) => {
                this.loading();
                if (response.data.status === 200) {
                  this.success(response.data.datas.messages);
                  window.location.href = "/sku-test";
                } else {
                  this.errors = response.data.errors;
                  this.resultError(response.data.errors);
                }
              })
              .catch((error) => {
                this.loading();
                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.error(error.response.data.errors.message);
                  } else {
                    this.$router.push("/page-not-found");
                  }
                }
              });
          }
        });
      },
  
  
      backForm() {
        window.location.href = "/sku-test";
      },
  
  
      loadCompany() {
        const baseURI = this.$settings.endPoint + "company?limit=100";
       
        return this.$http
          .get(
            baseURI
          )
          .then((response) => {
              this.fetchCompany=response.data.datas.data;
          });
      },
  
  
      loadPackage() {
        const baseURI = this.$settings.endPoint + "package?limit=100";
       
        return this.$http
          .get(
            baseURI
          )
          .then((response) => {
            console.log(response);
              this.fetchPackage=response.data.datas.data;
          });
      },
  
  
      loadCommodity() {
        const baseURI = this.$settings.endPoint + "commodity?limit=100";
       
        return this.$http
          .get(
            baseURI
          )
          .then((response) => {
              this.fetchCommodity=response.data.datas.data;
          });
      },
  
  
      loadData(id) {
        const baseURI = this.$settings.endPoint + "sku/detail/" + id;
        return this.$http.get(baseURI).then((response) => {
          
          this.forms = response.data.datas;
          console.log(this.forms);
          // this.form={ name: 'asep', usernmae:res.username };
        });
      },
  
  
      fetchIt() {
        const userDatas     = this.$getUserInfo();
        this.detailUser     = userDatas.sub;
        // this.permision_role = userDatas.sub.permision_role;
        let rolePermision = [];
        userDatas.sub.permision_role.forEach(function(permision){
          if(permision.permission.controller == 'SkuController'){
            rolePermision = permision;
            // console.log(permision);
          }
        });
  
        if(!rolePermision.method_read){
          this.$router.push("/authorized-error");
        }
  
        this.permision_role = rolePermision;
  
      },
  
  
      fade(sType) {
        this.isLoading = sType;
      },
  
      loading() {
        this.fade(true);
        setTimeout(() => {
          this.fade(false);
        }, 1000); // hide the message after 3 seconds
      },
  
      resultError(data) {
        var count = Object.keys(data).length;
        for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
          var objectData = data[nameOb];
          for (var y = 0; y < objectData.length; y++) {
            this.error(objectData[y]);
          }
        }
      },
  
      success(kata) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
          // onShown: function() {
          //     console.log('asdasdasd');
          //     //window.location.href = '/';
          // }
        });
  
        Toast.fire({
          icon: "success",
          title: kata,
        });
      },
  
      error(kata) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: kata,
        });
      },
    },
    events: {},
    created: function () {},
    mounted() {
      var params = this.$onBehind(this.$route.params.id);
      this.cek_message(this.$route.query.status, this.$route.query.msg);
      this.loadData(params[0]);
      this.fetchIt();
      this.loadCommodity();
      this.loadCompany();
      this.loadPackage();
    },
  };
  </script>
  <style scoped>
  </style>