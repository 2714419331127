<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading">
      {{ $t("loadingTxt") }}...
    </div>
    <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
      <!--begin::sidebar-->
      <sidebar-component classMenu="BranchAdd"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 1.5625rem"
                  >
                    Margin List Add
                  </h6>
                  <!-- <button
                    v-if="!isMaps"
                    class="btn btn-success float-end"
                    type="button"
                    style="margin-right: 1.25rem"
                    @click="onClickShowMaps(true)"
                  >
                    <i class="mdi mdi-google-maps"></i>
                    Show Maps
                  </button>
                  <button
                    v-if="isMaps"
                    class="btn btn-danger float-end"
                    type="button"
                    style="margin-right: 1.25rem"
                    @click="onClickShowMaps(false)"
                  >
                    <i class="mdi mdi-google-maps"></i>
                    Hide Maps
                  </button> -->
                  <button
                    class="btn btn-default btn-sm float-end"
                    @click="resetForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  
                  <!--end::loading-data-->

                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="d-flex justify-content-between align-items-baseline mb-2"
                    ></div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Customer Code</label
                        >
                      </div>
                      <div class="col-lg-4">
                        <v-select
                          @input="selectCodeCompany"
                          :options="fetchCompany"
                          v-model="company"
                          label="code"
                          return-object
                          @search="asyncSearchCompany"
                        
                          
                        ></v-select>

                        <div v-if="errors.customer_code">
                          <div
                            v-for="error in errors.customer_code"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Customer Name</label
                        >
                      </div>
                      <div class="col-lg-10">
                        <input
                          
                          type="text"
                          class="form-control"
                          id="Customer Name"
                          readonly
                          v-model="forms.customer_name"
                          
                        />

                        <div v-if="errors.customer_name">
                          <div
                            v-for="error in errors.customer_name"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Pick Location</label
                        >
                      </div>
                      <div class="col-lg-4">
                        <!-- <v-select
                          :options="fetchsl"
                          v-model="pick_sl"
                          return-object
                          label="name"
                          @search="asyncSearchLocation"
                          :custom-label="customLabelLocation"
                        ></v-select> -->

                        <multiselect  @select="onSelectPick" :custom-label="customLabelLocation" v-model="pick_sl" :options="fetchsl"
                          placeholder="Please Select" >
                        </multiselect>

                       
                        <div v-if="errors.pick_sl">
                          <div
                            v-for="error in errors.pick_sl"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <v-select
                          :options="fetchCity"
                          v-model="pick_city"
                          return-object
                          label="city"
                          @input="selectCityPick"
                         
                        ></v-select>
                       
                        <div v-if="errors.pick_city">
                          <div
                            v-for="error in errors.pick_city"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>



                      <div class="col-lg-3">
                        <v-select
                          :options="fetchProvince"
                          v-model="pick_province"
                          return-object
                          label="name"
                         
                        ></v-select>
                       
                        <div v-if="errors.pick_province">
                          <div
                            v-for="error in errors.pick_province"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                    </div>
                    

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Drop Location</label
                        >
                      </div>
                      <div class="col-lg-4">

                        <multiselect  @select="onSelectDrop" :custom-label="customLabelLocation" v-model="drop_sl" :options="fetchslDrop"
                          placeholder="Please Select" >
                        </multiselect>

                        <!-- <v-select
                          :options="fetchslDrop"
                          v-model="drop_sl"
                          return-object
                          label="name"
                         
                        ></v-select> -->
                       
                        <div v-if="errors.drop_sl">
                          <div
                            v-for="error in errors.drop_sl"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <v-select
                          :options="fetchCityDrop"
                          v-model="drop_city"
                          return-object
                          label="city"
                          @input="selectCityDrop"
                        ></v-select>
                       
                        <div v-if="errors.drop_city">
                          <div
                            v-for="error in errors.drop_city"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>



                      <div class="col-lg-3">
                        <v-select
                          :options="fetchProvinceDrop"
                          v-model="drop_province"
                          return-object
                          label="name"
                         
                        ></v-select>
                       
                        <div v-if="errors.drop_province">
                          <div
                            v-for="error in errors.drop_province"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Truck Type
                        </label>
                      </div>
                      <div class="col-lg-10">
                        <multiselect
                          v-model="truck_type"
                          :options="fetchTruckType"
                          :multiple="false"
                          group-values="type_truck"
                          group-label="category"
                          :group-select="true"
                          placeholder="Type to search "
                          track-by="type_truck"
                          label="type_truck"
                          ><span slot="noResult"
                            >Oops! No elements found. Consider changing the
                            search query.</span
                          ></multiselect
                        >

                        <div v-if="errors.truck_type">
                          <div
                            v-for="error in errors.truck_type"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Margin Value</label
                        >
                      </div>
                      <div class="col-lg-10">
                        <input
                          type="text"
                          class="form-control"
                          id="margin_value"
                       
                          v-model="forms.margin_value"
                          
                        />


                        <div v-if="errors.margin_value">
                          <div
                            v-for="error in errors.margin_value"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                     
                      </div>
                    </div>




                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Carrier</label
                        >
                      </div>
                      <div class="col-lg-10">
                        <multiselect  @search-change="asyncCarrier" :custom-label="customLabel" v-model="carrier" :options="fetchCarrier"
                          placeholder="Please Select Carrier" >
                        </multiselect>


                        <div v-if="errors.carrier">
                          <div
                            v-for="error in errors.carrier"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>

                        
                     
                      </div>
                    </div>

                    <div class="card-footer">
                      <button
                        class="btn btn-warning text-black fw-bolder btn-icon-text"
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button
                        v-if="!isLoading"
                        class="btn btn-primary float-end btn-sm"
                        type="submit"
                      >
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>

                      <div v-if="isLoading" class="d-flex align-items-center">
                        <div
                          class="spinner-border ms-auto"
                          role="status"
                          aria-hidden="true"
                        ></div>
                        <strong>{{ $t("loadingTxt") }}...</strong>
                      </div>

                    </div>
                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
// import DirectionsRenderer from "@/middleware/DirectionsRenderedDynamic";
import multiselect from "vue-multiselect";
// import myMixinDistance from "@/middleware/getDistance";
// import GMapAutocomplete from "/vue2-google-maps/src/components"

export default {
  name: "BranchAdd",
  props: {},
  // mixins: [myMixinDistance],
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    multiselect
    // DirectionsRenderer,
  },
  data() {
    const mapMarkerIcon = require("../../../assets/icontruck.png");
    return {
      isLoading: false,
      markerOption: {
        url: mapMarkerIcon,
        size: { width: 30, height: 30, f: "1.25rem", b: "1.25rem" },
        scaledSize: { width: 30, height: 30, f: "1.25rem", b: "1.25rem" },
        rotation: 45,
      },
      markers: [],
      center: {
        lat: -2.548926,
        lng: 118.0148634,
      },
      startLocation: null,
      endLocation: null,
      // waypnt: [],
      company:"",
      poolStart: [],
      fetchCompany:[],
      poolEnd: [],
      fetchPool: [],
      fetchCityOri:[],
      fetchCityDest:[],
      fetchDistrictOri:[],
      fetchDistrictDest:[],
      
      setMarker: null,
      fetchsl:[],
      fetchCity:[],
      fetchProvince:[],
      fetchTruckType: [],
      truck_type:"",
      carrier:"",
      fetchCarrier:[],
      pick_sl:'',
      pick_city:'',
      pick_province:'',

      drop_sl:'',
      drop_city:'',
      drop_province:'',

      fetchslDrop:[],
      fetchCityDrop:[],
      fetchProvinceDrop:[],

      mapSearch: "",
      suggestions: [],
      mapZoom: 8,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      poolAll: [],
      permision_role: [],
      userData: "",
      isMaps: true,
      companyCode: "",
      forms: {
        poolToLoading:0,
        loadToUnload :0,
        unloadToPool :0,
        distance: 1,
        origin_coordinate: "",
        destination_coordinate: "",
        margin: 10,
        route_destination:null,
        drop_state:null,
        route_origin:null,
        pick_state:null,
        city_origin:null,
        province_origin:null,
        city_destination:null,
        province_destination:null,
      },
      photo_temporary: null,
      photo: "",
      mapClick: 1,
    };
  },
  computed: {

   
    getDistanceMargin() {
      let value = 0;
      if (this.forms.margin && this.getDistance) {
        value =
          (this.getDistance * this.forms.margin) / 100 + this.getDistance;
      }
      return value;
    },

    getDistance() {
    let poolToLoading = parseInt(this.forms.pooltoloading, 10) || 0;
    let loadToUnload = parseInt(this.forms.loadtounload, 10) || 0;
    let unloadToPool = parseInt(this.forms.unloadtopool, 10) || 0;

    return poolToLoading + loadToUnload + unloadToPool;
    }

  },
  watch: {},
  methods: {
    provinceSelected(loc,ev){
      if(loc == 'origin'){
        this.forms.route_origin = null
        this.forms.city_origin = null
      }
      if(loc == 'dest'){
        this.forms.route_destination = null
        this.forms.city_destination = null
      }
      this.loadState(loc,ev);
      this.loadCity(ev,loc);
    },
    citySelected(loc,ev){
      if(loc == 'origin'){
        this.forms.route_origin = null
      }
      if(loc == 'dest'){
        this.forms.route_destination = null
      }
      this.loadDistrict(loc,ev);
    },
    loadProvince(){
      const baseURI = this.$settings.endPoint + "postal-code/select-province/indonesia";
      return this.$http.get(baseURI).then((response) => {
        this.fetchProvince = response.data.datas.data;
      });
    },
    asyncProvince(ev){
      const baseURI = this.$settings.endPoint + "postal-code/select-province/indonesia?province="+ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchProvince = response.data.datas.data;
      });
    },
    loadCity(ev,loc){
      if(ev){
        const baseURI = this.$settings.endPoint + "postal-code/select-city/"+ev;
        return this.$http.get(baseURI).then((response) => {
          if(loc == 'origin'){
            this.fetchCityOri = response.data.datas;
          }
          if(loc == 'dest'){
            this.fetchCityDest = response.data.datas;
          }
        });
      }
    },  

    customLabel(ev){
      return ev.carrier_name;
    },

    customLabelLocation(ev){
      return ev.name+' - '+ev.int_code;
    },
    
    handleChange(){
      var total=this.forms.pooltoloading + this.forms.loadtounload + this.forms.unloadtopool
      this.forms.distance=total;
    },
    loadState(loc,ev){
      const baseURI = this.$settings.endPoint + "postal-code?province="+ev;
      return this.$http.get(baseURI).then((response) => {
        let data = response.data.datas.data;
        if(data && data.length > 0){
          if(loc == 'dest'){
            this.$set(this.forms,'drop_state',data[0].state);
          }
          if(loc == 'origin'){
            this.$set(this.forms,'pick_state',data[0].state);
          }
        }
      });
    },
    loadDistrict(loc,ev){
      const baseURI = this.$settings.endPoint + "postal-code/select-district/"+ev;
      return this.$http.get(baseURI).then((response) => {
        if(loc == 'origin'){
            this.fetchDistrictOri = response.data.datas;
          }
          if(loc == 'dest'){
            this.fetchDistrictDest = response.data.datas;
          }
      });
    },

    loadForCarrier() {
      const baseURI =
        this.$settings.endPointCt + `master-carrier`;
      return this.$http.get(baseURI).then((response) => {
      
        this.fetchCarrier = response.data.datas.data.filter(carrier => 
          carrier.carrier_code !== '0001' && carrier.carrier_code !== '0000'
        );
      });
    },


    onSelectPick(ev){
     
      this.pick_city={
        city:ev.city
      };

      this.pick_province={
        name:ev.province
      }

    },

    selectCityDrop(ev){
      this.drop_province={
        name:ev.province
      }
    },

    selectCityPick(ev){
      this.pick_province={
        name:ev.province
      }
    },

    onSelectDrop(ev){
   
      this.drop_city={
        city:ev.city
      };

      this.drop_province={
        name:ev.province
      }

    },


    asyncCarrier(ev){
      this.fetchCarrier = [];
      const baseURI =
        this.$settings.endPointCt + `master-carrier?carrier_name=`+ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchCarrier = response.data.datas.data;
      });
    },

    loadTruckType() {
      const baseURI = this.$settings.endPointCt + `type-truck?select=select`;
      return this.$http.get(baseURI).then((response) => {
        let data = {
         
          type_truck: response.data.datas.data,
        };
        this.fetchTruckType.push(data);
      });
    },


    loadPostalCode() {
      const baseURI = this.$settings.endPoint + `postal-code/select-distinct-city?limit=1000`;
      return this.$http.get(baseURI).then((response) => {
        console.log(response);
        this.fetchCity=response.data.datas.data;
        this.fetchCityDrop=response.data.datas.data;
      });
    },


    loadLocation(company_id) {
      var data="";
      if(company_id==0){
        data=[1]
      }else{
        data=[company_id,1]
      }
      const baseURI = this.$settings.endPoint + `transport-order-margin-list/stop-location?limit=3000`;
      return this.$http.post(baseURI,{
        company_ids:data
      }).then((response) => {
      
        this.fetchsl=response.data.datas.data;
        this.fetchslDrop=response.data.datas.data;
      });
    },

    asyncSearchLocation(ev) {
      this.fetchsl=[];
      const baseURI = this.$settings.endPointCt + `transport-order-margin-list/stop-location?name=` + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchsl=response.data.datas.data;
      });
    },



    asyncSearchTruckType(ev) {
      const baseURI = this.$settings.endPointCt + `type-truck?type_truck=` + ev;

      return this.$http.get(baseURI).then((response) => {
        response.data.datas.data.forEach((item) => {
          const data = {
            type_truck_id: item.type_truck_id,
            type_truck: item.type_truck,
          };
          this.fetchTruckType.push(data);
        });
      });
    },


    asyncSearchCompany(ev) {

      const baseURI = this.$settings.endPoint + "company/select?code="+ev;
      //this.isLoading = true;
     
      return this.$http
        .get(
          baseURI,{timeout: 10000 }
        )
        .then((response) => {
          this.fetchCompany=[];
            //this.fetchCompany=response.data.datas.data;

            response.data.datas.data.forEach((item) => {
              if(item.code!='ALL'){
                const data = {
                  company_id:item.company_id,
                  name: item.name,
                  code: item.code,

                
                };

                this.fetchCompany.push(data);
             
                
              }
             
            });


        }).catch(error => {
          //this.isLoading = false;
           
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              }else if (error.response.status === 500) {
                  this.$router.push('/server-error');
              }else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
              }else{
                  this.$router.push('/page-not-found');
              }
            }

          }

        });
    },

    distanceData(ev) {
      console.log(ev);
      this.forms.distance = ev / 1000;
      // console.log(ev);
    },

    removeImages() {
      this.$refs.photo = "";
      this.photo_temporary = null;
    },

    handleFileUpload(event) {
      this.photo = event.target.files[0];
      this.photo_temporary = URL.createObjectURL(this.photo);
    },

    selectCodeCompany(ev){
      this.forms.customer_name=ev.name;
      this.loadLocation(ev.company_id)
    },

    
    isNumberLatLong(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 &&
        charCode !== 45
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    setMapMarker(type) {
      if (this.setMarker == type) {
        this.setMarker = null;
      } else {
        this.setMarker = type;
      }
    },

    loadCompany() {
      
      const baseURI = this.$settings.endPoint + "company/select";
      //this.isLoading = true;
     
      return this.$http
        .get(
          baseURI,{timeout: 10000 }
        )
        .then((response) => {
            // this.fetchCompany=response.data.datas.data;
            this.fetchCompany=[];

            response.data.datas.data.forEach((item) => {
              if(item.code!='ALL'){
                const data = {
                company_id:item.company_id,
                name: item.name,
                code: item.code,
              };
              this.fetchCompany.push(data);
                
              }
             
            });


        }).catch(error => {
          //this.isLoading = false;
           
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              // if(error.response.status === 422) {
              //   this.errors = error.response.data.errors;
              //   this.resultError(error.response.data.errors);
              // }else if (error.response.status === 500) {
              //     this.$router.push('/server-error');
              // }else if (error.response.status === 401) {
              //     this.$router.push("/authorized-error");
              // }else{
              //     this.$router.push('/page-not-found');
              // }
            }

          }

        });
    },


    resetForm() {
      console.log(this.forms);
      // this.forms.branch_name = "";
      // this.forms.description = "";
      // this.forms.sequence = "";
      // this.forms.value = "";
    },
    clickMap(ev) {
      console.log(ev);
      if (this.setMarker !== null) {
        if (this.setMarker == "origin") {
          this.forms.origin_coordinate =
            ev.latLng.lat() + ", " + ev.latLng.lng();
        }
        if (this.setMarker == "destination") {
          this.forms.destination_coordinate =
            ev.latLng.lat() + ", " + ev.latLng.lng();
        }
      }

      this.setMarker = null;
    },
    backForm() {
      window.location.href = "/margin-list";
    },
    setOriginCordinate() {
      console.log("Origin Changed !");
      if (this.poolStart && this.poolStart !== "") {
        var markerPickup = {
          lat: this.poolStart.pool_latitude,
          lng: this.poolStart.pool_longitude,
          label: this.$t("coordinate_start"),
        };
        this.center = markerPickup;
        this.startLocation = markerPickup;
      }
    },
    setDestinationCordinate() {
      if (this.poolEnd && this.poolEnd !== "") {
        var markerDestination = {
          lat: this.poolEnd.pool_latitude,
          lng: this.poolEnd.pool_longitude,
          label: this.$t("coordinate_end"),
        };
        this.endLocation = markerDestination;
      }
    },

    submitData() {


     
     
      let formData = {
        "company_id"          : this.company.company_id,
        "customer_code"       : this.company.code,
        "customer_name"       : this.company.name,
        "pick_location_id"    : this.pick_sl!==null ? this.pick_sl.location_id : null, // 4079
        "pick_city"           : this.pick_city.city, // Bekasi CILINCING
        "pick_province"       : this.pick_province.name, // Jawa Barat DKI Jakarta
        "drop_location_id"    : this.drop_sl!==null ? this.drop_sl.location_id : null, // 4087
        "drop_city"           : this.drop_city.city, // Tangerang BALARAJA
        "drop_province"       : this.drop_province.name, // Banten
        "truck_type"          : this.truck_type.type_truck, // TRONTON_WB
        "margin_value"        : this.forms.margin_value,
        "carrier"             : this.carrier.carrier_name,
      }

      if(this.company.code==='LTL' && !this.pick_sl && !this.drop_sl){
        this.$swal({
          title: this.$t("areYouSure"),
          text: "this process will take 5 minutes,",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        }).then((result) => {
          if (result.value) {
            this.submitDataPost(formData);
            //console.log(formData);
          }
        });  
      }else{
        this.submitDataPost(formData);
        //console.log(formData);
      }
      
      
    },

    submitDataPost(formData){
      this.isLoading = true;
      const baseURI = this.$settings.endPoint + "transport-order-margin-list/create";
      this.$http
        .post(baseURI,formData)
        .then((response) => {
          this.isLoading = false;
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            //console.log();
            this.success_box(response.data.datas.messages);
            // window.location.href =
            //   "/margin-list?status=200&msg=Successfully";
            // this.success("Berhasil");
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error("Please Check Your Internet Connection");
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                //this.resultError(error.response.data.errors);

                this.error_box(error.response.data.errors.messages);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },

    asyncPool(ev) {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=mpool&value=${ev}`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchPool = response.data.datas;
      });
      
    },
    setPlace(field, ev) {
      if (ev.geometry.viewport) {
        this.$refs.currentMaps.fitBounds(ev.geometry.viewport);
        // console.log(this.$refs.currentMaps)
        // console.log(this.$refs.currentMaps.fitBounds)
      } else {
        this.center = {
          lat: ev.geometry.location.lat(),
          lng: ev.geometry.location.lng(),
        };
        this.mapZoom = 15;
      }
      // this.center = {
      //   lat: ev.geometry.location.lat(),
      //   lng: ev.geometry.location.lng(),
      // };
      // this.mapZoom = 20;
      if (field == "origin") {
        this.forms.route_origin = ev.name;
      }
      if (field == "destination") {
        this.forms.route_destination = ev.name;
      }
      console.log(ev);
    },
    loadPool() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=mpool`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchPool = response.data.datas;
      });
    },
    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
        [];
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "TransportOrderMarginListController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },


    success_box(messages) {
      const htmlContent = `
      
          <p><strong>Attention:</strong> ${messages.attention}</p>
          <p><strong>Count Add Margin List:</strong> ${messages.count_add_margin_list}</p>
          <p><strong>Count Rute Margin Smaller Exist:</strong> ${messages.count_rute_margin_smaller_exist}</p>
          <p><strong>Count Rute Margin Same Exist:</strong> ${messages.count_rute_margin_same_exist}</p>
        
      `;
    
      this.$swal({
        icon: 'success',
        backdrop: true,
        title: "Success",
        html: htmlContent,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then((result) => {
          if (result.value) {
            window.location.href ="/margin-list?status=200&msg=Successfully";
            //console.log(formData);
          }
      });  


    },


    error_box(messages) {
      let htmlContent = '';

      if (Array.isArray(messages)) {
        htmlContent = messages.map(message => `<p>${message}</p>`).join('');
      } else {
        htmlContent = `
          <p><strong>Attention:</strong> ${messages.attention}</p>
          <p><strong>Count Add Margin List:</strong> ${messages.count_add_margin_list}</p>
          <p><strong>Count Rute Margin Smaller Exist:</strong> ${messages.count_rute_margin_smaller_exist}</p>
          <p><strong>Count Rute Margin Same Exist:</strong> ${messages.count_rute_margin_same_exist}</p>
        `;
      }
    
      this.$swal({
        icon: 'error',
        backdrop: true,
        title: "Error",
        html: htmlContent,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      })


    },


    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    
    this.fetchIt();
    this.loadTruckType();
    this.loadCompany();
    this.loadForCarrier();
    this.loadPostalCode();
    this.loadLocation(0)
    //this.loadLocation();
  
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content {
  filter: blur(.3125rem);
  pointer-events: none;
}
.suggestions {
  list-style: none;
  padding: 0;
  border: .0625rem solid #ccc;
  max-height: 9.375rem;
  overflow-y: auto;
}

.suggestions li {
  padding: .3125rem;
  cursor: pointer;
  background-color: #fff;
}

.suggestions li:hover {
  background-color: #f5f5f5;
}

.dropify-wrapper{
  height: 31.25rem;
}
</style>
