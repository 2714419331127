<template>
  <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title badge bg-primary">
            {{ data.status_fppk}}
          </h5>

          <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal', null)"
            aria-label="btn-close"></button>
        </div>

        <div class="modal-body">
          <vue-good-table title="list-user" mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
            :line-numbers="true" :isLoading.sync="isLoading" :pagination-options="{
    enabled: false,
  }" :rows="rows" :columns="columns">
            <!-- <template slot="table-row" slot-scope="props">
  
                  <span v-if="props.column.field == 'actions'">
                    <a href="#"                   
                      title="Edit" @click.prevent="submitParent(props.index, props.row)">
                      <i class="mdi mdi-square-edit-outline" style="font-size:16px"></i>
  
                    </a>
                  </span>
                </template> -->
          </vue-good-table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal', null)">
            {{ $t("close_txt") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "ModalFppkKanbanDetail",
  emits: ["closeModal"],
  props: {
    showModal: Boolean,
    data: {},
  },
  watch: {
    showModal: {
      handler(newVal) {
        this.active = newVal;
        this.orderStatus = this.data.status_fppk;
      


        if (this.active) {
            this.columns = this.columnsNew;
            this.loadItems();
        }
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      active: this.showModal,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 1000,
      },
      columns: [],
      
     
      columnsNew: [

      {
            label: "No FPPK",
            field: "no_fppk",
          },
          {
            label: "Tanggal FPPK",
            field: "tanggal_fppk",
          },
          {
            label: "Status FPPK",
            field: "status_fppk",
          },
          {
            label: "Route",
            field: "route",
          },
          {
            label: "Nopol",
            field: "nopol",
          },
          {
            label: "Merk",
            field: "merk",
          },
          {
            label: "Jenis Unit",
            field: "jenis_unit",
          },
          {
            label: "Driver",
            field: "driverName",
          },
          {
            label: "Km Odo",
            field: "km_odo",
          },
          {
            label: "Keluhan",
            field: "keluhan1",
          },
          {
            label: "Lokasi Unit",
            field: "lokasi_unit",
          },
          {
            label: "Status",
            field: "status",
          },
          {
            label: "Type Service",
            field: "type_service",
          },
          {
            label: "Tanggal Mulai",
            field: "tgl_mulai_service",
          },
          {
            label: "Tanggal Estimasi",
            field: "tanggal_estimasi_selesai",
          },
          {
            label: "Tanggal Selesai",
            field: "tgl_selesai_service",
          },


          {
            label: "Keluhan",
            field: "keluhan",
          },


          {
            label: "Analisa",
            field: "analisa",
          },

          {
            label: "Tanggal WO",
            field: "tanggal_wo",
          },

          {
            label: "No Wo",
            field: "no_wo",
          },
        
      ],
     
      rows: [],
      rowsSelection: [],
      locale: [],
      loadId: "",
      orderStatus: "",
      rules: {
        select: [(v) => !!v || "Item is required"],
      },
    };
  },
  methods: {
    submitParent(index, row) {
      this.$emit("closeModal", row);
    },

    removeOrderHeaderIds() {
      this.transport_order_header_ids = [];
      this.$refs["list-transport-orders"].unselectAllInternal();
      // console.log(this.$refs['list-transport-orders'].selectedRows)
    },

    selectionChanged(ev) {
      this.rowsSelection = [];
      this.transport_order_header_ids = [];
      this.rowsSelection = ev.selectedRows;

      this.rowsSelection.forEach((item) => {
        this.transport_order_header_ids.push(item.transport_order_header_id);
      });
    },

    loadItems() {
      this.rows = [];
      this.loadId = "";

      var startDate= this.data.tanggal_submit;
      var endDate= this.data.tanggal_submit;
      var tanggal_submit=startDate+'~'+endDate;

      const baseURI =
        this.$settings.endPointFppk + "v1/report-fppk/on-demand-report";
     
      return this.$http
        .get(
          baseURI +
          `?status_fppk=${this.orderStatus}&tanggal_submit=${tanggal_submit}`
        )
        .then((response) => {
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                // this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                // this.$router.push('/page-not-found');
              }
            }
          }
        });
    },

   
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },
    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  mounted() {
    //   this.loadItems();
    // this.rows = this.props.data;
  },
};
</script>

<style scoped>
.blur-content {
  filter: blur(5px);
  pointer-events: none;
}

.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 120%;
  height: 100%;
  margin-left: -10%;
}
</style>