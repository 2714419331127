<template>
    
    <div class="row">
        <div class="row">
            <div class="col-lg-6">
                
            </div>
            <div class="col-lg-6">
                <!-- <button
                    
                    class="btn btn-primary float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="createData()"
                  >
                    <i class="link-icon" data-feather="plus"></i>
                    {{ $t("createNew") }}
                  </button> -->
            </div>
        </div>

      <vue-good-table
        title="list-of-orders"
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :line-numbers="true"
        :isLoading.sync="isLoading"
        :pagination-options="{
          enabled: false,
        }"
        :filterOptions="{
          enabled: false}" 
        :rows="rowsTableOrder"
        :columns="columnsOrder"
        :sort-options="{
          enabled: false,
        }"
      >
      <div slot="table-actions">
          <div class="vgt-clearfix">
            <button class="btn btn-success btn-xs" style="font-size: 10pt; margin-top:-7px" @click.prevent="TambahFormModal()">
              <i class="link-icon" data-feather="plus"></i>
              {{ $t("createNew") }}
            </button>
          </div>
        </div>
        <template slot="table-row" slot-scope="props">
          
          <span v-if="props.column.field == 'control'">
            <!-- <a
              href="#"
              title="Detail"
              @click.prevent="detailData(props.index, props.row)"
            >
              <i class="mdi mdi-eye" style="font-size: 16px"></i>
            </a> -->
            <a
              href="#"
              title="Edit"
              @click.prevent="EditFormModal(props.row)"
            >
              <i class="mdi mdi-square-edit-outline" style="font-size: 16px"></i>
            </a>
  
            <a
              href="#"
              title="Delete"
              @click.prevent="deleteData(props.index, props.row)"
            >
              <i class="mdi mdi-trash-can-outline" style="font-size: 16px"></i>
            </a>
          </span>
  
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
  
      <modal-equipment 
        :showModal="showModalNow"
        :data="detailData"
        :master_driver_id="master_driver_id"
        @closeModal="closeMyModal"
      ></modal-equipment>
  
  
  
    </div>
  
  
  </template>
  
  
  <script>
  import modalEquipment from "@/components/modal/modalEquipment.vue";
  //import moment from "moment";

  export default {
    name: "equipmentDriverComponenent",
    props: {
      detail: [],
      //master_driver_id:0,
      master_driver_id: null
    },
    components: {
      "modal-equipment":  modalEquipment ,
      // DirectionsRenderer,
    },
    watch: {
      
      master_driver_id: function(val) {
        console.log(val)
        this.loadTable()
      },
    
    },
    data() {
      return {
        //vehicle_no:this.$route.params.vehicle_no,
        detailData:"",
        showModalNow: false,
        isLoading: false,
        totalRecords: 0,
        serverParams: {
          columnFilters: {},
          sort: {
            field: "",
            type: "",
          },
          page: 1,
          per_page: 10,
        },
        rowsTableOrder: [],
        columnsOrder: [
          {
            label: "Delivery Date",
            field: "delivery_date",
          },
  
          {
            label: "Delivery PIC",
            field: "delivery_pic",
          },
  
          {
            label: "Return Date",
            field: "return_date",
          },

          {
            label: "Return PIC",
            field: "return_pic",
          },

          {
            label: "Remark",
            field: "remark",
          },
  

          {
            label: "Control",
            field: "control",
          },
        ],
        //
      };
    },
    methods: {
  
      

      loadTable() {
        
        const baseURI =
          this.$settings.endPointCt + `driver-equipment?master_driver_id=`+this.master_driver_id;
        return this.$http.get(baseURI).then((response) => {
          this.rowsTableOrder=response.data.datas.data;
          //console.log(this.rowsTableOrder);
          // this.detailDriverID = response.data.datas.data;
          // console.log(this.detailDriverID);
        });
    },
  
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
  
      onColumnFilter(params) {
        params.page = 1;
        this.updateParams(params);
      },
  
      onPageChange(params) {
        this.updateParams({ page: params.currentPage });
      },
  
      onSortChange(params) {
        this.updateParams({
          sort: {
            type: params[0].type,
            field: params[0].field,
          },
        });
      },

      loadError(pesan) {
        this.$swal({
          backdrop: true,
          title: "Warning",
          html: pesan,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      },
  
  
  
  
      loadSuccess(pesan) {
        this.$swal({
          backdrop: true,
          title: "Success",
          html: pesan,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      },
      
      closeMyModal() {
        this.showModalNow = false;
        this.loadTable();
      },
  
      TambahFormModal() {
        this.detailData = '';
        this.showModalNow = !this.showModalNow;
      },
  
  
      EditFormModal(data) {
        this.detailData=data;
        this.showModalNow = !this.showModalNow;
      },
  
      deleteData(index, row) {
        this.$swal({
          title: this.$t("areYouSure"),
          text: this.$t("yourDataDelete"),
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        }).then((result) => {
          if (result.value) {
            
            const baseURI =
              this.$settings.endPointCt + "driver-equipment/delete/" + row.driver_equipment_id;
            this.$http
              .delete(baseURI)
              .then((response) => {
                //console.log('asdsad');
                if (response.data.status === 200) {
                  //console.log(response.data.datas.messages);
                  this.loadSuccess(response.data.datas.messages);
                  this.loadTable();
                } else {
                  this.errors = response.data.errors;
                  this.loadError(response.data.errors);
                }
              })
              .catch((error) => {
               
                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.loadError(this.errors);
                  } 
                }
              });
          }
        });
      },
  
      onPerPageChange(params) {
        this.updateParams({ per_page: params.currentPerPage });
      },
    },
  };
  </script>
  