import Truck from "@/components/master/truck/Truck.vue";
import TruckAdd from "@/components/master/truck/TruckAdd.vue";
import TruckDetail from "@/components/master/truck/TruckDetail.vue";
import TruckEdit from "@/components/master/truck/TruckEdit.vue";
import TruckUpload from "@/components/master/truck/TruckUpload.vue";

let routesTruck = [
    
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "TruckIndex",
        component: Truck
    },
    {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "TruckAdd",
        component: TruckAdd
    },
    {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "TruckDetail",
        component: TruckDetail
    },
    {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "TruckEdit",
        component: TruckEdit
    },
    {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "TruckUpload",
        component: TruckUpload
    },
   
  ]
  
  export default routesTruck
