import { render, staticRenderFns } from "./PostalCodeUpload.vue?vue&type=template&id=471d19cb&scoped=true"
import script from "./PostalCodeUpload.vue?vue&type=script&lang=js"
export * from "./PostalCodeUpload.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "471d19cb",
  null
  
)

export default component.exports