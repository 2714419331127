<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TemplateOrderUpload"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
            <div class="card">
              <div class="card-header">
                <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                &nbsp;&nbsp;&nbsp;
                <h6
                  class="card-title mb-0 float-start"
                  style="padding-left: 25px"
                >
                  Transport Order Add
                </h6>
                <button
                  class="btn btn-success btn-xs float-end"
                  @click="downloadData"
                  type="button"
                >
                  <i class="link-icon" data-feather="download"></i>
                  {{ $t("downloadData") }} Order
                </button>
              </div>
              <div class="card-body">
                <!--begin::loading-data-->
                <div v-if="isLoading" class="d-flex align-items-center">
                  <div
                    class="spinner-border ms-auto"
                    role="status"
                    aria-hidden="true"
                  ></div>
                  <strong>{{ $t("loadingTxt") }}...</strong>
                </div>
                <!--end::loading-data-->

                <form
                  class="forms-sample"
                  @submit.prevent="submitData"
                  method="POST"
                  enctype="multipart/form-data"
                >
                  <div
                    class="d-flex justify-content-between align-items-baseline mb-2"
                  ></div>

                  <div class="row mb-3">
                    <div class="col-lg-2">
                      <label
                        for="defaultconfig"
                        class="col-form-label"
                        style="padding-left: 25px"
                        >File</label
                      >
                    </div>
                    <div class="col-lg-8">
                      <input
                        type="file"
                        ref="file"
                        class="form-control"
                        @change="handleFileUpload($event)"
                      />

                      <div v-if="errors.code">
                        <div
                          v-for="error in errors.code"
                          :key="error"
                          class="alert alert-primary"
                          role="alert"
                        >
                          <i data-feather="alert-circle"></i>
                          {{ error }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-footer">
                    <button
                      class="btn btn-warning text-black fw-bolder btn-icon-text"
                      @click="backForm"
                      type="button"
                    >
                      <i class="link-icon" data-feather="arrow-left"></i>
                      {{ $t("backMess") }}
                    </button>

                    &nbsp;&nbsp;&nbsp;
                    <button
                      :disabled="isLoading"
                      class="btn btn-primary float-end btn-sm"
                      type="submit"
                    >
                      <i class="link-icon" data-feather="save"></i>
                      {{ $t("submitFormTxt") }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <!--end::card-->
          </div>
          <modal-transport-error
            :showModal="showModalTransportError"
            :data="this.errors"
            @closeModal="closeMyModal"
          ></modal-transport-error>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import modalTransportOrderError from "@/components/modal/modalTransportOrderError";

export default {
  name: "CommodityUpload",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "modal-transport-error": modalTransportOrderError,
  },
  data() {
    return {
      showModalTransportError: false,
      file: "",
      permision_role: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      forms: { name: "", usernmae: "" },
    };
  },
  watch: {},
  methods: {
    resetForm() {},

    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    closeMyModal() {
      this.showModalTransportError = false;
    },
    downloadData() {
      const baseURI =
        this.$settings.endPoint + "/transport-order-header-new/download-template";

      var file_name = "template_upload_transport_order.xlsx";

      //var columnFilters = this.serverParams.columnFilters;

      return this.$http
        .get(baseURI, {
          responseType: "blob",
        })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (
          permision.permission.controller == "TransportOrderHeaderNewController"
        ) {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_upload) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },

    backForm() {
      window.location.href = "/order/new-transport-order";
    },

    submitData() {
      this.fade(true);
      let formData = new FormData();

      formData.append("file", this.file);

      const baseURI = this.$settings.endPoint + "transport-order-header-new/upload";
      this.$http
        .post(baseURI, formData)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            this.resetForm();
            this.errors = [];
            //console.log(response.data.datas.user_id);
            //var params = this.$onRandom(response.data.datas.company_id);
            window.location.href = "/order/new-transport-order";
            // this.success('Berhasil');
          } else {
            this.errors = response.data.errors;
            if (response.data.errors.length >= 1) {
              this.showModalTransportError = true;
            } else {
              this.resultError(response.data.errors);
            }
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              if (error.response.data.errors.length >= 1) {
                this.showModalTransportError = true;
              } else {
                this.resultError(error.response.data.errors);
              }
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          this.resetForm();
        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    this.fetchIt();
  },
};
</script>
<style scoped>
</style>