<template>
  <div>
    <div class="page-content">
      <div class="col-lg-12 col-xl-12 stretch-card">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-lg-12">
                <h4 style="margin-right: 5px" class="float-start">
                  FPPK  {{ $t("fppkMenuPenerimaan") }}
                </h4>

                <!-- <button type="button" @click="addData" class="btn-success btn btn-sm float-end">
                  <i class="link-icon" data-feather="plus"></i> 
                  {{ $t("fppkMenuPenerimaanBtnNew") }}
                </button> -->
              </div>

           
            </div>
          </div>
          <div class="card-body">
            <div class="row my-4">
              <div class="col-sm-1">
                <label class="form-label">Status</label>
              </div>
              <div class="col-sm-10">
                  <div class="mb-1">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="all"
                        v-model="statusAll"
                        @change ="checkAll($event.target.checked)"
                      />
                      <label class="form-check-label" for="all">
                        All
                      </label>
                    </div>
                    <div class="form-check form-check-inline" v-for="select in statusFilter" :key="select.value">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="select.value"
                        :value="select.value"
                        :name="select.value"
                        v-model="status"
                      />
                      <label class="form-check-label" :for="select.value">
                        {{select.text}}
                      </label>
                    </div>
                  </div>
              </div>
            </div>
            <div class="row my-3">
              <div class="col-lg-3">
                  <label class="form-label">Date Range</label>
                  <date-range-picker
                    style="width: 100%"
                    control-container-class="form-control"
                    :locale-data="locale"
                    v-model="dateRange"
                    :opens="opens"
                    :showDropdowns="true"
                    :ranges="customRange"
                    :singleDatePicker="false"
                    :timePicker24Hour="false"
                    :timePicker="false"
                    :showWeekNumbers="false"
                  >
                  </date-range-picker>
              </div>
              <div class="col-lg-2">
                <label class="form-label">Filter By</label>
                <v-select
                :options="searchBy"
                label="text"
                :reduce="filter=>filter.field"
                v-model="search.filterBy"
                >

                </v-select>
                
              </div>
              <div class="col-lg-3">
                <label class="form-label">Search</label>
                <input type="text" class="form-control" v-model="search.text">
              </div>
              <div class="col-lg-2">
                <div class="mb-5">
                  <button style="margin-right: 10px;" class="btn btn-primary mt-4 " type="button" @click="searchFunction" >Search</button>
                  <button class="btn btn-warning mt-4 " type="button" @click="resetBtn" >Reset</button>
                </div>
              </div>

             
            </div>
           
            <vue-good-table
                  title="list-user"
                  mode="remote"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  :totalRows="totalRecords"
                  :line-numbers="true"
                  :isLoading.sync="isLoading"
                  :pagination-options="{
                    enabled: true,
                  }"
                  :select-options="{
                    enabled: true,
                    selectOnCheckboxOnly: true,
                    selectionText: $t('data_selected'),
                    clearSelectionText: $t('clear'),
                  }"
                  :rows="rows"
                  :columns="columns"
                >
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'actions'">
                      <a
                      v-if="props.row.status_fppk!='Cancelled' && props.row.status_fppk!='Selesai Service' && props.row.status_fppk!='Expired'"
                        href="#"
                        title="Edit"
                        @click.prevent="editData(props.index, props.row)"
                      >
                        <i
                          class="mdi mdi-square-edit-outline"
                          style="font-size: 16px"
                        ></i>
                      </a>

                      <!-- <a
                      
                        href="#"
                        title="Status"
                        @click.prevent="
                          btnModalShowCancel(props.row.fppk_id)
                        "
                      >
                        <i
                          class="mdi mdi-close-circle"
                          style="font-size: 16px"
                        ></i>
                      </a> -->

                      <!-- <a
                        v-if="props.row.status == 'ENABLED'"
                        href="#"
                        title="Status"
                        @click.prevent="
                          confirmStatus(props.index, props.row, 'DISABLED')
                        "
                      >
                        <i
                          class="mdi mdi-close-box-outline"
                          style="font-size: 16px"
                        ></i>
                      </a> -->
                      <!-- <a
                        href="#"
                        title="Reopen FPPK"
                        v-if="props.row.status_fppk=='Expired'"
                        @click.prevent="updateStatus(props.row,'Reopen')"
                      >
                        <i class="mdi mdi-restore" style="font-size: 16px"></i>
                      </a> -->
                      <a
                        v-if="props.row.status_fppk=='Selesai Service' && props.row.konfirmasiFlag==0"
                        href="#"
                        title="Konfirmasi"
                        @click.prevent="updateStatus(props.row,'Konfirmasi')"
                      
                      >
                        <i class="mdi mdi-checkbox-marked-circle" style="font-size: 16px"></i>
                      </a>
                      <a
                        href="#"
                        v-if="props.row.status_fppk=='Selesai Service' && props.row.eskalasiFlag==0"
                        title="Eskalasi"
                        @click.prevent="showEskalasi(props.row)"
                      >
                        <i class="mdi mdi-sort-descending" style="font-size: 16px"></i>
                      </a>
                      <a
                        href="#"
                        title="Detail"
                        @click.prevent="detailData(props.index, props.row)"
                      >
                        <i class="mdi mdi-eye" style="font-size: 16px"></i>
                      </a>
                    </span>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>
                </vue-good-table>
          </div>


          <modal-cancel-fppk :showModal="showModalCancelFppk" :fppk_id="fppk_id"
              @closeModal="closeMyModalCancel"></modal-cancel-fppk>

          <modal-eskalasi-fppk :showModal="showModalEskalasiFppk" :fppk_id="fppk_id"
              @closeModal="closeMyModalEskalasi" :data="dataShowEskalasi"></modal-eskalasi-fppk>

        </div>
      </div>
    </div>

    
  </div>
</template>

<script>
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import modalCancelFppk from "@/components/modal/modalCancelFppk.vue";
import modalEskalasiFppk from "@/components/modal/modalEskalasiFppk.vue";

export default {
  name: "FppkPengajuan",
  components: {
    DateRangePicker,
    "modal-cancel-fppk": modalCancelFppk,
    "modal-eskalasi-fppk": modalEskalasiFppk,
  },
  data() {
    // const startDate = moment(Date.now()).format("YYYY-MM-DD");
    // //const startDate = new Date();
    // const endDate = moment(Date.now()).format("YYYY-MM-DD");

    // let today = new Date();
    // today.setHours(0, 0, 0, 0);

    // let yesterday = new Date();
    // yesterday.setDate(today.getDate() - 1);
    // yesterday.setHours(0, 0, 0, 0);

    // const current = new Date();
    // let firstDay = current.getDate() - current.getDay();

    const startDate = moment().subtract(7, 'd').format('YYYY-MM-DD');
    //const startDate = new Date();
    const endDate = moment(Date.now() + 7 * 24 * 3600 * 1000).format('YYYY-MM-DD'); 

    return {
      fppk_id:'',
      showModalEskalasiFppk:false,
      dataShowEskalasi:'',
      showModalCancelFppk:false,
    
      // customRange: {
      //   Today: [today, today],
      //   Yesterday: [yesterday, yesterday],
      //   "This Week": [
      //     new Date(current.setDate(firstDay)),
      //     new Date(current.setDate(current.getDate() + 6)),
      //   ],
      //   "Last  Week": [
      //     new Date(current.setDate(firstDay - 7)),
      //     new Date(current.setDate(firstDay - 1)),
      //   ],
      //   "This Month": [
      //     new Date(today.getFullYear(), today.getMonth(), 1),
      //     new Date(today.getFullYear(), today.getMonth() + 1, 0),
      //   ],
      //   "Last month": [
      //     new Date(today.getFullYear(), today.getMonth() - 1, 1),
      //     new Date(today.getFullYear(), today.getMonth(), 0),
      //   ],
      //   "This year": [
      //     new Date(today.getFullYear(), 0, 1),
      //     new Date(today.getFullYear(), 11, 31),
      //   ],
      // },
      dateRange: {
        startDate: startDate,
        endDate: endDate,
      
      },
      statusFilter:[
        // {
        //   text:"Draft",
        //   value:"Draft"
        // },
        {
          text:"Pengajuan",
          value:"Pengajuan"
        },
        {
          text:"Mulai Service",
          value:"Mulai Service" 
        },
        {
          text:"Selesai Service",
          value:"Selesai Service"
        },
        {
          text:"Expired",
          value:"Expired"
        },
        {
          text:"Cancelled",
          value:"Cancelled"
        },
        {
          text:"Re Open",
          value:"Reopen"
        },
      ],
      status: [],
      statusAll:false,
      searchBy :[
        {
          text:"Route",
          field:"route"
        },
        {
          text:"Nopol",
          field:"nopol"
        },
        {
          text:"Merk",
          field:"merk"
        },
        {
          text:"Jenis Unit",
          field:"jenis_unit"
        },
        {
          text:"FPPK No",
          field:"no_fppk"
        },
        {
          text:"Status",
          field:"status"
        },
      ],
      search:[],
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [
      {
            label: "Action",
            field: "actions",
            width: "100px",
          },
          {
            label: "No FPPK",
            field: "no_fppk",
          },
          {
            label: "Tanggal FPPK",
            field: "tanggal_fppk",
          },
          {
            label: "Status FPPK",
            field: "status_fppk",
          },
          {
            label: "Route",
            field: "route",
          },
          {
            label: "Nopol",
            field: "nopol",
          },
          {
            label: "Merk",
            field: "merk",
          },
          {
            label: "Jenis Unit",
            field: "jenis_unit",
          },
          {
            label: "Driver",
            field: "driverName",
          },
          {
            label: "Km Odo",
            field: "km_odo",
          },
          {
            label: "Keluhan",
            field: "keluhan1",
          },
          {
            label: "Lokasi Unit",
            field: "lokasi_unit",
          },
          {
            label: "Status",
            field: "status",
          },
          {
            label: "Type Service",
            field: "type_service",
          },
          {
            label: "Tanggal Mulai",
            field: "tgl_mulai_service",
          },
          {
            label: "Tanggal Estimasi",
            field: "tanggal_estimasi_selesai",
          },
          {
            label: "Tanggal Selesai",
            field: "tgl_selesai_service",
          }
      ],
      rows:[],
      totalRecords:0,
      maxToasts: 100, 
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      permision_role: [],
      langs: ["id", "en"],
      opens: "center",
      locale: {
        format: "yyyy-mm-dd",
      },
    };
  },
  methods: {
    addData(){
      window.location.href = "/fppk-penerimaan/add";
    },
    checkAll(ev){
      if(ev==true){
        this.status=['Pengajuan','Mulai Service', 'Selesai Service', 'Expired', 'Cancelled', 'Reopen']
      }else{
        this.status=[]
      }
      
      // console.log(ev)
    },
    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },


    submitToCt(row) {
     
     

      this.fade(true);
      const baseURI = this.$settings.endPointCt + "master-truck/update-truck-status";
      this.$http
        .post(baseURI, row)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            // this.updateStatus(row.fppk_id, 'Pengajuan')

          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              if (this.errors.message) {
                this.error(this.errors.message);
              } else {
                this.resultError(error.response.data.errors);
              }
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },


    showEskalasi(row){
     
      var dataSend={
        status:"Eskalasi"
      }
      const baseURI = this.$settings.endPointFppk + "v1/penerimaan-fppk/update-status-fppk/"+row.fppk_id;
      this.$http
        .post(baseURI, dataSend)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            this.dataShowEskalasi=response.data.datas;

            if(this.dataShowEskalasi==null){
                this.showModalEskalasiFppk=true;
            }else{
                this.showModalEskalasiFppk=false;
            }
                

            this.showModalEskalasiFppk=true;


           
            // this.updateStatus(row.fppk_id, 'Pengajuan')

          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
       
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              if (this.errors.message) {
                this.error(this.errors.message);
              } else {
                this.resultError(error.response.data.errors);
              }
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },


    updateStatus(row,status) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: 'Update Status to: '+status,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // if(status=='Pengajuan'){
          //   this.submitToCt(row);
          // }

          const baseURI = this.$settings.endPointFppk + "v1/penerimaan-fppk/update-status-fppk/"+row.fppk_id;
        this.$http
          .post(baseURI, {
            status:status
          })
          .then((response) => {
            this.isLoading=false;
            if (response.data.status === 200) {

              this.loadItems()
              this.success("Success");
             
            } else {
             
              this.errors = response.data.errors;
              this.resultError(response.data.errors);
            }


          })
          .catch((error) => {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              
              }
            }
            this.isloading = false;
          });
        }

      })
      

    },

    resetBtn(){
      // this.dateRange= {
      //   startDate:null,
      //   endDate:null
      // };
      this.search.text='';
      this.search.filterBy='';
      this.status=[];
      this.loadItems();
    },

    searchFunction(){
      this.row=[];

      var data={}
         

      if(this.status.length > 0 ){
        data["status_fppks"]=this.status;
      }

      if(this.dateRange.startDate!=undefined || this.dateRange.endDate!=undefined){
        data['tanggal_fppk']=moment(this.dateRange.startDate).format("YYYY-MM-DD")+'~'+moment(this.dateRange.endDate).format("YYYY-MM-DD")
      }
      
      data[this.search.filterBy]=this.search.text
      // console.log(data);

      this.fade(true);
      const baseURI = this.$settings.endPointFppk + "v1/penerimaan-fppk";
    
      return this.$http
        .post(
          baseURI +
            `?limit=${this.serverParams.per_page}&page=${
              this.serverParams.page
            }&sort_field=${this.serverParams.sort.field}&sort_type=${
              this.serverParams.sort.type
            }
          `,data
        )
        .then((response) => {
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
          this.loading();
        }) .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              if (this.errors.message) {
                this.error(this.errors.message);
              } else {
                this.resultError(error.response.data.errors);
              }
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
     
    },


    detailData(index, row) {
      var params = this.$onRandom(row.fppk_id);
      window.location.href = "/fppk-penerimaan/detail/" + params;
    },

    editData(index, row) {
      var params = this.$onRandom(row.fppk_id);
      window.location.href = "/fppk-penerimaan/edit/" + params;
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    closeMyModalCancel() {
      this.showModalCancelFppk = false;
      this.loadItems();
    },

    closeMyModalEskalasi() {
      this.showModalEskalasiFppk = false;
      this.loadItems();
    },


    loadItems() {
     
      this.fade(true);
      const baseURI = this.$settings.endPointFppk + "v1/penerimaan-fppk";
     

      return this.$http
        .post(
          baseURI +
            `?limit=${this.serverParams.per_page}&page=${
              this.serverParams.page
            }&sort_field=${this.serverParams.sort.field}&sort_type=${
              this.serverParams.sort.type
            }
          `
        )
        .then((response) => {
          this.search.filterBy='nopol'
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
          this.loading();
        });
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "PenerimaanFppkController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    btnModalShowCancel(fppk_id){
      this.fppk_id=fppk_id
      this.showModalCancelFppk=true
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },
  },

  mounted() {
    this.loadItems();
    this.fetchIt();
    this.loading();
  },
};
</script>

<style>
</style>