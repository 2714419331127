<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>   
    <div class="loadertext" v-if="this.isLoading"> {{$t('loadingTxt')}}...</div>   
    <div class="main-wrapper" :class="{'blur-content': this.isLoading}">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TraffictViolationDetail"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            "
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
               <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 25px"
                  >
                  {{ $t("trafficViolation") }} Detail
                  </h6>

                  <button
                    class="btn btn-light float-end"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <i class="link-icon" data-feather="info"></i>
                    Audit Info
                  </button>

                  <div
                    class="row mb-3 collapse"
                    id="collapseExample"
                    style="padding-left: 20px"
                  >
                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created By:</label>
                      <p class="text-muted">{{ this.forms.create_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created At:</label>
                      <p class="text-muted">{{ this.forms.created_at }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated By:</label>
                      <p class="text-muted">{{ this.forms.update_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated At:</label>
                      <p class="text-muted">{{ this.forms.updated_at }}</p>
                    </div>
                  </div>
                </div>

                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->
                   <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "
                    ></div>
                    <div class="row">
                      <div class="col-sm-6">
                         <div class="row mb-3">
                      <div class="col-lg-3">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Status</label
                        >
                      </div>
                      <div class="col-lg-8">
                      <v-select
                         
                          v-model="status"
                          label="status"
                          return-object
                          disabled
                        ></v-select>
                        <div v-if="errors.status">
                          <div
                            v-for="error in errors.status"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-3">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Date</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input readonly type="text" id="load_number" name="load_number" v-model="forms.tv_date"
                              class="form-control" read-only />
                        <div v-if="errors.tv_date">
                          <div
                            v-for="error in errors.tv_date"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    
                   
                        <div class="row mb-3">
                          <div class="col-lg-3">
                            
                            <label for="defaultconfig" style="padding-left: 25px" 
                          class="col-form-label">Load ID<span class="wajib">*</span></label>
                          </div>
                          <div class="col-lg-8">
                            <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                            <input readonly type="text" id="load_number" name="load_number" v-model="forms.load_number"
                              class="form-control" placeholder="Load ID" read-only />


                            <div v-if="errors.load_number">
                              <div v-for="error in errors.load_number" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-8">
                            <!-- <button class="btn btn-success" @click.prevent="openModal()" style="margin-right:10px">Browse</button>
                            <button class="btn btn-danger" @click.prevent="clearLoad()">No Load</button> -->
                          </div>

                         

                        </div>
                        

                        <div class="row mb-3">
                          <div class="col-lg-3">
                            
                          <label for="defaultconfig" style="padding-left: 25px" 
                          class="col-form-label">Vehicle No<span class="wajib">*</span></label>
                          </div>
                          <div class="col-lg-8">
                            <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                            <input readonly type="text" id="vehicle_no" name="vehicle_no" v-model="forms.vehicle_no"
                              class="form-control" read-only />


                            <div v-if="errors.load_number">
                              <div v-for="error in errors.load_number" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-3">
                            
                          <label for="defaultconfig" style="padding-left: 25px" 
                          class="col-form-label">Driver NMK<span class="wajib">*</span></label>
                          </div>
                          <div class="col-lg-8">
                            <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                            <input readonly type="text" id="driver_nmk" name="driver_nmk" v-model="forms.driver_nmk"
                              class="form-control" read-only />


                            <div v-if="errors.load_number">
                              <div v-for="error in errors.load_number" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-3">
                            
                          <label for="defaultconfig" style="padding-left: 25px" 
                          class="col-form-label">Driver Name<span class="wajib">*</span></label>
                          </div>
                          <div class="col-lg-8">
                            <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                            <input readonly type="text" id="driver_name" name="driver_name" v-model="forms.driver_name"
                              class="form-control" read-only />


                            <div v-if="errors.load_number">
                              <div v-for="error in errors.load_number" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-3">
                            
                          <label for="defaultconfig" style="padding-left: 25px" 
                          class="col-form-label">Driver Hp<span class="wajib">*</span></label>
                          </div>
                          <div class="col-lg-8">
                            <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                            <input type="text" id="driver_hp" name="driver_hp" v-model="forms.driver_hp"
                              class="form-control" readonly />


                            <div v-if="errors.driver_hp">
                              <div v-for="error in errors.load_number" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        

                        <div class="row">
                          <div class="col-lg-3">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px" 
                              >Category</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <v-select
                             
                              v-model="category"
                              label="name"
                              return-object
                              disabled
                            ></v-select>

                            <div v-if="errors.category">
                              <div
                                v-for="error in errors.category"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row">
                          <div class="col-lg-3">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Reason</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <v-select
                              
                              v-model="reason"
                              label="name"
                              return-object
                              disabled
                            ></v-select>

                            <div v-if="errors.reason">
                              <div
                                v-for="error in errors.category"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="col-sm-6">
                           <div class="row mb-3">
                          <div class="col-lg-3">
                            
                          <label for="defaultconfig" style="padding-left: 25px" 
                          class="col-form-label">Location</label>
                          </div>
                          <div class="col-lg-8">
                            <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                            <input type="text" id="location" name="location" v-model="forms.location"
                              class="form-control" readonly/>


                            <div v-if="errors.location">
                              <div v-for="error in errors.location" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-3">
                            
                          <label for="defaultconfig" style="padding-left: 25px" 
                          class="col-form-label">Detail</label>
                          </div>
                          <div class="col-lg-8">
                            <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                            <!-- <input type="text" id="location" name="location" v-model="forms.location"
                              class="form-control" /> -->
                              <textarea class="form-control" v-model="forms.tv_detail" cols="10" disabled></textarea>


                            <div v-if="errors.tv_detail">
                              <div v-for="error in errors.tv_detail" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-3">
                            
                          <label for="defaultconfig" style="padding-left: 25px" 
                          class="col-form-label">Result</label>
                          </div>
                          <div class="col-lg-8">
                            <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                            <input type="text" id="tv_result" name="tv_result" v-model="forms.tv_result"
                              class="form-control" disabled/>


                            <div v-if="errors.tv_result">
                              <div v-for="error in errors.tv_result" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-3">
                            
                          <label for="defaultconfig" style="padding-left: 25px" 
                          class="col-form-label">Investigation Result</label>
                          </div>
                          <div class="col-lg-8">
                            <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                            <!-- <input type="text" id="location" name="location" v-model="forms.location"
                              class="form-control" /> -->
                              <textarea class="form-control" v-model="forms.tv_investigation_result" cols="10" disabled></textarea>


                            <div v-if="errors.tv_investigation_result">
                              <div v-for="error in errors.tv_investigation_result" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-3">
                            
                          <label for="defaultconfig" style="padding-left: 25px" 
                          class="col-form-label">Action Plan</label>
                          </div>
                          <div class="col-lg-8">
                            <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                            <!-- <input type="text" id="location" name="location" v-model="forms.location"
                              class="form-control" /> -->
                              <textarea class="form-control" v-model="forms.tv_action_plan" cols="10" disabled></textarea>


                            <div v-if="errors.tv_action_plan">
                              <div v-for="error in errors.tv_action_plan" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-3">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Date Finish</label
                            >
                          </div>
                          <div class="col-lg-8">
                        <input readonly type="text" id="tv_date_finish" name="tv_date_finish" v-model="forms.tv_date_finish"
                              class="form-control" read-only />

                            <!-- <Datepicker v-model="forms.tv_date_finish" :bootstrap-styling=true format="dd-MM-yyyy"
                            placeholder="Please Select Date..."></Datepicker> -->
                            <div v-if="errors.tv_date_finish">
                              <div
                                v-for="error in errors.tv_date_finish"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                      </div>
                        <div class="row mb-3">
                          <div class="col-lg-3">
                            
                          <label for="defaultconfig" style="padding-left: 25px" 
                          class="col-form-label">Cost</label>
                          </div>
                          <div class="col-lg-8">
                            <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                            <input type="number" id="tv_cost" name="tv_cost" v-model="forms.tv_cost"
                              class="form-control"  disabled/>


                            <div v-if="errors.tv_cost">
                              <div v-for="error in errors.tv_cost" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                   
                     
                    <div class="card-footer">
                      <button
                        class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        "
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>

                      <a
                        v-if="permision_role.method_delete"
                        class="
                          btn btn-danger
                          text-white
                          fw-bolder
                          btn-icon-text
                          float-end
                        "
                        @click="deleteData()"
                      >
                        <i class="link-icon" data-feather="trash"></i>
                        {{ $t("deleteMess") }}
                      </a>

                      <a
                        v-if="permision_role.method_update"
                        class="
                          btn btn-primary
                          me-2
                          text-white
                          fw-bolder
                          btn-icon-text
                          float-end
                        "
                        @click="editData()"
                      >
                        <i class="link-icon" data-feather="edit"></i>
                        {{ $t("editMess") }}
                      </a>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";

export default {
  name: "BranchDetail",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      category:"",
      status:"",
      reason:"",
      poolAll:[],
      permision_role:[],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      forms: [],
    };
  },
  watch: {},
  methods: {
      isNumberLatLong(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 45) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    resetForm() {

    },

    cek_message(status, msg) {
      if (status == "200") {
        this.success(msg);
      }
    },

    editData() {
      var params = this.$route.params.id;
      window.location.href = "/traffict-violation/edit/" + params;
    },

    deleteData() {
      var id = this.$onBehind(this.$route.params.id);
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI = this.$settings.endPointCt + "traffict-violation/delete/" + id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                window.location.href = "/traffict-violation";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },



    backForm() {
      window.location.href = "/traffict-violation";
    },

    loadData(id) {
      this.isLoading = true
      const baseURI = this.$settings.endPointCt + "driver-tv/detail/" + id;
      return this.$http.get(baseURI,{ timeout: 10000 }).then((response) => {
           this.isLoading = false
        
          this.forms    = response.data.datas;
          this.status=this.forms.tv_status ;
          this.category=this.forms.category;
          this.reason=this.forms.reason;

      }).catch(error => {
          this.isLoading = false;
          if(error.code == "ECONNABORTED"){
            this.error("Please Check Your Internet Connection");
          }else{

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
            
          }


        });
    },


    fetchIt() {
      const userDatas     = this.$getUserInfo();
      this.detailUser     = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function(permision){
        if(permision.permission.controller == 'DriverTvController'){
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if(!rolePermision.method_read){
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;

    },


    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.cek_message(this.$route.query.status, this.$route.query.msg);
    this.loadData(params[0]);
    this.fetchIt();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content{
  filter: blur(5px); 
  pointer-events: none;
}
</style>