<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div :class="{ 'blur-content': this.isLoading }">
      <div class="main-wrapper">
        <!--begin::sidebar-->
        <sidebar-component classMenu="TransportOrderCt"></sidebar-component>
        <!--end::sidebar-->

        <div class="page-wrapper">
          <!--begin::navbar-->
          <navbar-component></navbar-component>
          <!--end::navbar-->

          <!--begin::content-->
          <div class="page-content">

            <div>
              <h4 style="margin-right: 5px" class="float-start"></h4>
              <h4 class="mb-12 mb-md-0">
                {{ $t("driver_attendance_txt") }}
              </h4>
              <br />
            </div>

            <div class="col-lg-12 col-xl-12 stretch-card">
              <div class="card">
                <div class="card-header">
                  <div class="row">
                    <!--div class="col-lg-6">
                      <input type="text" class="form-control bg-transparent border-info flatpickr-input active" 
                      :value="tanggal" data-input="" readonly="readonly">
                    </div-->
                    <div class="col-lg-3">
                      <Datepicker v-model="filtertanggal" :bootstrap-styling=true format="dd-MM-yyyy"
                        placeholder="Please Select Date..."></Datepicker>
                    </div>

                    <div class="col-lg-3">
                      <button v-if="permision_role.method_custom1" class="button btn-lg btn-success"
                        @click="loadItems()">Go</button>
                      <span>{{ " " }}</span>
                      <button v-if="permision_role.method_custom2" class="button btn-lg btn-warning"
                        @click="calculate()">Calculate</button>
                    </div>

                    <div class="col-lg-6" style="text-align: right;">
                      <button v-if="permision_role.method_custom2" type="button"
                        class="btn btn-success btn-icon-text mb-2 mb-md-0 ml-1" @click="downloadData"
                        style="margin-right: 5px">
                        {{ $t("downloadData") }}
                      </button>
                      <button v-if="permision_role.method_custom2"
                        class="btn btn-primary btn-icon-text mb-2 mb-md-0 ml-1" style="margin-right: 5px"
                        @click.prevent="recap()">
                        Recap Data
                      </button>

                      <button v-if="permision_role.method_custom1"
                        class="btn btn-success btn-icon-text mb-2 mb-md-0 ml-1" style="margin-right: 5px"
                        @click.prevent="recapData()">
                        Back To Recap
                      </button>
                    </div>
                  </div>

                </div>

                <div class="card-body">

                  <div class="table-responsive" style="overflow-y: auto;">
                    <div class="col-sm-12">

                      <vue-good-table title="list-order-ct" mode="remote" :select-options="{
                        enabled: true,
                        selectOnCheckboxOnly: true,
                        selectionText: $t('data_selected'),
                        clearSelectionText: $t('clear'),
                      }" @on-selected-rows-change="selectionChanged" @on-page-change="onPageChange"
                        @on-sort-change="onSortChange" @on-column-filter="onColumnFilter"
                        @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :line-numbers="true"
                        :isLoading.sync="isLoading" :pagination-options="{
                          enabled: false,
                        }" :rows="rows" :columns="columns">
                        <div slot="table-actions">

                          {{ $t("no_select_data") }}
                        </div>
                        <div slot="selected-row-actions">

                          <!--select class="form-control" v-model="selected">
                                <option disabled value="">Please select one</option>
                                <option>NO DRIVER</option>
                                <option>B</option>
                                <option>C</option>
                                </select-->

                          <button type="button" v-if="permision_role.method_custom2" class="btn btn-primary btn-xs"
                            style="margin-left:5px;" @click="submitUpdate('AIS')">AIS</button>

                          <button type="button" v-if="permision_role.method_custom2" class="btn btn-warning btn-xs"
                            style="margin-left:5px;" @click="submitUpdate('NO DRIVER')">NO DRIVER</button>


                          <button type="button" v-if="permision_role.method_custom2" class="btn btn-info btn-xs"
                            style="margin-left:5px;" @click="submitUpdate('IDLE')">IDLE</button>

                          <!--button class="btn btn-danger btn-xs" ><i class="mdi mdi-close"></i>  {{ $t("reject_order_txt") }} </button-->
                        </div>



                        <template slot="table-row" slot-scope="props">
                          <span v-if="props.column.field == 'ais'">
                            <button class="btn btn-primary btn-xs" v-if="permision_role.method_custom2"
                              style="padding: 3px;font-size: 12px;border-radius: 5px;width:100%;"
                              @click.prevent="submitUpdateRow('AIS', props.row.truck_utility_detail_id)">AIS</button>
                          </span>

                          <span v-else-if="props.column.field == 'nodriver'">
                            <button class="btn btn-warning" v-if="permision_role.method_custom2"
                              style="padding: 3px;font-size: 12px;border-radius: 5px;width:100%;"
                              @click.prevent="submitUpdateRow('NO DRIVER', props.row.truck_utility_detail_id)">NODRIVER</button>

                            <!--a style="color: #ab0635;text-decoration: none;"
                                      v-if="permision_role.method_read"
                                      title="Detail"
                                      @click.prevent="
                                        detailData(props.index, props.row)
                                      "
                                    >
                                      <i class="mdi mdi-eye" style="font-size:16px"></i>
                                      
                                    </a>
  
                                    
                                    <a style="color: #ab0635;text-decoration: none;"
                                      v-if="permision_role.method_update"
                                      title="Edit"
                                      @click.prevent="editData(props.index, props.row)"
                                    >
                                      <i class="mdi mdi-square-edit-outline" style="font-size:16px"></i>
                                      
                                    </a-->

                          </span>
                          <span v-else-if="props.column.field == 'idle'">
                            <button class="btn btn-info btn-xs" v-if="permision_role.method_custom2"
                              style="padding: 3px;font-size: 12px;border-radius: 5px;width:100%;"
                              @click.prevent="submitUpdateRow('IDLE', props.row.truck_utility_detail_id)">IDLE</button>
                          </span>
                          <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                          </span>
                        </template>
                      </vue-good-table>



                    </div>

                  </div>
                </div>

              </div>
            </div>



          </div>
          <!--end::content-->




          <!--begin::footer-->
          <footer-component></footer-component>
          <!--end::footer-->
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import Datepicker from "vuejs-datepicker";

export default {
  name: "DriverAttendance",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    Datepicker
  },
  data() {
    return {
      maxToasts: 100,
      filtertanggal: new Date(),
      tanggal: '',
      selected: '',
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      permision_role: [],
      truck_utility_detail_ids: [],
      langs: ["id", "en"],
      detailUser: [],
      detailDivisi: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 1000,
      },
      columns: [
        {
          label: "Vehicle No",
          field: "vehicle_no",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Vehicle No", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Status",
          field: "unit_allocation",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Status", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Project",
          field: "project",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Project", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Driver ID",
          field: "driver_id",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Driver ID", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Driver Name",
          field: "driver_name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Driver Name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Branch Name",
          field: "branch_name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Branch Name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "SIM Type",
          field: "sim_type",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By SIM", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "SIM Expired",
          field: "sim_expire_date",
        },

        {
          label: "HP",
          field: "hp1",
        },

        {
          label: "Ethnic",
          field: "ethnic",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Ethnic", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Status",
          field: "status",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Status", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: ["IDLE", "NO DRIVER","AIS"], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "",
          field: "ais",
          sortable: false,
        },

        {
          label: "",
          field: "nodriver",
          sortable: false,
        },
        {
          label: "",
          field: "idle",
          sortable: false,
        },
      ],
      rows: [],
      rowsSelection: [],
    };
  },
  watch: {},
  computed: {
  },
  methods: {
    downloadData() {
      this.tanggal = this.filtertanggal.length == 10 ? this.filtertanggal : this.filtertanggal.toISOString().split('T')[0]
      this.isLoading = true;
      const baseURI = this.$settings.endPointCt + "truck-utility-detail";

      var file_name = `absence_driver_` + this.tanggal + `.xlsx`;

      return this.$http
        .get(
          baseURI
          +
          `?date=${this.tanggal}&limit=2000` +
          `&absence=absence` +
          `&download=download` +
          `&sort_field=${this.serverParams.sort.field}` +
          `&sort_type=${this.serverParams.sort.type}` +
          `&sort_field=${this.serverParams.sort.field}` +
          `&vehicle_no=${this.serverParams.columnFilters.vehicle_no}` +
          `&unit_allocation=${this.serverParams.columnFilters.unit_allocation}` +
          `&project=${this.serverParams.columnFilters.project}` +
          `&driver_id=${this.serverParams.columnFilters.driver_id}` +
          `&driver_name=${this.serverParams.columnFilters.driver_name}` +
          `&branch_name=${this.serverParams.columnFilters.branch_name}` +
          `&sim_type=${this.serverParams.columnFilters.sim_type}` +
          `&ethnic=${this.serverParams.columnFilters.ethnic}` +
          `&status=${this.serverParams.columnFilters.status}`,
          { responseType: "blob" }
        )
        .then((response) => {

          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    selectionChanged(ev) {
      this.rowsSelection = [];
      this.truck_utility_detail_ids = [];
      this.rowsSelection = ev.selectedRows;

      this.rowsSelection.forEach((item) => {
        this.truck_utility_detail_ids.push(item.truck_utility_detail_id);
      });

    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == 'TruckUtilityDetailController') {
          rolePermision = permision;
        }
      });

      if (!rolePermision.method_custom1) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;


      if (this.$route.query.msg) {
        if (this.$route.query.status == 200) {
          this.success(this.$route.query.msg)
        } else {
          this.error(this.$route.query.msg)
        }

      }

    },

    recap() {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
          let data = {
            date: this.tanggal
          }
          const baseURI =
            this.$settings.endPointCt + "truck-utility-detail/recap";
          this.$http
            .post(baseURI, data, { timeout: 30000 })
            .then((response) => {
              if (response.data.status === 200) {
                this.$router.push({ path: '/absensirecap' })
                this.success2(response.data.datas.messages);

                // this.loadItems();
                // this.isloading = false
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            }).catch(error => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {
                this.isLoading = false;
                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.error(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.$router.push('/server-error');
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push('/page-not-found');
                  }
                }

              }

            });
        }
      });

    },

    calculate() {
      this.$swal({
        title: "Calculate",
        text: "This calculation need a minute.. Please wait",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
          let data = {
            date: this.filtertanggal.length == 10 ? this.filtertanggal : this.filtertanggal.toISOString().split('T')[0]
          }
          const baseURI =
            this.$settings.endPointCt + "truck-utility-detail/calculate";
          this.$http
            .post(baseURI, data, { timeout: 30000 })
            .then((response) => {
              if (response.data.status === 200) {
                this.success2(response.data.datas.messages);
                this.loadItems();
                this.isloading = false
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            }).catch(error => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {

                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.$router.push('/server-error');
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push('/page-not-found');
                  }
                }

              }

            });
        }
      });




    },


    submitUpdate(val_status) {

      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
          let data = {
            truck_utility_detail_ids: this.truck_utility_detail_ids,
            status: val_status
          }
          const baseURI =
            this.$settings.endPointCt + "truck-utility-detail/update";
          this.$http
            .patch(baseURI, data, { timeout: 30000 })
            .then((response) => {
              if (response.data.status === 200) {
                this.success2(response.data.datas.messages);
                this.loadItems();
                this.isloading = false
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            }).catch(error => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {

                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.$router.push('/server-error');
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push('/page-not-found');
                  }
                }

              }

            });
        }
      });




    },

    submitUpdateRow(val_status, id) {

      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
          let data = {
            truck_utility_detail_ids: id.toString().split(','),
            status: val_status
          }
          const baseURI =
            this.$settings.endPointCt + "truck-utility-detail/update";
          this.$http
            .patch(baseURI, data, { timeout: 30000 })
            .then((response) => {
              if (response.data.status === 200) {
                this.success2(response.data.datas.messages);
                this.loadItems();
                this.isloading = false
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            }).catch(error => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {

                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.$router.push('/server-error');
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push('/page-not-found');
                  }
                }

              }

            });
        }
      });
    },

    updateFormatDate(date) {
      let date_format = this.$moment(date).format("dd mmm yyyy");
      return date_format;
    },

    editData(index, row) {
      var params = this.$onRandom(row.transport_order_header_id);
      window.location.href = "/ct-orders/edit/" + params;
    },

    recapData() {
      this.$router.push({ path: '/absensirecap' })
    },

    detailData(index, row) {
      var params = this.$onRandom(row.transport_order_header_id);
      window.location.href = "/ct-orders/detail/" + params;
    },


    // load items is what brings back the rows from server
    loadItems() {
      this.tanggal = this.filtertanggal.length == 10 ? this.filtertanggal : this.filtertanggal.toISOString().split('T')[0]
      this.isLoading = true;
      const baseURI = this.$settings.endPointCt + "truck-utility-detail";

      return this.$http
        .get(
          baseURI
          +
          `?date=${this.tanggal}&limit=2000` +
          `&absence=absence` +
          `&sort_field=${this.serverParams.sort.field}` +
          `&sort_type=${this.serverParams.sort.type}` +
          `&sort_field=${this.serverParams.sort.field}` +
          `&vehicle_no=${this.serverParams.columnFilters.vehicle_no}` +
          `&unit_allocation=${this.serverParams.columnFilters.unit_allocation}` +
          `&project=${this.serverParams.columnFilters.project}` +
          `&driver_id=${this.serverParams.columnFilters.driver_id}` +
          `&driver_name=${this.serverParams.columnFilters.driver_name}` +
          `&branch_name=${this.serverParams.columnFilters.branch_name}` +
          `&sim_type=${this.serverParams.columnFilters.sim_type}` +
          `&ethnic=${this.serverParams.columnFilters.ethnic}` +
          `&status=${this.serverParams.columnFilters.status}`,
          { timeout: 10000 }
        )
        .then((response) => {
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
          this.isLoading = false;
        }).catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    success2(msg) {
      // let pesan="";
      // msg.forEach((item) => {
      //     pesan=pesan+"<p>* "+item+"</p>";
      // });

      this.$swal({
        icon: 'info',
        backdrop: true,
        title: "Information",
        html: msg,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      })


    },


    loadingSubmiting() {


      this.$swal({
        icon: 'warning',
        backdrop: true,
        title: "Please wait",
        html: '<h2>SUBMITTING ORDER...</h2>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

    },


    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },

  },
  events: {},
  created: function () { },
  mounted() {
    this.fetchIt();
    this.loading();
    this.filtertanggal = this.$route.params.date;
    this.loadItems();


  },
};
</script>
<style scoped>
.blur-content {
  filter: blur(5px);
  pointer-events: none;
}

.card-body {
  padding: 5px 1.5rem 1rem;
}

.vgt-table th {
  text-align: left !important;
}

.toggleFilterClass {
  display: none !important;
}
</style>