var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('vue-good-table',{attrs:{"title":"list-of-gps-erorrs-reporting","mode":"remote","sort-options":{
      enabled: false,
    },"totalRows":_vm.totalRecords,"line-numbers":true,"isLoading":_vm.isLoading,"pagination-options":{
      enabled: true,
    },"filterOptions":{
      enabled: false,
    },"rows":_vm.rowsTable,"columns":_vm.columnsOrder},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'driver_id')?_c('span',[_vm._v(" "+_vm._s(props.row.driver.driver_name)+" "+_vm._s(props.row.driver.hp1)+" ")]):(props.column.field == 'control')?_c('span',[_c('a',{attrs:{"href":"#","title":"Edit"},on:{"click":function($event){$event.preventDefault();return _vm.EditFormModal(props.row)}}},[_c('i',{staticClass:"mdi mdi-square-edit-outline",staticStyle:{"font-size":"16px"}})]),_c('a',{attrs:{"href":"#","title":"Delete"},on:{"click":function($event){$event.preventDefault();return _vm.deleteData(props.index, props.row)}}},[_c('i',{staticClass:"mdi mdi-trash-can-outline",staticStyle:{"font-size":"16px"}})])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('div',{staticClass:"vgt-clearfix"},[_c('button',{staticClass:"btn btn-success btn-xs",staticStyle:{"font-size":"10pt","margin-top":"-7px"},on:{"click":function($event){$event.preventDefault();return _vm.TambahFormModal()}}},[_c('i',{staticClass:"link-icon",attrs:{"data-feather":"plus"}}),_vm._v(" "+_vm._s(_vm.$t("createNew"))+" ")])])])]),_c('modal-gps-erorr-reporting',{attrs:{"showModal":_vm.showModalNow,"data":_vm.detailGpsError,"vehicle_no":_vm.vehicle_no},on:{"closeModal":_vm.closeMyModal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }