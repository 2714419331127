<template>
  <div>
    <router-view />
  </div>
</template>
<script>
export default {
  name: "MasterPenyebranganIndex",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
