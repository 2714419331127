import Ujo from "@/components/master/ujo/Ujo.vue";
import UjoAdd from "@/components/master/ujo/UjoAdd.vue";
import UjoDetail from "@/components/master/ujo/UjoDetail.vue";
import UjoEdit from "@/components/master/ujo/UjoEdit.vue";
import UjoUpload from "@/components/master/ujo/UjoUpload.vue";

let routesUjo= [
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "Ujo",
        component: Ujo
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "UjoAdd",
        component: UjoAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "UjoEdit",
        component: UjoEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "UjoDetail",
        component: UjoDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "UjoUpload",
        component: UjoUpload
      },
    
]

export default routesUjo
  