import Sku from "./Sku.vue";
import SkuAdd from "./SkuAdd.vue";
import SkuDetail from "./SkuDetail.vue";
import SkuEdit from "./SkuEdit.vue";

let RouteSku = [
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "Sku",
        component: Sku
    },
    {
        path:'add',
        meta:{
            title:"Chain System",
        },
        name:"AddSku",
        component:SkuAdd
    },
    {
        path:'detail/:id',
        meta:{
            title:"Chain System",
        },
        name:"SkuDetail",
        component:SkuDetail
    }, 
    {
        path:'edit/:id',
        meta:{
            title:"Chain System",
        },
        name:"SkuEdit",
        component:SkuEdit
    },
]

export default RouteSku;