<template>
  <div>
    <div class="page-content">
      <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
        <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
          <div class="card">
            <div class="card-header">
              <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
              &nbsp;&nbsp;&nbsp;
              <h6 class="card-title mb-0 float-start" style="padding-left: 25px">
                FPPK Detail
              </h6>
              <button class="btn btn-default btn-sm float-end" @click="resetForm" type="button">
                <i class="link-icon" data-feather="repeat"></i>
                {{ $t("resetFormTxt") }}
              </button>
            </div>
            <div class="card-body">
              <!--begin::loading-data-->
              <div v-if="isLoading" class="d-flex align-items-center">
                <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                <strong>{{ $t("loadingTxt") }}...</strong>
              </div>
              <!--end::loading-data-->

              <form class="forms-sample" @submit.prevent="submitData" method="POST">
                <div class="d-flex justify-content-between align-items-baseline mb-2"></div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="row mb-3">
                      <label for="period_code" class="col-sm-4 col-form-label">No Fppk</label>
                      <div class="col-sm-7">
                        <input type="text" class="form-control" id="period_code" placeholder="No Fppk"
                          v-model="forms.no_fppk" readonly />
                        <div v-if="errors.period_code">
                          <div v-for="error in errors.period_code" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="price" class="col-form-label col-sm-4">Status</label>
                      <div class="col-sm-7">
                        <input type="text" readonly v-model="forms.status" class="form-control">
                        <!-- <v-select v-model="forms.status" :options="fetchStatus">
                        </v-select> -->
                        <div v-if="errors.status">
                          <div v-for="error in errors.status" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <label for="price" class="col-form-label col-sm-4">Status FPPK</label>
                      <div class="col-sm-7">
                        <input type="text" readonly v-model="forms.status_fppk" class="form-control">
                        <!-- <v-select v-model="forms.status" :options="fetchStatus">
                        </v-select> -->
                        <div v-if="errors.status">
                          <div v-for="error in errors.status" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <label for="price" class="col-form-label col-sm-4">Lokasi Unit</label>
                      <div class="col-sm-7">
                        <input type="text" readonly v-model="forms.lokasi_unit" class="form-control">
                        <div v-if="errors.status">
                          <div v-for="error in errors.status" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="defaultconfig" class="col-form-label col-sm-4">Tanggal FPPK</label>
                      <div class="col-sm-7">
                        <!-- <date-range-picker
                          style="width: 100%"
                          :locale-data="locale"
                          v-model="fppkDate"
                          :opens="opens"
                          :singleDatePicker="true"
                          :timePicker24Hour="true"
                          :timePicker="true"
                        >
                        </date-range-picker> -->
                        <input type="text" class="form-control" id="tanggal_fppk" readonly placeholder=""
                          v-model="forms.tanggal_fppk" />
                        <div v-if="errors.tanggal_fppk">
                          <div v-for="error in errors.tanggal_fppk" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="period_code" class="col-form-label col-sm-4">Nopol</label>
                      <div class="col-sm-7">
                        <input type="text" readonly class="form-control" id="nopol" placeholder="Nopol"
                          v-model="forms.nopol" />

                        <!-- <multiselect
                          v-model="truck"
                          :options="fetchTruck"
                          :multiple="false"
                          group-values="truck"
                          group-label="category"
                          :group-select="true"
                          placeholder="Type to search "
                          track-by="vehicle_no"
                          @search-change="asyncSearchTruck"
                          label="vehicle_no"
                          
                          @input="changeNopol"
                          ><span slot="noResult"
                            >Oops! No elements found. Consider changing the
                            search query.</span
                          ></multiselect
                        > -->



                        <div v-if="errors.vehicle_no">
                          <div v-for="error in errors.vehicle_no" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="period_code" class="col-form-label col-sm-4">Merk</label>
                      <div class="col-sm-7">
                        <!-- <v-select
                          v-model="forms.merk"
                          :options="fetchMerk"
                          return-object
                        >
                        </v-select> -->

                        <input type="text" class="form-control" id="merek" readonly placeholder="" v-model="forms.merk" />

                        <div v-if="errors.merk">
                          <div v-for="error in errors.merk" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="period_code" class="col-form-label col-sm-4">Jenis Unit</label>
                      <div class="col-sm-7">
                        <input type="text" class="form-control" id="merek" readonly placeholder=""
                          v-model="forms.jenis_unit" />
                        <div v-if="errors.jenis_unit">
                          <div v-for="error in errors.jenis_unit" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <label for="period_code" class="col-form-label col-sm-4">Driver NMK</label>
                      <div class="col-sm-7">
                        <input type="text" readonly v-model="forms.nmk_driver" class="form-control">
                        <!-- <multiselect
                        v-model="nmk_driver"
                        :options="fetchDriver"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :preserve-search="true"
                        placeholder="Driver NMK"
                        label="driver_id"
                        track-by="driver_id"
                        @input="changeDriver"
                        @search-change="onSearchChangeDriver"
                      > </multiselect> -->
                        <div v-if="errors.nmk_driver">
                          <div v-for="error in errors.nmk_driver" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <label for="period_code" class="col-form-label col-sm-4">Driver Name</label>
                      <div class="col-sm-7">
                        <input type="text" readonly v-model="forms.driverName" class="form-control">
                        <div v-if="errors.driverName">
                          <div v-for="error in errors.driverName" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <label for="period_code" class="col-form-label col-sm-4">Hp Driver</label>
                      <div class="col-sm-7">
                        <input type="text" readonly v-model="forms.hp_driver" class="form-control">
                        <div v-if="errors.hp_driver">
                          <div v-for="error in errors.hp_driver" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="period_code" class="col-form-label col-sm-4">Email Driver</label>
                      <div class="col-sm-7">
                        <input type="text" readonly v-model="forms.email_driver" class="form-control">
                        <div v-if="errors.email_driver">
                          <div v-for="error in errors.email_driver" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row mb-3">
                      <label for="period_code" class="col-sm-4 col-form-label">KM GPS</label>
                      <div class="col-sm-5">
                        <input type="text" readonly class="form-control" id="period_code" placeholder="KM GPS"
                          v-model="km_gps" />
                        <div v-if="errors.period_code">
                          <div v-for="error in errors.period_code" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-sm-4">
                        <a href="#" class="btn btn-primary" @click="getKm()">
                          GET KM GPS
                        </a>
                      </div> -->
                    </div>
                    <div class="row mb-3">
                      <label for="period_code" class="col-sm-4 col-form-label">KM ODO</label>
                      <div class="col-sm-5">
                        <input type="text" readonly class="form-control" id="period_code" placeholder="KM ODO"
                          v-model="forms.km_odo" />
                        <div v-if="errors.period_code">
                          <div v-for="error in errors.period_code" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="period_code" class="col-sm-4 col-form-label">Route</label>
                      <div class="col-sm-7">
                        <input type="text" readonly class="form-control" id="route" placeholder="Route"
                          v-model="forms.route" />

                        <!-- <v-select
                        v-model="forms.route"
                        :options="fetchRoute"
                        return-object
                        >

                        </v-select> -->
                        <div v-if="errors.route">
                          <div v-for="error in errors.route" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <label for="defaultconfig" class="col-form-label col-sm-4">Estimasi Tiba di Pool</label>
                      <div class="col-sm-7">
                        <!-- <date-range-picker
                          style="width: 100%"
                          :locale-data="locale"
                          v-model="tgl_estimasi_tiba"
                          :opens="opens"
                          :singleDatePicker="true"
                          :timePicker24Hour="true"
                          :timePicker="true"
                        >
                        </date-range-picker> -->

                        <input type="text" readonly class="form-control" id="route" placeholder="Tgl Estimasi Tiba"
                          v-model="forms.tgl_estimasi_tiba" />


                        <div v-if="errors.tgl_estimasi_tiba">
                          <div v-for="error in errors.tgl_estimasi_tiba" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>



                    <div class="row mb-3">
                      <label for="defaultconfig" class="col-form-label col-sm-4">Keluhan 1</label>
                      <div class="col-sm-7">
                        <input type="text" readonly v-model="forms.keluhan1" class="form-control">
                        <div v-if="errors.keluhan1">
                          <div v-for="error in errors.keluhan1" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <label for="defaultconfig" class="col-form-label col-sm-4">Keluhan 2</label>
                      <div class="col-sm-7">
                        <input type="text" readonly v-model="forms.keluhan2" class="form-control">
                        <div v-if="errors.keluhan1">
                          <div v-for="error in errors.keluhan2" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <label for="defaultconfig" class="col-form-label col-sm-4">Keluhan 3</label>
                      <div class="col-sm-7">
                        <input type="text" readonly v-model="forms.keluhan3" class="form-control">
                        <div v-if="errors.keluhan1">
                          <div v-for="error in errors.keluhan3" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <label for="defaultconfig" class="col-form-label col-sm-4">Keluhan 4</label>
                      <div class="col-sm-7">
                        <input type="text" readonly v-model="forms.keluhan4" class="form-control">
                        <div v-if="errors.keluhan4">
                          <div v-for="error in errors.keluhan4" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <label for="defaultconfig" class="col-form-label col-sm-4">Keluhan 5</label>
                      <div class="col-sm-7">
                        <input type="text" readonly v-model="forms.keluhan5" class="form-control">
                        <div v-if="errors.keluhan5">
                          <div v-for="error in errors.keluhan5" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <label for="defaultconfig" class="col-form-label col-sm-4">Keluhan 6</label>
                      <div class="col-sm-7">
                        <input type="text" readonly v-model="forms.keluhan6" class="form-control">
                        <div v-if="errors.keluhan6">
                          <div v-for="error in errors.keluhan6" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>


                <div class="card-footer">
                  <button class="btn btn-warning text-black fw-bolder btn-icon-text " @click="backForm" type="button">
                    <i class="link-icon" data-feather="arrow-left"></i>
                    {{ $t("backMess") }}
                  </button>


                  <!-- <button
                    class="btn btn-primary float-end btn-sm mr-10"
                    type="button"
                    @click="editForm"

                  >
                    <i class="link-icon" data-feather="save"></i>
                    Edit Data
                  </button> -->



                  <button v-if="forms.status_fppk == 'Draft'" class="btn btn-success float-end btn-sm mr-10" type="submit">
                    <i class="link-icon" data-feather="save"></i>
                    {{ $t("submitFormTxt") }}
                  </button>


                </div>
              </form>
            </div>
          </div>
          <!--end::card-->
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
// import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
// import multiselect from "vue-multiselect";

export default {
  name: "GerbangTolAdd",
  props: {},
  components: {
    // DateRangePicker,
    // multiselect: multiselect,
  },
  data() {
    return {
      locale: {
        format: "dd mmm yyyy H:MM:ss",
      },
      km_gps:'',
      truck: '',
      nmk_driver: '',
      fetchTruck: [],
      fppkDate: {
        startDate: null,
        endDate: null,
      },
      tgl_estimasi_tiba: {
        startDate: null,
        endDate: null,
      },

      fetchStatus: [],
      fetchRoute: [],
      fetchMerk: [],
      fetchJenisUnit: [],
      fetchDriver: [],


      opens: "center",
      permision_role: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      fetchCompany: [],
      fetchCompanyNotLinc: [],
      forms: { pcs_qty: 1 },
      company: [],
    };
  },
  watch: {},
  methods: {
    resetForm() {
      this.forms.period_name = "";
      this.forms.price_gap = "";
      this.forms.price = "";
      this.forms.period_code = "";
      this.fppkDate = {
        startDate: null,
        endDate: null,
      };
      this.expiredDate = {
        startDate: null,
        endDate: null,
      };
    },

    loadCompany() {
      const baseURI = this.$settings.endPoint + "company?limit=10";

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },

    loadCompanyNotLinc() {
      const baseURI = this.$settings.endPoint + `company/select`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },

    loadDriver() {
      const baseURI = this.$settings.endPointCt + `master-driver`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDriver = response.data.datas.data;
      });
    },

    onSearchChangeDriver(ev) {
      const baseURI =
        this.$settings.endPointCt + `master-driver?driver_name=` + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDriver = response.data.datas.data;
      });
    },

    asyncSearchCompany(ev) {
      const baseURI = this.$settings.endPoint + "company?code=" + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },

    asyncSearchCompanyNotLinc(ev) {
      const baseURI = this.$settings.endPoint + "company/select/code=" + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },

    changeNopol(ev) {
      this.forms.merk = ev.truck_brand
      this.forms.jenis_unit = ev.type_truck
      // console.log(ev);
    },


    loadData(id) {
      const baseURI = this.$settings.endPointFppk + "v1/pengajuan-fppk/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        // console.log(response);
        this.forms = response.data.datas;
        this.getKm()
        // this.form={ name: 'asep', usernmae:res.username };
      });
    },

    changeDriver(ev) {
      this.forms.driverName = ev.driver_name;
      this.forms.hp_driver = ev.hp1;
      // console.log(ev);
    },

    loadStatus() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=fpStat`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchStatus = response.data.datas;
      });
    },


    loadFeatchUnit() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=fpRout`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchRoute = response.data.datas;
      });
    },


    getKm() {
      
      this.km_gps=0;
      var nopol=this.forms.nopol;

      if(nopol==null || nopol=='' || nopol==undefined){
        this.loadingMGps=false;
        this.error('Vehicle No is empty');
      }else{

        const baseURI =
        this.$settings.endPointCt + `master-truck/get-lastkm/`+nopol;
        return this.$http.get(baseURI).then((response) => {
         
          if(response.data.status==200){
            
            
            this.km_gps=response.data.datas?.last_km;

          }
        }) .catch((error) => {
          // this.loadingMGps=false;
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                if (this.errors.message) {
                  this.error(this.errors.message);
                } else {
                  this.resultError(error.response.data.errors);
                }
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
            //this.resetForm();
          });
      }
      
    },


    loadMerk() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=fpMplan`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchMerk = response.data.datas;
      });
    },


    loadTruck() {
      const baseURI = this.$settings.endPointCt + `master-truck?select=select`;

      return this.$http.get(baseURI).then((response) => {
        let data = {
          category: " -- Select All Truck --",
          truck: response.data.datas.data,
        };

        this.fetchTruck.push(data);
      });
    },


    asyncSearchTruck(ev) {
      const baseURI =
        this.$settings.endPointCt + `master-truck?vehicle_no=` + ev;

      return this.$http.get(baseURI).then((response) => {
        response.data.datas.data.forEach((item) => {
          const data = {
            master_truck_id: item.master_truck_id,
            vehicle_no: item.vehicle_no,
          };
          this.fetchTruck.push(data);
        });
      });
    },




    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      this.companyCode = this.detailUser.company_detail.code;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "PengajuanFppkController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      this.permision_role = rolePermision;
    },

    backForm() {
      window.location.href = "/fppk-pengajuan";
    },

    editForm() {
      var params = this.$onRandom(this.forms.fppk_id);
      window.location.href = "/fppk-pengajuan/edit/" + params;
    },


    updateStatus(fppk_id, status) {
      const baseURI = this.$settings.endPointFppk + "v1/pengajuan-fppk/update-status-fppk/" + fppk_id;
      this.$http
        .post(baseURI, {
          status: status
        })
        .then((response) => {
          this.isLoading = false;
          if (response.data.status === 200) {

            this.success("Success");
            window.location.href = "/fppk-pengajuan";
          } else {

            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }


        })
        .catch((error) => {

          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);

            }
          }
          this.isloading = false;
        });
    },

    submitData() {
    
      this.forms.tanggal_fppk = moment(this.fppkDate.startDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );
   

      //var keluhan = this.forms.keluhan1 + ';' + this.forms.keluhan2 + ';' + this.forms.keluhan3 + ';' + this.forms.keluhan4 + ';';
      
      var keluhanArray = [
          this.forms.keluhan1,
          this.forms.keluhan2,
          this.forms.keluhan3,
          this.forms.keluhan4,
          this.forms.keluhan5,
          this.forms.keluhan6
      ].filter(keluhan => keluhan !== undefined && keluhan !== null);

                      

      var dataSend = {
        "vehicle_no": this.forms.nopol,
        "master_status_id": 8,
        "status_time": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        "reference": this.forms.no_fppk,
        "status_type": 'Pengajuan',
        "next_pm_service": '',
        "service_duration": '',
        "estimation_date": this.forms.tgl_estimasi_tiba,
        "fppk_keluhan": keluhanArray.join(';'),
        "fppk_analisa":";;;;",
      };


      this.fade(true);
      const baseURI = this.$settings.endPointCt + "master-truck/update-truck-status";
      this.$http
        .post(baseURI, dataSend)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            this.updateStatus(this.forms.fppk_id, 'Pengajuan')

          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              if (this.errors.message) {
                this.error(this.errors.message);
              } else {
                this.resultError(error.response.data.errors);
              }
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    fade(sType) {
      this.isLoading = sType;
    },


    loadJenisUnit() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=truca`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchJenisUnit = response.data.datas;
      });
    },
    asyncJenisUnit(ev) {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=truca&search=${ev}`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchJenisUnit = response.data.datas;
      });
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },


    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () { },
  mounted() {
    this.fetchIt();
    this.loadStatus();
    this.loadJenisUnit();
    this.loadFeatchUnit();
    this.loadTruck()
    this.loadDriver()
    var params = this.$onBehind(this.$route.params.id);
    this.loadData(params[0]);
  
    // this.loadCompany();
    // this.loadCompanyNotLinc();
  },
};
</script>
<style scoped></style>
  