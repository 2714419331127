<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>   
    <div class="loadertext" v-if="this.isLoading"> {{$t('loadingTxt')}}...</div>   
    <div class="main-wrapper" :class="{'blur-content': this.isLoading}">
      <!--begin::sidebar-->
      <sidebar-component classMenu="BranchDetail"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            "
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
               <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 25px"
                  >
                    Master Ujo Detail {{ $route.query.params }}
                  </h6>

                  <button
                    class="btn btn-light float-end"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <i class="link-icon" data-feather="info"></i>
                    Audit Info
                  </button>

                  <div
                    class="row mb-3 collapse"
                    id="collapseExample"
                    style="padding-left: 20px"
                  >
                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created By:</label>
                      <p class="text-muted">{{ this.forms.create_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created At:</label>
                      <p class="text-muted">{{ this.forms.created_at }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated By:</label>
                      <p class="text-muted">{{ this.forms.update_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated At:</label>
                      <p class="text-muted">{{ this.forms.updated_at }}</p>
                    </div>
                  </div>
                </div>

                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="d-flex justify-content-between align-items-baseline mb-2"
                    ></div>

                    <div
                      class="row mb-3 align-items-center justify-content-center"
                    >
                      <div
                        class="table-responsive"
                        style="max-height: 500px; min-height: 200px"
                      >
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th style="width: 5%">No</th>
                              <th>Rute</th>
                              <th>Pick State</th>
                              <th>Drop State</th>
                              <th>Route State</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in RouteList" :key="index">
                              <td>{{ index + 1 }}</td>
                              <td>
                               {{item.route_name}}
                              </td>
                                <td>{{ item.pick_state }}</td>
                              <td>{{ item.drop_state }}</td>
                              <td>{{ item.route_state }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-sm-4">

                        <div class="row mb-3">
                            <div class="col-lg-4">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 25px;">Type Kalkulasi</label>
                            </div>
                            <div class="col-lg-8 d-flex justify-content-between">
                                <div class="form-check">
                                    <input class="form-check-input" disabled type="radio" id="radioA" v-model="forms.type_kalkulasi" value="AUTO" >
                                    <label class="form-check-label" for="radioA">AUTO</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" disabled type="radio" id="radioB" v-model="forms.type_kalkulasi" value="MANUAL" >
                                    <label class="form-check-label" for="radioB">MANUAL</label>
                                </div>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-lg-4">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 25px;">Type UJO</label>
                            </div>
                            <div class="col-lg-8 d-flex justify-content-between">
                                <div class="form-check">
                                    <input class="form-check-input" disabled type="radio" id="radioA" v-model="forms.type_ujo" value="A" >
                                    <label class="form-check-label" for="radioA">A</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" disabled type="radio" id="radioB" v-model="forms.type_ujo" value="B" >
                                    <label class="form-check-label" for="radioB">B</label>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px;"
                              >Status</label
                            >
                          </div>
                          <div class="col-lg-8">
                         
                            <v-select
                              disabled
                              :options="fetchStatus"
                              v-model="forms.status"
                              label="status"
                              return-object
                             
                            ></v-select>
                          </div>
                         
                        </div>



                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px;"
                              >Leadtime</label
                            >
                          </div>
                          <div class="col-lg-8">
                           
                            <input readonly  :placeholder='$t("days")'   class="form-control" type="number" v-model.number="forms.lead_time" id="exampleCheckbox">
                          </div>
                         
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px;"
                              >{{ $t("customer") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input placeholder="customer_name"  class="form-control" readonly type="text" v-model="forms.customer_name" id="exampleCheckbox">
                            <input placeholder="code"  class="form-control" readonly type="text" v-model="forms.customer_code" id="exampleCheckbox">
                          </div>

                          
                         
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("branchTxt") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <!-- <multiselect
                              @search-change="asyncBranch"
                              v-model="branch"
                              :options="fetchBranch"
                              label="branch_name"
                              track-by="branch_name"
                              placeholder="Please Select Branch"
                              @input="loadRatio()"
                            >
                              <span slot="noResult">-</span>
                            </multiselect> -->
                            <input type="text" :value="forms.branch_name" disabled class="form-control">
                            <div v-if="errors.branch_name">
                              <div
                                v-for="error in errors.branch_name"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("type_truck") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input type="text" :value="forms?.type_truck?.type_truck" disabled class="form-control">
                            <!-- <multiselect
                              @search-change="asyncTypeTruck"
                              v-model="typeTruck"
                              :options="fetchTypeTruck"
                              label="type_truck"
                              track-by="type_truck"
                              placeholder="Please Select Route"
                              @input="loadRatio()"
                            >
                              <span slot="noResult">-</span>
                            </multiselect> -->
                            <div v-if="errors.type_truck">
                              <div
                                v-for="error in errors.type_truck"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Effective Date</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input type="text" :value="forms?.effective_date" readonly class="form-control">
                           
                            
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Expired Date</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input type="text" :value="forms?.expired_date" readonly class="form-control">
                           
                            
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Master Ujo Name
                              <span class="wajib">*</span></label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text"
                              class="form-control"
                              :value="forms.name"
                              placeholder="Master Ujo Name"
                              disabled
                            />
                            <div v-if="errors.name">
                              <div
                                v-for="error in errors.name"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("distance") }} ( Km )</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="number"
                              class="form-control"
                              v-model="forms.distance"
                              disabled
                              :placeholder="this.$t('distance')"
                            />
                            <div v-if="errors.distance">
                              <div
                                v-for="error in errors.distance"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Rasio Detail <span class="wajib">*</span></label
                            >
                          </div>
                          <div class="col-lg-8">
                            <!-- <multiselect
                              v-model="ratioDetail"
                              :options="fetchRatioDetail"
                              label="name"
                              track-by="name"
                              placeholder="Please Select Detail Ratio"
                            >
                              <span slot="noResult">-</span>
                            </multiselect> -->
                            <input type="text" :value="forms?.ratio_detail?.name" disabled class="form-control">

                            <div v-if="errors.ratio_master_name">
                              <div
                                v-for="error in errors.ratio_master_name"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                       
                       
                      </div>

                      <div class="col-sm-4">

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Solar ( Liter )</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="number"
                              class="form-control"
                              placeholder="Solar (Liter)"
                              :value="forms.fuel_liter"
                              step="any"
                              disabled
                            />
                            <div v-if="errors.fuel_liter">
                              <div
                                v-for="error in errors.fuel_liter"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Solar (Rp)</label
                            >
                          </div>
                          <div class="col-lg-8">
                           
                            <CurrencyInput
                              :value="forms.fuel_price"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              placeholder="Solar (Rp)"
                              disabled
                            />
                            <div v-if="errors.fuel_price">
                              <div
                                v-for="error in errors.fuel_price"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="working_day"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("masterUjoPay") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <!-- <input
                              type="number"
                              class="form-control"
                              id="salary"
                              :placeholder="$t('masterUjoPay')"
                              v-model.number="forms.salary"
                              disabled
                            /> -->
                            <CurrencyInput
                              :value="forms.salary"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              :placeholder="$t('masterUjoPay')"
                              disabled
                            />
                            <div v-if="errors.salary">
                              <div
                                v-for="error in errors.salary"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="working_day"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Self Insurance</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <CurrencyInput
                              v-model.number="forms.self_insurance"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              disabled
                              placeholder="Self Insurance"
                             
                            />
                           
                            <div v-if="errors.self_insurance">
                              <div
                                v-for="error in errors.self_insurance"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="working_day"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Rute Tol</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text"
                              class="form-control"
                              id="fee"
                              :placeholder="$t('masterUjoPay')"
                              :value="toll_route?.toll_route_code"
                              disabled
                            />
                            <div v-if="errors.toll_route_name">
                              <div
                                v-for="error in errors.toll_route_name"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="working_day"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Deposit</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <CurrencyInput
                              disabled
                              v-model.number="forms.deposit"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              
                            
                            />
                            <!-- <input
                              type="text"
                              class="form-control"
                              id="fee"
                              :placeholder="$t('masterUjoTolFee')"
                              :value="
                                forms.toll_price
                                  ? forms.toll_price
                                  : 0 | format_number
                              "
                              disabled
                            /> -->
                            <div v-if="errors.deposit">
                              <div
                                v-for="error in errors.deposit"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="working_day"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("masterUjoTolFee") }} (Rupiah)</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <CurrencyInput
                              :value="forms.toll_price"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              :placeholder="$t('masterUjoTolFee')"
                              disabled
                            />
                            <div v-if="errors.toll_price">
                              <div
                                v-for="error in errors.toll_price"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="working_day"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Mel</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <CurrencyInput
                              :value="forms.mel"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              placeholder="Mel"
                              disabled
                            />
                            <!-- <input
                              type="number"
                              class="form-control"
                              id="mel"
                              placeholder="Mel"
                              :value="forms.mel"
                              disabled
                            /> -->
                            <div v-if="errors.mel">
                              <div
                                v-for="error in errors.mel"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("nstBiayaBongkarTxt") }}</label
                            >
                          </div>
                          <div class="col-lg-6">
                            <input
                              type="number"
                              class="form-control"
                              disabled
                              v-model.number="forms.nst_biaya_bongkar"
                              step="any"
                            />
                            <div v-if="errors.nst_biaya_bongkar">
                              <div
                                v-for="error in errors.nst_biaya_bongkar"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-2">
                            <button
                              @click="rateBreak()"
                              type="button"
                              class="btn btn-sm btn-success"
                            >
                              <i class="mdi mdi-currency-usd"></i>
                            </button>
                          </div>

                        </div>


                       

                        <!-- <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("CrossTxt") }}</label
                            >
                          </div>
                          <div class="col-lg-6">
                            <p
                              v-for="(item, index) in penyebrangan"
                              :key="index"
                            >
                              {{ item.nama }} : {{ item.harga }}
                            </p>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("CrossFeeTxt") }} ( Rp )</label
                            >
                          </div>
                          <div class="col-lg-6">
                            <input
                              type="text"
                              class="form-control"
                              id="fee"
                              :placeholder="$t('CrossFeeTxt')"
                              :value="getBiayaPenyebrangan | format_number"
                              disabled
                            />
                            <div v-if="errors.ferry">
                              <div
                                v-for="error in errors.ferry"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div> -->
                        <!-- <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Total Base Rate</label
                            >
                          </div>
                          <div class="col-lg-6">
                            <CurrencyInput
                              :value="forms.ujo_total"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              :placeholder="$t('masterUjoTolFee')"
                              disabled
                            />
                            <div v-if="errors.ujo_total">
                              <div
                                v-for="error in errors.ujo_total"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("totalRoundingTxt") }}</label
                            >
                          </div>
                          <div class="col-lg-6">
                            <CurrencyInput
                              :value="forms.ujo_total_rounding"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              :placeholder="$t('totalRoundingTxt')"
                              disabled
                            />
                            <div v-if="errors.ujo_total_rounding">
                              <div
                                v-for="error in errors.ujo_total_rounding"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div> -->
                      </div>

                      <div class="col-sm-4">
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("nstInsentifRit1Txt") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="number"
                              class="form-control"
                             disabled
                              v-model.number="forms.nst_insentif_rit1"
                              step="any"
                            />
                            <div v-if="errors.nst_insentif_rit1">
                              <div
                                v-for="error in errors.nst_insentif_rit1"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("nstKomisiSupirTxt") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="number"
                              class="form-control"
                              disabled
                              v-model.number="forms.nst_komisi_supir"
                              step="any"
                            />
                            <div v-if="errors.nst_komisi_supir">
                              <div
                                v-for="error in errors.nst_komisi_supir"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("nstBiayaTerpalTxt") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="number"
                              class="form-control"
                              disabled
                              v-model.number="forms.nst_biaya_terpal"
                              step="any"
                            />
                            <div v-if="errors.nst_biaya_terpal">
                              <div
                                v-for="error in errors.nst_biaya_terpal"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("CrossTxt") }} </label
                            >
                          </div>
                          <div class="col-lg-8">
                            <p
                              v-for="(item, index) in penyebrangan"
                              :key="index"
                            >
                              {{ item.nama }} : {{ item.harga }}
                            </p>
                          </div>
                        </div> 
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("CrossFeeTxt") }} ( Rp )</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <!-- <input
                              type="text"
                              class="form-control"
                              id="fee"
                              
                              :placeholder="$t('CrossFeeTxt')"
                              :value="getBiayaPenyebrangan | format_number"
                              disabled
                            /> -->

                            <CurrencyInput
                              :value="getBiayaPenyebrangan"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              disabled
                              :placeholder="$t('CrossFeeTxt')"
                             
                            />


                            <div v-if="errors.ferry">
                              <div
                                v-for="error in errors.ferry"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >NST MEL</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="number"
                              class="form-control"
                              disabled
                              v-model.number="forms.nst_mel"
                              step="any"
                            />
                            <div v-if="errors.nst_mel">
                              <div
                                v-for="error in errors.nst_mel"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >NST REKON</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="number"
                              class="form-control"
                              disabled
                              v-model.number="forms.nst_rekon"
                              step="any"
                            />
                            <div v-if="errors.nst_rekon">
                              <div
                                v-for="error in errors.nst_rekon"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Total UJO</label
                            >
                          </div>
                          <div class="col-lg-6">
                            <CurrencyInput
                              :value="forms.ujo_total"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              placeholder="Total Ujo"
                              disabled
                            />
                            <div v-if="errors.ujo_total">
                              <div
                                v-for="error in errors.ujo_total"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("totalRoundingTxt") }}</label
                            >
                          </div>
                          <div class="col-lg-6">
                            <CurrencyInput
                            :value="forms.ujo_total_rounding"
                            
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              :placeholder="$t('totalRoundingTxt')"
                              disabled
                            />
                            <div v-if="errors.ujo_total_rounding">
                              <div
                                v-for="error in errors.ujo_total_rounding"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                            <div class="col-lg-4">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 25px;">Round Trip</label>
                            </div>
                            <div class="col-lg-8 d-flex justify-content-between">
                                <div class="form-check">
                                    <input class="form-check-input" disabled type="radio" id="radioC" v-model="forms.round_trip" value="YES" >
                                    <label class="form-check-label" for="radioC">Yes</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" disabled type="radio" id="radioD" v-model="forms.round_trip" value="NO" >
                                    <label class="form-check-label" for="radioD">No</label>
                                </div>
                            </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("alfaKosonganBlTxt") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="number"
                              class="form-control"
                              disabled
                              v-model.number="forms.alfa_kosongan_bl"
                              step="any"
                            />
                            <div v-if="errors.alfa_kosongan_bl">
                              <div
                                v-for="error in errors.alfa_kosongan_bl"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>



                       

                      </div>

                    </div>

                        <div class="row mb-3">
                          
                          <div class="col-lg-12">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Remarks</label
                            >
                           <textarea disabled class="form-control" name="remarks" v-model="forms.remarks"></textarea>

                           
                            <div v-if="errors.remarks">
                              <div
                                v-for="error in errors.remarks"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                    <div class="card-footer">
                      <button
                        class="btn btn-warning text-black fw-bolder btn-icon-text"
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                    <span class="float-end" v-if="$route.query.params==='approval'">
                      <a
                        style="margin-right:10px;"
                        class="mr-5 btn btn-primary  btn-sm"
                        href="#"
                        @click="showApprove(forms.rm_ujo_master_id)"
                      >
                        <i class="link-icon" data-feather="save"></i>
                       Approve
                      </a>
                      

                      <a
                        class="mr-5 btn btn-danger  btn-sm"
                        href="#"
                        @click="showAReject(forms.rm_ujo_master_id)"
                      >
                        <i class="link-icon" data-feather="save"></i>
                       Reject
                    </a>
                    </span>
                      
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>


        <modal-aprrove-reason
              :showModal="showModal"
              :data="modalData"
              :url="url"
              @closeModal="closeMyModal"
            ></modal-aprrove-reason>

            <modal-reject-reason
              :showModal="showModalReject"
              :data="modalData"
              :url="urlrejected"
              @closeModal="closeMyModal"
            ></modal-reject-reason>

            <modal-rate-break :showModal="showModalRateBreak" :data="passDataRate" formType="Detail"
            @closeModal="closeMyModalRateBreak" ></modal-rate-break>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import CurrencyInput from '@/components/_partials/inputCurrency.vue';

import modalApproveWithReason from "@/components/modal/modalApproveWithReason";
import modalRejectWithReason from "@/components/modal/modalRejectWithReason";
import modalRateBreak from "@/components/modal/modalRateBreakUjo.vue";


export default {
  name: "BranchDetail",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "modal-aprrove-reason":modalApproveWithReason,
    "modal-reject-reason":modalRejectWithReason,
    "modal-rate-break":modalRateBreak,
    CurrencyInput,
  },
  data() {
    return {
      url:"rm-ujo-master/approve",
      urlrejected:"rm-ujo-master/reject",
      showModal: false,
      showModalReject:false,
      showModalRateBreak:false,
      modalData:{
        id:null
      },
      passDataRate:[],
      fetchStatus:[
        "NEWENABLED",
        "ENABLED",
        "DISABLED",
        "BIDDING" 
      ],
      RouteList:[],
      toll_route:[],
      permision_role:[],
      penyebrangan:[],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      forms: [],
    };
  },
  watch: {},
   computed: {
    getBiayaPenyebrangan() {
       
       let price = 0.01;

       if (this.penyebrangan && this.penyebrangan.length > 0) { // Check if penyebrangan is not null and has elements
          //  price = 0;
           this.penyebrangan.forEach(function(item) {

               price += item.harga;
           });
       }

       return price;
   },

    getTotalUjo() {
      let price = 0;
      //   if(this.forms.fuel_price){
      //     price += this.forms.fuel_price;
      //   }
      if (this.forms.mel) {
        price += parseFloat(this.forms.mel);
      }

      if (this.forms.salary) {
        price += parseFloat(this.forms.salary);
      }
      if (this.forms.toll_price) {
        price += parseFloat(this.forms.toll_price);
      }
      price += this.getBiayaPenyebrangan;
      price += this.getSolarPrice;
      return price;
    },

    getUjoRounding(){
      let val = 0;
      val += this.getTotalUjo;
      val = val/1000;
      // console.log(val,"bagi 1000");
      val = Math.ceil(val);
      // console.log(val,"Rounding");
      val = val*1000;
      // console.log(val,"kali 1000");
      return val;
    },

    getSolarPrice() {
      let price = 0;
      // if(this.forms.fuel_liter){
      //     price = this.forms.fuel_liter * this.solarPrice;
      // }
      price = this.getSolarLiter * this.solarPrice ;
      return price;
    },
    getSolarLiter() {
      let price = 0;
      if (this.ratioDetail?.ratio && this.forms?.distance) {
        price = this.forms.distance / this.ratioDetail.ratio;
      }
      return price;
    },
  },
  methods: {
      isNumberLatLong(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 45) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    resetForm() {

    },

    cek_message(status, msg) {
      if (status == "200") {
        this.success(msg);
      }
    },

    editData() {
      var params = this.$route.params.id;
      window.location.href = "/branch/edit/" + params;
    },

    deleteData() {
      var id = this.$onBehind(this.$route.params.id);
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI = this.$settings.endPointCt + "branch/delete/" + id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                window.location.href = "/branch";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },


    closeMyModal(){
      this.showModal=false;
      this.showModalReject=false
      this.backForm();
      //this.loadData(this.forms.rm_ujo_master_id);
    },

    showApprove(id){
      this.showModal=true;
      this.modalData={
        id:id
      }
    },


    showAReject(id){
      this.showModalReject=true;
      this.modalData={
        id:id
      }
    },


    backForm() {
      this.params=this.$route.query.params;
      var url="";
      if(this.params=='request'){
        url= "/master-ujo?params=request";
      }else if(this.params=='approval'){
        url = "/master-ujo?params=approval";
      }else{
        url="/master-ujo"
      }
    
      window.location.href =url;
    },

    
    loadData(id) {
      this.isLoading = true
      const baseURI = this.$settings.endPoint + "rm-ujo-master/detail/" + id;
      return this.$http.get(baseURI,{ timeout: 10000 }).then((response) => {
           this.isLoading = false
          let datas     = response.data.datas;
          this.forms    = datas;
          this.passDataRate=this.forms.rate_break;
          // this.forms.status=this.forms.status=='ENABLED' ? true :false;
          datas.route.forEach((data)=>{
            let res = {
              route:data.route,
              rm_route_master_id:data.rm_route_master_id,
              route_name:data.route_name,
              pick_state:data.pick_state,
              drop_state:data.drop_state,
              route_state:data.route_state,
            };
            this.RouteList.push(res)
          });

          this.branch = datas.branch;
          this.typeTruck = datas.type_truck;
          this.ratio = datas.ratio;
          this.ratioDetail = {
            rm_ratio_master_detail_id:datas.ratio_detail.rm_ratio_master_detail_id,
            ratio:datas.ratio_detail.ratio,
            name:datas.ratio_detail.name
          };
          // this.RouteList = response.data.datas.route;
          this.toll_route = response.data.datas.toll_route;
          this.loadRatio(false);
  
          // this.poolAll  = response.data.datas.pools;
      }).catch(error => {
          this.isLoading = false;
          if(error.code == "ECONNABORTED"){
            this.error("Please Check Your Internet Connection");
          }else{

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
            
          }


        });
    },


    closeMyModalRateBreak(ev) {
      console.log(ev);
      this.showModalRateBreak=false;
    },



    getPenyebrangan(type_truck) {
      this.penyebrangan = [];
      var id = this.RouteList[0].rm_route_master_id;
      const baseURI = this.$settings.endPoint + "rm-ferry-rute/get-ferry/" + id+'?type_truck='+type_truck;
      this.$http.get(baseURI).then((response) => {
        this.penyebrangan = response.data.datas;
      });
    },


    loadRatio(check) {
      this.fetchRatio = [];
      // console.log(this.branch !== null && this.typeTruck !== null);
      if (this.branch !== null && this.typeTruck !== null) {
        this.getPenyebrangan(this.typeTruck.type_truck)
        const baseURI =
          this.$settings.endPoint +
          "rm-ratio-master?branch_name=" +
          this.branch.branch_name +
          "&truck_category=" +
          this.typeTruck.category;
        this.$http.get(baseURI).then((response) => {
          //   this.loading();
          this.fetchRatio = response.data.datas.data;
          if(this.fetchRatio && this.fetchRatio.length > 0){
            this.fetchRatioDetail = response.data.datas.data[0].detail;
            if(check){
              this.ratio = response.data.datas.data[0];
              this.$set(this.forms, "salary", this.ratio.driver_commision);
              this.$set(this.forms, "self_insurance", this.ratio.driver_self_insurance);
              this.$set(this.forms, "deposit", this.ratio.driver_deposit);
             
              if(this.ratio.detail && this.ratio.detail.length == 1){
                this.ratioDetail = this.ratio.detail[0];
              }else{
                this.error("Ratio Detail Empty");
              }
            }
          }else{
            this.error("Ratio Not Found");
          }
        });
      }
    },


    fetchIt() {
      const userDatas     = this.$getUserInfo();
      this.detailUser     = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function(permision){
        if(permision.permission.controller == 'BranchController'){
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if(!rolePermision.method_read){
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;

    },


    fade(sType) {
      this.isLoading = sType;
    },

    rateBreak() {
      this.showModalRateBreak = true;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.cek_message(this.$route.query.status, this.$route.query.msg);
    this.loadData(params[0]);
    // this.loadRatio(false);
    this.fetchIt();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content{
  filter: blur(5px); 
  pointer-events: none;
}
</style>