var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(this.isLoading)?_c('div',{staticClass:"loader"}):_vm._e(),_c('div',{class:{'blur-content': this.isLoading}},[_c('div',{staticClass:"main-wrapper"},[_c('sidebar-component',{attrs:{"classMenu":"TransportOrderCt"}}),_c('div',{staticClass:"page-wrapper"},[_c('navbar-component'),_c('div',{staticClass:"page-content"},[_vm._m(0),_c('div',{staticClass:"col-lg-12 col-xl-12 stretch-card"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"input-group flatpickr wd-220 me-2 mb-2 mb-md-0",attrs:{"id":"dashboardDate"}},[_c('date-range-picker',{staticStyle:{"width":"100%"},attrs:{"control-container-class":"form-control","locale-data":_vm.locale,"opens":_vm.opens,"singleDatePicker":false,"timePicker24Hour":true,"timePicker":true,"showWeekNumbers":false},on:{"change":_vm.pickupChange},model:{value:(_vm.dateFilter),callback:function ($$v) {_vm.dateFilter=$$v},expression:"dateFilter"}})],1)]),_c('div',{staticClass:"col-lg-4"},[_c('button',{staticClass:"button btn-lg btn-success",on:{"click":function($event){return _vm.getData()}}},[_vm._v("Go")])]),_c('div',{staticClass:"col-lg-5"},[(_vm.permision_role.method_read)?_c('button',{staticClass:"btn btn-success float-end btn-xs",staticStyle:{"margin-right":"5px"},on:{"click":function($event){$event.preventDefault();return _vm.downloadData()}}},[_c('i',{staticClass:"link-icon",attrs:{"data-feather":"download"}}),_vm._v(" "+_vm._s(_vm.$t("downloadData"))+" .xlsx ")]):_vm._e()])])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"table-responsive",staticStyle:{"overflow-y":"auto"}},[_c('div',{staticClass:"col-sm-12"},[_c('vue-good-table',{attrs:{"title":"list-order-ct","mode":"remote","select-options":{ 
                    enabled: false,
                    selectOnCheckboxOnly: true, 
                    selectionText: _vm.$t('data_selected'), 
                    clearSelectionText: _vm.$t('clear'), },"totalRows":_vm.totalRecords,"line-numbers":true,"isLoading":_vm.isLoading,"pagination-options":{
                        enabled: false,
                      },"rows":_vm.rows,"columns":_vm.columns},on:{"on-selected-rows-change":_vm.selectionChanged,"on-column-filter":_vm.onColumnFilter,"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[(_vm.permision_role.method_update)?_c('a',{staticStyle:{"color":"#ab0635","text-decoration":"none"},attrs:{"title":"Edit"},on:{"click":function($event){$event.preventDefault();return _vm.editData(props.row)}}},[_c('i',{staticClass:"mdi mdi-square-edit-outline",staticStyle:{"font-size":"16px"}})]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])])])])]),_c('footer-component')],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"float-start",staticStyle:{"margin-right":"5px"}}),_c('h4',{staticClass:"mb-12 mb-md-0"},[_vm._v(" Truck Utilization Report ")]),_c('br')])
}]

export { render, staticRenderFns }