<template>
  <div>
    <div class="main-wrapper">
      <sidebar-component
        classMenu="BillableRateManagementAddAcc"
      ></sidebar-component>
      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12 pe-2">
            <div class="card">
              <form
                class="forms-sample"
                @submit.prevent="submitData"
                method="POST"
              >
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  <h6 class="card-title mb-0 float-start">Won Rate</h6>
                  &nbsp;&nbsp;&nbsp;
                </div>
                <div class="card-body">
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <div
                    class="d-flex justify-content-between align-items-baseline mb-2"
                  ></div>

                  <div class="row mb-2">
                    <div class="col-sm-6">
                      <div class="row mb-2">
                        <div class="mb-3 col-sm-5">
                          <label class="form-label"
                            >Billable Tariff<span class="wajib">*</span></label
                          >
                          <multiselect
                            @search-change="asyncBillableTariff"
                            v-model="billableTariff"
                            :options="fetchBillableTariff"
                            label="tariff_name"
                            :readonly="false"
                            track-by="tariff_name"
                            placeholder="Please Select"
                          >
                            <span slot="noResult">-</span>
                          </multiselect>

                          <div
                            v-for="error in errors.billable_tariff_name"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                        <div class="mb-3 col-sm-5">
                          <label class="form-label"
                            >Opportunity ID<span class="wajib">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="forms.opty_id"
                            disabled
                          />
                          <div
                            v-for="error in errors.opty_id"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="mb-3 col-sm-5">
                          <label class="form-label"
                            >Unit<span class="wajib">*</span></label
                          >
                          <multiselect
                            @search-change="asyncTypeTruck"
                            v-model="typeTruck"
                            :options="fetchTypeTruck"
                            label="type_truck"
                            :readonly="false"
                            track-by="type_truck"
                            placeholder="Please Select"
                            disabled
                          >
                            <span slot="noResult">-</span>
                          </multiselect>

                          <div
                            v-for="error in errors.billable_tariff"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                        <div class="mb-3 col-sm-5">
                          <label class="form-label"
                            >Qty<span class="wajib">*</span></label
                          >
                          <input
                            type="number"
                            class="form-control"
                            v-model.number="forms.qty"
                            disabled
                          />
                          <div
                            v-for="error in errors.qty"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="mb-3 col-sm-5">
                          <label class="form-label"
                            >Origin C<span class="wajib">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="forms.origin"
                            disabled
                          />

                          <div
                            v-for="error in errors.origin"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                        <div class="mb-3 col-sm-5">
                          <label class="form-label"
                            >Destination C<span class="wajib">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="forms.destination"
                            disabled
                          />

                          <div
                            v-for="error in errors.destination"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="row mb-2">
                        <div class="mb-3 col-sm-5">
                          <label class="form-label"
                            >Origin K<span class="wajib">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="forms.origin_k"
                           
                          />

                          <div
                            v-for="error in errors.origin_k"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                        <div class="mb-3 col-sm-5">
                          <label class="form-label"
                            >Destination K<span class="wajib">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="forms.destination_k"
                            
                          />

                          <div
                            v-for="error in errors.destination_k"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="row mb-2">
                        <div class="mb-3 col-sm-5">
                          <label class="form-label"
                            >Unit Price<span class="wajib">*</span></label
                          >
                          <!-- <input
                            type="number"
                            class="form-control"
                            v-model.number="forms.unit_price"
                          /> -->
                          <CurrencyInput
                            disabled
                            v-model.number="forms.unit_price"
                            :options="{ currency: 'IDR' }"
                            class="form-control"
                            placeholder="Unit Price"
                          />

                          <div
                            v-for="error in errors.unit_price"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                        <div class="mb-3 col-sm-5">
                          <label class="form-label"
                            >Equipment Type<span class="wajib">*</span></label
                          >
                          <v-select
                            :options="fetchEquipmentType"
                            v-model="equipmentType"
                            return-object
                          ></v-select>

                          <div
                            v-for="error in errors.equipment_type"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="mb-3 col-sm-5">
                          <label class="form-label"
                            >UOM 1<span class="wajib">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="forms.uom1"
                            disabled
                          />
                          <div
                            v-for="error in errors.uom1"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                        <div class="mb-3 col-sm-5">
                          <label class="form-label">UOM 2</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="forms.uom2"
                            disabled
                          />

                          <div
                            v-for="error in errors.uom2"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="mb-3 col-sm-5">
                          <label class="form-label">Min</label>
                          <input
                            type="number"
                            v-model.number="forms.min"
                            class="form-control"
                          />

                          <div
                            v-for="error in errors.min"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                        <div class="mb-3 col-sm-5">
                          <label class="form-label">Max</label>
                          <input
                            type="number"
                            v-model.number="forms.max"
                            class="form-control"
                          />

                          <div
                            v-for="error in errors.max"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="mb-3 col-sm-10">
                          <label class="form-label">Description</label>
                          <!-- <input
                            type="number"
                            v-model="forms.min"
                            class="form-control"
                          /> -->
                          <textarea
                            name="description"
                            id="description"
                            class="form-control"
                            v-model="forms.description"
                          ></textarea>

                          <div
                            v-for="error in errors.min"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="row mb-2">
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Effective Date
                              <span class="wajib">*</span></label
                            >
                            <date-range-picker
                              style="width: 100%"
                              :locale-data="locale"
                              v-model="effective_date"
                              disabled
                              :opens="opens"
                              :singleDatePicker="true"
                              :timePicker24Hour="true"
                              :timePicker="true"
                              @update="EffectiveDateChange"
                            />

                            <div
                              v-for="error in errors.effective_date"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Expired Date <span class="wajib">*</span></label
                            >
                            <date-range-picker
                              style="width: 100%"
                              :locale-data="locale"
                              v-model="expiration_date"
                              :opens="opens"
                              disabled
                              :singleDatePicker="true"
                              :timePicker24Hour="true"
                              :timePicker="true"
                              :date-format="dateFormatExpired"
                            />
                            <div
                              v-for="error in errors.expired_date"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="mb-3 col-sm-5">
                          <label class="form-label"
                            >Sub Tariff<span class="wajib">*</span></label
                          >
                          <v-select
                            :options="fetchSubTariff"
                            v-model="subTariff"
                            label="code"
                            return-object
                          ></v-select>

                          <div
                            v-for="error in errors.sub_tariff"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                        <div class="mb-3 col-sm-5">
                          <label class="form-label"
                            >Currency<span class="wajib">*</span></label
                          >
                          <v-select
                            :options="fetchCurrency"
                            v-model="currency"
                            label="code"
                            return-object
                          ></v-select>

                          <div
                            v-for="error in errors.currency"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-3">
                          <div class="mb-3">
                            <label class="form-label"
                              >No Intermediate Stop</label
                            >
                            <div class="mb-4">
                              <div class="form-check mb-2">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  id="intermediateStop"
                                  true-value="1"
                                  false-value="0"
                                  v-model="forms.no_intermediate_stop"
                                />
                                <label
                                  class="form-check-label"
                                  for="intermediateStop"
                                >
                                  Yes
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="mb-3">
                            <label class="form-label">All Inclusive</label>
                            <div class="mb-4">
                              <div class="form-check mb-2">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  id="allInclusive"
                                  true-value="1"
                                  false-value="0"
                                  v-model="forms.all_inclusive"
                                />
                                <label
                                  class="form-check-label"
                                  for="allInclusive"
                                >
                                  Yes
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <label class="form-label"
                            >Tier<span class="wajib">*</span></label
                          >
                          <v-select
                            :options="fetchTier"
                            v-model="tier"
                            label="code"
                            return-object
                          ></v-select>

                          <div
                            v-for="error in errors.tier"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="mb-3 col-sm-5">
                          <label class="form-label"
                            >Accessorial Application Rule<span class="wajib"
                              >*</span
                            ></label
                          >
                          <!-- <input
                            type="text"
                            class="form-control"
                            v-model="forms.accessorial_application_rule"
                          /> -->
                          <v-select
                            :options="fetchAccessorial"
                            @search="asyncAccessorialApplicationRule"
                            v-model="forms.accessorial_application_rule"
                            label="description"
                            track-by="description"
                            :reduce="acc => acc.description"
                          ></v-select>
                          <div
                            v-for="error in errors.billable_tariff"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                        <div class="mb-3 col-sm-5">
                          <label class="form-label"
                            >Allocation Method<span class="wajib"
                              >*</span
                            ></label
                          >
                          <v-select
                            :options="fetchAllocationMethod"
                            v-model="allocationMethod"
                            label="code"
                            return-object
                          ></v-select>

                          <div
                            v-for="error in errors.allocation_method"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-10">
                          <table class="table table-stripped">
                            <thead>
                              <tr>
                                <td>Charge Code</td>
                                <td>Rate</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>TMB_TAGIH_MUAT</td>
                                <td>
                                  {{ this.forms.loading | format_number }}
                                </td>
                              </tr>
                              <tr>
                                <td>TMB_TAGIH_BONGKAR</td>
                                <td>
                                  {{ this.forms.unloading | format_number }}
                                </td>
                              </tr>
                              <tr>
                                <td>TMB_TAGIH_MULTIDROP</td>
                                <td>
                                  {{ this.forms.multidrop | format_number }}
                                </td>
                              </tr>
                              <tr>
                                <td>TMB_TAGIH_INAP</td>
                                <td>
                                  {{ this.forms.overnight | format_number }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button
                    class="btn btn-warning text-black fw-bolder btn-icon-text"
                    @click="backForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="arrow-left"></i>
                    {{ $t("backMess") }}
                  </button>
                  <button
                    v-if="!isLoading"
                    class="btn btn-success float-end btn-sm"
                    type="submit"
                  >
                    <i class="link-icon" data-feather="save"></i>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import multiselect from "vue-multiselect";

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import CurrencyInput from "@/components/_partials/inputCurrency";
export default {
  name: "WonRate",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    multiselect: multiselect,

    CurrencyInput,
    DateRangePicker,
  },
  data() {
    const startDate = new Date();
    const endDate = new Date();
    return {
      fetchBillableTariff: [],
      billableTariff: null,

      fetchTypeTruck: [],
      typeTruck: null,

      fetchUnit: [],
      unit: null,

      fetchEquipmentType: [],
      equipmentType: null,

      fetchCurrency: [],
      currency: "IDR",

      fetchTier: [],
      tier: "Tier 1",

      fetchSubTariff: [],
      subTariff: "DEFAULT",

      fetchAccessorial: [],
      accessorial: null,

      fetchAllocationMethod: [],
      allocationMethod: "WEIGHT",

      effective_date: {
        startDate,
        endDate,
      },
      expiration_date: {
        startDate,
        endDate,
      },
      locale: {
        format: "dd mmm yyyy H:MM:ss",
      },
      opens: "center",
      forms: {},
      header: {},
      detailAll: [],
      permision_role: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
    };
  },
  methods: {
    setAccessorial(ev) {
      if (ev !== null) {
        // let data = "";
        // data += ev.tariff_name.substring(0, 3);
        // data += this.typeTruck?.type_truck.substring(0, 2);
        // data += this.forms?.origin.substring(0, 2);
        // data += this.forms?.destination.substring(0, 2);
        // data += this.forms?.uom1;
        // this.forms.accessorial_application_rule = ev.tariff_name + "_ACC";
        // this.forms.accessorial_application_rule = ev.tariff_name + "_ACC";
        // this.forms.accessorial_application_rule = data;
      }else{
        this.forms.accessorial_application_rule = "";
      }
    },
    submitData() {
      var effective_date = moment(this.effective_date.startDate).format(
        "YYYY-MM-DD HH:mm"
      );
      var expiration_date = moment(this.expiration_date.startDate).format(
        "YYYY-MM-DD HH:mm"
      );
      this.forms.rm_billable_tariff_id =
        this.billableTariff?.rm_billable_tariff_id;
      this.forms.billable_tariff_name = this.billableTariff?.tariff_name;
      this.forms.effective_date = effective_date;
      this.forms.expired_date = expiration_date;
      this.forms.tier = this.tier;
      this.forms.sub_tariff = this.subTariff;
      this.forms.currency = this.currency;
      this.forms.equipment_type = this.equipmentType;
      this.forms.allocation_method = this.allocationMethod;
      this.fade(true);
      var paramsDetail = this.$onBehind(this.$route.params.idDetail);
      const baseURI =
        this.$settings.endPoint + `sales-project/update-detail/` + paramsDetail;
      this.$http
        .patch(baseURI, this.forms)
        .then((response) => {
          if (response.data.status === 200) {
            this.errors = [];
            this.loading();
            window.location.href =
              "/sales-project/detail/" + this.$route.params.id;
            this.success("Berhasil");
          } else {
            this.loading();
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
        });
    },
    loadBillableTariff() {
      const baseURI =
        this.$settings.endPoint +
        `rm-billable-tariff?customer_code=${this.header.customer_code}`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchBillableTariff = response.data.datas.data;
        this.fade(false);
      });
    },
    asyncBillableTariff(ev) {
      const baseURI =
        this.$settings.endPoint +
        `rm-billable-tariff?customer_code=${this.header.customer_code}&tariff_name=${ev}`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchBillableTariff = response.data.datas.data;
        this.fade(false);
      });
    },
    EffectiveDateChange(ev) {
      var effectiveDate = moment(ev.startDate).format("YYYY-MM-DD");
      var ExpDate = moment(this.expiration_date.startDate).format("YYYY-MM-DD");
      if (ExpDate < effectiveDate) {
        this.expiration_date = {
          startDate: moment(effectiveDate).add(1, "days"),
          endDate: moment(effectiveDate).add(1, "days"),
        };
      }
    },
    dateFormatExpired(classes, date) {
      if (!classes.disabled) {
        classes.disabled =
          date.getTime() - 86400000 <= new Date(this.effective_date.startDate);
      }
      return classes;
    },
    loadSubTariff() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmstf`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchSubTariff = response.data.datas;
        this.fade(false);
      });
    },
    loadCurrency() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmcur`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchCurrency = response.data.datas;
        this.fade(false);
      });
    },
    asyncTypeTruck(ev) {
      const baseURI = this.$settings.endPointCt + "type-truck?type_truck=" + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTypeTruck = response.data.datas.data;
      });
    },
    loadTruckType() {
      const baseURI = this.$settings.endPointCt + "type-truck";
      return this.$http.get(baseURI).then((response) => {
        this.fetchTypeTruck = response.data.datas.data;
      });
    },
    loadTier() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmtier`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchTier = response.data.datas;
        this.fade(false);
      });
    },
    loadEquipmentType() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmet`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchEquipmentType = response.data.datas;
        this.fade(false);
      });
    }, 
    loadAccessorialApplicationRule() {
      const baseURI =
        this.$settings.endPoint + `rm-billable-acc-rule?status=ENABLED`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchAccessorial = response.data.datas.data;
        this.fade(false);
      });
    },
    asyncAccessorialApplicationRule(ev){
 const baseURI =
        this.$settings.endPoint + `rm-billable-acc-rule?status=ENABLED&description=`+ev;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchAccessorial = response.data.datas.data;
        this.fade(false);
      });
    },
    loadAllocationMethod() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmam`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchAllocationMethod = response.data.datas;
        this.fade(false);
      });
    },
    loadTypeTruck(ev){
      const baseURI = this.$settings.endPointCt + `type-truck?type_truck=${ev}&page=-1`;
      return this.$http.get(baseURI).then((response) => {
        let data = response.data.datas.data;
        for (let i = 0; i < data.length; i++) {
           if(data[i].type_truck == ev){
            this.equipmentType = data[i].equipment_type;
            break;
          }
        }
      });
    },
    loadData() {
      this.fade(true);
      var paramsDetail = this.$onBehind(this.$route.params.idDetail);
      const baseURI =
        this.$settings.endPoint + "sales-project/detail-detail/" + paramsDetail;
      return this.$http.get(baseURI).then((response) => {
        let data = response.data.datas;
        this.header = data.header;
        this.forms = {
          opty_id: data.opty_id,
          qty: data.qty,
          origin: data.origin,
          destination: data.destination,
          destination_k:data.destination_k,
          origin_k:data.origin_k,
          uom1: data.uom1,
          uom2: data.uom2,
          min: data.min,
          max: data.max,
          no_intermediate_stop: data.no_intermediate_stop !== null ? data.no_intermediate_stop : 0,
          all_inclusive: data.all_inclusive ? data.all_inclusive : 1,
          loading: data.loading,
          unloading: data.unloading,
          overnight: data.overnight,
          multidrop: data.multidrop,
          unit_price: data.unit_price,
          accessorial_application_rule: data.accessorial_application_rule,
          description: data.description,
        };
        
        // this.forms = data;
        // this.forms = response.data.datas;
        this.typeTruck = {
          type_truck: data.type_truck,
        };
        
        this.billableTariff = {
          rm_billable_tariff_id: data.rm_billable_tariff_id,
          tariff_name: data.billable_tariff_name,
        };

        if(data.equipment_type){
          this.equipmentType = data.equipment_type;
        }else{
          this.loadTypeTruck(data.type_truck);
        }
        this.tier = data.tier ? data.tier : "Tier 1";
        this.subTariff = data.sub_tariff ? data.sub_tariff : "DEFAULT";
        this.currency = data.currency ? data.currency : "IDR";
        // this.accessorial = data.accessorial_application_rule;
        this.allocationMethod = data.allocation_method ? data.allocation_method : "WEIGHT";

        if (data.effective_date) {
          this.effective_date = {
            startDate: new Date(data.effective_date),
            endDate: new Date(data.effective_date),
          };
        }

        if (data.expired_date) {
          this.expiration_date = {
            startDate: new Date(data.expired_date),
            endDate: new Date(data.expired_date),
          };
        }
        this.loadBillableTariff();
        this.loadTruckType();
        this.loadEquipmentType();
        this.loadSubTariff();
        this.loadCurrency();
        this.loadTier();
        this.loadAccessorialApplicationRule();
        this.loadAllocationMethod();
        this.fade(false);
        // console.log(response);
        // let data = response.data.datas;
      });
    },
    backForm() {
      window.location.href = "/sales-project/detail/" + this.$route.params.id;
    },
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "SalesProjectController") {
          rolePermision = permision;
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },
    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  mounted() {
    this.fetchIt();
    this.loadData();
  },
};
</script>

<style></style>
