import RutePenyebrangan from "@/components/master/rutePenyebrangan/RutePenyebrangan.vue";
import RutePenyebranganAdd from "@/components/master/rutePenyebrangan/RutePenyebranganAdd.vue";
import RutePenyebranganDetail from "@/components/master/rutePenyebrangan/RutePenyebranganDetail.vue";
import RutePenyebranganEdit from "@/components/master/rutePenyebrangan/RutePenyebranganEdit.vue";
import RutePenyebranganUpload from "@/components/master/rutePenyebrangan/RutePenyebranganUpload.vue";

let routesRutePenyebrangan= [
    
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "RutePenyebranganIndex",
        component: RutePenyebrangan
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "RutePenyebranganAdd",
        component: RutePenyebranganAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "RutePenyebranganEdit",
        component: RutePenyebranganEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "RutePenyebranganDetail",
        component: RutePenyebranganDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "RutePenyebranganUpload",
        component: RutePenyebranganUpload
      },
    
]

export default routesRutePenyebrangan
  