import InformasiBongkar from "@/components/dasboard-ct/informasiBongkar/InformasiBongkar.vue";
import InformasiBongkarAdd from "@/components/dasboard-ct/informasiBongkar/InformasiBongkarAdd.vue";
import InformasiBongkarDetail from "@/components/dasboard-ct/informasiBongkar/InformasiBongkarDetail.vue";
import InformasiBongkarEdit from "@/components/dasboard-ct/informasiBongkar/InformasiBongkarEdit.vue";
import InformasiBongkarUpload from "@/components/dasboard-ct/informasiBongkar/InformasiBongkarUpload.vue";

let routesInformasiBongkar= [
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "InformasiBongkar",
        component: InformasiBongkar
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "InformasiBongkarAdd",
        component: InformasiBongkarAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "InformasiBongkarEdit",
        component: InformasiBongkarEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "InformasiBongkarDetail",
        component: InformasiBongkarDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "InformasiBongkarUpload",
        component: InformasiBongkarUpload
      },
    
]

export default routesInformasiBongkar
  