<template>
  <div>
    <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
      <form class="forms-sample" @submit.prevent="submitData" method="POST" enctype="multipart/form-data">
        <div class="modal-dialog modal-xl" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Billable Tariff File Upload</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
                aria-label="btn-close"></button>
            </div>
            <div class="modal-body">
              <!--begin::loading-data-->
              <div v-if="isLoading" class="d-flex align-items-center">
                <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                <strong>{{ $t("loadingTxt") }}...</strong>
              </div>
              <!--end::loading-data-->
              <div class="row mb-3">
                <div class="col-lg-8">
                  <div class="row">
                    <div class="col-lg-4">
                      <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Customer
                      </label>
                    </div>
                    <div class="col-lg-8">
                      <multiselect @search-change="asyncSearchCompany" v-model="company" :options="fetchCompany"
                        label="name" track-by="name" placeholder="Please Select">
                        <span slot="noResult">-</span>
                      </multiselect>
                      <div v-for="error in errors.company" :key="error" class="alert alert-primary" role="alert">
                        <i data-feather="alert-circle"></i>
                        {{ error }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-lg-8">
                  <div class="row">
                    <div class="col-lg-4">
                      <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Billable Tariff
                        Name<span class="wajib">*</span>
                      </label>
                    </div>
                    <div class="col-lg-8">
                      <input type="text" v-model="forms.tariff_name" class="form-control" required :maxlength="20" />
                      <div v-for="error in errors.tariff_name" :key="error" class="alert alert-primary" role="alert">
                        <i data-feather="alert-circle"></i>
                        {{ error }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3">Max 20 Character</div>
              </div>
              <div class="row mb-3">
                <div class="col-lg-8">
                  <div class="row">
                    <div class="col-lg-4">
                      <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Tariff Type
                      </label>
                    </div>
                    <div class="col-lg-8">
                      <!-- <v-select :options="fetchTariffType" v-model="tariffType"></v-select> -->
                      <input type="text" v-model="forms.tariff_type" :disabled="true" class="form-control">
                      <div v-for="error in errors.tariff_type" :key="error" class="alert alert-primary" role="alert">
                        <i data-feather="alert-circle"></i>
                        {{ error }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-lg-8">
                  <div class="row">
                    <div class="col-lg-4">
                      <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Billable Sub Tariff
                        Name
                      </label>
                    </div>
                    <div class="col-lg-8">
                      <input type="text" :disabled="true" value="DEFAULT" class="form-control" />
                      <div v-for="error in errors.tariff_type" :key="error" class="alert alert-primary" role="alert">
                        <i data-feather="alert-circle"></i>
                        {{ error }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-lg-8">
                  <div class="row">
                    <div class="col-lg-4">
                      <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Description
                      </label>
                    </div>
                    <div class="col-lg-8">
                      <textarea cols="30" rows="2" class="form-control" v-model="forms.description"></textarea>
                      <div v-for="error in errors.description" :key="error" class="alert alert-primary" role="alert">
                        <i data-feather="alert-circle"></i>
                        {{ error }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div v-if="tariffType == 'Managed'" class="row mb-3">
                <div class="col-lg-8">
                  <div class="row">
                    <div class="col-lg-4">
                      <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Effective Range <span
                          class="wajib">*</span>
                      </label>
                    </div>
                    <div class="col-lg-8">
                      <date-range-picker style="width: 100%" control-container-class="form-control" :locale-data="locale"
                        v-model="effective_date" :opens="opens" :singleDatePicker="false" :timePicker24Hour="true"
                        :timePicker="true" :showWeekNumbers="false">
                      </date-range-picker>
                      <div v-for="error in errors.supported_format" :key="error" class="alert alert-primary" role="alert">
                        <i data-feather="alert-circle"></i>
                        {{ error }}
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="row mb-3">
                <div class="col-lg-8">
                  <div class="row">
                    <div class="col-lg-4">
                      <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Supported Format<span
                          class="wajib">*</span>
                      </label>
                    </div>
                    <div class="col-lg-8">
                      <v-select :options="fetchFormat" v-model="supportedFormat"></v-select>
                      <div v-for="error in errors.supported_format" :key="error" class="alert alert-primary" role="alert">
                        <i data-feather="alert-circle"></i>
                        {{ error }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-lg-8">
                  <div class="row">
                    <div class="col-lg-4">
                      <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">File Name<span
                          class="wajib">*</span>
                      </label>
                    </div>
                    <div class="col-lg-8">
                      <input type="file" ref="file" required class="form-control" @change="handleFileUpload($event)" />
                      <div v-for="error in errors.supported_format" :key="error" class="alert alert-primary" role="alert">
                        <i data-feather="alert-circle"></i>
                        {{ error }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-lg-8">
                  <div class="row">
                    <div class="col-lg-4">
                      <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Notification Email<span
                          class="wajib">*</span>
                      </label>
                    </div>
                    <div class="col-lg-8">
                      <input type="email" class="form-control" required v-model="forms.email" />
                      <div v-for="error in errors.email" :key="error" class="alert alert-primary" role="alert">
                        <i data-feather="alert-circle"></i>
                        {{ error }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-lg-8">
                  <div class="row">
                    <div class="col-lg-4">
                      <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Activate Rates
                      </label>
                    </div>
                    <div class="col-lg-8">
                      <div class="form-check mb-3">
                        <input type="checkbox" class="form-check-input" v-model="forms.activate_rate" true-value="1"
                          false-value="0" />
                        <label class="form-check-label" for="exampleCheck1">
                          Indicates if the rates should be active upon Import
                        </label>
                      </div>
                      <div v-for="error in errors.activate_rate" :key="error" class="alert alert-primary" role="alert">
                        <i data-feather="alert-circle"></i>
                        {{ error }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" :disabled="isLoading" class="btn btn-secondary" v-on:click="$emit('closeModal')">
                cancel
              </button>
              <button type="submit" class="btn btn-primary" :disabled="isLoading">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// import DateRangePicker from "vue2-daterange-picker";
// import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import multiselect from "vue-multiselect";
// import moment from "moment";
export default {
  name: "modalTariffFileUpload",
  emits: ["closeModal"],
  components: {
    multiselect: multiselect,
    // DateRangePicker,
  },
  props: {
    showModal: Boolean,
    data: null,
  },
  watch: {
    showModal: {
      handler(newVal) {
        this.active = newVal;
        this.forms = {};
        this.file = "";
        this.company = [],
          this.detailAll = [];
        const body = document.querySelector("body");
        if (this.active) {
          this.loadDetail();
        }
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    const startDate = new Date();
    const endDate = new Date();
    return {
      fetchRateBreakType: [],
      isLoading: false,
      file: "",
      opens: "center",

      tariffType: "Manual",
      fetchTariffType: ["Manual", "Managed"],

      supportedFormat: "Excel",
      fetchFormat: ["Excel", "Csv"],
      company: [],
      fetchCompany: [],
      active: this.showModal,
      effective_date: {
        startDate,
        endDate,
      },
      errors: [],
      forms: {},
      locale: {
        format: "dd mmm yyyy H:MM:ss",
      },
    };
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    asyncSearchCompany(ev) {
      console.log(ev);
      this.fetchCompany = [];
      const baseURI = this.$settings.endPoint + "company?name=" + ev;
      //this.isLoading = true;

      return this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          this.fetchCompany = response.data.datas.data;
        })
        .catch((error) => {
          //this.isLoading = false;

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },
    loadCompany() {
      const baseURI = this.$settings.endPoint + "company";
      //this.isLoading = true;

      return this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          this.fetchCompany = response.data.datas.data;
          console.log(response);
        })
        .catch((error) => {
          this.isLoading = false;

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },
    loadDetail() {

      var userModel = JSON.parse(localStorage.getItem("userModels"));


    
      this.fade(true);
      const baseURI = this.$settings.endPoint + "rm-billable-tariff/detail/" + this.data;
      return this.$http
        .get(baseURI)
        .then((response) => {
          let datas = response.data.datas;
          this.forms = datas;
          this.forms.tariff_type = 'Manual';
          this.company = {
            code: datas.customer.code,
            name: datas.customer.name,
          };
          this.supportedFormat = datas.file_format==null ? 'Excel' :  datas.file_format;
          this.effective_date = {
            startDate: datas.effective_date,
            endDate: datas.expiration_date
          };
          // this.forms.email=userModel['sub']['email'];
          this.forms.activate_rate=datas.activate_rate==null ? 1 : datas.activate_rate;
          this.forms.email=datas.email==null ? userModel['sub']['email'] :  datas.email;
          this.loading();
        });
    },
    submitData() {
      // var effective_date = moment(this.effective_date.startDate).format(
      //   "YYYY-MM-DD HH:mm"
      // );
      // var expiration_date = moment(this.effective_date.endDate).format(
      //   "YYYY-MM-DD HH:mm"
      // );
      // this.forms.customer_code = this.company.code;
      // this.forms.tariff_type = this.tariffType;
      // this.forms.file_format = this.supportedFormat;
      // this.forms.subtariff_name = "DEFAULT";
      this.fade(true);
      let formData = new FormData();
      formData.append("customer_code", this.company.code);
      formData.append("tariff_type", this.tariffType);
      formData.append("file_format", this.supportedFormat);
      formData.append("file_name", this.file);
      formData.append("subtariff_name", "DEFAULT");
      formData.append("tariff_name", this.forms.tariff_name);
      formData.append("description", this.forms.description);
      formData.append("email", this.forms.email);
      formData.append("activate_rate", this.forms.activate_rate ?? "0");
      // formData.append("effective_date", effective_date);
      // formData.append("expiration_date", expiration_date);
      const baseURI = this.$settings.endPoint + "rm-billable-tariff/upload/" + this.data;
      this.$http
        .post(baseURI, formData)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            this.errors = [];
            this.success2(response.data.datas.messages);
            this.$emit("closeModal");
          } else {
            this.errors = response.data.errors;
            if (response.data.errors.length >= 1) {
              this.showModalTransportError = true;
            } else {
              this.resultError(response.data.errors);
            }
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              if (error.response.data.errors.length >= 1) {
                this.showModalTransportError = true;
              } else {
                this.resultError(error.response.data.errors);
              }
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          this.resetForm();
        });
      // var 
      // for (var key in this.forms) {
      //   formData.append(key, this.forms[key]);
      // }

      // console.log([formData]);
    },
    fade(sType) {
      this.isLoading = sType;
    },
    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },
    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },
    success2(msg) {
      this.$swal({
        icon: "info",
        backdrop: true,
        title: "Information",
        html: "<p>* " + msg + "</p>",
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },
  },
  mounted() {
    this.loadCompany();
  },
};
</script>
<style scoped>
.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  /* min-height: 600px; */
  height: 100%;
  margin-left: 0px !important;
}
</style>
