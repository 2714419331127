import Branch from "@/components/master/branch/Branch.vue";
import BranchAdd from "@/components/master/branch/BranchAdd.vue";
import BranchDetail from "@/components/master/branch/BranchDetail.vue";
import BranchEdit from "@/components/master/branch/BranchEdit.vue";
import BranchUpload from "@/components/master/branch/BranchUpload.vue";

let routesBranch= [
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "Branch",
        component: Branch
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "BranchAdd",
        component: BranchAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "BranchEdit",
        component: BranchEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "BranchDetail",
        component: BranchDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "BranchUpload",
        component: BranchUpload
      },
    
]

export default routesBranch
  