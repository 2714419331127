<template>
  <div class="row">
    <div class="col-lg-8">
      <full-calender :options="calendarOptions" >
        <template v-slot:event="slotProps">
    <div class="fc-content">
      <span class="fc-title">{{ slotProps.event.title }} asdsadsad</span>
    </div>
  </template>
      </full-calender>
    </div>

    <div class="col-lg-4">
      <h2>On Duty vs Idle</h2>
        <Doughnut  :chart-options="chartOptions"
                          :chart-data="chartData"
                          :chart-id="chartId" />
        <div class="row">
          <p><b>On Duty : 21 Trip</b></p>
          <p>> 1 Trip : 17 Trip</p>
          <p>> 2 Trip : 2 Trip</p>
          <br>
          <p><b>IDLE : 7 Days</b></p>
        </div>
    </div>
  </div>
</template>
  
  
  <script>
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Doughnut } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)


export default {
  name: "workingDaysHistoryComponent",
  props: {
    detail: [],
    chartId: {
      type: String,
      default: 'doughnut-chart'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object ,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  components: {
    "full-calender": FullCalendar,
    Doughnut
  },
  // components: {
  //   "modal-trip-analysis":  modalTripAnalysis ,
  //   // DirectionsRenderer,
  // },
  watch: {
    
    //   detailOrder: function(val) {
    //     this.loadTable(val)
    //   },
  },
  data() {
    return {
      chartData : {
      labels: ['ON DUTY', 'IDLE'],
      datasets: [
        {
          backgroundColor: ['#E46651','#41B883'],
          data: [40, 20]
        }
      ]
    },
    chartOptions : {
      responsive: true,
      maintainAspectRatio: false
    },
       
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        dateClick: this.handleDateClick,
        eventDisplay: 'block',
        contentHeight: 'auto',
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth",
        },
        height: 500,
        events: [
          { title: "Hadir", date: "2022-11-06" },
          { title: "Hadir", date: "2022-11-07" },
          { title: "Hadir", date: "2022-11-08" },
          { title: "Hadir", date: "2022-11-09" },
          { title: "Hadir", date: "2022-11-10" },
          { title: "Hadir", date: "2022-11-11" },
          { title: "Hadir", date: "2022-11-12" },
          { title: "Hadir", date: "2022-11-13" },
        ],
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
      },
      //vehicle_no:this.$route.params.vehicle_no,
    };
  },
  methods: {
    handleDateClick: function (arg) {
      console.log(new Date());
      alert("date click! " + arg.dateStr);
    },
  },
};
</script>

<style >
.fc-scrollgrid-sync-table{
  width:100%;
}

.fc-col-header {
  width:100%;
}
</style>
