<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading"> {{ $t('loadingTxt') }}...</div>
    <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
      <!--begin::sidebar-->
      <sidebar-component classMenu="BranchIndex"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            ">
            <div>
              <h4 class="mb-12 mb-md-0"></h4>
            </div>

            <div class="col-lg-12 col-xl-12 stretch-card">
              <div class="card">
                <div class="card-header">
                  <h4 style="margin-right: 15px; margin-top: 5px" class="float-start">{{ $t("accident") }}</h4>

                  <div class="float-start" style="margin-right: 15px;">
                    <date-range-picker style="width: 100%" @change="filterDateRange"
                      control-container-class="form-control" :locale-data="locale" v-model="dateFilter" :opens="opens"
                      :singleDatePicker="false" :timePicker24Hour="true" :timePicker="true" :showWeekNumbers="false">
                    </date-range-picker>

                  </div>

                  <div class="float-start">
                    <button class="btn btn-warning float-end btn-sm" style="margin-right: 5px"
                      @click.prevent="filterDateRange">
                      Go
                    </button>
                  </div>


                  <button v-if="permision_role.method_create" class="btn btn-primary float-end btn-xs"
                    style="margin-right: 5px" @click.prevent="createData()">
                    <i class="link-icon" data-feather="plus"></i>
                    {{ $t("createNew") }}
                  </button>

                  <button class="btn btn-warning float-end btn-xs" style="margin-right: 5px"
                    @click.prevent="downloadData()">
                    <i class="link-icon" data-feather="upload"></i>
                    Report
                  </button>


                  <!-- <button
                    v-if="permision_role.method_read"
                    class="btn btn-success float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="downloadData()"
                  >
                     <i class="link-icon" data-feather="download"></i>
                    {{ $t("downloadData") }} .xlsx
                  </button> -->
                </div>

                <div class="card-body">
                  <vue-good-table title="list-of-gps-erorrs-reporting" mode="remote" @on-page-change="onPageChange"
                    @on-sort-change="onSortChange" @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange" :sort-options="{
      enabled: false,
    }" @on-selected-rows-change="selectionChanged" :select-options="{
      enabled: true,
      selectOnCheckboxOnly: true,
      selectionText: $t('data_selected'),
      clearSelectionText: $t('clear'),
    }" :totalRows="totalRecords" :line-numbers="true" :isLoading.sync="isLoading" :pagination-options="{
      enabled: true,
    }" :filterOptions="{
      enabled: false,
    }" :rows="rowsTable" :columns="columns">
                    <div slot="table-actions">
                      {{ $t("no_select_data") }}
                    </div>

                    <div slot="selected-row-actions">
                      <button class="btn btn-success btn-xs" style="margin-right:20px;" @click="updateStatusAll()"> <i
                          class="mdi mdi-recycle"></i> Update Status</button>


                    </div>

                    <!-- <div slot="table-actions">
                      <div class="vgt-clearfix">
                        <button class="btn btn-success btn-xs" style="font-size: 10pt; margin-top:-7px" @click.prevent="TambahFormModal()">
                          <i class="link-icon" data-feather="plus"></i>
                          {{ $t("createNew") }}
                        </button>
                      </div>
                    </div> -->
                    <template slot="table-row" slot-scope="props">

                      <span v-if="props.column.field == 'control'">
                        <!-- <a
                          href="#"
                          title="Detail"
                          @click.prevent="detailData(props.index, props.row)"
                        >
                          <i class="mdi mdi-eye" style="font-size: 16px"></i>
                        </a> -->

                        <a href="#" title="Edit" @click.prevent="editData(props.index, props.row)">
                          <i class="mdi mdi-pencil" style="font-size: 16px"></i>
                        </a>


                        <a href="#" title="Delete" @click.prevent="deleteData(props.index, props.row)">
                          <i class="mdi mdi-trash-can-outline" style="font-size: 16px"></i>
                        </a>
                      </span>
                      <span v-else-if="props.column.field == 'driver'">
                        {{ props.row.driver?.driver_name }}
                      </span>
                      <span v-else-if="props.column.field == 'accident_time'">
                        {{ getAccidentTime(props.row) }}
                      </span>
                      <span v-else-if="props.column.field == 'accident_month'">
                        {{ getAccidentMonth(props.row) }}
                      </span>
                      <span v-else-if="props.column.field == 'accident_year'">
                        {{ getAccidentYear(props.row) }}
                      </span>
                      <span v-else-if="props.column.field == 'age_category'">
                        {{ getKategoriUsia(props.row) }}
                      </span>
                      <span v-else-if="props.column.field == 'masa_kerja'">
                        {{ getMasaKerja(props.row) }}
                      </span>

                      <span v-else-if="props.column.field == 'tipe_unit'">
                        {{ props.row?.vehicle?.type_truck?.type_truck }}
                      </span>

                      <span v-else-if="props.column.field == 'status'">
                        <v-select control-container-class="form-control" :options="listStatus" v-model="status"
                          return-object></v-select>
                      </span>

                      <span v-else-if="props.column.field == 'lead_time'">
                        {{ cekLeadTime(props.row) }}
                      </span>

                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import DateRangePicker from "vue2-daterange-picker";
import moment from 'moment';

export default {
  name: "TraffictViolationIndex",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    DateRangePicker
  },
  data() {

    const startDate = moment().clone().startOf('month').format('YYYY-MM-DD'); // new Date();
    const endDate = new Date();
    return {
      opens: "center",
      listStatus: ["Open", "Close"],
      filterDateRangeValue: false,
      locale: {
        format: "yyyy-mm-dd",
      },
      dateFilter: {
        startDate,
        endDate,
      },
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      permision_role: [],
      langs: ["id", "en"],
      detailUser: [],
      detailDivisi: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      rowsTable: [],
      // columnsOrder: [
      //   {
      //     label:"Active Date",
      //     field:"active_date"
      //   },
      //   {
      //     label: "Load ID",
      //     field: "load_number",
      //   },

      //   {
      //     label: "Vehicle No",
      //     field: "vehicle_no",
      //   },

      //   {
      //     label: "Shipment Date",
      //     field: "shipment_date",
      //   },



      //   {
      //     label: "Driver NMK",
      //     field: "driver_id",
      //   },

      //   {
      //     label: "Driver Name",
      //     field: "driver_name",
      //   },
      //   {
      //     label: "Driver Hp",
      //     field: "driver_hp",
      //   },
      //   {
      //     label: "Rute",
      //     field: "route",
      //   },

      //   {
      //     label: "Accident Date",
      //     field: "accident_date",
      //   },

      //   {
      //     label: "Location",
      //     field: "accident_location",
      //   },

      //   {
      //     label: "Category",
      //     field: "accident_category",
      //   },

      //   {
      //     label: "Caused",
      //     field: "caused",
      //   },

      //   {
      //     label: "Accident Damage",
      //     field: "kerusakan",
      //   },

      //   {
      //     label: "Case Result",
      //     field: "case_result",
      //   },

      //   {
      //     label: "Complete Date",
      //     field: "complete_date",
      //   },

      //   {
      //     label: "Lead Time",
      //     field: "lead_time",
      //   },

      //   {
      //     label: "Control",
      //     field: "control",
      //   },
      // ],
      columns: [],
      columnsPrimary: [

        {
          label: "Date",
          field: "accident_date",
          width: '100px',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Jam Kejadian",
          field: "accident_hour",
          width: '150px',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Waktu Kejadian",
          field: "accident_time",
          width: '150px',
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Month",
          field: "accident_month",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Year",
          field: "accident_year",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Nama Driver",
          field: "driver",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Kategori Usia",
          field: "age_category",
          width: '150px',
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Masa Kerja",
          field: "masa_kerja",
          width: '150px',
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Tipe Unit",
          field: "tipe_unit",
          width: '150px',
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Plat Nomor",
          field: "vehicle_no",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Kategori",
          field: "accident_category",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Kerusakan",
          field: "kerusakan",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Site",
          field: "vehicle_owner",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Project",
          field: "project",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Faktor",
          field: "caused",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Haul",
          field: "haul",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Tipe Kejadian",
          field: "tipe_kejadian",
          width: '130px',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Penyebab Langsung",
          field: "penyebab_langsung",
          width: '150px',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Keterangan Penyebab Langsung",
          field: "keterangan_penyebab_langsung",
          width: '250px',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Penyebab Dasar",
          field: "penyebab_dasar",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Kronologis",
          field: "accident_detail",
          width: "500px",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Tipe Kecelakaan",
          field: "event_type_remark",
          width: '130px',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Penjelasan Penyebab langsung",
          field: "direct_cause_detail",
          width: '250px',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Action Plan",
          field: "action_plan",
          width: '130px',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Actions",
          field: "control",

        },
      ],
      columnsSecondary: [
        {
          label: "Date",
          field: "accident_date",
          width: '100px',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },

        },

        {
          label: "Jam Kejadian",
          field: "accident_hour",
          width: '150px',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Waktu Kejadian",
          field: "accident_time",
          width: '150px',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Month",
          field: "accident_month",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Year",
          field: "accident_year",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Nama Driver",
          field: "driver",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Sim",
          field: "driver.sim1_type",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Kategori Usia",
          field: "age_category",
          width: '150px',
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Masa Kerja",
          field: "masa_kerja",
          width: '150px',
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Tipe Unit",
          field: "vehicle.truck_category",
          width: '150px',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Plat Nomor",
          field: "vehicle_no",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Kategori",
          field: "accident_category",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Kerusakan",
          field: "kerusakan",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Site",
          field: "vehicle_owner",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Project",
          field: "project",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Faktor",
          field: "caused",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Haul",
          field: "haul",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Tipe Kejadian",
          field: "tipe_kejadian",
          width: '130px',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Kronologis",
          field: "accident_detail",
          width: "500px",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Tipe Kecelakaan",
          field: "event_type_remark",
          width: '130px',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },

        },
        {
          label: "Action Plan",
          field: "action_plan",
          width: '130px',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Actions",
          field: "control",
        },
      ],
      rows: [],
      driver_accident_id: [],
    };
  },
  watch: {},
  methods: {

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == 'DriverAccidentController') {
          rolePermision = permision;
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }

      if (rolePermision.method_custom1) {
        this.columns = this.columnsPrimary;
      } else {
        this.columns = this.columnsSecondary;
      }
      this.permision_role = rolePermision;

    },

    cekLeadTime(row) {
      if (row.accident_date != '0000-00-00' && row.complete_date != '0000-00-00' && row.complete_date != null) {
        var tglCompelete = new Date(row.complete_date);
        var tglAccident = new Date(row.accident_date);

        const diffTime = Math.abs(tglAccident - tglCompelete);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
      }


    },
    getAccidentTime(row) {
      var time = row.accident_hour;
      if (time) {
        var arr = time.split(":");
        var hour = arr[0];
        var waktu = "";
        if (hour >= 0 && hour < 3) {
          waktu = "Dini Hari";
        } else if (hour >= 3 && hour < 5) {
          waktu = "Subuh";
        } else if (hour >= 5 && hour < 10) {
          waktu = "Pagi";
        } else if (hour >= 10 && hour < 15) {
          waktu = "Siang";
        } else if (hour >= 15 && hour < 18) {
          waktu = "Sore";
        } else if (hour >= 18 && hour < 19) {
          waktu = "Petang";
        } else {
          waktu = "Malam";
        }
        return waktu;
      }

    },
    getAccidentMonth(row) {
      return moment(row.accident_date).format("MMMM");
    },
    getAccidentYear(row) {
      return moment(row.accident_date).format("Y");
    },
    getKategoriUsia(row) {
      let birthDate = row?.driver?.birth_date;
      let age = null;
      if (!isNaN(new Date(birthDate)) && birthDate) {
        age = moment().diff(row.driver.birth_date, 'years', false);
      }
      let agetime = "";
      if (age < 31) {
        agetime = "<31";
      } else if (age >= 31 && age < 41) {
        agetime = "31-40";
      } else if (age >= 41 && age < 51) {
        agetime = "41-50";
      } else if (age > 50) {
        agetime = ">50";
      } else {
        agetime = "N/A";
      }
      return agetime;
    },
    getMasaKerja(row) {
      let joinDate = row?.driver?.join_date;
      let work = 0;
      let worktime = "";
      if (!isNaN(new Date(joinDate)) && joinDate) {
        work = moment().diff(joinDate, 'months', false);
        if (work < 3) {
          worktime = "<3bulan";
        } else if (work >= 3 && work < 12) {
          worktime = "3 s.d <12 Bulan";
        } else if (work >= 12 && work < 24) {
          worktime = "1 s.d 2 Tahun";
        } else if (work >= 24 && work < 36) {
          worktime = ">2 Tahun s.d <3 Tahun";
        } else if (work >= 36 && work < 60) {
          worktime = "3 s.d 5 Tahun";
        } else {
          worktime = ">5 Tahun";
        }
        return worktime;
      }
    },
    selectionChanged(ev) {
      this.rowsSelection = [];
      this.rowsSelection = ev.selectedRows;

      this.rowsSelection.forEach((item) => {
        this.driver_accident_id.push(item.driver_accident_id);
        // this.transport_order_header_ids.push(item.transport_order_header_id);
        // if(item.order_status=='NEW'){
        //   this.error('Order No:'+item.order_status+' Status Not Submited, because status order not NEW or SUBMITED');
        // }else{

        // }

      });

    },


    updateStatusAll() {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          //this.loadingSubmiting();
          this.loading();
          let data = {
            driver_accident_ids: this.driver_accident_id,

          }
          const baseURI =
            this.$settings.endPointCt + "driver-accident/update-status";
          this.$http
            .patch(baseURI, data)
            .then((response) => {
              //this.loading();
              if (response.data.status === 200) {
                // response.data.datas.messages.forEach((item) => {
                //   this.success(item);
                // });

                this.success(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {

                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });

    },


    downloadData() {
      var start = moment(String(this.dateFilter.startDate,)).format('YYYY-MM-DD');
      var end = moment(String(this.dateFilter.endDate,)).format('YYYY-MM-DD');
      const baseURI = this.$settings.endPointCt + "/driver-accident";
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = "download_" + CurrentDate + ".xlsx";
      var url = `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&download=download`;
      if (this.filterDateRangeValue == true) {
        url = `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&start=${start}&end=${end}&download=download`;
      }
      //var columnFilters = this.serverParams.columnFilters;


      return this.$http
        .get(
          baseURI +
          url,
          {
            responseType: "blob",
          }
        )
        .then((response) => {

          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },

    // downloadData() {
    //   this.fade(true);
    //   var baseURI = this.$settings.endPoint + "/user/download";
    //   var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
    //   var columnFilters = this.serverParams.columnFilters;
    //   var role = columnFilters["role.name"];
    //   var company_code = columnFilters["company_detail.code"];

    //   var sendData = {
    //     username: this.serverParams.columnFilters.username,
    //     name: this.serverParams.columnFilters.name,
    //     email: this.serverParams.columnFilters.email,
    //     level: this.serverParams.columnFilters.level,
    //     status: this.serverParams.columnFilters.status,
    //     role:role,
    //     company_code: company_code,
    //     file_name: "download_" + CurrentDate + ".xlsx",
    //   };
    //   this.$http({
    //     url: baseURI,
    //     method: "GET",
    //     data: sendData,
    //     responseType: "blob",
    //   })
    //     .then((response) => {
    //       this.errors = [];
    //       var filename = sendData.file_name;

    //       var fileURL = window.URL.createObjectURL(new Blob([response.data]));
    //       var fileLink = document.createElement("a");

    //       fileLink.href = fileURL;
    //       fileLink.setAttribute("download", filename);
    //       document.body.appendChild(fileLink);
    //       fileLink.click();
    //       this.fade(false);
    //     })
    //     .catch((error) => {
    //       if (error.response) {
    //         if (error.response.status === 422) {
    //           this.errors = { message: ["File Not Found"] };
    //           this.error("File Not Found");
    //         } else if (error.response.status === 500) {
    //           this.$router.push("/server-error");
    //         } else {
    //           this.$router.push("/page-not-found");
    //         }
    //       }
    //     });
    // },
    createData() {
      window.location.href = "/accident/add";
    },

    uploadData() {
      window.location.href = "/branch/upload";
    },

    editData(index, row) {
      var params = this.$onRandom(row.driver_accident_id);
      window.location.href = "/accident/edit/" + params;
    },

    detailData(index, row) {
      var params = this.$onRandom(row.driver_accident_id);
      window.location.href = "/accident/detail/" + params;
    },

    deleteData(index, row) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // var formData = {
          //   status: status,
          // };
          const baseURI =
            this.$settings.endPointCt + "driver-accident/delete/" + row.driver_accident_id;
          this.$http
            .delete(baseURI)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    // load items is what brings back the rows from server
    loadItems() {
      const baseURI = this.$settings.endPointCt + "driver-accident";

      //var columnFilters = this.serverParams.columnFilters;
      this.isLoading = true;
      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&accident_date=${this.serverParams.columnFilters.accident_date}&accident_hour=${this.serverParams.columnFilters.accident_hour}&driver_name=${this.serverParams.columnFilters.driver}&vehicle_no=${this.serverParams.columnFilters.vehicle_no}&accident_category=${this.serverParams.columnFilters.accident_category}&kerusakan=${this.serverParams.columnFilters.kerusakan}&vehicle_owner=${this.serverParams.columnFilters.vehicle_owner}&project=${this.serverParams.columnFilters.project}&caused=${this.serverParams.columnFilters.caused}&haul=${this.serverParams.columnFilters.haul}&tipe_kejadian=${this.serverParams.columnFilters.tipe_kejadian}&penyebab_langsung=${this.serverParams.columnFilters.penyebab_langsung}&keterangan_penyebab_langsung=${this.serverParams.columnFilters.keterangan_penyebab_langsung}&penyebab_dasar=${this.serverParams.columnFilters.penyebab_dasar}&accident_detail=${this.serverParams.columnFilters.accident_detail}&event_type_remark=${this.serverParams.columnFilters.event_type_remark}&direct_cause_detail=${this.serverParams.columnFilters.direct_cause_detail}&action_plan=${this.serverParams.columnFilters.action_plan}`,
          { timeout: 10000 }
        )
        .then((response) => {
          this.isLoading = false;
          this.rowsTable = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
        }).catch(error => {

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));

          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }
        });
    },


    filterDateRange() {
      var start = moment(String(this.dateFilter.startDate,)).format('YYYY-MM-DD');
      var end = moment(String(this.dateFilter.endDate,)).format('YYYY-MM-DD');
      this.filterDateRangeValue = true;
      const baseURI = this.$settings.endPointCt + "driver-accident";

      this.isLoading = true;
      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&start=${start}&end=${end}`,
          { timeout: 10000 }
        )
        .then((response) => {
          this.isLoading = false;
          this.rowsTable = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
        }).catch(error => {

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));

          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }
        });

    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },


    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },
  },
  events: {},
  created: function () { },
  mounted() {
    // this.loadItems();
    this.fetchIt();
    this.loading();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content {
  filter: blur(5px);
  pointer-events: none;

}
</style>