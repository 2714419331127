<template>
    <div>
      <div class="main-wrapper">
        <!--begin::sidebar-->
        <sidebar-component classMenu="StopLocationAdd"></sidebar-component>
        <!--end::sidebar-->
  
        <div class="page-wrapper">
          <!--begin::navbar-->
          <navbar-component></navbar-component>
          <!--end::navbar-->
  
          <!--begin::content-->
          <div class="page-content">
            <div
              class="
                d-flex
                justify-content-between
                align-items-center
                flex-wrap
                grid-margin
              "
            >
              <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
                <div class="card">
                  <div class="card-header">
                    <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                    &nbsp;&nbsp;&nbsp;
                    <h6
                      class="card-title mb-0 float-start"
                      style="padding-left: 25px"
                    >
                      Manage Freight Billable Party
                    </h6>
                    <button
                      class="btn btn-default btn-sm float-end"
                      @click="resetForm"
                      type="button"
                    >
                      <i class="link-icon" data-feather="repeat"></i>
                      {{ $t("resetFormTxt") }}
                    </button>
                  </div>
                  <div class="card-body">
                    <!--begin::loading-data-->
                    <div v-if="isLoading" class="d-flex align-items-center">
                      <div
                        class="spinner-border ms-auto"
                        role="status"
                        aria-hidden="true"
                      ></div>
                      <strong>{{ $t("loadingTxt") }}...</strong>
                    </div>
                    <!--end::loading-data-->
  
                    <form
                      class="forms-sample"
                      @submit.prevent="submitData"
                      method="POST"
                    >
                      <div
                        class="
                          d-flex
                          justify-content-between
                          align-items-baseline
                          mb-2
                        "
                      ></div>
  
                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label
                            for="defaultconfig"
                            class="col-form-label"
                            style="padding-left: 25px"
                            >Reference <span style="color:red">*</span></label
                          >
                        </div>
                        <div class="col-lg-8">
                          <!-- <input
                            type="text"
                            class="form-control"
                            id="reference"
                           
                            v-model="forms.reference"
                            required
                          /> -->

                          <v-select
                        
                          :options="fetchCompany"
                          v-model="reference"
                          label="sap_code"
                          return-object
                          @search="asyncSearchCompany"
                        
                          
                        ></v-select>

                          <div v-if="errors.reference">
                            <div
                              v-for="error in errors.reference"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
  
  
                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label
                            for="defaultconfig"
                            class="col-form-label"
                            style="padding-left: 25px"
                            >Name <span style="color:red">*</span></label
                          >
                        </div>
                        <div class="col-lg-8">
                          <input
                            type="text"
                            class="form-control"
                            id="name"
                          
                            v-model="forms.name"
                            required
                          />
                          <div v-if="errors.name">
                            <div
                              v-for="error in errors.name"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      

                      



                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label
                            for="defaultconfig"
                            class="col-form-label"
                            style="padding-left: 25px"
                            >{{ $t("country") }}
                            <span style="color:red">*</span>
                            </label
                          >
                        </div>
                        <div class="col-lg-8">
                          <!-- <v-select
                          :options="fetchCountry"
                          v-model="country"
                          label="name"
                        >
                        </v-select> -->

                        <v-select
                          :options="fetchCountryCode"
                          v-model="country"
                          label="country"
                          return-object
                          @input="contryCodeChange"
                        ></v-select>

                          <!-- <input
                            type="text"
                            class="form-control"
                            id="city"
                            :placeholder="$t('city')"
                            v-model="forms.city"
                          /> -->
                          <div v-if="errors.country">
                            <div
                              v-for="error in errors.country"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label
                            for="defaultconfig"
                            class="col-form-label"
                            style="padding-left: 25px"
                            >State / Province <span style="color:red">*</span></label
                          >
                        </div>
                        <div class="col-lg-8">
                          <!-- <input
                            type="text"
                            class="form-control"
                            id="state"
                             placeholder="State"
                            v-model="forms.state"
                          /> -->
                          <v-select
                          :options="featchProvinceCode"
                            v-model="province"
                            @input="provinceCodeChange"
                            label="province"
                            return-object
                          ></v-select>

                          <div v-if="errors.state">
                            <div
                              v-for="error in errors.state"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>

                      
                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label
                            for="defaultconfig"
                            class="col-form-label"
                            style="padding-left: 25px"
                            >{{ $t("city") }} <span style="color:red">*</span></label
                          >
                        </div>
                        <div class="col-lg-8">
                          <!-- <input
                            type="text"
                            class="form-control"
                            id="city"
                            :placeholder="$t('city')"
                            v-model="forms.city"
                          /> -->
                          <v-select
                          :options="featchCityCode"
                          v-model="city"
                          label="city"
                          return-object
                          @input="cityCodeChange"
                        ></v-select>
                          <div v-if="errors.city">
                            <div
                              v-for="error in errors.city"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
  
                      
  
                    
  
  
                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label
                            for="defaultconfig"
                            class="col-form-label"
                            style="padding-left: 25px"
                            >Postal Code <span style="color:red">*</span></label
                          >
                        </div>
                        <div class="col-lg-8">
                          <input
                            type="text"
                            class="form-control"
                            id="postal_code"
                             placeholder="Postal Code"
                            v-model="forms.postal_code"
                          />
                          <div v-if="errors.postal_code">
                            <div
                              v-for="error in errors.postal_code"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label
                            for="defaultconfig"
                            class="col-form-label"
                            style="padding-left: 25px"
                            >{{ $t("address") }} 1</label
                          >
                        </div>
                        <div class="col-lg-8">
                          <input
                            type="text"
                            class="form-control"
                            id="address_1"
                        
                            v-model="forms.address_1"
                          />
                          <div v-if="errors.address_1">
                            <div
                              v-for="error in errors.address_1"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>


                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label
                            for="defaultconfig"
                            class="col-form-label"
                            style="padding-left: 25px"
                            >{{ $t("address") }} 2</label
                          >
                        </div>
                        <div class="col-lg-8">
                          <input
                            type="text"
                            class="form-control"
                            id="address_2"
                        
                            v-model="forms.address_2"
                          />
                          <div v-if="errors.address_2">
                            <div
                              v-for="error in errors.address_2"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>


                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label
                            for="defaultconfig"
                            class="col-form-label"
                            style="padding-left: 25px"
                            >{{ $t("address") }} 3</label
                          >
                        </div>
                        <div class="col-lg-8">
                          <input
                            type="text"
                            class="form-control"
                            id="address_1"
                        
                            v-model="forms.address_3"
                          />
                          <div v-if="errors.address_3">
                            <div
                              v-for="error in errors.address_3"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
  
  
  
                      
                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label
                            for="defaultconfig"
                            class="col-form-label"
                            style="padding-left: 25px"
                            >TimeZone <span style="color:red">*</span></label
                          >
                        </div>
                        <div class="col-lg-8">
                          <v-select
                          :options="fetchTimeZone"
                          v-model="timeZone"
                          label="name"
                        >
                        </v-select>
                          <!-- <input
                            type="text"
                            class="form-control"
                            id="city"
                            :placeholder="$t('city')"
                            v-model="forms.city"
                          /> -->
                          <div v-if="errors.timeZone">
                            <div
                              v-for="error in errors.timeZone"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      

                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label
                            for="defaultconfig"
                            class="col-form-label"
                            style="padding-left: 25px"
                            >Enabled</label
                          >
                        </div>
                        <div class="col-lg-8">
                         
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="enabled"
                            v-model="forms.enabled"
                          />
                          <div v-if="errors.enabled">
                            <div
                              v-for="error in errors.enabled"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                    
  
  
                      
                      <div class="card-footer">
                        <button
                          class="
                            btn btn-warning
                            text-black
                            fw-bolder
                            btn-icon-text
                          "
                          @click="backForm"
                          type="button"
                        >
                          <i class="link-icon" data-feather="arrow-left"></i>
                          {{ $t("backMess") }}
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                          class="btn btn-primary float-end btn-sm"
                          type="submit"
                        >
                          <i class="link-icon" data-feather="save"></i>
                          {{ $t("submitFormTxt") }}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <!--end::card-->
              </div>
            </div>
          </div>
          <!--end::content-->
  
          <!--begin::footer-->
          <footer-component></footer-component>
          <!--end::footer-->
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import sidebarComponent from "@/components/_partials/_sidebar";
  import navbarComponent from "@/components/_partials/_navbar";
  import footerComponent from "@/components/_partials/_footer";
  import myMixinAddress from '@/middleware/mixinAddress.js';
  
  
  export default {
    name: "BillablePartiesAdd",
    props: {},
    mixins: [myMixinAddress],
    components: {
      "sidebar-component": sidebarComponent,
      "navbar-component": navbarComponent,
      "footer-component": footerComponent,
    },
    data() {
      return {
        
        isLoading: false,
        fetchTimeZone:[{
          name:'(GMT + 07.00 Asia / Jakarta)'
        }],
        timeZone:'',
      
        fetchCountry:[{
          name:'Indonesia'
        }],

        fetchCompany:[],
        reference:[],
        country:'',
        fetchCountryCode:[],
        featchProvinceCode: [],
        featchCityCode: [],
        city:'',
        province:'',
        maxToasts: 100,
        position: "up right",
        closeBtn: true,
        errors: [],
        permision_role: [],
        userData: "",
        companyCode: "",
        forms: { enabled:true },
      };
    },
    watch: {},
    methods: {
      resetForm() {
        this.forms=[];
      },
  
      backForm() {
        window.location.href = "/billable-parties";
      },
  
      submitData() {
        this.fade(true);
        this.forms.country=this.country.country;
        this.forms.state=this.province.province;
        this.forms.city=this.city.city;
        this.forms.reference=this.reference.sap_code

        this.forms.time_zone=this.timeZone.name;
        this.forms.status=this.forms.enabled==true ? 'ENABLED': 'DISABLED';

        const baseURI = this.$settings.endPoint + "rm-freight-billable-parties/create";
        this.$http
          .post(baseURI, this.forms)
          .then((response) => {
            this.loading();
            if (response.data.status === 200) {
              //this.resetForm();
              this.errors = [];
              //console.log(response.data.datas.user_id);
              var params = this.$onRandom(response.data.datas.rm_freight_billable_parties_id);
              window.location.href = "/billable-parties/detail/" + params + "?status=200&msg=Successfully";
              // this.success('Berhasil');
            } else {
              this.errors = response.data.errors;
              this.resultError(response.data.errors);
            }
          })
          .catch((error) => {
            this.loading();
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
            //this.resetForm();
          });
      },

      success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          setTimeout(() => {
            // if(this.company_id==null || this.company_id=='' || this.company_id==undefined){
            //   window.location.href = '/billable-method';
            // }else{
            //   var params = this.$onRandom(this.company_id);
            //   window.location.href = '/billable-method?company_id='+params;
            // }
            
          }, 3000);
        },
       
      });
      Toast.fire({
        icon: 'success',
        title: kata
      });
    },
  
      fade(sType) {
        this.isLoading = sType;
      },
  
      loading() {
        this.fade(true);
        setTimeout(() => {
          this.fade(false);
        }, 1000); // hide the message after 3 seconds
      },
  
      fetchIt() {
        const userDatas = this.$getUserInfo();
        this.detailUser = userDatas.sub;
        // this.permision_role = userDatas.sub.permision_role;
        let rolePermision = [];
        userDatas.sub.permision_role.forEach(function (permision) {
          if (permision.permission.controller == "RmFreightBillablePartiesController") {
            rolePermision = permision;
            // console.log(permision);
          }
        });
  
        if (!rolePermision.method_create) {
          this.$router.push("/authorized-error");
        }
  
        this.permision_role = rolePermision;
      },
  
      resultError(data) {
        var count = Object.keys(data).length;
        for (var x = 0; x < count; x++) {
          var nameOb = Object.keys(data)[x];
          var objectData = data[nameOb];
          for (var y = 0; y < objectData.length; y++) {
            this.error(objectData[y]);
          }
        }
      },


      loadCompany() {
      
      const baseURI = this.$settings.endPoint + "company";
      //this.isLoading = true;
     
      return this.$http
        .get(
          baseURI,{timeout: 10000 }
        )
        .then((response) => {
            this.fetchCompany=response.data.datas.data;

        }).catch(error => {
          //this.isLoading = false;
           
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              // if(error.response.status === 422) {
              //   this.errors = error.response.data.errors;
              //   this.resultError(error.response.data.errors);
              // }else if (error.response.status === 500) {
              //     this.$router.push('/server-error');
              // }else if (error.response.status === 401) {
              //     this.$router.push("/authorized-error");
              // }else{
              //     this.$router.push('/page-not-found');
              // }
            }

          }

        });
    },


    asyncSearchCompany(ev) {
      this.fetchCompany=[];
      const baseURI = this.$settings.endPoint + "company?sap_code="+ev;
      //this.isLoading = true;
     
      return this.$http
        .get(
          baseURI,{timeout: 10000 }
        )
        .then((response) => {
            this.fetchCompany=response.data.datas.data;

        }).catch(error => {
          //this.isLoading = false;
           
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              }else if (error.response.status === 500) {
                  this.$router.push('/server-error');
              }else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
              }else{
                  this.$router.push('/page-not-found');
              }
            }

          }

        });
    },
   
  
      // success(kata) {
      //   const Toast = this.$swal.mixin({
      //     toast: true,
      //     position: "top-end",
      //     showConfirmButton: false,
      //     timer: 3000,
      //     timerProgressBar: true,
      //     didOpen: (toast) => {
      //       toast.addEventListener("mouseenter", this.$swal.stopTimer);
      //       toast.addEventListener("mouseleave", this.$swal.resumeTimer);
      //     },
      //   });
      //   Toast.fire({
      //     icon: "success",
      //     title: kata,
      //   });
      // },
  
      error(kata) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: kata,
        });
      },
    },
    events: {},
    created: function () {},
    mounted() {
      this.fetchIt();
      this.loadCountryCode();
      this.loadCompany();
    },
  };
  </script>
  <style scoped>
  </style>