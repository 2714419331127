var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(this.isLoading)?_c('div',{staticClass:"loader"}):_vm._e(),(this.isLoading)?_c('div',{staticClass:"loadertext"},[_vm._v(" "+_vm._s(_vm.$t('loadingTxt'))+"...")]):_vm._e(),_c('div',{staticClass:"main-wrapper",class:{ 'blur-content': this.isLoading }},[_c('sidebar-component',{attrs:{"classMenu":"DashboardFullfilment"}}),_c('div',{staticClass:"page-wrapper"},[_c('navbar-component'),_c('div',{staticClass:"page-content"},[_vm._m(0),_c('div',{staticClass:"d-flex justify-content-between align-items-center flex-wrap grid-margin"},[_c('div',{staticClass:"col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-lg-2 col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.proformaInvoiceNo),expression:"proformaInvoiceNo"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"PI Number"},domProps:{"value":(_vm.proformaInvoiceNo)},on:{"change":function($event){return _vm.loadLocationTable()},"input":function($event){if($event.target.composing)return;_vm.proformaInvoiceNo=$event.target.value}}})])]),_c('div',{staticClass:"col-lg-2 col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.loadId),expression:"loadId"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Load Id"},domProps:{"value":(_vm.loadId)},on:{"change":function($event){return _vm.loadLocationTable()},"input":function($event){if($event.target.composing)return;_vm.loadId=$event.target.value}}})])]),_vm._m(1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('vue-good-table',{attrs:{"title":"list-order-ct","mode":"remote","select-options":{ 
                                                              enabled: true,
                                                              selectOnCheckboxOnly: true, 
                                                              selectionText: _vm.$t('data_selected'), 
                                                              clearSelectionText: _vm.$t('clear'), },"totalRows":_vm.totalRecords,"line-numbers":true,"isLoading":_vm.isLoading,"pagination-options":{
                                                  enabled: true,
                                              },"rows":_vm.rows,"columns":_vm.columns},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-selected-rows-change":_vm.selectionChanged},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[_c('button',{staticClass:"btn btn-primary btn-icon",staticStyle:{"color":"white","text-decoration":"none","cursor":"pointer"},attrs:{"type":"button","title":"Delete"},on:{"click":function($event){return _vm.deleteData(props.index, props.row)}}},[_c('i',{staticClass:"mdi mdi-delete",staticStyle:{"font-size":"16px"}})])]):(props.column.field == 'entries')?_c('span',[_c('a',{staticStyle:{"color":"#ab0635","text-decoration":"none","cursor":"pointer"},attrs:{"title":"Detail Load Id"},on:{"click":function($event){$event.preventDefault();return _vm.detailPi(props.row)}}},[_vm._v(" "+_vm._s(props.row.entries)+" ")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[_c('button',{staticClass:"btn btn-success btn-xs",staticStyle:{"margin-right":"20px"}},[_c('i',{staticClass:"mdi mdi-file-document"}),_vm._v(_vm._s(_vm.$t("downloadData"))+" .xlsx ")])])])],1)])])])])]),_c('modal-proformaInvoiceDetail',{attrs:{"showModal":_vm.showModalPI,"data":this.detailData},on:{"closeModal":_vm.closeMyModal}})],1),_c('footer-component')],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-between align-items-center flex-wrap grid-margin"},[_c('div',[_c('h4',{staticClass:"mb-3 mb-md-0"},[_vm._v("Proforma Invoice : Printed")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-1 col-md-12 col-sm-12 col-xs-12"},[_c('button',{staticClass:"btn btn-primary float-end"},[_vm._v(" Reset Filter ")])])
}]

export { render, staticRenderFns }