<template>
<div>
    <router-view />
</div>
</template>

<script>
export default {
    name:"PreformaInvoiceIndex"
}
</script>

<style>

</style>