import FppkPenerimaan from "@/components/fppk/penerimaan/penerimaanFppk.vue";
// import FppkPenerimaanAdd from "@/components/fppk/penerimaan/penerimaanFppkAdd.vue";
import FppkPenerimaanEdit from "@/components/fppk/penerimaan/penerimaanFppkEdit.vue";
import FppkPenerimaanDetail from "@/components/fppk/penerimaan/penerimaanFpkkDetail.vue";

let routes= [
    
    {
      path: '/',
      meta: {
        title: "Chain System",
      },
      name: "FppkPenerimaan",
      component: FppkPenerimaan
    },
    // {
    //   path: 'add',
    //   meta: {
    //     title: "Chain System",
    //   },
    //   name: "PenerimaanFppkAdd",
    //   component: FppkPenerimaanAdd
    // },
    {
      path: 'detail/:id',
      meta: {
        title: "Chain System",
      },
      name: "PenerimaanFppkDetail",
      component: FppkPenerimaanDetail
    },
    {
      path: 'edit/:id',
      meta: {
        title: "Chain System",
      },
      name: "PenerimaanFppkEdit",
      component: FppkPenerimaanEdit
    },
  ]
  
  export default routes
  