<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>   
    <div class="loadertext" v-if="this.isLoading"> {{$t('loadingTxt')}}...</div>   
    <div class="main-wrapper" :class="{'blur-content': this.isLoading}">
      <!--begin::sidebar-->
      <sidebar-component classMenu="FakturPajakDetail"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            ">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left: 25px">
                    {{ $t("FakturPajakDetail") }}
                  </h6>

                  <button class="btn btn-light float-end" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    <i class="link-icon" data-feather="info"></i>
                    {{ $t("audit_info") }}
                  </button>

                  <div class="row mb-3 collapse" id="collapseExample" style="padding-left: 20px">
                   
                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0"> {{ $t("create_by_txt") }}:</label>
                      <p class="text-muted">{{ this.forms.create_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">{{ $t("create_at_txt") }}:</label>
                      <p class="text-muted">{{ this.forms.created_at }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">{{ $t("update_by_txt") }}:</label>
                      <p class="text-muted">{{ this.forms.update_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">{{ $t("update_at_txt") }}:</label>
                      <p class="text-muted">{{ this.forms.updated_at }}</p>
                    </div>

                  </div>
                </div>

                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->
                  <form class="forms-sample" @submit.prevent="submitData" method="POST">
                    <div class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "></div>

                    <div class="row mb-3">

                      <div class="col-lg-6">

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('id_pemotong')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" readonly class="form-control" id="id_pemotong"
                              :placeholder="$t('id_pemotong')" v-model="forms.id_pemotong" />


                            <div v-if="errors.id_pemotong">
                              <div v-for="error in errors.id_pemotong" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('name_pemotong')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" readonly class="form-control" id="nama_pemotong"
                              :placeholder="$t('name_pemotong')" v-model="forms.nama_pemotong" />


                            <div v-if="errors.nama_pemotong">
                              <div v-for="error in errors.nama_pemotong" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('id_dipotong')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" readonly class="form-control" id="id_dipotong" :placeholder="$t('id_dipotong')"
                              v-model="forms.id_dipotong" required />
                            <div v-if="errors.id_dipotong">
                              <div v-for="error in errors.id_dipotong" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('nama_dipotong')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" readonly class="form-control" id="nama_dipotong"
                              :placeholder="$t('nama_dipotong')" v-model="forms.nama_dipotong" />


                            <div v-if="errors.nama_dipotong">
                              <div v-for="error in errors.nama_dipotong" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('pasal')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" readonly class="form-control" id="pasal" :placeholder="$t('pasal')"
                              v-model="forms.pasal" />
                            <div v-if="errors.pasal">
                              <div v-for="error in errors.pasal" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('kode_objek_pajak')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" readonly class="form-control" id="kode_objek_pajak"
                              :placeholder="$t('kode_objek_pajak')+' ex. 00-000-00'" v-model="forms.kode_objek_pajak" />
                            <div v-if="errors.kode_objek_pajak">
                              <div v-for="error in errors.kode_objek_pajak" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('no_bukti_potong')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" readonly class="form-control" id="no_bukti_potong"
                              :placeholder="$t('no_bukti_potong')" v-model="forms.no_bukti_potong" />
                            <div v-if="errors.no_bukti_potong">
                              <div v-for="error in errors.no_bukti_potong" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                      <div class="col-lg-6">

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('tgl_bukti_potong')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" readonly class="form-control" id="tanggal_bukti_potong"
                              :placeholder="$t('tgl_bukti_potong')+' ex. 01-2022'" v-model="forms.tanggal_bukti_potong" />

                            <div v-if="errors.tanggal_bukti_potong">
                              <div v-for="error in errors.tanggal_bukti_potong" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('pph_dipotong')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <money v-model="forms.pph_dipotong" readonly v-bind="money" class="form-control"></money>
                            <!--input type="text" readonly class="form-control" id="pph_dipotong"
                              :placeholder="pph_dipotong" v-model="forms.pph_dipotong" /-->
                            <div v-if="errors.pph_dipotong">
                              <div v-for="error in errors.pph_dipotong" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('jumlah_bruto')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <money readonly v-model="forms.jumlah_bruto" v-bind="money" class="form-control"></money>
                            <!--input type="text" readonly class="form-control" id="jumlah_bruto"
                              :placeholder="jumlah_bruto" v-model="forms.jumlah_bruto" /-->
                            <div v-if="errors.jumlah_bruto">
                              <div v-for="error in errors.jumlah_bruto" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label"
                              style="padding-left: 25px">{{$t('keterangan')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text-area" readonly class="form-control" id="keterangan"
                              :placeholder="$t('keterangan')" v-model="forms.keterangan" />
                            <div v-if="errors.keterangan">
                              <div v-for="error in errors.keterangan" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('file')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <!--input type="text" readonly class="form-control" id="file" :placeholder="file"
                              v-model="forms.file" /-->

                            <a v-if="permision_role.method_read" :href="forms.link_file" target="_blank"
                              title="Download File" class="btn btn-outline-success btn-sm">
                              <i class="link-icon"></i>{{$t('download_file')}}
                            </a>

                            <div v-if="errors.file">
                              <div v-for="error in errors.file" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="card-footer">
                      <button class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        " @click="backForm" type="button">
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>

                      <a v-if="permision_role.method_delete" class="
                          btn btn-danger
                          text-white
                          fw-bolder
                          btn-icon-text
                          float-end
                        " @click="deleteData()">
                        <i class="link-icon" data-feather="trash"></i>
                        {{ $t("deleteMess") }}
                      </a>

                      <a v-if="permision_role.method_update" class="
                          btn btn-primary
                          me-2
                          text-white
                          fw-bolder
                          btn-icon-text
                          float-end
                        " @click="editData()">
                        <i class="link-icon" data-feather="edit"></i>
                        {{ $t("editMess") }}
                      </a>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import { Money } from 'v-money';


export default {
  name: "FakturPajakDetail",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    Money
  },
  data() {
    return {
      permision_role: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      forms: [],
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        suffix: '',
        masked: false /* doesn't work with directive */
      },
    };
  },
  watch: {},
  methods: {
    formatKode() {
      //console.log(this.forms.kode_objek_pajak)
      let x = this.forms.kode_objek_pajak
        .replace(/\D/g, "")
        .match(/(\d{0,2})(\d{0,3})(\d{0,2})/);

      this.forms.kode_objek_pajak = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");

    },
    fixDate(event) {
      return this.$moment(event).format('MM-YYYY');
    },
    resetForm() {

    },

    downloadFile({ link_file, file_name }) {
      return this.$http
        .get(
          link_file,
          {
            responseType: "arraybuffer",
          }
        )

        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },

    cek_message(status, msg) {
      if (status == "200") {
        this.success(msg);
      }
    },

    editData() {
      var params = this.$route.params.id;
      window.location.href = "/faktur-pajak/edit/" + params;
    },

    deleteData() {
      var id = this.$onBehind(this.$route.params.id);
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI = this.$settings.endPointFP + "finance/root/faktur-pajak/delete/" + id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                window.location.href = "/faktur-pajak";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },



    backForm() {
      window.location.href = "/faktur-pajak";
    },

    loadData(id) {
      
      this.isLoading = true;
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      var  baseURI   = "";
      if (this.detailUser.role.name == 'user_faktur_pajak') {
        baseURI = this.$settings.endPointFP + "finance/user/faktur-pajak/detail/" + id;
      } else {
        baseURI = this.$settings.endPointFP + "finance/root/faktur-pajak/detail/" + id;
      }
 
      return this.$http.get(baseURI,{ timeout: 10000 }).then((response) => {

        this.forms = response.data.datas;
        this.isLoading = false;
      }).catch(error => {
        this.isLoading = false;
        if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
        }else{

            if (error.response) {
            if(error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
            }else if (error.response.status === 500) {
                this.$router.push('/server-error');
            }else if (error.response.status === 401) {
                this.$router.push('/authorized-error');
            }else{
                this.$router.push('/page-not-found');
            }
            }

        }

        });
    },


    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == 'FakturPajakController' || permision.permission.controller == 'FakturPajakUserController') {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;

    },


    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () { },
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.cek_message(this.$route.query.status, this.$route.query.msg);
    this.loadData(params[0]);
    this.fetchIt();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}
.blur-content{
  filter: blur(5px); 
}
</style>