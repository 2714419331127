<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TransportOrderEdit"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
            <div class="card">
              <div class="card-header">
                <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                &nbsp;&nbsp;&nbsp;
                <h6
                  class="card-title mb-0 float-start"
                  style="padding-left: 25px"
                >
                  Transport Order
                </h6>
                <button
                  class="btn btn-default float-end"
                  @click="resetForm"
                  type="button"
                >
                  <i class="link-icon" data-feather="repeat"></i>
                  {{ $t("resetFormTxt") }}
                </button>
              </div>

              <div class="card-body">
                <!--begin::loading-data-->
                <div v-if="isLoading" class="d-flex align-items-center">
                  <div
                    class="spinner-border ms-auto"
                    role="status"
                    aria-hidden="true"
                  ></div>
                  <strong>{{ $t("loadingTxt") }}...</strong>
                </div>
                <!--end::loading-data-->
                <form
                  class="forms-sample"
                  @submit.prevent="submitData"
                  method="POST"
                >
                  <div
                    class="d-flex justify-content-between align-items-baseline mb-2"
                  ></div>

                  <div class="row card">
                    <div class="card-body">
                      <div class="row">
                        <!-- Col -->
                        <div class="col-sm-3">
                          <div class="mb-3">
                            <label class="form-label"
                              >Order No <span class="wajib">*</span></label
                            >
                            <input
                              type="text"
                              id="order_no"
                              name="order_no"
                              v-model="forms.order_no"
                              class="form-control"
                              placeholder="Order No"
                              @keydown.space.prevent
                              disabled
                            />

                            <div
                              v-for="error in errors.order_no"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <!-- Col -->
                        <div class="col-sm-3">
                          <div class="mb-3">
                            <label class="form-label"
                              >Shipwith Ref <span class="wajib">*</span></label
                            >
                            <input
                              type="text"
                              id="shipwith_ref"
                              name="shipwith_ref"
                              v-model="forms.shipwith_ref"
                              class="form-control"
                              placeholder="Shipwith Ref"
                              @keydown.space.prevent
                            />
                            <div
                              v-for="error in errors.shipwith_ref"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="mb-3">
                            <label class="form-label"
                              >Company Code <span class="wajib">*</span></label
                            >
                            <multiselect
                              v-if="companyCode == 'LINC'"
                              @search-change="asyncSearchCompany"
                              v-model="company"
                              :options="fetchCompany"
                              label="code"
                              track-by="code"
                              :custom-label="customLabelCompany"
                              @input="getLocation"
                              placeholder="Please Select Company"
                            >
                              <span slot="noResult">-</span>
                            </multiselect>

                            <multiselect
                              v-if="companyCode != 'LINC'"
                              @search-change="asyncSearchCompanyNotLinc"
                              v-model="company"
                              :options="fetchCompanyNotLinc"
                              label="code"
                              track-by="code"
                              :custom-label="customLabelCompany"
                              @input="getLocation"
                              placeholder="Please Select Company"
                            >
                              <span slot="noResult">-</span>
                            </multiselect>
                          </div>

                          <div v-if="errors.company_id">
                            <div
                              v-for="error in errors.company_id"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="mb-3">
                            <label class="form-label"
                              >Order Date <span class="wajib">*</span></label
                            >
                            <date-range-picker
                              style="width: 100%"
                              :locale-data="locale"
                              v-model="order_date"
                              :opens="opens"
                              :singleDatePicker="true"
                              :timePicker24Hour="true"
                              :timePicker="true"
                              :date-format="dateFormatOrder"
                            >
                            </date-range-picker>
                            <div
                              v-for="error in errors.order_date"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-3">
                          <div class="mb-3">
                            <label class="form-label"
                              >Branch <span class="wajib">*</span></label
                            >
                            <multiselect
                              
                              v-model="branch"
                              :options="fetchBranch"
                              label="branch_name"
                              track-by="branch_name"
                              placeholder="Please Select"
                            >
                              <span slot="noResult">-</span>
                            </multiselect>
                            <div
                              v-for="error in errors.branch"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="mb-3">
                            <label class="form-label"
                              >Truck Type <span class="wajib">*</span></label
                            >
                            <multiselect
                             
                              v-model="truckType"
                              :options="fetchTruckType"
                              label="type_truck"
                              track-by="type_truck"
                              placeholder="Please Select"
                              @input="changeTypeTruck"
                            >
                              <span slot="noResult">-</span>
                            </multiselect>
                            <div
                              v-for="error in errors.truck_type"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="mb-3">
                            <label class="form-label"
                              >Tonnage <span class="wajib">*</span></label
                            >
                            <input
                              type="number"
                              id="tonnage"
                              name="tonnage"
                              v-model="forms.tonnage"
                              class="form-control"
                              placeholder="Tonnage"
                            />
                            <div
                              v-for="error in errors.tonnage"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-3">
                          <div class="mb-3">
                            <label class="form-label">Cubic</label>
                            <input
                              type="number"
                              id="cubic"
                              name="cubic"
                              v-model="forms.cubic"
                              class="form-control"
                              placeholder="Cubic"
                            />
                            <div
                              v-for="error in errors.cubic"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-3">
                          <div class="mb-3">
                            <label class="form-label"
                              >Pickup Plan <span class="wajib">*</span></label
                            >

                            <date-range-picker
                              style="width: 100%"
                              :date-format="dateFormat"
                              control-container-class="form-control"
                              :locale-data="locale"
                              v-model="pickupDates"
                              :opens="opens"
                              :singleDatePicker="true"
                              :timePicker24Hour="true"
                              :timePicker="true"
                              :showWeekNumbers="false"
                              @update="pickDateChange"
                            >
                            </date-range-picker>

                            <!-- <input type="text" id="order_date" name="order_date" v-model="forms.order_date" class="form-control" placeholder="Order Date"> -->
                            <div
                              v-for="error in errors.pick_plan"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="mb-3">
                            <label class="form-label"
                              >Destination Plan
                              <span class="wajib">*</span></label
                            >
                            <!-- <Datepicker
                                     :highlighted="highlighted"
                                    v-model="forms.dest_plan"
                                    :bootstrap-styling=true
                                    format="dd-MM-yyyy"
                                    placeholder="Please Select Date..."
                                  ></Datepicker> -->

                            <date-range-picker
                              style="width: 100%"
                              :locale-data="locale"
                              :date-format="dateFormatDest"
                              v-model="destDates"
                              :opens="opens"
                              :singleDatePicker="true"
                              :timePicker24Hour="true"
                              :timePicker="true"
                              @update="destDateChange"
                            >
                            </date-range-picker>

                            <!-- <input type="text" id="order_date" name="order_date" v-model="forms.order_date" class="form-control" placeholder="Order Date"> -->
                            <div
                              v-for="error in errors.dest_plan"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="mb-3">
                            <label class="form-label">Shipment Rate</label>
                            <div>
                              <div class="form-check form-check-inline">
                                <input
                                  @input="changeShipmentRate('Sameday')"
                                  type="radio"
                                  v-model="forms.shipment_rate"
                                  value="Sameday"
                                  class="form-check-input"
                                  name="shipment_rate"
                                  id="shipment_rate"
                                />
                                <label
                                  class="form-check-label"
                                  for="shipment_rate"
                                >
                                  Sameday
                                </label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input
                                  @input="changeShipmentRate('Regular')"
                                  type="radio"
                                  v-model="forms.shipment_rate"
                                  value="Regular"
                                  class="form-check-input"
                                  name="shipment_rate"
                                  id="shipment_rate"
                                />
                                <label
                                  class="form-check-label"
                                  for="shipment_rate"
                                >
                                  Regular
                                </label>
                              </div>
                            </div>
                            <div
                              v-for="error in errors.cubic"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-3">
                          <div class="mb-3">
                           
                            <label class="form-label">Shipment Type</label>
                            <v-select
                              :options="fetchOrderType"
                              v-model="order_type"
                              label="name"
                              return-object
                             
                            ></v-select>

                          </div>
                        </div>

                      </div>
                      <div class="row">
                        <div class="col-sm-8">
                          <div class="mb-3">
                            <label class="form-label"
                              >Route <span class="wajib">*</span></label
                            >
                            <input
                              type="text"
                              name="route"
                              id="route"
                              v-model="route"
                              class="form-control"
                              placeholder="Route"
                              disabled
                            />
                          </div>
                          <div
                            class="row"
                            v-if="this.route !== '' && this.route !== undefined"
                          >
                            <div class="col-sm-6">
                              <p class="text-muted">
                                <b>{{ location.name }}</b>
                                <i class="mdi mdi-map-marker"></i>Lat.{{
                                  location.latitude
                                }}
                                Long.{{ location.longitude }} <br />
                                {{ location.address1 }}
                                {{ location.address2 }}
                                {{ location.address3 }}
                                {{ location.sub_district }},
                                {{ location.district }}, {{ location.city }},
                                {{ location.province }}
                                {{ location.postal_code }}, {{ location.country
                                }}<br />
                                {{ location.contact_name }} -
                                {{ location.phone_number }}
                              </p>
                            </div>
                            <div class="col-sm-6">
                              <p class="text-muted">
                                <b>{{ location_dest.name }}</b>
                                <i class="mdi mdi-map-marker"></i>Lat.{{
                                  location_dest.latitude
                                }}
                                Long.{{ location_dest.longitude }} <br />
                                {{ location_dest.address1 }}
                                {{ location_dest.address2 }}
                                {{ location_dest.address3 }}
                                {{ location_dest.sub_district }},
                                {{ location_dest.district }},
                                {{ location_dest.city }},
                                {{ location_dest.province }}
                                {{ location_dest.postal_code }},
                                {{ location_dest.country }}<br />
                                {{ location_dest.contact_name }} -
                                {{ location_dest.phone_number }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-1">
                          <div class="mb-3 pt-4">
                            <button
                              type="button"
                              @click="pickRate"
                              class="btn btn-success"
                            >
                              Browse
                            </button>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="mb-3">
                            <label class="form-label">Billable Tariff </label>
                            <input
                              type="text"
                              name="billableTariff"
                              id="billableTariff"
                              class="form-control"
                              v-model="billableTariff"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="mb-3">
                            <label class="form-label">Remarks</label>
                            <textarea
                              id="remark"
                              name="remark"
                              v-model="forms.remarks"
                              class="form-control"
                              placeholder="Remark"
                            ></textarea>
                            <div
                              v-for="error in errors.remarks"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Col -->
                  </div>
                  <!-- Row -->

                  <div class="card-footer">
                    <button
                      class="btn btn-warning text-black fw-bolder btn-icon-text"
                      @click="backForm"
                      type="button"
                    >
                      <i class="link-icon" data-feather="arrow-left"></i>
                      {{ $t("backMess") }}
                    </button>

                    <!-- &nbsp;&nbsp;&nbsp;
                    <button class="btn btn-success float-end btn-sm" type="submit">
                      <i class="link-icon" data-feather="save"></i>
                      {{ $t("submitOrderTxt") }}
                    </button> -->
                    &nbsp;&nbsp;&nbsp;
                    <button
                      v-if="!isLoading"
                      class="btn btn-success float-end btn-sm"
                      type="submit"
                    >
                      <i class="link-icon" data-feather="save"></i>
                      {{ $t("submitOrderTxt") }}
                    </button>

                    <div v-if="isLoading" class="d-flex align-items-center">
                      <div
                        class="spinner-border ms-auto"
                        role="status"
                        aria-hidden="true"
                      ></div>
                      <strong>{{ $t("loadingTxt") }}...</strong>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <modal-route-transport
              :showModal="modalRouteTransport"
              :data="dataModalRoute"
              @closeModal="closeMyModal"
            ></modal-route-transport>
            <modal-route-region
              :showModal="modalPickRegion"
              :data="dataModalRegion"
              @closeModal="closeMyRegion"
            ></modal-route-region>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
// import Datepicker from "vuejs-datepicker";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import multiselect from "vue-multiselect";
import modalRouteTransport from "@/components/modal/modalRouteTransport.vue";
import modalRouteRegion from "@/components/modal/modalRouteRegion.vue";

export default {
  name: "TemplateOrderEdit",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    // Datepicker,
    DateRangePicker,
    multiselect: multiselect,
    "modal-route-transport": modalRouteTransport,
    "modal-route-region": modalRouteRegion,
  },
  data() {
    
    const startDate = new Date();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 6);

    return {
      disabledDates: {
        to: new Date(startDate.getTime() - 86400000),
      },

      fetchOrderType: [
      {
        name:'ONCALL',
      },{
        name:'DEDICATED',
      }],
      order_type:{
        name:'ONCALL'
      },

      opens: "center",
      locale: {
        format: "dd mmm yyyy H:MM:ss",
      },
      pickupDates: {
        startDate,
        endDate,
      },
      destDates: {
        startDate,
        endDate,
      },
      order_date: {
        startDate,
        endDate,
      },
      pickup_address: true,
      destination_address: true,
      fetchladingType: [],
      detailAll: [],
      fetchSKU: [],
      fetchCompany: [],
      fetchTemplateOrder: [],
      fetchCompanyNotLinc: [],
      fetchLocation: [],
      fetchLocationDest: [],
      fetchpackageType: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      permision_role: [],
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      company: [],
      template_order: [],
      location: [],
      location_dest: [],
      highlighted: {
        dates: [
          // Highlight an array of dates
          new Date(),
        ],
      },

      fetchBranch: [],
      fetchTruckType: [],
      branch: [],
      truckType: [],
      forms: [],
      route: "",
      billableTariff: "",
      billableDetailId: "",
      dataModalRoute: [],
      dataModalRegion: [],
      modalRouteTransport: false,
      modalPickRegion: false,
    };
  },
  watch: {},
  methods: {
    // dateFormatOrder (classes, date) {
    //   if (!classes.disabled) {
    //       classes.disabled = (date.getTime() + 86400000) < new Date() ;
    //   }

    //   return classes
    // },
    // dateFormat(classes, date) {

    //   if (!classes.disabled) {
    //     classes.disabled = date.getTime() < new Date(this.order_date.startDate);
    //   }
    //   return classes
    // },

    // dateFormatDest(classes, date) {
    //   if (!classes.disabled) {
    //     classes.disabled = date.getTime() < this.pickupDates.startDate;
    //   }
    //   return classes
    // },

    // dateFormatDest (classes, date) {
    //   if (!classes.disabled) {
    //     // classes.disabled = date.getTime() < this.pickupDates.startDate;
    //     classes.disabled = date.getTime() < new Date(this.pickupDates.startDate.getTime() - 86400000);
    //    }
    //   return classes
    // },
    dateFormat(classes, date) {
      // console.log(this.forms.shipment_rate);
      if (!classes.disabled) {
        if (this.forms.shipment_rate == "Regular") {
          classes.disabled =
            date.getTime() < new Date(this.order_date.startDate);
        } else if (this.forms.shipment_rate == "Sameday") {
          classes.disabled =
            date.getTime() + 43200000 <= new Date(this.order_date.startDate);
        }
      }
      return classes;
    },
    dateFormatOrder(classes, date) {
      if (!classes.disabled) {
        classes.disabled = date.getTime() + 86400000 < new Date();
      }

      return classes;
    },
    dateFormatDest(classes, date) {
      // console.log(this.pickupDates.startDate - 8640000);
      if (!classes.disabled) {
        if (this.forms.shipment_rate == "Regular") {
          classes.disabled =
            date.getTime() < new Date(this.order_date.startDate);
        } else if (this.forms.shipment_rate == "Sameday") {
          classes.disabled =
            date.getTime() + 43200000 <= new Date(this.order_date.startDate);
        }
      }
      return classes;
    },
    selectTemplateOrder(ev) {
      this.forms.tonnage = ev.tonnage;
      this.forms.cubic = ev.cubic;
    },

    cekData(index, fieldType) {
      fieldType[index].sku_description = fieldType[index].sku.sku_description;
      fieldType[index].lading_length = fieldType[index].sku.pcs_length;
      fieldType[index].lading_width = fieldType[index].sku.pcs_width;
      fieldType[index].lading_height = fieldType[index].sku.pcs_height;
      //fieldType[index].package_type = fieldType[index].sku.package_code;
      fieldType[index].lading_qty = fieldType[index].sku.lading_qty;
      fieldType[index].net_weight = fieldType[index].sku.net_weight;
      fieldType[index].weight = fieldType[index].sku.weight;
      fieldType[index].volume = fieldType[index].sku.volume;
      //fieldType[index].qty = 0;
      this.loadLadingType(fieldType[index].sku.sku_id);
    },

    customLabelCompany(option) {
      return `${option.code} - ${option.name}`;
    },

    cekQty(index, fieldType) {
      let qty = fieldType[index].qty;
      let sku_id = fieldType[index].sku.sku_id;
      let lading_type = fieldType[index].lading_type;
      let package_type = fieldType[index].package_type;

      let data = {
        sku_id: sku_id,
        qty: qty,
        lading_type: lading_type.package_type,
        package_type: package_type.package_type,
      };

      const baseURI = this.$settings.endPoint + "package/calculate";

      return this.$http.post(baseURI, data).then((response) => {
        let data = response.data.datas[0];

        fieldType[index].lading_height = data.lading_height;
        fieldType[index].lading_length = data.lading_length;
        fieldType[index].lading_qty = data.lading_qty;
        fieldType[index].lading_width = data.lading_width;
        fieldType[index].net_weight = data.net_weight;
        fieldType[index].volume = data.volume;
        fieldType[index].weight = data.weight;
        //fieldType[index].package_type= data.package_type;
      });
    },

    // cek(index,fieldType){
    //   console.log(index,fieldType);
    //    //this.loadLadingType(fieldType[index].sku.sku_id);
    // },


    loadPackage(ev) {
      this.fetchTruckType=[];
      const baseURI =
        this.$settings.endPoint +
        `package?limit=1000&company_code=`+ev;
      return this.$http.get(baseURI).then((response) => {
        response.data.datas.data.forEach((item) => {
          var row = {
            type_truck: item.package_code,       // Assuming the API returns these keys
            truck_capacity: item.pcs_net_weight,
            truck_cubic: item.pcs_volume,
          };
          // Push each row into the fetchTruckType array
          this.fetchTruckType.push(row);
        });
        // this.fetchTruckType = response.data.datas.data;
      });
    },

    resetForm() {
      this.forms = "";
      this.company = "";
      this.location = "";
      this.location_dest = "";
      this.pickup_address = false;
      this.destination_address = false;
      this.template_order = "";
      this.detailAll = [
        {
          sku: [],
          qty: 0,
          lading_length: 0,
          lading_width: 0,
          lading_height: 0,
          lading_type: "-",
          package_type: "-",
          volume: 0,
          weight: 0,
          net_weight: 0,
          lading_qty: 0,
        },
      ];
    },

    getLocation(ev) {
      this.fetchLocation = [];
      this.fetchLocationDest = [];
      this.template_order = [];
      this.company_name = ev.name;
      const baseURI =
        this.$settings.endPoint + "location?company_id=" + ev.company_id;

      return this.$http.get(baseURI).then((response) => {
        this.fetchLocation = response.data.datas.data;
        this.fetchLocationDest = response.data.datas.data;
        this.loadPackage(ev.code);
        this.loadSKU(this.company_name);
        this.loadTemplateOrder(this.company_name);
      });
    },

    loadLadingType(sku_id) {
      this.fetchladingType = [];
      const baseURI =
        this.$settings.endPoint + "package/select-package-type/" + sku_id;

      return this.$http.get(baseURI).then((response) => {
        this.fetchladingType = response.data.datas;
        this.fetchpackageType = response.data.datas;

        // item.forEach((item) => {
        //     const data = {
        //       name: item.package_type,
        //     };
        //     this.fetchladingType.push(data);
        //   });
      });
    },

    loadLocation(company_name) {
      this.fetchLocation = [];
      this.fetchLocationDest = [];
      const baseURI =
        this.$settings.endPoint + "location?company_name=" + company_name;

      return this.$http.get(baseURI).then((response) => {
        this.fetchLocation = response.data.datas.data;
        this.fetchLocationDest = response.data.datas.data;
      });
    },

    getLocationPickup(ev) {
      this.forms.pick_name = ev.name;
      this.forms.pick_country = ev.country;
      this.forms.pick_province = ev.province;
      this.forms.pick_city = ev.city;
      this.forms.pick_district = ev.district;
      this.forms.pick_sub_district = ev.sub_district;
      this.forms.pick_postal_code = ev.postal_code;
      this.forms.pick_address1 = ev.address1;
      this.forms.pick_address2 = ev.address2;
      this.forms.pick_address3 = ev.address3;
      this.forms.pick_latitude = ev.latitude;
      this.forms.pick_longitude = ev.longitude;
      this.forms.pick_radius = ev.radius;
      this.forms.pick_contact_name = ev.contact_name;
      this.forms.pick_phone_number = ev.phone_number;
    },

    getLocationDest(ev) {
      this.forms.dest_name = ev.name;
      this.forms.dest_country = ev.country;
      this.forms.dest_province = ev.province;
      this.forms.dest_city = ev.city;
      this.forms.dest_district = ev.district;
      this.forms.dest_sub_district = ev.sub_district;
      this.forms.dest_postal_code = ev.postal_code;
      this.forms.dest_address1 = ev.address1;
      this.forms.dest_address2 = ev.address2;
      this.forms.dest_address3 = ev.address3;
      this.forms.dest_latitude = ev.latitude;
      this.forms.dest_longitude = ev.longitude;
      this.forms.dest_radius = ev.radius;
      this.forms.dest_contact_name = ev.contact_name;
      this.forms.dest_phone_number = ev.phone_number;
    },

    asyncSearchLocation(ev) {
      let company_name = this.company.name;
      const baseURI =
        this.$settings.endPoint +
        "location?company_name=" +
        company_name +
        "&location_name=" +
        ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchLocation = response.data.datas.data;
      });
    },

    backForm() {
      this.$swal({
        title: this.$t("areYouSure"),
        text: "Leave page? All changes will not be saved, please confirm ?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          window.location.href = "/order/new-transport-order";
        }
      });
    },

    loadCompany() {
      const baseURI = this.$settings.endPoint + "company?limit=10";

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },

    loadCompanyNotLinc() {
      const baseURI = this.$settings.endPoint + `company/select`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },

    asyncSearchCompany(ev) {
      const baseURI = this.$settings.endPoint + "company?code=" + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },

    asyncSearchCompanyNotLinc(ev) {
      const baseURI = this.$settings.endPoint + "company/select?code=" + ev;
      this.companyCode;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },

    customLabel(ev) {
      return `${ev.location_code} – ${ev.name}`;
    },

    loadSKU(company_name) {
      const baseURI =
        this.$settings.endPoint + `sku?company_name=` + company_name;

      return this.$http.get(baseURI).then((response) => {
        this.fetchSKU = response.data.datas.data;
      });
    },

    asyncSearchSKU(ev) {
      const baseURI =
        this.$settings.endPoint +
        "sku?sku_code=" +
        ev +
        "?company_name=" +
        this.company_name;
      return this.$http.get(baseURI).then((response) => {
        this.fetchSKU = response.data.datas.data;
      });
    },

    asyncSearchLocationDest(ev) {
      let company_name = this.company.name;
      const baseURI =
        this.$settings.endPoint +
        "location?company_name=" +
        company_name +
        "&location_name=" +
        ev;
      this.fetchLocationDest = [];
      return this.$http.get(baseURI).then((response) => {
        this.fetchLocationDest = response.data.datas.data;
      });
    },
    loadTemplateOrder(company_name) {
      const baseURI =
        this.$settings.endPoint +
        `transport-order-template?company_name=` +
        company_name;

      return this.$http.get(baseURI).then((response) => {
        this.fetchTemplateOrder = response.data.datas.data;
      });
    },

    asyncSearchTemplateOrder(ev) {
      const baseURI =
        this.$settings.endPoint +
        "transport-order-template?description=" +
        ev +
        "&company_name=" +
        this.company_name;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTemplateOrder = response.data.datas.data;
      });
    },

    loadData(id) {
      //console.log("Saya adalah frontend developer".split("").reverse().join(""));
      const baseURI =
        this.$settings.endPoint + "transport-order-header-new/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        this.forms = response.data.datas;
        this.company = this.forms.company;
        this.company_name = this.company.name;
        // this.template_order = this.forms.transport_order_template;
        // this.loadSKU(this.company_name);
        // this.loadTemplateOrder(this.company_name);
        // this.loadLocation(this.company_name);
        this.order_type={
          name:this.forms.order_type,
        }
        this.location = this.forms.pick_location;
        this.location_dest = this.forms.dest_location;
        this.branch = {
          branch_name: this.forms.branch_name,
        };
        this.truckType = {
          type_truck: this.forms.type_truck,
        };

        this.billableTariff = this.forms.billable_tariff;
        this.route = this.forms.pick_name + "-" + this.forms.dest_name;

        this.pickupDates = {
          startDate: new Date(this.forms.pick_plan),
          endDate: new Date(this.forms.pick_plan),
        };
        this.order_date = {
          startDate: new Date(this.forms.order_date),
          endDate: new Date(this.forms.order_date),
        };
        this.destDates = {
          startDate: new Date(this.forms.dest_plan),
          endDate: new Date(this.forms.dest_plan),
        };


        this.loadPackage(this.company.code);
        // this.forms.transport_order_detail.forEach((item) => {
        //   this.loadLadingType(item.sku_id);

        //   const data = {
        //     sku: {
        //       sequence_no: "",
        //       sku_id: item.sku_id,
        //       barcode: item.barcode,
        //       sku_code: item.sku_code,
        //       sku_description: item.sku_description,
        //       sku_long_description: item.sku_long_description,
        //       // package_type:item.package_type,
        //       // lading_qty:item.lading_qty,
        //       // lading_type:item.lading_type,
        //       // price:item.price,
        //       // amount:item.amount,
        //       // lading_width:item.lading_width,
        //       // lading_height:item.lading_height,
        //       // weight:item.weight,
        //       // net_weight:item.net_weight,
        //       // volume:item.volume,
        //       stackable: item.stackable,
        //       commodity_id: item.commodity_id,
        //     },
        //     sku_description: item.sku_description,
        //     lading_length: item.lading_length,
        //     lading_width: item.lading_width,
        //     lading_height: item.lading_height,
        //     lading_type: { package_type: item.lading_type },
        //     package_type: { package_type: item.package_type },
        //     volume: item.volume,
        //     weight: item.weight,
        //     net_weight: item.net_weight,
        //     lading_qty: item.lading_qty,
        //     qty: item.qty,
        //   };

        //   this.detailAll.push(data);
        //   // console.log(this.detailAll);
        // });

        // this.form={ name: 'asep', usernmae:res.username };
      });
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      this.companyCode = this.detailUser.company_detail.code;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (
          permision.permission.controller == "TransportOrderHeaderNewController"
        ) {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_update) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    submitData() {
      this.fade(true);
      var pickPlan = moment(this.pickupDates.startDate).format(
        "YYYY-MM-DD HH:mm"
      );
      var destPlan = moment(this.destDates.startDate).format(
        "YYYY-MM-DD HH:mm"
      );
      var currentDate = moment(new Date()).format("YYYY-MM-DD HH:mm");
      if (pickPlan < currentDate) {
        this.error(this.$t("validatePickDate"));
        this.fade(false);
        return true;
      }

      if (destPlan < currentDate) {
        this.error(this.$t("validateDestDate"));
        this.fade(false);
        return true;
      }

      this.forms.company_id = this.company.company_id;
      this.forms.type_truck = this.truckType.type_truck;
      this.forms.branch_name = this.branch.branch_name;
      this.forms.pick_location_id = this.location.location_id;
      this.forms.dest_location_id = this.location_dest.location_id;
      this.forms.billable_tariff = this.billableTariff;
      // this.forms.order_source_id = "DATABASE";
      // this.forms.order_status = "NEW";
      // this.forms.status_tms = "NEW";
      // this.forms.transport_order_template_id =
      //   this.template_order.transport_order_template_id;
      this.forms.pick_plan = moment(String(this.pickupDates.startDate)).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.forms.dest_plan = moment(String(this.destDates.startDate)).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.forms.order_date = moment(String(this.order_date.startDate)).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      // this.forms.order_no = this.forms.order_no.split(" ").join("");
      // this.forms.shipwith_ref = this.forms.shipwith_ref.split(" ").join("");

      // this.forms.order_no = this.forms.order_no.split("&").join("AND");
      // this.forms.shipwith_ref = this.forms.shipwith_ref.split("&").join("AND");
      // this.forms.remarks = this.forms.remarks.split("&").join("AND");

      if (this.forms.order_no) {
        this.forms.order_no = this.forms.order_no.split(" ").join("");
        this.forms.order_no = this.forms.order_no.split("&").join("AND");
      }
      if (this.forms.shipwith_ref) {
        this.forms.shipwith_ref = this.forms.shipwith_ref.split(" ").join("");
        this.forms.shipwith_ref = this.forms.shipwith_ref
          .split("&")
          .join("AND");
      }
      if (this.forms.remarks) {
        this.forms.remarks = this.forms.remarks.split("&").join("AND");
      }
      const baseURI =
        this.$settings.endPoint +
        "transport-order-header-new/update/" +
        this.forms.transport_order_header_id;
      this.$http
        .patch(baseURI, this.forms)
        .then((response) => {
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            this.success(response.data.datas.messages);
            this.loading();
            //window.history.back();
            var params = this.$onRandom(this.forms.transport_order_header_id);
            window.location.href =
              "/order/new-transport-order/detail/" +
              params +
              "?status=200&msg=Successfully";
          } else {
            this.loading();
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              if (error.response.data.errors == "duplicateOrder") {
                var text =
                  "<p style='text-align:left'>Shipment dengan Company :" +
                  this.company.name +
                  "<br>Jenis Truck : " +
                  this.template_order.description +
                  " <br> Remarks : <br> Pickup Location : " +
                  this.location.name +
                  " ,<br>Pickup Date : " +
                  moment(String(this.pickupDates.startDate)).format(
                    "YYYY-MM-DD"
                  ) +
                  " ,<br>Destination Location : " +
                  this.location_dest.name +
                  "  ,<br>Destination Date : " +
                  moment(String(this.destDates.startDate)).format(
                    "YYYY-MM-DD"
                  ) +
                  " <br></p><br><h3 style='color:red'>Sudah Ada di Database !!!</h3><br><p>Kalau Memang Ada Shipment yang identik mohon edit remarks untuk membedakan</p>";
                this.$swal({
                  width: "600px",
                  icon: "warning",
                  title: "Warning !!!!",
                  html: text,
                });
              }else if(error.response.data.errors == "company_deactived"){
                    var datas=error.response.data.datas;
                    var textResponse =
                      "<p style='text-align:center'>Customer ini di block dan tidak di perkenankan untuk di jalankan order / shipmentnya <br>Detail Block :</p> ";
                      datas.forEach(function(data) {
                          textResponse += "<br> Customer : " + datas[0].name +"<br> Blocking pada Tanggal : " + data.deactived_date +
                                          "<br>Alasan di block : " + data.deactived_reason + " <br><hr>";
                      });
                      textResponse +="<br><p>Mohon hubungi Department FA untuk membuka Block</p>";

                    this.$swal({
                      width: "600px",
                      icon: "warning",
                      title: "Warning !!!!",
                      html: textResponse,
                    })
              } else {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              }
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    loadBranch() {
      const baseURI = this.$settings.endPointCt + `branch/select`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchBranch = response.data.datas.data;
      });
    },
    asyncSearchBranch(ev) {
      const baseURI = this.$settings.endPointCt + `branch?branch_name=${ev}`;
      this.fetchBranch = [];
      return this.$http.get(baseURI).then((response) => {
        this.fetchBranch = response.data.datas.data;
      });
    },
    loadTypeTruck() {
      const baseURI = this.$settings.endPointCt + `type-truck`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTruckType = response.data.datas.data;
      });
    },
    asyncTruckType(ev) {
      const baseURI = this.$settings.endPointCt + `type-truck?type_truck=${ev}`;
      this.fetchTruckType = [];
      return this.$http.get(baseURI).then((response) => {
        this.fetchTruckType = response.data.datas.data;
      });
    },
    pickDateChange(ev) {
      var pickPlan = moment(ev.startDate).format("YYYY-MM-DD HH:mm");
      var currentDate = moment(new Date()).format("YYYY-MM-DD HH:mm");
      if (pickPlan < currentDate) {
        this.pickupDates.startDate = currentDate;
        this.error(this.$t("validatePickDate"));
      }
    },
    destDateChange(ev) {
      var destPlan = moment(ev.startDate).format("YYYY-MM-DD HH:mm");
      var currentDate = moment(new Date()).format("YYYY-MM-DD HH:mm");
      if (destPlan < currentDate) {
        this.destDates.startDate = currentDate;
        this.error(this.$t("validateDestDate"));
      }
    },
    pickRate() {
       let validate = true;
      if (this.company?.code == undefined && this.company.length == 0) {
        this.error("Company Empty");
        validate = false;
      }
      if (!this.pickupDates || this.pickupDates?.startDate == null) {
        console.log(!this.pickupDates);
        console.log(this.pickupDates?.startDate == null  );
        this.error("Pick Plan Empty");
        validate = false;
      }
      if(validate) {
        this.dataModalRoute = 
        {
          "company":this.company,
          "truck_type":this.truckType,
          "pick_plan": moment(this.pickupDates.startDate).format("YYYY-MM-DD"),
        };
        this.modalRouteTransport = true;
      }
    },

    loadLocationDetail(id,code) {
      console.log(code);
      const baseURI = this.$settings.endPoint + `location/detail/` + id+`?location_code=`+code;
      let result = this.$http.get(baseURI).then((response) => {
        return response.data.datas;
      });
      return result;
    },

    changeTypeTruck(ev) {
      if (ev) {
        this.forms.tonnage = ev.truck_capacity;
        this.forms.cubic = ev.truck_cubic;
      } else {
        this.forms.tonnage = 0;
        this.forms.cubic = 0;
      }
    },
    async closeMyModal(ev) {
      if (ev == null) {
        this.modalRouteTransport = false;
        this.route = "";
        this.billableTariff = "";
        this.forms.rm_billable_rate_detail_id = "";
      } else {
        let billableRate = ev;
        this.billableTariff = billableRate.billable_tariff_name;
        this.forms.rm_billable_rate_detail_id =
          billableRate.rm_billable_rate_detail_id;
        if (billableRate.destination_location && billableRate.origin_location) {
          this.fade(true);
          this.location = await this.loadLocationDetail(billableRate.origin_location_id,billableRate.origin_location);
          this.location_dest = await this.loadLocationDetail(
            billableRate.destination_location_id,billableRate.origin_location
          );
          this.route = this.location.name + " - " + this.location_dest.name;
          this.getLocationPickup(this.location);
          this.getLocationDest(this.location_dest);
          this.fade(false);
        } else if (
          billableRate.destination_region &&
          billableRate.origin_region
        ) {
          this.dataModalRegion = {
            origin_region: ev.origin_region,
            destination_region: ev.destination_region,
            company: this.company.name,
          };
          this.modalPickRegion = true;
        } else {
          this.error("Billable Rate Error");
        }
        this.modalRouteTransport = false;
      }
    },
    closeMyRegion(ev) {
      if (ev == null) {
        this.modalPickRegion = false;
        this.route = "";
        this.billableTariff = "";
        this.forms.rm_billable_rate_detail_id = "";
      } else {
        this.modalPickRegion = false;
        this.location_dest = ev.destination_location;
        this.location = ev.origin_location;
        this.getLocationPickup(this.location);
        this.getLocationDest(this.location_dest);
        this.route =
          ev.origin_location.name + " - " + ev.destination_location.name;
      }
    },

    changeShipmentRate(ev) {
      this.forms.shipment_rate = ev;
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.loadData(params[0]);
    this.loadCompany();
    this.loadCompanyNotLinc();
    this.fetchIt();
    this.loadBranch();
    // this.loadTypeTruck();
  },
};
</script>
<style scoped></style>
