import Carrier from "@/components/master/carrier/CarrierData.vue";
import CarrierAdd from "@/components/master/carrier/CarrierAdd.vue";
import CarrierDetail from "@/components/master/carrier/CarrierDetail.vue";
import CarrierEdit from "@/components/master/carrier/CarrierEdit.vue";
import CarrierUpload from "@/components/master/carrier/CarrierUpload.vue";

let routesCarrier= [
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "Carrier",
        component: Carrier
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "CarrierCTAdd",
        component: CarrierAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "CarrierCTEdit",
        component: CarrierEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "CarrierCTDetail",
        component: CarrierDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "CarrierCTUpload",
        component: CarrierUpload
      },
    
]

export default routesCarrier
  