<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="StopLocationAdd"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 25px"
                  >
                    Approve Request Rg
                  </h6>
                  <button
                    class="btn btn-default btn-sm float-end"
                    @click="resetForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="d-flex justify-content-between align-items-baseline mb-2"
                    ></div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >To <span style="color: red">*</span></label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input-tag
                          placeholder="Add Email To"
                          v-model="emailTo"
                          :add-tag-on-blur="true"
                          validate="email"
                          :read-only="true"
                        ></input-tag>

                        <div v-if="errors.email_to">
                          <div
                            v-for="error in errors.email_to"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >CC <span style="color: red">*</span></label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input-tag
                          placeholder="Add Email To"
                          v-model="emailCc"
                          :add-tag-on-blur="true"
                          validate="email"
                          :read-only="true"
                        ></input-tag>

                        <div v-if="errors.email_to">
                          <div
                            v-for="error in errors.email_to"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Request Status</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <div class="form-check form-check-inline">
                          <input
                            type="radio"
                            value="NEW"
                            v-model="forms.request_status"
                            class="form-check-input"
                            name="requestStatus1"
                            id="requestStatus1"
                            disabled
                          />
                          <label class="form-check-label" for="requestStatus1">
                            New
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="radio"
                            value="UPDATE"
                            v-model="forms.request_status"
                            class="form-check-input"
                            name="requestStatus2"
                            id="requestStatus2"
                            checked=""
                            disabled
                          />
                          <label class="form-check-label" for="requestStatus2">
                            Update
                          </label>
                        </div>
                        <div v-if="errors.request_status">
                          <div
                            v-for="error in errors.request_status"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Request Type</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <div class="form-check form-check-inline">
                          <input
                            type="radio"
                            value="EXIM"
                            v-model="forms.request_type"
                            class="form-check-input"
                            name="requestType1"
                            id="requestType1"
                            checked=""
                            disabled
                          />
                          <label class="form-check-label" for="requestType1">
                            EXIM
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="radio"
                            value="NON - EXIM"
                            v-model="forms.request_type"
                            class="form-check-input"
                            name="requestType2"
                            id="requestType2"
                            checked=""
                            disabled
                          />
                          <label class="form-check-label" for="requestType2">
                            Non - Exim
                          </label>
                        </div>
                        <div v-if="errors.request_type">
                          <div
                            v-for="error in errors.request_type"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Carrier</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <div class="form-check form-check-inline">
                          <input
                            type="radio"
                            value="0000-OWN"
                            v-model="forms.carrier"
                            class="form-check-input"
                            name="requestCarrier1"
                            id="requestCarrier1"
                            checked=""
                            disabled
                          />
                          <label class="form-check-label" for="requestStatus1">
                            0000-OWN
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="radio"
                            value="0001-VENDOR"
                            v-model="forms.carrier"
                            class="form-check-input"
                            name="requestCarrier2"
                            id="requestCarrier2"
                            checked=""
                            disabled
                          />
                          <label class="form-check-label" for="requestStatus2">
                            0001-VENDOR
                          </label>
                        </div>
                        <div v-if="errors.carrier">
                          <div
                            v-for="error in errors.carrier"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>



                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Haul</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <div class="form-check form-check-inline">
                          <input
                            type="radio"
                            value="SHORT HAUL"
                            v-model="forms.haul"
                            class="form-check-input"
                            name="shorthaul"
                            id="shorthaul"
                            checked=""
                            disabled
                          />
                          <label class="form-check-label" for="shorthaul">
                            SHORT HAUL
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="radio"
                            value="LONG HAUL"
                            v-model="forms.haul"
                            class="form-check-input"
                            name="longhaul"
                            id="longhaul"
                            checked=""
                            disabled
                          />
                          <label class="form-check-label" for="longhaul">
                            LONG HAUL
                          </label>
                        </div>
                        <div v-if="errors.haul">
                          <div
                            v-for="error in errors.haul"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("branch_id") }}</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <!-- <v-select
                          :options="fetchBranch"
                          v-model="branch"
                          label="branch_name"
                          return-object
                          @search="asyncLoadBranch"
                        ></v-select> -->
                        <input type="text" class="form-control" :value="forms.branch" disabled>

                        <div v-if="errors.branch_id">
                          <div
                            v-for="error in errors.branch_id"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Truck Type</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <!-- <v-select
                          :options="fetchTruckType"
                          v-model="truckType"
                          label="type_truck"
                          return-object
                          @search="asyncTruckType"
                        ></v-select> -->
                        <input type="text" class="form-control" :value="forms.unit" disabled>

                        <div v-if="errors.unit">
                          <div
                            v-for="error in errors.unit"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Customer</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <!-- <v-select
                          :options="fetchCustomer"
                          v-model="customer"
                          label="name"
                          return-object
                          @input="asyncTruckType"
                        ></v-select> -->
                        <input type="text" class="form-control" :value="forms.customer_name" disabled>


                        <div v-if="errors.customer_id">
                          <div
                            v-for="error in errors.customer_id"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-5">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Body Email</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <vue-editor v-model="forms.body_email" :disabled="true" />

                        <div v-if="errors.branch_id">
                          <div
                            v-for="error in errors.branch_id"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-5">
                      <div class="row table-responsive">
                        <table class="table table-bordered table-hover">
                          <thead>
                            <th>Type</th>
                            <th>Nama</th>
                            <th>Jalan</th>
                            <th style="min-width: 150px">
                              {{ $t("provinceTxt") }}
                            </th>
                            <th style="min-width: 150px">
                              {{ $t("cityTxt") }}
                            </th>
                            <th style="min-width: 150px">
                              {{ $t("districtTxt") }}
                            </th>
                            <th style="min-width: 150px">
                              {{ $t("companyVillage") }}
                            </th>
                            <th style="min-width: 150px">
                              {{ $t("postalCodeTxt") }}
                            </th>
                            <th>{{ $t("latitude_txt") }}</th>
                            <th>{{ $t("longitude_txt") }}</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Start</td>
                              <td>
                                <v-select
                                  :options="fetchPool"
                                  v-model="poolStart"
                                  label="pool_name"
                                  return-object
                                  @input="asyncSearchPool"
                                  :disabled="true"
                                ></v-select>
                              </td>
                              <td>
                                <span
                                  v-if="
                                    poolStart && poolStart?.pool_address != null
                                  "
                                  >{{ poolStart.pool_address }}</span
                                >
                              </td>
                              <td>
                                <span
                                  v-if="
                                    poolStart &&
                                    poolStart?.pool_province != null
                                  "
                                  >{{ poolStart.pool_province }}</span
                                >
                              </td>
                              <td>
                                <span
                                  v-if="
                                    poolStart && poolStart?.pool_city != null
                                  "
                                  >{{ poolStart.pool_city }}</span
                                >
                              </td>
                              <td>-</td>
                              <td>-</td>
                              <td>
                                <span
                                  v-if="
                                    poolStart &&
                                    poolStart?.pool_postalcode != null
                                  "
                                  >{{ poolStart.pool_postalcode }}</span
                                >
                              </td>
                              <td>
                                <span
                                  v-if="
                                    poolStart &&
                                    poolStart?.pool_latitude != null
                                  "
                                  >{{ poolStart.pool_latitude }}</span
                                >
                              </td>
                              <td>
                                <span
                                  v-if="
                                    poolStart &&
                                    poolStart?.pool_longitude != null
                                  "
                                  >{{ poolStart.pool_longitude }}</span
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>Loading</td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="loadingData.name"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="loadingData.address"
                                />
                              </td>
                              <td>
                                <v-select
                                  :options="featchProvinceCode"
                                  v-model="loadingProvince"
                                  label="province"
                                  @input="LoadingLoadCity"
                                  @search="asyncProvince"
                                  return-object
                                  :disabled="true"
                                ></v-select>
                              </td>
                              <td>
                                <v-select
                                  :options="featchCityCode"
                                  v-model="loadingCity"
                                  label="city"
                                  @input="LoadingLoadDistrict"
                                  return-object
                                  :disabled="true"
                                  
                                ></v-select>
                              </td>
                              <td>
                                <v-select
                                  :options="featchDistrictCode"
                                  v-model="loadingDistrict"
                                  @input="LoadingLoadSubDistrict"
                                  label="district"
                                  return-object
                                  :disabled="true"
                                ></v-select>
                              </td>
                              <td>
                                <v-select
                                  :options="featchSubDistrictCode"
                                  v-model="loadingSubDistrict"
                                  @input="LoadingLoadPostalCode"
                                  label="sub_district"
                                  return-object
                                  :disabled="true"
                                ></v-select>
                              </td>
                              <td>
                                <v-select
                                  :options="fetchPostalCode"
                                  v-model="loadingPostalCode"
                                  label="postal_code"
                                  return-object
                                  :disabled="true"
                                ></v-select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="loadingData.latitude"
                                  disabled
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="loadingData.longitude"
                                  disabled
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Unloading</td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="unloadingData.name"
                                  disabled
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="unloadingData.address"
                                  disabled
                                />
                              </td>
                              <td>
                                <v-select
                                  :options="featchProvinceCode"
                                  v-model="unloadingProvince"
                                  label="province"
                                  @input="UnloadingLoadCity"
                                  @search="asyncProvince"
                                  return-object
                                  :disabled="true"
                                ></v-select>
                              </td>
                              <td>
                                <v-select
                                  :options="featchCityCode"
                                  v-model="unloadingCity"
                                  label="city"
                                  @input="UnloadingLoadDistrict"
                                  return-object
                                  :disabled="true"
                                ></v-select>
                              </td>
                              <td>
                                <v-select
                                  :options="featchDistrictCode"
                                  v-model="unloadingDistrict"
                                  @input="UnloadingLoadSubDistrict"
                                  label="district"
                                  return-object
                                  :disabled="true"
                                ></v-select>
                              </td>
                              <td>
                                <v-select
                                  :options="featchSubDistrictCode"
                                  v-model="unloadingSubDistrict"
                                  @input="UnloadingLoadPostalCode"
                                  label="sub_district"
                                  return-object
                                  :disabled="true"
                                ></v-select>
                              </td>
                              <td>
                                <v-select
                                  :options="fetchPostalCode"
                                  v-model="unloadingPostalCode"
                                  label="postal_code"
                                  return-object
                                  :disabled="true"
                                ></v-select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="unloadingData.latitude"
                                  disabled
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="unloadingData.longitude"
                                  disabled
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>End</td>
                              <td>
                                <v-select
                                  :options="fetchPool"
                                  v-model="poolEnd"
                                  label="pool_name"
                                  return-object
                                  @input="asyncSearchPool"
                                  :disabled="true"
                                ></v-select>
                              </td>
                              <td>
                                <span
                                  v-if="
                                    poolEnd && poolEnd?.pool_address != null
                                  "
                                  >{{ poolEnd.pool_address }}</span
                                >
                              </td>
                              <td>
                                <span
                                  v-if="
                                    poolEnd && poolEnd?.pool_province != null
                                  "
                                  >{{ poolEnd.pool_province }}</span
                                >
                              </td>
                              <td>
                                <span
                                  v-if="poolEnd && poolEnd?.pool_city != null"
                                  >{{ poolEnd.pool_city }}</span
                                >
                              </td>
                              <td>-</td>
                              <td>-</td>
                              <td>
                                <span
                                  v-if="
                                    poolEnd && poolEnd?.pool_postalcode != null
                                  "
                                  >{{ poolEnd.pool_postalcode }}</span
                                >
                              </td>
                              <td>
                                <span
                                  v-if="
                                    poolEnd && poolEnd?.pool_latitude != null
                                  "
                                  >{{ poolEnd.pool_latitude }}</span
                                >
                              </td>
                              <td>
                                <span
                                  v-if="
                                    poolEnd && poolEnd?.pool_longitude != null
                                  "
                                  >{{ poolEnd.pool_longitude }}</span
                                >
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="row mb-3 mx-4">
                      <h5>{{this.$t('stopLocationCreated')}}</h5>
                      <div class="row" style="max-width:40%;">
                        <table class="table table-bordered">
                          <thead>
                            <th>Pick</th>
                            <th>Drop</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                 <multiselect
                                    @search-change="asyncStopLocation"
                                    v-model="pickLocation"
                                    :options="fetchStopLocation"
                                    label="location_code"
                                    track-by="location_code"
                                    placeholder="Please Select"
                                  >
                                    <span slot="noResult">-</span>
                                  </multiselect>
                                     <div v-if="errors.pick_result">
                                        <div
                                          v-for="error in errors.pick_result"
                                          :key="error"
                                          class="alert alert-primary"
                                          role="alert"
                                        >
                                          <i data-feather="alert-circle"></i>
                                          {{ error }}
                                        </div>
                                      </div>
                              </td>
                               <td>
                                 <multiselect
                                    @search-change="asyncStopLocation"
                                    v-model="dropLocation"
                                    :options="fetchStopLocation"
                                    label="location_code"
                                    track-by="location_code"
                                    placeholder="Please Select"
                                  >
                                    <span slot="noResult">-</span>
                                  </multiselect>
                                   <div v-if="errors.drop_result">
                                        <div
                                          v-for="error in errors.pick_result"
                                          :key="error"
                                          class="alert alert-primary"
                                          role="alert"
                                        >
                                          <i data-feather="alert-circle"></i>
                                          {{ error }}
                                        </div>
                                      </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  <div class="row mb-3 mx-4"  v-if="this.forms.status == 'SUBMITTED' || this.forms.status == 'DONE'">
                      <h5>Body Email - Reply</h5>

                      <div class="row">
                        <vue-editor v-model="forms.body_email_reply" />

                        <div v-if="errors.body_email_reply">
                          <div
                            v-for="error in errors.body_email_reply"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card-footer mt-5">
                      <button
                        class="btn btn-warning text-black fw-bolder btn-icon-text"
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button
                        class="btn btn-primary float-end btn-sm"
                        type="submit"
                      >
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import myMixinAddress from "@/middleware/mixinAddress.js";
import multiselect from "vue-multiselect";
import InputTag from "vue-input-tag";
import { VueEditor } from "vue2-editor";
export default {
  name: "BillablePartiesAdd",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    InputTag,
    VueEditor,    
    multiselect: multiselect,
  },
  mixins: [myMixinAddress],
  data() {
    return {
      fetchStopLocation:[],
      pickLocation:null,
      dropLocation:null,

      emailTo: [],
      emailCc: [],

      featchProvinceCode: [],
      featchCityCode: [],
      featchDistrictCode: [],
      featchSubDistrictCode: [],
      fetchPostalCode: [],

      loadingProvince: null,
      loadingCity: null,
      loadingDistrict: null,
      loadingSubDistrict: null,
      loadingPostalCode: null,

      unloadingProvince: null,
      unloadingCity: null,
      unloadingDistrict: null,
      unloadingSubDistrict: null,
      unloadingPostalCode: null,

      unloadingData: {},
      loadingData: {},

      branch: null,
      fetchBranch: [],

      truckType: null,
      fetchTruckType: [],

      customer: null,
      fetchCustomer: [],

      poolStart: null,
      poolEnd: null,
      unloading: {},
      fetchPool: [],

      detailUser:null,
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      permision_role: [],
      userData: "",
      companyCode: "",
      forms: {
      },
    };
  },
  watch: {},
  computed:{
    getEmailTo(){
      let res = "";
      if(this.emailTo.length > 0){
        this.emailTo.forEach((data)=>{
          res = res+data+",";
        })
      }
      return res;
    },
    getEmailCc(){
      let res = "";
      if(this.emailCc.length > 0){
        this.emailCc.forEach((data)=>{
          res = res+data+",";
        })
      }
      return res;
    },
  },
  methods: {
    loadLocation(){
      const baseURI =
        this.$settings.endPoint +
        "location?company="+this.forms.customer_name;
      return this.$http.get(baseURI).then((response) => {
        //console.log(response.data.datas);
        this.fetchStopLocation = response.data.datas.data;
      });
    },
    asyncStopLocation(ev){
       const baseURI =
        this.$settings.endPoint +
        "location?company="+this.forms.customer_name+"&location_code="+ev;
      return this.$http.get(baseURI).then((response) => {
        //console.log(response.data.datas);
        this.fetchStopLocation = response.data.datas.data;
      });
    },
    asyncProvince(ev) {
      const baseURI =
        this.$settings.endPoint +
        "postal-code/select-province/indonesia?province=" +
        ev;
      return this.$http.get(baseURI).then((response) => {
        //console.log(response.data.datas);
        this.featchProvinceCode = response.data.datas.data;
      });
    },
    LoadingLoadCity(ev) {
      if (ev) {
        this.loadCityCode(ev.province);
      } else {
        this.loadingCity = null;
        this.loadingDistrict = null;
        this.loadingSubDistrict = null;
        this.loadingPostalCode = null;
      }
    },
    LoadingLoadDistrict(ev) {
      if (ev) {
        this.loadDistrictCode(ev.city);
      } else {
        this.loadingDistrict = null;
        this.loadingSubDistrict = null;
        this.loadingPostalCode = null;
      }
    },
    LoadingLoadSubDistrict(ev) {
      if (ev) {
        this.loadSubDistrictCode(ev.district);
      } else {
        this.loadingSubDistrict = null;
        this.loadingPostalCode = null;
      }
    },
    LoadingLoadPostalCode(ev) {
      if (ev) {
        this.loadPostalCodeOne(ev.sub_district);
      } else {
        this.loadingPostalCode = null;
      }
    },
    UnloadingLoadCity(ev) {
      if (ev) {
        this.loadCityCode(ev.province);
      } else {
        this.loadingCity = null;
        this.loadingDistrict = null;
        this.loadingSubDistrict = null;
        this.loadingPostalCode = null;
      }
    },
    UnloadingLoadDistrict(ev) {
      if (ev) {
        this.loadDistrictCode(ev.city);
      } else {
        this.loadingDistrict = null;
        this.loadingSubDistrict = null;
        this.loadingPostalCode = null;
      }
    },
    UnloadingLoadSubDistrict(ev) {
      if (ev) {
        this.loadSubDistrictCode(ev.district);
      } else {
        this.loadingSubDistrict = null;
        this.loadingPostalCode = null;
      }
    },
    UnloadingLoadPostalCode(ev) {
      if (ev) {
        this.loadPostalCodeOne(ev.sub_district);
      } else {
        this.loadingPostalCode = null;
      }
    },
    loadPool() {
      const baseURI = this.$settings.endPointCt + `pool-branch`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchPool = response.data.datas.data;
      });
    },

    asyncSearchPool(ev) {
      console.log(ev);
    },
    loadCustomer() {
      const baseURI = this.$settings.endPoint + `company`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchCustomer = response.data.datas.data;
      });
    },
    asyncCustomer(ev) {
      const baseURI = this.$settings.endPoint + `company?name=${ev}`;
      this.fetchCustomer = [];
      return this.$http.get(baseURI).then((response) => {
        this.fetchCustomer = response.data.datas.data;
      });
    },
    loadTypeTruck() {
      const baseURI = this.$settings.endPointCt + `type-truck`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTruckType = response.data.datas.data;
      });
    },
    asyncTruckType(ev) {
      const baseURI = this.$settings.endPointCt + `type-truck?type_truck=${ev}`;
      this.fetchTruckType = [];
      return this.$http.get(baseURI).then((response) => {
        this.fetchTruckType = response.data.datas.data;
      });
    },
    asyncLoadBranch(ev) {
      const baseURI = this.$settings.endPointCt + `branch?branch_name=` + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchBranch = response.data.datas.data;
      });
    },
    loadBranch() {
      const baseURI = this.$settings.endPointCt + `branch`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchBranch = response.data.datas.data;
      });
    },
    resetForm() {
      this.forms = [];
    },

    backForm() {
      window.location.href = "/request-rg";
    },
    approveData(){
      const baseURI =
        this.$settings.endPoint + "request-rg/send-email-approve/"+this.forms.request_rg_id;
       this.fade(true);
          this.$http
            .post(baseURI)
            .then((response) => {
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                var params = this.$onRandom(
                  this.request_rg_id
                );
                window.location.href =
                  "/request-rg/detail/" +
                  params +
                  "?status=200&msg=Successfully";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {
                if (error.response) {
                  // console.log(error.response.data.errors.message);
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.errors = error.response.data.errors;
                    this.error(error.response.data.errors.message);
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push("/page-not-found");
                  }
                }
              }
            });
    },
    submitData() {
      this.fade(true);
      this.forms.pick_result = this.pickLocation?.location_code;
      this.forms.drop_result = this.dropLocation?.location_code;
      // this.forms.detail = detail;
      var baseURI = this.$settings.endPoint + `request-rg/update/`+this.forms.request_rg_id;
      this.$http
        .patch(baseURI, this.forms)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            var params = this.$onRandom(this.forms.request_rg_id);
            window.location.href =
              "/request-rg/detail/" + params + "?status=200&msg=Successfully";
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
        });
    },
    loadData(id){
      var baseURI = this.$settings.endPoint + `request-rg/detail/`+id;
      this.$http
        .get(baseURI)
        .then((response) => {
          this.loading();
          this.forms = response.data.datas;
            if (this.forms.email_to) {
            this.emailTo = this.forms.email_to.split(",");
          }
          if (this.forms.email_to) {
            this.emailCc = this.forms.email_cc.split(",");
          }
          if(this.forms.pick_result){
            this.pickLocation = {
              location_code : this.forms.pick_result
            }
          }
          if(this.forms.drop_result){
            this.dropLocation = {
              location_code : this.forms.drop_result
            }
          }
           // Detail
          if (this.forms.detail) {
            this.forms.detail.forEach((el) => {
              if (el.type == "start") {
                this.poolStart = {
                  pool_name: el?.name,
                  pool_address: el?.address,
                  pool_province: el?.province,
                  pool_city: el?.city,
                  pool_postalcode: el?.postcode,
                  pool_latitude: el?.latitude,
                  pool_longitude: el?.longitude,
                };
              }

              if (el.type == "end") {
                this.poolEnd = {
                  pool_name: el?.name,
                  pool_address: el?.address,
                  pool_province: el?.province,
                  pool_city: el?.city,
                  pool_postalcode: el?.postcode,
                  pool_latitude: el?.latitude,
                  pool_longitude: el?.longitude,
                };
              }

              if (el.type == "loading") {
                this.loadingData = {
                  name: el.name,
                  address: el.address,
                  latitude: el.latitude,
                  longitude: el.longitude,
                };
                this.loadingProvince = {
                  province: el.province,
                };
                this.loadingCity = {
                  city: el.city,
                };
                this.loadingDistrict = {
                  district: el.district,
                };
                this.loadingSubDistrict = {
                  sub_district: el.sub_district,
                };
                this.loadingPostalCode = {
                  postal_code: el.postcode,
                };
              }
              if (el.type == "unloading") {
                this.unloadingData = {
                  name: el.name,
                  address: el.address,
                  latitude: el.latitude,
                  longitude: el.longitude,
                };
                this.unloadingProvince = {
                  province: el.province,
                };
                this.unloadingCity = {
                  city: el.city,
                };
                this.unloadingDistrict = {
                  district: el.district,
                };
                this.unloadingSubDistrict = {
                  sub_district: el.sub_district,
                };
                this.unloadingPostalCode = {
                  postal_code: el.postcode,
                };
              }
            });
            this.loadLocation();
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
        });
    },
    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          setTimeout(() => {
            // if(this.company_id==null || this.company_id=='' || this.company_id==undefined){
            //   window.location.href = '/billable-method';
            // }else{
            //   var params = this.$onRandom(this.company_id);
            //   window.location.href = '/billable-method?company_id='+params;
            // }
          }, 3000);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (
          permision.permission.controller ==
          "RequestRgController"
        ) {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
      cek_message(status,msg){
        if(status=='200'){
          this.success(msg);
        }
      },
  
  },
  events: {},
  created: function () {},
  mounted() {
    this.fetchIt();
     var params = this.$onBehind(this.$route.params.id);
      this.cek_message(this.$route.query.status,this.$route.query.msg);
      this.loadData(params[0]);
  },
};
</script>
<style scoped></style>
