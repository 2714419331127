<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>   
    <div class="loadertext" v-if="this.isLoading"> {{$t('loadingTxt')}}...</div>   
    <div class="main-wrapper" :class="{'blur-content': this.isLoading}">
      <!--begin::sidebar-->
      <sidebar-component classMenu="GTUserMappingCreate"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            ">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left: 25px">
                    {{ $t("txtUserMappingCreate") }}
                  </h6>
                  <button class="btn btn-default btn-sm float-end" @click="resetForm" type="button">
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form class="forms-sample" @submit.prevent="submitData" method="POST">
                    <div class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "></div>

                    <div class="row mb-3">
 

                      <div class="col-lg-12">

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px"> {{$t('user_choose_txt')}}</label>
                          </div>
                          <div class="col-lg-8">
                             

                            <v-select
                            :options="userDatas"
                            v-model="userData"
                            label="username"
                            return-object
                            @input="asyncLoadUser"
                            @search="asyncLoadUser"
                            ></v-select>

                            <div v-if="errors.user_id">
                              <div v-for="error in errors.user_id" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
   

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px"> {{$t('pool_choose_txt')}}</label>
                          </div>
                          <div class="col-lg-8">
                             

                            <v-select
                            :options="poolDatas"
                            v-model="poolData"
                            label="pool_name"
                            return-object
                            @input="asyncLoadPool"
                            @search="asyncLoadPool"
                            ></v-select>

                            <div v-if="errors.master_pool_id">
                              <div v-for="error in errors.master_pool_id" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

 
                      </div>

                    </div>

                    <div class="card-footer">
                      <button class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        " @click="backForm" type="button">
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button class="btn btn-primary float-end btn-sm" type="submit">
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import myMixinAddress from '@/middleware/mixinAddress.js';
 


export default {
    name: "GTUserMappingCreate",
    props: {},
    mixins: [myMixinAddress],
    components: {
        "sidebar-component": sidebarComponent,
        "navbar-component": navbarComponent,
        "footer-component": footerComponent,
      
    },
    data() {
        return {
            file: '',
            isLoading: false,
            maxToasts: 100,
            position: "up right",
            closeBtn: true,
            errors: [],
            permision_role: [],
            forms: {},
            userDatas:[],
            poolDatas:[],
            userData:{},
            poolData:{},
        };
    },
    watch: {},
    methods: {
        
        asyncLoadUser(ev) {
            const baseURI =
                this.$settings.endPoint +
                `user?user_type=GT&username=`+ev;

            return this.$http.get(baseURI).then((response) => {
                this.userDatas = response.data.datas.data;
            });
        },
        
        asyncLoadPool(ev) {
            const baseURI =
                this.$settings.endPointCt +
                `pool-branch?pool_name=`+ev;

            return this.$http.get(baseURI).then((response) => {
                this.poolDatas = response.data.datas.data;
            });
        },

        resetForm() {
            this.userData           = {}
            this.poolData           = {}
            this.userDatas          = [];
            this.poolDatas          = [];
            this.forms.user_id      = "";
            this.forms.master_pool_id      = "";
            this.forms.branch_id    = "";
            this.forms.pool_name    = "";
            this.forms.pool_province= "";
            this.forms.pool_address = "";
            this.forms.pool_city    = "";
            this.forms.pph_dipotong = "";
            this.forms.pool_postalcode  = "";
            this.forms.pool_latitude = "";
            this.forms.pool_longitude= "";
            this.forms.create_by    = "";
            this.forms.update_by    = "";
            this.forms.name         = "";
            this.forms.email        = "";
            this.forms.username     = "";
            this.forms.branch_name  = "";
            this.forms.branch_region= "";
            this.forms.branch_city  = "";
            this.forms.branch_province  = "";
            this.forms.branch_poscode   = "";
            this.forms.branch_phone     = "";
            this.forms.branch_phone2    = "";
            this.forms.branch_latitude  = "";
            this.forms.branch_longitude = "";
            this.forms.branch_sap_code  = "";
        
        },

        
        backForm() {
        window.location.href = "/gt/user-mapping";
        },

        submitData() {
            this.fade(true);

            let formData = new FormData();
        
            formData.append("user_id", this.userData.user_id);
            formData.append("name", this.userData.name);
            formData.append("email", this.userData.email);
            formData.append("username", this.userData.username);
            formData.append("branch_id", this.poolData.branch_id);
            formData.append("master_pool_id", this.poolData.master_pool_id);
            formData.append("pool_name", this.poolData.pool_name);
            formData.append("pool_province", this.poolData.pool_province);
            formData.append("pool_address", this.poolData.pool_address);
            formData.append("pool_city", this.poolData.pool_city);
            formData.append("pool_postalcode", this.poolData.pool_postalcode);
            formData.append("pool_latitude", this.poolData.pool_latitude);
            formData.append("pool_longitude", this.poolData.pool_longitude);
            formData.append("branch_name", this.poolData.branch_detail.branch_name);
            formData.append("branch_address", this.poolData.branch_detail.branch_address);
            formData.append("branch_region", this.poolData.branch_detail.branch_region);
            formData.append("branch_city", this.poolData.branch_detail.branch_city);
            formData.append("branch_province", this.poolData.branch_detail.branch_province);
            formData.append("branch_poscode", this.poolData.branch_detail.branch_poscode);
            formData.append("branch_phone", this.poolData.branch_detail.branch_phone);
            formData.append("branch_phone2", this.poolData.branch_detail.branch_phone2);
            formData.append("branch_longitude", this.poolData.branch_detail.branch_longitude);
            formData.append("branch_latitude", this.poolData.branch_detail.branch_latitude);
            formData.append("branch_sap_code", this.poolData.branch_detail.branch_sap_code);

            this.isLoading = true;
            const baseURI = this.$settings.endPointGT + "user-gate-system/create";
            this.$http.post(baseURI, formData,{ timeout: 10000 }).then((response) => {
                this.isLoading = false;
                if (response.data.status === 200) {
                this.errors = [];
                var params = this.$onRandom(response.data.datas.user_gate_system_id);
                window.location.href = "/gt/user-mapping/detail/" + params + "?status=200&msg=Successfully";
                } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
                }
            }).catch(error => {
                this.isLoading = false;
                if(error.code == "ECONNABORTED"){
                    this.error(this.$t("check_internet_connection_txt"));
                }else{

                    if (error.response) {
                    if(error.response.status === 422) {
                            this.errors = error.response.data.errors;
                            this.resultError(error.response.data.errors);
                    }else if (error.response.status === 500) {
                        this.$router.push('/server-error');
                    }else if (error.response.status === 401) {
                        this.$router.push('/authorized-error');
                    }else{
                        this.$router.push('/page-not-found');
                    }
                    }

                }

            });
    
        },

        fade(sType) {
        this.isLoading = sType;
        },

        loading() {
        this.fade(true);
        setTimeout(() => {
            this.fade(false);
        }, 1000); // hide the message after 3 seconds
        },

        fetchIt() {
        const userDatas = this.$getUserInfo();
        this.detailUser = userDatas.sub;
        // this.permision_role = userDatas.sub.permision_role;
        let rolePermision = [];
        userDatas.sub.permision_role.forEach(function (permision) {
            if (permision.permission.controller == "UserGateSystemController") {
            rolePermision = permision;
            }
        });

        if (!rolePermision.method_create) {
            this.$router.push("/authorized-error");
        }

        this.permision_role = rolePermision;
        },

        resultError(data) {
        var count = Object.keys(data).length;
        for (var x = 0; x < count; x++) {
            var nameOb = Object.keys(data)[x];
            var objectData = data[nameOb];
            for (var y = 0; y < objectData.length; y++) {
            this.error(objectData[y]);
            }
        }
        },

        success(kata) {
        const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
        });
        Toast.fire({
            icon: "success",
            title: kata,
        });
        },

        error(kata) {
        const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
        });
        Toast.fire({
            icon: "error",
            title: kata,
        });
        },
    },
    events: {},
    created: function () { },
    mounted() {
        this.fetchIt();
        this.loading();
        this.asyncLoadUser('');
        this.asyncLoadPool('');
    },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}
.blur-content{
  filter: blur(5px); 
}
</style>