<template>
  <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Request Reschedule Order : {{ data.order_no}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
            aria-label="btn-close"></button>
        </div>
        <div class="modal-body">
          <!--begin::loading-data-->
          
          <form class="forms-sample" @submit.prevent="submitData" method="POST">
            <div class="d-flex justify-content-between align-items-baseline mb-2"></div>

            <div class="row mb-3">
              <div class="col-lg-4">
                <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Pickup Plan</label>
              </div>
              <div class="col-lg-8">
                <date-range-picker style="width:100%" :date-format="dateFormat" 
                  control-container-class="form-control" :locale-data="locale" v-model="pickupDates" :opens="opens"
                  @update="updateValues" :singleDatePicker="true" :timePicker24Hour="true" :timePicker="true" :showWeekNumbers="false">

                </date-range-picker>
                <div v-if="errors.pick_plan">
                  <div v-for="error in errors.pick_plan" :key="error" class="alert alert-primary" role="alert">
                    <i data-feather="alert-circle"></i>
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-lg-4">
                <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Destination Plan </label>
              </div>
              <div class="col-lg-8">
                <date-range-picker style="width:100%" :locale-data="locale" :date-format="dateFormatDest" 
                  v-model="destDates" :opens="opens" :singleDatePicker="true" :timePicker24Hour="true"
                  :timePicker="true">

                </date-range-picker>

                <div v-if="errors.dest_plan">
                  <div v-for="error in errors.dest_plan" :key="error" class="alert alert-primary" role="alert">
                    <i data-feather="alert-circle"></i>
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              
              <!--end::loading-data-->
              <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
                {{ $t("close_txt") }}
              </button>
              <button class="btn btn-primary" type="submit">
                <span v-if="isLoading" class="spinner-border spinner-border-sm ms-auto"
                role="status"
                aria-hidden="true"></span>Send Request 
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";

export default {
  components: {
    DateRangePicker
  },
  name: "ModalTurnOffTripAlert",
  emits: ["closeModal"],
  props: {
    showModal: Boolean,
    data: null,
  },
  watch: {
    showModal: {
      handler(newVal) {
        this.forms = {
          transport_order_header_ids: [this.data.transport_order_header_id],
        };

        this.pickupDates.startDate = this.data.pick_plan;
        this.destDates.startDate = this.data.dest_plan;

        this.locale = {
          format: "dd-mm-yyyy HH:MM:ss",
        };

        this.active = newVal;
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    const startDate = new Date();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 6);

    return {
      disabledDates: {},
      locale:{
        format:"dd mmm yyyy H:MM:ss",
       
      },
      opens:"center",
      isLoading: false,
      active: this.showModal,
      forms: [],
      errors: [],
      type_error: [],
      pickupDates: {
        startDate,
        endDate,
      },
      destDates: {
        startDate,
        endDate,
      },
    };
  },
  methods: {
    updateValues(){
      this.destDates.startDate = this.pickupDates.startDate;
    },
    dateFormat (classes, date) {
      if (!classes.disabled) {
        classes.disabled = date.getTime() < this.data.order_date;
      }
      return classes
    },

    dateFormatDest (classes, date) {
      if (!classes.disabled) {
        // classes.disabled = date.getTime() < this.pickupDates.startDate;
        classes.disabled = date.getTime() < new Date(this.pickupDates.startDate.getTime() - 86400000);
       }
      return classes
    },

    resetForm() {
      this.forms = [];
    },

    submitData() {
      this.fade(true);
      this.forms.pick_plan=moment(String(this.pickupDates.startDate)).format('YYYY-MM-DD HH:mm:ss');
      this.forms.dest_plan=moment(String(this.destDates.startDate)).format('YYYY-MM-DD HH:mm:ss');
      
      let baseURI ='';
      if(this.data.source=='CT'){
        baseURI =
        this.$settings.endPoint + "control-tower/transport-order-header/reschedule" ;
      }else if(this.data.source=='CHAIN'){
        baseURI =
        this.$settings.endPoint + "transport-order-header/reschedule" ;
      }else if(this.data.source=='MULTI'){
        baseURI =
        this.$settings.endPoint + "transport-order-header-multiple/reschedule" ;
      }
      
      // this.$settings.endPoint + "transport-order-header-multiple/reschedule" ;

      this.$http
        .post(baseURI, this.forms)
        .then((response) => {
          this.fade(false);
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];

            this.loadSuccess(response.data.datas.messages);
            this.$emit("closeModal");
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.fade(false);
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var html = "";
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          html += objectData[y] + "<br>";
        }
      }

      this.loadError(html);
    },

    loadError(pesan) {
      this.$swal({
        backdrop: true,
        title: "Warning",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },

    loadSuccess(pesan) {
      this.$swal({
        backdrop: true,
        title: "Success",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },
  },
  mounted() {
    //tail(this.data);
  },
};
</script>

<style scoped>
.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left:0px !important;
}
</style>