<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading">
      {{ $t("loadingTxt") }}...
    </div>
    <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
      <!--begin::sidebar-->
      <sidebar-component classMenu="DashboardGT"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->
        <!--begin::content-->
        <div class="page-content">
          <div class="
                d-flex
                justify-content-between
                align-items-center
                flex-wrap
                grid-margin
              ">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-body">
                  <h2>
                    <span class="badge bg-primary">{{ $t("branchTxt") }} : {{ $t("all") }}</span>
                  </h2>
                  <!-- <div class="box">
                        <h6 class="card-title" style="color:white;padding-top: 15px;">{{$t('branchTxt')}} : {{$t('all')}}</h6>
                    </div> -->

                  <div class="row mb-3">
                    <div class="col-lg-4">
                      <figure class="mb-12">
                        <img class="img-fluid rounded" src="/assets/icon/truck.png" alt="" />
                      </figure>

                      <div class="card" @click="goTo('/gt/all-branch-transactions/PARKING/all')"
                        style="cursor: pointer; -webkit-box-shadow: 0 0 10px 0 rgb(26 28 32 / 20%)">
                        <div class="card-body">
                          <div class="row">
                            <div class="
                                  col-4 col-md-12 col-xl-4
                                  align-items-center
                                ">
                              <img src="/assets/icon/parking_1.png" height="70px" width="70px" />
                            </div>

                            <div class="col-8 col-md-12 col-xl-8">
                              <h3 class="mb-2 text-primary">{{ $t("parking") }}</h3>
                              <div class="d-flex align-items-baseline">
                                <h5>{{ this.totalParkir }}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />

                      <div class="card" @click="goTo('/gt/all-branch-transactions/KIR/all')"
                        style="cursor: pointer; -webkit-box-shadow: 0 0 10px 0 rgb(26 28 32 / 20%)">
                        <div class="card-body">
                          <div class="row">
                            <div class="
                                  col-4 col-md-12 col-xl-4
                                  align-items-center
                                ">
                              <img src="/assets/icon/kir_1.png" height="70px" width="70px" />
                            </div>

                            <div class="col-8 col-md-12 col-xl-8">
                              <h3 class="mb-2 text-primary">{{ $t("kir") }}</h3>
                              <div class="d-flex align-items-baseline">
                                <h5>{{ this.totalKIR }}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div class="card" @click="
                        goTo(
                          '/gt/all-branch-transactions/Corrective Maintenance/all'
                        )
                      " style="cursor: pointer; -webkit-box-shadow: 0 0 10px 0 rgb(26 28 32 / 20%)">
                        <div class="card-body">
                          <div class="row">
                            <div class="
                                  col-4 col-md-12 col-xl-4
                                  align-items-center
                                ">
                              <img src="/assets/icon/corrective_maintenance.png" height="70px" width="70px" />
                            </div>
                            <div class="col-8 col-md-12 col-xl-8">
                              <h3 class="mb-2 text-primary">
                                {{ $t("corrective_maintenance") }}
                              </h3>
                              <div class="d-flex align-items-baseline">
                                <h5>{{ this.totalCorrectiveMaintenance }}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div class="card" @click="
                        goTo(
                          '/gt/all-branch-transactions/Preventive Maintenance/all'
                        )
                      " style="cursor: pointer ; -webkit-box-shadow: 0 0 10px 0 rgb(26 28 32 / 20%)">
                        <div class="card-body">
                          <div class="row">
                            <div class="
                                  col-4 col-md-12 col-xl-4
                                  align-items-center
                                ">
                              <img src="/assets/icon/preventive maintenance_1.png" height="70px" width="70px" />
                            </div>
                            <div class="col-8 col-md-12 col-xl-8">
                              <h3 class="mb-2 text-primary">
                                {{ $t("preventive_maintenance") }}
                              </h3>
                              <div class="d-flex align-items-baseline">
                                <h5>{{ this.totalPreventiveMaintenance }}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-8">
                      <!-- <figure class="mb-12" style="transform: rotate(90deg);"> -->
                      <div class="mb-12">
                        <LineChartGenerator :chart-options="chartOptions" :chart-data="chartDataKeSatu"
                          :chart-id="chartId" :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses"
                          :styles="myStyles" :width="width" :height="height" />

                        <div v-if="this.availableChartDataKeSatu" style="
                              text-align: center;
                              left: 0;
                              top: 100px;
                              z-index: 20;
                              color: black;
                            ">
                          <b>{{ $t("no_data_available_txt") }}</b>
                        </div>
                      </div>

                      <div class="
                            d-flex
                            align-items-center
                            justify-content-between
                            p-3
                            border-bottom
                            tx-16
                          ">
                        <div class="d-flex align-items-center">
                          <span style="color: red">{{
                            $t("truck_record_by_permit_status")
                          }}</span>
                        </div>
                        <div>
                          <span style="color: green"><a href="/gt/all-branch-transactions/all/all">
                              {{ $t("more_record") }} >>>
                            </a></span>
                        </div>
                      </div>

                      <vue-good-table title="list-user" mode="remote" @on-page-change="onPageChange"
                        :select-options="{ enabled: false }" @on-sort-change="onSortChange"
                        @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
                        :line-numbers="true" :isLoading.sync="isLoading" :pagination-options="{
                          enabled: false,
                        }" :rows="rows" :columns="columns">
                        <template slot="table-row" slot-scope="props">
                          <span v-if="props.column.field == 'actions'">
                            <a href="#" v-if="permision_role.method_read" title="Detail" @click.prevent="
                              detailData(props.index, props.row)
                            ">
                              <i class="mdi mdi-eye" style="font-size: 16px"></i>
                            </a>
                          </span>
                          <span v-if="props.column.field == 'actions_date'">
                            {{ convertDate(props.row) }}
                          </span>

                          <span v-else-if="props.column.field == 'actions_time'">
                            {{ convertTime(props.row) }}
                          </span>
                          <span v-else-if="props.column.field == 'driver_name'">
                            {{ props.row.driver_name == '' ? props.row.nama_driver2 : props.row.driver_name }}
                          </span>
                          <span v-else-if="props.column.field == 'permit_status'">
                            <span v-if="props.row.permit_status == 'Rejected'"
                              style="background-color: red; color: white">{{ props.row.permit_status }}</span>
                            <span v-else>{{ props.row.permit_status }}</span>
                          </span>
                          <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                          </span>
                        </template>
                      </vue-good-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import { Bar as LineChartGenerator } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  LineElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  LineElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
);

export default {
  name: "DashboardGT",
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 300,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => { },
    },
    plugins: {
      type: Object,
      default: () => { },
    },
  },
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    LineChartGenerator,
  },
  data() {
    return {
      availableChartDataKeSatu: false,
      chartDataKeSatu: {
        labels: ["-"],
        datasets: [
          {
            label: "",
            borderColor: "#EF8F15",
            pointBackgroundColor: "white",
            borderWidth: 1,
            radius: 0,
            fill: true,
            pointBorderColor: "white",
            backgroundColor: "#EF8F15",
            tension: 0.25,
            data: [0],
          },
        ],
      },
      isLoading: false,
      chartOptions: {
        indexAxis: "y",

        scales: {
          y: {
            ticks: {
              beginAtZero: true,
              min: 0,
              stepSize: 10,
              // maxRotation: 90,
              // minRotation: 90
            },
            gridLines: {
              display: true,
            },
            position: "left",
          },
          x: {
            gridLines: {
              display: false,
            },
            onClick: (e) => {
              console.log(e);
            },
            ticks: {
              precision: 0,
              min: 0,
              beginAtZero: true,
              // callback: function(value) {
              //     if (Math.floor(value) === value) {
              //         return value;
              //     }
              // },
              stepSize: 5,
              // maxRotation: 90,
              // minRotation: 90
            },
          },
        },

        plugins: {
          legend: {
            display: false,
            position: "bottom",
          },
        },
        onClick: (c, i) => {
          var labelBar = c.chart.config._config.data.labels[i[0].index];
          window.location.href = "/gt/all-branch-transactions/all/" + labelBar;
        },
        responsive: true,
        maintainAspectRatio: true,
      },
      totalKIR: 0,
      totalParkir: 0,
      totalCorrectiveMaintenance: 0,
      totalPreventiveMaintenance: 0,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "gate_transaction_id",
          type: "DESC",
        },
        page: 1,
        per_page: 5,
      },
      columns: [
        {
          label: this.$t("action_txt"),
          field: "actions",
          sortable: false,
        },
        {
          label: this.$t("vehicle_no"),
          field: "vehicle_no",
          sortable: false,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("vehicle_no"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: this.$t("driver_name"),
          field: "driver_name",
          sortable: false,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("driver_name"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: this.$t("in_out_txt"),
          field: "status",
          sortable: false,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("in_out_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: this.$t("truck_type"),
          field: "vehicle_type",
          sortable: false,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("truck_type"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: this.$t("date"),
          field: "actions_date",
          sortable: false,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("date"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: this.$t("time"),
          field: "actions_time",
          sortable: false,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("time"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: this.$t("permit_status"),
          field: "permit_status",
          sortable: false,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By " + this.$t("permit_status"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
      ],
      rows: [],
    };
  },
  computed: {
    myStyles() {
      return {
        height: "10%",
        position: "relative",
      };
    },
  },
  watch: {},
  methods: {
    loadItems() {
      this.baseURI = this.$settings.endPointGT + "gate-transaction/index";

      return this.$http
        .get(
          this.baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}`,
          { timeout: 10000 }
        )
        .then((response) => {
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
        })
        .catch((error) => {
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },

    detailData(index, row) {
      var params = this.$onRandom(row.gate_transaction_id);
      window.location.href = "/gt/transaction/detail/" + params;
    },

    goTo(link) {
      window.location.href = link;
    },

    convertTime(data) {
      return this.$moment(data.transaction_date).format("HH:mm");
    },

    convertDate(data) {
      return this.$moment(data.transaction_date).format("DD/MM/YYYY");
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "GateTransactionController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },
    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    handle(point, event) {
      console.log(point);
      console.log(event);
    },

    loadDataGateSystem(id) {
      this.isLoading = true;
      var baseURI =
        this.$settings.endPointGT +
        "gate-transaction/get-chart-dashboard/" +
        id;
      this.isLoading = true;
      return this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          var datas = response.data.datas;

          if (datas.length > 0) {
            var labels = [];
            var datasets = [];

            datas.forEach((data) => {
              if (data.column_name == "totalKIR") {
                this.totalKIR = data.total;
              }
              if (data.column_name == "totalParkir") {
                this.totalParkir = data.total;
              }
              if (data.column_name == "totalCorrectiveMaintenance") {
                this.totalCorrectiveMaintenance = data.total;
              }
              if (data.column_name == "totalPreventiveMaintenance") {
                this.totalPreventiveMaintenance = data.total;
              }
              if (data.type == "chart") {
                labels.push(data.column_name);
                datasets.push(data.total);
              }
            });

            if (labels.length > 0) {
              this.chartDataKeSatu = {
                labels: labels,
                datasets: [
                  {
                    label: "",
                    borderColor: "#EF8F15",
                    pointBackgroundColor: "white",
                    borderWidth: 1,
                    radius: 0,
                    fill: true,
                    pointBorderColor: "white",
                    backgroundColor: "#EF8F15",
                    tension: 0.25,
                    data: datasets,
                  },
                ],
              };
            } else {
              this.availableChartDataKeSatu = true;

              this.chartDataKeSatu = {
                labels: [],
                datasets: [],
              };
            }
          } else {
            this.availableChartDataKeSatu = true;

            this.chartDataKeSatu = {
              labels: [],
              datasets: [],
            };
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },
  },
  events: {},
  created: function () { },
  mounted() {
    this.fetchIt();
    this.loadItems();
    this.loadDataGateSystem(0);
  },
};
</script>
<style >
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content {
  filter: blur(5px);
}

.box {
  width: 200px;
  height: 10%;
  border-style: solid;
  border-width: 0.5px;
  background-color: red;
  text-align: center;
  vertical-align: middle;
}
</style>