<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="CarrierIndex"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            ">
            <div>
              <h4 class="mb-12 mb-md-0"></h4>
            </div>

            <div class="col-lg-12 col-xl-12 stretch-card">
              <div class="card">
                <div class="card-header">
                  <h4 style="margin-right: 5px" class="float-start">Carrier</h4>
                  <button v-if="permision_role.method_create" class="btn btn-primary float-end btn-xs"
                    style="margin-right: 5px" @click.prevent="createData()">
                    <i class="link-icon" data-feather="plus"></i>
                    {{ $t("createNew") }}
                  </button>

                  <!-- <button
                    class="btn btn-warning float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="uploadData()"
                  >
                     <i class="link-icon" data-feather="upload"></i>
                    {{ $t("uploadData") }} .xlsx
                  </button> -->


                  <button v-if="permision_role.method_read" class="btn btn-success float-end btn-xs"
                    style="margin-right: 5px" @click.prevent="downloadData()">
                    <i class="link-icon" data-feather="download"></i>
                    {{ $t("downloadData") }} .xlsx
                  </button>
                </div>

                <div class="card-body">
                  <vue-good-table title="list-user" mode="remote" @on-page-change="onPageChange"
                    @on-sort-change="onSortChange" @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :line-numbers="true"
                    :isLoading.sync="isLoading" :pagination-options="{
                    enabled: true,
                  }" :rows="rows" :columns="columns">
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'qty_unit'">
                        {{ props.row.truck.length }}
                      </span>

                      <span v-if="props.column.field == 'qty_driver'">
                        {{ props.row.driver.length }}
                      </span>

                      <span v-if="props.column.field == 'actions'">
                        <a href="#" v-if="permision_role.method_read" title="Detail" @click.prevent="
                    detailData(props.index, props.row)
                    ">
                          <i class="mdi mdi-eye" style="font-size:16px"></i>

                        </a>
                        <a href="#" v-if="permision_role.method_update" title="Edit"
                          @click.prevent="editData(props.index, props.row)">
                          <i class="mdi mdi-square-edit-outline" style="font-size:16px"></i>

                        </a>

                        <a href="#" v-if="permision_role.method_delete" title="Delete" @click.prevent="
                    deleteData(props.index, props.row)
                    ">
                          <i class="mdi mdi-trash-can-outline" style="font-size:16px"></i>
                        </a>

                        <a href="#" v-if="permision_role.method_custom1 && props.row.status == 'active'"
                          title="Deactived" @click.prevent="showRejected(props.row.master_carrier_id)">

                          <i class="mdi mdi-close-circle" style="font-size: 16px; color:#ab0635"></i>
                        </a>

                        <a href="#" v-if="permision_role.method_custom1 && props.row.status != 'active'" title="Actived"
                          @click.prevent="showApprove(props.row.master_carrier_id)">
                          <i class="mdi mdi-checkbox-marked-circle" style="font-size: 16px; color:blue"></i>
                        </a>


                      </span>
                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->



        <modal-approve-company :showModal="showModal" :data="modalData"
          @closeModal="closeMyModal"></modal-approve-company>


        <modal-reject-companny :showModal="showModalReject" :data="modalData"
          @closeModal="closeMyModal"></modal-reject-companny>

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import modalApproveCompany from "@/components/modal/modalApproveCarrier";
import modalRejectCompany from "@/components/modal/modalRejectCarrier";


export default {
  name: "CarrierIndex",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "modal-approve-company": modalApproveCompany,
    "modal-reject-companny": modalRejectCompany
  },
  data() {
    return {
      showModal: false,
      showModalReject: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      modalData: [],
      permision_role: [],
      langs: ["id", "en"],
      detailUser: [],
      detailDivisi: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [
        {
          label: 'Carrier Name',
          field: "carrier_name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Carrier Name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: 'Carrier Code',
          field: "carrier_code",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: 'Carrier Email',
          field: "carrier_email",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: 'Location',
          field: "location",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: 'Qty Unit',
          field: "qty_unit",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: 'Qty Driver',
          field: "qty_driver",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: 'Status',
          field: "status",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },







        {
          label: "Action",
          field: "actions",
          sortable: false,
        },
      ],
      rows: [],
    };
  },
  watch: {},
  methods: {

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == 'MasterCarrierController') {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;

    },

    downloadData() {
      const baseURI = this.$settings.endPointCt + "/master-carrier";
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = "download_" + CurrentDate + ".xlsx";

      //var columnFilters = this.serverParams.columnFilters;


      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&carrier_name=${this.serverParams.columnFilters.carrier_name}&carrier_code=${this.serverParams.columnFilters.carrier_code}&carrier_email=${this.serverParams.columnFilters.carrier_email}&location=${this.serverParams.columnFilters.location}&download=download`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {

          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },

    createData() {
      window.location.href = "/master-carrier/add";
    },

    uploadData() {
      window.location.href = "/master-carrier/upload";
    },

    editData(index, row) {
      var params = this.$onRandom(row.master_carrier_id);
      window.location.href = "/master-carrier/edit/" + params;
    },


    closeMyModal() {
      this.showModal = false;
      this.showModalReject = false
      this.loadItems();
    },


    showApprove(id) {
      this.modalData = [];
      this.showModal = true;
      this.modalData.push(id);
    },

    showRejected(id) {
      this.modalData = [];
      this.showModalReject = true;
      this.modalData.push(id);
    },


    detailData(index, row) {
      var params = this.$onRandom(row.master_carrier_id);
      window.location.href = "/master-carrier/detail/" + params;
    },

    deleteData(index, row, status) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI =
            this.$settings.endPointCt + "master-carrier/delete/" + row.master_carrier_id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    // load items is what brings back the rows from server
    loadItems() {
      const baseURI = this.$settings.endPointCt + "master-carrier";

      //var columnFilters = this.serverParams.columnFilters;
      //http://localhost:8082/master-carrier/?carrier_code&carrier_name&carrier
      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&carrier_name=${this.serverParams.columnFilters.carrier_name}&carrier_code=${this.serverParams.columnFilters.carrier_code}&carrier_email=${this.serverParams.columnFilters.carrier_email}&location=${this.serverParams.columnFilters.location}`,
        )
        .then((response) => {
          // console.log(response);
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
        });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },


    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },
  },
  events: {},
  created: function () { },
  mounted() {
    this.fetchIt();
    this.loading();
  },
};
</script>
<style scoped></style>