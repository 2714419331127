import { render, staticRenderFns } from "./fppkNotificationDetail.vue?vue&type=template&id=79478afa&scoped=true"
import script from "./fppkNotificationDetail.vue?vue&type=script&lang=js"
export * from "./fppkNotificationDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79478afa",
  null
  
)

export default component.exports