<template>
  <div>
  <div class="loader" v-if="this.isLoading"></div>
  <div :class="{'blur-content': this.isLoading}">
    <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("postpone_order_txt") }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
              aria-label="btn-close"></button>
          </div>

          <form class="forms-sample" method="POST" @submit.prevent="submitDataPostpone">
            <div class="modal-body">


              <div class="mb-3">
                <label class="form-label">{{
                $t("postpone_time_txt")
                }}</label>
                <div class="input-group">

                  <input type="text" class="form-control" id="postpone_time" :placeholder="$t('postpone_time_txt')"
                    v-model="postpone_time" v-on:keypress="isNumber()" maxlength="2" required />
                  <span class="input-group-text input-group-addon">{{$t('total_hours_txt')}}</span>

                </div>

                <div v-if="errors.postpone_time">
                  <div v-for="error in errors.postpone_time" :key="error" class="alert alert-primary" role="alert">
                    <i data-feather="alert-circle"></i>
                    {{ error }}
                  </div>
                </div>
              </div>





            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
                {{ $t("close_txt") }}
              </button>
              <button class="btn btn-primary" type="submit">{{ $t("save_change_txt") }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>


export default {
  components: {
  },
  name: "ModalTransportOrderCtDetailPostpone",
  emits: ["closeModal"],
  props: {
    showModal: Boolean,
    data: {},
  },
  watch: {
    showModal: {
      handler(newVal) {
        this.getTotalPostpone()
        this.active = newVal;
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      postpone_time: 0,
      isLoading: false,
      active: this.showModal,
      forms: [],
      errors: [],
      locale: [],
      report_date: [],
      type_error: [],
    };
  },
  methods: {
    resultError(data) {
      var html = "";
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          html += objectData[y] + "<br>";
        }
      }

      this.loadError(html);
    },

    loadError(pesan) {
      this.$swal({
        backdrop: true,
        title: "Warning",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },

    loadSuccess(pesan) {
      this.$swal({
        backdrop: true,
        title: "Success",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },

    success2(msg) {

      this.$swal({
        icon: 'info',
        backdrop: true,
        title: "Information",
        html: "<p>* " + msg + "</p>",
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      })


    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },


    loadingSubmiting() {


      this.$swal({
        icon: 'warning',
        backdrop: true,
        title: "Please wait",
        html: '<h2>SUBMITTING ORDER...</h2>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

    },

    submitDataa() {
      // this.loadingSubmiting();        
      //this.isLoading = true;
      var forms = { postpone_time: this.postpone_time }
      const baseURI = this.$settings.endPoint + "control-tower/transport-order-header/update-postpone/" + this.data.transport_order_header_id;
      this.$http
        .post(baseURI, forms, {})//,{ timeout: 1000 })
        .then((response) => {  
          this.isLoading = false;       
          this.$emit("closeModal"); 
          this.errors = [];
          this.success2(response.data.datas.messages);
        })
        .catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    },

    submitDataPostpone() {
      // var forms = { postpone_time: this.postpone_time }  
      this.$emit("closeModal", this.postpone_time);     
    },

    getTotalPostpone() {
      const submitTime = this.data.submit_time
      const valueTime = this.data.postpone_time
      if (valueTime && valueTime.length > 0) {
        var a = this.$moment(String(valueTime));
        var b = this.$moment(String(submitTime));

        var hours = a.diff(b, 'hours')
        this.postpone_time = hours
      } else {
        this.postpone_time = 0
      }


    },
    isNumber(value) {
      let evt = (value) ? value : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    }

  },
  mounted() {

  },
};
</script>

<style scoped>
.blur-content {
  filter: blur(5px);
  pointer-events: none;
}

.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left:0px !important;
}
</style>