<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading">
      {{ $t("loadingTxt") }}...
    </div>
    <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
      <!--begin::sidebar-->
      <sidebar-component classMenu="Users"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left: 25px">
                    {{ $t("userAccessAdd") }}
                  </h6>
                  <button class="btn btn-default btn-sm float-end" @click="resetForm" type="button">
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form class="forms-sample" @submit.prevent="submitData" method="POST">
                    <div class="d-flex justify-content-between align-items-baseline mb-2"></div>

                    <!-- user_type -->
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="user_type" class="col-form-label" style="padding-left: 25px">{{ $t("user_type_txt")
                          }}</label>
                      </div>
                      <div class="col-lg-8">
                        <v-select :options="fetchUserType" return-object label="description" v-model="user_type"
                          @change="onUserTypeChange" :class="{ 'is-invalid': errors.user_type }"></v-select>

                        <!-- Tampilkan alert jika user_type kosong -->
                        <div v-if="errors.user_type">
                          <div class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i> {{ errors.user_type[0] }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- name -->
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="name" class="col-form-label" style="padding-left: 25px">{{ $t("nameTxt") }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="nameTxt" :placeholder="$t('nameTxt')"
                          v-model="forms.name" :class="{ 'is-invalid': errors.name }" />
                        <div v-if="errors.name">
                          <div class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i> {{ errors.name[0] }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- username -->
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="username" class="col-form-label" style="padding-left: 25px">{{ $t("usernameTxt")
                          }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="usernameTxt" :placeholder="$t('usernameTxt')"
                          v-model="forms.username" :class="{ 'is-invalid': errors.username }" />
                        <div v-if="errors.username">
                          <div class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i> {{ errors.username[0] }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- password -->
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="password" class="col-form-label" style="padding-left: 25px">{{ $t("password")
                          }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="password" class="form-control" id="password" :placeholder="$t('password')"
                          v-model="forms.password" :class="{ 'is-invalid': errors.password }" />
                        <div v-if="errors.password">
                          <div class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i> {{ errors.password[0] }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- email -->
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="email" class="col-form-label" style="padding-left: 25px">{{ $t("emailAddress")
                          }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="email" class="form-control" id="emailAddress" :placeholder="$t('emailAddress')"
                          v-model="forms.email" :class="{ 'is-invalid': errors.email }" />
                        <div v-if="errors.email">
                          <div class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i> {{ errors.email[0] }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- customerCode -->
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="customerCode" class="col-form-label" style="padding-left: 25px">{{
                          $t("customer_code") }}</label>
                      </div>
                      <div class="col-lg-8">
                        <v-select v-if="companyCode == 'LINC'" :options="fetchCompany" v-model="company" label="code"
                          return-object @search="asyncSearchCompany" @input="onCompanySelected" @clear="onCompanyClear"
                          :disabled="user_type && (user_type.value === 'MDRV' || user_type.value === 'MOPS')"
                          :class="{ 'is-invalid': errors.companyId }"></v-select>
                        <v-select v-if="companyCode != 'LINC'" :options="fetchCompanyNotLinc" v-model="company"
                          label="code" return-object @search="asyncSearchCompanyNotLinc" @input="onCompanySelected"
                          @clear="onCompanyClear"
                          :disabled="user_type && (user_type.value === 'MDRV' || user_type.value === 'MOPS')"
                          :class="{ 'is-invalid': errors.companyId }"></v-select>
                        <div v-if="errors.companyId">
                          <div class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i> {{ errors.companyId[0] }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Row for Customer Name -->
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="customerName" class="col-form-label" style="padding-left: 25px">{{
                          $t("customer_name") }}</label>
                      </div>
                      <div class="col-lg-8">
                        <div v-if="isLoadingCustomerName">
                          <!-- Indikator loading -->
                          <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                        <div v-else>
                          <!-- Tampilkan field customer name jika sudah selesai loading -->
                          <input type="text" class="form-control" id="customerName" v-model="forms.customer_name"
                            disabled />
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{ $t("roleTxt")
                          }}</label>
                      </div>
                      <div class="col-lg-8">
                        <v-select :options="fetchRole" v-model="role" label="name" return-object
                          @search="asyncSearchRole" :class="{ 'is-invalid': errors.roleId }"></v-select>
                        <div v-if="errors.roleId">
                          <div class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i> {{ errors.roleId[0] }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3" v-show="user_type && user_type.value === 'MDRV'">
                      <div class="col-lg-2">
                        <label for="vehicle_no" class="col-form-label" style="padding-left: 25px">{{ $t("vehicle_no")
                          }}</label>
                      </div>
                      <div class="col-lg-8">
                        <v-select :options="fetchVehicleNos" v-model="vehicle_no" label="vehicle_no" return-object
                          @search="asyncSearchVehicle" :loading="isSearchingVehicle"
                          :class="{ 'is-invalid': errors.vehicle_no }"></v-select>
                        <div v-if="errors.vehicle_no">
                          <div class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i> {{ errors.vehicle_no[0] }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3" v-show="user_type && user_type.value === 'MDRV'">
                      <div class="col-lg-2">
                        <label for="carrier" class="col-form-label" style="padding-left: 25px">{{ $t("carrier")
                          }}</label>
                      </div>
                      <div class="col-lg-8">
                        <v-select :options="fetchCarrier" v-model="carrier" label="" return-object
                          @search="asyncSearchCarrier" :loading="isSearchingCarrier"
                          :class="{ 'is-invalid': errors.carrier }"></v-select>
                        <div v-if="errors.carrier">
                          <div class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i> {{ errors.carrier[0] }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3" v-show="user_type && user_type.value === 'MDRV'">
                      <div class="col-lg-2">
                        <label for="driver_nmk" class="col-form-label" style="padding-left: 25px">{{ $t("driver_nmk")
                          }}</label>
                      </div>
                      <div class="col-lg-8">
                        <v-select :options="fetchMasterDriver" v-model="driver_nmk" label="driver_id" return-object
                          @search="asyncMasterDriver" @input="onDriverSelected" @clear="resetDriverData"
                          :loading="isSearching" :class="{ 'is-invalid': errors.driver_id }"></v-select>
                        <div v-if="errors.driver_id">
                          <div class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i> {{ errors.driver_id[0] }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3" v-show="user_type && user_type.value === 'MDRV'">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{ $t("driver_id")
                          }}</label>
                      </div>
                      <div class="col-lg-8">
                        <table class="table table-hover custom-table" v-if="driverNMKData && driverNMKData.length > 0">
                          <thead>
                            <tr>
                              <th scope="col">No</th>
                              <th scope="col">NMK</th>
                              <th scope="col">Driver Number</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(driver, index) in driverNMKData" :key="driver?.driver_nmk_id">
                              <td>{{ index + 1 }}</td>
                              <td>{{ driver?.nmk || "" }}</td>
                              <td>{{ driver?.driver_number || "" }}</td>
                            </tr>
                          </tbody>
                        </table>
                        <p v-else>
                          Data Tidak Ditemukan, Silahkan Pilih Driver NMK
                        </p>
                      </div>
                    </div>

                    <div class="row mb-3" v-show="user_type && user_type.value === 'MOPS'">
                      <div class="col-lg-2">
                        <label for="department" class="col-form-label" style="padding-left: 25px">{{ $t("department")
                          }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="department" v-model="forms.department"
                          :placeholder="$t('department')" :class="{ 'is-invalid': errors.department }" />
                        <div v-if="errors.department">
                          <div class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i> {{ errors.department[0] }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3" v-show="user_type && user_type.value === 'MOPS'">
                      <div class="col-lg-2">
                        <label for="driver_id" class="col-form-label" style="padding-left: 25px">{{ $t("driver_id")
                          }}</label>
                      </div>
                      <div class="col-lg-6">
                        <v-select :options="fetch_nmk_mops" v-model="nmk_mops" label="nmk" return-object
                          @search="async_search_nmk_mops" @input="on_nmk_mops_selected" @clear="clear_nmk_mops"
                          :loading="is_searching_nmk_mops" :class="{ 'is-invalid': errors.nmk }">
                        </v-select>
                        <div v-if="errors.nmk">
                          <div class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i> {{ errors.nmk[0] }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2">
                        <!-- Toggle Select All/Unselect All button -->
                        <div class="row mr-5">
                          <button class="btn d-flex align-items-center justify-content-center" :class="is_all_selected ? 'btn-secondary' : 'btn-info'
                            " @click="toggle_select_all_unselect" @click.prevent
                            :disabled="selected_nmk_mops.length === 0" style="
                              height: 38px;
                              width: 38px;
                              margin-right: 10px;
                            ">
                            <!-- Add margin-right for spacing -->
                            <i :class="is_all_selected
                              ? 'mdi mdi-selection-ellipse-remove'
                              : 'mdi mdi-selection-ellipse'
                              " style="font-size: 1.5rem"></i>
                          </button>

                          <!-- Delete button styled to fit with dropdown height -->
                          <button class="btn btn-danger d-flex align-items-center justify-content-center"
                            :disabled="!is_delete_enabled_nmk_mops" @click="delete_on_nmk_mops_checked"
                            style="height: 38px; width: 38px">
                            <i class="mdi mdi-delete-empty" style="font-size: 1.5rem"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Table for NMK MOPS -->
                    <div class="row mb-3" v-show="user_type && user_type.value === 'MOPS'">
                      <div class="col-lg-2"></div>
                      <div class="col-lg-8">
                        <table class="table table-hover custom-table" v-if="selected_nmk_mops.length > 0">
                          <thead>
                            <tr>
                              <th scope="col">No</th>
                              <th scope="col">NMK</th>
                              <th scope="col">Driver Number</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(nmk_mops, index) in selected_nmk_mops" :key="nmk_mops.nmk"
                              :class="nmk_mops.rowClass" @click="toggleRowSelection(nmk_mops)">
                              <td>{{ index + 1 }}</td>
                              <td>{{ nmk_mops.nmk }}</td>
                              <td>{{ nmk_mops.driver_number }}</td>
                            </tr>
                          </tbody>
                        </table>
                        <p v-else>
                          Data Tidak Ditemukan, Silahkan Pilih Driver ID
                        </p>
                      </div>
                    </div>

                    <div class="card-footer">
                      <button class="btn btn-warning text-black fw-bolder btn-icon-text" @click="backForm"
                        type="button">
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>

                      &nbsp;&nbsp;&nbsp;
                      <button class="btn btn-primary float-end btn-sm" type="submit">
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";

export default {
  name: "UsersAdd",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      fetchUserType: [],
      user_type: [],
      fetchRole: [],
      fetchCompany: [],
      fetchCompanyNotLinc: [],
      permision_role: [],
      company: [],
      role: [],
      forms: { name: "", username: "", department: "", customer_name: "" },
      driver_nmk: "",
      fetchMasterDriver: [],
      isSearching: false,
      driverNMKData: [],
      vehicle_no: null,
      fetchVehicleNos: [],
      isSearchingVehicle: false,
      carrier: null,
      fetchCarrier: [],
      isSearchingCarrier: false,
      is_searching_nmk_mops: false,
      is_delete_enabled_nmk_mops: false,
      selected_nmk_mops: [],
      fetch_nmk_mops: [],
      nmk_mops: null,
      is_all_selected: false,
      isLoadingCustomerName: false,
    };
  },
  watch: {
    company(newVal) {
      if (!newVal) {
        this.forms.customer_name = "";
      }
    },
    user_type() {
      //console.log("user_type changed to:", newVal);
      this.onUserTypeChange();
    }
  },
  methods: {
    loadInitialData() {
      this.loadRole();
      this.loadCompany();
      this.loadCompanyNotLinc();
      this.getSystemCode();
      this.loadMasterDriver();
      this.loadVehicleNo();
      this.loadCarrier();
      this.load_nmk_mops();
    },

    async onUserTypeChange() {

      if (this.user_type && this.user_type.value === "MDRV") {
        // Reset data terkait MDRV
        this.vehicle_no = null;
        this.carrier = null;
        this.driver_nmk = null;
        this.driverNMKData = [];

        // Set nilai default company untuk MDRV
        this.$set(this, 'company', { code: "BP" });

        if (this.companyCode === 'LINC') {
          await this.asyncSearchCompany("BP");
        } else {
          await this.asyncSearchCompanyNotLinc("BP");
        }

        if (this.company && this.company.name) {
          this.forms.customer_name = this.company.name;
        } else {
          this.forms.customer_name = "";
        }

      } else if (this.user_type && this.user_type.value === "MOPS") {
        // Reset data terkait MOPS
        this.nmk_mops = null;
        this.department = null;
        this.selected_nmk_mops = [];

        // Set nilai default company untuk MOPS
        this.$set(this, 'company', { code: "BP" });

        if (this.companyCode === 'LINC') {
          await this.asyncSearchCompany("BP");
        } else {
          await this.asyncSearchCompanyNotLinc("BP");
        }

        if (this.company && this.company.name) {
          this.forms.customer_name = this.company.name;
        } else {
          this.forms.customer_name = "";
        }

      } else {
        // Kosongkan data jika user_type bukan MDRV atau MOPS
        this.nmk_mops = null;
        this.company = null;
        this.forms.customer_name = "";
        this.vehicle_no = null;
        this.carrier = null;
        this.driver_nmk = null;
        this.driverNMKData = [];
        this.department = null;
        this.selected_nmk_mops = [];
      }
    },

    // Method to toggle between select all and unselect all
    toggle_select_all_unselect() {
      if (this.is_all_selected) {
        // If all rows are selected, unselect all
        this.unselect_all_nmk_mops();
      } else {
        // If not all rows are selected, select all
        this.select_all_nmk_mops();
      }
    },

    // Method to select all rows
    select_all_nmk_mops() {
      this.selected_nmk_mops.forEach((nmk_mops) => {
        nmk_mops.checked = true;
        this.$set(nmk_mops, "rowClass", "table-active");
      });
      this.on_nmk_mops_checked();
    },

    // Method to unselect all rows
    unselect_all_nmk_mops() {
      this.selected_nmk_mops.forEach((nmk_mops) => {
        nmk_mops.checked = false;
        this.$set(nmk_mops, "rowClass", "");
      });
      this.on_nmk_mops_checked();
    },

    toggleRowSelection(nmk_mops) {
      nmk_mops.checked = !nmk_mops.checked; // Toggle the checked status

      // Memastikan elemen yang diklik mendapatkan/tidak mendapatkan class table-active
      if (nmk_mops.checked) {
        this.$set(nmk_mops, "rowClass", "table-active");
      } else {
        this.$set(nmk_mops, "rowClass", "");
      }

      // Update the delete button's enabled/disabled status
      this.on_nmk_mops_checked();
    },

    clear_nmk_mops() {
      this.selected_nmk_mops = [];
      this.is_delete_enabled_nmk_mops = false;
    },

    delete_on_nmk_mops_checked() {
      this.selected_nmk_mops = this.selected_nmk_mops.filter(
        (nmk_mops) => !nmk_mops.checked
      );
      this.is_delete_enabled_nmk_mops = false;
      this.is_all_selected = false;
    },

    on_nmk_mops_checked() {
      this.is_delete_enabled_nmk_mops = this.selected_nmk_mops.some(
        (nmk_mops) => nmk_mops.checked
      );
      this.is_all_selected = this.selected_nmk_mops.every(
        (nmk_mops) => nmk_mops.checked
      );
    },

    delete_nmk_mops(index) {
      this.selected_nmk_mops.splice(index, 1);
      this.on_nmk_mops_checked();
    },

    on_nmk_mops_selected(nmk_mops) {
      if (
        nmk_mops &&
        !this.selected_nmk_mops.some((d) => d.nmk === nmk_mops.nmk)
      ) {
        this.selected_nmk_mops.push({ ...nmk_mops, checked: false });
      }
    },

    async_search_nmk_mops(searchQuery) {
      this.is_searching_nmk_mops = true;
      const baseURI = `${this.$settings.endPointCt}driver-nmk?nmk=${searchQuery}`;
      return this.$http
        .get(baseURI)
        .then((response) => {
          this.fetch_nmk_mops = response.data.datas.data;
        })
        .finally(() => {
          this.is_searching_nmk_mops = false;
        });
    },

    load_nmk_mops() {
      const baseURI = `${this.$settings.endPointCt}driver-nmk`;
      return this.$http
        .get(baseURI)
        .then((response) => {
          this.fetch_nmk_mops = response.data.datas.data;
        })
        .catch((error) => {
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.$router.push("/server-error");
            } else if (error.response.status === 401) {
              this.$router.push("/authorized-error");
            } else {
              this.$router.push("/page-not-found");
            }
          }
        });
    },

    asyncSearchCarrier(searchQuery) {
      this.isSearchingCarrier = true;
      const baseURI = `${this.$settings.endPointCt}master-lookup?unique_id=carer&search=${searchQuery}`;
      return this.$http
        .get(baseURI)
        .then((response) => {
          this.fetchCarrier = response.data.datas; // Array of strings
        })
        .finally(() => {
          this.isSearchingCarrier = false;
        });
    },

    loadCarrier() {
      const baseURI = `${this.$settings.endPointCt}master-lookup?unique_id=carer`;
      return this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          this.fetchCarrier = response.data.datas; // Array of strings
        })
        .catch((error) => {
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.$router.push("/server-error");
            } else if (error.response.status === 401) {
              this.$router.push("/authorized-error");
            } else {
              this.$router.push("/page-not-found");
            }
          }
        });
    },

    resetDriverData() {
      this.driverNMKData = [];
    },

    loadVehicleNo() {
      const baseURI = `${this.$settings.endPointCt}master-truck?limit=10`;
      return this.$http
        .get(baseURI)
        .then((response) => {
          this.fetchVehicleNos = response.data.datas.data;
        })
        .catch((error) => {
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.$router.push("/server-error");
            } else if (error.response.status === 401) {
              this.$router.push("/authorized-error");
            } else {
              this.$router.push("/page-not-found");
            }
          }
        });
    },

    asyncSearchVehicle(searchQuery) {
      this.isSearchingVehicle = true;
      const baseURI = `${this.$settings.endPointCt}master-truck?limit=10&vehicle_no=${searchQuery}`;
      return this.$http
        .get(baseURI)
        .then((response) => {
          this.fetchVehicleNos = response.data.datas.data;
        })
        .finally(() => {
          this.isSearchingVehicle = false;
        });
    },

    asyncMasterDriver(searchQuery) {
      this.isSearching = true;
      const baseURI = `${this.$settings.endPointCt}master-driver?limit=10&driver_id=${searchQuery}`;
      return this.$http
        .get(baseURI)
        .then((response) => {
          this.fetchMasterDriver = response.data.datas.data;

          const selectedDriver = this.fetchMasterDriver.find(
            (driver) => driver.driver_id === searchQuery
          );
          if (selectedDriver) {
            this.master_driver_id = selectedDriver.master_driver_id;
            return this.loadDriverNMK();
          }
        })
        .finally(() => {
          this.isSearching = false;
        });
    },

    onDriverSelected(selectedDriver) {
      if (!selectedDriver) {
        this.master_driver_id = null;
        this.driverNMKData = [];
        return;
      }

      if (selectedDriver && selectedDriver.master_driver_id) {
        this.master_driver_id = selectedDriver.master_driver_id;
        return this.loadDriverNMK();
      }
    },

    loadMasterDriver() {
      const baseURI = `${this.$settings.endPointCt}master-driver?limit=10`;
      return this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          this.fetchMasterDriver = response.data.datas.data;
          return this.loadDriverNMK();
        })
        .catch((error) => {
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.$router.push("/server-error");
            } else if (error.response.status === 401) {
              this.$router.push("/authorized-error");
            } else {
              this.$router.push("/page-not-found");
            }
          }
        });
    },

    loadDriverNMK() {
      const baseURI = `${this.$settings.endPointCt}driver-nmk?master_driver_id=${this.master_driver_id}`;
      return this.$http
        .get(baseURI)
        .then((response) => {
          this.driverNMKData = response.data.datas.data;
        })
        .catch((error) => {
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.$router.push("/server-error");
            } else if (error.response.status === 401) {
              this.$router.push("/authorized-error");
            } else {
              this.$router.push("/page-not-found");
            }
          }
        });
    },

    resetForm() {
      this.$settings.endPoint;
      this.forms.name = "";
      this.forms.username = "";
      this.forms.password = "";
      this.forms.email = "";
      this.forms.companyId = "";
      this.forms.roleId = "";
      this.forms.customer_name = "";
      this.forms.department = "";
      this.company = [];
      this.role = [];
      this.user_type = [];
      this.driver_nmk = "";
      this.fetchMasterDriver = [];
      this.isSearching = false;
      this.driverNMKData = [];
      this.vehicle_no = null;
      this.fetchVehicleNos = [];
      this.carrier = null;
      this.fetchCarrier = [];
      this.selected_nmk_mops = [];
      this.fetch_nmk_mops = [];
      this.nmk_mops = null;
    },

    backForm() {
      window.location.href = "/users";
    },

    loadRole() {
      const baseURI = this.$settings.endPoint + "/role?limit=10";
      this.isLoading = true;
      return this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          this.fetchRole = response.data.datas.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },

    asyncSearchRole(ev) {
      const baseURI = this.$settings.endPoint + "role?name=" + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchRole = response.data.datas.data;
      });
    },

    loadCompany() {
      const baseURI = this.$settings.endPoint + "company?limit=10";
      this.isLoading = true;

      return this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          this.fetchCompany = response.data.datas.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },

    loadCompanyNotLinc() {
      const baseURI = this.$settings.endPoint + `company/select`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },

    onCompanyClear() {
      // Hapus customer name ketika dropdown di-reset atau di-clear
      this.forms.customer_name = "";
      this.company = null;
    },

    onCompanySelected(selectedCompany) {
      if (selectedCompany && selectedCompany.name) {
        this.forms.customer_name = selectedCompany.name;
      } else {
        // Jika tidak ada company yang terpilih, kosongkan customer_name
        this.forms.customer_name = "";
      }
    },

    asyncSearchCompany(ev) {
      const baseURI = this.$settings.endPoint + "company/select?code=" + ev;
      this.isLoadingCustomerName = true;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
        this.isLoadingCustomerName = false;

        // Jika user_type adalah MDRV atau MOPS, isi otomatis customer_name dengan data hasil pencarian
        if (this.user_type && (this.user_type.value === "MDRV" || this.user_type.value === "MOPS")) {
          if (this.fetchCompany.length > 0) {
            this.company = this.fetchCompany[0]; // Ambil hasil pertama
            this.forms.customer_name = this.fetchCompany[0].name; // Isi otomatis
          }
        } else {
          // Hanya isi customer_name jika pengguna memilih item secara manual di dropdown
          if (this.company && this.company.name) {
            this.forms.customer_name = this.company.name;
          }
        }
      })
    },

    asyncSearchCompanyNotLinc(ev) {
      const baseURI =
        this.$settings.endPoint +
        "company?name=" +
        ev +
        "&code=" +
        this.companyCode;
      this.isLoadingCustomerName = true;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
        this.isLoadingCustomerName = false;

        // Jika user_type adalah MDRV atau MOPS, isi otomatis customer_name dengan data hasil pencarian
        if (this.user_type && (this.user_type.value === "MDRV" || this.user_type.value === "MOPS")) {
          if (this.fetchCompanyNotLinc.length > 0) {
            this.company = this.fetchCompanyNotLinc[0]; // Ambil hasil pertama
            this.forms.customer_name = this.fetchCompanyNotLinc[0].name; // Isi otomatis
          }
        } else {
          // Hanya isi customer_name jika pengguna memilih item secara manual di dropdown
          if (this.company && this.company.name) {
            this.forms.customer_name = this.company.name;
          }
        }
      });
    },

    // submitData() {
    //   this.fade(true);
    //   let formData = new FormData();
    //   formData.append("name", this.forms.name);
    //   formData.append("username", this.forms.username);
    //   formData.append("password", this.forms.password);
    //   formData.append("email", this.forms.email);
    //   formData.append("company_id", this.company.company_id);
    //   formData.append("role_id", this.role.role_id);
    //   formData.append("user_type", this.user_type.value);
    //   formData.append("status", "active");
    //   formData.append("customer_name", this.forms.customer_name);

    //   if (this.user_type.value === "MDRV") {
    //     formData.append("vehicle_no", this.vehicle_no.vehicle_no);
    //     formData.append("driver_nmk", this.driver_nmk.driver_id);
    //     formData.append("carrier", this.carrier);

    //     console.log("this.driverNMKData", this.driverNMKData);
    //     // Langsung kirim nmk_list sebagai array
    //     this.driverNMKData.forEach((driver, index) => {
    //       formData.append(`nmk_list[${index}][driver_number]`, driver.driver_number);
    //       formData.append(`nmk_list[${index}][nmk]`, driver.nmk);
    //     });
    //   }

    //   if (this.user_type.value === "MOPS") {
    //     formData.append("department", this.forms.department);

    //     console.log("this.selected_nmk_mops", this.selected_nmk_mops);
    //     // Langsung kirim nmk_list sebagai array untuk MOPS
    //     this.selected_nmk_mops.forEach((nmk_mops, index) => {
    //       formData.append(`nmk_list[${index}][driver_number]`, nmk_mops.driver_number);
    //       formData.append(`nmk_list[${index}][nmk]`, nmk_mops.nmk);
    //     });
    //   }

    //   formData.forEach((value, key) => {
    //     console.log(`${key}: ${value}`);
    //   });
    //   return;

    //   // const baseURI = this.$settings.endPoint + "user/create";
    //   // this.$http
    //   //   .post(baseURI, formData)
    //   //   .then((response) => {
    //   //     this.loading();
    //   //     if (response.data.status === 200) {
    //   //       this.errors = [];
    //   //       var params = this.$onRandom(response.data.datas.user_id);
    //   //       window.location.href =
    //   //         "/users/detail/" + params + "?status=200&msg=Successfully";
    //   //     } else {
    //   //       this.errors = response.data.errors;
    //   //       this.resultError(response.data.errors);
    //   //     }
    //   //   })
    //   //   .catch((error) => {
    //   //     this.loading();
    //   //     if (error.response) {
    //   //       if (error.response.status === 422) {
    //   //         this.errors = error.response.data.errors;
    //   //         this.resultError(error.response.data.errors);
    //   //       } else if (error.response.status === 500) {
    //   //         this.$router.push("/server-error");
    //   //       } else {
    //   //         this.$router.push("/page-not-found");
    //   //       }
    //   //     }
    //   //   });
    // },

    validateRequiredField(fieldName, fieldValue, translatedFieldName) {
      if (!fieldValue || (typeof fieldValue === 'object' && !fieldValue.value)) {
        // Tambahkan pesan error dengan "is required"
        const errorMessage = `${translatedFieldName} is required`;
        this.$set(this.errors, fieldName, [errorMessage]);
      } else {
        this.$delete(this.errors, fieldName);
      }
    },

    submitData() {
      this.errors = {}; // Reset errors

      // Validasi field secara dinamis menggunakan fungsi validateRequiredField
      this.validateRequiredField('user_type', this.user_type, this.$t('user_type_txt'));
      this.validateRequiredField('name', this.forms.name, this.$t('nameTxt'));
      this.validateRequiredField('username', this.forms.username, this.$t('usernameTxt'));
      this.validateRequiredField('password', this.forms.password, this.$t('password'));
      this.validateRequiredField('email', this.forms.email, this.$t('emailAddress'));

      if (this.user_type && this.user_type.value !== 'MDRV' && this.user_type.value !== 'MOPS') {
        this.validateRequiredField('companyId', this.company?.company_id, this.$t('customer_code'));
      }

      this.validateRequiredField('roleId', this.role?.role_id, this.$t('roleTxt'));

      if (this.user_type && this.user_type.value === 'MDRV') {
        this.validateRequiredField('vehicle_no', this.vehicle_no?.vehicle_no, this.$t('vehicle_no'));
        this.validateRequiredField('carrier', this.carrier, this.$t('carrier'));
        if(this.driverNMKData.length === 0){
          this.validateRequiredField('driver_id', this.driver_nmk?.driver_id, this.$t('driver_nmk'));
        }
      }

      if (this.user_type && this.user_type.value === 'MOPS') {
        this.validateRequiredField('department', this.forms.department, this.$t('department'));
        if(this.selected_nmk_mops.length === 0){
          this.validateRequiredField('nmk', this.nmk_mops?.nmk, this.$t('driver_id'));
        }
      }

      // Jika ada error, hentikan submit
      if (Object.keys(this.errors).length > 0) {
        return;
      }

      // Siapkan data yang akan dikirim sesuai user_type
      let data = {
        name: this.forms.name,
        username: this.forms.username,
        password: this.forms.password,
        email: this.forms.email,
        company_id: this.company ? this.company.company_id : null,
        user_type: this.user_type.value,
        status: 'active',
        customer_name: this.forms.customer_name,
        role_id: this.role.role_id,
      };

      // Jika user_type adalah MDRV, tambahkan data terkait MDRV
      if (this.user_type.value === 'MDRV') {
        data.vehicle_no = this.vehicle_no.vehicle_no;
        data.driver_nmk = this.driver_nmk.driver_id;
        data.carrier = this.carrier;

        // Kirimkan daftar nmk_list
        data.nmk_list = this.driverNMKData.map(driver => ({
          driver_number: driver.driver_number,
          nmk: driver.nmk,
        }));
      }

      // Jika user_type adalah MOPS, tambahkan data terkait MOPS
      if (this.user_type.value === 'MOPS') {
        data.department = this.forms.department;

        // Kirimkan daftar nmk_list
        data.nmk_list = this.selected_nmk_mops.map(nmk_mops => ({
          driver_number: nmk_mops.driver_number,
          nmk: nmk_mops.nmk,
        }));
      }

      // Debug untuk melihat data yang akan dikirim
      // console.log("Data yang dikirim:", data);
      // return;

      // Lanjutkan proses submit ke backend
      this.$http.post(this.$settings.endPoint + "user/create", data, {
        headers: {
          "Content-Type": "application/json",
        },
      }).then(response => {
        this.loading();
        if (response.data.status === 200) {
          this.errors = [];
          var params = this.$onRandom(response.data.datas.user_id);
          window.location.href = "/users/detail/" + params + "?status=200&msg=Successfully";
        } else {
          this.errors = response.data.errors;
          this.resultError(response.data.errors);
        }
      }).catch(error => {
        this.loading();
        if (error.response) {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
            this.resultError(error.response.data.errors);
          } else if (error.response.status === 500) {
            this.$router.push("/server-error");
          } else {
            this.$router.push("/page-not-found");
          }
        }
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      this.companyCode = this.detailUser.company_detail.code;
      // this.permision_role = userDatas.sub.permision_role;
      this.companyCode = this.detailUser.company_detail.code;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "UserController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },

    getSystemCode() {
      const baseURI =
        this.$settings.endPoint + "system-code/select?system_code=COMPANY_TYPE";

      this.isLoading = true;
      return this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          var rows = response.data.datas.data;
          if (rows.length > 0) {
            this.fetchUserType = rows;
          }

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },
  },
  events: {},
  created: function () { },
  mounted() {
    this.fetchIt();
    this.loadInitialData();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content {
  filter: blur(5px);
  pointer-events: none;
}

.custom-table {
  text-align: center;
}

.custom-table thead {
  background-color: #ab0635;
  color: white;
}

.custom-table th,
.custom-table td,
.custom-table tr {
  background-color: #ab0635;
  color: white;
}

.custom-table tbody tr:hover {
  background-color: #ab0635;
  color: white;
}

.table-active {
  background-color: #ff8c00 !important;
  /* Dark orange background for selected rows */
  color: white !important;
  /* White text for selected rows */
}

.custom-table .disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
