import SKU from "@/components/master/sku/SKU.vue";
import SKUAdd from "@/components/master/sku/SKUAdd.vue";
import SKUDetail from "@/components/master/sku/SKUDetail.vue";
import SKUEdit from "@/components/master/sku/SKUEdit.vue";
import SKUUpload from "@/components/master/sku/SKUUpload.vue";

let routesSKU= [
    
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "SKUIndex",
        component: SKU
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "SKUAdd",
        component: SKUAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "SKUEdit",
        component: SKUEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "SKUDetail",
        component: SKUDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "SKUUpload",
        component: SKUUpload
      },
    
]

export default routesSKU
  