import CustomerCT from "@/components/master/customerCT/CustomerCTData.vue";
import CustomerCTAdd from "@/components/master/customerCT/CustomerCTAdd.vue";
import CustomerCTDetail from "@/components/master/customerCT/CustomerCTDetail.vue";
import CustomerCTEdit from "@/components/master/customerCT/CustomerCTEdit.vue";
import CustomerCTUpload from "@/components/master/customerCT/CustomerCTUpload.vue";

let routesCustomerCT= [
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "CustomerCT",
        component: CustomerCT
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "CustomerCTAdd",
        component: CustomerCTAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "CustomerCTEdit",
        component: CustomerCTEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "CustomerCTDetail",
        component: CustomerCTDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "CustomerCTUpload",
        component: CustomerCTUpload
      },
    
]

export default routesCustomerCT
  