<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="BillablePartiesIndex"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div>
              <h4 class="mb-12 mb-md-0"></h4>
            </div>

            <div class="col-lg-12 col-xl-12 stretch-card">
              <div class="card">
                <div class="card-header">
                  <h4 style="margin-right: 5px" class="float-start">
                    Billable Acc Rules
                  </h4>
                  <button
                    v-if="permision_role.method_create"
                    class="btn btn-primary float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="createData()"
                  >
                    <i class="link-icon" data-feather="plus"></i>
                    {{ $t("createNew") }}
                  </button>

                  <!-- <button
                      class="btn btn-warning float-end btn-xs"
                      style="margin-right: 5px"
                      @click.prevent="uploadData()"
                    >
                       <i class="link-icon" data-feather="upload"></i>
                      {{ $t("uploadData") }} .xlsx
                    </button>
                     -->

                  <button
                      v-if="permision_role.method_read"
                      class="btn btn-success float-end btn-xs"
                      style="margin-right: 5px"
                      @click.prevent="downloadData()"
                    >
                       <i class="link-icon" data-feather="download"></i>
                      {{ $t("downloadData") }} .xlsx
                    </button>
                </div>

                <div class="card-body">
                  <vue-good-table
                    title="list-user"
                    mode="remote"
                    @on-selected-rows-change="selectionChanged"
                    @on-per-page-change="onPerPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-page-change="onPageChange"
                    :totalRows="totalRecords"
                    :line-numbers="true"
                    :select-options="{
                      enabled: true,
                      selectOnCheckboxOnly: true,
                      selectionText: $t('data_selected'),
                      clearSelectionText: $t('clear'),
                    }"
                    :isLoading.sync="isLoading"
                    :pagination-options="{
                      enabled: true,
                    }"
                    :rows="rows"
                    :columns="columns"
                  >
                    <div slot="table-actions">
                      {{ $t("no_select_data") }}
                    </div>
                    <div slot="selected-row-actions">
                      <button
                        type="button"
                        class="btn btn-twitter btn-xs me-2"
                        style="margin-right: 20px"
                        @click="sendOrder"
                      >
                        <i class="mdi mdi-send-outline"></i>
                        {{ $t("sendToBlujay") }}
                      </button>
                    </div>
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'last_modified'">
                        {{ props.row.updated_at }} - {{ props.row.update_by }}
                      </span>
                      <span v-if="props.column.field == 'actions'">
                        <a
                          href="#"
                          title="Edit"
                          @click.prevent="editData(props.index, props.row)"
                        >
                          <i
                            class="mdi mdi-square-edit-outline"
                            style="font-size: 16px"
                          ></i>
                        </a>
                        <a
                          href="#"
                          v-if="permision_role.method_delete && props.row.status !== 'DISABLED'"
                          title="Delete"
                          @click.prevent="deleteData(props.index, props.row)"
                        >
                          <i
                            class="mdi mdi-trash-can-outline"
                            style="font-size: 16px"
                          ></i>
                        </a>
                      </span>
                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>
  
  <script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";

export default {
  name: "BillablePartiesIndex",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      isLoading: false,
      passData: [],
      showModalRmAccessorialRule: false,
      totalRecords: "",
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      permision_role: [],
      rows: [],
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      rowsSelection: null,
      billableRateIds: null,
      columns: [
        {
          label: "SoB",
          field: "sob",
          filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By SoB", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
        },
        {
          label: "Description",
          field: "description",
          filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By Description", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
        },
        {
          label: "File Upload Cross Reference",
          field: "file_upload",
          filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By File Upload", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Action",
          field: "actions",
          width: "80px",
        },
      ],
    };
  },
  watch: {},
  methods: {
       downloadData() {
        const baseURI = this.$settings.endPoint + "rm-billable-rate-acc";
        var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
        var file_name = "download_" + CurrentDate + ".xlsx";
  
        //var columnFilters = this.serverParams.columnFilters;
  
  
        return this.$http
          .get(
            baseURI +
              `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&sob=${this.serverParams.columnFilters.sob}&description=${this.serverParams.columnFilters.description}&file_upload=${this.serverParams.columnFilters.file_upload}&status=ENABLED&download=download`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
           
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");
  
            fileLink.href = fileURL;
            fileLink.setAttribute("download", file_name);
            document.body.appendChild(fileLink);
            fileLink.click();
            this.fade(false);
          });
      },
       createData() {
        window.location.href = "/billable-acc-rule/add";
      },
    sendOrder() {
      const baseURI =
        this.$settings.endPoint + "rm-billable-acc-rule/send-to-blujay";
      let data = {
        rm_billable_acc_rules_ids: this.billableRateIds,
      };
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
          this.$http
            .post(baseURI, data, { timeout: 30000 })
            .then((response) => {
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
                this.isloading = false;
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {
                if (error.response) {
                  // console.log(error.response.data.errors.message);
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.errors = error.response.data.errors;
                    this.error(error.response.data.errors.message);
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push("/page-not-found");
                  }
                }
              }
            });
        }
      });
    },
    deleteData(index, row, status) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI =
            this.$settings.endPoint + "rm-billable-acc-rule/disable/" + row.rm_billable_acc_rules_id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },
      onColumnFilter(params) {
        params.page = 1;
        this.updateParams(params);
      },
    closeMyModal() {
      this.showModalRmAccessorialRule = false;
      this.loadItems();
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },
    selectionChanged(ev) {
      this.rowsSelection = [];
      this.billableRateIds = [];
      this.rowsSelection = ev.selectedRows;
      this.rowsSelection.forEach((item) => {
        this.billableRateIds.push(item.rm_billable_acc_rules_id);
      });
    },
         editData(index, row) {
        var params = this.$onRandom(row.rm_billable_acc_rules_id);
        window.location.href = "/billable-acc-rule/edit/" + params;
      },
    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },
    loadItems() {
      const baseURI =
        this.$settings.endPoint +
        "rm-billable-acc-rule" +
        `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&sob=${this.serverParams.columnFilters.sob}&description=${this.serverParams.columnFilters.description}&file_upload=${this.serverParams.columnFilters.file_upload}&status=ENABLED`;

      return this.$http.get(baseURI).then((response) => {
        this.rows = response.data.datas.data;
        this.totalRecords = response.data.datas.total;
        this.loading();
      });
    },
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      this.companyCode = this.detailUser.company_detail.code;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RmBillableAccRuleController") {
          rolePermision = permision;
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },
    detailData(index, row) {
      var params = this.$onRandom(row.rm_billable_rate_detail_id);
      window.location.href =
        "/billable-rate/detail/" + this.$route.params.id + "/detail/" + params;
    },
    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },
    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
    fade(sType) {
      this.isLoading = sType;
    },
    success2(msg) {
      let pesan = "";
      msg.forEach((item) => {
        pesan = pesan + "<p>* " + item + "</p>";
      });

      this.$swal({
        icon: "info",
        backdrop: true,
        title: "Information",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    this.fetchIt();
    this.loading();
  },
};
</script>
  <style scoped>
</style>