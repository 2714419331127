<template>
  <div
    ref="modal"
    class="modal fade"
    :class="{ show: active, 'd-block': active }"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Form Turn Off Trip Alert</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            v-on:click="$emit('closeModal')"
            aria-label="btn-close"
          ></button>
        </div>

        <div class="modal-body">
        <form class="forms-sample" @submit.prevent="submitData" method="POST">
          <div
            class="d-flex justify-content-between align-items-baseline mb-2"
          ></div>

          <div class="row mb-3">
            <div class="col-lg-4">
              <label
                for="defaultconfig"
                class="col-form-label"
                style="padding-left: 25px"
                >Vehicle No</label
              >
            </div>
            <div class="col-lg-8">
              <input
                type="text"
                class="form-control"
                id="description"
                v-model="forms.vehicle_no"
                readonly
              />
              <div v-if="errors.vehicle_no">
                <div
                  v-for="error in errors.vehicle_no"
                  :key="error"
                  class="alert alert-primary"
                  role="alert"
                >
                  <i data-feather="alert-circle"></i>
                  {{ error }}
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-4">
              <label
                for="defaultconfig"
                class="col-form-label"
                style="padding-left: 25px"
                >Alert Time</label
              >
            </div>
            <div class="col-lg-8">
              <input
                type="text"
                class="form-control"
                id="description"
                v-model="forms.alert_time"
                readonly
              />
              <div v-if="errors.alert_time">
                <div
                  v-for="error in errors.alert_time"
                  :key="error"
                  class="alert alert-primary"
                  role="alert"
                >
                  <i data-feather="alert-circle"></i>
                  {{ error }}
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-4">
              <label
                for="defaultconfig"
                class="col-form-label"
                style="padding-left: 25px"
                >Alert Type</label
              >
            </div>
            <div class="col-lg-8">
              <input
                type="text"
                class="form-control"
                id="description"
                v-model="forms.alert_type"
                readonly
              />
              <div v-if="errors.alert_type">
                <div
                  v-for="error in errors.alert_type"
                  :key="error"
                  class="alert alert-primary"
                  role="alert"
                >
                  <i data-feather="alert-circle"></i>
                  {{ error }}
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-4">
              <label
                for="defaultconfig"
                class="col-form-label"
                style="padding-left: 25px"
                >Alert Value</label
              >
            </div>
            <div class="col-lg-8">
              <input
                type="text"
                class="form-control"
                id="description"
                v-model="forms.alert_value"
                readonly
              />
              <div v-if="errors.alert_value">
                <div
                  v-for="error in errors.alert_value"
                  :key="error"
                  class="alert alert-primary"
                  role="alert"
                >
                  <i data-feather="alert-circle"></i>
                  {{ error }}
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-4">
              <label
                for="defaultconfig"
                class="col-form-label"
                style="padding-left: 25px"
                >Alert Sub Type</label
              >
            </div>
            <div class="col-lg-8">
              <!--v-select
                            :options="['delay','speed', 'DWH','harshBreaking','harshCornering','harshAcceleration','curvew']"
                            v-model="forms.alert_subtype"
                            label="alert_subtype"
                            return-object
                          ></v-select-->
              <input
                type="text"
                class="form-control"
                id="description"
                v-model="forms.alert_subtype"
                readonly
              >
              <div v-if="errors.alert_subtype">
                <div
                  v-for="error in errors.alert_subtype"
                  :key="error"
                  class="alert alert-primary"
                  role="alert"
                >
                  <i data-feather="alert-circle"></i>
                  {{ error }}
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-4">
              <label
                for="defaultconfig"
                class="col-form-label"
                style="padding-left: 25px"
                >Off Alert Reason</label
              >
            </div>
            <div class="col-lg-8">
              <textarea v-model="forms.alert_off_reason" class="form-control">
              </textarea>
              <div v-if="errors.alert_off_reason">
                <div
                  v-for="error in errors.alert_off_reason"
                  :key="error"
                  class="alert alert-primary"
                  role="alert"
                >
                  <i data-feather="alert-circle"></i>
                  {{ error }}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              v-on:click="$emit('closeModal')"
            >
              {{ $t("close_txt") }}
            </button>
            <button class="btn btn-primary" type="submit">
              {{ $t("save_change_txt") }}
            </button>
          </div>
        </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "ModalTurnOffTripAlert",
  emits: ["closeModal"],
  props: {
    showModal: Boolean,
    data: null,
  },
  watch: {
    showModal: {
      handler(newVal) {
        
        this.forms = {
            alert_id:this.data.alert_id,
            alert_setting_id:this.data.alert_setting_id,
            vehicle_no: this.data.vehicle_no,
            alert_time: this.data.alert_time,
            alert_type: this.data.alert_type,
            alert_value: this.data.alert_value,
            alert_subtype: this.data.alert_subtype,
            alert_off_reason: this.data.alert_off_reason,
          };
       
        this.active = newVal;
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      active: this.showModal,
      forms: [],
      errors: [],
      locale: [],
      report_date: [],
      pickup_date: "",
      delivery_date: "",
      type_error: [],
      
    };
  },
  methods: {
    resetForm() {
      this.forms = [];
    },

    submitData() {
      this.forms.alert_status = 0;
      const baseURI =
        this.$settings.endPointCt + "alert/update/" + this.forms.alert_id;
      this.$http
        .patch(baseURI, this.forms)
        .then((response) => {
          
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];

            this.loadSuccess(response.data.datas.messages);
            this.$emit("closeModal");
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
        
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    // loadDetail(data) {
    //   console.log(data);
    // },

    resultError(data) {
      var html = "";
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          html += objectData[y] + "<br>";
        }
      }

      this.loadError(html);
    },

    loadError(pesan) {
      this.$swal({
        backdrop: true,
        title: "Warning",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },

    loadSuccess(pesan) {
      this.$swal({
        backdrop: true,
        title: "Success",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },
  },
  mounted() {
    //tail(this.data);
  },
};
</script>

<style scoped>
.modal {
  background: rgba(0, 0, 0, 0.4);
}
.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left:0px !important;
}
</style>