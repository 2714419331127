<template>
  <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("reject_order_txt") }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
            aria-label="btn-close"></button>
        </div>

        <form class="forms-sample" method="POST" @submit.prevent="submitData">
          <div class="modal-body">


            <div class="mb-3">
              <label class="form-label">{{
                $t("reject_description_txt")
              }}</label>


              <v-select :options="fetchSystemCode" @input="getRejectGroup" :searchable="false" :placeholder="$t('reject_description_txt')"
                v-model="reject_reason" :required="!reject_reason" label="value"></v-select>


              <div v-if="errors.reject_reason">
                <div v-for="error in errors.reject_reason" :key="error" class="alert alert-primary" role="alert">
                  <i data-feather="alert-circle"></i>
                  {{ error }}
                </div>
              </div>
            </div>

            <div v-if="this.reject_reason == '' || this.reject_reason == 'Reject by customer'" class="mb-3">
              <!-- <label class="form-label">Notes:</label>

              <textarea class="form-control" v-model="reject_description" placeholder="">
              </textarea>
              <div v-if="errors.reject_description">
                <div v-for="error in errors.reject_description" :key="error" class="alert alert-primary" role="alert">
                  <i data-feather="alert-circle"></i>
                  {{ error }}
                </div>
              </div> -->
            </div>
            <div v-else class="mb-3">
              <label class="form-label">Reject Group</label>


              <v-select :options="fetchRejectGroup" :searchable="false" :placeholder="'Reject Group'"
                v-model="reject_group" label="value"></v-select>


              <div v-if="errors.reject_group">
                <div v-for="error in errors.reject_group" :key="error" class="alert alert-primary" role="alert">
                  <i data-feather="alert-circle"></i>
                  {{ error }}
                </div>
              </div>
            </div>




          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
              {{ $t("close_txt") }}
            </button>
            <button class="btn btn-primary" type="submit">{{ $t("save_change_txt") }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  components: {
  },
  name: "ModalTransportOrderCtRejectMass",
  emits: ["closeModal"],
  props: {
    showModal: Boolean,
    data: {},
  },
  watch: {
    showModal: {
      handler(newVal) {
        this.reject_description = "";
        this.reject_reason = "";
        this.reject_group="";  
        this.getSystemCode();
        this.active = newVal;
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      fetchSystemCode: [],
      fetchRejectGroup: [],
      reject_description: "",
      reject_reason: "",
      reject_group:"",  
      isLoading: false,
      active: this.showModal,
      forms: [],
      errors: [],
      locale: [],
      report_date: [],
      
      type_error: [],
      rules: {
        select: [(v) => !!v || "Item is required"]
      }

    };
  },
  methods: {
    resultError(data) {
      var html = "";
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          html += objectData[y] + "<br>";
        }
      }

      this.loadError(html);
    },

    loadError(pesan) {
      this.$swal({
        backdrop: true,
        title: "Warning",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },

    loadSuccess(pesan) {
      this.$swal({
        backdrop: true,
        title: "Success",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },


    success2(msg) {

      this.$swal({
        icon: 'info',
        backdrop: true,
        title: "Information",
        html: "<p>* " + msg + "</p>",
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      })


    },


    loadingSubmiting() {


      this.$swal({
        icon: 'warning',
        backdrop: true,
        title: "Please wait",
        html: '<h2>SUBMITTING ORDER...</h2>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

    },

    submitData() {

      this.loadingSubmiting();
      if (this.reject_description == "" && this.reject_reason == "Reject by customer") {
        this.errors = { reject_description: ["notes is required"] };
        this.resultError(this.errors);
      }else if(this.reject_reason == ""){
        this.errors = { reject_reason: ["reject description is required"] };
        this.resultError(this.errors);
      } else if (this.reject_group == "" && this.reject_reason == "Reject Internal"){
        this.errors = { reject_group: ["reject group is required"] };
        this.resultError(this.errors);
      }else {
        
        var forms = { reject_description: this.reject_description ? this.reject_description : this.reject_group, 
                      reject_reason: this.reject_reason, 
                      transport_order_header_ids: this.data }
        const baseURI = this.$settings.endPoint + "control-tower/transport-order-header/mass-reject";
        this.$http
          .post(baseURI, forms, { timeout: 30000 })
          .then((response) => {
            this.isLoading = false;
            this.errors = [];
            this.$emit("closeModal");
            this.success2(response.data.datas.messages);
          })
          .catch(error => {
            this.isLoading = false;
            if (error.code == "ECONNABORTED") {
              this.error(this.$t("check_internet_connection_txt"));
            } else {

              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push('/server-error');
                } else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
                } else {
                  this.$router.push('/page-not-found');
                }
              }

            }

          });

      }

    },

    getSystemCode() {
      // const baseURI = this.$settings.endPoint + "system-code/select?system_code=ORDER_CANCEL_REASON";
      const baseURI = this.$settings.endPointCt + "master-lookup?unique_id=rejec";

      return this.$http
        .get(
          baseURI,
          { timeout: 10000 }
        )
        .then((response) => {
          var rows = response.data.datas;
          this.fetchSystemCode = rows;


        })
        .catch(error => {

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    },
    getRejectGroup(){
      if(this.reject_reason !== 'Reject by customer'){
        const baseURI = this.$settings.endPointCt + "master-lookup/dependent?unique_id=rejec&parent="+this.reject_reason;
        this.reject_description = null;
        return this.$http
          .get(
            baseURI,
            { timeout: 10000 }
          )
          .then((response) => {
            var rows = response.data.datas;
            this.fetchRejectGroup = rows;
          })
          .catch(error => {

            if (error.code == "ECONNABORTED") {
              this.error(this.$t("check_internet_connection_txt"));
            } else {

              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push('/server-error');
                } else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
                } else {
                  this.$router.push('/page-not-found');
                }
              }

            }

          });
      }else{
        this.reject_group = null;
      }
    },
    asyncSearchSystemCode(ev) {

      const baseURI = this.$settings.endPoint + "system-code/select?system_code=ORDER_CANCEL_REASON&value=" + ev;

      return this.$http
        .get(
          baseURI,
          { timeout: 10000 }
        )
        .then((response) => {
          var rows = response.data.datas.data;
          this.fetchSystemCode = rows;


        }).catch(error => {

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    }

  },
  mounted() {

  },
};
</script>

<style scoped>
.blur-content {
  filter: blur(5px);
  pointer-events: none;
}

.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left:0px !important;
}
</style>