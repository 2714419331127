import Rasio from "@/components/master/rasio/Rasio.vue";
import RasioAdd from "@/components/master/rasio/RasioAdd.vue";
import RasioDetail from "@/components/master/rasio/RasioDetail.vue";
import RasioEdit from "@/components/master/rasio/RasioEdit.vue";
import RasioUpload from "@/components/master/rasio/RasioUpload.vue";


let routesRasio= [
    
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "RasioIndex",
        component: Rasio
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "RasioAdd",
        component: RasioAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "RasioEdit",
        component: RasioEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "RasioDetail",
        component: RasioDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "RasioUpload",
        component: RasioUpload
      },
  ]
  
  export default routesRasio
  