<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>   
    <div class="loadertext" v-if="this.isLoading"> {{$t('loadingTxt')}}...</div>   
    <div class="main-wrapper" :class="{'blur-content': this.isLoading}">
      <!--begin::sidebar-->
      <sidebar-component classMenu="FakturPajakDetail"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            ">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left: 25px">
                    {{ $t("txtUserMappingDetail") }}
                  </h6>

                  <button class="btn btn-light float-end" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    <i class="link-icon" data-feather="info"></i>
                    {{ $t("audit_info") }}
                  </button>

                  <div class="row mb-3 collapse" id="collapseExample" style="padding-left: 20px">
                   
                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0"> {{ $t("create_by_txt") }}:</label>
                      <p class="text-muted">{{ this.forms.create_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">{{ $t("create_at_txt") }}:</label>
                      <p class="text-muted">{{ this.forms.created_at }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">{{ $t("update_by_txt") }}:</label>
                      <p class="text-muted">{{ this.forms.update_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">{{ $t("update_at_txt") }}:</label>
                      <p class="text-muted">{{ this.forms.updated_at }}</p>
                    </div>
                  </div>
                </div>

                <div class="card-body">
              

                  <form class="forms-sample" @submit.prevent="submitData" method="POST">
       

                    <div class="row mb-3">

                      <div class="col-lg-6">

                        <h6 class="card-title">{{$t('txtUsers')}}</h6>
 
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('usernameTxt')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" readonly class="form-control" id="username" :placeholder="$t('usernameTxt')" v-model="forms.username" />
                          </div>
                        </div>
 
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('nameTxt')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" readonly class="form-control" id="name" :placeholder="$t('nameTxt')" v-model="forms.name" />
                          </div>
                        </div>
 
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('emailAddress')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" readonly class="form-control" id="email" :placeholder="$t('emailAddress')" v-model="forms.email" />
                          </div>
                        </div>
 
                        <h6 class="card-title">{{$t('branchTxt')}}</h6>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('branch_name')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" readonly class="form-control" id="branch_name" :placeholder="$t('branch_name')" v-model="forms.branch_name" />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('branch_province')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" readonly class="form-control" id="branch_province" :placeholder="$t('branch_province')" v-model="forms.branch_province" />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('branch_city')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" readonly class="form-control" id="branch_city" :placeholder="$t('branch_city')" v-model="forms.branch_city" />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('branch_region')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" readonly class="form-control" id="branch_region" :placeholder="$t('branch_region')" v-model="forms.branch_region" />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('branch_address')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" readonly class="form-control" id="branch_address" :placeholder="$t('branch_address')" v-model="forms.branch_address" />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('branch_poscode')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" readonly class="form-control" id="branch_poscode" :placeholder="$t('branch_poscode')" v-model="forms.branch_poscode" />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('branch_phone')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" readonly class="form-control" id="branch_phone" :placeholder="$t('branch_phone')" v-model="forms.branch_phone" />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('branch_phone2')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" readonly class="form-control" id="branch_phone2" :placeholder="$t('branch_phone2')" v-model="forms.branch_phone2" />
                          </div>
                        </div>



                      </div>

                      <div class="col-lg-6">
  
 
                        <h6 class="card-title">{{$t('pool_txt')}}</h6>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('pool_name')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" readonly class="form-control" id="pool_name" :placeholder="$t('pool_name')" v-model="forms.pool_name" />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('pool_province')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" readonly class="form-control" id="pool_province" :placeholder="$t('pool_province')" v-model="forms.pool_province" />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('pool_city')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" readonly class="form-control" id="pool_city" :placeholder="$t('pool_city')" v-model="forms.pool_city" />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('pool_address')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" readonly class="form-control" id="pool_address" :placeholder="$t('pool_address')" v-model="forms.pool_address" />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('pool_postalcode')}}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" readonly class="form-control" id="pool_postalcode" :placeholder="$t('pool_postalcode')" v-model="forms.pool_postalcode" />
                          </div>
                        </div>


 
                      </div>

                    </div>

                    <div class="card-footer">
                      <button class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        " @click="backForm" type="button">
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>

                      <a v-if="permision_role.method_delete" class="
                          btn btn-danger
                          text-white
                          fw-bolder
                          btn-icon-text
                          float-end
                        " @click="deleteData()">
                        <i class="link-icon" data-feather="trash"></i>
                        {{ $t("deleteMess") }}
                      </a>

                      <a v-if="permision_role.method_update" class="
                          btn btn-primary
                          me-2
                          text-white
                          fw-bolder
                          btn-icon-text
                          float-end
                        " @click="editData()">
                        <i class="link-icon" data-feather="edit"></i>
                        {{ $t("editMess") }}
                      </a>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
 

export default {
    name: "FakturPajakDetail",
    props: {},
    components: {
        "sidebar-component": sidebarComponent,
        "navbar-component": navbarComponent,
        "footer-component": footerComponent,
    
    },
    data() {
        return {
        permision_role: [],
        isLoading: false,
        maxToasts: 100,
        position: "up right",
        closeBtn: true,
        errors: [],
        userData: "",
        companyCode: "",
        forms: []
        };
    },
    watch: {},
    methods: {
        
 

        cek_message(status, msg) {
            if (status == "200") {
                this.success(msg);
            }
        },

        editData() {
            var params = this.$route.params.id;
            window.location.href = "/gt/user-mapping/edit/" + params;
        },

        deleteData() {
        var id = this.$onBehind(this.$route.params.id);
        this.$swal({
            title: this.$t("areYouSure"),
            text: this.$t("yourDataDelete"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!",
        }).then((result) => {
            if (result.value) {
            var formData = {
                status: status,
            };
            const baseURI = this.$settings.endPointGT + "user-gate-system/delete/" + id;
            this.$http
                .delete(baseURI, formData)
                .then((response) => {
                this.loading();
                if (response.data.status === 200) {
                    this.success(response.data.datas.messages);
                    window.location.href = "/gt/user-mapping/";
                } else {
                    this.errors = response.data.errors;
                    this.resultError(response.data.errors);
                }
                })
                .catch((error) => {
                this.loading();
                if (error.response) {
                    if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                    } else if (error.response.status === 500) {
                    this.error(error.response.data.errors.message);
                    } else {
                    this.$router.push("/page-not-found");
                    }
                }
                });
            }
        });
        },



        backForm() {
        window.location.href = "/gt/user-mapping";
        },

        loadData(id) {
        
            this.isLoading = true;
            var baseURI = this.$settings.endPointGT + "user-gate-system/detail/" + id;
    
            return this.$http.get(baseURI,{ timeout: 10000 }).then((response) => {

            this.forms = response.data.datas;
            this.isLoading = false;

            }).catch(error => {
                this.isLoading = false;
                if(error.code == "ECONNABORTED"){
                    this.error(this.$t("check_internet_connection_txt"));
                }else{

                    if (error.response) {
                    if(error.response.status === 422) {
                            this.errors = error.response.data.errors;
                            this.resultError(error.response.data.errors);
                    }else if (error.response.status === 500) {
                        this.$router.push('/server-error');
                    }else if (error.response.status === 401) {
                        this.$router.push('/authorized-error');
                    }else{
                        this.$router.push('/page-not-found');
                    }
                    }

                }

            });
        },


        fetchIt() {
            const userDatas = this.$getUserInfo();
            this.detailUser = userDatas.sub;
            // this.permision_role = userDatas.sub.permision_role;
            let rolePermision = [];
            userDatas.sub.permision_role.forEach(function (permision) {
                if (permision.permission.controller == 'UserGateSystemController') {
                rolePermision = permision;
                // console.log(permision);
                }
            });

            if (!rolePermision.method_read) {
                this.$router.push("/authorized-error");
            }

            this.permision_role = rolePermision;

        },


        fade(sType) {
        this.isLoading = sType;
        },

        loading() {
        this.fade(true);
        setTimeout(() => {
            this.fade(false);
        }, 1000); // hide the message after 3 seconds
        },

        resultError(data) {
        var count = Object.keys(data).length;
        for (var x = 0; x < count; x++) {
            var nameOb = Object.keys(data)[x];
            var objectData = data[nameOb];
            for (var y = 0; y < objectData.length; y++) {
            this.error(objectData[y]);
            }
        }
        },

        success(kata) {
        const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
            // onShown: function() {
            //     console.log('asdasdasd');
            //     //window.location.href = '/';
            // }
        });

        Toast.fire({
            icon: "success",
            title: kata,
        });
        },

        error(kata) {
        const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
        });
        Toast.fire({
            icon: "error",
            title: kata,
        });
        },
    },
    events: {},
    created: function () { },
    mounted() {
        var params = this.$onBehind(this.$route.params.id);
        this.cek_message(this.$route.query.status, this.$route.query.msg);
        this.loadData(params[0]);
        this.fetchIt();
    },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}
.blur-content{
  filter: blur(5px); 
}
</style>