<template>
    <div>
			<!-- partial:partials/_footer.html -->
			<footer class="footer d-flex flex-column flex-md-row align-items-center justify-content-between px-4 py-3 border-top small">
				<p class="text-muted mb-1 mb-md-0">Copyright © 2022 <a href="https://www.lincgrp.com/" target="_blank">LincGroup</a>.</p>
				<p class="text-muted">Development With <i class="mb-1 text-primary ms-1 icon-sm" data-feather="heart"></i></p>
			</footer>
			<!-- partial -->

    </div>
</template>


<script>

export default {
  name: 'footerMenu',
  props: {
  },
  components: {
  },
  data () {
    return {  
        
    }
  },
    watch: { 

    },
    methods: { 	
      
       

    },
    events: {

    },
    created: function() { 
    
    },
	mounted() {
        
        
    }

}
</script>
<style scoped>

</style>