<template>
    <div>
      <div class="main-wrapper">
          <div class="loader" v-if="this.isLoading"></div>   
          <div class="loadertext" v-if="this.isLoading"> {{$t('loadingTxt')}}...</div>   
          <sidebar-component classMenu="BranchIndex"></sidebar-component>
              <div class="page-wrapper">
                  <navbar-component></navbar-component>
                      <div class="page-content">
                        <form @submit.prevent="submitData" method="POST">
                                        <div
                                        class="
                                            d-flex
                                            justify-content-between
                                            align-items-baseline
                                            mb-2
                                        "
                                        ></div>

                                        <div class="row mb-3">
                                        <div class="col-lg-2">
                                            <label
                                            for="defaultconfig"
                                            class="col-form-label"
                                            style="padding-left: 25px"
                                            >{{ $t("skuCodeTxt") }}</label
                                            >
                                        </div>
                                        <div class="col-lg-4">
                                            <input
                                            type="text"
                                            class="form-control"
                                            id="sku_code"
                                            :placeholder="$t('skuCodeTxt')"
                                            v-model="forms.sku_code"
                                            required
                                            />
                                            <div v-if="errors.sku_code">
                                            <div
                                                v-for="error in errors.sku_code"
                                                :key="error"
                                                class="alert alert-primary"
                                                role="alert"
                                            >
                                                <i data-feather="alert-circle"></i>
                                                {{ error }}
                                            </div>
                                            </div>
                                        </div>
                                        </div>

                                        <div class="row mb-3">
                                        <div class="col-lg-2">
                                            <label
                                            for="defaultconfig"
                                            class="col-form-label"
                                            style="padding-left: 25px"
                                            >{{ $t("skuDescriptionTxt") }}</label
                                            >
                                        </div>
                                        <div class="col-lg-8">
                                            <input
                                            type="text"
                                            class="form-control"
                                            id="sku_description"
                                            :placeholder="$t('skuDescriptionTxt')"
                                            v-model="forms.sku_description"
                                            />
                                            <div v-if="errors.sku_description">
                                            <div
                                                v-for="error in errors.sku_description"
                                                :key="error"
                                                class="alert alert-primary"
                                                role="alert"
                                            >
                                                <i data-feather="alert-circle"></i>
                                                {{ error }}
                                            </div>
                                            </div>
                                        </div>
                                        </div>

                                        <div class="row mb-3">
                                        <div class="col-lg-2">
                                            <label
                                            for="defaultconfig"
                                            class="col-form-label"
                                            style="padding-left: 25px"
                                            >{{ $t("skuLongDescriptionTxt") }}</label
                                            >
                                        </div>
                                        <div class="col-lg-8">
                                            <input
                                            type="text"
                                            class="form-control"
                                            id="sku_long_description"
                                            :placeholder="$t('skuLongDescriptionTxt')"
                                            v-model="forms.sku_long_description"
                                            />
                                            <div v-if="errors.sku_long_description">
                                            <div
                                                v-for="error in errors.sku_long_description"
                                                :key="error"
                                                class="alert alert-primary"
                                                role="alert"
                                            >
                                                <i data-feather="alert-circle"></i>
                                                {{ error }}
                                            </div>
                                            </div>
                                        </div>
                                        </div>

                                        <div class="row mb-3">
                                        <div class="col-lg-2">
                                            <label
                                            for="defaultconfig"
                                            class="col-form-label"
                                            style="padding-left: 25px"
                                            >Barcode</label
                                            >
                                        </div>
                                        <div class="col-lg-8">
                                            <input
                                            type="text"
                                            class="form-control"
                                            id="barcode"
                                            placeholder="Barcode"
                                            v-model="forms.barcode"
                                            />
                                            <div v-if="errors.barcode">
                                            <div
                                                v-for="error in errors.barcode"
                                                :key="error"
                                                class="alert alert-primary"
                                                role="alert"
                                            >
                                                <i data-feather="alert-circle"></i>
                                                {{ error }}
                                            </div>
                                            </div>
                                        </div>
                                        </div>

                                        <div class="row mb-3">
                                        <div class="col-lg-2">
                                            <label
                                            for="defaultconfig"
                                            class="col-form-label"
                                            style="padding-left: 25px"
                                            >{{ $t("companyCode") }}</label
                                            >
                                        </div>
                                        <div class="col-lg-8">
                                            <v-select
                                            v-if="companyCode == 'LINC'"
                                            :options="fetchCompany"
                                            v-model="company"
                                            label="code"
                                            return-object
                                            @search="asyncSearchCompany"
                                            @input="companyChange"
                                            ></v-select>

                                            <v-select
                                            v-if="companyCode != 'LINC'"
                                            :options="fetchCompanyNotLinc"
                                            v-model="company"
                                            label="code"
                                            return-object
                                            @search="asyncSearchCompanyNotLinc"
                                            @input="companyChange"
                                            ></v-select>

                                            <div v-if="errors.company_id">
                                                <div
                                                    v-for="error in errors.company_id"
                                                    :key="error"
                                                    class="alert alert-primary"
                                                    role="alert"
                                                >
                                                    <i data-feather="alert-circle"></i>
                                                    {{ error }}
                                                </div>
                                            </div>
                                        </div>
                                        </div>

                                        <div class="row mb-3">
                                            <div class="col-lg-2">
                                                <label
                                                for="defaultconfig"
                                                class="col-form-label"
                                                style="padding-left: 25px"
                                                >{{ $t("packageName") }}</label
                                                >
                                            </div>
                                            <div class="col-lg-8">
                                                <!-- <select class="form-control" name="package_id" id="package_id" v-model="forms.package_id" >
                                                    <option  v-for="(a, index) of fetchPackage" :value="a.package_id" :key="index">{{a.package_code}} | {{a.package_name}}</option>
                                                </select> -->
                                                <v-select
                                                :options="fetchPackage"
                                                v-model="packageData"
                                                label="package_name"
                                                return-object
                                                @search="asyncSearchPackage"
                                                ></v-select>
                                                <div v-if="errors.package_id">
                                                <div
                                                    v-for="error in errors.package_id"
                                                    :key="error"
                                                    class="alert alert-primary"
                                                    role="alert"
                                                >
                                                    <i data-feather="alert-circle"></i>
                                                    {{ error }}
                                                </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-3">
                                            <div class="col-lg-2">
                                                <label
                                                for="defaultconfig"
                                                class="col-form-label"
                                                style="padding-left: 25px"
                                                >{{ $t("commodityTxt") }}</label
                                                >
                                            </div>
                                            <div class="col-lg-8">
                                                <!-- <select class="form-control" name="commodity_id" id="commodity_id" v-model="forms.commodity_id" >
                                                    <option  v-for="(a, index) of fetchCommodity" :value="a.commodity_id" :key="index">{{a.commodity_code}}</option>
                                                </select> -->
                                                <v-select
                                                :options="fetchCommodity"
                                                v-model="commodity"
                                                label="commodity_code"
                                                return-object
                                                @search="asyncSearchCommodity"
                                                ></v-select>

                                                <div v-if="errors.commodity_id">
                                                <div
                                                    v-for="error in errors.commodity_id"
                                                    :key="error"
                                                    class="alert alert-primary"
                                                    role="alert"
                                                >
                                                    <i data-feather="alert-circle"></i>
                                                    {{ error }}
                                                </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row mb-3">
                                            <div class="col-lg-2">
                                                <label
                                                for="defaultconfig"
                                                class="col-form-label"
                                                style="padding-left: 25px"
                                                >Stackable</label
                                                >
                                            </div>
                                            <div class="col-lg-4">
                                                <input class="form-check-input" type="radio" name="stackable" value="yes" v-model="forms.stackable"> Yes &nbsp;&nbsp;&nbsp; 
                                                <input class="form-check-input" type="radio" name="stackable" value="no" v-model="forms.stackable"> No
                                            </div>

                                            <div v-if="errors.commodity_id">
                                                <div
                                                    v-for="error in errors.commodity_id"
                                                    :key="error"
                                                    class="alert alert-primary"
                                                    role="alert"
                                                >
                                                    <i data-feather="alert-circle"></i>
                                                    {{ error }}
                                                </div>
                                            </div>

                                        </div>

                                        <div class="card-footer">
                                            <button
                                                class="
                                                btn btn-warning
                                                text-black
                                                fw-bolder
                                                btn-icon-text
                                                "
                                                @click="backForm"
                                                type="button"
                                            >
                                                <i class="link-icon" data-feather="arrow-left"></i>
                                                {{ $t("backMess") }}
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                            <button
                                                class="btn btn-primary float-end btn-sm"
                                                type="submit"
                                            >
                                                <i class="link-icon" data-feather="save"></i>
                                                {{ $t("submitFormTxt") }}
                                            </button>
                                        </div>
                                    </form>
                      </div>
                  <footer-component></footer-component>
              </div>
      </div>
    </div>
  </template>
  
  <script>
  import sidebarComponent from "@/components/_partials/_sidebar";
  import navbarComponent from "@/components/_partials/_navbar";
  import footerComponent from "@/components/_partials/_footer";
  export default {
      components: {
          "sidebar-component": sidebarComponent,
          "navbar-component": navbarComponent,
          "footer-component": footerComponent,
      },
      data(){
          return {
              isLoading:false,
                forms:[],
                errors:[],
                fetchCompany: [],
                fetchCompanyNotLinc: [],
                fetchPackage: [],
                fetchCommodity: [],
                permision_role: [],
                userData: "",
                companyCode: "",
                company: [],
                packageData: [],
                commodity: [],
          }
      },
      methods:{
        fetchIt() {
            const userDatas     = this.$getUserInfo();
            this.detailUser     = userDatas.sub;
            // this.permision_role = userDatas.sub.permision_role;
            let rolePermision = [];
            userDatas.sub.permision_role.forEach(function(permision){
                if(permision.permission.controller == 'SkuController'){
                rolePermision = permision;
                console.log(permision);
                }
            });

            if(!rolePermision.method_read){
                this.$router.push("/authorized-error");
            }
            this.permision_role = rolePermision;
        },
        loadData(id){
            const baseURI = this.$settings.endPoint + "sku/detail/" + id;
                return this.$http.get(baseURI).then((response) => {
                this.forms = response.data.datas;
                this.company = this.forms.company;
                this.packageData = this.forms.package;
                this.commodity = this.forms.commodity;
            });
        },
        loadCompany() {
            const baseURI = this.$settings.endPoint + "company?limit=10";

            return this.$http.get(baseURI).then((response) => {
                this.fetchCompany = response.data.datas.data;

                
            });
        },
        asyncSearchCompany(ev) {
            const baseURI = this.$settings.endPoint + "company?code=" + ev;

            return this.$http.get(baseURI).then((response) => {
                this.fetchCompany = response.data.datas.data;
            });
        },

        asyncSearchCompanyNotLinc(ev) {
            const baseURI =
                this.$settings.endPoint +
                "company/select?code="+ev;

            return this.$http.get(baseURI).then((response) => {
                this.fetchCompanyNotLinc = response.data.datas.data;
            });
        },
        loadCompanyNotLinc() {
            const baseURI =
                this.$settings.endPoint + `company/select`;

            return this.$http.get(baseURI).then((response) => {
                this.fetchCompanyNotLinc = response.data.datas.data;
            });
        },
        backForm(){
            window.location.href = "/sku-test"
        },
        loadPackage() {
        const baseURI = this.$settings.endPoint + "package?limit=10";

        return this.$http.get(baseURI).then((response) => {
            this.fetchPackage = response.data.datas.data;
        });
        },

        asyncSearchPackage(ev) {
        const baseURI = this.$settings.endPoint + "package?package_name=" + ev;

        return this.$http.get(baseURI).then((response) => {
            this.fetchPackage = response.data.datas.data;
        });
        },

        loadCommodity() {
        const baseURI = this.$settings.endPoint + "commodity?limit=10";
        return this.$http.get(baseURI).then((response) => {
            this.fetchCommodity = response.data.datas.data;
        });
        },

        asyncSearchCommodity(ev) {
        const baseURI =
            this.$settings.endPoint + "commodity?commodity_name=" + ev;
        return this.$http.get(baseURI).then((response) => {
            this.fetchCommodity = response.data.datas.data;
        });
        },

        companyChange(ev) {
        
        let company_code = ev.code;
        const baseURI =
            this.$settings.endPoint + "package/select-package/" + company_code;
        return this.$http
            .get(baseURI)
            .then((response) => {
            this.fetchPackage = response.data.datas;
            })
            .catch((error) => {
            if (error.response) {
                if (error.response.status === 422) {
                this.packageData = [];
                } else {
                this.packageData = [];
                }
            }
            });
        },
        fade(sType) {
        this.isLoading = sType;
        },

        submitData() {
        this.fade(true);
        this.forms.company_id = this.company.company_id;
        this.forms.package_id = this.packageData.package_id;
        this.forms.commodity_id = this.commodity.commodity_id;
        const baseURI =
            this.$settings.endPoint + "sku/update/" + this.forms.sku_id;
        this.$http
            .patch(baseURI, this.forms)
            .then((response) => {
            this.loading();
            if (response.data.status === 200) {
                //this.resetForm();
                this.errors = [];
                this.success(response.data.datas.messages);

                //window.history.back();
                var params = this.$onRandom(this.forms.sku_id);
                window.location.href =
                "/sku/detail/" + params + "?status=200&msg=Successfully";
            } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
            }
            })
            .catch((error) => {
            this.loading();
            if (error.response) {
                if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                this.$router.push("/server-error");
                } else {
                this.$router.push("/page-not-found");
                }
            }
            //this.resetForm();
            });
        },

        loading() {
        this.fade(true);
        setTimeout(() => {
            this.fade(false);
        }, 1000); // hide the message after 3 seconds
        },

    },
      mounted(){
        var params = this.$onBehind(this.$route.params.id);
        this.loadData(params[0]);
        this.loadCommodity();
        this.loadCompany();
        this.loadCompanyNotLinc();
        this.fetchIt();
      }
  }
  </script>
  
  <style>
</style>