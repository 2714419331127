<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading"> {{ $t('loadingTxt') }}...</div>
    <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
      <!--begin::sidebar-->
      <sidebar-component classMenu="InformasiBongkarAdd"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            ">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left:25px">Detail Unloading Information</h6>
                  <button
                    class="btn btn-light float-end"
                    type="button"
                    data-bs-toggle="collapse" 
                    data-bs-target="#collapseExample" 
                    aria-expanded="false" 
                    aria-controls="collapseExample"
                  >
                    <i class="link-icon" data-feather="info"></i>
                    Audit Info
                  </button>

                  <div class="row mb-3 collapse" id="collapseExample" style="padding-left:20px">
                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0 ">Created By:</label>
                      <p class="text-muted">{{this.forms.create_by}}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created At:</label>
                      <p class="text-muted">{{this.forms.created_at}}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated By:</label>
                      <p class="text-muted">{{this.forms.update_by}}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated At:</label>
                      <p class="text-muted">{{this.forms.updated_at}}</p>
                    </div>

                    
                    
                  </div>

                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form class="forms-sample" style="padding-left: 25px" @submit.prevent="submitData" method="POST">
                    <div class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "></div>

                    <div class="row mb-2">
                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Load ID<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-6">
                            <!-- <v-select :options="fetchLoadNumber" v-model="loadNumber" label="load_id" return-object
                              @input="getBlujay" @search="asyncSearchLoadNumber"></v-select> -->
                            <input type="text" id="load_number" name="load_number" v-model="forms.load_number"
                              class="form-control" placeholder="Load ID" readonly />


                            <div v-if="errors.load_number">
                              <div v-for="error in errors.load_number" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <button class="btn btn-success" disabled>Browse</button>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Tanggal Muat<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="tanggal_muat" name="tanggal_muat" v-model="forms.tanggal_muat"
                              class="form-control" placeholder="Tanggal Muat" readonly />
                            <div v-if="errors.tanggal_muat">
                              <div v-for="error in errors.tanggal_muat" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Shipment Number<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="shipment_number" name="shipment_number"
                              v-model="forms.shipment_number" class="form-control" placeholder="Shipment Number" readonly />
                            <div v-if="errors.shipment_number">
                              <div v-for="error in errors.shipment_number" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Tanggal Bongkar<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="tanggal_bongkar" name="tanggal_bongkar" v-model="forms.tanggal_bongkar"
                              class="form-control" placeholder="Tanggal Bongkar" readonly />
                            <div v-if="errors.tanggal_bongkar">
                              <div v-for="error in errors.tanggal_bongkar" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Shipment Date<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="shipment_date" name="shipment_date" v-model="forms.shipment_date"
                              class="form-control" placeholder="Shipment Date" readonly />
                            <div v-if="errors.shipment_date">
                              <div v-for="error in errors.shipment_date" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Provinsi Tujuan<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="provinsi_tujuan" name="provinsi_tujuan" v-model="forms.provinsi_tujuan"
                              class="form-control" placeholder="Provinsi Tujuan" readonly />                            
                            <div v-if="errors.provinsi_tujuan">
                              <div v-for="error in errors.provinsi_tujuan" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Vehicle No<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="vehicle_no" name="vehicle_no" v-model="forms.vehicle_no"
                              class="form-control" placeholder="Vehicle No" readonly />
                            <div v-if="errors.vehicle_no">
                              <div v-for="error in errors.vehicle_no" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Kota Tujuan<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="kota_tujuan" name="kota_tujuan" v-model="forms.kota_tujuan"
                              class="form-control" placeholder="KotaTujuan" readonly /> 
                            <div v-if="errors.kota_tujuan">
                              <div v-for="error in errors.kota_tujuan" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Driver NMK<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="driver_nmk" name="driver_nmk" v-model="forms.driver_nmk"
                              class="form-control" placeholder="Driver NMK" readonly />
                            <div v-if="errors.driver_nmk">
                              <div v-for="error in errors.driver_nmk" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Qty<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="number" id="qty" name="qty" v-model="forms.qty" class="form-control"
                              placeholder="Qty" readonly />
                            <div v-if="errors.qty">
                              <div v-for="error in errors.qty" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Driver Name<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="driver_name" name="driver_name" v-model="forms.driver_name"
                              class="form-control" placeholder="Driver Name" readonly />
                            <div v-if="errors.driver_name">
                              <div v-for="error in errors.driver_name" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Qty Return<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="number" id="qty_return" name="qty_return" v-model="forms.qty_return"
                              class="form-control" placeholder="Qty Return" readonly />
                            <div v-if="errors.qty_return">
                              <div v-for="error in errors.qty_return" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Driver HP<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="driver_hp" name="driver_hp" v-model="forms.driver_hp"
                              class="form-control" placeholder="Driver HP" readonly />
                            <div v-if="errors.driver_hp">
                              <div v-for="error in errors.driver_hp" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Sub Customer<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="sub_customer" name="sub_customer" v-model="forms.sub_customer"
                              class="form-control" placeholder="Sub Customer" readonly />

                            <div v-if="errors.sub_customer">
                              <div v-for="error in errors.sub_customer" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Route<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="route" name="route" v-model="forms.route" class="form-control"
                              placeholder="Route" readonly />
                            <div v-if="errors.route">
                              <div v-for="error in errors.route" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">Description<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <textarea id="description" readonly="true" name="description" v-model="forms.description"
                              class="form-control" placeholder="Description" rows="2" />
                            <div v-if="errors.description">
                              <div v-for="error in errors.description" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="row mb-2">
                          <div class="col-md-4">
                            <label class="form-label">SKU<span class="wajib">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="sku" name="sku" v-model="forms.sku" class="form-control"
                              placeholder="SKU" readonly/>
                            <div v-if="errors.sku">
                              <div v-for="error in errors.sku" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="card-footer">
                      <button
                        class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        "
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>

                      <a
                        v-if="permision_role.method_delete"
                        class="
                          btn btn-danger
                          text-white
                          fw-bolder
                          btn-icon-text
                          float-end
                        "
                        @click="deleteData()"
                      >
                        <i class="link-icon" data-feather="trash"></i>
                        {{ $t("deleteMess") }}
                      </a>

                      <a
                        v-if="permision_role.method_update"
                        class="
                          btn btn-primary
                          me-2
                          text-white
                          fw-bolder
                          btn-icon-text
                          float-end
                        "
                        @click="editData()"
                      >
                        <i class="link-icon" data-feather="edit"></i>
                        {{ $t("editMess") }}
                      </a>
                    </div>

                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->
        

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";

export default {
  name: "UnloadingInformationDetail",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      poolAll:[],
      permision_role:[],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      forms: [],
    };
  },
  watch: {},
  methods: {
      isNumberLatLong(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 45) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    resetForm() {

    },

    cek_message(status, msg) {
      if (status == "200") {
        this.success(msg);
      }
    },

    editData() {
      var params = this.$route.params.id;
      window.location.href = "/informasiBongkar/edit/" + params;
    },

    deleteData() {
      var id = this.$onBehind(this.$route.params.id);
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI = this.$settings.endPointCt + "informasi-bongkar/delete/" + id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                window.location.href = "/informasiBongkar";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },



    backForm() {
      window.location.href = "/informasiBongkar";
    },

    loadData(id) {
      this.isLoading = true
      const baseURI = this.$settings.endPointCt + "informasi-bongkar/detail/" + id;
      return this.$http.get(baseURI,{ timeout: 10000 }).then((response) => {
           this.isLoading = false
        
          this.forms    = response.data.datas;
          this.poolAll  = response.data.datas.pools;
      }).catch(error => {
          this.isLoading = false;
          if(error.code == "ECONNABORTED"){
            this.error("Please Check Your Internet Connection");
          }else{

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
            
          }


        });
    },


    fetchIt() {
      const userDatas     = this.$getUserInfo();
      this.detailUser     = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function(permision){
        if(permision.permission.controller == 'InformasiBongkarController'){
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if(!rolePermision.method_read){
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;

    },


    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.cek_message(this.$route.query.status, this.$route.query.msg);
    this.loadData(params[0]);
    this.fetchIt();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content{
  filter: blur(5px); 
  pointer-events: none;
}
</style>