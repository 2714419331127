<template>
  <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title badge bg-primary">Blujay Order</h5>

          <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal',null)"
            aria-label="btn-close"></button>
        </div>

        
          <div class="modal-body">
            <vue-good-table title="list-user" mode="remote" @on-page-change="onPageChange"
              @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange"
              :totalRows="totalRecords" :line-numbers="true" :isLoading.sync="isLoading" :pagination-options="{
                enabled: true,
              }" :rows="rows" :columns="columns">

              <template slot="table-row" slot-scope="props">

                <span v-if="props.column.field == 'actions'">
                  <a href="#"                   
                    title="Insert" @click.prevent="submitParent(props.index, props.row)">
                    <i class="mdi mdi-check" style="font-size:16px"></i>

                  </a>
                </span>
              </template>

            </vue-good-table>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal',null)">
              {{ $t("close_txt") }}
            </button>
          </div>
       
      </div>
    </div>
  </div>
</template>

<script>


export default {
  components: {
  },
  name: "ModalUnloading",
  emits: ["closeModal"],
  props: {
    showModal: Boolean,
    data: {},
  },
  watch: {
    showModal: {
      handler(newVal) {
        this.active = newVal;
        this.loadItems();
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");

      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      active: this.showModal,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [
        {
          label: "Load ID",
          field: "load_id",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Load ID", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Shipment Number",
          field: "shipment_number",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Shipment Number", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Route",
          field: "name_rute",
          filterOptions: {
            enabled: false, // enable filter for this column
          },
        },
        {
          label: "Vehicle No",
          field: "vehicle_no",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Vehicle No", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },      
        {
          label: "Vehicle Status",
          field: "vehicle_status",
          filterOptions: {
            enabled: false, // enable filter for this column
          },
        }, 
        {
          label: "Driver",
          field: "driver_name",
          filterOptions: {
            enabled: false, // enable filter for this column
          },
        }, 
        {
          label: "Driver Status",
          field: "driver_status",
          filterOptions: {
            enabled: false, // enable filter for this column
          },
        }, 
        {
          label: "Location",
          field: "location",
          filterOptions: {
            enabled: false, // enable filter for this column
          },
        }, 


        {
          label: "Action",
          field: "actions",
          sortable: false,
        },
      ],
      rows: [],
      rowsSelection: [],
      locale: [],
      rules: {
        select: [(v) => !!v || "Item is required"]
      }

    };
  },
  methods: {
    submitParent(index, row){
      this.$emit("closeModal", row);   
    },

    removeOrderHeaderIds() {
      this.transport_order_header_ids = [];
      this.$refs['list-transport-orders'].unselectAllInternal()
      // console.log(this.$refs['list-transport-orders'].selectedRows)
    },

    selectionChanged(ev) {
      this.rowsSelection = [];
      this.transport_order_header_ids = [];
      this.rowsSelection = ev.selectedRows;

      this.rowsSelection.forEach((item) => {
        this.transport_order_header_ids.push(item.transport_order_header_id);

      });

    },

    loadItems() {
      const baseURI = this.$settings.endPointCt + "blujay-order/unloading";
      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}`+
          `&page=${this.serverParams.page}`+
          `&sort_field=${this.serverParams.sort.field}`+
          `&sort_type=${this.serverParams.sort.type}`+
          `&load_id=${this.serverParams.columnFilters.load_id}`+
          `&vehicle_no=${this.serverParams.columnFilters.vehicle_no}`+
          `&shipment_number=${this.serverParams.columnFilters.shipment_number}`
        )
        .then((response) => {

          this.rows = response.data.datas.data;
          let i = 0;
          for (i = 0; i < 10; i++) {
            if (this.rows[i].order_status !== "NEW") {
              this.$set(this.rows[i], 'vgtDisabled', true);
              this.$set(this.rows[i], 'vgtSelected', false);
            }
          }
          this.totalRecords = response.data.datas.total;

        }).catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push('/authorized-error');
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

        onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },
  },
  mounted() {
    //this.loadItems();
    // this.rows = this.props.data;

  },
};
</script>

<style scoped>
.blur-content {
  filter: blur(5px);
  pointer-events: none;
}

.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 120%;
  height: 100%;
  margin-left: -10%;
}
</style>