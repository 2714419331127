<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TemplateOrderAdd"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->
        <div class="page-content">
          <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
            <div class="card">
              <div class="card-header">
                &nbsp;&nbsp;&nbsp;
                <h6
                  class="card-title mb-0 float-start"
                  style="padding-left: 25px"
                >
                  Detail - Billable SL (Customer) 
                </h6>
                <div v-if="isLoading" class="d-flex align-items-center">
                  <div
                    class="spinner-border ms-auto"
                    role="status"
                    aria-hidden="true"
                  ></div>
                  <strong>{{ $t("loadingTxt") }}...</strong>
                </div>
              </div>
              <div class="card-body">
                <div
                  class="d-flex justify-content-between align-items-baseline mb-2"
                ></div>
                <div class="row">
                  <div class="col-4">
                    <table>
                      <tr>
                        <td>Kode Customer</td>
                        <td>:</td>
                        <td>{{ this.forms?.customer_code }}</td>
                      </tr>
                      <tr>
                        <td>Nama Customer</td>
                        <td>:</td>
                        <td>{{ this.forms?.customer_name }}</td>
                      </tr>
                      <td>SAP Code</td>
                      <td>:</td>
                      <td>{{ this.forms?.sap_code }}</td>
                    </table>
                  </div>
                </div>

                <br />
                <div class="row">
                  <div class="col-lg-2">
                    <div class="mb-3">
                      <label class="form-label">Billable Tarif</label>
                      <!-- <input type="text" class="form-control" /> -->
                      <!-- <v-select
                        :options="fetchBillableTariff"
                        v-model="billableTariff"
                        label="tariff_name"
                      >
                      </v-select> -->

                      <multiselect
                        @search-change="asyncSearchBillabkeTarif"
                        v-model="billableTariff"
                        name="Billabel Tarif"
                        :options="fetchBillableTariff"
                        placeholder="Please Select"
                        track-by="tariff_name"
                        label="tariff_name"
                      >
                        <span slot="noResult">-</span>
                      </multiselect>

                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="mb-3">
                      <label class="form-label">Origin</label>
                      <input
                        type="text"
                        v-model="search.origin"
                        v-on:keyup.enter="searchData"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="mb-3">
                      <label class="form-label">Destination</label>
                      <input
                        type="text"
                        v-model="search.destination"
                        v-on:keyup.enter="searchData"
                        class="form-control"
                      />
                    </div>
                  </div>
                   <div class="col-lg-1">
                    <div class="mb-3">
                      <label class="form-label">Basis</label>
                      <input
                        type="text"
                        v-model="search.basis"
                        v-on:keyup.enter="searchData"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="mb-3">
                      <label class="form-label">Sku</label>
                      <!-- <input
                        type="text"
                        v-model="search.sku"
                        v-on:keyup.enter="searchData"
                        class="form-control"
                      /> -->
                      <multiselect
                        @search-change="asyncSearchSKU"
                        v-model="sku"
                        name="sku"
                        :options="fetchSku"
                        placeholder="Please Select"
                        track-by="type_truck"
                        label="type_truck"
                      >
                        <span slot="noResult">-</span>
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-lg-1">
                    <div class="mb-3">
                      <label class="form-label">Status</label>
                      <div class="mb-4">
                        <div class="form-check mb-2">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="future"
                            value="future"
                            name="future"
                            v-model="search.status"
                          />
                          <label class="form-check-label" for="future">
                            Future
                          </label>
                        </div>
                        <div class="form-check mb-2">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="current"
                            value="current"
                            name="current"
                            v-model="search.status"
                          />
                          <label class="form-check-label" for="current">
                            Current
                          </label>
                        </div>
                        <div class="form-check mb-2">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="expired"
                            value="expired"
                            name="expired"
                            v-model="search.status"
                          />
                          <label class="form-check-label" for="expired">
                            Expired
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                   <div class="col-lg-1">
                    <div class="mb-3">
                      <label class="form-label">SF Source</label>
                      <select name="" class="form-control" id="" v-model="search.sf_source">
                        <option value="">-</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-1">
                    <div class="mb-3">
                      <button
                        class="btn btn-primary"
                        type="button"
                        @click="searchData"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>

                <ul
                  class="nav nav-tabs nav-tabs-line"
                  id="lineTab"
                  role="tablist"
                >
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="shipment-line-tab"
                      data-bs-toggle="tab"
                      href="#shipment"
                      role="tab"
                      aria-controls="shipment"
                      aria-selected="true"
                      >Shipment</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="accessorial-line-tab"
                      data-bs-toggle="tab"
                      href="#accessorial"
                      role="tab"
                      aria-controls="accessorial"
                      aria-selected="false"
                      >Accessorial</a
                    >
                  </li>
                                    <!-- <li class="nav-item">
                    <a
                      class="nav-link"
                      id="rules-line-tab"
                      data-bs-toggle="tab"
                      href="#rules"
                      role="tab"
                      aria-controls="rules"
                      aria-selected="false"
                      >Accessorial Rules</a
                    >
                  </li> -->
                </ul>
                <div class="tab-content mt-3" id="lineTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="shipment"
                    role="tabpanel"
                    aria-labelledby="shipment-line-tab"
                  >
                    <billableRateManagementDetailTabs
                      :filter="this.filter"
                    ></billableRateManagementDetailTabs>
                  </div>
                  <div
                    class="tab-pane fade "
                    id="accessorial"
                    role="tabpanel"
                    aria-labelledby="accessorial-line-tab"
                  >
                    <billableRateManagementAccTabs
                      :filter="this.filter"
                    ></billableRateManagementAccTabs>
                  </div>
                      <!-- <div
                    class="tab-pane fade "
                    id="rules"
                    role="tabpanel"
                    aria-labelledby="rules-line-tab"
                  >
                    <billableRateManagementRuleTabs
                      :filter="this.filter"
                    ></billableRateManagementRuleTabs>
                  </div> -->
                </div>
              </div>
              <div class="card-footer">
                <button
                  class="btn btn-warning text-black fw-bolder btn-icon-text"
                  @click="backForm"
                  type="button"
                >
                  <i class="link-icon" data-feather="arrow-left"></i>
                  {{ $t("backMess") }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import multiselect from "vue-multiselect";
import billableRateManagementDetailTabs from "@/components/dasboard-rm/billableRateManagement/BillableRateManagementDetailTabs.vue";
import billableRateManagementAccTabs from "@/components/dasboard-rm/billableRateManagement/BillableRateManagementAccTabs.vue";
// import billableRateManagementRuleTabs from "@/components/dasboard-rm/billableRateManagement/BillableRateManagementRuleTabs.vue";
export default {
  name: "BillableRateManagementDetail",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    multiselect: multiselect,
    billableRateManagementDetailTabs,
    billableRateManagementAccTabs,
    // billableRateManagementRuleTabs,
  },
  data() {
    return {
      isLoading: false,
      totalRecords: "",
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      permision_role: [],
      userData: "",
      forms: {},
      fetchBillableTariff: [],
      billableTariff: null,
      passDataRate: null,
      passData: null,
      sku: null,
      fetchSku: [],
      filter: {},
      search: {
        sku: "",
        origin: "",
        destination: "",
        status: [],
        billableTariff: null,
        sf_source:"",
        show_able:false,
      },
    };
  },
  methods: {
    searchData() {
      if(this.billableTariff && this.billableTariff.tariff_name){
         
          this.filter = {};
          this.filter = {
            sku: this.sku?.type_truck ? this.sku.type_truck : "",
            origin: this.search.origin,
            destination: this.search.destination,
            status: this.search.status,
            basis: this.search.basis,
            sf_source: this.search.sf_source,
            billableTariff: this.billableTariff?.tariff_name
              ? this.billableTariff.tariff_name
              : "",
            show_able:true,
          };
          this.setCookies("filter",JSON.stringify(this.filter));
      }else{
        this.error("Billable Tariff Null")
      }

    },
    setCookies(name,data){
      let path = this.$route.path;
      let d = new Date();
      d.setDate(d.getDate()+1);
      let exp = "expires=" + d.toUTCString();
      document.cookie = name+"="+data+";"+exp+";path="+path;
      // console.log(data);
    },
    loadItems() {
      var params = this.$onBehind(this.$route.params.id);
      this.fade(true);
      const baseURI =
        this.$settings.endPoint + "rm-billable-rate-header/detail/" + params;

      return this.$http.get(baseURI).then((response) => {
        this.forms = response.data.datas.header;
        this.loadBillableTariff();
        this.loadSku();
        this.loading();
      });
    },
    rateBreak(row) {
      this.passDataRate = {
        id: row.rm_billable_rate_detail_id,
      };
      this.showModalRateBreak = true;
    },
    loadBillableTariff() {
      const baseURI =
        this.$settings.endPoint +
        `rm-billable-tariff?customer_code=${this.forms.customer_code}`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchBillableTariff = response.data.datas.data;
      });
    },
    loadSku() {
      const baseURI = this.$settings.endPointCt + `type-truck`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchSku = response.data.datas.data;
        this.fade(false);
      });
    },
    asyncSearchSKU(ev) {
      const baseURI = this.$settings.endPointCt + `type-truck?type_truck=` + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchSku = response.data.datas.data;
      });
    },

    asyncSearchBillabkeTarif(ev){
      const baseURI =
        this.$settings.endPoint +
        `rm-billable-tariff?customer_code=${this.forms.customer_code}&tariff_name=${ev}`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchBillableTariff = response.data.datas.data;
      });
    },

    success2(msg) {
      let pesan = "";
      msg.forEach((item) => {
        pesan = pesan + "<p>* " + item + "</p>";
      });

      this.$swal({
        icon: "info",
        backdrop: true,
        title: "Information",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },
    backForm() {
      window.location.href = "/billable-rate";
    },
    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      this.companyCode = this.detailUser.company_detail.code;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RateManagementController") {
          rolePermision = permision;
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
    applyCookieFilter(){
      let cookies = document.cookie;
      if(cookies){
        cookies  = cookies.split("; ");
        let objCookies ={};
        cookies.forEach((item)=>{
          let i = item.split("=");
          var data ="";
          if(i[0] == 'filter'){
            data = JSON.parse(i[1]);
          }else{
            data = i[1];
          }
          objCookies[i[0]] = data;
        })

        this.search = objCookies.filter;
        if(objCookies.filter.billableTariff){
          this.billableTariff = {
            tariff_name : objCookies.filter.billableTariff
          }
        }
        this.searchData();
        // console.log("Cookies Implement Complete");
      }else{
        // console.log("Fresh Page No Cookies");
      }
      // console.log(JSON.parse(cookies));
    },
  },
  mounted() {
    this.fetchIt();
    this.fade();
    this.loadItems();
    this.applyCookieFilter()
    // console.log(document.cookie)
  },
};
</script>

<style></style>
