<template>
  <div>
    <div class="main-wrapper">
      <sidebar-component classMenu="newRate"></sidebar-component>
      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->
        <div class="page-content">
          <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12 pe-2">
            <div class="card">
              <div class="card-header">
                <h4 style="margin-right: 5px" class="float-start">
                  Stop Location
                </h4>
                <button v-if="permision_role.method_read" class="btn btn-success float-end btn-xs"
                  style="margin-right: 5px" @click.prevent="createData()">
                  <i class="link-icon" data-feather="plus"></i>
                  {{ $t("createNew") }}
                </button>
                <button class="btn btn-warning float-end btn-xs" style="margin-right: 5px"
                  @click.prevent="downloadData()">
                  <i class="link-icon" data-feather="download"></i>
                  {{ $t("downloadData") }} .xlsx
                </button>
              </div>
              <div class="card-body">
                <!-- 
                        @on-page-change="onPageChange"
                        @on-sort-change="onSortChange"
                        @on-column-filter="onColumnFilter"
                        @on-per-page-change="onPerPageChange"
                   -->
                <vue-good-table title="list-user" mode="remote" :select-options="{
                  enabled: true,
                  selectOnCheckboxOnly: true,
                  selectionText: $t('data_selected'),
                  clearSelectionText: $t('clear'),
                }" :totalRows="totalRecords" :line-numbers="true" :isLoading.sync="isLoading" :pagination-options="{
  enabled: true,
}" :rows="rows" :columns="columns">
                  <div slot="table-actions">
                    {{ $t("no_select_data") }}
                  </div>
                  <div slot="selected-row-actions">
                    <button class="btn btn-success btn-xs" style="margin-right: 20px" @click="sendOrder">
                      <i class="mdi mdi-send"></i>
                      Send To Blujay
                    </button>
                    <button class="btn btn-success btn-xs" style="margin-right: 20px" @click="sendOrder">
                      <i class="mdi mdi-check-outline"></i>
                      Enable
                    </button>

                    <button class="btn btn-danger btn-xs" @click="cancelOrder('cancel_from_costumer')">
                      <i class="mdi mdi-close-octagon"></i>
                      Disable
                    </button>
                  </div>
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'actions'">
                      <a href="#" v-if="permision_role.method_read" title="Detail"
                        @click.prevent="detailData(props.index, props.row)">
                        <i class="mdi mdi-eye" style="font-size: 16px"></i>
                      </a>
                      <a href="#" v-if="permision_role.method_update" title="Edit"
                        @click.prevent="editData(props.index, props.row)">
                        <i class="mdi mdi-square-edit-outline" style="font-size: 16px"></i>
                      </a>

                      <a href="#" v-if="permision_role.method_delete" title="Delete"
                        @click.prevent="deleteData(props.index, props.row)">
                        <i class="mdi mdi-trash-can-outline" style="font-size: 16px"></i>
                      </a>
                    </span>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>
  
<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
export default {
  name: "NewRate",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      locale: {
        format: "yyyy-mm-dd",
      },
      opens: "center",
      closeBtn: true,
      isLoading: false,
      errors: [],
      totalRecords: 0,
      permision_role: [],
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [
        {
          label: "Status",
          field: "status",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Rating Status ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Company",
          field: "company",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Company", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Location Code",
          field: "location_code",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Location Code ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Name",
          field: "location_name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Location Name ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Region",
          field: "region",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Region ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Country",
          field: "country",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Country ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Province",
          field: "province",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Province ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "City",
          field: "city",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By City ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Distrcit",
          field: "district",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By District ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Postal",
          field: "postal",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Postal ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Action",
          field: "actions",
          sortable: false,
        },
      ],
      rows: [
        {
          name: "asffas",
          status: "FAILED SENT TO BL",
        },
        {
          name: "asffas",
          status: "FAILED SENT TO SF",
        },
        {
          name: "asffas",
          status: "SENT TO SF",
        },
      ],
    };
  },
  methods: {
    sendOrder() {
      // console.log("test");
    },
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RateManagementController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },
    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },
  },
  mounted() {
    this.fetchIt();
    this.loading();
  },
};
</script>
  
<style></style>