<template>
  <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title badge bg-primary">Recap Detail : {{data.date}}</h5>

          <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
            aria-label="btn-close"></button>
        </div>

        <form class="forms-sample" method="POST" @submit.prevent="submitData">
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table table-hover mb-0">
                <thead>
                  <tr>
                    <th class="pt-0" colspan="2">
                      <span v-if="data.detail[0].update_by == 'system'">UPDATED (By System)</span>
                      <span v-if="data.detail[0].update_by !== 'system'">SUBMITTED</span>
                    </th>
                    <th class="pt-0" colspan="2">
                      <span v-if="typeof data.detail[1] !== 'undefined' && data.detail[1].update_by == 'system'">UPDATED (By System)</span>
                      <span v-if="typeof data.detail[1] !== 'undefined' && data.detail[1].update_by !== 'system'">SUBMITTED</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Total Truck</td>
                    <td style="text-align:right"><span class="badge bg-badge bg-light text-dark">{{data.detail[0].total}}</span></td>
                    <td>Total Truck
                      <span v-html="up" v-if="typeof data.detail[1] !== 'undefined' && (data.detail[1].total > data.detail[0].total)"></span>
                      <span v-html="down" v-if="typeof data.detail[1] !== 'undefined' && (data.detail[1].total < data.detail[0].total)"></span>
                    </td>
                    <td style="text-align:right">
                      <span :class="typeof data.detail[1] !== 'undefined' && (data.detail[1].total > data.detail[0].total)?'badge bg-success':(typeof data.detail[1] !== 'undefined' && (data.detail[1].total < data.detail[0].total)?'badge bg-primary':'badge bg-badge bg-light text-dark')">
                       {{typeof data.detail[1] !== 'undefined'?data.detail[1].total:''}} 
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Service</td>
                    <td style="text-align:right"><span class="badge bg-badge bg-light text-dark">{{data.detail[0].service}}</span></td>
                    <td>Service
                      <span v-html="up" v-if="typeof data.detail[1] !== 'undefined' && (data.detail[1].service > data.detail[0].service)"></span>
                      <span v-html="down" v-if="typeof data.detail[1] !== 'undefined' && (data.detail[1].service < data.detail[0].service)"></span>
                    </td>
                    <td style="text-align:right">
                      <span :class="typeof data.detail[1] !== 'undefined' && (data.detail[1].service > data.detail[0].service)?'badge bg-success':(typeof data.detail[1] !== 'undefined' && (data.detail[1].service < data.detail[0].service)?'badge bg-primary':'badge bg-badge bg-light text-dark')">
                       {{typeof data.detail[1] !== 'undefined'?data.detail[1].service:''}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Utility</td>
                    <td style="text-align:right"><span class="badge bg-badge bg-light text-dark">{{data.detail[0].active}}</span></td>
                    <td>Utility
                      <span v-html="up" v-if="typeof data.detail[1] !== 'undefined' && (data.detail[1].active > data.detail[0].active)"></span>
                      <span v-html="down" v-if="typeof data.detail[1] !== 'undefined' && (data.detail[1].active < data.detail[0].active)"></span>
                    </td>
                    <td style="text-align:right">
                      <span :class="typeof data.detail[1] !== 'undefined' && (data.detail[1].active > data.detail[0].active)?'badge bg-success':(typeof data.detail[1] !== 'undefined' && (data.detail[1].active < data.detail[0].active)?'badge bg-primary':'badge bg-badge bg-light text-dark')">
                      {{typeof data.detail[1] !== 'undefined'?data.detail[1].active:''}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>No Driver</td>
                    <td style="text-align:right"><span class="badge bg-badge bg-light text-dark">{{data.detail[0].no_driver}}</span></td>
                    <td>No Driver
                      <span v-html="up" v-if="typeof data.detail[1] !== 'undefined' && (data.detail[1].no_driver > data.detail[0].no_driver)"></span>
                      <span v-html="down" v-if="typeof data.detail[1] !== 'undefined' && (data.detail[1].no_driver < data.detail[0].no_driver)"></span>
                    </td>
                    <td style="text-align:right">
                      <span :class="typeof data.detail[1] !== 'undefined' && (data.detail[1].no_driver > data.detail[0].no_driver)?'badge bg-success':(typeof data.detail[1] !== 'undefined' && (data.detail[1].no_driver < data.detail[0].no_driver)?'badge bg-primary':'badge bg-badge bg-light text-dark')">
                      {{typeof data.detail[1] !== 'undefined'?data.detail[1].no_driver:''}}</span>
                       </td>
                  </tr>
                  <tr>
                    <td>Available</td>
                    <td style="text-align:right"><span class="badge bg-badge bg-light text-dark">{{data.detail[0].available}}</span></td>
                    <td>Available
                      <span v-html="up" v-if="typeof data.detail[1] !== 'undefined' && (data.detail[1].available > data.detail[0].available)"></span>
                      <span v-html="down" v-if="typeof data.detail[1] !== 'undefined' && (data.detail[1].available < data.detail[0].available)"></span>
                    </td>
                    <td style="text-align:right">
                      <span :class="typeof data.detail[1] !== 'undefined' && (data.detail[1].available > data.detail[0].available)?'badge bg-success':(typeof data.detail[1] !== 'undefined' && (data.detail[1].available < data.detail[0].available)?'badge bg-primary':'badge bg-badge bg-light text-dark')">
                      {{typeof data.detail[1] !== 'undefined'?data.detail[1].available:''}}</span>
                      </td>
                  </tr>
                  <tr>
                    <td>Idle</td>
                    <td style="text-align:right"><span class="badge bg-badge bg-light text-dark">{{data.detail[0].idle}}</span></td>
                    <td>Idle
                      <span v-html="up" v-if="typeof data.detail[1] !== 'undefined' && (data.detail[1].idle > data.detail[0].idle)"></span>
                      <span v-html="down" v-if="typeof data.detail[1] !== 'undefined' && (data.detail[1].idle < data.detail[0].idle)"></span>
                    </td>
                    <td style="text-align:right">
                      <span :class="typeof data.detail[1] !== 'undefined' && (data.detail[1].idle > data.detail[0].idle)?'badge bg-success':(typeof data.detail[1] !== 'undefined' && (data.detail[1].idle < data.detail[0].idle)?'badge bg-primary':'badge bg-badge bg-light text-dark')">
                      {{typeof data.detail[1] !== 'undefined'?data.detail[1].idle:''}}</span>
                      </td>
                  </tr>
                  <tr>
                    <td>Idle Cap</td>
                    <td style="text-align:right"><span class="badge bg-badge bg-light text-dark">{{data.detail[0].idle_cap}}</span></td>
                    <td>Idle Cap
                      <span v-html="up" v-if="typeof data.detail[1] !== 'undefined' && (data.detail[1].idle_cap > data.detail[0].idle_cap)"></span>
                      <span v-html="down" v-if="typeof data.detail[1] !== 'undefined' && (data.detail[1].idle_cap < data.detail[0].idle_cap)"></span>
                    </td>
                    <td style="text-align:right">
                      <span :class="typeof data.detail[1] !== 'undefined' && (data.detail[1].idle_cap > data.detail[0].idle_cap)?'badge bg-success':(typeof data.detail[1] !== 'undefined' && (data.detail[1].idle_cap < data.detail[0].idle_cap)?'badge bg-primary':'badge bg-badge bg-light text-dark')">
                      {{typeof data.detail[1] !== 'undefined'?data.detail[1].idle_cap:''}}</span>
                      </td>
                  </tr>    
                  <tr>
                    <td>Holiday</td>
                    <td style="text-align:right"><span class="badge bg-badge bg-light text-dark">{{data.detail[0].holiday}}</span></td>
                    <td>Holiday
                      <span v-html="up" v-if="typeof data.detail[1] !== 'undefined' && (data.detail[1].holiday > data.detail[0].holiday)"></span>
                      <span v-html="down" v-if="typeof data.detail[1] !== 'undefined' && (data.detail[1].holiday < data.detail[0].holiday)"></span>
                    </td>
                    <td style="text-align:right">
                      <span :class="typeof data.detail[1] !== 'undefined' && (data.detail[1].holiday > data.detail[0].holiday)?'badge bg-success':(typeof data.detail[1] !== 'undefined' && (data.detail[1].holiday < data.detail[0].holiday)?'badge bg-primary':'badge bg-badge bg-light text-dark')">
                      {{typeof data.detail[1] !== 'undefined'?data.detail[1].holiday:''}}</span>
                      </td>
                  </tr>     
                  <tr>
                    <td>AIS</td>
                    <td style="text-align:right"><span class="badge bg-badge bg-light text-dark">{{data.detail[0].ais}}</span></td>
                    <td>AIS
                      <span v-html="up" v-if="typeof data.detail[1] !== 'undefined' && (data.detail[1].ais > data.detail[0].ais)"></span>
                      <span v-html="down" v-if="typeof data.detail[1] !== 'undefined' && (data.detail[1].ais < data.detail[0].ais)"></span>
                    </td>
                    <td style="text-align:right">
                      <span :class="typeof data.detail[1] !== 'undefined' && (data.detail[1].ais > data.detail[0].ais)?'badge bg-success':(typeof data.detail[1] !== 'undefined' && (data.detail[1].ais < data.detail[0].ais)?'badge bg-primary':'badge bg-badge bg-light text-dark')">
                      {{typeof data.detail[1] !== 'undefined'?data.detail[1].ais:''}}</span>
                      </td>
                  </tr>          
                </tbody>
              </table>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
              {{ $t("close_txt") }}
            </button>
           </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  components: {
  },
  name: "ModalTruckDetailUtility",
  emits: ["closeModal"],
  props: {
    showModal: Boolean,    
    data: {},
  },
  watch: {
    showModal: {      
      handler(newVal) {
        this.active = newVal;
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");       
        
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      up:"<i class='mdi mdi-chevron-double-up' style='color:green;font-size:16px'></i>",
      down:"<i class='mdi mdi-chevron-double-down' style='color:red;font-size:16px'></i>",
      statusDetail:{},
      tanggalModal:'',
      isLoading: false,
      active: this.showModal,
      locale: [],
      rules: {
        select: [(v) => !!v || "Item is required"]
      }

    };
  },
  methods: {  
  },
  mounted() {

  },
};
</script>

<style scoped>
.blur-content {
  filter: blur(5px);
  pointer-events: none;
}

.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left:0px !important;
}
</style>