import Accident from "@/components/dasboard-ct/accident/Accident.vue";
import AccidentAdd from "@/components/dasboard-ct/accident/AccidentAdd.vue";
import AccidentDetail from "@/components/dasboard-ct/accident/AccidentDetail.vue";
import AccidentEdit from "@/components/dasboard-ct/accident/AccidentEdit.vue";

let routesAccident= [
      {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "Accident",
        component: Accident
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "AccidentAdd",
        component: AccidentAdd
      },
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "AccidentEdit",
        component: AccidentEdit
      },
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "AccidentDetail",
        component: AccidentDetail
      },
    
   
    
]

export default routesAccident
  