<template>
    <div>
      <div class="loader" v-if="this.isLoading"></div>   
      <div class="loadertext" v-if="this.isLoading"> {{$t('loadingTxt')}}...</div>   
      <div class="main-wrapper" :class="{'blur-content': this.isLoading}">
        <!--begin::sidebar-->
        <sidebar-component classMenu="GTNewsPortalIndex"></sidebar-component>
        <!--end::sidebar-->
  
        <div class="page-wrapper">
          <!--begin::navbar-->
          <navbar-component></navbar-component>
          <!--end::navbar-->
  
          <!--begin::content-->
          <div class="page-content">
  
            <div class="
                d-flex
                justify-content-between
                align-items-center
                flex-wrap
                grid-margin
              ">
              <div>
                <h4 class="mb-12 mb-md-0"></h4>
              </div>
  
              <div class="col-lg-12 col-xl-12 stretch-card">
                <div class="card">
  
                  <div class="card-header">
                    <h4 style="margin-right: 5px" class="float-start">{{ $t("news_portal_txt") }}</h4>
                    <button v-if="permision_role.method_create" class="btn btn-primary float-end btn-xs"
                      style="margin-right: 5px" @click.prevent="createData()">
                      <i class="link-icon" data-feather="plus"></i>
                      {{ $t("createNew") }}
                    </button>
  
  
                    <button v-if="permision_role.method_read" class="btn btn-success float-end btn-xs"
                      style="margin-right: 5px" @click.prevent="downloadData()">
                      <i class="link-icon" data-feather="download"></i>
                      {{ $t("downloadData") }} .xlsx
                    </button>
                  </div>
  
                  <div class="card-body">
                    <vue-good-table title="gt-news-portal" mode="remote" @on-page-change="onPageChange"
                      :select-options="{ enabled: false }"
                      @on-selected-rows-change="selectionChanged" 
                      @on-sort-change="onSortChange" @on-column-filter="onColumnFilter"
                      @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :line-numbers="true"
                      :isLoading.sync="isLoading" :pagination-options="{
                        enabled: true,
                      }" :rows="rows" :columns="columns" :search-options="{
                                                    searchFn: filterFunction
                                                }">
  
             
  
                      <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'actions'">
                           
                          <a href="#" v-if="permision_role.method_read" title="Detail" @click.prevent="
                            detailData(props.index, props.row)
                          ">
                            <i class="mdi mdi-eye" style="font-size:16px"></i>
  
                          </a>
                          <a href="#" v-if="permision_role.method_update" title="Edit"
                            @click.prevent="editData(props.index, props.row)">
                            <i class="mdi mdi-square-edit-outline" style="font-size:16px"></i>
  
                          </a>
  
                          <a href="#" v-if="permision_role.method_delete" title="Delete" @click.prevent="
                            deleteData(props.index, props.row)
                          ">
                            <i class="mdi mdi-trash-can-outline" style="font-size:16px"></i>
                          </a>
                        </span>
                        <span v-else-if="props.column.field == 'active_status'">
                            <span v-if="props.row.active_status" style="color:blue;">{{$t('active_txt')}}</span>
                            <span v-else style="color:red;">{{$t('deactive_txt')}}</span>
                        </span>
                        <span v-else-if="props.column.field == 'news_from'">
                            {{formatDate(props.row.news_from)}}
                        </span>
                        <span v-else-if="props.column.field == 'news_to'">
                            {{formatDate(props.row.news_to)}}
                        </span>
                        <span v-else>
                          {{ props.formattedRow[props.column.field] }}
                        </span>
                      </template>
                    </vue-good-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end::content-->
  
          <!--begin::footer-->
          <footer-component></footer-component>
          <!--end::footer-->
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import sidebarComponent from "@/components/_partials/_sidebar";
  import navbarComponent from "@/components/_partials/_navbar";
  import footerComponent from "@/components/_partials/_footer";
  import flatPickr from "flatpickr";
  import "flatpickr/dist/flatpickr.css";
  import "flatpickr/dist/themes/material_red.css";

  export default {
    name: "GTNewsPortalIndex",
    components: {
      "sidebar-component": sidebarComponent,
      "navbar-component": navbarComponent,
      "footer-component": footerComponent,

      
    },
    data() {
      return {
        baseURI: "",
        maxToasts: 100,
        position: "up right",
        closeBtn: true,
        isLoading: false,
        errors: [],
        permision_role: [],
        tax_slip_ids:[],
        langs: ["id", "en"],
        detailUser: [],
        detailDivisi: [],
        totalRecords: 0,
        serverParams: {
          columnFilters: {},
          sort: {
            field: "",
            type: "",
          },
          page: 1,
          per_page: 10,
        },
        columns: [
            {
                label: this.$t('action_txt'),
                field: "actions",
                sortable: false,
            },
            {
                label: this.$t('pool_name'),
                field: "pool_name",
                filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By "+this.$t('pool_name'), // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
                },
            },
          {
            label: this.$t('news_from_date'),
            field: "news_from",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Format YYYY-MM-DD", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "keyup", //only trigger on enter not on keyup
            },
          },
          {
            label: this.$t('news_to_date'),
            field: "news_to",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Format YYYY-MM-DD", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "keyup", //only trigger on enter not on keyup
            },
          },
          {
            label: this.$t('title'),
            field: "title",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By "+this.$t('title'), // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },  
          {
            label: this.$t('brief'),
            field: "brief",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By "+this.$t('brief'), // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },  
          {
            label: this.$t('statusTxt'),
            field: "active_status",
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: "Filter By "+this.$t('statusTxt'), // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              filterDropdownItems: [{ value: '1', text: this.$t('active_txt') },{ value: '0', text: this.$t('deactive_txt') }], // dropdown (with selected values) instead of text input
              trigger: "enter", //only trigger on enter not on keyup
            },
          },
          
        ],
        rows: [],
      };
    },
    watch: {},
    methods: {

        formatDate(dateTime){
            return this.$moment(dateTime).format("DD/MM/YYYY");
        },
   
        selectionChanged(ev){

            console.log(ev);

        },
  
        deleteData(index,data) {
            var id = data.news_portal_id;
            this.$swal({
                title: this.$t("areYouSure"),
                text: this.$t("yourDataDelete"),
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes!",
            }).then((result) => {
                if (result.value) {
                var formData = {
                    status: status,
                };
                const baseURI = this.$settings.endPointGT + "news-portal/delete/" + id;
                this.$http
                    .delete(baseURI, formData)
                    .then((response) => {
                    this.loading();
                    if (response.data.status === 200) {
                        this.success(response.data.datas.messages);
                        this.loadItems();
                    } else {
                        this.errors = response.data.errors;
                        this.resultError(response.data.errors);
                    }
                    })
                    .catch((error) => {
                    this.loading();
                    if (error.response) {
                        if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                        this.resultError(error.response.data.errors);
                        } else if (error.response.status === 500) {
                        this.error(error.response.data.errors.message);
                        } else {
                        this.$router.push("/page-not-found");
                        }
                    }
                    });
                }
            });
        },

        fetchIt() {
            const userDatas = this.$getUserInfo();
            this.detailUser = userDatas.sub;
            //this.permision_role = userDatas.sub.permision_role;
            let rolePermision = [];
            userDatas.sub.permision_role.forEach(function (permision) {
            if (permision.permission.controller == 'NewsPortalController') {
                rolePermision = permision;
            }
            });
    
            if (!rolePermision.method_read) {
            this.$router.push("/authorized-error");
            }
            this.permision_role = rolePermision;

            var status = this.$route.query.status
            if(status == "200"){
              this.success(this.$route.query.msg)
            }
    
        },

     

        formatDateToBackend(val) {
          return val ? this.$moment(val).format("YYYY-MM-DD") : "";
        },
   
        downloadData() {
            this.baseURI = this.$settings.endPointGT + "news-portal/index";
            var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
            var file_name = "download_" + CurrentDate + ".xlsx";
    
            this.isLoading = true;
    
            return this.$http
            .get(
                this.baseURI +
                `?pool_name=${this.serverParams.columnFilters.pool_name}&news_from=${this.formatDateToBackend(this.serverParams.columnFilters.news_from)}&news_to=${this.formatDateToBackend(this.serverParams.columnFilters.news_to)}&active_status=${this.serverParams.columnFilters.active_status}&title=${this.serverParams.columnFilters.title}&brief=${this.serverParams.columnFilters.brief}&download=download`,
                {
                responseType: "blob",
                timeout: 30000
                }
            ).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement("a");
    
                fileLink.href = fileURL;
                fileLink.setAttribute("download", file_name);
                document.body.appendChild(fileLink);
                fileLink.click();
                this.isLoading = false;
            }).catch(error => {
                this.isLoading = false;
                if(error.code == "ECONNABORTED"){
                this.error(this.$t("check_internet_connection_txt"));
                }else{
    
                if (error.response) {
                    if(error.response.status === 422) {
                            this.errors = error.response.data.errors;
                            this.resultError(error.response.data.errors);
                    }else if (error.response.status === 500) {
                        this.$router.push('/server-error');
                    }else{
                        this.$router.push('/page-not-found');
                    }
                }
    
                }
    
            });
        },
    
        createData() {
            window.location.href = "/gt/news-portal/create";
        },
  
        editData(index, row) {
            var params = this.$onRandom(row.news_portal_id);
            window.location.href = "/gt/news-portal/edit/" + params;
        },
  
        detailData(index, row) {
            var params = this.$onRandom(row.news_portal_id);
            window.location.href = "/gt/news-portal/detail/" + params;
        },
  
      
  
        // load items is what brings back the rows from server
        loadItems() {
            this.isLoading = true;
            this.baseURI = this.$settings.endPointGT + "news-portal/index";
    
            return this.$http
            .get(
                this.baseURI +
                `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&pool_name=${this.serverParams.columnFilters.pool_name}&news_from=${this.formatDateToBackend(this.serverParams.columnFilters.news_from)}&news_to=${this.formatDateToBackend(this.serverParams.columnFilters.news_to)}&active_status=${this.serverParams.columnFilters.active_status}&title=${this.serverParams.columnFilters.title}&brief=${this.serverParams.columnFilters.brief}`,{ timeout: 10000 }
            )
            .then((response) => {
                this.isLoading = false;
                this.rows = response.data.datas.data;
                this.totalRecords = response.data.datas.total;
            }).catch(error => {
                this.isLoading = false;
                if(error.code == "ECONNABORTED"){
                this.error(this.$t("check_internet_connection_txt"));
                }else{
    
                if (error.response) {
                    if(error.response.status === 422) {
                            this.errors = error.response.data.errors;
                            this.resultError(error.response.data.errors);
                    }else if (error.response.status === 500) {
                        this.$router.push('/server-error');
                    }else{
                        this.$router.push('/page-not-found');
                    }
                }
    
                }
    
            });
        },
  
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
            this.loadItems();
        },
  
        onColumnFilter(params) {
          params.page = 1;
            this.updateParams(params);
        },
  
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
        },
    
        onSortChange(params) {
            this.updateParams({
            sort: {
                type: params[0].type,
                field: params[0].field,
            },
            });
        },
  
        onPerPageChange(params) {
            this.updateParams({ per_page: params.currentPerPage });
        },
  
        resultError(data) {
            var count = Object.keys(data).length;
            for (var x = 0; x < count; x++) {
            var nameOb = Object.keys(data)[x];
            var objectData = data[nameOb];
            for (var y = 0; y < objectData.length; y++) {
                this.error(objectData[y]);
            }
            }
        },
  
        success(kata) {
            const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
            });
            Toast.fire({
            icon: "success",
            title: kata,
            });
        },
  
        error(kata) {
            const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
            });
            Toast.fire({
            icon: "error",
            title: kata,
            });
        },
  
        fade(sType) {
            this.isLoading = sType;
        },
    
        loading() {
            this.fade(true);
            setTimeout(() => {
            this.isLoading = false;
            }, 1000); // hide the message after 3 seconds
        },

  
   
    },
    events: {},
    created: function () { },
    mounted() {
      this.fetchIt();
      let inputs = [
      'input[placeholder="Format YYYY-MM-DD"]',
    ];
  
    inputs.forEach(function(input) {
      flatPickr(input, {
        dateFormat: "Y-m-d",
        
        allowInput: false
      });
    });
     
      
    },
  };
  </script>
  <style scoped>
  button.gm-ui-hover-effect {
    visibility: hidden;
  }
  .blur-content{
    filter: blur(5px); 
  }

 
  </style>