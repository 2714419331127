<template>
  <div
    ref="modal"
    class="modal fade"
    :class="{ show: active, 'd-block': active }"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title badge bg-primary">Region</h5>

          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            v-on:click="$emit('closeModal', null)"
            aria-label="btn-close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-6">
              <p>Origin Location</p>
              <vue-good-table
                title="list-route"
                mode="remote"
                @on-page-change="onPageChangeOrigin"
                @on-sort-change="onSortChangeOrigin"
                @on-column-filter="onColumnFilterOrigin"
                @on-per-page-change="onPerPageChangeOrigin"
                :totalRows="totalRecordsOrigin"
                :line-numbers="true"
                :isLoading.sync="isLoading"
                :pagination-options="{
                  enabled: true,
                }"
                @on-row-click="onRowClickOrigin"
                :rows="rowsOrigin"
                :columns="columns"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'actions'">
                    <input
                      type="radio"
                      name="originRadio"
                      v-model="originRadio"
                      :value="props.index"
                    />
                    <!-- <a
                      href="#"
                      title="Insert"
                      @click.prevent="submitParent(props.index, props.row)"
                    >
                      <i class="mdi mdi-check" style="font-size: 16px"></i>
                    </a> -->
                  </span>
                </template>
              </vue-good-table>
            </div>
            <div class="col-sm-6">
              <p>Destination Location</p>
              <vue-good-table
                title="list-route"
                mode="remote"
                @on-page-change="onPageChangeDest"
                @on-sort-change="onSortChangeDest"
                @on-column-filter="onColumnFilterDest"
                @on-per-page-change="onPerPageChangeDest"
                @on-row-click="onRowClickDest"
                :totalRows="totalRecordsDest"
                :line-numbers="true"
                :isLoading.sync="isLoading"
                :pagination-options="{
                  enabled: true,
                }"
                :rows="rowsDestination"
                :columns="columns"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'actions'">
                    <input
                      type="radio"
                      name="destRadio"
                      v-model="destRadio"
                      :value="props.index"
                    />
                    <!-- <a
                      href="#"
                      title="Insert"
                      @click.prevent="submitParent(props.index, props.row)"
                    >
                      <i class="mdi mdi-check" style="font-size: 16px"></i>
                    </a> -->
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            v-on:click="$emit('closeModal', null)"
          >
            {{ $t("close_txt") }}
          </button>
          <button type="button" class="btn btn-twitter" v-on:click="sendData" :disabled="originRadio.length || destRadio.length">
            SET
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "ModalRouteTransport",
  emits: ["closeModal"],
  props: {
    showModal: Boolean,
    data: {},
  },
  watch: {
    showModal: {
      handler(newVal) {
        this.active = newVal;
        this.rowsOrigin = [];
        this.rowsDestination = [];
        if (this.active) {
          console.log(this.data)
          this.loadItemsDest();
          this.loadItemsOrigin();
        }
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      active: this.showModal,
      totalRecordsOrigin: 0,
      totalRecordsDest: 0,
      originRadio: "",
      destRadio: "",
      originServerParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      destServerParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [
        {
          label: "Action",
          field: "actions",
          sortable: false,
        },
        {
          label: "Location Reference Number",
          field: "location_code",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Location Reference Number", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Location Name",
          field: "name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Location Name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Address",
          field: "address1",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Address", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
      ],
      rowsOrigin: [],
      rowsDestination: [],
      rowsSelection: [],

      originSelected: [],
      destSelected: [],

      locale: [],
      rules: {
        select: [(v) => !!v || "Item is required"],
      },
    };
  },
  methods: {
    submitParent(index, row) {
      this.$emit("closeModal", row);
    },

    updateParamsOrigin(newProps) {
      this.originServerParams = Object.assign(
        {},
        this.originServerParams,
        newProps
      );
      this.loadItemsOrigin();
    },

    onColumnFilterOrigin(params) {
      params.page = 1;
      this.updateParamsOrigin(params);
    },

    onPageChangeOrigin(params) {
      this.updateParamsOrigin({ page: params.currentPage });
    },

    onSortChangeOrigin(params) {
      this.updateParamsOrigin({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChangeOrigin(params) {
      this.updateParamsOrigin({ per_page: params.currentPerPage });
    },
    onRowClickOrigin(ev) {
      this.originRadio = ev.pageIndex;
      this.originSelected = ev.row;
    },
    loadItemsOrigin() {
      this.originRadio = "";
      var company_name = encodeURIComponent(this.data.company);
      this.originSelected = [];
      const baseURI = this.$settings.endPoint + "location";
      return this.$http
        .get(
          baseURI +
            `?status=ENABLED&region_code=${this.data.origin_region}&limit=${this.originServerParams.per_page}&page=${this.originServerParams.page}&sort_field=${this.originServerParams.sort.field}&sort_type=${this.originServerParams.sort.type}&location_code=${this.originServerParams.columnFilters.location_code}&company_name=${company_name}&name=${this.originServerParams.columnFilters.name}&address1=${this.originServerParams.columnFilters.address1}`
        )
        .then((response) => {
          this.rowsOrigin = response.data.datas.data;
          this.totalRecordsOrigin = response.data.datas.total;
        });
    },

    updateParamsDest(newProps) {
      this.destServerParams = Object.assign(
        {},
        this.destServerParams,
        newProps
      );
      this.loadItemsDest();
    },

    onColumnFilterDest(params) {
      params.page = 1;
      this.updateParamsDest(params);
    },

    onPageChangeDest(params) {
      this.updateParamsDest({ page: params.currentPage });
    },

    onSortChangeDest(params) {
      this.updateParamsDest({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChangeDest(params) {
      this.updateParamsDest({ per_page: params.currentPerPage });
    },
    onRowClickDest(ev) {
      this.destRadio = ev.pageIndex;
      this.destSelected = ev.row;
    },
    loadItemsDest() {
      this.destRadio = "";
      this.destSelected = [];
      var company_name = encodeURIComponent(this.data.company);
      const baseURI = this.$settings.endPoint + "location";
      return this.$http
        .get(
          baseURI +
            `?status=ENABLED&region_code=${this.data.destination_region}&limit=${this.destServerParams.per_page}&page=${this.destServerParams.page}&sort_field=${this.destServerParams.sort.field}&sort_type=${this.destServerParams.sort.type}&location_code=${this.destServerParams.columnFilters.location_code}&company_name=${company_name}&name=${this.destServerParams.columnFilters.name}&address1=${this.destServerParams.columnFilters.address1}`
        )
        .then((response) => {
          this.rowsDestination = response.data.datas.data;
          this.totalRecordsDest = response.data.datas.total;
        });
    },
    sendData() {
      let datas = {
        destination_location: this.destSelected,
        origin_location: this.originSelected,
      };
      this.$emit("closeModal", datas);
    },
    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },
    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },
  },
  mounted() {
    //this.loadItems();
    // this.rows = this.props.data;
  },
};
</script>

<style scoped>
.blur-content {
  filter: blur(5px);
  pointer-events: none;
}

.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 120%;
  height: 100%;
  /* margin-left: -10%; */
}
</style>
