<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>   
    <div class="loadertext" v-if="this.isLoading"> {{$t('loadingTxt')}}...</div>   
    <div class="main-wrapper" :class="{'blur-content': this.isLoading}">
      <!--begin::sidebar-->
      <sidebar-component classMenu="FakturPajakAdd"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            ">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left: 25px">
                    {{ $t("FakturPajakAdd") }}
                  </h6>
                  <button class="btn btn-default btn-sm float-end" @click="resetForm" type="button">
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form class="forms-sample" @submit.prevent="submitData" method="POST">
                    <div class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "></div>

                    <div class="row mb-3">

                      <div class="col-lg-6">

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">ID
                              Dipotong</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" class="form-control" id="id_dipotong" :placeholder="id_dipotong"
                              v-model="forms.id_dipotong" required />
                            <div v-if="errors.id_dipotong">
                              <div v-for="error in errors.id_dipotong" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Nama
                              Dipotong</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" class="form-control" id="nama_dipotong" :placeholder="nama_dipotong"
                              v-model="forms.nama_dipotong" />


                            <div v-if="errors.nama_dipotong">
                              <div v-for="error in errors.nama_dipotong" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Pasal</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" class="form-control" id="pasal" :placeholder="pasal"
                              v-model="forms.pasal" />
                            <div v-if="errors.pasal">
                              <div v-for="error in errors.pasal" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Kode Objek
                              Pajak</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" @input="formatKode()" class="form-control" id="kode_objek_pajak"
                              placeholder="ex. 00-000-00" v-model="forms.kode_objek_pajak" />
                            <div v-if="errors.kode_objek_pajak">
                              <div v-for="error in errors.kode_objek_pajak" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">No Bukti
                              Potong</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" class="form-control" id="no_bukti_potong" :placeholder="no_bukti_potong"
                              v-model="forms.no_bukti_potong" />
                            <div v-if="errors.no_bukti_potong">
                              <div v-for="error in errors.no_bukti_potong" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                      <div class="col-lg-6">

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Tgl Bukti
                              Potong</label>
                          </div>
                          <div class="col-lg-8">
                            <Datepicker id="tanggal_bukti_potong" v-model="forms.tanggal_bukti_potong"
                              :bootstrap-styling=true minimum-view="month" format="MM-yyyy"
                              @input="forms.tanggal_bukti_potong = fixDate($event)"
                              :placeholder="forms.tanggal_bukti_potong"></Datepicker>

                            <div v-if="errors.tanggal_bukti_potong">
                              <div v-for="error in errors.tanggal_bukti_potong" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Pph
                              Dipotong</label>
                          </div>
                          <div class="col-lg-8">
                            <money v-model="forms.pph_dipotong" v-bind="money" class="form-control"></money>
                            <!--input type="text" class="form-control" id="pph_dipotong" :placeholder="pph_dipotong"
                              v-model="forms.pph_dipotong" /-->
                            <div v-if="errors.pph_dipotong">
                              <div v-for="error in errors.pph_dipotong" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Jumlah
                              Bruto</label>
                          </div>
                          <div class="col-lg-8">
                            <money v-model="forms.jumlah_bruto" v-bind="money" class="form-control"></money>
                            <!--input type="text" class="form-control" id="jumlah_bruto" :placeholder="jumlah_bruto"
                              v-model="forms.jumlah_bruto" /-->
                            <div v-if="errors.jumlah_bruto">
                              <div v-for="error in errors.jumlah_bruto" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label"
                              style="padding-left: 25px">Keterangan</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" class="form-control" id="keterangan" :placeholder="keterangan"
                              v-model="forms.keterangan" />
                            <div v-if="errors.keterangan">
                              <div v-for="error in errors.keterangan" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">File</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="file" ref="file" class="form-control" @change="handleFileUpload($event)" />
                            <div v-if="errors.file">
                              <div v-for="error in errors.file" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="card-footer">
                      <button class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        " @click="backForm" type="button">
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button class="btn btn-primary float-end btn-sm" type="submit">
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import myMixinAddress from '@/middleware/mixinAddress.js';
import Datepicker from "vuejs-datepicker";
import { Money } from 'v-money';


export default {
  name: "FakturPajakAdd",
  props: {},
  mixins: [myMixinAddress],
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    Datepicker, Money
  },
  data() {
    return {
      file: '',
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      permision_role: [],
      //  userData: "",
      //  companyCode: "",
      forms: {
        name: "", username: "", keterangan: ""},
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        suffix: '',
        masked: false /* doesn't work with directive */
      },
    };
  },
  watch: {},
  methods: {
    formatKode() {
      //console.log(this.forms.kode_objek_pajak)
      let x = this.forms.kode_objek_pajak
        .replace(/\D/g, "")
        .match(/(\d{0,2})(\d{0,3})(\d{0,2})/);

      this.forms.kode_objek_pajak = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");

    },
    fixDate(event) {
      return this.$moment(event).format('MM-YYYY');
    },
    resetForm() {
      this.forms.id_dipotong = "";
      this.forms.nama_dipotong = "";
      this.forms.pasal = "";
      this.forms.kode_objek_pajak = "";
      this.forms.no_bukti_potong = "";
      this.forms.tanggal_bukti_potong = "";
      this.forms.pph_dipotong = "";
      this.forms.jumlah_bruto = "";
      this.forms.keterangan = "";
      //this.forms.file = "";
    },

    handleFileUpload(event) {
      this.file = event.target.files[0];
    },

    backForm() {
      window.location.href = "/faktur-pajak";
    },

    submitData() {
      this.fade(true);

      let formData = new FormData();
      // console.log(this.file);
      // single xlsx
      formData.append("file", this.file);
      formData.append("id_dipotong", this.forms.id_dipotong);
      formData.append("nama_dipotong", this.forms.nama_dipotong);
      formData.append("pasal", this.forms.pasal);
      formData.append("kode_objek_pajak", this.forms.kode_objek_pajak);
      formData.append("no_bukti_potong", this.forms.no_bukti_potong);
      formData.append("tanggal_bukti_potong", this.forms.tanggal_bukti_potong);
      formData.append("pph_dipotong", this.forms.pph_dipotong);
      formData.append("jumlah_bruto", this.forms.jumlah_bruto);
      formData.append("keterangan", this.forms.keterangan);

      this.isLoading = true;
      const baseURI = this.$settings.endPointFP + "finance/root/faktur-pajak/create";
      this.$http.post(baseURI, formData).then((response) => {
        this.isLoading = false;
        if (response.data.status === 200) {
          this.errors = [];
          var params = this.$onRandom(response.data.datas.tax_slip_id);
          window.location.href = "/faktur-pajak/detail/" + params + "?status=200&msg=Successfully";
        } else {
          this.errors = response.data.errors;
          this.resultError(response.data.errors);
        }
      }).catch(error => {
          this.isLoading = false;
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                      this.errors = error.response.data.errors;
                      this.resultError(error.response.data.errors);
              }else if (error.response.status === 500) {
                  this.$router.push('/server-error');
              }else{
                  this.$router.push('/page-not-found');
              }
            }

          }

        });
 
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "FakturPajakController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () { },
  mounted() {
    this.fetchIt();
    this.loading();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}
.blur-content{
  filter: blur(5px); 
}
</style>