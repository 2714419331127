var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_vm._m(0),_c('vue-good-table',{attrs:{"title":"list-of-orders","mode":"remote","line-numbers":true,"isLoading":_vm.isLoading,"pagination-options":{
      enabled: false,
    },"filterOptions":{
      enabled: false
    },"rows":_vm.rowsTableOrder,"columns":_vm.columnsOrder,"sort-options":{
      enabled: false,
    }},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'branch_name')?_c('span',[(props.row.nmk.substring(0, 1) == 'N')?_c('p',[_vm._v(" Jakarta ")]):_vm._e(),(props.row.nmk.substring(0, 1) == 'M')?_c('p',[_vm._v(" Medan ")]):_vm._e(),(props.row.nmk.substring(0, 1) == 'H')?_c('p',[_vm._v(" Semarang ")]):_vm._e(),(props.row.nmk.substring(0, 1) == 'S')?_c('p',[_vm._v(" Surabaya ")]):_vm._e(),(props.row.nmk.substring(0, 1) == 'I')?_c('p',[_vm._v(" Jakarta ")]):_vm._e()]):_vm._e(),(props.column.field == 'control')?_c('span',[_c('a',{attrs:{"href":"#","title":"Edit"},on:{"click":function($event){$event.preventDefault();return _vm.EditFormModal(props.row)}}},[_c('i',{staticClass:"mdi mdi-square-edit-outline",staticStyle:{"font-size":"16px"}})])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),_c('modal-driver-id',{attrs:{"showModal":_vm.showModalNow,"data":_vm.detailData},on:{"closeModal":_vm.closeMyModal}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"}),_c('div',{staticClass:"col-lg-6"})])
}]

export { render, staticRenderFns }