import { render, staticRenderFns } from "./RequestRgAdd.vue?vue&type=template&id=09aa72ff&scoped=true"
import script from "./RequestRgAdd.vue?vue&type=script&lang=js"
export * from "./RequestRgAdd.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09aa72ff",
  null
  
)

export default component.exports