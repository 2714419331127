<template>
    <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Form {{ $t("teguran") }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
              aria-label="btn-close"></button>
          </div>
  
          <form class="forms-sample" method="POST" @submit.prevent="submitData">
            <div class="modal-body">
              <div v-if="isLoading" class="d-flex align-items-center">
                <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                <strong>Loading...</strong>
              </div>
              <!--end::loading-data-->  
              <div class="mb-3">
                <label class="form-label">Date </label>
                <Datepicker
                  v-model="forms.tanggal_teguran"
                  :bootstrap-styling="true"
                  format="dd-MM-yyyy"
                  placeholder="Please Select Date..."
                  
                ></Datepicker>
               
              </div>

            <div class="mb-3">
                <label class="form-label">{{ $t("teguran") }}</label>
                <input type="text" id="teguran" name="teguran" v-model="forms.teguran" class="form-control"
                     />
            </div>

            

          
             
  
             
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
                Close
              </button>
              <button class="btn btn-primary" type="submit">Save changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Datepicker from "vuejs-datepicker";
  import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
  import moment from "moment";
  
  export default {
    
    components: {
      Datepicker
      // DateRangePicker
    },
    name: "ModalFormTeguran",
    emits: ["closeModal"],
    props: {
      showModal: Boolean,
      data: null,
      master_driver_id: null,
    },
    watch: {
      showModal: {
        handler(newVal) {
          if (this.data != '') {
  
            // const startDate = this.data.delivery_date;
            // const endDate = new Date();

            // const startDate2 = this.data.return_date;
            // const endDate2 = new Date();

            this.forms = {
              driver_teguran_id: this.data.driver_teguran_id,
              master_driver_id: this.master_driver_id,
              tanggal_teguran: this.data.tanggal_teguran,
              teguran: this.data.teguran,
              jenis: 'edit'
            };
  
          
  
          } else {
  
            //const startDate = new Date();
            //const endDate = new Date();
            this.forms = {
              driver_teguran_id: '',
              master_driver_id: this.master_driver_id,
              tanggal_teguran: '',
              teguran: '',
              jenis: 'add'
            };
  
           
          }
  
          this.active = newVal;
          const body = document.querySelector("body");
          this.showModal
            ? body.classList.add("modal-open")
            : body.classList.remove("modal-open");
        },
        immediate: true,
        deep: true,
      },
    },
    data() {
      return {
        isLoading: false,
        active: this.showModal,
        forms: [],
        locale: [],
        return_date: [],
        delivery_date: [],
        equipment_name: [],
        fetchEquipmentName : [],
      };
    },
    methods: {
      resetForm() {
        this.forms = [];
      },
  
      submitData() {
        if (this.forms.jenis == 'add') {
          //this.forms.master_driver_id = this.master_driver_id;
          this.forms.tanggal_teguran = moment(
            String(this.forms.tanggal_teguran)
          ).format("YYYY-MM-DD");
        

          const baseURI = this.$settings.endPointCt + "driver-teguran/create";
          this.$http
            .post(baseURI, this.forms)
            .then((response) => {
              this.isloading = true;
              if (response.data.status === 200) {
                this.resetForm();
                this.$emit("closeModal");
                this.loadSuccess("Success");
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.isloading = true;
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                }
              }
              //this.resetForm();
            });
  
        } else {
     
            this.forms.tanggal_teguran = moment(
            String(this.forms.tanggal_teguran)
          ).format("YYYY-MM-DD");


          const baseURI = this.$settings.endPointCt + "driver-teguran/update" + "/" + this.forms.driver_teguran_id;
          this.$http
            .patch(baseURI, this.forms)
            .then((response) => {
              this.isloading = true;
              if (response.data.status === 200) {
                this.resetForm();
                this.$emit("closeModal");
                this.loadSuccess("Success");
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.isloading = true;
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                }
              }
              //this.resetForm();
            });
        }
  
      },
  
      // loadDetail(data) {
      //   console.log(data);
      // },
  
      resultError(data) {
        var html = "";
        var count = Object.keys(data).length;
        for (var x = 0; x < count; x++) {
          var nameOb = Object.keys(data)[x];
          var objectData = data[nameOb];
          for (var y = 0; y < objectData.length; y++) {
            html += objectData[y] + "<br>";
          }
        }
  
        this.loadError(html);
      },
  
      loadError(pesan) {
        this.$swal({
          backdrop: true,
          title: "Warning",
          html: pesan,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      },
  

  
      loadSuccess(pesan) {
        this.$swal({
          backdrop: true,
          title: "Success",
          html: pesan,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      },
  
      loadEquipment() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=equip `;

      return this.$http.get(baseURI).then((response) => {
        this.equipment_name = response.data.datas;
      });
    },
    },
    created: function () {
      //this.loadDriver();
  
    },
    mounted() {
      //tail(this.data);
      this.loadEquipment();
    },
  };
  </script>
  
  <style scoped>
  .modal {
    background: rgba(0, 0, 0, 0.4);
  }
  
  .modal-content {
    background: #fff;
    border-radius: 0px;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 0px;
    width: 100%;
    height: 100%;
    margin-left:0px !important;
  }
  </style>