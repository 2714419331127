import Package from "@/components/master/package/Package.vue";
import PackageAdd from "@/components/master/package/PackageAdd.vue";
import PackageDetail from "@/components/master/package/PackageDetail.vue";
import PackageEdit from "@/components/master/package/PackageEdit.vue";
import PackageUpload from "@/components/master/package/PackageUpload.vue";


let routesPackage= [
    
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "PackageIndex",
        component: Package
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "PackageAdd",
        component: PackageAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "PackageEdit",
        component: PackageEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "PackageDetail",
        component: PackageDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "PackageUpload",
        component: PackageUpload
      },
  ]
  
  export default routesPackage
  