<template>
    <div :class="{'blur-content': isLoading}">
      <div class="main-wrapper">
        <!--begin::sidebar-->
        <sidebar-component classMenu="Company"></sidebar-component>
        <!--end::sidebar-->
  
        <div class="page-wrapper">
          <!--begin::navbar-->
          <navbar-component></navbar-component>
          <!--end::navbar-->
  
          <!--begin::content-->
          <div class="page-content">
            <div
              class="
                d-flex
                justify-content-between
                align-items-center
                flex-wrap
                grid-margin
              "
            >
              <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
                <div class="card">
                  <div class="card-header">
                        <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                         &nbsp;&nbsp;&nbsp;
                        <h6 class="card-title mb-0 float-start" style="padding-left: 25px">{{ $t("vendorAdd") }}</h6>
                        <button
                          class="btn btn-default btn-sm float-end"
                          @click="resetForm"
                          type="button"
                        >
                        <i class="link-icon" data-feather="repeat"></i>
                          {{ $t("resetFormTxt") }}
                        </button>
                  </div>
                  <div class="card-body">
                    <!--begin::loading-data-->
                    <div v-if="isLoading" class="d-flex align-items-center">
                      <div
                        class="spinner-border ms-auto"
                        role="status"
                        aria-hidden="true"
                      ></div>
                      <strong>{{ $t("loadingTxt") }}...</strong>
                    </div>
                    <!--end::loading-data-->
  
                    <form
                      class="forms-sample"
                      @submit.prevent="submitData"
                      method="POST"
                    >
                      <div
                        class="
                          d-flex
                          justify-content-between
                          align-items-baseline
                          mb-2
                        "
                      >
                      
                      </div>
                      
                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                            $t('company_type_txt')
                          }}</label>
                        </div>
                        <div class="col-lg-8">
                            <input
                              type="text"
                              class="form-control"
                              id="company_type"
                              :placeholder="$t('company_type_txt')"
                              v-model="forms.company_type"
                              disabled
                            />
  
                          <div v-if="errors.company_type">
                            <div
                              v-for="error in errors.company_type"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                            $t('companyCode')
                          }}</label>
                        </div>
                        <div class="col-lg-8">
                          <input
                            type="text"
                            class="form-control"
                            id="code"
                            :placeholder="$t('companyCode')"
                            v-model="forms.code"
                            required
                          />
                          <div v-if="errors.code">
                            <div
                              v-for="error in errors.code"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
  
  
                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                            $t('companyName')
                          }}</label>
                        </div>
                        <div class="col-lg-8">
                          <input
                            type="text"
                            class="form-control"
                            id="name"
                            :placeholder="$t('companyName')"
                            v-model="forms.name"
                            required
                          />
                          <div v-if="errors.name">
                            <div
                              v-for="error in errors.name"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
  
  
                    
  
                       <div class="row mb-3">
                        <div class="col-lg-2">
                          <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('emailAddress')}}</label>
                        </div>
                        <div class="col-lg-8">
                          <input
                            type="text"
                            class="form-control"
                            id="country"
                            :placeholder="$t('emailAddress')"
                            v-model="forms.email"
                            
                          />
                          <div v-if="errors.email">
                            <div
                              v-for="error in errors.email"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
  
  
                       <div class="row mb-3">
                        <div class="col-lg-2">
                          <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{$t('sap_code')}}</label>
                        </div>
                        <div class="col-lg-8">
                          <input
                            type="text"
                            class="form-control"
                            id="sap_code"
                            :placeholder="$t('sap_code')"
                            v-model="forms.sap_code"
                            
                          />
                          <div v-if="errors.sap_code">
                            <div
                              v-for="error in errors.sap_code"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      
  
                      <div class="row mb-3">
                        <div class="col-lg-2">
                          <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                            $t('statusTxt')
                          }}</label>
                        </div>
                        <div class="col-lg-8">
                        <v-select
                            :options="fetchStatus"
                            v-model="forms.status"
                            return-object
                          ></v-select>
  
  
  
                          <div v-if="errors.status">
                            <div
                              v-for="error in errors.status"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                     
                      <div class="card-footer">
                        <button
                          class="
                            btn btn-warning
                            text-black
                            fw-bolder
                            btn-icon-text
                          "
                          @click="backForm"
                          type="button"
                        >
                          <i class="link-icon" data-feather="arrow-left"></i>
                          {{ $t("backMess") }}
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button class="btn btn-primary float-end btn-sm" type="submit">
                             <i class="link-icon" data-feather="save"></i>
                          {{ $t("submitFormTxt") }}
                        </button>
                      </div>
                    </form>
                  </div>
                </div> <!--end::card-->
              </div>
            </div>
          </div>
          <!--end::content-->
  
          <!--begin::footer-->
          <footer-component></footer-component>
          <!--end::footer-->
        </div>
      </div>
    </div>
</template>
  
  
  <script>
  import sidebarComponent from "@/components/_partials/_sidebar";
  import navbarComponent from "@/components/_partials/_navbar";
  import footerComponent from "@/components/_partials/_footer";
  
  export default {
    name: "FakturPajakVendorAdd",
    props: {},
    components: {
      "sidebar-component": sidebarComponent,
      "navbar-component": navbarComponent,
      "footer-component": footerComponent,
    },
    data() {
      return {
        fetchStatus:["active","deactived"],
        permision_role:[],
        isLoading: false,
        maxToasts: 100,
        position: "up right",
        closeBtn: true,
        errors: [],
        fetchSystemCode:[],
        company_type:"",
        userData: "",
        userRole: "",
        companyCode: "",
        forms: { company_id: "", code: "" ,name:"" ,email:"",
        status:"",
        sap_code:"",
        create_by:"",
        update_by:"",
        created_at:"",
        updated_at:"",
        company_type:"FP"},
      };
    },
    watch: {},
    methods: {
  
      resetForm() {
        this.forms.company_id = ""
        this.forms.code = ""
        this.forms.name = ""
        this.forms.email = ""
        this.forms.status = ""
        this.forms.sap_code = ""
        this.forms.create_by = ""
        this.forms.update_by = ""
        this.forms.created_at = ""
        this.forms.updated_at = ""
        this.forms.company_type = "FP"  
      },
  
      fetchIt() {
        const userDatas     = this.$getUserInfo();
        this.userData       = userDatas.sub;
        this.userRole       = userDatas.sub.role;
        // this.permision_role = userDatas.sub.permision_role;
        let rolePermision = [];
        userDatas.sub.permision_role.forEach(function(permision){
          if(permision.permission.controller == 'VendorFPController'){
            rolePermision = permision;
            // console.log(permision);
          }
        });
  
        if(!rolePermision.method_create){
          this.$router.push("/authorized-error");
        }
        this.permision_role = rolePermision;
  
      },
  
      backForm() {
         window.location.href = "/faktur-pajak/vendor";
      },
  
  
      submitData() {
            this.isLoading = true;
            
            const baseURI  =  this.$settings.endPoint+"company-faktur-pajak/create";
            this.$http.post(baseURI,this.forms,{ timeout: 10000 }).then((response) => {
                this.loading();
                if(response.data.status === 200) {
                //this.resetForm();
                    this.isLoading = false;
                    this.errors = [];
                    //console.log(response.data.datas.user_id);
                    var params = this.$onRandom(response.data.datas.company_id);
                      window.location.href = "/faktur-pajak/vendor/detail/" + params + "?status=200&msg=Successfully";
                    // this.success('Berhasil');
                }else{
                    this.isLoading = false;
                    this.errors = response.data.errors;
                    this.resultError(response.data.errors);
                }
            }).catch(error => {
                  this.loading();
                  this.isLoading = false;
                  if(error.code == "ECONNABORTED"){
                    this.error("Please Check Your Internet Connection");
                  }
  
                if (error.response) {
                  if(error.response.status === 422) {
                          this.errors = error.response.data.errors;
                          this.resultError(error.response.data.errors);
                  }else if (error.response.status === 500) {
                      this.$router.push('/server-error');
                  }else{
                      this.$router.push('/page-not-found');
                  }
                }
                //this.resetForm();
            });
      },
  
      fade(sType) {
        this.isLoading = sType;
      },
  
      loading() {
        this.fade(true);
        setTimeout(() => {
          this.fade(false);
        }, 1000); // hide the message after 3 seconds
      },
  
      resultError(data) {
        var count = Object.keys(data).length;
        for (var x = 0; x < count; x++) {
          var nameOb = Object.keys(data)[x];
          var objectData = data[nameOb];
          for (var y = 0; y < objectData.length; y++) {
            this.error(objectData[y]);
          }
        }
      },
  
      success(kata) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: kata,
        });
      },
  
      error(kata) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: kata,
        });
      },
    },
    events: {},
    created: function () {},
    mounted() {
      this.fetchIt();
    },
  };
  </script>
  <style scoped>
  .blur-content{
    filter: blur(5px); 
  }
  </style>