import DashboardProformaInvoice from "@/components/dasboard-ct/proformaInvoice/DashboardProformaInvoice.vue";
import ProformaInvoiceCreated from "@/components/dasboard-ct/proformaInvoice/ProformaInvoiceCreated.vue";
import ProformaInvoicePosting from "@/components/dasboard-ct/proformaInvoice/ProformaInvoicePosting.vue";
import ProformaInvoiceCreate from "@/components/dasboard-ct/proformaInvoice/ProformaInvoiceCreate.vue";
import ProformaInvoicePrinted from "@/components/dasboard-ct/proformaInvoice/ProformaInvoicePrinted.vue";

let routesProformaInvoice= [
      {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "DashboardProformaInvoice",
        component: DashboardProformaInvoice
      },
      {
        path: 'created',
        meta: {
          title: "Chain System",
        },
        name: "ProformaInvoiceCreated",
        component: ProformaInvoiceCreated
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "ProformaInvoiceCreate",
        component: ProformaInvoiceCreate
      },
      {
        path: 'post',
        meta: {
          title: "Chain System",
        },
        name: "ProformaInvoicePosting",
        component: ProformaInvoicePosting
      },
      
      {
        path: 'print',
        meta: {
          title: "Chain System",
        },
        name: "ProformaInvoicePrinted",
        component: ProformaInvoicePrinted
      }
    
]

export default routesProformaInvoice
  