<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div :class="{'blur-content': this.isLoading}">
      <div class="main-wrapper">
        <!--begin::sidebar-->
        <sidebar-component classMenu="TransportOrderCt"></sidebar-component>
        <!--end::sidebar-->

        <div class="page-wrapper">
          <!--begin::navbar-->
          <navbar-component></navbar-component>
          <!--end::navbar-->

          <!--begin::content-->
          <div class="page-content">

            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">

              <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">

                <div>
                  <h4 style="margin-right: 5px" class="float-start"></h4>
                  <h4 class="mb-12 mb-md-0">
                    Truck Utilization Report <b>{{detailInfo[0]["vehicle_no"]}}</b>
                  </h4>
                </div>
                <div class="d-flex align-items-center flex-wrap text-nowrap">
                  <button v-if="permision_role.method_update" class="btn btn-warning btn-icon-text mb-2 mb-md-0 ml-1"
                    style="margin-right: 5px" @click.prevent="detailData()">
                    Back To All Vehicle No
                  </button>
                </div>
              </div>
            </div>



            <div class="row">
              <div class="col-12 col-xl-12 stretch-card">
                <div class="row flex-grow-1">
                  <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <div class="table-responsive">
                          <table class="table table-hover mb-0">
                            <tbody>
                              <tr>
                                <td>Vehicle No</td>
                                <td><span class="badge bg-info">{{detailInfo[0]["vehicle_no"]}}</span></td>
                                <td>Branch</td>
                                <td><span class="badge bg-info">{{detailInfo[0]["branch_name"]}}</span></td>
                                <td>Active</td>
                                <td><span class="badge bg-primary">{{detailInfo[0]["active"]}}</span></td>
                                <td>Idle</td>
                                <td><span class="badge bg-primary">{{detailInfo[0]["idle"]}}</span></td>

                              </tr>
                              <tr>
                                <td>Ownership</td>
                                <td><span class="badge bg-info">{{detailInfo[0]["ownership"]}}</span></td>
                                <td>Project</td>
                                <td><span class="badge bg-info">{{detailInfo[0]["project"]}}</span></td>
                                <td>Service</td>
                                <td><span class="badge bg-primary">{{detailInfo[0]["service"]}}</span></td>
                                <td>No Driver</td>
                                <td><span class="badge bg-primary">{{detailInfo[0]["no_driver"]}}</span></td>

                              </tr>
                              <tr>
                                <td>Carrier</td>
                                <td><span class="badge bg-info">{{detailInfo[0]["carrier"]}}</span></td>
                                <td>GPS Vendor</td>
                                <td><span class="badge bg-info">{{detailInfo[0]["gps_vendor"]}}</span></td>
                                <td>Idle Cap</td>
                                <td><span class="badge bg-primary">{{detailInfo[0]["idle_cap"]}}</span></td>
                                <td>AIS</td>
                                <td><span class="badge bg-primary">{{detailInfo[0]["ais"]}}</span></td>

                              </tr>
                              <tr>
                                <td>Truck Type</td>
                                <td><span class="badge bg-info">{{detailInfo[0]["type_truck"]}}</span></td>
                                <td></td>
                                <td></td>
                                <td>Holiday</td>
                                <td><span class="badge bg-primary">{{detailInfo[0]["holiday"]}}</span></td>
                                <td>Total</td>
                                <td><span class="badge bg-primary">{{detailInfo[0]["total"]}}</span></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-12 col-xl-12 stretch-card">
              <div class="card">
                <div class="card-header">
                  <div class="row">
                    <div class="col-lg-3">
                      <div class="input-group flatpickr wd-220 me-2 mb-2 mb-md-0" id="dashboardDate">
                        <date-range-picker style="width: 100%" @change="pickupChange"
                          control-container-class="form-control" :locale-data="locale" v-model="dateFilter"
                          :opens="opens" :singleDatePicker="false" :timePicker24Hour="true" :timePicker="true"
                          :showWeekNumbers="false">
                        </date-range-picker>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <v-select control-container-class="form-control" :options="listStatus" placeholder="Filter By Status" v-model="status" return-object></v-select>
                    </div>

                    <div class="col-lg-4"><button class="button btn-lg btn-success" @click="getData()">Go</button></div>
                  </div>


                </div>

                <div class="card-body">

                  <div class="table-responsive" style="overflow-y: auto;">
                    <div class="col-sm-12">

                      <vue-good-table title="list-order-ct" mode="remote" :select-options="{ 
                      enabled: false,
                      selectOnCheckboxOnly: true, 
                      selectionText: $t('data_selected'), 
                      clearSelectionText: $t('clear'), }" @on-selected-rows-change="selectionChanged"
                        @on-page-change="onPageChange" @on-sort-change="onSortChange"
                        @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :line-numbers="true"
                        :isLoading.sync="isLoading" :pagination-options="{
                          enabled: false,
                        }" :rows="rows" :columns="columns">
                        <template slot="table-row" slot-scope="props">


                          <span v-if="props.column.field == 'action'">

                            <a style="color: #ab0635;text-decoration: none;" v-if="permision_role.method_update"
                              title="Edit" @click.prevent="editData(props.row)">
                              <i class="mdi mdi-square-edit-outline" style="font-size:16px"></i>

                            </a>

                          </span>

                          <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                          </span>
                        </template>
                      </vue-good-table>

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!--end::content-->
          <modal-edit-truck-detail :showModal="showModalTruckDetail" :data="this.dataModal" @closeModal="closeMyModal">
          </modal-edit-truck-detail>
          <!--begin::footer-->
          <footer-component></footer-component>
          <!--end::footer-->
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import DateRangePicker from "vue2-daterange-picker";
import ModalTruckDetailUtility from "@/components/modal/modalTruckDetailUtility.vue";

import moment from 'moment';

export default {
  name: "DriverAttendance",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "modal-edit-truck-detail": ModalTruckDetailUtility,
    DateRangePicker,
  },
  data() {
    const startDate = moment().clone().startOf('month').format('YYYY-MM-DD'); // new Date();
    const endDate = new Date();
    return {
      listStatus:["ACTIVE","SERVICE","NO DRIVER","IDLE","IDLE CAP","HOLIDAY","AIS"],
      status:'',
      showModalTruckDetail: false,
      dateFilter: {
        startDate,
        endDate,
      },
      vehicle_no: '',
      dataModal: {},
      // truck_utility_detail_id:'',
      maxToasts: 100,
      selected: '',
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      permision_role: [],
      langs: ["id", "en"],
      detailUser: [],
      detailDivisi: [],
      totalRecords: 0,
      serverParams: {
        // columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 1000,
      },
      detailInfo: {},
      columns: [
        {
          label: "Control",
          field: "action",
          sortable: false,
        },
        {
          label: "Date",
          field: "date",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Created At",
          field: "created_at",
        },
        {
          label: "Updated At",
          field: "updated_at",
        },
      ],
      rows: [],
      // rowsSelection:[],
    };
  },
  watch: {},
  computed: {
  },
  methods: {
    detailData() {
            window.location.href = "/truckUtilitationReport";
        },
    closeMyModal() {
      this.showModalTruckDetail = false;
      this.getData();
    },
    pickupChange() { },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == 'TruckUtilityDetailController') {
          rolePermision = permision;
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;

      if (this.$route.query.msg) {
        if (this.$route.query.status == 200) {
          this.success(this.$route.query.msg)
        } else {
          this.error(this.$route.query.msg)
        }

      }

    },



    submitUpdateRow(val_status, id) {

      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
          let data = {
            truck_utility_detail_ids: id.toString().split(','),
            status: val_status
          }
          const baseURI =
            this.$settings.endPointCt + "truck-utility-detail/update";
          this.$http
            .patch(baseURI, data, { timeout: 30000 })
            .then((response) => {
              //console.log(response);
              if (response.status === 200) {
                this.success2(response.data.datas.messages);
                this.loadItems();
                this.isloading = false
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            }).catch(error => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {

                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.$router.push('/server-error');
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push('/page-not-found');
                  }
                }

              }

            });
        }
      });
    },

    updateFormatDate(date) {
      let date_format = this.$moment(date).format("dd mmm yyyy");
      return date_format;
    },

    editData(row) {
      this.showModalTruckDetail = true;
      this.dataModal = {
        truck_utility_detail_id: row.truck_utility_detail_id,
        vehicle_no: row.vehicle_no,
        dates: row.date,
        status: row.status
      }

      // var params = this.$onRandom(row.transport_order_header_id);
      // window.location.href = "/ct-orders/edit/" + params;
    },

    getDataFirst(vvehicle, vstart, vend) {
      this.dateFilter.startDate = vstart
      this.dateFilter.endDate = vend
      this.vehicle_no = vvehicle
      let dataSend = {
        start: vstart,
        end: vend,
        vehicle_no: vvehicle
      };
      this.loadItems(dataSend);
      this.loadDetail(dataSend);

    },

    getData() {
      let dataSend = {
        start: moment(String(this.dateFilter.startDate,)).format('YYYY-MM-DD'), //this.dateFilter.startDate,  // moment(String(this.forms.join_date)).format('YYYY-MM-DD');
        end: moment(String(this.dateFilter.endDate,)).format('YYYY-MM-DD'),
        vehicle_no: this.vehicle_no
      };
      this.loadItems(dataSend);
      this.loadDetail(dataSend);
    },

    // load items is what brings back the rows from server
    loadItems(dataSend) {
      // let tanggal = this.filtertanggal.toISOString().split('T')[0]
      this.isLoading = true;
      const baseURI = this.$settings.endPointCt + "truck-utility-detail?vehicle_no=" + dataSend.vehicle_no + "&start=" + dataSend.start + "&end=" + dataSend.end + "&status=" + this.status;

      return this.$http
        .get(
          baseURI,
          // +
          // `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&company_name=${company_name}&transport_order_template_tonnage=${transport_order_template_tonnage}&transport_order_template_cubic=${transport_order_template_cubic}&transport_order_template_description=${transport_order_template_description}&order_status=${this.serverParams.columnFilters.order_status}&order_no=${this.serverParams.columnFilters.order_no}&postpone_time=${this.serverParams.columnFilters.postpone_time}&dest_name=${this.serverParams.columnFilters.dest_name}&order_date=${date_order}&pick_plan=${pick_plan}&dest_plan=${dest_plan}`,
          { timeout: 10000 }
        )
        .then((response) => {
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
          this.isLoading = false;
        }).catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    },

    loadDetail(dataSend) {
      // let tanggal = this.filtertanggal.toISOString().split('T')[0]
      this.isLoading = true;
      const baseURI = this.$settings.endPointCt + "truck-utility-detail/utilitation?vehicle_no=" + dataSend.vehicle_no + "&start=" + dataSend.start + "&end=" + dataSend.end;

      return this.$http
        .get(
          baseURI,
          // +
          // `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&company_name=${company_name}&transport_order_template_tonnage=${transport_order_template_tonnage}&transport_order_template_cubic=${transport_order_template_cubic}&transport_order_template_description=${transport_order_template_description}&order_status=${this.serverParams.columnFilters.order_status}&order_no=${this.serverParams.columnFilters.order_no}&postpone_time=${this.serverParams.columnFilters.postpone_time}&dest_name=${this.serverParams.columnFilters.dest_name}&order_date=${date_order}&pick_plan=${pick_plan}&dest_plan=${dest_plan}`,
          { timeout: 10000 }
        )
        .then((response) => {
          this.detailInfo = response.data.datas;
        }).catch(error => {
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    success2(msg) {
      // let pesan="";
      // msg.forEach((item) => {
      //     pesan=pesan+"<p>* "+item+"</p>";
      // });

      this.$swal({
        icon: 'info',
        backdrop: true,
        title: "Information",
        html: msg,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      })


    },


    loadingSubmiting() {


      this.$swal({
        icon: 'warning',
        backdrop: true,
        title: "Please wait",
        html: '<h2>SUBMITTING ORDER...</h2>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

    },


    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },

  },
  events: {},
  created: function () { },
  mounted() {
    this.fetchIt();
    this.loading();
    // var vehicle = this.$onBehind(this.$route.params.vehicle);
    var vehicle = this.$route.params.vehicle;
    var start = this.$route.params.start;
    var end = this.$route.params.end;
    this.getDataFirst(vehicle, start, end);
    //this.loadItems();


  },
};
</script>
<style scoped>
.blur-content {
  filter: blur(5px);
  pointer-events: none;
}

.card-body {
  padding: 5px 1.5rem 1rem;
}

.vgt-table th {
  text-align: left !important;
}

.toggleFilterClass {
  display: none !important;
}
</style>