import PayableRateByCustomer from "@/components/dasboard-rm/payable_rate/PayableRateByCustomer.vue";
import PayableRateDetailByCustomer from "@/components/dasboard-rm/payable_rate/PayableRateDetailByCustomer.vue";
import PayableRateAddByCustomer from "@/components/dasboard-rm/payable_rate/PayableRateAddByCustomer.vue"
import PayableRateAddByRute from "@/components/dasboard-rm/payable_rate/PayableRateAddByRute.vue"
import PayableRateDetailDetailByCustomer from "@/components/dasboard-rm/payable_rate/PayableRateDetailDetailByCustomer.vue"
import PayableRateEditByCustomer from "@/components/dasboard-rm/payable_rate/PayableRateEditByCustomer.vue"
import PayableRateByRute from "@/components/dasboard-rm/payable_rate/PayableRateDetailByRute.vue";
import PayableRateDetailByRute from "@/components/dasboard-rm/payable_rate/PayableRateDetailDetailByRute.vue";
import PayableRateEditByRute from "@/components/dasboard-rm/payable_rate/PayableRateEditByRute.vue"
import PayableRateByArchiving from "@/components/dasboard-rm/payable_rate/PayabelRateArchiving.vue";
import PayableRateDetailByArchiving from "@/components/dasboard-rm/payable_rate/PayableRateDetailByArchiving.vue";
import PayableRateArchivingDetailDetail from "@/components/dasboard-rm/payable_rate/PayableRateArchivingDetailDetail.vue";

let routes= [
    
    {
      path: 'customer',
      meta: {
        title: "Chain System",
      },
      name: "PayableRateByCustomer",
      component: PayableRateByCustomer
    },
    {
      path: 'archiving',
      meta: {
        title: "Chain System",
      },
      name: "PayableRateByArchiving",
      component:PayableRateByArchiving
    },
    {
      path: 'archiving/detail/:id',
      meta: {
        title: "Chain System",
      },
      name: "PayableRateDetailByArchiving",
      component:PayableRateDetailByArchiving
    },
    {
      path: 'archiving/detail/detail/:id',
      meta: {
        title: "Chain System",
      },
      name: "PayableRateArchivingDetailDetail",
      component:PayableRateArchivingDetailDetail
    },

    
    {
      path: 'rute',
      meta: {
        title: "Chain System",
      },
      name: "PayableRateByRute",
      component: PayableRateByRute
    },
    {
      path: 'rute/detail/:detailId',
      meta: {
        title: "Chain System",
      },
      name: "PayableRateDetailDetailByRute",
      component: PayableRateDetailByRute
    },

    {
      path: 'rute/add',
      meta: {
        title: "Chain System",
      },
      name: "PayableRateAddByRute",
      component: PayableRateAddByRute
    },

    {
      path: 'rute/edit/:detailId',
      meta: {
        title: "Chain System",
      },
      name: "PayableRateEditByRute",
      component: PayableRateEditByRute
    },
    {
      path: 'customer/detail/:id',
      meta: {
        title: "Chain System",
      },
      name: "PayableRateDetailByCustomer",
      component: PayableRateDetailByCustomer
    },
    {
      path: 'customer/detail/:id/add',
      meta: {
        title: "Chain System",
      },
      name: "PayableRateAddByCustomer",
      component: PayableRateAddByCustomer
    },
    
    {
      path: 'customer/detail/:id/detail/:detailId',
      meta: {
        title: "Chain System",
      },
      name: "PayableRateDetailDetailByCustomer",
      component: PayableRateDetailDetailByCustomer
    },
    {
      path: 'customer/detail/:id/edit/:detailId',
      meta: {
        title: "Chain System",
      },
      name: "PayableRateEditByCustomer",
      component: PayableRateEditByCustomer
    },
      // {
      //   path: 'add',
      //   meta: {
      //     title: "Chain System",
      //   },
      //   name: "BbmAdd",
      //   component: BbmAdd
      // },
    
      // {
      //   path: 'edit/:id',
      //   meta: {
      //     title: "Chain System",
      //   },
      //   name: "BbmEdit",
      //   component: BbmEdit
      // },
    
      // {
      //   path: 'detail/:id',
      //   meta: {
      //     title: "Chain System",
      //   },
      //   name: "BbmDetail",
      //   component: BbmDetail
      // },
    
      // {
      //   path: 'upload',
      //   meta: {
      //     title: "Chain System",
      //   },
      //   name: "BbmUpload",
      //   component: BbmUpload
      // },
  ]
  
  export default routes
  