<template>
    <div
      ref="modal"
      class="modal fade"
      :class="{ show: active, 'd-block': active }"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Priority</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              v-on:click="$emit('closeModal')"
              aria-label="btn-close"
            ></button>
          </div>
  
          <div class="modal-body">
          
            <div class="row">
              <div class="table-responsive pt-3">
									<table class="table table-striped">
										<thead>
											<tr>
												<th>No</th>
												<th>Customer</th>
												<th>Shipment Number</th>
												<th>ShipwithRef</th>
												<th>Tonnage</th>
                        <th>Kubik</th>
                        <th>Truck Type</th>
                        <th>Pickup</th>
                        <th>Drop</th>
                        <th>Tgl Pick</th>
                        <th>Tgl Drop</th>
											</tr>
										</thead>
										<tbody>
											<tr   v-for="(row, index)  in data"
                            :key="index">
												<td>{{ index + 1 }}</td>
                        <td>{{ row.company.name }}</td>
                        <td>{{ row.order_no }}</td>
                        <td>{{ row.shipwith_ref }}</td>
                        <td>{{ row.tonnage }}</td>
                        <td>{{ row.cubic }}</td>
                        <td>{{ row.type_truck }}</td>
                        <td>{{ row.pick_name }}</td>
                        <td>{{ row.dest_name }}</td>
                        <td>{{ row.pick_plan }}</td>
                        <td>{{ row.dest_plan }}</td>
                        <td>  </td>
											</tr>
											
										</tbody>
									</table>
								</div>
            </div>
          <br>
          <div class="row">
					<div class="col-md-12">

                  <div class="accordion" id="FaqAccordion">

                   
                    <div v-for="(row, i) in groupByCategory[0]" :key="i" >
                      
                      <div class="accordion-item" >
                      <h2 class="accordion-header" :id="'heading'+i">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseOne'+i" aria-expanded="true" aria-controls="collapseOne">
                          Priority {{ i }}
                        </button>
                      </h2>
                      <div :id="'collapseOne'+i" class="accordion-collapse collapse " :aria-labelledby="'headingOne'+i" data-bs-parent="#FaqAccordion">
                        <div class="accordion-body">
                         
                            <div class="table-responsive pt-3">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th>Control</th>
                                  <th>No</th>
                                  <th>Status</th>
                                  <th>Vehicle No</th>
                                  <th>Branch</th>
                                  <th>Carrier</th>
                                  <th>Type Truck</th>
                                  <th>Driver</th>
                                  <th>Jarak</th>
                                  <th>GPS</th>

                                  <th>KIR</th>
                                  <th>STNK</th>
                                  <th>Location</th>

                                </tr>
                              </thead>
                              <tbody> 
                                <tr   v-for="(row2, index)  of row"
                                      :key="index">
                                  <td>
                                    <input class="form-check-input" type="radio" @input="setPriority(i,index)" v-model="row2.checkbox" name="assigned"  id="assigned">
                                  </td>
                                  <td>{{ index + 1 }}</td>
                                  <td>{{ row2.truck_status }}</td>
                                  <td>{{ row2.vehicle_no}}</td>
                                  <td>{{ row2.branch_name}}</td>
                                  <td>{{ row2.carrier}}</td>
                                  <td>{{ row2.type_truck}}</td>
                                  <td>{{ row2.driver_name}}</td>
                                  <td>{{ row2.km}}</td>
                                  <td>{{ row2.equipment}}</td>

                                  <td>{{ row2.kir}}</td>
                                  <td>{{ row2.stnk}}</td>
                                  <td>{{ row2.location }}</td>
                                 
                                  
                                </tr>
                                
                              </tbody>
                            </table>
                          </div>

                        </div>
                      </div>
                    </div>
                    
                    </div>


                
                
                
                 
                </div>
					</div>
				</div>
        <br>
        <div class="row">
            <div class="row mb-3">
              <div class="col-lg-3">
                <label for="defaultconfig" class="col-form-label required" style="padding-left: 25px" >Automatic Process </label>
              </div>
              <div class="col-lg-8">
                <multiselect  v-model="auto_load" 
                  :show-labels="false" :options="fetch_auto_load" :custom-label="customLabel2"  placeholede="Please Select" label="name"
                  track-by="name">
                </multiselect>

              </div>
            </div>

          </div>


          </div>

          <div class="modal-footer">
              <button type="button" class="btn btn-secondary"  v-on:click="$emit('closeModal')">
                <span v-if="isLoadingReset" class="spinner-border spinner-border-sm ms-auto"
                role="status"
                aria-hidden="true"></span>Close
              </button>
              <button class="btn btn-success" type="submit" v-on:click="saveAssign">
                <span v-if="isLoading" class="spinner-border spinner-border-sm ms-auto"
                role="status"
                aria-hidden="true"></span>Assign
              </button>
          </div>

        </div>
      </div>
    </div>
  </template>
  
  <script>

  import multiselect from "vue-multiselect";
  export default {
    components: {
    "multiselect": multiselect
  },
    name: "ModalPriority",
    emits: ["closeModal"],
    props: {
      showModal: Boolean,
      data: null,
    },
    watch: {
      showModal: {
        handler(newVal) {
        this.fetch_auto_load=[];  
        this.isLoading=false;
        this.isLoadingReset=false;
        console.log(this.data);
        if(this.data.length!=0){
          this.loadItems(this.data[0].transport_order_header_id);
          this.loadShipWithRef(this.data[0].shipwith_ref);
        }

        this.fetch_auto_load.push({
          name:"Yes"
        },
        {
          name:"No"
        },{
          name:"Manual"
        }),
       
        //   this.forms = {
        //       alert_id:this.data.alert_id,
        //       alert_setting_id:this.data.alert_setting_id,
        //       vehicle_no: this.data.vehicle_no,
        //       alert_time: this.data.alert_time,
        //       alert_type: this.data.alert_type,
        //       alert_value: this.data.alert_value,
        //       alert_subtype: this.data.alert_subtype,
        //       alert_off_reason: this.data.alert_off_reason,
        //     };
         
          this.active = newVal;
          const body = document.querySelector("body");
          this.showModal
            ? body.classList.add("modal-open")
            : body.classList.remove("modal-open");
        },
        immediate: true,
        deep: true,
      },
    },
    data() {
      return {
        isLoadingReset:false,
        classColapsed:'',
        isLoading: false,
        active: this.showModal,
        forms: [],
        fetch_auto_load:[],
        auto_load:"",
        errors: [],
        locale: [],
        report_date: [],
        pickup_date: "",
        delivery_date: "",
        groupByCategory :[],
        type_error: [],
        rows:[],
        prioritycek:[],
        activePriority_1:false,
        activePriority_2:false,
        activePriority_3:false,
        activePriority_4:false,
        activePriority_5:false,
        activePriority_6:false,
        activePriority_7:false,
        activePriority_8:false,
        activePriority_9:false,
        activePriority_10:false,
        dataPriority_1:[],
        dataPriority_2:[],
        dataPriority_3:[],
        dataPriority_4:[],
        dataPriority_5:[],
        dataPriority_6:[],
        dataPriority_7:[],
        dataPriority_8:[],
        dataPriority_9:[],
        dataPriority_10:[],
        fetchDriver:[],
        detailShiftWithRef:[],
        planing:[],
      };
    },
    methods: {
      resetForm() {
        this.forms = [];
      },  

    reset(){
      this.rows=[];
      this.groupByCategory=[];
      this.loadItems(this.data[0].transport_order_header_id);
    },


   


    setPriority(i,index){
      this.planing=[];
      console.log(this.groupByCategory[0][i][index]);
      this.planing.push(this.groupByCategory[0][i][index]);

     
      console.log(this.planing);
      // console.log(ev);
      // var data={
      //   vehicle_no:row2.vehicle_no,
      //   value:'',
      // }
      // console.log(data);
    },

    uniqByKeepLast(a, key) {
      return [
          ...new Map(
              a.map(x => [key(x), x])
          ).values()
      ]
    },

    customLabel2(ev) {
      if(ev.name=='Yes'){
        return "YES - Shipment - Load Id - Appoint Load";
      }else if(ev.name=='No'){
        return "NO - Shipment - Load Id";
      }else{
        return "Manual - Shipment Only";
      }
    },

    errorAlert(msg) {
      this.$swal({
        icon: 'danger',
        backdrop: true,
        title: "Information",
        html: "<p>* " + msg + "</p>",
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
    },


    saveAssign() {
      this.isLoading=true;
      this.rows=[];

      // var uniqueArray = this.planing.filter(function(item, pos) {
      //     return this.planing.indexOf(item) == pos;
      // }); 
      // console.log(uniqueArray);
      // console.log(this.planing);

      // var filterPlaning = this.planing.filter((item) => {
      //   return (item.checkbox == true)
      // })

      // var dataSendPlanningDetail=this.uniqByKeepLast(filterPlaning, it => it.vehicle_no);
      // console.log(dataSendPlanningDetail);
      if(this.auto_load==""){
        this.isLoading = false;
        this.errorAlert('Please Select Automatic Process')
      }else{
        this.planing[0]['auto_load']=this.auto_load.name;
        console.log(this.data[0]);
      var dataSendPlaning={
          "customer_id": this.data[0].company_id,
          "customer": this.data[0].company.name,
          "shipment_number": this.data[0].order_no,
          "shipwith_ref": this.data[0].shipwith_ref,
          "tonage": this.data[0].tonnage,
          "cubic": this.data[0].cubic,
          "type_truck":this.data[0].type_truck,
          "pickup_location": this.data[0].pick_name,
          "pickup_date": this.data[0].pick_plan,
          "drop_location": this.data[0].dest_name,
          "drop_date": this.data[0].dest_date,
          "planning": this.planing
      }; 
      console.log(dataSendPlaning);
      const baseURI = this.$settings.endPointCt + "planning/create";
      return this.$http
        .post(
          baseURI,
          dataSendPlaning,
          { timeout: 10000 }
        )
        .then((response) => {

          if(response.status==200){
            this.isLoading=false;
            this.success2('Success Assign data !!');
            this.$emit("closeModal");

          }
         

          console.log(response);
        
        }).catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                // this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
      }
      
    },



    success2(msg) {

      this.$swal({
        icon: 'info',
        backdrop: true,
        title: "Information",
        html: "<p>* " + msg + "</p>",
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      })


      },

    loadItems(transport_order_header_id) {
      this.rows=[];
      const baseURI = this.$settings.endPointCt + "truck-priority/select/" + transport_order_header_id;
      return this.$http
        .get(
          baseURI,
          { timeout: 10000 }
        )
        .then((response) => {
         
          this.rows = response.data.datas;
          this.groupByCategory=[];
          var groupByCategory = this.rows.reduce((group, row) => {
            const {priority} = row;
            group[priority] = group[priority] ?? [];
            row['checkbox']=false;
            group[priority].push(row);
            return group;
          }, {});

  
          this.groupByCategory.push(groupByCategory);
    

          
          
          // this.totalRecords = response.data.datas.total;
          // this.isLoading = false;
        }).catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    },


    cekDetailShipwithRef(vehicle_no){
      console.log(this.detailShiftWithRef.length);
      for (let index = 0; index < this.detailShiftWithRef.length; index++) {
          if(this.detailShiftWithRef[index].vehicle_no==vehicle_no){
            return true;
          }
      }
    },


    resetData() {
      this.isLoadingReset=true;
      for (let index = 0; index < this.detailShiftWithRef.length; index++) {
         this.detailShiftWithRef[index]['checkbox']==false;
      }
      console.log(this.detailShiftWithRef);
      this.isLoadingReset=false;
    },


    loadShipWithRef(shipwith_ref) {
     
      const baseURI = this.$settings.endPointCt + "planning?shipwithref=" + shipwith_ref;
      return this.$http
        .get(
          baseURI,
          { timeout: 10000 }
        )
        .then((response) => {
          this.detailShiftWithRef=[];
          this.detailShiftWithRef=response.data.datas;
          console.log(this.detailShiftWithRef);
          // this.totalRecords = response.data.datas.total;
          // this.isLoading = false;
        }).catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    },



      loadPriority(no){
       
        console.log(no);
        if(no==1){
          this.activePriority_1=!this.activePriority_1;
          
        }

        if(no==2){
          this.activePriority_2=!this.activePriority_2;
        }

        if(no==3){
          this.activePriority_3=!this.activePriority_3;
        }

        if(no==4){
          this.activePriority_4=!this.activePriority_4;
        }

        if(no==5){
          this.activePriority_5=!this.activePriority_5;
        }

        if(no==6){
          this.activePriority_6=!this.activePriority_6;
        
        }

        if(no==7){
          this.activePriority_7=!this.activePriority_7;
        }

        if(no==8){
          this.activePriority_8=!this.activePriority_8;
        }

        if(no==9){
          this.activePriority_9=!this.activePriority_9;
        }

        if(no==10){
          this.activePriority_10=!this.activePriority_10;
        }

      
      }
    },
    mounted() {
      //tail(this.data);
    },
  };
  </script>
  
  <style scoped>
  .modal {
    background: rgba(0, 0, 0, 0.4);
  }
  .modal-content {
    background: #fff;
    border-radius: 0px;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 0px;
    width: 100%;
    height: 100%;
    margin-left:0px !important;
  }
  </style>