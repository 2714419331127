<template>
     
    <div
      ref="modal"
      class="modal fade"
      :class="{ show: active, 'd-block': active }"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title text-light">Main Alert</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')" aria-label="btn-close"></button>
          </div>
          <div class="modal-body ">
            <h5>Basic Info</h5>
            <hr>
            <table width="100%">
                <tr>
                    <td  width="100px">Vehicle No</td>
                    <td  width="10px">:</td>
                    <td  width="100px"><b>{{data.vehicle_no}}</b></td>

                    <td  width="100px">Km</td>
                    <td  width="10px">:</td>
                    <td  width="100px"><b>{{data.mileage}}</b></td>
                    
                </tr>

                 <tr>
                    <td>Equipment</td>
                    <td>:</td>
                    <td><b v-if="data.blujay_order!=null">{{dataBasicInfo.equipment}}</b></td>

                    <td>Hm</td>
                    <td>:</td>
                    <td></td>
                    
                </tr>
            </table>
            <br>
            <br>
            <h5>Alert</h5>
            <hr>
            <table width="100%">
                <tr>
                    <td width="100px">On Time</td>
                    <td width="10px">:</td>
                    <td  width="100px"><b v-if="Array.isArray(data.trip) && data.trip.length > 0" style="background:red;color:white">{{data.trip[0].delay}}</b></td>
                    <td width="100px">Speed</td>
                    <td width="10px">:</td>
                    <td  width="100px"><b v-if="Array.isArray(data.trip) && data.trip.length > 0 && data.trip[0].speed > 0" style="background:red;color:white" >{{data.trip[0].speed}} {{data.trip[0].time_server}} </b></td>
                    
                </tr>

                <tr>
                    <td>Billable</td>
                    <td>:</td>
                    <td></td>
                    <td>PNL</td>
                    <td>:</td>
                    <td></td>
                    
                </tr>
            </table>

            <br>
            <br>
            <h5>Reminder</h5>
            <hr>
            <table width="100%" >
                <tr>
                    <td width="150px">Next PM Service </td>
                    <td width="10px">:</td>
                    <td width="250px"><b>{{data.next_pm_service}}</b></td>
                    <td width="150px">Last Service Date</td>
                    <td width="10px">:</td>
                    <td><b>{{data.last_service}}</b></td>
                    
                </tr>

                 <td>Truck Document</td>
                    <td width="10px">:</td>
                    <td width="250px"><b v-if="Array.isArray(data.doc) && data.doc.length > 0">KIR {{data.doc[0].kir}} | STNK {{data.doc[0].stnk}}</b></td>
                    <td>Last KM Service</td>
                    <td width="10px">:</td>
                    <td><b v-if="data.last_km_service!=null">{{data.last_km_service.toLocaleString()}}</b>
                </td>

 
                 
            </table>

            <table>
              <tr>
                    <td width="150px">Out Standing Doc</td>
                    <td width="10px">:</td>
                    <td>
                        <hr>
                        <div v-if="data.blujay_order!=null">
                            
                                <span  v-for="(la,index) in dataOrderBlujay"
                                :key="index"
                                 >
                                <b>{{la.customer_name}} | {{la.pickup}} - {{la.delivery}} | {{la.requested_delivery}} </b><br>
                                <hr>
                                </span>
                            
                            
                        </div>

                       
                              
                    </td>
                  
                    
                </tr>
            </table>


            <br>
            <br>
            <h5>Last Order</h5>
            <div class="table-responsive">
               <table class="table table-hover" width="100%">
                <thead>
                <tr>
                    <th>No</th>
                    <th>Driver</th>
                    <th>Customer</th>
                    <th>Pickup</th>
                    <th>Delivery</th>
                    <th>Request Delivery</th>
                </tr>
                </thead>
                
                <tbody v-if="data.blujay_order!=null">
                    <tr v-for="(od,index) in dataOrderBlujay"
                                :key="index"  @click="loadDetail(od)" >
                    <td>{{index + 1}}</td>
                    <td>{{od.driver_name}}</td>
                    <td>{{od.customer_name}}</td>
                    <td>{{od.pickup}}</td>
                    <td>{{od.delivery}}</td>
                    <td>{{od.requested_delivery}}</td>
                    </tr>

                 
                </tbody>
                
            </table>

            </div>
           
          </div>
          <div class="modal-footer ">
            <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">Close</button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
          </div>
        </div>
      </div>
    </div>


    

</template>

<script>
export default {
  name: "BootstrapModal",
  emits: ["closeModal"],
  props: {
    showModal: Boolean,
    data: null,
    dataBasicInfo: null,
    dataOrderBlujay: null,
  },
  watch: {
    showModal: {
      handler(newVal) {
        this.dataList=[];
        this.active = newVal;
        const body = document.querySelector("body");
        this.showModal ? body.classList.add("modal-open") : body.classList.remove("modal-open")
        //this.filterDataOrderBlujay();
        
      },
      immediate: true,
      deep: true,
      
    },
  },
  data() {
    return {
      dataList: [],
      active: this.showModal,
    };
  },

  afterCreate() {
 
  },
 
  methods: {
   
    loadDetail(msg){

        
      console.log(msg.id);
    
    //   msg.forEach((item) => {
    //       pesan=pesan+"<p>* "+item+"</p>";
    //   });
   
      const baseURI =
        this.$settings.endPointCt + `blujay-order/detail-driver/` + msg.driver_id;

      return this.$http.get(baseURI).then((response) => {
        let detailOrder=response.data.datas
        this.loadPopupDetail(detailOrder);
       
        //this.forms = response.data.datas;
      })
      .catch((error) => {

        if (error.response) {
          if (error.response.status === 422) {
            console.log('asdsadasd');

            this.loadError('Driver ID Not Found');
          }
        }
      });

      
    },


    loadError(pesan) {
      this.$swal({
        icon: "warning",
        backdrop: true,
        title: "Warning",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },

    loadPopupDetail(detail){


        let pesan='<table width="100%" style="font-family: Poppins,sans-serif;font-size:12px;color:#333333;border-width: 1px;border-color: #666666;border-collapse: collapse;position: center;">';
        pesan+='<tr>';
        pesan+='<th>Shipment ID</th>';
        pesan+='<th>Request Delivery</th>';
        pesan+='<th>Customer</th>';
        pesan+='<th>Order Group</th>';
        pesan+='<th>Pickup</th>';
        pesan+='<th>Delivery</th>';
        pesan+='</tr>';

        detail.forEach(element => {
            let pecah=element.name_rute.split(",");
            pesan+='<tr>';
            pesan+='<td>'+element.order_number+'</td>';
            pesan+='<td>'+element.requested_delivery+'</td>';
            pesan+='<td>'+element.customer_name+'</td>';
            pesan+='<td>'+element.order_group+'</td>';
            pesan+='<td>'+pecah[0].slice(8)+'</td>';
            pesan+='<td>'+pecah[1].slice(8)+'</td>';

            pesan+='</tr>';
            
            //this.dataList.push(dataA);
        });

         pesan+='</table>';

         this.$swal({
            backdrop:true,
            title: "Detail Order",
            html:pesan,
            showConfirmButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            width: '800px'
          });
    },
  
  },
};
</script>

<style scoped>
.modal {
   
     background: rgba(0, 0, 0, 0.4);

}
.modal-content{
    background: #fff;
    border-radius: 0px;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 0px;
    width: 100%;
    height: 100%;
    margin-left:0px !important;
}

.swal-wide{
    width:500px !important;
}

</style>