<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="DriverDetail"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            ">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left: 15px">
                    Detail Driver
                  </h6>
                  <button class="btn btn-default btn-sm float-end" @click="resetForm" type="button">
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <form class="forms-sample" @submit.prevent="submitData" method="POST">
                  <div class="card-body">
                    <div v-if="isLoading" class="d-flex align-items-center">
                      <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                      <strong>{{ $t("loadingTxt") }}...</strong>
                    </div>

                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" id="basic-tab" data-bs-toggle="tab" href="#basic" role="tab"
                          aria-controls="home" aria-selected="true">Basic</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="requirements-tab" data-bs-toggle="tab" href="#requirements" role="tab"
                          aria-controls="requirements" aria-selected="false">{{ $t("requirements") }}</a>
                      </li>

                      <li class="nav-item">
                        <a class="nav-link" id="teguran-tab" data-bs-toggle="tab" href="#teguran" role="tab"
                          aria-controls="teguran" aria-selected="false">{{ $t("teguran") }}</a>
                      </li>

                      <li class="nav-item">
                        <a class="nav-link" id="hasil-test-tab" data-bs-toggle="tab" href="#hasiltest" role="tab"
                          aria-controls="hasiltest" aria-selected="false">{{ $t("hasilTest") }}</a>
                      </li>


                      <li class="nav-item">
                        <a class="nav-link" id="historical-delivery-tab" data-bs-toggle="tab"
                          href="#historical_delivery" role="tab" aria-controls="contact" aria-selected="false">{{
                    $t("historical_delivery") }}</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="training-tab" data-bs-toggle="tab" href="#training" role="tab"
                          aria-controls="training" aria-selected="training">{{ $t("training") }}</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="bak-tab" data-bs-toggle="tab" href="#bak" role="tab" aria-controls="bak"
                          aria-selected="bak">BAK</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="equipment-tab" data-bs-toggle="tab" href="#equipment" role="tab"
                          aria-controls="equipment" aria-selected="equipment">{{ $t("equipment") }}</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="driver-history-status-tab" data-bs-toggle="tab"
                          href="#driver_history_status" role="tab" aria-controls="driver_history_status"
                          aria-selected="driver_history_status">{{ $t("driver_history_status") }}</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="deposit-tab" data-bs-toggle="tab" href="#deposit" role="tab"
                          aria-controls="deposit" aria-selected="deposit">{{ $t("deposit") }}</a>
                      </li>
                    </ul>
                    <div class="tab-content border border-top-0 p-3" id="myTabContent">
                      <div class="tab-pane fade show active" id="basic" role="tabpanel"
                        aria-labelledby="basic-info-tab">
                        <ul class="nav nav-tabs" id="myTab2" role="tablist">
                          <li class="nav-item">
                            <a class="nav-link active" id="basic-info-tab" data-bs-toggle="tab" href="#basicinfo"
                              role="tab" aria-controls="basicinfo-tab" aria-selected="true">Basic</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" id="address-tab" data-bs-toggle="tab" href="#address" role="tab"
                              aria-controls="address" aria-selected="false">{{ $t("address") }}</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" id="sibiling-info-tab" data-bs-toggle="tab" href="#sibiling-info"
                              role="tab" aria-controls="sibiling-info" aria-selected="false">Sibling Info</a>
                          </li>

                          <li class="nav-item">
                            <a class="nav-link" id="recruitment-info-tab" data-bs-toggle="tab" href="#recruitment-info"
                              role="tab" aria-controls="recruitment-info" aria-selected="false">{{
                    $t("recruitment_info") }}</a>
                          </li>

                          <li class="nav-item">
                            <a class="nav-link" id="driver-id-info-tab" data-bs-toggle="tab" href="#driver-id-info"
                              role="tab" aria-controls="driver-id-info" aria-selected="false">{{ $t("driver_id") }}</a>
                          </li>
                        </ul>
                        <div class="tab-content border border-top-0 p-3" id="myTab2Content">
                          <div class="tab-pane fade show active" id="basicinfo" role="tabpanel"
                            aria-labelledby="basicinfo">
                            <div class="row">
                              <div class="col-lg-6">

                                <div class="row">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">
                                      {{ $t("driver_id") }}</label>
                                  </div>
                                  <div class="col-lg-8">
                                    <input type="text" class="form-control" id="driver_id"
                                      :placeholder="$t('driver_id')" v-model="forms.driver_id" readonly />
                                    <div v-if="errors.driver_id">
                                      <div v-for="error in errors.driver_id" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <br>
                                <div class="row">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label"
                                      style="padding-left: 15px">Status</label>
                                  </div>
                                  <div class="col-lg-4">
                                    <v-select :options="fetchStatus" v-model="driver_status" label="status"
                                      @input="changeStatus" return-object disabled></v-select>

                                    <div v-if="errors.driver_status">
                                      <div v-for="error in errors.driver_status" :key="error"
                                        class="alert alert-primary" role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label"
                                      style="padding-left: 15px">Sub</label>
                                  </div>
                                  <div class="col-lg-4">
                                    <v-select :options="fetchSub" v-model="driver_substatus" label="name" disabled
                                      return-object></v-select>

                                    <div v-if="errors.driver_substatus">
                                      <div v-for="error in errors.driver_substatus" :key="error"
                                        class="alert alert-primary" role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 12px">{{
                    $t("branch_id") }}</label>
                                  </div>
                                  <div class="col-lg-10">
                                    <v-select :options="fetchBranch" v-model="branch" label="branch_name" return-object
                                      disabled @input="asyncLoadBranch"></v-select>

                                    <div v-if="errors.branch_id">
                                      <div v-for="error in errors.branch_id" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Status
                                      Remarks</label>
                                  </div>
                                  <div class="col-lg-10">
                                    <input type="text" class="form-control" id="status_remarks" placeholder="" disabled
                                      v-model="forms.status_remark" required />

                                    <div v-if="errors.status_remark">
                                      <div v-for="error in errors.status_remark" :key="error"
                                        class="alert alert-primary" role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("name_of_id_card") }}</label>
                                  </div>
                                  <div class="col-lg-10">
                                    <input type="text" class="form-control" id="name_idcard" placeholder="" disabled
                                      v-model="forms.name_idcard" required />

                                    <div v-if="errors.name_idcard">
                                      <div v-for="error in errors.name_idcard" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("nickname") }}</label>
                                  </div>
                                  <div class="col-lg-4">
                                    <input type="text" class="form-control" id="nickname" name="nickname" placeholder=""
                                      disabled v-model="forms.nickname" required />

                                    <div v-if="errors.nickname">
                                      <div v-for="error in errors.nickname" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("gender") }}</label>
                                  </div>

                                  <div class="col-lg-4">
                                    <v-select :options="fetchGender" v-model="gender" label="name" disabled
                                      :placeholder="'--Select Gender--'" return-object></v-select>

                                    <div v-if="errors.gender">
                                      <div v-for="error in errors.gender" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>

                                </div>

                                <div class="row" style="margin-top: 20px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("position") }}</label>
                                  </div>
                                  <div class="col-lg-10">
                                    <v-select :options="fetchPosition" v-model="positions" label="name" disabled
                                      return-object></v-select>

                                    <div v-if="errors.driver_position">
                                      <div v-for="error in errors.driver_position" :key="error"
                                        class="alert alert-primary" role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row" style="margin-top: 20px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("site_pool") }}</label>
                                  </div>
                                  <div class="col-lg-10">
                                    <v-select :options="fetchPool" v-model="pool" label="pool_name" return-object
                                      disabled></v-select>

                                    <div v-if="errors.site_name">
                                      <div v-for="error in errors.site_name" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row" style="margin-top: 20px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("project") }}</label>
                                  </div>
                                  <div class="col-lg-10">
                                    <v-select :options="fetchProject" v-model="project" label="name" return-object
                                      disabled></v-select>

                                    <div v-if="errors.project_name">
                                      <div v-for="error in errors.project_name" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row" style="margin-top: 15px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("marital_status") }}</label>
                                  </div>
                                  <div class="col-lg-4">
                                    <v-select :options="fetchMariedStatus" v-model="maried_status" label="name" disabled
                                      return-object></v-select>

                                    <div v-if="errors.marital_status">
                                      <div v-for="error in errors.marital_status" :key="error"
                                        class="alert alert-primary" role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("number_of_childer") }}</label>
                                  </div>

                                  <div class="col-lg-4">
                                    <input type="number" class="form-control" id="children_number" placeholder=""
                                      v-model="forms.children_number" disabled />

                                    <div v-if="errors.children_number">
                                      <div v-for="error in errors.children_number" :key="error"
                                        class="alert alert-primary" role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("id_card_number") }}</label>
                                  </div>
                                  <div class="col-lg-10">
                                    <input type="text" class="form-control" id="id_card_number" placeholder=""
                                      v-model="forms.id_card_number" disabled />

                                    <div v-if="errors.id_card_number">
                                      <div v-for="error in errors.id_card_number" :key="error"
                                        class="alert alert-primary" role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row" style="margin-top: 15px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("education") }}</label>
                                  </div>
                                  <div class="col-lg-4">
                                    <v-select :options="fetchEducation" v-model="education" label="name" return-object
                                      disabled></v-select>

                                    <div v-if="errors.education">
                                      <div v-for="error in errors.education" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("religion") }}</label>
                                  </div>

                                  <div class="col-lg-4">
                                    <v-select :options="fetchReligion" v-model="religion" label="name" disabled
                                      return-object></v-select>

                                    <div v-if="errors.religon">
                                      <div v-for="error in errors.religion" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row" style="margin-top: 15px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("place_dob") }}</label>
                                  </div>
                                  <div class="col-lg-4">
                                    <input type="text" class="form-control" id="place_dob" name="place_dob"
                                      placeholder="" v-model="forms.birth_place" disabled />

                                    <div v-if="errors.education">
                                      <div v-for="error in errors.education" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-lg-6">
                                    <Datepicker v-model="forms.birth_date" :bootstrap-styling="true" format="dd-MM-yyyy"
                                      disabled placeholder="Please Select Date..." @input="changeAgeDate()">
                                    </Datepicker>

                                    <div v-if="errors.birth_date">
                                      <div v-for="error in errors.birth_date" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row" style="margin-top: 15px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("age") }}</label>
                                  </div>
                                  <div class="col-lg-4">
                                    <input type="number" class="form-control" id="age" name="age" placeholder=""
                                      v-model="forms.age" readonly />

                                    <div v-if="errors.age">
                                      <div v-for="error in errors.age" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("ethnic") }}</label>
                                  </div>

                                  <div class="col-lg-4">
                                    <v-select disabled :options="fetchEthnic" v-model="ethnic" label="name"
                                      return-object></v-select>

                                    <div v-if="errors.ethnic">
                                      <div v-for="error in errors.ethnic" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row" style="margin-top: 15px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">No
                                      Rekening</label>
                                  </div>
                                  <div class="col-lg-4">
                                    <input type="text" class="form-control" id="no_rekening" name="no_rekening"
                                      placeholder="" v-model="forms.no_rekening" readonly />

                                    <div v-if="errors.no_rekening">
                                      <div v-for="error in errors.no_rekening" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">SIM
                                      No</label>
                                  </div>

                                  <div class="col-lg-4">
                                    <input type="text" class="form-control" id="no_rekening" name="no_rekening"
                                      placeholder="" v-model="forms.sim_no" readonly />

                                    <div v-if="errors.sim_no">
                                      <div v-for="error in errors.sim_no" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row" style="margin-top: 15px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Aktual
                                      Unit</label>
                                  </div>
                                  <div class="col-lg-4">
                                    <input type="text" class="form-control" id="no_rekening" name="no_rekening"
                                      placeholder="" v-model="forms.type_truck" readonly />

                                    <div v-if="errors.type_truck">
                                      <div v-for="error in errors.type_truck" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label"
                                      style="padding-left: 15px">PIC</label>
                                  </div>

                                  <div class="col-lg-4">
                                    <input type="text" class="form-control" id="no_rekening" name="no_rekening"
                                      placeholder="" v-model="forms.pic" readonly />

                                    <div v-if="errors.pic">
                                      <div v-for="error in errors.pic" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-6">
                                <div class="row">
                                  <div class="col-lg-4">
                                    <!-- <label
                                      for="defaultconfig"
                                      class="col-form-label"
                                      style="padding-left: 25px"
                                      >Photo</label
                                    > -->
                                  </div>
                                  <div class="col-lg-4">
                                    <div v-if="photo_temporary" class="dropify-wrapper has-preview">
                                      <div class="dropify-message">
                                        <span class="file-icon" v-if="!photo_temporary">
                                          <p>
                                            Drag and drop a file here or click
                                          </p>
                                        </span>
                                        <p class="dropify-error">
                                          Ooops, something wrong appended.
                                        </p>
                                      </div>
                                      <div class="dropify-loader" style="display: none"></div>
                                      <div class="dropify-errors-container">
                                        <ul></ul>
                                      </div>
                                      <input type="file" ref="images" accept="image/*" class="form-control"
                                        @change="handleFileUpload($event)" />
                                      <button type="button" class="dropify-clear" @click="removeImages">
                                        Remove
                                      </button>
                                      <div class="dropify-preview" style="display: block">
                                        <img :src="photo_temporary" width="90%" height="80%" />
                                        <span class="dropify-render"></span>
                                        <div class="dropify-infos">
                                          <div class="dropify-infos-inner">
                                            <p class="dropify-filename"></p>
                                            <p class="dropify-infos-message">
                                              Drag and drop or click to replace
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div v-else class="dropify-wrapper">
                                      <div class="dropify-message">
                                        <span class="file-icon">
                                          <p>
                                            Drag and drop a file here or click
                                          </p>
                                        </span>
                                      </div>
                                      <div class="dropify-loader" style="display: none"></div>
                                      <div class="dropify-errors-container">
                                        <ul></ul>
                                      </div>
                                      <input type="file" ref="images" accept="image/*" class="form-control"
                                        @change="handleFileUpload($event)" />
                                      <button type="button" class="dropify-clear">
                                        Remove
                                      </button>
                                      <div class="dropify-preview" style="display: none">
                                        <img :src="photo_temporary" width="90%" height="80%" />
                                        <span class="dropify-render"></span>
                                        <div class="dropify-infos">
                                          <div class="dropify-infos-inner">
                                            <p class="dropify-filename"></p>
                                            <p class="dropify-infos-message">
                                              Drag and drop or click to replace
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div v-if="errors.photo">
                                      <div v-for="error in errors.photo" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row" style="margin-top: 50px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("joined_date") }}</label>
                                  </div>
                                  <div class="col-lg-4">
                                    <Datepicker disabled v-model="forms.join_date" :bootstrap-styling="true"
                                      format="dd-MM-yyyy" placeholder="Please Select Date..." @input="changeJoinDate()">
                                    </Datepicker>

                                    <div v-if="errors.join_date">
                                      <div v-for="error in errors.join_date" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("working_time") }}</label>
                                  </div>

                                  <div class="col-lg-2">
                                    <input type="number" class="form-control" id="year" name="year"
                                      :placeholder="$t('year')" readonly v-model="forms.working_year" required />
                                  </div>

                                  <div class="col-lg-2">
                                    <input type="number" class="form-control" id="month" name="month" readonly
                                      :placeholder="$t('month')" v-model="forms.working_month" required />
                                  </div>
                                </div>

                                <div class="row" style="margin-top: 15px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">SIM 1
                                      TYPE</label>
                                  </div>
                                  <div class="col-lg-4">
                                    <v-select :options="fetchSIM" v-model="sim_1" label="name" disabled
                                      return-object></v-select>

                                    <div v-if="errors.sim1_type">
                                      <div v-for="error in errors.sim1_type" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-lg-6">
                                    <Datepicker v-model="forms.sim1_expire_date" :bootstrap-styling="true"
                                      format="dd-MM-yyyy" disabled placeholder="Please Select Date..."></Datepicker>

                                    <div v-if="errors.sim1_expire_date">
                                      <div v-for="error in errors.sim1_expire_date" :key="error"
                                        class="alert alert-primary" role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row" style="margin-top: 15px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">SIM 2
                                      TYPE</label>
                                  </div>
                                  <div class="col-lg-4">
                                    <v-select :options="fetchSIM" disabled v-model="sim_2" label="name"
                                      return-object></v-select>

                                    <div v-if="errors.sim2_type">
                                      <div v-for="error in errors.sim2_type" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-lg-6">
                                    <Datepicker v-model="forms.sim2_expire_date" :bootstrap-styling="true" disabled
                                      format="dd-MM-yyyy" placeholder="Please Select Date..."></Datepicker>

                                    <div v-if="errors.sim2_expire_date">
                                      <div v-for="error in errors.sim2_expire_date" :key="error"
                                        class="alert alert-primary" role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row" style="margin-top: 15px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">HP
                                      1</label>
                                  </div>
                                  <div class="col-lg-4">
                                    <input type="text" class="form-control" id="hp1" name="hp1" placeholder="" disabled
                                      v-model="forms.hp1" required />

                                    <div v-if="errors.hp1">
                                      <div v-for="error in errors.hp1" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-lg-6">
                                    <label for="defaultconfig" class="col-form-label"
                                      style="padding-left: 15px">WA</label>
                                    <input class="form-check-input" v-model="forms.wa_hp1"
                                      style="margin-top: 8px; margin-left: 8px" type="checkbox" value="1" disabled
                                      id="flexCheckDefault" />
                                  </div>
                                </div>

                                <div class="row" style="margin-top: 25px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">HP
                                      2</label>
                                  </div>
                                  <div class="col-lg-4">
                                    <input type="text" class="form-control" id="hp2" name="hp2" placeholder="" disabled
                                      v-model="forms.hp2" required />

                                    <div v-if="errors.hp2">
                                      <div v-for="error in errors.hp2" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-lg-6">
                                    <label for="defaultconfig" class="col-form-label"
                                      style="padding-left: 15px">WA</label>
                                    <input class="form-check-input" v-model="forms.wa_hp2"
                                      style="margin-top: 8px; margin-left: 8px" type="checkbox" value="1" disabled
                                      id="flexCheckDefault" />
                                  </div>
                                </div>

                                <div class="row" style="margin-top: 25px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">SAP
                                      CODE</label>
                                  </div>
                                  <div class="col-lg-10">
                                    <input type="text" class="form-control" id="sap_code" name="sap_code" placeholder=""
                                      disabled v-model="forms.sap_code" required />

                                    <div v-if="errors.sap_code">
                                      <div v-for="error in errors.sap_code" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row" style="margin-top: 25px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("end_of_contract") }}</label>
                                  </div>
                                  <div class="col-lg-4">
                                    <Datepicker disabled v-model="forms.end_contract" :bootstrap-styling="true"
                                      format="dd-MM-yyyy" placeholder="Please Select Date..."></Datepicker>

                                    <div v-if="errors.end_contract">
                                      <div v-for="error in errors.end_contract" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("stop_date") }}</label>
                                  </div>

                                  <div class="col-lg-4">
                                    <Datepicker disabled v-model="forms.stop_date" :bootstrap-styling="true"
                                      format="dd-MM-yyyy" placeholder="Please Select Date..."></Datepicker>

                                    <div v-if="errors.stop_date">
                                      <div v-for="error in errors.stop_date" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row" style="margin-top: 15px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("stop_reason") }}</label>
                                  </div>
                                  <div class="col-lg-10">
                                    <v-select disabled :options="fetchStopReason" v-model="stop_reason" label="name"
                                      return-object></v-select>

                                    <div v-if="errors.stop_reason">
                                      <div v-for="error in errors.stop_reason" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row" style="margin-top: 15px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("stop_remark") }}</label>
                                  </div>
                                  <div class="col-lg-10">
                                    <textarea disabled class="form-control" v-model="forms.stop_remark"></textarea>

                                    <div v-if="errors.stop_remark">
                                      <div v-for="error in errors.stop_remark" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row" style="margin-top: 15px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Driver
                                      Coordinator</label>
                                  </div>
                                  <div class="col-lg-10">
                                    <input class="form-control" disabled v-model="forms.dc_name" name='dc_name' />

                                    <div v-if="errors.dc_name">
                                      <div v-for="error in errors.dc_name" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                            <br />
                          </div>
                          <div class="tab-pane fade" id="address" role="tabpanel" aria-labelledby="address-tab">
                            <h5>{{ $t("idCardAddress") }}</h5>
                            <div class="row" style="margin-top: 15px">
                              <div class="col-lg-2">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("address") }}</label>
                              </div>
                              <div class="col-lg-10">
                                <textarea class="form-control" v-model="forms.address_idcard" disabled></textarea>

                                <div v-if="errors.address_idcard">
                                  <div v-for="error in errors.address_idcard" :key="error" class="alert alert-primary"
                                    role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row" style="margin-top: 15px">
                              <div class="col-lg-2">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">RT</label>
                              </div>
                              <div class="col-lg-4">
                                <input class="form-control" v-model="forms.rt_idcard" type="number" disabled
                                  name="rt" />
                                <div v-if="errors.rt_idcard">
                                  <div v-for="error in errors.rt_idcard" :key="error" class="alert alert-primary"
                                    role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-2">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">RW</label>
                              </div>

                              <div class="col-lg-4">
                                <input class="form-control" v-model="forms.rw_idcard" type="number" name="rw"
                                  disabled />

                                <div v-if="errors.rw_idcard">
                                  <div v-for="error in errors.rw_idcard" :key="error" class="alert alert-primary"
                                    role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row" style="margin-top: 15px">
                              <div class="col-lg-2">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("provinceTxt") }}</label>
                              </div>
                              <div class="col-lg-4">
                                <v-select :options="fetchProvince" v-model="province" label="province" return-object
                                  @input="searchCity" disabled></v-select>

                                <div v-if="errors.province_idcard">
                                  <div v-for="error in errors.province_idcard" :key="error" class="alert alert-primary"
                                    role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row" style="margin-top: 15px">
                              <div class="col-lg-2">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("cityTxt") }}</label>
                              </div>
                              <div class="col-lg-4">
                                <v-select :options="fetchCity" v-model="city" label="city" return-object disabled
                                  @input="searchRegion"></v-select>

                                <div v-if="errors.city_idcard">
                                  <div v-for="error in errors.city_idcard" :key="error" class="alert alert-primary"
                                    role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row" style="margin-top: 15px">
                              <div class="col-lg-2">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("regionTxt") }}</label>
                              </div>
                              <div class="col-lg-4">
                                <v-select :options="fetchRegion" v-model="region" label="district" return-object
                                  disabled @input="searchSubDistrict"></v-select>

                                <div v-if="errors.region_idcard">
                                  <div v-for="error in errors.region_idcard" :key="error" class="alert alert-primary"
                                    role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row" style="margin-top: 15px">
                              <div class="col-lg-2">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("districtTxt") }}</label>
                              </div>
                              <div class="col-lg-4">
                                <v-select :options="fetchDistrict" v-model="district" label="sub_district"
                                  @input="searchPostalCode" disabled return-object></v-select>

                                <div v-if="errors.district_idcard">
                                  <div v-for="error in errors.district_idcard" :key="error" class="alert alert-primary"
                                    role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row" style="margin-top: 15px">
                              <div class="col-lg-2">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("postalCodeTxt") }}</label>
                              </div>
                              <div class="col-lg-4">
                                <v-select :options="fetchPostalCode" v-model="postalCode" label="postal_code" disabled
                                  return-object></v-select>

                                <div v-if="errors.postal_code_idcard">
                                  <div v-for="error in errors.postal_code_idcard" :key="error"
                                    class="alert alert-primary" role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <br />
                            <h5>{{ $t("domicileAddress") }}</h5>

                            <div class="row" style="margin-top: 15px">
                              <div class="col-lg-2">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("sameAsId") }}</label>
                              </div>
                              <div class="col-lg-10">
                                <input type="checkbox" disabled v-model="forms.same_as_id" class="form-check-input"
                                  v-on:change="checkSameID()" />
                                <!-- <div class="form-check">
                                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                      <label class="form-check-label" for="flexCheckDefault">
                                        Default checkbox
                                      </label>
                                    </div> -->

                                <!-- <div v-if="errors.addressDomicile">
                                      <div
                                        v-for="error in errors.addressDomicile"
                                        :key="error"
                                        class="alert alert-primary"
                                        role="alert"
                                      >
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div> -->
                              </div>
                            </div>

                            <div class="row" style="margin-top: 15px">
                              <div class="col-lg-2">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("address") }}</label>
                              </div>
                              <div class="col-lg-10">
                                <textarea class="form-control" v-model="forms.address_domicile" disabled></textarea>

                                <div v-if="errors.address_domicile">
                                  <div v-for="error in errors.address_domicile" :key="error" class="alert alert-primary"
                                    role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row" style="margin-top: 15px">
                              <div class="col-lg-2">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">RT</label>
                              </div>
                              <div class="col-lg-4">
                                <input class="form-control" v-model="forms.rt_domicile" type="number" disabled
                                  name="rt" />
                                <div v-if="errors.rt_domicile">
                                  <div v-for="error in errors.rt_domicile" :key="error" class="alert alert-primary"
                                    role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-2">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">RW</label>
                              </div>

                              <div class="col-lg-4">
                                <input class="form-control" v-model="forms.rw_domicile" type="number" name="rw"
                                  disabled />

                                <div v-if="errors.rw_domicile">
                                  <div v-for="error in errors.rw_domicile" :key="error" class="alert alert-primary"
                                    role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row" style="margin-top: 15px">
                              <div class="col-lg-2">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("provinceTxt") }}</label>
                              </div>
                              <div class="col-lg-4">
                                <v-select :options="fetchProvinceDomicile" v-model="provinceDomicile" label="province"
                                  disabled @input="searchCityDomicile" return-object></v-select>

                                <div v-if="errors.provinceDomicile">
                                  <div v-for="error in errors.provinceDomicile" :key="error" class="alert alert-primary"
                                    role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row" style="margin-top: 15px">
                              <div class="col-lg-2">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("cityTxt") }}</label>
                              </div>
                              <div class="col-lg-4">
                                <v-select :options="fetchCityDomicile" v-model="cityDomicile" label="city" return-object
                                  disabled @input="searchRegionDomicile"></v-select>

                                <div v-if="errors.cityDomicile">
                                  <div v-for="error in errors.cityDomicile" :key="error" class="alert alert-primary"
                                    role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row" style="margin-top: 15px">
                              <div class="col-lg-2">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("regionTxt") }}</label>
                              </div>
                              <div class="col-lg-4">
                                <v-select :options="fetchRegionDomicile" v-model="regionDomicile" label="district"
                                  return-object disabled @input="searchSubDistrictDomicile"></v-select>

                                <div v-if="errors.regionDomicile">
                                  <div v-for="error in errors.regionDomicile" :key="error" class="alert alert-primary"
                                    role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row" style="margin-top: 15px">
                              <div class="col-lg-2">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("districtTxt") }}</label>
                              </div>
                              <div class="col-lg-4">
                                <v-select :options="fetchDistrictDomicile" v-model="districtDomicile"
                                  label="sub_district" return-object disabled
                                  @input="searchPostalCodeDistrict"></v-select>

                                <div v-if="errors.districtDomicile">
                                  <div v-for="error in errors.districtDomicile" :key="error" class="alert alert-primary"
                                    role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row" style="margin-top: 15px">
                              <div class="col-lg-2">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("postalCodeTxt") }}</label>
                              </div>
                              <div class="col-lg-4">
                                <v-select :options="fetchPostalCodeDomicile" v-model="postalCodeDomicile"
                                  label="postal_code" disabled return-object></v-select>

                                <div v-if="errors.postalCodeDomicile">
                                  <div v-for="error in errors.postalCodeDomicile" :key="error"
                                    class="alert alert-primary" role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>
                                </div>
                              </div>
                            </div>


                          </div>
                          <div class="tab-pane fade" id="sibiling-info" role="tabpanel"
                            aria-labelledby="sibiling-info-tab">
                            <h5>Sibling Info</h5>

                            <div class="row" style="margin-top: 15px">
                              <div class="col-lg-2">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("nameTxt") }}</label>
                              </div>
                              <div class="col-lg-4">
                                <input class="form-control" v-model="forms.name_sibling" name="nameBilling" disabled />

                                <div v-if="errors.name_sibling">
                                  <div v-for="error in errors.name_sibling" :key="error" class="alert alert-primary"
                                    role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row" style="margin-top: 15px">
                              <div class="col-lg-2">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("relationShip") }}</label>
                              </div>
                              <div class="col-lg-4">
                                <v-select :options="fetchRelationShip" v-model="relationShip" label="name" disabled
                                  return-object></v-select>

                                <div v-if="errors.postalRelationShip">
                                  <div v-for="error in errors.postalRelationShip" :key="error"
                                    class="alert alert-primary" role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row" style="margin-top: 15px">
                              <div class="col-lg-2">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Hp</label>
                              </div>
                              <div class="col-lg-4">
                                <input class="form-control" v-model="forms.hp_sibling" name="hp" disabled />

                                <div v-if="errors.hp_sibling">
                                  <div v-for="error in errors.hp_sibling" :key="error" class="alert alert-primary"
                                    role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row" style="margin-top: 15px">
                              <div class="col-lg-2">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("provinceTxt") }}</label>
                              </div>
                              <div class="col-lg-4">
                                <v-select :options="fetchProvinceSibiling" v-model="provinceSibiling" label="province"
                                  return-object @input="searchCitySibiling" disabled></v-select>

                                <div v-if="errors.provinceSibiling">
                                  <div v-for="error in errors.provinceSibiling" :key="error" class="alert alert-primary"
                                    role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row" style="margin-top: 15px">
                              <div class="col-lg-2">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("cityTxt") }}</label>
                              </div>
                              <div class="col-lg-4">
                                <v-select :options="fetchCitySibiling" v-model="citySibiling" label="city" return-object
                                  @input="searchRegionSibiling" disabled></v-select>

                                <div v-if="errors.citySibiling">
                                  <div v-for="error in errors.citySibiling" :key="error" class="alert alert-primary"
                                    role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row" style="margin-top: 15px">
                              <div class="col-lg-2">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("regionTxt") }}</label>
                              </div>
                              <div class="col-lg-4">
                                <v-select :options="fetchRegionSibiling" v-model="regionSibiling" label="district"
                                  return-object disabled @input="searchSubDistrictSibling"></v-select>

                                <div v-if="errors.regionSibiling">
                                  <div v-for="error in errors.regionSibiling" :key="error" class="alert alert-primary"
                                    role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row" style="margin-top: 15px">
                              <div class="col-lg-2">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("districtTxt") }}</label>
                              </div>
                              <div class="col-lg-4">
                                <v-select :options="fetchDistrictSibiling" v-model="districtSibiling"
                                  label="sub_district" return-object disabled
                                  @input="searchPostalCodeSibling"></v-select>

                                <div v-if="errors.districtSibiling">
                                  <div v-for="error in errors.districtSibiling" :key="error" class="alert alert-primary"
                                    role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row" style="margin-top: 15px">
                              <div class="col-lg-2">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("postalCodeTxt") }}</label>
                              </div>
                              <div class="col-lg-4">
                                <v-select :options="fetchPostalCodeSibiling" v-model="postalCodeSibiling"
                                  label="postal_code" return-object disabled></v-select>

                                <div v-if="errors.postalCodeSibiling">
                                  <div v-for="error in errors.postalCodeSibiling" :key="error"
                                    class="alert alert-primary" role="alert">
                                    <i data-feather="alert-circle"></i>
                                    {{ error }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="tab-pane fade" id="recruitment-info" role="tabpanel"
                            aria-labelledby="recruitment-info-tab">
                            <div class="row">
                              <div class="col-lg-6">
                                <div class="row" style="margin-top: 15px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label"
                                      style="padding-left: 15px">Recruitment Source</label>
                                  </div>
                                  <div class="col-lg-10">
                                    <v-select :options="fetchRecruitmentSource" v-model="recruitmentSource" label="name"
                                      return-object disabled></v-select>

                                    <div v-if="errors.recruitmentSource">
                                      <div v-for="error in errors.recruitmentSource" :key="error"
                                        class="alert alert-primary" role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row" style="margin-top: 15px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("driver_id") }}</label>
                                  </div>
                                  <div class="col-lg-10">

                                    <v-select :options="fetchDriverId" disabled v-model="driverId" label="driver_id"
                                      @input="changeDriverIdResource" return-object></v-select>

                                    <div v-if="errors.driverId">
                                      <div v-for="error in errors.driverId" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row" style="margin-top: 15px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("nameTxt") }}</label>
                                  </div>
                                  <div class="col-lg-10">
                                    <input class="form-control" v-model="forms.recruiter_name" name="recruiter_name"
                                      disabled />

                                    <div v-if="errors.recruiter_name">
                                      <div v-for="error in errors.recruiter_name" :key="error"
                                        class="alert alert-primary" role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row" style="margin-top: 15px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("name_of_id_card") }}</label>
                                  </div>
                                  <div class="col-lg-10">
                                    <input class="form-control" v-model="forms.recruiter_id_card_number"
                                      name="recruiter_id_card_number" disabled />

                                    <div v-if="errors.recruiter_id_card_number">
                                      <div v-for="error in errors.recruiter_id_card_number" :key="error"
                                        class="alert alert-primary" role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row" style="margin-top: 15px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("locationPhoneNumber") }}</label>
                                  </div>
                                  <div class="col-lg-10">
                                    <input class="form-control" v-model="forms.phone_number" name="phone_number"
                                      disabled />

                                    <div v-if="errors.phone_number">
                                      <div v-for="error in errors.phone_number" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row" style="margin-top: 15px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("handphone") }}</label>
                                  </div>
                                  <div class="col-lg-10">
                                    <input class="form-control" v-model="forms.handphone_number" name="handphone_number"
                                      disabled />

                                    <div v-if="errors.handphone_number">
                                      <div v-for="error in errors.handphone_number" :key="error"
                                        class="alert alert-primary" role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row" style="margin-top: 15px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("relationShip") }}</label>
                                  </div>
                                  <div class="col-lg-10">
                                    <input class="form-control" v-model="forms.relationship_recruiter"
                                      name="relationShip_recruitment_info" disabled />

                                    <div v-if="errors.relationship_recruiter">
                                      <div v-for="error in errors.relationship_recruiter" :key="error"
                                        class="alert alert-primary" role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-6">
                                <div class="row" style="margin-top: 15px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                    $t("remarks_txt") }}</label>
                                  </div>
                                  <div class="col-lg-10">
                                    <textarea class="form-control" v-model="forms.remark" disabled></textarea>

                                    <div v-if="errors.remark">
                                      <div v-for="error in errors.remark" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>



                            </div>
                          </div>
                          <div class="tab-pane fade" id="driver-id-info" role="tabpanel"
                            aria-labelledby="driver-id-info">
                            <div class="row">
                              <table-driver-id :detail="detailDriverID"></table-driver-id>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="tab-pane fade" id="requirements" role="tabpanel" aria-labelledby="requirements-tab">
                        <div class="col-lg-12">
                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Perjanjian
                                Kemitraan</label>
                            </div>
                            <div class="col-lg-10">
                              <div class="form-check" style="margin-top: 10px">
                                <input disabled class="form-check-input" type="checkbox" value="" id="req_mitra"
                                  name="req_mitra" v-model="req_mitra" />
                                <label class="form-check-label" for="flexCheckChecked">
                                  Ada
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12">
                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Surat Jaminan
                                Keluarga</label>
                            </div>
                            <div class="col-lg-10">
                              <div class="form-check" style="margin-top: 10px">
                                <input disabled class="form-check-input" type="checkbox" value="" id="req_keluarga"
                                  name="req_keluarga" v-model="req_keluarga" />
                                <label class="form-check-label" for="flexCheckChecked">
                                  Ada
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12">
                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Jaminan Surat
                                Berharga</label>
                            </div>
                            <div class="col-lg-5">
                              <input disabled class="form-control" v-model="req_berharga" name="req_berharga"
                                id="req_berharga" />
                              <!-- <div class="form-check" style="margin-top: 10px">
                                      <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                      <label class="form-check-label" for="flexCheckChecked">
                                        Ada
                                      </label>
                                      
                                    </div> -->
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12">
                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label"
                                style="padding-left: 15px">Ijazah</label>
                            </div>
                            <div class="col-lg-1">
                              <div class="form-check" style="margin-top: 10px">
                                <input class="form-check-input" type="checkbox" id="req_ijazah" name="req_ijazah"
                                  v-model="req_ijazah" disabled />
                                <label class="form-check-label" for="flexCheckChecked">
                                  Ada
                                </label>
                              </div>
                            </div>

                            <div class="col-lg-1">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">No
                                Ijazah</label>
                            </div>

                            <div class="col-lg-3">
                              <input class="form-control" id="req_no_ijazah" v-model="req_no_ijazah"
                                name="req_no_ijazah" disabled />
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12">
                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">SKCK yang
                                masih berlaku</label>
                            </div>
                            <div class="col-lg-10">
                              <div class="form-check" style="margin-top: 10px">
                                <input class="form-check-input" type="checkbox" name="req_skck" id="req_skck"
                                  v-model="req_skck" disabled />
                                <label class="form-check-label" for="flexCheckChecked">
                                  Ada
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12">
                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Domisili
                                Terbaru</label>
                            </div>
                            <div class="col-lg-10">
                              <div class="form-check" style="margin-top: 10px">
                                <input class="form-check-input" type="checkbox" name="req_domicile" id="req_domicile"
                                  v-model="req_domicile" disabled />
                                <label class="form-check-label" for="flexCheckChecked">
                                  Ada
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12">
                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Kartu
                                Keluarga</label>
                            </div>
                            <div class="col-lg-10">
                              <div class="form-check" style="margin-top: 10px">
                                <input class="form-check-input" type="checkbox" name="req_kk" id="req_kk"
                                  v-model="req_kk" disabled />
                                <label class="form-check-label" for="flexCheckChecked">
                                  Ada
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12">
                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">ID Card /
                                KTP</label>
                            </div>
                            <div class="col-lg-10">
                              <div class="form-check" style="margin-top: 10px">
                                <input class="form-check-input" type="checkbox" name="req_ktp" id="req_ktp"
                                  v-model="req_ktp" disabled />
                                <label class="form-check-label" for="flexCheckChecked">
                                  Ada
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12">
                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Driving
                                Licensi</label>
                            </div>
                            <div class="col-lg-10">
                              <div class="form-check" style="margin-top: 10px">
                                <input class="form-check-input" type="checkbox" name="req_sim" id="req_sim"
                                  v-model="req_sim" disabled />
                                <label class="form-check-label" for="flexCheckChecked">
                                  Ada
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12">
                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Sertifikat
                                Covid - 19</label>
                            </div>
                            <div class="col-lg-10">
                              <div class="form-check" style="margin-top: 10px">
                                <input class="form-check-input" type="checkbox" name="req_covid_cert"
                                  id="req_covid_cert" v-model="req_covid_cert" disabled />
                                <label class="form-check-label" for="flexCheckChecked">
                                  Ada
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12">
                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Foto Berwarna
                                4x6</label>
                            </div>
                            <div class="col-lg-10">
                              <div class="form-check" style="margin-top: 10px">
                                <input class="form-check-input" type="checkbox" name="req_photo" id="req_photo" disabled
                                  v-model="req_photo" />
                                <label class="form-check-label" for="flexCheckChecked">
                                  Ada
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12">
                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Surat
                                Keterangan Sehat Terbaru</label>
                            </div>
                            <div class="col-lg-10">
                              <div class="form-check" style="margin-top: 10px">
                                <input class="form-check-input" type="checkbox" name="req_sehat" id="req_sehat"
                                  v-model="req_sehat" disabled />
                                <label class="form-check-label" for="flexCheckChecked">
                                  Ada
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12">
                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Lamaran
                                Lengkap</label>
                            </div>
                            <div class="col-lg-10">
                              <div class="form-check" style="margin-top: 10px">
                                <input class="form-check-input" type="checkbox" name="req_lamaran" id="req_lamaran"
                                  v-model="req_lamaran" disabled />
                                <label class="form-check-label" for="flexCheckChecked">
                                  Ada
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12">
                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Daftar
                                Riwayat Hidup</label>
                            </div>
                            <div class="col-lg-10">
                              <div class="form-check" style="margin-top: 10px">
                                <input class="form-check-input" type="checkbox" name="req_riwayathidup"
                                  id="req_riwayathidup" v-model="req_riwayathidup" disabled />
                                <label class="form-check-label" for="flexCheckChecked">
                                  Ada
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12">
                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Rekening
                                BCA</label>
                            </div>
                            <div class="col-lg-10">
                              <div class="form-check" style="margin-top: 10px">
                                <input class="form-check-input" type="checkbox" name="rekening_bca" id="rekening_bca"
                                  v-model="rekening_bca" disabled />
                                <label class="form-check-label" for="flexCheckChecked">
                                  Ada
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12">
                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Hp
                                Android</label>
                            </div>
                            <div class="col-lg-10">
                              <div class="form-check" style="margin-top: 10px">
                                <input class="form-check-input" type="checkbox" name="req_android" id="req_android"
                                  v-model="req_android" disabled />
                                <label class="form-check-label" for="flexCheckChecked">
                                  Ada
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="tab-pane fade" id="teguran" role="tabpanel" aria-labelledby="teguran-tab">
                        <div class="row">

                          <!-- <div class="alert alert-primary alert-dismissible fade show" role="alert">
                              Modul <strong>Under Construction!</strong>
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                          </div> -->
                        </div>
                        <teguran-driver-delivery :detail="[]"
                          :master_driver_id="forms.master_driver_id"></teguran-driver-delivery>
                      </div>


                      <div class="tab-pane fade" id="hasiltest" role="tabpanel" aria-labelledby="hasil-test-tab">
                        <div class="row">
                          <div class="col-lg-6">

                            <div class="row" style="margin-top: 15px">
                              <div class="col-sm-3">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Tanggal
                                  Test</label>
                              </div>
                              <div class="col-sm-9">
                                <Datepicker disabled v-model="forms.tanggal_test" :bootstrap-styling="true"
                                  format="dd-MM-yyyy" placeholder="Please Select Date..."></Datepicker>
                              </div>

                            </div>


                            <div class="row" style="margin-top: 15px">
                              <div class="col-sm-3">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Nilai
                                  Test</label>
                              </div>
                              <div class="col-sm-9">
                                <input class="form-control" disabled id="nilai_teknis" name="nilai_teknis"
                                  v-model="forms.nilai_teknis" />
                              </div>

                            </div>



                            <div class="row" style="margin-top: 15px">
                              <div class="col-sm-3">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Nilai
                                  Psikotes</label>
                              </div>
                              <div class="col-sm-9">
                                <input class="form-control" disabled id="nilai_psikotest" name="nilai_psikotest"
                                  v-model="forms.nilai_psikotest" />
                              </div>

                            </div>


                            <div class="row" style="margin-top: 15px">
                              <div class="col-sm-3">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Nilai Test
                                  Drive</label>
                              </div>
                              <div class="col-sm-9">
                                <input class="form-control" disabled id="nilai_testdrive" name="nilai_testdrive"
                                  v-model="forms.nilai_testdrive" />
                              </div>

                            </div>


                            <div class="row" style="margin-top: 15px">
                              <div class="col-sm-3">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Nilai Mata
                                  Kanan</label>
                              </div>
                              <div class="col-sm-9">
                                <input class="form-control" disabled id="nilai_mata_kanan" name="nilai_mata_kanan"
                                  v-model="forms.nilai_mata_kanan" />
                              </div>

                            </div>



                            <div class="row" style="margin-top: 15px">
                              <div class="col-sm-3">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Nilai Mata
                                  Kiri</label>
                              </div>
                              <div class="col-sm-9">
                                <input class="form-control" disabled id="nilai_mata_kiri" name="nilai_mata_kiri"
                                  v-model="forms.nilai_mata_kiri" />
                              </div>

                            </div>



                          </div>

                          <div class="col-lg-6">

                            <div class="row" style="margin-top: 15px">
                              <div class="col-sm-3">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Remark
                                </label>
                              </div>
                              <div class="col-sm-9">
                                <textarea name="remark_test" disabled id="remark_test" class="form-control"
                                  v-model="forms.remark_test" rows="10" cols="10"></textarea>

                              </div>

                            </div>


                          </div>



                        </div>
                        <!-- <hasil-test-driver-delivery
                          :detail="[]"
                        ></hasil-test-driver-delivery> -->
                      </div>


                      <div class="tab-pane fade" id="historical_delivery" role="tabpanel"
                        aria-labelledby="historical-delivery-tab">
                        <div class="row">
                          <div class="alert alert-primary alert-dismissible fade show" role="alert">
                            Modul <strong>Under Construction!</strong>
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-4">
                            <Datepicker v-model="periode_awal" :bootstrap-styling="true" format="dd-MM-yyyy" disabled
                              placeholder="Please Select Date..."></Datepicker>
                          </div>

                          <div class="col-lg-4">
                            <Datepicker v-model="periode_akhir" :bootstrap-styling="true" format="dd-MM-yyyy" disabled
                              placeholder="Please Select Date..."></Datepicker>
                          </div>

                          <div class="col-lg-4">
                            <span class="btn btn-primary" style="margin-left: 20px" @click="loadData">
                              GO
                            </span>
                            <span class="btn btn-success" style="margin-left: 20px" @click="downloadExcel"
                              type="button">
                              <i class="link-icon" data-feather="download"></i>
                              Download
                            </span>
                          </div>
                        </div>
                        <br />
                        <div class="row">
                          <div class="col-lg-12">
                            <ul class="nav nav-tabs" id="myDriverHistoryTab" role="tablist2">
                              <li class="nav-item">
                                <a class="nav-link active" id="all-tab" data-bs-toggle="tab" href="#all" role="tab"
                                  aria-controls="all" aria-selected="true">ALL</a>
                              </li>
                              <li class="nav-item">
                                <a class="nav-link" id="ontime-tab" data-bs-toggle="tab" href="#ontime" role="tab"
                                  aria-controls="ontime" aria-selected="false">{{ $t("ontime") }}</a>
                              </li>

                              <li class="nav-item">
                                <a class="nav-link" id="claim-tab" data-bs-toggle="tab" href="#claim" role="tab"
                                  aria-controls="claim" aria-selected="false">{{ $t("claim") }}</a>
                              </li>

                              <li class="nav-item">
                                <a class="nav-link" id="pok-tab" data-bs-toggle="tab" href="#pok" role="tab"
                                  aria-controls="pok" aria-selected="false">POK</a>
                              </li>

                              <li class="nav-item">
                                <a class="nav-link" id="service-tab" data-bs-toggle="tab" href="#service" role="tab"
                                  aria-controls="service" aria-selected="false">{{ $t("serviceTxt") }}</a>
                              </li>

                              <li class="nav-item">
                                <a class="nav-link" id="pod-tab" data-bs-toggle="tab" href="#pod" role="tab"
                                  aria-controls="pod" aria-selected="false">POD</a>
                              </li>

                              <li class="nav-item">
                                <a class="nav-link" id="accident-tab" data-bs-toggle="tab" href="#accident" role="tab"
                                  aria-controls="accident" aria-selected="false">{{ $t("accident") }}</a>
                              </li>

                              <li class="nav-item">
                                <a class="nav-link" id="traffic-tab" data-bs-toggle="tab" href="#traffic" role="tab"
                                  aria-controls="traffic" aria-selected="false">{{ $t("trafficViolation") }}</a>
                              </li>

                              <li class="nav-item">
                                <a class="nav-link" id="damage-tab" data-bs-toggle="tab" href="#damage" role="tab"
                                  aria-controls="damage" aria-selected="false">{{ $t("propertyDamage") }}</a>
                              </li>

                              <li class="nav-item">
                                <a class="nav-link" id="workingdays-tab" data-bs-toggle="tab" href="#workingdays"
                                  role="tab" aria-controls="workingdays" aria-selected="false">{{ $t("workingDays")
                                  }}</a>
                              </li>
                            </ul>
                            <div class="tab-content border border-top-0 p-3" id="myDriverHistoryTab">
                              <div class="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                                <div class="row">
                                  <div class="col-lg-2">
                                    <div class="badge bg-primary">
                                      Total Shipment : {{totalShipment}}
                                    </div>
                                  </div>

                                  <div class="col-lg-2">
                                    <div class="badge bg-success">
                                      Success Shipment : {{succesShipment}} (44,2 %)
                                    </div>
                                  </div>

                                  <div class="col-lg-2">
                                    <div class="badge bg-warning">
                                      Claim Shipment {{claimShipment}} (12,5%)
                                    </div>
                                  </div>

                                  <div class="col-lg-2">
                                    <div class="badge bg-danger">
                                      POK Shipment {{pokShipment}} (20.8%)
                                    </div>
                                  </div>

                                  <div class="col-lg-2">
                                    <div class="badge bg-info">
                                      Accident {{pokShipment}} ( 20,8 %)
                                    </div>
                                  </div>
                                </div>

                                <br />

                                <all-history-delivery :detail="fetchAllHistoricalDelivery"></all-history-delivery>
                              </div>

                              <div class="tab-pane fade" id="ontime" role="tabpanel" aria-labelledby="ontime-tab">
                                <ontime-history-delivery :detail="[]"></ontime-history-delivery>
                              </div>

                              <div class="tab-pane fade" id="claim" role="tabpanel" aria-labelledby="claim-tab">
                                <claim-history-delivery
                                  :detail="fetchAllHistoricalDeliveryClaim"></claim-history-delivery>
                              </div>

                              <div class="tab-pane fade" id="pok" role="tabpanel" aria-labelledby="pok-tab">
                                <pok-history-delivery :detail="fetchAllHistoricalDeliveryPOK"></pok-history-delivery>
                              </div>

                              <div class="tab-pane fade" id="service" role="tabpanel" aria-labelledby="service-tab">
                                <service-history-delivery :detail="[]"></service-history-delivery>
                                <br />
                                <div class="row">
                                  <h5 class="float-right">
                                    Total Cost Per Periode: Rp.
                                  </h5>
                                </div>
                              </div>

                              <div class="tab-pane fade" id="pod" role="tabpanel" aria-labelledby="pod-tab">
                                <pod-history-delivery :detail="[]"></pod-history-delivery>
                              </div>

                              <div class="tab-pane fade" id="accident" role="tabpanel" aria-labelledby="accident-tab">

                                <accident-history-delivery
                                  :detail="fetchAllHistoricalDeliveryAccident"></accident-history-delivery>
                              </div>

                              <div class="tab-pane fade" id="traffic" role="tabpanel" aria-labelledby="traffic-tab">
                                <traffic-history-delivery :detail="fetchAllHistoricalDeliveryTv">
                                </traffic-history-delivery>
                              </div>

                              <div class="tab-pane fade" id="damage" role="tabpanel" aria-labelledby="damage-tab">
                                <property-damage-history-delivery :detail="[]"></property-damage-history-delivery>
                              </div>

                              <div class="tab-pane fade" id="workingdays" role="tabpanel"
                                aria-labelledby="workingdays-tab">
                                <working-days-history-delivery :detail="[]"></working-days-history-delivery>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="training" role="tabpanel" aria-labelledby="training-tab">
                        <div class="row">
                          <div class="alert alert-primary alert-dismissible fade show" role="alert">
                            Modul <strong>Under Construction!</strong>
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                          </div>
                        </div>
                        <training-driver-delivery :detail="[]"></training-driver-delivery>
                      </div>
                      <div class="tab-pane fade" id="bak" role="tabpanel" aria-labelledby="bak-tab">
                        <div class="row">
                          <div class="alert alert-primary alert-dismissible fade show" role="alert">
                            Modul <strong>Under Construction!</strong>
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                          </div>
                        </div>
                        <bak-driver></bak-driver>
                      </div>
                      <div class="tab-pane fade" id="equipment" role="tabpanel" aria-labelledby="equipment-tab">
                        <equipment-driver :detail="fetchAllEquipment"></equipment-driver>
                      </div>
                      <div class="tab-pane fade" id="driver_history_status" role="tabpanel"
                        aria-labelledby="driver-history-status-tab">

                        <driver-history-status :detail="fetchAllStatus"></driver-history-status>
                      </div>
                      <div class="tab-pane fade" id="deposit" role="tabpanel" aria-labelledby="deposit-tab">
                        <!-- <div class="row">
                          <div class="alert alert-primary alert-dismissible fade show" role="alert">
                              Modul <strong>Under Construction!</strong>
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                          </div>
                        </div> -->
                        <div class="row">
                          <div class="col-lg-4">
                            <Datepicker v-model="periode_awal_deposit" :bootstrap-styling="true" format="MM-yyyy"
                              minimum-view="month" placeholder="Please Select Date..."></Datepicker>
                          </div>

                          <!-- <div class="col-lg-4">
                            <Datepicker
                              v-model="periode_akhir_deposit"
                              :bootstrap-styling="true"
                              format="dd-MM-yyyy"
                              placeholder="Please Select Date..."
                            ></Datepicker>
                          </div> -->

                          <div class="col-lg-4">
                            <button class="btn btn-primary" style="margin-left: 20px"
                              @click.prevent="driverDepositDetail">
                              GO
                            </button>
                            <!-- <button
                              class="btn btn-success"
                              style="margin-left: 20px"
                              @click="downloadExcel"
                              type="button"
                            >
                              <i class="link-icon" data-feather="download"></i>
                                Downlaod
                            </button> -->
                          </div>
                        </div>

                        <br />
                        <deposit-history :detail="fetchDeposit" :periode="periode"></deposit-history>
                      </div>
                    </div>
                    <!--begin::loading-data-->

                    <!--end::loading-data-->
                  </div>
                  <div class="card-footer">
                    <button class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        " @click="backForm" type="button">
                      <i class="link-icon" data-feather="arrow-left"></i>
                      {{ $t("backMess") }}
                    </button>

                    <a v-if="permision_role.method_delete" class="
                          btn btn-danger
                          text-white
                          fw-bolder
                          btn-icon-text
                          float-end
                        " @click="deleteData()">
                      <i class="link-icon" data-feather="trash"></i>
                      {{ $t("deleteMess") }}
                    </a>

                    <a v-if="permision_role.method_update" class="
                          btn btn-primary
                          me-2
                          text-white
                          fw-bolder
                          btn-icon-text
                          float-end
                        " @click="editData()">
                      <i class="link-icon" data-feather="edit"></i>
                      {{ $t("editMess") }}
                    </a>
                  </div>
                </form>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import tableDriverId from "@/components/master/driver/TableDriverId.vue";
import allHistoryDelivery from "@/components/master/driver/AllHistoryDelivery.vue";
import onTimeHistoryDelivery from "@/components/master/driver/OnTimeDelivery.vue";
import claimHistoryDelivery from "@/components/master/driver/ClaimDelivery.vue";
import pokHistoryDelivery from "@/components/master/driver/PokDelivery.vue";
import serviceHistoryDelivery from "@/components/master/driver/ServiceDelivery.vue";
import podHistoryDelivery from "@/components/master/driver/PodDelivery.vue";
import accidentHistoryDelivery from "@/components/master/driver/AccidentDelivery.vue";
import trafficViolationHistoryDelivery from "@/components/master/driver/TrafficViolationHistoryDelivery.vue";
import propertyDamageHistoryDelivery from "@/components/master/driver/PropertyDamageHistoryDelivery.vue";
import workingDaysHistoryDelivery from "@/components/master/driver/WorkingDaysHistoryDelivery.vue";
import trainingDriverDelivery from "@/components/master/driver/TrainingDriverDelivery.vue";
import bakDriverDelivery from "@/components/master/driver/BakDriver.vue";
import equipmentDriverComponenent from "@/components/master/driver/equipmentDriver.vue";
import driverHistoryStatus from "@/components/master/driver/DriverHistoryStatus.vue";
import depositComponent from "@/components/master/driver/DepositHistoryDriver.vue";
import TeguranDriverComponenent from "@/components/master/driver/TeguranDriver.vue";
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  name: "DriverAdd",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    Datepicker,
    "teguran-driver-delivery": TeguranDriverComponenent,
    "table-driver-id": tableDriverId,
    "all-history-delivery": allHistoryDelivery,
    "ontime-history-delivery": onTimeHistoryDelivery,
    "claim-history-delivery": claimHistoryDelivery,
    "pok-history-delivery": pokHistoryDelivery,
    "service-history-delivery": serviceHistoryDelivery,
    "pod-history-delivery": podHistoryDelivery,
    "accident-history-delivery": accidentHistoryDelivery,
    "traffic-history-delivery": trafficViolationHistoryDelivery,
    "property-damage-history-delivery": propertyDamageHistoryDelivery,
    "working-days-history-delivery": workingDaysHistoryDelivery,
    "training-driver-delivery": trainingDriverDelivery,
    "bak-driver": bakDriverDelivery,
    "equipment-driver": equipmentDriverComponenent,
    "driver-history-status": driverHistoryStatus,
    "deposit-history": depositComponent,
  },
  data() {
    return {
      totalShipment: 0,
      succesShipment: 0,
      claimShipment: 0,
      pokShipment: 0,
      accidentShipment: 0,
      detailDriverID: [],
      fetchAllHistoricalDelivery: [],
      fetchAllHistoricalDeliveryClaim: [],
      fetchAllHistoricalDeliveryPOK: [],
      fetchAllHistoricalDeliveryAccident: [],
      fetchAllHistoricalDeliveryTv: [],
      fetchAllEquipment: [],
      fetchAllStatus: [],
      driver_requirement_id: 0,
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      maried_status: "",
      driver_substatus: "",
      fetchMariedStatus: [],
      religion: "",
      fetchReligion: [],
      fetchProvince: [],
      province: "",
      fetchCity: [],
      city: "",
      fetchRegion: [],
      region: "",
      fetchDistrict: [],
      district: "",
      fetchPostalCode: [],
      postalCode: "",
      fetchProvinceDomicile: [],
      provinceDomicile: "",
      fetchCityDomicile: [],
      cityDomicile: "",
      fetchRegionDomicile: [],
      regionDomicile: "",
      fetchDistrictDomicile: [],
      districtDomicile: "",
      fetchPostalCodeDomicile: [],
      postalCodeDomicile: "",
      fetchRelationShip: [],
      relationShip: "",
      fetchProvinceSibiling: [],
      provinceSibiling: "",
      fetchCitySibiling: [],
      citySibiling: "",
      gender: "",
      fetchGender: ['Laki-laki', 'Perempuan'],
      fetchRegionSibiling: [],
      regionSibiling: "",
      fetchDistrictSibiling: [],
      districtSibiling: "",
      fetchPostalCodeSibiling: [],
      postalCodeSibiling: "",
      fetchRecruitmentSource: [],
      recruitmentSource: "",
      fetchDriverId: [],
      driverId: "",
      fetchTripAnalysis: [],
      periode_awal: moment(String(new Date())).format(
        "YYYY-MM-DD"
      ),
      periode_akhir: moment(String(new Date())).format(
        "YYYY-MM-DD"
      ),
      periode_awal_deposit: moment(String(new Date())).format(
        "YYYY-MM-DD"
      ),
      periode: "",
      periode_akhir_deposit: "",
      education: "",
      fetchEducation: [],
      stop_reason: "",
      fetchStopReason: [],
      positions: "",
      fetchPosition: [],
      pool: "",
      fetchPool: [],
      project: "",
      fetchProject: [],
      fetchBranch: [],
      branch: [],
      permision_role: [],
      userData: "",
      driver_status: "",
      ethnic: "",
      fetchEthnic: [],
      sim_1: "",
      sim_2: "",
      fetchSIM: [],
      fetchStatus: [],
      fetchSub: [],
      companyCode: "",
      forms: {
        driver_id: "",
        driver_name: "",
        branch_id: "",
        site_name: "",
        project_name: "",
        driver_position: "",
        hp1: "",
        wa_hp1: "",
        hp2: "",
        wa_hp2: "",
        sap_code: "",
        driver_status: "",
        driver_substatus: "",
        status_remark: "",
        join_date: "",
        nickname: "",
        birth_place: "",
        birth_date: "",
        sim_type: "",
        sim_expire_date: "",
        ethnic: "",
        marital_status: "",
        children_number: "",
        id_card_number: "",
        education: "",
        religion: "",
        sim1_type: "",
        sim1_expire_date: "",
        sim2_type: "",
        sim2_expire_date: "",
        end_contract: "",
        stop_date: "",
        stop_reason: "",
        stop_remark: "",

        address_idcard: "",
        rt_idcard: "",
        rw_idcard: "",
        province_idcard: "",
        city_idcard: "",
        region_idcard: "",
        district_idcard: "",
        hp_idcard: "",
        name_idcard: "",
        relationship_idcard: "",
        address_domicile: "",
        rt_domicile: "",
        rw_domicile: "",
        province_domicile: "",
        city_domicile: "",
        region_domicile: "",
        district_domicile: "",
        hp_domicile: "",
        name_domicile: "",
        relationship_domicile: "",
        address_sibling: "",
        rt_sibling: "",
        rw_sibling: "",
        province_sibling: "",
        city_sibling: "",
        region_sibling: "",
        district_sibling: "",
        hp_sibling: "",
        postal_code_domicile: "",
        postal_code_idcard: "",
        name_sibling: "",
        relationship_sibling: "",
        recruitment_source: "",
        master_driver_id: "",
        recruiter_name: "",
        recruiter_id_card_number: "",
        recruiter_driver_id: "",
        phone_number: "",
        handphone_number: "",
        relationship_recruiter: "",
        remark: "",
      },
      photo_temporary: null,
      photo: "",

      //driver_requirement_id: 2,
      rekening_bca: false,
      req_android: false,
      req_berharga: "",
      req_covid_cert: false,
      req_domicile: false,
      req_ijazah: false,
      req_keluarga: false,
      req_kk: false,
      req_ktp: false,
      req_lamaran: false,
      req_mitra: false,
      req_no_ijazah: "",
      req_photo: false,
      req_riwayathidup: false,
      req_sehat: false,
      req_sim: false,
      req_skck: false,
      fetchDeposit: [],
    };
  },
  watch: {},
  methods: {

    driverDepositDetail() {
      let start = moment(String(this.periode_awal_deposit)).format("YYYY-MM");
      let periode = moment(String(this.periode_awal_deposit)).format("MMM YYYY");
      // let end = moment(String(this.periode_akhir_deposit)).format("YYYY-MM-DD");
      let driver_id = this.forms.driver_id;
      const baseURI = this.$settings.endPointCt + `driver-deposit-detail?start=` + start + `&driver_nmk=` + driver_id;

      return this.$http.get(baseURI).then((response) => {
        this.fetchDeposit = response.data.datas.data;
        this.periode = periode;
      });

    },


    changeDriverIdResource() {

      const baseURI = this.$settings.endPointCt + `master-driver?driver_id=` + this.driverId.driver_id;
      return this.$http.get(baseURI).then((response) => {
        this.forms.recruiter_name = response.data.datas.data[0].driver_name;
        this.forms.recruiter_id_card_number = response.data.datas.data[0].id_card_number;
        this.forms.phone_number = response.data.datas.data[0].hp1;
        this.forms.handphone_number = response.data.datas.data[0].hp2;
        this.forms.relationship_recruiter = "";
        //this.fetchDriverId = response.data.datas.data;
      });
    },

    changeStatus(ev) {
      this.driver_substatus = "";

      const baseURI =
        this.$settings.endPointCt + `master-lookup/dependent?unique_id=stdri&parent=` + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchSub = response.data.datas;
      });
    },

    removeImages() {
      this.$refs.photo = "";
      this.photo_temporary = null;
    },

    handleFileUpload(event) {
      this.photo = event.target.files[0];
      this.photo_temporary = URL.createObjectURL(this.photo);
    },

    changeJoinDate() {
      let start = moment(this.forms.join_date);
      let end = moment(new Date());
      let duration = moment.duration(end.diff(start));

      let month = duration.months();
      let years = duration.years();

      this.forms.working_month = month;
      this.forms.working_year = years;
      //this.dateDiff = Math.round(month);
      //console.log(this.dateDiff);
    },

    changeAgeDate() {
      let start = moment(this.forms.birth_date);
      console.log(start);
      let end = moment(new Date());
      let duration = moment.duration(end.diff(start));

      let years = duration.years();

      this.forms.age = years;
      //this.forms.working_year=years;
      //this.dateDiff = Math.round(month);
      //console.log(this.dateDiff);
    },

    // changeRecruitmentSource(){

    // },

    downloadExcel() { },

    resetForm() {
      this.removeImages();
      this.forms = "";
    },

    backForm() {
      window.location.href = "/driver";
    },

    loadBranch() {
      const baseURI = this.$settings.endPointCt + `branch`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchBranch = response.data.datas.data;
      });
    },

    asyncLoadBranch(ev) {
      const baseURI = this.$settings.endPointCt + `branch?branch_name=` + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchBranch = response.data.datas.data;
      });
    },


    loadStatus() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=stdri`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchStatus = response.data.datas;
      });
    },

    loadProvince() {
      const baseURI =
        this.$settings.endPoint + `postal-code/select-province/indonesia`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchProvince = response.data.datas.data;
        this.fetchProvinceDomicile = response.data.datas.data;
        this.fetchProvinceSibiling = response.data.datas.data;
      });
    },


    loadDriverId(master_driver_id) {

      //https://control-tower-api-development-kmpl7urr3a-as.a.run.app/driver-nmk?master_driver_id=188
      const baseURI =
        this.$settings.endPointCt + `driver-nmk?master_driver_id=` + master_driver_id;
      return this.$http.get(baseURI).then((response) => {
        this.detailDriverID = response.data.datas.data;
        console.log(this.detailDriverID);
      });
    },


    loadHistoricalDelivery(driver_id) {
      console.log(driver_id);
      var periode_awal = moment(String(this.periode_awal)).format("YYYY-MM-DD");
      var periode_akhir = moment(String(this.periode_akhir)).format("YYYY-MM-DD");

      //https://control-tower-api-development-kmpl7urr3a-as.a.run.app/driver-nmk?master_driver_id=188
      const baseURI =
        this.$settings.endPointCt + `blujay-order/histori-driver?driver_id=` + driver_id + `&start=` + periode_awal + `&end=` + periode_akhir;
      return this.$http.get(baseURI).then((response) => {
        this.fetchAllHistoricalDelivery = response.data.datas.data;
        this.totalShipment = response.data.datas.total;
        console.log(response);
        // this.detailDriverID = response.data.datas.data;
        // console.log(this.detailDriverID);
      });
    },


    loadHistoricalDeliveryClaim(master_driver_id) {
      console.log(master_driver_id);
      var periode_awal = moment(String(this.periode_awal)).format("YYYY-MM-DD");
      var periode_akhir = moment(String(this.periode_akhir)).format("YYYY-MM-DD");

      const baseURI =
        this.$settings.endPointCt + `driver-claim?master_driver_id=` + master_driver_id + `&start=` + periode_awal + `&end=` + periode_akhir;
      return this.$http.get(baseURI).then((response) => {
        this.fetchAllHistoricalDeliveryClaim = response.data.datas.data;

        // this.detailDriverID = response.data.datas.data;
        // console.log(this.detailDriverID);
      });
    },


    loadHistoricalDeliveryClaimPok(master_driver_id) {
      console.log(master_driver_id);
      var periode_awal = moment(String(this.periode_awal)).format("YYYY-MM-DD");
      var periode_akhir = moment(String(this.periode_akhir)).format("YYYY-MM-DD");


      const baseURI =
        this.$settings.endPointCt + `driver-pok?master_driver_id=` + master_driver_id + `&start=` + periode_awal + `&end=` + periode_akhir;
      return this.$http.get(baseURI).then((response) => {
        this.fetchAllHistoricalDeliveryPOK = response.data.datas.data;

        // this.detailDriverID = response.data.datas.data;
        // console.log(this.detailDriverID);
      });
    },


    editData() {
      var params = this.$route.params.id
      window.location.href = "/driver/edit/" + params;
    },


    deleteData() {
      var id = this.$onBehind(this.$route.params.id);
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI =
            this.$settings.endPoint + "driver/delete/" + id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                window.location.href = "/driver";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    loadHistoricalDeliveryClaimAccident(master_driver_id) {
      console.log(master_driver_id);
      var periode_awal = moment(String(this.periode_awal)).format("YYYY-MM-DD");
      var periode_akhir = moment(String(this.periode_akhir)).format("YYYY-MM-DD");


      const baseURI =
        this.$settings.endPointCt + `driver-accident??master_driver_id=` + master_driver_id + `&start=` + periode_awal + `&end=` + periode_akhir;
      return this.$http.get(baseURI).then((response) => {
        this.fetchAllHistoricalDeliveryAccident = response.data.datas.data;

        // this.detailDriverID = response.data.datas.data;
        // console.log(this.detailDriverID);
      });
    },




    loadHistoricalDeliveryClaimTv(master_driver_id) {
      console.log(master_driver_id);
      var periode_awal = moment(String(this.periode_awal)).format("YYYY-MM-DD");
      var periode_akhir = moment(String(this.periode_akhir)).format("YYYY-MM-DD");


      const baseURI =
        this.$settings.endPointCt + `driver-tv?master_driver_id=` + master_driver_id + `&start=` + periode_awal + `&end=` + periode_akhir;
      return this.$http.get(baseURI).then((response) => {
        this.fetchAllHistoricalDeliveryTv = response.data.datas.data;

        // this.detailDriverID = response.data.datas.data;
        // console.log(this.detailDriverID);
      });
    },



    loadEquipment(master_driver_id) {
      console.log(master_driver_id);
      var periode_awal = moment(String(this.periode_awal)).format("YYYY-MM-DD");
      var periode_akhir = moment(String(this.periode_akhir)).format("YYYY-MM-DD");


      const baseURI =
        this.$settings.endPointCt + `driver-equipment?master_driver_id=` + master_driver_id + `&start=` + periode_awal + `&end=` + periode_akhir;
      return this.$http.get(baseURI).then((response) => {
        this.fetchAllEquipment = response.data.datas.data;

        // this.detailDriverID = response.data.datas.data;
        // console.log(this.detailDriverID);
      });
    },


    loadDriverStatus(master_driver_id) {
      console.log(master_driver_id);


      const baseURI =
        this.$settings.endPointCt + `driver-status?master_driver_id=` + master_driver_id;
      return this.$http.get(baseURI).then((response) => {
        this.fetchAllStatus = response.data.datas.data;

        // this.detailDriverID = response.data.datas.data;
        // console.log(this.detailDriverID);
      });
    },



    checkSameID() {
      console.log(this.forms.same_as_id);
      console.log(this.province);
      this.provinceDomicile = this.province;
      if (this.forms.same_as_id == true) {
        this.provinceDomicile = this.province;
        this.cityDomicile = this.city;
        this.regionDomicile = this.region;
        this.districtDomicile = this.district;
        this.postalCodeDomicile = this.postalCode;
        this.forms.address_domicile = this.forms.address_idcard;
        this.forms.rt_domicile = this.forms.rt_idcard;
        this.forms.rw_domicile = this.forms.rw_idcard;
      } else {
        this.provinceDomicile = [];
        this.cityDomicile = [];
        this.regionDomicile = [];
        this.districtDomicile = [];
        this.postalCodeDomicile = [];
        this.forms.address_domicile = "";
        this.forms.rt_domicile = "";
        this.forms.rw_d = "";
      }
    },

    searchCity(ev) {
      console.log(ev.province);
      const baseURI =
        this.$settings.endPoint + `postal-code/select-city/` + ev.province;
      return this.$http.get(baseURI).then((response) => {
        this.fetchCity = response.data.datas;
      });
    },

    searchCityDomicile(ev) {
      const baseURI =
        this.$settings.endPoint + `postal-code/select-city/` + ev.province;
      return this.$http.get(baseURI).then((response) => {
        this.fetchCityDomicile = response.data.datas;
      });
    },

    searchCitySibiling(ev) {
      console.log(ev.province);
      const baseURI =
        this.$settings.endPoint + `postal-code/select-city/` + ev.province;
      return this.$http.get(baseURI).then((response) => {
        this.fetchCitySibiling = response.data.datas;
      });
    },



    searchSubDistrict(ev) {
      const baseURI =
        this.$settings.endPoint + `postal-code/select-sub-district/` + ev.district;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDistrict = response.data.datas;
      });
    },

    searchSubDistrictDomicile(ev) {
      const baseURI =
        this.$settings.endPoint + `postal-code/select-sub-district/` + ev.district;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDistrictDomicile = response.data.datas;
      });
    },

    searchSubDistrictSibling(ev) {
      const baseURI =
        this.$settings.endPoint + `postal-code/select-sub-district/` + ev.district;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDistrictSibiling = response.data.datas;
      });
    },

    searchRegion(ev) {
      const baseURI =
        this.$settings.endPoint + `postal-code/select-district/` + ev.city;
      return this.$http.get(baseURI).then((response) => {
        this.fetchRegion = response.data.datas;
      });
    },

    searchPostalCode(ev) {
      var district = this.region.district;
      const baseURI =
        this.$settings.endPoint + `postal-code?district=` + district + `&sub_district=` + ev.sub_district;
      return this.$http.get(baseURI).then((response) => {
        this.fetchPostalCode = response.data.datas.data;
      });
    },

    searchPostalCodeDistrict(ev) {
      var district = this.regionDomicile.district;
      const baseURI =
        this.$settings.endPoint + `postal-code?district=` + district + `&sub_district=` + ev.sub_district;
      return this.$http.get(baseURI).then((response) => {
        this.fetchPostalCodeDomicile = response.data.datas.data;
      });
    },


    searchPostalCodeSibling(ev) {
      var district = this.regionSibiling.district;
      const baseURI =
        this.$settings.endPoint + `postal-code?district=` + district + `&sub_district=` + ev.sub_district;
      return this.$http.get(baseURI).then((response) => {
        this.fetchPostalCodeSibiling = response.data.datas.data;
      });
    },


    searchRegionSibiling(ev) {
      const baseURI =
        this.$settings.endPoint + `postal-code/select-district/` + ev.city;
      return this.$http.get(baseURI).then((response) => {
        this.fetchRegionSibiling = response.data.datas;
      });
    },

    searchRegionDomicile(ev) {
      const baseURI =
        this.$settings.endPoint + `postal-code/select-district/` + ev.city;
      return this.$http.get(baseURI).then((response) => {
        this.fetchRegionDomicile = response.data.datas;
      });
    },

    loadPostalCode() {
      const baseURI = this.$settings.endPoint + `postal-code`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchPostalCode = response.data.datas.data;
        this.fetchPostalCodeDomicile = response.data.datas.data;
        this.fetchPostalCodeSibiling = response.data.datas.data;
      });
    },
    // loadPostalCodeDomicile(){
    //   const baseURI = this.$settings.endPoint + `postal-code`;
    //   return this.$http.get(baseURI).then((response) => {
    //     this.fetchPostalCodeDomicile = response.data.datas.data;
    //   });
    // },

    loadSub() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=susta`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchSub = response.data.datas;
      });
    },

    loadMaried() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=marit`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchMariedStatus = response.data.datas;
      });
    },

    loadSim() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=simtp`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchSIM = response.data.datas;
      });
    },

    loadEthnic() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=ethni`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchEthnic = response.data.datas;
      });
    },

    loadPool() {
      const baseURI = this.$settings.endPointCt + `pool-branch`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchPool = response.data.datas.data;
      });
    },

    loadPosition() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=posit`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchPosition = response.data.datas;
      });
    },

    loadProject() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=proje`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchProject = response.data.datas;
      });
    },

    loadRelationShip() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=relat`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchRelationShip = response.data.datas;
      });
    },

    loadReligion() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=relig`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchReligion = response.data.datas;
      });
    },

    loadDriver() {
      const baseURI = this.$settings.endPointCt + `master-driver`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchDriverId = response.data.datas.data;
      });
    },

    loadEducation() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=educa`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchEducation = response.data.datas;
      });
    },

    loadReason() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=strea`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchStopReason = response.data.datas;
      });
    },

    loadRecruitmenSource() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=resou`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchRecruitmentSource = response.data.datas;
      });
    },


    loadRecruitments(id) {
      const baseURI =
        this.$settings.endPointCt + `driver-requirement?master_driver_id=` + id;

      return this.$http.get(baseURI).then((response) => {
        var resp = response.data.datas;
        if (resp.total > 0) {
          console.log(resp.data[0]);
          this.driver_requirement_id = resp.data[0].driver_requirement_id;
          this.rekening_bca = resp.data[0].rekening_bca;
          this.req_android = resp.data[0].req_android;
          this.req_berharga = resp.data[0].req_berharga;
          this.req_covid_cert = resp.data[0].req_covid_cert;
          this.req_domicile = resp.data[0].req_domicile;
          this.req_ijazah = resp.data[0].req_ijazah;
          this.req_keluarga = resp.data[0].req_keluarga;
          this.req_kk = resp.data[0].req_kk;
          this.req_ktp = resp.data[0].req_ktp;
          this.req_lamaran = resp.data[0].req_lamaran;
          this.req_mitra = resp.data[0].req_mitra;
          this.req_no_ijazah = resp.data[0].req_no_ijazah;
          this.req_photo = resp.data[0].req_photo;
          this.req_riwayathidup = resp.data[0].req_riwayathidup;
          this.req_sehat = resp.data[0].req_sehat;
          this.req_sim = resp.data[0].req_sim;
          this.req_skck = resp.data[0].req_skck;
        }

      });
    },


    loadData() {
      var id = this.$onBehind(this.$route.params.id);
      const baseURI = this.$settings.endPointCt + "master-driver/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        this.forms = response.data.datas;
        this.branch = response.data.datas.branch;
        this.photo_temporary = response.data.datas.photo;
        this.driver_status = this.forms.driver_status;
        this.gender = this.forms.gender;
        this.driver_substatus = this.forms.driver_substatus;

        this.positions = this.forms.driver_position;

        this.pool = {
          'pool_name': this.forms.site_name
        };

        this.project = this.forms.project_name;

        this.forms.name_idcard = this.forms.driver_name;


        this.maried_status = this.forms.marital_status;

        this.religion = this.forms.religion;

        this.education = this.forms.education;

        this.ethnic = this.forms.ethnic;

        this.sim_1 = this.forms.sim1_type;

        this.sim_2 = this.forms.sim2_type;

        this.stop_reason = this.forms.stop_reason;

        this.forms.rt_idcard = this.forms.address_idcard != null ? this.forms.address_idcard.rt : '';
        this.forms.rw_idcard = this.forms.address_idcard != null ? this.forms.address_idcard.rw : '';

        this.province = {
          'province': this.forms.address_idcard != null ? this.forms.address_idcard.province : ''
        }

        this.city = {
          'city': this.forms.address_idcard != null ? this.forms.address_idcard.city : ''
        };


        this.region = {
          'district': this.forms.address_idcard != null ? this.forms.address_idcard.region : ''
        }


        this.district = {
          'sub_district': this.forms.address_idcard != null ? this.forms.address_idcard.district : ''
        }


        this.postalCode = {
          'postal_code': this.forms.address_idcard != null ? this.forms.address_idcard.postal_code : ''
        }


        this.provinceDomicile = {
          'province': this.forms.address_domicile != null ? this.forms.address_domicile.province : ''
        }

        this.cityDomicile = {
          'city': this.forms.address_domicile != null ? this.forms.address_domicile.city : ''
        }


        this.regionDomicile = {
          'district': this.forms.address_domicile != null ? this.forms.address_domicile.region : ''
        }



        this.districtDomicile = {
          'sub_district': this.forms.address_domicile != null ? this.forms.address_domicile.district : ''
        }



        this.postalCodeDomicile = {
          'postal_code': this.forms.address_domicile != null ? this.forms.address_domicile.postal_code : ''
        }



        this.provinceSibiling = {
          'province': this.forms.address_sibling != null ? this.forms.address_sibling.province : ''
        }



        this.citySibiling = {
          'city': this.forms.address_sibling != null ? this.forms.address_sibling.city : ''
        }



        this.regionSibiling = {
          'district': this.forms.address_sibling != null ? this.forms.address_sibling.region : ''
        }


        this.districtSibiling = {
          'sub_district': this.forms.address_sibling != null ? this.forms.address_sibling.district : ''
        }

        this.postalCodeSibiling = {
          'postal_code': this.forms.address_sibling != null ? this.forms.address_sibling.postal_code : ''
        }

        this.forms.name_sibling = this.forms.address_sibling != null ? this.forms.address_sibling.name : ''
        this.forms.hp_sibling = this.forms.address_sibling != null ? this.forms.address_sibling.hp : '';


        //this.relationShip=this.forms.address_sibling.relationship;
        //this.forms.rw_sibling=this.forms.address_sibling.rw;

        this.forms.rt_domicile = this.forms.address_domicile != null ? this.forms.address_domicile.rt : '';
        this.forms.rw_domicile = this.forms.address_domicile != null ? this.forms.address_domicile.rw : '';

        this.forms.address_idcard = this.forms.address_idcard != null ? this.forms.address_idcard.address : '';
        this.forms.address_domicile = this.forms.address_domicile != null ? this.forms.address_domicile.address : '';


        this.recruitmentSource = this.forms.recruitment_info != null ? this.forms.recruitment_info.recruitment_source : '';
        this.relationShip = this.forms.address_sibling != null ? this.forms.address_sibling.relationship : '';

        this.driverId = this.forms.recruitment_info != null ? this.forms.recruitment_info.phone_number : '';
        this.forms.phone_number = this.forms.recruitment_info != null ? this.forms.recruitment_info.phone_number : '';
        this.forms.handphone_number = this.forms.recruitment_info != null ? this.forms.recruitment_info.phone_number : '';
        this.forms.recruiter_id_card_number = this.forms.recruitment_info != null ? this.forms.recruitment_info.handphone_number : '';
        this.forms.recruiter_name = this.forms.recruitment_info != null ? this.forms.recruitment_info.recruiter_name : '';
        this.forms.relationship_recruiter = this.forms.recruitment_info != null ? this.forms.recruitment_info.relationship_recruiter : '';
        this.forms.remark = this.forms.recruitment_info != null ? this.forms.recruitment_info.remark : '';
        this.changeAgeDate();
        this.changeJoinDate();
        this.loadRecruitments(id);
        this.loadDriverId(id);
        this.loadHistoricalDelivery(this.forms.driver_id);
        this.loadHistoricalDeliveryClaim(id);
        this.loadHistoricalDeliveryClaimPok(id);
        this.loadHistoricalDeliveryClaimAccident(id);
        this.loadHistoricalDeliveryClaimTv(id);
        this.loadHasilTest(id);
        //this.loadEquipment(id);
        this.loadDriverStatus(id);

        // this.form={ name: 'asep', usernmae:res.username };
      });
    },


    loadHasilTest(id) {
      const baseURI =
        this.$settings.endPointCt + `driver-test?master_driver_id=` + id;

      return this.$http.get(baseURI).then((response) => {
        var resp = response.data.datas;
        if (resp.total > 0) {
          //console.log(resp.data[0]);
          this.forms.driver_test_id = resp.data[0].driver_test_id;
          this.forms.tanggal_test = resp.data[0].tanggal_test;
          this.forms.nilai_teknis = resp.data[0].nilai_teknis;
          this.forms.nilai_psikotest = resp.data[0].nilai_psikotest;
          this.forms.nilai_testdrive = resp.data[0].nilai_testdrive;
          this.forms.nilai_mata_kanan = resp.data[0].nilai_mata_kanan;
          this.forms.nilai_mata_kiri = resp.data[0].nilai_mata_kiri;
          this.forms.remark_test = resp.data[0].remark;

        }

      });
    },



    submitData() {
      //console.log(this.maried_status);
      this.fade(true);
      this.forms.branch_id = this.branch.branch_id;
      this.forms.join_date = moment(String(this.forms.join_date)).format(
        "YYYY-MM-DD"
      );
      this.forms.birth_date = moment(String(this.forms.birth_date)).format(
        "YYYY-MM-DD"
      );
      this.forms.sim_expire_date = moment(
        String(this.forms.sim_expire_date)
      ).format("YYYY-MM-DD");

      this.forms.sim1_expire_date = moment(
        String(this.forms.sim1_expire_date)
      ).format("YYYY-MM-DD");

      this.forms.end_contract = moment(
        String(this.forms.end_contract)
      ).format("YYYY-MM-DD");

      this.forms.stop_date = moment(
        String(this.forms.stop_date)
      ).format("YYYY-MM-DD");

      this.forms.sim2_expire_date = moment(
        String(this.forms.sim2_expire_date)
      ).format("YYYY-MM-DD");

      this.forms.gender = this.gender;
      this.forms.branch_id = this.branch.branch_id;
      this.forms.driver_name = this.forms.name_idcard;
      this.forms.driver_position = this.positions;
      this.forms.site_name = this.pool.pool_name;
      this.forms.project_name = this.project;
      this.forms.education = this.education;
      this.forms.religion = this.religion;
      this.forms.ethnic = this.ethnic;
      this.forms.stop_reason = this.stop_reason;
      this.forms.province_idcard = this.province.province;
      this.forms.city_idcard = this.city.city;
      this.forms.region_idcard = this.region.district;
      this.forms.district_idcard = this.district.sub_district;
      this.forms.postal_code_idcard = this.postalCode.postal_code;
      this.forms.marital_status = this.maried_status;
      this.forms.sim1_type = this.sim_1;
      this.forms.sim2_type = this.sim_2;

      this.forms.province_domicile = this.provinceDomicile.province;
      this.forms.city_domicile = this.cityDomicile.city;
      this.forms.region_domicile = this.regionDomicile.district;
      this.forms.district_domicile = this.districtDomicile.sub_district;
      this.forms.postal_code_domicile = this.postalCodeDomicile.postal_code;

      this.forms.province_sibling = this.provinceSibiling.province;
      this.forms.city_sibling = this.citySibiling.city;
      this.forms.region_sibling = this.regionSibiling.district;
      this.forms.district_sibling = this.districtSibiling.sub_district;
      this.forms.postal_code_sibling = this.postalCodeSibiling.postal_code;

      this.forms.relationship_sibling = this.relationShip;

      this.forms.recruitment_source = this.recruitmentSource;
      this.forms.recruiter_driver_id = this.driverId.driver_id;
      this.forms.photo = this.photo;

      this.forms.driver_status = this.driver_status;
      this.forms.driver_substatus = this.driver_substatus;

      //console.log(this.forms.driver_status);

      if (this.forms.wa_hp1 == true) {
        this.forms.wa_hp1 = 1;
      } else {
        this.forms.wa_hp1 = 0;
      }

      if (this.forms.wa_hp2 == true) {
        this.forms.wa_hp2 = 1;
      } else {
        this.forms.wa_hp2 = 0;
      }

      let formData = new FormData();

      Object.keys(this.forms).forEach((key) => {
        formData.append(key, this.forms[key]);
      });

      // for (let i = 0; i < this.forms.length; i++) {
      //   console.log(this.forms[i]);
      //   formData.append(this.forms[i], this.forms[i]);
      // }

      // this.forms.forEach((number, index, array) => {
      //     console.log(array);
      //     console.log(number);
      //     console.log(index);
      // });
      var dataRequirement = {
        'driver_requirement_id': this.driver_requirement_id,
        'master_driver_id': this.forms.master_driver_id,
        'rekening_bca': this.rekening_bca ? 1 : 0,
        'req_android': this.req_android ? 1 : 0,
        'req_berharga': this.req_berharga,
        'req_covid_cert': this.req_covid_cert ? 1 : 0,
        'req_domicile': this.req_domicile ? 1 : 0,
        'req_ijazah': this.req_ijazah ? 1 : 0,
        'req_keluarga': this.req_keluarga ? 1 : 0,
        'req_kk': this.req_kk ? 1 : 0,
        'req_ktp': this.req_ktp ? 1 : 0,
        'req_lamaran': this.req_lamaran ? 1 : 0,
        'req_mitra': this.req_mitra ? 1 : 0,
        'req_no_ijazah': this.req_no_ijazah,
        'req_photo': this.req_photo ? 1 : 0,
        'req_riwayathidup': this.req_riwayathidup ? 1 : 0,
        'req_sehat': this.req_sehat ? 1 : 0,
        'req_sim': this.req_sim ? 1 : 0,
        'req_skck': this.req_skck ? 1 : 0,
      };
      // console.log(this.forms);
      const baseURI = this.$settings.endPointCt + "master-driver/update/" + this.forms.master_driver_id;
      this.$http
        .post(baseURI, formData)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            //this.resetForm();
            this.submitDataRequirment(dataRequirement);
            this.errors = [];
            // console.log(response.data.datas.user_id);
            var params = this.$onRandom(this.forms.master_driver_id);
            window.location.href = "/driver/detail/" + params + "?status=200&msg=Successfully";
            this.success('Berhasil');
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },


    submitDataRequirment(data) {

      console.log(data.driver_requirement_id);
      if (data.driver_requirement_id != 0) {
        var baseURiEdit = this.$settings.endPointCt + "driver-requirement/update/" + this.driver_requirement_id;
        this.$http
          .patch(baseURiEdit, data)
          .then((response) => {
            console.log(response);
            //this.loading();
            // if (response.data.status === 200) {

            // } else {
            //   this.errors = response.data.errors;
            //   this.resultError(response.data.errors);
            // }
          })
          .catch((error) => {
            this.loading();
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
            //this.resetForm();
          });
      } else {
        var baseURI = this.$settings.endPointCt + "driver-requirement/create";
        this.$http
          .post(baseURI, data)
          .then((response) => {
            console.log(response);
            //this.loading();
            // if (response.data.status === 200) {

            // } else {
            //   this.errors = response.data.errors;
            //   this.resultError(response.data.errors);
            // }
          })
          .catch((error) => {
            this.loading();
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                //this.$router.push("/page-not-found");
              }
            }
            //this.resetForm();
          });

      }

    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "MasterDriverController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {
    this.loadBranch();
  },
  mounted() {
    //var params = this.$onBehind(this.$route.params.id);
    this.loadData();
    this.fetchIt();
    this.loadStatus();
    this.loadSub();
    this.loadPosition();
    this.loadPool();
    this.loadMaried();
    this.loadEducation();
    this.loadProject();
    this.loadReligion();
    this.loadEthnic();
    this.loadSim();
    this.loadReason();
    this.loadRelationShip();
    this.loadProvince();
    // this.loadRegion();
    this.loadPostalCode();
    this.loadRecruitmenSource();
    this.loadDriver();
  },
};
</script>
