import Penyebrangan from "@/components/master/penyebrangan/Penyebrangan.vue";
import PenyebranganAdd from "@/components/master/penyebrangan/PenyebranganAdd.vue";
import PenyebranganDetail from "@/components/master/penyebrangan/PenyebranganDetail.vue";
import PenyebranganEdit from "@/components/master/penyebrangan/PenyebranganEdit.vue";
import PenyebranganUpload from "@/components/master/penyebrangan/PenyebranganUpload.vue";

let routesPenyebrangan = [
  {
    path: "",
    meta: {
      title: "Chain System",
    },
    name: "PenyebranganIndex",
    component: Penyebrangan,
  },
  {
    path: "add",
    meta: {
      title: "Chain System",
    },
    name: "PenyebranganAdd",
    component: PenyebranganAdd,
  },

  {
    path: "edit/:id",
    meta: {
      title: "Chain System",
    },
    name: "PenyebranganEdit",
    component: PenyebranganEdit,
  },

  {
    path: "detail/:id",
    meta: {
      title: "Chain System",
    },
    name: "PenyebranganDetail",
    component: PenyebranganDetail,
  },

  {
    path: "upload",
    meta: {
      title: "Chain System",
    },
    name: "PenyebranganUpload",
    component: PenyebranganUpload,
  },
];

export default routesPenyebrangan;
