<template>
    <div>
        <router-view />
    </div>
</template>

<script>
export default {
    name:"SkuMaster",
    data () {
        return {}
    },
    created () {

    },
    methods: {
    
    },
}
</script>

<style>

</style>