<template>
  <div>
    <div
      ref="modal"
      class="modal fade"
      :class="{ show: active, 'd-block': active }"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Customer</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              v-on:click="$emit('closeModal', null, null)"
              aria-label="btn-close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-lg-3">
                <label
                  for="defaultconfig"
                  class="col-form-label"
                  style="padding-left: 25px"
                  >SAP Code
                </label>
              </div>
              <div class="col-lg-8">
                <input
                  type="text"
                  class="form-control"
                  v-model="forms.sap_code"
                  :disabled="isLoading"
                />
                <div
                  v-for="error in errors.sap_code"
                  :key="error"
                  class="alert alert-primary"
                  role="alert"
                >
                  <i data-feather="alert-circle"></i>
                  {{ error }}
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-lg-3">
                <label
                  for="defaultconfig"
                  class="col-form-label"
                  style="padding-left: 25px"
                  >Customer Code
                </label>
              </div>
              <div class="col-lg-8">
                <input
                  type="text"
                  class="form-control"
                  v-model="forms.customer_code"
                  :disabled="isLoading"
                />
                <div
                  v-for="error in errors.customer_code"
                  :key="error"
                  class="alert alert-primary"
                  role="alert"
                >
                  <i data-feather="alert-circle"></i>
                  {{ error }}
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-lg-3">
                <label
                  for="defaultconfig"
                  class="col-form-label"
                  style="padding-left: 25px"
                  >Customer Name
                </label>
              </div>
              <div class="col-lg-8">
                <input
                  type="text"
                  class="form-control"
                  v-model="forms.customer_name"
                  :disabled="isLoading"
                />
                <div
                  v-for="error in errors.customer_name"
                  :key="error"
                  class="alert alert-primary"
                  role="alert"
                >
                  <i data-feather="alert-circle"></i>
                  {{ error }}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              v-on:click="$emit('closeModal')"
            >
              cancel
            </button>
            <div>
              <button
                type="button"
                class="btn btn-primary me-2"
                v-on:click="submitData"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modalUpdateDate",
  emits: ["closeModal"],

  props: {
    showModal: Boolean,
    data: null,
  },
  watch: {
    showModal: {
      handler(newVal) {
        this.active = newVal;
        this.forms = {};
        if (this.active) {
          this.loadData();
        }
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      opens: "center",
      active: this.showModal,
      company_code: "",
      fetchCompany: [],
      company: [],
      forms: {},
      errors: [],
      locale: {
        format: "dd mmm yyyy H:MM:ss",
      },
    };
  },
  methods: {
    submitData() {
      let data = {
        sap_code: this.forms.sap_code,
        customer_name: this.forms.customer_name,
        customer_code: this.forms.customer_code,
        status_customer: this.forms.status_customer,
        sob_status: this.forms.sob_status,
      };
      //   data.append("sap_code", this.forms.sap_code);
      //   data.append("customer_name", this.forms.customer_name);
      //   data.append("customer_code", this.forms.customer_code);
      //   data.append("status_customer", this.forms.status_customer);
      //   data.append("sob_status", this.forms.sob_status);

      var baseURI =
        this.$settings.endPoint + "sales-project/update/" + this.data;
      return this.$http
        .patch(baseURI, data)
        .then((response) => {
          if (response.data.status === 200) {
            this.$emit("closeModal");
            this.success2("Data Berhasil Disimpan");
            this.errors = [];
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },
    loadData() {
        this.fade(true);
      const baseURI =
        this.$settings.endPoint + "sales-project/detail/" + this.data;
      //var columnFilters = this.serverParams.columnFilters;
      return this.$http.get(baseURI).then((response) => {
        this.forms = response.data.datas;
        this.fade(false);
      });
    },
    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },
    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success2(msg) {
      this.$swal({
        icon: "info",
        backdrop: true,
        title: "Information",
        html: "<p>* " + msg + "</p>",
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },
  },
  mounted() {},
};
</script>
<style scoped>
.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left: 0px !important;
}
</style>
