<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="CustomerCTAdd"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            "
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 25px"
                  >
                    Carrier
                  </h6>
                  <button
                    class="btn btn-default btn-sm float-end"
                    @click="resetForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "
                    ></div>

                    <div class="row">
                    

                      <div class="col-lg-6">
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                            >
                              Carrier Code </label
                            >
                          </div>
                          <div class="col-lg-4">
                     
                            <input
                              type="text"
                              class="form-control"
                              id="Carrier Code"
                              placeholder="Carrier Code"
                              v-model="forms.carrier_code"
                         
                              required
                            />

                            <div v-if="errors.carrier_code">
                              <div
                                v-for="error in errors.carrier_code"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                  

                      </div>

                      <div class="col-lg-6">
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                            >
                              Carrier Name</label
                            >
                          </div>
                          <div class="col-lg-8">
                     
                            <input
                              type="text"
                              class="form-control"
                              id="name"
                              placeholder="Carrier Name"
                              v-model="forms.carrier_name"
                              required
                            />

                            <div v-if="errors.carrier_name">
                              <div
                                v-for="error in errors.carrier_name"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                  

                      </div>


                      <div class="col-lg-6">
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                            >
                              Carrier Email </label
                            >
                          </div>
                          <div class="col-lg-8">
                     
                            <input
                              type="text"
                              class="form-control"
                              id="state"
                              placeholder="Carrier Email"
                              v-model="forms.carrier_email"
                             
                            />

                            <div v-if="errors.carrier_email">
                              <div
                                v-for="error in errors.carrier_email"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                  

                      </div>


                      <div class="col-lg-6">
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                            >
                              Carrier Location </label
                            >
                          </div>
                          <div class="col-lg-8">
                     
                            <input
                              type="text"
                              class="form-control"
                              id="state"
                              placeholder="Carrier Location"
                              v-model="forms.location"
                             
                            />

                            <div v-if="errors.location">
                              <div
                                v-for="error in errors.location"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                  

                      </div>

                     

                     

                      

                   

                    

                    
                      
                    </div>

                    <div class="row card">
                      <div class="card-header">
                        <h5 class="float-start">
                          Truck
                        </h5>

                        <button
                          class="
                            btn btn-success btn-icon
                            text-white
                            fw-bolder
                            btn-icon-text
                            float-end
                          "
                          @click="addFieldTruck()"
                          type="button"
                        >
                          <i class="link-icon" data-feather="plus"></i>
                        </button>


                       

                      </div>

                      <div class="card-body">
                        <div class="table-responsive">
                          <table
                            class="table"
                            style="height: 300px"
                          >
                            <thead>
                              <tr>
                                <th>#</th>
                                <th style="width:500px">Nopol</th>
                                <th>Truck Type</th>
                            
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              
                              <tr
                                v-for="(input, index) in this.detailTruck"
                                :key="`phoneInput-${index}`"
                              >
                                <th>{{ index + 1 }}</th>
                                

                                <td width="200px">
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="vehicle_no"
                                   
                                    v-model="input.vehicle_no"
                                  
                                  />
                                </td>

                                <td width="200px">
                                

                              <multiselect
                              @search-change="asyncTypeTruck"
                              v-model="input.truck_type"
                              :options="fetchTypeTruck"
                              label="type_truck"
                              track-by="type_truck"
                              placeholder="Please Select Route"
                            
                            >
                              <span slot="noResult">-</span>
                            </multiselect>
                                  <!-- <v-select
                                  v-model="input.truck_type"
                                  :options="fetchTypeTruck"
                                  label="type_truck"
                                  :reduce="truck => truck.type_truck"
                                  >

                                  </v-select> -->


                                  
                                </td>

                              


                           



                             


                            


                            

                            





                                <td>
                                  <!--          Remove Svg Icon-->
                                  <svg
                                    @click="removeFieldTruck(index, detailTruck)"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    class="ml-2 cursor-pointer"
                                  >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path
                                      fill="red"
                                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                                    />
                                  </svg>

                                  

                                 
                                </td>
                              </tr>
                            </tbody>

                            <tfoot></tfoot>
                          </table>
                        </div>
                      </div>
                    </div>

                    <br>

                    <div class="row card">
                      <div class="card-header">
                        <h5 class="float-start">
                          Driver
                        </h5>

                        <button
                          class="
                            btn btn-success btn-icon
                            text-white
                            fw-bolder
                            btn-icon-text
                            float-end
                          "
                          @click="addFieldDriver()"
                          type="button"
                        >
                          <i class="link-icon" data-feather="plus"></i>
                        </button>


                       

                      </div>

                      <div class="card-body">
                        <div class="table-responsive">
                          <table
                            class="table"
                            style="height: 300px"
                          >
                            <thead>
                              <tr>
                                <th>#</th>
                                <th style="width:500px">Driver Name</th>
                                <th>Driver Number</th>
                               
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              
                              <tr
                                v-for="(input, index) in this.detailDriver"
                                :key="`phoneInput-${index}`"
                              >
                                <th>{{ index + 1 }}</th>
                                

                                <td width="200px">
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="driver_name"
                                   
                                    v-model="input.driver_name"
                                  
                                  />


                                </td>

                                <td width="200px">
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="driver_number"
                                    readonly
                                    v-model="input.driver_number"
                                  
                                  />
                                </td>

                                


                           



                             


                            


                  


                                <td>
                                  <!--          Remove Svg Icon-->
                                  <svg
                                    @click="removeFieldDriver(index, detailDriver)"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    class="ml-2 cursor-pointer"
                                  >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path
                                      fill="red"
                                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                                    />
                                  </svg>

                                  

                                 
                                </td>
                              </tr>
                            </tbody>

                            <tfoot></tfoot>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="card-footer">
                      <button
                        class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        "
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button
                        class="btn btn-primary float-end btn-sm"
                        type="submit"
                      >
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import multiselect from "vue-multiselect";

export default {
  name: "CustomerCTAdd",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    multiselect: multiselect,
  },
  data() {
    return {
      isLoading: false,

      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      fetchTypeDocument: [],
      fetchDocumentReference:[],

      highlighted:{

          dates: [ // Highlight an array of dates
            new Date(),
          ],

      },
      permision_role: [],
      userData: "",
      companyCode: "",
      type_document: "",
      document_reference: "",
      detailTruck:[],
      detailDriver:[],
      fetchTypeTruck:[],
      forms: {
       
      },
    };
  },
  watch: {},
  methods: {
    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 45) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    uppercase() { 
        this.forms.location_reference_number  = this.forms.location_reference_number.toUpperCase();
    },
    removeSpace(event) {
       if(event.keyCode === 32){
        event.preventDefault();
      }
    },

    resetForm() {
      this.forms = {
        master_customer_id : "",
        location_reference_number : "",
        name : "",
        address : "",
        city : "",
        region : "",
        state : "",
        country : "",
        postal_code : "",
        latitude : "",
        longitude : "",
        create_by : "",
        created_at : "",
        update_by : "",
        updated_at : ""
      };
    },

    backForm() {
      window.location.href = "/master-carrier";
    },
 
    loadTypeTruck(){
      const baseURI = this.$settings.endPointCt + `type-truck`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchTypeTruck = response.data.datas.data;
        this.fade(false);
      });
    },
    asyncTypeTruck(ev){
      const baseURI = this.$settings.endPointCt + `type-truck?type_truck=` + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTypeTruck = response.data.datas.data;
      });
    },

    submitData() {
      this.fade(true);

      var dataSend={
          "carrier_code": this.forms.carrier_code,
          "carrier_name": this.forms.carrier_code+"-"+this.forms.carrier_name,
          "carrier_email": this.forms.carrier_email,
          "location": this.forms.location,
          "status":'active',
          "driver": this.detailDriver.map(res => {
              return {
                  "driver_number": res.driver_number,
                  "driver_name": res.driver_name // Mengambil hanya data "type_truck"
              };
          }),
          "truck": this.detailTruck.map(truck => {
              return {
                  "vehicle_no": truck.vehicle_no.toUpperCase().replace(/\s/g, ''),
                  "truck_type": truck.truck_type.type_truck // Mengambil hanya data "type_truck"
              };
          })
      }
  
      const baseURI = this.$settings.endPointCt + "master-carrier/create-multiple";
      this.$http
        .post(baseURI, dataSend)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            //console.log(response.data.datas.user_id);
            var params = this.$onRandom(response.data.datas.master_carrier_id);
            window.location.href =
              "/master-carrier/detail/" + params + "?status=200&msg=Successfully";
            this.success('Berhasil');
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "MasterCarrierController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },


    addFieldTruck() {
      //fieldType.push({ value: "" });
      this.detailTruck.push({
    
        vehicle_no: "",
        truck_type: "",

      });
    },

    removeFieldTruck(index, fieldType) {
      fieldType.splice(index, 1);
    },


    addFieldDriver() {
      //fieldType.push({ value: "" });
      this.detailDriver.push({
    
        driver_number: "",
        driver_name: "",

      });
    },

    removeFieldDriver(index, fieldType) {
      fieldType.splice(index, 1);
    },


    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    this.fetchIt();
    this.loadTypeTruck();
  },
};
</script>
<style scoped>
</style>