import StopLocation from "@/components/master/stop_location/StopLocation.vue";
import StopLocationAdd from "@/components/master/stop_location/StopLocationAdd.vue";
import StopLocationDetail from "@/components/master/stop_location/StopLocationDetail.vue";
import StopLocationEdit from "@/components/master/stop_location/StopLocationEdit.vue";
import StopLocationUpload from "@/components/master/stop_location/StopLocationUpload.vue";

let routesStopLocation= [
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "StopLocation",
        component: StopLocation
      },
      {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "StopLocationAdd",
        component: StopLocationAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "StopLocationEdit",
        component: StopLocationEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "StopLocationDetail",
        component: StopLocationDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "StopLocationUpload",
        component: StopLocationUpload
      },
    
]

export default routesStopLocation
  