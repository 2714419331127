import GTNewsPortalIndex from "@/components/dasboard-gt/news-portal/GTNewsPortalIndex.vue";
import GTNewsPortalCreate from "@/components/dasboard-gt/news-portal/GTNewsPortalCreate.vue";
import GTNewsPortalDetail from "@/components/dasboard-gt/news-portal/GTNewsPortalDetail.vue";
import GTNewsPortalEdit from "@/components/dasboard-gt/news-portal/GTNewsPortalEdit.vue";

let routesNewsPortalGT= [
    {
        path: '',
        meta: {
          title: "Gate System - News Portal",
        },
        name: "GTNewsPortalIndex",
        component: GTNewsPortalIndex
    },
    {
        path: 'create',
        meta: {
          title: "Gate System - News Portal",
        },
        name: "GTNewsPortalCreate",
        component: GTNewsPortalCreate
    },
     
    {
        path: 'detail/:id',
        meta: {
          title: "Gate System - News Portal",
        },
        name: "GTNewsPortalDetail",
        component: GTNewsPortalDetail
    },
     
    {
        path: 'edit/:id',
        meta: {
          title: "Gate System - News Portal",
        },
        name: "GTNewsPortalEdit",
        component: GTNewsPortalEdit
    },
    
]

export default routesNewsPortalGT
  