import { render, staticRenderFns } from "./podIndex.vue?vue&type=template&id=dba44c80&scoped=true"
import script from "./podIndex.vue?vue&type=script&lang=js"
export * from "./podIndex.vue?vue&type=script&lang=js"
import style0 from "./podIndex.vue?vue&type=style&index=0&id=dba44c80&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dba44c80",
  null
  
)

export default component.exports