import Driver from "@/components/master/driver/Driver.vue";
import DriverAdd from "@/components/master/driver/DriverAdd.vue";
import DriverDetail from "@/components/master/driver/DriverDetail.vue";
import DriverEdit from "@/components/master/driver/DriverEdit.vue";
import DriverUpload from "@/components/master/driver/DriverUpload.vue";
import DriverApproval from "@/components/master/driver/driver_approval/DriverApproval.vue";
import DriverApprovalSearch from "@/components/master/driver/driver_approval/DriverApprovalSearch.vue";
import DriverBlackList from "@/components/master/driver/driver_blacklist/DriverBlackList.vue";
import DriverBlackListAdd from "@/components/master/driver/driver_blacklist/DriverBlackListAdd.vue";
import DriverBlackListDetail from "@/components/master/driver/driver_blacklist/DriverBlackListDetail.vue";
import DriverBlackListEdit from "@/components/master/driver/driver_blacklist/DriverBlackListEdit.vue";
import DriverBlackListUpload from "@/components/master/driver/driver_blacklist/DriverBlackListUpload.vue";

let routesDriver = [

  {
    path: '',
    meta: {
      title: "Chain System",
    },
    name: "DriverIndex",
    component: Driver
  },
  {
    path: 'add',
    meta: {
      title: "Chain System",
    },
    name: "DriverAdd",
    component: DriverAdd
  },
  {
    path: 'detail/:id',
    meta: {
      title: "Chain System",
    },
    name: "DriverDetail",
    component: DriverDetail
  },
  {
    path: 'edit/:id',
    meta: {
      title: "Chain System",
    },
    name: "DriverEdit",
    component: DriverEdit
  },
  {
    path: 'upload',
    meta: {
      title: "Chain System",
    },
    name: "DriverUpload",
    component: DriverUpload
  },
  {
    path: 'driver-approval',
    meta: {
      title: "Chain System",
    },
    name: "DriverApproval",
    component: DriverApproval
  },
  {
    path: 'driver-approval/check',
    meta: {
      title: "Chain System",
    },
    name: "DriverApprovalSearch",
    component: DriverApprovalSearch
  },
  {
    path: 'driver-blacklist',
    meta: {
      title: "Chain System",
    },
    name: "DriverBlackList",
    component: DriverBlackList
  },

  {
    path: 'driver-blacklist/add',
    meta: {
      title: "Chain System",
    },
    name: "DriverBlackListAdd",
    component: DriverBlackListAdd
  },

  {
    path: 'driver-blacklist/upload',
    meta: {
      title: "Chain System",
    },
    name: "DriverBlackListUpload",
    component: DriverBlackListUpload
  },

  {
    path: 'driver-blacklist/detail/:id',
    meta: {
      title: "Chain System",
    },
    name: "DriverBlackListDetail",
    component: DriverBlackListDetail
  },
  {
    path: 'driver-blacklist/edit/:id',
    meta: {
      title: "Chain System",
    },
    name: "DriverBlackListEdit",
    component: DriverBlackListEdit
  },

]

export default routesDriver

