import CustomerTripOrders from "@/components/dasboard-ct/customerTripOrders/CustomerTripOrders.vue";
// import CustomerTripOrdersAdd from "@/components/dasboard-ct/customerTripOrders/CustomerTripOrdersAdd.vue";
// import CustomerTripOrdersDetail from "@/components/dasboard-ct/customerTripOrders/CustomerTripOrdersDetail.vue";
import CustomerTripOrdersDetail from "@/components/dasboard-ct/customerTripOrders/CustomerTripOrdersDetail.vue";
import CustomerTripOrdersDetailDetail from "@/components/dasboard-ct/customerTripOrders/CustomerTripOrdersDetailDetail.vue";


let routesCustomerTripOrders= [
      {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "CustomerTripOrders",
        component: CustomerTripOrders
      },
    //   {
    //     path: 'add',
    //     meta: {
    //       title: "Chain System",
    //     },
    //     name: "CustomerTripOrdersAdd",
    //     component: CustomerTripOrdersAdd
    //   },
    //   {
    //     path: 'edit/:id',
    //     meta: {
    //       title: "Chain System",
    //     },
    //     name: "CustomerTripOrdersEdit",
    //     component: CustomerTripOrdersEdit
    //   },
      {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "CustomerTripOrdersDetail",
        component: CustomerTripOrdersDetail
      },

      {
        path: 'detail/:customer_order_id/:customer_order_detail_id',
        meta: {
          title: "Chain System",
        },
        name: "CustomerTripOrdersDetailDetail",
        component: CustomerTripOrdersDetailDetail
      },
    
   
    
]

export default routesCustomerTripOrders
  