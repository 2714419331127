import PengajuanFppk from "@/components/fppk/pengajuan/PengajuanFppk.vue";
import PengajuanFppkAdd from "@/components/fppk/pengajuan/PengajuanFppkAdd.vue";
import PengajuanFppkDetail from "@/components/fppk/pengajuan/PengajuanFppkDetail.vue";
import PengajuanFppkEdit from "@/components/fppk/pengajuan/PengajuanFppkEdit.vue";

let routes= [
    
    {
      path: '/',
      meta: {
        title: "Chain System",
      },
      name: "PengajuanFppk",
      component: PengajuanFppk
    },
    {
      path: 'add',
      meta: {
        title: "Chain System",
      },
      name: "PengajuanFppkAdd",
      component: PengajuanFppkAdd
    },
    {
      path: 'detail/:id',
      meta: {
        title: "Chain System",
      },
      name: "PengajuanFppkDetail",
      component: PengajuanFppkDetail
    },
    {
      path: 'edit/:id',
      meta: {
        title: "Chain System",
      },
      name: "PengajuanFppkEdit",
      component: PengajuanFppkEdit
    },
  ]
  
  export default routes
  